export const GET_STARTED_BASE_PATH = '/getStarted/dashboard';

export const PAGES = {
  getStarted: {
    label: 'Getting Started',
    path: GET_STARTED_BASE_PATH
  },
  learnMore: {
    label: 'Learn About Breach Secure Now',
    path: `${GET_STARTED_BASE_PATH}/learnMore`
  },
  products: {
    label: 'Products',
    path: `${GET_STARTED_BASE_PATH}/learnMore/products`
  }
};
