import React, { createContext, useContext } from 'react';

export const PreassessmentContext = createContext(null)

export const PreassessmentProvider = ({children, value}) => {
  return (
    <PreassessmentContext.Provider value={value}>
      {children}
    </PreassessmentContext.Provider>
  )
}

export const usePreassessmentContext = () => {
  return useContext(PreassessmentContext)
}




