import React, { useState } from 'react';
import { useLocation } from 'hooks';
import { useHistory } from 'react-router-dom';
import { LoadingStyled } from 'components';
import OrganizationProfile from './OrganizationProfile';
import { SRA_SUB_PAGES } from '../constants';
import { useClientInfo } from '../hooks';
import { useSRA } from '../context/sraContext';

const OrganizationProfilePage = () => {
  const { app, tab, item } = useLocation();
  const history = useHistory();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const { clientId } = useSRA();
  const { isHSN, isLoadingClientInfo } = useClientInfo(clientId);
  const [state, setState] = useState(null);
  const [originalState, setOriginalState] = useState(null);

  const goToSraPage = () => {
    history.push(`/${app}/${tab}${item && !isSraSubPage ? `/${item}` : ''}`);
  };

  if (isLoadingClientInfo) return <LoadingStyled />;


  return (
    <OrganizationProfile
      isHSN={isHSN}
      clientId={clientId}
      handleClose={goToSraPage}
      state={state}
      setState={setState}
      originalState={originalState}
      setOriginalState={setOriginalState}
    />
  );
};

export default OrganizationProfilePage;
