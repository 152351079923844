/* eslint-disable no-console */
// @flow
import React from 'react';
import { Error } from 'react-admin';
import Loadable from 'react-loadable';
import Loading from './Loading';

export { default as Jira } from './Jira';
export { default as JiraNotified } from './JiraNotified';
export { default as FileSearch } from './FileSearch';
export { default as Account } from './Account';
export { default as Alert2 } from './Alert2';
export { default as AddSheild } from './AddSheild';
export { default as Copy } from './Copy';
export { default as CopyLink } from './CopyLink';
export { default as Conflict } from './Conflict';
export { default as DarkWeb } from './DarkWeb';
export { default as DarkWebMan } from './DarkWebMan';
export { default as Done } from './Done';
export { default as DragDrop } from './DragDrop';
export { default as Distributor } from './Distributor';
export { default as Key } from './Key';
export { default as LandingPage } from './LandingPage';
export { default as Bell } from './Bell';
export { default as Lock } from './Lock';
export { default as LogOut } from './LogOut';
export { default as Phishing } from './Phishing';
export { default as User } from './User';
export { default as Users2 } from './Users2';
export { default as Users3 } from './Users3';
export { default as UserSearch } from './UserSearch';
export { default as UserMove } from './UserMove';
export { default as DarkMode } from './DarkMode';
export { default as Home } from './Home';
export { default as Notes } from './Notes';
export { default as Notification } from './Notification';
export { default as NotificationAlert } from './NotificationAlert';
export { default as Minus } from './Minus';
export { default as Monitor } from './Monitor';
export { default as MyDashboard } from './MyDashboard';
export { default as MyCompany } from './MyCompany';
export { default as Clients } from './Clients';
export { default as Newsfeed } from './Newsfeed';
export { default as WatchNow } from './WatchNow';
export { default as Shield } from './Shield';
export { default as ShieldAlert } from './ShieldAlert';
export { default as Chat } from './Chat';
export { default as Star } from './Star';
export { default as Newspaper } from './Newspaper';
export { default as StarFilled } from './StarFilled';
export { default as Info } from './Info';
export { default as Internet } from './Internet';
export { default as Close } from './Close';
export { default as CloseCircle } from './CloseCircle';
export { default as CircleArrow } from './CircleArrow';
export { default as Search } from './Search';
export { default as SearchIcon } from './SearchIcon';
export { default as ScrollRight } from './ScrollRight';
export { default as ScrollLeft } from './ScrollLeft';
export { default as Print } from './Print';
export { default as Email } from './Email';
export { default as Email2 } from './Email2';
export { default as Envelope } from './Envelope';
export { default as Eye } from './Eye';
export { default as Delete } from './Delete';
export { default as DeleteIcon } from './DeleteIcon';
export { default as LikeIcon } from './LikeIcon';
export { default as CommentIcon } from './CommentIcon';
export { default as Comment } from './Comment';
export { default as Mention } from './Mention';
export { default as Alert } from './Alert';
export { default as WeHaventInfoYet } from './WeHaventInfoYet';
export { default as UserOutline } from './UserOutline';
export { default as UserOutline2 } from './UserOutline2';
export { default as Track } from './Track';
export { default as Document } from './Document';
export { default as SelectedBullet } from './SelectedBullet';
export { default as Bullet } from './Bullet';
export { default as Mail } from './Mail';
export { default as Refresh } from './Refresh';
export { default as Download } from './Download';
export { default as Download2 } from './Download2';
export { default as DownloadIcon } from './DownloadIcon';
export { default as Heart } from './Heart';
export { default as Replay } from './Replay';
export { default as Planet } from './Planet';
export { default as Partners } from './Partners';
export { default as PartnerProfile } from './PartnerProfile';
export { default as HelpCenterIcon } from './HelpCenterIcon';
export { default as PartnerResource } from './PartnerResource';
export { default as PartnerKey } from './PartnerKey';
export { default as PartnerLogin } from './PartnerLogin';
export { default as PartnerSecurity } from './PartnerSecurity';
export { default as PartnerDark } from './PartnerDark';
export { default as PartnerBPP } from './PartnerBPP';
export { default as PartnerPII } from './PartnerPII';
export { default as PartnerPHI } from './PartnerPHI';
export { default as PartnerRemote } from './PartnerRemote';
export { default as Publish } from './Publish';
export { default as ColorPickup } from './ColorPickup';
export { default as Consulting } from './Consulting';
export { default as Insurance } from './Insurance';
export { default as Enable } from './Enable';
export { default as Disable } from './Disable';
export { default as Scenario } from './Scenario';
export { default as Calendar } from './Calendar';
export { default as CalendarFile } from './CalendarFile';
export { default as Plus } from './Plus';
export { default as PlusCircle } from './PlusCircle';
export { default as Filter } from './Filter';
export { default as Play } from './Play';
export { default as Play2 } from './Play2';
export { default as Pin } from './Pin';
export { default as PinFilled } from './PinFilled';
export { default as Message } from './Message';
export { default as Welcome } from './Welcome';
export { default as Save } from './Save';
export { default as Save2 } from './Save2';
export { default as Policy } from './Policy';
export { default as OtherPolicy } from './OtherPolicy';
export { default as WelcomeMessage } from './WelcomeMessage';
export { default as Clear } from './Clear';
export { default as Help } from './Help';
export { default as Edit } from './Edit';
export { default as EditIcon } from './EditIcon';
export { default as Loading } from './Loading';
export { default as Success } from './Success';
export { default as SuccessCircle } from './SuccessCircle';
export { default as SuccessXl } from './SuccessXl';
export { default as Warning } from './Warning';
export { default as WarningXl } from './WarningXl';
export { default as Arrow } from './Arrow';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowLeft } from './ArrowLeft';
export { default as ArrowExternal } from './ArrowExternal';
export { default as LeftArrow } from './LeftArrow';
export { default as RightArrow } from './RightArrow';
export { default as Arrows } from './Arrows';
export { default as Clock } from './Clock';
export { default as EyeClose } from './EyeClose';
export { default as Complete } from './Complete';
export { default as NotComplete } from './NotComplete';
export { default as Alphabetical } from './Alphabetical';
export { default as Sort } from './Sort';
export { default as Bar } from './Bar';
export { default as Renew } from './Renew';
export { default as Reset } from './Reset';
export { default as Update } from './Update';
export { default as Settings } from './Settings';
export { default as Image } from './Image';
export { default as Video } from './Video';
export { default as File } from './File';
export { default as SVGbaseline } from './SVGbaseline';
export { default as SVGpreemployment } from './SVGpreemployment';
export { default as Like } from './Like';
export { default as AlertProducts } from './AlertProducta';
export { default as ContentAdmin } from './ContentAdmin';
export { default as ContentAdminEmailTemplate } from './ContentAdminEmailTemplate';
export { default as ContentAdminLandingPage } from './ContentAdminLandingPage';
export { default as ContentAdminSendingProfile } from './ContentAdminSendingProfile';
export { default as ContentAdminScenario } from './ContentAdminScenario';
export { default as SendEmail } from './SendEmail';
export { default as Flag } from './Flag';
export { default as WhiteMode } from './WhiteMode';
export { default as ReferralCodeIcon } from './ReferralCodeIcon';
export { default as Share } from './Share';
export { default as Hashtag } from './Hashtag';
export { default as Exel } from './Exel';
export { default as Badge } from './Badge';
export { default as Newsletter } from './Newsletter';
export { default as Microtraining } from './Microtraining';
export { default as Coin } from './Coin';
export { default as DocSearch } from './DocSearch';
export { default as PartnerBSN } from './PartnerBSN';
export { default as ShoppingCart } from './ShoppingCart';
export { default as PlaySquare } from './PlaySquare';
export { default as PlaySquareOutline } from './PlaySquareOutline';
export { default as PasswordSecurity } from './PasswordSecurity';
export { default as Cybersecurity } from './Cybersecurity';
export { default as Word } from './Word';
export { default as Excel } from './Excel';
export { default as Ppt } from './Ppt';
export { default as Pdf } from './Pdf';
export { default as Doc } from './Doc';
export { default as Csv } from './Csv';
export { default as Newsletters } from './Newsletters';
export { default as Policies } from './Policies';
export { default as UserChecked } from './UserChecked';
export { default as Block } from './Block';
export { default as CatchPhish } from './CatchPhish';
export { default as Progress } from './Progress';
export { default as Check } from './Check';
export { default as Map } from './Map';
export { default as Cube } from './Cube';
export { default as EmailSend } from './EmailSend';
export { default as EmailClicked } from './EmailClicked';
export { default as EmailOpened } from './EmailOpened';
export { default as EmptyList } from './EmptyList';
export { default as Export2 } from './Export2';
export { default as Database } from './Database';
export { default as Scenarios } from './Scenarios';
export { default as CaughtPhishing } from './CaughtPhishing';
export { default as Script } from './Script';
export { IconContainer } from './IconContainer';
export { default as QuestionMark } from './QuestionMark';
export { default as MessageQuestion } from './MessageQuestion';
export { default as Link } from './Link';
export { default as Unlink } from './Unlink';
export { default as ImportEmail } from './ImportEmail';
export { default as ShieldChecked } from './ShieldChecked';
export { default as FolderChecked } from './FolderChecked';
export { default as Campaign } from './Campaign';
export { default as Reload } from './Reload';
export { default as Pencil } from './Pencil';
export { default as CloudUpload } from './CloudUpload';
export { default as ThumbsUp } from './ThumbsUp';
export { default as Alert3 } from './Alert3';
export { default as MultiMedia } from './MultiMedia';
export { default as Financies } from './Financies';
export { default as Communications } from './Communications';
export { default as Upload } from './Upload';
export { default as FileCheck } from './FileCheck';
export { default as CircleCheck } from './CircleCheck';
export { default as DocFile } from './DocFile';
export { default as PdfFile } from './PdfFile';
export { default as Uploadlg } from './Uploadlg';
export { default as Trash } from './Trash';
export { default as CircleChecked } from './CircleChecked';
export { default as Remediation } from './Remediation';
export { default as ShieldCorrect } from './ShieldCorrect';
export { default as Happy } from './Happy';
export { default as Redo } from './Redo';
export { default as AlertLampOn } from './AlertLampOn';
export { default as CalendarTime } from './CalendarTime';
export { default as Archive } from './Archive';
export { default as Undo } from './Undo';
export { default as Union } from './Union';
export { default as Disconnect } from './Disconnect';
export { default as NewDownload } from './NewDownload';
export { default as HappyFace } from './HappyFace';
export { default as Siren } from './Siren';
export { default as NewWelcomeMessage } from './NewWelcomeMessage';
export { default as Tag } from './Tag';
export { default as UserNoOutline } from './UserNoOutline';
export { default as CloudUploadIcon } from './CloudUploadIcon';
export { default as BellOn } from './BellOn';

export const LazyIcon = ({ component = '', color = 'primary', ...rest }) => {
  if (!component) {
    return <></>;
  }

  const LoadableIcon = Loadable<LoadableProps>({
    loader: () => import(`./${component}`),
    loading: ({ error }) => {
      if (error) {
        console.group(`%cERROR: LAZY ICON`, 'font-size: medium; color: red');
        console.log(error);
        console.groupEnd();
        return <Error />;
      }
      return <Loading />;
    }
  });

  return <LoadableIcon {...rest} color={color} />;
};
