import React from 'react';

const CircleChecked = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 18.6185 5.38154 24 12 24C18.6185 24 24 18.6185 24 12C24 5.38154 18.6185 0 12 0C5.38154 0 0 5.38154 0 12ZM1.84615 12C1.84615 6.39692 6.39692 1.84615 12 1.84615C17.5938 1.84615 22.1538 6.39692 22.1538 12C22.1538 17.6031 17.6031 22.1538 12 22.1538C6.39692 22.1538 1.84615 17.6031 1.84615 12ZM16.4769 9.04612C16.8369 8.68612 17.4184 8.68612 17.7784 9.04612C18.1384 9.40612 18.1384 9.98766 17.7969 10.3569L10.403 17.7507C10.2184 17.9261 9.98761 18.0184 9.74761 18.0184C9.61838 18.0184 9.49842 17.9815 9.37842 17.9354C9.26765 17.8892 9.15685 17.8246 9.06455 17.7323L6.23069 14.8984C5.87069 14.5384 5.87069 13.9569 6.23069 13.5969C6.59069 13.2369 7.17226 13.2369 7.53226 13.5969L9.72919 15.7938L16.4769 9.04612Z"
        fill="#0CCE6B"
      />
    </svg>
  );
};

export default CircleChecked;
