import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Typography, VideoModal } from 'components';
import { Notes, PlaySquareOutline, AlertLampOn } from 'components/icons';
import { CardStyled, CardTitle, CardBody, CardImg, CardContent, CardFooter, CardActions } from './style';
import { ButtonOutline, ButtonBaseFilled, ChipStyled } from '../../style';

const WorkPlan = ({ task }) => {
  const history = useHistory();
  const location = useLocation();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const {
    percent_complete,
    info: { description, name, video_url, outstanding_recommendations }
  } = task || { info: {} };
  const reportIsPending = percent_complete === 'Pending';

  const goToWorkPlanPage = () => {
    if (!reportIsPending) {
      sessionStorage.setItem('accessWorkplanSRA', 'true');
      history.push(`${location.pathname}/workPlan`);
    }
  };

  return (
    <CardStyled>
      <CardTitle>{name}</CardTitle>

      <CardBody>
        <CardImg>
          <img src="/media/sra/work_plan.svg" alt="task" />
        </CardImg>

        <CardContent>
          <Typography.p fontSize={14} mt="0px" mb="0px">
            {description}
          </Typography.p>

          {outstanding_recommendations !== null && (
            <Box alignSelf="flex-start" mt={4}>
              <ChipStyled
                icon={
                  <Box pl={1} pr={0.5} display="flex" alignItems="center">
                    <AlertLampOn size={1.1} color="info" />
                  </Box>
                }
                label={`Outstanding recommendation${
                  outstanding_recommendations === 1 ? '' : 's'
                }: ${outstanding_recommendations}`}
                variant="outlined"
                color="var(--colorSystemInfo)"
              />
            </Box>
          )}

          <CardFooter>
            <CardActions>
              <ButtonBaseFilled
                variant="outlined"
                startIcon={<PlaySquareOutline mr={1.2} size={1.3} />}
                disableElevation
                fullWidth
                onClick={() => setOpenVideoModal(true)}
              >
                Watch Video
              </ButtonBaseFilled>

              <ButtonOutline
                variant="outlined"
                startIcon={<Notes mr={1.2} size={1.3} />}
                disableElevation
                fullWidth
                onClick={goToWorkPlanPage}
                disabled={reportIsPending}
              >
                Work Plan
              </ButtonOutline>
            </CardActions>
          </CardFooter>
        </CardContent>
      </CardBody>

      <VideoModal
        open={openVideoModal}
        close={() => setOpenVideoModal(false)}
        title={name}
        video={video_url}
        // loading={loading}
      />
    </CardStyled>
  );
};

WorkPlan.propTypes = {
  task: PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, null]),
    name: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    percent_complete: PropTypes.string.isRequired
  }).isRequired
};

WorkPlan.defaultProps = {};

export default WorkPlan;
