import React, { cloneElement } from 'react';
import styled from "styled-components"
import {Breadcrumbs as MUIBreadcrumbs} from "@material-ui/core"
import { Link, useHistory } from "react-router-dom"
import Typography from './Typography';
import { Arrow } from '../icons';
import Container from './Containers';

const BreadcrubContainer = styled(Container.Grid)``

const BreadcrubLink = styled(Link)`
  font-size: 2.5rem;
  text-decoration: none;
  font-weight: 700;
  color: var(--colorLightGray);
`

const BreadcrubSingleText = styled(Typography.h3)`
  font-size: 2.5rem;
  color: var(--colorBase);
  margin: 0;
`

const BreadcrubText = styled(Typography.p)`
  font-size: 2.5rem;
  text-decoration: none;
  font-weight: 700;
  color: ${({isLast}) => isLast ? `var(--colorDefault)` : `var(--colorLightGray)`};
  margin: 0;
`

const MUIBreadcrumbsStyled = styled(MUIBreadcrumbs)`
  && {
    margin: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 3);
    font-size: 2.5rem;
  }
`

const ArrowLeft = styled(Arrow)`
  && {
    transform: rotate(-90deg);
    color: var(--colorLight);
    margin-right: var(--spacing);
  }
`

export const BreadcrumbsItem = ({label, path, isLast, oneItem}) => {

  if (oneItem) {
    return (
      <BreadcrubSingleText>
        {label}
      </BreadcrubSingleText>
    )
  }

  if (!path || isLast) {
    return (
      <BreadcrubText isLast={isLast}>
        {label}
      </BreadcrubText>
    )
  }

  return (
    <BreadcrubLink to={path}>
      {label}
    </BreadcrubLink>
  )
}

const Breadcrumbs = ({children}) => {

  const childrenCount = React.Children.count(children)

  return (
    <BreadcrubContainer alignItems={"center"}>
      <ArrowLeft/>
      <MUIBreadcrumbsStyled>
        {

          React.Children.map(children, (child, index) => {
            return cloneElement(child, {
              isLast: childrenCount - 1 === index && childrenCount > 1,
              oneItem: childrenCount === 1,
            })
          })

        }
      </MUIBreadcrumbsStyled>
    </BreadcrubContainer>
  )
}

export default Breadcrumbs
