// @flow

import modals from './modals';
import tables from './tables';
import system from './system';
import tabs from './tabs';

const state: Object = {};

state.modals = modals;
state.tables = tables;
state.system = system;
state.tabs = tabs;

Object.freeze(state);

export default state;
