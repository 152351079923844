// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Copy = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 14 14" role="img" aria-hidden="false">
    <path d="M11.1391 2.69045H9.24876V1.61105C9.24876 0.724971 8.52916 0 7.63771 0H2.77772C1.89164 0 1.16667 0.724971 1.16667 1.61105V9.6985C1.16667 10.5899 1.89164 11.3096 2.77772 11.3096H4.66801V12.389C4.66801 13.275 5.39299 14 6.27906 14H11.1391C12.0305 14 12.7501 13.275 12.7501 12.389V4.3015C12.7501 3.41005 12.0305 2.69045 11.1391 2.69045ZM2.77772 10.2355C2.48236 10.2355 2.2407 9.99923 2.2407 9.6985V1.61105C2.2407 1.31569 2.48236 1.07403 2.77772 1.07403H7.63771C7.93307 1.07403 8.17473 1.31569 8.17473 1.61105V2.69045H6.27906C6.09648 2.69045 5.91926 2.72267 5.75816 2.77637C5.12448 2.99655 4.66801 3.59264 4.66801 4.3015V10.2355H2.77772ZM11.6761 12.389C11.6761 12.6843 11.4344 12.926 11.1391 12.926H6.27906C5.9837 12.926 5.74205 12.6843 5.74205 12.389V11.3096V10.2355V4.3015C5.74205 4.25853 5.74742 4.21557 5.75816 4.17798C5.81186 3.9417 6.02666 3.76448 6.27906 3.76448H8.17473H8.36805H11.1391C11.4344 3.76448 11.6761 4.00614 11.6761 4.3015V12.389Z" />
  </SvgIconStyled>
);

export default Copy;
