import React from 'react';
import { ErrorBoundary } from '../error';
import TableContent from './TableContent';
import styled from 'styled-components';

const TableContentStyled = styled(TableContent)`
  box-shadow: none !important;
  .table-toolbar {
    border: none;
    border-radius: var(--borderRadius);
    border-bottom: 1px solid var(--borderTableDefault);
    padding: 18px 25px;
  }
  .table-head {
    tr {
      th {
        border-top: 1px solid var(--borderTableDefault);
        border-bottom: 1px solid var(--borderTableDefault);

        &:first-of-type,
        &:last-of-type {
          border-left: none;
          border-right: none;
        }
      }
    }
  }
`

const CustomTable = (props) => {
  return (
    <ErrorBoundary>
      <TableContentStyled {...props} />
    </ErrorBoundary>
  );
};

export default CustomTable;
