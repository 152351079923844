// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, DialogContent, Box, RadioGroup } from '@material-ui/core';
import { Monitor, Email2 } from 'components/icons';
import { RadioWithIcon } from '../../common';
import { ModalTitle } from './index';
import { getNotifyAudience } from '../../../newsfeedHelpers';
import { AUDIENCES } from 'apps/newsfeed/constants';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const SelectNotifyType = ({ setView, notifyAudience, setNotifyAudience, isContentAdmin, audienceData }) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(getNotifyAudience(notifyAudience).value);
  const disableEmailNotifications = isContentAdmin
    ? [AUDIENCES.general, AUDIENCES.cybersecurity, AUDIENCES.healthcare, AUDIENCES.microsoft, AUDIENCES.artificial_intelligence].includes(
        audienceData?.audience?.name
      )
    : audienceData?.audience?.name === AUDIENCES.partner;

  const onChangeOption = e => {
    const { value } = e.target;
    setSelectedOption(value);
    setNotifyAudience({
      push_notifications: value === 'push_notifications' || value === 'email_notifications',
      email_notifications: value === 'email_notifications'
    });

    setView('main');
  };

  const onClickOption = e => {
    if (e.target.value === selectedOption) {
      setSelectedOption('');
      setNotifyAudience({
        push_notifications: false,
        email_notifications: false
      });
    }
  };

  const handleViewChange = useCallback(view => setView(view), []);

  return (
    <>
      <ModalTitle title="Notify Audience" onClick={() => handleViewChange('main')} />

      <DialogContent className={classes.dialogContent}>
        <RadioGroup name="notify-audience" value={selectedOption} onChange={onChangeOption}>
          <RadioWithIcon
            value="push_notifications"
            label="Portal Notification"
            icon={<Monitor />}
            selectedVal={selectedOption}
            onClick={onClickOption}
          />
          <RadioWithIcon
            value="email_notifications"
            label="Portal and Email Notification"
            icon={<Email2 />}
            selectedVal={selectedOption}
            disabled={disableEmailNotifications}
            onClick={onClickOption}
          />
        </RadioGroup>
      </DialogContent>
    </>
  );
};

export default SelectNotifyType;
