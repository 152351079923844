import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { Container, Typography } from '../types';

const DashboardCardContainer = styled(Container.Grid)`
  margin-bottom: 30px;
`;
const DashboardCardWrapper = styled(Card)`
  width: calc(100% - 30px);
  padding: 40px calc(var(--spacing) * 2) 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;
const DashboardCardIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorGreyLight3);
  svg {
    width: 30px;
    height: 30px;
    color: var(--colorSystemInfo);
    fill: var(--colorSystemInfo);
  }
`;
const DashboardCardTitle = styled(Typography.h3)`
  font-size: 24px;
  line-height: 29px;
  color: var(--colorDefault);
`;

const DashboardCardDescription = styled(Typography.p)`
  font-size: 14px;
  line-height: 25px;
  color: var(--colorDefault);
  max-width: 240px;
  text-align: center;
  margin: 0;
`;

const DashboardCard = ({ icon, title, description, path, handleClick }) => {
  const history = useHistory();

  const defaultHandleClick = () => {
    path && history.push(path);
  };

  return (
    <DashboardCardContainer md={4} sm={6} xs={12}>
      <DashboardCardWrapper onClick={handleClick || defaultHandleClick}>
        <DashboardCardIcon>{icon}</DashboardCardIcon>

        <DashboardCardTitle>{title}</DashboardCardTitle>

        {description && <DashboardCardDescription>{description}</DashboardCardDescription>}
      </DashboardCardWrapper>
    </DashboardCardContainer>
  );
};

DashboardCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string,
  handleClick: PropTypes.func
};

DashboardCard.defaultProps = {
  description: undefined,
  path: undefined,
  handleClick: undefined
};

export default DashboardCard;
