import React from 'react';

const Siren = props => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9365 2.34621C12.9365 2.7357 12.5192 3.051 12.0091 3.051C11.4991 3.051 11.0818 2.7357 11.0818 2.34621V0.704793C11.0818 0.315303 11.4991 0 12.0091 0C12.5192 0 12.9365 0.315303 12.9365 0.704793V2.34621ZM21.3848 15.0974V15.0696L18.7697 7.16847C18.4451 6.19474 17.4065 5.54559 16.1731 5.54559H7.78054C6.54715 5.54559 5.49922 6.19474 5.18392 7.16847L2.5688 15.0696V15.1066C1.14067 15.2179 0 16.4049 0 17.8702V20.8563C0 22.3864 1.25193 23.6383 2.78207 23.6383H21.2179C22.7481 23.6383 24 22.3864 24 20.8563V17.8702C23.9907 16.3864 22.8315 15.1901 21.3848 15.0974ZM6.93664 7.75271C6.97373 7.62288 7.2983 7.40031 7.77126 7.40031H16.1639C16.6461 7.40031 16.9614 7.62288 16.9985 7.75271L19.4189 15.0788H4.49769L6.93664 7.75271ZM22.136 20.847C22.136 21.357 21.7187 21.7743 21.2086 21.7743H2.77282C2.26277 21.7743 1.84546 21.357 1.84546 20.847V17.8609C1.84546 17.3509 2.26277 16.9335 2.77282 16.9335H21.2086C21.7187 16.9335 22.136 17.3509 22.136 17.8609V20.847ZM14.7079 12.7882C14.8099 13.2148 15.1901 13.5023 15.6074 13.5023C15.6723 13.5023 15.7465 13.493 15.8207 13.4745C16.3215 13.3632 16.6275 12.8624 16.5162 12.3617L15.932 9.8207C15.8207 9.31993 15.3199 9.00463 14.8192 9.12519C14.3184 9.23647 14.0124 9.73725 14.1236 10.238L14.7079 12.7882ZM17.2951 3.19938L18.4543 2.04019C18.7325 1.76198 18.6583 1.25193 18.2967 0.890265C17.935 0.528596 17.4157 0.454409 17.1468 0.732616L15.9876 1.89181C15.7094 2.17002 15.7835 2.68006 16.1452 3.04173C16.5069 3.4034 17.0262 3.47759 17.2951 3.19938ZM7.85477 3.04173C7.4931 3.4034 6.98306 3.47759 6.70486 3.19938L5.54566 2.04019C5.26745 1.77125 5.34165 1.25193 5.70332 0.890265C6.06499 0.528596 6.57503 0.454409 6.85324 0.732616L8.01243 1.89181C8.29064 2.16075 8.21644 2.68006 7.85477 3.04173Z"
        fill={props.color ?? '#FF5A61'}
      />
    </svg>
  );
};

export default Siren;
