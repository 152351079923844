// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WelcomeMessage = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 31.4 14.1" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h31.4v14.1H0z" />
    <g transform="translate(9.5 -6.2)">
      <path d="M20.2,6.2H2.2A1.685,1.685,0,0,0,.5,7.9V18.6a1.752,1.752,0,0,0,1.7,1.7h18a1.685,1.685,0,0,0,1.7-1.7V7.8A1.605,1.605,0,0,0,20.2,6.2ZM15,13.4l5.6-5V18Zm-7.5,0L1.9,18V8.4Zm4,1.2a.637.637,0,0,1-.6,0L3,7.5H19.5Zm-3-.3L10,15.6a1.825,1.825,0,0,0,2.4,0l1.5-1.3,5.5,4.5H3.1Z" />
      <rect width="10" height="1.8" rx="0.65" transform="translate(-10 14)" />
      <rect width="8" height="1.8" rx="0.65" transform="translate(-8 11)" />
    </g>
  </SvgIconStyled>
);

export default WelcomeMessage;
