import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { useLocation } from 'react-router-dom';
import bsnPartnersApi from 'helpers/apis/BSNPartnersAPI/bsnpartnersapi';
import { LoadingStyled } from 'components';
import { useNotify } from 'ra-core';

const QuickSightDashboard = () => {
  const location = useLocation();
  const dashboardRef = useRef([]);
  const notify = useNotify();
  const dashboardId = new URLSearchParams(location.search).get('dashboard_id');
  const [loading, setLoading] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  };

  const embed = async () => {
    const options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: '1920px'
    };

    await embeddingContext.embedDashboard(options);
  };

  useEffect(() => {
    if (dashboardUrl) {
      createContext();
      setLoading(false);
    } else {
      setLoading(true);
      bsnPartnersApi
        .getQuicksightDashboard(dashboardId)
        .then(res => {
          setDashboardUrl(res?.data?.url);
        })
        .catch(() => {
          notify('An Error Occurred', 'error');
        });
    }
  }, [dashboardUrl]);

  useEffect(() => {
    if (embeddingContext && dashboardUrl) {
      embed();
    }
  }, [dashboardUrl, embeddingContext]);

  if (loading) return <LoadingStyled />;

  return <div ref={dashboardRef} style={{ marginTop: '25px' }} />;
};

export default QuickSightDashboard;
