// @flow
const debug: Object =
  process.env.NODE_ENV === 'development'
    ? console
    : {
        log: () => null,
        table: () => null
      };

export default debug;
