import React, { useEffect, useState } from 'react';
import { Button, Container, FilterActive as SelectPolicyType, LazyIcon } from 'components';

const PoliciesToolbar = ({ navigateToCreate, setParam }) => {
  const [policyTypeValue, setPolicyTypeValue] = useState('0'); // if 1 is hipaa policies

  useEffect(() => {
    setParam('hsn', policyTypeValue);
  }, []);

  const onChangeParam = ({ target: { name, value } }) => {
    setParam(name, value);
    setPolicyTypeValue(value);
  };

  return (
    <Container.Grid alignItems="flex-start" justifyContent="space-between" >
      <Container.Grid item md={4} lg={3} xl={2}>
        <SelectPolicyType
          name="hsn"
          value={policyTypeValue}
          state={{
            label: 'Policy Type',
            choices:  [
              { value: '0', label: 'Security Policies' },
              { value: '1', label: 'HIPAA Policies' },
            ]
          }}
          onChange={onChangeParam}
          isFixed
          noLabel
        />
      </Container.Grid>

      <Container.Grid item md={4} lg={2} xl={2} justify="flex-end" pt={1}>
        <Button
          backgroundColor="colorBaseBase"
          backgroundColorHover="colorBaseBase"
          disabled
          onClick={navigateToCreate}
        >
          <LazyIcon component="Plus" color="grey" size={1} mr={1} />
          New Policy
        </Button>
      </Container.Grid>
    </Container.Grid>
  );
};

export default PoliciesToolbar;
