// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getStorage, decodeId, updateFeed } from 'helpers';
import { useLocation } from 'hooks';
import { IconButton, Box, Tooltip, AlertBellOnIcon, AlertBellMuteIcon } from '@trustsecurenow/components-library';
import { CustomTooltip } from 'components';
import { Pin } from 'components/icons';
import { mutePostNotifications, unMutePostNotifications } from 'helpers/apis/services/newsFeed';
import { useDispatch } from 'react-redux';
import { ClientsModal } from '../modals';
import UserAvatar from '../common/UserAvatar';
import PostHeaderActions from './PostHeaderActions';
import { getAudienceAndRolePreview } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '100%'
  },
  padded: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  },
  btn: {
    padding: 0,
    border: 0,
    background: 'none',
    outline: 'none !important',
    cursor: 'pointer',
    color: 'var(--colorDark)'
  }
}));

const PostHeader = ({ feed, resetFeedList }) => {
  const classes = useStyles();
  const { item } = useLocation();
  const dispatch = useDispatch();
  const [openClientsModal, setOpenClientsModal] = useState(false);
  const user = { id: decodeId(getStorage('userId')) };
  const isOwnPost = feed.user.id === Number(user.id);

  const NotificationIcon = feed.notification_activity_bell ? AlertBellOnIcon : AlertBellMuteIcon;

  const onOpenClientsModal = () => {
    setOpenClientsModal(true);
  };

  const onCloseClientsModal = () => {
    setOpenClientsModal(false);
  };

  const handleToggleMuteNotifications = () => {
    feed.notification_activity_bell ? mutePostNotifications(feed.id) : unMutePostNotifications(feed.id);
    dispatch(
      updateFeed(feed.id, {
        notification_activity_bell: feed.notification_activity_bell ? 0 : 1
      })
    );
  };

  const getIcon = () => {
    const data = {
      allowed_groups: feed.allowed_groups,
      included_clients: feed.included_clients,
      audience: feed.audience
    };
    const obj = getAudienceAndRolePreview(data);

    return (
      <CustomTooltip title={obj.text} placement="top">
        {data.included_clients?.length > 0 ? (
          <button type="button" className={classes.btn} onClick={onOpenClientsModal}>
            {obj.icon}
          </button>
        ) : (
          obj.icon
        )}
      </CustomTooltip>
    );
  };

  return (
    <>
      <Box className={classes.padded} display="flex" alignItems="center">
        <UserAvatar 
        user={feed.user} 
        timestamp={feed.timestamp} 
        icon={isOwnPost && getIcon()}
        allowedGroups={feed?.allowed_groups} 
        clickable={!item}
        />
        {feed.pinned && !item && (
          <Box px={{ xs: 0.5, md: 2 }}>
            <Pin size={1.7} />
          </Box>
        )}
        {feed.notification_activity_bell !== null && feed.notification_activity_bell !== undefined && (
          <Box mr={1}>
            <Tooltip
              title={`Notifications are turned ${feed.notification_activity_bell ? 'on' : 'off'} for this post`}
              placement="top"
            >
              <Box ml={1}>
                <IconButton onClick={handleToggleMuteNotifications}>
                  <NotificationIcon sx={{ fontSize: 24 }} />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        )}

        <Box mr={-1.5}>
          <PostHeaderActions feed={feed} isOwnPost={isOwnPost} resetFeedList={resetFeedList} />
        </Box>
      </Box>
      {openClientsModal && (
        <ClientsModal close={onCloseClientsModal} open={openClientsModal} data={feed.included_clients} />
      )}
    </>
  );
};

export default React.memo(PostHeader);
