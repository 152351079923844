// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import Linkify from 'linkify-react';
import parse from 'html-react-parser';
import { convertStyleAttr, unicodeToEmoji, cleanTextFromHtml } from 'helpers';
import ReactDOMServer from 'react-dom/server';
import { setFeedSearch, setFeedSearchBy } from 'helpers/action';

const useStyles = makeStyles({
  content: {
    fontFamily: 'var(--fontFamily)',
    fontSize: '1.6rem',
    lineHeight: '1.5',
    color: 'inherit',
    '& > span > p:last-child': {
      display: 'inline'
    },
    '& p': {
      margin: 0
    },
    '& .link': {
      color: 'var(--colorSystemInfo)',
      wordBreak: 'break-word',
      textDecoration: 'none'
    }
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: 5,
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

// Character limit to cut post text when the text length exceeds the limit (show "...See More" when character count is more than 250)
const SEE_MORE_LIMIT = 250;

const LinkifiedText = ({ text, allowTruncate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cleanText, setCleanText] = useState(cleanTextFromHtml(text));
  const [isExpanded, setIsExpanded] = useState(cleanText.length <= SEE_MORE_LIMIT);
  const hasSeeMore = cleanText.length > SEE_MORE_LIMIT;
  const [newLimit, setNewLimit] = useState(SEE_MORE_LIMIT);

  const linkifyOptions = {
    defaultProtocol: 'https',
    className: 'link',
    target: '_blank'
  };

  const parserOptions = {
    replace: domNode => {
      if (domNode.attribs) {
        if (domNode.attribs['data-sn-user-id']) {
          let txt = domNode.children?.[0]?.next?.children?.[1]?.data || domNode.attribs['data-value'];
          return (
            <a
              href={window.location.href}
              className='link'
              onClick={(e) => onMentionClick(e, domNode.attribs['data-sn-user-id'])}
            >
              {txt}&nbsp;
            </a>
          );
        } else if (domNode.attribs['data-value'] && domNode.attribs['data-value'].charAt(0) === '#') {
          let txt = domNode.children?.[0]?.next?.children?.[1]?.data || domNode.attribs['data-value'];
          return (
            <a
              href={window.location.href}
              className='link'
              onClick={(e) => onHashtagClick(e, domNode.attribs['data-value'])}
            >
              {txt}&nbsp;
            </a>
          );
        }
      }
    }
  };

  useEffect(() => {
    setCleanText(cleanTextFromHtml(text));
  }, [text]);

  useEffect(() => {
    setIsExpanded(cleanText.length <= SEE_MORE_LIMIT);
  }, [cleanText]);

  // To be used in the future when there is an action for mention click
  const onMentionClick = (e, userId) => {
    e.preventDefault();
  };

  const onHashtagClick = (e, value) => {
    e.preventDefault();
    const hashtag = value.substring(1);
    window.location.href = '/#/newsfeed/dashboard';
    dispatch(setFeedSearch({ tag_name: hashtag }));
    dispatch(setFeedSearchBy('hashtag'));
  };

  const getText = (str) => {
    if (allowTruncate && hasSeeMore) {
      return isExpanded ? str : str.substr(0, newLimit);
    } else {
      return str;
    }
  };
  
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  
  let linkifiedText = (
    <Linkify tagName='span' options={linkifyOptions}>
      {parse(unicodeToEmoji(convertStyleAttr(text, true)))}
    </Linkify>
  );
  let linkifiedTextStr = ReactDOMServer.renderToStaticMarkup(linkifiedText);

  useEffect(() => {
    if (hasSeeMore) {
      let newHtml = '';
      let newLimitIndex = 0;
      for (let index in linkifiedTextStr) {
        if (cleanTextFromHtml(newHtml).length <= SEE_MORE_LIMIT ) {
          newHtml += linkifiedTextStr[index];
          newLimitIndex = index;
        }
      } 
      setNewLimit(newLimitIndex);
    }
  }, [hasSeeMore, linkifiedTextStr]);

  return (
    <>
      <Box className={classes.content} component="span">
        {parse(getText(linkifiedTextStr), parserOptions)}
      </Box>

      {allowTruncate && hasSeeMore && (
        <span>
          {!isExpanded && '...'}
          <span onClick={toggleText} className={classes.link}>
            {isExpanded ? 'See Less' : 'See More'}
          </span>
        </span>
      )}
    </>
  );
};

export default LinkifiedText;