import React from "react"
import { CardPageLayoutGrid } from '../../style';
import { CardTitleAndText } from '../shared';

const ScenarioPagesCard = (props) => {

  const {name, difficulty_level, country_code} = props;

  return (
    <CardPageLayoutGrid>

      <CardPageLayoutGrid xs={12}>
        <CardTitleAndText
          title={"Scenario Name"}
          text={name}
        />
      </CardPageLayoutGrid>

      <CardPageLayoutGrid xs={12}>
        <CardTitleAndText
          title={"Difficulty"}
          text={difficulty_level}
        />
      </CardPageLayoutGrid>

      <CardPageLayoutGrid xs={12}>
        <CardTitleAndText
          title={"Country"}
          text={country_code}
        />
      </CardPageLayoutGrid>

    </CardPageLayoutGrid>
  )
}


export default ScenarioPagesCard
