// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Calendar = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18.285 19.012" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18.285v19.012H0z" />
    <g id="icon" transform="translate(-357 -198)">
      <path
        id="Path_191"
        data-name="Path 191"
        d="M21.792,6.224H20.129V5.081a.831.831,0,1,0-1.662,0V6.224H11.818V5.081a.831.831,0,1,0-1.662,0V6.224H8.493A2.494,2.494,0,0,0,6,8.717V20.769a2.494,2.494,0,0,0,2.493,2.493h13.3a2.494,2.494,0,0,0,2.493-2.493V8.717A2.494,2.494,0,0,0,21.792,6.224Zm.831,14.545a.831.831,0,0,1-.831.831H8.493a.831.831,0,0,1-.831-.831V13.912H22.623Zm0-8.519H7.662V8.717a.831.831,0,0,1,.831-.831h1.662v.935a.831.831,0,1,0,1.662,0V7.886h6.649v.935a.831.831,0,1,0,1.662,0V7.886h1.662a.831.831,0,0,1,.831.831Z"
        transform="translate(351 193.75)"
      />
    </g>
  </SvgIconStyled>
);

export default Calendar;
