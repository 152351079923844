import React from 'react';
import { Container, CustomTable, TableCellField } from 'components';
import { TableCellText } from '../../../apps/admin/style/AdminCommon';


const AdminCapabilitiesNotes = ({ isLoading, data, total, dispatch }) => (
  <Container.Grid container md={12} spacing={2} pb={2} pl={2}>
    <CustomTable
      source={'clients/clientNotes'}
      total={total}
      data={data}
      loading={isLoading}
      onLoad={(params) => dispatch.onLoad(params)}
      showPagination={false}
    >
      <TableCellField
        renderComponent={({ last_name, first_name }) => (
          <TableCellText>{last_name}, {first_name}</TableCellText>
        )}
      />
      <TableCellField
        renderComponent={({ notes }) => (
          <TableCellText>{notes}</TableCellText>
        )}
      />
      <TableCellField
        renderComponent={({ created }) => (
          <TableCellText>{created}</TableCellText>
        )}
      />
    </CustomTable>
  </Container.Grid>
);

export default AdminCapabilitiesNotes;