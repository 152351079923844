// @flow
import React, { useState } from 'react';
import { usePartnerAddEdit } from '../../hooks';
import { IconButton, Tooltip, InputAdornment } from '@material-ui/core';
import {
  Arrow,
  ArrowDown,
  Button,
  DateField,
  DragDropLogo,
  Container,
  LazyIcon,
  LoadingStyled,
  PageError,
  PagePushNotifications,
  SelectField,
  Switch,
  TextField,
  Typography
} from 'components';
import { ArrowsContainer } from '../../style/AdminCommon';
import { AccordionFooter, ConfirmModal } from '../../components';
import { decodeId } from 'helpers';
import PartnersTaxExempt from './PartnersTaxExempt';
import PartnersBillingReport from './PartnersBillingReport';
import PartnersNotes from './PartnersNotes';
import PartnerInformation from '../../../partner/PartnerInformation';
import PartnerIntegrations from '../../../partner/PartnerIntegrations';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography as TypographyCL,
  TextField as TextFieldCL
} from '@trustsecurenow/components-library';
import SendingTimesAccordion from 'components/pages/pushNotifications/SendingTimesAccordion';

const PartnersAddEdit = () => {
  const {
    coupon,
    createMode,
    darkWebLicense,
    dialogConfig,
    disabledSave,
    disabledAccountInfo,
    disabledTMSconfig,
    disabledEmailsSave,
    dispatch,
    error,
    fieldsError,
    loading,
    loadingDWMLicense,
    loadingCoupon,
    loadingLogo,
    logo,
    service_logo,
    partnerId,
    record,
    tmsOptions,
    tmsConfiguration,
    notesData,
    partnerInfo
  } = usePartnerAddEdit();
  const {
    applyCoupon,
    addDWMBlocks,
    copyValue,
    deleteLogo,
    onChange,
    onChangeCoupon,
    onChangeDistributor,
    onChangeEmail,
    onChangeEmailAddresses,
    onChangeLink,
    onChangeLogo,
    onChangeServiceLogo,
    onIncrementUsersLimit,
    onDecrementUsersLimit,
    onChangeTmsConfiguration,
    pressignedUploadFile,
    removeCoupon,
    removeDWMBlocks,
    save,
    onCloseDialog
  } = dispatch;

  const [previewPartnerLogo, setPreviewPartnerLogo] = useState(false);
  const [previewServiceLogo, setPreviewServiceLogo] = useState(false);
  const [isLoadingSendingTimes, setIsLoadingSendingTimes] = useState(true);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  const [expandedList, setExpandedList] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedList((prevPanels) =>
      isExpanded ? [...prevPanels, panel] : prevPanels.filter(p => p !== panel)
    );
  };
  const isPanelExpanded = (panel) => expandedList.includes(panel);

  const hasNewSRAToggle = Object.prototype.hasOwnProperty.call(partnerInfo, 'new_sra');
  if (loading) return <LoadingStyled />;

  if (error) {
    return (
      <PageError
        title="Oops!"
        subtitile="There was a problem with the page?"
        linkText="Contact Us"
        linkTo="/user/contactUs"
      />
    )
  }

  return (
    <>
      {/* <Container.Grid container spacing={24}> */}
        <Stack direction="column" spacing={1} fullWidth>
          <Accordion expanded={isPanelExpanded('panel1a')} onChange={handleChange('panel1a')}>
            <AccordionSummary>
              <TypographyCL variant='subtitle1' color={isPanelExpanded('panel1a') && 'info.main'}>Account Information</TypographyCL>
            </AccordionSummary>
            <AccordionDetails>
              <Container.Grid container md={12}>
                <Container.Grid container pb={3} md={12} spacing={2}>
                  <Container.Grid pb={1} pl={2}>
                    <Switch
                      label="Active Status"
                      value={Boolean(record.active)}
                      checked={Boolean(record.active)}
                      name="active"
                      onChange={({ target: { name, checked } }) => onChange(name, checked)}
                    />
                    {!createMode && record.distributor === 'BSN' && (
                      <Switch
                        disabled={!record.allow_enterprise_migration}
                        label="Enterprise BPP"
                        name="enterprise_enabled"
                        value={Boolean(record.enterprise_enabled)}
                        checked={Boolean(record.enterprise_enabled)}
                        onChange={({ target: { name, checked } }) => onChange(name, checked)}
                      />
                    )}
                    {!createMode && hasNewSRAToggle && (
                      <Switch
                        disabled={Boolean(record.new_sra)}
                        label="New SRA"
                        name="new_sra"
                        value={Boolean(record.new_sra)}
                        checked={Boolean(record.new_sra)}
                        onChange={({ target: { name, checked } }) => onChange(name, +checked)}
                      />
                    )}
                  </Container.Grid>
                  <Container.Grid item md={6} lg={3}>
                    <TextField
                      name="name"
                      label="Organization Name"
                      value={record.name}
                      onChange={({ target: { name, value } }) => onChange(name, value)}
                      fullWidth
                      required
                      size="small"
                    />
                  </Container.Grid>
                  <Container.Grid item md={6} lg={3}>
                    <TextField
                      name="email"
                      label="Email"
                      value={record.email}
                      onChange={({ target: { name, value } }) => onChangeEmail(name, value)}
                      fullWidth
                      error={fieldsError.email}
                      helperText={fieldsError.email && 'Please enter a valid email address'}
                      required
                      size="small"
                    />
                  </Container.Grid>

                  <Container.Grid item md={6} lg={3} mt={1}>
                    <SelectField
                      allowEmpty={false}
                      name="distributor"
                      label="Distributor"
                      value={record.distributor}
                      choices={[
                        { value: 'BSN', label: 'BSN' },
                        { value: 'PAX8', label: 'PAX8' },
                        { value: 'MP', label: 'MP' }
                      ]}
                      onChange={({ target: { name, value } }) => onChangeDistributor(name, value)}
                      fullWidth
                      required
                      size="small"
                      disabled={record.enterprise_enabled}
                    />
                  </Container.Grid>

                  <Container.Grid item md={6} lg={3}>
                    <TextField
                      name="link"
                      label="Link - Please include 'http://'"
                      value={record.link}
                      onChange={({ target: { name, value } }) => onChangeLink(name, value)}
                      error={fieldsError.link}
                      helperText={fieldsError.link && 'Please enter a valid link'}
                      fullWidth
                      size="small"
                    />
                  </Container.Grid>
 
                  <Container.Grid item md={6} lg={3} mt={1}>
                    <SelectField
                      name="is_real"
                      label="Account type"
                      choices={[
                        { value: true, label: 'Production' },
                        { value: false, label: 'Test' }
                      ]}
                      value={createMode ? record.is_real : Boolean(record.is_real)}
                      onChange={({ target: { name, value } }) => onChange(name, value)}
                      fullWidth
                      required
                      size="small"
                    />
                  </Container.Grid>


                  {!createMode && (
                    <Container.Grid item md={6} lg={3}>
                      <TextField
                        name="user_count"
                        label="Active Users/Limit"
                        value={`${record.user_count}/${record.users_limit}`}
                        onChange={({ target: { name, value } }) => onChange(name, value)}
                        fullWidth
                        disabled
                        size="small"
                      />
                    </Container.Grid>
                  )}
                  <Container.Grid item md={6} lg={3} >
                    <TextField
                      name="users_limit"
                      label="Registered Limit"
                      value={Number(record.users_limit)}
                      onChange={e => e.preventDefault()}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >
                            <ArrowsContainer>
                              <Arrow
                                onClick={onIncrementUsersLimit}
                                color="colorDefault"
                                size={1}
                                style={{ cursor: 'pointer' }}
                              />
                              <ArrowDown
                                onClick={onDecrementUsersLimit}
                                color="colorDefault"
                                size={1}
                                style={{ cursor: 'pointer' }}
                              />
                            </ArrowsContainer>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Container.Grid>
                  <Container.Grid item md={6} lg={3} mt={1}>
                    <DateField
                      name="created"
                      label="Created Date"
                      value={record.created}
                      fullWidth
                      datePicker
                      disabled
                      size="small"
                    />
                  </Container.Grid>

                  {!createMode && (
                    <Container.Grid item md={6} lg={3}>
                      <TextField
                        name="id"
                        label="Partner Id"
                        value={decodeId(record.id)}
                        disabled
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                copyValue(decodeId(record.id));
                              }}
                            >
                              <LazyIcon component="Copy" color="colorBox" size={1.4} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Container.Grid>
                  )}
                  {Boolean(record.enterprise_enabled) && (
                    <Container.Grid item md={6} lg={3} mt={1}>
                      <SelectField
                        name="bpp_tier_id"
                        label="Tier level"
                        valueKey="id"
                        choices={record.ebp_tier_picklist?.map(tier => ({
                          ...tier,
                          label: `${tier.from} - ${tier.to} Users`
                        }))}
                        value={record.bpp_tier_id}
                        onChange={({ target: { name, value } }) => onChange(name, value)}
                        fullWidth
                        required
                        size="small"
                      />
                    </Container.Grid>
                  )}
                </Container.Grid>

                {!createMode && <AccordionFooter onSubmit={save} disabled={disabledAccountInfo || loading} />}
              </Container.Grid>
            </AccordionDetails>
          </Accordion>

          {!createMode && (
            <>
              <Accordion expanded={isPanelExpanded('panel2a')} onChange={handleChange('panel2a')}>
                <AccordionSummary>
                  <TypographyCL variant='subtitle1' color={isPanelExpanded('panel2a') && 'info.main'}>Dark Web Monitoring</TypographyCL>
                </AccordionSummary>
                <AccordionDetails>
                  <Container.Grid container md={12}>
                    <Container.Grid item md={4} lg={3} pr={2}>
                      <TextField
                        name="dwm_coupon_licenses"
                        label="DWM Coupon code"
                        value={coupon}
                        onChange={({ target: { value } }) => onChangeCoupon(value)}
                        fullWidth
                        size="small"
                      />
                    </Container.Grid>

                    <Container.Grid item md={2} lg={2} pl={2} pt={1}>
                      <Button
                        size="small"
                        backgroundColor="colorBaseBase"
                        backgroundColorHover="colorBaseBase"
                        onClick={applyCoupon}
                        style={{ maxHeight: '56px' }}
                        disabled={loadingCoupon.loading || !coupon}
                      >
                        Apply coupon
                      </Button>
                    </Container.Grid>

                    <Container.Grid container md={12} bb={1} pb={2} alignItems="center">
                      {darkWebLicense.coupons_applied.map((coupon, idx) => (
                        <Container.Grid key={`${coupon}${idx}`} container md={12}>
                          <Container.Grid
                            item
                            md={4}
                            lg={3}
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Typography.successSpan fontSize={14} color="colorSystemSuccess" mt={0.4} mb={0.4} mr={2}>
                              Coupon applied: {coupon}
                            </Typography.successSpan>
                            <Tooltip title="Remove Coupon" placement="top">
                              <IconButton onClick={() => removeCoupon(coupon)} disabled={loadingCoupon.coupon === coupon}>
                                <LazyIcon component="Delete" color="colorDefault" />
                              </IconButton>
                            </Tooltip>
                          </Container.Grid>
                        </Container.Grid>
                      ))}
                    </Container.Grid>

                    {loadingDWMLicense ? (
                      <Container.Grid container alignItems="center" justifyContent="center">
                        <LoadingStyled />
                      </Container.Grid>
                    ) : (
                      <>
                        <Container.Grid item md={4} lg={3} pr={2} alignItems="center">
                          <LazyIcon component="Disable" mr={2} color="colorDefault" size={1.6} />
                          <Container.Grid pb={2.6} pt={2.6} direction="column">
                            <Typography.h6 mr={2} mt={0.4} mb={0.4}>
                              Block Purchased
                            </Typography.h6>
                            <Typography.p fontSize={12} mt={0.4} mb={0.4}>
                              DWM Licenses Allocated
                            </Typography.p>
                          </Container.Grid>
                          <TextField
                            size="small"
                            value={darkWebLicense.block_purchased}
                            integerOnly
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  onClick={removeDWMBlocks}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <LazyIcon component="Minus" color="colorSystemInfo" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end" onClick={addDWMBlocks} style={{ cursor: 'pointer' }}>
                                  <LazyIcon component="Plus" color="colorSystemInfo" size={1} />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Container.Grid>

                        <Container.Grid item md={0} lg={1}></Container.Grid>

                        <Container.Grid item md={4} lg={3} alignItems="center">
                          <LazyIcon component="Notes" mr={2} color="colorDefault" size={1.6} />
                          <Container.Grid pb={2.6} pt={2.6} direction="column">
                            <Typography.h6 mt={0.4} mb={0.4}>
                              Licenses Allocated
                            </Typography.h6>
                            <Typography.p fontSize={12} mt={0.4} mb={0.4}>
                              DWM Licenses Allocated
                            </Typography.p>
                          </Container.Grid>
                          <TextField value={darkWebLicense.licences_allocated} size="small" disabled />
                        </Container.Grid>
                      </>
                    )}
                  </Container.Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={isPanelExpanded('panel3a')} onChange={handleChange('panel3a')}>
                <AccordionSummary>
                  <TypographyCL variant='subtitle1' color={isPanelExpanded('panel3a') && 'info.main'}>TMS Configurations</TypographyCL>
                </AccordionSummary>
                <AccordionDetails>
                  <Container.Grid container md={12}>
                    <Container.Grid item md={3} pb={2}>
                      <Switch
                        label="Enable TMS System"
                        value={tmsConfiguration}
                        checked={tmsConfiguration}
                        onChange={({ target: { checked } }) => onChangeTmsConfiguration(checked)}
                      />
                    </Container.Grid>

                    {tmsConfiguration && (
                      <Container.Grid item md={4} pb={2}>
                        <SelectField
                          label="Select TMS Integration"
                          value={record.tms_id}
                          name="tms_id"
                          choices={tmsOptions}
                          onChange={({ target: { name, value } }) => onChange(name, value)}
                          required
                          fullWidth
                        />
                      </Container.Grid>
                    )}
                    <AccordionFooter onSubmit={save} disabled={loading || disabledTMSconfig} />
                  </Container.Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={isPanelExpanded('panel4a')} onChange={handleChange('panel4a')}>
                <AccordionSummary>
                  <TypographyCL variant='subtitle1' color={isPanelExpanded('panel4a') && 'info.main'}>Email Notifications</TypographyCL>
                </AccordionSummary>
                <AccordionDetails>
                  <Container.Grid container md={12} spacing={2}>
                    <Container.Grid item md={5} pt={2} pb={2}>
                      <TextFieldCL
                        label="Email addresses to receive contact us notification (separated by semicolon)"
                        value={record.contact_us_notification_email}
                        name="contact_us_notification_email"
                        onChange={({ target: { name, value } }) => onChangeEmailAddresses(name, value)}
                        error={fieldsError.contact_us_notification_email}
                        helperText={fieldsError.contact_us_notification_email && 'Please enter valid email addresses'}
                        fullWidth
                        size="small"
                      />
                    </Container.Grid>
                    <Container.Grid item md={5} pt={2} pb={2}>
                      <TextFieldCL
                        label="Email address to receive directory synchronization errors"
                        value={record.add_notification_email}
                        name="add_notification_email"
                        onChange={({ target: { name, value } }) => onChangeEmail(name, value)}
                        error={fieldsError.add_notification_email}
                        helperText={fieldsError.add_notification_email && 'Please enter valid email address'}
                        fullWidth
                        size="small"
                      />
                    </Container.Grid>

                    <AccordionFooter onSubmit={save} disabled={loading || disabledEmailsSave} />
                  </Container.Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={isPanelExpanded('panel5a')} onChange={handleChange('panel5a')}>
                <AccordionSummary>
                  <TypographyCL variant='subtitle1' color={isPanelExpanded('panel5a') && 'info.main'}>Push notifications</TypographyCL>
                </AccordionSummary>
                <AccordionDetails>
                  <Container.Grid block direction="column">
                    <SendingTimesAccordion isOnPartnerProfile={true} partner_Id={partnerId} onFinishLoading={() => setIsLoadingSendingTimes(false)} isLoading={isLoadingNotifications}/>
                    <PagePushNotifications partnerId={partnerId} clientId={null} trackGA onFinishLoading={() => setIsLoadingNotifications(false)} isLoading={isLoadingSendingTimes}/>
                  </Container.Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          <Accordion expanded={isPanelExpanded('panel6a')} onChange={handleChange('panel6a')}>
            <AccordionSummary>
              <TypographyCL variant='subtitle1' color={isPanelExpanded('panel6a') && 'info.main'}>Logos</TypographyCL>
            </AccordionSummary>
            <AccordionDetails>
              <Container.Grid md={12} fullWidth>
                <Container.Grid container>
                  <Container.Grid item md={3}>
                    <DragDropLogo
                      label="Partner logo"
                      name="logo"
                      logo={record.logo || logo?.url}
                      onChange={onChangeLogo}
                      preview={previewPartnerLogo}
                      setPreview={setPreviewPartnerLogo}
                      deleteLogo={() => deleteLogo('logo')}
                      fileName={logo?.fileName}
                      openDeleteModal={!logo?.isChanged}
                    />
                  </Container.Grid>
                  <Container.Grid item md={2}></Container.Grid>
                  {!createMode && (
                    <Container.Grid item md={3}>
                      <DragDropLogo
                        label="Service logo"
                        name="service_logo"
                        logo={record.service_logo || service_logo?.url}
                        onChange={onChangeServiceLogo}
                        preview={previewServiceLogo}
                        setPreview={setPreviewServiceLogo}
                        deleteLogo={() => deleteLogo('service_logo')}
                        fileName={service_logo?.fileName}
                        openDeleteModal={!service_logo?.isChanged}
                      />
                    </Container.Grid>
                  )}
                </Container.Grid>

                {!createMode && (
                  <AccordionFooter
                    onSubmit={() => pressignedUploadFile(logo?.isChanged, service_logo?.isChanged)}
                    disabled={loadingLogo || !(logo?.isChanged || service_logo?.isChanged)}
                  />
                )}
              </Container.Grid>
            </AccordionDetails>
          </Accordion>

          {!createMode && (
            <>
              {/* Hide Payment Exempt panel if distributor is MP or PAX8 */}
              {record.distributor === 'BSN' ? (
                <Accordion expanded={isPanelExpanded('panel7a')} onChange={handleChange('panel7a')}>
                  <AccordionSummary>
                    <TypographyCL variant='subtitle1' color={isPanelExpanded('panel7a') && 'info.main'}>Payment Information</TypographyCL>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container.Grid container md={12}>
                      <Container.Grid md={3} pb={2}>
                        <Switch
                          label="Payment Exempt"
                          value={record.payment_exempt}
                          checked={record.payment_exempt}
                          name="payment_exempt"
                          onChange={({ target: { name, checked } }) => onChange(name, checked)}
                          disabled={loading}
                        />
                      </Container.Grid>
                      <AccordionFooter
                        onSubmit={save}
                        disabled={loading || partnerInfo.payment_exempt === record.payment_exempt}
                      />
                    </Container.Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {record?.distributor === 'BSN' && !record?.payment_exempt && <PartnersTaxExempt partnerId={partnerId} />}

              {/* Hide Billing panel if distibutor is MP or PAX8  */}
              {record.distributor === 'BSN' ? (
                <Accordion expanded={isPanelExpanded('panel8a')} onChange={handleChange('panel8a')}>
                  <AccordionSummary>
                    <TypographyCL variant='subtitle1' color={isPanelExpanded('panel8a') && 'info.main'}>Billing Report</TypographyCL>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container.Grid container md={12}>
                      <PartnersBillingReport partnerId={partnerId} />
                    </Container.Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              {record?.brand_key ? (
                <Accordion expanded={isPanelExpanded('panel9a')} onChange={handleChange('panel9a')}>
                  <AccordionSummary>
                    <TypographyCL variant='subtitle1' color={isPanelExpanded('panel9a') && 'info.main'}>Branded Login URL</TypographyCL>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container.Grid container md={12}>
                      <PartnerInformation record={record} />
                    </Container.Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}

              <Accordion expanded={isPanelExpanded('panel10a')} onChange={handleChange('panel10a')}>
                <AccordionSummary>
                  <TypographyCL variant='subtitle1' color={isPanelExpanded('panel10a') && 'info.main'}>Integrations</TypographyCL>
                </AccordionSummary>
                <AccordionDetails>
                  <Container.Grid container md={12}>
                    <PartnerIntegrations partner_id={partnerId} />
                  </Container.Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          <Accordion expanded={isPanelExpanded('panel11a')} onChange={handleChange('panel11a')}>
            <AccordionSummary>
              <TypographyCL variant='subtitle1' color={isPanelExpanded('panel11a') && 'info.main'}>Notes</TypographyCL>
            </AccordionSummary>
            <AccordionDetails>
              <Container.Grid container md={12}>
                <Container.Grid item md={5} pb={2} pl={1}>
                  <TextFieldCL
                    label="Enter information that may be important"
                    name="notes"
                    value={record.notes}
                    onChange={({ target: { name, value } }) => onChange(name, value)}
                    fullWidth
                    multiline
                    rows={4}
                    size="small"
                  />
                </Container.Grid>

                {!createMode && (
                  <>
                    <PartnersNotes data={notesData} />
                    <AccordionFooter onSubmit={save} disabled={loading || !record.notes} />
                  </>
                )}
              </Container.Grid>
            </AccordionDetails>
          </Accordion>

          {createMode && (
            <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container.Paper fullWidth radius={2} p={2}>
                <Container.Grid item md={12} justify="flex-end" alignItems="center">
                  <Button
                    backgroundColor="colorSystemInfo"
                    backgroundColorHover="colorSystemInfoHover"
                    onClick={save}
                    disabled={disabledSave || loading}
                  >
                    <LazyIcon component="Save" color="colorCommonWhite" mr={1} />
                    Save
                  </Button>
                </Container.Grid>
              </Container.Paper>
            </Container.Grid>
          )}

        </Stack>
      {/* </Container.Grid> */}
      <ConfirmModal config={dialogConfig} onClose={onCloseDialog} onSubmit={dialogConfig.submit} />
    </>
  );
};

export default React.memo(PartnersAddEdit);
