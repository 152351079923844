// @flow

import store from 'helpers/store';
import {
  whiteSmoke,
  lightGrey,
  royalBlue,
  sanMarino,
  lavenderBlue,
  bayOfMany,
  black,
  mid_gray,
  chelseaCucumber,
  eletricIndigo,
  chantilly,
  turkishRose,
  karry,
  nobel,
  eastSide,
  lilacBush
} from './colors';

let theme = store('bsn').getState().partnerProfile.colorScheme;

export const colorChange = (formData: Object): void => {
  theme = formData;
};

store('bsn').subscribe(colorChange);

const schemes = {
  winter: {
    background: theme.background,
    alt_bg: theme.alt_bg,
    nav_bg: theme.nav_bg,
    body_copy: theme.body_copy,
    nav_copy: theme.nav_copy,
    links: theme.links,
    active_links: theme.active_links,
    nav_links: theme.nav_links,
    mid_gray: theme.mid_gray
  },
  spring: {
    background: whiteSmoke,
    alt_bg: lightGrey,
    nav_bg: chelseaCucumber,
    body_copy: sanMarino,
    nav_copy: lavenderBlue,
    links: bayOfMany,
    active_links: eletricIndigo,
    nav_links: black,
    mid_gray
  },
  summer: {
    background: whiteSmoke,
    alt_bg: lightGrey,
    nav_bg: chantilly,
    body_copy: turkishRose,
    nav_copy: karry,
    links: nobel,
    active_links: royalBlue,
    nav_links: black,
    mid_gray
  },
  autumn: {
    background: whiteSmoke,
    alt_bg: lightGrey,
    nav_bg: eastSide,
    body_copy: lilacBush,
    nav_copy: lavenderBlue,
    links: bayOfMany,
    active_links: nobel,
    nav_links: black,
    mid_gray
  }
};

export default schemes;
