/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Link,
  Stack,
  AddIcon,
  CloseIcon,
  IconButton,
  SaveIcon,
  Chip,
  Loading
} from '@trustsecurenow/components-library';
import Parser from 'html-react-parser';


const ConfigureTrainingDialog = ({
  open,
  onCloseDialog,
  packageName,
  configureText,
  onClickClientList,
  onDeleteClient,
  onSaveExcludePackageClients,
  isLoading,
  isSaving,
  selectedClients = []
}) => {
  return (
    <Dialog open={open} onClose={onCloseDialog} fullWidth>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} pr={2.5}>
        <DialogTitle variant="h2">Configure {packageName}</DialogTitle>
        <IconButton onClick={onCloseDialog}>
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      </Stack>

      <DialogContent dividers>
        {isLoading ? (
          <Loading />
        ) : (
          <Stack spacing={1.75}>
            <Stack spacing={6.5}>
              <DialogContentText color="text">
                {configureText ? Parser(configureText) : configureText}
              </DialogContentText>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack spacing={0.5}>
                  <Typography variant="subtitle2">Exclude from {packageName}:</Typography>
                  <Typography variant="body2">Your list has {selectedClients.length} client(s)</Typography>
                </Stack>
                <Button startIcon={<AddIcon />} onClick={onClickClientList}>
                  Add Client List
                </Button>
              </Stack>
            </Stack>
            <Stack spacing={1.25} direction="row" flexWrap="wrap" useFlexGap>
              {selectedClients.map(client => (
                <Chip
                  key={client.id}
                  label={client.name}
                  variant="outlined"
                  onDelete={() => onDeleteClient(client.id)}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack spacing={3.75} direction="row">
          <Link component="button" onClick={onCloseDialog}>
            Cancel
          </Link>
          <Button
            width={150}
            startIcon={<SaveIcon />}
            color="info"
            disabled={isSaving || isLoading}
            loading={isSaving}
            onClick={onSaveExcludePackageClients}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigureTrainingDialog;
