import React from 'react';
import PropTypes from 'prop-types';
import AboutWorkPlan from './AboutWorkPlan';
import RecommendationItem from './RecommendationItem';

const RecommendationInfo = ({ currentRecommendation, setCurrentRecommendation, aboutVideo, getWorkplanInfo }) => {
  return currentRecommendation ? (
    <RecommendationItem
      currentRecommendation={currentRecommendation}
      setCurrentRecommendation={setCurrentRecommendation}
      getWorkplanInfo={getWorkplanInfo}
    />
  ) : (
    <AboutWorkPlan aboutVideo={aboutVideo} />
  );
};

RecommendationInfo.propTypes = {
  currentRecommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    priority: PropTypes.string,
    due_date: PropTypes.string,
    date_completed: PropTypes.string,
    notes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    finding: PropTypes.string,
    recommendation: PropTypes.string
  }),
  setCurrentRecommendation: PropTypes.func.isRequired,
  aboutVideo: PropTypes.string,
  getWorkplanInfo: PropTypes.func.isRequired
};

RecommendationInfo.defaultProps = {
  currentRecommendation: null,
  aboutVideo: ''
};

export default RecommendationInfo;
