import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const LineProgress = ({ backgroundColor = null, barColor = null, score = 0, ...props }) => {

  const useStyles = makeStyles({
    barColorPrimary: {
      backgroundColor: barColor
    },
    colorPrimary: {
      backgroundColor: backgroundColor
    }
  });
  const classes = useStyles();
  return (
    <LinearProgress
      {...props}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary
      }}
    />
  );
};

export default LineProgress;
