import styled from "styled-components"
import { Container, Typography } from 'components';

export const PreassessmentModalContainer = styled(Container.Grid)`
  padding: 0 46px;

  svg {
    width: 70px;
    height: 70px;
  }
`

export const PreassessmentModalTitle = styled(Typography.h3)`
  text-align: center;
  color: var(--colorSystemSuccess);
  font-size: 24px;
  line-height: 29px;
  max-width: 351px;
  margin: 15px 0 30px 0;
`

export const PreassessmentModalDescription = styled(Typography.p)`
  text-align: center;
  color: var(--colorGreyLight1);
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 30px 0;
`
