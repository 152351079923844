// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Welcome = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15 17" role="img" aria-hidden="false">
    <g id="icon" transform="translate(-41.916 -41.218)">
      <g id="sigin" transform="translate(41.916 41.218)">
        <path
          id="Path_188"
          data-name="Path 188"
          d="M15.395,0H9.41a.72.72,0,0,0-.73.708.72.72,0,0,0,.73.708H15.4a.657.657,0,0,1,.552.708V14.861a.657.657,0,0,1-.552.708H9.41a.709.709,0,1,0,0,1.417H15.4a2.075,2.075,0,0,0,2.012-2.132V2.132A2.085,2.085,0,0,0,15.395,0Z"
          transform="translate(-2.532)"
          fill="#fff"
        />
        <path
          id="Path_189"
          data-name="Path 189"
          d="M6.219,13.983a.716.716,0,0,0,.517.205.759.759,0,0,0,.517-.205l3.088-3.017a.677.677,0,0,0,.156-.234.727.727,0,0,0,0-.545.843.843,0,0,0-.156-.234L7.253,6.935a.74.74,0,0,0-1.027,0,.7.7,0,0,0,0,1.006L8.068,9.754H.73a.709.709,0,1,0,0,1.417H8.068l-1.849,1.8A.71.71,0,0,0,6.219,13.983Z"
          transform="translate(0 -1.962)"
          fill="#fff"
        />
      </g>
    </g>
  </SvgIconStyled>
);

export default Welcome;
