// @flow

import React, { memo } from 'react';
import { Checkbox, Radio } from '@material-ui/core';
import { useTableRowSelected } from 'hooks';
import { TableCellContent } from '../ComponentTypes';
import { useTableList2 } from '../TableList2Context';

type TableListSelectingTypes = {
  row: Object,
  index: number
};

const TableListSelecting = ({ row, index }: TableListSelectingTypes) => {
  const { settings } = useTableList2();
  const { isSelected, setSelect } = useTableRowSelected();
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const { selecting } = settings;
  if (!selecting) return null;

  return (
    <TableCellContent>
      {settings?.selectingRadio ? (
        <Radio
          checked={isItemSelected}
          onClick={() => setSelect(row.id, true)}
          inputProps={{ 'aria-labelledby': labelId }}
          name="tableSelect"
          value={row.id}
        />
      ) : (
        <Checkbox
          checked={isItemSelected}
          onClick={() => setSelect(row.id)}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      )}
    </TableCellContent>
  );
};

export default memo<TableListSelectingTypes>(TableListSelecting);
