import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { setNotifications, updateNotifications, setUnopenedNotificationsCount, markAllOpened } from 'helpers/action';
import { getStorage, decodeId, encodeId } from 'helpers';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingStyled } from 'components';
import { Box, Drawer, Divider } from '@material-ui/core';
import NotificationAvatar from './NotificationAvatar';
import ToastCloseButton from './ToastCloseButton';
import NoNotifications from './NoNotifications';
import ReactTimeAgo from 'react-time-ago';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { BSN_SET_USER_ALL_NOTIFICATIONS_SEEN } from 'conf';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 460,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: 460
    }
  },
  timeAgo: {
    color: '#847e7e',
    fontSize: '10px',
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  header: {
    fontSize: '18px',
    fontWeight: '700',
    padding: '15px 20px'
  },
  close: {
    float: 'right',
    cursor: 'pointer'
  },
  markAll: {
    padding: '8px 15px',
    textAlign: 'right'
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    marginLeft: 5,
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function TemporaryDrawer({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.newsfeed.notifications);
  const reloadNotifications = useSelector(state => state.newsfeed.reloadNotifications);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const user = {
    id: decodeId(getStorage('userId'))
  };

  const markNotificationSeen = (type, id, postId, opened) => {
    window.location.href = `/#/newsfeed/dashboard/${postId}?notification_id=${id}&notification_type=${type}&previouslyOpened=${opened}`;
  };

  const markAllRead = () => {
    newsFeed
      .markAllOpened()
      .then(() => {
        dispatch(setUnopenedNotificationsCount(true, 0));
        dispatch(markAllOpened());
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchData = () => {
    newsFeed
      .getNotifications(user.id, notifications.length)
      .then(res => {
        if (res.data.length !== 0) {
          const notificationsArray = [...notifications, ...res.data];
          dispatch(setNotifications(notificationsArray));
        } else setHasMore(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const initialFetch = () => {
    newsFeed
      .getNotifications(user.id, 0)
      .then(res => {
        dispatch(setNotifications(res.data));
        if (res.data.length % 5 !== 0 || res.data.length === 0) setHasMore(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (reloadNotifications) {
      usersAPI.getUserData(['feed']).then(data => {
        dispatch(setUnopenedNotificationsCount(true, data.newsfeed_unopened_notifications_count));
        dispatch({
          type: BSN_SET_USER_ALL_NOTIFICATIONS_SEEN,
          payload: data.all_newsfeed_notifications_seen
        });
      });
      initialFetch();
      dispatch(updateNotifications(false));
    }
  }, [reloadNotifications]);

  const onFocusChange = () => {
    if (!document.hidden) {
      initialFetch();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', onFocusChange);
    return () => {
      window.removeEventListener('visibilitychange', onFocusChange);
    };
  }, []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        classes: { root: classes.drawer }
      }}
    >
      <div role="presentation">
        <div className={classes.header}>
          Notifications
          <span onClick={onClose} className={classes.close}>
            <ToastCloseButton />
          </span>
        </div>
        <Divider />

        {!notifications ? (
          <LoadingStyled />
        ) : !notifications.length ? (
          <NoNotifications />
        ) : (
          <>
            <div className={classes.markAll}>
              <span onClick={markAllRead} className={classes.link}>
                Mark all as read
              </span>
            </div>
            <Divider />
            <div id="scrollableDivNotifications" style={{ height: 'calc(100vh - 92px)', overflowY: 'auto' }}>
              <InfiniteScroll
                dataLength={notifications.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<LoadingStyled />}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                scrollableTarget="scrollableDivNotifications"
              >
                {notifications.map(notification => (
                  <div key={notification.notification_id}>
                    <Box
                      px={1}
                      py={1.5}
                      display="flex"
                      alignItems="center"
                      style={{
                        backgroundColor: notification.opened === 1 ? 'inherit' : 'var(--backgroundBaseLight)',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        onClose();
                        markNotificationSeen(
                          notification.type,
                          encodeId(notification.notification_id),
                          encodeId(notification.data.post.id),
                          notification.opened
                        );
                      }}
                    >
                      <Box p={1}>
                        <NotificationAvatar user={notification.data.user} type={notification.type} size="sm" />
                      </Box>
                      <Box>
                        <Box pl={1} pr={1} sx={{ display: 'block' }}>
                          {notification.notification_body}
                        </Box>
                        <Box pl={1} pr={1} sx={{ display: 'block' }}>
                          <ReactTimeAgo
                            className={classes.timeAgo}
                            timeStyle="round-minute"
                            date={notification.timestamp.replace(' ', 'T') + 'Z'}
                            locale="en-US"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}
