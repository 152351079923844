// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Clients = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 19.3 15.9" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h19.3v15H9z" />
    <g>
      <path d="M0.8,4h2.5c0.4,1.7,2.2,2.7,3.9,2.3C8.4,6,9.3,5.2,9.6,4h8.9c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-9   C9.1,0.7,7.4-0.3,5.7,0.1C4.5,0.4,3.6,1.3,3.3,2.4H0.8c0,0-0.1,0-0.1,0C0.3,2.5,0,2.9,0,3.3C0.1,3.7,0.4,4,0.8,4z M4.8,3.2   c0-0.9,0.7-1.6,1.6-1.6c0,0,0,0,0,0c0.9,0,1.6,0.7,1.6,1.6c0,C4.8,3.2,4.8,3.2,4.8,3.2z" />
      <path d="M18.5,11.9L18.5,11.9l-2.5,0c-0.4-1.7-2.2-2.7-3.9-2.3c-1.1,0.3-2,1.2-2.3,2.3h-9c-0.4,0-0.8,0.4-0.8,0.9   c0,0.4,0.4,0.8,0.8,0.8h9c0.4,1.7,2.2,2.7,3.9,2.3c1.1-0.3,2-1.2,2.3-2.3h2.5c0.4,0,0.8-0.4,0.8-0.8C19.3,12.2,18.9,11.9,18.5,11.9   z M14.5,12.7c0,0.9-0.7,1.6-1.6,1.6c0,0,0,0,0,0c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6C13.7,11,14.5,11.8,14.5,12.7   C14.5,12.7,14.5,12.7,14.5,12.7z" />
    </g>
  </SvgIconStyled>
);

export default Clients;
