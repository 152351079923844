// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Mail = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 30 19.5" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h30v19.5H0z" />
    <g>
      <path d="M27.9,0H2.1C1,0,0,0.9,0,2.1v15.3c0,1.2,1,2.1,2.1,2.1h25.7c1.2,0,2.1-0.9,2.1-2.1V2.1C30,0.9,29,0,27.9,0z   M27.5,1.4L15.6,12c-0.4,0.3-0.9,0.3-1.2,0L2.5,1.4H27.5z M1.4,17.3v-15l8.7,7.8L1.4,17.3z M2.6,18.1l8.6-7.1l2.3,2  c0.9,0.8,2.2,0.8,3.1,0l2.3-2l8.6,7.1H2.6z M28.6,17.3l-8.7-7.2l8.7-7.8V17.3z"/>
    </g>
  </SvgIconStyled>
);

export default Mail;
