// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { TableBody } from '@material-ui/core';
import { type partnerProfileInformationType } from 'conf';
import { Container, Typography, Table } from 'components';
import { Heart as HeartIcon } from 'components/icons';
import PartnerInformationRow from './PartnerInformationRow';

export const TableBodyPartner: ComponentType<*> = styled(TableBody)`
  tr {
    height: calc(var(--spacing) * 8);
  }
`;

export const StrongLabel: ComponentType<*> = styled.span`
  font-weight: var(--fontWeightBold);
`;

type PartnerInformationType = {
  record?: partnerProfileInformationType
};

const PartnerInformation = ({
  record = {
    brand_key: '',
    logo: '',
    branded_urls: {}
  }
}: PartnerInformationType) => {
  const { brand_key: brandKey, branded_urls: brandedUrls, logo } = record;
  // const handleCopy = (i, message) => {
  //   console.log(i);
  //   console.log(message);
  //   dispatch('snackState', {
  //     state: true,
  //     message
  //   });
  // };

  return (
    <>
      <Container.Paper px={2} mt={2} alignItems="center">
        <HeartIcon mr={2.5} size={1.5} color="info" />
        <Typography.p>Branded Login URL</Typography.p>
      </Container.Paper>
      <Container.Paper mt={2} direction="column">
        <Table.Container>
          <TableBodyPartner>
            <PartnerInformationRow name="Key" url={brandKey} icon="PartnerKey" isCopy={false} />
            {brandedUrls.map(({ name, url, icon }) => (
              <PartnerInformationRow name={name} url={url} icon={icon} isCopy />
            ))}
          </TableBodyPartner>
        </Table.Container>
        <Typography.p pl={2} pr={4} color="colorSystemDangerHover">
          <StrongLabel>Note: </StrongLabel>The landing page URL pre-populates the partner email address on file. This
          email address will receive form submissions when completed by an individual. To adjust where these form
          submissions are sent, update the email address with your preferred email address.
        </Typography.p>
      </Container.Paper>
    </>
  );
};

PartnerInformation.defaultProps = {
  record: {
    brand_key: '',
    logo: '',
    branded_urls: []
  }
};

export default PartnerInformation;
