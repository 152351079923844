import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Container, LoadingStyled } from 'components';
import { admin } from 'helpers';
import PartnerBillingReport from '../../../partner/PartnerBillingReport';
import { Box } from '@trustsecurenow/components-library';

const PartnersBillingReport = ({ partnerId }) => {
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    
    admin.partners
      .getBilling(partnerId)
      .then((resp) => setData(resp.data))
      .catch(err => {
        notify(err?.response?.data?.message || 'Failed to get Billing Information', 'error');
      })
      .finally(() => setLoading(false));
  }, [partnerId]);
  
  if (loading) return <Box width={'100%'}><LoadingStyled /></Box>;

  return (
    <Container.Grid md={12}>
      <PartnerBillingReport record={data} partnerId={partnerId} />
    </Container.Grid>
  )
}

export default PartnersBillingReport;