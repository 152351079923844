import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const ThumbsUp = props => (
  <SvgIconStyled {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" role="img" aria-hidden="false">
    <path
      d="M31.557 12.1578H23.1673L24.2868 6.79505C24.6186 5.23322 23.9137 3.64375 22.5039 2.74535L19.2973 0.685929C18.0948 -0.0880776 16.6159 -0.212472 15.3167 0.340391C14.1142 0.86561 13.2711 1.8884 13.0362 3.14616L11.1564 13.0286C11.032 13.1253 10.9214 13.2083 10.8108 13.305C10.0783 12.6001 9.06931 12.1578 7.96359 12.1578H4.64647C2.35209 12.1578 0.5 14.0237 0.5 16.3043V31.8535C0.5 34.1341 2.35209 36 4.64647 36H7.96359C9.06931 36 10.0783 35.5577 10.8108 34.8528C11.6539 35.5715 12.815 36 14.1142 36H27.5211C30.0228 36 32.0269 34.3967 32.2757 32.282L36.2425 17.0368C36.3116 16.8019 36.3393 16.5669 36.3393 16.3319C36.3393 13.9961 34.2384 12.1578 31.557 12.1578ZM9.34574 16.3319V31.8259C9.34574 31.8397 9.34574 31.8397 9.34574 31.8535C9.34574 32.6137 8.73759 33.2357 7.96359 33.2357H4.64647C3.88628 33.2357 3.26431 32.6137 3.26431 31.8535V16.3043C3.26431 15.5441 3.88628 14.9221 4.64647 14.9221H7.96359C8.73759 14.9221 9.34574 15.5441 9.34574 16.3043C9.34574 16.3181 9.34574 16.3181 9.34574 16.3319ZM29.539 31.8259C29.539 32.5999 28.6407 33.2357 27.5211 33.2357H14.1142C13.0223 33.2357 12.1377 32.6137 12.1101 31.8535C12.1101 31.8397 12.1101 31.8397 12.1101 31.8259V17.0645V16.3319C12.1101 16.3181 12.1101 16.3181 12.1101 16.3043C12.1377 15.5441 13.0223 14.9221 14.1142 14.9221H15.6622L15.0402 14.8116C14.2939 14.6733 13.7963 13.9408 13.9345 13.1944L14.128 12.1578L15.7451 3.65755C15.8281 3.20144 16.1875 2.9803 16.3948 2.88355C16.8509 2.69005 17.3622 2.73151 17.7907 3.00794L21.0111 5.06736C21.4396 5.34379 21.6608 5.7999 21.564 6.22836L20.3201 12.1578L20.1404 13.0009C20.1266 13.0562 20.1265 13.0977 20.1265 13.153C20.1127 13.1806 20.0989 13.2083 20.0851 13.2497C19.9054 14.1067 20.555 14.9221 21.4396 14.9221H21.7022H22.573H31.5432C32.6489 14.9221 33.5611 15.5579 33.5611 16.3319L29.539 31.8259Z"
      fill="#0069FF"
    />
  </SvgIconStyled>
);

export default React.memo(ThumbsUp);
