import React from 'react';


const WarningTriangle = (props) => (
  <svg {...props} width="25" height="40" viewBox="0 0 30 30">
    <path 
    fill="currentColor" // This will make the path fill color inherit from the parent's text color
    fill-rule="evenodd" clip-rule="evenodd" d="M5.03 10.08L9.21 1.73C10.36 -0.580002 13.64 -0.580002 14.78 1.73L18.96 10.08L23.66 19.47C23.89 19.93 24 20.41 24 20.87C24 22.51 22.69 24 20.88 24H12H3.12C1.32 24 0 22.51 0 20.87C0 20.41 0.11 19.93 0.33 19.47L5.03 10.08ZM17.18 10.97L13 2.62C12.72 2.06 12.21 2 12 2C11.79 2 11.28 2.06 11 2.62L6.82 10.98L2.12 20.37C2.04 20.53 2 20.7 2 20.87C2 21.41 2.43 22 3.12 21.98H12H20.88C21.57 21.98 22 21.4 22 20.86C22 20.69 21.96 20.52 21.88 20.36L17.18 10.97ZM10.79 9.82998L11.06 13.92C11.11 14.45 11.2 14.85 11.31 15.1C11.43 15.36 11.64 15.49 11.95 15.49C12.25 15.49 12.47 15.36 12.6 15.09C12.73 14.83 12.82 14.44 12.86 13.94L13.23 9.72997C13.27 9.33998 13.29 8.95998 13.29 8.57998C13.29 7.93998 13.21 7.44998 13.04 7.10998C12.88 6.76998 12.55 6.59998 12.06 6.59998C11.65 6.59998 11.33 6.73998 11.08 6.99998C10.83 7.26998 10.71 7.63998 10.71 8.10998C10.71 8.45998 10.74 9.02998 10.79 9.82998ZM11.1 19.21C11.36 19.44 11.66 19.55 12.01 19.55C12.35 19.55 12.65 19.43 12.91 19.21C13.16 18.98 13.29 18.67 13.29 18.27C13.29 17.92 13.16 17.62 12.91 17.37C12.66 17.12 12.35 17 11.99 17C11.63 17 11.33 17.12 11.08 17.37C10.83 17.62 10.71 17.92 10.71 18.27C10.71 18.67 10.84 18.99 11.1 19.21Z"/>
  </svg>
);

export default WarningTriangle;
