import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNA } from '../helpers';

const BreachesCell = ({ breaches, clientId }) => {
  if (isNA(breaches)) return <Typography variant="body2">N/A</Typography>;

  return (
    <Tooltip title="Click here to navigate to the Dark Web tab" placement="top">
      <Link
        component={RouterLink}
        to={`/clients/darkWebMonitoring/${clientId}`}
        underline="hover"
        hoverColor="info.main"
        variant="body2"
        onClick={e => e.stopPropagation()}
      >
        {breaches}
      </Link>
    </Tooltip>
  );
};

BreachesCell.propTypes = {
  breaches: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};

export default BreachesCell;
