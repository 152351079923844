// @flow
import React, { memo } from 'react';
import { TableBody, TableCell, Checkbox, useMediaQuery } from '@material-ui/core';
import { ScrollLeft as ScrollLeftIcon, ScrollRight as ScrollRightIcon } from 'components/icons';
import { LazyIcon, Typography, DashboardBoxTitle, InfoButtonWithPopover } from 'components';
import { hasKey } from 'helpers';
import { useTableRowSelected, useBreakpoint } from 'hooks';
import FilterTable from './FilterTable';
import ShowingHeadCell from './ShowingHeadCell';
import {
  TableHeadContent,
  TableRowContent,
  TableSort,
  TableHeadIconMobile,
  TableHeadContentSubLabel
} from './ComponentTypes';
import { useTableList } from './TableListContext';
import { useDispatch } from 'react-redux';
import { resetCreateUserMatchingEmailError } from 'helpers/action/errorHandlerAction';

type TableHeadTypes = {
  numSelected: number,
  onRequestSort: Function,
  onSelectAllClick: Function,
  order: 'asc' | 'desc',
  orderBy: string,
  rowCount: number,
  tableSettings: Object,
  isFilter: boolean,
  onSearch: Function,
  showPassword: Function,
  record: any
};

const TableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableSettings,
  isFilter,
  onSearch,
  showPassword,
  record,
  hideColumns,
  responsive,
  isFetching
}: TableHeadTypes) => {
  const { isSelectedAll } = useTableRowSelected();
  const { cells, selecting, folding } = tableSettings;
  const mobileView = useBreakpoint('sm');
  const { app, tab } = useTableList();
  const dispatch = useDispatch();

  const createSortHandler = property => event => {
    const currSort = cells.find(curr => curr.id === property);
    if (currSort && !currSort.hideOrderBy) {
      onRequestSort(event, property);
    }
    dispatch(resetCreateUserMatchingEmailError());
  };

  const isRadio = hasKey(tableSettings, 'selectingRadio') && tableSettings.selectingRadio;

  const newCells = cells.filter(c => {
    return !(c?.hideWhenNoResult || hideColumns?.includes(c?.id));
  });

  const hiddenCell = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const isFixedHead = mobileView && app === 'clients' && tab === 'usersList';

  return (
    <>
      <TableHeadContent>
        <TableRowContent>
          {!hiddenCell && responsive && (
            <TableHeadIconMobile padding="none">
              <ScrollLeftIcon size={1.7} ml={mobileView ? 0 : 2} mr={mobileView ? 0 : 2} />
            </TableHeadIconMobile>
          )}
          {selecting && (
            <TableCell align="left" padding="checkbox" id="fistElement">
              {!isRadio && (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={isSelectedAll || numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                  disabled={isFetching}
                  style={{ opacity: isFetching ? 0.4 : 1 }}
                />
              )}
            </TableCell>
          )}
          {folding && <TableCell align="left" padding="checkbox" />}
          {newCells.map(headCell => (
            <TableCell
              style={
                headCell.id === 'name' && isFixedHead
                  ? { position: 'sticky', left: 0, background: 'var(--backgroundPaper)', zIndex: 2 }
                  : {}
              }
              align={headCell.labelAlign || 'left'}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSort
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.ghostId || headCell.id)}
                disabled={headCell.hideOrderBy}
                labelPaddingLeft={headCell.labelPaddingLeft}
              >
                {headCell.renderIconLabel ? (
                  <LazyIcon component={headCell.renderIconLabel} size={1.5} ml={1.5} color="colorDefault" />
                ) : (
                  <>
                    {headCell.infoIcon ? (
                      <DashboardBoxTitle
                        icon
                        label={headCell.label}
                        mbbox
                        fullWidth
                        fontSize={14}
                        iconSize={1}
                        description={headCell.infoIconDescription}
                        labelColor="var(--colorFontTableHeader)"
                        iconMl={1}
                      />
                    ) : (
                      headCell.label
                    )}
                  </>
                )}
                {orderBy === headCell.id ? <span>{order === 'desc' ? '' : ''}</span> : null}
                {headCell.tooltipLabel && (
                  <InfoButtonWithPopover
                    iconCustomStyle={{ pointerEvents: 'initial' }}
                    tooltip={headCell.tooltipLabel}
                  />
                )}
              </TableSort>
              <br />
              {rowCount !== 0 && headCell.subLabel && (
                <TableHeadContentSubLabel>
                  {headCell.subLabel.map(label => (
                    <Typography.p fontSize={12} mt={0.1} mb={0.1}>
                      {label}
                    </Typography.p>
                  ))}
                </TableHeadContentSubLabel>
              )}
              {headCell.showing && (
                <ShowingHeadCell
                  optionDefault={headCell.defaultShowingValue}
                  options={headCell.showingValue}
                  showPassword={showPassword}
                />
              )}
            </TableCell>
          ))}
          {!hiddenCell && responsive && (
            <TableHeadIconMobile padding="none">
              <ScrollRightIcon size={1.7} ml={mobileView ? 0 : 2} mr={mobileView ? 0 : 2} />
            </TableHeadIconMobile>
          )}
        </TableRowContent>
      </TableHeadContent>
      {isFilter && (
        <TableBody>
          <TableRowContent>
            {selecting && <TableCell align="left" />}
            {folding && <TableCell align="left" />}
            {newCells.map(cell => (
              <TableCell align="left" id="lastElement">
                <FilterTable dataFilter={cell.filter} onFilter={onSearch} onSearch={onSearch} />
              </TableCell>
            ))}
          </TableRowContent>
        </TableBody>
      )}
    </>
  );
};

export default memo<TableHeadTypes>(TableHead);
