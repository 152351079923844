// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const CloseCircle = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 25" role="img" aria-hidden="false">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 0C18.6182 0 23.9998 5.38154 23.9998 12C23.9998 18.6185 18.6182 24 11.9998 24C5.38129 24 -0.000244141 18.6185 -0.000244141 12C-0.000244141 5.38154 5.38129 0 11.9998 0ZM11.9998 1.84615C6.39668 1.84615 1.84591 6.39692 1.84591 12C1.84591 17.6031 6.39668 22.1538 11.9998 22.1538C17.6028 22.1538 22.1536 17.6031 22.1536 12C22.1536 6.39692 17.6028 1.84615 11.9998 1.84615ZM13.3054 12.0391L16.2622 14.9959C16.6212 15.3549 16.6212 15.9423 16.2622 16.3013C15.9032 16.6603 15.3158 16.6603 14.9568 16.3013L12 13.3445L9.04321 16.3013C8.68421 16.6603 8.09677 16.6603 7.73778 16.3013C7.37879 15.9423 7.37879 15.3549 7.73778 14.9959L10.6946 12.0391L7.73778 9.0823C7.37879 8.7233 7.37879 8.13586 7.73778 7.77687C8.09677 7.41788 8.68421 7.41788 9.04321 7.77687L12 10.7337L14.9568 7.77687C15.3158 7.41788 15.9032 7.41788 16.2622 7.77687C16.6212 8.13586 16.6212 8.7233 16.2622 9.0823L13.3054 12.0391Z" />
  </SvgIconStyled>
);

export default CloseCircle;
