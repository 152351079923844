// @flow
const currentDate = new Date().toISOString();

const workPlan = {
  title: 'Add New Risk Assessment Work Plan',
  component: 'myCompany/workPlan/CompanyWorkPlanModal',
  idRequired: true,
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Submit',
      icon: 'Done'
    },
    other: []
  },
  initialState: {
    name: '',
    year: currentDate,
    base64: null
  },
  invalidFormMessage: {
    name: "Please enter a Plan name",
    file: "Please attach a file"
  }
};

export default workPlan;
