import apiClient from '../../apiClient/apiClient';
import { getParams } from 'helpers/dataOptions';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/sra`;

export const getClientOrganizationProfile = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/OrganizationProfiles`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * This endpoint gets information for the main SRA page
 * such as the Available Assesments, Compliance Center Summary stats, and Reports sections
 */
export const getDashboardInfo = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${clientID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getOrganizationProfile = (clientID, tab) => {
  const requestOptions = {
    url: `${BASE_PATH}/organization_profile/${clientID}?tab=${tab}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setOrganizationProfile = (clientID, tabData, tab) => {
  const requestOptions = {
    url: `${BASE_PATH}/organization_profile/${clientID}`,
    headers: getHeaders(),
    data: { ...tabData, tab }
  };
  return apiClient.put(requestOptions);
};

export const getClientSraStatus = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/Sra/Status`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setClientOrganizationProfile = (clientID, clientOrganizationalProfile) => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/OrganizationProfiles`,
    data: { organization_profile: clientOrganizationalProfile },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const setClientSraStatus = (clientID, sraStatus) => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/Sra/Status`,
    headers: getHeaders(),
    data: sraStatus
  };
  return apiClient.post(requestOptions);
};

export const getClientSraQA = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/Sra/result`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getClientInfo = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/info`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const migrateClientSRA = clientID => {
  const requestOptions = {
    url: `${BASE_PATH}/quizzes/migrate/${clientID}`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const setClientSraQA = (clientID, clientSraQA) => {
  const requestOptions = {
    url: `${BASE_PATH}/Clients/${clientID}/Sra/result`,
    data: { questions: clientSraQA },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const getRevisionRecommendations = ({ revision }) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/recommendations/${revision}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const updateRevisionRecommendation = ({ revision, revision_recommendation_id, date_completed, notes }) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/recommendations/${revision}`,
    data: { revision_recommendation_id, date_completed, notes },
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const getWorkplanInfo = revision_id => {
  const requestOptions = {
    url: `${BASE_PATH}/workplan/information/${revision_id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getWorkplanRecommendations = (params, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/workplan/recommendations/${revision_id}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const createWorkplanRecommendation = (data, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/workplan/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const updateWorkplanRecommendation = (data, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/workplan/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const deleteWorkPlanRecommendation = (data, revision_id) => {
  // TODO: get the right endpoint for recommendation deletion
  const requestOptions = {
    url: `${BASE_PATH}/workplan/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.delete(requestOptions);
};

export const getRevisionId = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/revision/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getRecommendations = (params, client_id, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/recommendations/${revision_id}`,
    headers: getHeaders(),
    params: getParams({ ...params, keepInitialFilter: true })
  };
  return apiClient.get(requestOptions);
};

export const createRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const updateRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const deleteRecommendation = (data, client_id, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/recommendations/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.delete(requestOptions);
};

export const getSraReports = (params, client_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/risk-assessment-reports/`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const getSraReportDetails = (client_id, report_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/risk-assessment-reports/${report_id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const deleteSraReports = (client_id, report_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/risk-assessment-reports/${report_id}/`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const restoreSraReports = (client_id, report_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/restore-risk-assessment-reports/${report_id}/`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const updateSraReport = (client_id, report_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/risk-assessment-reports/${report_id}/`,
    headers: getHeaders(),
    data
  };
  return apiClient.patch(requestOptions);
};

export const getSraTaskList = client_id => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/task_list/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const retakeSra = (client_id, revision_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/retake/${revision_id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const completeSra = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/complete_sra/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const getSraThreats = (client_id, revision_id, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/threats/${revision_id}`,
    headers: getHeaders(),
    params
  }
  return apiClient.get(requestOptions);
};

export const getSRAQuizId = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/quizzes/sra-training/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setSraThreat = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/threats/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const deleteSraThreat = (client_id, revision_id, threat_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/${client_id}/threats/${revision_id}`,
    headers: getHeaders(),
    data: {
      excluded: 1,
      id: threat_id
    }
  };
  return apiClient.delete(requestOptions);
}

export const publishSraReport = (client_id, report_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/publish-sra-report/${report_id}/`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const setSraEditMode = (client_id, revision_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/dashboard/${client_id}/edit_mode/${revision_id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

/**
 * @param {string} revisionID for which to download the wrok plan report
 */
export const getWorkPlanReport = revisionID => {
  const requestOptions = {
    url: `${BASE_PATH}/reports/work-plan-report/${revisionID}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * @param {string} revisionID for which to download the executive summary report
 */
export const getExecutiveSummaryReport = revisionID => {
  const requestOptions = {
    url: `${BASE_PATH}/reports/executive-summary-report/${revisionID}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getDetailedReport = revisionID => {
  const requestOptions = {
    url: `${BASE_PATH}/reports/detailed-report/${revisionID}/`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const saveSraDocument = ( file_path ) => {
  return apiClient.post({
    url: `${BASE_PATH}/quizzes/save-sra-document`,
    headers: getHeaders(),
    data: { file_path }
  });
};
