// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Notes = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5942 0.943353V2.27514C18.9815 2.42312 20.0728 3.58844 20.0821 5.02197V21.2254C20.0821 22.7514 18.8335 24 17.3075 24H5.77457C4.24855 24 3 22.7514 3 21.2254V5.02197C3 3.59769 4.08207 2.43237 5.46935 2.27514V0.924855C5.46935 0.416185 5.88553 0 6.3942 0C6.90287 0 7.31906 0.416185 7.31906 0.924855V2.2474H10.6578V0.943353C10.6578 0.434682 11.074 0.0184971 11.5826 0.0184971C12.0913 0.0184971 12.5075 0.434682 12.5075 0.943353V2.2474H15.7445V0.943353C15.7445 0.434682 16.1607 0.0184971 16.6693 0.0184971C17.178 0.0184971 17.5942 0.434682 17.5942 0.943353ZM17.2982 22.1503C17.8069 22.1503 18.2231 21.7341 18.2231 21.2254V5.02197C18.2231 4.61503 17.9549 4.27283 17.5849 4.1526V5.43815C17.5849 5.94682 17.1687 6.36301 16.6601 6.36301C16.1514 6.36301 15.7352 5.94682 15.7352 5.43815V4.09711H12.4982V5.43815C12.4982 5.94682 12.082 6.36301 11.5734 6.36301C11.0647 6.36301 10.6485 5.94682 10.6485 5.43815V4.09711H7.3098V5.41965C7.3098 5.92832 6.89362 6.34451 6.38495 6.34451C5.87628 6.34451 5.46009 5.92832 5.46009 5.41965V4.16185C5.0994 4.28208 4.84045 4.61503 4.84045 5.02197V21.2254C4.84045 21.7341 5.25664 22.1503 5.76531 22.1503H17.2982ZM14.5698 10.1641C14.5698 10.6728 14.1537 11.089 13.645 11.089H9.41839C8.90972 11.089 8.49353 10.6728 8.49353 10.1641C8.49353 9.65547 8.90972 9.23929 9.41839 9.23929H13.645C14.1537 9.23929 14.5698 9.65547 14.5698 10.1641ZM14.0797 13.4659H9.85308C9.34441 13.4659 8.92822 13.8821 8.92822 14.3907C8.92822 14.8994 9.34441 15.3156 9.85308 15.3156H14.0797C14.5883 15.3156 15.0045 14.8994 15.0045 14.3907C15.0045 13.8821 14.5976 13.4659 14.0797 13.4659ZM9.85308 17.7202H14.0797C14.5976 17.7202 15.0045 18.1364 15.0045 18.6451C15.0045 19.1538 14.5883 19.57 14.0797 19.57H9.85308C9.34441 19.57 8.92822 19.1538 8.92822 18.6451C8.92822 18.1364 9.34441 17.7202 9.85308 17.7202Z"
    />
  </SvgIconStyled>
);

export default React.memo(Notes);