// @flow
import React, { type Element, type ComponentType, useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useNotify } from 'react-admin';
import styled, { css } from 'styled-components';
import { generateDropzoneAccept } from 'utils/generateDropzoneAccept';
import { Container } from '../types';
import { LazyIcon } from '../icons';
import { toBase64 } from 'helpers';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const DragZoneStyled: ComponentType<*> = styled(Box)`
  position: relative;
  border:  ${props => props.borderStyle ? props.borderStyle : '1px dashed var(--colorSystemInfo)'};
  border-radius: var(--borderRadius);
  width: 100%;
  outline: none;
  text-align: center;
  z-index: 2;
  padding: 40px 16px;
  ${({ error }) =>
    error &&
    css`
      border-color: var(--colorSystemDanger);
    `}
`;

const DragZoneContent: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 16px;
  z-index: 1;
  width: calc(100% - 32px);
  svg {
    font-size: 24px;
    color: var(--colorBase);
  }
`;

export const SmallBlueInfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: 11px;
  }
  color: var(--colorBase);
`;

export const IconContainer = styled.div`
  margin: 0 16px 0 8px;
`;

export const TextContainer = styled.div`
  max-width: calc(100% - 48px);
`;

export const InfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    color: var(--colorDefault);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
  padding: 0;
  margin: 0;
`;
export const DangerText = styled(Typography)`
  && {
    font-size: 1rem;
    color: var(--colorSystemDanger);
    margin: 0 0 0 14px;
  }
`;
export const TextColorBase: ComponentType<*> = styled(Typography)`
  && {
    font-size: 16px;
    color: var(--colorBase);
  }
  padding: 0;
  margin: 0;
`;

export const InfoTextTitle: ComponentType<*> = styled(Typography)`
  && {
    color: var(--colorBase);
    font-size: var(--fontSize);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;

const sanitizeFilename = file => {
  // only keep alphanumerics and dots in filenames

  const hasInvalidName = /[^a-z0-9.]/gi.test(file.name);
  if (!hasInvalidName) return file;

  const newFilename = file.name.replace(/[^a-z0-9.]/gi, '');

  // need to do it this way since file.name is read-only
  // https://stackoverflow.com/a/46327909
  const blob = file.slice(0, file.size, file.type);
  return new File([blob], newFilename, { type: file.type, lastModified: file.lastModified });
};

type DragZoneTypes = {
  title?: string,
  titleInside?: string,
  info?: string,
  hint?: string | boolean,
  icon?: string | Function,
  fileType: string,
  dispatch?: Function | null,
  size?: number,
  record: Object,
  disabled?: boolean,
  disabledClick?: boolean,
  type?: string,
  isRequired?: boolean,
  invalidErrorMessage: string,
};

const DragZone = ({
  title,
  titleInside,
  info,
  hint = false,
  icon,
  fileType,
  allowMultipleFiles,
  maxUploadSize = null,
  record,
  disabled,
  disabledClick = false,
  dispatch,
  type,
  size,
  isRequired,
  value,
  multiple = false,
  error: dragZoneError,
  helperText,
  borderStyle,
  invalidErrorMessage
}: DragZoneTypes): Element<*> => {
  const [error, setError] = useState(dragZoneError);
  const [errorMessage, setErrorMessage] = useState(helperText);

  const notify = useNotify();
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const recordFileName = record?.file && (typeof record.file === 'object' ? record.file.name : record.file);
  const infoText = record?.file && file ? file.name : recordFileName || info;

  useEffect(() => {
    setBase64(value);
    if (value) {
      setError(false);
      setErrorMessage('');
    }
  }, [value]);

  const handleInvalid = e => {
    e.preventDefault();
    setError(!e.target.validity.valid);
    setErrorMessage(invalidErrorMessage || e.target.validationMessage);
  };

  const onDropAccepted = useCallback(
    acceptedFiles => {
      const files = acceptedFiles.map(file => sanitizeFilename(file));
      const acceptedFile = files[0];

      const fileExtension = "." + acceptedFile.name.split('.').pop();
      const isNonSupportedFile = !fileType.split(', ').includes(fileExtension);
      if (isNonSupportedFile) {
        enqueueAlertSnackbar(`[${acceptedFile?.name}] Invalid file. Please use only [${fileType}] files`, {
          props: { severity: 'error' }
        })
        return 
      }

      const newFileObject = {};
      const fileObject = Object.assign(newFileObject, {
        lastModified: acceptedFile.lastModified,
        lastModifiedDate: acceptedFile.lastModifiedDate,
        name: acceptedFile.name,
        path: acceptedFile.path,
        size: acceptedFile.size,
        type: acceptedFile.type,
        webkitRelativePath: acceptedFile.webkitRelativePath
      });

      setFile(fileObject);

      switch (type) {
        case 'base64':
          toBase64(acceptedFile).then(res => {
            setBase64(res);
            if (dispatch) {
              dispatch(res, fileObject);
            }
            notify('File attached');
          });
          break;
        case 'file':
          if (dispatch) {
            dispatch(acceptedFile);
          }
          notify('File attached');
          break;
        case 'input':
          break;
        default:
          break;
      }
    },
    [dispatch, notify, type]
  );

  const onDropRejected = useCallback(rejectedFiles => {
    const files = rejectedFiles.map(file => ({ ...file, file: sanitizeFilename(file?.file) }));
    // we are only interested in the first rejected file
    const rejectedFile = files[0]?.file;
    let errorMsg = 'Failed to upload selected file';
    if(!fileType.split(', ').includes(rejectedFile.type)) {
      errorMsg = `[${rejectedFile?.name}] Invalid file. Please use only [${fileType}] files`;
    }
    else if (maxUploadSize && rejectedFile.size > maxUploadSize) {
      errorMsg = `File [${rejectedFile?.name}] exceeds maximum allowed file size (${maxUploadSize / 1000000} MB)`;
    }
    notify(errorMsg, 'error');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: generateDropzoneAccept(fileType), // e.g.: .csv, .png, .docx
    disabled,
    multiple: allowMultipleFiles,
    maxSize: maxUploadSize,
    onDropAccepted,
    onDropRejected
  });
  const inputProps = getInputProps();

  useEffect(() => {
    setError(dragZoneError);
    setErrorMessage(helperText);
  }, [dragZoneError, helperText]);

  return (
    <Container.Grid item direction="column" sm={12} xs={12} xl={12} md={12}>
      <Container.Grid direction="row" alignItems="center" mb={1} sm={12} xs={12} xl={12} md={12}>
        <InfoTextTitle>{title}</InfoTextTitle>
      </Container.Grid>
      <Box position="relative">
        <Container.Grid direction="row" alignItems="center" mb={1} sm={size} xs={size} xl={size} md={size}>
          <DragZoneStyled error={error} display="flex" alignItems="center" flexDirection="row" {...getRootProps()} borderStyle = { borderStyle }>
            <input type="file" id="file" {...inputProps} multiple />
            {type === 'base64' && (
              <textarea
                name="base64"
                required={isRequired}
                value={base64 || ''}
                style={{ display: 'none' }}
                onInvalid={handleInvalid}
              />
            )}
          </DragZoneStyled>
          <DragZoneContent>
            <IconContainer>
              <TextColorBase>{icon ? <LazyIcon component={icon} /> : <LazyIcon component="DragDrop" />}</TextColorBase>
            </IconContainer>
            <TextContainer>
              <TextColorBase>{titleInside}</TextColorBase>
              <InfoText>{infoText}</InfoText>
            </TextContainer>
          </DragZoneContent>
        </Container.Grid>
      </Box>
      <Container.Grid direction="row" alignItems="flex-start" sm={size} xs={size} xl={size} md={size}>
        {errorMessage ? (
          <DangerText>{errorMessage}</DangerText>
        ) : (
          <SmallBlueInfoText>
            {fileType !== '' ? `* Only ${fileType} files will be accepted` : '* All files will be accepted' || hint}
          </SmallBlueInfoText>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

DragZone.defaultProps = {
  title: 'Upload and files',
  titleInside: 'Attachment',
  info: <span>Drag & Drop your files or <span style={{ textDecoration: 'underline' }}>Browse</span></span>,
  hint: false,
  icon: null,
  size: 5,
  disabled: false,
  disabledClick: false,
  dispatch: null,
  type: null,
  isRequired: false,
  invalidErrorMessage: ''
};

export default DragZone;
