// @flow
import { ContentAdmin } from 'components/icons';
import ReportsList from './ReportsList';

export default {
  name: 'reports',
  list: ReportsList,
  icon: ContentAdmin,
  options: {
    label: 'Reports',
    hasMobileSublist: false
  }
};

