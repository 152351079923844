import React, { useCallback } from 'react';
import Controller from './Controller';
import { FormControlLabel } from '@material-ui/core';
import { SwitchStyle } from './ComponentTypes';
import { useFormContext } from 'react-hook-form';

const SwitchWrapper = ({ labelPlacement, label, ...props }) => {
  const onKeyDown = e => {
    if (e.keyCode === 32) e.preventDefault();
  };

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={label}
      control={
        <SwitchStyle
          {...props}
          color="green"
          onKeyDown={onKeyDown}
          classes={{
            root: `switchRoot`,
            switchBase: `switchBase`,
            thumb: `swichThumb`,
            checked: `switchChecked`,
            track: `switchTrack`,
            colorSecondary: `switchColorSecondary`,
            edgeEnd: `switchEndStyle`
          }}
        />
      }
    />
  );
};

const Switch = ({ name, value, checked, onChange = () => {}, ...props }) => {
  const formContextData = useFormContext();

  const handleChange = useCallback(
    e => {
      if (formContextData) {
        formContextData.setValue(name, e.target.checked);
        onChange(e);
      }
    },
    [onChange, formContextData?.setValue]
  );

  if (!formContextData) {
    return <SwitchWrapper {...props} onChange={onChange} checked={value || checked} />;
  }

  return (
    <Controller
      name={name}
      render={({ field }) => <SwitchWrapper {...field} {...props} onChange={handleChange} checked={field.value} />}
    />
  );
};

export default Switch;
