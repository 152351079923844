import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import TextField from './TextField';
import { Search } from '../icons';
import { useDebounce } from '../../hooks';

const TextFieldStyled = styled(TextField)`
  && {
    input {
      padding-left: 10px;
    }
  }
`;

const SearchFieldContainer = styled.div`
  width: 265px;
`;

const SearchField = ({ name, icon = <Search />, onChange = () => {}, placeholder = 'Search', ...props }) => {
  const [value, setValue] = useState('');

  const [searchDebounce] = useDebounce({ value: value, delay: 500 });

  useEffect(() => {
    onChange(searchDebounce.value);
  }, [searchDebounce.value]);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <SearchFieldContainer>
      <TextFieldStyled
        {...props}
        fullWidth
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        InputProps={{
          startAdornment: icon
        }}
      />
    </SearchFieldContainer>
  );
};

export default SearchField;
