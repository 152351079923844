// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Plus = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 19" role="img" aria-hidden="false">
    <path d="M17.3077 9.28563H9.69231V1.67024C9.69231 1.28947 9.38077 0.977936 9 0.977936C8.61923 0.977936 8.30769 1.28947 8.30769 1.67024V9.28563H0.692308C0.311538 9.28563 0 9.59717 0 9.97794C0 10.3587 0.311538 10.6702 0.692308 10.6702H8.30769V18.2856C8.30769 18.6664 8.61923 18.9779 9 18.9779C9.38077 18.9779 9.69231 18.6664 9.69231 18.2856V10.6702H17.3077C17.6885 10.6702 18 10.3587 18 9.97794C18 9.59717 17.6885 9.28563 17.3077 9.28563Z"/>
  </SvgIconStyled>
);

export default Plus;
