import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { Container, SVGbaseline, SVGpreemployment } from 'components';

export const PreassessmentLogoContainer = styled(Container.Grid)`
  height: 100%;
  background: linear-gradient(#3387ff, #0069ff);
  position: relative;
`;

export const ImgWrap = styled(Card)`
  padding: 20px 30px;
  box-shadow: none !important;
  position: absolute;
  top: 14.666%;
  left: 50%;
  transform: translateX(-50%);
`;

export const BaselineIlustration = styled(SVGbaseline)`
  && {
    width: 100%;
    height: 380px;
    position: absolute;
    bottom: 15.12%;
    left: 50px;
  }
`;
export const PreemploymentIlustration = styled(SVGpreemployment)`
  && {
    width: 100%;
    height: 100%;
  }
`;
// height: 380px;
// position: absolute;
// bottom: 15.12%;
// left: 50px;
