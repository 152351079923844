import React, { createContext, useContext } from 'react';

const LayoutContext = createContext({});

export const LayoutContextProvider = ({children, value}) => {
    return (
      <LayoutContext.Provider value={value}>
        {children}
      </LayoutContext.Provider>
    )
}

export const useLayoutContext = () => useContext(LayoutContext)
