import { useState, useReducer } from 'react';
import { useNotify } from 'react-admin';
import { isValidEmail, admin } from 'helpers';

const useAdminEmailSettings = (page) => {
  const initialState = {
    action: null, // 'configure' | 'sendEmail' | 'recall'
    openSettingsModal: false,
    confirmModal: {
      open: false,
      title: '',
      message: '',
    },
    data: null,
  };
  
  const notify = useNotify();
  const [state, setState] = useReducer(reducer, initialState);
  const [invalidEmails, setInvalidEmails] = useState(false);

  const closeConfirm = () => {
    setState({ type: 'RESET', payload: { ...initialState } });
  };

  const dispatch = {};

  dispatch.closeSettings = () => {
    setState({
      type: 'SET_SETTINGS_MODAL',
      payload: {  openSettingsModal: false },
    });
  };

  dispatch.openConfirm = () => {
    let title = "";
    let message = "";

    switch (state.action) {
      case 'configure': 
        title = 'Email Configuration Settings';
        message = 'This action will schedule the selected email to all applicable client. Are you sure you ready to schedule?';
        break;
      case 'sendEmail':
        title = 'Email Configuration Settings';
        message = 'Are you sure you want send to those recipients?';
        break;
      default:
        title = 'Recall';
        message = 'Recalling the selected email will remove it from the sending queue for all applicable clients. Are you sure you would like to recall this email?';
    }
    
    setState({
      type: 'SET_CONFIRM_MODAL',
      payload: { open: true, title, message },
    });
  };

  dispatch.openSettings = (action, id) => {
    const date = new Date();
    const today = date.toISOString().split('T')[0];
    date.setMinutes(date.getMinutes() + 30); // add 30 minutes
    const time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`; // hh:mm
    
    const newData = {};
    let openSettings = false;

    switch (action) {
      case 'configure':
        openSettings = true;
        newData.date = today;
        newData.email = [];
        newData.id = [id];
        newData.time = time;
        newData.type = 'send_email_to_recipients';
        newData.mass_email = 1;
        newData.send_at = `${newData.date} ${newData.time}:00`;
        break;
      case 'sendEmail':
        openSettings = true;
        newData.date = today;
        newData.email = [];
        newData.id = [id];
        newData.type = 'send_email_to_recipients';
        break;
      default:
        //newData.id = id;
        openSettings = false;
        newData.type = 'recall_email';
        dispatch.openConfirm();
    }
    
    setState({
      type: 'SET_SETTINGS_MODAL',
      payload: {
        action,
        data: { ...newData },
        openSettingsModal: openSettings,
      },
    });
  };

  dispatch.onChange = (name, value) => {
    const type = state.action === 'configure' ? 'SET_SCHEDULE': 'SET_DATA';
    setState({ type, payload: { name, value } });
  };

  dispatch.submitConfirm = () => {
    admin[page].configureEmail(state.data)
      .then((res) => notify(res.data?.status))
      .catch((err) => notify(err?.response?.data?.status, 'error'));
    
    closeConfirm();
  };

  dispatch.validateEmailAddresses = (name, value) => {
    let result = value.replace(/\s/g, '').split(/;/);
    if (result[0] === '') result = [];
    dispatch.onChange(name, result);
    const invalid = result.some(
      (email) => (email === '' && value !== '') || (!isValidEmail(email) && email !== '') || email.length > 199
    );

    setInvalidEmails(invalid);
  };

  dispatch.closeConfirm = () => closeConfirm();

  return {
    action: state.action,
    data: state.data,
    openSettings: state.openSettingsModal,
    confirmModalConfig: state.confirmModal,
    dispatch,
    invalidEmails,
  };
};

function reducer(state, { type, payload}) {
  switch (type) {
    case 'SET_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
        }
      };
    case 'SET_SCHEDULE':
      const send_at = payload.name === 'date'
        ? `${payload.value} ${state.data.time}:00` 
        : `${state.data.date} ${payload.value}:00`;
      
        return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
          send_at,
        }
      };
    case 'SET_CONFIRM_MODAL':
      return {
        ...state,
        openSettingsModal: false,
        confirmModal: payload,
      };
    case 'SET_SETTINGS_MODAL':
      return { ...state, ...payload };
    case 'RESET':
      return { ...payload };
  }
};

export default useAdminEmailSettings;