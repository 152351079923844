// @flow

const microTrainingUsers = {
  microTrainingsQuiz: {
    title: 'Micro Training Quizz',
    body: '',
    component: 'DashboardMicroTrainingModal',
    disableSubmit: true
  }
};

export default microTrainingUsers;
