import React, { useMemo } from 'react';
import { ReferralCodeInput, ReferralCodeSubmitButton, ReferralCodeSubmissionTitle } from './style';
import { useReferralCodeContext } from './ReferralCodeContext';
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom"

const ReferralCodeForm = () => {

  const { record, dispatch } = useReferralCodeContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch.onSubmit()
  }

  const _renderReferralCodeInout = useMemo(() => {
    return (
      <ReferralCodeInput
        value={record.referral_code}
        name="referral_code"
        placeholder={"Type the code"}
        label={"Type the code"}
        onChange={(e) => {
          const {name, value} = e.target;
          value ? dispatch.setDisabled(false) : dispatch.setDisabled(true)
          dispatch.onChange(name, value)
        }}
        fullWidth
        required
      />
    )
  }, [record.referral_code, dispatch.onChange, dispatch.setDisabled])

  const _renderReferralCodeSubmitButton = useMemo(() => {
    return (
      <ReferralCodeSubmitButton
        backgroundColor="colorSystemSuccess"
        backgroundColorHover="colorSystemSuccessHover"
        type="submit"
        fullwidth
        disabled={record.disabled}
      >
        Verify Code now
      </ReferralCodeSubmitButton>
    )
  }, [record.disabled])

  return (
    <form onSubmit={handleSubmit}>
      <ReferralCodeSubmissionTitle>Do you have a March Madness Referral Code?</ReferralCodeSubmissionTitle>
      <p>
        Enter the referral code you were provided with here. No code? No Problem! 
        You can close out of this window to start accessing your portal or&nbsp;
        <Link href="https://www.breachsecurenow.com/march-madness/" target='_blank'>click here</Link>&nbsp;
        for a breakdown of our March Referral Madness promotion and learn how you can enter!
      </p>
      { _renderReferralCodeInout }
      { _renderReferralCodeSubmitButton }
    </form>
  )
}

export default ReferralCodeForm
