import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, VideoModal } from 'components';
import { PlaySquareOutline } from 'components/icons';
import { CustomLinearProgress } from 'components/charts/CustomLinearProgress';
import { CardStyled, CardTitle, CardBody, CardImg, CardContent, CardFooter, CardActions, CardProgress } from './style';
import { getProgressColor } from '../../utils';
import { ButtonOutline, ButtonBaseFilled } from '../../style';

const AssessmentQuestionnaire = ({ task, isMarkedComplete }) => {
  const history = useHistory();
  const location = useLocation();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const {
    percent_complete,
    complete,
    is_a_retake,
    info: { description, name, video_url }
  } = task || { info: {} };
  const progress = parseFloat(percent_complete);

  const goToQuestionsPage = () => {
    sessionStorage.setItem('accessQuestionsSRA', 'true');
    const isUpdating = is_a_retake && !complete;
    history.push({
      pathname: `${location.pathname}/questions`,
      state: { isUpdating }
    });
  };

  return (
    <CardStyled>
      <CardTitle>{name}</CardTitle>

      <CardBody>
        <CardImg>
          <img src="/media/sra/assessment_questions.svg" alt="task" />
        </CardImg>

        <CardContent>
          <Typography.p fontSize={14} mt="0px" mb="0px">
            {description}
          </Typography.p>

          <CardFooter>
            <CardProgress>
              <CustomLinearProgress
                variant="determinate"
                backgroundColor={getProgressColor(progress)}
                height={5}
                value={progress}
              />
              <Typography.p fontSize={14} color="var(--colorLight)" mt={1.2} mb="0px">
                {progress}%
              </Typography.p>
            </CardProgress>

            <CardActions>
              <ButtonBaseFilled
                variant="outlined"
                startIcon={<PlaySquareOutline mr={1.2} size={1.3} />}
                disableElevation
                fullWidth
                onClick={() => setOpenVideoModal(true)}
              >
                Watch Video
              </ButtonBaseFilled>

              {!isMarkedComplete && (
                <ButtonOutline variant="outlined" disableElevation fullWidth onClick={goToQuestionsPage}>
                  Select
                </ButtonOutline>
              )}
            </CardActions>
          </CardFooter>
        </CardContent>
      </CardBody>

      <VideoModal
        open={openVideoModal}
        close={() => setOpenVideoModal(false)}
        title={name}
        video={video_url}
        // loading={loading}
      />
    </CardStyled>
  );
};

AssessmentQuestionnaire.propTypes = {
  task: PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, null]),
    name: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    percent_complete: PropTypes.string.isRequired
  }).isRequired
};

AssessmentQuestionnaire.defaultProps = {};

export default AssessmentQuestionnaire;
