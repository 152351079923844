// @flow

import React, { type Element, type Context, createContext, useContext, useReducer, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { LoadingStyled } from 'components';
import { useApp, dataProvider, isValidEmail } from 'helpers';
import directorySync from 'helpers/apis/directorySync';
import { useLocation } from 'hooks';
import { updateNestedMutable } from 'utils/update';
import { getStorage } from 'helpers/storage';
import apiClient from 'helpers/apiClient/apiClient';
import { BSN_SET_ANY } from 'conf';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

// AAD available sync types
const AAD_SYNC_TYPE_MULTITENANT = 'multitenant';
const AAD_SYNC_TYPE_APPID = 'appid';

type DirectorySyncContextState = {
  type: string | null,
  clientName: string | null,
  automatedWelcome: boolean | null,
  customWelcome: boolean | null,
  welMessFrequency: string | null,
  welMessHowMany: string | null,
  csvData: string | null,
  syncType: string | null,
  disableComponent: boolean | null,
  enableSyncType: boolean | null,
  disableSave: boolean | null,
  useAsPortalLogonValue: string | null,
  applicationId: string | null,
  azureAdIdentifier: string | null,
  certificateThumbprint: string,
  adminEmail: string | null,
  rocketCyberClientId: string,
  rocketCyberUrl: string,
  base64: string | null,
  file: Object | null,
  component: string | null,
  types: Array<*> | null,
  powerShellScript: string | null,
  directory_sync_message: boolean | null,
  message: string | null,
  enableManualSetup: boolean | null
};

type DirectorySyncContextSetState = {
  type: string,
  payload: any
};

type DirectorySyncContextType = {
  state: DirectorySyncContextState,
  dispatch: Object,
  verifyAADState: VerifyAADState,
  loading: boolean
};

type DirectorySyncProviderType = {
  children: Element<*> | null,
  record: Object
};

type VerifyAADGroups = {
  found: boolean,
  group: string
};
type VerifyAADState = {
  count: number,
  groups: Array<VerifyAADGroups>,
  last_successful_sync: string,
  latest_sync_date: string,
  message: string,
  status: number,
  sync_status: string
};

const DirectorySyncContext: Context<DirectorySyncContextType> = createContext({});

const useAsPortalLogon = [
  { value: 'UserPrincipalName', label: 'User Principal Name' }, // this value need to be Capital to match to the info on dataBase
  { value: 'mail', label: 'Email' } // this value need to be in lowercase to match to the info on dataBase
];

export const DirectorySyncProvider = ({ children, record, hideLoading,  ...props }: DirectorySyncProviderType): Element<*> => {
  const queryClient = useQueryClient();
  const { item } = useLocation('clientId');
  const dispatchRedux = useDispatch();
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  const app = 'clients';
  const tab = 'directorySync';

  const notify = useNotify();
  const { dispatch: dispatchApp } = useApp();
  const [loadingCSVTemplate, setLoadingCSVTemplate] = useState(false);
  const [state, setState] = useReducer<DirectorySyncContextState, DirectorySyncContextSetState>(reducer, {
    type: null,
    syncType: null,
    disableComponent: false,
    enableSyncType: null,
    disableSave: true,
    csvData: null,
    clientName: null,
    automatedWelcome: null,
    pending_reset: false,
    pending_save: false,
    automatedWelcome_loading: false,
    customWelcome: null,
    welMessFrequency: null,
    welMessHowMany: null,
    deferred_sending: null,
    useAsPortalLogonValue: null,
    applicationId: null,
    azureAdIdentifier: null,
    certificateThumbprint: null,
    adminEmail: null,
    rocketCyberClientId: null,
    rocketCyberUrl: null,
    base64: null,
    file: null,
    component: null,
    types: null,
    powerShellScript: null,
    directory_sync_message: null,
    message: null,
    enableManualSetup: false
  });
  const [verifyAADState, setVerifyAADState] = useState<VerifyAADState | null>(null);
  const [loading, setLoading] = useState(false);
  const [verifyAADFailed, setVerifyAADFailed] = useState(false);
  const [aadCredentialsVerified, setAAdCredentialsVerified] = useState(false);
  const baseURL = process.env.DIRECTORY_SYNC_BASE_URL;

  useEffect(() => {
    // control the enable and disable of all inputs
    if (state.enableSyncType === false) {
      setState({ type: '', payload: { disableComponent: true } });
    } else if (state.enableSyncType === true) {
      setState({ type: '', payload: { disableComponent: false } });
    }
  }, [state.enableSyncType]);

  function reducer(previousState, { type, payload, syncType }) {
    const defaultType = syncType || 'CsvBulkUpload';
    const defaultEnable = !!syncType;
    switch (type) {
      case 'SET_DATA': {
        return updateNestedMutable(previousState, payload);
      }
      case 'SETRECORD': {
        return {
          ...previousState,
          ...payload,
          type: defaultType[0].toLowerCase() + defaultType.slice(1),
          syncType: defaultType,
          enableSyncType: defaultEnable,
          component: `DirectorySync${defaultType}`
        };
      }
      case 'SETDATA': {
        const saveButton = getDisableSave({ ...previousState, [payload.name]: payload.value });
        return { ...previousState, [payload.name]: payload.value, disableSave: saveButton };
      }
      case 'SETFILE': {
        const saveButton = getDisableSave({ ...previousState, file: payload.value });
        return { ...previousState, file: payload.value, disableSave: saveButton };
      }
      default: {
        const saveButton = getDisableSave({ ...previousState, ...payload });
        return { ...previousState, ...payload, disableSave: saveButton };
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => queryClient.clear(app, tab, item), []);

  const setData = React.useCallback(data => {
    return {
      clientName: data ? data.name : '',
      automatedWelcome: data ? Boolean(data.automatedWelcome) : false,
      customWelcome: data ? Boolean(data.use_custom_message) : false,
      welMessFrequency: data ? data.welMessFrequency : '',
      welMessHowMany: data ? data.welMessHowMany : '',
      deferred_sending: data ? data.deferred_sending : '',
      useAsPortalLogonValue: (data && data.AAD_sync_email_field) || useAsPortalLogon[0].value,
      applicationId: data && data.mfa_application_id,
      azureAdIdentifier: data && data.AAD_identifier,
      certificateThumbprint: data && data.mfa_certificate_thumbprint,
      adminEmail: data && data.gsuite_admin_email,
      rocketCyberClientId: data?.AAD_identifier || data?.Guid,
      rocketCyberUrl: data && data.rocketCyberUrl,
      types: data && data.types,
      directory_sync_message: data && data.directory_sync_message,
      message: data && data.message,
      aadSyncAccount: data && data.AAD_sync_account,
      enableManualSetup: data && data.AAD_sync_account?.toLowerCase() === AAD_SYNC_TYPE_APPID
    };
  }, []);

  useEffect(() => {
    if (typeof record === 'undefined' || !record) {
      dataProvider.getOne(app, `${tab}/${item}`, {}).then(resp => {
        setState({
          type: 'SETRECORD',
          payload: setData(resp.data),
          syncType: resp?.data?.type
        });
      });
    } else {
      setState({
        type: 'SETRECORD',
        payload: setData(record),
        syncType: record.type
      });
    }
  }, [record, item, setData]);

  const dispatch = {};

  function getDisableSave(val: Object): boolean {
    switch (val.component) {
      case 'DirectorySyncCsvBulkUpload': {
        return !val.file;
      }
      case 'DirectorySyncAzureActiveDirectoryPowerShell': {
        return !(
          val.azureAdIdentifier &&
          val.applicationId &&
          val.certificateThumbprint &&
          val.useAsPortalLogonValue &&
          val.file
        );
      }
      case 'DirectorySyncGoogleGSuite': {
        return !(val.adminEmail && val.file);
      }
      case 'DirectorySyncOnPremiseActiveDirectory': {
        return !(val.rocketCyberClientId && val.rocketCyberUrl && val.useAsPortalLogonValue);
      }
      default:
        return false;
    }
  }

  dispatch.setState = setState;

  dispatch.onChange = ({ target: { name, value } }, type) => {
    setState({ type, payload: { name, value } });
  };

  dispatch.onSwitch = (name, checked) => {
    const toggle = name === 'automatedWelcome' ? 'sendAutomated' : 'customMessageToggle';
    const reduxKey = name === 'automatedWelcome' ? 'automatedWelcome' : 'use_custom_message';
    dispatchApp.set(app, 'directorySync', {
      name: state.clientName,
      automatedWelcome: state.automatedWelcome,
      use_custom_message: state.customWelcome,
      welMessFrequency: state.welMessFrequency,
      welMessHowMany: state.welMessHowMany,
      AAD_sync_email_field: state.useAsPortalLogonValue && state.useAsPortalLogonValue,
      mfa_application_id: state.applicationId && state.applicationId,
      AAD_identifier: state.azureAdIdentifier && state.azureAdIdentifier,
      mfa_certificate_thumbprint: state.certificateThumbprint && state.certificateThumbprint,
      gsuite_admin_email: state.adminEmail && state.adminEmail,
      rocketCyberUrl: state.rocketCyberUrl && state.rocketCyberUrl,
      [`${reduxKey}`]: checked
    });

    setState({ type: 'SETDATA', payload: { name: [`${name}_loading`], value: true } });
    setState({ type: 'SETDATA', payload: { name, value: checked } });
    dataProvider
      .post('clients', `${toggle}/${item}`, null, { [`${name}`]: checked })
      .then(res => {
        enqueueAlertSnackbar( res.data, { props: { severity: 'success' } });
      })
      .catch(error => {
        setState({ type: 'SETDATA', payload: { name, value: !checked } });
        notify(`${error?.response?.data?.description || 'Something went wrong'}`, 'error');
      })
      .finally(() => {
        setState({ type: 'SETDATA', payload: { name: [`${name}_loading`], value: false } });
      });
  };

  dispatch.onFileUpload = (base64, file) => {
    // path is undefined in case if file has special char
    setState({ type: '', payload: { base64, file: { ...file, path: file.name } } });
  };

  dispatch.setDisableSave = val => {
    setState({ type: 'SETDATA', payload: { name: 'disableSave', value: val } });
  };

  dispatch.handleBack = () => setState({ type: 'STEPPREVIOUS', payload: null });

  dispatch.onChangeSyncType = ({ target: { name, value } }) => {
    const typeLowerCase = value[0].toLowerCase() + value.slice(1);
    setState({
      type: '',
      payload: { syncType: value, component: `DirectorySync${value}`, type: typeLowerCase, file: null }
    });
  };

  dispatch.resetSettings = (onSuccess = () => {}) => {
    const requestHeaders = {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    };

    const requestOptions = {
      url: `${baseURL}reset-directory/${item}/`,
      data: { sync_type: state.syncType === 'GoogleGSuite' ? 'gsuite' : 'aad' },
      headers: requestHeaders
    };
    apiClient
      .post(requestOptions)
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        notify(`${error?.response?.data?.message || 'Soemthing went wrong'}`, 'error');
      });
  };

  dispatch.resetDirectory = (onSuccess = () => {}, onError = () => {}) => {
    const typeLowerCase = state.syncType[0].toLowerCase() + state.syncType.slice(1);
    // dispatchApp.set(app, tab, {
    //   ...state,
    //   type: 'CsvBulkUpload',
    //   syncType: 'CsvBulkUpload'
    // });
    setState({
      type: 'SET_DATA',
      payload: {
        pending_reset: true
      }
    });
    dataProvider
      .post(app, `${tab}/${item}`, null, { type: typeLowerCase, status: false })
      .then(res => {
        notify(res.data, 'info');
        setState({
          type: 'SET_DATA',
          payload: {
            enableSyncType: false,
            type: 'csvBulkUpload',
            syncType: 'CsvBulkUpload',
            component: 'DirectorySyncCsvBulkUpload',
            file: null
          }
        });
        onSuccess();
        // Update current state for client also when disabling the sync type
        // dataProvider.getOne(app, `${tab}/${item}`, {}).then(resp => {
        //   setState({
        //     type: 'SETRECORD',
        //     payload: setData(resp.data)
        //   });
        // });

        setAAdCredentialsVerified(false);
      })
      .catch(error => {
        notify(`${error?.response?.data?.description || 'Soemthing went wrong'}`, 'error');
        onError();
        setState({
          type: 'SET_DATA',
          payload: {
            enableSyncType: false
          }
        });
      })
      .finally(() => {
        setState({
          type: 'SET_DATA',
          payload: {
            pending_reset: false
          }
        });
      });
  };

  const addSpaceAfterCapitalLetters = inputString => {
    const spacedString = inputString.replace(/([A-Z])/g, ' $1');
    return spacedString.trim();
  };

  dispatch.onSwitchEnableSyncType = onDisable => {
    setState({ type: '', payload: { enableSyncType: !state.enableSyncType } });
    if (!state.enableSyncType) {
      notify(`${addSpaceAfterCapitalLetters(state.syncType)} has been enabled`, 'info');
    }
    if (state.enableSyncType && !state.disableComponent) {
      onDisable();
    }
  };

  dispatch.onSwitchEnableManualSetup = () => {
    setState({
      type: '',
      payload: {
        enableManualSetup: !state.enableManualSetup
      }
    });
  };

  dispatch.onClick = (type: string, comp: string) => {
    if (type === 'powerShell') {
      notify('Sending the certificate password to create a PowerShell Script', 'info');
      dataProvider
        .post(app, `${tab}/${item}`, null, {
          type: 'azureActiveDirectory'
        })
        .then(res => {
          setState({
            type: '',
            payload: {
              type: 'azureActiveDirectoryPowerShell',
              powerShellScript: res.data,
              component: comp
            }
          });
        });
    }
  };

  dispatch.downloadFile = (fileName, type = 'text/csv', varName) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    if (varName === 'csvData' && state.type === 'csvBulkUpload') {
      setLoadingCSVTemplate(true);
      dataProvider
        .getOne(app, `directorySync-csvBulkDownload/${item}`, {})
        .then(response => {
          dispatch.setState({ type: 'SETDATA', payload: { name: varName, value: response.data } });
          a.href = window.URL.createObjectURL(new Blob([response.data], { type }));
          a.setAttribute('download', fileName);
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        })
        .finally(() => setLoadingCSVTemplate(false));
    } else {
      // if (!state.powerShellScript || state.powerShellScript === 'undefined') {
      //   notify('It was not possible get the script.');
      // }

      dataProvider
        .post(app, `${tab}/${item}`, null, {
          type: type === 'text/plain' ? 'azureActiveDirectory' : 'onpremiseactivedirectorydownloadmsi'
        })
        .then(res => {
          a.href =
            type === 'text/plain' ? window.URL.createObjectURL(new Blob([state[varName]], { type })) : res.data.url;
          a.setAttribute('download', fileName);
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        });
    }
  };

  const handleBtnStatus = resp => {
    if (resp.status === 200) {
      dispatchRedux({
        type: BSN_SET_ANY,
        payload: {
          user: {
            profile: {
              shared: {
                buttonsStatus: { ...buttonsState, directory_sync_enabled: true }
              }
            }
          }
        }
      });
    }
  };

  dispatch.onCancel = () => {
    setState({ type: 'SET_DATA', payload: { file: null, pending_reset: false } });
  };

  dispatch.onSave = () => {
    if (state.syncType === 'GoogleGSuite' && !isValidEmail(state.adminEmail)) {
      notify('Please enter a valid email address', 'warning');
      return;
    }
    const typeFixed = state.type[0].toUpperCase() + state.type.slice(1);

    setState({ type: 'SET_DATA', payload: { pending_save: true } });
    dispatchApp.set(app, tab, {
      ...state,
      type: typeFixed,
      syncType: typeFixed,
      name: state.clientName,
      automatedWelcome: state.automatedWelcome,
      use_custom_message: state.customWelcome,
      welMessFrequency: state.welMessFrequency,
      welMessHowMany: state.welMessHowMany,
      AAD_sync_email_field: state.useAsPortalLogonValue && state.useAsPortalLogonValue,
      mfa_application_id: state.applicationId && state.applicationId,
      AAD_identifier: state.azureAdIdentifier && state.azureAdIdentifier,
      mfa_certificate_thumbprint: state.certificateThumbprint && state.certificateThumbprint,
      gsuite_admin_email: state.adminEmail && state.adminEmail,
      rocketCyberUrl: state.rocketCyberUrl && state.rocketCyberUrl
    });

    const sendData = getSendDate();
    dataProvider
      .post(app, `${tab}/${item}`, null, { ...sendData })
      .then(res => {
        notify(res.data, 'info');
        setState({ type: 'SET_DATA', payload: { enableSyncType: true } });
        handleBtnStatus(res);
        if (state.type === 'csvBulkUpload') {
          setState({ type: 'SETFILE', payload: { value: null } });
          dispatch.setState({ type: 'SETDATA', payload: { name: 'csvData', value: null } });
        } else if (state.type === 'azureActiveDirectoryPowerShell' || state.type === 'azureActiveDirectory') {
          // if the form saved successfully, validate the credentials
          dispatch.onVerify(true);
        }
      })
      .catch(error => {
        error.response?.data?.description && notify(`${error.response?.data?.description}`, 'error');
        setState({ type: 'SET_DATA', payload: { enableSyncType: false } });
        if (state.type === 'csvBulkUpload') {
          setState({ type: 'SETFILE', payload: { value: null } });
        } else if (state.type === 'azureActiveDirectoryPowerShell' || state.type === 'azureActiveDirectory') {
          setAAdCredentialsVerified(false);
        }
      })
      .finally(() => {
        setState({ type: 'SET_DATA', payload: { pending_save: false } });
      });

    queryClient.clear(app, tab, item);
  };

  dispatch.onVerify = (onlyVerify = false) => {
    // onlyVerify: if false verifies and changes state for groups and users count, else just verifying the credentials
    setLoading(true);
    directorySync
      .getVerification(item)
      .then(res => {
        if (!onlyVerify) {
          // change state for groups and users count notify the user
          setVerifyAADState(res?.data);
          setVerifyAADFailed(false);
          notify(res?.data?.message, 'info');
        } else {
          // just verify if the provided aad credentials are correct
          setAAdCredentialsVerified(true);
          dispatchRedux({
            type: BSN_SET_ANY,
            payload: {
              user: {
                profile: {
                  shared: {
                    buttonsStatus: { ...buttonsState, directory_sync_enabled: true }
                  }
                }
              }
            }
          });
        }
      })
      .catch(error => {
        setVerifyAADState(null);
        if (!onlyVerify) {
          // make state null, notify the user about wrong credentials
          notify(error?.response?.data?.description, 'info');
          setVerifyAADFailed(true);
        } else {
          // just set the credentials as not verified, notify user about it
          setAAdCredentialsVerified(false);
          notify('We could not verify your Azure Active Directory credentials.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  dispatch.onAzureSync = () => {
    directorySync
      .syncAzure(item)
      .then(res => {
        notify(res?.data?.description, 'info');
      })
      .catch(error => {
        notify(error?.response?.data?.description, 'info');
      });
  };

  dispatch.onRefreshDirectory = () => {
    directorySync
      .RefreshDirectory(item)
      .then(res => {
        notify(res?.data?.description, 'info');
      })
      .catch(error => {
        notify(error?.response?.data?.message, 'error');
      });
  };

  function getSendDate() {
    switch (state.syncType) {
      case 'CsvBulkUpload':
        return {
          type: state.type.toLowerCase(),
          automatedWelcome: state.automatedWelcome,
          welMessFrequency: state.welMessFrequency,
          welMessHowMany: state.welMessHowMany,
          customWelcome: state.customWelcome,
          welMessagePriorToPasswordReset: false,
          welMessagePostToPasswordReset: false,
          welBackMessagePriorToPasswordReset: false,
          welBackMessagePostToPasswordReset: false,
          file: state.file,
          base64: state.base64
        };
      case 'AzureActiveDirectory':
        return {
          type: state.type.toLowerCase(),
          customWelcome: state.customWelcome,
          welMessFrequency: state.welMessFrequency,
          welMessHowMany: state.welMessHowMany,
          automatedWelcome: state.automatedWelcome,
          welMessagePriorToPasswordReset: false,
          welMessagePostToPasswordReset: false,
          welBackMessagePriorToPasswordReset: false,
          welBackMessagePostToPasswordReset: false,
          azureAdIdentifier: state.azureAdIdentifier,
          applicationId: state.applicationId,
          certificateThumbprint: state.certificateThumbprint,
          useAsPortalLogonValue: state.useAsPortalLogonValue,
          file: state.file,
          base64: state.base64
        };
      case 'OnPremiseActiveDirectory':
        return {
          type: state.type.toLowerCase(),
          automatedWelcome: state.automatedWelcome,
          customWelcome: state.customWelcome,
          welMessFrequency: state.welMessFrequency,
          welMessHowMany: state.welMessHowMany,
          welMessagePriorToPasswordReset: false,
          welMessagePostToPasswordReset: false,
          welBackMessagePriorToPasswordReset: false,
          welBackMessagePostToPasswordReset: false,
          rocketCyberClientId: state.rocketCyberClientId,
          rocketCyberUrl: state.rocketCyberUrl,
          useAsPortalLogonValue: state.useAsPortalLogonValue
        };
      case 'GoogleGSuite':
        return {
          type: state.type.toLowerCase(),
          automatedWelcome: state.automatedWelcome,
          customWelcome: state.customWelcome,
          adminEmail: state.adminEmail,
          fileName: state.file.name,
          file: state.file,
          base64: state.base64
        };
      default:
        return state;
    }
  }

  if (state.clientName === null) {
    if (hideLoading) return null;
    return <LoadingStyled />;
  }
  
  return (
    <DirectorySyncContext.Provider
      value={{
        dispatch,
        state,
        verifyAADState,
        loading,
        verifyAADFailed,
        useAsPortalLogon,
        aadCredentialsVerified,
        AAD_SYNC_TYPE_MULTITENANT,
        AAD_SYNC_TYPE_APPID
      }}
    >
      {children}
    </DirectorySyncContext.Provider>
  );
};

export const useDirectorySync = () => useContext(DirectorySyncContext);
