import { format, isDate } from 'date-fns';
import { getOrdinalNumber } from 'helpers';

export const APPS = {
  admin: 'admin',
  partnerProfile: 'partnerProfile'
};

export const TYPES = {
  includeUser: 'includedUser',
  includePartner: 'includedPartner',
  excludeClient: 'excludedClient'
};

export const FREQUENCY_PERIOD = [
  { label: 'Weekly', value: 7 },
  { label: 'Bi-Weekly', value: 14 },
  { label: 'Monthly', value: 28 },
  { label: 'Quarterly', value: 84 }
];

export const WEEKDAYS = [
  { label: 'Sunday', value: 1 },
  { label: 'Monday', value: 2 },
  { label: 'Tuesday', value: 3 },
  { label: 'Wednesday', value: 4 },
  { label: 'Thursday', value: 5 },
  { label: 'Friday', value: 6 },
  { label: 'Saturday', value: 7 }
];

export const PN_SUBSCRIPTION_CUSTOM_MSG_LIMIT = 65000;

export const getFrequencyPeriodText = (frequencyDays, dayOfWeek, dayOfMonth, startSendingDate, nextSend) => {
  let longText = '';
  let shortText = '';

  if (frequencyDays) {
    const periodName = FREQUENCY_PERIOD.find(el => el.value === frequencyDays)?.label;

    if (dayOfWeek) {
      const weekName = WEEKDAYS.find(el => el.value === dayOfWeek)?.label;
      longText = `Sends ${periodName} on ${weekName}s`;
      shortText = `${periodName} on ${weekName}`;
    } else if (dayOfMonth) {
      if (periodName === 'Quarterly') {
        if (!nextSend) return // still loading nextSend after revert
        const nextSendDate = isDate(nextSend) ? nextSend : new Date(nextSend?.split(' ')?.[0]);
        const nextDateFormatted = format(nextSendDate, 'MMMM do, yyyy');
        const nextDateFormattedShort = format(nextSendDate, 'MMMM do');
        longText = `Sends ${periodName}, next sent on ${nextDateFormatted}`;
        shortText = `${periodName} next on ${nextDateFormattedShort}`;
      } else {
        if (dayOfMonth === 29 || dayOfMonth === 30) {
          longText = `Send the ${dayOfMonth}th or the last day of the month`;
        } else if (dayOfMonth === 31) {
          longText = `Send the last day of the month`;
        } else {
          longText = `Sends ${periodName} on the ${getOrdinalNumber(dayOfMonth)}`;
        }
        shortText = `${periodName} on the ${getOrdinalNumber(dayOfMonth)}`;
      }
    }
  }
  return { longText, shortText };
};

export const getFrequencyText = (frequency_days, day_of_week, day_of_month, next_send, running) => {
  if (day_of_week || day_of_month) {
    return running ? getFrequencyPeriodText(frequency_days, day_of_week, day_of_month, null, next_send)?.shortText : '';
  }

  // eslint-disable-next-line no-nested-ternary
  return frequency_days
    ? `Sends every ${frequency_days === 1 ? 'day' : `${frequency_days} days`}`
    : running
    ? 'N/A'
    : '';
};

export const dateToMonthDate = date => {
  const pickedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  return pickedDate.split('T')[0].slice(-5);
};

export const dateFromMonthDate = monthDate => {
  return parseDate(`${getCurrentYear()}-${monthDate}`);
};

export const parseDate = dateString => {
  // parses yyyy-mm-dd date string into a date object
  // we need to append T00:00:00 to yyyy-mm-dd so JS Date object parses correct day accross all time zones
  // check https://stackoverflow.com/a/7556634
  if (!dateString || typeof dateString !== 'string') return null;

  const match = dateString.match(/^(\d{4}-\d{2}-\d{2})/); // does it start with yyyy-mm-dd?
  if (!match) return null;
  const dateStr = match[0];

  return new Date(`${dateStr}T00:00:00`);
};

export const getCurrentYear = () => new Date().getFullYear();

export const validateNumber = ({ value, constraints }) => {
  const errorObj = {
    error:
      value === null ||
      value === '' ||
      (constraints.minimum !== null && value < constraints.minimum) ||
      (constraints.maximum !== null && value > constraints.maximum) ||
      (constraints.integer && !Number.isInteger(Number(value)))
  };
  errorObj.error_text = errorObj.error
    ? (constraints.integer ? 'Number must be integer.' : '') +
      (constraints.minimum !== null ? ` Number must be with a minimum value= ${constraints.minimum}.` : '') +
      (constraints.maximum !== null ? ` Number must be with a maximum value= ${constraints.maximum}.` : '')
    : '';
  return errorObj;
};
