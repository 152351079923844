import React from 'react';
import { Box } from '@material-ui/core';
import { Button, ButtonCancel, Container, Modal, SelectField, Typography } from 'components';
import { Done as DoneIcon, Info } from 'components/icons';

import PropTypes from 'prop-types';
/**
 *
 * @param {typeof ClientIndustryModal.defaultProps } props
 * @returns {JSX.Element}
 */
function ClientIndustryModal(props) {
  return (
    <Modal
      onClose={props.onClose}
      opened={props.opened}
      title="Industry"
      loadingContent={props.loading}
      dismissOutside
      overwriteStyles={{
        dialog: { maxWidth: '65rem', margin: '0 auto' },
      }}
      loadingSize={60}
      loadingSkeleton={{ width: '50rem', height: '25rem' }}
      data={
        <>
          <Container.Grid>
            <Box display="flex" flexDirection="column" width="100%" p={2.625}>
              <Typography.p fontSize={16} mt={0} mb={3.28125} bold={500}>
                Please select the industry for this client. This information will help us determine the direction of our
                products and content in the future.
              </Typography.p>
              <SelectField
                fullWidth
                allowEmpty={false}
                id="selectIdentityProviders"
                label="Industry"
                choices={props.choices} // Todo api should return clientIndustries as id name
                name="selectIdentityProviders"
                value={
                  // Todo update client Industry based on Api
                  props.selectedValue
                }
                onChange={props.onChange} // Todo SetClient Industry
                // required
              />{' '}
            </Box>
          </Container.Grid>
          <div style={{ height: '1px', background: 'var(--colorGreyLight3)' }} />
          <Container.Grid
            pl={2.5}
            pr={2.5}
            pb={2.5}
            pt={2.375}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justify="flex-end"
          >
            <ButtonCancel onClick={props.onClose}>Cancel</ButtonCancel>
            <Button
              backgroundColor="colorSystemSuccess"
              backgroundColorHover="colorSystemSuccessHover"
              backgroundDisabled="colorSystemSuccessDisable"
              height={36}
              pr={3}
              pl={3}
              onClick={() => {
                !props.pendingAction && props.onSave();
              }}
              disabled={!props.selectedValue || props.pendingAction}
            >
              <DoneIcon color="colorCommonWhite" />
              &nbsp;&nbsp;Save
            </Button>
          </Container.Grid>
        </>
      }
    />
  );
}

ClientIndustryModal.propTypes = {
  selectedValue: PropTypes.string,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  pendingAction: PropTypes.bool,
  choices: PropTypes.oneOfType([PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })]),
  onChange: PropTypes.func,
  loading: PropTypes.bool
};

ClientIndustryModal.defaultProps = {
  selectedValue: '',
  opened: false,
  loading: false,
  pendingAction: false,
  onClose: () => {},
  onSave: () => {},
  choices: [],
  onChange: () => {}
};

export default React.memo(ClientIndustryModal);
