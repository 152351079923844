// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Redo = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.7206 7.01473L23.0423 6.33645L19.9853 3.27943C19.6127 2.90686 19.0013 2.90686 18.6287 3.27943C18.2561 3.65201 18.2561 4.26341 18.6287 4.63598L20.9501 6.9574C19.6413 6.94785 17.4823 6.90964 15.3138 6.86188C12.7822 6.81411 10.2219 6.76634 8.92268 6.76634C4.00278 6.76634 0 10.0526 0 14.0936C0 18.1537 4.00278 21.4496 8.92268 21.4496C9.18061 21.4496 9.49586 21.4496 9.85888 21.4591H9.86845C10.3939 21.4591 10.8142 21.0388 10.8238 20.5134C10.8333 19.988 10.4034 19.5581 9.87799 19.5485C9.50542 19.5485 9.19016 19.5389 8.92268 19.5389C5.05363 19.5389 1.91064 17.0933 1.91064 14.0936C1.91064 11.1035 5.05363 8.67698 8.92268 8.67698C10.2219 8.67698 12.7631 8.72475 15.2756 8.77251C17.2435 8.81073 19.1828 8.84894 20.5202 8.85849L18.6287 10.75C18.2561 11.1226 18.2561 11.734 18.6287 12.1066C18.8198 12.2881 19.0586 12.3836 19.307 12.3836C19.5554 12.3836 19.7942 12.2881 19.9853 12.1066L23.0423 9.04955L23.7206 8.37128C24.0931 7.99871 24.0931 7.39686 23.7206 7.01473Z" />
  </SvgIconStyled>
);

export default Redo;
