// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Success = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path d="M10 20C4.48462 20 0 15.5154 0 10C0 4.48462 4.48462 0 10 0C15.5154 0 20 4.48462 20 10C20 15.5154 15.5154 20 10 20ZM10 1.53846C5.33077 1.53846 1.53846 5.33077 1.53846 10C1.53846 14.6692 5.33077 18.4615 10 18.4615C14.6692 18.4615 18.4615 14.6692 18.4615 10C18.4615 5.33077 14.6615 1.53846 10 1.53846Z" />
    <path d="M14.8154 7.53843C14.5154 7.23843 14.0308 7.23843 13.7308 7.53843L8.1077 13.1615L6.27693 11.3307C5.97693 11.0307 5.49229 11.0307 5.19229 11.3307C4.89229 11.6307 4.89229 12.1154 5.19229 12.4154L7.55383 14.7769C7.63075 14.8538 7.72308 14.9077 7.81539 14.9461C7.91539 14.9846 8.01536 15.0154 8.12305 15.0154C8.32305 15.0154 8.51538 14.9384 8.66922 14.7923L14.8308 8.63074C15.1154 8.32305 15.1154 7.83843 14.8154 7.53843Z" />
  </SvgIconStyled>
);

export default Success;
