import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@trustsecurenow/components-library';

const PRODUCT_TYPE = {
  BBP: 'Breach Prevention Platform (BPP)',
  HIPAA_BP: 'HIPAA BPP'
};

const PRODUCT_TYPE_CONFIG = {
  [PRODUCT_TYPE.BBP]: {
    text: 'BPP',
    tooltipTitle: 'Breach Prevention Platform'
  },
  [PRODUCT_TYPE.HIPAA_BP]: {
    text: 'HIPAA BPP',
    tooltipTitle: 'HIPAA Breach Prevention Platform'
  }
};

const ProductTypeCell = ({ productType }) => {
  const { text = '', tooltipTitle = '' } = PRODUCT_TYPE_CONFIG[productType] || {};

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Typography whiteSpace="break-spaces" variant="body2">{text || productType}</Typography>
    </Tooltip>
  );
};

ProductTypeCell.propTypes = {
  productType: PropTypes.string.isRequired
};

export default ProductTypeCell;
