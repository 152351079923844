// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Monitor = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M21.2136 2H2.78638C1.25387 2 0 3.25387 0 4.78638V14.966C0 16.4985 1.25387 17.7523 2.78638 17.7523H11.0713V20.0929H6.91017C6.39933 20.0929 5.98138 20.5109 5.98138 21.0217C5.98138 21.5325 6.39933 21.9505 6.91017 21.9505H11.8235C11.8793 21.9598 11.935 21.9876 12.0001 21.9876C12.0651 21.9876 12.1207 21.9598 12.1765 21.9505H17.0898C17.6007 21.9505 18.0186 21.5325 18.0186 21.0217C18.0186 20.5109 17.6007 20.0929 17.0898 20.0929H12.9289V17.7523H21.2136C22.7461 17.7523 24 16.4985 24 14.966V4.78638C24 3.25387 22.7554 2 21.2136 2ZM22.1424 14.966C22.1424 15.4768 21.7245 15.8948 21.2136 15.8948H2.78638C2.27554 15.8948 1.85759 15.4768 1.85759 14.966V4.78638C1.85759 4.27554 2.27554 3.85759 2.78638 3.85759H21.2229C21.7338 3.85759 22.1517 4.27554 22.1517 4.78638V14.966H22.1424Z" />
  </SvgIconStyled>
);

export default React.memo(Monitor);
