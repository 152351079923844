// @flow

const customMessage = {
  title: 'Customize message',
  component: 'shared/directorySync/DirectorySyncCustomMessageModal',
  paramKey: 'message_type',
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Publish',
      icon: 'Done'
    },
    other: [
      {
        type: 'customMessageDraft',
        label: 'Save draft',
        icon: 'Save'
      }
    ]
  },
  initialState: null
};

export default customMessage;
