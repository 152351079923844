import React from 'react';
import { Controller as RHFController, useFormContext } from 'react-hook-form';
import { resolveObj } from 'helpers';

const Controller = React.memo(({ required, rules: controllerRules, render, name, ...props }) => {
  const { control, setValue } = useFormContext();

  const rules = React.useMemo(() => {
    return {
      required: required ? (typeof required === 'boolean' ? 'This field is required' : required) : undefined,
      onBlur: e => {
        const { value } = e.target;
        const newValue = typeof value === 'string' ? value.trim() : value;
        setValue(name, newValue);
      },
      ...(controllerRules ?? {})
    };
  }, [controllerRules, name, required]);
  
  return (
    <RHFController
      {...props}
      control={control}
      name={name}
      rules={rules}
      render={({ formState, ...params }) => {
        const { errors } = formState;
        const error = resolveObj(name, errors);
        return render({ formState, ...params }, error);
      }}
    />
  );
});

export default Controller;
