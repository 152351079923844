// @flow
import React, { type Element } from 'react';
import { DashboardProvider } from './DashboardContext';
import DashboardComplianceContent from './DashboardComplianceContent';

const DashboardCompliance = (props: Object): Element<*> => {
  return (
    <DashboardProvider {...props}>
      <DashboardComplianceContent {...props} />
    </DashboardProvider>
  );
};

export default DashboardCompliance;
