import React, { useCallback, Suspense } from 'react';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { enqueueAlertSnackbar, LinearProgress } from '@trustsecurenow/components-library';
import clientsAPI from 'helpers/apis/clients';
import { addPluralS, dataPost } from 'helpers';
import { BULK_ACTIONS, MODALS } from './constants';

function Modals({
  modalsState,
  setModalState,
  resetModal,
  industryModalOptions,
  refetchTable,
  refetchIndustryFilterOptions,
  partnerId,
  updateRowSelection
}) {
  const handleClientActivate = useCallback(() => {
    const { clientId, selectedClientIds } = modalsState[MODALS.ACTIVATE_CLIENT];
    setModalState(MODALS.ACTIVATE_CLIENT, { submitting: true });
    const handleActivateAction = !selectedClientIds
      ? bsnclientservices.activateDeactivateClient(clientId, { type: 'activate' })
      : bsnclientservices.handleClientBulkAction(partnerId, {
          data: { action_type: 'enable', client_ids: selectedClientIds }
        });

    handleActivateAction
      .then(res => {
        updateRowSelection(selectedClientIds ? selectedClientIds : [clientId], BULK_ACTIONS.ENABLE_CLIENT);
        return res;
      })
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(
          !selectedClientIds
            ? res.statusText || res.data.message || 'Success'
            : `${res.data.updated_clients_count} client(s) enabled`,
          {
            props: { severity: 'success' }
          }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.ACTIVATE_CLIENT);
      });
  }, [partnerId, modalsState[MODALS.ACTIVATE_CLIENT]]);

  const handleDisableClient = useCallback(() => {
    const { selectedClientIds } = modalsState[MODALS.BULK_DISABLE_CLIENT];
    setModalState(MODALS.BULK_DISABLE_CLIENT, { submitting: true });
    bsnclientservices
      .handleClientBulkAction(partnerId, { data: { action_type: 'disable', client_ids: selectedClientIds } })
      .then(res => {
        updateRowSelection(selectedClientIds, BULK_ACTIONS.DISABLE_CLIENT);
        refetchTable();
        enqueueAlertSnackbar(
          `${res.data.updated_clients_count} ${addPluralS('client', res.data.updated_clients_count)} disabled`,
          {
            props: { severity: 'success' }
          }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.BULK_DISABLE_CLIENT);
      });
  }, [partnerId, modalsState[MODALS.BULK_DISABLE_CLIENT]]);

  const handleBulkEnableWelcomeMessage = useCallback(() => {
    const { selectedClientIds } = modalsState[MODALS.BULK_ENABLE_WELCOME_MESSAGE];
    setModalState(MODALS.BULK_ENABLE_WELCOME_MESSAGE, { submitting: true });
    bsnclientservices
      .handleClientBulkAction(partnerId, {
        data: { action_type: 'enable_welcome_message', client_ids: selectedClientIds }
      })
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(`Welcome messages enabled for ${res.data.updated_clients_count} client(s)`, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.BULK_ENABLE_WELCOME_MESSAGE);
      });
  }, [partnerId, modalsState[MODALS.BULK_ENABLE_WELCOME_MESSAGE]]);

  const handleBulkEnableMicroTraining = useCallback(() => {
    const { selectedClientIds } = modalsState[MODALS.BULK_ENABLE_MICRO_TRAINING];
    setModalState(MODALS.BULK_ENABLE_MICRO_TRAINING, { submitting: true });
    bsnclientservices
      .handleClientBulkAction(partnerId, {
        data: { action_type: 'enable_newsletter', client_ids: selectedClientIds }
      })
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(
          `Micro Training and Newsletter emails enabled for ${res.data.updated_clients_count} ${addPluralS(
            'client',
            res.data.updated_clients_count
          )}`,
          {
            props: { severity: 'success' }
          }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.BULK_ENABLE_MICRO_TRAINING);
      });
  }, [partnerId, modalsState[MODALS.BULK_ENABLE_MICRO_TRAINING]]);

  const handleClientActivate2 = useCallback(
    systemEmailsEnabled => {
      const { clientId } = modalsState[MODALS.ACTIVATE_CLIENT2];
      const submittingFlag = systemEmailsEnabled ? 'submittingWithSystemEmailsEnabled' : 'submitting';
      setModalState(MODALS.ACTIVATE_CLIENT2, { [submittingFlag]: true });
      bsnclientservices
        .applyClientAction(clientId, 'activate', systemEmailsEnabled)
        .then(res => {
          updateRowSelection([clientId], BULK_ACTIONS.ENABLE_CLIENT);
          refetchTable();
          enqueueAlertSnackbar(res.data.message, {
            props: { severity: 'success' }
          });
        })
        .catch(err => {
          enqueueAlertSnackbar(`${err}`, {
            props: { severity: 'error' }
          });
        })
        .finally(() => {
          resetModal(MODALS.ACTIVATE_CLIENT2);
        });
    },
    [modalsState[MODALS.ACTIVATE_CLIENT2]]
  );

  const handleClientDeactivate = useCallback(() => {
    const { clientId } = modalsState[MODALS.DEACTIVATE_CLIENT];
    setModalState(MODALS.DEACTIVATE_CLIENT, { submitting: true });
    bsnclientservices
      .applyClientAction(clientId, 'deactivate')
      .then(res => {
        updateRowSelection([clientId], BULK_ACTIONS.DISABLE_CLIENT);
        refetchTable();
        enqueueAlertSnackbar(res.data.message, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.DEACTIVATE_CLIENT);
      });
  }, [modalsState[MODALS.DEACTIVATE_CLIENT]]);

  const handleClientUndelete = useCallback(() => {
    const { clientId } = modalsState[MODALS.UNDELETE_CLIENT];
    setModalState(MODALS.UNDELETE_CLIENT, { submitting: true });
    bsnclientservices
      .applyClientAction(clientId, 'undelete')
      .then(res => {
        updateRowSelection([clientId], BULK_ACTIONS.ENABLE_CLIENT);
        refetchTable();
        enqueueAlertSnackbar(res.data.message, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.UNDELETE_CLIENT);
      });
  }, [modalsState[MODALS.UNDELETE_CLIENT]]);

  const handleSystemEmailsEnable = useCallback(() => {
    const { clientId } = modalsState[MODALS.ENABLE_SYSTEM_EMAILS];
    setModalState(MODALS.ENABLE_SYSTEM_EMAILS, { submitting: true });
    // TODO: refactor this to use apiClient instead of dataPost
    const app = 'clients';
    const tab = 'actions';
    const data = {
      type: 'system_emails'
    };
    dataPost({ app, tab, item: clientId, params: {}, data })
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(res.data.message || res.data.description, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(err?.response?.description?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.ENABLE_SYSTEM_EMAILS);
      });
  }, [modalsState[MODALS.ENABLE_SYSTEM_EMAILS]]);

  const handleClientDelete = useCallback(() => {
    const { clientId, selectedClientIds } = modalsState[MODALS.DELETE_CLIENT];
    setModalState(MODALS.DELETE_CLIENT, { submitting: true });
    const deleteFunction = !selectedClientIds
      ? bsnclientservices.deleteClient(clientId)
      : bsnclientservices.deleteBulkClient(partnerId, { client_ids: selectedClientIds });

    deleteFunction
      .then(res => {
        updateRowSelection(selectedClientIds ? selectedClientIds : [clientId], BULK_ACTIONS.DELETE_CLIENT);
        return res;
      })
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(
          !selectedClientIds
            ? res.statusText || res.data.message || 'Success'
            : `${res?.data?.deleted_clients_count} ${addPluralS('client', res?.data?.deleted_clients_count)} deleted`,
          {
            props: { severity: 'success' }
          }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.DELETE_CLIENT);
      });
  }, [partnerId, modalsState[MODALS.DELETE_CLIENT]]);

  const handleIndustrySave = useCallback(() => {
    const { selectedIndustryId, clientId } = modalsState[MODALS.SELECT_INDUSTRY];
    setModalState(MODALS.SELECT_INDUSTRY, { submitting: true });
    clientsAPI
      .setIndustryToClient(clientId, {
        industry_id: selectedIndustryId
      })
      .then(r => {
        refetchTable();
        refetchIndustryFilterOptions();
        enqueueAlertSnackbar('Successfully added Industry', {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar('Failed to add Industry', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.SELECT_INDUSTRY);
      });
  }, [modalsState[MODALS.SELECT_INDUSTRY]]);

  const handleIndustryChange = useCallback(e => {
    setModalState(MODALS.SELECT_INDUSTRY, { selectedIndustryId: e.target.value });
  }, []);

  const handleSendWelcomeMessage = useCallback(() => {
    const {
      welcomeMessageType,
      clientId,
      selectedUsersCount,
      filteredUserIds,
      parentModalName,
      selectedClientIds
    } = modalsState[MODALS.SEND_WELCOME_MESSAGE];

    const id = filteredUserIds ? clientId : partnerId;
    const actionParams = filteredUserIds
      ? {
          type: 'sendWelcomeMessages',
          ids: filteredUserIds
        }
      : {
          client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
          action_type: 'send_welcome_messages',
          welcome_messages_type: welcomeMessageType
        };

    setModalState(MODALS.SEND_WELCOME_MESSAGE, { submitting: true });

    const apiCall = filteredUserIds
      ? usersAPI.postClientUsersActions(id, actionParams)
      : bsnclientservices.manageClientActions(id, actionParams);

    apiCall
      .then(r => {
        if (parentModalName === MODALS.MICRO_TRAINING) {
          setModalState(MODALS.MICRO_TRAINING, { welcomeMessageSuccess: true });
        }
        refetchTable();
        enqueueAlertSnackbar(`Welcome Messages sent to ${selectedUsersCount} user(s)`, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar('Failed to send Welcome Messages', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.SEND_WELCOME_MESSAGE);
      });
  }, [partnerId, modalsState[MODALS.SEND_WELCOME_MESSAGE]]);

  const handleWelcomeMessageTypeChange = useCallback(
    e => {
      const type = e.target.value;
      const { clientId, selectedClientIds } = modalsState[MODALS.SEND_WELCOME_MESSAGE];
      setModalState(MODALS.SEND_WELCOME_MESSAGE, {
        isLoadingUsersCount: true,
        welcomeMessageType: type,
        filteredUserIds: null
      });
      bsnclientservices
        .manageClientActions(partnerId, {
          client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
          action_type: 'welcome_message_users',
          welcome_messages_type: type
        })
        .then(res => {
          setModalState(MODALS.SEND_WELCOME_MESSAGE, {
            isLoadingUsersCount: false,
            selectedUsersCount: res.data.welcome_message_users_count
          });
        })
        .catch(error => {
          console.error(error);
          setModalState(MODALS.SEND_WELCOME_MESSAGE, { isLoadingUsersCount: false });
        });
    },
    [partnerId, modalsState[MODALS.SEND_WELCOME_MESSAGE]]
  );

  const handleGetWelcomeMessageActiveUsers = useCallback(() => {
      const { clientId, selectedClientIds } = modalsState[MODALS.SEND_WELCOME_MESSAGE];
      setModalState(MODALS.SEND_WELCOME_MESSAGE, {
        isLoadingActiveUsersCount: true
      });
      bsnclientservices
        .manageClientActions(partnerId, {
          client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
          action_type: 'welcome_message_users',
          welcome_messages_type: 'users_not_received'
        })
        .then(res => {
          setModalState(MODALS.SEND_WELCOME_MESSAGE, {
            activeUsersCount: res.data.active_users_count,
            isLoadingActiveUsersCount: false,
          });
        })
        .catch(error => {
          console.error(error);
          setModalState(MODALS.SEND_WELCOME_MESSAGE, { isLoadingActiveUsersCount: false });
        });
    },
    [partnerId, modalsState[MODALS.SEND_WELCOME_MESSAGE]]
  );

  const handleWelcomeMessageViewUsersClick = useCallback(() => {
    const { clientId, welcomeMessageType, clientName, selectedClientIds } = modalsState[MODALS.SEND_WELCOME_MESSAGE];
    setModalState(MODALS.SEND_WELCOME_MESSAGE, { viewUsersSubmitting: true });
    bsnclientservices
      .manageClientActions(partnerId, {
        client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
        action_type: 'welcome_message_users',
        welcome_messages_type: welcomeMessageType,
        get_users_list: true
      })
      .then(res => {
        setModalState(MODALS.VIEW_USERS, {
          parentModalName: MODALS.SEND_WELCOME_MESSAGE,
          open: true,
          usersList: res.data.users_data,
          clientName,
          selectedClientIds
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setModalState(MODALS.SEND_WELCOME_MESSAGE, { viewUsersSubmitting: false });
      });
  }, [partnerId, modalsState[MODALS.SEND_WELCOME_MESSAGE]]);

  const handleSaveSelectedUsers = useCallback(
    filteredUserIds => {
      const { parentModalName } = modalsState[MODALS.VIEW_USERS];
      setModalState(parentModalName, { filteredUserIds, selectedUsersCount: filteredUserIds.length });
      resetModal(MODALS.VIEW_USERS);
    },
    [modalsState[MODALS.VIEW_USERS]]
  );

  const handleEnablePositiveOptIn = useCallback(() => {
    const { clientId, parentModalName, selectedClientIds } = modalsState[MODALS.POSITIVE_OPTIN];
    setModalState(MODALS.POSITIVE_OPTIN, { submitting: true });
    const handleOptInAction = !selectedClientIds
      ? bsnclientservices.manageClientActions(partnerId, { action_type: 'enable_positive_opt_in', client_ids: [clientId] })
      : bsnclientservices.handleClientBulkAction(partnerId, {
          data: { action_type: 'enable_positive_opt_in', client_ids: selectedClientIds }
        });

    handleOptInAction
      .then(res => {
        refetchTable();
        if (parentModalName === MODALS.MICRO_TRAINING) {
          setModalState(MODALS.MICRO_TRAINING, { positiveOptinSuccess: true });
        }
        enqueueAlertSnackbar(
          !selectedClientIds
            ? res.statusText || res.data.message || 'Positve Opt-in has been enabled'
            : `Positive Opt-in Enabled for ${res.data.updated_clients_count} ${addPluralS(
                'client',
                res.data.updated_clients_count
              )}`,
          {
            props: { severity: 'success' }
          }
        );
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.POSITIVE_OPTIN);
      });
  }, [partnerId, modalsState[MODALS.POSITIVE_OPTIN]]);

  const handleBouncedEmailViewUsersClick = useCallback(() => {
    const { clientId, clientName, selectedClientIds } = modalsState[MODALS.BOUNCED_EMAILS];
    setModalState(MODALS.BOUNCED_EMAILS, { viewUsersSubmitting: true });
    bsnclientservices
      .manageClientActions(partnerId, {
        client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
        action_type: 'bounced_emails_users',
        get_users_list: true
      })
      .then(response => {
        setModalState(MODALS.VIEW_USERS, {
          parentModalName: MODALS.BOUNCED_EMAILS,
          open: true,
          usersList: response.data.users_data,
          clientName,
          selectedClientIds
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        setModalState(MODALS.BOUNCED_EMAILS, { viewUsersSubmitting: false });
      });
  }, [partnerId, modalsState[MODALS.BOUNCED_EMAILS]]);

  const handleRemoveBouncedEmails = useCallback(() => {
    const { clientId, filteredUserIds, parentModalName, selectedClientIds } = modalsState[MODALS.BOUNCED_EMAILS];

    const id = filteredUserIds ? clientId : partnerId;
    const actionParams = filteredUserIds
      ? {
          type: 'removeBounceEmail',
          ids: filteredUserIds
        }
      : {
          client_ids: !selectedClientIds ? [clientId] : selectedClientIds,
          action_type: 'clear_bounced_emails'
        };

    setModalState(MODALS.BOUNCED_EMAILS, { submitting: true });

    const apiCall = filteredUserIds
    ? usersAPI.postClientUsersActions(id, actionParams)
    : bsnclientservices.manageClientActions(id, actionParams);

    apiCall
      .then(res => {
        if (parentModalName === MODALS.MICRO_TRAINING) {
          setModalState(MODALS.MICRO_TRAINING, { bouncedEmailSuccess: true });
        }
        refetchTable();
        enqueueAlertSnackbar(res.statusText || res.data.message || `Bounced Emails have been cleared for ${!selectedClientIds ? '1' : selectedClientIds?.length } user(s)`, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar('Failed to remove bounced emails', {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.BOUNCED_EMAILS);
      });
  }, [partnerId, modalsState[MODALS.BOUNCED_EMAILS]]);

  const loadMTNLModal = useCallback(() => {
    const clientId = modalsState[MODALS.MICRO_TRAINING].clientId;
    bsnclientservices.getMTNLData(clientId).then(res => {
      setModalState(MODALS.MICRO_TRAINING, {
        loadingModal: false,
        bouncedEmailUsersCount: res.data.bounced_emails_users_count,
        isClientMTNLDisabled: res.data.is_client_mt_newsletter_disabled,
        mtnlDisabledUsersCount: res.data.users_disabled_email_count,
        mtnlReceivedUsersCount: res.data.received_mt_nl_users_count,
        noWelcomeMessageUsersCount: res.data.users_not_received_wm_count
      });
    });
  }, [modalsState[MODALS.MICRO_TRAINING]]);

  const handleMTNLEnablePositiveOptin = useCallback(() => {
    const { clientId, clientName } = modalsState[MODALS.MICRO_TRAINING];
    setModalState(MODALS.POSITIVE_OPTIN, {
      open: true,
      clientId,
      clientName,
      parentModalName: MODALS.MICRO_TRAINING
    });
  }, [modalsState[MODALS.MICRO_TRAINING]]);

  const handleMTNLConfigBouncedEmails = useCallback(() => {
    const { clientId, clientName, totalUsersCount, bouncedEmailPercent, bouncedEmailUsersCount } = modalsState[MODALS.MICRO_TRAINING];

    setModalState(MODALS.BOUNCED_EMAILS, {
      open: true,
      clientId,
      clientName,
      bouncedEmailPercent,
      totalUsersCount,
      selectedUsersCount: bouncedEmailUsersCount,
      parentModalName: MODALS.MICRO_TRAINING
    });
  }, [modalsState[MODALS.MICRO_TRAINING]]);

  const handleMTNLConfigWelcomeMessage = useCallback(() => {
    const { clientId, clientName, totalUsersCount, noWelcomeMessageUsersCount } = modalsState[MODALS.MICRO_TRAINING];

    setModalState(MODALS.SEND_WELCOME_MESSAGE, {
      open: true,
      clientId,
      clientName,
      usersWithNoWelcomeMessage: noWelcomeMessageUsersCount,
      totalUsersCount,
      selectedUsersCount: 0,
      parentModalName: MODALS.MICRO_TRAINING
    });
  }, [modalsState[MODALS.MICRO_TRAINING]]);

  const handleMicroTrainingEnabled = useCallback(() => {
    const { clientId, parentModalName } = modalsState[MODALS.MICRO_TRAINING_ENABLED];
    setModalState(MODALS.MICRO_TRAINING_ENABLED, { submitting: true });
    bsnclientservices
      .enableNewsLetters(clientId, {
        send_newsletter: true
      })
      .then(res => {
        refetchTable();
        if (parentModalName === MODALS.MICRO_TRAINING) {
          setModalState(MODALS.MICRO_TRAINING, { mtnlSuccess: true });
        }
        enqueueAlertSnackbar(res.statusText || res.data.message || 'Success', {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err?.data?.description || err.message || 'Failed'}`, {
          props: { severity: 'error' }
        });
      })
      .finally(() => {
        resetModal(MODALS.MICRO_TRAINING_ENABLED);
      });
  }, [modalsState[MODALS.MICRO_TRAINING_ENABLED]]);

  const handleMTNLEnableMTNL = useCallback(() => {
    const { clientId, mtnlDisabledUsersCount } = modalsState[MODALS.MICRO_TRAINING];

    setModalState(MODALS.MICRO_TRAINING_ENABLED, {
      open: true,
      clientId,
      usersWithNoMtNl: mtnlDisabledUsersCount,
      parentModalName: MODALS.MICRO_TRAINING
    });
  }, [modalsState[MODALS.MICRO_TRAINING]]);

  const handleEnableWelcomeMessage = useCallback(() => {
    const { clientId } = modalsState[MODALS.ENABLE_WELCOME_MESSAGE];
    setModalState(MODALS.ENABLE_WELCOME_MESSAGE, { submitting: true });

    bsnclientservices
      .enableDisableWelcomeMessage(clientId, { automatedWelcome: true } )
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar('Welcome Message Enabled. New Users will receive welcome messages by default.', {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err.response.data.description}`, { props: { severity: 'error' } });
      })
      .finally(() => {
        resetModal(MODALS.ENABLE_WELCOME_MESSAGE);
      });
  }, [modalsState[MODALS.ENABLE_WELCOME_MESSAGE]]);

  const handleDisableWelcomeMessage = useCallback(() => {
    const { clientId } = modalsState[MODALS.DISABLE_WELCOME_MESSAGE];
    setModalState(MODALS.DISABLE_WELCOME_MESSAGE, { submitting: true });
    bsnclientservices
      .enableDisableWelcomeMessage(clientId, { automatedWelcome: false } )
      .then(res => {
        refetchTable();
        enqueueAlertSnackbar(res.data, {
          props: { severity: 'success' }
        });
      })
      .catch(err => {
        enqueueAlertSnackbar(`${err.response.data.description}`, { props: { severity: 'error' } });
      })
      .finally(() => {
        resetModal(MODALS.DISABLE_WELCOME_MESSAGE);
      });
  }, [modalsState[MODALS.DISABLE_WELCOME_MESSAGE]]);

  const handleTeamsAppDeploymentInstructions = useCallback(async () => {
    const { clientId, isHSN } = modalsState[MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS];
    setModalState(MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS, { loading: true });
    try {
      await bsnclientservices.sendTeamsAppAnnouncement(clientId);
      resetModal(MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS);
      setModalState(MODALS.TEAMS_APP_USER_NOTIFIED_DIALOG, {
        open: true,
        titleText: "Users Notified",
        isHSN: isHSN
      });
    } catch(error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal(MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS);
    }
  }, [modalsState[MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS]]);

  const handleSendTeamsAppEngagementReminder = useCallback(async (filteredUserIds) => {
    const { clientId, isHSN } = modalsState[MODALS.TEAMS_APP_ENGAGEMENT_REMINDER];
    setModalState(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER, { loading: true });
    try {
      await bsnclientservices.sendTeamsAppAnnouncement(clientId, filteredUserIds);
      resetModal(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER);
      setModalState(MODALS.TEAMS_APP_USER_NOTIFIED_DIALOG, {
        open: true,
        titleText: "Reminder Was Sent",
        isHSN: isHSN
      });
    } catch(error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER);
    }

  }, [modalsState[MODALS.TEAMS_APP_ENGAGEMENT_REMINDER]]);

  const getTeamsAppUnregisteredUsers = useCallback(async () => {
    const { clientId, isHSN } = modalsState[MODALS.TEAMS_APP_ENGAGEMENT_REMINDER];
    try {
      const unregisteredUsers = await bsnclientservices.getTeamsAppUnregisteredUsers(clientId);
      setModalState(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER, {
        loading: false,
        usersList: unregisteredUsers?.data?.users,
        isHSN: isHSN
      });
    }
    catch(error) {
      enqueueAlertSnackbar(`${error}`, { props: { severity: 'error' } });
      resetModal(MODALS.TEAMS_APP_ENGAGEMENT_REMINDER);
    }
  }, [partnerId, modalsState[MODALS.TEAMS_APP_ENGAGEMENT_REMINDER]]);

  const modals = [
    {
      modalName: MODALS.ACTIVATE_CLIENT,
      onSubmit: handleClientActivate
    },
    {
      modalName: MODALS.ACTIVATE_CLIENT2,
      onSubmit: handleClientActivate2
    },
    {
      modalName: MODALS.DEACTIVATE_CLIENT,
      onSubmit: handleClientDeactivate
    },
    {
      modalName: MODALS.DELETE_CLIENT,
      onSubmit: handleClientDelete
    },
    {
      modalName: MODALS.ENABLE_SYSTEM_EMAILS,
      onSubmit: handleSystemEmailsEnable
    },
    {
      modalName: MODALS.SELECT_INDUSTRY,
      onSubmit: handleIndustrySave,
      modalProps: {
        industryOptions: industryModalOptions,
        handleIndustryChange
      }
    },
    {
      modalName: MODALS.SEND_WELCOME_MESSAGE,
      onSubmit: handleSendWelcomeMessage,
      modalProps: {
        handleWelcomeMessageTypeChange,
        handleWelcomeMessageViewUsersClick,
        handleGetWelcomeMessageActiveUsers
      }
    },
    {
      modalName: MODALS.VIEW_USERS,
      onSubmit: handleSaveSelectedUsers
    },
    {
      modalName: MODALS.POSITIVE_OPTIN,
      onSubmit: handleEnablePositiveOptIn
    },
    {
      modalName: MODALS.BOUNCED_EMAILS,
      onSubmit: handleRemoveBouncedEmails,
      modalProps: {
        handleBouncedEmailViewUsersClick
      }
    },
    {
      modalName: MODALS.MICRO_TRAINING,
      modalProps: {
        loadMTNLModal,
        handleMTNLConfigBouncedEmails,
        handleMTNLConfigWelcomeMessage,
        handleMTNLEnableMTNL,
        handleMTNLEnablePositiveOptin
      }
    },
    {
      modalName: MODALS.MICRO_TRAINING_ENABLED,
      onSubmit: handleMicroTrainingEnabled
    },
    {
      modalName: MODALS.BULK_DISABLE_CLIENT,
      onSubmit: handleDisableClient
    },
    {
      modalName: MODALS.BULK_ENABLE_WELCOME_MESSAGE,
      onSubmit: handleBulkEnableWelcomeMessage
    },
    {
      modalName: MODALS.BULK_ENABLE_MICRO_TRAINING,
      onSubmit: handleBulkEnableMicroTraining
    },
    {
      modalName: MODALS.ENABLE_WELCOME_MESSAGE,
      onSubmit: handleEnableWelcomeMessage
    },
    {
      modalName: MODALS.DISABLE_WELCOME_MESSAGE,
      onSubmit: handleDisableWelcomeMessage
    },
    {
      modalName: MODALS.UNDELETE_CLIENT,
      onSubmit: handleClientUndelete
    },
    {
      modalName: MODALS.TEAMS_APP_DEPLOYMENT_INSTRUCTIONS,
      onSubmit: handleTeamsAppDeploymentInstructions
    },
    {
      modalName: MODALS.TEAMS_APP_ENGAGEMENT_REMINDER,
      onSubmit: handleSendTeamsAppEngagementReminder,
      modalProps: {
        getTeamsAppUnregisteredUsers
      }
    },
    {
      modalName: MODALS.TEAMS_APP_USER_NOTIFIED_DIALOG,
    },
  ];

  return modals.map(({ modalName, modalProps = {}, onSubmit }) => {
    const ModalComponent = React.lazy(() => import(`../Modals/${modalName}`));
    return (
      modalsState[modalName].open && (
        <Suspense fallback={<LinearProgress shaded />}>
          <ModalComponent
            key={modalName}
            state={modalsState[modalName]}
            setState={state => setModalState(modalName, state)}
            resetModal={() => resetModal(modalName)}
            onSubmit={onSubmit}
            {...modalProps}
          />
        </Suspense>
      )
    );
  });
}

export default React.memo(Modals);
