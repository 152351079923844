import React from 'react';
import { AlertDialog, Typography } from '@trustsecurenow/components-library';

function LeavePageDialog({ openDialog, onCloseDialog, onSubmitDialog }) {

    return (
        <AlertDialog
            open={openDialog}
            dialogTitle="Leave without saving?"
            message={<Typography variant="subtitle1"> Are you sure you want to leave the page without saving? Any unsaved information will be deleted</Typography>}
            SubmitButtonProps={{
                color: "primary",
                children: "Leave Page",
                onClick: onSubmitDialog,
            }}
            onClose={onCloseDialog}
        />
    );
}

export default LeavePageDialog;