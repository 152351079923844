import React, { useEffect, useState, useMemo } from 'react';
import { Button, Container, LazyIcon, TableList, TextField } from 'components';
import { isValidDomain, isValidEmail } from 'helpers';
import { useSelector } from 'react-redux';
import dwAPI from 'helpers/apis/darkWeb';
import styled from 'styled-components';

const tableOptions = {
  refetchOnWindowFocus: false
};

const StyledTextField = styled(TextField)`
  width: 320px;
  .Mui-error {
    width: 320px;
  }
`;

const DarkWeb = () => {
  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');
  const [validation, setValidation] = useState({ isValid: false, error: '' });
  const [prohibitedDomains, setProhibitedDomains] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableSettings = useSelector(state => state.bsn.tables['admin']['darkWeb']);

  useEffect(() => {
    dwAPI
      .getProhibitedDomains()
      .then(res => {
        setProhibitedDomains(res?.data?.blacklisted_domains);
      })
      .catch(err => console.error(err));
  }, []);

  const validate = value => {
    setValidation(() => {
      // if (!value) return { isValid: false, error: '' };
      if (isValidEmail(value)) return { isValid: true, error: '' };
      else if (isValidDomain(value)) {
        const isProhibited = prohibitedDomains.includes(value) || prohibitedDomains.includes(value.toLowerCase()) ;
        return !isProhibited
          ? { isValid: true, error: '' }
          : { isValid: false, error: 'The FQDN entered is on the Blacklisted domains list and cannot be used' };
      }
      // else if (value.length > 50) return 'Exceed in 50 Characters required fields';
      else return { isValid: false, error: '' };
    });
  };

  const handleValueChange = e => {
    const value = e.target.value;
    setValue(value);
    validate(value);
  };

  const handleQueryNow = () => {
    setQuery(value);
  };

  const table = useMemo(
    () => (
      <TableList
        tableSettings={tableSettings}
        extraParams={{ query }}
        showSpinnerOnFetching={true}
        setLoading={setLoading}
        tableOptions={tableOptions}
        hideColumns={['remediated']}
      />
    ),
    [tableSettings, query]
  );

  return (
    <>
      <Container.Paper px={2} mt={1} mb={1} alignItems="center">
        <Container.Grid mt={1} mb={1} container style={{ width: '100%' }}>
          <Container.Grid item pr={1} direction="column">
            <StyledTextField
              margin="dense"
              size="small"
              value={value}
              name="domain"
              label="Check"
              onChange={handleValueChange}
              error={!!validation.error}
              helperText={validation.error}
            />
          </Container.Grid>
          <Container.Grid item pl={1} pt={1}>
            <Button
              backgroundColor="colorSystemInfo"
              backgroundColorHover="colorSystemInfoHover"
              backgroundDisabled="colorSystemSuccessDisable"
              height={40}
              pr={3}
              pl={3}
              disabled={!validation.isValid || loading}
              onClick={handleQueryNow}
            >
              <LazyIcon component="MessageQuestion" strokeColor="colorCommonWhite" color="info" mr={1} size={1.5} />
              Query Now
            </Button>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
      {table}
    </>
  );
};

export default DarkWeb;
