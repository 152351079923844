import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { VideoPlayer } from 'components';
import { useRecommendations } from '../context/recommendationsContext';

const useStyles = makeStyles(theme => ({
  box: {
    background: 'var(--backgroundPaper)',
    borderRadius: 5,
    padding: 20
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    color: 'var(--colorSystemInfo)',
    margin: '0 0 15px'
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 14,
    margin: '0 0 5px'
  },
  text: {
    fontSize: 14,
    margin: '0 0 20px',
    '&:last-child': {
      marginBottom: 0
    }
  },
  videoTitle: {
    fontWeight: 700,
    fontSize: 18,
    margin: '0 0 20px'
  },
  video: {
    borderRadius: 5,
    overflow: 'hidden',
    '& video': {
      display: 'block'
    }
  }
}));

const AboutWorkPlan = ({ aboutVideo }) => {
  const classes = useStyles();
  const { isHSN } = useRecommendations();

  return (
    <>
      <Box className={classes.box} mb={2.5}>
        <p className={classes.title}>About Your Work Plan</p>
        <p className={classes.text}>Click on a recommendation on the left table to view the recommendation.</p>
        <p className={classes.subTitle}>Completing recommendation</p>
        <p className={classes.text}>
          Once a recommendation is complete, the corresponding questions will be updated from No to Yes. If the
          recommendation requires a policy, a template is provided and you are able to upload the policy right into the
          recommendation. The policy will be stored in your portal.
        </p>
        <p className={classes.subTitle}>Section</p>
        <p className={classes.text}>
          The recommendations are broken into 3 categories: Administrative, Physical and Technical.
        </p>

        {isHSN && (
          <>
            <p className={classes.subTitle}>Priority</p>
            <p className={classes.text}>
              Required: These recommendations must be completed in order to maintain HIPAA Compliance <br />
              Addressable: Just because these recommendations aren’t required, doesn’t mean they do not need to be
              completed. If you do not complete an addressable recommendation, you need a good reason for not doing so.
              This can be documented in the notes.
            </p>
          </>
        )}

        <p className={classes.subTitle}>Uploading a policy</p>
        <p className={classes.text}>
          Recommendations that have a template, will allow you to download it, customize it and then upload it into your
          account.
        </p>
      </Box>

      <div className={classes.box}>
        <p className={classes.videoTitle}>Watch the video to learn all about the work plan</p>
        <div className={classes.video}>
          <VideoPlayer
            src={`https://videodelivery.net/${aboutVideo}/manifest/video.m3u8?clientBandwidthHint=4.9`}
            controls
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </>
  );
};

export default AboutWorkPlan;
