import React from 'react';
import { PreassessmentButton, PreassessmentEmailStepTitle, PreassessmentInputField } from '../../style';
import cognito from '../../../../helpers/apis/cognito';
import { usePreassessmentContext } from '../../contexts/PreassessmentContext';
import { Grid, TextField } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { Typography } from '@trustsecurenow/components-library';

const PreassessmentUserInfo = () => {
  const notify = useNotify();

  const {
    type,
    assessment_id,
    client_id,
    user: { first_name, last_name, email, job_title: position, job_title_id },
    dispatch
  } = usePreassessmentContext();

  const fields = [
    { name: 'first_name', label: 'First Name', value: first_name },
    { name: 'last_name', label: 'Last Name', value: last_name },
    { name: 'email', label: 'Email', value: email },
    { name: 'position', label: 'Position', value: position }
  ];

  const handleSubmit = async () => {
    const body = {
      client_id,
      email,
      first_name,
      last_name,
      job_title_id
    };
    const queryParams = {
      preassessment_id: assessment_id,
      preassessment_type: type
    };
    cognito
      .registerExternalUser({ body, queryParams })
      .then(res => {
        const { code, message } = res?.data || {};

        switch (res.status) {
          case 204:
            // email was registered
            dispatch.setDisabled(false);
            dispatch.changeStep(2);
            break;
          case 200:
            // email was not registered
            // are we registering him from this form and letting him to continue??
            notify('Email is not registered', 'error');
            break;
          default:
            notify(message || 'Something went wrong', 'error');
            break;
        }
      })
      .catch(err => {
        notify('Something went wrong', 'error');
        console.log('err', err);
      });
  };

  return (
    <>
      <PreassessmentEmailStepTitle sx={{ mb: 1 }}>Let’s Get Started</PreassessmentEmailStepTitle>
      <Typography variant="body2" sx={{ mb: 2 }}>
      Is this your information below? If so, click ‘Next.’ If not, please reach out to your point of contact or support team.
      </Typography>
      <Grid container spacing={2}>
        {fields.map(field => (
          <Grid item key={field.name} xs={12} sm={6}>
            <TextField {...field} variant="outlined" size="small" fullWidth disabled />
          </Grid>
        ))}
        <Grid item xs={12} sm={3} justifyContent={'flex-end'}>
          <PreassessmentButton fullwidth onClick={handleSubmit}>
            Next
          </PreassessmentButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PreassessmentUserInfo;
