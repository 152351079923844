// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { LinkifiedText } from '../common';
import { FeedContent, FeedNewsletter, FeedMicrotraining } from './index';

const useStyles = makeStyles(theme => ({
  textContent: {
    '& pre': {
      border: 'none',
      background: 'none',
      padding: 0,
      margin: 0,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontFamily: 'var(--fontFamily)',
      fontSize: '1.6rem',
      lineHeight: '1.5',
      color: 'inherit'
    }
  }
}));

const FeedViewMode = (props) => {
  const { allowTruncate, text, content, fileIsReady, postId, currPlayingVideoPostId, setCurrPlayingVideoPostId, newsletter, microtraining, updateScrollPosition } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.textContent}>
        <pre>
          <LinkifiedText text={text} allowTruncate={allowTruncate} />
        </pre>
      </div>
      {newsletter && <FeedNewsletter newsletter={newsletter} />}
      {microtraining && <FeedMicrotraining microtraining={microtraining} postId={postId} updateScrollPosition={updateScrollPosition} />}
      {/* Image, Video, File, Thumbnail */}
      {content && (
        <FeedContent
          content={content}
          fileIsReady={fileIsReady}
          postId={postId}
          currPlayingVideoPostId={currPlayingVideoPostId}
          setCurrPlayingVideoPostId={setCurrPlayingVideoPostId}
        />
      )}
    </>
  );
};

export default FeedViewMode;
