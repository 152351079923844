import {
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_DOMAINS,
  CONTENT_ADMIN_PICKLIST_TAGS,
  CONTENT_ADMIN_SCENARIO,
  CONTENT_ADMIN_SENDING_PROFILES
} from '../constants';
import contentAdmin from '../../../helpers/apis/contentAdmin';
import { getPickListLabel, strToBase64 } from 'helpers';

export const getEmailTemplateDefaultValues = {
  name: '',
  email_subject: '',
  email_html: '',
  is_published: false,
  logo: '',
  attachments: []
};

export const getLandingPagesDefaultValues = {
  name: '',
  redirect_uri: '',
  html: '',
  capture_submitted_data: false
};

export const getSendingProfilesDefaultValues = {
  host: '',
  port: '',
  user: '',
  password: '',
  smtp_headers: [{ header_name: '', header_value: '' }]
};

export const getScenariosDefaultValues = {
  name: '',
  difficulty_level_id: '',
  country_id: '',
  landing_page_id: null,
  template_id: null,
  is_hipaa: false,
  scenario_tags: [],
  email_local_part: '',
  phishing_domain_id: null,
  from_name: '',
  is_published: false
};

export const getPickListArr = page => {
  switch (page) {
    case CONTENT_ADMIN_SCENARIO:
      return [
        // contentAdmin.getPickList(CONTENT_ADMIN_PICKLIST_DIFFICULTIES),
        contentAdmin.getPickList(CONTENT_ADMIN_PICKLIST_COUNTRIES),
        contentAdmin.getPickList(CONTENT_ADMIN_PICKLIST_TAGS),
        contentAdmin.getList(CONTENT_ADMIN_PICKLIST_DOMAINS)
      ];
    default:
      return [];
  }
};

export const getEmailTemplateData = record => {
  return {
    email_templates: {
      name: record.name,
      email_subject: record.email_subject,
      email_html: strToBase64(record.email_html),
      logo: record.logo || ''
    },
    attachments: record?.attachmentKeys?.map(filename => ({ filename })) || []
  };
};

export const getLandingPagesData = record => {
  const htmlValue = record.html || `<html></html>`;
  return {
    name: record.name,
    html: strToBase64(htmlValue),
    redirect_uri: record.redirect_uri,
    capture_submitted_data: record.capture_submitted_data || false
  };
};

export const getSendingProfilesData = record => {
  return {
    smtp: {
      host: record.host,
      port: record.port,
      user: record.user,
      password: record.password
    },
    smtp_headers: record.smtp_headers
  };
};

export const getScenarioData = record => {
  return {
    name: record.name || '',
    difficulty_level_id: record.difficulty_level_id || '',
    country_id: record.country_id || '',
    landing_page_id: record.landing_page_id?.id || '',
    template_id: record.template_id?.id || '',
    is_hipaa: record.is_hipaa || false,
    scenario_tags: record.scenario_tags?.map(tag => (typeof tag === 'string' ? tag : tag.label)) || [],
    email_local_part: record.email_local_part || '',
    phishing_domain_id: record.phishing_domain_id?.value,
    from_name: record.from_name || '',
    is_published: record.is_published || false
  };
};

export const getButtonName = pagePath => {
  switch (pagePath) {
    case CONTENT_ADMIN_EMAIL_TEMPLATES:
      return 'Template';
    case CONTENT_ADMIN_LANDING_PAGES:
      return 'Landing Page';
    case CONTENT_ADMIN_SCENARIO:
      return 'Scenario';
    case CONTENT_ADMIN_SENDING_PROFILES:
      return 'Sending Profile';
    default:
      return '';
  }
};

export const setEmailTemplateData = value => {
  const { name, email_subject, is_published, email_html, logo, logo_name, logo_path } = value.template;

  return {
    attachments: value.attachments?.map(attachment => ({ ...attachment, isNew: false })) || [],
    name: name || '',
    email_subject: email_subject || '',
    email_html: email_html || '',
    logo: {
      preview: logo || '',
      fileName: logo_name || '',
      path: logo_path || '',
      isNew: false
    }
  };
};

export const setLandingPagesData = value => {
  return {
    name: value.name,
    redirect_uri: value.redirect_uri,
    html: value.html,
    capture_submitted_data: value.capture_submitted_data
  };
};

export const setSendingProfilesData = value => {
  const { headers, smtp } = value;
  const { host, port, user, password, smtp_headers } = getSendingProfilesDefaultValues;
  return {
    host: smtp.host || host,
    port: smtp.port || port,
    user: smtp.user || user,
    password: smtp.password || password,
    smtp_headers: headers.length ? headers : smtp_headers
  };
};

export const setScenarioData = value => {
  const {
    name,
    difficulty_level_id,
    landing_page,
    tags,
    template,
    country_id,
    is_hipaa,
    email_local_part,
    phishing_domain_id,
    from_name,
    is_published
  } = value;

  return {
    name,
    difficulty_level_id,
    country_id,
    is_hipaa,
    landing_page_id: landing_page,
    scenario_tags: tags.map(({ tag, id }) => ({ label: tag, value: id })),
    template_id: template,
    email_local_part,
    phishing_domain_id,
    from_name,
    is_published: is_published || ''
  };
};

export const getPickLists = pickListData => {
  const pickLists = {};
  pickListData.forEach(list => {
    const listNameMatch = list.request.responseURL.match(/\/[a-z-]+\/$/)[0];
    const listName = listNameMatch.slice(1, listNameMatch.length - 1);
    const data = list.data.result || list.data;
    const listData = data.map(listValue => {
      const listDataValueKey = getPickListLabel(listName);
      return {
        label: listValue[listDataValueKey],
        value: listValue.id
      };
    });

    pickLists[listName] = listData;
  });
  return pickLists;
};
