// @flow

import axios from 'axios';
import dataOptions from './dataOptions';

type useDataGetTypes = {
  app: string,
  tab: string | false,
  item?: string | false,
  params?: Object
};

function dataFetch({ app, tab, item, params = {} }: useDataGetTypes) {
  const options = dataOptions({ app, tab, item, params, method: 'GET' });

  return axios(options);
}

export default dataFetch;
