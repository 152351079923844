import React from 'react';

const NewDownload = props => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12816 8.19814L7.56408 8.63406C7.80353 8.87351 8.19647 8.87351 8.43591 8.63406L8.87183 8.19814L10.8365 6.23345C10.9532 6.11065 11.0146 5.95716 11.0146 5.79753C11.0146 5.6379 10.9532 5.48441 10.8365 5.36161C10.5971 5.12217 10.2041 5.12217 9.96469 5.36161L8.61397 6.71234V1.94795C8.61397 1.61027 8.33768 1.33398 8 1.33398C7.66232 1.33398 7.38603 1.61027 7.38603 1.94795V6.71234L6.0353 5.36161C5.79585 5.12217 5.40291 5.12217 5.16347 5.36161C4.92402 5.60106 4.92402 5.994 5.16347 6.23345L7.12816 8.19814ZM15.386 5.06691C15.0484 5.06691 14.7721 5.34319 14.7721 5.68088V12.9564C14.7721 13.2143 14.2072 13.6011 13.3599 13.6011H2.64006C1.79279 13.6011 1.22794 13.2143 1.22794 12.9564V5.68088C1.22794 5.34319 0.95165 5.06691 0.613968 5.06691C0.276285 5.06691 0 5.34319 0 5.68088V12.9564C0 14.0063 1.1604 14.829 2.64006 14.829H13.3599C14.8396 14.829 16 14.0063 16 12.9564V5.68088C16 5.34319 15.7237 5.06691 15.386 5.06691ZM11.4075 11.9433H4.58634C4.24866 11.9433 3.97237 11.6671 3.97237 11.3294C3.97237 10.9917 4.24866 10.7154 4.58634 10.7154H11.4075C11.7452 10.7154 12.0215 10.9917 12.0215 11.3294C12.0215 11.6671 11.7452 11.9433 11.4075 11.9433Z"
        fill="#535353"
      />
    </svg>
  );
};

export default NewDownload;
