import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

export const CustomLinearProgress = withStyles(theme => ({
  root: {
    height: props => `${props.height}px`,
    borderRadius: 20,
    width: '100%',
    marginRight: '8px'
  },
  colorPrimary: {
    backgroundColor: props => (props.backgroundMain ? `${props.backgroundMain}` : '#F6F6F6')
  },
  bar: {
    borderRadius: 5,
    backgroundColor: props => `${props.backgroundColor}`,
    animation: '$linearAnimation 1s linear alternate',
    animationDuration: props => (props.noAnimation ? '0s' : '1s')
  },

  '@keyframes linearAnimation': {
    '0%': {
      width: 0
    },
    '100%': {
      width: '100%'
    }
  }
}))(LinearProgress);
