import React, { useCallback, useMemo } from 'react';
import styled from "styled-components"
import { Dialog } from '../../../forms';
import { DialogTitle } from '@material-ui/core';
import ReferralCodeForm from './ReferralCodeForm';
import { useReferralCodeContext } from './ReferralCodeContext';
import ReferralCodeSubmissionResult from './ReferralCodeSubmissionResult';



const ReferralCodeModalHeader = styled(DialogTitle)`
  && {
    border-bottom: 1px solid var(--borderDefault);
    padding: 24px;

    h2 {
      font-size: 1.8rem !important;
      line-height: 22px;
      color: var(--colorDefault);
    }

  }
`



const ReferralCodeModal = ({ open, onClose }) => {
  const { record } = useReferralCodeContext();

  const _renderReferralCodeDialogHeader = useMemo(() => {
    return (
      <ReferralCodeModalHeader>
        Referral Code Submission
      </ReferralCodeModalHeader>
    )
  }, [])

  const handleCloseModal = useCallback((val) => {
    if (record.disabled && record.referral_code) return () => {}
    return onClose(val)
  }, [record.disabled, record.referral_code])

  return (
    <Dialog
      open={open}
      setOpen={handleCloseModal}
      variant={"standard"}
      maxWidth="sm"
      onlyOk
      closeButton
      disableBackdropClick
      titleComponent={!record.modalData ? _renderReferralCodeDialogHeader : undefined}
    >
      {
        record.modalData ? (
          <ReferralCodeSubmissionResult onClose={onClose}/>
        ) : (
          <ReferralCodeForm/>
        )
      }
    </Dialog>
  )
}

export default ReferralCodeModal
