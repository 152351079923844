import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { Container } from '../types';
import { Button, Dialog } from '../forms';
import { LazyIcon } from '../icons';
import { ButtonCancel } from './ComponentTypes';

const Wrapper = styled(Container.Grid)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ topPadding }) => `${topPadding}px`};
`;

const IconWrapper = styled.div`
  padding: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-width: 70px;
  min-height: 70px;
  box-sizing: border-box;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => `var(--${color})`};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
  }
  svg {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
  }
  path {
    fill: ${({ color }) => `var(--${color})`};
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8125rem;
  color: ${({ color }) => `var(--${color})`};
`;

const SubmitButton = styled(Button)`
  && {
    background-color: ${({ color }) => `var(--${color})`};
    color: white;
    margin: 30px 0 15px;
  }
`;

const ButtonCancelStyled = styled(ButtonCancel)`
  && {
    margin: 0;
  }
`;

const SuccessContent = styled.div`
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SuccessTitle = styled(Typography)`
  && {
    color: var(--colorSystemSuccess);
    font-weight: bold;
  }
`;

const SubmissionModal = ({
  open,
  onClose,
  icon,
  iconSize = 48,
  maxWidth = 'sm',
  children,
  title,
  color,
  description,
  submitButtonTitle,
  cancelButtonTitle,
  topPadding,
  onSubmit,
  disabled = false,
  hideIcon,
  showSuccessDialog = false,
  successIcon = 'CircleChecked',
  successMessage,
  successDescription = ''
}) => {
  return (
    <Dialog
      open={open}
      setOpen={() => onClose(false)}
      variant="standard"
      maxWidth={maxWidth}
      onlyOk
      closeButton
      disableBackdropClick
    >
      {showSuccessDialog ? (
        <SuccessContent>
          <Box pb={2} display="flex" justifyContent="center">
            <IconWrapper color={color} iconSize={iconSize}>
              <LazyIcon component={successIcon} color={color} strokeColor={color} />
            </IconWrapper>
          </Box>
          <SuccessTitle variant="h3" display="block">
            {successMessage}
          </SuccessTitle>
          <Box pt={3}>{successDescription}</Box>
        </SuccessContent>
      ) : (
        <Wrapper topPadding={topPadding || 0}>
          {hideIcon || (
            <IconWrapper color={color} iconSize={iconSize}>
              <LazyIcon component={icon} color={color} strokeColor={color} />
            </IconWrapper>
          )}

          <Title color={color}>{title}</Title>

          {description}

          {submitButtonTitle && (
            <SubmitButton onClick={onSubmit} color={color} disabled={disabled || !open}>
              {submitButtonTitle}
            </SubmitButton>
          )}

          {cancelButtonTitle && <ButtonCancelStyled onClick={onClose}>{cancelButtonTitle}</ButtonCancelStyled>}
        </Wrapper>
      )}
    </Dialog>
  );
};

export default SubmissionModal;
