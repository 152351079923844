// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { useNotify } from 'react-admin';
import { Grid, makeStyles, DialogContent, Box, FormGroup, Divider } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { LoadingStyled } from 'components';
import { CustomAvatar, CheckboxWithIcon } from '../../common';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { getAvatar } from '../../../newsfeedHelpers';
import { ModalTitle, ModalActions, ModalSearch } from './index';
import { ChipOutlineInfo } from '../../../ComponentTypes';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minHeight: 300,
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  clientList: {
    maxHeight: 300,
    minHeight: 150,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'initial'
    }
  },
  selectedClients: {
    padding: '25px 24px',
    marginTop: 'auto',
    '& p': {
      margin: '0 0 10px',
      fontWeight: 500,
      color: 'var(--colorSystemInfo)'
    },
    '& [class*=MuiGrid-item]': {
      maxWidth: 'calc(100% - 16px)',
      margin: '0 16px 12px 0'
    }
  },
  selectedClientsList: {
    maxHeight: 100,
    overflowY: 'auto'
  },
  name: {
    fontWeight: 500
  },
  access: {
    fontWeight: 500,
    width: 'calc(30% + 28px)',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(10% + 28px)'
    }
  }
}));

const SelectClients = ({ setView, data, setAudienceData, clientsAudience }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(null);
  const [selectedClients, setSelectedClients] = useState(data.included_clients);

  const getClientList = useCallback(
    (searchValue) => {
      setLoading(true);
      newsFeed
        .getFeedPicklist(['clients'], { name: searchValue })
        .then(res => {
          setClients(res?.data?.clients || null);
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        })
        .finally(() => setLoading(false));
    },
    [notify]
  );

  useEffect(() => {
    // Send empty string as a search value to get latest clients (to display list by default befor user searches something)
    getClientList('');
  }, [getClientList]);

  const onChangClients = (isChecked, obj) => {
    setSelectedClients(prevState => {
      if (isChecked) return [...prevState, obj];
      else return prevState.filter(item => item.id != obj.id);
    });
  };

  const onSaveClients = useCallback(
    () => {
      setView('audience');
      setAudienceData(prevState => {
        return {
          ...prevState,
          included_clients: selectedClients,
          audience: selectedClients?.length > 0 ? clientsAudience : prevState.audience
        }
      });
    },
    [selectedClients, clientsAudience]
  );

  const handleViewChange = useCallback(view => setView(view), []);

  return (
    <>
      <ModalTitle title="Select specific clients" onClick={() => handleViewChange('audience')} />

      <DialogContent className={classes.dialogContent}>
        <ModalSearch
          placeholder="Search client"
          onSearch={getClientList}
          title={`Clients ${clients?.length > 0 ? `(${clients.length})` : ''}`}
        />

        {loading ? (
          <LoadingStyled />
        ) : (
          <div>
            <Box py={2.5} px={3} display="flex" alignItems="center">
              <span className={classes.name}>Name</span>
              <span className={classes.access}>Newsfeed Access</span>
            </Box>
            <Divider />
            {clients &&
              (clients.length > 0 ? (
                <div className={classes.clientList}>
                  <FormGroup>
                    {clients.map(item => {
                      const isChecked = selectedClients.find(obj => obj.id == item.id);
                      return (
                        <CheckboxWithIcon
                          key={item.id}
                          checked={Boolean(isChecked)}
                          onChange={e => onChangClients(e.target?.checked, item)}
                          name={item.id}
                          label={item.name}
                          labelPlacement="start"
                          icon={<CustomAvatar size={40}>{getAvatar(item.name)}</CustomAvatar>}
                          accessIcon
                          access={item.newsfeed}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              ) : (
                <Box py={3} px={2} fontWeight={700} textAlign="center">
                  No results found
                </Box>
            ))}
          </div>
        )}

        {selectedClients?.length > 0 && (
          <div className={classes.selectedClients}>
            <p>
              Selected client{selectedClients.length > 1 && 's'} ({selectedClients.length})
            </p>
            <Grid container className={classes.selectedClientsList}>
              {selectedClients.map(item => (
                <Grid item key={item.id}>
                  <ChipOutlineInfo
                    label={item.name}
                    size="small"
                    onDelete={() => onChangClients(false, item)}
                    deleteIcon={<CloseIcon />}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </DialogContent>

      <ModalActions title="Save Changes" onClick={onSaveClients} />
    </>
  );
};

export default SelectClients;
