import {
  SET_FEEDS,
  CLEAR_FEEDS,
  EMPTY_FEEDS,
  ADD_FEED,
  UPDATE_FEED,
  UPDATE_FEED_ID,
  UPDATE_FEED_SCORE,
  UPDATE_FEEDS,
  DELETE_FEED,
  SET_COMMENTS,
  CLEAR_COMMENTS,
  ADD_COMMENT,
  UPDATE_COMMENT_ID,
  DELETE_COMMENT,
  SET_NOTIFICATIONS,
  MARK_OPENED,
  MARK_ALL_OPENED,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  NEWSFEED_LICENSE,
  NEWSFEED_LICENSE_AGREED,
  SET_USER_NOTIFICATION_COUNT,
  SET_CAN_FLAG,
  SET_CAN_PIN_POST,
  SET_FEED_SEARCH,
  SET_FEED_SEARCH_BY,
  SET_FEED_FILTER,
  SET_FEED_SEARCH_FILTER,
  SET_HAS_PINNED_POST
} from 'conf/constants';

// general
export const setNewsfeedLicense = value => {
  return { type: NEWSFEED_LICENSE, payload: value };
};

export const setNewsfeedLicenseAgreed = () => {
  return { type: NEWSFEED_LICENSE_AGREED };
};

export const setCanFlag = value => {
  return { type: SET_CAN_FLAG, payload: value };
};

export const setCanPinPost = value => {
  return { type: SET_CAN_PIN_POST, payload: value };
};

export const setHasPinnedPost = value => {
  return { type: SET_HAS_PINNED_POST, payload: value };
};

//feeds

export const setFeeds = feeds => {
  return { type: SET_FEEDS, payload: feeds };
};

export const clearFeeds = () => {
  return { type: CLEAR_FEEDS };
};

export const emptyFeeds = () => {
  return { type: EMPTY_FEEDS };
};

export const addFeed = feed => {
  return { type: ADD_FEED, payload: feed };
};

export const updateFeed = (postId, params) => {
  return { type: UPDATE_FEED, postId, params };
};

export const updateFeedId = (oldId, newId) => {
  return { type: UPDATE_FEED_ID, oldId, newId };
};


export const updateFeedScore = (postId, value) => {
  return { type: UPDATE_FEED_SCORE, postId, value };
};

export const updateFeeds = value => {
  return { type: UPDATE_FEEDS, payload: value };
};

export const deleteFeed = feedId => {
  return { type: DELETE_FEED, payload: feedId };
};

export const setFeedSearch = value => {
  return { type: SET_FEED_SEARCH, payload: value };
};

export const setFeedSearchBy = value => {
  return { type: SET_FEED_SEARCH_BY, payload: value };
};

export const setFeedFilter = value => {
  return { type: SET_FEED_FILTER, payload: value };
};

export const setFeedSearchFilter = value => {
  return { type: SET_FEED_SEARCH_FILTER, payload: value };
};

//comments

export const setComments = (postId, comments) => {
  return { type: SET_COMMENTS, postId, postComments: comments };
};

//if action is called without "postId" param, all the comments are being cleared, otherwise only a specific post's comments
export const clearComments = postId => {
  return { type: CLEAR_COMMENTS, payload: postId };
};

export const addComment = (postId, newComment) => {
  return { type: ADD_COMMENT, postId, newComment };
};

export const updateCommentId = (postId, oldId, newId) => {
  return { type: UPDATE_COMMENT_ID, postId, oldId, newId };
};

export const deleteComment = (postId, commentId) => {
  return { type: DELETE_COMMENT, postId, commentId };
};

//notifications

export const setNotifications = (notifications) => {
  return { type: SET_NOTIFICATIONS, payload: notifications };
};

export const markOpened = (notificationId) => {
  return { type: MARK_OPENED, notificationId };
};

export const markAllOpened = () => {
  return { type: MARK_ALL_OPENED };
};

export const addNotification = notification => {
  return { type: ADD_NOTIFICATION, payload: notification };
};

export const deleteNotification = id => {
  return { type: DELETE_NOTIFICATION, id };
};

export const updateNotifications = value => {
  return { type: UPDATE_NOTIFICATIONS, payload: value };
};

export const setUnopenedNotificationsCount = (replace, value) => {
  return { type: SET_USER_NOTIFICATION_COUNT, replace, value };
};