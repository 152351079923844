// @flow

import { useQuery, useQueryClient } from 'react-query';
import { dataPost, getStorage } from 'helpers';
import useLocation from './useLocation';

const exeptions = [
  'login',
  'signup',
  'logout',
  'redirector.html',
  'federated',
  'setPassword',
  'forgotPassword',
  'newUser',
  'MicroTrainingPublicView',
  'PreAssessment',
  'expiredSession',
  'bsnteamsappabout',
  'bsnteamsapptermsofuse',
  'bsnteamsappprivacy',
  'hsnteamsappabout',
  'hsnteamsapptermsofuse',
  'hsnteamsappprivacy'
];

function useUserProfile() {
  const { app } = useLocation();
  const isException = exeptions.includes(app);
  const options = {
    staleTime: Infinity,
    enabled: !isException
  };
  const { data } = useQuery('user', postFunction, options);
  const queryClient = useQueryClient();
  if (isException) queryClient.removeQueries('user');
  return data;
}

async function postFunction() {
  const { data: userData } = await dataPost({
    app: 'user',
    tab: 'authorize2',
    data: {
      access_token: getStorage('accessToken', true),
      id_token: getStorage('idToken', true),
      refresh_token: getStorage('refreshToken', true)
    }
  });
  return userData;
}

export default useUserProfile;
