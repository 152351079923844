// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WeHaventInfoYet = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 64.5 47.152" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h64.5v47.152H0z" />
    <g transform="translate(-48.188 -14.206)">
      <rect width="46.393" height="3.704" rx="1.852" transform="translate(66.295 18.466)" fill="#535353" />
      <path
        d="M59.969,14.648a1.528,1.528,0,0,0-2.15,0l-3.52,3.52-3.517-3.52a1.521,1.521,0,0,0-2.15,2.153l3.517,3.517-3.517,3.517a1.521,1.521,0,0,0,2.15,2.153L54.3,22.47l3.52,3.517a1.521,1.521,0,0,0,2.15-2.153l-3.517-3.517L59.969,16.8A1.526,1.526,0,0,0,59.969,14.648Z"
        transform="translate(0 0)"
        fill="#535353"
      />
      <path
        d="M59.969,21.022a1.523,1.523,0,0,0-2.15,0L54.3,24.539l-3.517-3.517a1.521,1.521,0,1,0-2.15,2.153l3.517,3.517-3.517,3.517a1.52,1.52,0,1,0,2.15,2.15L54.3,28.842l3.52,3.517a1.52,1.52,0,1,0,2.15-2.15l-3.517-3.517,3.517-3.517A1.526,1.526,0,0,0,59.969,21.022Z"
        transform="translate(0 11.514)"
        fill="#535353"
      />
      <rect width="46.393" height="3.704" rx="1.852" transform="translate(66.295 36.707)" fill="#535353" />
      <path
        d="M59.969,27.092a1.528,1.528,0,0,0-2.15,0l-3.52,3.52-3.517-3.52a1.521,1.521,0,1,0-2.15,2.153l3.517,3.517-3.517,3.517a1.521,1.521,0,0,0,2.15,2.153L54.3,34.914l3.52,3.517a1.521,1.521,0,0,0,2.15-2.153l-3.517-3.517,3.517-3.517A1.526,1.526,0,0,0,59.969,27.092Z"
        transform="translate(0 22.484)"
        fill="#535353"
      />
      <rect width="46.393" height="3.704" rx="1.852" transform="translate(66.295 53.748)" fill="#535353" />
    </g>
  </SvgIconStyled>
);

export default WeHaventInfoYet;
