// @flow
import { Flag } from 'components/icons';
import GetStartedList from './GetStartedList';

export default {
  name: 'getStarted',
  icon: Flag,
  list: GetStartedList,
  edit: GetStartedList,
  options: {
    label: 'Getting Started',
    hasMobileSublist: false
  }
};
