// @flow
import React, { type Element, useState } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';
import { CustomTooltip } from 'components';
import { trackingUtils } from 'helpers';
import { LazyIcon } from '../icons';
import Modal3 from '../modal/Modal3';
import { useTableList } from './TableListContext';

const Button = styled(MuiButton)`
  white-space: nowrap;
`;

type TableListActionTypes = {
  icon?: string | null,
  hideIcon: boolean,
  label?: string | null,
  paramId?: string | null,
  tooltip?: string,
  modal?: {
    app: string,
    tab: string
  },
  rowId: string,
  handleModal: Function
};

const TableListAction = ({
  icon,
  hideIcon,
  label,
  paramId,
  tooltip,
  modal,
  rowId,
  handleModal
}: TableListActionTypes): Element<*> | null => {
  const { app, tab } = useTableList();
  const [open, setOpen] = useState(false);
  
  const onClick = () => {
    if (modal) {
      setOpen(true);
    }
    trackingUtils.clickButton(tooltip);
  };
  const modalApp = modal?.app || app;
  const modalTab = modal?.tab || tab;
  return !hideIcon ? (
    <>
      <CustomTooltip title={tooltip} placement="left">
        <Button color="primary" aria-label={label} onClick={onClick} >
          {icon && <LazyIcon component={icon} />}
          {icon && label && <>&nbsp;&nbsp;</>}
          {label}
        </Button>
      </CustomTooltip>
      {modal && open && (
        <Modal3
          paramId={paramId}
          {...modal}
          open={open}
          app={modalApp}
          tab={modalTab}
          setOpen={setOpen}
          rowId={rowId}
          handleModal={handleModal}
        />
      )}
    </>
  ) : null;
};

TableListAction.defaultProps = {
  icon: null,
  label: null,
  tooltip: '',
  modal: null,
  paramId: null
};

export default TableListAction;
