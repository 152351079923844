import styled, { css } from 'styled-components';
import { Box } from '@material-ui/core';
import { Arrow } from '../../icons';

export const MobilePaginationContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MobilePaginationItemsCount = styled.p`
  font-size: 14px;
  color: var(--colorBaseLight2);
`

export const MobilePaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CurrentPaginationInput = styled.input`
  padding: 12px 5px;
  width: 42px;
  border: 0.5px solid var(--colorDefault);
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  outline: none;
  font-weight: 600;
  margin-right: 10px;

  &:disabled {
    border-color: var(--borderLight);
    color: var(--borderLight);
  }

  ${({danger}) => danger ? (
    css`
      background-color: rgba(255, 90, 97, 0.2);
      border-color: #CC484E;
      color: #CC484E;
    `
  ) : (
    css`
      border-color: var(--colorDefault);
      background-color: var(--whiteAndBlack);
      color: var(--colorBaseLight2);
    `
  )}
`

export const ArrowLeft = styled(Arrow)`
  transform: rotate(-90deg);
  margin-right: 25px;
  path {
    fill: ${({disabled}) => disabled ? "var(--borderLight)" : "var(--colorDefault)" };
  }
`

export const ArrowRight = styled(Arrow)`
  transform: rotate(90deg);
  margin-left: 25px;
  path {
    fill: ${({disabled}) => disabled ? "var(--borderLight)" : "var(--colorDefault)" };
  }
`
