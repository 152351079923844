// @flow
import React, { memo, type Element } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { hasKey, trackingUtils } from 'helpers';
import { TabsHeader, TabsFixed, TabButton } from './ComponentTypes';
import { useTabs } from './TabsContext';
import TabsTab from './TabsTab';
import { Hidden } from '@material-ui/core';

type TabsTypes = Object;

const TabsTabs = (): Element<*> => {
  const history = useHistory();
  const { tabCurrent, layout, tabs, dispatch, access, hideTabs, app } = useTabs();

  if (typeof access === 'object' && !Object.keys(access).length && !tabs.length && app) {
    history.push(`/`);
  }

  const isComingSoon = hasKey(layout[tabCurrent], 'soon') && layout[tabCurrent]?.soon;
  let validTabs = tabs.length > 0 ? <TabsTab /> : null

  return (
    <>
      {!hideTabs && (
        <Hidden smDown>
          <TabsFixed>
            <TabsHeader variant="scrollable" scrollButtons="desktop" value={tabCurrent} onChange={dispatch.onChangeTab}>
              {tabs.map(
                tid =>
                  access[tid] && (
                    <Link
                      key={tid}
                      tabId={tid}
                      value={tid}
                      label={layout[tid]?.label}
                      component={TabButton}
                      onClick={() => {
                        trackingUtils.clickTab(null, tid);
                        // we need to navigate using history.push for the 
                        // page leave confirmation message to work 
                        const pathname = history.location.pathname;
                        const [, pathApp, , item] = pathname.split('/');

                        // Prevent reloading component when clicking on current tab
                        if (app === pathApp && tid === tabCurrent) return;

                        history.push(item ? `/${pathApp}/${tid}/${item}` : `/${pathApp}/${tid}`);
                      }}
                      data-ga-tab="true"
                    />
                  )
              )}
            </TabsHeader>
          </TabsFixed>
        </Hidden>
      )}
      {isComingSoon ? <h1>{layout[tabCurrent]?.label} coming soon!</h1> : validTabs }
    </>
  );
};

export default memo<TabsTypes>(TabsTabs);
