// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Jira = props => (
  <SvgIconStyled {...props} viewBox="0 0 15 16" role="img" aria-hidden="false">
    {/* <svg width="15" height="16"  fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path
        d="M13.9922 8.66965L8.68747 14.1573C8.10478 14.7601 7.14115 14.7683 6.54833 14.1754L1.06066 8.68778C0.474874 8.10199 0.474874 7.15225 1.06066 6.56646L6.54833 1.07879C7.14115 0.485971 8.10478 0.494137 8.68747 1.09692L13.9922 6.58459C14.5542 7.16599 14.5542 8.08825 13.9922 8.66965Z"
        stroke={props.color}
      />
      <path
        d="M7.50012 0.5C8.77131 2.27966 9.15223 3.05094 7.62681 4.8306M7.62681 4.8306L5.89086 6.56655C5.30507 7.15234 5.30507 8.10208 5.89086 8.68787L7.62681 10.4238M7.62681 4.8306L9.36276 6.56655C9.94855 7.15234 9.94855 8.10209 9.36276 8.68787L7.62681 10.4238M7.62681 10.4238C5.84715 12.2035 7.23741 13.483 8.00012 14.4999"
        stroke={props.color}
      />
    {/* </svg>{' '} */}
  </SvgIconStyled>
);

export default React.memo(Jira);
