import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TimePicker } from './ComponentTypes';
import Controller from './Controller';
import { useFormContext } from 'react-hook-form';

const TimeFieldWrapper = ({ value, required, onChange, helperText, error, nullValue, ...props }) => {
  const onDateChange = (e, onChange) => {
    const date = new Date(e);
    let dt = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    const [HH, MM] = dt
      .split('T')[1]
      .split('.')[0]
      .split(':');
    dt = `${HH}:${MM}:00`;
    return onChange(dt);
  };

  const newVal = `${value}.000Z`;
  const newDate = new Date();
  const [, time] = newDate.toISOString().split('T');
  let date = nullValue && !value ? null : `2000-01-01T${!value ? time : newVal}`;

  date = date && new Date(date);

  const timeValue = date && new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        {...props}
        label={props.label && `${props.label} ${required && '*'}`}
        value={timeValue}
        autoOk
        inputVariant="outlined"
        margin="none"
        onChange={e => onDateChange(e, onChange)}
        mask="__:__ _M"
        placeholder="08:00 AM"
        InputLabelProps={{ classes: { focused: 'field-focused' } }}
        KeyboardButtonProps={{
          'aria-label': 'change time'
        }}
        ampm={true}
        error={error}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );
};

const TimeField = ({ name, required, rules, value, ...props }) => {
  const formContextData = useFormContext();

  if (!formContextData) {
    return (
      <TimeFieldWrapper
        {...props}
        required={required}
        value={value}
        error={props.error}
        helperText={props.helperText}
      />
    );
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => {
        return (
          <TimeFieldWrapper
            {...props}
            {...field}
            required={required}
            value={field.value}
            error={error || props.error}
            helperText={error?.message || props.helperText}
          />
        );
      }}
    />
  );
};

export default TimeField;
