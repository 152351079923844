// @flow
import { FileSearch } from 'components/icons';
import AuditLogList from './AuditLogList';

export default {
  name: 'auditlog',
  list: AuditLogList,
  icon: FileSearch,
  options: {
    label: 'Audit Log',
    hasMobileSublist: false
  }
};
