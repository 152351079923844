/* eslint-disable react/prop-types */
import { ArchiveIcon, CalendarTimeIcon, Chip } from '@trustsecurenow/components-library';
import React from 'react';

const getSraReportStatusChip = status => {
  switch (status) {
    case 0:
      return {
        label: 'Archived',
        icon: <ArchiveIcon />,
        disabled: true
      };
    case 1:
      return {
        label: 'Pending',
        color: 'warning',
        icon: <CalendarTimeIcon />
      };
    case 2:
    default:
      return {
        label: 'Published',
        color: 'success'
      };
  }
};

const StatusCell = props => {
  const { icon, label, color, disabled } = getSraReportStatusChip(props.row.is_deleted ? 0 : props.value);
  return <Chip variant="outlined" label={label} color={color} icon={icon} disabled={disabled} />;
};

export default StatusCell;
