// @flow

import React, { memo } from 'react';
import { hasKey } from 'helpers';
import { TableCellContent } from '../ComponentTypes';
import TypesCell from '../TypesCell';
import { useTableList2 } from '../TableList2Context';

type TableListCelltypes = {
  isOpen: boolean,
  row: Object
};

const TableListCell = ({ isOpen, row }: TableListCelltypes) => {
  const {
    settings,
    paramKeyForModal,
    keyCheckToHideIcon,
    password,
    dispatch: { setOpen }
  } = useTableList2();
  const tableCellContentProps = {};
  const { cells } = settings;
  const hasRowAction = hasKey(settings, 'row');
  const record = cells
    .filter(c => !(hasKey(c, 'conditional') && c.conditional))
    .map(({ id, editing, editingValue, align }) => [id, editing, editingValue, align])
    .reduce((result, key) => ({ ...result, [key[0]]: [row[key[0]], key[1], [key[2]], key[3]] }), {});
  const data = [...Object.values(record)];
  if (hasRowAction) {
    switch (settings.row.click && settings.row.action) {
      case 'modal': {
        if (!isOpen) tableCellContentProps.onClick = () => setOpen(row.id);
        break;
      }

      case 'newWindow': {
        tableCellContentProps.onClick = () => window.open(row[settings.row.field], '_blank');
        break;
      }
      default: {
        break;
      }
    }
  }

  return data.map((cell, i) => (
    <TableCellContent
      component="td"
      scope="row"
      align={Array.isArray(cell) ? cell[3] : 'left'}
      {...tableCellContentProps}
    >
      <TypesCell
        paramIdForModal={paramKeyForModal ? row[paramKeyForModal] : ''}
        hideIcon={keyCheckToHideIcon ? row[keyCheckToHideIcon] : false}
        cell={cell}
        config={settings.cells[i]}
        statePass={password}
        rowId={row.id}
        row={row}
      />
    </TableCellContent>
  ));
};

export default memo<TableListCelltypes>(TableListCell);
