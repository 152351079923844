import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Button, Dialog, makeStyles, IconButton } from '@material-ui/core';
const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 0,
    fontSize: '24px'
  },
  button: {
    marginBottom: '2%',
    padding: '0 6%',
    height: '40px',
    textTransform: 'none'
  },
  cancelButton: {
    color: 'var(--colorDarkGrey)',
    textDecoration: 'underline'
  },
  buttonText: {
    fontSize: '14px'
  }
});

const GradientDialog = ({ opened, onClose, gradientSync }) => {
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();

  return (
    <Dialog style={{ 'background-color': '#082F49F5' }} onClose={handleClose} open={opened} fullWidth>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div style={{ marginTop: '40px', textAlign: 'center', fontFamily: 'Roboto' }}>
        <h2> {'Gradient clients'} </h2>
        <p> Your clients information will be sent to gradient, as well as grant </p>
        <p> access to functionalities within your boards, </p>
        <p> this includes but is not limited to: </p>
        <br />
        <p> - Names of all of your client;</p>
        <p> - Access to all of your PSA boards</p>
        <br />
        <Button
          variant="contained"
          className={`${classes.button}`}
          style={{ 'background-color': 'var(--colorSystemSuccess)', color: 'white' }}
          size="small"
          onClick={gradientSync}
        >
          <p className={classes.buttonText}> Yes, Sent To Gradient </p>
        </Button>
        <br />
        <Button className={`${classes.button} ${classes.cancelButton}`} onClick={handleClose}>
          <p className={classes.buttonText}> Cancel </p>
        </Button>
      </div>
    </Dialog>
  );
};

export default GradientDialog;
