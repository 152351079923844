/* eslint-disable no-console */
// @flow
import { BSN_SET_USER } from 'conf';
import { getStorage, setStorage, clearStorage, getStorageObject, setStorageObject } from './storage';
import { setSession, setExpirationTime, checkSession } from './session';
import dataProvider from './dataProvider';
import store from './store';
import { isPublicUrl, setVariables, setMfaConfigData } from './utils';
import userPreferences from './apis/UsersAPI/userPreferences';
import axios from 'axios';

const setGlobalVars = user => {
  window.clientType = user.client_type;
  window.userFirstName = user.first_name;
  window.userLastName = user.last_name;
  window.userEmail = user.email;
  window.clientName = user.client_name;
  window.productName = user.product_name;
  window.partnerName = user.partner_name;
  window.productName = user.product_name;
  window.userRole = user.user_role;
  window.lastLogin = user.last_login;
  window.productType = user.product_name;
  window.partnerDistributor = user.partner_distributor;
};

let timer = 0;

export const setData = (data, dispatchRx) => {
  const { AuthenticationResult, user, colors, access, userpool_parameters, password_config } = data;

  const {
    id,
    partner_id: partnerId,
    client_id: clientId,
    client_name: clientName,
    first_name: firstName,
    last_name: lastName,
    group_id: groupId,
    email,
    product_name: productName,
    federated: federated,
    partner_name: partnerName,
    user_role: userRole
  } = user;
  const { IdToken, RefreshToken, AccessToken, ExpiresIn } = AuthenticationResult || {};

  const sessionObj = {
    userId: id,
    partnerId,
    clientId,
    clientName,
    groupId,
    userName: `${firstName} ${lastName}`,
    email,
    productName,
    federated,
    partnerName,
    userRole
  };
  if (IdToken) sessionObj.idToken = IdToken;
  if (RefreshToken) sessionObj.refreshToken = RefreshToken;
  if (AccessToken) sessionObj.accessToken = AccessToken;
  if (ExpiresIn) setExpirationTime(ExpiresIn);
  if (AccessToken !== localStorage.getItem('accessToken')) setStorage('tokenUpdated', 'true', true);
  sessionObj.userpoolClientID = userpool_parameters.client_id;
  sessionObj.userpoolUrl = userpool_parameters.url;
  setSession(sessionObj);
  setGlobalVars(user);
  localStorage.setItem('name', `${user.first_name} ${user.last_name}`);
  localStorage.setItem('ess', user.ess);
  sessionStorage.setItem('currentTab', 'true');

  if (!localStorage.getItem('preferences')) {
    userPreferences.setInitialValue(user.preferences);
  }

  clearTimeout(timer);
  timer = setTimeout(() => {
    checkSession();
  }, (ExpiresIn - 60) * 1000);

  setStorageObject('userData', {profile: user, access, theme: colors});

  const dispatchObj = {
    type: BSN_SET_USER,
    payload: {
      profile: user,
      access,
      theme: colors
    }
  };

  if (dispatchRx) dispatchRx(dispatchObj);
  else store('bsn').dispatch(dispatchObj);

  setVariables(colors);
};

export const handleAuthorize = (data) => {
  const { AuthenticationResult } = data;

  const { IdToken, RefreshToken, AccessToken, ExpiresIn } = AuthenticationResult;

  const sessionObj = {
    idToken: IdToken
  };
  if (RefreshToken) sessionObj.refreshToken = RefreshToken;
  if (AccessToken) sessionObj.accessToken = AccessToken;
  if (ExpiresIn) setExpirationTime(ExpiresIn);
  if (AccessToken !== localStorage.getItem('accessToken')) setStorage('tokenUpdated', 'true', true);
  setSession(sessionObj);

  clearTimeout(timer);
  timer = setTimeout(() => {
    checkSession();
  }, (ExpiresIn - 60) * 1000);

  const userData = getStorageObject('userData');
  const {profile, access, theme} = userData || {};

  const dispatchObj = {
    type: BSN_SET_USER,
    payload: {
      profile,
      access,
      theme
    }
  };

  store('bsn').dispatch(dispatchObj);

  setVariables(theme);
};

const authProvider = {
  /**
   * a method to validate user credentials
   * @param {Object} : { loginPayload, dispatchRx }
   * @returns Promise : resolved if login is valid, rejected if the login is invalid
   */
  login: async ({ loginPayload, dispatchRx }) => {
    try {
      // sending credentials to the server
      const response = await dataProvider.post('user', 'login', null, loginPayload);
      // throw if invalid login
      if (response.status < 200 || response.status >= 300) throw new Error({ response });

      const { password_config, Session, user, AuthenticationResult } = response.data;

      const clientEnabledMFA = password_config.show_verify_code;
      const userConfiguredMFA = !password_config.show_configure_mfa;

      if (clientEnabledMFA) {
        setMfaConfigData(password_config);
        localStorage.setItem('email', user.email);
        if (userConfiguredMFA) {
          localStorage.setItem('sessionToken', Session);
          window.location.href = '#/mfavalidation';
        } else {
          localStorage.setItem('configToken', AuthenticationResult?.AccessToken);
          localStorage.setItem('idToken', AuthenticationResult?.IdToken);
          localStorage.setItem('refreshToken', AuthenticationResult?.RefreshToken);
          // Encoding the qr code image into URI component,
          // to force the mfaconfig page update the qr image when it changes.
          // As some other pages may call authorize endpoint,
          // and cause the qr image to change in local storage,
          //  without the mfa page being trigerred to update it
          window.location.href = `#/configmfaapp?qr_code_image=${encodeURIComponent(password_config.qr_code_image)}`;
        }
        return Promise.reject({ logoutUser: false });
      }

      // setting data if valid login
      setData(response.data, dispatchRx);
      const darkMode = response.data.user.dark_mode;
      setStorage('darkMode', darkMode, true);
      if (document.body) {
        document.body.setAttribute('data-theme', darkMode ? 'dark' : 'light');
        document.querySelector("meta[name='theme-color']").setAttribute('content', darkMode ? '#191a20' : '#f3f5f6');
      }

      return;
    } catch (error) {
      return Promise.reject({
        redirectTo:
          '#/login' +
          (new URLSearchParams(window.location.search).get('microtraining_id')
            ? '?microtraining_id=' + new URLSearchParams(window.location.search).get('microtraining_id')
            : ''),

        message: error.response.data.message,
        statusCode: error.response.status
      });
    }
  },
  logout: () => {
    clearStorage();
    return Promise.resolve();
  },
  /**
   * function to check the auth status of a user,
   * @returns Promise: resolved if auth is valid, rejected if the auth is not valid
   */
  checkAuth: async () => {
    try {
      const hash = window.location.hash;
      // resolve if the route is public
      if (isPublicUrl(hash.split('/')[1].split('?')[0])) return;
      if (hash === '#/login') return;

      // getting auth data from browser storage
      const id_token = getStorage('idToken', true);
      const refresh_token = getStorage('refreshToken', true);
      const access_token = getStorage('accessToken', true);
      const show_configure_mfa = getStorage('show_configure_mfa', true) === 'true';
      const show_verify_code = getStorage('show_verify_code', true) === 'true';

      // console.log('checkAuth auth data', {
      //   id_token,
      //   refresh_token,
      //   access_token,
      //   show_configure_mfa,
      //   show_verify_code
      // });

      const isUserLoggedIn = !!refresh_token && !!id_token;

      /**
       * the following code is about not allowing to deeplink the MFA pages
       * the two pages (configmfaapp, mfavalidation) are only accessible if
       * 1- user has just passed the login page of the portal. (show_verify_code is in localStorage)
       * 2- MFA authentication was enabled by client (show_verify_code is true)
       * Note: show_verify_code & show_configure_mfa are removed from localStorage upon submitting MFA code
       */
      const isMfaConfigPage = hash.startsWith('#/configmfaapp');
      const isMfaValidationPage = hash.startsWith('#/mfavalidation');
      const isMfaPage = isMfaConfigPage || isMfaValidationPage;

      if (isMfaPage) {
        // user is trying to access one of the MFA pages

        const userJustPassedLogin = getStorage('show_verify_code', true) !== null; // check if show_verify_code is in localStorage
        const isMfaEnabled = show_verify_code;

        if (userJustPassedLogin) {
          // possible case to allow th page
          const isMfaConfigPageAllowed = isMfaConfigPage && show_configure_mfa;
          const isMfaValidationPageAllowed = isMfaValidationPage && !show_configure_mfa;
          const isMfaPageAllowed = isMfaConfigPageAllowed || isMfaValidationPageAllowed;

          if (isMfaEnabled && isMfaPageAllowed) return;
          return Promise.reject({ redirectTo: '/login', message: 'Please Login to Continue' });
        } else {
          // user is trying to deeplink MFA pages
          if (!isUserLoggedIn) {
            // user is outside the portal
            return Promise.reject({ redirectTo: '/login', message: 'Please Login to Continue' });
          }
          // user is inside the portal
          window.history.back();
          return;
        }
      }

      // throw if there is no auth data
      if (!isUserLoggedIn) throw new Error('#/login');

      // validating auth data
      const response = await dataProvider.post('user', 'authorize2', null, {
        id_token,
        refresh_token,
        access_token
      });

      // throw if auth data are invalid
      if (response.status < 200 || response.status >= 300) {
        const err = new Error('#/login');
        err.response = response;
        throw err;
      }

      // setting auth data if vaild
      handleAuthorize(response.data);

      // resolving with success
      return;
    } catch (error) {
      if (error.response) {
        // invalid auth data reponse status
        const status = error.response.status;
        // clear broswer storage
        if (status === 500) clearStorage();
      }
      if (axios.isAxiosError(error)) {
        // Fix for Firefox browser
        if (error.message === 'Request aborted') return;
        // Fix for Safari browser
        if (error.message === 'Network Error' && navigator.userAgent.match(/safari/i))
          return;
      }
      // rejecting and redirect to the specified page
      return Promise.reject({ redirectTo: '/login', message: 'Please Login to Continue' });
    }
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      clearStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: params => Promise.resolve(),
  userAuthorize: () => {
    const id_token = getStorage('idToken', true);
    const refresh_token = getStorage('refreshToken', true);
    const access_token = getStorage('accessToken', true);
    // eslint-disable-next-line prefer-promise-reject-errors
    return dataProvider
      .post('user', 'authorize2', null, { id_token, refresh_token, access_token })
      .then(({ status, statusText, data }) => {
        if (status < 200 || status >= 300) throw new Error(statusText);
        return data;
      })
      .catch(error => {
        console.log(error);
        return Promise.reject();
      });
  }
};

export default authProvider;
