import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Card } from '../shared';
import { PAGES } from '../constants';

const GetStarted = ({ showHint, progress }) => {
  const history = useHistory();

  const onClickLearnMore = () => {
    history.push(PAGES.learnMore.path);
  };

  const onClickPartnerProfile = () => {
    history.push('/partnerProfile/configure');
  };

  return (
    <Box display="flex" flexWrap="wrap" mt={5}>
      <Box mr={3.5} mb={2}>
        <Card
          imgSrc="/media/cardImage.svg"
          imgAlt="Learn More"
          title="Learn about Breach Secure Now!"
          infoTooltip="Watch our short Partner welcome video to learn more about your new subscription and the tools and resources at your disposal."
          description="For more information click the info icon above"
          btnTitle="Select"
          btnTooltip={showHint && 'To learn about the platform, you can click here'}
          onClick={onClickLearnMore}
        />
      </Box>
      <Box mb={2}>
        <Card
          imgSrc="/media/cardImage.svg"
          imgAlt="Partner Profile"
          title="Partner Profile"
          infoTooltip="Learn about each of our Partner tools and resources with guidance on important features and proper configuration techniques."
          description="For more information click the info icon above"
          progress={{ value: progress }}
          btnTitle="Select"
          onClick={onClickPartnerProfile}
        />
      </Box>
    </Box>
  );
};

GetStarted.propTypes = {
  showHint: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired
};

export default GetStarted;
