// @flow
import React, { useEffect, useState } from 'react';
import { CustomSearchField, Button, LazyIcon, Container, AdvancedFilter, Typography } from 'components';
import { MenuItem, Paper, ClickAwayListener, MenuList, ListItemText } from '@material-ui/core';
import { ContainerMenuActions } from '../style/AdminCommon';
import { useDebounce } from '../../../hooks';
import { snakeToCamelTitle } from 'helpers';

type Props = {
  setSearchValue: Function,
  setFilters: Function,
  navigateToCreate: Function,
  performAction?: Function,
  numSelected?: number,
  resource: string,
  actions: Object,
  filters: Object,
};

const Toolbar = ({
  actions,
  filters,
  setSearchValue,
  setFilters,
  navigateToCreate,
  performAction,
  numSelected,
  resource,
}: Props) => {
  const [value, setValue] = useState('');
  const [anchor, setAnchor] = useState(null);
  const [searchDebounceValue] = useDebounce({ value, delay: 500 });
  const hasItemsSelected = numSelected > 0;

  useEffect(() => {
    setSearchValue(searchDebounceValue.value);
  }, [searchDebounceValue]);

  const onClickAction = (option) => {
    performAction(option);
    setAnchor(null);
  };

  return (
    <Container.Grid alignItems="flex-start" justifyContent="space-between">
      <Container.Grid item md={5} lg={4} xl={3} pr={1} pt={1}>
        <CustomSearchField value={value} onChange={setValue} />

        {actions && (
          <>
            <Button
              backgroundColor="colorBaseBase"
              backgroundColorHover="colorBaseBase"
              onClick={e => setAnchor(e.currentTarget)}
              style={{ height: '42px' }}
              ml={1}
            >
              <LazyIcon component="Settings" color="grey" size={1} mr={1} />
              Actions
            </Button>

            <ContainerMenuActions
              aria-labelledby="filter-button"
              anchorEl={anchor}
              open={Boolean(anchor)}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              <Paper elevation={6}>
                <ClickAwayListener onClickAway={() => setAnchor(null)}>
                  <MenuList autoFocusItem={Boolean(anchor)}>
                    {Object.keys(actions).map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        id="filter-choice"
                        onClick={() => onClickAction(option)}
                        disabled={!hasItemsSelected}
                      >
                        <LazyIcon component={actions[option].icon} color={actions[option].colorIcon} mr={1.4} size={1} />
                        <ListItemText primary={actions[option].label} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </ContainerMenuActions>
          </>
        )}
      </Container.Grid>

      {filters && (
        <Container.Grid item md={5} lg={6} xl={7}>
          <AdvancedFilter filtersConfig={filters} setFilters={setFilters} />
        </Container.Grid>
      )}

      <Container.Grid item md={2} mt={1} justify="flex-end" alignItems="center">
        {hasItemsSelected && (
          <Typography.p m={0.4} color="colorDefault" fontSize={13}>{numSelected} selected</Typography.p>
        )}
        <Button
          backgroundColor="colorBaseBase"
          backgroundColorHover="colorBaseBase"
          style={{ height: '42px' }}
          onClick={navigateToCreate}
          ml={1}
        >
          <LazyIcon component="Plus" color="grey" size={1} mr={1} />
          {`New ${snakeToCamelTitle(resource)}`}
        </Button>
      </Container.Grid>
    </Container.Grid>
  );
};

export default Toolbar;