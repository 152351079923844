// @flow
import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Image, Video, File } from 'components/icons';
import { CustomTooltip } from 'components';

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'inline-block',
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 16
    },
  },
  iconBtn: {
    padding: 8
  },
  smallIcon: {
    display: 'inline-block',
    position: 'absolute',
    top: 9,
    left: 45,
    cursor: 'pointer',
    padding: '3px 5px',
    textAlign: 'center',
    width: 28,
    height: 24,
    lineHeight: '23px',
    boxSizing: 'border-box',
    '&:after': {
      background: '#0d1e42',
      color: 'white',
      padding: '0.5em',
      borderRadius: '0.4em',
      top: '-120%',
      left: '-10px',
      position: 'absolute',
      fontSize: '12px',
      lineHeight: 1.2
    },
    '&:hover': {
      '&:after': {
        content: '"Image"'
      },
      '& svg': {
        color: '#06c'
      }
    }
  }
}));

function getType(type, iconSize) {
  switch (type) {
    case 'image':
      return {
        accept: '.bmp, .jpeg, .jpg, .png',
        title: 'Image',
        name: 'image',
        icon: <Image color="colorIcon" size={iconSize} />
      };
    case 'video':
      return {
        accept: '.3g2, .3gp, .avi, .flv, .h264, .m4v, .mkv, .mov, .mp4, .mpg, .mpeg, .rm, .swf, .vob, .wmv',
        title: 'Video',
        name: 'video',
        icon: <Video color="colorIcon" size={iconSize} />
      };
    case 'file':
      return {
        accept: '.doc, .docx, .pdf, .ppt, .pptx, .csv, .xls, .xlsx',
        title: 'File',
        name: 'data',
        icon: <File color="colorIcon" size={iconSize} />
      };
    default:
      return {
        accept: '.ai, .bmp, .gif, .ico, .jpeg, .jpg, .png, .ps, .psd, .svg, .tif, .tiff',
        title: 'Image',
        name: 'image',
        icon: <Image color="colorIcon" size={iconSize} />
      };
  }
}

const FileUpload = ({ type, previewFile, iconSize, insideEditor, disabled }) => {
  const classes = useStyles();
  const { accept, title, name, icon } = getType(type, iconSize);

  return (
    <label className={insideEditor ? classes.smallIcon : classes.icon}>
      <input
        accept={accept}
        style={{ display: 'none' }}
        multiple={false}
        type="file"
        name={name}
        onChange={previewFile}
        disabled={disabled}
      />
      {insideEditor ? (
        <span>{icon}</span>
      ) : (
        <CustomTooltip title={title} placement='top'>
          <IconButton
            component="span"
            className={classes.iconBtn}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </CustomTooltip>
      )}
    </label>
  );
};

export default React.memo(FileUpload);
