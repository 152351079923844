import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from './index';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const AutoComplete = ({
  label,
  required,
  value,
  creatable,
  onChange,
  labelName,
  getOptionLabel,
  filterSelectedOptions,
  error: autoCompleteError,
  helperText,
  name,
  ...props
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInvalid = e => {
    e.preventDefault();
    setError(!e.target.validity.valid);
    setErrorMessage(e.target.validationMessage);
  };

  useEffect(() => {
    if (!value || value.length !== 0) {
      setError(false);
      setErrorMessage('');
    }
  }, [value]);

  useEffect(() => {
    setError(autoCompleteError);
    setErrorMessage(helperText);
  }, [autoCompleteError, helperText]);

  const inputValue = Array.isArray(value) ? value.join('') : value;

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      onChange(event, {
        [labelName]: newValue
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      onChange(event, {
        [labelName]: newValue.inputValue
      });
    } else {
      onChange(event, newValue);
    }
  };

  const filter = createFilterOptions();

  const handleGetOptionLabel = option => {
    if (!option) return option;
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option?.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[labelName];
  };

  const getLabel = getOptionLabel ? getOptionLabel : handleGetOptionLabel;

  return (
    <>
      <Autocomplete
        {...props}
        value={value}
        freeSolo={creatable}
        selectOnFocus={creatable}
        clearOnBlur={creatable}
        handleHomeEndKeys={creatable}
        filterSelectedOptions={creatable ? false : filterSelectedOptions}
        filterOptions={
          creatable
            ? (options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(option => inputValue === option[labelName]);
                if (inputValue !== '' && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }
            : undefined
        }
        getOptionLabel={getLabel}
        onChange={handleChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              name={name}
              inputProps={{
                ...params.inputProps,
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off'
                }
              }}
              label={label}
              error={error}
              helperText={errorMessage}
            />
          );
        }}
      />
      <input value={inputValue} required={required} onInvalid={handleInvalid} style={{ display: 'none' }} />
    </>
  );
};

export default AutoComplete;
