// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Download2 = (props) => {
  const color = props.color || 'white';
  return (
    <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
      <path
        d="M8.51918 8.22218L9.00959 8.71259C9.27897 8.98197 9.72103 8.98197 9.9904 8.71259L10.4808 8.22218L12.6911 6.0119C12.8223 5.87375 12.8914 5.70107 12.8914 5.52149C12.8914 5.3419 12.8223 5.16923 12.6911 5.03108C12.4217 4.7617 11.9797 4.7617 11.7103 5.03108L10.1907 6.55065V1.19071C10.1907 0.810821 9.87989 0.5 9.5 0.5C9.1201 0.5 8.80928 0.810821 8.80928 1.19071V6.55065L7.28971 5.03108C7.02033 4.7617 6.57828 4.7617 6.3089 5.03108C6.03952 5.30046 6.03952 5.74252 6.3089 6.0119L8.51918 8.22218ZM17.8093 4.69954C17.4294 4.69954 17.1186 5.01036 17.1186 5.39025V13.5752C17.1186 13.8653 16.4831 14.3005 15.5299 14.3005H3.47007C2.51688 14.3005 1.88143 13.8653 1.88143 13.5752V5.39025C1.88143 5.01036 1.57061 4.69954 1.19071 4.69954C0.810821 4.69954 0.5 5.01036 0.5 5.39025V13.5752C0.5 14.7563 1.80545 15.6819 3.47007 15.6819H15.5299C17.1946 15.6819 18.5 14.7563 18.5 13.5752V5.39025C18.5 5.01036 18.1892 4.69954 17.8093 4.69954ZM13.3335 12.4355H5.65963C5.27974 12.4355 4.96892 12.1247 4.96892 11.7448C4.96892 11.3649 5.27974 11.0541 5.65963 11.0541H13.3335C13.7134 11.0541 14.0242 11.3649 14.0242 11.7448C14.0242 12.1247 13.7134 12.4355 13.3335 12.4355Z"
        fill={color}
      />
    </SvgIconStyled>
  );
};

export default React.memo(Download2);
