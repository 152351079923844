// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerRemote = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 20" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h16v20H0z" />
    <g transform="translate(-360 -199)">
      <path
        d="M16,8H15V5A5,5,0,0,0,5,5V8H4a2.006,2.006,0,0,0-2,2v8a2.006,2.006,0,0,0,2,2H16a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,16,8ZM7,5a2.946,2.946,0,0,1,3-3,2.946,2.946,0,0,1,3,3V8H7Zm9,13H4V10H16Z"
        transform="translate(358 199)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerRemote;
