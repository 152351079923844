// @flow
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { Grid, Typography, Link, makeStyles, Hidden, ButtonBase } from '@material-ui/core';
import { cleanTextFromHtml, unicodeToEmoji, emojiToUnicode, convertStyleAttr } from 'helpers';
import PostCommentActions from './PostCommentActions';
import { FeedViewMode, FeedEditMode } from './index';
import { CustomAvatar, CharacterProgress, FileUpload } from '../common';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import DeleteCommentModal from '../modals/DeleteCommentModal';
import ReactionsModal from '../modals/ReactionsModal';
import ReactTimeAgo from 'react-time-ago';
import { setComments, setFeedSearch, setFeedSearchBy } from 'helpers/action';
import { formatHashtags, getFilePreview, removeEmptyHashtagAndMention } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8
    }
  },
  mainContent: {
    flexGrow: 1,
    paddingLeft: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10
    }
  },
  comment: {
    backgroundColor: 'var(--backgroundDefault)',
    padding: 16,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 10
    }
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  timeAgo: {
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  commentInfo: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  commentInfoRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    margin: '14px 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0'
    },
    '& button': {
      color: 'var(--colorDefault)',
      fontWeight: 500,
      fontSize: 14,
      '&[disabled]': {
        pointerEvents: 'none',
        opacity: 0.5
      },
      '&.last': {
        marginLeft: '20px'
      }
    }
  },
  actionButtonsEdit: {
    justifyContent: 'space-between'
  },
  actionsEditMode: {
    height: 25,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    '& pre': {
      border: 'none',
      background: 'none',
      padding: 0,
      margin: 0,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontFamily: 'var(--fontFamily)',
      fontSize: '1.6rem',
      lineHeight: '1.5',
      color: 'inherit'
    }
  },
  editor: {
    position: 'relative',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12
    }
  },
  feedViewMode: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 12
    }
  },
  likes: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px'
    }
  },
  buttonBase: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '50px',
    borderRadius: '100px'
  }
}));

const editorOptions = [['custom-emoji']];
const LIMIT = 1000;

const PostComment = ({
  comment,
  idx,
  isOwnComment,
  postId,
  toggleLike,
  onDeleteComment,
  onReplyToComment,
  canPost,
  commentsCount
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const comments = useSelector(state => state.newsfeed.comments);
  const canFlag = useSelector(state => state.newsfeed.canFlag);
  const [isViewMode, setIsViewMode] = useState(true);
  const [open, setOpen] = useState(false);
  const [openLikes, setOpenLikes] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [startPreview, setStartPreview] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);
  const [disableCommenting, setDisableCommenting] = useState(true);
  const [commentData, setCommentData] = useState({
    user_id: comment.user.id,
    post_id: postId,
    comment_text: unicodeToEmoji(convertStyleAttr(comment.comment_text, true)),
    thumbnail_metadata: comment.content && comment.content.type === 'thumbnail' ? comment.content.data : null,
    filename: comment.content && comment.content.type !== 'thumbnail' ? comment.content.data.filename : null,
    filePreview: comment.content && comment.content.type !== 'thumbnail' ? comment.content : null
  });
  const [filePreviewData, setFilePreviewData] = useState(
    commentData.filePreview ? getFilePreview(commentData.filePreview) : null
  );
  const cleanCommentText = useMemo(() => cleanTextFromHtml(commentData.comment_text), [commentData.comment_text]);
  
  const includesDangerousUrl = () => {
    return commentData?.comment_text?.includes(localStorage.getItem('prohibitedLink'));
  };

  useEffect(() => {
    if (cleanCommentText?.trim() === '' || commentLoading || cleanCommentText.length > LIMIT || includesDangerousUrl()) {
      setDisableCommenting(true);
    } else {
      setDisableCommenting(false);
    }
  }, [cleanCommentText, commentLoading]);

  const postComment = useCallback(
    (filename = null) => {
      const newCommentData = {
        id: comment.id,
        comment_text: formatHashtags(
          unicodeToEmoji(convertStyleAttr(removeEmptyHashtagAndMention(commentData.comment_text), true))
        ),
        thumbnail_metadata: commentData.thumbnail_metadata,
        post_id: postId,
        filename
      };

      setCommentData(newCommentData);
      setCommentLoading(false);

      const updatedComment = {
        ...comments[postId][idx],
        comment_text: newCommentData.comment_text
      };

      if (filePreviewData) {
        updatedComment.content = {
          data: {
            filename: filename,
            url: `${process.env.NEWSFEED_ASSETS_BASE_URL}assets/newsfeed/${encodeURIComponent(filename)}`
          },
          type: filePreviewData.type
        };
      } else if (commentData.thumbnail_metadata) {
        updatedComment.content = {
          data: {
            ...commentData.thumbnail_metadata
          },
          type: 'thumbnail'
        };
      } else {
        updatedComment.content = null;
      }

      dispatch(
        setComments(postId, [
          ...comments[postId].slice(0, idx),
          ...[updatedComment],
          ...comments[postId].slice(idx + 1)
        ])
      );

      newsFeed
        .updateComment({
          ...newCommentData,
          comment_text: emojiToUnicode(convertStyleAttr(newCommentData.comment_text))
        })
        .then(res => {
          notify(res?.data?.message);
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        });

      setIsViewMode(true);
    },
    [
      comment.id,
      commentData.comment_text,
      commentData.thumbnail_metadata,
      comments,
      dispatch,
      filePreviewData,
      idx,
      notify,
      postId
    ]
  );

  const cancelUpdateComment = useCallback(() => {
    setIsViewMode(true);
    const preview = comment.content && comment.content.type !== 'thumbnail' ? comment.content : null;
    setCommentData(prevState => ({
      ...prevState,
      comment_text: convertStyleAttr(comment.comment_text, true),
      thumbnail_metadata: comment.content && comment.content.type === 'thumbnail' ? comment.content.data : null,
      filename: comment.content && comment.content.type !== 'thumbnail' ? comment.content.data.filename : null,
      filePreview: preview
    }));
    setFilePreviewData(preview ? getFilePreview(preview) : null);
  }, [comment.comment_text, comment.content]);

  const replyToComment = () => {
    const data = {
      denotationChar: '@',
      id: comment.user.id,
      snUserId: comment.user.id,
      value: `@${comment.user.first_name} ${comment.user.last_name}`
    };
    onReplyToComment(data);
  };

  const previewFile = useCallback(e => setStartPreview(e.target), []);

  const onSetData = useCallback(newData => {
    setCommentData(prevState => {
      return { ...prevState, ...newData };
    });
  }, []);

  const handleUserClick = () => {
    dispatch(setFeedSearchBy('user'));
    dispatch(setFeedSearch(comment.user));
  };

  const timeAgoComponentMemo = useMemo(
    () => (
      <ReactTimeAgo
        className={classes.timeAgo}
        date={comment.timestamp.replace(' ', 'T') + 'Z'}
        locale="en-US"
        timeStyle="round-minute"
      />
    ),
    [comment.timestamp]
  );

  const avatarMemo = useMemo(
    () => (
      <ButtonBase onClick={handleUserClick} className={classes.buttonBase}> 
        <CustomAvatar user={comment?.user} background={comment.user.partner_base_color}>
          {`${comment.user.first_name.charAt(0)}${comment.user.last_name.charAt(0)}`}
        </CustomAvatar>
      </ButtonBase>
    ),
    [comment.user.first_name, comment.user.last_name, comment.user.partner_base_color, handleUserClick]
  );

  const fileUploadMemo = useMemo(
    () => <FileUpload type="image" previewFile={previewFile} iconSize={1} insideEditor />,
    [previewFile]
  );

  const feedEditModeMemo = useMemo(
    () => (
      <FeedEditMode
        data={commentData}
        setData={onSetData}
        initialData={comment}
        textType="comment_text"
        loading={commentLoading}
        setLoading={setCommentLoading}
        startUpload={startUpload}
        setStartUpload={setStartUpload}
        startPreview={startPreview}
        setStartPreview={setStartPreview}
        setFileChanged={setFileChanged}
        onSubmit={postComment}
        filePreviewData={filePreviewData}
        setFilePreviewData={setFilePreviewData}
        editorData={{
          editorOptions,
          height: '100px',
          placeholder: 'Write a comment'
        }}
      />
    ),
    [commentData, comment, filePreviewData, onSetData, postComment, commentLoading, startPreview, startUpload]
  );

  const postCommentActionsMemo = useMemo(
    () => (
      <PostCommentActions
        showDeleteModal={() => setOpen(true)}
        toggleUpdate={() => setIsViewMode(false)}
        comment={comment}
        postId={postId}
        isOwnComment={isOwnComment}
        canFlag={canFlag}
        commentsCount={commentsCount}
      />
    ),
    [canFlag, comment, commentsCount, isOwnComment, postId]
  );

  const feedViewModeMemo = useMemo(
    () => (
      <div className={classes.feedViewMode}>
        <FeedViewMode text={comment.comment_text} content={comment.content} />
      </div>
    ),
    [comment.comment_text, comment.content]
  );

  const progressMemo = useMemo(() => <CharacterProgress length={cleanCommentText.length} limit={LIMIT} />, [cleanCommentText.length]);

  const deleteCommentModalMemo = useMemo(
    () => (
      <DeleteCommentModal
        close={() => setOpen(false)}
        open={open}
        commentId={comment.id}
        postId={postId}
        onDeleteComment={onDeleteComment}
        commentsCount={commentsCount}
      />
    ),
    [comment.id, commentsCount, onDeleteComment, open, postId]
  );

  const editModeActionsMemo = useMemo(
    () => (
      <div className={classes.actionsEditMode}>
        <Link component="button" variant="body2" onClick={cancelUpdateComment}>
          Cancel
        </Link>
        <Link
          component="button"
          variant="body2"
          className="last"
          disabled={disableCommenting}
          onClick={() => {
            const hasFile = filePreviewData && (!commentData.filename || fileChanged);
            return hasFile ? setStartUpload(true) : postComment(commentData.filename);
          }}
        >
          Save
        </Link>
      </div>
    ),
    [cancelUpdateComment, commentData.filename, disableCommenting, fileChanged, filePreviewData, postComment]
  );

  const viewModeActionsMemo = useMemo(
    () => (
      <div className={classes.actionButtons}>
        <Link
          component="button"
          variant="body2"
          disabled={comment.disableActions}
          onClick={() => toggleLike(idx)}
          style={{ color: comment.liked ? '#0CCE6B' : 'var(--colorDefault)' }}
        >
          Like
        </Link>
        &nbsp;&nbsp;
        {comment.likes_count !== 0 && (
          <Link
            style={{ color: 'var(--colorDefault)' }}
            href="#"
            onClick={e => {
              e.preventDefault();
              setOpenLikes(true);
            }}
          >
            <span className={classes.likes}>
              {comment.likes_count}
            </span>
          </Link>
        )}
        {!isOwnComment && canPost && (
          <Link
            component="button"
            variant="body2"
            className="last"
            disabled={comment.disableActions}
            onClick={replyToComment}
          >
            Reply
          </Link>
        )}
      </div>
    ),
    [canPost, comment.disableActions, comment.liked, comment.likes_count, idx, isOwnComment, replyToComment, toggleLike]
  );

  const commentHeaderInfoMemo = useMemo(
    () => (
      <Grid item xs={9} md={6}>
        <ButtonBase onClick={handleUserClick}>
          <Typography variant="h4" gutterBottom className={classes.name}>
            {`${comment.user.first_name} ${comment.user.last_name}`}
          </Typography>
        </ButtonBase>
        <Typography variant="caption" display="block" gutterBottom>
          {!comment.user.content_admin && comment.user.client_name}
          <Hidden mdUp>
            <>&nbsp;/&nbsp;{timeAgoComponentMemo}</>
          </Hidden>
        </Typography>
      </Grid>
    ),
    [comment.user.client_name, comment.user.first_name, comment.user.last_name, timeAgoComponentMemo, handleUserClick]
  );

  const timeMemo = useMemo(
    () => (
      <Hidden smDown>
        <Typography variant="caption" display="block" gutterBottom>
          {timeAgoComponentMemo}
        </Typography>
      </Hidden>
    ),
    [timeAgoComponentMemo]
  );

  return (
    <div className={classes.container}>
        {avatarMemo}
      <div className={classes.mainContent}>
        <div className={classes.comment}>
          <Grid className={classes.commentInfo} container>
            {commentHeaderInfoMemo}
            <Grid item xs={3} md={6}>
              <div className={classes.commentInfoRight}>
                {timeMemo}
                {(isOwnComment || canFlag) && postCommentActionsMemo}
              </div>
            </Grid>
          </Grid>

          {isViewMode && feedViewModeMemo}

          {!isViewMode && (
            <div className={classes.editor}>
              {fileUploadMemo}
              {feedEditModeMemo}
            </div>
          )}
        </div>

        {!isViewMode && (
          <div className={`${classes.actionButtons} ${classes.actionButtonsEdit}`}>
            {editModeActionsMemo}
            {cleanCommentText.trim().length > 0 && progressMemo}
          </div>
        )}

        {isViewMode && viewModeActionsMemo}
      </div>

      {deleteCommentModalMemo}

      {openLikes && (
        <ReactionsModal close={() => setOpenLikes(false)} open={openLikes} type="comment" id={comment.id} />
      )}
    </div>
  );
};

export default PostComment;
