// @flow
import React, { useRef, useState } from 'react';
import { Paper, makeStyles, Divider } from '@material-ui/core';
import PostHeader from './PostHeader';
import PostInteractions from './PostInteractions';
import PostBody from './PostBody';
import PostCommentingSection from './PostCommentingSection';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '20px 20px 8px',
    marginBottom: '20px',
    color: 'var(--colorDark)',
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '10px',
      borderRadius: '10px',
      maxWidth: '95vw',
    }
  },
  image: {
    maxWidth: '100%'
  }
}));

const Post = ({
  feed,
  openCommentsSection,
  currPlayingVideoPostId,
  setCurrPlayingVideoPostId,
  canPost,
  updateFeedsScrollPosition,
  resetFeedList
}) => {
  const classes = useStyles();
  const [showCommentsSection, setShowCommentsSection] = useState(openCommentsSection);
  const [disableCommentBtn, setDisableCommentBtn] = useState(false);
  const postRef = useRef(null);

  const updateScrollPosition = () => {
    if (postRef?.current) updateFeedsScrollPosition(postRef.current.offsetTop);
  };

  return (
    <Paper className={classes.paper} elevation={0} ref={postRef}>
      <PostHeader feed={feed} resetFeedList={resetFeedList} />
      <Divider />
      <PostBody
        feed={feed}
        currPlayingVideoPostId={currPlayingVideoPostId}
        setCurrPlayingVideoPostId={setCurrPlayingVideoPostId}
        updateScrollPosition={updateScrollPosition}
      />
      <Divider />
      <PostInteractions
        toggleCommentsSection={() => setShowCommentsSection(!showCommentsSection)}
        feed={feed}
        disableCommentBtn={disableCommentBtn}
      />
      {showCommentsSection && (
        <PostCommentingSection
          commentsCount={feed.comments_count}
          postId={feed.id}
          canPost={canPost}
          setDisableCommentBtn={setDisableCommentBtn}
        />
      )}
    </Paper>
  );
};

export default Post;
