import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';
import parse from 'html-react-parser';
import { useDataGet, useId } from 'hooks'
import { getStorage } from 'helpers'

const PaymentAlerts = () => {
  const location = useLocation();
  
  const [loggedIn, setLoggedIn] = useState(false)
  const userId = useId({ key: 'userId' });

  const isLoggedIn = !!getStorage('idToken', true) && !!userId

  const { data: alerts } = useDataGet({
    app: 'user',
    tab: 'alerts',
    item: userId,
    options: {
      refetchOnWindowFocus: false,
      enabled: loggedIn
    }
  });
  
  useEffect(() => {
    if (isLoggedIn) setLoggedIn(true)
  }, [isLoggedIn])

  const hasAlerts = alerts?.length > 0


  const parserOptions = {
    replace: domNode => {
      if (domNode?.attribs?.href) {
        const { href } = domNode.attribs;
        const url = href.replace('/#', '');
        const txt = domNode.children?.[0].data || 'Click here';
        return <Link to={url}>{txt}</Link>;
      }
    }
  };

  const Alerts = () => {
    if (location.pathname === '/login' || isMobile || !hasAlerts) {
      return null;
    }

    return alerts.map(alert => (
      <Alert severity="error" key={alert}>
        <span>{parse(alert, parserOptions)}</span>
      </Alert>
    ))
  }

  return <Alerts />
};

export default PaymentAlerts;
