// @flow

/**
 * @desc Function to easy create opacity for colors.
 * @examples `createOpacity(0.5);` Will return `rgba(0, 0, 0, 0.5)`
 *           `createOpacity(0.5, 'white');` Will return `rgba(255, 255, 255, 0.5)`
 * @param {number} opacity - Required - Opacity value in number format.
 * @param {string} colorName - Optional - The color name `white`.
 * @return {string} The rgba formatted color.
 * @since 1.0
 */
const createOpacity = (opacity: number, colorName?: string): string => {
  const color = colorName === 'white' ? '255, 255, 255' : '0, 0, 0';
  return `rgba(${color}, ${opacity})`;
};

export { createOpacity };
