import React from 'react';
import { Stack, AlertDialog, Typography } from '@trustsecurenow/components-library';

const configureCustomDefaultWelcome = (isCustomWelcomeMsg) => {
    if (isCustomWelcomeMsg) {
        return 'Custom'
    } else {
        return 'Default'
    }
}

const configureMsgTiming = (welcomeMsgDeferred, welcomeMsgHowMany, welcomeMsgFrequency) => {
    if (!welcomeMsgDeferred) {
      return 'immediately';
    }
    if (welcomeMsgHowMany === 1) {
      return `in ${welcomeMsgHowMany} hour`;
    }
    return `in ${welcomeMsgHowMany} ${welcomeMsgFrequency}`;
  };

function EnabledWelcomeMessageDialog({ 
    openDialog, 
    onCloseDialog, 
    onSubmitDialog,
    isCustomWelcomeMsg,
    welcomeMsgFrequency,
    welcomeMsgHowMany,
    welcomeMsgDeferred
}) {
    return (
        <AlertDialog
            open={openDialog}
            dialogTitle="Enable Welcome Messages"
            message={<Stack spacing={3} maxWidth={480}>
                <Typography variant="subtitle1" >
                    By enabling Welcome Messages, welcome messages will be sent to all
                    <Typography variant="h4" component="span">
                        {" "}new users{" "}
                    </Typography>
                    added and all
                    <Typography variant="h4" component="span">
                        {" "}existing users{" "}
                    </Typography>
                    who have not already received a welcome message or logged in.
                </Typography>
                <Typography variant="subtitle1" >
                    Are you sure you would like to enable?
                </Typography>
        
                <Typography variant="body2" >
                    The {configureCustomDefaultWelcome(isCustomWelcomeMsg)} Welcome Message is configured to send {configureMsgTiming(welcomeMsgDeferred, welcomeMsgHowMany, welcomeMsgFrequency)}. To change these settings click cancel and click the Configure Welcome Message button.
                </Typography>
            </Stack>}
            SubmitButtonProps={{
                color: "success",
                children: "Enable Welcome Messages",
                onClick: onSubmitDialog,
            }}
            onClose={onCloseDialog}
        />
    );
}

export default EnabledWelcomeMessageDialog;