// @flow
// export { default as api } from './api';

export const mobilePagesWhiteList = {
  myDashboard: ['dashboard', 'securityTraining', 'microTrainings', 'darkWeb', 'newsletters'],
  newsfeed: ['dashboard'],
  myCompany: ['dashboard', 'employees'],
  clients: ['dashboard', 'users']
};

export const GAbuttonExceptions = ['cancel', 'next', 'save and next', 'back', 'close'];
