import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAdminNavigate from './useAdminNavigate';
import useAdminSteps from './useAdminSteps';
import { admin, formDataUpload, objectsEqual, dateToISO } from 'helpers';
import { updateNestedMutable } from 'utils/update';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { addHours, format } from 'date-fns';
import getNextThursday from 'utils/getNextThursday';
import merge from 'lodash/merge';

const UPLOAD_STEP_NUMBER = 1;

const getNextLastThursdayInMonth = () => {
  const nextThursday = getNextThursday();
  const secondNextThursday = new Date(nextThursday);
  secondNextThursday.setDate(secondNextThursday.getDate() + 7);
  while (nextThursday.getMonth() === secondNextThursday.getMonth()) {
    nextThursday.setDate(nextThursday.getDate() + 7);
    secondNextThursday.setDate(secondNextThursday.getDate() + 7);
  }
  return nextThursday;
};

const useNewslletterAddEdit = (steps) => {
  const { page, method, id } = useParams();
  const { navigateToList, navigateGoBack } = useAdminNavigate();
  const { nextStep, onBack, activeStep, completed } = useAdminSteps();

  const [record, setRecord] = useState({
    name: null,
    created_date: dateToISO(new Date()),
    publish_date: getNextLastThursdayInMonth().toISOString(),
    publicly_accessible: false,
    description: null,
    hash_tags: null,
    email_body: '',
    quick_tips_body: '',
    is_published: null,
    file: null
  });
  const [successModal, setSuccessModal] = useState({ open: false, title: '' });
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(false);

  const createMode = method === 'create' && !id;
  const isLastStep = activeStep === steps.length - 1;

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await admin[page].information(id);
      const newsletter = {
        ...res.data,
        quick_tips_body: res.data.quick_tips_body || '',
        email_body: res.data.email_body || ''
      };
      setInformation(newsletter);
      setRecord({
        ...newsletter,
        publish_date: res.data.publish_date && addHours(new Date(res.data.publish_date), 12)
      });
    } catch (err) {
      navigateToList();
      enqueueAlertSnackbar(
        err?.response?.data?.status || 'Failed on load information',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  const onSuccess = () => {
    // if last step then display success message on modal
    if (isLastStep) {
      const title = createMode ? `Newsletters Created!` : `Newsletters Updated!`;
      const message = `You have successfully ${createMode ? 'created' : 'updated'} a newsletter`
      openModal(title, message);
      return;
    }

    nextStep();
  }

  const create = () => {
    const recordData = { ...record, publish_date: format(new Date(record.publish_date), 'yyyy-MM-dd') }
    return admin[page].create(recordData);
  };

  const update = () => {
    const recordData = { ...record, publish_date: format(new Date(record.publish_date), 'yyyy-MM-dd') }
    return admin[page].update(record.id, recordData);
  };

  const save = async () => {
    try {
      setLoading(true);
      if (createMode && !record?.id) {
        const resp = await create();
        setRecord(old => updateNestedMutable(old, { id : resp.data.data.id }));
        setInformation(updateNestedMutable(record, { id : resp.data.data.id }));
      } else {
        await update();
      }
      onSuccess();
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.status || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const postUrl = async (res) => {
    try {
      const formData = formDataUpload(res, record.file);
      await admin.postUrl(res.data.url, formData, 'multipart/form-data');
      nextStep();
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async () => {
    try {
      setLoading(true);
      const res = await admin.pressignedUploadFile('newsletters', record.id, record.file.name, 'newsletter');
      postUrl(res);
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.message || 'Something went wrong',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoading(false);
    }
  };

  const openModal = (title, message) => {
    setSuccessModal({ open: true, title, message });
  };

  const dispatch = {};

  dispatch.onChange = (name, value) => {
    setRecord(old => updateNestedMutable(old, { [name]: value }));
  };

  dispatch.onFileUpload = (file) => {
    setRecord(old => merge({}, old, { file }));
  };

  dispatch.onCloseModal = () => {
    navigateGoBack();
    setSuccessModal({ open: false, title: '' });
  };

  dispatch.onNext = () => {
    const isDataNotChanged = objectsEqual(information, {
      ...record,
      publish_date: record.publish_date && format(new Date(record.publish_date), 'yyyy-MM-dd')
    });
    if (isDataNotChanged && !isLastStep) {
      return nextStep();
    }

    if (UPLOAD_STEP_NUMBER === activeStep && information.file !== record.file) {
      return uploadFile();
    }

    return save();
  };

  dispatch.onCancel = () => {
    setRecord(id ? information : record);
  };

  dispatch.onBack = () => onBack();

  return {
    activeStep,
    completed,
    dispatch,
    loading,
    page,
    record,
    successModal,
  };
};

export default useNewslletterAddEdit;
