/* eslint-disable no-nested-ternary */
import { Button, Info, LazyIcon, LoadingStyled } from 'components';
import { Alert } from '@material-ui/lab';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import React, { useEffect } from 'react';
import DirectorySyncSwitchComponent from 'apps/shared/directorySync/DirectorySyncSwitchComponent';
import { DirectorySyncProvider, useDirectorySync } from 'apps/shared/directorySync/DirectorySyncContext';
import DirectorySyncCsvBulkUpload from 'apps/shared/directorySync/DirectorySyncCsvBulkUpload';
import { Box, CircularProgress, Divider, Paper } from '@material-ui/core';
import { CancelButton } from 'apps/admin/style/AdminCommon';
import { Typography, Link } from '@trustsecurenow/components-library';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'hooks';

const CSVBulkUploadStart = () => {
  const {
    state,
    dispatch,
    loadingCSVTemplate,
    aadCredentialsVerified,
    AAD_SYNC_TYPE_MULTITENANT,
    AAD_SYNC_TYPE_APPID
  } = useDirectorySync();
  const {directory_sync_enabled} = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus ?? {});
  const { app, item } = useLocation();

  // Check if the client is authorized in azure
  const aadSyncAccount = state?.aadSyncAccount?.toLowerCase();
  const aadVerified = aadCredentialsVerified;

  const configureMsgTiming = () => {
    const { welMessFrequency, welMessHowMany, deferred_sending } = state;
    if (deferred_sending == 0) {
      return 'immediately';
    }
    if (welMessHowMany === 1) {
      return `in ${welMessHowMany} hour`;
    }

    return `in ${welMessHowMany} ${welMessFrequency}`;
  };

  useEffect(() => {
    if (aadSyncAccount === AAD_SYNC_TYPE_MULTITENANT || aadSyncAccount === AAD_SYNC_TYPE_APPID) {
      dispatch.onVerify(true);
    }
    dispatch.setState({ type: '', payload: { type: 'csvBulkUpload' } });
  }, [AAD_SYNC_TYPE_APPID, AAD_SYNC_TYPE_MULTITENANT, aadSyncAccount]);

  if (!state) return <LoadingStyled />;

  if (directory_sync_enabled) {
    const redirectPath = app === "clients" ? `/${app}/users/${item}` : `/${app}/employees`;
    return <Redirect to={redirectPath} />
  }

  return (
    <Box mt={6}>
      {state.automatedWelcome !== undefined && !state.automatedWelcome_loading ? (
        state.automatedWelcome ? (
          <Alert
            style={{
              border: '1px solid',
              borderColor: 'var(--colorSystemInfo)',
              borderLeftWidth: 5,
              color: 'var(--colorSystemInfo)'
            }}
            icon={<NotificationsActiveIcon style={{ color: 'var(--colorSystemInfo)' }} fontSize="inherit" />}
            severity="info"
          >
            Welcome Message is configured to send {configureMsgTiming()} after a new user is added
          </Alert>
        ) : (
          <Alert
            style={{
              border: '1px solid',
              borderColor: 'var(--colorSystemDanger)',
              borderLeftWidth: 5,
              color: 'var(--colorSystemDanger)'
            }}
            icon={
              <LazyIcon style={{ fontSize: `var(--fontSize)` }} color="var(--colorSystemDanger)" component="Siren" />
            }
            severity="error"
          >
            Welcome Messages will not be sent to new users
          </Alert>
        )
      ) : null}
      <Box mt={1.5}>
        <Paper>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              component="header"
              flexWrap="wrap"
              py={1.5}
              px={2.5}
            >
              <Typography variant="subtitle2">1.Settings</Typography>
              <section style={{ display: 'flex' }}>
                <DirectorySyncSwitchComponent enable={!state?.enableSyncType} record={state} dispatch={dispatch} />
                <Button
                  pr={3}
                  pl={3}
                  ml={3}
                  onClick={() => dispatch.downloadFile('CsvBulkUploadTemplate.csv', 'text/csv', 'csvData')}
                  disabled={loadingCSVTemplate}
                >
                  {!loadingCSVTemplate ? (
                    <>
                      <LazyIcon
                        style={{ fontSize: `var(--fontSize)` }}
                        color="var(--colorCommonWhite)"
                        component="Download"
                      />
                      &nbsp;&nbsp;Download CSV Template
                    </>
                  ) : (
                    <>
                      <CircularProgress style={{ color: 'white' }} size={18} thickness={3} />
                      &nbsp; Loading CSV Template...
                    </>
                  )}
                </Button>
              </section>
            </Box>
            <Divider />
            <Box p={2.5}>
              <DirectorySyncCsvBulkUpload enable={!state.enableSyncType} record={state} dispatch={dispatch} />
              {state.file && typeof state.file === 'object' && (
                <Box display="flex">
                  <Typography variant="button" color="info.main" mr={0.5}>
                    Current attachment:
                  </Typography>
                  <Typography variant="button" sx={{ textDecoration: 'underline' }}>
                    {state.file.name}
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end">
                {!state.disableSave && !state.pending_save && (
                  <Link
                    sx={{ mr: 2 }}
                    component="button"
                    onClick={() => dispatch.setState({ type: 'SETDATA', payload: { name: 'file', value: null } })}
                  >
                    Cancel
                  </Link>
                )}
                <Button
                  backgroundColor="colorSystemInfo"
                  backgroundColorHover="colorSystemInfoHover"
                  backgroundDisabled="colorSystemInfoDisable"
                  height={36}
                  pr={3}
                  pl={3}
                  disabled={state.disableSave || state.pending_save || false}
                  onClick={e => dispatch.onSave()}
                >
                  {state.pending_save ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <LazyIcon color="colorCommonWhite" component="Save2" />
                  )}
                  &nbsp;&nbsp;Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Box mt={1.5}>
          <Paper>
            <Box p={2.5}>
              <Typography variant="subtitle2" color="info.main" display="inline-flex" alignItems="center">
                <Info />
                &nbsp;Helpful hints for ensuring upload success
              </Typography>
            </Box>
            <Divider />
            <Box display="inline-flex" flexDirection="column" justifyContent="center" alignItems="flex-start" p={2.5}>
              <Typography variant="subtitle2">Required Fields *</Typography>

              <Typography variant="body2">First name, Last name, Email, Transaction.</Typography>
              <Typography variant="body2">
                {' '}
                If the user will have a manager role, place an ”X” in the manager column. Leave this field blank for
                employees
              </Typography>

              <Typography variant="subtitle2" mt={2}>
                Do not
              </Typography>

              <Typography variant="body2"> Change headers names.</Typography>
              <Typography variant="body2"> Edit/Delete UserID in Column A</Typography>

              <Typography variant="subtitle2" mt={2}>
                Transaction Column Key
              </Typography>
              <Typography variant="body2" mb={2}>
                Used to manage user access
              </Typography>

              <Typography variant="body2">A- Add or reactivate user (User will be notified)</Typography>
              <Typography variant="body2">D- Deactivate user (User will not be notified)</Typography>
              <Typography variant="body2">M- Modify user information (Default for existing users)</Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

const CSVBulkUpload = props => {
  return (
    <DirectorySyncProvider {...props}>
      <CSVBulkUploadStart />
    </DirectorySyncProvider>
  );
};

export default CSVBulkUpload;
