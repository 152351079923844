// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const LogOut = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 21.4" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20v21H4z" />
    <g>
      <path d="M10,10.9c0.5,0,0.9-0.4,0.9-0.9V0.9C10.9,0.4,10.5,0,10,0c0,0,0,0,0,0C9.5,0,9.1,0.4,9.1,0.9V10   C9.1,10.5,9.5,10.9,10,10.9z" />
      <path d="M12.8,1.8c-0.5-0.1-1,0.2-1.1,0.7c-0.1,0.5,0.2,0.9,0.6,1.1c4.3,1.3,6.8,5.8,5.5,10.2c-1,3.5-4.2,5.9-7.9,5.9   c-4.5,0-8.1-3.6-8.1-8.1c0-3.6,2.4-6.8,5.9-7.9c0.5-0.1,0.8-0.7,0.6-1.1C8.2,2,7.7,1.7,7.2,1.8C1.9,3.4-1.2,8.9,0.4,14.3   C2,19.6,7.5,22.6,12.8,21c4.2-1.2,7.2-5.1,7.2-9.6C20,7,17,3.1,12.8,1.8z" />
    </g>
  </SvgIconStyled>
);

export default LogOut;
