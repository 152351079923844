import styled from 'styled-components';

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
`

export const HeadTitleContainer = styled.div`
  display: flex;
  align-items: center;

  h3 {
    color: var(--colorDefault);
    font-size: 18px;
    margin: 15px 0;
  }

  .back-button-arrow {
    transform: rotate(-90deg);
    margin-right: 14px;
    path {
      fill: var(--colorLight)
    }
  }
`
