import React, { cloneElement, useMemo } from 'react';
import { 
  InfoStep,
  CompleteStep,
  QuestionsStep,
  SuccessModal,
  StepsContainer,
} from '../../components';
import { useAdminSteps, useMicroTrainingAddEdit } from '../../hooks';

const steps = ['Information', 'Questions', 'Complete'];

const MicroTrainingsAddEdit = () => {
  const stepsConfig = useAdminSteps();
  const { activeStep, completed } = stepsConfig;
  
  const mtProps = useMicroTrainingAddEdit({ steps, stepsConfig })
  const { createMode, dispatch, loading, mtSuccessModalOpen } = mtProps

  const components = [ <InfoStep />, <QuestionsStep />, <CompleteStep /> ];

  const stepComponents = useMemo(
    () => components.map((component, idx) => {
      return cloneElement(component, { stepNumber: idx + 1, ...mtProps })
    }), [components, mtProps]
  )

  return (
    <>
      <StepsContainer
        activeStep={activeStep}
        completed={completed}
        stepLabels={steps}
        stepComponents={stepComponents}
        loading={loading}
      />

      <SuccessModal
        onClose={dispatch.onCloseModal}
        config={{
          open: mtSuccessModalOpen,
          title: `You have successfully ${ createMode ? 'created' : 'updated'} a Micro training!`
        }}
      />
    </>
  )
}

export default MicroTrainingsAddEdit;