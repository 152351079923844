// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Bullet = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 10 10" role="img" aria-hidden="false">
    <circle cx="5" cy="5" r="5" />
  </SvgIconStyled>
);

export default Bullet;
