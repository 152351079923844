import useLocation from './useLocation';
import useId from './useId';

const useClientId = () => {
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  return  item || clientId;
}

export default useClientId
