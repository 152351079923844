const policies = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc',
  },
  selecting: false,
  sort: ['name', 'created', 'modified'],
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
    },
    {
      id: 'created',
      label: 'Created Date',
      align: 'left',
    },
    {
      id: 'modified',
      label: 'Modified Date',
      align: 'left',
    },
    {
      id: "attachment_link",
      label: 'Download',
      align: 'left',
    },
  ]
};

export default policies;
