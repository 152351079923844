// @flow
import React from 'react';

type Props = Object;

const ContentAdminLandingPage = (props: Props) => (
  <svg width={props.width || 24} height={props.height || 24} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.5183 14.6918H13.6241C12.4914 14.6918 11.4051 15.1417 10.6042 15.9427C9.80324 16.7436 9.35327 17.8299 9.35327 18.9626V26.2658C9.35327 27.3985 9.80324 28.4848 10.6042 29.2858C11.4051 30.0867 12.4914 30.5367 13.6241 30.5367H23.5895C24.7222 30.5367 25.8085 30.0867 26.6095 29.2858C27.4104 28.4848 27.8604 27.3985 27.8604 26.2658V18.9626C27.8605 18.3957 27.7477 17.8345 27.5286 17.3116C27.3095 16.7888 26.9886 16.3147 26.5844 15.9172C26.1803 15.5197 25.701 15.2066 25.1746 14.9962C24.6482 14.7858 24.0852 14.6823 23.5183 14.6918ZM24.942 26.2658C24.942 26.6434 24.792 27.0055 24.525 27.2725C24.258 27.5395 23.8959 27.6894 23.5183 27.6894H13.6241C13.2466 27.6894 12.8845 27.5395 12.6175 27.2725C12.3505 27.0055 12.2005 26.6434 12.2005 26.2658V18.9626C12.2005 18.5851 12.3505 18.223 12.6175 17.956C12.8845 17.689 13.2466 17.539 13.6241 17.539H23.5895C23.9671 17.539 24.3292 17.689 24.5962 17.956C24.8632 18.223 25.0131 18.5851 25.0131 18.9626L24.942 26.2658Z"/>
    <path d="M32.7861 0H4.3136C3.18089 0 2.09458 0.449966 1.29363 1.25091C0.49269 2.05185 0.0427246 3.13817 0.0427246 4.27087L0.0427246 32.6722C0.0427246 33.8049 0.49269 34.8912 1.29363 35.6921C2.09458 36.4931 3.18089 36.9431 4.3136 36.9431H32.7861C33.9188 36.9431 35.0051 36.4931 35.8061 35.6921C36.607 34.8912 37.057 33.8049 37.057 32.6722V4.27087C37.057 3.13817 36.607 2.05185 35.8061 1.25091C35.0051 0.449966 33.9188 0 32.7861 0V0ZM4.3136 2.84725H32.7861C33.1637 2.84725 33.5258 2.99724 33.7927 3.26422C34.0597 3.5312 34.2097 3.8933 34.2097 4.27087V9.06849H2.88997V4.27087C2.88997 3.8933 3.03996 3.5312 3.30694 3.26422C3.57392 2.99724 3.93603 2.84725 4.3136 2.84725V2.84725ZM32.7861 34.0958H4.3136C3.93603 34.0958 3.57392 33.9458 3.30694 33.6788C3.03996 33.4119 2.88997 33.0497 2.88997 32.6722V11.9157H34.2097V32.6722C34.2097 33.0497 34.0597 33.4119 33.7927 33.6788C33.5258 33.9458 33.1637 34.0958 32.7861 34.0958Z"/>
    <path d="M6.22119 7.18932C6.48305 7.18932 6.73902 7.11168 6.95675 6.9662C7.17447 6.82072 7.34417 6.61394 7.44438 6.37202C7.54459 6.13009 7.57081 5.86388 7.51972 5.60706C7.46863 5.35023 7.34254 5.11433 7.15738 4.92916C6.97222 4.744 6.73631 4.61791 6.47948 4.56682C6.22266 4.51574 5.95645 4.54196 5.71453 4.64216C5.4726 4.74237 5.26583 4.91207 5.12035 5.12979C4.97487 5.34752 4.89722 5.6035 4.89722 5.86535C4.89722 6.21649 5.03671 6.55325 5.285 6.80154C5.53329 7.04984 5.87005 7.18932 6.22119 7.18932Z" />
    <path d="M10.9904 7.18925C11.2516 7.18644 11.5062 7.10641 11.722 6.95923C11.9379 6.81205 12.1054 6.60431 12.2034 6.36215C12.3014 6.11999 12.3256 5.85425 12.2729 5.59837C12.2202 5.34249 12.093 5.10791 11.9073 4.92417C11.7216 4.74043 11.4857 4.61574 11.2293 4.5658C10.9728 4.51586 10.7074 4.54289 10.4663 4.64351C10.2252 4.74412 10.0192 4.91381 9.87436 5.13122C9.7295 5.34863 9.6522 5.60403 9.65218 5.86528C9.65023 6.04089 9.68362 6.2151 9.75036 6.37755C9.8171 6.54 9.91583 6.68737 10.0407 6.81089C10.1655 6.9344 10.3139 7.03155 10.4771 7.09655C10.6402 7.16155 10.8148 7.19308 10.9904 7.18925Z" />
    <path d="M16.0584 7.18925C16.3197 7.18644 16.5742 7.10641 16.7901 6.95923C17.0059 6.81205 17.1734 6.60431 17.2714 6.36215C17.3694 6.11999 17.3936 5.85425 17.341 5.59837C17.2883 5.34249 17.1611 5.10791 16.9754 4.92417C16.7896 4.74043 16.5537 4.61574 16.2973 4.5658C16.0409 4.51586 15.7754 4.54289 15.5343 4.64351C15.2932 4.74412 15.0873 4.91381 14.9424 5.13122C14.7975 5.34863 14.7202 5.60403 14.7202 5.86528C14.7202 6.04035 14.7549 6.21368 14.8223 6.37525C14.8898 6.53681 14.9886 6.6834 15.113 6.80653C15.2375 6.92966 15.3851 7.02689 15.5474 7.09258C15.7097 7.15827 15.8834 7.19113 16.0584 7.18925Z"/>
  </svg>
);

export default ContentAdminLandingPage;
