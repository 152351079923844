import React, { Children, createElement, memo } from 'react';
import { Checkbox, Radio, TableRow } from '@material-ui/core';
import { CollapseIcon, TableCellStyled, TableRowStyled } from '../style';
import isEqual from 'lodash/isEqual';

const CustomTableRootCell = memo(
  ({ child, data, isCollapsed, onClick = () => {}, tableData, index }) => {
    return createElement(child.type, {
      ...child.props,
      data: { ...data, isCollapsed },
      onClick,
      rowClick: tableData.rowClick,
      isCollapsed,
      index
    });
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.data, nextProps.data) && prevProps.isCollapsed === nextProps.isCollapsed;
  }
);

const CustomTableRootRow = ({
  index: ind,
  rowId,
  isCollapsed,
  isHighlighted,
  data,
  tableChildren,
  tableData,
  dispatch,
  isSelectAll,
  selectedRows,
  onClickRow
}) => {
  return (
    <TableRowStyled key={rowId} hover isHighlighted={isHighlighted} highlightOnClick={tableData.highlightOnClick}>
      {tableData.selecting || tableData.collapse ? (
        <TableCellStyled
          align="left"
          padding="checkbox"
          onClick={tableData.collapse ? () => onClickRow(rowId) : () => {}}
          rowClick={tableData.collapse ? tableData.rowClick : undefined}
          isCollapsed={isCollapsed}
        >
          {!tableData.collapse ? (
            <Checkbox
              onChange={e => dispatch.handleSelectRow(e, rowId, data)}
              checked={isSelectAll ? !selectedRows.includes(rowId) : selectedRows.includes(rowId)}
            />
          ) : (
            <CollapseIcon isCollapsed={isCollapsed} />
          )}
        </TableCellStyled>
      ) : null}

      {tableData?.selectingRadio ? (
        <TableCellStyled align="left" padding="checkbox">
          <Radio
            checked={selectedRows.includes(rowId)}
            onChange={e => dispatch.handleRadioSelectRow(e, rowId, data)}
            value={rowId}
          />
        </TableCellStyled>
      ) : null}

      {Children.map(tableChildren, (child, index) => {
        if (child.props.hideCell) return;
        return (
          <CustomTableRootCell
            key={index}
            index={ind}
            child={child}
            data={data}
            isCollapsed={isCollapsed}
            onClick={() => onClickRow(rowId, false, data)}
            tableData={tableData}
          />
        );
      })}
    </TableRowStyled>
  );
};

export default React.memo(CustomTableRootRow, (prevProps, nextProps) => {
  return (
    prevProps.isCollapsed === nextProps.isCollapsed &&
    isEqual(prevProps.data, nextProps.data) &&
    (prevProps.tableData.collapse ? true : isEqual(prevProps.selectedRows, nextProps.selectedRows)) &&
    prevProps.isSelectAll === nextProps.isSelectAll &&
    prevProps.isHighlighted === nextProps.isHighlighted
  );
});