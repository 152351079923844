export function deepFreez(obj) {
  if (typeof obj == 'object') {
    if (Array.isArray(obj)) {
      return Object.freeze(obj.map(v => deepFreez(v)));
    }
    const nObj = Object.keys(obj).reduce((prev, curr) => {
      return { ...prev, [curr]: deepFreez(obj[curr]) };
    }, {});
    return Object.freeze(nObj);
  }
  return obj;
}

/**
 *
 * @param obj
 * @param param1
 * @param v
 * @returns
 * @description just update nested object based on path you provide without changing refrences of other paths
 * @example
 * var t_obj = { x: { x1: "1" }, y: { y1: 3, y2: 2 }, z: [1, { z: 1 }] };
 * const newobj = update(t_obj, ["z", 1, "z"], (old) => old + 1);
 * console.log(newobj); //{ x: { x1: '1' }, y: { y1: 3, y2: 2 }, z: [ 1, { z: 2 } ] }
 * console.log(t_obj.y == newobj.y); // true
 * console.log(t_obj.x == newobj.x); // true
 * console.log(t_obj.z == newobj.z); // false
 * 
 * const newobj2 = update(t_obj, ["y", "y1"], "a new val");
 * console.log(newobj2); //{ x: { x1: '1' }, y: { y1: "a new val", y2: 2 }, z: [ 1, { z: 1 } ] }
 * 
 * @note if you access out of index array update function will just add your value
 * @example
 * const newobj3 = update(t_obj, ["z", 30, "z"], 100); 
 * console.log(newobj3); //{ x: { x1: '1' }, y: { y1: 3, y2: 2 }, z: [ 1, { z: 1 }, { z: 100 } ] }
 * 
 * const newobj4 = update(t_obj, ["z", 30, "z"], (old)=>old+100);
 * console.log(newobj4); 
 * //{
 * //x: { x1: '1' },
 * //y: { y1: 3, y2: 2 },
 * //z: [ 1, { z: 1 }, { z: [Function (anonymous)] } ]
 * //}
 
 */
export function update(obj, [...t], v) {
  function updateAux(nObj, [...path]) {
    if (!path.length) {
      return Object.freeze(typeof v == 'function' ? v(nObj) : v);
    }
    if (Array.isArray(nObj)) {
      return Object.freeze(
        nObj.map(val => {
          return val != Reflect.get(nObj, path[0])
            ? val
            : updateAux(Reflect.get(nObj, path[0]), path.slice(1, path.length));
        })
      );
    }
    if (!nObj && typeof path[0] == 'number') {
      return deepFreez([v]);
    }
    return Object.freeze({
      ...nObj,
      [Reflect.get(path, 0)]: nObj
        ? Object.freeze(updateAux(Reflect.get(nObj, path[0]), path.slice(1, path.length)))
        : deepFreez(v)
    });
  }
  return updateAux(obj, [...t]);
}

export function updateMutable(obj, [...t], v) {
  function updateAux(nObj, [...path]) {
    if (!path.length) {
      return typeof v == 'function' ? v(nObj) : v;
    }
    if (Array.isArray(nObj)) {
      return nObj.map(val => {
        return val !== Reflect.get(nObj, path[0])
          ? val
          : updateAux(Reflect.get(nObj, path[0]), path.slice(1, path.length));
      });
    }
    if (!nObj && typeof path[0] == 'number') {
      return [v];
    }
    return {
      ...nObj,
      [Reflect.get(path, 0)]: nObj ? updateAux(Reflect.get(nObj, path[0]), path.slice(1, path.length)) : v
    };
  }
  return updateAux(obj, [...t]);
}

/**
 *
 * @param obj
 * @param refrence
 * @returns
 * @description a super version of update without path but a refrence object to look at
 * @example
 * var t_obj = { x: { x1: "1" }, y: { y1: 3, y2: 2 } };
 * var t_refrence = { n: { n1: 5 }, y: { y2: 100, y3: 100 } };
 * const newobj = updateNested(t_obj, t_refrence);
 * console.log(newobj); //{ x: { x1: '1' }, y: { y1: 3, y2: 100, y3: 100 }, n: { n1: 5 } }
 * console.log(t_obj.y == newobj.y); //fale
 * console.log(t_obj.x == newobj.x); //true
 */
export function updateNested(obj, refrence) {
  return Object.entries(refrence ?? {}).reduce((p, c) => {
    if (Array.isArray(c[1])) {
      return update(p, [c[0]], c[1]);
    }
    return update(
      p,
      [c[0]],
      // eslint-disable-next-line no-nested-ternary
      typeof c[1] == 'object' && c[1] != null ? (!p[c[0]] ? c[1] : updateNested(p[c[0]], c[1])) : c[1]
    );
  }, obj ?? {});
}

export function updateNestedMutable(obj, refrence) {
  return Object.entries(refrence ?? {}).reduce((p, c) => {
    if (Array.isArray(c[1])) {
      return updateMutable(p, [c[0]], c[1]);
    }
    return updateMutable(
      p,
      [c[0]],
      // eslint-disable-next-line no-nested-ternary
      typeof c[1] == 'object' && c[1] != null ? (!p[c[0]] ? c[1] : updateNestedMutable(p[c[0]], c[1])) : c[1]
    );
  }, obj ?? {});
}

/**
 *
 * @param obj normal / deepreadonly object
 * @param refrence normal/ deepreadonly object
 * @returns deepreadonly obj same type of "obj" param
 * @description produce new object from "obj" param with new fields from "refrence" param object without changing refrences of "obj" param fields
 * @example
 * var t_obj = { x: { x1: "1" }, y: { y1: 3, y2: 2 } };
 * var t_refrence = { n: { n1: 5 }, y: { y2: 100, y3: 100 } };
 * const newobj = updateNestedHardSet(t_obj, t_refrence);
 * console.log(newobj); //{ x: { x1: '1' }, y: { y1: 3, y2: 2 }, n: { n1: 5 } }
 * console.log(t_obj.y == newobj.y);//true
 * console.log(t_obj.x == newobj.x);//true
 */
export function updateNestedHardSet(obj, refrence) {
  return Object.entries(refrence ?? {}).reduce((p, c) => {
    if (p[c[0]]) {
      return p;
    }
    return update(
      p,
      [c[0]],
      // eslint-disable-next-line no-nested-ternary
      typeof c[1] == 'object' && c[1] != null ? (!p[c[0]] ? c[1] : updateNested(p[c[0]], c[1])) : c[1]
    );
  }, obj);
}
export function updateNestedHardSetMutable(obj, refrence) {
  return Object.entries(refrence ?? {}).reduce((p, c) => {
    if (p[c[0]]) {
      return p;
    }
    return updateMutable(
      p,
      [c[0]],
      // eslint-disable-next-line no-nested-ternary
      typeof c[1] == 'object' && c[1] != null ? (!p[c[0]] ? c[1] : updateNestedMutable(p[c[0]], c[1])) : c[1]
    );
  }, obj ?? {});
}

export const updateState = n_state => old => updateNestedMutable(old, n_state);

