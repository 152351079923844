/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Link,
  Stack,
  Chip,
  CloseIcon,
  IconButton,
  SaveIcon,
  ArrowLeftIcon,
  Loading,
  Box
} from '@trustsecurenow/components-library';
import normalizeText from 'utils/normalizeText';
import SearchInput from './SearchInput';
import ClientsList from './ClientsList';

const filterClientsByQuery = (clients, query) => {
  if (!query) {
    return clients;
  }
  return clients.filter(client => normalizeText(client.name).includes(normalizeText(query)));
};

const filterSelectedClients = (clients, selectedClients = []) =>
  clients.filter(({ id }) => !selectedClients.some(selectedClient => selectedClient.id === id));

const SelectClientsDialog = ({
  open,
  onCloseDialog,
  onBack,
  onSaveCheckedClients,
  selectedClients,
  allClients = [],
  isLoading
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [checkedClients, setCheckedClients] = useState([]);

  const displayedClients = useMemo(
    () => filterSelectedClients(filterClientsByQuery(allClients, searchValue), checkedClients),
    [allClients, searchValue, checkedClients]
  );

  useEffect(() => {
    setCheckedClients(selectedClients);
  }, [open, selectedClients]);

  const handleDeleteClient = clientId => {
    setCheckedClients(prevState => prevState.filter(client => client.id !== clientId));
  };

  const handleChangeSearchValue = e => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSelectClient = (event, client) => {
    const { checked } = event.target;
    setCheckedClients(prevState => (checked ? [...prevState, client] : prevState.filter(({ id }) => id !== client.id)));
  };

  const handleSelectAllClients = event => {
    setCheckedClients(event.target.checked ? allClients : []);
  };

  const handleSaveCheckedClients = () => {
    onSaveCheckedClients(checkedClients);
  };

  return (
    <Dialog
      hideBackdrop
      open={open}
      onClose={onCloseDialog}
      fullWidth
      PaperProps={{
        sx: {
          height: '100%'
        }
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={1.5}>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={onBack}>
            <ArrowLeftIcon sx={{ fontSize: 15 }} />
          </IconButton>
          <DialogTitle variant="h3">Select Clients</DialogTitle>
        </Stack>
        <IconButton onClick={onCloseDialog}>
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Stack>

      <DialogContent dividers sx={{ p: 0 }}>
        {isLoading ? (
          <Box p={2.5}>
            <Loading label="" />
          </Box>
        ) : (
          <Stack spacing={1.25}>
            <Stack p={2.5} spacing={2.75}>
              <SearchInput searchValue={searchValue} onChangeSearchValue={handleChangeSearchValue} />

              <Stack spacing={1.25}>
                <Typography variant="button" textTransform="initial">
                  You added ({checkedClients?.length}) clients in the list
                </Typography>
                <Stack spacing={1.25} direction="row" flexWrap="wrap" useFlexGap>
                  {checkedClients.map(client => (
                    <Chip
                      key={client.id}
                      label={client.name}
                      variant="outlined"
                      onDelete={() => handleDeleteClient(client.id)}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
            <ClientsList
              data={displayedClients}
              onSelectAllClients={handleSelectAllClients}
              checkedAllClients={checkedClients.length === allClients.length}
              onChangeClientsList={handleSelectClient}
            />
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Stack spacing={3.5} direction="row">
          <Link component="button" onClick={onCloseDialog}>
            Cancel
          </Link>
          <Button
            disabled={isLoading}
            width={113}
            startIcon={<SaveIcon />}
            color="info"
            onClick={handleSaveCheckedClients}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SelectClientsDialog;
