import { getIdByResource, decodeId } from 'helpers/utils';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';
import apiClient from '../../apiClient/apiClient';

const BASE_PATH = `${DOMAIN_URL}/dashboard/`;

/**
 * fetches leaderboard data
 * @param app : currentl app name
 * @param sort : { field: name of sorted field, order: sorting direction }
 * @param pagination : { page: starting page, perPage: number of records in each page }
 * @returns a list of the leaderboard records
 */
export const getLeaderBoard = ({ app, sort, pagination }) => {
  const id = getIdByResource(app, 'leaderboard');
  const requestOptions = {
    url: `${BASE_PATH}${app}/leaderboard/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches leaderboard overall data
 * @param app : currentl app name
 * @param sort : { field: name of sorted field, order: sorting direction }
 * @param pagination : { page: starting page, perPage: number of records in each page }
 * @returns a list of the leaderboard overall records
 */
export const getLeaderBoardOverAll = ({ app, sort, pagination }) => {
  const id = getIdByResource(app, 'leaderboardOverall');
  const requestOptions = {
    url: `${BASE_PATH}${app}/leaderboardOverall/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches dashboard stats
 * @param app : current app name
 * @param customID: string id to be used instead of the default id
 * @param tags: list of tags to filter stats with
 */
export const dashboard = ({ app, customID, tags = [] }) => {
  const id = customID || getIdByResource(app, 'dashboard');
  const filters = encodeURI(
    `{"tags":${JSON.stringify(
      tags.map(tag => {
        if (tag.key === 'None') {
          // '0' is the id of the empty tag in the database
          return '0';
        }
        return decodeId(tag.key);
      })
    )}}`
  );
  const requestOptions = {
    url: `${BASE_PATH}${app}/dashboard/${id}?filters=${filters}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getEssTrend = ({ app, customID, tags = [] }) => {
  const id = customID || getIdByResource(app, 'dashboard');
  const filters = encodeURI(
    `{"tags":${JSON.stringify(
      tags.map(tag => {
        if (tag.key === 'None') {
          // '0' is the id of the empty tag in the database
          return '0';
        }
        return decodeId(tag.key);
      })
    )}}`
  );
  const requestOptions = {
    url: `${BASE_PATH}${app}/dashboard/ess-trend/${id}?filters=${filters}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches copmliance dashboard data
 * @param app : current app name
 * @param clientID
 */
export const getComplianceDashboard = ({ app, clientID }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/complianceDashboard/${clientID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches individual ess ranking
 * @param app : current app name
 * @param customID: string id to be used instead of the default id
 * @param filter: filtering object, contains filtering tags.
 * @param sort: object constains sort.field and sort.order
 * @param pagination: object contains pagination.page and pagination.perPage
 * @returns axios get response
 */
export const getIndividualEssRanking = ({ app, customID, filters, sort, pagination }) => {
  const id = customID || getIdByResource(app, 'individualEssRanking');
  const requestOptions = {
    url: `${BASE_PATH}${app}/individualEssRanking/${id}?filters=${encodeURI(filters)}&_start=${pagination.page}&_end=${
      pagination.perPage
    }&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getMicroTrainingStats = ({ app, id }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/microTrainingsStats/${id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches policy types for a specific client
 * @param clientID : id of the client to get policy types for
 * @param app : name of the currently viewed app
 * @returns axios get response
 */
export const getPolicyTypes = ({ clientID, app }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policyType/${clientID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * fetches security policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, in myDashboard it is the user id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @implements there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
export const getSecurityPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/securityPolicies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @implements there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
export const getPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * create a new  policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new policy data
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
export const createPolicy = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policies/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * delete  policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of policies IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
export const deletePolicies = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policies/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * create a new other policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new policy data
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
export const createOtherPolicy = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicies/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * delete an other policy
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of policies IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
export const deleteOtherPolicies = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicies/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * fetches other policies
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, in myDashboard it is the user id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 * @todo there are sorting, pagination, and filtering params
 * that are sent with the old api, but actually they aren't applied
 * so they are removed from this api call
 */
export const getOtherPolicies = ({ app, id, sort, pagination, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicies/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * used to acknowledge the security policies
 * @param userID : id of the current authenticated user
 * @param app : name of the currently viewed app
 * @returns axios put response
 */
export const setSecurityPolicyAcknowledgement = ({ userID, app }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/setSecurityPolicyAcknowlegment/${userID}`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

/**
 * used to acknowledge the other policies
 * @param userID : id of the current authenticated user
 * @param app : name of the currently viewed app
 * @returns axios put response
 */
export const setOtherPolicyAcknowledgement = ({ userID, app }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/setOtherPolicyAcknowlegment/${userID}`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

/**
 * used to fetch the newsletters list
 * @param userID : id of the current authenticated user
 * @param app : name of the current app
 * @param pagination object
 * @param sort object
 * @returns axios get response
 */
export const getNewsletters = ({ userID, app, sort, pagination }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/newsletters/${userID}?_start=${pagination.page}&_end=${pagination.perPage}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the url of a specific newsletter
 * @param app: name of the current app
 * @param id : of the newsletter to download
 * @returns axios get response
 */
export const getNewsletter = ({ app, id }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/newsletter/${id}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific security policy
 * @param app: name of the current app
 * @param id : of the security Policy to download
 * @returns axios get response
 */
export const getSecurityPolicy = ({ app, policyID }) => {
  const id = getIdByResource(app, 'securityPolicy');

  const requestOptions = {
    url: `${BASE_PATH}${app}/securityPolicy/${id}?policyId=${policyID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific policy
 * @param app: name of the current app
 * @param id : of the Policy to download
 * @returns axios get response
 */
export const getPolicyInfo = ({ app, policyID }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policyInfo/${policyID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to update the details of a specific policy
 * @param app: name of the current app
 * @param id : of the Policy
 * @param data: policy updated data
 * @returns axios get response
 */
export const updatePolicyInfo = ({ app, policyID, data }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/policyInfo/${policyID}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

/**
 * used to fetch the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy to download
 * @returns axios get response
 */
export const getOtherPolicy = ({ app, policyID }) => {
  const id = getIdByResource(app, 'otherPolicy');

  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicy/${id}?policyId=${policyID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to fetch the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy to download
 * @returns axios get response
 */
export const getOtherPolicyInfo = ({ app, policyID }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicyInfo/${policyID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

/**
 * used to update the details of a specific other policy
 * @param app: name of the current app
 * @param id : of the other Policy
 * @param data: policy updated data
 * @returns axios get response
 */
export const updateOtherPolicyInfo = ({ app, policyID, data }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/otherPolicyInfo/${policyID}`,
    headers: getHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

export const presignedUpload = ({ app, id, data }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/presignedUpload/${id}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

/**
 * fetches documents types of a specific client
 * @param app: name of the current app
 * @param id : of the current viewed client
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
export const getDocumentsTypes = ({ app, clientID, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/documents/${clientID}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches Documents
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 *
 * list of endpoints that are called using this function
 * 1. clients/documentsTemplates/:id
 * 2. clients/otherDocuments/:id
 * 3. clients/documentsServiceProvider/:id
 * 4. clients/disasterRecovery/:id
 * 5. myCompany/sraDocuments/:id
 * 6. myCompany/workPlan/:id
 */
export const getDocuments = ({ app, documentType, id, filters, sort, pagination, cancelToken }) => {
  const filtersString = `${filters.categoryID ? `category_id:${filters.categoryID}` : ''}`;
  const requestOptions = {
    url: `${BASE_PATH}${app}/${documentType}/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_filter=${filtersString}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * fetches Documents
 * @param app : name of the currently viewed app
 * @param id : document id
 * @param cancelToken: axios cancel token
 * @returns axios get response
 *
 * list of endpoints that are called using this function
 * 1. clients/disasterRecoveryInfo/:id
 * 2. clients/documentsServiceProviderInfo/:id
 * 3. clients/otherDocumentsInfo/:id
 * 4. myCompany/sraDocumentsInfo/:id
 * 5. myCompany/workPlanFindingInfo/:id
 */
export const getDocumentInfo = ({ app, documentType, id, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/${documentType}/${id}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * creates a new  document
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: new document data
 * @param cancelToken: axios cancel token
 * @returns axios post response
 * list of endpoints that are called using this function
 * 1. clients/otherDocuments/:id
 * 2. clients/documentsServiceProvider/:id
 * 3. clients/disasterRecovery/:id
 * 4. myCompany/sraDocuments/:id
 * 5. myCompany/workPlan/:id
 */
export const createDocument = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/${documentType}/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.post(requestOptions);
};

/**
 * used to update the details of a specific document
 * @param app: name of the current app
 * @param id : of the document
 * @param data: document updated data
 * @returns axios put response
 *
 * list of endpoints that are called using this function
 * 1. clients/disasterRecoveryInfo/:id
 * 2. clients/documentsServiceProviderInfo/:id
 * 3. clients/otherDocumentsInfo/:id
 * 4. myCompany/sraDocumentsInfo/:id
 * 4. myCompany/workPlanFindingInfo/:id
 */
export const updateDocument = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/${documentType}/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.put(requestOptions);
};

/**
 * fetches Documents Service Provider Relationships
 * @param app : name of the currently viewed app
 * @param id : changes according to tab, usualy client id
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
export const getDocumentsServiceProviderRelationships = ({ app, id, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/documentsServiceProviderRelationships/${id}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * delete  documents
 * @param app : name of the currently viewed app
 * @param id : may differ according to the app, usually its client id
 * @param data: array of documents IDs to be deleted
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 * 1. clients/otherDocuments/:id
 * 2. clients/documentsServiceProvider/:id
 * 3. clients/disasterRecovery/:id
 * 4. myCompany/sraDocuments/:id
 * 5. myCompany/workPlan/:id
 */
export const deleteDocuments = ({ app, documentType, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/${documentType}/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

/**
 * fetches workplan findings
 * @param app : name of the currently viewed app
 * @param id : of the selected workplan
 * @param sort : {field: name of sorted field, order: sorting direction}
 * @param pagination :{page: starting page, perPage: number of records in each page}
 * @param filter: {type: finding type}
 * @param cancelToken: axios cancel token
 * @returns axios get response
 */
export const getWorkPlanFindings = ({ app, id, filters, sort, pagination, cancelToken }) => {
  const filtersString = `${filters.type ? `type:${filters.type}` : ''}`;
  const requestOptions = {
    url: `${BASE_PATH}${app}/workPlanFinding/${id}?_start=${pagination.page}&_end=${pagination.perPage}&_filter=${filtersString}&_sort=${sort.field}&_order=${sort.order}`,
    headers: getHeaders()
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.get(requestOptions);
};

/**
 * deletes a workplan finding
 * @param app : name of the currently viewed app
 * @param id : of the selected workplan
 * @param cancelToken: axios cancel token
 * @returns axios delete response
 */
export const deleteWorkPlanFinding = ({ app, id, data, cancelToken }) => {
  const requestOptions = {
    url: `${BASE_PATH}${app}/workPlanFinding/${id}`,
    headers: getHeaders(),
    data
  };
  if (cancelToken) requestOptions.cancelToken = cancelToken;
  return apiClient.delete(requestOptions);
};

export const getCompletedCourses = ({
  mobile = false,
  order = null,
  orderBy = null,
  start = null,
  end = null
}) => {
  const requestOptions = {
    url: mobile
      ? `${BASE_PATH}/myDashboard/securityTraining`
      : `${BASE_PATH}/myDashboard/securityTraining?_sort=${orderBy}&_order=${order}&_start=${start}&_end=${end}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};