import React, { cloneElement, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useContentAdminPage } from '../hooks';
import { LoadingStyled } from 'components';
import {
  CONTENT_ADMIN,
  CONTENT_ADMIN_CAMPAIGN,
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_ROOT_PATH,
  CONTENT_ADMIN_SCENARIO,
  CONTENT_ADMIN_SENDING_PROFILES
} from '../constants';
import { CardPageLayout, EmailTemplateCard, LandingPagesCard, ScenarioPagesCard } from '../components';
import { CardPageProvider } from '../context';
import CardPageLayoutBox from '../components/CardPageLayout/CardPageLayoutBox';
import { CardPageNoResult } from '../style';
import { PreviewHTMLModal } from 'components';
import { useCheckAccess } from 'helpers';

const cardWrapperObj = {
  [CONTENT_ADMIN_EMAIL_TEMPLATES]: <EmailTemplateCard />,
  [CONTENT_ADMIN_LANDING_PAGES]: <LandingPagesCard />,
  [CONTENT_ADMIN_SCENARIO]: <ScenarioPagesCard />
};

const ContentAdminCardPage = () => {
  const hasAccess = useCheckAccess('app', CONTENT_ADMIN);

  const [dialogData, setDialogData] = useState({
    open: false,
    data: null
  });

  const history = useHistory();
  const { page } = useParams();

  if (page === CONTENT_ADMIN_SENDING_PROFILES) {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}`);
  }

  const isValidPage = Object.keys(cardWrapperObj).includes(page);

  const {
    isLoading,
    isFetching,
    data: record,
    limit,
    page: currentPage,
    dispatch,
    search,
    pickLists,
    filters
  } = useContentAdminPage(page, isValidPage);

  if (isLoading) {
    return <LoadingStyled />;
  }

  const pagination = {
    total: record?.data?.count || 0,
    limit: limit || 9,
    page: currentPage || 1
  };

  const cardWrapper = cardWrapperObj[page];

  const handleAddDialogDataAndOpen = data => {
    setDialogData({ open: true, data });
  };

  const handleCloseDialog = () => {
    setDialogData(prevState => ({ ...prevState, open: false }));
  };

  if (!isValidPage) {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}`);
    return null;
  }

  if (!hasAccess && hasAccess !== null) return <div>You don't have access for this page</div>;

  return (
    <>
      <CardPageProvider
        value={{
          pagination,
          search,
          dispatch,
          pagePath: page,
          pickLists,
          filters
        }}
      >
        <PreviewHTMLModal
          {...dialogData.data}
          title={'Template preview'}
          selector={'body > *'}
          open={dialogData.open}
          onClose={handleCloseDialog}
        />
        <CardPageLayout>
          {record?.data?.result?.length === 0 ? (
            <CardPageNoResult>
              <h2>No results.</h2>
            </CardPageNoResult>
          ) : (
            record?.data?.result.map(({ id, status, ...restParam }) => {
              return (
                <CardPageLayoutBox id={id} status={status} isFetching={isFetching}>
                  {cloneElement(cardWrapper, {
                    ...restParam,
                    status,
                    id,
                    setDialogDataAndOpen: handleAddDialogDataAndOpen
                  })}
                </CardPageLayoutBox>
              );
            })
          )}
        </CardPageLayout>
      </CardPageProvider>
    </>
  );
};

export default ContentAdminCardPage;
