// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const MyCompany = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 25.8 20.6" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h25.8v20H6z" />
    <g>
      <path d="M24.9,18.9h-4.3v-18c0-0.5-0.4-0.9-0.9-0.9H6C5.5,0,5.2,0.4,5.2,0.9v18H0.9c-0.5,0-0.9,0.4-0.9,0.9   s0.4,0.9,0.9,0.9h24.1c0.5,0,0.9-0.4,0.9-0.9S25.4,18.9,24.9,18.9z M14.6,18.9h-3.4v-3.4h3.4V18.9z M18.9,18.9h-2.6v-4.3   c0-0.5-0.4-0.9-0.9-0.9h-5.2c-0.5,0-0.9,0.4-0.9,0.9v4.3H6.9V1.7h12V18.9z" />
      <path d="M9.9,6.3H11c0.3,0,0.5-0.2,0.5-0.5V4.7c0-0.3-0.2-0.5-0.5-0.5H9.9c-0.3,0-0.5,0.2-0.5,0.5v1.1   C9.4,6.1,9.6,6.3,9.9,6.3z" />
      <path d="M9.9,11.3H11c0.3,0,0.5-0.2,0.5-0.5V9.8c0-0.3-0.2-0.5-0.5-0.5H9.9c-0.3,0-0.5,0.2-0.5,0.5v1.1   C9.4,11.1,9.6,11.3,9.9,11.3z" />
      <path d="M14.7,6.3h1.1c0.3,0,0.5-0.2,0.5-0.5V4.7c0-0.3-0.2-0.5-0.5-0.5h-1.1c-0.3,0-0.5,0.2-0.5,0.5v1.1   C14.3,6.1,14.5,6.3,14.7,6.3z" />
      <path d="M14.7,11.3h1.1c0.3,0,0.5-0.2,0.5-0.5V9.8c0-0.3-0.2-0.5-0.5-0.5h-1.1c-0.3,0-0.5,0.2-0.5,0.5v1.1   C14.3,11.1,14.5,11.3,14.7,11.3z" />
    </g>
  </SvgIconStyled>
);

export default MyCompany;
