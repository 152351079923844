// @flow

const newPhishingCampaign = {
  title: 'Add Phishing Campaign',
  component: 'shared/phishingCampaign/PhishingCampaign',
  noQuery: true,
  buttons: {},
  initialState: {
    data: {
      name: '',
      is_test_campaign: 0,
      date_start: '',
      date_end: '',
      note: '',
      scenarios_list: [],
      recipients_list: ['all'],
      recipients_list_exclude: [],
      recipients_filter: {},
      white_listing_acknowledgement: false
    },
    type: 'single',
    scenario_difficulty: 0,
    scenario_name: '',
    recipients_total: 0,
    step: 0,
    createApi: 'createPhishingCampaign',
    error: false,
    errorMessage: ''
  }
};

export default newPhishingCampaign;
