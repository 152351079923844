import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Container, RichTextEditor, TextField, Typography } from 'components';
import { CharacterProgress } from 'apps/newsfeed/components';
import { PN_SUBSCRIPTION_CUSTOM_MSG_LIMIT } from './helpers';

/**
 * overriding the color set of the text editor
 * to fix the bug of removing the text color when setting it to black
 * or removing the background color when setting it to white
 */
const colorOptions = [
  `rgb(0, 0,0)`,
  `rgb(230, 0, 0)`,
  `rgb(255, 153, 0)`,
  'rgb(255, 255, 0)',
  'rgb(0, 138, 0)',
  'rgb(0, 102, 204)',
  'rgb(153, 51, 255)',
  'rgb(255, 255, 255)',
  'rgb(250, 204, 204)',
  'rgb(255, 235, 204)',
  'rgb(255, 255, 204)',
  'rgb(204, 232, 204)',
  'rgb(204, 224, 245)',
  'rgb(235, 214, 255)',
  'rgb(187, 187, 187)',
  'rgb(240, 102, 102)',
  'rgb(255, 194, 102)',
  'rgb(255, 255, 102)',
  'rgb(102, 185, 102)',
  'rgb(102, 163, 224)',
  ' rgb(194, 133, 255)',
  'rgb(136, 136, 136)',
  'rgb(161, 0, 0)',
  'rgb(178, 107, 0)',
  'rgb(178, 178, 0)',
  'rgb(0, 97, 0)',
  'rgb(0, 71, 178)',
  'rgb(107, 36, 178)',
  'rgb(68, 68, 68)',
  'rgb(92, 0, 0)',
  'rgb(102, 61, 0)',
  'rgb(102, 102, 0)',
  'color: rgb(0, 55, 0)',
  'color: rgb(0, 41, 102)',
  'rgb(61, 20, 102)'
];

const editorOptions = [
  [{ size: ['0.75em', false, '1.5em', '2.5em'] }],
  [{ font: [] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ color: colorOptions }, { background: colorOptions }],
  ['link', 'image']
];


function CustomMessage({ state, setState, initialData }) {
  const atValues = React.useMemo(() => {
    return state?.picklist?.custom_message_picklist?.map(f => {
      return {
        value: f
          .replace(/_/g, ' ')
          .split(' ')
          .map(word => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(' '),
        url: f
      };
    });
  }, [state?.picklist?.custom_message_picklist]);

  return (
    <>
      {/* Use custom message */}
      {Boolean(state?.push_notification?.show_use_custom_message) && (
        <Container.Grid py={1.2} container>
          <Container.Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  label=""
                  value={state.subscription.use_custom_message}
                  checked={state.subscription.use_custom_message === 1}
                  name="custom_message"
                  onClick={e => {
                    const newVal = e.target.checked ? 1 : 0;
                    setState(old => ({
                      ...old,
                      subscription: {
                        ...old.subscription,
                        use_custom_message: newVal,
                        // reset message body and subject on check change to reflect correct save button state
                        custom_message_body: initialData.subscription.custom_message_body,
                        custom_message_subject: initialData.subscription.custom_message_subject
                      }
                    }));
                  }}
                />
              }
              label="Use custom message"
            />
          </Container.Grid>
        </Container.Grid>
      )}

      {/* Use custom message content */}
      {state.subscription.use_custom_message === 1 && (
        <>
          <Container.Grid container>
            <Typography.p>* use {'{'} to insert dynamic text (e.g. First Name, Course Name)</Typography.p>
          </Container.Grid>
          <Container.Grid container>
            <Container.Grid item xs={12}>
              <Typography.p>Customize Message subject</Typography.p>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid container>
            <Container.Grid item xs={12}>
              <TextField
                name="customize_message_subject"
                value={state.subscription.custom_message_subject}
                onChange={e => {
                  const newVal = e.target.value;
                  setState(old => ({
                    ...old,
                    subscription: { ...old.subscription, custom_message_subject: newVal }
                  }));
                }}
                fullWidth
              />
            </Container.Grid>
          </Container.Grid>
          <Container.Grid container>
            <Container.Grid item xs={12}>
              <Typography.p>Customize message body</Typography.p>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid container>
            <Container.Grid item xs={12} className="editor-wrap">
              <RichTextEditor
                name="push_notification"
                currentState={state.subscription.custom_message_body}
                enableOnChangeApiSource={false}
                bounds=".editor-wrap"
                toolbarOptions={editorOptions}
                autoComplete={{
                  allowedChars: /^[a-zA-Z0-9_]*$/,
                  mentionDenotationChars: ['{', '#'],
                  showDenotationChar: false,
                  source(searchTerm, renderItem, mentionChar) {
                    let values;
                    if (mentionChar === '{') {
                      values = atValues;
                    }
                    if (searchTerm.length === 0) {
                      renderItem(values, searchTerm);
                    } else {
                      const matches = [];
                      for (let i = 0; i < values.length; i++)
                        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                      renderItem(matches, searchTerm);
                    }
                  },
                  onSelect(item, insertItem) {
                    item.value = `{{${item.value
                      .toLowerCase()
                      .split(' ')
                      .join('_')}}}`;
                    insertItem(item);
                  }
                }}
                onChangeEditorState={(e, source) => {
                  // if (source === 'user') {
                  //   validateForm();
                  // }
                  setState(old => ({ ...old, subscription: { ...old.subscription, custom_message_body: e } }));
                }}
              />
            </Container.Grid>
          </Container.Grid>
          <br />
          <CharacterProgress
            length={state.subscription.custom_message_body?.length}
            limit={PN_SUBSCRIPTION_CUSTOM_MSG_LIMIT}
            mr={16}
            customErrorText="Maximum custom message body size exceeded 65 KB"
          />
        </>
      )}
    </>
  );
}

export default CustomMessage;
