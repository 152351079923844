/* eslint-disable react/prop-types */
// @flow
import React, { cloneElement, type Element, useState, useEffect, type ComponentType } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  Filter,
  // BooleanField,
  CreateButton,
  FunctionField,
  TopToolbar,
  SearchInput,
  BooleanInput,
  useRefresh,
  SelectInput,
  sanitizeListRestProps,
  useNotify,
  Pagination as RaPagination,
  useQuery,
  SelectArrayInput,
  TextField,
  CRUD_CHANGE_LIST_PARAMS
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  Toolbar,
  CircularProgress,
  useTheme,
  useMediaQuery,
  TableCell,
  Box,
  alpha
} from '@material-ui/core';

import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { CloseIcon } from 'components/common/ComponentTypes';
import {
  List,
  ChartHalfCircle,
  LazyIcon,
  Typography,
  CustomTooltip,
  Modal,
  Button,
  ButtonCancel,
  SubmissionModal
} from 'components';
import { Info, Warning as AlertIcon } from 'components/icons';
import { dataProvider, useApp, dataPost, setUserNewsfeedAppAccess, getStorage, getActiveTab } from 'helpers';
import { useId } from 'hooks';
import { Alert, Pagination as MuiPagination } from '@material-ui/lab';
import CopyButton from 'components/buttons/CopyButton';
import { BSN_SET_ANY } from 'conf';
import { LockedContent, ObscureSpan } from 'components/common/ContentModifiers';
import EmailAlert from 'components/icons/EmailAlert';
import ClientsCreate from './ClientsCreate';
import ClientsEdit from './ClientsEdit';
import reporting from '../../helpers/apis/reporting';
import useHasInternet from '../../hooks/useHasInternet';
import clients from '../../helpers/apis/clients';
import ClientsListMobile from './mobileClients/ClientsListMobile';
import ClientIndustryModal from './ClientIndustryModal';
import DeactivateClientModal from './mobileClients/DeactivateClientModal';
import { ArrowUndoIcon, BlockIcon, IconButton, Stack, Tooltip } from '@trustsecurenow/components-library';

type ClientsListProps = Object;

const Chart = styled(ChartHalfCircle)`
  && {
    width: 5rem;
    height: 3rem;
    display: table-caption;

    text {
      font-size: 2.5rem;
      transform: translateX(-9px);
    }

    path {
      stroke-width: calc(var(--spacing) * 1.4);
    }

    .chart-halfcircle-foregournd {
      stroke: var(
        ${({ chart: { chartData } }) => {
          if (chartData[0].name < 500) return '--colorSystemDanger';
          if (chartData[0].name < 630) return '--colorSystemWarning';
          return '--colorSystemSuccess';
        }}
      );
    }
  }
`;

const HeadTopbar: ComponentType<*> = styled(TopToolbar)`
  && {
    padding: 0;

    & button,
    a {
      text-transform: capitalize;
      background-color: var(--colorBaseBase);
      color: var(--colorCommonWhite);
      padding: calc(var(--spacing) * 0.5) calc(var(--spacing) * 2);
      margin: 0 calc(var(--spacing) * 2);
      font-weight: var(--fontWeightRegular);
    }
  }
`;

const FilterSearchInput: ComponentType<*> = styled(SearchInput)`
  && {
    margin: 0;
    & > div {
      display: flex;
      flex-direction: row-reverse;
      border-radius: var(--borderRadius);
      width: calc(var(--spacing) * 45);
      background-color: var(--backgroundDefault);
      padding: calc(var(--spacing) * 0.2) 0;
      &:before,
      &:after {
        display: none;
      }

      & svg {
        font-size: calc(var(--fontSize) * 1.5);
      }
    }
  }
`;

const FilterSelectInput: ComponentType<*> = styled(SelectInput)`
  && {
    margin: 0;
    & > div {
      display: flex;
      border-radius: var(--borderRadius);
      width: calc(var(--spacing) * 45);
      background-color: var(--backgroundDefault);
      &:before,
      &:after {
        display: none;
      }

      & svg {
        font-size: calc(var(--fontSize) * 1.5);
        margin-top: calc(var(--spacing) * 0.4);
      }
    }
  }
`;

const MultiSelectInput: ComponentType<*> = styled(SelectArrayInput)`
  && {
    margin: 0;

    & > div {
      display: flex;
      border-radius: var(--borderRadius);
      width: calc(var(--spacing) * 45);
      background-color: var(--backgroundDefault);
      &:before,
      &:after {
        display: none;
      }
      & > div {
        padding-top: 16px;
        & > div {
          & > div {
            background: inherit;
            & > span {
              padding: 0;
            }
            &:not(:last-of-type)::after {
              content: ',';
            }
          }
        }
      }
      & svg {
        font-size: calc(var(--fontSize) * 1.5);
        margin-top: calc(var(--spacing) * 0.4);
      }
    }
  }
`;

const CompanyName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 1.4rem;
`;

const ProductType = styled.span`
  display: block;
  font-size: 1.2rem;
`;

const NoResults = styled.h2`
  text-align: center;
  color: var(--colorDefault);
  padding: 30px;
`;

const PaginationToolbar: ComponentType<*> = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PaginationAction: ComponentType<*> = styled(MuiPagination)`
  && {
    min-width: ${({ count }) => count && `calc(38px * ${(count < 7 ? count : 7) + 2})`};
    ul {
      flex-wrap: nowrap;
    }
  }
`;

const Pagination: ComponentType<*> = styled(RaPagination)`
  margin: var(--spacing);
  font-size: calc(var(--fontSize) * 1.2);
`;

const PaginationLink: ComponentType<*> = styled(Typography.p)`
  cursor: pointer;
`;

const ArrowIconForward: ComponentType<*> = styled(ArrowForwardIosIcon)`
  margin-left: calc(var(--spacing) * 1.3);
  font-size: calc(var(--fontSize) * 1.3);
`;

const ArrowIconBack: ComponentType<*> = styled(ArrowBackIosIcon)`
  margin-right: calc(var(--spacing) * 1.3);
  font-size: calc(var(--fontSize) * 1.3);
`;

const ActionsContainer = styled.div`
  display: inline-flex;
`;

const ClientListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}: Object) => (
  <HeadTopbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton label="New Client" basePath={basePath} />
  </HeadTopbar>
);

const ClientListFilter = props => {
  const app_dispatch = useDispatch();
  const partnerId = useId({ key: 'partnerId' });
  const [productsType, setProductsType] = useState(null);
  const hasInternet = useHasInternet();
  const notify = useNotify();
  const { is_updated_used_industries, client_used_industries, client_id, user_role } = useSelector(
    s => s?.bsn?.user?.profile ?? {}
  );
  const isAdmin = user_role === 'Administrator';
  useEffect(() => {
    // dispatch.set('clients', 'information', null);
    app_dispatch({ type: BSN_SET_ANY, payload: { clients: { information: null } } });
    return () => {
      app_dispatch({ type: CRUD_CHANGE_LIST_PARAMS, payload: { filter: {} }, meta: { resource: 'clients' } });
    };
  }, [app_dispatch]);

  useEffect(() => {
    if (productsType === null) {
      dataProvider.getList('clients', `productsType/${partnerId}`, {}).then(res => {
        hasInternet(() => {
          setProductsType(res.data);
        });
      });
    }
  }, [productsType]);

  React.useEffect(() => {
    if (!client_id) {
      return;
    }
    if (is_updated_used_industries && client_used_industries) {
      return;
    }
    clients
      .getPickList({
        client_id,
        types: 'used_industries'
      })
      .then(response => {
        if (response.data) {
          app_dispatch({
            type: BSN_SET_ANY,
            payload: {
              user: {
                profile: {
                  is_updated_used_industries: true,
                  client_used_industries:
                    response?.data?.used_industries?.map(v => ({ value: v.id, label: v.name })) ?? []
                }
              }
            }
          });
        }
      })
      .catch(err => {
        notify('Failed to get Client Industries', 'error');
      });
  }, [app_dispatch, client_id, client_used_industries, is_updated_used_industries, notify]);

  return (
    <Filter {...props}>
      <FilterSearchInput source="name" alwaysOn allowEmpty />
      {productsType && (
        <FilterSelectInput source="product_type" emptyText="Product" label="Product" choices={productsType} />
      )}
      {Boolean(client_used_industries && client_used_industries.length) && (
        <MultiSelectInput
          optionValue="value"
          optionText="label"
          source="industry_id"
          emptyText="Industry"
          label="Industry"
          choices={client_used_industries}
        />
      )}
      <BooleanInput label="Active" source="active" defaultValue />
      <BooleanInput label="Branding" source="branding" defaultValue />
      <BooleanInput label="Consulting" source="ra_consulting" defaultValue />
      {isAdmin && <BooleanInput label="Deleted" source="deleted" defaultValue />}
      <BooleanInput label="Insurance" source="insurance" defaultValue />
    </Filter>
  );
};

const StyledPagination = ({ page, perPage, total, setPage, loaded, ...props }: ClientsListProps) => {
  const start = page * perPage - (perPage - 1);
  const end = Math.min(start + perPage - 1, total);
  const loadingList = getStorage('showLoading', true) === 'true';

  useEffect(() => {
    // reset page in initial time
    if (page > 1) {
      setPage(1);
    }
  }, []);

  if (loadingList) return null;

  return (
    <>
      {loaded && total === '0' && <NoResults>No results.</NoResults>}

      {total !== '0' && (
        <Pagination
          {...props} // native pagination component for List from react-admin
          component="div"
          labelRowsPerPage={
            <Typography.p fontSize={12} mr={2} ml={5}>
              {start} - {end} of {total} Items
            </Typography.p>
          }
          labelDisplayedRows={() => (
            <Typography.p fontSize={12} mr={2}>
              Items per page
            </Typography.p>
          )}
          rowsPerPageOptions={[25, 50, 100, 200]} // same default select for rows Per page
          ActionsComponent={PaginationActions}
          SelectProps={{
            disabled: props.loading
          }}
        />
      )}
    </>
  );
};

const PaginationActions = ({ ...props }) => {
  const nbPages = Math.ceil(props.count / props.rowsPerPage) || 1;
  return (
    <PaginationAction
      count={nbPages}
      page={props.page + 1}
      onChange={(event, paginationPage) => props.onPageChange(event, paginationPage - 1)}
    />
  );
};

const FunctionFieldNameRender = React.memo(props => {
  return (
    <>
      <CompanyName>
        <span>{props.name}</span>
      </CompanyName>
      <ProductType>
        Product: {props.product_type}
        {props.is_visible_client_id && (
          <>
            &nbsp;&nbsp; | Client ID: {props.clientid}&nbsp;&nbsp;
            <CopyButton
              text={props.clientid}
              message={`Client ID: ${props.clientid} has been copied to the clipboard`}
              iconProps={{ size: 0.8 }}
            />
          </>
        )}
      </ProductType>
      <span style={{ fontSize: '1.2rem' }}>Industry: {props.clientIndustry || 'Not selected'}</span>
    </>
  );
});
const FunctionFieldBrandingRender = React.memo(props => {
  return props.branding && <LazyIcon component="Shield" size={1.6} />;
});
const FunctionFieldRaConsultRender = React.memo(props => {
  return props.ra_consulting && <LazyIcon component="Consulting" size={1.6} />;
});
const FunctionFieldInsuranceCodeRender = React.memo(props => {
  return (
    props.insurance_code !== '' && (
      <a href={props.insurance_code} target="_blank" onClick={e => e.stopPropagation()} rel="noopener noreferrer">
        <LazyIcon component="Insurance" size={1.6} />
      </a>
    )
  );
});
const FunctionFieldRaCompleteRender = React.memo(props => {
  return props.ra_complete && <span style={{ whiteSpace: 'nowrap' }}>{props.ra_complete}</span>;
});
const FunctionFieldIndustryRender = React.memo(props => {
  return props.isLoading ? (
    <CircularProgress size={18} thickness={2} />
  ) : props.clientIndustry ? (
    <CustomTooltip placement="top" title={`Industry: ${props.clientIndustry ?? 'Industry Name'}`}>
      <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
    </CustomTooltip>
  ) : (
    <Tooltip placement="top" title="Click here to select industry">
      <IconButton aria-label="Client NewsFeed Control" disabled={props.isLoading} onClick={props.onClick}>
        <LazyIcon component="Disable" color="colorSystemDanger" />
      </IconButton>
    </Tooltip>
  );
});
const FunctionFieldAvgESSRender = React.memo(props => {
  return (
    props.average_ess && (
      <div style={{ display: 'inline-flex' }}>
        <Chart
          title="Average ESS"
          chart={{
            chartData: [{ name: props.average_ess, value: (props.average_ess * 100) / 800 }]
          }}
          labels
          showOf={false}
          showLegends={false}
          showTitle={false}
          classses={{ root: 'chart-root' }}
        />
      </div>
    )
  );
});

const FunctionFieldActiveRender = React.memo(props => {
  return props.isAdmin && props.isFilteringDeleted ? (
    <Stack direction="row">
      <BlockIcon color="error" style={{ fontSize: 18, padding: 8 }} />
      <Tooltip title="Undelete Client" placement="top">
        <IconButton aria-label="Undelete Client" onClick={props.onUndeleteClient}>
          <ArrowUndoIcon color="info" style={{ fontSize: 18 }} />
        </IconButton>
      </Tooltip>
    </Stack>
  ) : props.product_type === 'Unlimited Cybersecurity Training' ? (
    <>
      {props.active ? (
        <CustomTooltip title="Disable Client" placement="top">
          <IconButton aria-label="Deactivate Client" onClick={props.onEnableClick}>
            <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
          </IconButton>
        </CustomTooltip>
      ) : (
        <>
          <IconButton aria-label="Deactivate Client" onClick={props.onDisableClick}>
            <LazyIcon component="Disable" color="colorSystemDanger" />
          </IconButton>
          <IconButton aria-label="Deactivate Client" onClick={props.onDeleteClick}>
            <LazyIcon component="Delete" color="colorSystemDanger" />
          </IconButton>
        </>
      )}
    </>
  ) : props.product_type === 'Breach Prevention Platform (BPP)' && props.product_code === 'EBPPC-0' ? (
    <>
      {props.active ? (
        <CustomTooltip title="Deactivate Client" placement="top">
          <IconButton aria-label="Deactivate Client" onClick={props.onEnableClick}>
            <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
          </IconButton>
        </CustomTooltip>
      ) : (
        <ActionsContainer>
          <CustomTooltip
            placement="top"
            title={
              props.isAdmin ? (
                'Activate Client'
              ) : (
                <span>
                  Client Inactive: <br /> Activate to Access Account
                </span>
              )
            }
          >
            <IconButton aria-label="Activate Client" onClick={props.onDisableClick}>
              <LazyIcon component="Disable" color="colorSystemDanger" />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="Delete Client" placement="top">
            <IconButton aria-label="Delete Client" onClick={props.onDeleteClick}>
              <LazyIcon component="Delete" color="colorSystemDanger" />
            </IconButton>
          </CustomTooltip>
        </ActionsContainer>
      )}
    </>
  ) : (
    <>
      {props.active ? (
        <CustomTooltip title="Contact support to downgrade client" placement="top">
          <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
        </CustomTooltip>
      ) : (
        <LazyIcon component="Disable" color="colorSystemDanger" />
      )}
    </>
  );
});

const FunctionFieldClientReportRender = React.memo(props => {
  return props.isLoading ? (
    <CircularProgress size={18} thickness={2} />
  ) : (
    <Tooltip title="Download Client Report">
      <IconButton aria-label="Download Client Report" disabled={props.isLoading} onClick={props.onClick}>
        <LazyIcon component="SpreadSheet" color="buttonPrimaryBackground" size={1.4} />
      </IconButton>
    </Tooltip>
  );
});

const FunctionFieldRenderWrapper = (Component, disable_wrapper) => {
  return render_props => {
    if (disable_wrapper) {
      return <Component {...render_props} />;
    }

    const { enterprise_partner, active, product_code } = render_props;
    if (!active && enterprise_partner === true && product_code === 'EBPPC-0') {
      return (
        <LockedContent locked opacity="0.5">
          <Component {...render_props} />
        </LockedContent>
      );
    }

    return <Component {...render_props} />;
  };
};

let timer = 0;

const ClientList = (props: ClientsListProps): Element<*> => {
  /**
   * clients list page, doens't use Tabs
   * component, so redirection doesn't happen to microtrainings
   * so redirection logic, is re-applied here
   */
  const { push, replace, location } = useHistory();
  const history = useHistory();
  const historyUpdate = location.pathname.includes('undefined') ? replace : push;
  if (sessionStorage.getItem('microtraining_id')) historyUpdate(`/myDashboard/microTrainings`);
  const { bsn } = useSelector(rxState => rxState);
  const profile = useSelector(state => state?.bsn?.user?.profile);
  const isAdmin = profile?.user_role === 'Administrator';
  const is_enterprise_partner = profile?.is_enterprise_partner;

  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const hasAccess = accessApps?.hasOwnProperty('clients');
  if (accessApps && !hasAccess) window.location.href = '/#/myDashboard/dashboard';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const disabled_system_email_clients_count = profile?.disabled_system_email_clients_count;

  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const currentClient = getStorage('clientId', true);
  const [openDialog, setOpen] = useState(false);

  const [idOnOpen, setIdOnOpen] = useState(null);
  const [idOnReport, setIdOnReport] = useState(null);
  const [idOnNewsfeed, setIdOnNewsfeed] = useState(null);

  const { client_industries } = useSelector(s => s?.bsn?.client ?? {});
  const [clientListState, setClientListState] = React.useState({
    isIndustryModalOpen: false,
    tms_clients: null,
    tms_name: null,
    selected_industry: { value: '', label: '' },
    loading_industry_modal: false,
    pending_industry_save: false,
    current_client: { id: '', product_type: '', name: '' },
    isActiveEmailModalOpen: false,
    isActiveEmailModalPending: false,
    isEnableEmailModalOpen: false,
    isEnableEmailModalPending: false,
    isDeactivateClientModalOpen: false,
    isDeactivateClientModalPending: false,
    isUndeleteClientModalOpen: false,
  });

  const listParams = useSelector(state => state?.admin?.resources?.clients?.list?.params);
  const start = React.useMemo(
    () =>
      listParams.page && listParams.perPage && listParams.page !== 1
        ? (listParams.page - 1) * listParams.perPage + 1
        : 0,
    [listParams.page, listParams.perPage]
  );
  const end = React.useMemo(
    () =>
      listParams.page && listParams.perPage && listParams.page !== 1
        ? listParams.page * listParams.perPage + 1
        : listParams.perPage || 25,
    [listParams.page, listParams.perPage]
  );

  const { refetch } = useQuery({
    type: 'getList',
    resource: 'clients',
    payload: {
      _start: start,
      _end: end,
      _sort: listParams.sort || 'name',
      _order: listParams.order || 'ASC',
      _filter: Object.entries(listParams.filter)
        .map(e => e.join(':'))
        .join(',')
    }
  });

  const resetCurrentClientState = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setClientListState(old => {
        return {
          ...old,
          current_client: { id: '', product_type: '', name: '' },
          isActiveEmailModalPending: false,
          isEnableEmailModalPending: false,
          isDeactivateClientModalPending: false
        };
      });
    }, 300);
  };

  const closeActiveModal = () => {
    setClientListState(old => ({ ...old, isActiveEmailModalOpen: false }));
    resetCurrentClientState();
  };

  const closeEnableSystemEmailsModal = () => {
    setClientListState(old => ({ ...old, isEnableEmailModalOpen: false }));
    resetCurrentClientState();
  };

  const closeAllActionsModal = () => {
    setClientListState(old => {
      return {
        ...old,
        isEnableEmailModalOpen: false,
        isActiveEmailModalOpen: false,
        isDeactivateClientModalOpen: false,
        isUndeleteClientModalOpen: false
      };
    });
    resetCurrentClientState();
  };

  const actionClient = React.useCallback(
    (type, id, withEmails) => {
      const app = 'clients';
      const tab = 'actions';
      const data = {
        type
      };
      if (withEmails !== undefined) {
        data.system_emails_status = Boolean(withEmails);
      }

      setClientListState(old => {
        return {
          ...old,
          isActiveEmailModalPending: true,
          isEnableEmailModalPending: true,
          isDeactivateClientModalPending: true
        };
      });
      dataPost({ app, tab, item: id, params: {}, data })
        .then(res => {
          refresh();
          notify(res.data.message || res.data.description, 'success');
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        })
        .finally(() => {
          closeAllActionsModal();
        });
    },
    [notify, refresh]
  );
  const setOpenDialog = (val, id) => {
    setOpen(val);
    setIdOnOpen(id);
  };

  const actionDeleteClient = () => {
    const app = 'clients';
    const tab = 'actions';

    setOpenDialog(false);

    dataProvider
      .delete(app, tab, idOnOpen)
      .then(res => {
        refresh();
        notify(res.data.message);
      })
      .catch(err => notify(`${err.response.data.description}`, 'error'))
      .finally(() => {
        setOpenDialog(false, null);
      });
  };

  const downloadClientReport = React.useCallback(
    curr_client_id => {
      setIdOnReport(curr_client_id);

      clients
        .getClientReport(curr_client_id)
        .then(res => window.open(res.data.url))
        .catch(error => notify(`${error.response}`, 'error'))
        .finally(() => setIdOnReport(null));
    },
    [notify]
  );

  const openModalForIndustries = React.useCallback(
    (client_id, client_name, client_product_type) => {
      setClientListState(old => ({
        ...old,
        isIndustryModalOpen: true,
        loading_industry_modal: true,
        current_client: { id: client_id, product_type: client_product_type, name: client_name }
      }));
      if (client_industries && client_industries.length) {
        setClientListState(old => ({ ...old, isIndustryModalOpen: true, loading_industry_modal: false }));
        return;
      }

      clients
        .getPickList({
          client_id,
          types: 'industries'
        })
        .then(industriesRes => {
          if (industriesRes.data) {
            dispatch({
              type: BSN_SET_ANY,
              payload: {
                  client: {
                    client_industries: industriesRes?.data?.industries?.map(v => ({ value: v.id, label: v.name })) ?? []
                  }
              }
            });
          }
        })
        .catch(err => {
          setClientListState(old => ({ ...old, isIndustryModalOpen: false }));
          notify('failed', 'error');
        })
        .finally(() => {
          setClientListState(old => ({ ...old, loading_industry_modal: false }));
        });
    },
    [client_industries, dispatch, notify]
  );

  function saveSelectedClientIndustry() {
    if (!clientListState.current_client) {
      return;
    }
    setClientListState(old => ({ ...old, pending_industry_save: true }));
    clients
      .setIndustryToClient(clientListState.current_client.id, {
        industry_id: clientListState.selected_industry.value,
        product_type: clientListState.current_client.product_type,
        name: clientListState.current_client.name
      })
      .then(r => {
        // mark used industries  as out of date and should be refeatched
        if (r.status === 200) {
          dispatch({
            type: BSN_SET_ANY,
            payload: {
              user: {
                profile: {
                  is_updated_used_industries: false
                }
              }
            }
          });
          notify('Successfully added Industry', 'success');
          setClientListState(old => ({
            ...old,
            isIndustryModalOpen: false,
            selected_industry: { label: '', value: '' },
            current_client: { id: '', product_type: '', name: '' },
            pending_industry_save: false
          }));
          refresh();
        }
      })
      .catch(err => {
        notify('Failed to add Industry', 'error');
        setClientListState(old => ({
          ...old,
          selected_industry: { label: '', value: '' },
          pending_industry_save: false
        }));
      });
  }

  const activateEBBClient = React.useCallback(
    ({ name, id, product_type }) => {
      if (!is_enterprise_partner) {
        actionClient('activate', id, false);
        return;
      }
      setClientListState(old => {
        return {
          ...old,
          isActiveEmailModalOpen: true,
          current_client: {
            name,
            id,
            product_type
          }
        };
      });
    },
    [actionClient, is_enterprise_partner]
  );

  const memList = React.useMemo(() => {
    return (
      <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<ClientListActions basePath={props.basePath} />}
        edit={<ClientsEdit />}
        create={<ClientsCreate />}
        bulkActions={false}
        bulkActionButtons={false}
        filters={<ClientListFilter />}
        pagination={<StyledPagination />}
        activateEBBClient={activateEBBClient}
      >
        <FunctionField
          label="Name"
          sortBy="name"
          sortByOrder="ASC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return (
              <FunctionFieldNameRender
                name={renderProps.name}
                clientIndustry={renderProps.industry_name}
                product_type={renderProps.product_type}
                is_visible_client_id={renderProps.is_visible_client_id}
                clientid={renderProps.clientid}
              />
            );
          }, isAdmin || !is_enterprise_partner)}
        />

        <FunctionField
          label="Branding"
          textAlign="center"
          sortBy="branding"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return <FunctionFieldBrandingRender branding={renderProps.branding} />;
          }, isAdmin || !is_enterprise_partner)}
        />
        <FunctionField
          label="Consulting" 
          textAlign="center"
          sortBy="ra_consulting"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return <FunctionFieldRaConsultRender ra_consulting={renderProps.ra_consulting} />;
          }, isAdmin || !is_enterprise_partner)}
        />
        {/* <BooleanField source="branding" label="Branding" /> */}
        {/* <BooleanField source="ra_consulting" label="Consulting" /> */}
        <FunctionField
          label="Insurance"
          textAlign="center"
          sortBy="insurance"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return <FunctionFieldInsuranceCodeRender insurance_code={renderProps.insurance_code} />;
          }, isAdmin || !is_enterprise_partner)}
        />
        <FunctionField
          label="RA"
          sortBy="ra_complete"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return <FunctionFieldRaCompleteRender ra_complete={renderProps.ra_complete} />;
          }, isAdmin || !is_enterprise_partner)}
        />

        <FunctionField
          label="Users"
          textAlign="center"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            const { users } = renderProps;
            return <span>{users}</span>;
          }, isAdmin || !is_enterprise_partner)}
        />

        <FunctionField
          label="Approx Breaches"
          textAlign="center"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            const { data_breaches } = renderProps;
            return <span>{data_breaches}</span>;
          }, isAdmin || !is_enterprise_partner)}
        />
        {/* <FunctionField
  label="Breaches"
  textAlign="right"
  sortBy="data_breaches"
  sortByOrder="DESC"
  render={({ data_breaches }) => {
    const db = data_breaches.split('/');
    return typeof db[1] !== 'undefined' ? (
      <>
        <CompanyName>{db[0]}</CompanyName>
        <ProductType>({Math.round((db[0] / db[1]) * 100)}%)</ProductType>
      </>
    ) : (
      db[0]
    );
  }}
/> */}
        <FunctionField
          label="ESS"
          textAlign="center"
          sortBy="average_ess"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            return <FunctionFieldAvgESSRender average_ess={renderProps.average_ess} />;
          }, isAdmin || !is_enterprise_partner)}
        />
        {/* <DateField source="last_breach" label="Last Breach" /> */}
        {/* <NumberField source="average_micro_quizz" label="Ave Micro Quiz" />
<NumberField source="phishing_fail_rate" label="Phishing Fail Rate" /> */}
        {/* <BooleanField source="active" label="Active" /> */}

        <FunctionField
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Industry</span>
              <ObscureSpan>
                <CustomTooltip
                  placement="top"
                  title={
                    <span>
                      This information will help us create more
                      <br />
                      industry specific content in the future
                    </span>
                  }
                  disableClickListener
                >
                  <IconButton style={{ padding: '1rem' }}>
                    <Info />
                  </IconButton>
                </CustomTooltip>
              </ObscureSpan>
            </div>
          }
          textAlign="center"
          sortBy="industry_id"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            const isLoading = idOnNewsfeed === renderProps.id;
            return (
              <FunctionFieldIndustryRender
                id={renderProps.id}
                isLoading={isLoading}
                name={renderProps.name}
                clientIndustry={renderProps.industry_name}
                access={renderProps.access}
                onClick={e => {
                  e.stopPropagation();
                  openModalForIndustries(renderProps.id, renderProps.name, renderProps.product_type);

                  // toggleNewsFeed(id, name, !newsfeed);
                }}
              />
            );
          }, isAdmin || !is_enterprise_partner)}
        />

        <FunctionField
          label="Active"
          textAlign="center"
          sortBy="active"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(
            renderProps => (
              <FunctionFieldActiveRender
                onEnableClick={e => {
                  e.stopPropagation();
                  setClientListState(old => {
                    return {
                      ...old,
                      isDeactivateClientModalOpen: true,
                      current_client: {
                        id: renderProps.id,
                        name: renderProps.name,
                        product_type: renderProps.product_type
                      }
                    };
                  });
                }}
                onDisableClick={e => {
                  e.stopPropagation();
                  const {
                    id,
                    name,
                    product_type,
                    enterprise_partner,
                    product_code,
                    sys_conf_disable_emails
                  } = renderProps;
                  if (
                    enterprise_partner === true &&
                    product_code === 'EBPPC-0' &&
                    sys_conf_disable_emails &&
                    is_enterprise_partner
                  ) {
                    setClientListState(old => {
                      return {
                        ...old,
                        isActiveEmailModalOpen: true,
                        current_client: {
                          name,
                          id,
                          product_type
                        }
                      };
                    });
                  } else {
                    actionClient('activate', id);
                  }
                }}
                onDeleteClick={e => {
                  e.stopPropagation();
                  setOpenDialog(true, renderProps.id);
                }}
                onUndeleteClient={e => {
                  e.stopPropagation();
                  setClientListState(old => {
                    return {
                      ...old,
                      isUndeleteClientModalOpen: true,
                      current_client: {
                        id: renderProps.id,
                        name: renderProps.name,
                        product_type: renderProps.product_type
                      }
                    };
                  });
                }}
                active={renderProps.active}
                account_type={renderProps.account_type}
                product_type={renderProps.product_type}
                enterprise_partner={renderProps.enterprise_partner}
                product_code={renderProps.product_code}
                isAdmin={isAdmin}
                isFilteringDeleted={listParams.filter?.deleted}
              />
            ),
            true
          )}
        />
        {!is_enterprise_partner ? null : (
          <FunctionField
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>System Emails</span>
                <ObscureSpan>
                  <CustomTooltip
                    light
                    maxWidth="38rem"
                    placement="top"
                    textAlign="left"
                    title={
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Identifies clients that have system emails disabled.
                        <br />
                        Systems emails include: All Phishing Campaigns,
                        <br />
                        Client Reminders, Automated Reports and other notifications.
                      </span>
                    }
                    disableClickListener
                  >
                    <IconButton style={{ padding: '1rem' }}>
                      <Info />
                    </IconButton>
                  </CustomTooltip>
                </ObscureSpan>
              </div>
            }
            textAlign="center"
            sortBy="sys_conf_disable_emails"
            sortByOrder="DESC"
            render={FunctionFieldRenderWrapper(renderProps => {
              const { id, name, product_type, enterprise_partner, product_code, sys_conf_disable_emails } = renderProps;
              if (enterprise_partner === true && product_code === 'EBPPC-0' && sys_conf_disable_emails) {
                return (
                  <CustomTooltip
                    light
                    textAlign="left"
                    placement="top"
                    title={
                      <>
                        System emails are disabled for this client.
                        <br />
                        Click here to enable.
                      </>
                    }
                    maxWidth="27.4rem"
                  >
                    <IconButton
                      aria-label="Client Inactive Email"
                      disabled={props.clientIndustry || props.isLoading}
                      onClick={e => {
                        e.stopPropagation();
                        setClientListState(old => {
                          return { ...old, isEnableEmailModalOpen: true, current_client: { id, name, product_type } };
                        });
                      }}
                    >
                      <EmailAlert fill="var(--colorSystemDanger)" />
                    </IconButton>
                  </CustomTooltip>
                );
              }
              return <></>;
            })}
          />
        )}
        <FunctionField
          label="Client Report"
          textAlign="center"
          sortBy="client_report"
          sortByOrder="DESC"
          render={FunctionFieldRenderWrapper(renderProps => {
            if (!renderProps.users) return null;

            const isLoading = idOnReport === renderProps.id;
            return (
              <FunctionFieldClientReportRender
                isLoading={isLoading}
                onClick={e => {
                  e.stopPropagation();
                  downloadClientReport(renderProps.id);
                }}
              />
            );
          }, isAdmin || !is_enterprise_partner)}
        />
      </List>
    );
  }, [
    actionClient,
    activateEBBClient,
    downloadClientReport,
    idOnNewsfeed,
    idOnReport,
    isAdmin,
    is_enterprise_partner,
    listParams.filter,
    openModalForIndustries,
    props
  ]);

  if (accessApps && !hasAccess) return null;

  const ClientsAlert =
    disabled_system_email_clients_count > 0 ? (
      <Alert
        style={{
          border: '1px solid',
          borderColor: 'var(--colorSystemDanger)',
          borderLeftWidth: 5,
          color: 'var(--colorSystemDanger)',
          display: 'flex',
          alignItems: 'center',
          margin: '2rem 0rem'
        }}
        icon={<AlertIcon style={{ fontSize: '2rem' }} color="var(--colorSystemDanger)" />}
        severity="error"
      >
        {disabled_system_email_clients_count === 1
          ? 'One client is active with system emails disabled. Go to the System Emails column to view this client and enable.'
          : `${disabled_system_email_clients_count} clients are active with system emails disabled. 
            Go to the System Emails column to view these clients and enable.`}
      </Alert>
    ) : null;

  return mobileView ? (
    <ClientsListMobile clientsAlert={ClientsAlert} basePath={props.basePath} />
  ) : (
    <>
      {ClientsAlert}
      {memList}
      <SubmissionModal
        open={openDialog}
        onClose={() => setOpenDialog(false, null)}
        color="colorSystemDanger"
        onSubmit={actionDeleteClient}
        icon="Delete"
        iconSize={34}
        title="Delete"
        description={
          <Typography.p mx={0} mt={0} textAlign="center">
            This client will be deleted and cannot be recovered.
            <br />
            Are you sure about that?
          </Typography.p>
        }
        submitButtonTitle="Yes, I want to delete"
        cancelButtonTitle="Cancel"
        maxWidth="xs"
      />

      <SubmissionModal
        open={clientListState.isUndeleteClientModalOpen}
        onClose={closeAllActionsModal}
        color="colorSystemInfo"
        onSubmit={() => actionClient('undelete', clientListState.current_client.id)}
        icon="Refresh"
        iconSize={34}
        title="Undelete Client"
        description={
          <Typography.p mx={0} mt={0} textAlign="center">
            This client will be undeleted.
            <br />
            Are you sure about that?
          </Typography.p>
        }
        submitButtonTitle="Yes, I want to undelete"
        cancelButtonTitle="Cancel"
        maxWidth="xs"
      /> 

      <DeactivateClientModal
        clientState={clientListState}
        onClose={closeAllActionsModal}
        onSubmit={() => actionClient('deactivate', clientListState.current_client.id)}
      />

      <Modal
        onClose={closeActiveModal}
        loadingContent={clientListState.isActiveEmailModalPending}
        loadingSize={60}
        loadingSkeleton={{ width: '50rem', height: '25rem' }}
        opened={clientListState.isActiveEmailModalOpen}
        dismissOutside
        overwriteStyles={{
          dialog: { maxWidth: '60rem', margin: '0 auto' }
        }}
        data={
          <>
            <CloseIcon
              style={{ marginLeft: 'auto', padding: '2rem', width: '2.2rem', height: '2.2rem', cursor: 'pointer' }}
              onClick={closeActiveModal}
            />
            <div
              style={{
                padding: '5.4rem 4rem',
                display: 'flex',
                justifySelf: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <Typography.h2 mx={0} my={0} textAlign="center" maxWidth={501} style={{ lineHeight: '24px' }}>
                You are about to activate {clientListState.current_client.name.trim()}
              </Typography.h2>

              <Typography.h2 mx={0} mb={0} mt={2} textAlign="center" maxWidth={373} style={{ lineHeight: '24px' }}>
                Would you like to activate with system emails turned on?
              </Typography.h2>

              <Typography.p mx={0} mt={0} mb={5} textAlign="center">
                All Phishing Campaigns, Client Reminders, Automated Reports and other system emails will start sending
                if configured.
              </Typography.p>

              <Button
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                backgroundDisabled="colorSystemSuccessDisable"
                height={36}
                width={300}
                variant="outlined"
                style={{ padding: '1.2rem 4.5rem' }}
                textTransform={false}
                onClick={() => {
                  actionClient('activate', clientListState.current_client.id, true);
                }}
              >
                Activate and Send System Emails
              </Button>
              <Button
                backgroundColor="buttonSecondaryBackground"
                backgroundColorHover="buttonSecondaryBackground"
                bordercolor="colorSystemSuccess"
                labelcolor="var(--colorSystemSuccess)"
                height={36}
                onClick={() => {
                  actionClient('activate', clientListState.current_client.id, false);
                }}
                variant="outlined"
                style={{ width: '300px', marginTop: '2rem' }}
                textTransform={false}
              >
                Activate and Do Not Send System Emails
              </Button>
            </div>
          </>
        }
      />
      <Modal
        onClose={closeEnableSystemEmailsModal}
        loadingContent={clientListState.isEnableEmailModalPending}
        loadingSize={60}
        loadingSkeleton={{ width: '50rem', height: '25rem' }}
        opened={clientListState.isEnableEmailModalOpen}
        dismissOutside
        overwriteStyles={{
          dialog: { maxWidth: '60rem', margin: '0 auto' }
        }}
        data={
          <>
            <CloseIcon
              style={{ marginLeft: 'auto', padding: '2rem', width: '2.2rem', height: '2.2rem', cursor: 'pointer' }}
              onClick={closeEnableSystemEmailsModal}
            />
            <div
              style={{
                padding: '2.6rem 4rem 4.6rem 4rem',
                display: 'flex',
                justifySelf: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <Typography.h2 mx={0} my={0} textAlign="center" maxWidth={386} style={{ lineHeight: '24px' }}>
                Would you like to enable system emails for {clientListState.current_client.name.trim()}?
              </Typography.h2>

              <Typography.p mx={0} mt={2.5} mb={0} textAlign="center">
                All Phishing Campaigns, Client Reminders, Automated Reports and other system emails will start sending
                if configured.
              </Typography.p>

              <Button
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                backgroundDisabled="colorSystemSuccessDisable"
                height={40}
                width={200}
                variant="outlined"
                style={{ padding: '1.2rem 1.6rem', marginTop: '4rem' }}
                onClick={() => {
                  actionClient('system_emails', clientListState.current_client.id);
                }}
              >
                Yes, Enable System Emails
              </Button>
              <ButtonCancel onClick={closeEnableSystemEmailsModal} style={{ marginTop: '3rem', marginRight: '0' }}>
                Cancel
              </ButtonCancel>
            </div>
          </>
        }
      />

      <ClientIndustryModal
        onClose={() => {
          setClientListState(old => ({
            ...old,
            isIndustryModalOpen: false,
            selected_industry: { label: '', value: '' },
            current_client: { id: '', product_type: '', name: '' }
          }));
        }}
        loading={clientListState.loading_industry_modal}
        opened={clientListState.isIndustryModalOpen}
        choices={client_industries}
        selectedValue={clientListState.selected_industry.value}
        pendingAction={clientListState.pending_industry_save}
        onChange={ev => {
          const { value } = ev.target;

          setClientListState(old => ({
            ...old,
            selected_industry: client_industries?.find(v => v.value === value) ?? old.selected_industry
          }));
          // onClientIndustyChange(value);
        }}
        onSave={() => {
          saveSelectedClientIndustry();
        }}
      />
    </>
  );
};

export default ClientList;
