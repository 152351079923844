import React from 'react';
import { Step, StepLabel } from '@material-ui/core';
import { Container, LoadingStyled } from 'components';
import { Enable } from 'components/icons';
import { Stepper, StepIconStyled, CompleteStepIcon, CurrentStepIcon, StepConnector } from '../../style/AdminCommon';

function StepIcon({ active, completed, icon }) {
  return (
    <StepIconStyled completed={completed} active={active}>
      {completed ? (
        <CompleteStepIcon>
          <Enable color="colorSystemSuccess"/>
        </CompleteStepIcon>
      ) : (
        <CurrentStepIcon active={active}>{icon}</CurrentStepIcon>
      )}
    </StepIconStyled>
  );
};

const StepsContainer = ({ activeStep, completed, stepLabels, stepComponents, loading }) => {
  return (
    <Container.Grid xs={12} sm={12} md={12} lg={12} direction="column" justify="center">
      <Container.Grid item xs={12} md={12} justify="center">
        <Container.Grid xs={12} md={8}>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel connector={<StepConnector />}>
            {stepLabels.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container.Grid>
      </Container.Grid>
      <Container.Grid item xs={12} sm={12} md={12}>
        <Container.Grid container xs={12} sm={12} md={12} justify="center">
          {loading ? <LoadingStyled /> : stepComponents[activeStep]}
        </Container.Grid> 
      </Container.Grid>
    </Container.Grid>
  );
};

export default StepsContainer;