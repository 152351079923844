// @flow
// TODO: Remove file
import grey from '@material-ui/core/colors/grey';
import yellow from '@material-ui/core/colors/yellow';

const common = {
  black: '#000',
  white: '#fff'
};

grey[50] = '#f9f9f9';
grey[200] = '#bebebe';
grey[300] = '#e0e0f4';
grey[700] = '#545761';
grey[800] = '#333333';
grey[900] = '#212529';
grey.A100 = '#d5d5d5';
grey.A200 = '#aaaaaa';
grey.A400 = '#303030';
grey.A700 = '#7f7f7f';

yellow[700] = '#fbca00';

const whiteSmoke = {
  '50': '#fdfdfd',
  '100': '#fafafa',
  '200': '#f7f7f7',
  '300': '#f3f3f3',
  '400': '#f1f1f1',
  '500': '#eeeeee',
  '600': '#ececec',
  '700': '#e9e9e9',
  '800': '#e7e7e7',
  '900': '#e2e2e2',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff'
};

const lightGrey = {
  '50': '#fafafa',
  '100': '#f3f3f3',
  '200': '#ebebeb',
  '300': '#e2e2e2',
  '400': '#dcdcdc',
  '500': '#d6d6d6',
  '600': '#d1d1d1',
  '700': '#cccccc',
  '800': '#c6c6c6',
  '900': '#bcbcbc',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#fffdfd'
};

const royalBlue = {
  '50': '#e9f2fc',
  '100': '#c9def6',
  '200': '#a5c8f1',
  '300': '#80b1eb',
  '400': '#65a1e6',
  '500': '#4a90e2',
  '600': '#4388df',
  '700': '#3a7dda',
  '800': '#3273df',
  '900': '#2261cf',
  A100: '#ffffff',
  A200: '#d5e3ff',
  A400: '#a2c1ff',
  A700: '#89b0ff'
};

const sanMarino = {
  '50': '#e9edf3',
  '100': '#c8d3e0',
  '200': '#a4b6cb',
  '300': '#7f98b6',
  '400': '#6382a7',
  '500': '#486c97',
  '600': '#41648f',
  '700': '#385984',
  '800': '#304f7a',
  '900': '#213d69',
  A100: '#a9c8ff',
  A200: '#76a8ff',
  A400: '#4387ff',
  A700: '#2a77ff'
};

const lavenderBlue = {
  '50': '#f8fbff',
  '100': '#eef5fe',
  '200': '#e2effe',
  '300': '#d6e9fe',
  '400': '#cee4fd',
  '500': '#c5dffd',
  '600': '#bfdbfd',
  '700': '#b8d7fc',
  '800': '#b0d2fc',
  '900': '#a3cafc',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff'
};

const bayOfMany = {
  '50': '#e7eaed',
  '100': '#c2cad2',
  '200': '#9aa7b5',
  '300': '#728397',
  '400': '#536980',
  '500': '#354e6a',
  '600': '#304762',
  '700': '#283d57',
  '800': '#22354d',
  '900': '#16253c',
  A100: '#7babff',
  A200: '#488bff',
  A400: '#156aff',
  A700: '#005bfb'
};

const sulu = {
  '50': '#f6fcf0',
  '100': '#eaf8d8',
  '200': '#dcf4c3',
  '300': '#cdf0aa',
  '400': '#c3ec98',
  '500': '#b8e986',
  '600': '#b1e67e',
  '700': '#a8e373',
  '800': '#a0df69',
  '900': '#91d956',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#e8ffd6',
  A700: '#daffbd'
};

const black = {
  '50': '#e0e0e0',
  '100': '#b3b3b3',
  '200': '#808080',
  '300': '#4d4d4d',
  '400': '#262626',
  '500': '#000000',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666'
};

const mid_gray = {
  '50': '#f0f0f0',
  '100': '#d9d9d9',
  '200': '#bfbfbf',
  '300': '#a5a5a5',
  '400': '#929292',
  '500': '#7f7f7f',
  '600': '#777777',
  '700': '#6c6c6c',
  '800': '#626262',
  '900': '#4f4f4f',
  A100: '#f8bfbf',
  A200: '#f39191',
  A400: '#ff5252',
  A700: '#ff3838'
};

const chelseaCucumber = {
  '50': '#f2f3ea',
  '100': '#dee2ca',
  '200': '#c8cfa6',
  '300': '#b2bb82',
  '400': '#a2ad68',
  '500': '#919e4d',
  '600': '#899646',
  '700': '#7e8c3d',
  '800': '#748234',
  '900': '#627025',
  A100: '#efffb3',
  A200: '#e5ff80',
  A400: '#dbff4d',
  A700: '#d6ff33'
};

const eletricIndigo = {
  '50': '#f2e3ff',
  '100': '#deb8ff',
  '200': '#c889ff',
  '300': '#b15afe',
  '400': '#a136fe',
  '500': '#9013fe',
  '600': '#8811fe',
  '700': '#7d0efe',
  '800': '#730bfe',
  '900': '#6106fd',
  A100: '#ffffff',
  A200: '#f6f2ff',
  A400: '#d3bfff',
  A700: '#c2a5ff'
};

const chantilly = {
  '50': '#fdf6f9',
  '100': '#f9eaf1',
  '200': '#f6dce8',
  '300': '#f2cddf',
  '400': '#efc3d8',
  '500': '#ecb8d1',
  '600': '#eab1cc',
  '700': '#e7a8c6',
  '800': '#e4a0c0',
  '900': '#df91b5',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#fff6fa'
};

const turkishRose = {
  '50': '#f4eef1',
  '100': '#e4d4dc',
  '200': '#d3b8c5',
  '300': '#c19cae',
  '400': '#b3869c',
  '500': '#a6718b',
  '600': '#9e6983',
  '700': '#955e78',
  '800': '#8b546e',
  '900': '#7b425b',
  A100: '#ffd3e6',
  A200: '#ffa0c9',
  A400: '#ff6dac',
  A700: '#ff539d'
};

const karry = {
  '50': '#fffcf8',
  '100': '#fef7ee',
  '200': '#fef1e2',
  '300': '#feebd6',
  '400': '#fde7ce',
  '500': '#fde3c5',
  '600': '#fde0bf',
  '700': '#fcdcb8',
  '800': '#fcd8b0',
  '900': '#fcd0a3',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff'
};

const nobel = {
  '50': '#f3f3f3',
  '100': '#e1e1e1',
  '200': '#cdcdcd',
  '300': '#b9b9b9',
  '400': '#aaaaaa',
  '500': '#9b9b9b',
  '600': '#939393',
  '700': '#898989',
  '800': '#7f7f7f',
  '900': '#6d6d6d',
  A100: '#fdeeee',
  A200: '#f8c0c0',
  A400: '#ff8686',
  A700: '#ff6d6d'
};

const eastSide = {
  '50': '#f6f2f7',
  '100': '#e8dfea',
  '200': '#d9cadd',
  '300': '#cab4cf',
  '400': '#bea4c4',
  '500': '#b394ba',
  '600': '#ac8cb3',
  '700': '#a381ab',
  '800': '#9a77a3',
  '900': '#8b6594',
  A100: '#ffffff',
  A200: '#f6d4ff',
  A400: '#eca1ff',
  A700: '#e788ff'
};

const lilacBush = {
  '50': '#f6eff7',
  '100': '#e7d6ec',
  '200': '#d8bbe0',
  '300': '#c89fd3',
  '400': '#bc8bc9',
  '500': '#b076c0',
  '600': '#a96eba',
  '700': '#a063b2',
  '800': '#9759aa',
  '900': '#87469c',
  A100: '#fcf5ff',
  A200: '#efc2ff',
  A400: '#e28fff',
  A700: '#dc75ff'
};

export {
  common,
  grey,
  yellow,
  whiteSmoke,
  lightGrey,
  royalBlue,
  sanMarino,
  lavenderBlue,
  bayOfMany,
  sulu,
  black,
  mid_gray,
  chelseaCucumber,
  eletricIndigo,
  chantilly,
  turkishRose,
  karry,
  nobel,
  eastSide,
  lilacBush
};
export { default as amber } from '@material-ui/core/colors/amber';
export { default as purple } from '@material-ui/core/colors/purple';
export { default as red } from '@material-ui/core/colors/red';
export { default as blue } from '@material-ui/core/colors/blue';
export { default as pink } from '@material-ui/core/colors/pink';
export { default as green } from '@material-ui/core/colors/green';
