const trainingUsersTrainings = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc'
  },
  selecting: false,
  sort: ['name', 'score', 'created'],
  rowClick: false,
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'score',
      label: 'Score',
      align: 'left'
    },
    {
      id: 'created',
      label: 'Completed',
      align: 'left'
    }
  ]
};

export default trainingUsersTrainings;
