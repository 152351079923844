// @flow
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isObjectEmpty } from 'helpers';

type useTableFilterReturnType = {
  filters: Object,
  setFilter: Function,
  removeFilter: Function,
  clearFilter: Function,
  hasFilters: boolean
};

function useTableFilter(): useTableFilterReturnType {
  const app = 'system';
  const tab = 'tableFilter';
  const filters = useSelector(state => state.bsn[app][tab]);
  const dispatch = useDispatch();

  const setDispatch = useCallback(
    (payload: Object) =>
      dispatch({
        type: 'BSN_SET_FILTER',
        resource: app,
        tab,
        payload
      }),
    [dispatch]
  );

  const setFilter = (name: string, value: any, multiple: boolean) => setDispatch({ ...filters, [name]: multiple ? value : String(value) });

  const removeFilter = (name: string) => {
    const newFilters = filters;
    delete newFilters[name];
    const isEmpty = Object.keys(newFilters).length === 0;
    return setDispatch(isEmpty ? {} : newFilters);
  };

  const clearFilter = () => setDispatch({});

  const hasFilters: boolean = !isObjectEmpty(filters);

  return { filters, setFilter, removeFilter, clearFilter, hasFilters };
}

export default useTableFilter;
