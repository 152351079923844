import apiClient from '../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_4;
const baseURL = `https://${api}.${base}/${env}`;

const mfa = {};
const DEFAULT_CONTENT_TYPE = 'application/json';

mfa.verify = async (otp, type) => {
  const email = localStorage.getItem('email');
  const access_token = localStorage.getItem('configToken');
  const session_token = localStorage.getItem('sessionToken');
  const data = { email, code: otp, submission_type: type };

  type === 'mfa_configure' && (data.access_token = access_token);
  type === 'mfa_challenge' && (data.session_token = session_token);

  return await apiClient.post({
    url: baseURL + '/submit_mfa',
    headers: {
      'Content-Type': DEFAULT_CONTENT_TYPE
    },
    data
  });
};

export default mfa;
