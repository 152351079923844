import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilterField from 'apps/auditlog/FilterField';
import bsnPartnersApi from 'helpers/apis/BSNPartnersAPI/bsnpartnersapi';
import { useNotify } from 'ra-core';
import { useDebounce } from 'hooks';

const searchLimit = 20;
const customZIndex = 1000;
const debounce_delay = 500;

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 202
  }
}));

const Container = styled.div`
  height: ${({ open }) => {
    return open ? 'auto' : '50px';
  }};
  background-color: var(--backgroundDefault);
`;

const TextFieldsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 15px;
  flex-wrap: wrap;
  .applyFiltersBtn {
    margin: 10px auto;
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const FiltersBar = ({ filters, applyFilters }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [internalFilters, setInternalFilters] = useState({
    ...filters
  });
  const [partners, setPartners] = useState({
    options: [],
    open: false,
    selected: null,
    search: null,
    loading: false
  });

  const [clients, setClients] = useState({
    options: [],
    open: false,
    selected: null,
    search: null,
    loading: false
  });

  const [searchPartnerDebounce] = useDebounce({ value: partners.search, delay: debounce_delay });
  const [searchClientDebounce] = useDebounce({ value: clients.search, delay: debounce_delay });

  const resetClients = () => {
    setClients(prev => ({
      ...prev,
      options: [],
      selected: null
    }));
  };

  const getPartners = (partnerName = null) => {
    setPartners(prev => ({
      ...prev,
      loading: true
    }));
    bsnPartnersApi
      .audiLogsSearch({
        search: 'partners',
        filters: { ...(partnerName ? { name: partnerName } : {}), _limit: searchLimit }
      })
      .then(res => {
        setPartners(prev => ({
          ...prev,
          options: res?.data?.data || []
        }));
      })
      .catch(e => {
        notify('failed to load partners', 'error');
      })
      .finally(() =>
        setPartners(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const getClients = (partnerId, clientName = null) => {
    resetClients();
    setClients(prev => ({
      ...prev,
      loading: true
    }));
    bsnPartnersApi
      .audiLogsSearch({
        search: 'clients',
        filters: { partner_id: partnerId, _limit: searchLimit, ...(clientName ? { name: clientName } : {}) }
      })
      .then(res => {
        setClients(prev => ({
          ...prev,
          options: res?.data?.data || []
        }));
      })
      .catch(e => {
        notify('failed to load clients', 'error');
      })
      .finally(() =>
        setClients(prev => ({
          ...prev,
          loading: false
        }))
      );
  };

  const onSearchPartner = partnerName => {
    if (!partnerName) {
      setClients(prev => ({
        ...prev,
        selected: null
      }));
    }
    getPartners(partnerName);
  };

  const onSearchClient = clientName => {
    setClients(prev => ({
      ...prev,
      loading: true
    }));
    getClients(partners.selected.id, clientName);
  };

  useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (partners.search || partners.search === '') && onSearchPartner(searchPartnerDebounce.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPartnerDebounce.value]);

  useEffect(() => {
    (clients.search || clients.search === '') && onSearchClient(searchClientDebounce.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClientDebounce.value]);

  const handleChange = e => {
    const { name, value } = e.target;
    setInternalFilters(oldValue => {
      return {
        ...oldValue,
        [name]: value
      };
    });
  };

  const handleDateChange = (date, value) => {
    const delivery_date = date.toISOString().split('T')[0];
    setInternalFilters(oldValue => {
      return {
        ...oldValue,
        delivery_date
      };
    });
  };

  return (
    <Container open={open}>
      <Button variant="contained" onClick={e => setOpen(!open)}>
        Filters
      </Button>
      {open && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextFieldsContainer>
            <FilterField
              id="partners"
              options={partners.options}
              label="Partner Name"
              getOptionLabel={option => option.name}
              value={partners.selected}
              loading={partners.loading}
              open={partners.open}
              onOpen={() => {
                setPartners(prev => ({
                  ...prev,
                  open: true
                }));
                partners.length === 0 && getPartners();
              }}
              onClose={() => {
                setPartners(prev => ({
                  ...prev,
                  open: false
                }));
              }}
              onChange={(e, val, reason) => {
                setPartners(prev => ({
                  ...prev,
                  selected: val
                }));
                val ? getClients(val?.id) : resetClients();
                if (reason === 'clear') {
                  setPartners(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              onChangeTextField={e => {
                const { target } = e;
                setPartners(prev => ({
                  ...prev,
                  search: target.value
                }));
              }}
              popUpZIndex={customZIndex}
              variant="standard"
              popPlacement="bottom-start"
            />
            <FilterField
              id="clients"
              options={clients.options}
              disabled={!partners?.selected?.id}
              value={clients.selected}
              inputValue={clients.search ? clients.search : ''}
              label="Client Name"
              loading={clients.loading}
              open={clients.open}
              onOpen={() => {
                setClients(prev => ({
                  ...prev,
                  open: true
                }));
                clients.length === 0 && getClients(partners.selected.id);
              }}
              onClose={() => {
                setClients(prev => ({
                  ...prev,
                  open: false
                }));
              }}
              getOptionLabel={option => option.name}
              onChange={(e, val, reason) => {
                console.log('🚀 ~ file: FiltersBar.js:341 ~ FiltersBar ~ val:', val);
                setClients(prev => ({
                  ...prev,
                  selected: val,
                  search: val && val.name ? val.name : ''
                }));
                if (reason === 'clear') {
                  setClients(prev => ({
                    ...prev,
                    search: ''
                  }));
                }
              }}
              onChangeTextField={e => {
                const { target } = e;
                setClients(prev => ({
                  ...prev,
                  search: target.value
                }));
              }}
              popUpZIndex={customZIndex}
              variant="standard"
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="selecting_sorting_field_label">Sorting Field</InputLabel>
              <Select
                labelId="selecting_sorting_field_label"
                id="selecting_sorting_field"
                name="sortingField"
                value={internalFilters.sortingField}
                onChange={handleChange}
              >
                <MenuItem value="partner_name">Partner name</MenuItem>
                <MenuItem value="client_name">Client name</MenuItem>
                <MenuItem value="delivery_date">Delivery Date</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="selecting_sorting_direction_label">Sorting Direction</InputLabel>
              <Select
                labelId="selecting_sorting_direction_label"
                id="selecting_sorting_direction"
                name="sortingDirection"
                value={internalFilters.sortingDirection}
                onChange={handleChange}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
            <DatePicker
              autoOk
              clearable
              disableFuture
              style={{ margin: 0 }}
              variant="inline"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Delivery Date"
              value={internalFilters.delivery_date}
              onChange={handleDateChange}
            />
            <div className="applyFiltersBtn">
              <Button
                variant="contained"
                color="primary"
                onClick={e => {
                  applyFilters({
                    ...internalFilters,
                    partner_id: partners.selected?.id || '',
                    client_id: clients.selected?.id || ''
                  });
                }}
              >
                apply filters
              </Button>
            </div>
          </TextFieldsContainer>
        </MuiPickersUtilsProvider>
      )}
    </Container>
  );
};

FiltersBar.propTypes = {
  filters: PropTypes.shape({
    partner_id: PropTypes.string.isRequired,
    partner_name: PropTypes.string.isRequired,
    client_name: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    delivery_date: PropTypes.string.isRequired,
    sortingField: PropTypes.string.isRequired,
    sortingDirection: PropTypes.string.isRequired
  }).isRequired,
  applyFilters: PropTypes.func.isRequired
};

export default FiltersBar;
