import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ChartColorPercent } from '../../helpers';

const circleAnimation = keyframes`
  from {
    stroke-dashoffset: calc(var(--fill) * 2.4);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const SvgCircle = styled.svg`
  width: 55px;
  height: 55px;
  --path: 100%;
  --fill: ${({ fillValue }) => `${fillValue}%`};
  --none: transparent;
  position: relative;
`;

export const CircleBackground = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: #f2f2f2;
  stroke-dasharray: calc(var(--path) * 2.4);
`;

export const CircleForeground = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: ${({ colorVariant, fillValue }) => `var(--colorSystem${colorVariant ?? ChartColorPercent(fillValue)})`};
  stroke-dasharray: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  stroke-dashoffset: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  animation: ${circleAnimation} 1s linear;
`;

export const TextCircle = styled.text`
  font-size: 25px;
  font-weight: 700;
  fill: var(--colorDefault);
`;

const Indicator = ({ title, value, className, colorVariant, ...props }) => {
  return (
    <SvgCircle viewBox="0 0 120 120" fillValue={value} className={className} {...props}>
      <CircleBackground r="45" cx="60" cy="60" />
      <g transform={'rotate(-90 60 60)'}>
        <CircleForeground r="45" cx="60" cy="60" fillValue={value} colorVariant={colorVariant} />
      </g>

      <TextCircle x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" dominantBaseline={'central'}>
        {title}
      </TextCircle>
    </SvgCircle>
  );
};

export default Indicator;