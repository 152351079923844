// @flow

import React, { type Element, type ComponentType } from 'react';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

export const ButtonStyled: ComponentType<*> = styled(MuiButton)`
  && {
    padding: 8px 24px;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? `var(--${backgroundColor})` : 'var(--buttonPrimaryBackground)'};
    ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
    ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};
    ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
    ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
    ${({ my }) => my && `margin-bottom: calc(${my} * var(--spacing)); margin-top: calc(${my} * var(--spacing))`};
    ${({ mx }) => mx && `margin-left: calc(${mx} * var(--spacing)); margin-right: calc(${mx} * var(--spacing))`};
    ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
    ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
    ${({ px }) => px && `padding-left: calc(${px} * var(--spacing)); padding-right: calc(${px} * var(--spacing))`};
    ${({ py }) => py && `padding-bottom: calc(${py} * var(--spacing)); padding-top: calc(${py} * var(--spacing))`};
    height: ${({ height }) => height && `${height}`}px;
    width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
    border-radius: var(--borderRadius);
    ${({ bordercolor }) => bordercolor ? `border: 1px solid var(--${bordercolor})` : 'border: none'};
    :hover {
      background-color: ${({ backgroundColorHover }) =>
        backgroundColorHover ? `var(--${backgroundColorHover})` : 'var(--buttonPrimaryHover)'};
    }
  }

  .bsn-button-label {
    color: ${({ labelcolor }) => labelcolor ? labelcolor : 'var(--colorCommonWhite)' };
    font-size: var(--fontSize);
    ${({ textTransform = true }) => `text-transform: ${textTransform ? 'capitalize' : 'none'}`};
    white-space: nowrap;
  }
`;

export const ContainerButtonDisabled: ComponentType<*> = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? `right`};
  ${({ disabled }) => disabled && `cursor: not-allowed;`} ${({ fullwidth }) => fullwidth && `width: 100%`};
`;

type ButtonType = {
  fullwidth?: boolean,
  disabled?: boolean,
  ...
};

const Button = ({ fullwidth, disabled, justifyContent, ...props }: ButtonType): Element<*> => {
  return (
    <ContainerButtonDisabled fullwidth={fullwidth} disabled={disabled} justifyContent={justifyContent}>
      <ButtonStyled
        classes={{ label: 'bsn-button-label' }}
        fullwidth={fullwidth}
        disabled={disabled}
        {...(props: $Rest<ButtonType, any>)}
      />
    </ContainerButtonDisabled>
  );
};

Button.defaultProps = {
  fullwidth: false
};

export default Button;
