// @flow
import React, { memo, type Element, type ComponentType, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LazyApp } from 'apps';
import { Container } from 'components';
import { Button, Fade } from '@material-ui/core';
import { Done as DoneIcon } from 'components/icons';
import { useApp } from 'helpers';
import { useLocation } from 'hooks';
import { BLOCKED_TAB_OPEN_REQUEST, initialState } from 'conf'; // TODO: Default Component Workaround
import { useTabs } from './TabsContext';
import LoadingStyled from '../types/Loading';
import { Typography } from '../types';

type TabsTypes = Object;

const ButtonCancel: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    & span {
      color: var(--colorDefault);
    }
    &:hover {
      color: var(--colorDefault);
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;

const ButtonSave: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    font-weight: var(--fontWeightRegular);
    color: var(--colorCommonWhite);
    background: var(--colorSystemSuccess);
    box-shadow: none;
    font-size: var(--fontSize);
    padding: calc(var(--spacing) * 1) calc(var(--spacing) * 4);

    &:hover {
      background: var(--colorSystemSuccess);
      box-shadow: none;

      & svg {
        color: var(--colorCommonWhite);
      }
    }
  }
`;

const ErrorMessage = styled(Typography.h3)`
  text-align: center;
  margin: 100px auto;
  max-width: 500px;
  font-size: 24px;
  color: var(--colorSystemDanger);
  line-height: 30px;
`;

const exception = ['profile', 'access', 'dashboard', 'information'];

const TabsTab = (): Element<*> => {
  const { dispatch: dispatchApp } = useApp();
  const { app, tab: tabLoc } = useLocation();
  const {
    record,
    dispatch,
    setMutation,
    hasMutation,
    id,
    showSaveButton,
    settings,
    noQuery,
    access,
    tabCurrent,
    success,
    error,
    ...query
  } = useTabs();
  const [delay, setDelay] = useState(true);
  const isTabAllowed = access[tabLoc];
  const tab = isTabAllowed ? tabLoc : tabCurrent;
  const componentDefault = settings?.component || initialState.tabs.myDashboard.dashboard.component;

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setDelay(false);
      }, 0);
    }
  }, [delay]);

  useEffect(() => {
    return () => {
      if (!exception.includes(tab)) dispatchApp.set(app, tab, null);
      setDelay(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = e => {
    dispatch.update();
  };
  const onCancel = e => {
    dispatch.cancel();
  };

  const loading = !success || delay || !access || !tabCurrent || (!noQuery && !record) || (tab && tab !== tabCurrent);

  if (tab === 'false' || tab === 'undefined') {
    return null;
  }

  const errorMessage = error?.response?.data?.description || error?.response?.data?.message;

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (loading && !(app in BLOCKED_TAB_OPEN_REQUEST && BLOCKED_TAB_OPEN_REQUEST[app].includes(tab))) {
    return <LoadingStyled centerAlign />;
  }

  return (
    <>
      <Fade in={loading}>
        <LazyApp
          component={componentDefault}
          record={record}
          setMutation={setMutation}
          onChangeData={dispatch.onChangeData}
          onChangeDataArray={dispatch.onChangeDataArray}
          id={id}
          dispatch={dispatch}
          {...query}
        />
      </Fade>
      {showSaveButton && (
        <Container.Grid item sm={12} xs={12}>
          <Container.Paper
            pt={2}
            pb={2}
            pr={2}
            bt={1}
            radiusBottom={2}
            fullWidth
            justify="flex-start"
            direction="row-reverse"
          >
            <ButtonSave disabled={!hasMutation} disableElevation startIcon={<DoneIcon />} onClick={onClick}>
              Save
            </ButtonSave>
            <ButtonCancel onClick={onCancel} color="secondary">
              Cancel
            </ButtonCancel>
          </Container.Paper>
        </Container.Grid>
      )}
    </>
  );
};

export default memo<TabsTypes>(TabsTab);
