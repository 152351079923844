import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

function QuestionAnswers({ selectedAnswerId, handleAnswerQuestion, answers }) {
  return (
    <RadioGroup
      value={selectedAnswerId}
      onChange={e => handleAnswerQuestion({ answer_id: e.target.value })}
      // name={questionId}
    >
      {answers?.map(({ answer, answer_id }) => (
        <FormControlLabel
          label={answer}
          value={answer_id}
          control={<Radio style={{ fontSize: '20px' }} />}
          style={{
            margin: '10px 0px',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '5px',
            ...(selectedAnswerId === answer_id
              ? {
                  color: '#0054CC',
                  border: '1px solid #0054CC',
                  backgroundColor: '#1994E633'
                }
              : {
                  color: 'var(--colorDefault)',
                  border: '1px solid var(--borderLight)'
                })
          }}
        />
      ))}
    </RadioGroup>
  );
}

export default React.memo(QuestionAnswers);
