const sraRiskAssessment = {
  defaultSortValue: {},
  collapse: false,
  rowClick: false,
  selectingAll: false,
  selecting: false,
  sort: [],
  head: [
    {
      id: 'report_name',
      label: 'Report Name',
      align: 'left'
    },
    {
      id: 'date_completed',
      label: 'Date',
      align: 'left'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
    {
      id: 'files',
      label: 'Report Files',
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      align: 'left'
    }
  ]
};

export default sraRiskAssessment;
