// @flow
import { Settings as AdminIcon } from 'components/icons';
import AdminList from './AdminList';

export default {
  name: 'admin',
  list: AdminList,
  edit: AdminList,
  icon: AdminIcon,
  options: {
    label: 'Admin',
    hasMobileSublist: false
  }
};