// @flow
import { type ComponentType } from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  IconButton as MuiIconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel
} from '@material-ui/core';

export const ButtonCancel: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    &:hover {
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;

export const ButtonSubmit: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorCommonWhite);
    background: var(--colorSystemSuccess);
    font-size: var(--fontSize);
    padding-right: 15px;
    padding-left: 15px;
    ${({fullwidth}) => fullwidth && css`
      width: 100%;
      padding: 15px;
    ` }
    &:hover {
      background: var(--colorSystemSuccessHover);
    }
    &:disabled {
      opacity: 0.2;
      background: var(--colorSystemSuccess);
      color: var(--colorCommonWhite);
    }
  }
`;

export const ButtonDownload: ComponentType<*> = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorCommonWhite);
    background: var(--buttonInfo);
    align-items: left;
    margin-right: var(--spacing);
    box-shadow: none;
    padding-right: 15px;
    padding-left: 15px;
    font-size: var(--fontSize);
    &:hover {
      background: var(--colorSystemInfoHover);
      box-shadow: none;
    }
  }
`;
export const ButtonUppercase: ComponentType<*> = styled(Button)`
  && {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: var(--colorBaseLight3);
    background: var(--buttonPrimaryBackground);
    border: 1px solid var(--buttonPrimaryBackground);
    &:hover {
      background: var(--hoverButtonDefault);
      color: var(--colorBase);
      border-color: var(--colorBase);
    }
    &:disabled {
      color: rgb(255 255 255 / 31%);
    }
  }
`;

export const DialogTitleStyled: ComponentType<*> = styled(DialogTitle)`
  && {
    background: var(--backgroundDefault);
    padding: calc(var(--spacing) * 3);
    .modal-title {
      margin: 0 5% 0 0;
    }
  }
`;

export const DialogContentStyled: ComponentType<*> = styled(DialogContent)`
  background: var(--backgroundDefault);
  font-size: var(--fontSize);
`;

export const DialogActionsStyled: ComponentType<*> = styled(DialogActions)`
  background: var(--backgroundDefault);
  display: flex;
  padding: 12px 24px !important;
  && {
    justify-content: space-between;
  }
`;

export const AlignRight: ComponentType<*> = styled.div`
  && {
    justify-content: right;
  }
`;

export const AlignLeft: ComponentType<*> = styled.div`
  && {
    justify-content: left;
  }
`;

export const IconButton: ComponentType<*> = styled(MuiIconButton)`
  right: calc(var(--spacing) * 3);
  top: calc(var(--spacing) * 2);
  white-space: nowrap;
  && {
    position: absolute;
    svg {
      color: var(--colorDefault);
      font-size: var(--fontSize);
    }
  }
`;

export const FormControlLabel: ComponentType<*> = styled(MuiFormControlLabel)`
  padding-left: 24px;
`;

export const Checkbox: ComponentType<*> = styled(MuiCheckbox)`
  transform: scale(1.5);
  && {
    margin: 0 8px;
    padding: 0;
  }
`;
