import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ADMIN_ROOT_PATH } from 'apps/admin/constants';
import { AUDIT_LOG_ROOT_PATH } from 'apps/auditlog/constants';
import { GET_STARTED_BASE_PATH } from 'apps/getstarted/constants';
import { CONTENT_ADMIN_ROOT_PATH } from 'apps/contentadmin/constants';
import { REPORTS_BASE_PATH } from 'apps/reports/constants';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { Arrow } from 'components/icons';
import { snakeToCamelTitle } from 'helpers';
import styled from 'styled-components';
import { SRA_SUB_PAGES } from '../pages/SRA/constants';
import { useEffect } from 'react';

const Breadcrumbs = styled(MuiBreadcrumbs)`
  && {
    font-size: 2.6rem;
    font-weight: 500;
    color: var(--colorLight);
  }
`;

const ArrowBack = styled(Arrow)`
  transform: rotate(270deg);
  margin-right: var(--spacing);
  path {
    fill: inherit;
  }
`;

const Link = styled.span`
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ active }) => (active ? 'var(--colorDark)' : 'var(--colorLight)')};
  &:hover {
    color: ${({ active }) => (active ? 'none' : 'var(--colorGreyLight2)')};
  }
`;

const clientsCrumbConfig = Object.freeze({
  directorySync: {
    basePath: 'clients/users',
    label: 'Configure Directory Sync'
  },
  welcomeMessage: {
    basePath: 'clients/users',
    label: 'Welcome Message'
  },
  csvBulkUpload: {
    basePath: 'clients/users',
    label: 'CSV Bulk Upload'
  },
  users: {
    label: 'User Management'
  }
});

const myCompanyCrumbConfig = Object.freeze({
  directorySync: {
    basePath: 'myCompany/employees',
    label: 'Configure Directory Sync'
  },
  welcomeMessage: {
    basePath: 'myCompany/employees',
    label: 'Welcome Message'
  },
  csvBulkUpload: {
    basePath: 'myCompany/employees',
    label: 'CSV Bulk Upload'
  },
  employees: {
    label: 'Employee Management'
  }
});

const BasePath = Object.freeze({
  admin: ADMIN_ROOT_PATH,
  auditlog: AUDIT_LOG_ROOT_PATH,
  reports: REPORTS_BASE_PATH,
  getStarted: GET_STARTED_BASE_PATH,
  partnerProfile: '/partnerProfile/configure',
  contentadmin: CONTENT_ADMIN_ROOT_PATH
});

const AppDisplayName = Object.freeze({
  admin: 'Admin',
  auditlog: 'Audit Log',
  reports: 'Reports',
  getStarted: 'Getting Started',
  partnerProfile: 'Partner Profile',
  contentadmin: 'Content Admin',
  myCompany: 'My Company',
  clients: 'Manage Clients'
});

const CustomPathNames = Object.freeze({
  getStarted: {
    learnMore: 'Learn About Breach Secure Now'
  },
  partnerProfile: {
    automated_reminders_and_reports: 'Automated Reminders & Reports'
  },
  contentadmin: {
    mt_nl: 'Test MT/NL'
  },
  myCompany: {
    orgProfile: 'Organization Profile',
    editSraReport: 'Edit SRA Report'
  },
  clients: {
    orgProfile: 'Organization Profile',
    editSraReport: 'Edit SRA Report'
  }
});

const AddEditPathNames = Object.freeze({
  admin: {
    'manage-partners': 'Partner',
    policies: 'Policy',
    microTrainings: 'Micro Training',
    newsletters: 'Newsletter'
  },
  contentadmin: {
    landingPages: 'Landing Page',
    emailTemplates: 'Email Template',
    sendingProfiles: 'Sending Profile',
    scenarios: 'Scenario'
  }
});

const DismissPathNames = Object.freeze({
  contentadmin: ['sendingProfiles']
});

const PathFollowedById = Object.freeze({
  clients: {
    sra: 'editSraReport'
  },
  myCompany: {
    sra: 'editSraReport'
  }
});

// List of pages that come after "tab" in url to differ from "item" (id) from useLocation custom hook
const Items = [...SRA_SUB_PAGES];

const AppBreadcrumbs = ({ app, tab, item }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const basePath = `/${app}/${tab}${item && !Items.includes(item) ? `/${item}` : ''}`;
  const basePathName = BasePath[app] || basePath;
  const nextPathnames = pathname
    .slice(basePathName.length, pathname.length)
    .split('/')
    .filter(x => x);

  const pathnames =
    // eslint-disable-next-line no-nested-ternary
    app === 'clients' && clientsCrumbConfig[tab]
      ? [`${clientsCrumbConfig[tab]?.basePath}/${item}`, `${app}/${tab}/${item}`]
      : app === 'myCompany' && myCompanyCrumbConfig[tab]
      ? [`${myCompanyCrumbConfig[tab]?.basePath}/`, `${app}/${tab}/`]
      : [basePathName?.substring(1), ...nextPathnames];
  const handleClick = path => {
    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }
    history.push(path);
  };

  const getName = (name, index) => {
    const resource = AddEditPathNames?.[app]?.[pathnames[index - 1]];

    if (app === 'clients' && clientsCrumbConfig[tab]) {
      return clientsCrumbConfig[Object.keys(clientsCrumbConfig).filter(config => name.includes(config))[0]]?.label;
    }

    if (app === 'myCompany' && myCompanyCrumbConfig[tab]) {
      return myCompanyCrumbConfig[Object.keys(myCompanyCrumbConfig).filter(config => name.includes(config))[0]]?.label;
    }

    if (CustomPathNames?.[app]?.[name]) {
      return CustomPathNames[app][name];
    }

    switch (name) {
      case basePathName.substring(1):
        return AppDisplayName[app];
      case 'create':
        return `New ${snakeToCamelTitle(resource)}`;
      case 'edit':
        return `Edit ${snakeToCamelTitle(resource)}`;
      default:
        return snakeToCamelTitle(name);
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathnames.map((name, index) => {
        let path = `/${pathnames.slice(0, index + 1).join('/')}`;
        const hasId = pathnames[index] === 'edit' || pathnames[index] === 'conflict';
        const isLast = index === pathnames.length - 1 || hasId;

        //not show id
        const pathname = pathnames[index - 1]
        if (
          pathname === 'edit' ||
          pathname === 'conflict' ||
          (pathname && (pathname === PathFollowedById[app]?.[tab]))
        ) {
          return null;
        }

        if (DismissPathNames[app] && DismissPathNames[app].includes(name)) return null;

        if (pathnames[index] === PathFollowedById?.[app]?.[tab] && pathnames[index + 1]) {
          path += `/${pathnames[index + 1]}`;
        }

        return isLast ? (
          <Link key={name} active>
            {index === 0 && <ArrowBack color="inherit" />}
            {getName(name, index)}
          </Link>
        ) : (
          <Link key={name} onClick={() => handleClick(path)}>
            {index === 0 && <ArrowBack color="inherit" />}
            {getName(name, index)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;