/* eslint-disable react/prop-types */
import { Typography } from '@trustsecurenow/components-library';
import React from 'react';

const DateCell = props => {
  return (
    <Typography variant="body2" color={props.color}>
      {props.date}
    </Typography>
  );
};

export default DateCell;
