// @flow
import React, { useEffect } from 'react';
import 'intersection-observer';
import { Viewer, Worker, RenderPageProps } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

const renderPage = (props: RenderPageProps) => {
  return (
    <>
      {props.svgLayer.children}
      {props.textLayer.children}
      {props.annotationLayer.children}
    </>
  );
};

const PDFViewer = ({ url, svgLayer, divWrap = true, onDocumentLoad }) => {
  let viewerProps = { fileUrl: url };
  if (svgLayer) viewerProps.renderPage = renderPage;
  if (onDocumentLoad) viewerProps.onDocumentLoad = onDocumentLoad;

  return (
    <Worker workerUrl={pdfjsWorker}>
      <ConditionalWrapper
        condition={divWrap}
        wrapper={children => <div className="viewer-container"> {children} </div>}
      >
        <Viewer {...viewerProps} />
      </ConditionalWrapper>
    </Worker>
  );
};

export default PDFViewer;
