import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Upload = props => (
  <SvgIconStyled {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C16.3476 1 19.941 4.22111 20.3693 8.33592C22.436 8.75485 24 10.6261 24 12.8697C24 15.4298 21.9798 17.5058 19.4942 17.5058C18.9822 17.5058 18.5632 17.0869 18.5632 16.5749C18.5632 16.0628 18.9822 15.6439 19.4942 15.6439C20.9465 15.6439 22.1381 14.3964 22.1381 12.8697C22.1381 11.3522 20.9558 10.114 19.4942 10.114C19.2242 10.114 18.9915 10.0023 18.8239 9.82544C18.6563 9.65787 18.5446 9.42514 18.5446 9.16447C18.5446 5.69201 15.6028 2.87121 11.9907 2.87121C8.39721 2.87121 5.47402 5.69201 5.47402 9.16447C5.47402 9.55547 5.23196 9.88131 4.88751 10.0209C4.76649 10.0675 4.64546 10.1047 4.50582 10.1047C3.05353 10.1047 1.86191 11.3522 1.86191 12.879C1.86191 14.3964 3.04422 15.6346 4.50582 15.6346C5.01784 15.6346 5.43677 16.0535 5.43677 16.5656C5.43677 17.0776 5.01784 17.4965 4.50582 17.4965C2.02017 17.4965 0 15.4298 0 12.879C0 10.6261 1.58262 8.74554 3.65864 8.33592C4.08688 4.22111 7.66177 1 12 1ZM12.6609 13.4282L13.3219 14.0892L16.3009 17.0683C16.664 17.4314 16.664 18.0272 16.3009 18.3902C15.9379 18.7533 15.3421 18.7533 14.979 18.3902L12.9309 16.3421V22.8961C12.9309 23.4081 12.512 23.827 11.9999 23.827C11.4879 23.827 11.069 23.4081 11.069 22.8961V16.3421L9.02088 18.3902C8.65781 18.7533 8.06198 18.7533 7.69891 18.3902C7.52203 18.204 7.42896 17.9713 7.42896 17.7293C7.42896 17.4872 7.52203 17.2545 7.69891 17.0683L10.678 14.0892L11.3389 13.4282C11.702 13.0652 12.2978 13.0652 12.6609 13.4282Z"
      fill="#535353"
    />
  </SvgIconStyled>
);

export default React.memo(Upload);
