
import { getStorage } from '../storage';
import apiClient from '../apiClient/apiClient';
const taxExempt = {}

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_14;
const baseURL = `https://${apiGWID}.${base}/${env}`;

const requestOptions = (data, method, query = "") => ({
  url: `${baseURL}/partnerProfile/${method}/${getStorage("partnerId")}${query}`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true),
  },
  data
})

taxExempt.getCertificate = (certificateID) => {
  return apiClient.get(requestOptions(null, "certificate", `?certificate=${certificateID}`));
}

taxExempt.addCertificate = (data) => {
  return apiClient.post(requestOptions(data, "addCertificate"))
}

taxExempt.putCertificate = (data) => {
  return apiClient.put(requestOptions(data, "certificate"))
}

taxExempt.postCertificate = (data) => {
  return apiClient.post(requestOptions(data, "certificate"))
}

taxExempt.getChoices = () => {
  return apiClient.get(requestOptions(null, "addCertificate"))
}

taxExempt.postCertificateConfirm = () => {
  return apiClient.post(requestOptions(null, "sendTaxConfirmation"))
}

taxExempt.viewCertificate = (certificateId) => {
  return apiClient.get(requestOptions(null, "certificate", `?certificate=${certificateId}`));
}

export default taxExempt
