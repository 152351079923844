const complianceChart = {
  defaultSortValue: {
    sortname: 'code',
    order: 'asc'
  },
  sort: ['code'],
  head: [
    {
      id: 'policy',
      label: 'Code',
      align: 'left'
    },
    {
      id: 'compliance_name',
      label: 'HIPPA Compliance based on existing controls',
      align: 'left'
    },
    {
      id: 'implemented',
      label: 'Implemented',
      align: 'left'
    },
    {
      id: 'partial',
      label: 'Partially Implemented',
      align: 'left'
    },
    {
      id: 'not_implemented',
      label: 'Not Implemented',
      align: 'left'
    }
  ]
};

export default complianceChart;
