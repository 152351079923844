import { FormControl as MuiFormControl, Popper, ListItemText as MuiListItemText } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import styled from 'styled-components';

export const CloseFilter = styled.div`
  cursor: pointer;
`;

export const ContainerSearch = styled(Popper)`
  && {
    z-index: 2;
    top: initial !important;
    left: initial !important;
    transform: translate3d(160px, 70px, 0) !important;
  }
`;

export const ContainerMenuFilters = styled(Popper)`
  && {
    z-index: 100;
  }
`;

export const ArrowIcon = styled(ArrowForwardIos)`
  margin-left: 16px;
  path {
    fill: ${({ active }) => (active ? 'var(--colorBox)' : 'var(--colorDefault)')};
  }
`;

export const ListItemText = styled(MuiListItemText)`
  color: ${({ selected }) => (selected ? 'var(--colorBox)' : 'var(--colorDefault)')};
`;

export const FormControl = styled(MuiFormControl)`
  && {
    background-color: var(--backgroundDefault);
    margin-right: calc(var(--spacing) * 2);
    margin-top: var(--spacing);
    border-radius: 4px;
    color: var(--colorDefault);
    width: 100%;
    height: 42px;

    & > label {
      color: var(--colorLight);
      &.Mui-focused,
      &[data-shrink=true] {
        transform: translate(12px, 4px) scale(0.7);
      }
      &[data-shrink=false] {
        z-index: 1;
        transform: translate(12px, 14px) scale(1);
        pointer-events: none;
      }
    }

    & > div {
      background-color: var(--backgroundDefault);
      border-radius: 4px;
      height: 42px;

      &:before, &:after, &:hover::before {
        border: none;
      }

      & > div {
        padding: 0 12px;
        background-color: var(--backgroundDefault);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        color: var(--colorDefault);
        & > div {
          margin: 0;
          padding-top: ${({ noLabel }) => noLabel ? '0' : '12px'};
          & > span {
            font-size: 14px;
          }
        }
      }
    }
  }
`;