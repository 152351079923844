import { useReducer, useState } from 'react';
import { useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { admin } from 'helpers';
const initialState = {
  filters: {
    name: null
  },
  sort: {
    sortname: '',
    order: 'asc'
  },
  pagination: {
    page: 0,
    perPage: 25
  }
};
const useAdminList = (adminPage, partnerId) => {
  const notify = useNotify();
  const [state, setState] = useReducer(reducer, initialState);
  const [idsSelected, setIdsSelected] = useState([]);
  const [openDialog, setOpen] = useState(false);

  const { isSuccess, data: record, refetch, ...query } = useQuery(
    [`admin-${adminPage}-list`, { ...state, partnerId }],
    async () => {
      const { data, headers } = await admin[adminPage].getList(state, partnerId);
      return { data, total: headers['x-total-count'] || data.length };
    },
    {
      staleTime: 10,
      cacheTime: false,
      notifyOnChangeProps: ['data', 'error'],
      refetchOnWindowFocus: false
    }
  );

  const setOpenDialog = (val, id) => {
    setOpen(val);
    setIdsSelected(id ? [...idsSelected, id] : []);
  };

  const dispatch = {};

  dispatch.delete = () => {
    setOpenDialog(false);

    admin[adminPage]
      .delete(idsSelected)
      .then(res => {
        refetch();
        notify(res.data?.message);
      })
      .catch(err => {
        notify(err?.response?.data?.description || 'Failed to Delete', 'error');
      });
  };

  dispatch.actions = type => {
    admin[adminPage]
      .actions(type, idsSelected)
      .then(res => {
        notify(res.data?.status);
        refetch();
        setIdsSelected([]);
      })
      .catch(err => {
        notify(err?.response?.data?.status, 'error');
      });
  };

  dispatch.setOpenDialog = (val, id) => {
    setOpenDialog(val, id);
  };

  dispatch.setPage = page => {
    setState({ type: 'SET_PAGE', payload: page });
  };

  dispatch.setPerPage = perPage => {
    setState({ type: 'SET_PERPAGE', payload: perPage });
  };

  dispatch.setSort = sortValue => {
    setState({ type: 'SET_SORT', payload: sortValue });
  };

  dispatch.onLoad = params => {
    setState({ type: 'SET_INITIAL_PARAMS', payload: params });
  };

  dispatch.search = value => {
    setState({ type: 'SET_SEARCH_FILTERS', payload: { name: 'name', value } });
  };

  dispatch.filters = (name, value) => {
    setState({ type: 'SET_SEARCH_FILTERS', payload: { name, value } });
  };

  dispatch.setNewParam = (name, value) => {
    setState({ type: 'SET_NEW_PARAM', payload: { name, value } });
  };

  dispatch.onSelectAll = checked => {
    setIdsSelected(checked ? record.data.map(row => row.id) : []);
  };

  dispatch.onSelectRow = (checked, id) => {
    if (checked) {
      setIdsSelected([...idsSelected, id]);
    } else {
      setIdsSelected(idsSelected.filter(idS => idS !== id));
    }
  };

  const newData = adminPage === 'partners' ? record?.data?.data : record?.data;

  return {
    ...query,
    data: isSuccess ? newData : null,
    total: isSuccess ? record.total : 0,
    isSuccess,
    refetch,
    page: state.pagination.page || 0,
    perPage: state.pagination.perPage || 25,
    filters: state.filters,
    dispatch,
    idsSelected,
    openDialog,
    state
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_PARAMS':
      return {
        ...state,
        sort: {
          sortname: action.payload.sortname,
          order: action.payload.order
        },
        pagination: {
          page: action.payload.page,
          perPage: action.payload.perPage
        }
      };
    case 'SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      };
    case 'SET_PERPAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
          page: 0
        }
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: {
          sortname: action.payload.sortname,
          order: action.payload.order
        }
      };
    case 'SET_SEARCH_FILTERS':
      const page = action.payload.value ? 0 : state.pagination.page;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page
        },
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value
        }
      };
    case 'SET_NEW_PARAM':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 0
        },
        [action.payload.name]: action.payload.value
      };
  }
}

export default useAdminList;
