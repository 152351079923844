// @flow

import React, { type ComponentType, type Element } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import MuiMenuIcon from '@material-ui/icons/Menu';
// import { theme } from 'conf';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import { IconButton } from '@material-ui/core';

const arrowSize = 6;

export const ButtonSuspended: ComponentType<*> = styled(IconButton)`
  flex: 0;
  width: 55px;
  height: 55px;
  align-self: flex-end;
  position: relative;
  font-size: 20px;

  &:before,
  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: ${arrowSize}px solid transparent;
    border-bottom: ${arrowSize}px solid transparent;
    transition: opacity 300ms ease-in-out;
  }

  &:before {
    border-right: ${arrowSize}px solid rgba(255, 255, 255, 0.6);
    margin-right: 4px;
    opacity: ${({ open }) => (open ? 1 : 0)};
  }

  &:after {
    border-left: ${arrowSize}px solid rgba(255, 255, 255, 0.6);
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
`;

// export const IconSuspended: ComponentType<*> = styled(MuiMenuIcon)`
//   transition: ${theme.transitions.create(['transform'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   })};
//   transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
//   fill: ${({ isSuspended }) => (isSuspended ? '#fff' : '#000')};
//
//   &:hover {
//     background-color: ${({ isSuspended }) => (isSuspended ? 'rgba(255, 255, 255, 0.2) !important' : 'transparent')};
//   }
// `;

export const IconSuspended: ComponentType<*> = styled(MuiMenuIcon)`
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  fill: ${({ isSuspended }) => (isSuspended ? '#fff' : '#000')};

  &:hover {
    background-color: ${({ isSuspended }) => (isSuspended ? 'rgba(255, 255, 255, 0.2) !important' : 'transparent')};
  }
`;

type Props = {
  toggleSidebar: Function,
  open: boolean,
  icon?: Element<*>,
  isSuspended?: boolean
};

const MenuIcon = ({ toggleSidebar, open, icon, isSuspended }: Props) => {
  return (
    <ButtonSuspended onClick={toggleSidebar} open={open}>
      <IconSuspended color="inherit" aria-label="open drawer" isSuspended={isSuspended} open={open}>
        {icon}
      </IconSuspended>
    </ButtonSuspended>
  );
};

MenuIcon.defaultProps = {
  icon: <MuiMenuIcon />,
  isSuspended: false
};

const enhance = compose(
  connect(
    state => ({
      open: state.admin.ui.sidebarOpen
    }),
    {
      toggleSidebar: toggleSidebarAction
    }
  )
);

export default enhance(MenuIcon);
