// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const CalendarFile = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M21.2389 3.97614H20.5371V2.92343C20.5371 2.41554 20.1215 2 19.6137 2C19.1058 2 18.6902 2.41554 18.6902 2.92343V3.97614H12.9558V2.92343C12.9558 2.41554 12.5402 2 12.0323 2C11.5244 2 11.1089 2.41554 11.1089 2.92343V3.97614H5.38362V2.92343C5.38362 2.41554 4.96807 2 4.46019 2C3.9523 2 3.53675 2.41554 3.53675 2.92343V3.97614H2.77029C1.24663 3.97614 0 5.22277 0 6.74643V19.2404C0 20.7641 1.24663 22.0107 2.77029 22.0107C2.77029 22.0107 18.9858 22.02 19.0135 22.02C19.2535 22.02 19.4844 21.9276 19.6691 21.7522L23.7229 17.6983C24.0277 17.3566 23.9907 17.135 23.9999 17.135V6.74643C24.0092 5.22277 22.7626 3.97614 21.2389 3.97614ZM18.9027 19.9145V17.135H21.6822L18.9027 19.9145ZM22.1623 15.2882H18.1177C17.8591 15.2882 17.6283 15.3897 17.4621 15.5652C17.222 15.7314 17.0558 15.9992 17.0558 16.3132V20.1823L2.77953 20.1639C2.27165 20.1639 1.8561 19.7483 1.8561 19.2404V11.3451H22.1623V15.2882ZM22.1623 9.49825H1.8561V6.74643C1.8561 6.23855 2.27165 5.823 2.77953 5.823H3.546V6.33088C3.546 6.83877 3.96154 7.25431 4.46943 7.25431C4.97731 7.25431 5.39286 6.83877 5.39286 6.33088V5.823H11.1181V6.33088C11.1181 6.83877 11.5337 7.25431 12.0416 7.25431C12.5495 7.25431 12.965 6.83877 12.965 6.33088V5.823H18.6995V6.33088C18.6995 6.83877 19.115 7.25431 19.6229 7.25431C20.1308 7.25431 20.5463 6.83877 20.5463 6.33088V5.823H21.2481C21.756 5.823 22.1716 6.23855 22.1716 6.74643V9.49825H22.1623Z" />
  </SvgIconStyled>
);

export default CalendarFile;
