import React, { useMemo } from 'react';
import { Typography, Avatar, InfoIcon, Stack, styled, alpha, AlertDialog } from '@trustsecurenow/components-library';

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 70,
  height: 70,
  backgroundColor: alpha(theme.palette.error.main, 0.2)
}));

const UnPublishDialog = ({ open, packageName, onCloseDialog, unpublishText, onUnpublishPackage }) => {

  const messageParts = useMemo(() => {
    if (!unpublishText) return [];
    const match = unpublishText.match(/^(([^.]*\.){2})(.*)$/);
    if (!match) return [unpublishText];
    return [match[1], match[3]];
  }, [unpublishText]);

  return (
    <AlertDialog
      open={open}
      onClose={onCloseDialog}
      dialogTitle={
        <Stack spacing={2} alignItems="center">
          <CustomAvatar>
            <InfoIcon color="error" sx={{ fontSize: 34 }} />
          </CustomAvatar>

          <Typography color="error" variant="h2">
            Are you sure you want to unpublish {packageName}?
          </Typography>
        </Stack>
      }
      message={
        messageParts.length && (
          <Stack spacing={2}>
            {messageParts.map(message => (
              <Typography key={message} variant="subtitle1" component="p">
                {message}
              </Typography>
            ))}
          </Stack>
        )
      }
      SubmitButtonProps={{
        color: 'error',
        children: `Yes, unpublish ${packageName}`,
        onClick: onUnpublishPackage
      }}
    />
  );
};

export default UnPublishDialog;
