import { GridRow } from '@mui/x-data-grid';
import { Box, Tooltip } from '@trustsecurenow/components-library';
import React from 'react';
import { MODALS, PRODUCT_TYPES } from './constants';

const CustomRow = ({ setModalState, isEBPP, ...props }) => {
  // https://mui.com/material-ui/react-tooltip/#virtual-element
  const positionRef = React.useRef({
    x: 0,
    y: 0
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const isBPP = PRODUCT_TYPES.BPP === props.row.product_type;

  const handleMouseMove = event => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const handleActivateClient = () => {
    if (isBPP) {
      setModalState(isEBPP ? MODALS.ACTIVATE_CLIENT2 : MODALS.ACTIVATE_CLIENT, {
        open: true,
        clientId: props.row.id,
        clientName: props.row.name
      });
    }
  };

  return (
    <Tooltip
      title={isBPP ? 'Client Inactive: Click here to activate' : null}
      placement="top"
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(positionRef.current?.x, areaRef.current?.getBoundingClientRect()?.y, 0, 0);
          }
        }
      }}
    >
      <Box
        ref={areaRef}
        onMouseMove={handleMouseMove}
        sx={{
          '.MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox):not(.alwaysEnabledCell)': {
            opacity: 0.5,
            pointerEvents: 'none'
          }
        }}
        onClick={handleActivateClient}
      >
        <GridRow {...props} />
      </Box>
    </Tooltip>
  );
};

export default CustomRow;
