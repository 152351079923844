import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import contentAdmin from 'helpers/apis/contentAdmin';
import styled from 'styled-components';
import { LoadingStyled } from 'components';
import { Stack } from '@trustsecurenow/components-library';
import { useNotify } from 'ra-core';
import CustomDrawer from '../components/inboxPage/drawer';
import EmailViewer from '../components/inboxPage/emailViewer';
import FiltersBar from '../components/inboxPage/FiltersBar';

const Container = styled.div`
  height: 80vh;
  margin: 40px 5px 5px 5px;
  background-color: var(--backgroundDefault);
  display: flex;
  flex-direction: column;
`;

const FiltersContainer = styled.div`
  /* margin: 5px auto; */
  flex-basis: 100%;
`;

const DrawerContainer = styled.div`
  height: 100%;
  flex-basis: ${props => props.flex || 30}%;
`;
const EmailViewerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${props => props.flex || 70}%;
`;

const ContentAdminInboxPage = () => {
  /**
   * mostly using Refs for window eventlisteners,
   * using react State for react components.
   * as Refs won't trigger UI update when changes
   * and window eventlisteners won't pickup changes in react State.
   */
  const notify = useNotify();
  const [open, setOpen] = useState(true);
  const emailsRef = useRef();
  const [emails, setEmails] = useState(null);
  emailsRef.current = emails;
  const [selectedEmail, setSelectedEmail] = useState(null);
  const loadingRef = useRef(false);
  const [loadingState, setLoadingState] = useState(false);
  loadingRef.current = loadingState;
  const itemsPerPage = useRef(10);
  const currentPage = useRef(0);
  const [filters, setFilters] = useState({
    partner_name: '',
    client_name: '',
    partner_id: '',
    client_id: '',
    delivery_date: null,
    sortingField: 'delivery_date',
    sortingDirection: 'DESC'
  });
  const filtersRef = useRef();
  filtersRef.current = { ...filters };

  const toggleDrawer = () => {
    setOpen(v => !v);
  };

  const getInboxEmailsList = async (filtersParam, reset = false) => {
    try {
      const start = currentPage.current * itemsPerPage.current;
      const end = itemsPerPage.current;
      setLoadingState(true);
      const data = await contentAdmin.getInboxEmailsList(start, end, filtersParam);
      if (emailsRef.current === null || reset) {
        setEmails([...data.data]);
      } else {
        setEmails([...emailsRef.current, ...data.data]);
      }
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      notify(error.message, 'error');
    }
  };

  const applyFilters = useCallback(newFilters => {
    setFilters(oldFilters => {
      return { ...oldFilters, ...newFilters };
    });
    setSelectedEmail(null);
    currentPage.current = 0;
    getInboxEmailsList({ ...newFilters }, true);
  }, []);

  const onReachingScrollEnd = () => {
    if (loadingRef.current) return;
    currentPage.current += 1;
    getInboxEmailsList(filtersRef.current, false);
  };

  useEffect(() => {
    getInboxEmailsList(filters, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (emails === null) return <LoadingStyled />;

  return (
    <Container className="ContentAdminInboxPage_container">
      <FiltersContainer>
        <FiltersBar filters={filters} applyFilters={applyFilters} />
      </FiltersContainer>
      <Stack direction="row">
        <DrawerContainer className="ContentAdminInboxPage_drawerContainer" flex={open ? 25 : 5}>
          {open ? (
            <CustomDrawer
              open={open}
              onClose={() => setOpen(false)}
              onRefresh={() => {
                currentPage.current = 0;
                getInboxEmailsList(filters, true);
              }}
              onReachingScrollEnd={onReachingScrollEnd}
            >
              {emails.map((email, index) => {
                return (
                  <ListItem
                    disabled={loadingState}
                    button
                    key={email.id}
                    onClick={() => {
                      setSelectedEmail({ ...email });
                    }}
                    selected={selectedEmail !== null && email.id === selectedEmail.id}
                    style={{ borderBottom: '2px solid #eee' }}
                  >
                    <ListItemIcon style={{ fontSize: 25 }}>
                      <MailOutlineIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<p style={{ color: 'black', fontWeight: 'bold' }}>{email.from_name}</p>}
                      secondary={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '-10px'
                          }}
                        >
                          <p
                            style={{
                              color: 'rgb(15, 108, 189)',
                              textOverflow: 'ellipsis',
                              flexBasis: '70%',
                              overflow: 'hidden'
                            }}
                          >
                            {email.subject.slice(0, 25)}...
                          </p>
                          <p style={{ fontSize: 12, color: 'rgb(15, 108, 189)', flexBasis: '25%' }}>
                            {email.delivery_date}
                          </p>
                        </div>
                      }
                    />
                  </ListItem>
                );
              })}
            </CustomDrawer>
          ) : (
            <IconButton style={{ width: 30, height: 30 }} onClick={toggleDrawer} color="primary">
              <SubjectIcon style={{ fontSize: 40 }} />
            </IconButton>
          )}
        </DrawerContainer>
        <EmailViewerContainer className="ContentAdminInboxPage_emailViewerContainer" flex={open ? 75 : 95}>
          <EmailViewer email={selectedEmail} />
        </EmailViewerContainer>
      </Stack>
    </Container>
  );
};

export default ContentAdminInboxPage;