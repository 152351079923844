import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  CloseIcon,
  Stack,
  Link,
  Box,
  CircularProgress
} from '@trustsecurenow/components-library';

const TEAMS_APP_DEPLOYMENT_INSTRUCTIONS_URLS = {
  BSN: 'https://help.breachsecurenow.com/hc/en-us/articles/23505565890708-PII-Protect-Microsoft-Teams-Application-Setup',
  HSN: 'https://help.breachsecurenow.com/hc/en-us/articles/27047310425108-HIPAA-Secure-Now-Teams-App-Deployment'
};

const TeamsAppDeploymentInstructionsDialog = ({ state, resetModal, onSubmit }) => {
  const brandingAppName = state.isHSN ? 'HIPAA Secure Now' : 'PII Protect';
  const deploymentInstructionsUrl = state.isHSN ? TEAMS_APP_DEPLOYMENT_INSTRUCTIONS_URLS.HSN : TEAMS_APP_DEPLOYMENT_INSTRUCTIONS_URLS.BSN;
  return (
    <Dialog open={state.open} maxWidth={"md"}>
      <DialogTitle>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{brandingAppName} Teams App Deployment</Typography>
          {!state.loading && (
            <IconButton onClick={resetModal}>
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        {state.loading ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress shaded size={75} />
          </Box>
        ) : (
          <>
            <Typography variant="subtitle2" mb={1.5}>
              Step 1: Deploy The {brandingAppName} Teams app
            </Typography>
            <Typography variant="body2" mb={3.25} minWidth={834}>
              <Link color="info.main" variant="link2" href={deploymentInstructionsUrl} target="_blank">
                Click here
              </Link>{' '}
              to access deployment instructions. Please be aware that the {brandingAppName} app will not <br />
              be marked as &lsquo;deployed&rsquo; on your dashboard until a user from this client accesses the app for
              the first time.
            </Typography>

            <Typography variant="subtitle2" mb={1.5}>
              Step 2: Notify your users that the {brandingAppName} Teams app is now available
            </Typography>
            <Typography variant="body2" mb={2.5} minWidth={834}>
              Notify all users in this client that the {brandingAppName} app is now available in Teams. This will send an
              email to all users <br /> and display a banner on their screen next time they login to the platform.
            </Typography>

            <Stack mb={5}>
              <Button width={223} color="success" onClick={onSubmit} sx={{ textTransform: 'none' }}>
                Click here to notify all users
              </Button>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

TeamsAppDeploymentInstructionsDialog.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    clientId: PropTypes.string.isRequired,
    isHSN: PropTypes.bool.isRequired,
  }).isRequired,
  resetModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TeamsAppDeploymentInstructionsDialog;
