// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const LandingPage = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 12 14" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h12v14H0z" />
    <path d="M12,3.9v8.587A1.588,1.588,0,0,1,10.365,14H1.635A1.588,1.588,0,0,1,0,12.491V1.509A1.588,1.588,0,0,1,1.635,0H7.77V1.758A2.246,2.246,0,0,0,10.1,3.9Z" />
    <path d="M0,0,3,2H1.617C.733,2,0,1.511,0,.922Z" transform="translate(9 1)" />
  </SvgIconStyled>
);

export default LandingPage;
