import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components';
import WorkPlanAddButton from './WorkPlanAddButton';

const choices = [
  { value: 'sraDocuments', label: 'SRA Documents' },
  { value: 'workPlan', label: 'Work Plans' }
];

const WorkPlanToolbar = ({ value, setRefresh, id, dispatchSetValue, refetchOnCreate, allowCreate }) => (
  <>
    <SelectField
      value={value}
      name="policies"
      onChange={e => dispatchSetValue(e.target.value)}
      emptyValue="SRA Type"
      choices={choices}
      mr={2}
      variant
      GAclick
    />
    {allowCreate && (
      <WorkPlanAddButton
        tab={value}
        label={value === 'workPlan' ? 'New Work Plan' : 'New SRA Document'}
        setRefresh={setRefresh}
        id={id}
        refetchOnCreate={refetchOnCreate}
      />
    )}
  </>
);

WorkPlanToolbar.defaultProps = {
  allowCreate: false
};

WorkPlanToolbar.propTypes = {
  value: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  dispatchSetValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  refetchOnCreate: PropTypes.func.isRequired,
  allowCreate: PropTypes.bool
};

export default memo(WorkPlanToolbar);
