// @flow
import React, { useCallback } from 'react';
import Modal from '../../modal/Modal';
import TrainingModalTable from './TrainingModalTable';
import {
  TRAINING_ANNUAL,
  TRAINING_TRAININGS,
  TRAINING_TRAININGS_USER,
  TRAINING_USERS,
  TRAINING_USERS_TRAININGS
} from './constants';

const TrainingModal = ({ source, open, onClose, id }) => {

  const getTableContent = useCallback((source) => {
    switch (source) {
      case TRAINING_TRAININGS:
        return TRAINING_TRAININGS_USER
      case TRAINING_USERS:
        return TRAINING_USERS_TRAININGS
    }
  }, [source])

  const tableSource = getTableContent(source);

  return (
    <Modal
      data={<TrainingModalTable source={tableSource} onClose={onClose} id={id}/>}
      fullWidth
      dismissOutside
      title={"Micro Training"}
      opened={open}
      onClose={onClose}
      maxWidth={"md"}
    />
  );
};

export default TrainingModal;
