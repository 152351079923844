import React, { createContext, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useId, useLocation } from 'hooks';
import * as sra from 'helpers/apis/services/sra';
import { encodeId } from 'helpers';
import { SRA_SUB_PAGES } from '../constants';

export const initialState = {
  activeTask: 'information',
  revisionId: ''
};

export const SRAContext = createContext(null);

export const SRADispatcherContext = createContext(null);

export const SRAProvider = ({ children }) => {
  const userId = useId();
  const { item } = useLocation();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const clientId = item && !isSraSubPage ? item : userId;
  const [state, setState] = useReducer(reducer, initialState);

  const dispatch = {};

  dispatch.setActiveTask = task => {
    setState({ type: 'SET_ACTIVE_TASK', payload: task });
  };

  dispatch.setRevisionId = id => {
    setState({ type: 'SET_REVISION_ID', payload: id });
  };

  useEffect(() => {
    if (clientId && state.revisionId === '') {
      sra.getRevisionId(clientId).then(res => {
        dispatch.setRevisionId(res?.data?.id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, state.revisionId]);

  return <SRAContext.Provider value={{ state, dispatch, clientId }}>{children}</SRAContext.Provider>;
};

SRAProvider.propTypes = {
  children: PropTypes.node.isRequired
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'SET_ACTIVE_TASK':
      return {
        ...prevState,
        activeTask: payload
      };
    case 'SET_REVISION_ID':
      return {
        ...prevState,
        revisionId: payload
      };
    default:
      return prevState;
  }
}

export const useSRA = () => useContext(SRAContext);
