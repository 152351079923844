// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Bar = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 27.1 21.9" role="img" aria-hidden="false">
      <path d="M20.33 7H4C3.45 7 3 6.55 3 6C3 5.45 3.45 5 4 5H20.33C20.88 5 21.33 5.45 21.33 6C21.33 6.55 20.88 7 20.33 7Z" fill="white"/>
      <path d="M20.33 12.56H4C3.45 12.56 3 12.11 3 11.56C3 11.01 3.45 10.56 4 10.56H20.33C20.88 10.56 21.33 11.01 21.33 11.56C21.33 12.11 20.88 12.56 20.33 12.56Z" fill="white"/>
      <path d="M20.33 18.16H4C3.45 18.16 3 17.71 3 17.16C3 16.61 3.45 16.16 4 16.16H20.33C20.88 16.16 21.33 16.61 21.33 17.16C21.33 17.71 20.88 18.16 20.33 18.16Z" fill="white"/>
  </SvgIconStyled>
);

export default Bar;
