import React from 'react';
import { TextField } from '@trustsecurenow/components-library';

function PercentageInputFilter(props) {
  const { item, applyValue } = props;

  const handleFilterChange = event => {
    const inputValue = event.target.value;
    const parsedValue = parseFloat(inputValue);
    if (parsedValue > 100 || parsedValue < 0) return;
    applyValue({ ...item, value: inputValue });
  };

  // prevent firefox and edge accepts letters on input type number
  const handleKeyDown = event => {
    // Allow: backspace, delete, tab, escape, enter
    if (
      [46, 8, 9, 27, 13].includes(event.keyCode) ||
      // Allow: Ctrl+A, Command+A
      (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (event.keyCode >= 35 && event.keyCode <= 40)
    ) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  };

  return (
    <TextField
      label="Value"
      inputMode="numeric"
      value={item.value}
      onChange={handleFilterChange}
      onKeyDown={handleKeyDown}
      inputProps={{
        min: 0,
        max: 100
      }}
      type="number"
    />
  );
}

export default PercentageInputFilter;
