import React from 'react';
import PropTypes from 'prop-types';
import WorkPlan from './workPlan/WorkPlan';

const PageWorkPlan = ({ app }) => <WorkPlan app={app} />;

PageWorkPlan.propTypes = {
  app: PropTypes.string.isRequired
};

export default PageWorkPlan;
