// @flow
import React, { useState, useCallback } from 'react';
import { makeStyles, DialogContent, FormGroup } from '@material-ui/core';
import { CheckboxWithIcon } from '../../common';
import { getRoleByName } from '../../../newsfeedHelpers';
import { ModalTitle, ModalActions } from './index';
import { AUDIENCES } from 'apps/newsfeed/constants';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0
  }
}));

const SelectRole = ({ options, setView, data, setAudienceData }) => {
  const classes = useStyles();
  const [selectedRoles, setSelectedRoles] = useState([...data.allowed_groups]);

  const onChangeRole = e => {
    const { checked, name } = e.target;
    setSelectedRoles(prevState => {
      if (checked) return [...prevState, name];
      else return prevState.filter(item => item !== name);
    });
  };

  const onSaveRoles = useCallback(
    () => {
      setView('main');
      setAudienceData(prevState => {
        return {
          ...prevState,
          allowed_groups: selectedRoles,
          audience: selectedRoles?.length > 0 && prevState.audience?.name === AUDIENCES.partner ? null : prevState.audience
        }
      });
    },
    [selectedRoles]
  );

  const handleViewChange = useCallback(view => setView(view), []);

  return (
    <>
      <ModalTitle title="Roles - Select the option" onClick={() => handleViewChange('main')} />

      <DialogContent className={classes.dialogContent}>
        <FormGroup>
          {options.map(item => (
            <CheckboxWithIcon
              key={item.id}
              checked={selectedRoles.includes(item.id.toString())}
              onChange={onChangeRole}
              name={item.id}
              label={getRoleByName(item.name).name}
              labelPlacement="start"
              icon={getRoleByName(item.name).icon}
            />
          ))}
        </FormGroup>
      </DialogContent>

      <ModalActions title="Save Changes" onClick={onSaveRoles} />
    </>
  );
};

export default SelectRole;
