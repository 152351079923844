// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { Avatar, SnackbarContent as MuiSnackbarContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { theme } from 'conf/theme';
import { green, amber } from 'conf/theme/colors';

export const AvatarColor: ComponentType<*> = styled(Avatar)`
  border: 1px solid grey;
  width: 20px;
  height: 20px;
`;

export const SnackbarContent: ComponentType<*> = styled(MuiSnackbarContent)`
  position: relative;
  padding: 0 30px !important;
  text-align: left;
  background-color: ${({ type }) => {
    switch (type) {
      case 'success':
        return green[600];
      case 'error':
        return theme.palette.error.dark;
      case 'warning':
        return amber[700];
      default:
        return theme.palette.primary.dark;
    }
  }} !important;

  .error-message-icon,
  .error-message-action {
    position: absolute;
    top: 0;
  }

  .error-message-icon {
    opacity: 0.9;
    left: 5px;
    top: 10px;
  }

  .error-message-action {
    right: 5px;
  }
`;

export const CloseIcon: ComponentType<*> = styled(Close)`
  font-size: 20px;
`;
