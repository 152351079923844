// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Delete = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path d="M14.1391 2.08965H9.94746C9.95365 2.06492 9.96599 2.04019 9.96599 2.01546C9.96599 0.902633 9.08192 0 8 0C6.91808 0 6.03401 0.902633 6.03401 2.01546C6.03401 2.04019 6.03401 2.06492 6.03401 2.08965H1.85471C0.834621 2.08965 0 2.92427 0 3.94436V4.19784C0 5.21793 0.834621 6.05255 1.85471 6.05255H2.37405C2.31223 6.23184 2.27511 6.4235 2.27511 6.62133V14.1329C2.27511 15.153 3.10973 15.9876 4.12982 15.9876H11.8702C12.8903 15.9876 13.7249 15.153 13.7249 14.1329V6.62133C13.7249 6.4235 13.6878 6.23184 13.626 6.05255H14.1453C15.1654 6.05255 16 5.21793 16 4.19784V3.94436C15.9938 2.92427 15.1654 2.08965 14.1391 2.08965ZM7.2643 2.01546C7.2643 1.5827 7.59196 1.23648 7.99381 1.23648C8.39567 1.23648 8.72333 1.58888 8.72333 2.01546C8.72333 2.04019 8.73571 2.06492 8.74189 2.08965H7.25192C7.25192 2.06492 7.2643 2.04019 7.2643 2.01546ZM12.4822 6.62133V14.1329C12.4822 14.4729 12.204 14.7512 11.864 14.7512H4.12364C3.78361 14.7512 3.5054 14.4729 3.5054 14.1329V6.62133C3.5054 6.36167 3.65999 6.14529 3.88874 6.05255H5.52086V11.4807C5.52086 11.8207 5.79907 12.0989 6.1391 12.0989C6.47913 12.0989 6.75734 11.8207 6.75734 11.4807V6.05255H9.23029V11.4374C9.23029 11.7774 9.50849 12.0556 9.84853 12.0556C10.1886 12.0556 10.4668 11.7774 10.4668 11.4374V6.05255H12.0989C12.3277 6.14529 12.4822 6.36785 12.4822 6.62133ZM14.7573 4.19784C14.7573 4.53787 14.4791 4.81608 14.1391 4.81608H12.2659C12.136 4.78517 12 4.76662 11.8578 4.76662H4.11749C3.97529 4.76662 3.84544 4.78517 3.70943 4.81608H1.83619C1.49616 4.81608 1.21795 4.53787 1.21795 4.19784V3.94436C1.21795 3.60433 1.49616 3.32612 1.83619 3.32612H14.1329C14.4729 3.32612 14.7511 3.60433 14.7511 3.94436V4.19784H14.7573Z" />
  </SvgIconStyled>
);

export default Delete;
