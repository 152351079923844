// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const RightArrow = props => (
  <SvgIconStyled {...props} role="img" aria-hidden="false">
    <path
      d="M0.959497 22.0001C0.690619 22.0001 0.431344 21.8849 0.239288 21.6736C-0.106413 21.2703 -0.0680016 20.6653 0.325713 20.3196L10.9464 11.0049L0.325713 1.68059C-0.0776044 1.33489 -0.116016 0.72031 0.239288 0.326595C0.584988 -0.0671191 1.19957 -0.115133 1.59328 0.24017L12.214 9.55488C12.6269 9.91979 12.867 10.4479 12.867 10.9953C12.867 11.5427 12.6269 12.0708 12.214 12.4357L1.59328 21.7504C1.41083 21.9233 1.18036 22.0001 0.959497 22.0001Z"
    />
  </SvgIconStyled>
);

export default RightArrow;
