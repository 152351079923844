// @flow

import errorHandlerReducer from './errorHandlerReducer';
import bsnReducer from './bsnReducerPersist';
import newsfeedReducer from './newsfeedReducer';
import authReducer from './authReducer';

export default {
  errorHandler: errorHandlerReducer,
  bsn: bsnReducer,
  newsfeed: newsfeedReducer,
  auth: authReducer
};

export { default as errorHandlerReducer } from './errorHandlerReducer';
export { default as bsnReducer } from './bsnReducerPersist';
