// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ColorPickup = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18.922 18.942" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18.922v18.942H0z" />
    <g transform="translate(-251 -82.5)">
      <path
        d="M4.322,23.177A3.036,3.036,0,0,0-.859,21.02l-2.153,2.154-1.139-1.139a.624.624,0,0,0-.891,0L-7.1,24.088a.628.628,0,0,0-.188.446.628.628,0,0,0,.188.446l1.165,1.165-6.312,6.362a.772.772,0,0,0-.149.217l-.729,2.436a.415.415,0,0,1-.111.161l-1.192,1.192a.632.632,0,0,0-.176.458.763.763,0,0,0,.144.419l1.5,1.51a.631.631,0,0,0,.458.176.63.63,0,0,0,.458-.176l1.192-1.192a.787.787,0,0,1,.193-.12l2.362-.7.064-.027a.5.5,0,0,0,.215-.158l6.292-6.292,1.266,1.266a.674.674,0,0,0,.878.032l2.093-2.086a.624.624,0,0,0,0-.891l-1.241-1.24L3.43,25.334A3.031,3.031,0,0,0,4.322,23.177ZM-2.61,29.491-8.8,35.706l-2.319.691a1.275,1.275,0,0,0-.541.318l-.507.507a.421.421,0,0,1-.58,0,.42.42,0,0,1,0-.579l.507-.507a1.327,1.327,0,0,0,.317-.538l.692-2.322L-5.04,27.06ZM.563,26.419l-2.507-2.506L.032,21.936a1.772,1.772,0,1,1,2.507,2.506Zm.63,2.742L.03,30.323l-5.778-5.777,1.163-1.163Z"
        transform="translate(265.6 62.367)"
      />
    </g>
  </SvgIconStyled>
);

export default ColorPickup;
