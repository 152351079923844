import React, { useEffect } from 'react';
import { getStorage } from '../../../../helpers';
import { useHistory } from 'react-router-dom';
import directorySync from '../../../../helpers/apis/directorySync';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { AAD_SERVICE, DMD_SERVICE } from './constants';
import { useNotify } from 'react-admin';

const PhishingDirectMailboxDelivery = props => {
  const notify = useNotify();

  const history = useHistory();

  const search = props.location.search;
  const params = new URLSearchParams(search);

  const id_token = params.get('id_token');
  const state = params.get('state');
  const admin_consent = params.get('admin_consent');
  const redirect_to = getStorage('redirect_to');

  useEffect(() => {
    if (admin_consent === 'True') {
      const data = {
        client_id: state,
        id_token,
        service: AAD_SERVICE
      };
      contentAdmin
        .aadAuth(data)
        .then(resp => {
          const message = 'You have authorized successfully.';
          history.push(redirect_to);
          notify(message);
        })
        .catch(error => {
          const message = error?.response?.data?.message || 'Something went wrong, please, try again.';
          history.push(redirect_to);
          notify(message, 'warning');
        });
    } else {
      const message = 'You should allow all the permission to continue.';
      history.push(redirect_to);
      notify(message, 'warning');
    }
  }, []);

  return <div> Redirecting...... </div>;
};

export default PhishingDirectMailboxDelivery;
