// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Microtraining = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.1761 8.01903C23.0561 7.89902 22.9268 7.79748 22.7883 7.71439L13.6305 2.43389C13.5935 2.41543 13.5567 2.38773 13.5106 2.36927C13.0028 2.12001 12.5043 2 12.0058 2C11.5073 2 11.0087 2.12001 10.501 2.36927C10.4641 2.38773 10.4179 2.40619 10.381 2.43389L1.20471 7.73285C1.05701 7.81594 0.92778 7.91749 0.817001 8.0375C-0.272334 9.14529 -0.272334 10.9362 0.817001 12.044C0.937012 12.164 1.06624 12.2656 1.20471 12.3487L3.41109 13.6134V18.0077C3.41109 18.1277 3.43879 18.2385 3.47572 18.34C3.47572 18.3492 3.47572 18.3492 3.47572 18.3585C3.52188 18.4693 3.58651 18.5616 3.66036 18.6447C3.66959 18.6539 3.6696 18.6631 3.6696 18.6631C5.89443 20.9156 8.84857 22.1527 11.9873 22.1527C15.1261 22.1527 18.0802 20.9156 20.305 18.6631C20.4066 18.5616 20.4712 18.4416 20.5174 18.3216C20.6004 18.1831 20.6559 18.0354 20.6559 17.8692V13.558L22.7883 12.3302C22.936 12.2471 23.0653 12.1456 23.1761 12.0256C24.2746 10.927 24.2746 9.12683 23.1761 8.01903ZM18.8095 17.5461C16.9632 19.3278 14.5537 20.3064 11.9966 20.3064C9.4671 20.3064 7.09452 19.3463 5.25742 17.6107V14.6843L10.3717 17.6292C10.3902 17.6384 10.4087 17.6476 10.4271 17.6569C10.7779 17.8415 11.295 18.1185 11.9873 18.1185C12.6243 18.1185 13.1505 17.8784 13.5751 17.6476L18.8188 14.6197V17.5461H18.8095ZM21.8651 10.7331L12.6889 16.0321C12.3751 16.1983 12.1812 16.2814 11.9873 16.2814C11.7842 16.2814 11.5904 16.189 11.295 16.0321L2.13712 10.7516C1.74939 10.3639 1.74939 9.72688 2.13712 9.33916L11.3226 4.04019C11.581 3.92018 11.8026 3.85556 12.0242 3.85556C12.2457 3.85556 12.4765 3.92018 12.7258 4.04019L21.8836 9.32069C22.2529 9.70842 22.2529 10.3454 21.8651 10.7331Z" />
  </SvgIconStyled>
);

export default React.memo(Microtraining);
