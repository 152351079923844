import React from 'react';
import useQueryString from './useQueryString';

// this hook is used when you need to perform an action on a specific target on the page
// after redirection. for example: open a modal, go to a tab, select a dropdown item, etc.
// it parses redirectionTarget key from query string for further processing
function useRedirectionTarget() {
  const [redirectionTarget, setRedirectionTarget] = React.useState(null);
  const queryString = useQueryString();

  React.useEffect(() => {
    const redirectionTarget = queryString.get('redirectionTarget');
    if (redirectionTarget) setRedirectionTarget(redirectionTarget);
  }, [queryString]);

  return redirectionTarget;
}

export default useRedirectionTarget;
