import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { Button, Modal3 } from 'components';
import { CircularProgress } from '@material-ui/core';
import { LazyIcon } from 'components/icons';
import reporting from '../../../helpers/apis/reporting';
import clients from 'helpers/apis/clients';

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const WorkPlanAddButton = ({ tab, label, setRefresh, id, refetchOnCreate }) => {
  const [loading, setLoading] = useState(false);
  const app = 'myCompany';
  const [open, dispatchOpen] = useState(false);

  const setOpen = value => {
    setRefresh(value);
    dispatchOpen(value);
  };

  useEffect(() => {
    // the refresh on the table it was not working on creating a new file
    setRefresh(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function downloadApendix() {
    setLoading(true);
    clients
      .getSRAppendixReport(id)
      .then(response => {
        window.open(response.data.url);
        setLoading(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err.response);
        setLoading(false);
      });
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} mr={1} pr={2} pl={2}>
        <LazyIcon component="Plus" color="colorCommonWhite" mr={1} size={0.7} />
        {label}
      </Button>
      {tab === 'sraDocuments' && (
        <Button disabled={loading} onClick={() => downloadApendix(id)} mr={1} pr={2} pl={2}>
          {loading ? (
            <>
              <CircularProgress size={18} thickness={2} />
              &nbsp;&nbsp;Loading...
            </>
          ) : (
            <>
              <LazyIcon component="Download" color="colorCommonWhite" mr={1} size={1} />
              Download SRA Appendix
            </>
          )}
        </Button>
      )}
      {open && (
        <Modal3
          open={open}
          setOpen={setOpen}
          app={app}
          tab={tab}
          createNew
          TransitionComponent={Transition}
          refetchOnCreate={refetchOnCreate}
        />
      )}
    </>
  );
};

WorkPlanAddButton.propTypes = {
  tab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default memo(WorkPlanAddButton);
