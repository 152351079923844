// @flow
import React, { type ComponentType } from 'react';
// import { IconButton } from '@material-ui/core';
import { /* Copy as CopyIcon, */ LazyIcon } from 'components/icons';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
// import { useApp } from 'helpers';
import { TableRowPartner, TableCellPartner } from './ComponentTypes';

type PartnerInformationRowType = {
  name: string,
  url: string,
  icon: string,
  isCopy: boolean
};

// const ButtonIcon: ComponentType<*> = styled(IconButton)`
//   margin: calc(var(--spacing) * 8);
//   & svg {
//     width: 15px;
//     height: 15px;
//   }
// `;

const PartnerInformationRow = ({ name, url, icon, isCopy }: PartnerInformationRowType) => {
  // const { dispatch } = useApp();

  // const handleCopy = (i, message) => {
  //   console.log(i);
  //   console.log(message);
  //   dispatch('snackState', {
  //     state: true,
  //     message
  //   });
  // };
  const newIcon = icon || `Partner${name.split(' ')[0].toString()}`;

  return (
    <TableRowPartner key={name}>
      <TableCellPartner component="th" scope="row">
        <LazyIcon component={newIcon} size={1.5} color="colorDefault" ml={1.3} mr={2} /> {name}
      </TableCellPartner>
      <TableCellPartner align="left">{url}</TableCellPartner>
      {/* <TableCellPartner align="center">
        {isCopy && (
          <CopyToClipboard text={url} onCopy={() => dispatch('snack', { state: true, message: name })}>
            <ButtonIcon color="primary" aria-label="Copy to clipboard">
              <CopyIcon />
            </ButtonIcon>
          </CopyToClipboard>
        )}
      </TableCellPartner> */}
    </TableRowPartner>
  );
};

export default PartnerInformationRow;
