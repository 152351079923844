// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Badge = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
      <path  d="M14.7154 1.28519C14.7154 1.28519 12.7632 1.22399 11.0679 1.22399C9.84394 1.22399 8.76067 1.25459 8.65051 1.35863L1.41676 8.54344C1.15973 8.80048 1.1536 9.21052 1.41064 9.46756L6.45347 14.5471C6.58199 14.6756 6.75335 14.743 6.91859 14.743C7.08383 14.743 7.24907 14.6818 7.37759 14.5532L14.6114 7.36842C14.8684 7.11138 14.7154 1.28519 14.7154 1.28519ZM7.78765 0.495716C8.19157 0.0917992 8.54039 0 11.0679 0C12.757 0 14.7338 0.0611995 14.7583 0.0673194C15.407 0.0917992 15.9271 0.611995 15.9455 1.26071C16.1106 7.61337 15.8847 7.83923 15.4815 8.24243L15.4804 8.24357L8.24664 15.4284C7.89169 15.7772 7.42655 15.9731 6.92472 15.9731C6.41676 15.9731 5.94553 15.7711 5.59057 15.4161L0.547742 10.3366C-0.186652 9.60219 -0.180526 8.40881 0.553868 7.68053L7.78765 0.495716ZM10.1009 5.88126C10.5048 6.28518 11.0373 6.48714 11.5697 6.48714C12.1144 6.48714 12.6652 6.27906 13.0936 5.85678C13.4975 5.45287 13.7239 4.92655 13.7362 4.36352C13.7484 3.79436 13.5404 3.26193 13.1426 2.86413C12.7448 2.46633 12.2001 2.24602 11.6431 2.25826C11.0862 2.26438 10.5538 2.49081 10.1499 2.88861C9.31147 3.72092 9.28695 5.06731 10.1009 5.88126ZM11.6615 3.48225H11.6738C11.9002 3.48225 12.1144 3.57404 12.2735 3.72704C12.4326 3.88616 12.5183 4.10648 12.5122 4.33904C12.5061 4.58384 12.4082 4.81027 12.2307 4.98775C11.8696 5.34271 11.3066 5.35495 10.97 5.01835C10.6334 4.68176 10.6518 4.1126 11.0128 3.75764C11.1903 3.58628 11.4167 3.48837 11.6615 3.48225Z"/>
  </SvgIconStyled>
);

export default Badge;
