import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  Link,
  CloseIcon
} from '@trustsecurenow/components-library';

function EnableSystemEmailsDialog({ state, resetModal, onSubmit }) {
  return (
    <Dialog open={true}>
      <DialogTitle display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={resetModal}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pb: 10 }}
      >
        <Typography variant="h2" mb={2.25} px={8} mt={1.5} textAlign="center">
          Would you like to enable system emails for {state.clientName}?
        </Typography>
        <Typography textAlign="center" px={6} pb={4} variant="subtitle1">
          All Phishing Campaigns, Client Reminders, Automated Reports and other system emails will start sending if
          configured.
        </Typography>
        <Button
          onClick={onSubmit}
          color="success"
          sx={{ width: 200, mb: 4, whiteSpace: 'nowrap' }}
          loading={state.submitting}
        >
          Yes, Enable System Emails
        </Button>
        <Link variant="link2" component="button" onClick={resetModal}>
          Cancel
        </Link>
      </DialogContent>
    </Dialog>
  );
}

export default EnableSystemEmailsDialog;
