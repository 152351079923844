// @flow

import newAutophishCampaign from './newAutophishCampaign';

const autoPhishCampaign = {
  ...newAutophishCampaign,
  title: 'Edit Auto Phish',
  requireRowData: true
};

export default autoPhishCampaign;
