import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Divider, Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import LoadingStyled from '../types/Loading';
import VideoPlayer from '../common/VideoPlayer';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '30px 30px 60px'
  },
  dialogHeader: {
    padding: '22px 30px',
    position: 'relative'
  },
  closeBtn: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: 'var(--colorDefault)'
  }
}));

const VideoModal = ({ open, close, title, video, loading, autoplay }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle className={classes.dialogHeader}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton className={classes.closeBtn} onClick={close}>
          <CloseIcon style={{ fontSize: 28 }} />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {loading && !video ? (
          <LoadingStyled />
        ) : (
          <VideoPlayer
            src={`https://videodelivery.net/${video}/manifest/video.m3u8?clientBandwidthHint=4.9`}
            playing={autoplay}
            controls
            width="100%"
            height="auto"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

VideoModal.defaultProps = {
  loading: false,
  autoplay: false
};

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  autoplay: PropTypes.bool
};

export default VideoModal;
