// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const UserSearch = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_4955_39393)">
      <path d="M8.48814 9.1363C9.09708 9.14357 9.70148 9.03081 10.2668 8.80447C10.8322 8.57813 11.3474 8.24264 11.7831 7.81716C12.2188 7.39169 12.5664 6.88455 12.8061 6.32472C13.0458 5.76489 13.1728 5.16334 13.18 4.5544C13.1728 3.94546 13.0458 3.3439 12.8061 2.78407C12.5664 2.22424 12.2188 1.71711 11.7831 1.29163C11.3474 0.866157 10.8322 0.530669 10.2668 0.30433C9.70148 0.0779905 9.09708 -0.0347644 8.48814 -0.027501C7.25747 -0.0421823 6.07126 0.43204 5.18989 1.29108C4.30852 2.15011 3.80401 3.32376 3.78711 4.5544C3.79428 5.16412 3.92164 5.76643 4.1619 6.32687C4.40215 6.8873 4.75058 7.39484 5.18724 7.82044C5.62391 8.24604 6.14022 8.58133 6.70664 8.80713C7.27305 9.03292 7.87844 9.14478 8.48814 9.1363ZM8.48814 1.80526C8.8564 1.79797 9.22249 1.8633 9.5655 1.99752C9.90851 2.13175 10.2217 2.33223 10.4872 2.58753C10.7527 2.84282 10.9653 3.14792 11.1129 3.4854C11.2605 3.82289 11.3401 4.18614 11.3472 4.5544C11.3401 4.92266 11.2605 5.28591 11.1129 5.62339C10.9653 5.96087 10.7527 6.26597 10.4872 6.52127C10.2217 6.77657 9.90851 6.97705 9.5655 7.11127C9.22249 7.2455 8.8564 7.31083 8.48814 7.30354C8.11912 7.31205 7.75205 7.24763 7.40798 7.11395C7.06391 6.98028 6.74962 6.77999 6.48314 6.52457C6.21666 6.26916 6.00322 5.96364 5.85508 5.62555C5.70694 5.28746 5.62701 4.92345 5.61987 4.5544C5.64146 3.81385 5.95524 3.11194 6.49266 2.60199C7.03009 2.09204 7.74748 1.81549 8.48814 1.83275V1.80526Z"/>
      <path d="M12.2453 11.5739C12.9188 11.2389 13.5519 10.9091 14.298 10.8133C13.9075 10.5011 13.4833 10.2337 13.0333 10.016C12.7919 9.90441 12.5296 9.84507 12.2636 9.84192C11.8094 9.8431 11.3718 10.0129 11.0356 10.3184C10.9402 10.4045 10.8392 10.4841 10.7332 10.5567C10.6231 10.6363 10.5066 10.7068 10.385 10.7675L10.275 10.8224L10.1009 10.8958L9.94515 10.9599L9.7802 11.0149H9.72521H9.67023C9.49873 11.0608 9.32418 11.0945 9.1479 11.1157H9.08375C8.90377 11.129 8.72306 11.129 8.54309 11.1157C8.3998 11.1267 8.25601 11.1298 8.11238 11.1249H8.01158H7.73667L7.36095 11.0332L7.14102 10.9599H7.04022L6.8936 10.8958H6.83862L6.47206 10.6758C6.36134 10.599 6.25713 10.5132 6.16049 10.4192C5.81877 10.104 5.36999 9.93035 4.90505 9.93356C4.65321 9.93154 4.40365 9.98145 4.17195 10.0802C2.98044 10.6053 1.96694 11.4649 1.25447 12.5548C0.541994 13.6446 0.161166 14.9178 0.158203 16.2199C0.158203 16.8664 0.415017 17.4864 0.87215 17.9436C1.32928 18.4007 1.94929 18.6575 2.59577 18.6575H8.79051C8.76267 18.0437 8.80571 17.4287 8.9188 16.8247H2.59577C2.43537 16.8247 2.28153 16.761 2.16811 16.6476C2.05468 16.5342 1.99096 16.3803 1.99096 16.2199C1.99052 15.2745 2.26602 14.3495 2.78366 13.5584C3.30129 12.7673 4.03857 12.1445 4.90505 11.7663C5.07104 11.9138 5.24544 12.0515 5.42739 12.1787C5.61421 12.3088 5.81022 12.4252 6.01387 12.5269L6.16049 12.6094L6.38959 12.7102L6.54538 12.7652L6.90276 12.8935C7.08318 12.95 7.26678 12.9959 7.45259 13.0309L7.83747 13.0951H8.04824C8.24984 13.0951 8.46061 13.0951 8.68054 13.0951C8.94726 13.0956 9.21368 13.0773 9.47779 13.0401C9.73417 13.0032 9.98815 12.9512 10.2384 12.8843H10.4033L10.6233 12.811C11.0899 12.3096 11.6384 11.8913 12.2453 11.5739Z" />
      <path fillRule="evenodd" clip-rule="evenodd" d="M12.3881 20.0022C13.1956 20.7874 14.2816 21.2201 15.4078 21.2054C16.295 21.2076 17.1548 20.9323 17.8707 20.4268C17.9034 20.4757 17.9408 20.5216 17.9829 20.5639L20.9427 23.5238C21.0276 23.6104 21.1288 23.6793 21.2405 23.7265C21.3522 23.7737 21.4721 23.7982 21.5933 23.7987C21.7146 23.7982 21.8346 23.7737 21.9463 23.7265C22.058 23.6793 22.1592 23.6104 22.244 23.5238C22.4147 23.3521 22.5105 23.1199 22.5105 22.8778C22.5105 22.6357 22.4147 22.4034 22.244 22.2317L19.284 19.2718C19.2232 19.2105 19.1541 19.1582 19.0789 19.1164C19.4455 18.4959 19.6516 17.7869 19.669 17.0542C19.6617 15.9297 19.2092 14.8538 18.4106 14.0621C17.612 13.2704 16.5323 12.8273 15.4078 12.8297C14.2816 12.815 13.1956 13.2477 12.3881 14.0328C11.5807 14.818 11.1177 15.8914 11.1008 17.0175C11.1177 18.1437 11.5807 19.2171 12.3881 20.0022ZM13.684 15.3287C14.1477 14.8873 14.7677 14.6477 15.4078 14.6625C16.0462 14.66 16.6598 14.91 17.1147 15.3581C17.5696 15.8061 17.829 16.4158 17.8362 17.0542C17.8101 17.6798 17.5425 18.2709 17.0896 18.7033C16.6368 19.1356 16.0339 19.3756 15.4078 19.3726C14.7677 19.3874 14.1477 19.1478 13.684 18.7064C13.2202 18.2649 12.9504 17.6576 12.9336 17.0175C12.9504 16.3775 13.2202 15.7702 13.684 15.3287Z" />
      </g>
      <defs>
        <clipPath id="clip0_4955_39393">
          <rect width="23.7068" height="24" fill="white" transform="translate(0.020752)"/>
        </clipPath>
      </defs>
  </SvgIconStyled>
);

export default React.memo(UserSearch);
