import { useCallback, useState } from 'react';

export const useDialog = () => {
  const [openedDialogs, setOpenedDialog] = useState([]);

  const hasDialogOpen = dialogId => openedDialogs.includes(dialogId);

  const closeDialog = useCallback(
    dialogId => setOpenedDialog(prevState => prevState.filter(id => id !== dialogId)),
    []
  );

  const closeDialogs = useCallback(() => setOpenedDialog([]), []);

  const openDialog = dialogId => setOpenedDialog(prevState => [...prevState, dialogId]);

  return { hasDialogOpen, closeDialog, openDialog, closeDialogs };
};
