import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Play2 = props => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path d="M18.5564 7.68333L11.5988 4.19999L3.77461 0.28333C3.39131 0.0916636 2.99136 0 2.60806 0C1.24154 0 0 1.1 0 2.6V10V17.4C0 18.9083 1.24154 20 2.60806 20C2.99136 20 3.39131 19.9083 3.77461 19.7167L11.5988 15.8L18.5564 12.3167C20.4812 11.375 20.4812 8.64167 18.5564 7.68333ZM17.8148 10.8417L10.8572 14.325L3.03302 18.2417C2.8997 18.3083 2.75805 18.3417 2.61639 18.3417C2.16644 18.3417 1.68316 17.9833 1.68316 17.4083V10.0083V2.60832C1.68316 2.03332 2.16644 1.675 2.61639 1.675C2.75805 1.675 2.8997 1.70832 3.03302 1.77499L10.8572 5.69167L17.8148 9.175C18.2814 9.40833 18.3314 9.83333 18.3314 10.0083C18.3314 10.1833 18.2814 10.6083 17.8148 10.8417Z"/>
  </SvgIconStyled>
);

export default Play2;

/*
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5564 7.68333L11.5988 4.19999L3.77461 0.28333C3.39131 0.0916636 2.99136 0 2.60806 0C1.24154 0 0 1.1 0 2.6V10V17.4C0 18.9083 1.24154 20 2.60806 20C2.99136 20 3.39131 19.9083 3.77461 19.7167L11.5988 15.8L18.5564 12.3167C20.4812 11.375 20.4812 8.64167 18.5564 7.68333ZM17.8148 10.8417L10.8572 14.325L3.03302 18.2417C2.8997 18.3083 2.75805 18.3417 2.61639 18.3417C2.16644 18.3417 1.68316 17.9833 1.68316 17.4083V10.0083V2.60832C1.68316 2.03332 2.16644 1.675 2.61639 1.675C2.75805 1.675 2.8997 1.70832 3.03302 1.77499L10.8572 5.69167L17.8148 9.175C18.2814 9.40833 18.3314 9.83333 18.3314 10.0083C18.3314 10.1833 18.2814 10.6083 17.8148 10.8417Z" fill="#535353"/>
</svg>


*/