// @flow
import { type initialStateType } from 'conf';
import { BSN_SET_USER_NEWSFEED_APP_ACCESS, BSN_SET_CLIENT_LOGO } from '../../conf/constants';

export type bsnActionType = {
  type: string,
  resource: string,
  tab?: string | Array<any>,
  item?: string | Array<any>,
  key?: string,
  payload: any,
  atBeginning?: boolean
};

export const bsnAction: Function = (args: bsnActionType): bsnActionType => args;

export const setClientLogo = value => {
  return { type: BSN_SET_CLIENT_LOGO, payload: value };
};

export const setUserNewsfeedAppAccess = access => {
  return { type: BSN_SET_USER_NEWSFEED_APP_ACCESS, payload: access };
};
