import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import {
  Container,
  Switch,
  Typography,
  TextField,
  LoadingStyled,
  DashboardBoxTitle
} from 'components';
import {
  InputAdornment,
  Card,
  CardContent,
  useMediaQuery
} from '@material-ui/core';
import styled from 'styled-components';
import CopyButton from 'components/buttons/CopyButton';
import { trackingUtils } from 'helpers';
import integrationApi from '../../helpers/apis/integrationApi';
import PartnerIntegrationsApisKeys from './PartnerIntegrationsApisKeys';
import { getStorage } from 'helpers';
import { ContainerTitle } from './ComponentTypes';
import { InfoButtons, ActionButtons } from './shared';
import { Box } from '@trustsecurenow/components-library';


const Img = styled.img`
  max-width: 112px;
  max-height: 56px;
`;

const ContainerDescription = styled.div`
  max-width: 340px;
  min-width: 220px;
`;

const PartnerIntegrations = ({ partner_id, isCofigureTab }) => {
  const partnerId = partner_id || getStorage("partnerId");
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [loadingCardIds, setLoadingCardIds] = useState([]); // [ id:string ]
  const [data, setData] = useState({ api_keys_section_visible: false, integrations: [] });
  const [openCreateFriendlyKey, setOpenCreateFriendlyKey] = useState(false);
  const hiddenLogo = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const toggleCreateFriendlyKey = () => {
    setOpenCreateFriendlyKey(prevState => !prevState);
  };

  const handleToggleIntegration = async ({ target: { checked } }, idIntegration) => {
    setLoadingCardIds(old => {
      return [...old, idIntegration];
    });

    integrationApi
      .update(
        partnerId,
        data?.integrations
          .filter(integration => integration.id === idIntegration)
          .map(integration =>
            integration.id === idIntegration
              ? {
                  ...integration,
                  enabled: !integration.enabled
                }
              : integration
          )
      )
      .then(resp => {
        setData(resp.data);

        const currentIntegration = data?.integrations.filter(integration => integration.id === idIntegration);
        const currentIntegrationName = currentIntegration[0]?.integration_name;

        if (checked && currentIntegrationName) {
          trackingUtils.customEvent('completed_subtask', {
            sendUserName: true,
            sendPartnerName: true,
            label: currentIntegrationName
          });
        }
      })
      .catch(_err => notify('Something Went Wrong', 'error'))
      .finally(() => {
        setLoadingCardIds(old => old.filter(v => v !== idIntegration));
      });
  };

  useEffect(() => {
    setLoading(true);

    integrationApi
      .get(partnerId)
      .then(resp => setData(resp.data))
      .catch(_err => notify(`Something went wrong during loading data`, `error`))
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Box width={'100%'}><LoadingStyled /></Box>;

  return (
    <>
      {/* Integrations Partners */}
      <Container.Paper mt={2} radius={1}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12}>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                {isCofigureTab ? 'Integrations Partners' : 'Integrations'}
              </Typography.h6>
              {isCofigureTab && <InfoButtons name="integrations" />}
            </ContainerTitle>

            <Container.Grid container spacing={3} px={2.5}>
              {data?.integrations?.map((item, idx) =>
                item.visible ? (
                  <Container.Grid item sm={12} md={6} lg={4} mb={2}>
                    {loadingCardIds.includes(item.id) ? (
                      <Container.Grid container justify="center" alignItems="center">
                        <LoadingStyled />
                      </Container.Grid>
                    ) : (
                      <Card variant="outlined">
                        <CardContent>
                          <Container.Grid container>
                            <Container.Grid item pl={1} pr={1} xs={6} md={6}>
                              <Switch
                                // label={item.integration_name}
                                value={Boolean(item.enabled)}
                                checked={item.enabled}
                                name={item.integration_name}
                                id={item.id}
                                onChange={e => handleToggleIntegration(e, item.id)}
                              />
                            </Container.Grid>

                            <Container.Grid item xs={12} sm={12} md={12} pb={1}>
                              <Container.Grid container justify="space-between" alignItems="center" pl={1} pr={1}>
                                <Container.Grid item block direction="column" xs={12} sm={8} md={8}>
                                  <ContainerDescription>
                                    {item.description ? (
                                      <DashboardBoxTitle
                                        label={item.integration_name}
                                        labelColor="colorDefault"
                                        description={item.description}
                                        fontSize={15}
                                        icon
                                        iconSize={1.3}
                                        iconMl={1.4}
                                        justify="flex-start"
                                        mbbox={2}
                                        pt={1}
                                      />
                                    ) : (
                                      <Typography.h5 mb={1} mt={1}>
                                        {item.integration_name}
                                      </Typography.h5>
                                    )}

                                    <Typography.p mt={0.4} mb={1} fontSize={14}>
                                      {item.website_url}
                                    </Typography.p>
                                  </ContainerDescription>
                                </Container.Grid>
                                {!hiddenLogo && item.logo_url && (
                                  <Container.Grid item sm={4} md={4} justify="flex-end" alignItems="center">
                                    <Img alt="Partner Logo" src={item.logo_url} />
                                  </Container.Grid>
                                )}
                              </Container.Grid>
                            </Container.Grid>

                            <Container.Grid item pl={1} pr={1} pt={0.5} xs={12} sm={12} md={12}>
                              <TextField
                                name="partner_integration_key"
                                label="Partner Integration Key"
                                fullWidth
                                value={item.partner_integration_key}
                                type={item.enabled ? 'text' : 'password'}
                                disabled={!item.enabled}
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: !!item.enabled && (
                                    <InputAdornment position="end">
                                      <CopyButton
                                        text={item.partner_integration_key}
                                        message="Key has been copied to the clipboard"
                                      />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Container.Grid>
                          </Container.Grid>
                        </CardContent>
                      </Card>
                    )}
                  </Container.Grid>
                ) : (
                  <></>
                )
              )}
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>

      {/* APIs Keys */}
      {data.api_keys_section_visible && (
        <Container.Paper mt={2} radius={1} fullWidth>
          <Container.Grid item direction="column" xs={12}>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                APIs Keys
              </Typography.h6>
            </ContainerTitle>

            <PartnerIntegrationsApisKeys
              handleCreateKey={toggleCreateFriendlyKey}
              createFriendlyKey={openCreateFriendlyKey}
            />
          </Container.Grid>
        </Container.Paper>
      )}

      {/* Action buttons */}
      {isCofigureTab && <ActionButtons />}
    </>
  );
};

export default PartnerIntegrations;
