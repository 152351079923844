// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WatchNow = (props: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.62433 1.56414C8.62433 1.8238 8.34613 2.034 8.0061 2.034C7.66607 2.034 7.38786 1.8238 7.38786 1.56414V0.469862C7.38786 0.210202 7.66607 0 8.0061 0C8.34613 0 8.62433 0.210202 8.62433 0.469862V1.56414ZM14.2566 10.065V10.0464L12.5131 4.77902C12.2968 4.12987 11.6043 3.6971 10.7821 3.6971H5.18703C4.36477 3.6971 3.66615 4.12987 3.45595 4.77902L1.71253 10.0464V10.0711C0.760445 10.1453 0 10.9367 0 11.9135V13.9042C0 14.9243 0.834621 15.7589 1.85471 15.7589H14.1453C15.1654 15.7589 16 14.9243 16 13.9042V11.9135C15.9938 10.9243 15.221 10.1268 14.2566 10.065ZM4.62443 5.16851C4.64916 5.08196 4.86554 4.93358 5.18084 4.93358H10.7759C11.0974 4.93358 11.3076 5.08196 11.3323 5.16851L12.9459 10.0526H2.99846L4.62443 5.16851ZM14.7573 13.898C14.7573 14.2381 14.4791 14.5163 14.1391 14.5163H1.84854C1.50851 14.5163 1.23031 14.2381 1.23031 13.898V11.9073C1.23031 11.5673 1.50851 11.2891 1.84854 11.2891H14.1391C14.4791 11.2891 14.7573 11.5673 14.7573 11.9073V13.898ZM9.80526 8.52543C9.87326 8.80982 10.1267 9.00147 10.4049 9.00147C10.4482 9.00147 10.4977 8.99529 10.5471 8.98292C10.881 8.90874 11.085 8.57489 11.0108 8.24104L10.6213 6.54706C10.5471 6.21322 10.2133 6.00302 9.87944 6.08339C9.54559 6.15758 9.34157 6.49143 9.41576 6.82527L9.80526 8.52543ZM11.5301 2.13296L12.3029 1.36016C12.4884 1.17469 12.4389 0.834663 12.1978 0.593551C11.9567 0.352438 11.6105 0.30298 11.4312 0.488451L10.6584 1.26125C10.4729 1.44672 10.5224 1.78675 10.7635 2.02786C11.0046 2.26898 11.3508 2.31843 11.5301 2.13296ZM5.23651 2.02786C4.9954 2.26898 4.65538 2.31843 4.4699 2.13296L3.69711 1.36016C3.51164 1.18088 3.5611 0.834663 3.80222 0.593551C4.04333 0.352438 4.38335 0.30298 4.56882 0.488451L5.34162 1.26125C5.52709 1.44054 5.47763 1.78675 5.23651 2.02786Z"
      fill="#FF5A61"
    />
  </svg>
);

export default WatchNow;
