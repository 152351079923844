import React from "react";
import styled, { css } from 'styled-components';

const InputRadio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked  {
    & ~ .radio-content:after {
      border-color: #0054CC;
      background-color: rgba(25, 148, 230, .2);
    }

    & ~ .radio-content {
      .radio-label {
        color: #0054CC;

        &:after {
          border-color: #0054CC;
        }

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #0054CC;
          position: absolute;
          top: 50%;
          right: calc(100% + 15px);
          transform: translateY(-52%);
        }
      }
    }
  }
`

const InputRadioContent = styled.div`
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--borderLight);
  }
`

const InputRadioLabel = styled.span`
  position: relative;
  color: #535353;
  font-weight: 400;
  font-size: 16px;

  &:after {
    content: "";
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1.2px solid var(--borderLight);
  }
`

const RadioButtonWrapper = styled.label`
  padding: 18px 18px 18px 49px;
  position: relative;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 20px 0;
  cursor: pointer;

  ${({isRightAnswer}) => {

  if (isRightAnswer !== undefined) {
    const color = isRightAnswer ? "#0AA556" : "#CC484E";
    const bgColor = isRightAnswer ? "rgba(12, 206, 107, 0.2)" : "rgba(255, 90, 97, 0.2)";
    return css`
          .radio-content:after {
            border-color: ${color} !important;
            background-color: ${bgColor} !important;
          }

          .radio-content {
            .radio-label {
              color: ${color} !important;

              &:after {
                border-color: ${color} !important;
              }

              &:before {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: ${color} !important;
                position: absolute;
                top: 50%;
                right: calc(100% + 15px);
                transform: translateY(-52%);
              }
            }
          }
        `
  }
}}
`


export const RadioButtonWithTitle = ({label, id, name, onChange, hide, isRightAnswer, checked = false}) => {

  if (hide) return null

  return (
    <RadioButtonWrapper
      htmlFor={id}
      isRightAnswer={isRightAnswer}
      onClick={(e) => {
        if (isRightAnswer !== undefined) {
          e.preventDefault()
        }
      }}
    >
      <InputRadio checked={checked} id={id} name={name} type={"radio"} onChange={onChange} />

      <InputRadioContent className={"radio-content"}>
        <InputRadioLabel className={"radio-label"}>
          {label}
        </InputRadioLabel>
      </InputRadioContent>

    </RadioButtonWrapper>
  )
}

export default RadioButtonWithTitle
