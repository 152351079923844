// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Img: ComponentType<*> = styled.img`
  border-radius: 5px;
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

const Logo = () => {
  const logo = useSelector(({ bsn: { user: { profile } } }) => (profile ? profile.logo_partner : null));
  return logo ? <Img src={logo} alt="Logo" /> : null;
};

export default Logo;
