import React from 'react';
import WorkPlanPage from './workPlan/WorkPlanPage';
import OrganizationProfilePage from './organizationProfile/OrganizationProfilePage';
import QuestionsPage from './questions/QuestionsPage';
import EditSraPage from './editSraReport/EditSraPage';
import EditRecommendations from './editSraReport/recommendations/EditRecommendations';
import EditThreats from './editSraReport/threats/EditThreats';
import EditOrganizationProfile from './editSraReport/organizationProfile/EditOrganizationProfile';
import EditQuestions from './editSraReport/questions/EditQuestions';

export const PAGES = {
  workPlan: {
    component: <WorkPlanPage />
  },
  orgProfile: {
    component: <OrganizationProfilePage />
  },
  questions: {
    component: <QuestionsPage />
  },
  editSraReport: {
    component: <EditSraPage />,
    subpages: {
      recommendations: {
        component: <EditRecommendations />
      },
      questions: {
        component: <EditQuestions />
      },
      threats: {
        component: <EditThreats />
      },
      organizationProfile: {
        component: <EditOrganizationProfile />
      }
    }
  }
};
