import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { Container, TextField, Button, LoadingStyled } from 'components';
import { dataProvider } from 'helpers';
import { useDataGet, useId } from 'hooks';
import { ButtonCancel, MuiPhoneNumberInput } from './ComponentTypes';
import { TextField as TextFieldCL } from '@trustsecurenow/components-library';

const UserContactUs = () => {
  const item = useId({ key: 'userId' });
  const clientId = useId({ key: 'clientId' });

  const notify = useNotify();
  const history = useHistory();

  const { isFetching, data: profile, isSuccess: isSuccessProfile } = useDataGet({
    app: 'user',
    tab: 'profile',
    item,
    options: { staleTime: 0 }
  });
  const { data: company, isSuccess: isSuccessCompany } = useDataGet({ app: 'clients', tab: 'company', clientId });

  const initial = {
    id: item,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    company: undefined,
    subject: '',
    feedback: ''
  };
  // const [validationState, setValidationState] = React.useState({ validPhone: false });
  const [data, setData] = useState(initial);
  const [loading, setloading] = useState(false);

  const isDataChanged =
    data.first_name !== profile?.firstName ||
    data.last_name !== profile?.lastName ||
    data.phone !== profile?.phoneNumber ||
    data.email !== profile?.email ||
    data.company !== company?.name ||
    data.subject ||
    data.feedback;
  // handle intensive regex validation only on phone changes
  // React.useEffect(() => {
  //   if (!data?.phone) {
  //     return;
  //   }
  //   // mobile phones withpit "-,(,), "
  //   const compact_phone_number = data.phone.replaceAll(/[\(\)\s\-]/gm, '');
  //   setValidationState(old => {
  //     return {
  //       ...old,
  //       validPhone: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\s?(\(*\d\)*\s*\-*){4,14}$/.test(
  //         compact_phone_number
  //       )
  //     };
  //   });
  // }, [data?.phone]);

  useEffect(() => {
    if (isSuccessProfile) {
      setData(old => ({
        ...old,
        first_name: profile.firstName,
        last_name: profile.lastName,
        phone: profile.phoneNumber
      }));

      if (!data.email) setData(old => ({ ...old, email: profile.email }));
    }
  }, [isSuccessProfile, profile?.firstName, profile?.lastName, profile?.email, profile?.phoneNumber, data?.email]);

  useEffect(() => {
    if (isSuccessCompany && !data?.company) {
      setData(old => ({
        ...old,
        company: company.name
      }));
    }
  }, [isSuccessCompany, company, data?.company]);

  // memorize concurencey of setting onchange refrence  on elements
  const onChange = React.useCallback((name, value) => {
    setData(old => ({ ...old, [name]: value }));
  }, []);

  const handleChangePhones = onChange;

  const handleTextAreaOnChange = React.useCallback(event => {
    const { name, value } = event.target;
    setData(old => ({ ...old, [name]: value }));
  }, []);

  const resetForm = () => {
    setData(initial);
  };

  const onUpdate = e => {
    e.preventDefault();
    setloading(true);

    dataProvider
      .post('user', 'contactUs', data.id, data)
      .then(res => {
        notify(res.data.message, 'info');
        resetForm();
      })
      .catch(err => {
        notify(err.response?.data?.description || err.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => {
        setloading(false);
      });
  };

  const render_textarea = React.useMemo(() => {
    return (
      <TextFieldCL
        id="feedback"
        name="feedback"
        rows="4"
        label="Feedback"
        value={data.feedback}
        inputProps={{ onChange: e => handleTextAreaOnChange(e), maxLength: 2000 }}
        required
        fullWidth
        multiline
      />
    );
  }, [data?.feedback, handleTextAreaOnChange]);

  // separate phone as its render takes alot of time
  const render_phone = React.useMemo(() => {
    return (
      <MuiPhoneNumberInput
        variant="outlined"
        value={data?.phone?.includes('+') ? data?.phone : `+1 ${data?.phone}`}
        name="phone"
        label="Phone number"
        onChange={e => {
          if (Math.abs(e.length - data?.phone?.length) < 3 && (e.includes(data?.phone) || data?.phone?.includes(e))) {
            handleChangePhones('phone', e);
            return;
          }
          // due to undefined behaviour f 3rd party lib
          // this lib doesnt support pasting , reselecting or Refs hooks/events
          // to handle automatic version of country switch for number paste this need to be 2 separate quick renderes
          // this can't be and Effect since its dependencies will lead to undefined behaviour or infinite loading
          handleChangePhones('phone', '');
          setTimeout(() => {
            handleChangePhones('phone', e);
          }, 10);
        }}
        defaultCountry="us"
        countryCodeEditable={false}
        fullWidth
      />
    );
  }, [data?.phone, handleChangePhones]);

  const isValidForm =
    data?.first_name?.trim().length > 0 &&
    data?.last_name?.trim().length > 0 &&
    data?.subject?.trim().length > 0 &&
    data?.feedback?.trim().length > 0;
  // &&
  // validationState.validPhone;

  if (!data?.email || !data?.company || isFetching) return <LoadingStyled />;
  return (
    <form onSubmit={onUpdate}>
      <Container.Paper mt={2} p={2} radius={1}>
        <Container.Grid spacing={4}>
          <>
            <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <TextField
                value={data.first_name}
                name="first_name"
                label="First name"
                inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                required
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <TextField
                value={data.last_name}
                name="last_name"
                label="Last name"
                inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                required
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <TextField
                value={data.company}
                name="company"
                label="Company"
                inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                fullWidth
                disabled
              />
            </Container.Grid>
            <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <TextField
                value={data.email}
                name="email"
                label="Email"
                inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 199 }}
                // required
                fullWidth
                disabled
              />
            </Container.Grid>
            <Container.Grid item mt={0.9} xs={12} sm={12} md={12} lg={5} xl={5}>
              {render_phone}
            </Container.Grid>
            <Container.Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <TextField
                value={data.subject}
                name="subject"
                label="Subject"
                inputProps={{ onChange: ({ target: { name, value } }) => onChange(name, value), maxLength: 50 }}
                required
                fullWidth
              />
            </Container.Grid>
            <Container.Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
              {render_textarea}
            </Container.Grid>
            <Container.Grid justify="flex-end" bt={1} pt={2} pb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
              <ButtonCancel
                // workaround since we need to provide the "to" prop
                to={history.location.pathname}
                primaryText="Cancel"
                onClick={resetForm}
                disabled={!isDataChanged}
              />
              <Button
                mr={3}
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                type="submit"
                disabled={!isValidForm || loading}
              >
                {loading && <CircularProgress color="inherit" size={18} thickness={2} style={{ marginRight: 10 }} />}
                Submit
              </Button>
            </Container.Grid>
          </>
        </Container.Grid>
      </Container.Paper>
    </form>
  );
};

export default UserContactUs;
