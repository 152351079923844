import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import styled, { css } from 'styled-components';
import Controller from '../Controller';
import { cleanTextFromHtml, toBase64 } from 'helpers';
import 'react-quill/dist/quill.snow.css'; // ES6
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import 'quill-emoji/dist/quill-emoji.css';
import './RichTextEditorStyles.css';
import * as InlineStyle from './RichTextEditorInStyle';
import { useFormContext, useWatch } from 'react-hook-form';

Quill.register('modules/emoji', Emoji);

const PreWrap = styled.pre`
  width: 100%;
  font-family: var(--fontFamily);
  margin: 0;
  border: none;
  background: none;
  padding: 0;
`;

const RQcontent = styled.div`
  width: 100%;
  .ql-snow .ql-stroke {
    stroke: var(--colorIcon);
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--colorIcon);
  }
  .ql-snow .ql-picker-label::before {
    color: var(--colorIcon);
  }
  .ql-formats button {
    position: relative;
    &:hover::after {
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .quill {
    width: 100%;
    min-height: ${({ minHeight }) => minHeight || '300px'};
    display: flex;
    flex-direction: column;
  }
  .ql-toolbar {
    border-radius: 5px 5px 0 0;
    border-color: var(--borderBase);
  }
  .ql-container {
    border-radius: 0 0 5px 5px;
    font-family: var(--fontFamily);
    font-size: ${({ fontSize }) => fontSize || '13px'};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-color: var(--borderBase);
    .ql-mention-list-container {
      max-height: 200px;
      white-space: normal;
    }
  }
  .ql-editor {
    resize: ${({ allowResize }) => (allowResize ? 'vertical' : 'none')};
    flex-grow: 1;
    max-height: 350px;
    word-break: break-word;
    pre,
    code {
      background-color: var(--backgroundCode);
    }
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    color: var(--colorDarkGrey);
    opacity: 0.5;
  }
  .mention {
    color: var(--colorSystemInfo);
    font-weight: 500;
  }
  .ql-bold {
    &:hover::after {
      content: 'Bold';
    }
  }
  .ql-italic {
    &:hover::after {
      content: 'Italic';
    }
  }
  .ql-underline {
    &:hover::after {
      content: 'Underline';
    }
  }
  .ql-strike {
    &:hover::after {
      content: 'Strike';
    }
  }
  .ql-font {
    &:hover::after {
      content: 'Font';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-blockquote {
    &:hover::after {
      content: 'Quote';
    }
  }
  .ql-code-block {
    &:hover::after {
      content: 'Code';
    }
  }
  .ql-size {
    &:hover::after {
      content: 'Size';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-list[value='ordered'] {
    &:hover::after {
      content: 'Ordered-List';
    }
  }
  .ql-list[value='bullet'] {
    &:hover::after {
      content: 'Bullet-List';
    }
  }
  .ql-script[value='sub'] {
    &:hover::after {
      content: 'Sub-Script';
    }
  }
  .ql-script[value='super'] {
    &:hover::after {
      content: 'Super-Script';
    }
  }
  .ql-background {
    &:hover::after {
      content: 'Text-Background';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-color {
    &:hover::after {
      content: 'Text-Color';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-link {
    &:hover::after {
      content: 'Link';
    }
  }
  .ql-image {
    &:hover::after {
      content: 'Image URL';
    }
  }
  .ql-emoji {
    &:hover::after {
      content: 'Emoji';
    }
  }

  ${({ error }) =>
    error &&
    css`
      .ql-toolbar {
        border-top-color: var(--colorSystemDanger);
        border-left-color: var(--colorSystemDanger);
        border-right-color: var(--colorSystemDanger);
      }

      .ql-container.ql-snow {
        border-color: var(--colorSystemDanger);
      }
    `}
  .ql-custom-emoji {
    background: url('/media/emojiIconDark.svg') no-repeat center/19px 19px !important;
  }

  body[data-theme='dark'] & .ql-custom-emoji {
    background: url('/media/emojiIconLight.svg') no-repeat center/19px 19px !important;
  }
`;

const HelperText = styled.p`
  margin: 3px 0 0 14px;
  font-size: 10px;
  color: var(--colorSystemDanger);
`;

const defaultToolBarOptions = [
  [{ size: ['0.75em', false, '1.5em', '2.5em'] }], // custom dropdown
  [{ font: [] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }]
];

const defaultFormat = [
  'size',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'script',
  'color',
  'background',
  'link',
  'image',
  'video',
  'mention',
  'emoji'
];

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['0.75em', '1em', '1.5em', '2.5em'];
Quill.register(Size, true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register(InlineStyle.BlockquoteInlineStyle, true);
Quill.register(InlineStyle.CodeblockInlineStyle, true);

const Link = Quill.import('formats/link');
Link.sanitize = function(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

const RichTextEditor = ({
  name,
  toolbarOptions = defaultToolBarOptions,
  format = defaultFormat,
  autoComplete = {},
  onChangeEditorState,
  // currentState,
  height,
  fontSize,
  allowResize,
  clearState,
  // error,
  // helperText,
  setClearState,
  mentionData = null,
  scrollingContainer = null,
  disabled = false,
  enableOnChangeApiSource = true,
  required,
  rules,
  ...rest
}) => {
  const { setValue, control } = useFormContext();
  const [mentionDataLocal, setMentionDataLocal] = useState(null);
  const quillRef = useRef(null);

  const removeMentionCloseDiv = useCallback(() => {
    return document?.querySelector('#mention-close-div')?.remove();
  }, []);

  const richTextValue = useWatch({ control, name });

  useEffect(() => {
    if (!quillRef.current) {
      return;
    }
    setClearState &&
      setClearState(old => {
        if (old) {
          const quill = quillRef.current.getEditor();
          quill.root.innerHTML = '';
          setValue(name, '');
          quill.setSelection(null, 'user');
          setClearState(!old);
        }
      });
  }, [quillRef, clearState]);

  useEffect(() => {
    if (!quillRef?.current) {
      return;
    }
    if (disabled) quillRef.current.editor.enable(false);
  }, [disabled, quillRef]);

  // This effect is programmatically setting @mention inside of a text editor when user clicks on "Reply" from Newsfeed comments section. This is only allowed when the editor is empty or there is only another mention that should be replaced with the new one
  useEffect(() => {
    if (mentionData) {
      setMentionDataLocal(prevData => {
        const cleanText = cleanTextFromHtml(richTextValue);
        const userChanged =
          prevData?.id !== mentionData.id && cleanText.substring(1, cleanText.length - 1) === prevData?.value;
        if ((!prevData && !cleanText.trim()) || !cleanText.trim() || userChanged) {
          const quill = quillRef.current.getEditor();
          quill.root.innerHTML = '';
          quill.setContents([{ insert: ' ' }]);
          setValue(name, '');
          quill.getModule('mention').insertItem(mentionData, true);
          return mentionData;
        }
        return prevData;
      });
    }
  }, [mentionData]);

  useEffect(() => {
    document.body.addEventListener('click', closeEmojiMenu);
    document.body.addEventListener('click', closeMentionMenu);
    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', onRotate);
    }
    return () => {
      document.body.removeEventListener('click', closeEmojiMenu);
      document.body.removeEventListener('click', closeMentionMenu);
      if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', onRotate);
      }

      const closeEmojiDiv = document.querySelector('#emoji-close-div');
      if (closeEmojiDiv) closeEmojiDiv.style.display = 'none';

      closeMentionMenu();
      removeMentionCloseDiv();
    };
  }, [removeMentionCloseDiv, closeMentionMenu, onRotate, closeEmojiMenu]);

  const onRotate = React.useCallback(
    e => {
      closeMentionMenu();
    },
    [closeMentionMenu]
  );

  const closeEmojiMenu = React.useCallback(e => {
    const isEmojiOpenBtn = e.target.closest('.ql-formats');
    const isEmojiToolbar = e.target.closest('#tab-toolbar');
    const closeEmojiDiv = document.querySelector('#emoji-close-div');
    if (
      closeEmojiDiv &&
      !(isEmojiOpenBtn || isEmojiToolbar) &&
      (closeEmojiDiv.style.display === 'block' || closeEmojiDiv.style.display === '')
    ) {
      closeEmojiDiv.click();
    }
  }, []);

  const closeMentionMenu = React.useCallback(() => {
    const mentionListDiv = document.querySelector('.ql-mention-list-container');
    if (mentionListDiv) mentionListDiv.remove();
  });

  const handleChange = (content, delta, source, editor) => {
    // this fix for all places RichTextEditor is used in
    // more refs can be found here https://quilljs.com/docs/api/#text-change
    // if the source is API then the changes was triggered by setting
    // the value initially from the "api" response
    // we should only care about changes with source "user"
    if (source === 'user' || (source === 'api' && enableOnChangeApiSource === true)) {
      const value = content === '<p><br></p>' ? '' : content;
      onChangeEditorState ? onChangeEditorState(value) : setValue(name, value);
    }
  };

  const imageHandler = React.useCallback(() => {
    if (!quillRef.current) {
      return;
    }

    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const res = await toBase64(file);
        editor.insertEmbed(editor.getSelection(), 'image', res);
      } else {
        console.log('You could only upload images.');
      }
    };
  }, [quillRef]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
          emoji: e => {}
        }
      },
      history: {
        userOnly: true
      },
      mention: autoComplete,
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true
    }),
    [JSON.stringify(autoComplete)]
  );

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => {
        const isError = error && !field.value;

        return (
          <PreWrap>
            <RQcontent fontSize={fontSize} minHeight={height} allowResize={allowResize} error={isError}>
              <ReactQuill
                {...rest}
                value={field.value}
                modules={modules}
                formats={format ?? []}
                theme="snow"
                scrollingContainer={scrollingContainer}
                onChange={handleChange}
                ref={quillRef}
              />
              <textarea disabled name={`${name}`} ref={field.ref} value={field.value} style={{ display: 'none' }} />
              {isError && <HelperText>{error.message}</HelperText>}
            </RQcontent>
          </PreWrap>
        );
      }}
    />
  );
};

export default RichTextEditor;
