const recommendations = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc'
  },
  highlightOnClick: true,
  sort: ['name', 'section', 'priority', 'due_date', 'date_completed'],
  head: [
    {
      id: 'completed',
      label: '',
      align: 'left'
    },
    {
      id: 'name',
      label: 'Recommendation Name',
      align: 'left'
    },
    {
      id: 'section',
      label: 'Section',
      align: 'left'
    },
    {
      id: 'priority',
      label: 'Priority',
      align: 'left'
    },
    {
      id: 'due_date',
      label: 'Due Date',
      align: 'left'
    },
    {
      id: 'date_completed',
      label: 'Completed Date',
      align: 'left'
    }
  ]
};

export default recommendations;
