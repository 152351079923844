// @flow
import React from 'react';
import { Typography, makeStyles, Box, ButtonBase } from '@material-ui/core';
import ReactTimeAgo from 'react-time-ago';
import CustomAvatar from './CustomAvatar';
import { ROLE_OPTIONS } from 'apps/newsfeed/constants';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { useDispatch } from 'react-redux';
import { setFeedSearch, setFeedSearchBy } from 'helpers/action';
import { Tooltip } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  details: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'left'
  },
  timeAgo: {
    display: 'inline-block',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  icon: {
    marginLeft: 14,
    display: 'inline-block',
    verticalAlign: 'middle',
    '& svg': {
      fontSize: 16,
      display: 'block'
    }
  },
  buttonBase: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '50px',
    borderRadius: '100px'
  }
}));

const getRolesDescription = groupIds => {
  groupIds = groupIds || [];
  const groupNames = ROLE_OPTIONS.filter(role => groupIds.includes(role.id))
    .map(role => role.name)
    .join(', ');
  return groupNames ? `Sent to ${groupNames}` : ``;
};

const UserAvatar = ({ user, timestamp, icon, allowedGroups, clickable = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleUserClick = () => {
    dispatch(setFeedSearchBy('user'));
    dispatch(setFeedSearch(user));
  };

  const GetTimestamp = timestamp => {
    return (
      <>
        <ReactTimeAgo
          className={classes.timeAgo}
          timeStyle="round-minute"
          date={timestamp.replace(' ', 'T') + 'Z'}
          locale="en-US"
        />
      </>
    );
  };

  return (
    <>
      <Box>
        <ConditionalWrapper
          condition={clickable}
          wrapper={children => (
            <ButtonBase onClick={handleUserClick} className={classes.buttonBase}>
              {children}
            </ButtonBase>
          )}
        >
          <CustomAvatar background={user.partner_base_color} user={user}>
            {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
          </CustomAvatar>
        </ConditionalWrapper>
      </Box>
      <Box pl={{ xs: 1.2, md: 2 }} flexGrow={1}>
        <Box>
          <ConditionalWrapper
            condition={clickable}
            wrapper={children => <ButtonBase onClick={handleUserClick}>{children}</ButtonBase>}
          >
            <Tooltip title={clickable ? 'Click to view more content.' : null} arrow placement="top-start">
              <Typography className={classes.name} variant="h4" gutterBottom>
                {`${user.first_name} ${user.last_name}`}
              </Typography>
            </Tooltip>
          </ConditionalWrapper>
        </Box>
        <Box>
          <Typography className={classes.details} variant="caption" display="block">
            {getRolesDescription(allowedGroups)}
          </Typography>
          {user?.content_admin ? (
            <>
              <Typography className={classes.details} variant="caption" display="block">
                {timestamp ? GetTimestamp(timestamp) : null}
              </Typography>
            </>
          ) : (
            <Typography className={classes.details} variant="caption" display="block">
              {user.client_name}
              {timestamp ? (
                <>
                  {' '}
                  {` \u002F`} {GetTimestamp(timestamp)}{' '}
                </>
              ) : null}
              {icon && <span className={classes.icon}>{icon}</span>}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(UserAvatar);
