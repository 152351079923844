// @flow

import axios from 'axios';
import dataOptions from './dataOptions';

type dataPostTypes = {
  app: string,
  tab: string,
  item?: string,
  params?: Object,
  data: any
};

function dataPost({ app, tab, item, params = {}, data }: dataPostTypes) {
  const options = dataOptions({ app, tab, item, params, method: 'POST' });

  return axios({ ...options, data });
}

export default dataPost;
