import apiClient from '../../apiClient/apiClient';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_8;
const baseURL = `https://${api}.${base}/${env}`;

const localURL = 'http://127.0.0.1:5000';

const notifications = {};

notifications.getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
});

notifications.testMTNL = payload => {
  const requestOptions = {
    url: `${baseURL}/send_mt_nl_notification`,
    headers: notifications.getHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
}

export default notifications;
