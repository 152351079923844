import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import {
  ADMIN_MANAGE_PARTNERS,
  ADMIN_MICRO_TRAINING,
  ADMIN_NEWSLETTERS,
  ADMIN_ROOT_PATH
} from '../constants';
import { PartnersAddEdit, PartnersConflict } from './ManagePartners';
import { NewslettersAddEdit } from './Newsletters';
import { MicroTrainingsAddEdit } from './MicroTrainings';
import { useAdminAccess } from '../hooks';

const pages = {
  [ADMIN_MANAGE_PARTNERS]: {
    edit: <PartnersAddEdit />,
    create: <PartnersAddEdit />,
    conflict: <PartnersConflict />,
  },
  [ADMIN_NEWSLETTERS]: {
    create: <NewslettersAddEdit />,
    edit: <NewslettersAddEdit />,
  },
  [ADMIN_MICRO_TRAINING]: {
    create: <MicroTrainingsAddEdit />,
    edit: <MicroTrainingsAddEdit />,
  }
};
const ChangeAdminPage = () => {
  const hasAccess = useAdminAccess();
  const { page, method, id } = useParams();
  const history = useHistory();
  const component = pages?.[page]?.[method]

  if (method === 'edit' && !id) {
    history.goBack();
  }

  if (!component) history.replace(ADMIN_ROOT_PATH)

  return hasAccess && <Box pt={2}>{component}</Box>;
};

export default ChangeAdminPage;