// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const HelpCenterIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 27.3 20.1" role="img" aria-hidden="false">
    <path
      d="M17.9511 9.69909C17.9511 9.67581 17.9588 9.66029 17.9588 9.64476C17.9588 5.2443 14.381 1.6665 9.98058 1.6665C5.58011 1.6665 2.00229 5.2443 2.00229 9.64476C2.00229 9.67581 2.01783 9.69909 2.01783 9.73013C0.88473 10.0871 0.0620535 11.1116 0.0620535 12.3301C0.0620535 12.625 0.0465487 13.2226 0.0310268 13.8279C0.0155049 14.4566 0 15.093 0 15.4189C0 16.9323 1.25727 18.1585 2.80946 18.1585C4.36165 18.1585 5.62669 16.9323 5.62669 15.4189C5.62669 15.124 5.6422 14.5342 5.65772 13.9366C5.67324 13.3079 5.68875 12.6638 5.68875 12.3378C5.68875 11.0495 4.77298 9.97849 3.53899 9.68357C3.53899 9.67581 3.54676 9.66805 3.54676 9.65253C3.54676 6.11353 6.42605 3.22645 9.97281 3.22645C13.5196 3.22645 16.3989 6.10577 16.3989 9.65253C16.3989 9.67581 16.4066 9.69133 16.4144 9.71461C15.2425 10.0406 14.3733 11.0883 14.3733 12.3378C14.3733 12.6327 14.3578 13.2303 14.3422 13.8357C14.3267 14.4643 14.3112 15.1007 14.3112 15.4267C14.3112 16.9401 15.5685 18.1663 17.1207 18.1663C18.6729 18.1663 19.9379 16.9401 19.9379 15.4267C19.9379 15.1318 19.9534 14.5419 19.9689 13.9443C19.9844 13.3157 19.9999 12.6715 19.9999 12.3456C20.0077 11.0728 19.1308 10.0251 17.9511 9.69909ZM4.15209 12.3223C4.15209 12.6483 4.13659 13.2691 4.12107 13.8822C4.10554 14.4954 4.08999 15.1007 4.08999 15.4034C4.08999 16.0553 3.52344 16.5908 2.82495 16.5908C2.13423 16.5908 1.56768 16.0553 1.56768 15.4034C1.56768 15.0852 1.58323 14.4643 1.59875 13.8512C1.61428 13.2303 1.62978 12.625 1.62978 12.3145C1.62978 11.6626 2.19633 11.1271 2.88705 11.1271C3.58554 11.1349 4.15209 11.6704 4.15209 12.3223ZM18.4245 13.89C18.409 14.5031 18.3934 15.1085 18.3934 15.4112C18.3934 16.0631 17.8269 16.5986 17.1284 16.5986C16.4377 16.5986 15.8711 16.0631 15.8711 15.4112C15.8711 15.093 15.8867 14.4721 15.9022 13.859C15.9177 13.2381 15.9332 12.6327 15.9332 12.3223C15.9332 11.6704 16.4998 11.1349 17.1905 11.1349C17.889 11.1349 18.4555 11.6704 18.4555 12.3223C18.4555 12.6483 18.44 13.2691 18.4245 13.89Z"
      fill="white"
    />
  </SvgIconStyled>
);

export default HelpCenterIcon;
