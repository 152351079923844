// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Shield = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 26.7 31.8" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h26.7v31.8H0z" />
    <path d="M26.7,8.2V8c0-0.8,0-1.5,0-2.3c0-0.6-0.5-1.1-1.1-1.1c-4.8,0-8.4-1.4-11.4-4.3c-0.4-0.4-1.1-0.4-1.6,0  c-3,2.9-6.6,4.3-11.4,4.3c-0.6,0-1.1,0.5-1.1,1.1c0,0.8,0,1.6,0,2.5c-0.2,8-0.4,18.9,13,23.6c0.2,0.1,0.5,0.1,0.7,0  C27.1,27.1,26.9,16.2,26.7,8.2z M13.4,29.5C1.9,25.3,2.1,15.9,2.3,8.2c0-0.3,0-0.7,0-1c0-0.1,0-0.3,0-0.4c4.1,0,8-1.5,11.1-4.2  c3.1,2.7,7,4.2,11.1,4.2c0,0.4,0,0.7,0,1.1l0,0.3C24.6,15.9,24.8,25.4,13.4,29.5z" />
  </SvgIconStyled>
);

export default Shield;
