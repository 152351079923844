// @flow
import state from 'conf/state';

export function useConfig(type?: string, app?: string, tab?: string, item?: string, key?: string) {
  if (type && app && tab && item && key) return state[type][app][tab][item][key];
  if (type && app && tab && item) return state[type][app][tab][item];
  if (type && app && tab) return state[type][app][tab];
  if (type && app) return state[type][app];
  if (type) return state[type];
  return state;
}

export default useConfig;
