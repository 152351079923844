// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const EmailSend = (props: Props) => (
  <SvgIconStyled {...props} width="15" height="14" viewBox="0 0 15 14">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0319 0.0965576H2.14532C1.2404 0.0965576 0.5 0.84424 0.5 1.75807V7.83369C0.5 8.74752 1.2404 9.4952 2.14532 9.4952H6.71384L6.65351 9.43428C6.36832 9.15182 6.26961 8.75306 6.36833 8.38753H2.14532C1.84368 8.38753 1.59688 8.1383 1.59688 7.83369V1.97961L6.82353 7.2577C6.97161 7.40723 7.1526 7.51246 7.34456 7.55677C7.44327 7.59 7.54199 7.60108 7.64071 7.60108C7.66813 7.60108 7.70104 7.60108 7.72846 7.59C7.99171 7.57892 8.24948 7.46815 8.44692 7.26323L13.5803 2.08484V7.83369C13.5803 8.1383 13.3335 8.38753 13.0319 8.38753H12.4231L13.4597 9.43428C14.1617 9.24598 14.6772 8.59799 14.6772 7.83369V1.75807C14.6772 0.84424 13.9368 0.0965576 13.0319 0.0965576ZM7.67362 6.48232C7.65716 6.49894 7.61878 6.4934 7.60232 6.47678L2.38116 1.20424H12.8948L7.67362 6.48232ZM8.80342 11.2952C8.91311 11.1844 8.91311 11.0127 8.80342 10.902L7.24037 9.32352C7.02648 9.10752 7.02648 8.75307 7.24037 8.53707C7.45426 8.32107 7.80526 8.32107 8.01916 8.53707L9.77417 10.3094L10.1636 10.7026C10.3775 10.9186 10.3775 11.273 10.1636 11.489L9.77417 11.8823L8.01916 13.6545C7.90947 13.7598 7.77235 13.8152 7.62976 13.8152C7.48716 13.8152 7.35006 13.7598 7.24037 13.6545C7.02648 13.4385 7.02648 13.0841 7.24037 12.8681L8.80342 11.2952ZM12.1982 10.902C12.3079 11.0127 12.3079 11.1844 12.1982 11.2952L10.6351 12.8681C10.4212 13.0841 10.4212 13.4385 10.6351 13.6545C10.7448 13.7598 10.8819 13.8152 11.0245 13.8152C11.1671 13.8152 11.3042 13.7598 11.4139 13.6545L13.1689 11.8823L13.5583 11.489C13.7722 11.273 13.7722 10.9186 13.5583 10.7026L13.1689 10.3094L11.4139 8.53707C11.2 8.32107 10.849 8.32107 10.6351 8.53707C10.4212 8.75307 10.4212 9.10752 10.6351 9.32352L12.1982 10.902Z" />
  </SvgIconStyled>
);

export default EmailSend;