import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CalendarFile, Container, CustomTooltip, Info, Switch, TextField, Typography } from 'components';
import { theme } from 'conf';
import { dateFromMonthDate, dateToMonthDate, getCurrentYear } from './helpers';
import React from 'react';

const FIRST_YEAR_DAY = `${getCurrentYear()}-01-01T00:00:00`;
const LAST_YEAR_DAY = `${getCurrentYear()}-12-31T23:59:59`;

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return date.toLocaleString('default', { month: 'long' });
  }
}

const defaultMaterialTheme = createTheme({
  ...theme,
  overrides: {
    MuiInputLabel: {
      outlined: {
        color: 'var(--colorDefault)'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: 'var(--blackAndWhite)'
        }
      },
      input: {
        color: 'var(--blackAndWhite)',
        fontSize: '85%'
      },
      notchedOutline: {
        borderColor: 'var(--borderBase)'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'var(--colorSystemInfo)',
        '&:hover': {
          backgroundColor: 'var(--colorSystemInfo)'
        }
      },
      day: {
        color: 'var(--blackAndWhite)'
      }
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: 'var(--blackAndWhite)'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: 'var(--blackAndWhite)'
      },
      dayLabel: {
        color: 'var(--colorLight)'
      }
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: 'var(--colorLight)'
        }
      }
    }
  }
});

const dateToolbarStyle = {
  fontWeight: 'bold',
  fontSize: '130%',
  alignSelf: 'center',
  margin: '15px 0 -10px 0',
  color: 'var(--blackAndWhite)'
};

function ActiveTimeFrame({ state, setState, actionLoading }) {
  const validate = (date, name) => {
    let errorObj = {};
    switch (name) {
      case 'start_time_frame': {
        const isError =
          dateFromMonthDate(state.subscription.end_time_frame) < date || date.toDateString() === 'Invalid Date';
        const textError =
          date.toDateString() === 'Invalid Date'
            ? 'Invalid Date Format'
            : isError
            ? "End Date Can't be Before Start Date"
            : '';
        errorObj = {
          error: isError,
          error_text: isError ? textError : ''
        };
        break;
      }
      case 'end_time_frame': {
        const isError =
          date < dateFromMonthDate(state.subscription.start_time_frame) || date.toDateString() === 'Invalid Date';
        const textError =
          date.toDateString() === 'Invalid Date'
            ? 'Invalid Date Format'
            : isError
            ? "End Date Can't be Before Start Date"
            : '';
        errorObj = {
          error: isError,
          error_text: isError ? textError : ''
        };
        break;
      }
      default:
        errorObj = {
          error: date.trim() === '',
          error_text: date.trim() !== '' ? '' : "this field shouldn't be empty"
        };
    }
    return errorObj;
  };

  const handleDateChange = (date, name) => {
    const error = validate(date, name);
    const type = 'time_frame';
    setState(state => {
      return {
        ...state,
        subscription: { ...state.subscription, [name]: dateToMonthDate(date) },
        subscription_validation: {
          ...state.subscription_validation,
          [`${type}_error`]: error.error,
          [`${type}_error_text`]: error.error_text
        }
      };
    });
  };

  const handleSwitchChange = checked => {
    setState(state => {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          active_time_frame: Number(checked)
        },
        subscription_validation: {
          ...state.subscription_validation,
          time_frame_error: false,
          time_frame_error_text: ''
        }
      };
    });
  };

  return (
    <>
      {Boolean(state?.push_notification?.show_active_timeframe) && (
        <Container.Grid py={1.5} container alignItems="center">
          <Container.Grid item xs={8} pr={1}>
            <Typography.p>Set Active Time Frame</Typography.p>
            <div style={{ alignSelf: 'center', margin: '0 20px 0 10px' }}>
              <CustomTooltip
                title={'Set a time frame for when this rule will be active each year'}
                arrow
                tooltipX={40}
                arrowX={7}
                maxWidth="none"
                light
                placement="top"
                component="span"
                disableClickListener
              >
                <Info color={'colorDefault'} />
              </CustomTooltip>
            </div>
            <Switch
              value={true}
              checked={state?.subscription?.active_time_frame}
              name="active_time_frame"
              onChange={({ target: { checked } }) => {
                handleSwitchChange(checked);
              }}
              // disabled={loading}
            />
          </Container.Grid>
          <Container.Grid item xs={4}>
            {/* Start Date */}
            {Boolean(state?.subscription?.active_time_frame) && (
              <ThemeProvider theme={defaultMaterialTheme}>
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                  <KeyboardDatePicker
                    ToolbarComponent={() => <div style={dateToolbarStyle}>{'Choose Date'}</div>}
                    TextFieldComponent={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant={false}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                      />
                    )}
                    disabled={actionLoading}
                    style={{ width: '150px', marginRight: '25px' }}
                    value={dateFromMonthDate(state.subscription.start_time_frame)}
                    id="start_time_frame"
                    format="MM/dd"
                    minDate={FIRST_YEAR_DAY}
                    maxDate={LAST_YEAR_DAY}
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    allowKeyboardControl={false}
                    autoOk
                    label="Start Date"
                    onChange={date => {
                      if (date) {
                        handleDateChange(date, 'start_time_frame');
                      }
                    }}
                    {...(state.subscription_validation.time_frame_error ? { error: true } : {})}
                    {...(state.subscription_validation.time_frame_error
                      ? { helperText: state.subscription_validation.time_frame_error_text }
                      : {})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
                  />
                </MuiPickersUtilsProvider>
                {/* End Date */}
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                  <KeyboardDatePicker
                    ToolbarComponent={() => <div style={dateToolbarStyle}>{'Choose Date'}</div>}
                    TextFieldComponent={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant={false}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                      />
                    )}
                    disabled={actionLoading}
                    style={{ width: '150px' }}
                    value={dateFromMonthDate(state.subscription.end_time_frame)}
                    id="end_time_frame"
                    format="MM/dd"
                    minDate={FIRST_YEAR_DAY}
                    maxDate={LAST_YEAR_DAY}
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    allowKeyboardControl={false}
                    autoOk
                    label="End Date"
                    onChange={date => {
                      if (date) {
                        handleDateChange(date, 'end_time_frame');
                      }
                    }}
                    {...(state.subscription_validation.time_frame_error ? { error: true } : {})}
                    {...(state.subscription_validation.time_frame_error
                      ? { helperText: state.subscription_validation.time_frame_error_text }
                      : {})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            )}
          </Container.Grid>
        </Container.Grid>
      )}
    </>
  );
}

export default ActiveTimeFrame;
