// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Scenario = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21.696 17.142" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h21.696v17.142H0z" />
    <g id="icon" transform="translate(-2 -6)">
      <path
        id="Path_192"
        data-name="Path 192"
        d="M21.527,6H4.17A2.083,2.083,0,0,0,2,7.978V21.164a2.083,2.083,0,0,0,2.17,1.978H21.527a2.083,2.083,0,0,0,2.17-1.978V7.978A2.083,2.083,0,0,0,21.527,6Zm.723,15.164a.694.694,0,0,1-.723.659H4.17a.694.694,0,0,1-.723-.659V15.833l5.786-5.274,8.89,8.1a.773.773,0,0,0,1.023,0l3.1-2.83Zm0-7.2-3.616,3.3-8.89-8.1a.774.774,0,0,0-1.023,0L3.446,13.968V7.978a.694.694,0,0,1,.723-.659H21.527a.694.694,0,0,1,.723.659Z"
        transform="translate(0)"
      />
      <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(16 9)" />
    </g>
  </SvgIconStyled>
);

export default Scenario;
