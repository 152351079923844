import React, { useMemo } from 'react';
import { ContentAdminFormGrid, ContentAdminFormRow, SubTitle } from '../../style';
import {
  RHFTextField as TextField,
  RHFDynamicForm as DynamicForm,
  Button
} from 'components';
import { PlusCircle } from 'components/icons';
import { useContentAdminFormContext } from '../../context';

const SendingProfileForm = () => {

  const dynamicFormAddButton = (
    <Button>
      <PlusCircle mr={1} /> Add Custom Header
    </Button>
  )

  return (
    <>
      <ContentAdminFormRow>
        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            name="host"
            label="Host"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            type={"number"}
            name="port"
            label="Port"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8} mb={2.5}>
          <TextField
            name="user"
            label="User"
            fullWidth
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={8}>
          <TextField
            type={"password"}
            name="password"
            label="Password"
            fullWidth
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
      <ContentAdminFormRow>
        <SubTitle>Email Headers:</SubTitle>

        <DynamicForm
          name={"smtp_headers"}
          addButton={dynamicFormAddButton}
        >
          <TextField
            name="header_name"
            label="X-Custom-Header"
            fullWidth
            required
            gridSize={{
              md: 6,
              sm: 12
            }}
          />
          <TextField
            name="header_value"
            label="{{.URL.}}-gophish"
            fullWidth
            required
            gridSize={{
              md: 6,
              sm: 12
            }}
          />
        </DynamicForm>

      </ContentAdminFormRow>
    </>
  )
}

export default SendingProfileForm
