// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerKey = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 19.357 19.247" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h19.357v19.247H0z" />
    <g transform="translate(0.192 0.123)">
      <path
        d="M5.662,3.095A2.687,2.687,0,0,0,3.055,5.676,2.553,2.553,0,0,0,3.81,7.514a2.646,2.646,0,0,0,1.9.789h.006A2.608,2.608,0,0,0,8.27,5.679,2.651,2.651,0,0,0,5.662,3.095Zm1.314,2.6a1.3,1.3,0,0,1-2.246.914,1.273,1.273,0,0,1-.381-.914,1.389,1.389,0,0,1,1.313-1.3V4.173l0,.216A1.353,1.353,0,0,1,6.976,5.692Z"
        transform="translate(0.122 0.128)"
      />
      <path
        d="M19.129,15.557a2.35,2.35,0,0,0-.616-1.417c-1.688-1.728-3.491-3.535-5.847-5.859-.232-.229-.275-.306-.206-.613a6.363,6.363,0,0,0-1.229-5.4A6.472,6.472,0,0,0,5.1-.032,6.4,6.4,0,0,0,6.887,12.626c.664-.088.978.011,1.245.4a2.189,2.189,0,0,0,2.111.9c.387-.03.781-.024,1.2-.017l.113,0v1.9a.6.6,0,0,0,.6.6h2.614v2.125a.6.6,0,0,0,.6.6h3.2a.589.589,0,0,0,.422-.175.582.582,0,0,0,.173-.419c0-.291,0-.577.007-.859C19.166,16.947,19.174,16.263,19.129,15.557Zm-6.981-2.83c-.165,0-.325,0-.482,0l-.148,0c-.472-.007-.916-.015-1.367.018a1.029,1.029,0,0,1-1.042-.4,2.221,2.221,0,0,0-2.382-.9A5.2,5.2,0,0,1,5.3,1.144a5.288,5.288,0,0,1,5,1.871,5.177,5.177,0,0,1,1,4.4,1.665,1.665,0,0,0,.533,1.719c2.336,2.3,4.134,4.1,5.833,5.843a1.163,1.163,0,0,1,.28.66c.042.661.034,1.322.026,2.022l0,.279H15.953V15.809a.6.6,0,0,0-.6-.6H12.744V13.322A.6.6,0,0,0,12.148,12.727Z"
        transform="translate(0 0)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerKey;
