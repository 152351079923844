import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { isValidEmail, checkIfDuplicateExists } from 'helpers';

const EmailValidator = ({ label = null, initialEmails, newEmails }) => {
  const [emails, setEmails] = useState(initialEmails || []);
  const [validEmail, setValidEmail] = useState(true);
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    newEmails({
      list: emails,
      valid: validEmail
    });
  }, [emails]);

  useEffect(() => {
    setEmails(initialEmails || []);
  }, [initialEmails]);

  return (
    <TextField
      error={!validEmail}
      helperText={!validEmail && errorText}
      value={emails.join(';')}
      name="Email_Validator"
      label={label}
      onChange={({ target: { name, value } }) => {
        const values = value.replace(/\s/g, '').split(';');
        if (values[0] === '') values.shift();
        const emailValidity = values.every(email => isValidEmail(email));
        const emailsAreDuplicate = checkIfDuplicateExists(values, false);
        setEmails(values);

        if (emailsAreDuplicate) {
          setErrorText('Emails entered have a duplicate email');
        }

        if (!emailValidity) {
          setErrorText('Please enter valid email addresses');
        }

        setValidEmail(emailValidity && !emailsAreDuplicate);

        if ((values.length === 1) & (values[0] === '')) {
          setValidEmail(true);
          setEmails([]);
        }
      }}
      fullWidth
      variant="outlined"
    />
  );
};

export default EmailValidator;
