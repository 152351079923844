// @flow

const documentsServiceProvider = {
  type: 'documents',
  selecting: true,
  selectAll: true,
  filters: [
    {
      name: 'test'
    },
    {
      name: 'test2'
    }
  ],
  params: {
    _sort: 'contact'
  },
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'clients',
      tab: 'documentsServiceProviderInfo'
    }
  },

  cells: [
    { id: 'business_name', label: 'Business Name', align: 'left' },
    { id: 'contact', label: 'Contact', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'contract_date', label: 'Contract Date', align: 'left' },
    { id: 'created', label: 'Created', align: 'left' },
    { id: 'modified', label: 'Modified', align: 'left' },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      renderDowloadIcon: true,
      hideOrderBy: true,
      GAbutton: 'Download SPD or BAA'
    }
  ]
};

export default documentsServiceProvider;
