// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Scenarios = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 36 36">
    <path d="M22.8827 14.2945H13.2559C12.1538 14.2945 11.0969 14.7323 10.3176 15.5116C9.53827 16.2909 9.10046 17.3479 9.10046 18.45V25.5558C9.10046 26.6579 9.53827 27.7148 10.3176 28.4941C11.0969 29.2734 12.1538 29.7112 13.2559 29.7112H22.9519C24.054 29.7112 25.111 29.2734 25.8903 28.4941C26.6696 27.7148 27.1074 26.6579 27.1074 25.5558V18.45C27.1075 17.8984 26.9977 17.3523 26.7846 16.8436C26.5714 16.3349 26.2591 15.8737 25.8659 15.4869C25.4727 15.1001 25.0064 14.7954 24.4942 14.5907C23.982 14.386 23.4342 14.2853 22.8827 14.2945V14.2945ZM24.2678 25.5558C24.2678 25.9231 24.1219 26.2755 23.8621 26.5352C23.6024 26.795 23.2501 26.9409 22.8827 26.9409H13.2559C12.8885 26.9409 12.5362 26.795 12.2765 26.5352C12.0167 26.2755 11.8708 25.9231 11.8708 25.5558V18.45C11.8708 18.0826 12.0167 17.7303 12.2765 17.4705C12.5362 17.2108 12.8885 17.0648 13.2559 17.0648H22.9519C23.3193 17.0648 23.6716 17.2108 23.9314 17.4705C24.1912 17.7303 24.3371 18.0826 24.3371 18.45L24.2678 25.5558Z"/>
    <path d="M31.8999 0H4.19695C3.09486 0 2.0379 0.437804 1.25861 1.2171C0.479308 1.9964 0.0415039 3.05335 0.0415039 4.15544L0.0415039 31.7892C0.0415039 32.8912 0.479308 33.9482 1.25861 34.7275C2.0379 35.5068 3.09486 35.9446 4.19695 35.9446H31.8999C33.002 35.9446 34.059 35.5068 34.8383 34.7275C35.6176 33.9482 36.0554 32.8912 36.0554 31.7892V4.15544C36.0554 3.05335 35.6176 1.9964 34.8383 1.2171C34.059 0.437804 33.002 0 31.8999 0V0ZM4.19695 2.7703H31.8999C32.2673 2.7703 32.6196 2.91623 32.8794 3.176C33.1391 3.43576 33.2851 3.78808 33.2851 4.15544V8.82339H2.8118V4.15544C2.8118 3.78808 2.95774 3.43576 3.2175 3.176C3.47727 2.91623 3.82958 2.7703 4.19695 2.7703V2.7703ZM31.8999 33.1743H4.19695C3.82958 33.1743 3.47727 33.0284 3.2175 32.7686C2.95774 32.5088 2.8118 32.1565 2.8118 31.7892V11.5937H33.2851V31.7892C33.2851 32.1565 33.1391 32.5088 32.8794 32.7686C32.6196 33.0284 32.2673 33.1743 31.8999 33.1743Z"/>
    <path d="M6.05308 6.99483C6.30786 6.99483 6.55692 6.91928 6.76876 6.77773C6.9806 6.63619 7.14571 6.435 7.24321 6.19961C7.34071 5.96423 7.36622 5.70522 7.31652 5.45533C7.26681 5.20545 7.14412 4.97592 6.96397 4.79576C6.78381 4.6156 6.55428 4.49291 6.30439 4.44321C6.05451 4.3935 5.7955 4.41902 5.56011 4.51651C5.32473 4.61401 5.12354 4.77913 4.98199 4.99097C4.84044 5.20281 4.76489 5.45187 4.76489 5.70665C4.76489 6.04829 4.90061 6.37595 5.14219 6.61753C5.38378 6.85911 5.71143 6.99483 6.05308 6.99483V6.99483Z"/>
    <path d="M10.6934 6.99476C10.9475 6.99203 11.1952 6.91416 11.4052 6.77096C11.6152 6.62776 11.7782 6.42563 11.8736 6.19002C11.9689 5.9544 11.9925 5.69584 11.9412 5.44687C11.89 5.19791 11.7662 4.96967 11.5855 4.7909C11.4048 4.61213 11.1753 4.49081 10.9258 4.44222C10.6763 4.39362 10.418 4.41993 10.1834 4.51782C9.94881 4.61572 9.74843 4.78082 9.60749 4.99235C9.46654 5.20388 9.39133 5.45238 9.39131 5.70657C9.38942 5.87744 9.4219 6.04694 9.48684 6.205C9.55178 6.36306 9.64784 6.50644 9.76931 6.62662C9.89078 6.7468 10.0352 6.84133 10.1939 6.90457C10.3527 6.96781 10.5225 6.99848 10.6934 6.99476V6.99476Z"/>
    <path d="M15.6244 6.99476C15.8786 6.99203 16.1263 6.91416 16.3363 6.77096C16.5463 6.62776 16.7093 6.42563 16.8046 6.19002C16.9 5.9544 16.9236 5.69584 16.8723 5.44687C16.821 5.19791 16.6973 4.96967 16.5166 4.7909C16.3359 4.61213 16.1063 4.49081 15.8568 4.44222C15.6073 4.39362 15.349 4.41993 15.1145 4.51782C14.8799 4.61572 14.6795 4.78082 14.5386 4.99235C14.3976 5.20388 14.3224 5.45238 14.3224 5.70657C14.3224 5.87691 14.3562 6.04556 14.4218 6.20276C14.4874 6.35996 14.5835 6.50259 14.7046 6.62239C14.8257 6.74219 14.9693 6.83679 15.1272 6.90071C15.2851 6.96462 15.4541 6.99659 15.6244 6.99476V6.99476Z"/>
    <defs>
      <clipPath id="clip0_12082_133352">
        <rect width="36" height="35.8615" fill="white"/>
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default Scenarios;
