import React from 'react';
import Controller from './Controller';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { PhoneNumberInput  } from '@trustsecurenow/components-library';

const PhoneTextField = ({ name, required, rules, type, onChange, ...props }) => {
  const formContextData = useFormContext();

  const { setValue, control } = formContextData;
  const phoneValue = useWatch({ control, name });

  const handleChange = value => {
    const newValue = value.includes('+') ? value : `+1 ${value}`;
    setValue(name, newValue);
  };

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }) => {
        return (
          <PhoneNumberInput
            {...field}
            variant="outlined"
            name="phone"
            label="Phone number"
            onChange={value => {
              if (
                Math.abs(value.length - phoneValue?.length) < 3 &&
                (value.includes(phoneValue) || phoneValue?.includes(value))
              ) {
                handleChange(value);
                return;
              }
              handleChange(value);
              setTimeout(() => {
                handleChange(value);
              }, 10);
            }}
            defaultCountry="us"
            countryCodeEditable={false}
            fullWidth
          />
        );
      }}
    />
  );
};

export default PhoneTextField;
