import apiClient from 'helpers/apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/pushnotifications`;

export const getPushNotificationsSubscriptions = (partnerID, clientID = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/PushNotificationsSubscriptions/${partnerID}`,
    headers: getHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.get(requestOptions);
};

export const createSubscription = (partnerID, clientID = null, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/PushNotificationsSubscriptions/${partnerID}`,
    data,
    headers: getHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.post(requestOptions);
};

export const enableDisableSubscription = (partnerID, clientID = null, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/PushNotificationsSubscriptions/${partnerID}`,
    data,
    headers: getHeaders(),
    ...(clientID !== null ? { params: { client_id: clientID } } : {})
  };
  return apiClient.put(requestOptions);
};

export const getSubscription = subscriptionID => {
  const requestOptions = {
    url: `${BASE_PATH}/Subscriptions/${subscriptionID}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setSubscription = (subscriptionID, subscription) => {
  const requestOptions = {
    url: `${BASE_PATH}/Subscriptions/${subscriptionID}`,
    data: subscription,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const revertSubscription = subscriptionID => {
  const requestOptions = {
    url: `${BASE_PATH}/Revert/subscription/${subscriptionID}`,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const testPushNotificationsMessage = (subscriptionID, customMessage) => {
  const requestOptions = {
    url: `${BASE_PATH}/Subscriptions/${subscriptionID}/test_push_notification_message`,
    data: customMessage,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const getSendingTimes = (partner_id, client_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/Notifications/SendingTime/${partner_id}${client_id == null ? '' : `?client_id=${client_id}`}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const postSendingTimes = (partner_id, client_id, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/Notifications/SendingTime/${partner_id}${client_id == null ? '' : `?client_id=${client_id}`}`,
    data,
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};

export const reactionaryTestNotification = (name, partnerId, itemId) => {
  const config = {
    MicroTraining: 'micro_training_id',
    MonthlyNewsletter: 'security_newsletter_id'
  };
  const requestOptions = {
    url: `${BASE_PATH}/reactionaryNotification/test`,
    data: {
      push_notification_name: name,
      partner_id: partnerId,
      parameters: { [config[name]]: itemId }
    },
    headers: getHeaders()
  };
  return apiClient.post(requestOptions);
};