// @flow

const trainingTrainings = {
  title: 'Micro Training',
  component: 'clients/ClientsTrainingModal',
  buttons: {
    cancel: {
      label: 'Cancel'
    }
  },
  initialState: null
};

export default trainingTrainings;
