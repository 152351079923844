// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { MenuItemLink as MuiMenuItemLink } from 'react-admin';
import MuiPhoneNumber from 'material-ui-phone-number';

export const ButtonCancel: ComponentType<*> = styled(MuiMenuItemLink)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 20px;
    font-size: var(--fontSize);
    & span {
      color: var(--colorDefault);
    }
    &:hover {
      color: var(--colorDefault);
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;


export const MuiPhoneNumberInput: ComponentType<*> = styled(MuiPhoneNumber)`
  input {
    &:-webkit-autofill {
      border-radius: 0;
      -webkit-text-fill-color: var(--colorDarkGrey);
      box-shadow: 0 0 0px 1000px var(--backgroundPaper) inset;
    }
  }
`;