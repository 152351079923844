/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Box } from '@material-ui/core';
import { Button, ButtonCancel, Container, Modal, Typography as div } from 'components';

import PropTypes from 'prop-types';
/**
 *
 * @param {typeof ClientCreateProceedModal.defaultProps } props
 * @returns {JSX.Element}
 */
function ClientCreateProceedModal(props) {
  return (
    <Modal
      onClose={props.onClose}
      opened={props.opened}
      title=" "
      loadingContent={props.loading}
      dismissOutside
      overwriteStyles={{
        dialog: { minWidth: '65rem', margin: '0 auto' },
        dialogHeader: { height: '2rem' }
      }}
      loadingSize={60}
      loadingSkeleton={{ width: '50rem', height: '25rem' }}
      data={
        <>
          <Container.Grid>
            <Box display="flex" alignItems="center" flexDirection="column" width="100%" p={2.625}>
              <div.h3>Create New Client</div.h3>

              <div>A client with a similiar name already exists in your account.</div>
              <div>Would you like to create this client anyway?</div>
            </Box>
          </Container.Grid>
          <div style={{ height: '1px', background: 'var(--colorGreyLight3)', minWidth: '60rem' }} />
          <Container.Grid
            pl={2.5}
            pr={2.5}
            pb={2.5}
            pt={2.375}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justify="center"
            direction="column"
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                backgroundDisabled="colorSystemSuccessDisable"
                height={36}
                pr={3}
                pl={3}
                justifyContent="center"
                onClick={() => {
                  !props.pendingAction && props.onSave();
                }}
                disabled={props.pendingAction}
              >
                Yes, Create new client
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '20px', marginBottom: '7rem' }}>
              <ButtonCancel onClick={props.onClose}>Cancel</ButtonCancel>
            </div>
          </Container.Grid>
        </>
      }
    />
  );
}

ClientCreateProceedModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  pendingAction: PropTypes.bool,
  loading: PropTypes.bool
};

ClientCreateProceedModal.defaultProps = {
  opened: false,
  loading: false,
  pendingAction: false,
  onClose: () => {},
  onSave: () => {}
};

export default React.memo(ClientCreateProceedModal);
