import apiClient from '../../apiClient/apiClient';

export const uploadFile = (url, formData, onUploadProgress, cancelToken) => {
  const requestOptions = {
    url,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    cancelToken,
    onUploadProgress
  };
  return apiClient.post(requestOptions);
};