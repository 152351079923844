// @flow

const trackSecurityIncidentsInfo = {
  title: 'Security Incident',
  component: 'shared/track/TrackIncidentModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save'
    }
  },
  initialState: {}
};

export default trackSecurityIncidentsInfo;
