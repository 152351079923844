import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import { getNameShortcut } from 'helpers';

const trackingUtils = {};
const trackingId = process.env.REACT_APP_TRACK_ID;

trackingUtils.initialize = () => {
  ReactGA.initialize(trackingId);
  const history = createBrowserHistory();
  history.listen(location => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, epoch: Date.now().toString() });
  });
};

trackingUtils.clickApp = app => {
  ReactGA.event('click_app', {
    epoch: Date.now().toString(),
    email: localStorage.getItem('email'),
    user_role: getNameShortcut(localStorage.getItem('userRole')),
    app
  });
};

trackingUtils.clickTab = (app, tab) => {
  const [, appLoc] = window.location.hash.split('/');
  ReactGA.event('click_tab', {
    epoch: Date.now().toString(),
    email: localStorage.getItem('email'),
    app: app || appLoc,
    tab
  });
};

trackingUtils.clickButton = label => {
  if (label) {
    const [, appLoc, tabLoc] = window.location.hash.split('/');
    const tab = tabLoc?.split('?')[0];
    ReactGA.event('click_button', {
      epoch: Date.now().toString(),
      email: localStorage.getItem('email'),
      app: appLoc,
      ...(tab && { tab }),
      label
    });
  }
};

trackingUtils.customEvent = (event_name, { sendUserName, sendPartnerName, ...params }) => {
  ReactGA.event(event_name, {
    epoch: Date.now().toString(),
    email: localStorage.getItem('email'),
    ...(sendUserName && { user_name: localStorage.getItem('userName') }),
    ...(sendPartnerName && { partner_name: localStorage.getItem('partnerName') }),
    ...params
  });
};

export default trackingUtils;
