// @flow

const workPlanFindingInfo = {
  title: 'Risk Assessment Work Plan',
  component: 'myCompany/workPlan/CompanyWorkPlanModalFindingInfo',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save',
      icon: 'Done'
    },
    other: []
  },
  initialState: null
};

export default workPlanFindingInfo;
