import partners from './partners';
import partnersTaxExempt from './partnersTaxExempt';
import newsletters from './newsletters';
import microTrainings from './microTrainings';
import policies from './policies';

const admin = {};

admin.partners = partners;
admin.partnersTaxExempt = partnersTaxExempt;
admin.newsletters = newsletters;
admin.microTrainings = microTrainings;
admin.policies = policies;

export default admin;