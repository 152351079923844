import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';




const baseURL = process.env.REPORTING_BASE_URL;

const reporting = {};

reporting.getReport = (data) => {

    const requestOptions = {
        url: baseURL,
        headers: {
            "Content-Type": "application/json",
            "Authorization": getStorage('idToken', true)
        },
        data,
    }
    return apiClient.post(requestOptions);
}


export default reporting;
