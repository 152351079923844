import React from "react"
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 22
    }
  },
  feedback: {
    margin: 0,
    color: 'var(--colorDefault)',
    fontSize: '1.6rem',
    lineHeight: 1.5,
    position: 'relative',
    '-webkit-text-size-adjust': '100%',
    '&:before': {
      content: props => props.isCorrect ? "'Correct!'" : "'Incorrect!'",
      color: props => props.isCorrect ? "var(--colorSystemSuccess)" : "var(--colorSystemDanger)",
      position: 'absolute',
      top: 0,
      left: 0
    }
  }
}));

const QuestionFeedback = ({ isCorrect, feedback }) => {
  const classes = useStyles({ isCorrect });

  return (
    <div className={classes.wrapper}>
      <p className={classes.feedback}>
        {feedback}
      </p>
    </div>
  )
}

export default React.memo(QuestionFeedback)
