// @flow

import React, { Children, isValidElement } from 'react';
import compose from 'recompose/compose';
import { Toolbar as ToolBar, withWidth } from '@material-ui/core';
import { DeleteButton } from 'react-admin';
import { SaveButton } from 'components';

import type { Element } from 'react';

type ToolbarTypes = {
  basePath: string,
  children: Element<*>,
  handleSubmit: Function,
  handleSubmitWithRedirect: Function,
  invalid: Boolean,
  pristine: Boolean,
  record: Object,
  redirect: string | Boolean | Function,
  resource: string,
  saving: Object | Boolean,
  submitOnEnter?: Boolean,
  undoable: Boolean,
  width: string,
  ...
};

const valueOrDefault = (value, defaultValue) => (typeof value === 'undefined' ? defaultValue : value);

const Toolbar = ({
  basePath,
  children,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  width,
  ...rest
}: ToolbarTypes) => {
  return (
    <ToolBar isMobile={width === 'xs'} role="toolbar" {...(rest: $Rest<ToolbarTypes, any>)}>
      {Children.count(children) === 0 ? (
        <>
          <SaveButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            buttonFullWidth="true"
            invalid={invalid}
            redirect={redirect}
            saving={saving}
            submitOnEnter={submitOnEnter}
          />
          {record && typeof record.id !== 'undefined' && (
            <DeleteButton basePath={basePath} record={record} resource={resource} undoable={undoable} />
          )}
        </>
      ) : (
        Children.map(children, button =>
          button && isValidElement(button)
            ? React.cloneElement(button, {
              basePath,
              handleSubmit: valueOrDefault(button.props.handleSubmit, handleSubmit),
              handleSubmitWithRedirect: valueOrDefault(button.props.handleSubmitWithRedirect, handleSubmitWithRedirect),
              invalid,
              pristine,
              record,
              resource,
              saving,
              submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
              undoable: valueOrDefault(button.props.undoable, undoable)
            })
            : null
        )
      )}
    </ToolBar>
  );
};

Toolbar.defaultProps = {
  submitOnEnter: true
};

const enhance = compose(withWidth());
export default enhance(Toolbar);
