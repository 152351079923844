export const convert12HourTo24HourFormat = input => {
  // Extract hours and am/pm from the input string
  const [hours, ampm] = input.match(/\d+|[aApP][mM]/g);

  // Convert hours to 24-hour format
  let militaryHours = parseInt(hours, 10);
  if (ampm.toLowerCase() === 'pm' && militaryHours < 12) {
    militaryHours += 12;
  } else if (ampm.toLowerCase() === 'am' && militaryHours === 12) {
    militaryHours = 0;
  }

  // Format the result
  const formattedTime = `${militaryHours.toString().padStart(2, '0')}:00:00`;

  return formattedTime;
};
