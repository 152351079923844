import React, { useEffect, useState } from 'react';
import TextField from './TextField';
import { useQuery } from 'react-query';
import { useDebounce } from '../../hooks';
import { AutoComplete } from './index';

const AutoCompleteAsync = ({
  id,
  options,
  api,
  dataPath,
  label,
  labelName = '',
  value,
  onChange,
  oneRequest = false,
  refetch,
  getOptionSelected,
  ...props
}) => {
  const [initialRequest, setInitialRequest] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [singleValue, setSingleValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState();

  const [searchValueDebounce] = useDebounce({ value: inputValue, delay: 500 });

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const { isLoading, isFetching, refetch: refetchQuery, data } = useQuery(
    id,
    () =>
      api({
        page: 1,
        limit: 10,
        filter: inputValue
      }),
    {
      keepPreviousData: true,
      staleTime: 10,
      cacheTime: false,
      refetchOnWindowFocus: false
    }
  );

  function getData() {
    if (data) {
      const dataPathArr = dataPath.split('.');
      let currentData = data;

      dataPathArr.forEach(path => {
        if (currentData[path]) {
          currentData = currentData[path];
        }
      });

      return currentData;
    }

    return data;
  }

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (value !== '' && typeof value !== 'object') {
        const data = getData().find(({ id }) => id === value);
        setSingleValue(data);
      }
      setInitialRequest(false);
      setIsOptionsLoading(false);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (!oneRequest) {
      if (selectedValue !== '' && searchValueDebounce.value !== '') {
        if (selectedValue[labelName] !== searchValueDebounce.value) {
          refetchQuery();
        }
        setIsOptionsLoading(false);
      } else {
        refetchQuery();
      }
    }
  }, [searchValueDebounce.value]);

  useEffect(() => {
    refetch && refetchQuery();
  }, [refetch]);

  const getOptionLabel = option => {
    if (option) {
      if (Array.isArray(labelName)) {
        return `${labelName.map(label => option[label]).join(' ')}`;
      } else {
        return option[labelName];
      }
    }
    return '';
  };

  if (!labelName) {
    throw new Error('Please write label name');
  }

  const currentGetOptionSelected = getOptionSelected
    ? getOptionSelected
    : (option, value) => {
        return getOptionLabel(option) === getOptionLabel(value);
      };

  return (
    <AutoComplete
      {...props}
      onChange={(e, value) => {
        value ? setSelectedValue(value[labelName]) : setSelectedValue('');
        onChange(e, value);
      }}
      getOptionLabel={getOptionLabel}
      id={id}
      label={label}
      options={getData() || []}
      value={typeof value === 'object' ? value : singleValue}
      getOptionSelected={currentGetOptionSelected}
      loading={isOptionsLoading}
      inputDefaultValue={initialRequest ? value[labelName] : inputValue}
      onInputChange={(event, newInputValue) => {
        !initialRequest && setInputValue(newInputValue);
        !oneRequest && setIsOptionsLoading(true);
      }}
      renderInput={params => {
        return <TextField {...params} label={label} />;
      }}
    />
  );
};

export default AutoCompleteAsync;
