// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerPHI = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15.247 19.059" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h15.247v19.059H0z" />
    <g transform="translate(-360 -196.941)">
      <path
        d="M-28.992-2.377h-8.576V-5.125A2.93,2.93,0,0,1-34.8-8.093,2.8,2.8,0,0,1-31.948-6a.978.978,0,0,0,.931.764h0a.96.96,0,0,0,.926-1.2A4.708,4.708,0,0,0-34.558-10a4.938,4.938,0,0,0-4.916,4.985v2.637h-.953A1.911,1.911,0,0,0-42.333-.471V7.153a1.911,1.911,0,0,0,1.906,1.906h11.435a1.911,1.911,0,0,0,1.906-1.906V-.471A1.911,1.911,0,0,0-28.992-2.377Zm0,9.529H-40.427V-.471h11.435Z"
        transform="translate(402.333 206.942)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerPHI;
