import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageUnavailableContainer = styled.div`
  height: 100%;

  .page-unavailable {
    width: 268px;
    height: 268px;
    min-height: 268px;
  }

  h3 {
    font-size: 24px;
    margin: 40px 0 14px;
    color: var(--colorBaseDark3);
    text-align: center;
  }
`;

export const PageUnavailableContent = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 20px 16px;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

export const PageUnavailableDescription = styled.p`
  font-size: 16px;
  color: #3f414a;
  text-align: center;
  margin: 0 0 25px;
  width: 100%;
`;

export const AvailableLinksItemWrap = styled.p`
  margin: 0;
  width: 100%;
  text-align: center;
`;

export const AvailableLinksItem = styled(Link)`
  color: var(--colorSystemInfo);
  margin: 15px 0;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
`;

export const LogoutButton = styled(Link)`
  && {
    color: var(--colorBaseLight3);
    background: var(--colorBaseBase);
    max-width: 243px;
    width: 100%;
    font-size: 16px;
    padding: 18px 0;
    text-transform: inherit;
    margin-top: 13px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
  }
`;
