import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const api = process.env.REACT_APP_BASE_URL_19;
const baseURL = `https://${api}.${base}/${env}`;

const basePartner = process.env.REACT_APP_BASE_URL_16;
const basePartnerURL = `https://${basePartner}.${base}/${env}`;

const PartnerApi = {};
const DEFAULT_CONTENT_TYPE = 'application/json';

PartnerApi.getRequestHeaders = (includeAuthorization = true, contentType = DEFAULT_CONTENT_TYPE) => {
  return {
    'Content-Type': contentType,
    ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
  };
};

PartnerApi.getPresignedPost = (mimeType, partner_Id, type = '', serviceLogoMimeType = '') => {
  const requestOptions = {
    url: `${baseURL}/partner/getPreSignedPost/${partner_Id}?${type}`,
    params: { mimeType, serviceLogoMimeType },
    headers: PartnerApi.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

PartnerApi.uploadLogo = (url, formData) => {
  const requestOptions = {
    url,
    headers: PartnerApi.getRequestHeaders(false, 'multipart/form-data'),
    data: formData
  };
  return apiClient.post(requestOptions);
};

PartnerApi.migrateToEnterprise = (partner_Id, bpp_tier_id) => {
  const requestOptions = {
    url: `${baseURL}/partner/EBP/migrate/${partner_Id}`,
    headers: PartnerApi.getRequestHeaders(),
    data: { bpp_tier_id }
  };
  return apiClient.post(requestOptions);
};

PartnerApi.getSraAutoPublishSettings = partner_id => {
  const requestOptions = {
    url: `${baseURL}/partnerProfile/auto-publish-settings/${partner_id}/`,
    headers: PartnerApi.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

PartnerApi.setSraAutoPublishSettings = (partner_id, data) => {
  const requestOptions = {
    url: `${baseURL}/partnerProfile/auto-publish-settings/${partner_id}/`,
    headers: PartnerApi.getRequestHeaders(),
    data
  };
  return apiClient.put(requestOptions);
};

PartnerApi.getCutomMessages = (partner_id, params) => {
  const requestOptions = {
    url: `${basePartnerURL}/partnerProfile/customMessages/${partner_id}`,
    headers: PartnerApi.getRequestHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

// saving welcome message
PartnerApi.setCustomMessage = (partner_id, params, data) => {
  const requestOptions = {
    url: `${basePartnerURL}/partnerProfile/customMessages/${partner_id}`,
    headers: PartnerApi.getRequestHeaders(),
    data,
    params
  };
  return apiClient.post(requestOptions);
};

export default PartnerApi;
