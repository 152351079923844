// @flow
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Avatar as MuiAvatar } from '@trustsecurenow/components-library';
import { styled } from '@trustsecurenow/components-library';

const StyledAvatar = styled(MuiAvatar)(({ theme, size = 50, background }) => ({
  backgroundColor: background,
  width: size,
  height: size,
  [theme.breakpoints.down('sm')]: {
    width: '40px !important',
    height: '40px !important',
  },
}));

const CustomAvatar = ({ background, size, user, children }) => {
  const baseColor = useSelector(useCallback(state => state?.bsn?.user?.theme?.base?.base, []));
  const backgroundColor = user?.avatar_filename ? null : (background || baseColor);

  return (
    <StyledAvatar
      src={user?.avatar_filename ? `/media/avatars/${user?.avatar_filename}` : undefined}
      size={size}
      background={backgroundColor}
    >
      {children}
    </StyledAvatar>
  );
};

export default React.memo(CustomAvatar);
