import React from 'react';
import { AlertCampaignIcon, Avatar, Stack, Typography, styled } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';

const StyledAvatar = styled(Avatar)(({ backgroundColor }) => ({
  backgroundColor,
  width: 80,
  height: 80,
  borderRadius: 12
}));

const AccountDependence = ({ title, description, avatarColor, avatarIconColor, titleColor, descriptionColor }) => {
  return (
    <Stack
      py={11}
      px={3.75}
      maxWidth={455}
      margin="auto"
      spacing={{ xs: 4, md: 3 }}
      alignItems="center"
      textAlign="center"
    >
      <StyledAvatar backgroundColor={avatarColor}>
        <AlertCampaignIcon color={avatarIconColor} sx={{ fontSize: 40 }} />
      </StyledAvatar>
      <Stack spacing={{ xs: 3, md: 2 }}>
        <Typography
          color={titleColor}
          variant="heading"
          fontSize={{ xs: 24, md: 40 }}
          lineHeight={{ xs: '24px', md: '67.2px' }}
        >
          {title}
        </Typography>
        <Typography color={descriptionColor} variant="subtitle1">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

AccountDependence.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  avatarColor: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  descriptionColor: PropTypes.string.isRequired,
  avatarIconColor: PropTypes.string.isRequired
};

export default AccountDependence;
