// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { transitions } from 'conf';
import Base from './Base';

type ContainerTypes = {
  Paper: ComponentType<*>,
  Grid: ComponentType<*>,
  Tab: ComponentType<*>
};

const Container: ContainerTypes = {};

Container.Tab = styled(Box)`
  /* max-width: 1000px; */
`;

Container.Grid = styled(
  ({
    p,
    pt,
    pr,
    pb,
    pl,
    m,
    mt,
    mr,
    mb,
    ml,
    b,
    bt,
    br,
    bb,
    bl,
    alignSelf,
    mobileInvertOrder,
    mobileFlexDirection,
    opacity,
    ...props
  }) => <Grid {...props} />
).attrs(props => ({
  container: !props.item,
  item: !!props.item,
  spacing: props.spacing ? props.spacing : 24
}))`
  && {
    display: ${({ block }) => (block ? 'block' : 'flex')};
    ${({ p }) => p && `padding: calc(${p} * var(--spacing))`};
    ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
    ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
    ${({ pb }) => pb && `padding-bottom: calc(${pb} * var(--spacing))`};
    ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};
    ${({ px }) => px && `padding-left: calc(${px} * var(--spacing)); padding-right: calc(${px} * var(--spacing))`};
    ${({ py }) => py && `padding-top: calc(${py} * var(--spacing)); padding-bottom: calc(${py} * var(--spacing))`};

    ${({ m }) => m && `margin: calc(${m} * var(--spacing))`};
    ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
    ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
    ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
    ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};
    ${({ mx }) => mx && `margin-left: calc(${mx} * var(--spacing)); margin-right: calc(${mx} * var(--spacing))`};
    ${({ my }) => my && `margin-top: calc(${my} * var(--spacing)); margin-bottom: calc(${my} * var(--spacing))`};

    ${({ b }) => b && `border: ${b}px solid var(--borderDefault)`};
    ${({ bt }) => bt && `border-top: ${bt}px solid var(--borderDefault)`};
    ${({ br }) => br && `border-right: ${br}px solid var(--borderDefault)`};
    ${({ bb }) => bb && `border-bottom: ${bb}px solid var(--borderDefault)`};
    ${({ bl }) => bl && `border-left: ${bl}px solid var(--borderDefault)`};

    ${({ mobileInvertOrder }) =>
      mobileInvertOrder &&
      `
      @media (min-width: 0px) and (max-width: 1279px) {
        order: 2;
      }
    `};

    ${({ mobileFlexDirection }) =>
      mobileFlexDirection &&
      `
      flex-direction: column;
      @media (min-width: 0px) and (max-width: 1279px) {
        flex-direction: row;
      }
    `};
    ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
    ${({ opacity }) => opacity && `opacity: ${opacity}`};
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
    ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  }
`;

Container.Paper = styled(
  ({ background, fullWidth, radius, radiusTop, radiusBottom, hidden, justify, alignItems, direction, ...props }) => (
    <Base {...props} />
  )
)`
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ radius }) => radius && `border-radius: calc(${radius} * var(--borderRadius))`};
  ${({ radiusTop }) =>
    radiusTop &&
    `border-radius: calc(${radiusTop} * var(--borderRadius)) calc(${radiusTop} * var(--borderRadius)) 0 0`};
  ${({ radiusBottom }) =>
    radiusBottom &&
    `border-radius: 0 0 calc(${radiusBottom} * var(--borderRadius)) calc(${radiusBottom} * var(--borderRadius))`};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${({ hidden }) =>
    hidden &&
    `
    && {
      @media (min-width: 0px) and (max-width: 1279px) {
        display: none;
      }
    }
  `};
  background-color: ${({ background }) => (background ? `#${background}` : `var(--backgroundPaper)`)};
  box-sizing: border-box;
  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
`;

export default Container;
