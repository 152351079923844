// @flow
import React, { type Element, useState } from 'react';
import { Container, Switch, /*Button,*/ Modal3, CustomTooltip } from 'components';
import { Info, LazyIcon } from 'components/icons';
import { getId } from 'helpers';
import { useLocation } from 'hooks';
import CreateIcon from '@material-ui/icons/Create';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WelcomeMessageStatusButton from 'apps/contentadmin/components/shared/WelcomeMessageStatusButton';

type DirectorySyncSwitchComponentTypes = {
  record: Object,
  enable: boolean,
  dispatch: Object
};

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'var(--colorDefault)',
    boxShadow: '0px 2px 16px 0px #0000001F',
    padding: 16,
    fontSize: 12,
    fontWeight: 400
  },
  arrow: {
    color: 'white'
  }
})(Tooltip);

const DirectorySyncSwitchComponent = ({
  record,
  enable: enableValue,
  dispatch
}: DirectorySyncSwitchComponentTypes): Element<*> => {
  const enable = record.type === 'csvBulkUpload' ? false : enableValue;
  const [open, setOpen] = useState(false);
  const [isWelcomeMsgDialogOpened, setIsWelcomeMsgDialogOpened] = useState(false);
  const [type, setType] = useState(null);
  const history = useHistory();
  const [paramIdValue, setParamIdValue] = useState(null);
  const { app, tab, item } = useLocation();
  const id = item || getId('clientId');
  const user_profile = useSelector(s => s?.bsn?.user?.profile);

  const onClick = (t, paramId) => {
    setOpen(true);
    setType(t);
    setParamIdValue(paramId);
  };

  return (
    <div
      style={{
        // backgroundColor: 'lightgreen',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      <WelcomeMessageStatusButton
        tooltipPlacement="left"
        isWelcomeMsgDialogOpened={isWelcomeMsgDialogOpened}
        setIsWelcomeMsgDialogOpened={setIsWelcomeMsgDialogOpened}
      />

      <Button
        style={{
          textDecoration: 'none',
          textTransform: 'none',
          borderColor: 'var(--colorBase)'
        }}
        onClick={e => {
          history.push({
            pathname: `/${app}/welcomeMessage/${id}`,
            search: `?${Object.entries({
              id,
              test_message_id: id,
              new_welcome_message_seen: '1',
              email: user_profile.email,
              location: `${history.location.pathname}`
            })
              .map(v => v.join('='))
              .join('&')}`
          });
          // onClick('welcomeMessage', 1001);
        }}
        variant="outlined"
      >
        <CreateIcon />
        &nbsp;&nbsp; Edit Welcome Message
      </Button>
      {open && paramIdValue && (
        <Modal3
          open={open}
          type={type}
          paramId={paramIdValue}
          parentId={id}
          setOpen={setOpen}
          app="clients"
          tab="customMessage"
          apiApp="clients"
          apiTab="customMessage"
        />
      )}
    </div>
  );

  return (
    <Container.Grid item justify="flex-end" mt={1} sm={12} xs={12} xl={12} md={12}>
      <Container.Grid direction="row" alignSelf="flex-start" mb={1} ml={3} sm={6} xs={6} xl={6} md={6}>
        <Switch
          label="Send Welcome Message"
          value={record.automatedWelcome}
          checked={record.automatedWelcome}
          name="automatedWelcome"
          id="automatedWelcome"
          disabled={enable}
          onChange={e => dispatch.onSwitch(e.target.name, e.target.checked)}
        />
      </Container.Grid>
      {/* <Container.Grid direction="row" alignSelf="flex-start" mb={1} sm={6} xs={6} xl={6} md={6}>
        <Switch
          label="Use custom message"
          value={record.customWelcome}
          checked={Boolean(record.customWelcome)}
          name="customWelcome"
          id="customWelcome"
          disabled={enable}
          onChange={e => dispatch.onSwitch(e.target.name, e.target.checked)}
        />
      </Container.Grid> */}
      <Container.Grid direction="row" alignSelf="flex-start" mb={1} ml={3} sm={3} xs={3} xl={3} md={3}>
        <Button pr={3} pl={3} onClick={e => onClick('welcomeMessage', 1001)} disabled={!record.customWelcome || enable}>
          <LazyIcon style={{ fontSize: `var(--fontSize)` }} color="var(--colorCommonWhite)" component="Welcome" />
          &nbsp;&nbsp;Welcome Message
        </Button>
      </Container.Grid>
      <Container.Grid direction="row" alignSelf="flex-start" mb={1} ml={3} mr={3} sm={3} xs={3} xl={3} md={3}>
        {/* <Button
          pr={3}
          pl={3}
          onClick={e => onClick('welcomeBackMessage', 1002)}
          disabled={!record.customWelcome || enable}
        >
          <LazyIcon style={{ fontSize: `var(--fontSize)` }} color="var(--colorCommonWhite)" component="Message" />
          &nbsp;&nbsp;Welcome back Message
        </Button> */}
        {open && paramIdValue && (
          <Modal3
            open={open}
            type={type}
            paramId={paramIdValue}
            parentId={id}
            setOpen={setOpen}
            app="clients"
            tab="customMessage"
            apiApp="clients"
            apiTab="customMessage"
          />
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

export default DirectorySyncSwitchComponent;
