import React from 'react';
import { Box, Stack } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';
import RoundedButton from './RoundedButton';

const Header = ({ onContactUsClick, logoPath, title, isWhiteContactUsButton }) => {
  return (
    <Stack flexWrap="wrap" useFlexGap py={2} px={2.5} spacing={1} direction="row">
      <img src={logoPath} alt={title} />
      <Box ml="auto">
        <RoundedButton onClick={onContactUsClick} variant="outlined" size="medium" isWhite={isWhiteContactUsButton}>
          Contact Us
        </RoundedButton>
      </Box>
    </Stack>
  );
};

Header.propTypes = {
  onContactUsClick: PropTypes.func.isRequired,
  logoPath: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  isWhiteContactUsButton: PropTypes.bool
};

Header.defaultProps = {
  isWhiteContactUsButton: false
};

export default Header;
