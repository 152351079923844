// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Hashtag = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24"  role="img" aria-hidden="false">
    <path d="M21.2251 7.516H17.6868L18.4953 2.9236L18.4954 2.92276C18.5741 2.45224 18.2596 1.99946 17.7873 1.9123L17.7873 1.91228L17.7852 1.91194C17.306 1.83381 16.8594 2.14678 16.7719 2.61776L16.7717 2.61872L15.9106 7.516H9.73636L10.5448 2.9236L10.545 2.92276C10.6237 2.45224 10.3092 1.99946 9.83684 1.9123L9.83684 1.91229L9.83508 1.91199C9.36374 1.83372 8.90913 2.14643 8.82152 2.61776L8.82135 2.61872L7.96026 7.516H2.77489C2.29382 7.516 1.9 7.90787 1.9 8.38809C1.9 8.86831 2.29382 9.26018 2.77489 9.26018H7.65383L6.67392 14.8277H2.77489C2.29382 14.8277 1.9 15.2195 1.9 15.6998C1.9 16.18 2.29382 16.5719 2.77489 16.5719H6.3675L5.57455 21.0716L5.57441 21.0724C5.4957 21.543 5.8102 21.9957 6.28255 22.0829L6.28431 22.0832L6.28869 22.0839C6.3319 22.0911 6.38529 22.1 6.43242 22.1C6.85143 22.1 7.21995 21.804 7.29038 21.3759C7.29041 21.3757 7.29045 21.3755 7.29048 21.3753L8.13585 16.5719H14.3102L13.5172 21.0716L13.5171 21.0724C13.4384 21.543 13.7529 21.9957 14.2252 22.0829L14.227 22.0832L14.2313 22.0839C14.2746 22.0911 14.328 22.1 14.3751 22.1C14.7941 22.1 15.1626 21.804 15.233 21.3759C15.2331 21.3757 15.2331 21.3755 15.2331 21.3753L16.0785 16.5719H21.2096C21.6907 16.5719 22.0845 16.18 22.0845 15.6998C22.0845 15.2222 21.7088 14.8277 21.2251 14.8277H16.4005L17.3804 9.26018H21.2251C21.7062 9.26018 22.1 8.86831 22.1 8.38809C22.1 7.90787 21.7062 7.516 21.2251 7.516ZM14.6166 14.8277H8.4423L9.4222 9.26018H15.5965L14.6166 14.8277Z" />
  </SvgIconStyled>
);

export default Hashtag;
