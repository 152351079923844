// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const OtherPolicy = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21.17 21.17" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h21.17v21.17H0z" />
    <g>
      <path d="M17.924,4.622,10.32.238A1.807,1.807,0,0,0,9.421,0a1.889,1.889,0,0,0-.909.238L.9,4.64A1.764,1.764,0,0,0,0,6.183a17.045,17.045,0,0,0,8.512,14.74,1.807,1.807,0,0,0,.909.247,1.752,1.752,0,0,0,.9-.247,15.969,15.969,0,0,0,2.249-1.561,10.994,10.994,0,0,1-1.111-1.376,14.369,14.369,0,0,1-2.038,1.4,15.236,15.236,0,0,1-7.63-13.17V6.183l7.63-4.4,7.595,4.384a.073.073,0,0,1,.035.053,14.443,14.443,0,0,1-.185,2.32l1.658.953a16.8,16.8,0,0,0,.318-3.273A1.855,1.855,0,0,0,17.924,4.622Z" />
      <path
        d="M16.924,9.49l-5.143,2.946-.441.291v.5a10.188,10.188,0,0,0,5.134,8.8l.45.256.45-.256a10.212,10.212,0,0,0,5.134-8.839v-.5Zm0,10.761a8.459,8.459,0,0,1-3.8-6.563l3.8-2.179,3.8,2.179A8.445,8.445,0,0,1,16.924,20.251Z"
        transform="translate(-1.337 -1.119)"
      />
      <path
        d="M16.815,18.736a.854.854,0,0,1-.609-.247L14.654,17a.88.88,0,0,1,1.217-1.27l.917.882,2.267-2.346a.88.88,0,1,1,1.27,1.217L17.45,18.462A.869.869,0,0,1,16.815,18.736Z"
        transform="translate(-1.696 -1.65)"
      />
    </g>
  </SvgIconStyled>
);

export default OtherPolicy;
