import { useNotify, useRedirect } from 'react-admin';
import { useSelector } from 'react-redux';
import { getActiveTab, setStorage, removeStorage, useApp } from 'helpers';

const useMaskPartner = () => {
  const { dispatch } = useApp();
  const redirect = useRedirect();
  const notify = useNotify();

  const {
    tabs,
    system: { tabCurrent }
  } = useSelector(rxState => rxState.bsn);

  const enableMaskMode = (data, filters) => {
    setStorage('maskMode', true, true);
    setStorage('maskPartnerId', data.id, true);
    setStorage('maskPartnerName', data.name, true);
    setStorage('maskPartnerDistributor', data.distributor, true);
    // Key to show loading after enable or disable mask partner
    setStorage('showLoading', true, true);

    let path = '/clients'
    dispatch.changeApp('clients', 'admin', getActiveTab(tabs['clients']), tabCurrent);

    if (filters.user && data.client_id) {
      const filter = encodeURIComponent(JSON.stringify({ user: filters.user }))
      path = `/clients/users/${data.client_id}?filter=${filter}`
    }
    
    if (filters.client && data.client_name) {
      const filter = encodeURIComponent(JSON.stringify({ name: data.client_name }))
      path = `/clients?filter=${filter}`
    }

    redirect(path);
  };

  const cleanMaskModeStorage = () => {
    removeStorage('maskMode', true);
    removeStorage('maskPartnerId', true);
    removeStorage('maskPartnerName', true);
    removeStorage('maskPartnerDistributor', true);
    // Key to show loading after enable or disable mask partner
    setStorage('showLoading', true, true);
    notify('Mask mode disabled', 'info');
  };

  const returnToAdmin = () => {
    dispatch.changeApp('admin', 'clients', 'dashboard', tabCurrent);
    cleanMaskModeStorage();
    redirect('/admin/dashboard/manage-partners');
  };

  return {
    enableMaskMode,
    cleanMaskModeStorage,
    returnToAdmin
  };
};

export default useMaskPartner;