import React from 'react';
import { Container, CustomTable, LazyIcon, TableCellField } from 'components';
import { useAdminList } from '../../hooks';
import { TableCellText } from '../../style/AdminCommon';
import { cleanTextFromHtml } from 'helpers';
import PoliciesToolbar from './PoliciesToolbar';
import { IconButton } from '@material-ui/core';

const Policies = () => {
  const { isLoading, isFetching, data, dispatch, total } = useAdminList('policies');

  return (
    <>
      <CustomTable
        toolbar={<PoliciesToolbar setParam={dispatch.setNewParam} navigateToCreate={() => {}} />}
        source={'admin/policies'}
        total={total}
        data={data}
        loading={isLoading}
        isFetching={isFetching}
        onSort={sortValue => dispatch.setSort(sortValue)}
        onChangePage={page => dispatch.setPage(page)}
        onChangePerPage={perPage => dispatch.setPerPage(perPage)}
        onLoad={params => dispatch.onLoad(params)}
        preferences={{
          app: 'admin',
          tab: 'policies'
        }}
      >
        <TableCellField renderComponent={({ name }) => <TableCellText>{name}</TableCellText>} />

        <TableCellField
          renderComponent={({ description }) => <TableCellText>{cleanTextFromHtml(description)}</TableCellText>}
        />

        <TableCellField renderComponent={({ created }) => <TableCellText>{created}</TableCellText>} />

        <TableCellField renderComponent={({ modified }) => <TableCellText>{modified}</TableCellText>} />

        <TableCellField
          disableRowClick
          renderComponent={({ attachment_link }) => (
            <Container.Grid alignItems="center" justifyContent="center">
              <IconButton onClick={() => window.open(attachment_link)}>
                <LazyIcon component="Download" color="colorDarkGrey" size={1} />
              </IconButton>
            </Container.Grid>
          )}
        />
      </CustomTable>
    </>
  );
};

export default Policies;
