// @flow

import React, { memo } from 'react';
import { Checkbox, Radio } from '@material-ui/core';
import { hasKey } from 'helpers';
import { TableCellContent } from '../ComponentTypes';
import { useTableList } from '../TableListContext';

type TableListSelectingTypes = {
  row: Object,
  index: number,
  isItemSelected: boolean
};

const TableListSelecting = ({ row, index, isItemSelected }: TableListSelectingTypes) => {
  const {
    settings,
    dispatch: { handleClick, handleRadio }
  } = useTableList();
  const labelId = `enhanced-table-checkbox-${index}`;
  const { selecting } = settings;
  if (!selecting) return null;
  return (
    <TableCellContent>
      {hasKey(settings, 'selectingRadio') && settings.selectingRadio ? (
        <Radio
          checked={isItemSelected}
          onClick={event => handleRadio(event, row.id)}
          inputProps={{ 'aria-labelledby': labelId }}
          name="tableSelect"
          value={row.id}
        />
      ) : (
        <Checkbox
          checked={isItemSelected}
          onClick={event => handleClick(event, row.id, row)}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      )}
    </TableCellContent>
  );
};

export default memo<TableListSelectingTypes>(TableListSelecting);
