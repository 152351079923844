// @flow

import { useApp } from 'helpers';
import { useSelector } from 'react-redux';
import useDataGet from './useDataGet';

type useTabDataType = {
  app: string,
  tab: string | false,
  item?: string | false,
  params?: string,
  enabled?: boolean,
  queryOptions?: Object
};

function useTabData({
  app,
  tab: tabTab,
  item,
  params = {},
  enabled: tabEnabled = true,
  queryOptions = {}
}: useTabDataType) {
  const { dispatch } = useApp();
  let enabled = tabEnabled;
  const UseAccess = ({ app: tabApp }) =>
    useSelector(state => {
      const isTab = tabTab !== false;
      let accessTab = tabTab;
      if (!isTab) {
        const accessObject = state.bsn.user?.access?.apps[tabApp] || {};
        const accessArray = Object.keys(accessObject).filter(a => accessObject[a]);
        accessTab = accessArray.includes(accessTab) ? accessTab : accessArray[0];
      }
      return accessTab;
    });
  const tab = UseAccess({ app });
  enabled = tab ? enabled : false;
  const options = {
    ...queryOptions,
    enabled,
    onSuccess: ({ data }) => {
      dispatch.set(app, tab, data);
    }
  };

  const isOldUsersCall = app === 'clients' && tab === 'users';

  const data = useDataGet({
    app,
    tab: isOldUsersCall ? 'users/mfa_and_strong_password_status' : tab,
    item,
    params,
    options
  });

  return data;
}

export default useTabData;
