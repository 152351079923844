// @flow

const otherPolicyInfo = {
  title: 'Other Policy Information',
  component: 'clients/ClientsEditOtherPolicyInfoModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel',
      clear: true
    },
    submit: {
      icon: 'Done',
      label: 'Update',
      uploadType: 'other_policy'
    },
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: null
};

export default otherPolicyInfo;
