// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DarkWeb = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 13 13" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h13v13H0z" />
    <path d="M10.833,13H2.166A2.169,2.169,0,0,1,0,10.833V2.166A2.169,2.169,0,0,1,2.166,0h8.667A2.169,2.169,0,0,1,13,2.166v8.667A2.169,2.169,0,0,1,10.833,13ZM1.445,5.778v5.055a.723.723,0,0,0,.722.723h8.667a.724.724,0,0,0,.723-.723V5.778ZM6.5,2.166a.722.722,0,1,0,.723.723A.723.723,0,0,0,6.5,2.166Zm-2.889,0a.722.722,0,1,0,.723.723A.723.723,0,0,0,3.611,2.166Z" />
  </SvgIconStyled>
);

export default DarkWeb;
