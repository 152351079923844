// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Save = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 12 12" role="img" aria-hidden="false">
    <path
      id="icon"
      d="M40.665,30.665l-1.33-1.33A1.143,1.143,0,0,0,38.527,29H30.143A1.143,1.143,0,0,0,29,30.143v9.714A1.143,1.143,0,0,0,30.143,41h9.714A1.143,1.143,0,0,0,41,39.857V31.473A1.143,1.143,0,0,0,40.665,30.665ZM33,30.429a.286.286,0,0,1,.286-.286h3.429a.286.286,0,0,1,.286.286v1.429a.286.286,0,0,1-.286.286H33.286A.286.286,0,0,1,33,31.857Zm6.857,9.143a.286.286,0,0,1-.286.286H30.429a.286.286,0,0,1-.286-.286V30.429a.286.286,0,0,1,.286-.286h1.143a.286.286,0,0,1,.286.286v2a.857.857,0,0,0,.857.857h4.571a.857.857,0,0,0,.857-.857V30.335a.192.192,0,0,1,.192-.192h.073a.285.285,0,0,1,.2.084l1.163,1.163a.285.285,0,0,1,.084.2Z"
      transform="translate(-29 -29)"
      fill="#fff"
    />
  </SvgIconStyled>
);

export default Save;
