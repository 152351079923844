// @flow
import { useSelector } from 'react-redux';

function useRecord(app: string, tab?: string, item?: string, key?: string, filterKey?: string): Object | null {
  const state = useSelector(rx => {
    if (app && tab && item && key && filterKey)
      return rx.bsn?.[app]?.[tab]?.[item].filter(data => data[filterKey] === key)[0];
    if (app && tab && item && key) return rx.bsn?.[app]?.[tab]?.[item]?.[key];
    if (app && tab && item) return rx.bsn?.[app]?.[tab]?.[item];
    if (app && tab) return rx.bsn?.[app]?.[tab];
    return rx.bsn[app];
  });
  return state;
}

export default useRecord;
