import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const CloudUploadIcon = (props) => {
  return (
    <SvgIconStyled { ...props } width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1.41602C23.1591 1.41602 28.2498 5.97925 28.8565 11.8086C31.7843 12.4021 34 15.0529 34 18.2314C34 21.8582 31.1381 24.7993 27.6168 24.7993C26.8914 24.7993 26.2979 24.2058 26.2979 23.4804C26.2979 22.755 26.8914 22.1616 27.6168 22.1616C29.6742 22.1616 31.3623 20.3943 31.3623 18.2314C31.3623 16.0816 29.6874 14.3276 27.6168 14.3276C27.2343 14.3276 26.9046 14.1693 26.6672 13.9187C26.4298 13.6813 26.2715 13.3516 26.2715 12.9824C26.2715 8.06304 22.104 4.0669 16.9868 4.0669C11.8961 4.0669 7.75486 8.06304 7.75486 12.9824C7.75486 13.5363 7.41195 13.9979 6.92397 14.1957C6.75252 14.2616 6.58107 14.3144 6.38324 14.3144C4.32583 14.3144 2.6377 16.0816 2.6377 18.2446C2.6377 20.3943 4.31264 22.1484 6.38324 22.1484C7.10861 22.1484 7.70209 22.7419 7.70209 23.4672C7.70209 24.1926 7.10861 24.7861 6.38324 24.7861C2.8619 24.7861 0 21.8582 0 18.2446C0 15.0529 2.24204 12.3889 5.18308 11.8086C5.78975 5.97925 10.8542 1.41602 17 1.41602ZM17.9363 19.0227L18.8727 19.9591L23.093 24.1794C23.6074 24.6938 23.6074 25.5378 23.093 26.0522C22.5787 26.5665 21.7346 26.5665 21.2202 26.0522L18.3188 23.1507V32.4354C18.3188 33.1608 17.7253 33.7543 16.9999 33.7543C16.2746 33.7543 15.6811 33.1608 15.6811 32.4354V23.1507L12.7796 26.0522C12.2652 26.5665 11.4211 26.5665 10.9068 26.0522C10.6562 25.7884 10.5244 25.4587 10.5244 25.1158C10.5244 24.7729 10.6562 24.4432 10.9068 24.1794L15.1271 19.9591L16.0635 19.0227C16.5779 18.5084 17.4219 18.5084 17.9363 19.0227Z" fill="#0069FF"/>
    </SvgIconStyled>
  );
};

export default CloudUploadIcon;
