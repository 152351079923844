import React from 'react';
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import { cleanTextFromHtml, getCookie } from 'helpers';
import { Users2, Users3, Monitor, Email2 } from 'components/icons';
import { AUDIENCE_OPTIONS, ROLE_OPTIONS, ASSET_COOKIES, AUDIENCES } from './constants';

// Map html string to detect hashtags that are created by user (not selected from existing list)
// and change them to match the Quill editor structure
export const formatHashtagsInElement = string => {
  // optimizing looping over post text in case of it doesn't contain hashtags
  if (!string.includes('#')) {
    return string;
  }
  const textFormatted = string.split(' ').map(item => {
    const cleanText = cleanTextFromHtml(item);
    if (cleanText.charAt(0) === '#' && cleanText.length > 1) {
      const hashtagList = cleanText.match(/#[a-z0-9_]+/gi);
      if (hashtagList?.length) {
        const hashtagListCoverted = hashtagList.map(hashtagItem => {
          if (hashtagItem.match(/#[0-9]\d*\b/g)) return hashtagItem;
          return `<span class="mention" data-denotation-char data-value="${hashtagItem}"><span contenteditable="false"><span class="ql-mention-denotation-char"></span>${hashtagItem}</span></span>`;
        });
        const oldString = hashtagList.join('');
        const newString = hashtagListCoverted.join('');
        const replacedItem = item.replace(oldString, newString);
        return replacedItem;
      } else {
        return item;
      }
    } else {
      return item;
    }
  });
  return textFormatted.join(' ');
};

export const formatHashtags = string => {
  const document = new DOMParser().parseFromString(string, 'text/html');
  const elements = [...document.body.childNodes].map(child => {
    const element = child.outerHTML || child.textContent;
    return formatHashtagsInElement(element);
  });
  return elements.join('');
};

// Remove empty hashtag or mention html tags from html string (to fix RichTextEditor issue)
export const removeEmptyHashtagAndMention = htmlString => {
  // Parse html string to detect if hashtag or mention html structure is empty (it was removed by user) and to remove it
  const cleanHtmlElement = parse(htmlString, {
    replace: domNode => {
      if (
        domNode.attribs &&
        domNode.attribs['data-value'] &&
        domNode?.attribs?.class === 'mention' &&
        !domNode.children.find(child => child.type === 'tag')
      ) {
        return <></>;
      }
    }
  });

  // Convert back to html string
  const convertedHtmlString = ReactDOMServer.renderToStaticMarkup(cleanHtmlElement);

  return convertedHtmlString;
};

export const getFilePreview = file => {
  switch (file.type) {
    case 'image':
      return { preview: file.data.url, type: file.type };
    case 'video':
      return { preview: file.data.url, poster: file.data.video_thumbnail, type: file.type };
    default:
      return { preview: file.data.filename, type: 'data' };
      break;
  }
};

export const getAvatar = name => {
  return name
    ? name
        .split(' ')
        .map((n, i) => (i < 2 ? n[0] : null))
        .join('')
    : name;
};

export const getAudiencePreview = data => {
  if (data.included_clients?.length > 0) {
    const icon = AUDIENCE_OPTIONS.find(item => item.name === AUDIENCES.clients).icon;
    const text = `Clients (${data.included_clients?.length})`;
    return { icon, text, tooltip: text };
  } else if (data.audience) {
    const item = AUDIENCE_OPTIONS.find(item => item.name === data.audience.name);
    return { icon: item.icon, text: item.name, tooltip: item.tooltip };
  } else {
    return { icon: <Users2 />, text: 'Select Audience' };
  }
};

export const getRolePreview = data => {
  if (data.allowed_groups?.length > 0) {
    const icon = AUDIENCE_OPTIONS.find(item => item.name === 'Roles').icon;
    const text = ROLE_OPTIONS.filter(item => data.allowed_groups.includes(item.id))
      .map(item => item.abbr)
      .join(', ');
    return { icon, text: `Roles - ${text}` };
  } else {
    return { icon: <Users3 />, text: 'Select Role' };
  }
};

export const getAudienceAndRolePreview = data => {
  let audienceData = getAudiencePreview(data);
  let roleData;
  if (data.allowed_groups?.length > 0) {
    roleData = getRolePreview(data);
  }
  return { icon: audienceData.icon, text: audienceData.tooltip + (roleData ? ` and ${roleData.text}` : '') };
};

export const getAudienceByName = name => {
  return AUDIENCE_OPTIONS.find(item => item.name === name);
};

export const getAudienceById = (audiences, id) => {
  const item = audiences.find(item => item?.id === id);
  return { id, name: item.name };
};

export const getRoleByName = name => {
  return ROLE_OPTIONS.find(item => item.value === name);
};

export const getRoleByid = id => {
  return ROLE_OPTIONS.find(item => item.id === id);
};

export const getClientsDescription = clients => {
  const num = clients.length;
  return `You added ${num} client${num === 1 ? '' : 's'} on the list`;
};

export const getAssetUrl = url => {
  let params = '';
  const isLocalFile = url?.startsWith('blob:');
  // Add cookies as params to the url
  if (!isLocalFile) {
    ASSET_COOKIES.map((cookieName, index) => {
      const symbol = index === 0 ? '?' : '&';
      const key = cookieName.replace('CloudFront-', '');
      const value = getCookie(cookieName);
      params += `${symbol}${key}=${value}`;
    });
  }

  return `${url}${params}`;
};

export const getNotifyAudience = data => {
  if (data.email_notifications) {
    return { icon: <Email2 />, value: 'email_notifications' };
  }
  if (data.push_notifications) {
    return { icon: <Monitor />, value: 'push_notifications' };
  }
  return { icon: null, value: false };
};
