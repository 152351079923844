// @flow
import React, { createContext, useContext } from 'react';

type Props = {
  children: Element<any>,
  value: any,
};

const TableContext = createContext(null);

export const TableContextProvider = ({ children, value }: Props) => {
  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => {
  return useContext(TableContext);
};
