import React from 'react';
import { Avatar, CloseIcon, Dialog, IconButton, HappyIcon, Stack, Typography } from '@trustsecurenow/components-library';

const SuccessModal = ({ onClose, config }) => {
  return (
    <Dialog open={config?.open} onClose={onClose}>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" p={2}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      
      <Stack direction="column" alignItems="center" spacing={3} justifyContent="center" p={8} px={10}>
        <Avatar sx={{ width: 70, height: 70, backgroundColor: 'rgba(12, 206, 107, 0.2)' }}>
          <HappyIcon color="success" sx={{ fontSize: 48 }} />
        </Avatar>

        <Typography variant="h2" color="success.main" align="center" sx={{ width: '270px' }}>
          {config?.title}
        </Typography>

        <Typography>{config?.message}</Typography>
      </Stack>
    </Dialog>
  );
};

export default SuccessModal;