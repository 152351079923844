import React, { useState } from 'react';
import { TextFieldStyled, CustomLabelStyled, InputWrapper } from './ComponentTypes';
import Controller from './Controller';
import { isNumber } from 'helpers';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

const CustomLabel = ({customLabel}) => {

  const {label, position = 'left right'} = customLabel

  const positionObj = {}
  const positionArr = position.split(" ")
  const filteredPositionArr = new Set(positionArr)

  if (filteredPositionArr.size === 1) {
    if (filteredPositionArr.has('left') || filteredPositionArr.has('right')) {
      filteredPositionArr.add("top")
    } else {
      filteredPositionArr.add("left")
    }
  }

  if (
    (filteredPositionArr.has('left') && filteredPositionArr.has('right'))
    ||
    (filteredPositionArr.has("top") && filteredPositionArr.has("bottom"))
  ) {
    throw new Error("Text field position values not set correctly")
  }

  for (const val of filteredPositionArr) {
    switch (val) {
      case "left":
        positionObj.left = true;
        break;
      case "right":
        positionObj.right = true;
        break;
      case "bottom":
        positionObj.bottom = true;
        break;
      case "top":
        positionObj.top = true
    }
  }

  return (
    <CustomLabelStyled positionObj={positionObj}>
      {label}
    </CustomLabelStyled>
  )
}

const Input = ({ autoFocus, variant, integerOnly = false, enableEnterKey = false, required, customLabel, ...props }) => {
  const textFieldOnKeyPress = e => {
    if ((integerOnly && !isNumber(e.key)) || (!enableEnterKey && e.key === 'Enter')) {
      e.preventDefault();
    }
  };

  const variantValue = variant ? 'standard' : 'outlined';

  return (
    <InputWrapper>
      {customLabel && <CustomLabel customLabel={customLabel}/>}
      <TextFieldStyled
        {...props}
        required={required}
        label={props.label && `${props.label}`}
        classes={{ root: `variant-${variantValue}` }}
        margin="none"
        width={1}
        InputLabelProps={{ classes: { focused: 'field-focused' }, autoFocus }}
        variant={variantValue}
        onKeyPress={textFieldOnKeyPress}
      />
    </InputWrapper>
  );
};

const TextField = ({ name, required, rules, type, onChange, ...props }) => {
  const [text, setText] = useState('');

  let formContext = {};

  // when we put TextField without using Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { setValue } = formContext;

  const handleChange = (RHFOnChange, e) => {
    const { value } = e.target;
    if (onChange) {
      return onChange(e);
    } else {
      type === 'number' && value ? setValue(name, +value) : RHFOnChange(e);
    }
  };

  if (!formContextData) {
    return (
      <Input
        value={text}
        {...props}
        required={required}
        type={type}
        onChange={e => {
          const { value } = e.target;
          setText(value);
          onChange(e);
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field, formState: { errors } }, error) => {
        const isError = error || get(errors, name);
        return (
          <Input
            {...props}
            {...field}
            type={type}
            onChange={e => handleChange(field.onChange, e)}
            error={isError || props.error}
            helperText={isError?.message || props.helperText}
          />
        );
      }}
    />
  );
};

export default TextField;
