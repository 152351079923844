import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Link, Box, makeStyles } from '@material-ui/core';
import { LazyIcon, Reset } from 'components';
import Button from '@material-ui/core/Button';
import { LockedContent } from 'components/common/ContentModifiers';
import { useDirectorySync } from '../DirectorySyncContext';

const useStyles = makeStyles(() => ({
  resetButton: {
    textTransform: 'none',
    fontSize: 'inherit',
    fontWeight: 'normal',
    padding: 0,
    margin: 0
  },
  content: {
    minWidth: '600px',
    minHeight: '300px',
    padding: '30px 20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  cancelBtn: {
    color: 'var(--colorDefault)',
    textDecoration: 'underline'
  },
  submitBtn: {
    backgroundColor: ({ disabledSubmit }) => `${disabledSubmit ? '#ffa6aa' : '#FF5A61'} !important`,
    color: '#fff !important',
    textTransform: 'none',
    fontWeight: 'normal'
  },
  resetIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '35px'
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    fontSize: 20
  }
}));

const ResetDirectoryModal = props => {
  const { open, onReset, onCancel, onOpen, loading } = props;
  const classes = useStyles();
  // const { dispatch } = useDirectorySync();
  // const handleClose = () => {
  //   setButtonsStatus(oldButtonsStatus => ({
  //     ...oldButtonsStatus,
  //     directory_sync_enabled: false
  //   }));
  //
  //   dispatch.onChangeSyncType({ target: { value: 'CsvBulkUpload' } });
  // };

  return (
    <div>
      <Button className={classes.resetButton} onClick={onOpen}>
        <Reset />
        &nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Reset Settings</span>
      </Button>
      <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={open}>
        <div className={classes.content}>
          <Box pb={2.5}>
            <Reset className={classes.resetIcon} />
          </Box>
          <Typography
            className={classes.title}
            variant="h4"
            display="block"
            gutterBottom
            style={{ color: '#FF5A61' }}
            success={false}
          >
            Reset directory?
          </Typography>
          <Typography display="block" gutterBottom>
            This information will be reset and cannot be recovered.
          </Typography>
          <Box pb={2.5}>
            <Typography display="block" gutterBottom>
              Are you sure about that?
            </Typography>
          </Box>
          <>
            <LockedContent locked={loading} opacity="0.5">
              <Box pb={2.5}>
                <Button variant="contained" className={classes.submitBtn} onClick={onReset} disabled={false}>
                  Yes, I want to reset it
                </Button>
              </Box>
              <Box>
                <Link
                  className={classes.cancelBtn}
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    onCancel();
                  }}
                  color="inherit"
                >
                  Cancel
                </Link>
              </Box>
            </LockedContent>
          </>
        </div>
      </Dialog>
    </div>
  );
};

export default ResetDirectoryModal;
