import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Tag = (props) => {
  return (
    <SvgIconStyled { ...props } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0731 1.92778C22.0731 1.92778 19.1447 1.83598 16.6019 1.83598C14.7659 1.83598 13.141 1.88188 12.9758 2.03794L2.12515 12.8152C1.73959 13.2007 1.7304 13.8158 2.11596 14.2013L9.6802 21.8207C9.87298 22.0134 10.13 22.1144 10.3779 22.1144C10.6257 22.1144 10.8736 22.0226 11.0664 21.8298L21.9171 11.0526C22.3027 10.6671 22.0731 1.92778 22.0731 1.92778ZM11.6815 0.743574C12.2873 0.137699 12.8106 0 16.6019 0C19.1356 0 22.1007 0.0917992 22.1374 0.100979C23.1105 0.137699 23.8907 0.917992 23.9183 1.89106C24.1659 11.4201 23.8271 11.7588 23.2223 12.3636L23.2206 12.3654L12.37 23.1426C11.8375 23.6658 11.1398 23.9596 10.3871 23.9596C9.62514 23.9596 8.91829 23.6567 8.38586 23.1242L0.821613 15.5049C-0.279978 14.4033 -0.270789 12.6132 0.830802 11.5208L11.6815 0.743574ZM15.1514 8.8219C15.7572 9.42777 16.5559 9.73071 17.3546 9.73071C18.1716 9.73071 18.9977 9.41859 19.6403 8.78518C20.2462 8.1793 20.5859 7.38983 20.6043 6.54528C20.6226 5.69154 20.3106 4.89289 19.7139 4.2962C19.1172 3.6995 18.3001 3.36902 17.4647 3.38738C16.6293 3.39656 15.8307 3.73622 15.2249 4.33292C13.9672 5.58138 13.9304 7.60097 15.1514 8.8219ZM17.4923 5.22337H17.5107C17.8503 5.22337 18.1716 5.36107 18.4103 5.59056C18.6489 5.82924 18.7775 6.15972 18.7683 6.50856C18.7591 6.87575 18.6123 7.21541 18.3461 7.48163C17.8044 8.01406 16.9598 8.03242 16.4549 7.52753C15.95 7.02263 15.9776 6.1689 16.5193 5.63646C16.7855 5.37943 17.1251 5.23255 17.4923 5.22337Z" fill="white"/>
    </SvgIconStyled>
  );
};

export default Tag;
