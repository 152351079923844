// @flow

const workPlanFinding = {
  type: 'workPlanFinding',
  selecting: true,
  selectAll: true,
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'myCompany',
      tab: 'workPlanFindingInfo'
    }
  },
  cells: [
    { id: 'finding', label: 'Finding', align: 'left', removeHtml: true },
    { id: 'recommendation', label: 'Recommendation', align: 'left', removeHtml: true },
    { id: 'estimation_date', label: 'Estimated Date', align: 'left' },
    { id: 'completed_date', label: 'Date Completed', align: 'left' },
    { id: 'is_completed', label: 'Completed', align: 'left'},
    { id: 'notes', label: 'Notes', align: 'left' }
  ]
};

export default workPlanFinding;
