const firebaseConfigs = new URLSearchParams(process.env.FIREBASE_CONFIGS).toString();

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`firebase-messaging-sw.js?${firebaseConfigs}`)
      .then(function(registration) {
        // eslint-disable-next-line no-console
        console.log('[SW]: SCOPE: ', registration.scope);
        return registration.scope;
      })
      .catch(function(err) {
        return err;
      });
  }
};
