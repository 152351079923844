import React, { useState, useEffect } from 'react';
import { Box, InputAdornment } from '@material-ui/core';
import { AdvancedFilter } from 'components';
import { Plus, Search, Filter } from 'components/icons';
import { useDebounce } from 'hooks';
import { ButtonBaseFilled, ButtonOutline, SearchField } from '../../../style';
import { getRecommendationSections, getRecommendationSubsections, getRecommendationPriorities } from '../../../utils';

function getfiltersConfig(isHSN) {
  return {
    section: {
      label: 'Section',
      icon: false,
      hasSearch: false,
      width: '175px',
      choices: getRecommendationSections()
    },
    subsection: {
      label: 'Subsection',
      icon: false,
      hasSearch: false,
      width: '235px',
      choices: getRecommendationSubsections(isHSN)
    },
    ...(isHSN && {
      priority: {
        label: 'Priority',
        icon: false,
        hasSearch: false,
        width: '175px',
        choices: getRecommendationPriorities()
      }
    })
  };
}

const Toolbar = ({ setSearchValue, setFilters, setOpenAddEditModal, isHSN }) => {
  const [value, setValue] = useState('');
  const [searchDebounceValue] = useDebounce({ value, delay: 500 });

  useEffect(() => {
    setSearchValue(searchDebounceValue.value);
  }, [searchDebounceValue]);

  const handleFilter = (name, val) => {
    setFilters(prev => {
      if (val) {
        return { ...prev, [name]: new Array(val) };
      }

      const newFilters = { ...prev };
      delete newFilters[name];
      return { ...newFilters };
    });
  };

  const openNewRecommendationDialog = () => {
    setOpenAddEditModal(true);
  };

  return (
    <Box mb={4.5} display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" flexGrow={1}>
        <AdvancedFilter
          searchComponent={
            <SearchField
              placeholder="Search Recommendations"
              onChange={e => setValue(e.target.value)}
              value={value}
              fullWidth
              size="small"
              name="search-recommendation"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          }
          filterBtnComponent={
            <ButtonOutline variant="outlined" ml="24px" startIcon={<Filter mr={1.2} size={1.3} />} disableElevation>
              Filter
            </ButtonOutline>
          }
          filtersConfig={getfiltersConfig(isHSN)}
          setFilters={handleFilter}
          customGrid
        />
      </Box>

      <Box flexShrink={0} my={1}>
        <ButtonBaseFilled
          variant="outlined"
          disableElevation
          startIcon={<Plus mr={1.2} size={1.3} />}
          onClick={openNewRecommendationDialog}
        >
          New Recommendation
        </ButtonBaseFilled>
      </Box>
    </Box>
  );
};

export default Toolbar;
