import React, { useState } from 'react';
import { useHistory } from "react-router-dom"
import { CardPageLayoutBoxCard, CardPageLayoutBoxContainer } from '../../style';
import { ElementActions } from '../shared';
import { getContentAdminPath } from 'helpers';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { useCardPageContext } from '../../context';
import { CONTENT_ADMIN_ROOT_PATH } from '../../constants';
import { Dialog } from '../../../../components';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const CardPageLayoutBox = ({id, children, isFetching, status}) => {
  const history = useHistory()
  const {dispatch, pagePath, pagination} = useCardPageContext()
  const path = getContentAdminPath(pagePath)


  const [modal, setModal] = useState({open: false, id: null})
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${pagePath}/edit/${id}`)
  }

  const handleDelete = async () => {
    setLoading(true);

    try {
      await contentAdmin.deleteItem(path, id);
      enqueueAlertSnackbar('The Item Was Deleted', { props: { severity: 'success' } });
      const record = await dispatch.refetch();
      if (record?.data?.data?.count > 0 && record?.data?.data?.result.length <= 0) {
        if (pagination.page > 1) {
          dispatch.onChange("page", pagination.page - 1)
        }
      }
    } catch (e) {
      enqueueAlertSnackbar(e?.response?.data?.message, { props: { severity: 'error' } });
    } finally {
      setLoading(false);
    }

    setModal({open: false, id: null})

  }

  return (
    <CardPageLayoutBoxContainer sm={4} item>
      <CardPageLayoutBoxCard isFetching={isFetching}>
        {children}
        <ElementActions
          itemId={id}
          onEdit={handleEdit}
          onDelete={(id) => setModal({open: true, id})}
          status={status}
        />
      </CardPageLayoutBoxCard>

      <Dialog
        title="Delete"
        message="This information will be deleted. Please confirm if you would like to delete?"
        buttonSubmit="Yes, I want to delete"
        open={modal.open}
        setOpen={() => setModal({open: false, id: null})}
        maxWidth="xs"
        onSubmit={() => handleDelete(modal.id)}
        disabledSubmit={loading}
      />

    </CardPageLayoutBoxContainer>
  )
}

export default CardPageLayoutBox
