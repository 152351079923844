import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { LoadingStyled } from 'components';
import { useLocation } from 'hooks';
import { Card } from '../shared';
import VideoModal from './VideoModal';
import { usePartnerChecklist } from '../hooks';
import { PAGES } from '../constants';

const LearnMore = () => {
  const { app } = useLocation();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const hasAccess = accessApps?.hasOwnProperty(app);
  const history = useHistory();
  const { page } = useParams();

  useEffect(() => {
    if (page !== 'learnMore') {
      history.push(PAGES.getStarted.path);
    }
  }, [page]);

  if (accessApps && !hasAccess) window.location.href = '/#/myDashboard/dashboard';

  const aboutVideoStorage = sessionStorage.getItem('autoplayVideo') || '';
  const autoplay = !!aboutVideoStorage;
  const [openVideoModal, setOpenVideoModal] = useState(autoplay);
  const { loading, aboutVideo } = usePartnerChecklist();

  const onCloseVideo = () => {
    sessionStorage.removeItem('autoplayVideo');
    setOpenVideoModal(false);
    history.push(PAGES.getStarted.path) // will back to 'getStarted/dashboard' when exit out of the video
  };

  if (accessApps && !hasAccess) return null;

  if (!accessApps) return <LoadingStyled centerAlign />;

  return (
    <>
      <Box display="flex" flexWrap="wrap" mt={5}>
        <Box mr={3.5} mb={2}>
          <Card
            imgSrc="/media/cardImage.svg"
            imgAlt="About BSN"
            title="About Breach Secure Now"
            infoTooltip="Watch our short Partner welcome video to learn more about your new subscription and the tools and resources at your disposal."
            description="For more information click the info icon above"
            btnTitle="Watch"
            onClick={() => setOpenVideoModal(true)}
          />
        </Box>
      </Box>

      <VideoModal
        open={openVideoModal}
        close={onCloseVideo}
        title="About Breach Secure Now"
        video={aboutVideoStorage || aboutVideo}
        loading={loading}
        autoplay={autoplay}
        trackGA
      />
    </>
  );
};

export default LearnMore;
