// @flow
import React from 'react';
import { Clients as ClientsIcon } from 'components/icons';
import ClientsList from './ClientsList';
import ClientsEdit from './ClientsEdit';
import ClientsCreate from './ClientsCreate';
import PresetsTable from './presets';

const TestClientList = props => {
  return (
    <>
      <PresetsTable />
      {/* <br />
      <br /> */}
      {/* <ClientsList {...props} /> */}
    </>
  );
};

export default {
  name: 'clients',
  list: TestClientList,
  edit: ClientsEdit,
  // create: ClientsCreate,
  icon: ClientsIcon,
  options: {
    label: 'Manage Clients',
    hasMaskMode: true
  }
};
