import React from 'react';

function useDebounce({ value = '', delay = 300 }) {
  // State and setters for debounced value
  // const [debouncedValue, setDebouncedValue] = React.useReducer(
  //   (state, { type, payload }) => ({ ...state, value: payload.value, delay: payload.delay }),
  //   { value, delay }
  // );
  const [debouncedValue, setDebouncedValue] = React.useState({ value, delay });

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        // setDebouncedValue({ type: 'SET', payload: { value, delay } });
        setDebouncedValue({ value, delay });
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => clearTimeout(handler);
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return [debouncedValue, setDebouncedValue];
}

export default useDebounce;
