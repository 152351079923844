// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const PlusCircle = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 25" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.0294C0 5.41096 5.38154 0.0294189 12 0.0294189C18.6185 0.0294189 24 5.41096 24 12.0294C24 18.6479 18.6185 24.0294 12 24.0294C5.38154 24.0294 0 18.6479 0 12.0294ZM1.84615 12.0294C1.84615 17.6325 6.39692 22.1833 12 22.1833C17.6031 22.1833 22.1538 17.6325 22.1538 12.0294C22.1538 6.42634 17.6031 1.87557 12 1.87557C6.39692 1.87557 1.84615 6.42634 1.84615 12.0294ZM12.9508 11.134H17.1323C17.64 11.134 18.0554 11.5494 18.0554 12.0571C18.0554 12.5648 17.64 12.9802 17.1323 12.9802H12.9508V17.1617C12.9508 17.6694 12.5354 18.0848 12.0277 18.0848C11.52 18.0848 11.1046 17.6694 11.1046 17.1617V12.9802H6.92308C6.41538 12.9802 6 12.5648 6 12.0571C6 11.5494 6.41538 11.134 6.92308 11.134H11.1046V6.9525C11.1046 6.4448 11.52 6.02942 12.0277 6.02942C12.5354 6.02942 12.9508 6.4448 12.9508 6.9525V11.134Z" />
  </SvgIconStyled>
);

export default React.memo(PlusCircle);
