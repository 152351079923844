import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import {
  Container,
  Typography,
  Button,
  TextField as Field,
  LazyIcon,
  CustomTable,
  TableCellField,
  DeleteConfirmationModal
} from 'components';

import { IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import CopyButton from 'components/buttons/CopyButton';
import integrationApi from '../../helpers/apis/integrationApi';
import { TableCellText } from '../admin/style/AdminCommon';
import { getStorage } from '../../helpers';

const TextField = styled(Field)`
  background: ${({ success }) => success && 'rgba(12, 206, 107, 0.2)'};
  border-color: ${({ success }) => success && 'rgba(12, 206, 107, 0.2)'};

  input {
    background: ${({ success }) => success && 'rgba(12, 206, 107, 0.07)'};
  }
`;

const PartnerIntegrationsApisKeys = ({ handleCreateKey, createFriendlyKey }) => {
  const notify = useNotify();
  const userId = getStorage('userId', true);

  const [apiKeys, setApiKeys] = useState([]);
  const [sortedApiKeys, setSortedApiKeys] = useState([]);
  const [keyName, setKeyName] = useState('');
  const [key, setKey] = useState({ key: '', successGenerated: false });

  const [loading, setLoading] = useState(false);
  const [loadingKey, setLoadingKey] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowOnOpen, setRowOnOpen] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [sort, setSort] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25
  });

  const onGenerateKeyName = () => {
    if (!keyName.trim()) {
      notify('Please enter a key name', 'warning');
      return;
    }

    setLoadingKey(true);
    integrationApi
      .createApiKey(userId, keyName)
      .then(response => {
        setKey({ key: response.data.api_key, successGenerated: true });
        fetchApisKeys();
      })
      .catch(error => {
        notify(error?.response?.data?.message || 'Something went wrong', 'warning');
      })
      .finally(() => setLoadingKey(false));
  };

  const setOpenDialog = (val, row) => {
    setOpen(val);
    setRowOnOpen(row);
  };

  const handleDeleteKey = () => {
    setDisableSubmit(true);
    integrationApi
      .deleteApiKey(userId, rowOnOpen)
      .then(resp => {
        notify(resp.data.message, 'info');
        fetchApisKeys();
      })
      .catch(err => notify('Error to try to delete friendly key', 'warning'))
      .finally(() => {
        setOpenDialog(false, null);
        setDisableSubmit(false);
      });
  };

  const sortApiKeys = (sortname, order) => {
    if (sortname) {
      const newSort = apiKeys.slice().sort((currentItem, nextItem) => {
        let sort =
          currentItem[sortname]?.toLowerCase() > nextItem[sortname]?.toLowerCase()
            ? 1
            : currentItem[sortname] === nextItem[sortname]
            ? 0
            : -1;
        if (order === 'desc') {
          sort = sort === 1 ? -1 : sort === -1 ? 1 : 0;
        }
        return sort;
      });
      setSortedApiKeys([...newSort]);
    }
  };

  const onLoad = ({ sortname, order, page, perPage }) => {
    setSort({ sortname, order });
    setPagination( { page: page + 1, perPage });
  };

  const fetchApisKeys = () => {
    setLoading(true);
    integrationApi
      .getApiKeys(userId)
      .then(resp => setApiKeys(resp.data))
      .catch(() => setApiKeys([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchApisKeys();
  }, []);

  const { page, perPage } = pagination;
  const tableData = sortedApiKeys.slice((page - 1) * perPage, page * perPage);

  useEffect(() => {
    if (sort) sortApiKeys(sort.sortname, sort.order);
  }, [apiKeys, sort]);

  return (
    <Container.Grid container>
      <Container.Grid container item xs={12} px={2.5}>
        {createFriendlyKey ? (
          <Container.Grid item alignItems="center" xs={12} md={12} lg={12}>
            <Container.Grid item md={4} pr={2}>
              <TextField
                name="friendly_key_name"
                label={!key.successGenerated && 'Friendly Key Name'}
                fullWidth
                value={keyName}
                onChange={e => setKeyName(e.target.value)}
                size="small"
                success={key.successGenerated}
                InputProps={{
                  startAdornment: key.successGenerated && (
                    <InputAdornment>
                      <LazyIcon component="Enable" color="colorSystemSuccess" size={1.6} mr={1} />
                    </InputAdornment>
                  ),
                  endAdornment: key.successGenerated && (
                    <InputAdornment position="end">
                      <CopyButton
                        text={key.key}
                        message="Key has been copied to the clipboard"
                        onClick={() => {
                          setKeyName('');
                          setKey({ key: '', successGenerated: false });
                        }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Container.Grid>
            <Container.Grid item md={4}>
              <Button backgroundColor="colorSystemInfo" onClick={onGenerateKeyName}>
                {loadingKey ? (
                  <CircularProgress size={18} thickness={2} color="var(--colorCommonWhite)" />
                ) : (
                  <LazyIcon
                    component="Refresh"
                    style={{ fontSize: `var(--fontSize)` }}
                    color="var(--colorCommonWhite)"
                  />
                )}
                &nbsp;&nbsp;Generate Key
              </Button>
            </Container.Grid>
          </Container.Grid>
        ) : (
          <Container.Grid md={12} lg={12}>
            <Button onClick={e => handleCreateKey()} backgroundColor="colorSystemSuccess">
              <LazyIcon style={{ fontSize: `var(--fontSize)` }} color="var(--colorCommonWhite)" component="Key" />
              &nbsp;&nbsp;Create Friendly Key
            </Button>
          </Container.Grid>
        )}
      </Container.Grid>

      {key.successGenerated && (
        <Container.Grid container item xs={12} px={2.5}>
          <Typography.p fontSize={14} color="colorSystemSuccess" mt={0.6}>
            Copy and save the key in a safe location. You will no longer have access to this information in the future.
          </Typography.p>
        </Container.Grid>
      )}

      <Container.Grid container item xs={12} pt={4}>
        <CustomTable
          source={`partnerProfile/integrationsApisKeys`}
          total={apiKeys.length}
          data={tableData}
          onSort={({ sortname, order }) => setSort({ sortname, order })}
          loading={loading}
          onChangePage={page => setPagination(prev => ({ ...prev, page: page + 1 }))}
          onChangePerPage={perPage => setPagination(prev => ({ ...prev, perPage, page: 1 }))}
          onLoad={params => onLoad(params)}
          preferences={{ 
            app: 'partner',
            tab: 'apisKeys'
          }}
        >
          <TableCellField renderComponent={({ friendly_name }) => <TableCellText>{friendly_name}</TableCellText>} />

          <TableCellField renderComponent={({ date_created }) => <TableCellText>{date_created}</TableCellText>} />

          <TableCellField
            renderComponent={row => (
              <TableCellText>
                <IconButton
                  onClick={e => {
                    setOpenDialog(true, row);
                  }}
                >
                  <LazyIcon component="Delete" />
                </IconButton>
              </TableCellText>
            )}
          />
        </CustomTable>

        <DeleteConfirmationModal
          open={open || false}
          close={setOpen}
          onDelete={handleDeleteKey}
          title="Delete?"
          description="This Friendly Key will be deleted and cannot be recovered."
          disabledSubmit={disableSubmit}
        />
      </Container.Grid>
    </Container.Grid>
  );
};

export default PartnerIntegrationsApisKeys;
