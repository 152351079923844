// @flow
import { Newsfeed as NewsfeedIcon } from 'components/icons';
import NewsfeedList from './NewsfeedList';

export default {
  name: 'newsfeed',
  list: NewsfeedList,
  edit: NewsfeedList,
  icon: NewsfeedIcon,
  options: {
    label: 'Newsfeed',
    hasDot: true,
    hasMobileSublist: false
  }
};
