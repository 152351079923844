// @flow
import React, { useState } from 'react';
import { makeStyles, Box, Menu, MenuItem, Button } from '@material-ui/core';
import { ArrowDown } from 'components/icons';

const useStyles = makeStyles(theme => ({
  content: {
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      position: 'absolute',
      right: 7,
      top: 14
    }
  },
  btn: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
    color: 'var(--colorDefault)',
    '& span svg': {
      transform: props => `rotate(${props.open ? 180 : 0}deg)`
    }
  },
  label: {
    fontSize: '14px'
  },
  menuLink: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    },
    '& svg': {
      marginRight: '12px'
    }
  }
}));

const SortBySelect = ({ selectedSortIndex, setSelectedSortIndex, setSortByChanged, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ open: Boolean(anchorEl) });

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelect = (event, index) => {
    if (index === selectedSortIndex) setSortByChanged(true);
    setAnchorEl(null);
    setSelectedSortIndex(index);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.content}>
      <span className={classes.label}>Sort by:</span>
      <Button onClick={openMenu} className={classes.btn}>
        {options[selectedSortIndex].label}
        <ArrowDown size={1} ml={0.5} color="colorDefault" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedSortIndex}
            onClick={event => onSelect(event, index)}
            className={classes.menuLink}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SortBySelect;
