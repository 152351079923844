// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Disable = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18v18H0z" />
    <path d="M9,0C4,0,0,4,0,9s4,9,9,9c5,0,9-4,9-9S14,0,9,0z M9,1.7c2.6,0,4.9,1.4,6.2,3.5c0,0,0,0,0,0L2.4,12.1c-0.4-0.9-0.7-2-0.7-3.1  C1.7,5,5,1.7,9,1.7z M9,16.3c-2.4,0-4.5-1.2-5.8-2.9l12.7-6.8c0.3,0.8,0.4,1.6,0.4,2.4C16.3,13,13,16.3,9,16.3z" />
  </SvgIconStyled>
);

export default Disable;
