import React from 'react';
import { CardPageLayoutGrid } from '../../style';
import { CardTitleAndText } from '../shared';
import { format } from 'date-fns';

const LandingPagesCard = props => {
  const { name, modified } = props;

  return (
    <CardPageLayoutGrid>
      <CardPageLayoutGrid xs={12}>
        <CardTitleAndText title="Name" text={name} />
      </CardPageLayoutGrid>

      <CardPageLayoutGrid xs={12}>
        <CardTitleAndText title="Modified Date" text={format(new Date(modified), 'MMMM do yyyy, h:mm:ss aaa')} />
      </CardPageLayoutGrid>
    </CardPageLayoutGrid>
  );
};

export default LandingPagesCard;
