// @flow

const today = new Date().toISOString().slice(0, 10);

const trackSecurityIncidents = {
  layout: Object.freeze({
    header: Object.freeze({
      title: 'Security Incident'
    }),
    content: Object.freeze({
      component: 'shared/track/TrackIncidentModal'
    }),
    footer: Object.freeze({
      left: 'apps/shared/track/TrackIncidentFooterLeft',
      right: 'apps/shared/track/TrackIncidentFooterRight'
    })
  }),
  open: false,
  disabled: true,
  isNew: false,
  defaultChoice: 'trackSecurityIncidents',
  data: {},
  step: 0, // for hipaa client
  steps: ['Incident', 'Resolution Details', 'Breach Notification 1', 'Breach Notification 2', 'Corrective Measures'] // for hipaa client
};

export default trackSecurityIncidents;
