// @flow

const securityPolicies = {
  type: 'securityPolicies',
  orderBy: 'policy_id',
  order: 'asc',
  noQuery: true,
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'myDashboard',
      tab: 'securityPolicy'
    }
  },

  cells: [
    { id: 'policy_id', label: 'Policy', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      removeHtml: true,
      hideOrderBy: true
    },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      val: 'attachment_link',
      renderDowloadIcon: true,
      hideOrderBy: true,
      GAbutton: 'Download Policy'
    },
    {
      id: 'acknowledgement',
      label: 'Acknowledge',
      labelAlign: 'center',
      align: 'center',
      renderCheckIcon: true,
      hideOrderBy: true,
      tooltipLabel:
        "The checkboxes display acknowledgement status.\n\nPlease Click on the 'Agree' link on the bottom to acknowledge review policies"
    }
  ]
};

export default securityPolicies;
