import React from "react"
import { useContentAdminFormContext } from '../../context';
import { getContentAdminFormTitle } from 'helpers';
import { ContentAdminFormLayoutHeaderContainer } from '../../style';

const ContentAdminFormLayoutHeader = () => {

  const {method, page} = useContentAdminFormContext()

  const title = getContentAdminFormTitle(page, method);

  return (
    <ContentAdminFormLayoutHeaderContainer>
      {title}
    </ContentAdminFormLayoutHeaderContainer>
  )
}


export default ContentAdminFormLayoutHeader
