import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import {
  ArrowRightIcon,
  Button,
  Grid,
  Paper,
  RadioGroup,
  RadioInput,
  SaveIcon,
  TextField,
  Typography,
} from '@trustsecurenow/components-library';

const EMR_EHR_OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const EMR_EHR_LOCATION_CHOICES = [
  { label: 'Running on a server in office', value: 'Running on a server in office' },
  { label: 'Hosted by vendor(Cloud/SaaS)', value: 'Hosted by vendor(Cloud/SaaS)' },
  {
    label: 'Hosted by third party(Not EMR vendor)',
    value: 'Hosted by third party(Not EMR vendor)'
  },
  { label: 'Other', value: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const EmrAndEhr = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };

    if (name === 'emr_ehr_os' && value !== 'Other' && state.emr_ehr_os_other) {
      newState.emr_ehr_os_other = '';
    }
    if (name === 'emr_ehr_location' && value !== 'Other' && state.emr_ehr_other_location) {
      newState.emr_ehr_other_location = '';
    }
    if (name === 'emr_ehr_implemented' && value === 'No') {
      newState.emr_ehr_vendor = '';
      newState.emr_ehr_internal_name = '';
      newState.emr_ehr_os = null;
      newState.emr_ehr_os_other = '';
      newState.emr_ehr_details = '';
      newState.emr_ehr_location = null;
      newState.emr_ehr_other_location = '';
      newState.emr_ehr_description = '';
    }

    setState({ ...newState });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      {/* Title */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth radius={2}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='p' component='p' sx={{ padding: 1, paddingLeft: 2, marginY: 2 }} fontSize={16}>
                  In this section we will ask you questions about your Electronic Medical Records (EMR) or Electronic
                  Health Records (EHR).
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth radius={2} pb={2}>
            <Grid container>
              {/* Do you have an EMR/EHR implemented? */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={4} justify="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      Do you have an EMR/EHR implemented? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>


              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name="emr_ehr_implemented"
                  onChange={handleChangeState}
                  value={state.emr_ehr_implemented}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput
                      key={choice.value}
                      label={choice.label}
                      value={choice.value}
                      labelPlacement="End"
                      color="default"
                    />
                  ))}
                </RadioGroup>
              </Grid>

              {state.emr_ehr_implemented === 'Yes' ? (
                <>
                  {/* What is the name of your EMR/EHR Vendor? */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          What is the name of your EMR/EHR Vendor?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr vendor */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      sx={{ marginY: 1 }}
                      value={state.emr_ehr_vendor}
                      name="emr_ehr_vendor"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>

                  {/* What is the internal name that you use to refer to your EMR/EHR? */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          What is the internal name that you use to refer to your EMR/EHR?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr internal name */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      sx={{ marginY: 1 }}
                      value={state.emr_ehr_internal_name}
                      name="emr_ehr_internal_name"
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      onChange={handleChangeState}
                    />
                  </Grid>

                  {/* What server operating system does your EMR/EHR run on? */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          What server operating system does your EMR/EHR run on? *
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr os */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      row
                      name="emr_ehr_os"
                      value={state.emr_ehr_os}
                      onChange={handleChangeState}
                    >
                      {EMR_EHR_OS_CHOICES.map(choice => (
                        <RadioInput
                          key={choice.value}
                          label={choice.label}
                          value={choice.value}
                          labelPlacement="End"
                          color="default"
                        />
                      ))}
                    </RadioGroup>
                  </Grid>

                  {/* Other EMR/EHR OS */}
                  {state.emr_ehr_os === 'Other' && (
                    <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        sx={{ marginY: 1 }}
                        value={state.emr_ehr_os_other}
                        name="emr_ehr_os_other"
                        label="Other EMR/EHR OS"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Grid>
                  )}

                  {/* Please enter EMR/EHR Other operating system or provide more details: */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          Please enter EMR/EHR Other operating system or provide more details:
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr details */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      sx={{ marginY: 1 }}
                      value={state.emr_ehr_details}
                      name="emr_ehr_details"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>

                  {/* Where is your EMR/EHR located? */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          Where is your EMR/EHR located? *
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr location */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 2 }}
                      row
                      name="emr_ehr_location"
                      value={state.emr_ehr_location}
                      onChange={handleChangeState}
                    >
                      {EMR_EHR_LOCATION_CHOICES.map(choice => (
                        <RadioInput
                          key={choice.value}
                          label={choice.label}
                          value={choice.value}
                          labelPlacement="End"
                          color="default"
                        />
                      ))}
                    </RadioGroup>
                  </Grid>

                  {/* Other EMR/EHR Location */}
                  {state.emr_ehr_location === 'Other' && (
                    <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        sx={{ marginY: 1 }}
                        value={state.emr_ehr_other_location}
                        name="emr_ehr_other_location"
                        label="Other EMR/EHR Location"
                        fullWidth
                        onChange={handleChangeState}
                      />
                    </Grid>
                  )}

                  {/* Please describe where your EMR/EHR is located: */}
                  <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justify="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          Please describe where your EMR/EHR is located:
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* emr/ehr description */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      sx={{ marginY: 1 }}
                      value={state.emr_ehr_description}
                      name="emr_ehr_description"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Paper>

          {/* Action buttons */}
          <Grid
            item
            sx={{ marginTop: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid container spacing={4} display="flex" justifyContent="flex-end">
              <Grid
                sx={{ margin: 2 }}
                display="flex"
                justifyContent="flex-end"
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <ButtonCancel variant="text" onClick={e => handleClose()}>
                  Exit
                </ButtonCancel>
                <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
                  Save
                </Button>
                <Button
                  color="success"
                  startIcon={<ArrowRightIcon />}
                  variant="contained"
                  disableElevation
                  sx={{ marginLeft: '30px' }}
                  onClick={() => save('next')}
                >
                  Save and Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

EmrAndEhr.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default EmrAndEhr;
