import React from 'react';
import styled from 'styled-components';
import { LazyIcon, Container, LoadingStyled, PDFViewer } from 'components';
import Dialog from 'components/forms/Dialog';

const TaxExemptPdfContent = styled.div`
  && {
    margin-bottom: 12px;
    [data-testid='core__inner-pages'] {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }

  .viewer-container {
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 750px;
  }
`;

const PartnerTaxExemptViewModal = ({ open, setOpen, pdfData, setPdfData }) => {
  const handleCloseViewModal = value => {
    setOpen(value);
    setPdfData(null);
  };

  const handleDownloadPdf = () => {
    window.open(pdfData);
  };

  const DownloadButton = () => {
    return (
      <Container.Grid alignItems="center">
        <LazyIcon style={{ fontSize: `var(--fontSize)` }} color="var(--colorCommonWhite)" component="Download" />
        &nbsp;&nbsp; Download Certificate
      </Container.Grid>
    );
  };

  return (
    <Dialog
      open={open}
      setOpen={handleCloseViewModal}
      variant="standard"
      buttonSubmit={pdfData ? <DownloadButton /> : null}
      onSubmit={handleDownloadPdf}
      maxWidth="md"
      onlyOk
      closeButton
    >
      <TaxExemptPdfContent>{pdfData ? <PDFViewer url={pdfData} /> : <LoadingStyled />}</TaxExemptPdfContent>
    </Dialog>
  );
};

export default PartnerTaxExemptViewModal;
