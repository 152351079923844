// @flow

import clients from './clients';
import myCompany from './myCompany';
import myDashboard from './myDashboard';
import partnerProfile from './partnerProfile';
import admin from './admin';

const tables = {};

tables.clients = clients;
tables.myCompany = myCompany;
tables.myDashboard = myDashboard;
tables.partnerProfile = partnerProfile;
tables.admin = admin;

export default tables;
