/* eslint-disable react/prop-types */
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const ShieldCorrect = props => (
  <SvgIconStyled {...props} width="40" height="40" viewBox="0 0 37 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2945 39.3817C17.0055 39.7991 17.8093 40 18.6131 40C19.4169 40 20.2207 39.7836 20.9317 39.3817C30.5771 33.817 36.5592 23.4451 36.5592 12.3157C36.5592 10.6618 35.6781 9.11603 34.2406 8.29679L20.9317 0.614433C19.4942 -0.204811 17.732 -0.204811 16.2945 0.614433L2.98561 8.29679C1.54807 9.13149 0.666992 10.6618 0.666992 12.3157C0.666992 23.4451 6.66448 33.817 16.2945 39.3817ZM17.8402 3.28857C18.0721 3.14945 18.3503 3.08761 18.6131 3.08761C18.8759 3.08761 19.1541 3.16491 19.386 3.28857L32.6948 10.9709C33.174 11.2492 33.4677 11.7592 33.4677 12.3157C33.4677 22.3321 28.073 31.6839 19.386 36.6921C18.9068 36.9703 18.3194 36.9703 17.8402 36.6921C9.15312 31.6839 3.75848 22.3476 3.75848 12.3157C3.75848 11.7592 4.05217 11.2492 4.53135 10.9709L17.8402 3.28857ZM24.7341 14.0934C25.3369 13.4905 26.3107 13.4905 26.9136 14.0934C27.5164 14.6962 27.5164 15.67 26.9445 16.2883L16.1088 27.124C15.7997 27.4177 15.4132 27.5723 15.0113 27.5723C14.7949 27.5723 14.594 27.5104 14.393 27.4331C14.2076 27.3559 14.0221 27.2477 13.8675 27.0931L9.12205 22.3477C8.51921 21.7448 8.51921 20.771 9.12205 20.1681C9.72489 19.5653 10.6987 19.5653 11.3016 20.1681L14.9804 23.847L24.7341 14.0934Z"
      fill={props.fill ?? '#0069FF'}
    />
  </SvgIconStyled>
);

export default ShieldCorrect;
