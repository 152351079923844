// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import { Typography, ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';

export const Accordion: ComponentType<*> = styled(ExpansionPanel)`
  margin: 6.77px 0;
  && {
    &:before {
      background: none;
    }
  }
`;

export const AccordionSummary: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border-radius: var(--borderRadius);
  }
  > div:first-child {
    margin: 0 0;
    color: grey;
  }
  .Selected {
    color: blue;
  }
`;
// TODO: set width 100% on AccordionSummary, then align on right
// TODO: when selected change the color of AccordionSummary to blue (text and svg);

export const AccordionSummaryHeader: ComponentType<*> = styled(ExpansionPanelSummary)`
  && {
    border: 1px solid grey;
    border-radius: var(--borderRadius);
  }
  > div:first-child {
    margin: 0 0;
    color: grey;
    display: flex;
    justify-content: flex-end;
  }
`;

export const TextTitle: ComponentType<*> = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 500;
    color: var(--colorBase);
  }
`;


export const InfoText: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    color: var(--colorDefault);
  }
  padding: 0;
  margin: 0;
`;

export const InfoTextTitle: ComponentType<*> = styled(Typography)`
  && {
    font-size: var(--fontSize);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;

export const TextColorBase: ComponentType<*> = styled(Typography)`
  && {
    font-size: 16px;
    color: var(--colorBase);
  }
  padding: 0;
  margin: 0;
`;
