// @flow
import React, { useState } from 'react';
import { Button as MuiButton, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useDirectorySync } from './DirectorySyncContext';
import { Container, LazyIcon} from 'components';
import { TextTitle } from './ComponentTypes';
import { Dialog } from '../../../components';

const ButtonStyled = styled(MuiButton)`
  && {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

const SYNC_TYPE_NAMES = {
  AzureActiveDirectory: 'Azure Active Directory',
  OnPremiseActiveDirectory: 'On Premise Active Directory'
};

const DirectorySyncRefreshComponent = ({ syncType }) => {
  const { dispatch } = useDirectorySync();
  const [modal, setModal] = useState({open: false})

  return (
    <>

      <Dialog
        title={`Do you want to reset ${SYNC_TYPE_NAMES[syncType]}?`}
        message="The directory would be reset."
        buttonSubmit="Yes, I want to reset"
        open={modal.open}
        setOpen={() => setModal({open: false})}
        maxWidth="xs"
        onSubmit={() => {
          dispatch.onRefreshDirectory();
          setModal({open: false});
        }}
      />

      <Container.Paper mt={2} radius={1}>
        <Grid container>
          <Container.Grid item container p={3} bb={1} xs={12}>
            <TextTitle>
              Reset Directory
            </TextTitle>
          </Container.Grid>

          <Container.Grid item p={3} xs={12} container alignItems="center" justify="space-between">
            <span>You can reset your Active Directory here.</span>
            <ButtonStyled
              pr={3}
              pl={3}
              onClick={() => setModal({open: true})}
              variant='outlined'
            >
              <LazyIcon style={{ fontSize: `var(--fontSize)` }} color='var(--colorCommonWhite)' component='Refresh' />
              &nbsp;&nbsp;Reset Directory
            </ButtonStyled>
          </Container.Grid>

        </Grid>
      </Container.Paper>
    </>
  );

};

export default DirectorySyncRefreshComponent;
