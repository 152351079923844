// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Comment = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M21.2054 2H2.7853C1.2441 2 0 3.2441 0 4.7853V14.9609C0 16.5021 1.2441 17.7462 2.7853 17.7462H14.3629L16.359 20.875C16.7025 21.4043 17.2781 21.7292 17.9095 21.7292C17.9188 21.7292 17.9188 21.7292 17.928 21.7292C18.5501 21.7292 19.1257 21.4228 19.4785 20.9029L21.6046 17.7091C21.8739 17.672 22.1338 17.607 22.3752 17.4956C23.0344 17.1985 23.5543 16.6507 23.805 15.9729C23.935 15.6665 24 15.323 24 14.9702V4.7853C23.9907 3.2441 22.7466 2 21.2054 2ZM22.1338 14.9609C22.1338 14.9795 22.1338 15.0073 22.1246 15.0259C22.0967 15.4623 21.7718 15.8151 21.3354 15.8801C20.6298 15.8894 20.6762 15.7687 20.3327 16.2515L17.928 19.8723L15.5698 16.1957C15.3006 15.7779 15.1242 15.9172 14.335 15.8986H2.7853C2.26538 15.8986 1.85687 15.4809 1.85687 14.9702V4.7853C1.85687 4.27466 2.26538 3.85687 2.7853 3.85687H21.2054C21.7253 3.85687 22.1338 4.27466 22.1338 4.7853V14.9609Z" />
  </SvgIconStyled>
);

export default Comment;
