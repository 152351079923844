import styled, { css } from 'styled-components';
import { Typography, Button } from 'components';

export const PreassessmentEmailStepTitle = styled(Typography.h5)`
  font-size: 24px;
  line-height: 28.8px;
`

export const PreassessmentButton = styled(Button)`
  && {
    padding: 15px 30px;

    ${({disabled}) => disabled && css`
      background-color: var(--colorGreyLight3);
    `}

    ${({disabled, isStarted}) => !disabled && isStarted && css`
        background-color: var(--colorSystemSuccess);
    `}

    ${({outlined}) => outlined && css`
      border: 1px solid var(--colorBaseLight2);
      background-color: var(--colorCommonWhite);

      span {
        color: var(--colorBaseLight2);
      }

      &:hover {
        span {
          color: var(--colorCommonWhite);
        }
      }

    ` }
  }
`
