// @flow

import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { SelectField } from 'components';
import { LazyIcon } from 'components/icons';
import { snakeToCamelTitle } from 'helpers';

const CloseFilter: ComponentType<*> = styled.div`
  cursor: pointer;
`;

type ClientsUsersSelectTypes = {
  name: string,
  state: {
    active: boolean,
    value: any,
    multiple: boolean
  },
  handleClose: Function,
  onChange: Function,
  choicesTags: Array<*>,
  choicesGroups: Array<*>
};

const choices = {
  group_role: [],
  ess: [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' }
  ],
  policy: [
    { value: 'yes', label: 'Acknowledged Policies' },
    { value: 'no', label: 'Not Acknowledged Policies' }
  ],
  other_policy: [
    { value: 'yes', label: 'Acknowledged Other Policies' },
    { value: 'no', label: 'Not Acknowledged Other Policies' }
  ],
  activated: [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' }
  ],
  locked: [
    { value: 'true', label: 'Locked' },
    { value: 'false', label: 'Unlocked' }
  ],
  deleted: [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
  ],
  welcome_email_received: [
    { value: 'true', label: 'Welcome Email Sent' },
    { value: 'false', label: 'Welcome Email Not Sent' }
  ],
  tag: []
};

const ClientsUsersSelect = ({ name, state, handleClose, onChange, choicesTags, choicesGroups }: ClientsUsersSelectTypes) => {
  const newChoices = name === 'tag' ? choicesTags : name === 'group_role' ? choicesGroups : choices[name];

  if (
    !state.active ||
    (name === 'tag' && typeof choicesTags === 'undefined') ||
    (name === 'group_role' && typeof choicesGroups === 'undefined')
  )
    return <LazyIcon component="Loading" mr={1} color="colorDefault" size={3} />;

  return (
    <Box display="flex" alignItems="center">
      <CloseFilter onClick={() => handleClose(name)}>
        <LazyIcon component="Close" mr={1} color="colorDefault" size={0.5} />
      </CloseFilter>
      <SelectField
        name={name}
        value={state.value}
        disabled={newChoices?.length === 0}
        displayEmpty
        onChange={onChange}
        choices={newChoices}
        emptyValue={getLabelSelect(name)}
        mr={2}
        variant
        multiple={state.multiple}
        renderValue={e => (state.multiple ? renderMultiple(e, name, newChoices) : renderSingle(e, name, newChoices))}
      />
    </Box>
  );
};

function renderSingle(selected, name, newChoices) {
  if (selected.length === 0 || (newChoices.length === 1 && !newChoices[0].value)) return getLabelSelect(name);
  return newChoices.filter(c => c.value === selected)[0].label;
}

function renderMultiple(selected, name, newChoices) {
  const checkChoices = choices[name].length === 0 ? newChoices : choices[name];
  if (selected.length === 0) return getLabelSelect(name);
  return selected
    .map(sel => {
      let val = '';
      checkChoices.forEach(choice => {
        if (choice.value === sel) val = `${choice.label}`;
      });
      return val;
    })
    .join(', ');
}

function getLabelSelect(title) {
  switch (title) {
    case 'ess':
      return title.toUpperCase();
    case 'activated':
      return 'Status';
    case 'welcome_email_received':
      return 'Welcome Email Status';
    default:
      return snakeToCamelTitle(title);
  }
}

export default ClientsUsersSelect;
