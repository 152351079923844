import React, { useState, useEffect, useMemo } from 'react';
import parse from 'html-react-parser';
import { makeStyles, Box } from '@material-ui/core';
import { cleanTextFromHtml } from 'helpers';

const useStyles = makeStyles(theme => ({
  textContent: {
    fontFamily: 'var(--fontFamily)',
    color: 'var(--colorDefault)',
    wordBreak: 'break-word',
    '& p:last-child': {
      display: 'inline'
    },
    '& p': {
      margin: 0
    },
    '& pre': {
      overflowY: 'scroll'
    }
  },
  linkWrap: {
    display: 'inline'
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: 5,
    background: 'none',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    display: 'inline',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const CroppedHtmlText = ({ text, limit }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [newLimit, setNewLimit] = useState(limit);
  const cleanText = useMemo(() => cleanTextFromHtml(text), [text]);
  const hasSeeMore = cleanText.length > limit;

  useEffect(() => {
    setIsExpanded(cleanText.length <= limit);
  }, [cleanText, limit]);

  const getText = str => {
    if (hasSeeMore) {
      return isExpanded ? str : str.substr(0, newLimit);
    }
    return str;
  };

  const toggleText = () => {
    setIsExpanded(prevState => !prevState);
  };

  useEffect(() => {
    if (hasSeeMore) {
      let newHtml = '';
      let newLimitIndex = 0;
      for (const index in text) {
        if (cleanTextFromHtml(newHtml).length <= limit) {
          newHtml += text[index];
          newLimitIndex = index;
        }
      }
      setNewLimit(newLimitIndex);
    }
  }, [hasSeeMore, text, limit]);

  return (
    <>
      <Box className={classes.textContent} component="span">
        {parse(getText(text))}
      </Box>

      {hasSeeMore && (
        <span className={classes.linkWrap}>
          {!isExpanded && ' ...'}
          <button type="button" onClick={toggleText} className={classes.link}>
            {isExpanded ? 'See Less' : 'See More'}
          </button>
        </span>
      )}
    </>
  );
};

export default CroppedHtmlText;
