import React from "react"
import { Pagination } from '../shared';
import { CardPageLayoutFooterContainer } from '../../style';
import { useCardPageContext } from '../../context';

const CardPageLayoutFooter = () => {
  const { pagination, dispatch } = useCardPageContext();
  const { total, limit, page } = pagination

  return (
    <CardPageLayoutFooterContainer>
      {total ? (
        <Pagination
          dataCount={total}
          limitItem={limit}
          currentPage={page}
          onChangePage={(page) => dispatch.onChange("page", +page)}
        />
      ) : null}
    </CardPageLayoutFooterContainer>
  )
}

export default CardPageLayoutFooter
