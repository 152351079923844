import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const CardStyled = styled(Card)`
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardTitle = styled.span`
  font-size: 16px;
  color: var(--colorSystemInfo);
  font-weight: 700;
  padding-bottom: 20px;
  display: block;
  border-bottom: 1px solid var(--borderDefault);
`;

export const CardBody = styled.div`
  padding-top: 30px;
  display: flex;
  flex-grow: 1;
`;

export const CardImg = styled.div`
  flex-basis: 30%;
  display: flex;
  align-self: flex-start;
  padding: 0 10px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const CardContent = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`;

export const CardFooter = styled.div`
  margin-top: auto;
`;

export const CardProgress = styled.div`
  margin-top: 15px;

  p {
    text-align: right;
  }
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;

  button {
    width: ${({ btnWidth }) => btnWidth || '48%'};
  }
`;
