import LabelAndInfo from '../../../../components/types/LabelAndInfo';
import React from 'react';

const selectScenarios = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc'
  },
  selecting: true,
  sort: ['name', 'country', 'difficulty', 'capture_submitted_data', 'actions'],
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'country',
      label: 'Country',
      align: 'left'
    },
    {
      id: 'difficulty',
      label: 'Difficulty',
      align: 'left'
    },
    {
      id: 'capture_submitted_data',
      label: (
        <LabelAndInfo
          weight={300}
          label={'Capture Submitted Data'}
          tooltip={
            'Campaigns that capture submitted data (Yes) will bring users to a fake landing page if a link is clicked. Additional ESS points will be lost, and results will be tracked if users submit any data on this fake landing page. No submitted data will be saved or accessible.'
          }
        />
      ),
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      align: 'left'
    }
  ]
};

export default selectScenarios;
