import React, { useState, useEffect } from 'react';
import { LoadingStyled, PageError } from 'components';
import { useLocation, useId } from 'hooks';
import * as sra from 'helpers/apis/services/sra';
import SRA from './SRA/SRA';
import SRAX from './SRA/SRAX';
import { useClientInfo } from './SRA/hooks';
import { SRAProvider } from './SRA/context/sraContext';

const PageSRA = () => {
  const userId = useId();
  const { item } = useLocation();
  const clientId = item || userId;
  const { isHSN, migrateSRA, newSRA, hasLegacyDocuments, isLoadingClientInfo } = useClientInfo(clientId);
  const [isLoadingMigration, setIsLoadingMigration] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (migrateSRA) {
      sra
        .migrateClientSRA(clientId)
        .then(res => {})
        .catch(err => {
          setError(true);
        })
        .finally(() => {
          setIsLoadingMigration(false);
        });
    } else if (migrateSRA === false) {
      setIsLoadingMigration(false);
    }
  }, [migrateSRA, clientId]);

  if (isLoadingClientInfo || isLoadingMigration || isHSN === null) return <LoadingStyled centerAlign />;

  if (error)
    return (
      <PageError
        title="Oops!"
        subtitile="There was a problem with the page?"
        linkText="Contact Us"
        linkTo="/user/contactUs"
      />
    );

  return newSRA ? (
    <SRAProvider>
      <SRAX hasLegacyDocuments={hasLegacyDocuments} />
    </SRAProvider>
  ) : (
    <SRA />
  );
};

export default PageSRA;