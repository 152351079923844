import React from 'react';
import styled, { css } from 'styled-components';
import { LazyIcon } from 'components/icons';
import { CircularProgress } from '@material-ui/core';

const ActionButton = styled.div`
  display: inline-flex;
  align-items: center;

  color: ${({ color }) => `var(--${color})`};
  cursor: ${({ onClick, isUnderlined }) => (onClick || isUnderlined ? 'pointer' : 'default')};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};

  p {
    margin: 0 0 0 8px;
    text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
  }
`;

function TextWithIcon({
  text = '',
  icon = '',
  isBlue = false,
  isDisabled = false,
  isUnderlined = false,
  isLoading = false,
  onClick
}) {
  const color = isDisabled ? 'colorLight' : isBlue ? 'colorSystemInfo' : 'colorDefault';

  return (
    <ActionButton isDisabled={isDisabled} isUnderlined={isUnderlined} onClick={onClick} color={color}>
      {isLoading ? <CircularProgress size={14} thickness={2} /> : <LazyIcon component={icon} color={color} />}
      <p>{text}</p>
    </ActionButton>
  );
}

export default TextWithIcon;
