// @flow

const workPlan = {
  type: 'workPlan',
  selecting: true,
  selectAll: true,
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'myCompany',
      tab: 'workPlanFinding',
      fullScreen: true
    }
  },
  cells: [
    { id: 'name', label: 'Plan Name', align: 'left' },
    { id: 'year', label: 'Year', align: 'left' },
    { id: 'created_date', label: 'Date Created', align: 'left', type: 'date', isUTC: true },
    { id: 'print', label: 'Print', align: 'left', printWorkPlanReport: true, hideOrderBy: true }
  ]
};

export default workPlan;
