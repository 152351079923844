// @flow
import React, { type Element } from 'react';
import { TableListProvider, type TableListProviderTypes } from './TableList2Context';
import TableListTable from './TableListTable';

const TableList = (props: TableListProviderTypes): Element<*> => {
  return (
    <TableListProvider {...props}>
      <TableListTable />
    </TableListProvider>
  )
};

TableList.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true
};

export default TableList;
