import { useReducer } from 'react';

function preassessmentReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_STEP': {
      return { ...state, step: action.payload };
    }
    case 'CHANGE_FIELD': {
      return { ...state, [action.payload.field]: action.payload.value };
    }
    case 'SET_DISABLED': {
      return { ...state, disabled: action.payload };
    }
    case 'SET_USER_STATE': {
      return { ...state, userState: action.payload };
    }
    default:
      return state;
  }
}

const usePreassessment = () => {
  const initialState = {
    step: 1,
    email: '',
    disabled: false,
    userState: 'start',
    quiz_token: null
  };

  const [state, setState] = useReducer(preassessmentReducer, initialState);

  const dispatch = {};

  dispatch.changeStep = step => {
    setState({
      type: 'CHANGE_STEP',
      payload: step
    });
  };

  dispatch.onChange = (value, field) => {
    setState({
      type: 'CHANGE_FIELD',
      payload: {
        value,
        field
      }
    });
  };

  dispatch.setDisabled = value => {
    setState({
      type: 'SET_DISABLED',
      payload: value
    });
  };

  dispatch.setUserState = userState => {
    setState({
      type: 'SET_USER_STATE',
      payload: userState
    });
  };

  return {
    ...state,
    dispatch
  };
};

export default usePreassessment;
