// @flow

import securityPolicies from './securityPolicies';
import otherPolicies from './otherPolicies';

const myDashboard = {};

myDashboard.securityPolicies = securityPolicies;
myDashboard.otherPolicies = otherPolicies;

export default myDashboard;
