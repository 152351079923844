// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DeleteIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M21.2086 3.1369H14.9212C14.9305 3.09977 14.949 3.06265 14.949 3.02553C14.949 1.355 13.6229 0 12 0C10.3771 0 9.05101 1.355 9.05101 3.02553C9.05101 3.06265 9.05101 3.09977 9.05101 3.1369H2.78207C1.25193 3.1369 0 4.3898 0 5.92112V6.30163C0 7.83295 1.25193 9.08585 2.78207 9.08585H3.56108C3.46834 9.355 3.41267 9.6427 3.41267 9.93968V21.2158C3.41267 22.7471 4.6646 24 6.19474 24H17.8053C19.3354 24 20.5873 22.7471 20.5873 21.2158V9.93968C20.5873 9.6427 20.5317 9.355 20.439 9.08585H21.2179C22.7481 9.08585 24 7.83295 24 6.30163V5.92112C23.9907 4.3898 22.7481 3.1369 21.2086 3.1369ZM10.8964 3.02553C10.8964 2.37588 11.3879 1.85615 11.9907 1.85615C12.5935 1.85615 13.085 2.38516 13.085 3.02553C13.085 3.06265 13.1036 3.09977 13.1128 3.1369H10.8779C10.8779 3.09977 10.8964 3.06265 10.8964 3.02553ZM18.7233 9.93968V21.2158C18.7233 21.7262 18.306 22.1439 17.796 22.1439H6.18545C5.67541 22.1439 5.2581 21.7262 5.2581 21.2158V9.93968C5.2581 9.54989 5.48999 9.22507 5.83311 9.08585H8.28129V17.2343C8.28129 17.7448 8.6986 18.1624 9.20865 18.1624C9.71869 18.1624 10.136 17.7448 10.136 17.2343V9.08585H13.8454V17.1694C13.8454 17.6798 14.2627 18.0974 14.7728 18.0974C15.2828 18.0974 15.7001 17.6798 15.7001 17.1694V9.08585H18.1484C18.4915 9.22507 18.7233 9.55917 18.7233 9.93968ZM22.136 6.30163C22.136 6.81207 21.7187 7.22971 21.2086 7.22971H18.3988C18.204 7.1833 18 7.15546 17.7867 7.15546H6.17623C5.96294 7.15546 5.76816 7.1833 5.56414 7.22971H2.75428C2.24423 7.22971 1.82692 6.81207 1.82692 6.30163V5.92112C1.82692 5.41068 2.24423 4.99304 2.75428 4.99304H21.1994C21.7094 4.99304 22.1267 5.41068 22.1267 5.92112V6.30163H22.136Z" />
  </SvgIconStyled>
);

export default DeleteIcon;
