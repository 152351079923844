// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DarkWebMan = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
      <path d="M16.7695 9.58808H18.2542C18.7926 9.58808 19.2311 10.0265 19.2465 10.565C19.2465 11.1035 18.808 11.5419 18.2695 11.5419H17.7695C17.8618 11.6727 17.9311 11.8265 17.9311 11.9958C17.9311 16.365 14.3772 19.9189 10.008 19.9189C5.63876 19.9189 2.08492 16.365 2.08492 11.9958C2.08492 11.8189 2.15415 11.6727 2.24645 11.5419H1.74645C1.20799 11.5419 0.769531 11.1035 0.769531 10.565C0.769531 10.0265 1.20799 9.58808 1.74645 9.58808H3.52338L5.92338 2.00347C6.03107 1.6727 6.30799 1.41885 6.65415 1.34962C7.0003 1.28039 7.35415 1.39578 7.58492 1.66501L10.0003 4.44962L12.4003 1.66501C12.6234 1.40347 12.9772 1.28039 13.3157 1.34193C13.6541 1.40347 13.9388 1.64962 14.0541 1.9727L16.7695 9.58808ZM13.1695 13.0958C14.108 13.0958 14.9003 12.4189 15.0772 11.5342H11.2618C11.4465 12.4265 12.2311 13.0958 13.1695 13.0958ZM7.08492 13.0958C8.02338 13.0958 8.81569 12.4189 8.99261 11.5342H5.17722C5.36184 12.4265 6.14646 13.0958 7.08492 13.0958ZM3.70799 13.0342C4.20799 16.0573 6.83876 18.3727 10.0003 18.3727C12.9695 18.3727 15.4618 16.3342 16.1695 13.5881C15.4618 14.4727 14.3849 15.0419 13.1618 15.0419C11.9311 15.0419 10.8388 14.4496 10.1234 13.5496C9.41569 14.4496 8.32338 15.0419 7.08492 15.0419C5.62338 15.0419 4.36953 14.2265 3.70799 13.0342ZM8.67722 15.6497H11.3311C11.7541 15.6497 12.1003 15.9958 12.1003 16.4189C12.1003 16.842 11.7541 17.1882 11.3311 17.1882H8.67722C8.25415 17.1882 7.90799 16.842 7.90799 16.4189C7.90799 15.9958 8.25415 15.6497 8.67722 15.6497Z"/>
  </SvgIconStyled>
);

export default DarkWebMan;