// @flow
import React, { useState, type Element, type ComponentType, useEffect } from 'react';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import { InputAdornment, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout as AppLayout, Footer, LoadingStyled } from 'components';
import { Eye, EyeClose } from 'components/icons';
import cognito from '../../helpers/apis/cognito';
import PasswordMeter from '../../components/forms/PasswordMeter';
import { TextField } from "@trustsecurenow/components-library"

const Layout: ComponentType<*> = styled.div`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: 5px solid #082f49;
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
  .box {
    margin: 0 !important;
  }
`;

type InputType = {
  autoFocus?: boolean,
  id: string,
  name: string,
  value: string,
  label: string,
  disabled: boolean,
  type: string,
  onChange: Function,
  meta?: {
    touched: boolean,
    error: string
  },
  input?: Object,
  ...
};

const Input = ({
  meta: { touched, error } = { touched: false, error: '' },
  input: { ...inputProps },
  ...props
}: InputType): Element<*> => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

Input.defaultProps = {
  autoFocus: false,
  meta: { touched: false, error: '' },
  input: {}
};

type UserSetPasswordType = {
  location: {
    search: Object
  }
};

const UserSetPassword = ({ location }: UserSetPasswordType) => {
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [redirectData, setRedirectData] = useState({ token: null, redirectionPath: null, redirectionValue: null });
  const notify = useNotify();

  const passwordMatch = () => {
    return password === passwordVerification;
  };

  const passwordEmpty = () => {
    return !password && !passwordVerification;
  };

  const submitDisabled = loading || !passwordMatch() || passwordEmpty() || (password && passwordScore < 3);
  const showPasswordMatchError = !passwordMatch() && password.trim() !== '' && passwordVerification.trim() !== '';

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    const redirectionPath = new URLSearchParams(location.search).get('redirection_path');
    const microTrainingId = new URLSearchParams(location.search).get('training_id');
    const newsLetterUrl = new URLSearchParams(location.search).get('newsletter_url');
    const redirectionValue = (redirectionPath == "microtraining") ? microTrainingId : (redirectionPath == "newsletter") ? newsLetterUrl : ''

    setRedirectData(prevState => ({
      token: token,
      redirectionPath: redirectionPath,
      redirectionValue: redirectionValue
    }));

    if (redirectionPath === 'microtraining' || redirectionPath === 'newsletter') {
      checkIfUserExists(token, redirectionPath, microTrainingId, newsLetterUrl);
    }
    else {
      setScreenLoading(false);
    }
  }, [location.search]);

  const checkIfUserExists = (token, redirectionPath, microTrainingId, newsLetterUrl) => {
    setScreenLoading(true);
      cognito
        .verifyTokenExists(token)
        .then(_ => {
          setScreenLoading(false);
        })
        .catch(err => {
          if(err?.response?.status === 400) {
            // user not found moving to login
            if(redirectionPath === "newsletter") {
              window.open(newsLetterUrl, '_blank');
            }
            const queryPathParam = `/#login?${(redirectionPath === 'microtraining')
            ? `microtraining_id=${microTrainingId}` : ''}`;
            window.location.href = queryPathParam;
          }
        })
  }

  const submit = e => {
    e.preventDefault();
    if (passwordScore < 3) return notify('Please Create a Stronger Password');
    setLoading(true);
    cognito
      .resetUserPassword(password, redirectData.token)
      .then(response => {
        setLoading(false);
        notify('Your Password is changed successfully');
        window.location.href = `/#login?email=${response.data.email}${
          redirectData.redirectionPath === 'microtraining' ? `&microtraining_id=${redirectData.redirectionValue}` : ''
        }`;
        if (redirectData.redirectionPath === 'newsletter') {
          window.open(redirectData.redirectionValue, '_blank');
        }
      })
      .catch(err => {
        setLoading(false);
        notify(err?.response?.data?.message || 'an error occurred while setting the new password', 'warning');
      });
    return null;
  };

  return screenLoading === true ? (
    <LoadingStyled />
  ) : (
    <AppLayout sidebar={null} background="paper" centered footer={<Footer />}>
      <Layout>
        <Form onSubmit={submit} noValidate>
          <h2 style={{ color: '#082f49' }}> Reset Password </h2>
          <Input
            autoFocus
            id="password"
            name="password"
            value={password}
            label="Password"
            disabled={loading}
            type={showPassword ? 'text' : 'password'}
            onChange={e => {
              setPassword(e.target.value);
            }}
            inputProps={{maxLength: 128}}
            error={password && passwordScore < 3}
          />
          {password && (
            <PasswordMeter
              password={password}
              onChangePassword={e => {
                setPasswordScore(e);
              }}
            />
          )}
          <TextField
            id="passwordVerification"
            name="passwordVerification"
            value={passwordVerification}
            label="Verify Password"
            type={showPassword ? 'text' : 'password'}
            disabled={loading}
            onChange={e => {
              setPasswordVerification(e.target.value);
            }}
            inputProps={{maxLength: 128}}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Eye /> : <EyeClose />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={showPasswordMatchError}
            helperText={showPasswordMatchError && 'Passwords do not match.'}
          />
          <Button
            style={{
              backgroundColor: `${submitDisabled ? '#ccc' : '#082f49'}`,
              color: '#fff',
              cursor: `${submitDisabled ? 'not-allowed' : 'pointer'}`,
              'pointer-events': 'auto'
            }}
            type="submit"
            disabled={submitDisabled}
            variant="contained"
            color="primary"
          >
            &nbsp;&nbsp;Submit
            {loading && <CircularProgress size={18} thickness={2} />}
          </Button>
        </Form>
      </Layout>
    </AppLayout>
  );
};

export default UserSetPassword;
