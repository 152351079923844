import React from 'react';
import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNA } from '../helpers';

const DWMCell = ({ enabled, clientId }) => {
  if (isNA(enabled))
    return (
      <Tooltip placement="top" title="This account does not have Dark Web Monitoring">
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  const Icon = enabled ? CircleCheckIcon : CircleCloseIcon;
  const color = enabled ? 'success' : 'error';
  const tooltipTitle = enabled
    ? 'Click here to navigate to the Dark Web tab'
    : 'Click here to navigate to the Dark Web tab and configure monitoring';

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton
        color={color}
        LinkComponent={Link}
        to={`/clients/darkWebMonitoring/${clientId}`}
        onClick={e => e.stopPropagation()}
      >
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

DWMCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired
};

export default DWMCell;
