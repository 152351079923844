import { decodeId } from 'helpers';
import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_19;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const partnerChecklistApi = {};

partnerChecklistApi.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

partnerChecklistApi.getProgress = () => {
  const userId = decodeId(getStorage('userId'));
  const requestOptions = {
    url: `${baseURL}setupGuide/progress/${userId}`,
    headers: partnerChecklistApi.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

partnerChecklistApi.welcomeMessageStatus = () => {
  const userId = decodeId(getStorage('userId'));
  const requestOptions = {
    url: `${baseURL}setupGuide/welcomeMessageStatus/${userId}`,
    headers: partnerChecklistApi.getRequestHeaders()
  };
  return apiClient.put(requestOptions);
};

partnerChecklistApi.disableNotification = () => {
  const requestOptions = {
    url: `${baseURL}setupGuide/disableNotification`,
    headers: partnerChecklistApi.getRequestHeaders()
  };
  return apiClient.post(requestOptions);
};

export default partnerChecklistApi;
