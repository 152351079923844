// @flow

const policyInfo = {
  title: 'Policy Information',
  component: 'clients/ClientsEditPolicyInfoModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel',
      clear: true
    },
    submit: {
      icon: 'Done',
      label: 'Update',
      uploadType: 'policy'
    },
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: null
};

export default policyInfo;
