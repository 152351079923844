// @flow

import sraDocuments from './sraDocuments';
import workPlan from './workPlan';
import workPlanFinding from './workPlanFinding';
import phishing from './phishing';
import phishingSummary from './phishingSummary';
import selectRecipients from './selectRecipients';
import selectScenarios from './selectScenarios';
import sraRiskAssessment from './sraRiskAssessment';

const myCompany = {};

myCompany.sraDocuments = sraDocuments;
myCompany.workPlan = workPlan;
myCompany.workPlanFinding = workPlanFinding;
myCompany.phishing = phishing;
myCompany.phishingSummary = phishingSummary;
myCompany.selectRecipients = selectRecipients;
myCompany.selectScenarios = selectScenarios;
myCompany.sraRiskAssessment = sraRiskAssessment;

export default myCompany;
