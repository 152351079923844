// @flow
import React, { type Element, useState } from 'react';
import { Container, DragZone } from 'components';
import { LazyIcon } from 'components/icons';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { AccordionSummary, Accordion, InfoText, InfoTextTitle, TextColorBase } from './ComponentTypes';

type DirectorySyncCsvBulkUploadTypes = {
  record: Object,
  dispatch: Object,
  enable: boolean
};

const DirectorySyncCsvBulkUpload = ({ record, dispatch, enable }: DirectorySyncCsvBulkUploadTypes): Element<*> => {
  return (
    <>
      <Container.Grid item direction="column" justify="center" mt={2} mb={2} sm={12} xs={12} xl={12} md={12}>
        <Container.Grid direction="row" alignItems="center" sm={4} xs={4} xl={4} md={4}>
          <DragZone
            title=""
            fileType=".csv"
            size={12}
            name="file"
            dispatch={dispatch.onFileUpload}
            record={record}
            type="base64"
          />
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

export default DirectorySyncCsvBulkUpload;

export const DirectorySyncCsvBulkUploadHint = (): Element<*> => {
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <Container.Grid block spacing={24}>
      <Accordion expanded={openInfo} onChange={e => setOpenInfo(!openInfo)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="access2-content"
          id="access2-body"
          classes={{
            root: `accessAccordionSummary`,
            selected: `Selected`
          }}
        >
          <Container.Grid direction="row" alignItems="center" pt={1} mb={1} sm={12} xs={12} xl={12} md={12}>
            <LazyIcon component="Info" color="info" mt={2} mb={2} mr={2} />
            <TextColorBase>Helpful hints for ensuring upload success</TextColorBase>
          </Container.Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Container.Grid item direction="column" xs={12}>
            <InfoTextTitle>Required Fields</InfoTextTitle>
            <InfoText>First name, Last name, email, transaction.</InfoText>
            <InfoText>
              If the user will have a manager role, place an "X" in the manager column. Leave this field blank for
              employees.
            </InfoText>
            <br />
            <InfoTextTitle>Do Not</InfoTextTitle>
            <InfoText>Change headers names</InfoText>
            <InfoText>Edit/Delete UserID in Column A</InfoText>
            <br />
            <InfoTextTitle>Transaction Column Key</InfoTextTitle>
            <InfoText>Used to manage user access</InfoText>
            <br />
            <InfoText>
              <b>A - </b>Add or reactivate user (User will be notified)
            </InfoText>
            <InfoText>
              <b>D - </b>Deactivate user (User will not be notified)
            </InfoText>
            <InfoText>
              <b>M - </b>Modify user information (default for existing users)
            </InfoText>
          </Container.Grid>
        </AccordionDetails>
      </Accordion>
    </Container.Grid>
  );
};
