// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ShoppingCart = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.06674 4.60875H21.3947C22.8308 4.60875 24 5.73188 24 7.10357C24 7.23245 23.9908 7.36133 23.9632 7.49022L22.4626 14.4684C22.3245 15.7296 21.2106 16.7054 19.8665 16.7054H7.59494C6.24166 16.7054 5.13694 15.7204 4.99885 14.4684L3.49827 7.49022C3.47986 7.36133 3.46145 7.23245 3.46145 7.10357C3.46145 7.06323 3.46581 7.02071 3.47029 6.97708C3.47501 6.93108 3.47986 6.88384 3.47986 6.83659C3.47986 6.82832 3.478 6.82004 3.47595 6.81093C3.47345 6.79977 3.47066 6.78736 3.47066 6.77215V2.8412H0.920598C0.414269 2.8412 0 2.42693 0 1.9206C0 1.41427 0.414269 1 0.920598 1H5.81818C6.32451 1 6.73878 1.41427 6.73878 1.9206C6.73878 2.42693 6.32451 2.8412 5.81818 2.8412H5.31185V4.72842C5.55121 4.65478 5.79977 4.60875 6.06674 4.60875ZM19.8665 14.8642C20.29 14.8642 20.6306 14.5696 20.6306 14.2106L22.1588 7.10357C22.1588 6.74453 21.8182 6.44994 21.3947 6.44994H6.06674C5.64327 6.44994 5.30265 6.74453 5.30265 7.10357L6.83084 14.2106C6.83084 14.5696 7.17146 14.8642 7.59494 14.8642H19.8665ZM9.61097 19.0253C8.59831 19.0253 7.76978 19.817 7.76978 20.8021C7.76978 21.7871 8.59831 22.5788 9.61097 22.5788C10.6236 22.5788 11.4522 21.7871 11.4522 20.8021C11.4522 19.817 10.6328 19.0253 9.61097 19.0253ZM17.9977 19.0253C16.985 19.0253 16.1565 19.817 16.1565 20.8021C16.1565 21.7871 16.985 22.5788 17.9977 22.5788C19.0103 22.5788 19.8389 21.7871 19.8389 20.8021C19.8389 19.817 19.0103 19.0253 17.9977 19.0253Z" />
  </SvgIconStyled>
);

export default React.memo(ShoppingCart);
