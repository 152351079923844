// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Info = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h19.5v19.5H0z" />
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M9.8,0C4.4,0,0,4.4,0,9.8c0,0,0,0,0,0c0,5.4,4.4,9.8,9.8,9.8v0c5.4,0,9.8-4.4,9.8-9.8S15.2,0,9.8,0z M9.8,17.8   c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S14.2,17.8,9.8,17.8z" />
      <path d="M9.8,8.4c-0.6,0-1.1,0.5-1.1,1.1v0V14c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1V9.5C10.9,8.9,10.4,8.4,9.8,8.4z" />
      <polygon points="8.4,5.8 8.4,5.8 8.4,5.8  " />
      <path d="M9.8,4.4C9,4.4,8.4,5,8.4,5.8c0,0.7,0.6,1.3,1.3,1.3c0,0,0,0,0,0c0.7,0,1.3-0.6,1.3-1.3   C11.1,5,10.5,4.4,9.8,4.4z" />
    </g>
  </SvgIconStyled>
);

export default Info;
