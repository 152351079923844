import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    padding: '16px 20px'
  },
  dialogContent: {
    padding: 20
  },
  dialogActions: {
    padding: '15px 20px 20px'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  }
}));

const SchedulingDialog = ({ open, close, openMarkComplete, onMarkCompleteRequest, setState, loading }) => {
  const classes = useStyles();
  const calendlyURL = process.env.CALENDLY_URL;

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: e => {
      console.log('onEventScheduled', e.data.payload);
      setState({ dateScheduled: true });
      onMarkCompleteRequest(true);
    }
  });

  const onBack = () => {
    openMarkComplete();
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle className={classes.dialogHeader}>
        <Typography className={classes.title}>Choose date and time</Typography>
        <IconButton className={classes.closeBtn} onClick={close} disabled={loading}>
          <CloseIcon style={{ fontSize: 25 }} />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        <InlineWidget url={calendlyURL} />
        {/* <InlineWidget url="https://calendly.com/scholar-dev-uiowa/initial-sdc?back=1&month=2023-05" /> */}
      </DialogContent>

      <Divider />

      <DialogActions className={classes.dialogActions}>
        <ButtonCancel variant="text" onClick={onBack} disabled={loading}>
          Back
        </ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};

// SchedulingDialog.propTypes = {
//   setShowSchedule: PropTypes.func.isRequired,
//   setScheduling: PropTypes.func.isRequired
// };

export default SchedulingDialog;
