import { DarkWebMan } from 'components/icons';
import InstantScanList from './InstantScanList';

export default {
  name: 'instantScan',
  list: InstantScanList,
  edit: InstantScanList,
  icon: DarkWebMan,
  options: {
    label: 'DW Instant Scan',
    tooltip: 'Dark Web Instant Scan',
    hasMaskMode: true,
    hasMobileSublist: false,
    hasRightIcon: true,
    hasLeftIcon: true
  }
};
