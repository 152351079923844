import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Container, TextField, Typography } from 'components';
import { validateNumber } from './helpers';

function Parameters({ state, setState }) {
  const handleParamChange = (value, index) => {
    setState(state => {
      return {
        ...state,
        parameters: state.parameters.map((param, idx) => {
          if (idx !== index) return param;
          return {
            ...param,
            value,
            ...validateNumber({ value, constraints: param.constraints })
          };
        })
      };
    });
  };

  const handleFrequencyDaysChange = e => {
    const param = 'frequency_days';
    const error = validateNumber({
      value: e.target.value === '' ? '' : Number(e.target.value),
      constraints: {
        integer: true,
        minimum: 1,
        maximum: 365
      }
    });

    const newVal = e.target.value === '' ? '' : Number(e.target.value);
    setState(state => {
      return {
        ...state,
        subscription: { ...state.subscription, [param]: newVal },

        subscription_validation: {
          ...state.subscription_validation,
          [`${param}_error`]: error.error,
          [`${param}_error_text`]: error.error_text
        }
      };
    });
  };

  return (
    <>
      {/* Parameters */}
      {state.parameters.map((param, index) => (
        <Container.Grid py={0.5} container alignItems="center" key={param.parameter_display_name}>
          <Container.Grid item xs={9} pr={1}>
            <Typography.p>{param.parameter_display_name}</Typography.p>
          </Container.Grid>
          <Container.Grid item xs={3}>
            <TextField
              inputProps={{ min: param.constraints.minimum, max: param.constraints.maximum }}
              fullWidth
              error={param.error}
              helperText={param.error_text}
              integerOnly
              type="number"
              onChange={e => handleParamChange(e.target.value, index)}
              value={param.value}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={
                param.parameter_name === 'percentage_threshold'
                  ? {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }
                  : null
              }
            />
          </Container.Grid>
        </Container.Grid>
      ))}

      {/* Follow up reminder */}
      {Boolean(state?.push_notification?.show_frequency_days) && (
        <Container.Grid py={0.5} container alignItems="center">
          <Container.Grid item xs={9} pr={1}>
            <Typography.p>Send follow up reminder after how many days</Typography.p>
          </Container.Grid>
          <Container.Grid item xs={3}>
            <TextField
              inputProps={{ min: '1', max: '365' }}
              fullWidth
              error={state.subscription_validation.frequency_days_error}
              helperText={state.subscription_validation.frequency_days_error_text}
              integerOnly
              type="number"
              onChange={handleFrequencyDaysChange}
              value={state.subscription.frequency_days === null ? 1 : state.subscription.frequency_days}
            />
          </Container.Grid>
        </Container.Grid>
      )}
    </>
  );
}

export default Parameters;
