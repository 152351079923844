import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import {
  ArrowRightIcon,
  Button,
  Grid,
  Paper,
  RadioGroup,
  RadioInput,
  SaveIcon,
  TextField,
  Typography,
} from '@trustsecurenow/components-library';

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const MediaAndDevices = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };

    if (name === 'portable_media_devices' && value === 'No') {
      newState[isHSN ? 'phi_on_portable_media' : 'pii_on_portable_media'] = null;
      newState.tablets = null;
      newState.list_portable_devices = '';
    }
    if (name === 'backup_media' && value === 'No') {
      newState[isHSN ? 'phi_on_backup_media' : 'pii_on_backup_media'] = null;
    }
    if (((isHSN && name === 'phi_on_cloud_backup') || (!isHSN && name === 'pii_on_cloud_backup')) && value === 'No') {
      newState.backup_media_details = '';
    }
    if (name === 'smartphones' && value === 'No') {
      newState[isHSN ? 'phi_on_smartphones' : 'pii_on_smartphones'] = null;
      newState.list_smartphone_vendors = '';
    }

    setState(newState);
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      {/* Portable media */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              {/* Portable media title */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      1.Portable media
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      Portable media includes: USB drives, CD-ROM, DVD-ROM, Floppy Drives, Tablet Computers (iPad), etc.
                      Do you use portable media devices? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Portable media devices options */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name="portable_media_devices"
                  onChange={handleChangeState}
                  value={state.portable_media_devices}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                  ))}
                </RadioGroup>
              </Grid>

              {/* Portable media devices */}
              {state.portable_media_devices === 'Yes' ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                          {isHSN
                            ? 'Do you have patient information on any portable media (including USB drives, CDs, DVD, Tablets, etc.)? *'
                            : 'Do you have PII or sensitive data on any portable media (including USB drives, CDs, DVD, tablets, etc)? *'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Portable media */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name={isHSN ? 'phi_on_portable_media' : 'pii_on_portable_media'}
                      onChange={handleChangeState}
                      value={isHSN ? state.phi_on_portable_media : state.pii_on_portable_media}
                    >
                      {YES_NO_CHOICES.map(choice => (
                        <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                      ))}
                    </RadioGroup>
                  </Grid>

                  {/* Do you use tablets? */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                          Do you use tablets? *
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Tablets */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name="tablets"
                      onChange={handleChangeState}
                      value={state.tablets}
                    >
                      {YES_NO_CHOICES.map(choice => (
                        <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                      ))}
                    </RadioGroup>
                  </Grid>

                  {/* List portable devices */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      sx={{ marginTop: '16px' }}
                      value={state.list_portable_devices}
                      name="list_portable_devices"
                      label="List the portable media devices you are currently using"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Backup Media */}
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      2.Backup Media
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      Do you utilize physical backup media? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Backup media options */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name="backup_media"
                  onChange={handleChangeState}
                  value={state.backup_media}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                  ))}
                </RadioGroup>
              </Grid>

              {state.backup_media === 'Yes' ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                          {isHSN
                            ? 'Does your backup media store any ePHI? *'
                            : 'Does your backup media store any sensitive data? *'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Backup media options */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name={isHSN ? 'phi_on_backup_media' : 'pii_on_backup_media'}
                      onChange={handleChangeState}
                      value={isHSN ? state.phi_on_backup_media : state.pii_on_backup_media}
                    >
                      {YES_NO_CHOICES.map(choice => (
                        <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                      ))}
                    </RadioGroup>
                  </Grid>
                </>
              ) : null}

            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      3.Cloud-Based Backup Media
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>



              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      {isHSN
                        ? 'Do you use any cloud-based backup solutions for patient information? *'
                        : 'Do you use any cloud-based backup solutions for sensitive data? *'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Backup media options */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name={isHSN ? 'phi_on_cloud_backup' : 'pii_on_cloud_backup'}
                  onChange={handleChangeState}
                  value={isHSN ? state.phi_on_cloud_backup : state.pii_on_cloud_backup}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                  ))}
                </RadioGroup>
              </Grid>

              {/* Backup media details */}
              {((isHSN && state.phi_on_cloud_backup === 'Yes') ||
                (!isHSN && state.pii_on_cloud_backup === 'Yes')) && (
                  <>
                    <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        sx={{ marginTop: '16px' }}
                        value={state.backup_media_details}
                        name="backup_media_details"
                        label="Please provide us with any additional details regarding your
                          backup media"
                        fullWidth
                        rows={4}
                        multiline
                        onChange={handleChangeState}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Smartphone */}
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
            <Grid container>
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      4.Smartphone
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                      Do you utilize smartphones? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Smartphones options */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name="smartphones"
                  onChange={handleChangeState}
                  value={state.smartphones}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default"/>
                  ))}
                </RadioGroup>
              </Grid>

              {/* Information on smartphones */}
              {state.smartphones === 'Yes' ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3" fontSize={16} p={2} m={0.1}>
                          {isHSN
                            ? 'Do you have patient information on any smartphones (including emails, text messages, etc)? *'
                            : 'Do you have PII or sensitive data on any smartphone (including emails, text messages, etc)? *'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Information on smartphones options */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name={isHSN ? 'phi_on_smartphones' : 'pii_on_smartphones'}
                      onChange={handleChangeState}
                      value={isHSN ? state.phi_on_smartphones : state.pii_on_smartphones}
                    >
                      {YES_NO_CHOICES.map(choice => (
                        <RadioInput label={choice.label} labelPlacement="end" value={choice.value} color="default" />
                      ))}
                    </RadioGroup>
                  </Grid>

                  {/* List smartphone vendors */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      sx={{ marginTop: '16px' }}
                      value={state.list_smartphone_vendors}
                      name="list_smartphone_vendors"
                      label="List the smartphone vendors and/or phones"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Paper>

          {/* Action buttons */}
          <Grid item padding={2} xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="flex-end">
            <ButtonCancel variant="text" onClick={e => handleClose()}>
              Exit
            </ButtonCancel>
            <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty} >
              Save
            </Button>
            <Button
              color="success"
              startIcon={<ArrowRightIcon />}
              variant="contained"
              disableElevation
              sx={{ marginLeft: '30px' }}
              onClick={() => save('next')}
            >
              Save and Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MediaAndDevices.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default MediaAndDevices;
