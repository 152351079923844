// @flow
import React from 'react';

const FileSearch = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 14 22" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.674 16.7098C11.4347 16.6542 11.2021 16.5728 10.9803 16.4671C10.8871 16.5197 10.7822 16.5483 10.6751 16.5503H2.08092C1.89696 16.5503 1.72053 16.4772 1.59045 16.3471C1.46036 16.217 1.38728 16.0406 1.38728 15.8566V2.08092C1.38728 1.89696 1.46036 1.72053 1.59045 1.59045C1.72053 1.46036 1.89696 1.38728 2.08092 1.38728H10.6543C10.8383 1.38728 11.0147 1.46036 11.1448 1.59045C11.2749 1.72053 11.348 1.89696 11.348 2.08092V8.7815C11.7052 8.68442 12.0738 8.63544 12.4439 8.63584H12.7353V2.08092C12.7353 1.52903 12.516 0.999738 12.1258 0.609489C11.7355 0.21924 11.2062 0 10.6543 0L2.08092 0C1.52903 0 0.999738 0.21924 0.609489 0.609489C0.21924 0.999738 0 1.52903 0 2.08092L0 15.8566C0 16.4085 0.21924 16.9378 0.609489 17.3281C0.999738 17.7183 1.52903 17.9376 2.08092 17.9376H10.6543C11.0329 17.9346 11.4035 17.8283 11.7262 17.6303C12.0489 17.4323 12.3114 17.15 12.4855 16.8139C12.212 16.8093 11.9398 16.7744 11.674 16.7098Z" />
      <path d="M3.19071 11.2231C3.00675 11.2231 2.83032 11.2962 2.70023 11.4263C2.57015 11.5564 2.49707 11.7328 2.49707 11.9168C2.49707 12.1008 2.57015 12.2772 2.70023 12.4073C2.83032 12.5373 3.00675 12.6104 3.19071 12.6104H6.65892C6.84289 12.6104 7.01932 12.5373 7.1494 12.4073C7.27948 12.2772 7.35256 12.1008 7.35256 11.9168C7.35256 11.7328 7.27948 11.5564 7.1494 11.4263C7.01932 11.2962 6.84289 11.2231 6.65892 11.2231H3.19071Z" />
      <path d="M9.44742 4.09253H3.28788C3.10391 4.09253 2.92748 4.16561 2.7974 4.29569C2.66732 4.42578 2.59424 4.60221 2.59424 4.78617C2.59424 4.97014 2.66732 5.14657 2.7974 5.27665C2.92748 5.40673 3.10391 5.47981 3.28788 5.47981H9.44742C9.63138 5.47981 9.80781 5.40673 9.93789 5.27665C10.068 5.14657 10.1411 4.97014 10.1411 4.78617C10.1411 4.60221 10.068 4.42578 9.93789 4.29569C9.80781 4.16561 9.63138 4.09253 9.44742 4.09253Z" />
      <path d="M10.1411 8.32365C10.1411 8.13968 10.068 7.96325 9.93789 7.83317C9.80781 7.70308 9.63138 7.63 9.44742 7.63H3.28788C3.10391 7.63 2.92748 7.70308 2.7974 7.83317C2.66732 7.96325 2.59424 8.13968 2.59424 8.32365C2.59424 8.50761 2.66732 8.68404 2.7974 8.81413C2.92748 8.94421 3.10391 9.01729 3.28788 9.01729H9.44742C9.63138 9.01729 9.80781 8.94421 9.93789 8.81413C10.068 8.68404 10.1411 8.50761 10.1411 8.32365Z" />
      <path d="M17.7988 16.7653L15.5584 14.5248C15.5149 14.4854 15.4658 14.4526 15.4127 14.4277C15.7107 13.9433 15.8714 13.3871 15.8775 12.8184C15.9024 12.1689 15.7325 11.5268 15.3898 10.9745C15.047 10.4222 14.547 9.98497 13.9539 9.71896C13.3608 9.45296 12.7017 9.37032 12.0613 9.48165C11.4209 9.59299 10.8284 9.89322 10.3599 10.3438C9.89141 10.7943 9.56829 11.3747 9.43204 12.0102C9.2958 12.6458 9.35265 13.3076 9.5953 13.9106C9.83795 14.5136 10.2553 15.0303 10.7938 15.3944C11.3323 15.7585 11.9673 15.9532 12.6173 15.9537C13.2916 15.954 13.9499 15.7483 14.504 15.3641C14.5221 15.4041 14.5454 15.4414 14.5734 15.4751L16.8139 17.7156C16.8787 17.7798 16.9556 17.8307 17.0401 17.8652C17.1246 17.8997 17.2151 17.9172 17.3064 17.9167C17.3976 17.9172 17.4881 17.8997 17.5727 17.8652C17.6572 17.8307 17.734 17.7798 17.7988 17.7156C17.9198 17.5869 17.9871 17.417 17.9871 17.2404C17.9871 17.0638 17.9198 16.8939 17.7988 16.7653ZM12.6173 14.5664C12.2689 14.5488 11.9332 14.4292 11.652 14.2226C11.3708 14.016 11.1564 13.7314 11.0355 13.4042C10.9145 13.0769 10.8922 12.7213 10.9714 12.3814C11.0506 12.0416 11.2278 11.7325 11.481 11.4924C11.7342 11.2523 12.0523 11.0918 12.3959 11.0308C12.7394 10.9698 13.0933 11.011 13.4137 11.1492C13.7341 11.2875 14.0069 11.5167 14.1982 11.8085C14.3896 12.1002 14.4911 12.4418 14.4902 12.7907C14.4839 13.0303 14.4304 13.2663 14.3329 13.4852C14.2354 13.7042 14.0956 13.9017 13.9217 14.0666C13.7478 14.2315 13.5431 14.3606 13.3193 14.4463C13.0954 14.5321 12.8569 14.5729 12.6173 14.5664Z" />
    </svg>
  );
};

export default React.memo(FileSearch);
