// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Reset = props => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path d="M17.0212 13.7484C16.6276 13.5786 16.1723 13.7561 16.0025 14.142C14.4048 17.7849 10.1445 19.4443 6.517 17.8544C2.88181 16.2568 1.23015 11.9964 2.82778 8.35348C4.42542 4.71057 8.68578 3.04348 12.3133 4.64111C12.3596 4.65655 12.3981 4.65655 12.4444 4.67199L10.1213 5.67532C9.72769 5.84512 9.55018 6.30049 9.71998 6.69411C9.8126 6.88706 9.95923 7.0337 10.1445 7.10316C10.3297 7.18034 10.5381 7.18035 10.7388 7.09545L13.9495 5.71392L14.6595 5.4052C15.0531 5.2354 15.2307 4.78003 15.0609 4.38641L14.7521 3.67636L13.3706 0.465652C13.2008 0.0720327 12.7455 -0.105474 12.3518 0.0643231C11.9582 0.23412 11.7807 0.689478 11.9505 1.0831L12.8612 3.20556C8.46966 1.32236 3.34489 3.33677 1.40767 7.73605C-0.537276 12.1585 1.47712 17.3296 5.88412 19.2668C7.01867 19.7684 8.20724 20 9.38038 20C12.7454 20 15.9639 18.0396 17.4071 14.7594C17.5924 14.3813 17.4149 13.9259 17.0212 13.7484Z"/>
  </SvgIconStyled>
);

export default Reset;
