// @flow

const sraDocuments = {
  title: 'Add SRA Document',
  component: 'company/CompanySraDocumentsModal',
  noQuery: true,
  buttons: [],
  initialState: {
    year: new Date().toISOString(),
    name: '',
    description: '',
    fileType: '.doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls'
  }
};

export default sraDocuments;
