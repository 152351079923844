// @flow

const addUser = {
  title: 'New User',
  component: 'shared/users/ClientsUsersModalAddUser',
  noQuery: true,
  newTabSource: 'addUser',
  newItemSource: 'usersList',
  successfullyCreated: 'The user has been successfully created.',
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save',
      icon: 'Save2',
      style: {
        backgroundColor: '#0069FF'
      }
    }
    // other: [
    //   {
    //     type: 'resetPassword',
    //     label: 'Reset Password'
    //   } // ,
    //   // {
    //   //   type: 'resetMFAToken',
    //   //   label: 'Reset MFA Token'
    //   // }
    // ]
  },
  initialState: {
    group_id: '',
    tag_id: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '',
    phone_number_ext: '',
    cell_number: '',
    extension: '',
    mobile_number: '',
    send_welcome_message: false
  }
};

export default addUser;
