// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Like = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 70 70" role="img" aria-hidden="false">
      <circle cx="35" cy="35" r="35" fill="#0CCE6B" fillOpacity="0.2"/>
      <path d="M48.7824 28.8333H39.9266L41.1083 23.1726C41.4585 21.524 40.7144 19.8462 39.2263 18.8979L35.8416 16.724C34.5723 15.907 33.0112 15.7757 31.6398 16.3593C30.3705 16.9137 29.4806 17.9933 29.2326 19.321L27.2484 29.7524C27.1171 29.8545 27.0004 29.9421 26.8836 30.0442C26.1104 29.3001 25.0454 28.8333 23.8782 28.8333H20.3768C17.955 28.8333 16 30.8028 16 33.2101V49.6232C16 52.0304 17.955 54 20.3768 54H23.8782C25.0454 54 26.1104 53.5331 26.8836 52.7891C27.7736 53.5477 28.9991 54 30.3705 54H44.5223C47.163 54 49.2784 52.3076 49.5411 50.0754L53.7282 33.9833C53.8012 33.7353 53.8303 33.4873 53.8303 33.2393C53.8303 30.7737 51.6127 28.8333 48.7824 28.8333ZM25.3372 33.2393V49.594C25.3372 49.6086 25.3372 49.6086 25.3372 49.6232C25.3372 50.4256 24.6952 51.0821 23.8782 51.0821H20.3768C19.5744 51.0821 18.9179 50.4256 18.9179 49.6232V33.2101C18.9179 32.4077 19.5744 31.7511 20.3768 31.7511H23.8782C24.6952 31.7511 25.3372 32.4077 25.3372 33.2101C25.3372 33.2247 25.3372 33.2247 25.3372 33.2393ZM46.6523 49.594C46.6523 50.411 45.704 51.0821 44.5223 51.0821H30.3705C29.218 51.0821 28.2842 50.4256 28.2551 49.6232C28.2551 49.6086 28.2551 49.6086 28.2551 49.594V34.0125V33.2393C28.2551 33.2247 28.2551 33.2247 28.2551 33.2101C28.2842 32.4077 29.218 31.7511 30.3705 31.7511H32.0045L31.348 31.6344C30.5602 31.4885 30.035 30.7153 30.1809 29.9275L30.3851 28.8333L32.0921 19.8608C32.1796 19.3793 32.559 19.1459 32.7778 19.0437C33.2593 18.8395 33.799 18.8833 34.2513 19.175L37.6506 21.3489C38.1029 21.6407 38.3364 22.1221 38.2343 22.5744L36.9212 28.8333L36.7315 29.7232C36.7169 29.7816 36.7169 29.8253 36.7169 29.8837C36.7023 29.9129 36.6878 29.942 36.6732 29.9858C36.4835 30.8904 37.1692 31.7511 38.1029 31.7511H38.3801H39.2993H48.7678C49.9349 31.7511 50.8979 32.4223 50.8979 33.2393L46.6523 49.594Z" fill="#0CCE6B"/>
  </SvgIconStyled>
);

export default Like;
