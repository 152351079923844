import React, { useState } from 'react';
import { useAdminList, useAdminEmailSettings, useAdminNavigate } from '../hooks';
import { Container, CustomTable, CustomTooltip, Dialog, LazyIcon, TableCellField } from 'components';
import { IconButton } from '@material-ui/core';
import { TableCellText, Chip } from '../style/AdminCommon';
import ConfirmModal from './ConfirmModal';
import EmailSettingsModal from './EmailSettingsModal';
import Toolbar from './Toolbar';
import { Video as TrainingVideo } from 'apps/dashboard/microTraining/components';
import { decodeId } from 'helpers';
import { Link, PlayIcon } from '@trustsecurenow/components-library';

const filters = {
  is_publish: {
    label: 'Publish',
    icon: 'Publish',
    choices: [
      { value: '1', label: 'Yes' },
      { value: '0', label: 'No' },
    ],
  },
  publicly_accessible: {
    label: 'Publicly Accessible',
    icon: 'Users3',
    choices: [
      { value: '1', label: 'Yes' },
      { value: '0', label: 'No' },
    ],
  },
  hash_tags: {
    label: 'Hashtag',
    icon: 'Hashtag',
    hasSearch: true,
    choices: [],
  },
};

const actions = {
  unpublish: {
    label: 'Unpublish',
    icon: 'Publish',
    colorIcon: 'borderBase',
  },
  publish: {
    label: 'Publish',
    icon: 'Publish',
    colorIcon: 'colorDefault',
  },
  publicly_accessible: {
    label: 'Publicly Accessible',
    icon: 'Users3',
    colorIcon: 'colorDefault',
  },
  remove_publicly_accessible: {
    label: 'Hide from public',
    icon: 'EyeClose',
    colorIcon: 'colorDefault',
  },
};

const MicroTrainingsNewslettersList = ({ adminPage, resource }) => {
  const { isLoading, isFetching, data, dispatch, total, openDialog, idsSelected, page, perPage } = useAdminList(adminPage);
  const { navigateToCreate, navigateToEdit } = useAdminNavigate();
  const {
    action,
    data: settingsRecord,
    confirmModalConfig,
    openSettings,
    dispatch: dispatchEmail,
    invalidEmails
  } = useAdminEmailSettings(adminPage, resource);
  
  const [openVideo, setOpenVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);

  const onClickWatch = (video_number) => {
    if (video_number) {
      const src = `https://player.vimeo.com/video/${video_number}`;
      setVideoSrc(src);
      setOpenVideo(true);
    }
  };

  const onCloseWatch = () => {
    setVideoSrc(null);
    setOpenVideo(false);
  };

  return (
    <>
      <CustomTable
        toolbar={
          <Toolbar
            actions={actions}
            performAction={dispatch.actions}
            setSearchValue={dispatch.search}
            navigateToCreate={navigateToCreate}
            filters={filters}
            setFilters={dispatch.filters}
            numSelected={idsSelected.length}
            resource={resource}
          />
        }
        source={`admin/${adminPage}`}
        total={total}
        data={data}
        loading={isLoading}
        isFetching={isFetching}
        onSort={(sortValue) => dispatch.setSort(sortValue)}
        onChangePage={(page) => dispatch.setPage(page)}
        onChangePerPage={(perPage) => dispatch.setPerPage(perPage)}
        onLoad={(params) => dispatch.onLoad(params)}
        onSelectAll={(checked) => dispatch.onSelectAll(checked)}
        onSelectRow={(checked, id) => dispatch.onSelectRow(checked, id)}
        selectedRows={idsSelected}
        onClickRow={(id, _) => {
          navigateToEdit(id);
        }}
        preferences={{
          app: 'admin',
          tab: adminPage
        }}
        params={{
          page,
          perPage
        }}
      >
        <TableCellField
          renderComponent={({ id }) => (
            <TableCellText>{decodeId(id)}</TableCellText>
          )}
        />

        <TableCellField
          renderComponent={({ name }) => (
            <TableCellText>{name}</TableCellText>
          )}
        />

        <TableCellField
          renderComponent={({ hash_tags }) => (
            <TableCellText>{hash_tags}</TableCellText>
          )}
        />

        <TableCellField 
          renderComponent={({ published_date }) => (
            <TableCellText>{published_date}</TableCellText>
          )}
        />

        <TableCellField
          renderComponent={({ publicly_accessible, is_publish }) => (
            <Container.Grid alignItems="center">
              <CustomTooltip title={publicly_accessible ? 'Publicly Accessible' : 'Hidden from Public'} placement="top">
                <LazyIcon
                  component={publicly_accessible ? 'Users3' : 'EyeClose'}
                  color="grey"
                  size={1.2}
                  mr={1.6} 
                />
              </CustomTooltip>
              <CustomTooltip title={is_publish ? 'Published' : 'Unpublished'} placement="top">
                <LazyIcon
                  component="Publish"
                  color={is_publish ? 'grey' : 'borderBase'}
                  size={1.2}
                />
              </CustomTooltip>
            </Container.Grid>
          )}
        />

        {/* <TableCellField
          disableRowClick
          renderComponent={({ id }) => (
            <>
              <Chip
                borderRadius={5}
                color="--colorSystemWarning"
                label="Configure"
                onClick={() => dispatchEmail.openSettings('configure', id)}
                size="small"
                variant="outlined"
              />
            </>
          )}
        /> 

        <TableCellField renderComponent={() => <TableCellText></TableCellText>} />

        <TableCellField 
          disableRowClick 
          renderComponent={({ id }) => (
            <Container.Grid alignItems="center">
              <CustomTooltip title="Send Email" placement="top">
                <IconButton onClick={() => dispatchEmail.openSettings('sendEmail', id)}>
                  <LazyIcon component="EmailSend" color="colorDefault" size={1.2} />
                </IconButton>
              </CustomTooltip>
            </Container.Grid>
          )}
        /> */}

        <TableCellField
          disableRowClick
          renderComponent={({ link, video_number }) => (
            <Container.Grid alignItems="center">
              {adminPage === 'newsletters' ? (
                <CustomTooltip title="Download" placement="top">
                  <IconButton onClick={() => window.open(link)}>
                    <LazyIcon component="Download" color="colorDefault" size={1.2} />
                  </IconButton>
                </CustomTooltip>
              ) : (
                <Link onClick={() => onClickWatch(video_number)} component="button">
                  <PlayIcon style={{ padding: 4 }} />
                  Watch Video
                </Link>
              )}
            </Container.Grid>
          )}
        />
      </CustomTable>

      <EmailSettingsModal
        action={action}
        open={openSettings}
        data={settingsRecord}
        onChange={dispatchEmail.onChange}
        onClose={dispatchEmail.closeSettings}
        onSubmit={dispatchEmail.openConfirm}
        validateEmails={dispatchEmail.validateEmailAddresses}
        resource={resource}
        invalidEmails={invalidEmails}
      />
      
      <ConfirmModal
        config={confirmModalConfig}
        onClose={dispatchEmail.closeConfirm}
        onSubmit={dispatchEmail.submitConfirm} 
      />

      <Dialog
        open={openVideo || false}
        setOpen={onCloseWatch}
        maxWidth="md"
        onlyOk
        closeButton
        variant="standard"
      >
        <Container.Grid container>
          <TrainingVideo src={videoSrc} />
        </Container.Grid>
      </Dialog>
    </>
  );
};

export default MicroTrainingsNewslettersList;
