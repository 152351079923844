// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const EditIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.1794 2.8219L21.1809 0.814187C20.6442 0.296068 19.9503 0 19.2102 0C18.4607 0 17.7668 0.296068 17.2395 0.814187L5.24874 12.8142C5.07295 12.99 4.92492 13.1935 4.79539 13.3971C4.68437 13.6191 4.59181 13.8504 4.52705 14.0817C4.51779 14.1002 4.49931 14.1187 4.49006 14.1372L3.76837 15.4695L0.215583 22.0385C-0.173006 22.751 0.0213018 23.3431 0.345126 23.6577C0.530168 23.852 0.82622 24 1.19631 24C1.42761 24 1.68668 23.9445 1.96425 23.7872L8.62573 20.1789L9.86554 19.5127C9.90255 19.4942 9.93954 19.4757 9.9673 19.448C10.2079 19.3832 10.4299 19.2814 10.6427 19.1519C10.837 19.0409 11.0128 18.9021 11.1793 18.7355L23.1701 6.7448C24.2526 5.6623 24.2526 3.90439 23.1794 2.8219ZM9.8748 17.431C9.70826 17.5975 9.35668 17.7733 9.35668 17.7733C9.2364 17.7826 9.10686 17.8196 8.99584 17.8843L4.96193 20.0679L3.9349 19.0686L6.1277 15.0162C6.17396 14.9329 6.2017 14.8311 6.2202 14.7386C6.2202 14.7386 6.37749 14.313 6.57178 14.1187L16.0645 4.62606L19.46 7.8458L9.8748 17.431ZM21.8655 5.44025L20.7645 6.54125L17.3597 3.32151L18.5533 2.12799C18.7291 1.94295 18.9696 1.85042 19.2102 1.85042C19.4415 1.85042 19.682 1.94295 19.8578 2.12799L21.8655 4.12645C22.2263 4.48728 22.2263 5.07016 21.8655 5.44025Z" />
  </SvgIconStyled>
);

export default EditIcon;
