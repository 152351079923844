// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PasswordSecurity = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0954 22.1538H2.76923C2.26154 22.1538 1.84615 21.7385 1.84615 21.2308V11.4646C1.84615 11.0769 2.0954 10.7354 2.43694 10.6062C2.53848 10.56 2.64923 10.5415 2.76923 10.5415H14.2154C14.3723 10.5415 14.5108 10.5877 14.64 10.6615C15.2677 10.4585 15.9693 10.3477 16.7262 10.3108C16.2831 9.36 15.3323 8.69538 14.2154 8.69538H4.28309V5.67693C4.28309 3.56309 6.16616 1.84615 8.48308 1.84615C10.7815 1.84615 12.6554 3.55385 12.6554 5.65847C12.6554 6.16616 13.0708 6.58154 13.5785 6.58154C14.0862 6.58154 14.5016 6.16616 14.5016 5.65847C14.5016 2.53847 11.8062 0 8.48308 0C5.15077 0 2.43694 2.5477 2.43694 5.67693V8.72309C1.07078 8.88001 0 10.0523 0 11.4646V21.2308C0 22.7538 1.24615 24 2.76923 24H12.8585C12.1385 23.5015 11.5569 22.8646 11.0954 22.1538ZM17.6678 11.8615C14.2339 11.8615 11.437 14.5754 11.437 17.9077C11.437 21.24 14.2339 23.9538 17.6678 23.9538C21.1016 23.9538 23.8986 21.24 23.8986 17.9077C23.8986 14.5754 21.1016 11.8615 17.6678 11.8615ZM17.6678 22.1077C15.2493 22.1077 13.2832 20.2246 13.2832 17.9077C13.2832 15.5907 15.2493 13.7077 17.6678 13.7077C20.0862 13.7077 22.0524 15.5907 22.0524 17.9077C22.0524 20.2246 20.0862 22.1077 17.6678 22.1077ZM18.2584 16.0061C18.563 15.7108 19.0984 15.7569 19.4584 16.1169C19.8184 16.4769 19.8645 17.0123 19.5692 17.3169L18.9784 17.9077L19.5692 18.4984C19.8645 18.8031 19.8184 19.3384 19.4584 19.6984C19.0984 20.0584 18.563 20.1138 18.2584 19.8092L17.6676 19.2184L17.0768 19.8092C16.7722 20.1046 16.2369 20.0584 15.8769 19.6984C15.5169 19.3384 15.4615 18.8031 15.7661 18.4984L16.3569 17.9077L15.7661 17.3169C15.4707 17.0123 15.5169 16.4769 15.8769 16.1169C16.2369 15.7569 16.7722 15.7015 17.0768 16.0061L17.6676 16.5969L18.2584 16.0061Z" />
  </SvgIconStyled>
);

export default React.memo(PasswordSecurity);
