// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Close = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 19 20" role="img" aria-hidden="false">
      <path d="M10.8925 10.4631L18.6725 2.68308C19.0625 2.29308 19.0625 1.66308 18.6725 1.27308C18.2825 0.883081 17.6525 0.883081 17.2625 1.27308L9.4825 9.04308L1.7025 1.26308C1.3125 0.873081 0.6825 0.873081 0.2925 1.26308C-0.0975 1.65308 -0.0975 2.28308 0.2925 2.67308L8.0725 10.4531L0.2925 18.2331C-0.0975 18.6231 -0.0975 19.2531 0.2925 19.6431C0.4925 19.8431 0.7425 19.9331 1.0025 19.9331C1.2625 19.9331 1.5125 19.8331 1.7125 19.6431L9.4925 11.8631L17.2725 19.6431C17.4725 19.8431 17.7225 19.9331 17.9825 19.9331C18.2425 19.9331 18.4925 19.8331 18.6925 19.6431C19.0825 19.2531 19.0825 18.6231 18.6925 18.2331L10.8925 10.4631Z" />
  </SvgIconStyled>
);

export default Close;
