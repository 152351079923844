import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { SelectField, TextField, ButtonCancel, RichTextEditor } from 'components';
import { Save2 } from 'components/icons';
import { useSRA } from 'components/pages/SRA/context/sraContext';
import * as sra from 'helpers/apis/services/sra';
import { cleanTextFromHtml, USER_ROLES } from 'helpers';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { DatePickerField } from '../../../components';
import { BlueButton } from '../../../style';
import { getRecommendationSections, getRecommendationSubsections, getRecommendationPriorities } from '../../../utils';
import { chunk } from 'lodash';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    padding: '16px 20px'
  },
  dialogContent: {
    padding: 20,
    maxHeight: '70vh',
    '& .ql-disabled': {
      opacity: 0.5
    }
  },
  dialogActions: {
    padding: '15px 20px 20px'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  },
  label: {
    margin: '0 0 6px 0',
    fontSize: 12
  },
  clearDateBtn: {
    position: 'absolute',
    right: 50,
    top: 6
  }
}));

const initialState = {
  section: '',
  subsection: '',
  priority: '',
  finding: '',
  recommendation: '',
  notes: '',
  due_date: null
};

const AddEditRecommendation = ({ open, close, recommendation, isHSN }) => {
  const classes = useStyles();
  const [data, setData] = useState(initialState);
  const [originalRecommendation, setOriginalRecommendation] = useState(recommendation?.recommendation);
  const [originalFinding, setOriginalFinding] = useState(recommendation?.finding);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const isEdit = recommendation?.id;
  const {
    state: { revisionId },
    clientId
  } = useSRA();
  const title = isEdit ? 'Edit Recommendation' : 'New Recommendation';
  const isAdmin = userRole === USER_ROLES.ADMINISTRATOR;
  const isPartnerAdmin = userRole === USER_ROLES.PARTNER_ADMINISTRATOR;
  const disablePartnerAdminEditing = isEdit && isPartnerAdmin && recommendation?.admin_created;

  useEffect(() => {
    if (open) setData(recommendation || initialState);
    setOriginalFinding(recommendation?.finding);
    setOriginalRecommendation(recommendation?.recommendation);
  }, [recommendation, open]);

  useEffect(() => {
    const isFindingChanged = !compareHTML(originalFinding, data.finding);
    const isRecommendationChanged = !compareHTML(originalRecommendation, data.recommendation);
    const isPriorityChanged = isAdmin && recommendation?.priority !== data.priority;

    const compareNotes = () => {
      // if both are falsy, they are effectively equal
      if(!recommendation?.notes && !data?.notes) return true;
      return recommendation?.notes === data?.notes;
    }
    const isDataChanged =
      recommendation?.section !== data.section ||
      recommendation?.subsection !== data.subsection ||
      isPriorityChanged ||
      !compareNotes() ||
      recommendation?.due_date !== data.due_date ||
      isFindingChanged ||
      isRecommendationChanged;

    const hasFinding = cleanTextFromHtml(data.finding).trim() !== '';
    const hasRecommendation = cleanTextFromHtml(data.recommendation).trim() !== '';
    const isRequiredDataFilled =
      data.section && (isHSN && isAdmin ? data.priority : true) && hasFinding && hasRecommendation;
    setIsDisabled(!(isDataChanged && isRequiredDataFilled));
  }, [recommendation, data, isHSN, isAdmin]);

  const onChangeData = (name, value) => {
    setData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const updateRecommendation = useCallback(() => {
    setIsDisabled(true);
    setIsLoading(true);

    const newData = {
      ...data,
      finding: disablePartnerAdminEditing ? recommendation.finding : data.finding
    };

    sra
      .updateRecommendation(newData, clientId, revisionId)
      .then(res => {
        enqueueAlertSnackbar(res?.data?.description || 'Recommendation Updated!',
          { props: { severity: 'success' } }
        );
      })
      .catch(error => {
        enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong',
          { props: { severity: 'error' } }
        );
      })
      .finally(() => {
        setIsDisabled(false);
        setIsLoading(false);
        close(true);
      });
  }, [close, data, clientId, revisionId, disablePartnerAdminEditing, recommendation?.finding]);

  const createRecommendation = useCallback(() => {
    setIsDisabled(true);
    setIsLoading(true);

    const newData = {
      ...data,
      priority: isAdmin ? data.priority : 'Addressable'
    };

    sra
      .createRecommendation(newData, clientId, revisionId)
      .then(res => {
        enqueueAlertSnackbar(res?.data?.description || 'Recommendation Created!',
          { props: { severity: 'success' } }
        );
      })
      .catch(error => {
        enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong',
          { props: { severity: 'error' } }
        );
      })
      .finally(() => {
        setIsDisabled(false);
        setIsLoading(false);
        close(true);
      });
  }, [close, data, clientId, revisionId, isAdmin]);

  const onSubmit = e => {
    e.preventDefault();
    if (isEdit) {
      updateRecommendation();
    } else {
      createRecommendation();
    }
  };

  const compareHTML = (content1, content2) => {
    if(typeof content1 === 'string' && typeof content2 === 'string') {
      // The <RichTextEditor> component will insert a <p> tag into any text that does not have an outer HTML tag.
      // All recommendations that are pre-filled from the backend will be missing outer HTML tags.
      // This means that every recommendation is mutated by the RichTextEditor as soon as it is opened.
      // We need to account for this in the comparison of HTML, otherwise every recommendation
      // will be considered "changed" immediately upon opening of the modal, which will cause the
      // save button to be enabled when it should be disabled.
      const string1 = content1.startsWith('<p>')
        ? content1
        : `<p>${content1}</p>`
      const string2 = content2.startsWith('<p>')
        ? content2
        : `<p>${content2}</p>`
      return formattedHTML(string1) === formattedHTML(string2)
    }
    else {
      return content1 === content2
    }

  }

  const formattedHTML = value => {
    if (!value) return value;

    // 1) The editor handle text inserted creating multiples paragraph tags (depending on the number of \n),
    // if some kind of style is applied, sometimes it replaces a break with an empty span with the style applied
    // other times it also adds a span with class="ql-cursor" and an invisible U+feff char.
    // When reopening the editor after saving, the span tags are cleaned by the editor.
    // Also, the editor does a trim, removing all breaks at the end, if any.

    // 2) In some cases the properties of style applied change order after reopening the editor.
    // for example <strong style="font-size: 0.75em; font-family: serif;"> becomes
    // <strong style="font-family: serif; font-size: 0.75em;"> (font-size and font-family are inverted)

    // So, for an effective comparison, we need to take all this factors into consideration.

    // This is for point 1)
    const formattedValue = value
      .split(/<p>/) // takes every paragraph tag
      .map(el => el.replace(/<span\sclass="ql-cursor">.?<\/span>/g, '').replace('﻿', '')) // removes the span with "ql-cursor" class and U+feff char
      .filter(el => !el.match(/<span\s(style=".+")?><\/span><\/p>/)) // removes the empty span with style applied
      .join('<p>')
      .replaceAll(/(<p><br><\/p>)+$/g, ''); // removes breaks at the end

    // This is for point 2)
    const sortedValue = formattedValue
      .split('style=')
      .map(el => {
        if (el[0] === '"') {
          const lastIndex = el.lastIndexOf('"');
          const stringToEdit = el.slice(1, lastIndex); // takes everything between the double quotes after style (double quotes not included)
          const sortedStyleByPropName = chunk(stringToEdit.split(' '), 2) // create a an array of [prop, value] and sort them by prop name
            .sort((a, b) => {
              if (a[0] === b[0]) return 0;
              if (a[0] < b[0]) return -1;
              return 1;
            })
            .flat()
            .join(' ');
          return `${sortedStyleByPropName}${el.slice(lastIndex + 1, el.length)}"`; // put the string with props sorted by value back to the original string, including final double quote
        }
        return el;
      })
      .join('style="'); // put back style, included initial double quote

    return sortedValue; // return the clean HTML with sorted style props
  };

  return (
    <>
      <Dialog open={open} onClose={() => close()} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogHeader}>
          <Typography className={classes.title}>{title}</Typography>

          <IconButton className={classes.closeBtn} onClick={() => close()}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </DialogTitle>

        <Divider />

        <form onSubmit={onSubmit}>
          <DialogContent className={classes.dialogContent}>
            {/* Section */}
            <Box mb={3}>
              <SelectField
                allowEmpty={false}
                fullWidth
                size="small"
                id="section"
                label="Section"
                choices={getRecommendationSections()}
                name="section"
                required
                value={data.section}
                onChange={e => onChangeData(e.target.name, e.target.value)}
              />
            </Box>

            {/* Subsection */}
            <Box mb={isHSN ? 3 : 2}>
              <SelectField
                deselectValue="Please Select"
                allowEmpty={false}
                fullWidth
                size="small"
                id="subsection"
                label="Subsection"
                choices={getRecommendationSubsections(isHSN)}
                name="subsection"
                value={data.subsection}
                onChange={e => onChangeData(e.target.name, e.target.value)}
                disabled={disablePartnerAdminEditing}
              />
            </Box>

            {/* Priority */}
            {isHSN && isAdmin && (
              <Box mb={2}>
                <SelectField
                  allowEmpty={false}
                  fullWidth
                  size="small"
                  id="priority"
                  label="Priority"
                  choices={getRecommendationPriorities()}
                  name="priority"
                  required
                  value={data.priority}
                  onChange={e => onChangeData(e.target.name, e.target.value)}
                />
              </Box>
            )}

            {/* Finding */}
            <Box mb={2}>
              <p className={classes.label}>Finding*</p>
              <RichTextEditor
                name="finding"
                height="150px"
                fontSize="14px"
                placeholder="Type something here..."
                currentState={data.finding}
                onChangeEditorState={(content, source) => {
                  if(source === 'api') {
                    setOriginalRecommendation(content);
                  };
                  onChangeData('finding', content)
                }}
                disabled={disablePartnerAdminEditing}
                containerComponent='div'
              />
            </Box>

            {/* Recommendation */}
            <Box mb={1}>
              <p className={classes.label}>Recommendation*</p>
              <RichTextEditor
                name="recommendation"
                height="150px"
                fontSize="14px"
                placeholder="Type something here..."
                currentState={data.recommendation}
                onChangeEditorState={(content, source) => {
                  if(source === 'api') {
                    setOriginalRecommendation(content);
                  };
                  onChangeData('recommendation', content)
                }}
                containerComponent='div'
              />
            </Box>

            {/* Notes */}
            <Box mb={0.5}>
              <TextField
                name="notes"
                label="Notes"
                multiline
                rows={3}
                maxRows={3}
                fullWidth
                value={data.notes}
                onChange={e => onChangeData(e.target.name, e.target.value)}
              />
            </Box>

            {/* Due Date */}
            <Box position="relative">
              <DatePickerField
                id="due_date"
                name="due_date"
                label="Due date"
                allowKeyboardControl={false}
                value={data.due_date}
                onChange={date => {
                  const newDate = date ? format(date, 'yyyy-MM-dd') : null;
                  onChangeData('due_date', newDate);
                }}
                TextFieldComponent={params => (
                  <TextField
                    {...params}
                    size="small"
                    variant={false}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true
                    }}
                  />
                )}
              />
              {data.due_date && (
                <IconButton onClick={() => onChangeData('due_date', null)} className={classes.clearDateBtn}>
                  <CloseIcon style={{ fontSize: 20 }} />
                </IconButton>
              )}
            </Box>
          </DialogContent>

          <Divider />

          <DialogActions className={classes.dialogActions}>
            <ButtonCancel variant="text" onClick={() => close()}>
              Cancel
            </ButtonCancel>

            <BlueButton
              type="submit"
              startIcon={!isLoading ? <Save2 /> : <CircularProgress size={16} />}
              variant="outlined"
              disableElevation
              disabled={isDisabled || isLoading}
              onClick={onSubmit}
            >
              Save
            </BlueButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddEditRecommendation;
