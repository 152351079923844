export const CONTENT_ADMIN = 'contentadmin';
// ------- Content Admin root path ---------
export const CONTENT_ADMIN_ROOT_PATH = '/contentadmin/dashboard';

//  ------- Content Admin Pages ----------

export const CONTENT_ADMIN_EMAIL_TEMPLATES = 'emailTemplates';
export const CONTENT_ADMIN_LANDING_PAGES = 'landingPages';
export const CONTENT_ADMIN_SENDING_PROFILES = 'sendingProfiles';
export const CONTENT_ADMIN_CAMPAIGN = 'campaign';
export const CONTENT_ADMIN_SCENARIO = 'scenarios';
export const CONTENT_ADMIN_INBOX = 'inbox';
export const CONTENT_ADMIN_MT_NL = 'mt_nl';
export const CONTENT_ADMIN_TEST_TEMPLATES = 'test_template';

//  ------- Content Admin PickList ----------

export const CONTENT_ADMIN_PICKLIST_COUNTRIES = 'countries';
export const CONTENT_ADMIN_PICKLIST_DIFFICULTIES = 'difficulties';
export const CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED = 'difficulties/?grouped=1';
export const CONTENT_ADMIN_PICKLIST_TAGS = 'tags';
export const CONTENT_ADMIN_PICKLIST_DOMAINS = 'domains';

//  ------- Scenarios ----------

export const FROM_NAME = 'FROM_NAME';
export const EMAIL_LOCAL_PART = 'EMAIL_LOCAL_PART';

//  ------- Users ----------
export const MYCOMPANY_EMPLOYEES_BASE_URL = '/myCompany/employees';
export const CLIENTS_USERS_BASE_URL = '/clients/users/:id';
export const DIRECTORY_SYNC_CLIENTS = '/clients/directorySync';
export const WELCOME_MESSAGE_CLIENTS_DIRECTORYSYNC = '/clients/directorySync/welcomeMessage';
export const DIRECTORY_SYNC_MYCOMPANY = '/myCompany/directorySync';
export const WELCOME_MESSAGE_MYCOMPANY_DIRECTORYSYNC = '/myCompany/directorySync/welcomeMessage';
export const WELCOME_MESSAGE_CLIENTS = '/clients/welcomeMessage';
export const WELCOME_MESSAGE_MYCOMPANY = '/myCompany/welcomeMessage';
export const CSV_BULK_UPLOAD_CLIENTS = '/clients/csvBulkUpload';
export const CSV_BULK_UPLOAD_MYCOMPANY = '/myCompany/csvBulkUpload';

export const TEST_MT_NL_OPTIONS = [
  {
    label: 'Micro Training',
    value: 'micro_trainings'
  },
  {
    label: 'News Letter',
    value: 'newsletters'
  }
];
