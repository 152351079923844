// @flow
import React, { type Element } from 'react';
import { TableList } from 'components';

type PageDarkWebMonitoringTypes = { id: string, toolbar: any };

const PageDarkWebMonitoring = ({ id, toolbar }: PageDarkWebMonitoringTypes): Element<*> => (
  <TableList source="darkWebMonitoring" id={id} toolbar={toolbar} />
);

export default PageDarkWebMonitoring;
