import React, { Children, cloneElement, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Container } from '../types';
import { IconButton } from '@material-ui/core';
import { Delete } from '../icons';

const DynamicFormContainer = styled(Container.Grid)``;
const DynamicFormWrapper = styled(Container.Grid)``;
const DynamicFormButton = styled(Container.Grid)``;
const DynamicFormRow = styled(Container.Grid)``;
const InputGrid = styled(Container.Grid)``;

const DynamicForm = ({ name, children, addButton }) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({ name, control });

  const [formState, setFormState] = useState({});

  useEffect(() => {
    Children.forEach(children, child => {
      setFormState(prev => ({
        ...prev,
        [child.props.name]: ''
      }));
    });
  }, []);

  const handleAddNewForm = () => {
    append(formState);
  };

  return (
    <DynamicFormContainer alignItems={'flex-start'}>
      <DynamicFormWrapper md={8} alignItems={'center'}>
        {fields.map((field, index) => {
          return (
            <>
              <DynamicFormRow xs={11} key={`form-${index}`}>
                {Children.map(children, (child, childIndex) => {
                  return (
                    <InputGrid {...child.props.gridSize} key={field.id} pr={3} mb={2.5}>
                      {cloneElement(child, {
                        ...child.props,
                        name: `${name}[${index}].${child.props.name}`
                      })}
                    </InputGrid>
                  );
                })}
              </DynamicFormRow>

              {index > 0 && (
                <DynamicFormRow xs={1} mb={2.5}>
                  <IconButton onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </DynamicFormRow>
              )}
            </>
          );
        })}
      </DynamicFormWrapper>

      <DynamicFormButton md={4} pt={0.5}>
        {cloneElement(addButton, {
          onClick: handleAddNewForm
        })}
      </DynamicFormButton>
    </DynamicFormContainer>
  );
};

export default DynamicForm;
