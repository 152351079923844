import React, { useEffect } from 'react'
import { Checkbox, Chip as MuiChip, IconButton, FormControlLabel, Link } from '@material-ui/core';
import { Container, Dialog, Typography, Button, LazyIcon , SubmissionModal} from 'components';
import FindClientModal from './FindClientModal'
import useMoveUser from './useMoveUser'

const Chip = ({ label, onDelete, fullwidth, ...props}) => {
  return (
    <MuiChip
      label={label}
      onDelete={onDelete}
      deleteIcon={
        <LazyIcon
          component="Close"
          color="--colorGreyLight1"
          style={{ width: '12px', padding: '0 8px',  }}
        />
      }
      style={{
        backgroundColor: 'var(--backgroundDefault)',
        borderRadius: '5px',
        margin: '4px 8px' ,
        fontSize: '13px',
        width: fullwidth ? '90%' : '140px'
      }}
      {...props}
    />
  )
}

const MoveUsersModal = ({ open, onClose, onRefetch, dispatchTable, item }) => {
  const state = useMoveUser({ dispatchTable, clientId: item, onClose, onRefetch })
  const totalUsers = state.tableRowSelectedList.length
  const submitLabel = `Move User${totalUsers > 1 ? 's' : ''}`

  const handleClose = () => {
    state.dispatch.resetProps()
    state.dispatch.setOpenFindClientModal();
    onClose()
  }

  useEffect(() => {
    if (!totalUsers) onClose()
  }, [totalUsers])

  return (
    <Dialog
      open={open || false}
      setOpen={handleClose}
      onlyOk
      maxWidth={totalUsers >= 25 ? 'md' : 'sm'}
      variant="standard"
    >
      <Container.Grid direction="row" justify="space-between" alignItems="center" pb={1} bb={1}>
        <div>
          <Typography.p bold m={0.2} fontSize={14}>Configure</Typography.p>
          <Typography.p m={0.2} fontSize={14}>
            The selected user(s) will be moved to the desired client
          </Typography.p>
        </div>

        <IconButton onClick={handleClose}>
          <LazyIcon component="Close" color="colorDefault" size={1} />
        </IconButton>
      </Container.Grid>

      <Container.Grid container pt={4} pb={4}>
        <Container.Grid item md={12}>
          <Typography.p fontSize={14}>
            Your list has {totalUsers} user(s)
          </Typography.p>
        </Container.Grid>

        <Container.Grid item md={12}>
          <Container.Grid container>
            {state.tableRowSelectedList.map(item => (
              <Container.Grid md={totalUsers >= 25 ? 3 : 4} lg={totalUsers >= 25 ? 2 : 3}>
                <Chip
                  key={item.id}
                  label={`${item.last_name}, ${item.first_name}`}
                  onDelete={() => state.dispatch.handleUnselectUser(item.id, item)}
                  fullwidth
                />
              </Container.Grid>
            ))}
          </Container.Grid>
        </Container.Grid>

        <Container.Grid item md={12}>
          <Container.Grid container pt={4} pb={2}>
            <Container.Grid item md={4} alignItems="center">
              <Typography.p m={0.4} fontSize={14}>
                Move selected users to:
              </Typography.p>
            </Container.Grid>

              {state.clients.selected ? (
                <Container.Grid item md={12}>
                  <Chip
                    label={state.clients.selected?.name}
                    onDelete={() => {
                      state.dispatch.onDeleteItemSelected({ isClient: false, cleanSearch: true })}
                    }
                  />
                </Container.Grid>
              ) : (
                <Container.Grid item md={8}>
                  <Button
                    backgroundColor="colorBaseBase"
                    backgroundColorHover="colorBaseBase"
                    backgroundDisabled="colorBaseDisabled"
                    onClick={() => state.setOpenFindClientModal(true)}
                  >
                    <LazyIcon component="UserSearch" color="commonWhite" mr={2} />
                    Find Client
                  </Button>
                </Container.Grid>
              )}

              <Container.Grid item md={12} pt={3}>
                <FormControlLabel
                  label="Include user training data"
                  style={{ color: 'var(--colorDefault)', fontSize: 14 }}
                  control={
                    <Checkbox
                      value={state.includeTrainingData}
                      checked={state.includeTrainingData}
                      onChange={(e) => state.setIncludeTrainingData(e.target.checked)}
                      name="includeTrainingData"
                      color="colorDefault"
                    />
                  }
                />
              </Container.Grid>

              <FindClientModal
                open={state.openFindClientModal}
                onClose={handleClose}
                {...state}
              />
          </Container.Grid>
        </Container.Grid>
      </Container.Grid>

      <Container.Grid container justify="flex-end" alignItems="center" bt={1} pt={1}>
        <Link
          style={{ color: 'var(--colorDefault)', textDecoration: 'underline' }}
          href="#"
          onClick={e => {
            e.preventDefault();
            state.dispatch.onDeleteItemSelected({ isClient: false })
            onClose();
          }}
          color="inherit"
        >
          Cancel
        </Link>

        <Button
          backgroundColor="colorSystemSuccess"
          backgroundColorHover="colorSystemSuccessHover"
          disabled={!state.clients.selected || state.isLoading}
          onClick={() => state.setOpenConfirmation(true)}
          ml={4}
        >
          <LazyIcon component="UserMove" color="commonWhite" mr={2} />
          {submitLabel}
        </Button>

        <SubmissionModal
          open={state.openConfirmation}
          onClose={() => state.setOpenConfirmation(false)}
          icon="Check"
          title="Confirmation Message"
          color="colorSystemSuccess"
          description={`Are you sure you want to move this user to client ${state.clients.selected?.name}`}
          submitButtonTitle="Yes"
          cancelButtonTitle="Cancel"
          onSubmit={state.dispatch.submit}
          disabled={state.isLoading}
        />

      </Container.Grid>
    </Dialog>
  )
}

export default MoveUsersModal
