import React, { createContext, useContext } from 'react';

const PreassessmentLayoutContext = createContext(null)


export const PreassessmentLayoutProvider = ({ children, value }) => {
  return (
    <PreassessmentLayoutContext.Provider value={value}>
      {children}
    </PreassessmentLayoutContext.Provider>
  )
}

export const usePreassessmentLayout = () => {
  return useContext(PreassessmentLayoutContext);
}
