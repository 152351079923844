// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Eye = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 15" role="img" aria-hidden="false">
      <path d="M23.0754 5.5381C20.5389 2.19804 16.5212 0.0294189 12 0.0294189C7.47879 0.0294189 3.46103 2.19804 0.924522 5.5381C0.701852 5.82854 0.498589 6.11898 0.304962 6.42878C-0.101654 7.05807 -0.101654 7.88098 0.304962 8.51027C0.498589 8.81039 0.701852 9.11051 0.924522 9.40095C3.46103 12.741 7.47879 14.9096 12 14.9096C16.5212 14.9096 20.5389 12.741 23.0754 9.40095C23.2981 9.11051 23.5014 8.82007 23.695 8.51027C24.1017 7.88098 24.1017 7.05807 23.695 6.42878C23.5014 6.11898 23.2981 5.82854 23.0754 5.5381ZM12 12.9637C8.33074 12.9637 5.02944 11.2985 2.83178 8.68453C2.2509 7.98748 2.2509 6.95157 2.83178 6.25452C5.02944 3.63088 8.32106 1.97537 12 1.97537C15.6789 1.97537 18.9803 3.64056 21.1779 6.25452C21.7588 6.95157 21.7588 7.98748 21.1779 8.68453C18.9803 11.2985 15.6789 12.9637 12 12.9637Z" />
      <path d="M12 12.3731C9.22145 12.3731 6.95605 10.1754 6.95605 7.46466C6.95605 4.75389 9.22145 2.55623 12 2.55623C14.7785 2.55623 17.044 4.75389 17.044 7.46466C17.044 10.1754 14.7785 12.3731 12 12.3731ZM12 4.4925C10.2864 4.4925 8.89232 5.82852 8.89232 7.46466C8.89232 9.10081 10.2864 10.4368 12 10.4368C13.7136 10.4368 15.1077 9.10081 15.1077 7.46466C15.1077 5.82852 13.7136 4.4925 12 4.4925Z" />
  </SvgIconStyled>
);

export default Eye;
