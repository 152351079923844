import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const ChipOutlineInfo = styled(Chip)`
  && {
    border: 1px solid var(--colorSystemInfo);
    background: none;
    color: var(--colorSystemInfo);
    font-weight: 500;
    ${({ br }) => `border-radius: ${br ? br + 'px' : 'var(--borderRadius)'};`}
    span {
      padding: 0 12px;
    }
    svg {
      color: var(--colorSystemInfo) !important;
      width: 20px;
      height: 20px;
    }
  }
`;
