/* eslint-disable react/prop-types */
import { CheckboxCheckedIcon, CheckboxIcon, Stack, Typography } from '@trustsecurenow/components-library';
import { cleanTextFromHtmlImproved } from 'helpers';
import React from 'react';

const FindingCell = props => {
  const isCompleted = props.row.date_completed;
  const Icon = isCompleted ? CheckboxCheckedIcon : CheckboxIcon;
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Icon color={isCompleted ? 'success' : 'default'} sx={{ fontSize: '2.4rem' }} />
      <Typography variant="body2" color="inherit">
        {cleanTextFromHtmlImproved(props.value)}
      </Typography>
    </Stack>
  );
};

export default FindingCell;
