import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, VideoModal } from 'components';
import { PlaySquareOutline } from 'components/icons';
import { CardStyled, CardTitle, CardBody, CardImg, CardContent, CardFooter, CardActions } from './style';
import { ButtonBaseFilled } from '../../style';

const AssessmentInfo = ({ task }) => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const { report_name, quiz_description, quiz_video_url } = task || {};

  return (
    <CardStyled>
      <CardTitle>{report_name}</CardTitle>

      <CardBody>
        <CardImg>
          <img src="/media/sra/assessment_info.svg" alt="task" />
        </CardImg>

        <CardContent>
          <Typography.p fontSize={14} mt="0px" mb="0px">
            {quiz_description}
          </Typography.p>

          <CardFooter>
            <CardActions>
              <ButtonBaseFilled
                variant="outlined"
                startIcon={<PlaySquareOutline mr={1.2} size={1.3} />}
                disableElevation
                fullWidth
                onClick={() => setOpenVideoModal(true)}
              >
                Watch Video
              </ButtonBaseFilled>
            </CardActions>
          </CardFooter>
        </CardContent>
      </CardBody>

      <VideoModal
        open={openVideoModal}
        close={() => setOpenVideoModal(false)}
        title={report_name}
        video={quiz_video_url}
        // loading={loading}
      />
    </CardStyled>
  );
};

AssessmentInfo.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

AssessmentInfo.defaultProps = {};

export default AssessmentInfo;
