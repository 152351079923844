// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Clear = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20.479 16.091" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20.479v16.091H0z" />
    <path
      d="M20.747,16.628H13.735l2.4-2.4,0,0,0,0L21.263,9.1a.732.732,0,0,0,0-1.034L15.412,2.214a.732.732,0,0,0-1.034,0l-11.7,11.7a.732.732,0,0,0,0,1.034l1.678,1.678H1.731a.731.731,0,0,0,0,1.463H20.747a.731.731,0,0,0,0-1.463ZM14.9,3.766l4.817,4.817-4.085,4.085L10.811,7.851ZM4.228,14.434,9.777,8.885,14.592,13.7l-2.924,2.926H6.422Z"
      transform="translate(-1 -2)"
    />
  </SvgIconStyled>
);

export default Clear;
