// @flow
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import { ButtonCancel, Container, LoadingStyled, TextWithIcon, Typography } from 'components';
import { LockedContent } from 'components/common/ContentModifiers';
import { LazyIcon } from 'components/icons';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import { useLocation } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { IconButton } from '../../modal/ComponentTypes';
import ActiveTimeFrame from './ActiveTimeFrame';
import CustomMessage from './CustomMessage';
import EnableDisableButton from './EnableDisableButton';
import ExcludeReceivers from './ExcludeReceivers';
import ExternalEmails from './ExternalEmails';
import FrequencyPeriod from './FrequencyPeriod';
import IncludeReceivers from './IncludeReceivers';
import Parameters from './Parameters';
import ReceiversSelectionModal from './ReceiversSelectionModal';
import RevertSettingsButton from './RevertSettingsButton';
import SaveButton from './SaveButton';
import TestMessageButton from './TestMessageButton';
import { validateNumber, PN_SUBSCRIPTION_CUSTOM_MSG_LIMIT, APPS } from './helpers';


const DialogTitleStyled = styled(DialogTitle)`
  && {
    padding: 22px 24px;
    h3 {
      margin: 0 30px 0 0;
      font-weight: normal;
    }
    button {
      top: 5px;
      right: 5px;
    }
  }
`;

const ConfigurationModal = props => {
  const {
    subscriptionId,
    configurationModalOpen,
    closeConfigurationModal,
    partnerId,
    clientId,
    trackGA,
    refetchTable
  } = props;
  const notify = useNotify();
  const { app } = useLocation();
  const isPartnerProfileOrAdminApp = app === APPS.partnerProfile || app === APPS.admin;
  const [state, setState] = useState(null);
  const [invalidEmails, setInvalidEmails] = useState(false);
  const [initialData, setInitialData] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [modalState, setModalState] = useState({ opened: false, type: '' });

  const { isLoading: isSubscriptionLoading, refetch: refetchSubscription } = useQuery(
    ['pushNotificationConfigModal', subscriptionId],
    () => pushNotifications.getSubscription(subscriptionId),
    {
      enabled: !!subscriptionId,
      keepPreviousData: true,
      cacheTime: false,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        setSubscriptionState(res.data);
      },
      onError: err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      }
    }
  );

  const setSubscriptionState = data => {
    const frequency_days_error = validateNumber({
      value: data.subscription.frequency_days === null ? 1 : data.subscription.frequency_days,
      constraints: { minimum: 1, maximum: data?.push_notification?.show_frequency_period ? 84 : 365, integer: true }
    });

    // Set default_frequency_days only if show_frequency_period is false
    data.subscription.frequency_days =
      data.subscription.frequency_days ||
      (data?.push_notification?.show_frequency_period ? '' : data.push_notification.default_frequency_days);
    data.subscription_validation = {
      frequency_days_error: frequency_days_error.error,
      frequency_days_error_text: frequency_days_error.error_text,
      time_frame_error: false,
      time_frame_error_text: ''
    };
    data.parameters.map((item, idx) => {
      const { value, constraints } = item;
      data.parameters[idx] = { ...item, ...validateNumber({ value, constraints }) };
    });
    setInitialData(cloneDeep(data));
    if (data?.pre_selected_included_users && !data.is_saved) {
      data.included_users = data.pre_selected_included_users;
    }
    setState(data);
  };

  const noUsersSelected = React.useMemo(() => {
    const users_selected = Boolean(
      (state?.push_notification?.allow_external_emails && state?.external_users?.length) ||
        (state?.push_notification?.allow_individual_targeting &&
          (state?.receiving_groups?.filter(item => item?.checked == 1).length || state?.included_users?.length))
    );
    const select_users_condition = Boolean(
      state?.push_notification?.allow_external_emails || state?.push_notification?.allow_individual_targeting
    );
    return Boolean(!users_selected && select_users_condition);
  }, [state]);

  const isFrequencyPeriodSelected =
    state?.subscription?.frequency_days &&
    (state?.subscription?.day_of_week || state?.subscription?.day_of_month || state?.subscription?.start_sending_date);

  const invalidData = React.useMemo(() => {
    if (!state) return false;
    return (
      state.subscription_validation.frequency_days_error ||
      state.subscription_validation.day_of_month_error ||
      state.subscription_validation.time_frame_error ||
      state.parameters.map(e => e.error).includes(true) ||
      (state.subscription.use_custom_message &&
        (!state.subscription.custom_message_body ||
          !state.subscription.custom_message_subject ||
          state.subscription.custom_message_body === ('' || '<p><br></p>') ||
          state.subscription.custom_message_subject === '' ||
          state.subscription.custom_message_body?.length > PN_SUBSCRIPTION_CUSTOM_MSG_LIMIT)) ||
      invalidEmails ||
      (state?.push_notification?.show_frequency_period && !isFrequencyPeriodSelected) ||
      noUsersSelected
    );
  }, [state, invalidEmails, isFrequencyPeriodSelected, noUsersSelected]);

  const { isEditedData, isEditedSubscriptionData } = React.useMemo(() => {
    // exclude subscription_validation object form comparison
    let comparisonState,
      comparisonData = null;
    if (!!state && !!initialData) {
      const { subscription_validation: svState, ...restState } = state;
      comparisonState = restState;
      const { subscription_validation: svData, ...restData } = initialData;
      comparisonData = restData;
    }

    // check if subscription data changed
    const isEditedSubscriptionData =
      JSON.stringify(comparisonData?.subscription) !== JSON.stringify(comparisonState?.subscription);
    // check if all data changed
    // note: if subsciption data changed -> data changed
    const isEditedData = isEditedSubscriptionData || JSON.stringify(comparisonData) !== JSON.stringify(comparisonState);
    // console.log('amroo', { isEditedSubscriptionData, isEditedData, comparisonData, comparisonState });
    return { isEditedData, isEditedSubscriptionData };
  }, [initialData, state]);

  return (
    <Dialog
      open={configurationModalOpen}
      onClose={closeConfigurationModal}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
    >
      <DialogTitleStyled id="dialog-title">
        <Typography.h3>
          <strong>Configure: </strong>
          {state?.push_notification?.name}
        </Typography.h3>
        <IconButton onClick={closeConfigurationModal}>
          <LazyIcon component="Close" />
        </IconButton>
      </DialogTitleStyled>
      <Divider />
      {isSubscriptionLoading || !state ? (
        <LoadingStyled />
      ) : (
        <LockedContent locked={actionLoading}>
          <DialogContent>
            <Container.Grid py={1.2} container>
              <Container.Grid item xs={12}>
                <Typography.p style={{ whiteSpace: 'pre' }} fontWeight="fontWeightMedium">
                  {state.push_notification.header_text}
                </Typography.p>
              </Container.Grid>
            </Container.Grid>
            {!isPartnerProfileOrAdminApp && !!state.use_global_subscription && (
              <TextWithIcon text="Default settings are being used" icon="Internet" isBlue />
            )}
            <Parameters state={state} setState={setState} />
            <ActiveTimeFrame state={state} setState={setState} actionLoading={actionLoading} />
            <IncludeReceivers
              state={state}
              setState={setState}
              setModalState={setModalState}
              isPartnerProfileApp={isPartnerProfileOrAdminApp}
            />
            <ExternalEmails state={state} setState={setState} setInvalidEmails={setInvalidEmails} />
            <FrequencyPeriod
              state={state}
              setState={setState}
              initialData={initialData}
              isFrequencyPeriodSelected={isFrequencyPeriodSelected}
              isEditedSubscriptionData={isEditedSubscriptionData}
              actionLoading={actionLoading}
            />
            <CustomMessage state={state} setState={setState} initialData={initialData} />

            <ExcludeReceivers state={state} setState={setState} setModalState={setModalState} />
            {!isPartnerProfileOrAdminApp && !!state.show_revert_button && (
              <RevertSettingsButton
                subscriptionId={state?.subscription?.id}
                refetchTable={refetchTable}
                setSubscriptionState={setSubscriptionState}
                setActionLoading={setActionLoading}
              />
            )}
          </DialogContent>

          <Divider />

          <DialogActions>
            <Box py={1} px={2} display="flex" justifyContent="flex-end" alignItems="center">
              <ButtonCancel onClick={closeConfigurationModal}>Cancel</ButtonCancel>
              <SaveButton
                state={state}
                setInitialData={setInitialData}
                refetchSubscription={refetchSubscription}
                refetchTable={refetchTable}
                noUsersSelected={noUsersSelected}
                invalidData={invalidData}
                isEditedData={isEditedData}
                setActionLoading={setActionLoading}
              />
              <EnableDisableButton
                state={state}
                refetchSubscription={refetchSubscription}
                refetchTable={refetchTable}
                isEditedData={isEditedData}
                invalidData={invalidData}
                setActionLoading={setActionLoading}
                partnerId={partnerId}
                clientId={clientId}
                trackGA={trackGA}
              />
              {Boolean(state?.push_notification?.show_test_message) && (
                <TestMessageButton state={state} setActionLoading={setActionLoading} />
              )}
            </Box>
          </DialogActions>

          {modalState.opened && (
            <ReceiversSelectionModal
              open={modalState.opened}
              type={modalState.type}
              onClose={() => setModalState(st => ({ ...st, opened: false }))}
              state={state}
              setState={setState}
            />
          )}
        </LockedContent>
      )}
    </Dialog>
  );
};

export default ConfigurationModal;
