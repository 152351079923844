// @flow
import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Input
} from '@material-ui/core';
import { getStorage, capitalizeFirstLetter, decodeId, trackingUtils } from 'helpers';
import { Container, SelectField, Switch, Typography, LoadingStyled } from 'components';
import { Link as ConnectIcon, Unlink as DisconnectIcon } from 'components/icons';
import axios from 'axios';
import { useNotify } from 'react-admin';
import GradientDialog from './GradientDialog';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle, ContainerFooter } from './ComponentTypes';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const PartnerTMSConfiguration = () => {
  const [record, setRecord] = useState(null);
  const [open, setOpen] = useState(false);
  const [newTms, setNewTms] = useState('');
  const [disableConnect, setDisableConnect] = useState(true);
  const [loading, setLoading] = useState(true);
  const partnerId = decodeId(getStorage('partnerId', true));
  const env = process.env.REACT_APP_BUILD_ENV;
  const base = process.env.REACT_APP_BASE_URL;
  const prefix = process.env.REACT_APP_BASE_URL_7;
  const prefix1 = process.env.REACT_APP_BASE_URL_8;
  const [update, forceUpdate] = useState(true);
  const notify = useNotify();
  const [openDialog, setOpenDialog] = useState(false);

  const defaultPayload = {
    type: 'TicketManagementSystem'
  };

  const getRequestHeaders = (contentType = 'application/json', includeAuthorization = true) => {
    return {
      'Content-Type': contentType,
      ...(includeAuthorization ? { Authorization: getStorage('idToken', true) } : {})
    };
  };

  const GetAllTmss = () => {
    const headers = { headers: getRequestHeaders() };
    return axios.get(`https://${prefix}.${base}/${env}/GetAllTmss?type=TicketManagementSystem`, headers);
  };

  const PickList = () => {
    const headers = { headers: getRequestHeaders() };
    return axios.get(
      `https://${prefix}.${base}/${env}/PickList?type=TicketManagementSystem&partner_id=${partnerId}&data_type=null`,
      headers
    );
  };

  const gradientSync = () => {
    const data = {
      type: 'TicketManagementSystem',
      partner_id: partnerId
    };
    const headers = { headers: getRequestHeaders() };
    return axios.post(`https://${prefix}.${base}/${env}/partnerProfile/GradientSync`, data, headers);
  };

  const SavePartnerTmsAndLoginInfo = data => {
    const body = {
      type: 'TicketManagementSystem',
      tms_name: record.selectedTms,
      partner_id: partnerId,
      ...data
    };
    const headers = {
      headers: getRequestHeaders()
    };
    return axios.post(`https://${prefix}.${base}/${env}/SavePartnerTmsAndLoginInfo`, body, headers);
  };

  const GetPartnerClientNotificationMechanismsTemplatesStatus = () => {
    const headers = { headers: getRequestHeaders() };
    return axios.get(
      `https://${prefix1}.${base}/${env}/GetPartnerClientNotificationMechanismsTemplatesStatus?type=TicketManagementSystem&partner_id=${partnerId}`,
      headers
    );
  };

  const DeletePartnerData = () => {
    const requestData = {
      headers: getRequestHeaders(),
      data: {
        type: 'TicketManagementSystem',
        partner_id: partnerId
      }
    };
    return axios.delete(`https://${prefix}.${base}/${env}/DeletePartnerData`, requestData);
  };

  const GetPartnerTmsData = () => {
    const headers = { headers: getRequestHeaders() };
    return axios.get(
      `https://${prefix}.${base}/${env}/GetPartnerTmsData?type=TicketManagementSystem&partner_id=${partnerId}`,
      headers
    );
  };

  const SetPartnerClientNotificationMechanismTemplateStatus = status => {
    const headers = { headers: getRequestHeaders() };
    return axios.post(
      `https://${prefix1}.${base}/${env}/SetPartnerClientNotificationMechanismTemplateStatus`,
      status,
      headers
    );
  };

  const GetTmsNotificationTemplates = tms => {
    const headers = { headers: getRequestHeaders() };
    return axios.get(
      `https://${prefix}.${base}/${env}/GetTmsNotificationTemplates?type=TicketManagementSystem&tms_name=${tms}`,
      headers
    );
  };

  const SavePartnerTmsData = data => {
    const requestData = {
      type: 'TicketManagementSystem',
      partner_id: partnerId,
      partner_associations: { notification_template_queue: data }
    };
    const headers = { headers: getRequestHeaders() };
    return axios.post(`https://${prefix}.${base}/${env}/SavePartnerTmsData`, requestData, headers);
  };

  const SaveAssoc = callback => {
    setLoading(true);
    const data = [];
    Object.keys(record.templates).map(key => {
      if (record.templates[key].queue_id) {
        data.push({
          notification_template_id: record.templates[key].notification_template_id,
          queue_id: record.templates[key].queue_id
        });
      }
    });

    if (data.length !== 0) {
      SavePartnerTmsData(data).then(response => {
        console.log(response);
        callback();
      });
    }
    setLoading(false);
    enqueueAlertSnackbar('TMS configurations have been successfully updated!', {
      props: {
        severity: "success"
      }
    })
    if (data.length === 0) callback();
  };

  const onChangeSwitch = ({ target: { checked } }) => {
    setLoading(true);
    const data = {
      connected: false,
      enable_psa_system: checked,
      queues: record.queues,
      tmss: record.tmss
    };
    if (!checked) {
      DeletePartnerData().then(result => {
        setRecord(data);
        setLoading(false);
      });
    } else {
      setRecord(data);
      setLoading(false);
    }
  };

  const onChangeText = ({ target: { value, name } }) => {
    const data = record;
    data[name] = value?.trim();
    setRecord(data);
    setDisableConnect(checkEnableConnect());
    forceUpdate(!update);
  };

  const onChangeSelect = ({ target: { value, name } }) => {
    setNewTms(value);
    if (record.connected) setOpen(true);
    else ChangeSelectedTms(value);
  };

  const ChangeSelectedTms = value => {
    setOpen(false);
    const data = record;
    data.selectedTms = value.toLowerCase();
    if (record.connected) {
      setLoading(true);
      DeletePartnerData().then(() => {
        data.connected = false;
        setRecord({...data, password: ''});
        setLoading(false);
        forceUpdate(!update);
      });
    } else {
      setRecord(data);
      forceUpdate(!update);
    }
    setDisableConnect(checkEnableConnect());
  };

  const onChangeCheckBox = ({ target: { checked, name } }) => {
    setLoading(true);
    const data = record;
    const idx = name.split('_')[1];
    const type = name.split('_')[0];
    data.templates[idx][type].enabled = checked;
    const status = {
      partner_id: partnerId,
      client_id: null,
      notification_template: record.templates[idx].notification_template_id,
      notification_mechanism: type,
      enabled: checked ? 1 : 0
    };
    SetPartnerClientNotificationMechanismTemplateStatus(status).then(() => {
      setRecord(data);
      setLoading(false);
      forceUpdate(!update);
    });
  };

  const EnableDisableAll = ({ target: { checked } }) => {
    setLoading(true);
    const data = record;
    const status = [];
    Object.keys(record.templates).map(key => {
      data.templates[key].tms.enabled = checked;
      status.push({
        partner_id: partnerId,
        client_id: null,
        notification_template: record.templates[key].notification_template_id,
        notification_mechanism: 'tms',
        enabled: checked ? 1 : 0
      });
    });
    SetPartnerClientNotificationMechanismTemplateStatus(status).then(() => {
      setRecord(data);
      setLoading(false);
      forceUpdate(!update);
    });
  };

  const connectPartnerTMS = (data, showNotification = true) => {
    return SavePartnerTmsAndLoginInfo(data).then(savePartnerRes => {
      PickList()
        .then(pickResponse => {
          const recordData = record;
          if (record.selectedTms === 'autotask') recordData.queues = pickResponse.data.response.queues;
          GetDataStatusAndConstruct(recordData);
          showNotification &&
            enqueueAlertSnackbar(`${capitalizeFirstLetter(record.selectedTms)} configuration has been connected`, {
              props: {
                severity: "success"
              }
            })

          trackingUtils.customEvent('completed_subtask', {
            sendUserName: true,
            sendPartnerName: true,
            label: 'TMS Configuration'
          });
        })
        .catch(errRes => {
          DeletePartnerData();
          if (showNotification) {
            notify(
              errRes?.response?.data?.response || errRes?.response?.data?.message || 'Something went wrong',
              'error'
            );
          }
          setLoading(false);
        });
    });
  };

  const saveGradient = () => {
    setLoading(true);

    const data = { connection_parameters: {} };
    data.connection_parameters.psa_key = record.psaKey;
    connectPartnerTMS(data, false).then(res => {
      gradientSync()
        .then(res => {
          enqueueAlertSnackbar(`${capitalizeFirstLetter(record.selectedTms)} configuration has been connected`, {
            props: {
              severity: "success"
            }
          })
        })
        .catch(err => {
          DeletePartnerData();
          notify('Wrong Credentials', 'error');
        });
    });
    setOpenDialog(false);
  };

  const onChangeOnPrim = ({ target: { checked, name } }) => {
    const data = record;
    data[name] = checked;
    setRecord(data);
    setDisableConnect(checkEnableConnect());
    forceUpdate(!update);
  };

  const onChangeQueue = ({ target: { value, name } }) => {
    const data = record;
    data.templates[name].queue_id = value;
    setRecord(data);
    forceUpdate(!update);
  };

  const connect = () => {
    setLoading(true);
    const data = { connection_parameters: {} };
    switch (record.selectedTms) {
      case 'autotask':
        data.connection_parameters.username = record.username;
        data.connection_parameters.password = record.password;
        break;
      case 'connectwise':
        data.connection_parameters.username = `${record.company_id}+${record.public_api_key}`;
        data.connection_parameters.password = record.private_api_key;
        if (record.onprim) data.connection_parameters.site = record.site;
        break;
      case 'gradient':
        setOpenDialog(true);
        setLoading(false);
        return;
      default:
        break;
    }
    connectPartnerTMS(data);
  };

  const DisConnect = () => {
    setLoading(true);
    const data = {
      connected: false,
      enable_psa_system: true,
      selectedTms: record.selectedTms,
      tmss: record.tmss
    };
    DeletePartnerData().then(result => {
      setRecord(data);
      setDisableConnect(true);
      setLoading(false);
      enqueueAlertSnackbar(`${capitalizeFirstLetter(record.selectedTms)} configuration has been disconnected`, {
        props: {
          severity: "success"
        }
      })
    });
  };

  const GetDataStatusAndConstruct = cons => {
    const data = cons;
    GetTmsNotificationTemplates(data.selectedTms).then(temps => {
      data.templates = temps.data.data.reduce(
        (totalTemplates, currentTemplate) => ({
          ...totalTemplates,
          [currentTemplate.template]: currentTemplate
        }),
        {}
      );

      GetPartnerClientNotificationMechanismsTemplatesStatus().then(resp => {
        resp.data.map(x => {
          data.templates[x.notification_template_display_name][x.notification_mechanism] = x;
        });
        GetPartnerTmsData().then(respo => {
          respo.data.data.map(y => {
            if (y.field_name === 'connection_parameters') {
              switch (cons.selectedTms) {
                case 'autotask':
                  data.username = y.field_value.username;
                  data.password = '*'.repeat(y.field_value.password);
                  break;
                case 'connectwise':
                  [data.company_id, data.public_api_key] = y.field_value.username.split('+');

                  data.private_api_key = '*'.repeat(y.field_value.password);

                  // eslint-disable-next-line no-prototype-builtins
                  if (y.field_value.hasOwnProperty('site')) {
                    data.onprim = true;
                    data.site = y.field_value.site;
                  }
                  break;
                case 'gradient':
                  data.psaKey = y.field_value.psa_key;
                  break;
                default:
                  break;
              }
            } else if (y.field_name === 'partner_associations') {
              y.field_value.notification_template_queue.map(assoc => {
                Object.keys(data.templates).map(key => {
                  if (data.templates[key].notification_template_id === assoc.notification_template_id) {
                    data.templates[key].queue_id = assoc.queue_id;
                  }
                });
              });
            }
          });
          const updatedData = data;
          Object.keys(data.templates).map(templa => {
            // eslint-disable-next-line no-prototype-builtins
            if (!data.templates[templa].hasOwnProperty('email')) {
              updatedData.templates[templa].email = {
                client_id: null,
                enabled: 1,
                notification_mechanism: 'email',
                notification_template_id: data.templates[templa].notification_template_id,
                partner_id: partnerId
              };
            }
            // eslint-disable-next-line no-prototype-builtins
            if (!data.templates[templa].hasOwnProperty('tms')) {
              updatedData.templates[templa].tms = {
                client_id: null,
                enabled: 0,
                notification_mechanism: 'tms',
                notification_template_id: data.templates[templa].notification_template_id,
                partner_id: partnerId
              };
            }
            updatedData.templates[templa].tms.enabled = updatedData.templates[templa].tms.enabled === 1;
            updatedData.templates[templa].email.enabled = updatedData.templates[templa].email.enabled === 1;
          });
          updatedData.connected = true;
          setRecord(updatedData);
          setLoading(false);
        });
      });
    });
  };

  const checkEnableConnect = () => {
    let disabled = false;
    switch (record.selectedTms) {
      case 'autotask':
        if (!record.username || record.username === '' || !record.password || record.password === '') disabled = true;

        break;
      case 'connectwise':
        if (
          !record.company_id ||
          record.company_id === '' ||
          !record.public_api_key ||
          record.public_api_key === '' ||
          !record.private_api_key ||
          record.private_api_key === '' ||
          (record.onprim && (!record.site || record.site === ''))
        )
          disabled = true;

        break;
      case 'gradient':
        if (!record.psaKey) disabled = true;
        break;
      default:
        break;
    }
    return disabled;
  };

  useEffect(() => {
    if (record === null) {
      GetAllTmss().then(res => {
        const data = {};
        data.tmss = res.data.data;
        PickList()
          .then(response => {
            console.log(response);
            if (response.data.tms_name === 'autotask') data.queues = response.data.response.queues;
            data.enable_psa_system = true;
            data.selectedTms = response.data.tms_name;
            data.connected = true;
            GetDataStatusAndConstruct(data);
          })
          .catch(err => {
            data.enable_psa_system = false;
            data.connected = false;
            setRecord(data);
            setLoading(false);
          });
      });
    }
  }, [record]);

  if (loading) return <LoadingStyled />;

  return (
    <>
      {/* TMS Configurations */}
      <Container.Paper mt={2} radius={1}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                TMS Configurations
              </Typography.h6>
              <InfoButtons name="ticket_management_system" />
            </ContainerTitle>

            <Container.Grid item md={12} px={2.5} pb={2.5}>
              <Container.Grid item md={6}>
                <Switch
                  label="Enable TMS System"
                  value={record.enable_psa_system}
                  checked={record.enable_psa_system}
                  name="enable_psa_system"
                  onChange={onChangeSwitch}
                />
              </Container.Grid>

              <Container.Grid item md={6}>
                <SelectField
                  id="partner-profile_notifications__psa_integration"
                  name="selectedTms"
                  value={record.selectedTms ? capitalizeFirstLetter(record.selectedTms) : record.selectedTms}
                  disabled={!record.enable_psa_system}
                  onChange={onChangeSelect}
                  label="Select TMS Integration"
                  choices={record.tmss.map(t => {
                    return { ...t, tms_name: capitalizeFirstLetter(t.tms_name) };
                  })}
                  allowEmpty
                  emptyValue=""
                  fullWidth
                  labelKey="tms_name"
                  valueKey="tms_name"
                />
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>

      {/* autotask, connectwise, gradient */}
      {record.enable_psa_system && (
        <>
          {record.selectedTms && (
            <Container.Paper mt={2} radius={1}>
              <Container.Grid spacing={4}>
                <Container.Grid item direction="column" xs={12}>
                  <ContainerTitle>
                    <Typography.h6 mt={0.1} mb={0.1}>
                      {capitalizeFirstLetter(record.selectedTms)} Configuration
                    </Typography.h6>
                  </ContainerTitle>

                  {/* Autotask */}
                  {record.selectedTms === 'autotask' && (
                    <Container.Grid item md={12} pl={1.5} pr={1.5} pb={2.5}>
                      <Container.Grid p={1} item md={6}>
                        <TextField
                          disabled={record.connected}
                          fullWidth
                          label="User Name"
                          value={record.username}
                          name="username"
                          onChange={onChangeText}
                        />
                      </Container.Grid>

                      <Container.Grid p={1} item md={6}>
                        <TextField
                          disabled={record.connected}
                          fullWidth
                          label="Password"
                          type="password"
                          value={record.password}
                          name="password"
                          onChange={onChangeText}
                        />
                      </Container.Grid>
                    </Container.Grid>
                  )}

                  {/* Connectwise */}
                  {record.selectedTms === 'connectwise' && (
                    <Container.Grid item md={12} pl={1.5} pr={1.5} pb={2.5}>
                      <Container.Grid p={1} item md={4}>
                        <TextField
                          disabled={record.connected}
                          fullWidth
                          label="CompanyID"
                          value={record.company_id}
                          name="company_id"
                          onChange={onChangeText}
                        />
                      </Container.Grid>

                      <Container.Grid p={1} item md={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="public_api_key">Public Api Key</InputLabel>
                          <Input
                            disabled={record.connected}
                            id="public_api_key"
                            name="public_api_key"
                            type="text"
                            value={record.public_api_key}
                            onChange={onChangeText}
                          />
                        </FormControl>
                      </Container.Grid>

                      <Container.Grid p={1} item md={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="private_api_key">Private Api Key</InputLabel>
                          <Input
                            disabled={record.connected}
                            id="private_api_key"
                            name="private_api_key"
                            type="password"
                            value={record.private_api_key}
                            onChange={onChangeText}
                          />
                        </FormControl>
                      </Container.Grid>
                      <Container.Grid p={1} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              label=""
                              value={record.onprim}
                              checked={record.onprim}
                              name="onprim"
                              onClick={onChangeOnPrim}
                            />
                          }
                          label="Check if using Connectwise On Premise"
                        />

                        {record.onprim && (
                          <TextField fullWidth label="site" value={record.site} name="site" onChange={onChangeText} />
                        )}
                      </Container.Grid>
                    </Container.Grid>
                  )}

                  {/* Gradient */}
                  {record.selectedTms === 'gradient' && (
                    <Container.Grid item md={12} pl={1.5} pr={1.5} pb={2.5}>
                      <TextField
                        fullWidth
                        label="Partner integration Key"
                        value={record.psaKey}
                        name="psaKey"
                        onChange={onChangeText}
                      />
                    </Container.Grid>
                  )}

                  <Container.Grid item md={12}>
                    <ContainerFooter>
                      {!record.connected && (
                        <>
                          <Button
                            variant="contained"
                            style={{ 'background-color': '#0cce6b', color: 'white' }}
                            size="small"
                            startIcon={<ConnectIcon />}
                            onClick={connect}
                            disabled={disableConnect}
                          >
                            Connect
                          </Button>
                          <GradientDialog
                            opened={openDialog}
                            onClose={() => {
                              setOpenDialog(false);
                            }}
                            gradientSync={saveGradient}
                          />
                        </>
                      )}

                      {record.connected && (
                        <>
                          <Button
                            variant="contained"
                            style={{ 'background-color': '#ffa457', color: 'white' }}
                            size="small"
                            startIcon={<DisconnectIcon />}
                            onClick={DisConnect}
                          >
                            Disconnect
                          </Button>
                        </>
                      )}
                    </ContainerFooter>
                  </Container.Grid>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          )}

          {record.connected && (
            <Container.Paper mt={2} radius={1}>
              <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container.Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={Object.keys(record.templates).every(
                                key => record.templates[key].tms.enabled === true
                              )}
                              onClick={EnableDisableAll}
                            />{' '}
                            Enable
                          </TableCell>
                          <TableCell>Service Name</TableCell>
                          {record.selectedTms === 'autotask' && <TableCell>Association</TableCell>}
                          <TableCell align="right">Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(record.templates).map(key => (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              <Checkbox
                                value={record.templates[key].tms.enabled}
                                checked={record.templates[key].tms.enabled}
                                name={`tms_${key}`}
                                onClick={onChangeCheckBox}
                              />
                            </TableCell>
                            <TableCell>{record.templates[key].template}</TableCell>
                            {record.selectedTms === 'autotask' && (
                              <TableCell>
                                <SelectField
                                  id={key}
                                  name={key}
                                  value={record.templates[key].queue_id}
                                  onChange={onChangeQueue}
                                  label="Select Associated Queue"
                                  choices={record.queues}
                                  allowEmpty
                                  emptyValue=""
                                  fullWidth
                                  labelKey="Label"
                                  valueKey="Value"
                                  disabled={!record.templates[key].tms.enabled}
                                />
                              </TableCell>
                            )}
                            <TableCell align="right">
                              <Checkbox
                                value={record.templates[key].email.enabled}
                                checked={record.templates[key].email.enabled}
                                name={`email_${key}`}
                                onClick={onChangeCheckBox}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          )}
        </>
      )}

      {/* Action buttons */}
      <ActionButtons
        hasCancel={record.connected && record.selectedTms === 'autotask'}
        hasSave={record.connected && record.selectedTms === 'autotask'}
        onSave={SaveAssoc}
      />

      {/* Confirm disconnect */}
      <Dialog
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm disconnect?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Changing selected TMS integration will disconnect you from the other one
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => ChangeSelectedTms(newTms)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartnerTMSConfiguration;
