// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DocSearch = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M15.5653 22.2798C15.2463 22.2055 14.9362 22.097 14.6405 21.9561C14.5161 22.0263 14.3763 22.0644 14.2335 22.0671H2.77457C2.52928 22.0671 2.29404 21.9696 2.12059 21.7962C1.94715 21.6227 1.84971 21.3875 1.84971 21.1422V2.77457C1.84971 2.52928 1.94715 2.29404 2.12059 2.12059C2.29404 1.94715 2.52928 1.84971 2.77457 1.84971H14.2058C14.4511 1.84971 14.6863 1.94715 14.8598 2.12059C15.0332 2.29404 15.1306 2.52928 15.1306 2.77457V11.7087C15.6069 11.5792 16.0983 11.5139 16.5919 11.5145H16.9803V2.77457C16.9803 2.03871 16.688 1.33298 16.1677 0.812652C15.6474 0.29232 14.9416 0 14.2058 0L2.77457 0C2.03871 0 1.33298 0.29232 0.812652 0.812652C0.29232 1.33298 0 2.03871 0 2.77457L0 21.1422C0 21.8781 0.29232 22.5838 0.812652 23.1041C1.33298 23.6244 2.03871 23.9168 2.77457 23.9168H14.2058C14.7106 23.9128 15.2047 23.7711 15.6349 23.5071C16.0651 23.2431 16.4152 22.8667 16.6474 22.4185C16.2827 22.4124 15.9198 22.3658 15.5653 22.2798Z" />
    <path d="M4.25432 14.9642C4.00904 14.9642 3.7738 15.0616 3.60035 15.2351C3.42691 15.4085 3.32947 15.6437 3.32947 15.889C3.32947 16.1343 3.42691 16.3696 3.60035 16.543C3.7738 16.7164 4.00904 16.8139 4.25432 16.8139H8.8786C9.12389 16.8139 9.35913 16.7164 9.53257 16.543C9.70602 16.3696 9.80346 16.1343 9.80346 15.889C9.80346 15.6437 9.70602 15.4085 9.53257 15.2351C9.35913 15.0616 9.12389 14.9642 8.8786 14.9642H4.25432Z" />
    <path d="M12.5966 5.45667H4.38384C4.13855 5.45667 3.90331 5.5541 3.72987 5.72755C3.55642 5.90099 3.45898 6.13623 3.45898 6.38152C3.45898 6.62681 3.55642 6.86205 3.72987 7.03549C3.90331 7.20894 4.13855 7.30638 4.38384 7.30638H12.5966C12.8418 7.30638 13.0771 7.20894 13.2505 7.03549C13.424 6.86205 13.5214 6.62681 13.5214 6.38152C13.5214 6.13623 13.424 5.90099 13.2505 5.72755C13.0771 5.5541 12.8418 5.45667 12.5966 5.45667Z" />
    <path d="M13.5214 11.0983C13.5214 10.853 13.424 10.6177 13.2505 10.4443C13.0771 10.2708 12.8418 10.1734 12.5966 10.1734H4.38384C4.13855 10.1734 3.90331 10.2708 3.72987 10.4443C3.55642 10.6177 3.45898 10.853 3.45898 11.0983C3.45898 11.3435 3.55642 11.5788 3.72987 11.7522C3.90331 11.9257 4.13855 12.0231 4.38384 12.0231H12.5966C12.8418 12.0231 13.0771 11.9257 13.2505 11.7522C13.424 11.5788 13.5214 11.3435 13.5214 11.0983Z" />
    <path d="M23.7318 22.3537L20.7445 19.3664C20.6866 19.3139 20.6211 19.2702 20.5503 19.237C20.9476 18.5912 21.1618 17.8495 21.1699 17.0913C21.2031 16.2253 20.9767 15.3691 20.5197 14.6327C20.0627 13.8963 19.396 13.3133 18.6052 12.9587C17.8144 12.604 16.9356 12.4938 16.0818 12.6422C15.2279 12.7907 14.4379 13.191 13.8132 13.7918C13.1886 14.3925 12.7577 15.1663 12.5761 16.0137C12.3944 16.8611 12.4702 17.7435 12.7937 18.5475C13.1173 19.3516 13.6738 20.0405 14.3918 20.5259C15.1097 21.0113 15.9564 21.271 16.8231 21.2716C17.7222 21.2721 18.5999 20.9978 19.3387 20.4855C19.3627 20.5388 19.3939 20.5886 19.4312 20.6335L22.4185 23.6208C22.5049 23.7065 22.6074 23.7743 22.7201 23.8203C22.8328 23.8664 22.9534 23.8897 23.0751 23.889C23.1969 23.8897 23.3175 23.8664 23.4302 23.8203C23.5429 23.7743 23.6454 23.7065 23.7318 23.6208C23.8931 23.4493 23.9829 23.2227 23.9829 22.9873C23.9829 22.7518 23.8931 22.5252 23.7318 22.3537ZM16.8231 19.4219C16.3585 19.3984 15.9109 19.239 15.536 18.9635C15.1611 18.6881 14.8753 18.3086 14.7139 17.8723C14.5526 17.4359 14.5229 16.9617 14.6285 16.5086C14.7341 16.0555 14.9704 15.6434 15.308 15.3233C15.6456 15.0032 16.0697 14.7892 16.5278 14.7078C16.9859 14.6265 17.4578 14.6814 17.8849 14.8657C18.3121 15.05 18.6758 15.3556 18.931 15.7446C19.1861 16.1337 19.3214 16.5891 19.3202 17.0543C19.3118 17.3738 19.2406 17.6884 19.1105 17.9803C18.9805 18.2723 18.7942 18.5357 18.5623 18.7555C18.3304 18.9754 18.0574 19.1474 17.759 19.2618C17.4606 19.3761 17.1426 19.4306 16.8231 19.4219Z" />
  </SvgIconStyled>
);

export default React.memo(DocSearch);
