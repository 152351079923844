import React, { useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import classes from './passwordMeterStyles.module.css';
import LineProgress from '../loaders/LineProgress';
import { Warning, NotComplete, Success, Policy } from 'components/icons';
const riskColor = '#FF5A61';
const weakColor = '#FFA457';
const goodColor = '#0CCE6B';
const barColors = { 0: riskColor, 1: riskColor, 2: weakColor, 3: goodColor, 4: goodColor };

const iconStyles = {
  verticalAlign: 'text-bottom'
};

const REJECTED_SCORES = [0, 1, 2];

const PasswordMeter = ({ password, onChangePassword = null, onPasswordScorePassed }) => {
  const score = zxcvbn(password).score;

  useEffect(() => {
    onChangePassword(score);
  }, [password]);

  useEffect(() => {
    if (onPasswordScorePassed) {
      onPasswordScorePassed(REJECTED_SCORES.includes(score));
    }
  }, [score]);

  return (
    <>
      <LineProgress
        className={classes.progress}
        backgroundColor="#D9D9D9"
        barColor={barColors[score]}
        variant="determinate"
        value={((score ? score : 0.5) / 4) * 100}
      />
      <FeedbackText score={score} />
    </>
  );
};

const FeedbackText = ({ score }) => {
  switch (score) {
    case 0:
      return (
        <div className={`${classes.box} ${classes.risk}`}>
          {' '}
          <NotComplete style={iconStyles} color={'danger'} />{' '}
          {
            'Yikes! That’s a risky password that could easily be guessed. Try using a passphrase or adding additional words or characters to raise the complexity level.'
          }{' '}
        </div>
      );
    case 1:
      return (
        <div className={`${classes.box} ${classes.risk}`}>
          {' '}
          <NotComplete style={iconStyles} color={'danger'} />{' '}
          {
            'Keep trying! Your password could use a bit more work. Try adding another word or additional characters to raise the complexity level.'
          }{' '}
        </div>
      );
    case 2:
      return (
        <div className={`${classes.box} ${classes.weak}`}>
          {' '}
          <Warning style={iconStyles} color="warning" />{' '}
          {
            'Getting closer! Your password may be sufficient but adding another word or additional characters can help build that strong password.'
          }{' '}
        </div>
      );
    case 3:
      return (
        <div className={`${classes.box} ${classes.good}`}>
          {' '}
          <Success style={iconStyles} color="success" />{' '}
          {
            'Your password is good and meets our requirements, but we’d encourage you to take it to the next level of security by adding an additional word or character.'
          }{' '}
        </div>
      );
    case 4:
      return (
        <div className={`${classes.box} ${classes.good}`}>
          {' '}
          <Policy style={iconStyles} color="success" />{' '}
          {'Great job! You’ve got a strong password here that will help keep your account secured.'}{' '}
        </div>
      );
    default:
      return null;
  }
};

export default PasswordMeter;
