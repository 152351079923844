// @flow
import React, { type Element, memo, useCallback, useState, useRef } from 'react';
import { Popover } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import { useTheme } from 'helpers';
import { Typography } from '../types';
import { AvatarColor } from './ComponentTypes';

type ColorPickerTypes = {
  label?: string,
  color?: string,
  hasPicker?: boolean,
  name: string,
  id: string,
  dispatch?: Function
};

const ColorPicker = ({ label, color, hasPicker, name, id }: ColorPickerTypes): Element<*> => {
  const { dispatch } = useTheme();
  const inputRef = useRef();
  const [newColor, setNewColor] = useState(color);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const newId = id.split('__')[1];
  const newProps = {
    'aria-owns': open ? 'simple-popper' : undefined,
    'aria-haspopup': hasPicker ? 'true' : 'false',
    style: { backgroundColor: !label ? newColor : 'none' },
    onClick: (e: Event) => (hasPicker ? setAnchorEl(e.currentTarget) : null)
  };
  const handleChange = useCallback(
    hex => {
      dispatch.setColorSchemeCustomItem(newId, hex);
      return setNewColor(hex);
    },
    [dispatch, newId]
  );

  return (
    <>
      {!label ? (
        <AvatarColor {...newProps}>&nbsp;</AvatarColor>
      ) : (
        <Typography.p {...newProps} fontSize={14} bb={2}>
          {label}
        </Typography.p>
      )}
      <input type="hidden" name={`${name}[${newId}]`} value={newColor} ref={inputRef} />
      {hasPicker && (
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <SketchPicker color={newColor} onChange={pickedColor => handleChange(pickedColor.hex)} />
        </Popover>
      )}
    </>
  );
};

ColorPicker.defaultProps = {
  color: '#ccc',
  hasPicker: true,
  dispatch: () => null
};

export default memo<ColorPickerTypes>(ColorPicker);
