import React from 'react';
import { Radio, FormControlLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  radioBtn: {
    margin: 0,
    justifyContent: 'space-between',
    padding: '18px 15px 18px 25px',
    color: 'var(--colorDefault)',
    borderBottom: '1px solid var(--backgroundPaper)',
    '& [class*=MuiIconButton-label] svg': {
      width: 22,
      height: 22
    },
    '& [class*=MuiFormControlLabel-label]': {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center'
    },
    '&.selected': {
      color: 'var(--colorSystemInfo)',
      background: 'var(--backgroundDefault)'
    }
  },
  labelWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 40,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      verticalAlign: 'middle',
      fontSize: 24
    }
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5
  },
  hoverStyle: {
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)',
      background: ({ disabled }) => (disabled ? 'none' : 'var(--backgroundDefault)')
    }
  }
}));

const RadioWithIcon = ({ value, label, icon, selectedVal, disabled, onClick = () => {} }) => {
  const classes = useStyles({ disabled });

  return (
    <FormControlLabel
      value={value}
      control={<Radio color="default" onClick={onClick} />}
      labelPlacement="start"
      disabled={disabled}
      className={`${classes.radioBtn} ${classes.hoverStyle} ${selectedVal === value ? 'selected' : ''}`}
      label={
        <>
          {icon && <span className={classes.icon}>{icon}</span>}
          {label}
        </>
      }
    />
  );
};

export default React.memo(RadioWithIcon);
