import React, { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import { Box } from '@trustsecurenow/components-library';

import PropTypes from 'prop-types';
import { TextFieldStyled } from './ComponentTypes';
import Controller from './Controller';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';

const AutoCompleteIcon = styled(KeyboardArrowDownIcon)`
  font-size: 22px;
`;

const AutoCompleteWrapper = ({
  label,
  required,
  rules,
  name,
  creatable,
  filterSelectedOptions,
  labelName,
  getOptionLabel,
  onChange,
  options,
  getValue,
  inputProps,
  InputProps,
  ...props
}) => {
  const handleGetOptionLabel = option => {
    if (!option) return option;
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option?.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return String(option[labelName]);
  };

  const getLabel = getOptionLabel || handleGetOptionLabel;

  const filter = createFilterOptions();

  const handleChange = (event, newValue, onChange) => {
    if (typeof newValue === 'string') {
      onChange(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      onChange(newValue.inputValue);
    } else {
      onChange && onChange(newValue || null);
    }
  };

  return (
    <Autocomplete
      {...props}
      onChange={(event, newValue) => handleChange(event, newValue, onChange)}
      options={options}
      classes={{ root: `variant-outlined` }}
      freeSolo={creatable}
      selectOnFocus={creatable}
      clearOnBlur={creatable}
      handleHomeEndKeys={creatable}
      filterSelectedOptions={creatable ? false : filterSelectedOptions}
      popupIcon={<AutoCompleteIcon />}
      sx={{ maxWidth: 200 }}
      renderOption={(propsObj, option) => {
        if (typeof propsObj === 'string') return propsObj;
        return (
          <Box 
            sx={{
              maxWidth: 200,
              minWidth: 'min-content',
              overflowWrap: 'anywhere'
            }}
          >
            {props.disableCheckbox || !props.multiple ? null : <Checkbox checked={option.selected} />}
            <span>{propsObj[labelName]}</span>
          </Box>
        );
      }}
      filterOptions={
        creatable
          ? (options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(option => inputValue === option[labelName]);
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }
          : undefined
      }
      getOptionLabel={getLabel}
      renderInput={params => {
        return (
          <TextFieldStyled
            {...params}
            InputProps={{
              ...params.InputProps,
              ...InputProps
            }}
            name={name}
            autocomplete="none"
            inputProps={{
              ...params.inputProps,
              autocomplete: 'off',
              form: {
                autocomplete: 'off'
              }
            }}
            variant="outlined"
            label={label}
            error={inputProps.error}
            helperText={inputProps.helperText}
          />
        );
      }}
    />
  );
};

AutoCompleteWrapper.propTypes = {
  labelName: PropTypes.string,
  disableCheckbox: PropTypes.bool
};
AutoCompleteWrapper.defaultProps = {
  labelName: 'label',
  disableCheckbox: false
};
const AutoComplete = ({ required, rules, name, onChange, value, getValue, labelName, ...props }) => {
  let formContext = {};

  // when we use AutoComplete outside of Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { setValue } = formContext;

  useEffect(() => {
    if (formContextData) {
      const rhfValue = formContextData.getValues(name);

      if (rhfValue && typeof rhfValue !== 'object' && !getValue && props.options.length) {
        setValue(
          name,
          props.options.find(option => option.value === rhfValue)
        );
      }
    }
  }, [props.options]);

  if (!formContextData) {
    return (
      <AutoCompleteWrapper
        {...props}
        value={value}
        labelName={labelName}
        onChange={onChange}
        inputProps={{
          error: props.error,
          helperText: props.helperText
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field, formState }, error) => {
        const getValueHandler = () => {
          if (value) return value;
          return getValue ? getValue(props.options, field.value) : field.value;
        };

        return (
          <AutoCompleteWrapper
            {...field}
            {...props}
            labelName={labelName}
            value={getValueHandler()}
            onChange={onChange || field.onChange}
            inputProps={{
              error: error || props.error,
              helperText: error?.message || props.helperText
            }}
          />
        );
      }}
    />
  );
};

export default AutoComplete;
