// @flow
import React, { type Element, useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from 'components';
import { dataProvider, getStorage } from 'helpers';
import Parser from 'html-react-parser';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Box,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader
} from '@material-ui/core';
import * as sra from '../../../helpers/apis/services/sra';
import { set } from 'date-fns';
import { Height } from '@material-ui/icons';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  padded: {
    padding: '15px'
  },
  stepper: {
    display: 'grid !important',
    gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))',
    gridGap: '5px'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  fontsize: {
    fontSize: '25px'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  footer: {
    padding: '10px 15px 40px 15px',
    backgroundColor: 'var(--backgroundDefault)'
  },
  bolded: {
    fontWeight: 'bold'
  },
  hiddenFlex: {
    flex: '0'
  },
  content: {
    '& *': {
      color: 'var(--colorDarkGrey) !important'
    }
  }
}));
const useQontoStepIconStyles = makeStyles({
  root: {
    width: 27,
    height: 27,
    borderRadius: '50%',
    border: '1px solid var(--borderLight)',
    color: '#494949',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  activeCompleted: {
    borderColor: '#0cce6b',
    textAlign: 'center'
  },
  circle: {
    margin: '0 auto'
  },
  completed: {
    margin: '0 auto',
    width: 27,
    height: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    borderColor: '#0cce6b',
    color: 'var(--whiteAndBlack)',
    position: 'relative',
    textAlign: 'center'
  },
  active: {
    borderColor: 'var(--colorBase)',
    textAlign: 'center'
  },
  check: {
    position: 'absolute',
    margin: '0 auto',
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#0cce6b',
    borderColor: '#0cce6b',
    color: 'var(--whiteAndBlack)',
    top: '-8px',
    left: '-8px'
  },
  checkActiveCompleted: {
    backgroundColor: 'var(--colorBase)',
    borderColor: 'var(--colorBase)'
  },
  num: {
    width: 21,
    height: 21,
    borderRadius: '50%',
    backgroundColor: '#0cce6b',
    borderColor: '#0cce6b',
    color: 'var(--whiteAndBlack)',
    display: 'inline-block',
    lineHeight: '21px',
    verticalAlign: 'middle'
  },
  numActive: {
    width: 21,
    height: 21,
    borderRadius: '50%',
    backgroundColor: 'var(--colorBase)',
    borderColor: 'var(--colorBase)',
    color: 'var(--whiteAndBlack)',
    display: 'inline-block',
    lineHeight: '21px',
    verticalAlign: 'middle'
  },
  numActiveCompleted: {
    backgroundColor: 'var(--colorBase)',
    borderColor: 'var(--colorBase)'
  },
  checkMark: {
    verticalAlign: 'top'
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.activeCompleted]: completed
      })}
    >
      {completed ? (
        <div className={classes.completed}>
          <div
            className={clsx(classes.check, {
              [classes.checkActiveCompleted]: completed && active
            })}
          >
            <Check className={classes.checkMark} />
          </div>
          <span
            className={clsx(classes.num, {
              [classes.numActiveCompleted]: completed && active
            })}
          >
            {props.icon}
          </span>
        </div>
      ) : (
        <div className={classes.circle}>
          <span
            className={clsx({
              [classes.numActive]: active,
              [classes.num]: completed
            })}
          >
            {props.icon}
          </span>
        </div>
      )}
    </div>
  );
}
function dots(props) {
  const { active, completed } = props;

  return <div>...</div>;
}
function hidden(props) {
  const { active, completed } = props;

  return <div></div>;
}
const SraQuestions = props => {
  const [record, setRecord] = useState(null);
  const [categories, setCategories] = useState(null);
  const [update, forceUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const userId = getStorage('userId', true);
  const clientId = props.id;
  const close = props.close;

  const totalSteps = () => {
    return record.questions.length;
  };

  const isStepOptional = step => {
    return true;
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const handleNext = () => {
    setStep(step + 1);
    forceUpdate(!update);
  };

  const handleNextAndSave = next => {
    setLoading(true);
    const qa = record.questions.map(({ question_number, client_answer }) => ({ question_number, client_answer }));
    sra
      .setClientSraQA(clientId, qa)
      .then(res => {
        enqueueAlertSnackbar('Saved successfully.', {
          props: { severity: 'success' }
        });
        if (next === 'next') setStep(step + 1);
        if (next === 'close') close();
        forceUpdate(!update);
        setLoading(false);
      })
      .catch(err => {
        console.clear();
        console.log(err);
      });
  };

  const handleBack = () => {
    setStep(step - 1);
    forceUpdate(!update);
  };

  const handleStep = step => () => {
    setStep(step);
    forceUpdate(!update);
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const constructCategories = arr => {
    const data = {};
    arr.map(item => {
      if (!data.hasOwnProperty(item.category_name)) {
        data[item.category_name] = {};
        data[item.category_name][item.sub_category_name] = 1;
      } else if (!data[item.category_name].hasOwnProperty(item.sub_category_name))
        data[item.category_name][item.sub_category_name] = 1;
      else data[item.category_name][item.sub_category_name] += 1;
    });
    return data;
  };

  function isStepComplete(step) {
    return record.questions[step].hasOwnProperty('client_answer') && record.questions[step].client_answer !== '';
  }

  const handleAnswerChange = event => {
    const QuizData = record;
    QuizData.questions[step].client_answer = event.target.value;
    let answered_questions = QuizData.questions.filter(val => {
      return val.hasOwnProperty('client_answer') && val.client_answer !== '';
    });
    setProgress((answered_questions.length / QuizData.questions.length) * 100);
    setRecord(QuizData);
    forceUpdate(!update);
  };

  const changeCategory = e => {
    setStep(record.questions.findIndex(q => q.sub_category_name === e.target.value));
  };
  useEffect(() => {
    if (!record)
      sra
        .getClientSraQA(clientId)
        .then(res => {
          setRecord(res.data);
          let answered_questions = res.data.questions.filter(val => {
            return val.hasOwnProperty('client_answer') && val['client_answer'] !== '';
          });
          setProgress((answered_questions.length / res.data.questions.length) * 100);
          setCategories(constructCategories(res.data.questions));
          forceUpdate(!update);
        })
        .catch(err => {
          console.clear();
          console.log(err);
        });
  }, [record]);

  if (!record || !categories || loading) return <Loading />;
  console.log(record);
  console.log(categories);
  return (
    <div className={classes.padded}>
      <Container.Grid sm={12} xs={12}>
        <Container.Grid sm={12} xs={12}>
          <Container.Grid alignItems="center" sm={6} xs={6}>
            <Container.Grid sm={12} xs={12}>
              <Typography variant="h4" display="block" gutterBottom>
                Risk Assessment Questions
              </Typography>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid alignItems="center" Container justify="flex-end" sm={6} xs={6}>
            <Container.Grid justify="flex-end" item xs={9} sm={9}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-native-select">Categories</InputLabel>
                <Select
                  onChange={changeCategory}
                  native
                  value={record.questions[step].sub_category_name}
                  id="grouped-native-select"
                >
                  {Object.keys(categories).map(cat => {
                    return (
                      <>
                        <optgroup label={cat}>
                          {Object.keys(categories[cat]).map(subCat => {
                            return (
                              <option value={subCat}>
                                {subCat}
                                {' ('}
                                {categories[cat][subCat]}
                                {')'}
                              </option>
                            );
                          })}
                        </optgroup>
                      </>
                    );
                  })}
                </Select>
              </FormControl>
            </Container.Grid>
            <Container.Grid justify="flex-end" item xs={3} sm={3}>
              <Box position="relative">
                <CircularProgress variant="static" size={56} value={progress} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h5" component="div" color="textSecondary">{`${Math.round(
                    progress
                  )}%`}</Typography>
                </Box>
              </Box>
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid p={2} sm={12} xs={12}>
          <Divider className={classes.root} />
        </Container.Grid>
        <Container.Grid className={classes.stepper} sm={12} xs={12}>
          <Stepper p={0} className={classes.stepper} alternativeLabel nonLinear activeStep={step}>
            {record.questions.map((question, index) => {
              const stepProps = {};
              const buttonProps = {};
              if (isStepOptional(index)) {
                buttonProps.optional = '';
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step onClick={handleStep(index)} completed={isStepComplete(index)}>
                  <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Container.Grid>
        <Container.Grid p={2} sm={12} xs={12}>
          <Divider className={classes.root} />
        </Container.Grid>
        <Container.Grid>
          <Container.Grid sm={12} xs={12}>
            <Container.Grid pb={4}>
              <FormControl component="fieldset">
                <Typography color="primary" variant="h4">
                  {step + 1}
                  {'.'}
                  {record.questions[step].question}
                </Typography>
                <Container.Grid p={1} sm={12} xs={12}></Container.Grid>
                <Typography className={classes.bolded} variant="h5">
                  Additional Information
                </Typography>
                <div className={classes.content}>{Parser(record.questions[step].additional_information)}</div>
                <Typography className={classes.bolded} variant="h5">
                  How To Answer Question
                </Typography>
                <div className={classes.content}>{Parser(record.questions[step].how_to_answer_question)}</div>
                <RadioGroup
                  aria-label="question"
                  name={`question${step}`}
                  value={record.questions[step].client_answer}
                  onChange={handleAnswerChange}
                  row
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                  <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Container.Grid>

            <Container.Grid className={classes.footer} justify="space-between">
              <Container.Grid p={2} sm={12} xs={12}>
                <Divider className={classes.root} />
              </Container.Grid>
              <Button variant="outlined" disabled={step === 0} onClick={handleBack} className={classes.button}>
                {'<'} Return to previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={e => handleNextAndSave('')}
                className={classes.button}
              >
                Save
              </Button>
              {step !== record.questions.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => handleNextAndSave('next')}
                  className={classes.button}
                >
                  Save and Next
                </Button>
              )}
              {step === record.questions.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => handleNextAndSave('close')}
                  className={classes.button}
                >
                  Save and Next Step
                </Button>
              )}
            </Container.Grid>
          </Container.Grid>
        </Container.Grid>
      </Container.Grid>
    </div>
  );
};

export default SraQuestions;
