// @flow
import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import type { Element } from 'react';
// todo: Remove file
type Props = {
  disabled?: boolean,
  fullWidth?: boolean,
  id: string | number,
  label: string,
  multiline?: boolean,
  name: string,
  record?: Object,
  source: string
};

const Input = ({ source, record, label, id, name, multiline, fullWidth, disabled }: Props): Element<*> => {
  const [value, setValue] = useState(record);
  return (
    <FormControl fullWidth margin="dense" variant="outlined" disabled={disabled}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        name={source}
        value={value}
        onChange={(e: { target: { value: string | number } }) => setValue(e.target.value)}
        fullWidth={fullWidth}
        multiline={multiline}
      />
    </FormControl>
  );
};

Input.defaultProps = {
  disabled: false,
  fullWidth: false,
  multiline: false,
  record: {}
};

export default Input;
