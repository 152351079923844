// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const NotificationAlert = (props: Props) => (
  <SvgIconStyled {...props} width="18" height="24" viewBox="0 0 18 24" role="img" aria-hidden="false">
    <path d="M10.8228 19.7068C10.315 19.7345 9.92727 20.1777 9.95497 20.6855V20.7778C9.95497 21.5349 9.37332 22.1535 8.65319 22.1535C7.93305 22.1535 7.35139 21.5349 7.35139 20.7778C7.35139 20.27 6.93593 19.8546 6.42814 19.8546C5.92035 19.8546 5.50488 20.27 5.50488 20.7778C5.50488 22.5597 6.91747 24 8.65319 24C10.3889 24 11.8015 22.5505 11.8015 20.7778C11.8015 20.704 11.8015 20.6393 11.7923 20.5655C11.7646 20.0577 11.3399 19.6699 10.8228 19.7068Z" />
    <path d="M16.6028 16.8448C14.9871 16.4201 14.0361 14.6844 14.4793 12.9948C14.4885 12.9671 14.4978 12.9486 14.4978 12.9209L14.507 12.9117C14.8763 12.1085 15.061 11.2591 15.061 10.382C15.061 7.89844 13.5468 5.75649 11.3679 4.75938C12.0973 3.54068 11.9773 1.96192 10.9709 0.927871C9.76148 -0.30929 7.73955 -0.30929 6.46546 0.927871C5.39448 1.97115 5.2006 3.56838 5.89305 4.79631C3.75109 5.80266 2.26465 7.92614 2.26465 10.382C2.26465 11.2037 2.43084 11.9977 2.74474 12.7455C2.74474 12.764 2.79091 12.9302 2.80937 12.9948C2.83707 13.0779 2.86477 13.2626 2.86477 13.2626C3.16021 14.8783 2.20003 16.4478 0.685889 16.854C0.196564 16.9833 -0.0988837 17.4911 0.030372 17.9804C0.141163 18.3959 0.510466 18.6636 0.92593 18.6636C0.944395 18.6636 16.3535 18.6636 16.3628 18.6636C16.769 18.6636 17.1475 18.3866 17.2583 17.9804C17.3876 17.4818 17.0921 16.974 16.6028 16.8448ZM7.67493 4.14079C7.17637 3.633 7.2133 2.78361 7.75802 2.24813C8.30274 1.72187 9.15214 1.7034 9.65069 2.21119C10.1493 2.71898 10.1123 3.56838 9.5676 4.10387C9.53067 4.1408 9.51221 4.18696 9.48451 4.22389C9.21676 4.18696 8.93979 4.1685 8.66281 4.1685C8.3489 4.1685 8.04423 4.19619 7.73956 4.24235C7.72109 4.21465 7.70263 4.16849 7.67493 4.14079ZM8.66281 6.01501C11.1741 6.01501 13.2145 7.9723 13.2145 10.382C13.2145 10.9913 12.7159 12.4224 12.6882 12.5239C12.2727 14.0842 12.6328 15.6538 13.5007 16.8355H3.75109C4.55433 15.7553 4.94209 14.352 4.68358 12.9209C4.65588 12.7917 4.11116 10.9544 4.11116 10.3728C4.11116 7.9723 6.15156 6.01501 8.66281 6.01501Z" />
  </SvgIconStyled>
);

export default NotificationAlert;
