import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';
import { getStorage } from 'helpers/storage';

const BASE_PATH = `${DOMAIN_URL}/UsersAPI`;

export const moveClient = (client_id, new_partner_id) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/moveClient/${client_id}`,
    headers: getHeaders(),
    data: { new_partner_id: new_partner_id }
  };
  return apiClient.put(requestOptions);
};

export const getClientUsersList = (clientId, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/usersList/${clientId}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const postClientUsersActions = (clientId, data, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/usersListActions/${clientId}`,
    data,
    headers: getHeaders(),
    params
  };
  return apiClient.post(requestOptions);
};

export const deleteClientUsersActions = (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/usersListActions/${clientId}`,
    data,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const resetPassword = userId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/resetPassword/${userId}`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const resetMFA = userId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/resetMFA/${userId}`,
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};

export const getClientTags = (clientId, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/tags/${clientId}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const addClientTags = (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/tags/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const getUsersGroups = (clientId) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/userGroups/${clientId}`,
    headers: getHeaders(),
  };
  return apiClient.get(requestOptions);
};

export const updateUserProfile = (userId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/user/profile/${userId}`,
    headers: getHeaders(),
    data: data
  };
  return apiClient.put(requestOptions);
};

export const getUserData = fields => {
  const requestOptions = {
    url: `${BASE_PATH}/user/get_user_data?fields=${fields.join(',')}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const getUserConfig = () => {
  const requestOptions = {
    url: `${BASE_PATH}/user/config`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};

export const setFavoriteApplication = (appName) => {
  const requestOptions = {
    url: `${BASE_PATH}/user/favoriteapp`,
    headers: getHeaders(),
    data: { favourite_application: appName },
  };
  return apiClient.put(requestOptions);
};

export const getEssStats = () => {
  const userId = getStorage('userId');
  const requestOptions = {
    url: `${BASE_PATH}/user/essStats/${userId}`,
    headers: getHeaders(),
  };
  return apiClient.get(requestOptions);
}

export const manageUserDarkModeData = (isDarkMode) => {
  const requestOptions = {
    url: `${BASE_PATH}/user/darkmode`,
    headers: getHeaders(),
    data: { dark_mode: isDarkMode },
  };
  return apiClient.put(requestOptions);
};

export const getPromotionClaimVisibility = () => {
  const requestOptions = {
    url: `${BASE_PATH}/PromotionClaims`,
    headers: getHeaders(),
  };
  return apiClient.get(requestOptions);
};

export const sendPromotionClaim = (data) => {
  const requestOptions = {
    url: `${BASE_PATH}/PromotionClaims`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const getPromotion = () => {
  const requestOptions = {
    url: `${BASE_PATH}/Promotion`,
    headers: getHeaders(),
  };
  return apiClient.get(requestOptions);
};

export const updateRequest = preferences => {
  const requestOptions = {
    url: `${BASE_PATH}/user/preferences`,
    headers: getHeaders(),
    data: preferences
  };
  return apiClient.put(requestOptions);
};

export const setDevicesToken = (userId, token) => {
  const requestOptions = {
    url: `${BASE_PATH}/user/SetDevicesTokens/${userId}`,
    data: { device_token: token },
    headers: getHeaders()
  };
  return apiClient.put(requestOptions);
};
