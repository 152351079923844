// @flow
import { type ComponentType } from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

export const SvgIconStyled: ComponentType<*> = styled(SvgIcon)`
  font-size: ${({ size }) => (size ? `calc(${size} * var(--fontSize))` : `calc(var(--fontSize) * 1.3)`)};

  ${({ m }) => m && `margin: calc(${m} * var(--spacing))`};
  ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
  ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
  ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
  ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};

  color: ${({ color }) => {
    switch (color) {
      case 'danger':
        return 'var(--colorSystemDanger)';
      case 'warning':
        return 'var(--colorSystemWarning)';
      case 'success':
        return 'var(--colorSystemSuccess)';
      case 'info':
        return 'var(--colorSystemInfo)';
      default:
        return `var(--${color})`;
    }
  }};

  & > g {
    ${({ strokeColor }) => strokeColor && `stroke: var(--${strokeColor})`};
  }
`;
