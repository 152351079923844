import { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Redirects the user to a specified link.
 * @param {Object} props - The component props.
 * @param {string} props.link - The link to redirect to.
 * @returns {null} - This component does not render anything.
 */
const Redirect = ({ link }) => {
  useEffect(() => {
    window.location.href = link;
  }, [link]);

  return null;
};

Redirect.propTypes = {
  link: PropTypes.string.isRequired
};

export default Redirect;
