// @flow
import React, { useState, type Element, useEffect } from 'react';
import { Button, CircularProgress, Dialog } from '@material-ui/core';
import { Typography } from 'components';
import { LazyIcon } from 'components/icons';
import PropTypes from 'prop-types';
import { DialogTitleStyled, IconButton, ButtonUppercase } from './ComponentTypes';

type ModalTypes = {
  button: {
    variant?: string,
    color?: string,
    icon?: Element<*>,
    label?: string,
    disabled?: boolean
  },
  buttonUppercase: {
    icon?: Element<*>,
    label?: string,
    disabled?: boolean
  },
  typography: { label?: string },
  title?: string,
  showDismiss?: boolean,
  fullWidth: boolean,
  fullScreen: boolean,
  data?: Object,
  component?: Element<*> | null,
  opened?: boolean,
  dismissOutside: boolean,
  onClose?: () => void,
  overwriteStyles?: Object
};

const Modal = ({
  button,
  buttonUppercase,
  typography,
  dismissOutside,
  title,
  data,
  opened = false,
  showDismiss = true,
  fullWidth = false,
  fullScreen = false,
  maxWidth = 'md',
  onClose,
  overwriteStyles,
  loadingContent,
  loadingSkeleton,
  loadingSize,
  ...rest
}: ModalTypes): Element<*> => {
  const [open, setOpen] = useState(opened);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  return (
    <div>
      {button && (
        <Button disabled={button.disabled} variant={button.variant} color={button.color} onClick={() => setOpen(true)}>
          {button.icon && <>{button.icon}&nbsp;</>}
          {button.label}
        </Button>
      )}
      {buttonUppercase && (
        <ButtonUppercase disabled={buttonUppercase.disabled} onClick={() => setOpen(true)}>
          {buttonUppercase.icon && <>{buttonUppercase.icon}&nbsp;</>}
          {buttonUppercase.label}
        </ButtonUppercase>
      )}
      {typography && (
        <Typography.h4 pt={2} pb={2} mt={0.1} mb={0.1} onClick={() => setOpen(true)}>
          {typography.label}
        </Typography.h4>
      )}
      <Dialog
        {...rest}
        open={open}
        onClose={onClose ? () => onClose() : () => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={dismissOutside}
        disableEscapeKeyDown={dismissOutside}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        style={{ overflowScrolling: 'touch', WebkitOverflowScrolling: 'touch', ...overwriteStyles?.dialog }}
      >
        {title && (
          <DialogTitleStyled id="dialog-title" style={overwriteStyles?.dialogHeader}>
            <Typography.h3
              className="modal-title"
              style={{ width: '90%', overflowWrap: 'anywhere', ...overwriteStyles?.title }}
            >
              {title}
            </Typography.h3>

            <IconButton onClick={onClose ? () => onClose() : () => setOpen(false)}>
              <LazyIcon component="Close" style={overwriteStyles?.icon} />
            </IconButton>
          </DialogTitleStyled>
        )}

        {loadingContent ? (
          <div style={{ display: 'felx', justifyContent: 'center', alignItems: 'center', ...loadingSkeleton }}>
            {' '}
            <CircularProgress
              style={{
                margin: `calc(${loadingSkeleton.height}/3 - ${loadingSize}px/2) calc(${loadingSkeleton.width}/2 - ${loadingSize}px/2)`
              }}
              size={loadingSize}
              thickness={4}
            />{' '}
          </div>
        ) : (
          data
        )}
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  loadingContent: PropTypes.bool,
  loadingSkeleton: PropTypes.shape({ width: PropTypes.string, height: PropTypes.string }),
  loadingSize: PropTypes.number
};

Modal.defaultProps = {
  opened: false,
  title: '',
  showDismiss: false,
  data: null,
  component: null,
  loadingContent: false,
  loadingSkeleton: { width: '50rem', height: '25rem' },
  loadingSize: 60,
  onClose: null
};

export default Modal;
