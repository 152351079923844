/* eslint-disable react/jsx-pascal-case */
// @flow
import React, { memo, type ComponentType } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNotify, useCreate, useRedirect } from 'react-admin';

import { Done as DoneIcon } from 'components/icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
  RHFAutoCompleteAsync as AutoCompleteAsync,
  CustomTooltip,
  Info,
  Typography,
  Container as GContainer,
  ButtonCancel,
  Button
} from 'components';
import { Box, IconButton, TextField as AutoCompleteTextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { Element } from 'react';
import SelectField from 'components/newForms/SelectField';

import contentAdmin from 'helpers/apis/contentAdmin';
import clients from 'helpers/apis/clients';

import { BSN_SET_ANY } from 'conf';
import { useDebounce } from 'hooks';
import { updateNestedMutable } from 'utils/update';
import ClientCreateProceedModal from './ClientCreateProceedModal';

type ClientsCreateProps = Object;

const REDIRECT = '/clients';
const ClientsCreate: Function = (props: ClientsCreateProps): Element<*> => {
  const [clientCreateState, setClientCreateState] = React.useState({
    is_save_pending: false,
    data: { name: '' },
    tms_data: {
      tms_clients: [],
      tms_name: ''
    },
    is_industries_pending: false,
    is_tmsclients_pending: false,
    current_selected_industry: '',
    tms_search_value: '',
    current_selected_tms: '',
    is_proceed_modal_open: false
  });
  const [tms_seach_value] = useDebounce({ value: clientCreateState.tms_search_value, delay: 500 });
  const dispatch = useDispatch();
  const notify = useNotify();

  const { partner_id } = useSelector(s => s?.bsn?.user?.profile ?? {});
  const { client_industries, tms_clients, tms_name } = useSelector(s => s?.bsn?.client ?? {});
  // switch partner id in case of mask mode
  const maskMode = localStorage.getItem('maskMode');
  const maskPartnerId = localStorage.getItem('maskPartnerId');
  const partnerId = maskMode ? maskPartnerId : partner_id;

  // const [create] = useCreate('clients');
  const redirectTo = useRedirect();
  const { basePath } = props;

  const handleSave = proceed => {
    if (!clientCreateState.data.name?.trim()) {
      notify('Please enter a client name', 'warning');
      return;
    }
    if (!clientCreateState.current_selected_industry) {
      notify('Please enter an Industry name', 'error');
      return;
    }
    setClientCreateState(old => ({ ...old, is_save_pending: true }));

    const send_data = {
      ...clientCreateState.data,
      industry_id: clientCreateState.current_selected_industry,
      proceed: proceed ?? false,
      tms: { tms_client_id: clientCreateState.current_selected_tms, tms_name }
    };
    if (!clientCreateState.current_selected_tms) {
      delete send_data.tms;
    }

    clients
      // VDFSUlBRPT0=
      .addClient(partnerId, send_data)
      .then(res => {
        notify('Client has been created', 'info');
        dispatch({
          type: BSN_SET_ANY,
          payload: {
              client: {
                is_updated_used_industries: false
              }
          }
        });
        setClientCreateState(old => ({ ...old, is_save_pending: false }));
        redirectTo(REDIRECT, basePath);
      })
      .catch(err => {
        if (err.response.status === 400) {
          // already exits
          dispatch({
            type: BSN_SET_ANY,
            payload: {
                client: {
                  is_updated_used_industries: false
                }
            }
          });
          setClientCreateState(old => ({ ...old, is_proceed_modal_open: true, is_save_pending: false }));
        } else {
          setClientCreateState(old => ({ ...old, is_save_pending: false }));
          notify(err.response.data.status, 'error');
        }
      });

    // create();
  };

  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const hasAccess = accessApps?.hasOwnProperty('clients');

  // filteing  on tms
  React.useEffect(() => {
    if (!tms_name || !partnerId) {
      return;
    }
    setClientCreateState(old => ({ ...old, is_tmsclients_pending: true }));
    const filters = { tms: tms_seach_value.value !== '' ? tms_seach_value.value : undefined };
    clients
      .getPickList({
        client_id: partnerId,
        types: 'tms_clients',
        filters: Object.keys(filters).length ? JSON.stringify(filters) : undefined,
        ...(maskMode && { masked_partner_id: maskPartnerId })
      })
      .then(res => {
        if (res.data) {
          dispatch({
            type: BSN_SET_ANY,
            payload: {
                client: {
                  // tms client can be {id,AccountName} or {identifier,name}
                  tms_clients:
                    res?.data?.tms_clients?.map(v => ({
                      value: v.id ?? v.identifier,
                      label: v.AccountName ?? v.name
                    })) ?? [],
                  tms_name: res?.data?.tms_name ?? ''
                }
            }
          });
          setClientCreateState(old => ({ ...old, is_tmsclients_pending: false }));
        }
      })
      .catch(err => {
        setClientCreateState(old => ({ ...old, is_tmsclients_pending: false }));
        notify('Failed to Filter tms Clients', 'error');
      });
  }, [tms_seach_value.value, dispatch, notify, partnerId, tms_name, maskMode, maskPartnerId]);

  // onmount
  React.useEffect(() => {
    if (partnerId) {
      setClientCreateState(old => ({ ...old, is_industries_pending: true, is_tmsclients_pending: true }));
      clients
        .getPickList({
          client_id: partnerId,
          types: `tms_clients${!client_industries ? ',industries' : ''}`,
          ...(maskMode && { masked_partner_id: maskPartnerId })
        })
        .then(industriesRes => {
          if (industriesRes.data) {
            let { industries: c_industries, tms_clients: tms_c, tms_name: tms_n } = industriesRes.data;

            try {
              c_industries = client_industries ?? c_industries?.map(v => ({ value: v.id, label: v.name })) ?? [];
            } catch (err) {
              c_industries = [];
            }

            try {
              //  tms clients can be a string in response  try&catch makes sure each operation fall back to default
              // tms client can be {id,AccountName} or {identifier,name}
              tms_c =
                tms_c?.map(v => ({
                  value: v.id ?? v.identifier,
                  label: v.AccountName ?? v.name
                })) ?? [];
            } catch (err) {
              tms_c = [];
              tms_n = null;
            }

            dispatch({
              type: BSN_SET_ANY,
              payload: {
                  client: {
                    client_industries: c_industries,
                    tms_clients: tms_c,
                    tms_name: tms_n
                  }
              }
            });
          }
        })
        .catch(err => {
          setClientCreateState(old => ({ ...old, is_industries_pending: false }));
          notify('Failed to get Pick list', 'error');
        })
        .finally(() => {
          setClientCreateState(old => ({ ...old, is_industries_pending: false, is_tmsclients_pending: false }));
        });
    }
  }, [partnerId, client_industries, dispatch, notify, maskMode, maskPartnerId]);

  if (accessApps && !hasAccess) window.location.href = '/#/myDashboard/dashboard';

  console.log('Ra-props', props);
  const onCompanyNameChange = ev => {
    const { value } = ev.target;
    setClientCreateState(old => ({ ...old, data: { ...old.data, name: value } }));
  };

  return (
    <>
      {/* <Create {...props}> */}
      {/* <SimpleForm toolbar={<CreateToolbar handleCancel={props.history.push} />}>  */}

      <GContainer.Paper mt={3} p={2.625}>
        <GContainer.Grid container maxWidth xs={12} sm={12} md={12} lg={12} xl={12}>
          <GContainer.Grid container maxWidth xs={12} sm={12} md={12} lg={12} xl={12}>
            <GContainer.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography.h3 mb={4}>Create New Client</Typography.h3>
            </GContainer.Grid>
            <GContainer.Grid container disableGutters xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
              {tms_name ? (
                <>
                  <GContainer.Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ justifyContent: 'stretch', display: 'flex', alignItems: 'center', width: '99.5%' }}>
                      <Autocomplete
                        options={!clientCreateState.is_tmsclients_pending ? tms_clients ?? [] : []}
                        getOptionLabel={option => option.label}
                        fullWidth
                        loadingText="loading"
                        disabled={clientCreateState.is_tmsclients_pending && !tms_name}
                        loading={clientCreateState.is_tmsclients_pending}
                        onChange={(_ev, item) => {
                          setClientCreateState(old =>
                            updateNestedMutable(old, {
                              current_selected_tms: item?.value,
                              data: { name: item?.label ?? old.data.name }
                            })
                          );
                        }}
                        onClose={() => {
                          if (!clientCreateState.current_selected_tms) {
                            setClientCreateState(old => ({ ...old, tms_search_value: '' }));
                          }
                        }}
                        // loading
                        renderInput={params => (
                          <AutoCompleteTextField
                            {...params}
                            onChange={e => {
                              const { value } = e.target;
                              setClientCreateState(old => ({ ...old, tms_search_value: value }));
                            }}
                            fullWidth
                            label="Select Client from TMS"
                            variant="outlined"
                          />
                        )}
                      />
                      <CustomTooltip
                        title={
                          <p>
                            Please note, this field is not required to <br />
                            create a new client. All clients that have
                            <br />
                            already mapped will not be in the list.
                          </p>
                        }
                        placement="top"
                        disableClickListener
                      >
                        <IconButton style={{ padding: '1rem' }}>
                          <Info />
                        </IconButton>
                      </CustomTooltip>
                    </div>
                  </GContainer.Grid>
                </>
              ) : null}
              <GContainer.Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <AutoCompleteTextField
                  variant="outlined"
                  value={clientCreateState.data.name}
                  label="Company Name"
                  onChange={onCompanyNameChange}
                  fullWidth
                />
              </GContainer.Grid>
              <GContainer.Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div
                  style={{
                    justifyContent: 'stretch',
                    display: 'flex',
                    alignItems: 'center',
                    width: '96%'
                  }}
                >
                  <SelectField
                    fullWidth
                    required
                    allowEmpty={false}
                    id="selectIdentityProviders"
                    label="Industry"
                    loading={clientCreateState.is_industries_pending}
                    choices={client_industries} // Todo api should return clientIndustries as id name
                    name="selectIdentityProviders"
                    value={clientCreateState.current_selected_industry}
                    onChange={ev => {
                      const { value } = ev.target;
                      setClientCreateState(old => ({ ...old, current_selected_industry: value }));
                    }} // Todo SetClient Industry
                    // required
                  />
                  <CustomTooltip
                    title={
                      <p>
                        This information will help us create more
                        <br />
                        industry specific content in the future
                      </p>
                    }
                    placement="top"
                    disableClickListener
                  >
                    <IconButton style={{ padding: '1rem' }}>
                      <Info />
                    </IconButton>
                  </CustomTooltip>
                </div>
              </GContainer.Grid>
            </GContainer.Grid>
          </GContainer.Grid>
          <GContainer.Grid container maxWidth xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ height: '1px', background: 'var(--colorGreyLight3)', width: '100%', marginTop: '4.5rem' }} />
            <Box display="flex" style={{ marginLeft: 'auto' }} alignItems="center" justifyContent="end" p={2.625}>
              <ButtonCancel
                onClick={() => {
                  redirectTo('/clients');
                }}
              >
                Cancel
              </ButtonCancel>
              <Button
                disabled={clientCreateState.is_save_pending}
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                backgroundDisabled="colorSystemSuccessDisable"
                height={36}
                pr={3}
                pl={3}
                onClick={() => {
                  if (!clientCreateState.is_save_pending) {
                    handleSave(false);
                  }
                }}
                style={{
                  cursor: `${clientCreateState.is_save_pending ? 'not-allowed' : 'pointer'}`
                }}
                type="submit"
              >
                {clientCreateState.is_save_pending ? (
                  <CircularProgress size={18} thickness={2} />
                ) : (
                  <DoneIcon color="colorCommonWhite" />
                )}
                &nbsp;&nbsp;Create
              </Button>
            </Box>
          </GContainer.Grid>
        </GContainer.Grid>
      </GContainer.Paper>
      <ClientCreateProceedModal
        opened={clientCreateState.is_proceed_modal_open}
        onClose={() => {
          setClientCreateState(old => ({ ...old, is_proceed_modal_open: false }));
        }}
        pendingAction={clientCreateState.is_save_pending}
        onSave={() => {
          handleSave(true);
        }}
      />
      {/* </SimpleForm> */}
      {/* </Create> */}
    </>
  );
};

export default memo<ClientsCreateProps>(ClientsCreate);
