import React from 'react';
import { Button, ButtonCancel, Modal, Typography } from 'components';
import { CloseIcon } from 'components/common/ComponentTypes';
import { Box } from '@material-ui/core';
import { Warning as AlertIcon } from 'components/icons';

const DeactivateClientModal = ({ clientState, onClose, onSubmit, isMobile = false }) => {
  return (
    <Modal
      onClose={onClose}
      loadingContent={clientState.isDeactivateClientModalPending}
      loadingSize={60}
      loadingSkeleton={{ width: '50rem', height: '25rem' }}
      opened={clientState.isDeactivateClientModalOpen}
      dismissOutside
      overwriteStyles={{
        dialog: { maxWidth: '60rem', margin: '0 auto', padding: '2rem' }
      }}
      data={
        <>
          <CloseIcon
            style={{ marginLeft: 'auto', padding: '2rem', width: '2.2rem', height: '2.2rem', cursor: 'pointer' }}
            onClick={onClose}
          />
          <div
            style={{
              padding: '1.6rem 13.8rem 6rem 13.8rem',
              display: 'flex',
              justifySelf: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Box
              mt={1}
              p={2.25}
              display="flex"
              alignItems="center"
              style={{ backgroundColor: 'rgba(var(--colorSystemDanger-rgb),0.2)', marginBottom: '2.6rem' }}
              borderRadius="50%"
            >
              <AlertIcon color="var(--colorSystemDanger)" style={{ fontSize: '3.4rem' }} />
            </Box>
            <Typography.h2
              mx={0}
              my={0}
              textAlign="center"
              width={isMobile ? 272 : 323}
              style={{ lineHeight: '24px', color: 'var(--colorSystemDanger)', fontSize: isMobile ? '18px' : '24px' }}
            >
              Are you sure you would like to deactivate this client?
            </Typography.h2>

            <Button
              backgroundColor="colorSystemDanger"
              backgroundColorHover="colorSystemDangerHover"
              backgroundDisabled="colorSystemDangerDisable"
              height={36}
              width={183}
              variant="outlined"
              style={{ padding: '1.2rem 4.5rem', marginTop: '4.5rem' }}
              onClick={onSubmit}
            >
              Yes, I want to deactivate
            </Button>
            <ButtonCancel
              onClick={onClose}
              style={{ marginTop: '3rem', marginRight: '0' }}
            >
              Cancel
            </ButtonCancel>
          </div>
        </>
      }
    />
  )
}

export default DeactivateClientModal;