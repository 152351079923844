import React from "react"
import DashboardCertificateModal from '../../../apps/dashboard/DashboardCertificateModal';
import { encodeId } from '../../../helpers';
import Modal from '../../modal/Modal';

const TrainingPrintModal = ({
  open = false,
  onClose = () => {},
  userId,
  trainingId
}) => {
  return (
    <Modal
      data={<DashboardCertificateModal userId={userId} trainingId={trainingId} />}
      opened={open}
      fullWidth
      dismissOutside
      onClose={onClose}
      title={'View the Certificate'}
    />
  )
}

export default TrainingPrintModal
