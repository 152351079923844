// @flow
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import DashboardBoxTitle from '../dashboard/DashboardBoxTitle';

const colorIndicator = (value, colorBreakPointsArr) => {
  const sortedUpperLimits = colorBreakPointsArr.map(cb => cb.upperLimit).sort((a, b) => a - b);
  const targetUpperLimit = sortedUpperLimits.filter(limit => limit >= value)[0];

  const color = colorBreakPointsArr.filter(cb => cb.upperLimit === targetUpperLimit)[0]?.color || 'grey';

  return color;
};

const circleAnimation = keyframes`
  from {
    stroke-dashoffset: calc(var(--fill) * 2.4);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const ContainerCircle = styled.div`
  width: 100%;
`;

const SvgCircle = styled.svg`
  --path: 100%;
  --fill: ${({ fillValue }) => `${fillValue}%`};
  --none: transparent;
  position: relative;
`;

const CircleBackground = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: var(--colorGreyLight3);
  stroke-dasharray: calc(var(--path) * 2.4);
`;

const CircleForeground = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: ${({ fillValue, colorBreakPoints }) => colorIndicator(fillValue, colorBreakPoints)};
  stroke-dasharray: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  stroke-dashoffset: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  animation: ${circleAnimation} 1s linear;
`;

const TextCircle = styled.text`
  font-size: calc(var(--fontSize) * 1.5);
  font-weight: var(--fontWeightMedium);
  fill: var(--colorDefault);
`;

const GTotal = styled.g`
  transform: translate(-5%, 12%);

  @-moz-document url-prefix() {
    transform: translate(-5%, 15%);
  }
`;

const TextTotalCircle = styled(TextCircle)`
  font-size: calc(var(--fontSize) / 3);
`;

const GChartCircle = ({
  score,
  total,
  hideScore,
  hideTotal,
  customScoreLabel,
  customTotalLabel,
  colorBreakPoints,
  title,
  description,
  fontSize,
  icon,
  iconSize
}) => {
  const fillPercent = (score / total) * 100;

  return (
    <>
      {title && (
        <DashboardBoxTitle
          label={title}
          description={description}
          fontSize={fontSize}
          icon={icon}
          iconSize={iconSize}
        />
      )}
      <ContainerCircle>
        <SvgCircle viewBox="0 0 120 120" fillValue={fillPercent}>
          <g transform="translate(60, 60)">
            <CircleBackground r="45" cx="0" cy="0" />
          </g>

          <g transform="translate(60, 60) rotate(-90 0 0)">
            <CircleForeground r="45" cx="0" cy="0" fillValue={fillPercent} colorBreakPoints={colorBreakPoints} />
          </g>

          <>
            {!hideScore && (
              <TextCircle x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" dominantBaseline="central">
                {customScoreLabel || score}
              </TextCircle>
            )}
            {!hideTotal && (
              <GTotal>
                <TextTotalCircle x="50%" y="48%" alignmentBaseline="middle">
                  {customTotalLabel || `of ${total}`}
                </TextTotalCircle>
              </GTotal>
            )}
          </>
        </SvgCircle>
      </ContainerCircle>
    </>
  );
};

GChartCircle.propTypes = {
  score: PropTypes.number.isRequired,
  total: PropTypes.number,
  hideScore: PropTypes.bool,
  hideTotal: PropTypes.bool,
  customScoreLabel: PropTypes.string,
  customTotalLabel: PropTypes.string,
  colorBreakPoints: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      upperLimit: PropTypes.number.isRequired
    })
  ),
  title: PropTypes.string,
  description: PropTypes.string,
  fontSize: PropTypes.number,
  icon: PropTypes.bool,
  iconSize: PropTypes.number
};

GChartCircle.defaultProps = {
  total: 100,
  hideScore: false,
  hideTotal: false,
  // default to score value
  customScoreLabel: '',
  // default to total value
  customTotalLabel: '',
  // bar color will be grey from 0% to 100%
  // the upper limit is included
  colorBreakPoints: [{ color: 'grey', upperLimit: 100 }],
  title: '',
  description: '',
  fontSize: 13,
  icon: false,
  iconSize: 1.3
};

export default GChartCircle;
