// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';


const Envelope = (props) => (
  <SvgIconStyled {...props} viewBox="0 0 19 12" role="img" aria-hidden="false">
    <path d="M18.1727 10.8348C18.2215 10.7582 18.2632 10.6816 18.2981 10.5981C18.312 10.5632 18.3329 10.5284 18.3468 10.4936C18.3816 10.4031 18.4095 10.3126 18.4373 10.2151C18.4443 10.1872 18.4513 10.1594 18.4582 10.1246C18.4861 9.99923 18.5 9.86692 18.5 9.72766V2.08897C18.5 0.940039 17.56 0 16.411 0H2.58897C1.44004 0 0.5 0.940039 0.5 2.08897V9.72766C0.5 10.8766 1.44004 11.8166 2.58897 11.8166H8.38936H16.411C16.599 11.8166 16.7801 11.7818 16.9472 11.74L16.9542 11.747C17.0029 11.7331 17.0516 11.7122 17.0934 11.6983C17.1491 11.6774 17.1979 11.6634 17.2536 11.6426C17.365 11.5938 17.4764 11.5381 17.5739 11.4685C17.5809 11.4615 17.5948 11.4545 17.6017 11.4476C17.6992 11.3849 17.7828 11.3083 17.8663 11.2317C17.8942 11.2108 17.9151 11.183 17.936 11.1551C17.9986 11.0855 18.0544 11.0159 18.1101 10.9393C18.1309 10.8975 18.1518 10.8627 18.1727 10.8348ZM16.2369 1.39265L9.60793 8.02863C9.58704 8.04952 9.5383 8.04255 9.51741 8.02166L2.88839 1.39265H16.2369ZM2.58897 10.424C2.206 10.424 1.89265 10.1106 1.89265 9.72766V2.36751L8.52863 9.00348C8.71663 9.19149 8.94642 9.32379 9.19014 9.3795C9.31547 9.42128 9.44081 9.4352 9.56615 9.4352C9.60097 9.4352 9.64275 9.4352 9.67756 9.42128C10.0118 9.40735 10.3391 9.26809 10.5897 9.01045L17.1073 2.49981V9.72766C17.1073 10.1106 16.794 10.424 16.411 10.424H2.58897Z" />
  </SvgIconStyled>
);

export default Envelope;
