// @flow
import React, { type Element } from 'react';
import { Tabs } from 'components';

type PhishingEditTypes = {
  id?: string
}

const PhishingEdit = ({ id }: PhishingEditTypes): Element<*> => <Tabs id={id} />;

export default PhishingEdit;
