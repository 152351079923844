import { RadioGroup as MuiRadioGroup } from '@material-ui/core';
import React from 'react';
import Controller from './Controller';
import { useFormContext } from 'react-hook-form';

const RadioGroupWrapper = ({ children, name, onChange, ...props }) => {
  return (
    <MuiRadioGroup
      {...props}
      onChange={e => {
        const { value } = e.target;
        const val = isNaN(value) ? value : +value;
        onChange(val);
      }}
      name={name}
    >
      {children}
    </MuiRadioGroup>
  );
};

const RadioGroup = ({ name, onChange, ...props }) => {
  const formContextData = useFormContext();

  if (!formContextData) {
    return <RadioGroupWrapper {...props} name={name} onChange={onChange} />;
  }

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return <RadioGroupWrapper {...props} {...field} name={name} onChange={field.onChange} />;
      }}
    />
  );
};

export default RadioGroup;
