import React, { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

const Form = ({ onSubmit = () => {}, children, defaultValues, ...props }) => {
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    ...props
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>{children}</FormProvider>
    </form>
  );
};

export default Form;
