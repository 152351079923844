import React from 'react';
import { SvgIconStyled } from './ComponentTypes'

const UserMove = (props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.61637 7.44254C7.098 7.44943 7.57622 7.35858 8.02366 7.17521C8.47109 6.99183 8.87895 6.71953 9.22389 6.37388C9.56883 6.02824 9.84407 5.61604 10.0339 5.16089C10.2236 4.70574 10.3242 4.21658 10.3299 3.72141C10.3166 2.72196 9.91803 1.76879 9.2218 1.07114C8.52556 0.373482 7.58853 -0.0116544 6.61637 0.000268816C5.64422 -0.0116544 4.70718 0.373482 4.01095 1.07114C3.31472 1.76879 2.91619 2.72196 2.90283 3.72141C2.91619 4.72085 3.31472 5.67402 4.01095 6.37167C4.70718 7.06933 5.64422 7.45447 6.61637 7.44254ZM6.61637 1.48872C6.90788 1.48181 7.19784 1.53413 7.46964 1.6427C7.74143 1.75126 7.9897 1.91392 8.20021 2.12135C8.41071 2.32879 8.57931 2.57691 8.69633 2.85148C8.81336 3.12606 8.8765 3.42168 8.88214 3.72141C8.8765 4.02113 8.81336 4.31675 8.69633 4.59133C8.57931 4.8659 8.41071 5.11403 8.20021 5.32146C7.9897 5.52889 7.74143 5.69155 7.46964 5.80012C7.19784 5.90868 6.90788 5.961 6.61637 5.95409C6.02817 5.96606 5.45935 5.73776 5.03462 5.31923C4.60988 4.9007 4.36391 4.3261 4.35061 3.72141C4.36953 3.12063 4.61797 2.55172 5.04209 2.13798C5.46621 1.72424 6.03183 1.49899 6.61637 1.51105V1.48872Z" />
    <path d="M9.51912 9.33291C10.0477 9.06008 10.6184 8.88381 11.2058 8.81195C10.9031 8.55196 10.5699 8.33194 10.214 8.15703C10.023 8.06722 9.816 8.01907 9.60598 8.01563C9.24468 8.01474 8.8961 8.15278 8.62874 8.40263C8.55593 8.47251 8.47853 8.53716 8.39709 8.59613C8.30904 8.65915 8.21716 8.71632 8.12201 8.7673L8.02791 8.81195L7.89761 8.87149L7.77455 8.92359L7.63701 8.96824H7.60082H7.55738C7.42191 9.00557 7.28402 9.03292 7.14477 9.0501H7.09409C6.94952 9.06128 6.80434 9.06128 6.65976 9.0501C6.53923 9.05773 6.41835 9.05773 6.29782 9.0501H6.21095H6.00103L5.70423 8.97568L5.5305 8.91614H5.45087L5.33505 8.86405H5.29162L4.99482 8.68543C4.90654 8.62104 4.82195 8.55146 4.74146 8.47705C4.47335 8.22276 4.12175 8.08189 3.75697 8.08261C3.55803 8.08097 3.36089 8.12151 3.17786 8.20169C2.23529 8.62702 1.43317 9.32469 0.869019 10.2099C0.304864 11.0951 0.00296008 12.1297 0 13.188C0.00190693 13.7124 0.205387 14.2148 0.566084 14.5857C0.92678 14.9565 1.41544 15.1657 1.92554 15.1677H6.82626C6.80213 14.6694 6.83371 14.17 6.92036 13.6792H1.92554C1.79941 13.6773 1.67897 13.6249 1.58978 13.5332C1.50058 13.4415 1.44964 13.3177 1.44777 13.188C1.44896 12.4196 1.66803 11.6682 2.07817 11.0258C2.4883 10.3834 3.0717 9.87785 3.75697 9.57106C3.88809 9.69081 4.02587 9.80264 4.16959 9.90597C4.31616 10.0132 4.47112 10.1078 4.63288 10.1888L4.7487 10.2558L4.92967 10.3376L5.05273 10.3823L5.33505 10.4865C5.47983 10.5311 5.6246 10.5683 5.76938 10.5981L6.07341 10.6502H6.23267C6.39675 10.6502 6.56324 10.6502 6.73215 10.6502C6.94525 10.6509 7.15813 10.636 7.36917 10.6055C7.56944 10.5763 7.7677 10.5341 7.96276 10.479H8.09306L8.27403 10.4195C8.63963 10.0089 9.07351 9.6687 9.55531 9.41478L9.51912 9.33291Z" />
    <path d="M17.7785 13.8133L17.2645 13.2849L14.9481 10.9033C14.8805 10.8344 14.8002 10.7798 14.712 10.7428C14.6238 10.7057 14.5294 10.6869 14.4341 10.6875C14.3389 10.6869 14.2444 10.7057 14.1562 10.7428C14.068 10.7798 13.9878 10.8344 13.9202 10.9033C13.8523 10.9725 13.7985 11.0548 13.7617 11.1455C13.725 11.2362 13.706 11.3335 13.706 11.4317C13.706 11.53 13.725 11.6273 13.7617 11.718C13.7985 11.8086 13.8523 11.891 13.9202 11.9601L15.5127 13.6049H9.9026C9.71061 13.6049 9.52649 13.6833 9.39073 13.8229C9.25498 13.9624 9.17871 14.1517 9.17871 14.3491C9.17871 14.5465 9.25498 14.7358 9.39073 14.8754C9.52649 15.0149 9.71061 15.0933 9.9026 15.0933H15.5272L13.9346 16.7232C13.8668 16.7924 13.8129 16.8747 13.7762 16.9654C13.7394 17.0561 13.7205 17.1534 13.7205 17.2516C13.7205 17.3498 13.7394 17.4471 13.7762 17.5378C13.8129 17.6285 13.8668 17.7108 13.9346 17.78C14.0019 17.8498 14.082 17.9051 14.1702 17.9429C14.2584 17.9807 14.353 18.0001 14.4486 18.0001C14.5442 18.0001 14.6388 17.9807 14.727 17.9429C14.8152 17.9051 14.8953 17.8498 14.9626 17.78L17.279 15.3985L17.793 14.8701C17.8599 14.7999 17.9126 14.7168 17.9481 14.6256C17.9836 14.5344 18.0012 14.4368 17.9999 14.3386C17.9985 14.2403 17.9783 14.1433 17.9403 14.0532C17.9023 13.963 17.8473 13.8815 17.7785 13.8133Z" />
  </SvgIconStyled>
)

export default UserMove
