// @flow
import { type ComponentType } from 'react';
import styled, { css } from 'styled-components';
import {
  Toolbar,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
  IconButton,
  TablePagination,
  Button
} from '@material-ui/core';
import { Arrow } from 'components/icons';

export const PaperContent: ComponentType<*> = styled.div`
  background-color: var(--backgroundPaper);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  thead {
    span,
    span:hover {
      color: var(--colorFontTableHeader) !important;
      font-size: var(--fontSize);
      font-weight: var(--fontWeightMedium);
    }
  }
`;

export const TableResponsive: ComponentType<*> = styled.div`
  margin-top: -2px;
  position: relative;
  overflow-x: auto;
`;

export const TableHeadIconMobile: ComponentType<*> = styled(TableCell)`
  > svg {
    color: var(--colorSystemInfo);
  }
`;

export const TableContent: ComponentType<*> = styled(Table)`
  && {
    border-collapse: separate;
  }
  border-radius: var(--borderRadius);
`;

export const ToolbarContent: ComponentType<*> = styled.div`
  border: var(--borderSize) solid var(--borderBase);
  border-bottom: none;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
`;

export const ToolbarRow: ComponentType<*> = styled(Toolbar)`
  display: flex;
  && {
    flex-direction: row;
    padding-left: calc(var(--spacing) * 2);
    padding-right: calc(var(--spacing) * 2);
  }
  position: relative;
`;

export const TableHeadContent: ComponentType<*> = styled(TableHead)`
  th {
    border-top: var(--borderSize) solid var(--borderBase);
    border-bottom: var(--borderSize) solid var(--borderBase);
    padding: 3px 8px;
    h3 {
      font-weight: 500;
    }
    &:nth-child(1) {
      border-left: var(--borderSize) solid var(--borderBase);
      border-radius: var(--borderRadius) 0px 0px var(--borderRadius);
      padding-left: 22px;
    }
    &:nth-last-child(1) {
      border-right: var(--borderSize) solid var(--borderBase);
      border-radius: 0px var(--borderRadius) var(--borderRadius) 0px;
    }
  }
`;

export const ActionButton: ComponentType<*> = styled(IconButton)`
  && {
    /* padding: 0; */
    padding: calc(var(--spacing) / 1.1);
    /* &:hover {
      background: transparent;
    } */
  }
`;

export const TableHeadContentSubLabel: ComponentType<*> = styled.div`
  display: flex;

  & > p {
    margin-right: calc(var(--spacing) * 2);
  }
`;

export const TableBodyContent: ComponentType<*> = styled(TableBody)`
  tr {
    opacity: ${({ isFetching }) => (isFetching ? 0.5 : 1)};
    pointer-events: ${({ isFetching }) => (isFetching ? 'none' : 'auto')};
    &:hover {
      background-color: var(--backgroundDefault);
      td {
        color: var(--colorDefault);
      }
    }
  }
`;

export const TableSort: ComponentType<*> = styled(TableSortLabel)`
  ${({ labelPaddingLeft }) => labelPaddingLeft && `padding-left: ${labelPaddingLeft}px!important;`}

  & > svg {
    ${({ disabled }) => disabled && 'display: none;'}
    margin-right: 0;
  }
`;

export const TableRowContent: ComponentType<*> = styled(TableRow)`
  && {
    height: calc(var(--spacing) * 6);
  }
`;

export const TableCellContent: ComponentType<*> = styled(TableCell)`
  && {
    &:hover {
      cursor: pointer;
    }
    /* word-break: break-word; */
    /* overflow-wrap: break-word; */
    color: var(--colorDefault);
    padding: 3px 8px;
    border-bottom: var(--borderSize) solid var(--borderTableDefault);
    font-size: var(--fontSize);

    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        color: var(--colorBox) !important;
        background-color: var(--backgroundInfo);
      `}
    ${({ collapse }) =>
      collapse &&
      css`
        background-color: var(--backgroundDefault);
      `}
    &:first-child {
      padding-left: ${({ collapsible }) => (collapsible ? '12px' : '22px')};
    }
  }
`;

export const CollapseIcon = styled(Arrow)`
  transform: rotate(180deg);
  margin-right: 16px;

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      transform: rotate(0deg);
      color: var(--colorBox);
    `}
`;

export const CollapseRow: ComponentType<*> = styled(TableRow)`
  border-top: var(--borderSize) solid var(--borderDefault);
  border-bottom: var(--borderSize) solid var(--borderDefault);
`;

export const TableCellAlign: ComponentType<*> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
`;

export const TableCellAlignRight: ComponentType<*> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--iconDefaultcolor);
  margin-bottom: 2px;

  & > small {
    font-size: calc(var(--fontSize) * 0.9);
  }
`;

export const ButtonIcon: ComponentType<*> = styled(IconButton)`
  svg {
    font-size: var(--fontSize);
  }
`;

export const PaginationTable: ComponentType<*> = styled(TablePagination)`
  font-size: 11px;
  p {
    font-size: 11px;
  }
  > div {
    padding: 0;
  }
`;

export const BadgeIcon: ComponentType<*> = styled.div`
  background: ${props => props.color};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 10px;
`;

export const ButtonTag: ComponentType<*> = styled(Button)`
  && {
    text-transform: initial;
    color: var(--colorDefault);
  }
`;
