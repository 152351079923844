// @flow
import React from 'react';
import { Container, Tabs } from 'components';

const ReportsEdit = () => {
  return (
    <Container.Tab>
      <Tabs />
    </Container.Tab>
  );
};

export default ReportsEdit;
