// @flow
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { useConfig } from '../useConfig';
import React from 'react';

export function useModalConfig(app = 'default', tab = 'modal') {
  const dispatch = useDispatch();
  const config = useSelector(({ bsn }) => bsn?.modals?.[app]?.[tab]);
  const open = useSelector(state => state.bsn?.modals?.[app]?.[tab]?.open || false);

  function setDispatch(payload) {
    dispatch({
      type: 'BSN_SET_CONFIG_MODAL',
      resource: 'modals',
      tab: app,
      payload
    });

    return payload;
  }

  function setKey(key, value) {
    dispatch({
      type: 'BSN_SET_ITEM',
      resource: 'modals',
      tab: app,
      key,
      item: tab,
      payload: value
    });
  }

  const setOpen = (value: boolean, item?: string | boolean = false) => {
    console.log({ setOpen: { value, item } });
    setKey('open', value);
    setKey('item', item); // TODO - one action
  };

  function setDisabled(value: boolean) {
    setKey('disabled', value);
  }

  function setNext() {
    setKey('step', config.step + 1);
  }

  function setBack() {
    setKey('step', config.step - 1);
  }

  function reset() {
    setDispatch({ [tab]: { ...GetConfig(app, tab), open: false } });
  }

  return { ...config, open, setOpen, setNext, setBack, setDisabled, reset };
}

// Function to avoid calling hooks when not needed.
function GetConfig(app, tab) {
  return useConfig('modals', app, tab);
}
