// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const SearchIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.7285 22.2957L15.3075 13.8747C16.463 12.4142 17.1562 10.5747 17.1562 8.57812C17.1562 3.84536 13.3108 0 8.57808 0C3.84533 0 0 3.84536 0 8.57812C0 13.3109 3.84533 17.1562 8.57808 17.1562C10.6394 17.1562 12.5344 16.426 14.0226 15.2058L22.4251 23.6083C22.61 23.7932 22.841 23.8764 23.0814 23.8764C23.3217 23.8764 23.5528 23.7839 23.7377 23.6083C24.089 23.2386 24.089 22.6562 23.7285 22.2957ZM8.57808 15.2983C4.87138 15.2983 1.84873 12.2756 1.84873 8.56887C1.84873 4.86216 4.86213 1.83949 8.57808 1.83949C12.294 1.83949 15.3075 4.86216 15.3075 8.56887C15.3075 12.2756 12.2848 15.2983 8.57808 15.2983Z" />
  </SvgIconStyled>
);

export default SearchIcon;
