// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Policy = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18.524 21.17" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18.524v21.17H0z" />
    <g>
      <path d="M9.262,1.782l7.464,4.384a.075.075,0,0,1,.035.062,15.245,15.245,0,0,1-7.5,13.161,15.245,15.245,0,0,1-7.5-13.161V6.183ZM9.262,0a1.8,1.8,0,0,0-.89.238L.882,4.64A1.778,1.778,0,0,0,0,6.183,17.13,17.13,0,0,0,8.372,20.932a1.783,1.783,0,0,0,1.781,0,17.093,17.093,0,0,0,8.372-14.7,1.874,1.874,0,0,0-.908-1.605L10.152.238A1.8,1.8,0,0,0,9.262,0Z" />
      <path
        d="M8.072,15.584a.776.776,0,0,1-.55-.229L4.205,12a.8.8,0,0,1,0-1.12.779.779,0,0,1,1.109,0l2.767,2.8,6.076-6.139a.779.779,0,0,1,1.109,0,.8.8,0,0,1,0,1.12l-6.634,6.7A.8.8,0,0,1,8.072,15.584Z"
        transform="translate(-0.469 -0.862)"
      />
    </g>
  </SvgIconStyled>
);

export default Policy;
