// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Arrows = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.7961 5.1895C9.43614 5.54947 8.84544 5.54947 8.48548 5.1895L6.45489 3.16816V23.077C6.45489 23.5847 6.03955 24 5.5319 24C5.02426 24 4.60892 23.5847 4.60892 23.077V3.15893L2.57833 5.1895C2.21836 5.54947 1.62767 5.54947 1.2677 5.1895C1.09233 5.0049 1 4.77416 1 4.53418C1 4.2942 1.09233 4.06346 1.2677 3.87886L4.22128 0.925296L4.87656 0.269974C5.23653 -0.0899913 5.82728 -0.0899913 6.18725 0.269974L6.84253 0.925296L9.7961 3.87886C10.1561 4.23882 10.1561 4.82954 9.7961 5.1895ZM14.3283 18.7943C14.6883 18.4344 15.279 18.4344 15.639 18.7943L17.6696 20.8249V0.925296C17.6696 0.417653 18.0849 0.00230789 18.5926 0.00230789C19.1002 0.00230789 19.5156 0.417653 19.5156 0.925296V20.8249L21.5461 18.7943C21.9061 18.4344 22.4968 18.4344 22.8568 18.7943C23.0321 18.9789 23.1244 19.2097 23.1244 19.4497C23.1244 19.6896 23.0321 19.9204 22.8568 20.105L19.9032 23.0586L19.2479 23.7139C18.8879 24.0738 18.2972 24.0738 17.9372 23.7139L17.2819 23.0586L14.3283 20.105C13.9684 19.745 13.9684 19.1543 14.3283 18.7943Z" />
  </SvgIconStyled>
);

export default Arrows;
