// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Print = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 17 17" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h17v17H0z" />
    <g>
      <path d="M14.9,4.2h-1.4V0.7c0-0.4-0.3-0.7-0.7-0.7H4.2C3.9,0,3.5,0.3,3.5,0.7v3.5H2.1C1,4.2,0,5.2,0,6.4V12   c0,1.2,1,2.1,2.1,2.1h1.4v2.1c0,0.4,0.3,0.7,0.7,0.7h8.5c0.4,0,0.7-0.3,0.7-0.7v-2.1h1.4c1.2,0,2.1-1,2.1-2.1V6.4   C17,5.2,16,4.2,14.9,4.2z M5,1.4H12v2.8H5V1.4z M12,15.6H5v-4.2H12V15.6z M15.6,12c0,0.4-0.3,0.7-0.7,0.7h-1.4v-2.1   c0-0.4-0.3-0.7-0.7-0.7H4.2c-0.4,0-0.7,0.3-0.7,0.7v2.1H2.1c-0.4,0-0.7-0.3-0.7-0.7V6.4c0-0.4,0.3-0.7,0.7-0.7h12.8   c0.4,0,0.7,0.3,0.7,0.7V12z" />
      <path d="M4.2,6.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8C4.6,8.5,5,8.1,5,7.7S4.6,6.9,4.2,6.9z" />
    </g>
  </SvgIconStyled>
);

export default Print;
