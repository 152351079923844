import React from 'react'
import styled from 'styled-components';
import TextField from './TextField';
import { LazyIcon } from '../icons';

const SearchField = styled(TextField)`
  && {
    min-width: 160px;
    margin: 0;
    padding: 5px 0px;
    max-height: 42px;

    & > div {
      padding: 0;

      & > input {
        padding: 6px 0;
      }
    }
    svg {
      margin-left: 15px;
    }
  }
  &.variant-standard {
    max-width: ${({ fullwidth }) => fullwidth ? '100%' : '300px'};
  }
`;


const CustomSearchField = ({
  label,
  value,
  onChange,
  ...props
}) => {
  return (
    <SearchField
      placeholder={label || 'Search'}
      variant
      value={value}
      inputBase
      InputProps={{
        startAdornment: (
          <LazyIcon component="Search" color="grey" size={1} mr={1} />
        )
      }}
      onChange={({ target: { value: val } }) => onChange(val)}
      {...props}
    />
  );
};

export default CustomSearchField;