import React from "react"
import { Clock, Container, Timer } from 'components';
import { useQuiz } from 'components';
import { QuizHeaderContainer, HeaderTimeTitle } from './quizStyle';

const QuizHeader = () => {
  const {time_elapsed, isQuizFinished} = useQuiz()
  const seconds = time_elapsed ? +time_elapsed : 0
  return (
    <QuizHeaderContainer>
      <Container.Grid justifyContent={"flex-end"} alignItems={"center"}>
        <Clock/>
        <HeaderTimeTitle>
           Time Elapsed <Timer pause={isQuizFinished} seconds={seconds}/>
        </HeaderTimeTitle>
      </Container.Grid>
    </QuizHeaderContainer>
  )
}

export default QuizHeader
