import React from 'react';
import PropTypes from 'prop-types';
import { Success } from 'components/icons';
import { Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import { Dialog } from '@trustsecurenow/components-library'
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '80px 90px',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    color: 'var(--colorSystemSuccess)',
    fontWeight: 'bold'
  },
  icon: {
    backgroundColor: 'rgba(12, 206, 107, 0.2)',
    borderRadius: '50%',
    width: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SuccessDialog = ({ open, close, dateScheduled, editingEnabled }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={close} fullWidth>
        <div className={classes.content}>
          <IconButton className={classes.closeBtn} onClick={close}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
          <Box pb={2} display="flex" justifyContent="center">
            <span className={classes.icon}>
              <Success size={3.4} color="success" />
            </span>
          </Box>
          <Typography variant="h3" display="block" className={classes.title}>
            {dateScheduled && !editingEnabled ? 'Scheduling' : 'Assessment'} Complete!
          </Typography>

          <Box fontSize={16} lineHeight="24px" mt={2}>
            {dateScheduled && !editingEnabled ? (
              <div>
                Your Risk Assessment consultation call has been successfully scheduled. For additional questions or
                issues, please use the <strong>Contact Us</strong> section. Thank you!
              </div>
            ) : (
              <>
                <div>You’ve completed your Security Risk Assessment!</div>
                <div>
                  Your final reports are being processed and a notification will be sent to you when it is completed.
                </div>
              </>
            )}
          </Box>
        </div>
    </Dialog>
  );
};

// SuccessDialogContent.propTypes = {
//   setOpen: PropTypes.func.isRequired
// };

export default SuccessDialog;
