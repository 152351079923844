import React, { useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({ 
  textContent: {
    fontFamily: 'var(--fontFamily)',
    color: 'var(--colorDefault)',
    '& p:last-child': {
      display: 'inline'
    },
    '& .text': {
      fontSize: 14,
      lineHeight: '21px',
      margin: '0 0 20px',
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  linkWrap: {
    display: 'inline'
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: 5,
    background: 'none',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    display: 'inline',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const CroppedText = ({ text, limit }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasSeeMore = text.length > limit;

  const getText = str => {
    if (hasSeeMore) return isExpanded ? str : str.substr(0, limit);
    return str;
  };

  const toggleText = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <>
      <Box className={classes.textContent} component="span">
        {getText(text)}
      </Box>

      {hasSeeMore && (
        <span className={classes.linkWrap}>
          {!isExpanded && ' ...'}
          <button type="button" onClick={toggleText} className={classes.link}>
            {isExpanded ? 'See Less' : 'See More'}
          </button>
        </span>
      )}
    </>
  );
};

export default CroppedText;
