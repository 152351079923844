// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles({
  video: {
    '& video': {
      outline: 'none !important'
    }
  }
});

const VideoPlayer = ({ src, ...rest }) => {
  const classes = useStyles();

  return (
    <ReactPlayer
      url={src}
      className={classes.video}
      {...rest}
    />
  );
};

export default VideoPlayer;
