// @flow
import React, { type Element, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'hooks';
import { TabsProvider, type TabsProviderTypes } from './TabsContext';
import TabsTabs from './TabsTabs';

const Tabs = (props: TabsProviderTypes): Element<*> => {
  const history = useHistory();
  const { app, tab } = useLocation();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);

  useEffect(() => {
    if (accessApps) {
      const hasAppAccess = accessApps?.hasOwnProperty(app);
      const hasTabAccess = accessApps?.[app]?.[tab];
      const isMicroTrainingsTab = app === 'myDashboard' && tab === 'microTrainings';

      if (!hasAppAccess) {
        history.push('/myDashboard/dashboard');
      } else if (isMicroTrainingsTab && (!hasAppAccess || !hasTabAccess)) {
        sessionStorage.removeItem('microtraining_id');
        history.push('/myDashboard/dashboard');
      }
    }
  }, [app, tab, accessApps, history]);

  return (
    <TabsProvider {...props}>
      <TabsTabs />
    </TabsProvider>
  );
};

export default Tabs;
