/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-nested-ternary */
// @flow
import React, { type Element, useEffect, useState, useCallback } from 'react';
import { CircularProgress, Dialog, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SelectField,
  Container,
  Switch,
  Button,
  LoadingStyled,
  ButtonCancel,
  Typography as LegacyTypography
} from 'components';
import { LazyIcon, Warning, Success, Settings } from 'components/icons';
import { Alert } from '@material-ui/lab';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { CancelButton } from 'apps/admin/style/AdminCommon';
import { useId, useLocation } from 'hooks';
import { isValidEmail } from 'helpers';
import clientsApi from 'helpers/apis/clients';
import { LockedContent } from 'components/common/ContentModifiers';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Typography, enqueueAlertSnackbar, Link } from '@trustsecurenow/components-library';
import { BSN_SET_ANY } from 'conf';
import { useDirectorySync } from './DirectorySyncContext';
import { LazyApp } from '../..';
import { DirectorySyncCsvBulkUploadHint } from './DirectorySyncCsvBulkUpload';
import DirectorySyncRefreshComponent from './DirectorySyncRefreshComponent';
import ResetDirectoryModal from './modals/ResetDirectoryModal';
import DirectorySyncSwitchComponent from './DirectorySyncSwitchComponent';
import AzureActiveDirectoryVerifySetupAndSync from './AzureActiveDirectoryVerifySetupAndSync';

const useStyles = makeStyles(() => ({
  content: {
    minWidth: '600px',
    minHeight: '300px',
    padding: '30px 20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  resetIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '35px'
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    fontSize: 20
  }
}));

const DialogTitle = props => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

const DirectorySyncStart = (): Element<*> => {
  const classes = useStyles();
  const {
    state,
    dispatch,
    loadingCSVTemplate,
    aadCredentialsVerified,
    AAD_SYNC_TYPE_MULTITENANT,
    AAD_SYNC_TYPE_APPID
  } = useDirectorySync();
  const [dir_start_state, setDirStartState] = React.useState({
    show_confirmation_reset: false,
    show_feedback_reset: false
  });
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  let clientId = useId({ key: 'clientId' });
  const { item } = useLocation();
  clientId = item || clientId;
  const syncTypeText = () => {
    if (state.syncType === 'AzureActiveDirectory') {
      return 'Azure';
    } else if (state.syncType === 'OnPremiseActiveDirectory') {
      return 'On Premise Active Directory';
    } else {
      return 'Google';
    }
  };
  // Check if the client is authorized in azure
  const aadVerified = aadCredentialsVerified;
  useEffect(() => {
    const aadSyncAccount = state?.aadSyncAccount?.toLowerCase();
    if (aadSyncAccount === AAD_SYNC_TYPE_MULTITENANT || aadSyncAccount === AAD_SYNC_TYPE_APPID) {
      dispatch.onVerify(true);
    }
  }, [AAD_SYNC_TYPE_APPID, AAD_SYNC_TYPE_MULTITENANT, state?.aadSyncAccount]);

  const getOperationButtonsStatus = useCallback(async () => {
    if (buttonsState === undefined) {
      try {
        const status = await clientsApi.getOperationButtonsStatus(clientId);
        dispatchRedux({
          type: BSN_SET_ANY,
          payload: {
            user: {
              profile: {
                shared: {
                  buttonsStatus: status?.data
                }
              }
            }
          }
        });
      } catch (error) {
        enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', {
          props: { severity: 'error' }
        });
      }
    }
  }, [buttonsState, dispatchRedux, clientId]);

  useEffect(() => {
    getOperationButtonsStatus();
  }, [getOperationButtonsStatus]);

  const handleSyncValue = value => {
    // Changing default select value show behavior
    if (state.syncType === 'AzureActiveDirectory' && !aadVerified) {
      return (
        <>
          {' '}
          <Warning color="danger" /> {value}{' '}
        </>
      );
    }
    if (state.syncType === 'AzureActiveDirectory' && aadVerified) {
      return (
        <>
          {' '}
          <Success color="success" /> {value}{' '}
        </>
      );
    }
    return value;
  };
  const handleSelectBgColor = () => {
    // Changing default select background color
    if (state.syncType === 'AzureActiveDirectory' && !aadVerified) {
      return 'rgba(255, 90, 97, 0.2)';
    }
    if (state.syncType === 'AzureActiveDirectory' && aadVerified) {
      return 'rgba(12, 206, 107, 0.2)';
    }
    return 'initial';
  };

  const handleSelectCustomMessage = () => {
    if (state.syncType === 'AzureActiveDirectory' && aadVerified) {
      return 'Verified Successfully !';
    }
    if (state.syncType === 'AzureActiveDirectory' && !aadVerified) {
      return 'Not Verified...';
    }
    return '';
  };

  const handleSelectCustomMessageColor = () => {
    if (state.syncType === 'AzureActiveDirectory' && !aadVerified) {
      return 'var(--colorSystemDanger)';
    }
    if (state.syncType === 'AzureActiveDirectory' && aadVerified) {
      return 'var(--colorSystemSuccess)';
    }
    return '';
  };
  const disableSwitch = () => {
    dispatch.resetDirectory(() => {
      setDirStartState(old => ({
        ...old,
        show_confirmation_reset: false
      }));

      dispatchRedux({
        type: BSN_SET_ANY,
        payload: {
          user: {
            profile: {
              shared: {
                buttonsStatus: { ...buttonsState, directory_sync_enabled: false }
              }
            }
          }
        }
      });
    });
  };

  const handleEnableSwitchChange = () => {
    dispatch.onSwitchEnableSyncType(disableSwitch);
  };

  const handleDirectorySyncSwitchComponent = () => {
    if (
      (state.syncType === 'AzureActiveDirectory' && !aadVerified) ||
      (state.component === 'DirectorySyncAzureActiveDirectoryPowerShell' && state.enableManualSetup)
    ) {
      return false;
    }
    return true;
  };

  const configureMsgTiming = () => {
    const { welMessFrequency, welMessHowMany, deferred_sending } = state;
    if (!deferred_sending) {
      return 'immediately';
    }
    if (welMessHowMany === 1) {
      return `in ${welMessHowMany} hour`;
    }

    return `in ${welMessHowMany} ${welMessFrequency}`;
  };

  const handleSaveButtonComponent = () => {
    if (state && state.component === 'DirectorySyncCsvBulkUpload') {
      return false;
    }
    if (state && state.component !== 'DirectorySyncAzureActiveDirectory') {
      if (
        state.component !== 'DirectorySyncAzureActiveDirectoryPowerShell' ||
        (state.component === 'DirectorySyncAzureActiveDirectoryPowerShell' && state.enableManualSetup)
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const handleEnableManualSetup = () => {
    if (state.syncType !== 'AzureActiveDirectory') {
      return null;
    }

    return (
      <ButtonCancel
        style={{ fontSize: '12px', marginRight: 0, paddingTop: 0 }}
        onClick={() => dispatch.onSwitchEnableManualSetup()}
      >
        <Settings style={{ marginRight: '8px' }} />
        {state.enableManualSetup ? 'Disable ' : 'Enable '}Manual Setup
      </ButtonCancel>
    );
  };

  if (!state || !buttonsState) return <LoadingStyled />;

  return (
    <Box mt={2.5}>
      {!state.automatedWelcome_loading &&
        (state.automatedWelcome ? (
          <Alert
            icon={<LazyIcon color="var(--colorSystemInfo)" component="BellOn" />}
            severity="info"
            style={{
              border: '1px solid var(--colorSystemInfo)',
              borderLeft: '5px solid var(--colorSystemInfo)',
              marginBottom: '10px'
            }}
          >
            <LegacyTypography.Base component="span" color="var(--colorSystemInfo)" fontSize="14px" bold={400}>
              Welcome Message is configured to send {configureMsgTiming()} after a new user is added
            </LegacyTypography.Base>
          </Alert>
        ) : (
          <Alert
            style={{
              border: '1px solid var(--colorSystemDanger)',
              borderLeft: '5px solid var(--colorSystemDanger)',
              marginBottom: '10px'
            }}
            icon={<LazyIcon color="var(--colorSystemDanger)" component="Siren" />}
            severity="error"
          >
            <LegacyTypography.Base component="span" color="var(--colorSystemDanger)" fontSize="14px" bold={400}>
              Welcome Messages will not be sent to new users
            </LegacyTypography.Base>
          </Alert>
        ))}

      <div>
        <Container.Paper>
          <Container.Grid p={2.5}>
            <Container.Grid item xs={12} fullWidth style={{ dispay: 'flex', justifyContent: 'space-between', gap: '30px' }}>
              <Typography variant="button" ml={1}>
                Choose Your Sync Type
              </Typography>
              <DirectorySyncSwitchComponent enable={!state.enableSyncType} record={state} dispatch={dispatch} />
            </Container.Grid>
            <Container.Grid item fullWidth xs={12}>
              <nav
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flext-start',
                  width: '70%',
                  height: 'auto',
                  flexDirection: 'column'
                }}
              >
                <div style={{ width: '152%', display: 'flex', alignItems: 'center' }}>
                  <SelectField
                    offset={81}
                    id="syncType"
                    choices={[{ label: 'Please Select', value: 'CsvBulkUpload' }, ...state.types]}
                    name="syncType"
                    value={state?.syncType || 'CsvBulkUpload'}
                    GAclick
                    ml={3}
                    mr={1.3}
                    mb={1}
                    pl={4}
                    allowEmpty={false}
                    disabled={state.enableSyncType}
                    onChange={e => dispatch.onChangeSyncType(e)}
                    customMessage={handleSelectCustomMessage()}
                    customMessageColor={handleSelectCustomMessageColor()}
                    customMessageRightAligned={handleEnableManualSetup()}
                  />
                  {state?.type !== 'csvBulkUpload' && (
                    <div
                      style={{
                        transform: state?.syncType === 'AzureActiveDirectory' && 'translateY(-14px)',
                        marginLeft: '8px'
                      }}
                    >
                      <Switch
                        label="Enable"
                        value={state.enableSyncType}
                        checked={state.enableSyncType}
                        name="enableSyncType"
                        id="enableSyncType"
                        onChange={handleEnableSwitchChange}
                      />
                    </div>
                  )}
                </div>
                {state?.syncType === 'AzureActiveDirectory' && (
                  <LazyApp
                    record={state}
                    dispatch={dispatch}
                    component="shared/directorySync/DirectorySyncAzureActiveDirectory"
                  />
                )}
              </nav>
            </Container.Grid>
          </Container.Grid>
        </Container.Paper>
        {state?.type !== 'csvBulkUpload' && (
          <main>
            <Container.Paper mt={1.25}>
              <Container.Grid p={2.5} pb={1} pt={1}>
                <Container.Grid item xs={6} fullWidth direction="column">
                  {state?.enableSyncType && state?.syncType === 'AzureActiveDirectory' && aadVerified && (
                    <AzureActiveDirectoryVerifySetupAndSync />
                  )}
                  {state?.component !== 'DirectorySyncAzureActiveDirectoryPowerShell' &&
                    state?.syncType !== 'AzureActiveDirectory' &&
                    state?.type !== 'csvBulkUpload' && (
                      <LazyApp
                        enable={!state.enableSyncType}
                        record={state}
                        dispatch={dispatch}
                        component={`shared/directorySync/${state.component}`}
                      />
                    )}
                  {state?.component === 'DirectorySyncAzureActiveDirectoryPowerShell' && state?.enableManualSetup && (
                    <LazyApp
                      enable={!state.enableSyncType}
                      record={state}
                      dispatch={dispatch}
                      component={`shared/directorySync/${state.component}`}
                    />
                  )}
                </Container.Grid>
                <Box pl={2.5}>
                  {state.file && (
                    <Box display="flex">
                      <Typography variant="button" color="info.main" mr={0.5}>
                        Current attachment:
                      </Typography>
                      <Typography variant="button" sx={{ textDecoration: 'underline' }}>
                        {state?.file?.name}
                      </Typography>
                    </Box>
                  )} 
                </Box>
                <Container.Grid item mt={1.25} xs={12} display="flex" style={{ justifyContent: 'flex-end' }}>
                  {state.syncType === 'GoogleGSuite' && state.file && (
                    <Link
                      sx={{ mr: 2 }}
                      component="button"
                      onClick={dispatch.onCancel}
                    >
                      Cancel
                    </Link>
                  )}
                  {handleSaveButtonComponent() && (
                    <Button
                      backgroundColor="colorSystemInfo"
                      backgroundColorHover="colorSystemInfoHover"
                      backgroundDisabled="colorSystemInfoDisable"
                      height={36}
                      pr={3}
                      pl={3}
                      mr={3}
                      disabled={
                        state?.disableComponent ||
                        state?.pending_save ||
                        (state.syncType === 'GoogleGSuite' && (!isValidEmail(state.adminEmail) || !state.file))
                      }
                      onClick={dispatch.onSave}
                    >
                      <LazyIcon color="colorCommonWhite" component="Save2" />
                      &nbsp;&nbsp;Save
                    </Button>
                  )}
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
          </main>
        )}
        {state.syncType !== 'CsvBulkUpload' &&
          state?.enableSyncType &&
          (buttonsState?.directory_sync_enabled || (state?.syncType === 'AzureActiveDirectory' && aadVerified)) && (
            <Container.Paper mt={1.25}>
              <Container.Grid p={2.5}>
                <footer
                  style={{
                    width: '50%',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingLeft: 20
                  }}
                >
                  <Typography variant="button">Reset Directory</Typography>
                  <Typography variant="body2" mt={5} mb={3.5}>
                    If you have confirmed that users are already in the correct security group, but users are still not
                    syncing properly, you may want to try resetting the directory. This process will ensure that all
                    existing user accounts are cross-referenced in {syncTypeText()} and associated with an Object ID,
                    resolving any potential synchronization conflicts for users who are not currently in the directory.
                  </Typography>
                  <ResetDirectoryModal
                    reset={dispatch.resetDirectory}
                    state={state}
                    open={dir_start_state.show_confirmation_reset}
                    loading={state.pending_reset}
                    onOpen={() => {
                      setDirStartState(old => ({ ...old, show_confirmation_reset: true }));
                    }}
                    onCancel={() => {
                      setDirStartState(old => ({ ...old, show_confirmation_reset: false }));
                    }}
                    onReset={() => {
                      dispatch.resetSettings(() => {
                        setDirStartState(old => ({
                          ...old,
                          show_feedback_reset: true,
                          show_confirmation_reset: false
                        }));
                      });
                    }}
                  />
                </footer>
              </Container.Grid>
            </Container.Paper>
          )}
        <Dialog
          onClose={() => {
            setDirStartState(old => ({ ...old, show_feedback_reset: false }));
          }}
          aria-labelledby="simple-dialog-title"
          open={dir_start_state.show_feedback_reset}
        >
          <DialogTitle
            onClose={() => {
              setDirStartState(old => ({ ...old, show_feedback_reset: false }));
            }}
          />
          <div className={classes.content}>
            <Box pb={2.5}>
              <LazyIcon
                className={classes.resetIcon}
                style={{ backgroundColor: '#0CCE6B33' }}
                component="HappyFace"
                color="colorSystemSuccess"
                size={4}
              />
            </Box>
            <Typography variant="h2" textAlign="center" color="success.main">
              Reset is done!
            </Typography>
            <Typography variant="body2">The Active Directory was reset.</Typography>
            <Box pb={2.5}>
              <Typography variant="body2">Now, you can set up it again.</Typography>
            </Box>
          </div>
        </Dialog>
      </div>
    </Box>
  );
};
export default DirectorySyncStart;
