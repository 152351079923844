import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid, Typography, Paper } from '@material-ui/core';
import styled from 'styled-components';
import OtpVerification from '../../components/forms/OtpVerification';
import { Footer } from 'components';
import { setData } from '../../helpers/authProvider';
import { Notification } from 'ra-ui-materialui';
import { useNotify } from 'ra-core';
import { useHistory } from 'react-router-dom';
import { clearMfaConfigData } from 'helpers';

const RootContainer = styled(Container)`
  background-color: var(--backgroundDefault);
  padding-top: 50px;
  min-height: 100vh;
`;

const ContentWrap = styled.div`
  min-height: calc(100vh - 150px);
`;

const ContentBox = styled(Container)(({ theme }) => ({
  borderTop: '5px solid var(--colorBaseBase)',
  padding: 10,
  background: 'var(--backgroundPaper)',
  minHeight: '60vh',
  margin: '40px auto 20px auto',
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'cneter'
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'var(--colorDefault)'
}));

const MfaValidation = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async response => {
    try {
      setData(response.data, dispatch);
      clearMfaConfigData()
      const nextPath = sessionStorage.getItem('redirectToPath');
      if (nextPath) {
        history.push(nextPath);
        sessionStorage.removeItem('redirectToPath');
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  return (
    <RootContainer>
      <Container maxWidth="md">
        <ContentWrap>
          <ContentBox>
            <Grid container spacing={2}>
              {/* Header and Stepper */}
              <Grid item xs={12}>
                <Item elevation={0}>
                  <StyledTypography align="left" variant="h2" gutterBottom component="div">
                    Verify
                  </StyledTypography>
                </Item>
              </Grid>

              <Grid item xs={12}>
                <Item elevation={0}>
                  <StyledTypography align="left" variant="body2" gutterBottom component="div">
                    Enter the 6-digit code generated by Authenticator app below
                  </StyledTypography>
                </Item>
              </Grid>
              {/* this empty gird is kept for vertical spacing */}
              <Grid item xs={12}></Grid>

              {/* OTP Verification Form */}
              <Grid item xs={12}>
                <Item elevation={0}>
                  <OtpVerification type="mfa_challenge" handleSubmit={handleSubmit} />
                </Item>
              </Grid>
            </Grid>
          </ContentBox>
        </ContentWrap>
      </Container>
      <Footer sidebar={false} />
      <Notification />
    </RootContainer>
  );
};

export default MfaValidation;
