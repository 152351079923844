import React from "react"
import { CardPageLayoutTitle, CardPageLayoutText, CardTitleAndTextContainer } from '../../style';

const CardTitleAndText = ({title, text}) => {
  return (
    <CardTitleAndTextContainer>
      <CardPageLayoutTitle>
        {title}
      </CardPageLayoutTitle>
      <CardPageLayoutText>
        {text}
      </CardPageLayoutText>
    </CardTitleAndTextContainer>
  )
}


export default CardTitleAndText
