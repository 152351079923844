import React from 'react';
import { DatePicker } from '@trustsecurenow/components-library';
import { ADMIN_MICRO_TRAINING } from '../../constants';

const PublishDatePicker = ({ dispatch, record, page }) => {
  const isMicroTraining = page === ADMIN_MICRO_TRAINING;

  const shouldDisableDate = date => {
    const selectedDate = new Date(date);

    if (selectedDate.getDay() !== 4) return true;
    const nextThursday = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 7);
    const isLastThursdayInMonth = nextThursday.getMonth() !== selectedDate.getMonth();
    return isMicroTraining ? isLastThursdayInMonth : !isLastThursdayInMonth;
  };

  function isValidDate(date) {
    const newDate = new Date(date);

    // valid Thursday day && not null object date
    if(date && newDate instanceof Date && !isNaN(newDate.getTime()) && newDate.getDay() === 4){
      const nextThursday = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 7);
      const isLastThursdayInMonth = nextThursday.getMonth() !== newDate.getMonth();
      return isMicroTraining && !isLastThursdayInMonth || !isMicroTraining && isLastThursdayInMonth;
    }
  }

  const handleDateChange = (date) => {
    dispatch.onChange('publish_date', new Date(isValidDate(date) ? date : record?.publish_date).toISOString());
  };

  return (
    <DatePicker
      value={record?.publish_date || null}
      label="Publish Date"
      disablePast
      slotProps={{ textField: { error: false }}}
      shouldDisableDate={shouldDisableDate}
      onChange={handleDateChange}
      sx={{ width: '100%' }}
    />
  );
};

export default PublishDatePicker;