import React, { forwardRef, useCallback, useState } from 'react';
import Controller from './Controller';
import { Box, FormControlLabel } from '@material-ui/core';
import { Container, Typography } from '../types';
import { LazyIcon } from '../icons';
import type { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { toBase64 } from 'helpers';
import { generateDropzoneAccept } from 'utils/generateDropzoneAccept';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const DragZoneContainer = styled(Container.Grid)``;
const DragZoneRow = styled(Container.Grid)``;
const DragZoneGrid = styled(Container.Grid)``;

const DragZoneWrapper = styled(Container.Grid)`
  position: relative;
  border: calc(var(--borderSize) * 2) dashed var(--borderDefault);
  border-radius: calc(var(--borderSize) * 10);
  padding: 24px;
  cursor: pointer;
  outline: none !important;

  ${({ error }) =>
    error &&
    css`
      border-color: var(--colorSystemDanger);
    `}
`;

const DragZoneStyled: ComponentType<*> = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  text-align: center;
  z-index: 2;

  ${({ error }) =>
    error &&
    css`
      border-color: var(--colorSystemDanger);
    `}
`;

const DragZoneContent: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: var(--colorSystemInfo);

    ${({ error }) =>
      error &&
      css`
        color: var(--colorSystemDanger);
      `}
  }
`;

export const SmallBlueInfoText: ComponentType<*> = styled(Typography.h6)`
  && {
    font-size: 11px;
  }
  color: var(--colorBox);
`;

export const DangerText = styled(Typography.p)`
  && {
    font-size: 1rem;
    color: var(--colorSystemDanger);
    margin: 0 0 0 14px;
  }
`;

export const InfoTextTitle: ComponentType<*> = styled(Typography.h5)`
  && {
    padding: 0;
    margin: 0;

    ${({ error }) =>
      error &&
      css`
        color: var(--colorSystemDanger);
      `}
  }
`;

export const InfoText = styled.span`
  font-weight: 600;
  text-decoration: underline;
  color: var(--colorDark);
`;

export const BoxDescription: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h6,
  p {
    margin: 0;
    padding-bottom: 8px;
  }
`;

const Title = styled(Typography.h6)`
  color: var(--colorSystemInfo);
  ${({ error }) =>
    error &&
    css`
      color: var(--colorSystemDanger);
    `}
`;

const Description = styled(Typography.p)`
  font-size: 13px;
  ${({ error }) =>
    error &&
    css`
      color: var(--colorSystemDanger);
    `}
`;

const sanitizeFilename = file => {
  // only keep alphanumerics and dots in filenames

  const hasInvalidName = /[^a-z0-9.]/gi.test(file.name);
  if (!hasInvalidName) return file;

  const newFilename = file.name.replace(/[^a-z0-9.]/gi, '');

  // need to do it this way since file.name is read-only
  // https://stackoverflow.com/a/46327909
  const blob = file.slice(0, file.size, file.type);
  return new File([blob], newFilename, { type: file.type, lastModified: file.lastModified });
};

const DragZoneComponent = forwardRef(
  (
    {
      multiple,
      value,
      label,
      labelPlacement,
      title = 'Upload and files',
      titleInside = 'Attachments',
      info = (
        <>
          Drag &amp; drop here or <InfoText>browse</InfoText>
        </>
      ),
      size,
      disabled,
      fileType,
      type,
      dispatch,
      hint = false,
      icon,
      onChange,
      name,
      setValue = () => {},
      error,
      helperText,
      accept
    },
    ref
  ) => {

    const getFile = file => {
      const newFile = file;
      if (
        !newFile ||
        !fileType
          .replace(/\s/g, '')
          .split(',')
          .some(t => newFile.name.toLowerCase().includes(t))
      ) {
        return enqueueAlertSnackbar(`This type of file is not allowed. Please use a ${fileType.toUpperCase()} file`,
          { props: { severity: 'error',  sx: { backgroundColor: '#D32F2F' }}}
        );
      }

      const newFileObject = {};
      const fileObject = Object.assign(newFileObject, {
        lastModified: newFile.lastModified,
        lastModifiedDate: newFile.lastModifiedDate,
        name: newFile.name,
        path: newFile.path,
        size: newFile.size,
        type: newFile.type,
        webkitRelativePath: newFile.webkitRelativePath
      });

      return {
        fileObject,
        newFile
      };
    };

    const getUploadedFileValue = async acceptedFiles => {
      let fileData = multiple ? [] : getFile(acceptedFiles[0]);

      if (multiple) {
        fileData = acceptedFiles.map(file => {
          return getFile(file);
        });
      }

      switch (type) {
        case 'base64':
          let base64Value;

          if (multiple) {
            base64Value = [];
            for (const { newFile, fileObject } of fileData) {
              const res = await toBase64(newFile);
              base64Value = [...base64Value, { res, fileObject }];
            }
            base64Value = [...value, ...base64Value];
          } else {
            const res = await toBase64(fileData.newFile);
            base64Value = { res, fileObject: res.fileObject };
          }

          if (onChange) {
            const changeFeedback = onChange(base64Value);
            changeFeedback && enqueueAlertSnackbar('File Attached', { props: { severity: 'success' } });
          } else {
            setValue(name, base64Value);
          }

          enqueueAlertSnackbar('File Attached', { props: { severity: 'success' } });
          break;
        case 'file':
          if (onChange) {
            const fileValue = multiple ? fileData.map(({ newFile }) => newFile) : fileData.newFile;
            const changeFeedback = onChange(fileValue);
            changeFeedback && enqueueAlertSnackbar('File Attached', { props: { severity: 'success' } });
          } else {
            const fileValue = multiple ? [...value, ...fileData.map(({ newFile }) => newFile)] : fileData.newFile;
            setValue(name, fileValue);
          }
          break;
        case 'input':
          break;
        default:
          break;
      }
    };

    const onDrop = useCallback(
      acceptedFiles => {
        const files = acceptedFiles.map(file => sanitizeFilename(file));
        getUploadedFileValue(files);
      },
      [value]
    );

    const { getRootProps, getInputProps } = useDropzone({
      accept: generateDropzoneAccept(fileType), // e.g.: .csv, .png, .docx
      disabled,
      onDrop
    });

    const inputProps = getInputProps();

    const isError = error && !value.length;
    const recordFileName = value && (typeof value === 'object' ? value.name : value);
    const infoText = value && !multiple ? value.name : recordFileName || info;
    return (
      <DragZoneContainer item direction="column" sm={12} xs={12} xl={12} md={12}>
        <DragZoneRow direction="row" alignItems="center" mb={1} sm={12} xs={12} xl={12} md={12}>
          <InfoTextTitle error={isError}>{title}</InfoTextTitle>
        </DragZoneRow>

        <Box position="relative">
          <DragZoneWrapper
            error={isError}
            direction="row"
            alignItems="center"
            mb={1}
            sm={size}
            xs={size}
            xl={size}
            md={size}
          >
            <DragZoneStyled error={isError} display="flex" alignItems="center" flexDirection="row" {...getRootProps()}>
              <input accept={accept} type="file" id="file" ref={ref} {...inputProps} multiple />
            </DragZoneStyled>
            <DragZoneContent error={isError}>
              {icon ? (
                <LazyIcon component={icon} size={2.3} mr={3} />
              ) : (
                <LazyIcon component="DragDrop" size={2.3} mr={3} />
              )}
              <BoxDescription>
                <Title error={isError}>{titleInside}</Title>
                <Description error={isError}>{infoText}</Description>
              </BoxDescription>
            </DragZoneContent>
          </DragZoneWrapper>
        </Box>
        {isError && (
          <Container.Grid direction="row" alignItems="flex-start" sm={size} xs={size} xl={size} md={size}>
            <DangerText>{helperText}</DangerText>
          </Container.Grid>
        )}
      </DragZoneContainer>
    );
  }
);

const DragZone = ({ name, required, rules, value, ...props }) => {
  let formContext = {};

  // when we use DragZone outside of Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { setValue } = formContext;

  if (!formContextData) {
    return <DragZoneComponent value={value} {...props} />;
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => {
        return (
          <DragZoneComponent
            ref={field.ref}
            setValue={setValue}
            value={field.value}
            error={error}
            helperText={error?.message}
            {...props}
          />
        );
      }}
    />
  );
};

export default DragZone;
