// @flow

import clients from './clients';
import myDashboard from './myDashboard';
import myCompany from './myCompany';
import partnerProfile from './partnerProfile';

const modals = {};

modals.clients = clients;
modals.myDashboard = myDashboard;
modals.myCompany = myCompany;
modals.partnerProfile = partnerProfile
modals.default = {
    modal: {
        title: 'Test Modal',
        open: false
    }
};

export default modals;
