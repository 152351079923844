import { CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const IndustryCell = ({ industryName, onIndustryButtonClick, industryId }) => {
  if (isNA(industryId)) return <Typography variant="body2">N/A</Typography>;

  if (industryName) {
    return (
      <Typography whiteSpace="break-spaces" variant="body2">
        {industryName.replace('/', ' / ')}
      </Typography>
    );
  }

  return (
    <Tooltip title="Click here to select industry" placement="top">
      <IconButton color="error" onClick={onIndustryButtonClick}>
        <CircleCloseIcon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

IndustryCell.propTypes = {
  industryId: PropTypes.number,
  industryName: PropTypes.string,
  onIndustryButtonClick: PropTypes.func.isRequired
};

IndustryCell.defaultProps = {
  industryName: ''
};

export default IndustryCell;
