import styled from 'styled-components';
import { Accordion, ListItem, Button, Chip } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { TextField } from 'components';

export const OrganisationListItem = styled(ListItem)`
  padding-left: 0 !important;
`;

export const SystemAccordion = styled(Accordion)`
  .system-accordion-text {
    margin: 0;
  }

  .pt-0 {
    padding-top: 0 !important;
  }
`;

export const MuiPhoneNumberInput = styled(MuiPhoneNumber)`
  input {
    &:-webkit-autofill {
      border-radius: 0;
      -webkit-text-fill-color: var(--colorDarkGrey);
      box-shadow: 0 0 0px 1000px var(--backgroundPaper) inset;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  font-size: 14px !important;
  text-decoration-line: underline !important;
  font-weight: 400 !important;
  margin-right: 5px !important;
  text-transform: none !important;
  background: none !important;
  transition: 0.3s !important;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  &:hover {
    color: ${props => props.hoverColor || 'var(--colorSystemInfo)'};
  }
`;

export const GreenButton = styled(Button)`
  && {
    background-color: var(--colorSystemSuccess);
    border-color: var(--colorSystemSuccess) !important;
    color: var(--buttonLight) !important;
    font-size: 14px;
    min-width: 130px;
    min-height: 40px;
    text-transform: none;
    margin-left: ${({ ml }) => ml || '0'};
    &:hover {
      background-color: var(--colorSystemSuccessHover);
    }
  }
`;

export const BlueButton = styled(Button)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#d2eafa' : 'var(--colorSystemInfo)')};
    border-color: ${({ disabled }) => (disabled ? '#d2eafa !important' : 'var(--colorSystemInfo)')};
    color: ${({ disabled }) => (disabled ? '#98c2ff !important' : 'var(--buttonLight) !important')};
    font-size: 14px;
    min-width: 130px;
    min-height: 40px;
    text-transform: none;
    opacity: 1 !important;
    &:hover {
      background-color: var(--colorSystemInfoHover);
    }
  }
`;

export const ButtonOutline = styled(Button)`
  && {
    border-color: var(--colorBase);
    color: var(--colorBase);
    text-transform: none;
    font-size: 14px;
    min-width: 130px;
    min-height: 40px;
    margin-left: ${({ ml }) => ml || '0'};
    &:hover {
      background-color: var(--colorBase);
      color: var(--whiteAndBlack);
    }
  }
`;

export const ButtonBlueOutline = styled(Button)`
  && {
    border-color: var(--colorSystemInfo) !important;
    color: var(--colorSystemInfo) !important;
    text-transform: none;
    font-size: 14px;
    min-width: 130px;
    min-height: 40px;
    margin-left: ${({ ml }) => ml || '0'};
    transition: 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const ButtonBaseFilled = styled(Button)`
  && {
    background-color: var(--colorBaseBase);
    border-color: var(--colorBaseBase);
    color: var(--buttonLight) !important;
    text-transform: none;
    font-size: 14px;
    min-width: 130px;
    min-height: 40px;
    white-space: nowrap;
    &:hover {
      background-color: var(--buttonLight);
      color: var(--colorBaseBase) !important;
    }
  }
`;

export const SearchField = styled(TextField)`
  && {
    width: 440px;
    background-color: var(--backgroundPaper);

    fieldset {
      border: none;
    }
  }
`;

export const ToolbarSearchField = styled(TextField)`
  && {
    min-width: 260px;
    background-color: var(--backgroundDefault);

    fieldset {
      border: none;
    }
  }
`;

export const ChipStyled = styled(Chip)`
  && {
    color: ${({ color }) => color || 'var(--colorDefault)'};
    border-color: ${({ color }) => color || 'var(--colorDefault)'};
    background-color: ${({ bgColor }) => bgColor || 'transparent'};
    height: 24px;
    font-weight: 500;
  }
`;
