import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { TextField, SelectOutlined } from 'components';
import { ButtonIcon, TableCellAlign } from './ComponentTypes';

export default function FilterTable({ dataFilter, onFilter }) {
  const { type, placeholder, options, help } = dataFilter;

  const eventHandler = event => {
    const filter = {};
    filter[event.target.id] = event.target.value;
    onFilter(filter);
  };

  return (
    <>
      {type === 'text' && (
        <TableCellAlign>
          <TextField placeholder={placeholder} margin="dense" onInput={eventHandler} />
          <Tooltip title={help}>
            <ButtonIcon size="small" edge="end" aria-label="search">
              <InfoIcon fontSize="inherit" />
            </ButtonIcon>
          </Tooltip>
        </TableCellAlign>
      )}
      {type === 'select' && (
        <TableCellAlign>
          <SelectOutlined
            // source="PartnerMessage.defere_hours"
            label={placeholder}
            choices={options}
            allowEmpty
            emptyValue=""
            onChange={eventHandler}
          />
          <Tooltip title={help}>
            <ButtonIcon size="small" edge="end" aria-label="search">
              <InfoIcon fontSize="inherit" />
            </ButtonIcon>
          </Tooltip>
        </TableCellAlign>
      )}
    </>
  );
}

FilterTable.propTypes = {
  dataFilter: PropTypes.objectOf.isRequired,
  onSearch: PropTypes.func.isRequired
};
