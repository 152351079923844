import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { makeStyles, Box, CircularProgress, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { TextField, ButtonSubmit, ButtonDownload, Typography } from 'components';
import { Done, Redo, CloudUpload, ArrowExternal, Save2 } from 'components/icons';
import * as sra from 'helpers/apis/services/sra';
import { useLocation } from 'hooks';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { DatePickerField } from '../components';
import { ButtonBlueOutline, ButtonOutline } from '../style';
import { findLastTagBeforeLimit } from '../utils';
import { useRecommendations } from '../context/recommendationsContext';

const useStyles = makeStyles(theme => ({
  box: {
    background: 'var(--backgroundPaper)',
    borderRadius: 5,
    padding: 20
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    margin: 0,
    color: ({ isCompleted }) => (isCompleted ? 'var(--colorSystemSuccess)' : 'var(--colorIcon)')
  },
  textContent: {
    fontFamily: 'var(--fontFamily)',
    color: 'inherit',
    '& pre': {
      overflowY: 'scroll'
    },
    '& p:last-child': {
      display: 'inline'
    },
    '& .boldText': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '21px',
      margin: 0
    },
    '& .text': {
      fontSize: 14,
      lineHeight: '21px',
      margin: '0 0 20px',
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  linkWrap: {
    display: 'inline'
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: 5,
    background: 'none',
    border: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    display: 'inline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button, & a': {
      width: 'calc((100% - 10px) / 2) !important',
      textTransform: 'capitalize',
      height: 40,
      fontSize: 14,
      margin: '0 !important'
    },
    '@media (max-width: 1580px)': {
      flexDirection: 'column',
      '& button, & a': {
        width: '100% !important',
        marginTop: '10px !important'
      }
    }
  },
  linkButtons: {
    '@media (max-width: 1750px)': {
      flexDirection: 'column',
      '& button, & a': {
        width: '100% !important',
        marginTop: '10px !important'
      }
    }
  },
  clearDateBtn: {
    position: 'absolute',
    right: 50,
    top: -2
  }
}));

function getDescriptionText(finding, recommendation, has_resource) {
  return `
    <p class="text">
      <span class="boldText">Finding:</span>
      ${finding}
    </p>

    <p class="text">
      <span class="boldText">Recommendation:</span>
      ${recommendation}
    </p>

    ${
      has_resource
        ? `<p class="boldText">Resources</p>
        <p class="text">
          Use the below template to help you address this finding. When you’re done, you can upload the customized
          template by clicking the upload file button. (optional)
        </p>`
        : ''
    }
  `;
}

// Character limit to cut text when the text length exceeds the limit
const SEE_MORE_LIMIT = 800;

const initialState = {
  due_date: null,
  notes: null
};

const RecommendationItem = ({ currentRecommendation, setCurrentRecommendation, getWorkplanInfo }) => {
  const { app, item } = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadingUpdateDate, setLoadingUpdateDate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const {
    date_completed,
    finding,
    recommendation,
    document_type,
    policy_type,
    template_category_id
  } = currentRecommendation;
  const classes = useStyles({ isCompleted: date_completed });
  const [data, setData] = useState(initialState);
  const { revisionId, setRowUpdated } = useRecommendations();
  const has_resource = document_type || policy_type || template_category_id;

  const locationClientId = app === 'clients' ? item : '';
  const urlToDocuments = `/${app}/${policy_type ? 'policies' : 'documents'}${
    locationClientId ? `/${locationClientId}` : ''
  }`;
  const urlToTemplates = `/${app}/documents${locationClientId ? `/${locationClientId}` : ''}`;

  const text = getDescriptionText(finding, recommendation, has_resource);
  const hasSeeMore = text.length > SEE_MORE_LIMIT;

  const setCateory = type => {
    switch (type) {
      case 'document':
        return localStorage.setItem('documentType', document_type);
      case 'policy':
        return localStorage.setItem('policyType', policy_type);
      case 'template':
        return localStorage.setItem('templateType', template_category_id);
      default:
        return () => {};
    }
  };

  useEffect(() => {
    if (currentRecommendation) {
      setData({ ...currentRecommendation });
    } else {
      setData(initialState);
    }
  }, [currentRecommendation]);

  useEffect(() => {
    const isDataChanged = !isEqual(currentRecommendation, data);
    setDisableSave(!isDataChanged);
  }, [currentRecommendation, data]);

  const updateRecommendation = async (newData, updateInfo, showSuccessToast = false) => {
    try {
      // convert due_date from MM/dd/yyyy format used by DatePicker component to yyyy-mm-dd format used by the backend
      const { due_date, ...rest } = newData;
      const formatDate = () => {
        if (due_date) {
          const [month, day, year] = due_date.split('/');
          return `${year}-${month}-${day}`;
        }
        return null;
      }
      await sra.updateWorkplanRecommendation({due_date: formatDate(), ...rest}, revisionId);
      if (updateInfo) getWorkplanInfo(false);
      setRowUpdated(newData); //updating this state will refetch recommendations
      setCurrentRecommendation(newData);
      if (showSuccessToast)
        enqueueAlertSnackbar('The recommendation has been completed', { props: { severity: 'success' } });
    } catch (error) {
      enqueueAlertSnackbar(error?.response?.data?.message || 'Something went wrong', { props: { severity: 'error' } });
    } finally {
      setLoadingSave(false);
      setLoadingUpdateDate(false);
    }
  };

  const getText = str => {
    if (hasSeeMore) {
      if (isExpanded) return str;

      const lastIndex = findLastTagBeforeLimit(str, SEE_MORE_LIMIT);
      return str.substring(0, lastIndex);
    }

    return str;
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const onChangeData = (name, value) => {
    setData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const onUpdateCompletedDate = newDate => {
    onChangeData('date_completed', newDate);
    setLoadingUpdateDate(true);
    const newData = {
      ...currentRecommendation,
      date_completed: newDate
    };
    // don't show success toast when work item is reset (i.e. date is null)
    const showSuccessToast = newDate !== null
    updateRecommendation(newData, true, showSuccessToast);
  };

  const onComplete = () => {
    onUpdateCompletedDate(format(new Date(), 'yyyy-MM-dd'));
  };

  const onRedo = () => {
    onUpdateCompletedDate(null);
  };

  const onSave = () => {
    const newData = { ...data };
    setLoadingSave(true);
    updateRecommendation(newData);
  };

  return (
    <>
      <div className={classes.box}>
        {/* Text content */}
        <Box mb={4}>
          <Box mb={2.5}>
            <p className={classes.title}>{date_completed ? `Complete: ${date_completed}` : 'Incomplete'}</p>
          </Box>

          <Box className={classes.textContent} component="span">
            {parse(getText(text))}
          </Box>

          {hasSeeMore && (
            <span className={classes.linkWrap}>
              {!isExpanded && ' ...'}
              <button type="button" onClick={toggleText} className={classes.link}>
                {isExpanded ? 'See Less' : 'See More'}
              </button>
            </span>
          )}
        </Box>

        {/* Links (Upload, Download) */}
        {has_resource && (
          <Box mb={4}>
            <div className={`${classes.buttons} ${classes.linkButtons}`}>
              {(document_type || policy_type) && (
                <Link
                  to={urlToDocuments}
                  onClick={() => setCateory(document_type ? 'document' : 'policy')}
                  target="_blank"
                  component={ButtonOutline}
                  variant="outlined"
                  startIcon={<CloudUpload size={1.3} />}
                  disableElevation
                  fullWidth
                >
                  Upload Document
                </Link>
              )}

              {((template_category_id || policy_type) && template_category_id !== -1) && (
                <Link
                  to={template_category_id ? urlToTemplates : urlToDocuments}
                  onClick={() => setCateory(template_category_id ? 'template': 'policy')}
                  target="_blank"
                  component={ButtonOutline}
                  variant="outlined"
                  startIcon={<ArrowExternal size={1.3} />}
                  disableElevation
                  fullWidth
                >
                  Download Template
                </Link>
              )}
            </div>
          </Box>
        )}

        <Typography.p mt={0.1} mb={2.5} fontSize={14}>
          When finished, mark this recommendation as complete
        </Typography.p>

        {/* Due Date */}
        <Box mb={1} position="relative">
          <DatePickerField
            id="due_date"
            label="Due date"
            allowKeyboardControl={false}
            value={data.due_date}
            name="due_date_field"
            onChange={(datetime, date) => {
              onChangeData('due_date', date)
            }}
            onKeyDown={(e) => e.preventDefault()}
          />
          {data.due_date && (
            <IconButton onClick={() => onChangeData('due_date', null)} className={classes.clearDateBtn}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Box>

        {/* Notes */}
        <Box mb={2}>
          <TextField
            value={data.notes || ''}
            name="notes"
            label="Notes"
            onChange={e => {
              const val = e.target.value;
              const newVal = val || null;
              onChangeData('notes', newVal);
            }}
            multiline
            rows={2}
            maxRows={4}
            fullWidth
            inputProps={{ maxLength: 1000 }}
          />
        </Box>

        {/* Buttons */}
        <div className={classes.buttons}>
          <ButtonBlueOutline
            variant="outlined"
            startIcon={
              loadingSave ? (
                <>
                  <CircularProgress color="inherit" size={18} thickness={2} />
                  &nbsp;&nbsp;
                </>
              ) : (
                <Save2 size={1.3} />
              )
            }
            disableElevation
            fullwidth
            disabled={disableSave || loadingSave || loadingUpdateDate}
            onClick={onSave}
          >
            Save
          </ButtonBlueOutline>

          {!date_completed ? (
            <ButtonSubmit type="button" fullwidth onClick={onComplete} disabled={loadingUpdateDate}>
              {loadingUpdateDate ? (
                <>
                  <CircularProgress color="inherit" size={18} thickness={2} />
                  &nbsp;&nbsp;
                </>
              ) : (
                <Done mr={1} size={1.5} />
              )}
              Complete
            </ButtonSubmit>
          ) : (
            <ButtonDownload onClick={onRedo} variant="outlined" fullwidth disableElevation disabled={loadingUpdateDate}>
              {loadingUpdateDate ? (
                <>
                  <CircularProgress color="inherit" size={18} thickness={2} />
                  &nbsp;&nbsp;
                </>
              ) : (
                <Redo mr={1} size={1.3} />
              )}
              Redo
            </ButtonDownload>
          )}
        </div>
      </div>
    </>
  );
};

RecommendationItem.propTypes = {
  currentRecommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    due_date: PropTypes.string,
    date_completed: PropTypes.string,
    notes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    finding: PropTypes.string,
    recommendation: PropTypes.string,
    document_type: PropTypes.string,
    policy_type: PropTypes.string,
    template_category_id: PropTypes.string
  }),
  setCurrentRecommendation: PropTypes.func.isRequired,
  getWorkplanInfo: PropTypes.func.isRequired
};

RecommendationItem.defaultProps = {
  currentRecommendation: null
};

export default RecommendationItem;
