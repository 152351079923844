// @flow

import prefix from './subdomainMappings';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;

const appWithSubtabs = ['admin/dashboard/darkWeb'];

function dataApiUrl(
  app?: string = 'login',
  tab?: string | false = 'default',
  item?: string = ''
): { baseUrl: string, apiUrl: string } {
  const _tab = tab && tab.split('/')[0];
  const _subtab = typeof item === 'string' && item.split('/')[0];
  const tabName = appWithSubtabs.includes(`${app}/${_tab}/${_subtab}`) ? _subtab : _tab;
  const t = Object.prototype.hasOwnProperty.call(prefix[app], tabName) ? tabName : 'default';
  const baseUrl = `https://${prefix[app][t]}.${base}/${env}`;
  const apiUrl = tab ? `${baseUrl}/${app}/${tab === 'false' ? 'dashboard' : tab}` : `${baseUrl}/${app}`;
  return { baseUrl, apiUrl };
}

export default dataApiUrl;
