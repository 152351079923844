/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { AddIcon, Button, Menu, MenuItem, Tooltip, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { addPluralS } from 'helpers';
import { BULK_ACTIONS, MODALS, PRODUCT_TYPES, BULK_MENU_ACTION_TYPES } from './constants';
import * as bsnclientservices from 'helpers/apis/services/bsnclientservices';
import { useSelector } from 'react-redux';

const BULK_ACTION_MESSAGES = {
  ALREADY_ACTIVE:
    'This action is disabled as it cannot be used on clients that are already active. Unselect these clients to use this action.',
  INACTIVE_OR_INTERNAL: 'This action is disabled as it cannot be used on inactive clients, HIPAA clients or internal clients. If you would like to cancel a paid account, contact support by using the contact us button in the bottom left-hand corner.',
  ACTIVE_ACCOUNTS:
    'This action is disabled as it cannot be used on active client accounts. Unselect these clients and select clients to use this action.',
  IN_PROGRESS_DOWNLOAD:
    'Another bulk download is already in progress. Please wait until the current set of reports is fully downloaded before starting another bulk download.',
  INACTIVE_OR_HIPAA:
    'This action is disabled as it cannot be used on inactive clients or clients with a HIPAA Compliance account. Unselect these clients to use this action.',
  INACTIVE:
    'This action is disabled as it cannot be used on inactive clients. Unselect these clients to use this action.',
  NO_BOUNCED_MAILS:
  'This action is disabled as it cannot be used on clients with 0% Bounced Emails. Unselect these clients to use this action.'
};

const BULK_ACTIONS_SETTINGS = {
  [BULK_ACTIONS.ENABLE_CLIENT]: {
    modalName: MODALS.ACTIVATE_CLIENT,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    usePlural: true,
    checkDisabled: flags => flags.isActiveClientIncluded || flags.isDeletedClientIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.ALREADY_ACTIVE
  },
  [BULK_ACTIONS.DISABLE_CLIENT]: {
    modalName: MODALS.BULK_DISABLE_CLIENT,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    usePlural: true,
    checkDisabled: flags => flags.isInactiveClientIncluded || flags.isInternalClientIncluded || flags.isClientWithHIPAACompIncluded || flags.isClientWithHIPAABPIncluded || flags.isClientWithEVAMDIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.INACTIVE_OR_INTERNAL
  },
  [BULK_ACTIONS.DELETE_CLIENT]: {
    modalName: MODALS.DELETE_CLIENT,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    usePlural: true,
    checkDisabled: flags => flags.isActiveClientIncluded || flags.isDeletedClientIncluded || flags.isClientWithHIPAACompIncluded || flags.isClientWithHIPAABPIncluded || flags.isClientWithEVAMDIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.ACTIVE_ACCOUNTS
  },
  [BULK_ACTIONS.DOWNLOAD_CLIENT_REPORT]: {
    actionType: BULK_MENU_ACTION_TYPES.DIRECT_ACTION,
    usePlural: true,
    checkDisabled: flags => !flags.isAllClientDownloadReportEnabled || flags.isReportDownloadBlocked,
    getDisabledMessage: flags =>
      flags.isReportDownloadBlocked && flags.isAllClientDownloadReportEnabled
        ? BULK_ACTION_MESSAGES.IN_PROGRESS_DOWNLOAD
        : BULK_ACTION_MESSAGES.INACTIVE_OR_HIPAA
  },
  [BULK_ACTIONS.DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT]: {
    actionType: BULK_MENU_ACTION_TYPES.DIRECT_ACTION,
    usePlural: true,
    checkDisabled: flags =>
      flags.isInactiveClientIncluded || flags.isClientWithHIPAACompIncluded || flags.isReportDownloadBlocked,
    getDisabledMessage: flags =>
      flags.isReportDownloadBlocked && !flags.isClientWithHIPAACompIncluded && !flags.isInactiveClientIncluded
        ? BULK_ACTION_MESSAGES.IN_PROGRESS_DOWNLOAD
        : BULK_ACTION_MESSAGES.INACTIVE_OR_HIPAA
  },
  [BULK_ACTIONS.ENABLE_WELCOME_MESSAGE]: {
    modalName: MODALS.BULK_ENABLE_WELCOME_MESSAGE,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    checkDisabled: flags => flags.isInactiveClientIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.INACTIVE
  },
  [BULK_ACTIONS.SEND_WELCOME_MESSAGE]: {
    modalName: MODALS.SEND_WELCOME_MESSAGE,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    checkDisabled: flags => flags.isInactiveClientIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.INACTIVE
  },
  [BULK_ACTIONS.ENABLE_MT_NL]: {
    modalName: MODALS.BULK_ENABLE_MICRO_TRAINING,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    checkDisabled: flags => flags.isInactiveClientIncluded || flags.isClientWithHIPAACompIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.INACTIVE_OR_HIPAA
  },
  [BULK_ACTIONS.ENABLE_POSITIVE_OPTIN]: {
    modalName: MODALS.POSITIVE_OPTIN,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    checkDisabled: flags => flags.isInactiveClientIncluded || flags.isClientWithHIPAACompIncluded,
    getDisabledMessage: () => BULK_ACTION_MESSAGES.INACTIVE_OR_HIPAA
  },
  [BULK_ACTIONS.BOUNCED_EMAILS]: {
    modalName: MODALS.BOUNCED_EMAILS,
    actionType: BULK_MENU_ACTION_TYPES.OPEN_MODAL,
    checkDisabled: flags => flags.isInactiveClientIncluded || flags.isClearedBouncedEmailsClientIncluded,
    getDisabledMessage: flags =>
      flags.isInactiveClientIncluded
        ? BULK_ACTION_MESSAGES.INACTIVE
        : BULK_ACTION_MESSAGES.NO_BOUNCED_MAILS
  }
};

const getTotalUserWithNoBouncedEmailsCount = (selectedRows) => {
  const totalUsers = selectedRows.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.users_email_bounced_count;
  }, 0);

  return totalUsers;
};

const BulkActionsMenu = ({ visibleBulkActions, selectedRowsObj = {}, setModalState, partnerId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userEmail = useSelector(state => state?.bsn?.user?.profile?.email);
  const [isReportDownloadBlocked, setIsReportDownloadBlocked] = React.useState(false);

  const { disableFlags, selectedClientIds, selectedRows } = React.useMemo(() => {
    const selectedRows = Object.values(selectedRowsObj);
    const isNoSelectedRows = Boolean(!selectedRows.length);
    const isActiveClientIncluded = selectedRows.some(row => row.active);
    const isInactiveClientIncluded = selectedRows.some(row => !row.active);
    const isDeletedClientIncluded = selectedRows.some(row => row.deleted);
    const isInternalClientIncluded = selectedRows.some(row => row.is_internal);
    const isClientWithHIPAACompIncluded = selectedRows.some(row => row.product_type === PRODUCT_TYPES.HIPAA_COMPLIANCE);
    const isClientWithHIPAABPIncluded = selectedRows.some(row => row.product_type === PRODUCT_TYPES.HIPAA_BPP);
    const isClientWithEVAMDIncluded = selectedRows.some(row => row.product_type === PRODUCT_TYPES.EVA_MD);
    const isClearedBouncedEmailsClientIncluded = selectedRows.some(row => row.users_email_bounced === 0);
    const isAllClientDownloadReportEnabled = selectedRows.every(row => +row.client_report === 1);
    const disableFlags = {
      isNoSelectedRows,
      isActiveClientIncluded,
      isInactiveClientIncluded,
      isDeletedClientIncluded,
      isInternalClientIncluded,
      isClientWithHIPAACompIncluded,
      isClientWithHIPAABPIncluded,
      isClientWithEVAMDIncluded,
      isClearedBouncedEmailsClientIncluded,
      isReportDownloadBlocked,
      isAllClientDownloadReportEnabled
    };
    const selectedClientIds = Object.keys(selectedRowsObj);
    return { disableFlags, selectedClientIds, selectedRows };
  }, [selectedRowsObj, isReportDownloadBlocked]);

  const handleDownloadReports = useCallback(
    settings => {
      bsnclientservices
        .handleClientBulkAction(partnerId, {
          data: { action_type: settings.reportKey, client_ids: selectedClientIds }
        })
        .then(res => {
          const successMsg = `${selectedClientIds.length} ${addPluralS(
            settings.reportName,
            selectedClientIds.length
          )} will be emailed to ${userEmail}`;
          enqueueAlertSnackbar(successMsg || res.statusText || res.data.message || 'Success', {
            props: { severity: 'success' }
          });
          setIsReportDownloadBlocked(true);
        })
        .catch(err => {
          enqueueAlertSnackbar(
            'Error: Another bulk download is already in progress. Please wait until the current set of reports is fully downloaded before starting another bulk download.',
            {
              props: { severity: 'error' }
            }
          );
          setIsReportDownloadBlocked(true);
        });
    },
    [selectedClientIds, userEmail]
  );

  const handleActionClick = (props, actionName) => {
    if (props.actionType === BULK_MENU_ACTION_TYPES.OPEN_MODAL) {
      setModalState(props.modalName, {
        open: true,
        selectedClientIds,
        totalUsersCount:
          props.modalName == MODALS.BOUNCED_EMAILS && getTotalUserWithNoBouncedEmailsCount(selectedRows)
      });
      return;
    } else {
      // direct action
      const settings = {
        [BULK_ACTIONS.DOWNLOAD_CLIENT_REPORT]: {
          reportKey: 'client_report',
          reportName: 'Client Report'
        },
        [BULK_ACTIONS.DOWNLOAD_EMPLOYEE_SECURE_SCORE_REPORT]: {
          reportKey: 'ess_report',
          reportName: 'Employee Secure Score Report'
        }
      }[actionName];
      handleDownloadReports(settings);
    }
  };

  return (
    <>
      <Button
        disabled={disableFlags.isNoSelectedRows}
        onClick={e => setAnchorEl(e.currentTarget)}
        startIcon={<AddIcon />}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {visibleBulkActions.map(actionName => {
          const action = BULK_ACTIONS_SETTINGS[actionName];
          const isDisabled = action.checkDisabled(disableFlags);

          return (
            <Tooltip title={isDisabled && action.getDisabledMessage(disableFlags)} placement="top" key={actionName}>
              <span>
                <MenuItem
                  disabled={isDisabled}
                  sx={{ width: '100%', px: 2.5 }}
                  onClick={() => {
                    handleActionClick(action, actionName);
                    setAnchorEl(null);
                  }}
                >
                  {action.usePlural ? addPluralS(actionName, selectedRows.length) : actionName}
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default React.memo(BulkActionsMenu);
