import React from 'react';
import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import Controller from './Controller';
import { useFormContext } from 'react-hook-form';

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    &.checkbox-form-control {
      span:last-of-type {
        white-space: nowrap;
      }
    }
  }
`;

const Checkbox = ({ name, labelPlacement, label, ...props }) => {
  const formContextData = useFormContext();

  const CheckBoxWrapper = checkBoxProps => {
    return <MUICheckbox {...props} {...checkBoxProps} />;
  };

  if (!formContextData) {
    return <CheckBoxWrapper checked={props.checked || props.value} />;
  }

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <FormControlLabelStyled
          className={'checkbox-form-control'}
          labelPlacement={labelPlacement}
          label={label}
          control={<CheckBoxWrapper {...field} checked={field.value} />}
        />
      )}
    />
  );
};

export default Checkbox;
