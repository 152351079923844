// @flow

const otherDocumentsInfo = {
  title: 'Other Contracts & Documents',
  component: 'clients/ClientsEditOtherDocumentsInfoModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      icon: 'Done',
      label: 'Save',
      uploadType: 'other_documents' // to UploadPresignedURL API
    },
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: null
};

export default otherDocumentsInfo;
