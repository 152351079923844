import React, { useCallback, useMemo } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { format } from 'date-fns';
import { CustomTable, TableCellField } from '../../customTable';
import { TRAINING_ANNUAL, TRAINING_TRAININGS, TRAINING_USERS } from './constants';
import { LazyIcon } from '../../icons';
import { useTrainingsContext } from './TrainingContext';
import Toolbar from './TrainingToolbar';

const TableCellText = styled.span`
  font-size: var(--fontSize);
`;

const TrainingTable = ({ ...props }) => {
  const { record, dispatch, params } = useTrainingsContext();

  const { trainingReport, requestReportType, selectedRows } = record;
  const {
    onLoadParams,
    onChangePage,
    onChangePerPage,
    onChangeSort,
    setTrainingModalData,
    setTrainingPrintData,
    onSelectRow,
    onSelectAllRows
  } = dispatch;

  const trainingAnnualTableContent = useMemo(
    () => (
      <>
        <TableCellField width="45%" renderComponent={({ name }) => <TableCellText>{name}</TableCellText>} />
        <TableCellField width="10%" renderComponent={({ score }) => <TableCellText>{score}</TableCellText>} />
        <TableCellField width="25%" renderComponent={({ date }) => <TableCellText>{date}</TableCellText>} />
        <TableCellField
          width="20%"
          renderComponent={({ id, training_id, hideCert, isFetching }) => {
            const method = !isFetching
              ? () => setTrainingPrintData({ open: true, id, trainingId: training_id })
              : () => {};
            return (
              <TableCellText>
                {!hideCert && (
                  <Tooltip title="Print Training Certificate" placement="left">
                    <IconButton onClick={method}>
                      <LazyIcon component="Print" color="colorSystemInfo" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCellText>
            );
          }}
        />
      </>
    ),
    []
  );

  const trainingUsersTableContent = useMemo(
    () => (
      <>
        <TableCellField width="80%" renderComponent={({ name }) => <TableCellText>{name}</TableCellText>} />
        <TableCellField
          width="20%"
          renderComponent={({ id, isFetching }) => {
            const method = !isFetching ? () => setTrainingModalData({ open: true, id }) : () => {};

            return (
              <TableCellText>
                <Tooltip title="View User Micro Training Report" placement="left">
                  <IconButton onClick={method}>
                    <LazyIcon component="Eye" color="colorSystemInfo" />
                  </IconButton>
                </Tooltip>
              </TableCellText>
            );
          }}
        />
      </>
    ),
    []
  );

  const trainingTrainingsTableContent = useMemo(
    () => (
      <>
        <TableCellField width="45%" renderComponent={({ name }) => <TableCellText>{name}</TableCellText>} />
        <TableCellField
          width="30%"
          renderComponent={({ published }) => {
            let published_date = published;
            if (published) {
              published_date = new Date(published);
              if (published_date.toString() !== 'Invalid Date') {
                published_date = format(published_date, 'yyyy-MM-dd');
              }
            }
            return <TableCellText>{published_date}</TableCellText>;
          }}
        />
        <TableCellField
          width="25%"
          renderComponent={({ id, isFetching }) => {
            const method = !isFetching ? () => setTrainingModalData({ open: true, id }) : () => {};

            return (
              <TableCellText>
                <Tooltip title="View Micro Training Report" placement="left">
                  <IconButton onClick={method}>
                    <LazyIcon component="Eye" color="colorSystemInfo" />
                  </IconButton>
                </Tooltip>
              </TableCellText>
            );
          }}
        />
      </>
    ),
    []
  );

  const getTableContent = useCallback(
    source => {
      switch (source) {
        case TRAINING_ANNUAL:
          return trainingAnnualTableContent;
        case TRAINING_USERS:
          return trainingUsersTableContent;
        case TRAINING_TRAININGS:
          return trainingTrainingsTableContent;
      }
    },
    [requestReportType, trainingAnnualTableContent, trainingUsersTableContent, trainingTrainingsTableContent]
  );

  const getTablePreferences = useCallback(
    source => {
      switch (source) {
        case TRAINING_ANNUAL:
          return {
            app: 'client',
            tab: TRAINING_ANNUAL
          };
        case TRAINING_USERS:
          return {
            app: 'client',
            tab: TRAINING_USERS
          };
        case TRAINING_TRAININGS:
          return {
            app: 'client',
            tab: TRAINING_TRAININGS
          };
      }
    },
    [requestReportType]
  );

  const tableContent = getTableContent(requestReportType);
  const preferences = getTablePreferences(requestReportType);

  return (
    <>
      <CustomTable
        {...props}
        toolbar={<Toolbar />}
        source={`clients/${requestReportType}`}
        total={trainingReport?.count || 0}
        data={trainingReport?.data || []}
        loading={trainingReport.loading}
        isFetching={trainingReport.isFetching}
        onLoad={onLoadParams}
        onChangePage={onChangePage}
        onChangePerPage={onChangePerPage}
        onSort={onChangeSort}
        preferences={preferences}
        onSelectAll={onSelectAllRows}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        params={{
          page: params?.pagination?.page,
          perPage: params?.pagination?.perPage
        }}
      >
        {tableContent}
      </CustomTable>
    </>
  );
};

export default TrainingTable;
