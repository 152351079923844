// @flow

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useTabDefault from './useTabDefault';
import useTabsAllowed from './useTabsAllowed';
import useLocation from './useLocation';

type useTabsLayoutTypes = {
  app: string,
  tab?: string | false,
  override?: Object,
  accessMerger?: Object
};

function useTabsLayout({ app, tab: tabProps, override, accessMerger = {} }: useTabsLayoutTypes) {
  const { tab: tabLoc, item } = useLocation();
  const { push, replace, location } = useHistory();
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const tab = tabProps === tabLoc ? tabProps : tabLoc;
  const previous = useSelector(rxState => rxState.bsn.system.tabPrevious);
  const Layout = () => useSelector(state => state.bsn.tabs[app]);
  const layout = override || Layout();

  if (app === 'partnerProfile' && userRole === 'Partner Administrator') {
    delete layout.taxExempt;
  }

  const allowed = useTabsAllowed({ layout, app, accessMerger });
  const allowedArray = Object.keys(allowed);
  const tabDefault = useTabDefault({ tab: String(tab), layout, layoutOverride: override, accessMerger });
  const active: string = allowedArray.includes(tabDefault) ? tabDefault || tabLoc : allowedArray[0] || tabLoc;
  const settings = Object.keys(layout).includes(active) ? layout[active] : layout[tab];

  useEffect(() => {    
    // to keep the back button of the browser functional
    const historyUpdate = location.pathname.includes('undefined') ? replace : push;
    if (sessionStorage.getItem('microtraining_id')) {
      historyUpdate(`/myDashboard/microTrainings`);
    } else if (app === `myDashboard` && active === `securityTraining`) {
      historyUpdate(`/${app}/${active}${encodeURI(location.search)}`);
    } else if (item && tabLoc !== active) {
      if(app === 'clients') {
        replace(`/${app}/${active}/${item}`)
        return;
      }
      historyUpdate(`/${app}/${active}/${item}`);
    } else if (!item && app !== 'clients') {
      historyUpdate(`/${app}/${active}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, tabLoc, item, location.pathname]);

  return { layout: allowed, active, previous, settings };
}

export default useTabsLayout;
