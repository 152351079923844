// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Home = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 22.4 19.8" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h22.4v19H8z" />
    <path
      className="st0"
      d="M22.2,8.4L11.8,0.2c-0.3-0.2-0.7-0.2-0.9,0L0.3,8.4C0,8.7-0.1,9.2,0.2,9.5s0.7,0.3,1,0.1l1.5-1.2V18  c0,1,0.8,1.8,1.8,1.8H9c0.4,0,0.8-0.3,0.8-0.8v-4.2c0-0.9,0.6-1.6,1.5-1.7c0.9,0,1.6,0.6,1.7,1.5c0,0,0,0,0,0.1v0.1V19  c0,0.4,0.3,0.8,0.8,0.8h4.4c1,0,1.8-0.8,1.8-1.8V8.6l1.3,1.1c0.3,0.3,0.8,0.2,1.1-0.1C22.5,9.2,22.5,8.7,22.2,8.4z M18,18.3h-3.7  v-3.5c0-1.7-1.3-3.1-3-3.2c-1.7,0-3.1,1.3-3.2,3c0,0.1,0,0.1,0,0.2v3.5H4.5c-0.1,0-0.3-0.1-0.3-0.3V7.2l7-5.5l7,5.6V18h0.1  C18.3,18.1,18.2,18.3,18,18.3z"
    />
  </SvgIconStyled>
);

export default Home;
