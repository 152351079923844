// @flow

import microTrainingUsers from './microTrainingUsers';
import documentsInfo from './documentsInfo';
import workPlan from './workPlan';
import workPlanFinding from './workPlanFinding';
import workPlanFindingInfo from './workPlanFindingInfo';
import sraDocuments from './sraDocuments';
import sraDocumentsInfo from './sraDocumentsInfo';

const myCompany = {};

myCompany.microTrainingUsers = microTrainingUsers;
myCompany.documentsInfo = documentsInfo;
myCompany.workPlan = workPlan;
myCompany.workPlanFinding = workPlanFinding;
myCompany.workPlanFindingInfo = workPlanFindingInfo;
myCompany.sraDocuments = sraDocuments;
myCompany.sraDocumentsInfo = sraDocumentsInfo;

export default myCompany;
