import { useCallback, useEffect, useReducer, useState, useMemo } from 'react';

import { useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import training from 'helpers/apis/training';
import { ANNUAL, MICRO_TRAINING, TRAINING_ANNUAL, TRAINING_TRAININGS } from './constants';
import { useClientId, ObjectToCommaSeparatedString } from 'helpers';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import { useRedirectionTarget } from 'hooks';

const initialState = {
  reportType: ANNUAL,
  reportValue: ANNUAL,
  subValue: null,
  filter: '',
  id: '',
  selectedRows: [],
  trainingFilters: {
    category: [{ label: '', value: '' }],
    type: {
      annual: [
        {
          current_course: false,
          id: '',
          label: '',
          training_id: 0,
          value: ''
        }
      ],
      'micro-training': [{ label: '', value: '' }]
    }
  }
};

const getReportTypeForAPI = type => {
  switch (type) {
    case ANNUAL:
      return TRAINING_ANNUAL;
    case MICRO_TRAINING:
      return TRAINING_TRAININGS;
    default:
      return type;
  }
};

const useTraining = () => {
  const [pauseRequest, setPauseRequest] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [params, setParams] = useState(null);
  const [pageError, setPageError] = useState(false);

  const redirectionTarget = useRedirectionTarget();

  const requestReportType = getReportTypeForAPI(state.reportType);
  const preferences = userPreferences.getTableSettings('client', requestReportType);

  const item = useClientId();

  const notify = useNotify();

  const filterString = useMemo(() => ObjectToCommaSeparatedString(params?._filter), [params?._filter]);

  const {
    isLoading: trainingFilterLoading,
    data: trainingFilterRecord,
    refetch: trainingFilterRefetch,
    isSuccess: trainingFilterIsSuccess
  } = useQuery('get training filter', async () => training.trainingFilters(item), {
    staleTime: 1000000,
    cacheTime: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: trainingLoading,
    data: trainingData,
    refetch: trainingRefetch,
    isFetching: trainingIsFetching
  } = useQuery(
    ['get training report', requestReportType, params],
    async () =>
      training.trainingReport(item, requestReportType, {
        ...params,
        _filter: filterString
      }),
    {
      staleTime: 1000000,
      cacheTime: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!params && pauseRequest
    }
  );

  useEffect(() => {
    if (state.reportType === ANNUAL && state.trainingFilters?.type[ANNUAL]) {
      setParams(p => ({ ...p, _reportId: state.trainingFilters.type[ANNUAL][0].id }));
    } else {
      setParams(p => ({ ...p, _reportId: null }));
    }
  }, [state.reportType, state.trainingFilters]);

  useEffect(() => {
    if (trainingFilterIsSuccess) {
      if (Object.keys(trainingFilterRecord.data.type).length) {
        const initialReportType = redirectionTarget === MICRO_TRAINING ? MICRO_TRAINING : ANNUAL;
        setState({ type: 'INITIALIZE', payload: trainingFilterRecord.data, initialReportType });
      } else setPageError(true);
    }
  }, [trainingFilterIsSuccess, trainingFilterRecord, redirectionTarget]);

  const onChangeValue = useCallback(
    ({ target: { value: val } }) => {
      setPauseRequest(false);
      setState({ type: 'CHANGETYPE', payload: val });
    },
    [state.trainingFilters]
  );

  const onChangeSubValue = useCallback(
    ({ target: { value: val } }) => {
      setResetSearch(p => !p);
      setParams(p => ({
        ...p,
        pagination: {
          page: 0,
          perPage: preferences?.pagination?.perPage || 25
        }
      }));

      const newReportType = state.reportValue === MICRO_TRAINING ? val : state.reportType;

      if (state.reportValue === ANNUAL) {
        const index = state.trainingFilters.type[state.reportValue].findIndex(x => x.value === val);
        const { id } = state.trainingFilters.type.annual[index];

        setState({ type: 'CHANGEFILTER', payload: { val, reportType: newReportType, training_id: id } });
        setParams(p => ({
          ...p,
          _reportId: id
        }));
      } else {
        setState({ type: 'CHANGEFILTER', payload: { val, reportType: newReportType } });
        setPauseRequest(false);
      }
    },
    [state.reportType, state.reportValue, state.trainingFilters?.type, preferences?.pagination?.perPage]
  );

  const onLoadParams = useCallback(({ sortname, order, page, perPage, ...params }) => {
    setResetSearch(p => !p);
    setParams(prev => ({
      ...prev,
      sort: { sortname, order },
      pagination: { page, perPage },
      ...params
    }));
    setPauseRequest(true);
  }, []);

  const onChangePage = useCallback(page => {
    setParams(prev => ({ ...prev, pagination: { ...prev.pagination, page } }));
  }, []);

  const onChangePerPage = useCallback(perPage => {
    setParams(prev => ({ ...prev, pagination: { ...prev.pagination, perPage, page: 0 } }));
  }, []);

  const onChangeSort = useCallback(sort => {
    setParams(prev => ({ ...prev, sort }));
  }, []);

  const onChangeFilter = useCallback((name, value) => {
    setParams(prev => {
      const _filter = { ...prev?._filter };

      // overwrite or delete target key
      if (!!name && !!value) {
        _filter[name] = value;
      } else {
        delete _filter[name];
      }

      return { ...prev, _filter };
    });
  }, []);

  const onSelectRow = useCallback((checked, id) => {
    setState({ type: 'SELECTROW', payload: { id, checked } });
  }, []);

  const onSelectAllRows = useCallback(
    checked => {
      setState({ type: 'SELECTROWS', payload: checked ? trainingData?.data.map(({ id }) => id) || [] : [] });
    },
    [trainingData?.data]
  );

  return {
    params,
    filterString,
    error: pageError,
    record: {
      ...state,
      resetSearch,
      requestReportType,
      trainingFilterData: {
        loading: trainingFilterLoading,
        data: trainingFilterRecord?.data,
        refetch: trainingFilterRefetch
      },
      trainingReport: {
        loading: trainingLoading,
        data: trainingData?.data,
        count: +trainingData?.headers?.['x-total-count'],
        refetch: trainingRefetch,
        isFetching: trainingIsFetching
      }
    },
    dispatch: {
      onChangeSubValue,
      onChangeValue,
      onLoadParams,
      onChangePage,
      onChangePerPage,
      onChangeSort,
      onChangeFilter,
      onSelectRow,
      onSelectAllRows
    }
  };
};

function reducer(prevState, { type, payload, initialReportType }) {
  switch (type) {
    case 'INITIALIZE': {
      if (payload.length === 0) return prevState;
      const types = payload.type;
      const firstTypeKey = Object.keys(types)[0];
      const reportType = initialReportType || firstTypeKey;
      return {
        ...prevState,
        reportType: reportType,
        reportValue: reportType,
        subValue: types?.[reportType]?.[0]?.value || '',
        id: types?.[reportType]?.[0]?.[reportType === ANNUAL ? 'id' : 'label'] || '',
        trainingFilters: payload
      };
    }

    case 'CHANGETYPE': {
      return {
        ...prevState,
        reportType: payload,
        reportValue: payload,
        subValue: prevState.trainingFilters.type[payload][0].value,
        id: prevState.trainingFilters.type[payload][0].id,
        selectedRows: []
      };
    }

    case 'CHANGEFILTER': {
      return {
        ...prevState,
        subValue: payload.val,
        reportType: payload.reportType,
        id: payload.training_id
      };
    }

    case 'SELECTROW': {
      return {
        ...prevState,
        selectedRows: payload.checked
          ? [...prevState.selectedRows, payload.id]
          : prevState.selectedRows.filter(id => id !== payload.id)
      };
    }

    case 'SELECTROWS': {
      return {
        ...prevState,
        selectedRows: payload
      };
    }

    default: {
      return { ...prevState, ...payload };
    }
  }
}

export default useTraining;
