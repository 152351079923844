import React, { createContext, useContext } from 'react';

const PreassessmentQuizContext = createContext(null);


export const PreassessmentQuizProvider = ({children, value}) => {
  return (
    <PreassessmentQuizContext.Provider value={value}>
      {children}
    </PreassessmentQuizContext.Provider>
  )
}

export const usePreassessmentQuizContext = () => {
  return useContext(PreassessmentQuizContext)
}
