import React, { createContext, useContext } from 'react';

const QuizContext = createContext({});

export const QuizContextProvider = ({children, value}) => {
  return (
    <QuizContext.Provider value={value}>
      {children}
    </QuizContext.Provider>
  )
}

export const useQuiz = () => {
  return useContext(QuizContext)
}
