// @flow
import React, { type Element, type Context, createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BSN_PARTNER_PROFILE_COLOR_SCHEME,
  BSN_PARTNER_PROFILE_COLOR_SCHEME_UPDATE,
  BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM,
  BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_UPDATE,
  BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM
} from 'conf/constants';
import { setVariables } from './utils';

const ThemeContext: Context<{
  state: { colorScheme: Object, colorSchemeCustom: Object },
  dispatch: Function
}> = createContext({
  state: {
    colorScheme: {},
    colorSchemeCustom: {}
  },
  dispatch: e => e
});

type ThemeProviderTypes = {
  children: any
};

export const ThemeProvider = ({ children }: ThemeProviderTypes): Element<*> => {
  const colorSchemeCustom = useSelector(state => state.bsn.partnerProfile.colorSchemeCustom);
  const colors = useSelector(state => state.bsn.user.theme);
  const reduxDispatch = useDispatch();
  const cs = localStorage.getItem('colorScheme');
  const [state, dispatch] = useState({
    colorScheme: cs ? JSON.parse(cs) : {},
    colorSchemeCustom
  });

  useEffect(() => {
    if (colors) setVariables(colors);
  }, [colors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatchMiddleware = {
    setColorScheme: useCallback(
      (data: string, save?: boolean) => {
        reduxDispatch({
          type: save ? BSN_PARTNER_PROFILE_COLOR_SCHEME_UPDATE : BSN_PARTNER_PROFILE_COLOR_SCHEME,
          resource: 'partnerProfile',
          tab: 'colorScheme',
          payload: data
        });
        dispatch({
          ...state,
          colorScheme: data
        });
        localStorage.setItem('colorScheme', JSON.stringify(data));
      },
      [reduxDispatch, state]
    ),
    setColorSchemeCustom: useCallback(
      (data: string, save?: boolean) => {
        reduxDispatch({
          type: save ? BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_UPDATE : BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM,
          resource: 'partnerProfile',
          tab: 'colorScheme',
          payload: data
        });
        dispatch({
          colorScheme: data,
          colorSchemeCustom: data
        });
        localStorage.setItem('colorScheme', JSON.stringify(data));
      },
      [reduxDispatch]
    ),
    setColorSchemeCustomItem: useCallback(
      (item: string, data: string) => {
        reduxDispatch({
          type: BSN_PARTNER_PROFILE_COLOR_SCHEME_CUSTOM_ITEM,
          resource: 'partnerProfile',
          tab: ['colorScheme', 'colorSchemeCustom'],
          item: [item, item],
          payload: [data, data]
        });
        dispatch({
          ...state,
          colorScheme: {
            ...state.colorScheme,
            [item]: data
          },
          colorSchemeCustom: {
            ...state.colorSchemeCustom,
            [item]: data
          }
        });
      },
      [reduxDispatch, state]
    )
  };

  return <ThemeContext.Provider value={{ state, dispatch: dispatchMiddleware }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
