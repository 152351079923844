// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Enable = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18v18H0z" />
    <g fill="none" stroke-width="1.8">
      <circle cx="9" cy="9" r="9" stroke="none" />
      <circle cx="9" cy="9" r="8.1" fill="none" />
    </g>
    <path
      d="M32.675,30.236a.676.676,0,0,0-.557-.268.684.684,0,0,0-.5.269c-1.813,2.183-3.464,4.337-5.226,6.487l-2.229-2.142a.654.654,0,0,0-1.032.138,1.007,1.007,0,0,0,.115,1.24L25.992,38.6a.646.646,0,0,0,.98-.069c1.971-2.374,3.744-4.714,5.685-7.052A1.012,1.012,0,0,0,32.675,30.236Z"
      transform="translate(-18.586 -25.025)"
    />
  </SvgIconStyled>
);

export default Enable;
