// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Notification = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20.4" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20v20H4z" />
    <path d="M17.7,12.3V7.7C17.7,3.4,14.2,0,10,0C5.8,0,2.3,3.4,2.3,7.7v4.6C1,12.5,0,13.7,0,15v0.4c0,0.8,0.7,1.5,1.5,1.5  c0,0,0,0,0,0h5c0,1.9,1.5,3.5,3.4,3.5c1.9,0,3.5-1.5,3.5-3.4c0,0,0-0.1,0-0.1h5c0.8,0,1.5-0.7,1.5-1.5c0,0,0,0,0,0V15  C20,13.7,19,12.5,17.7,12.3z M10,18.8c-1.1,0-1.9-0.9-1.9-1.9h3.8C11.9,18,11.1,18.8,10,18.8z M18.5,15l0,0.4H1.5V15  c0-0.6,0.5-1.2,1.2-1.2c0,0,0,0,0,0c0.6,0,1.1-0.5,1.1-1.2v-5c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2v5c0,0.6,0.5,1.1,1.1,1.2  C17.9,13.8,18.5,14.4,18.5,15C18.5,15,18.5,15,18.5,15z" />
  </SvgIconStyled>
);

export default Notification;
