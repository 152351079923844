// @flow
import React, { useEffect, useState } from 'react';
import { CustomSearchField, Button, LazyIcon, AdvancedFilter, Container } from 'components';
import { useDebounce } from '../../../../hooks';

type Props = {
  setSearchValue: Function,
  setFilters: Function,
  navigateToCreate: Function,
};

const filtersConfig = {
  active: {
    label: 'Active',
    icon: 'Enable',
    hasSearch: false,
    choices: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  account: {
    label: 'Account',
    icon: 'Account',
    hasSearch: false,
    choices: [
      { value: 'true', label: 'Production' },
      { value: 'false', label: 'Test' },
    ],
  },
  distributor: {
    label: 'Distributor',
    icon: 'Distributor',
    choices: [
      { value: 'bsn', label: 'BSN' },
      { value: 'pax8', label: 'PAX8' },
      { value: 'mp', label: 'MP' },
    ],
  },
  ebpp: {
    label: 'EBPP',
    icon: 'File',
    choices: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  pending: {
    label: 'Pending',
    icon: 'Clock',
    hasSearch: false,
    choices: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ],
  },
  client: {
    label: 'Client',
    icon: 'Users2',
    hasSearch: true,
    choices: [],
  },
  user: {
    label: 'User',
    icon: 'UserOutline',
    hasSearch: true,
    choices: [],
  },
};

const ManagePartnerToolbar = ({ setSearchValue, setFilters, navigateToCreate }: Props) => {
  const [value, setValue] = useState('');

  const [searchDebounceValue] = useDebounce({ value: value, delay: 500 });

  useEffect(() => {
    setSearchValue(searchDebounceValue.value);
  }, [searchDebounceValue]);

  return (
    <Container.Grid alignItems="flex-start" justifyContent="space-between" >
      <Container.Grid item md={10} lg={10} xl={10}>
        <AdvancedFilter
          searchComponent={
            <Container.Grid item sm={12} md={4} lg={3} xl={2} pt={1} pb={1} pr={2}>
              <CustomSearchField value={value} onChange={setValue} />
            </Container.Grid>
          }
          filtersConfig={filtersConfig}
          setFilters={setFilters}
        />
      </Container.Grid>

      <Container.Grid item md={2} lg={2} xl={2} justify="flex-end" pt={1}>
        <Button backgroundColor="colorBaseBase" backgroundColorHover="colorBaseBase" onClick={navigateToCreate}>
          <LazyIcon component="Plus" color="grey" size={1} mr={1} />
          New Partner
        </Button>
      </Container.Grid>
    </Container.Grid>
  );
};

export default ManagePartnerToolbar;