import React from 'react';
import classes from './css/cardStyles.module.css';
import { Info, Clock } from '../../components/icons';
import LineProgress from '../loaders/LineProgress';
import { Box, Button, Card, CardActionArea, IconButton, Typography } from '@material-ui/core';
import CustomTooltip from '../common/CustomTooltip';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import * as testAuthoringSystem from '../../helpers/apis/services/testAuthoringSystem';
import { useNotify } from 'react-admin';
import { encodeId } from '../../helpers';
import { Tooltip } from '@trustsecurenow/components-library';

const colors = ['var(--colorSystemDanger)', 'var(--colorSystemWarning)', 'var(--colorSystemSuccess)'];

const barColor = progress => {
  if (progress === 100) return colors[2];
  if (progress >= 50) return colors[1];
  if (progress > 0) return colors[0];
  return '#D9D9D9';
};

const CustomInfoTooltip = React.memo(({ open, setOpen, tooltipText, iconStyles }) => {
  return (
    <CustomTooltip
      open={open}
      className={classes.tooltip}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={<span style={{ whiteSpace: 'pre-line' }}> {tooltipText} </span>}
      placement="top"
      component="span"
      disableClickListener
    >
      <IconButton
        onMouseDown={event => event.stopPropagation()}
        style={iconStyles}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          setOpen(prev => !prev);
        }}
      >
        <Info style={{ fontSize: '16px', marginLeft: '5px' }} />
      </IconButton>
    </CustomTooltip>
  );
});

const TrainingCard = React.memo(
  ({
    description,
    duration,
    img,
    onOpen,
    additionalText = null,
    onQuiz = null,
    progress = null,
    watchButton = false,
    quizButton = false,
    markCompleteButton = false,
    trainingDetails = null,
    customStyles = {},
    clickable = false,
    width = 285,
    quizButtonText = null,
    watchButtonText = null,
    update = null,
    courseId = null,
    showProgress = true,
    PackageButton = null,
    packageButton = false,
    availableCourses,
    completedCourses,
    onSelectItem,
    category,
    impactsESS,
    packageName,
  }) => {
    const notify = useNotify();
    const [titleTooltip, setTitleTooltip] = React.useState(false);
    const [impactEssTooltip, setImpactEssTooltip] = React.useState(false);

    const handleCompletion = () => {
      testAuthoringSystem.setCompletedCourse(encodeId(courseId)).then(res => {
        if (res.status === 200) {
          update();
        } else {
          notify('an error occurred', 'warning');
        }
      });
    };

    return (
      <Card style={{ maxWidth: width, ...customStyles }} className={`${classes.root}`}>
        <ConditionalWrapper
          condition={clickable}
          wrapper={children => <CardActionArea onClick={onOpen}> {children} </CardActionArea>}
        >
          <>
            <div className={classes.container}>
              <div className={classes.imgContainer}>
                <img className={classes.imgClass} src={img} alt="img" />
              </div>
              <div className={classes.content}>
                <div className={classes.titleContainer}>
                  <Tooltip title={description} placement="top" variant="dark">
                    <h5 className={classes.title}>{description}</h5>
                  </Tooltip>
                  <CustomInfoTooltip
                    open={titleTooltip}
                    setOpen={setTitleTooltip}
                    tooltipText={trainingDetails}
                    iconStyles={{ padding: 5 }}
                  />
                </div>
                {category && (
                  <Typography style={{ fontSize: 10, color: 'var(--colorDefault)' }} component="p" variant="caption">
                    Category: {category}
                  </Typography>
                )}
                {packageName && (
                  <Typography style={{ fontSize: 10, color: 'var(--colorDefault)' }} component="p" variant="caption">
                    Package: {packageName}
                  </Typography>
                )}
                {impactsESS && (
                  <Typography style={{ fontSize: 10, color: 'var(--colorDefault)' }} component="p" variant="caption">
                    Impacts ESS?: {impactsESS?.text}
                    <CustomInfoTooltip
                      open={impactEssTooltip}
                      setOpen={setImpactEssTooltip}
                      tooltipText={impactsESS.tooltip}
                      iconStyles={{ fontSize: '16px', marginLeft: '5px', padding: 0 }}
                    />
                  </Typography>
                )}
                {additionalText  && (
                  <>
                    <p className={classes.duration} style={{ color: 'var(--colorLight)', marginTop: 14 }}>
                      {`${additionalText} `}
                    </p>
                    <>&ndash; </>
                  </>
                )}
                {!packageButton ? (
              
                    <div style={{ marginTop: 10, marginBottom: 10, display: 'inline-block' }}>
                      <Clock className={classes.durationIcon} />
                      <p className={classes.duration}> {duration ? `${duration} mins` : 'not calculated yet'} </p>
                    </div>
                  
                ) : (
                  <Box mt={1.5} mb={4.75}>
                    <Typography variant="body2" style={{ fontSize: 14, color: 'var(--colorLight)' }}>
                      Courses Available: {availableCourses}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 14, color: 'var(--colorLight)' }}>
                      Courses Completed: {completedCourses}
                    </Typography>
                  </Box>
                )}

                {showProgress && (
                  <>
                    <LineProgress
                      className={classes.progress}
                      backgroundColor="#D9D9D9"
                      barColor={barColor(progress)}
                      value={progress ? progress : 0}
                      variant="determinate"
                    />
                    <p className={classes.scorePercentage}>
                      {progress ? `${!packageButton ? 'Video Watched: ' : ''}${progress}%` : '0%'}
                    </p>
                  </>
                )}
              </div>
              <div className={classes.actionArea}>
                {watchButton && (
                  <Button onClick={onOpen} variant="outlined" className={`${classes.button} ${classes.watchButton}`}>
                    {watchButtonText}
                  </Button>
                )}
                {quizButton && (
                  <Button onClick={onQuiz} variant="outlined" className={`${classes.button} ${classes.quizButton}`}>
                    {quizButtonText}
                  </Button>
                )}
                {markCompleteButton && (
                  <Button
                    onClick={handleCompletion}
                    disabled={progress !== 100}
                    variant="outlined"
                    className={`${classes.button} ${classes.quizButton}`}
                  >
                    Mark Complete
                  </Button>
                )}
                {packageButton && (
                  <Button
                    fullWidth
                    variant="outlined"
                    className={`${classes.button} ${classes.watchButton} ${classes.packageButton} `}
                    onClick={() => onSelectItem(courseId)}
                  >
                    View Courses
                  </Button>
                )}
                {PackageButton}
              </div>
            </div>
          </>
        </ConditionalWrapper>
      </Card>
    );
  }
);

export default TrainingCard;