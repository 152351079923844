import React, { useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { IconButton } from './ComponentTypes';
import { Typography } from '../types';
import { LazyIcon } from '../icons';
import styled from 'styled-components';
import type { ComponentType } from 'react';

const Content = styled.div`
  img {
    max-width: 100% !important;
  }
`;

const { h3: H3 } = Typography;

const DialogTitleStyled: ComponentType<*> = styled(DialogTitle)`
  && {
    background: white;
    padding: calc(var(--spacing) * 3);
    .modal-title {
      margin: 0 5% 0 0;
      color: var(--colorCommonBlack);
    }
  }
`;

const PreviewContentStyled = styled(DialogContent)`
  background-color: var(--colorBaseLight3);
  color: var(--colorCommonBlack);

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGreyLight2);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: var(--colorBaseLight3);
  }
`;

const DialogBody = ({ preview_html, onClose, title, selector }) => {
  const templatePreviewRef = useRef(null);

  useEffect(() => {
    if (!preview_html || !templatePreviewRef?.current) return;

    const parser = new DOMParser();
    const html = parser.parseFromString(preview_html, 'text/html');
    const elements = html.querySelectorAll(selector);
    if (!elements) {
      return;
    }
    for (const element of elements) {
      const links = element.querySelectorAll('a');

      Array.from(links).forEach(link => {
        link.style.pointerEvents = 'none';
      });

      templatePreviewRef.current.appendChild(element);
    }
  }, [preview_html, templatePreviewRef]);

  return (
    <>
      <DialogTitleStyled id="dialog-title">
        <H3 className={'modal-title'}>{title}</H3>
        <IconButton onClick={onClose}>
          <LazyIcon component="Close" />
        </IconButton>
      </DialogTitleStyled>
      <PreviewContentStyled>
        <Content ref={templatePreviewRef} />
      </PreviewContentStyled>
    </>
  );
};

const PreviewHTMLModal = ({ open, onClose, ...data }) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogBody {...data} onClose={onClose} />
    </Dialog>
  );
};

export default PreviewHTMLModal;
