// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';


const SuccessCircle = (props) => (
  <svg {...props} viewBox="0 0 70 70" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 35C18 44.3762 25.6238 52 35 52C44.3762 52 52 44.3762 52 35C52 25.6238 44.3762 18 35 18C25.6238 18 18 25.6238 18 35ZM20.6154 35C20.6154 27.0623 27.0623 20.6154 35 20.6154C42.9246 20.6154 49.3846 27.0623 49.3846 35C49.3846 42.9377 42.9377 49.3846 35 49.3846C27.0623 49.3846 20.6154 42.9377 20.6154 35ZM41.3422 30.8153C41.8522 30.3053 42.6761 30.3053 43.1861 30.8153C43.6961 31.3253 43.6961 32.1492 43.2122 32.6723L32.7376 43.1469C32.4761 43.3953 32.1491 43.5261 31.8091 43.5261C31.626 43.5261 31.4561 43.4738 31.2861 43.4084C31.1292 43.343 30.9722 43.2515 30.8414 43.1207L26.8268 39.1061C26.3168 38.5961 26.3168 37.7723 26.8268 37.2623C27.3368 36.7523 28.1607 36.7523 28.6707 37.2623L31.783 40.3746L41.3422 30.8153Z" />
    <circle cx="35" cy="35" r="35" fill-opacity="0.2"/>
  </svg>
);

export default SuccessCircle;
