import styled from 'styled-components';

export const ObscureSpan = styled.span`
  & * {
    cursor: default;
    user-select: none !important;
  }
`;

export const LockedContent = styled.div`
  cursor: ${({ locked }) => (locked ? `no-drop !important` : `auto`)};
  opacity: ${({ opacity, locked }) => (locked ? opacity ?? '1' : '1')};
  & * {
    pointer-events: ${({ locked }) => (locked ? `none !important` : `all`)};
    user-select: none !important;
  }
`;
