// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WarningXl = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 230 230" role="img" aria-hidden="false">
    <path d="M115 0C92.2552 0 70.0211 6.74463 51.1095 19.381C32.1978 32.0174 17.458 49.9779 8.7539 70.9914C0.0498257 92.0049 -2.22756 115.128 2.20974 137.435C6.64705 159.743 17.5998 180.234 33.6828 196.317C49.7658 212.4 70.2569 223.353 92.5647 227.79C114.873 232.228 137.995 229.95 159.009 221.246C180.022 212.542 197.983 197.802 210.619 178.891C223.255 159.979 230 137.745 230 115C230 99.898 227.026 84.9439 221.246 70.9914C215.467 57.039 206.996 44.3615 196.317 33.6827C185.639 23.004 172.961 14.5331 159.009 8.75385C145.056 2.97456 130.102 0 115 0ZM115 204.852C97.229 204.852 79.8571 199.582 65.081 189.709C50.3049 179.836 38.7883 165.803 31.9876 149.385C25.1869 132.967 23.4076 114.9 26.8745 97.4707C30.3415 80.0412 38.8991 64.0311 51.4651 51.465C64.0311 38.899 80.0412 30.3414 97.4708 26.8745C114.9 23.4075 132.967 25.1869 149.385 31.9876C165.803 38.7883 179.836 50.3048 189.709 65.0809C199.582 79.857 204.852 97.229 204.852 115C204.852 138.83 195.386 161.684 178.535 178.535C161.685 195.385 138.83 204.852 115 204.852Z"/>
    <path d="M109.19 126.626L109.191 126.637L109.192 126.647C109.464 129.54 109.96 131.724 110.594 133.223L110.598 133.234L110.603 133.245C110.978 134.057 111.514 134.706 112.232 135.146C112.948 135.583 113.791 135.781 114.731 135.781C115.648 135.781 116.489 135.581 117.214 135.14C117.94 134.698 118.501 134.042 118.904 133.208C119.665 131.684 120.16 129.476 120.378 126.747C120.378 126.746 120.378 126.746 120.378 126.745L122.37 104.087C122.587 101.964 122.696 99.8942 122.696 97.8232C122.696 94.3412 122.265 91.5661 121.275 89.5803C120.247 87.4059 118.139 86.4116 115.323 86.4116C112.921 86.4116 110.999 87.2418 109.505 88.7956L109.505 88.7956L109.496 88.8059C107.985 90.4378 107.304 92.6344 107.304 95.2924C107.304 97.2049 107.467 100.299 107.736 104.601L107.736 104.603L109.19 126.626ZM120.384 155.58L120.396 155.57L120.408 155.56C121.929 154.161 122.696 152.28 122.696 149.946C122.696 147.853 121.91 146.049 120.43 144.57C118.933 143.073 117.068 142.358 114.946 142.358C112.817 142.358 111.008 143.077 109.516 144.57C108.025 146.06 107.304 147.868 107.304 149.946C107.304 152.268 108.065 154.219 109.663 155.575C111.204 156.935 112.995 157.589 115.054 157.589C117.077 157.589 118.861 156.869 120.384 155.58Z" stroke={props.strokeColor} stroke-width="1.5"/>
  </SvgIconStyled>
);

export default WarningXl;
