import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import Button from './Button';

export const ButtonPulseAnimate: ComponentType<*> = styled(Button)`
  && {
    box-shadow: 0px 0px 1px 1px #0000001a;
    animation: ${({animation}) => 
    animation ? 'pulse-animation 2s infinite;' : 'none'}
  }
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
    }
    100% {
      box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
    }
  }
`;