// @flow

const phishingReportsCampaign = {
  title: 'Phishing Reports',
  component: 'shared/phishingReports/PhishingReportModal',
  requireRowId: true,
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Close'
    },
    close: {
      icon: 'Refresh',
      method: 'onRefresh'
    },
    other: [
      {
        icon: 'Refresh',
        type: 'refresh',
        label: 'Refresh'
      }
    ]
  }
};

export default phishingReportsCampaign;
