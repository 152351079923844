import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import { Tooltip } from '@material-ui/core';
import {
  ArrowRightIcon,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  RadioGroup,
  RadioInput,
  SaveIcon,
  Select,
  Typography,
  TextField,
} from '@trustsecurenow/components-library';

const NETWORK_OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const AVAILABLE_OSES = [
  {
    label: 'Windows 11',
    name: 'os_win11'
  },
  {
    label: 'Windows 10',
    name: 'os_win10'
  },
  {
    label: 'Windows 8',
    name: 'os_win8'
  },
  {
    label: 'Windows 7',
    name: 'os_win7'
  },
  {
    label: 'Windows vista',
    name: 'os_winvista'
  },
  {
    label: 'Windows xp',
    name: 'os_winxp'
  },
  {
    label: 'Older Windows',
    name: 'os_winold'
  },
  {
    label: 'Apple/Mac',
    name: 'os_mac'
  }
];

const Network = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  };

  const handleStateBooleanChange = event => {
    const { name, checked } = event.target;
    setState(prevState => {
      return { ...prevState, [name]: checked ? 1 : 0 };
    });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  const handleNumberInputKeyDown = e => {
    if (/^[eE]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validNumberOfLaptops = state.number_laptops === null || state.number_laptops >= 0;
  const validNumberOfServers = state.number_of_servers === null || state.number_of_servers >= 0;
  const validNumberOfWorkstations = state.number_workstations === null || state.number_workstations >= 0;

  return (
    <>
      {/* Section info */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ borderRadius: 2 }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component='p' variant='p' sx={{ padding: 1, paddingLeft: 2, marginY: 2 }} fontSize={16}>
                  In this section we will ask you questions about your network including your servers, desktops,
                  laptops, etc. This will help us get an overview of your network and assist with the Risk Assessment.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>
              {/* About data */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                sx={{ marginBottom: 3 }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      1.About data *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* How many physical servers do you have? */}
              <Grid item sx={{ padding: 2 }} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.number_of_servers}
                  name="number_of_servers"
                  label="How many physical servers do you have?"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                  error={!validNumberOfServers}
                  helperText={validNumberOfServers ? ' ' : 'Number of servers cannot be less than 0'}
                  onKeyDown={handleNumberInputKeyDown}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>
              {/* Server data */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      {isHSN
                        ? '2.Do you have patient information on any physical servers? *'
                        : '2.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses, or confidential organizational information) on any physical server? *'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Servers/pi on device */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name={isHSN ? 'phi_on_servers' : 'pii_on_servers'}
                  value={isHSN ? state.phi_on_servers : state.pii_on_servers}
                  onChange={handleChangeState}
                >
                  {YES_NO_CHOICES.map(choice => {
                    return (
                      <RadioInput
                        key={choice.value}
                        value={choice.value}
                        label={choice.label}
                        labelPlacement="end"
                        color="default"
                      />
                    );
                  })}
                </RadioGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>
              {/* What is the network operating system? */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      3.What is the network operating system? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Network operating system */}
              <Grid item mb={1} pl={2} pr={2} pt={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip
                  title={state.network_operating_system ? "" : "This is the operating system used on your servers."}
                  PopperProps={{ style: { zIndex: 0 } }}
                >
                    <Select
                      fullWidth
                      name="network_operating_system"
                      value={state.network_operating_system}
                      onChange={handleChangeState}
                    >
                      {NETWORK_OS_CHOICES.map(choice => {
                        return <MenuItem key={choice.value} value={choice.value}>{choice.label}</MenuItem>
                      })}
                    </Select>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>

              {/* Please provide details of your network */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      4.Please provide details of your network
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Network Details */}
              <Grid item pl={2} pr={2} pt={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.network_details}
                  label="Network Details"
                  name="network_details"
                  placeholder="Ex: We have four different network at company. Both use Windows"
                  fullWidth
                  rows={4}
                  multiline
                  onChange={handleChangeState}
                />
              </Grid>
              <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6} />

              {/* How many workstations(desktops) do you have? */}
              <Grid item pl={2} pr={2} pt={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.number_workstations}
                  name="number_workstations"
                  label="How many workstations(desktops) do you have? *"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                  error={!validNumberOfWorkstations}
                  helperText={validNumberOfWorkstations ? ' ' : 'Number of workstations cannot be less than 0'}
                  onKeyDown={handleNumberInputKeyDown}
                />
              </Grid>

              {/* How many laptops do you have? */}
              <Grid item pl={2} pr={2} pt={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  value={state.number_laptops}
                  name="number_laptops"
                  label="How many laptops do you have? *"
                  type="number"
                  fullWidth
                  onChange={handleChangeState}
                  inputProps={{ min: 0, step: 1, maxLength: 7 }}
                  integerOnly
                  error={!validNumberOfLaptops}
                  helperText={validNumberOfLaptops ? ' ' : 'Number of laptops cannot be less than 0'}
                  onKeyDown={handleNumberInputKeyDown}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>

              {/* Workstations information */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      {isHSN
                        ? '5.Do you have patient information on any workstations? *'
                        : '5.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any workstations? *'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Workstations/pi on device */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                  <div>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name={isHSN ? 'phi_on_workstations' : 'pii_on_workstations'}
                      value={isHSN ? state.phi_on_workstations : state.pii_on_workstations}
                      onChange={handleChangeState}
                    >
                      {YES_NO_CHOICES.map(choice => {
                        return (
                          <RadioInput
                            key={choice.value}
                            value={choice.value}
                            label={choice.label}
                            labelPlacement="end"
                            color="default"
                          />
                        );
                      })}
                    </RadioGroup>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>

              {/* Laptops information */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/*  eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      {isHSN
                        ? '6.Do you have patient information on any laptops? *'
                        : '6.Do you collect and/or store sensitive data (including credit cards, social security numbers, date of birth, driver’s licenses or confidential organizational information) on any Laptops? *'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Laptops/pi on device */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tooltip title="Keep in mind, sensitive data may be in Documents, Spreadsheets, PDFs, Email Attachments, Screenshots, Audio/Videos, etc.">
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name={isHSN ? 'phi_on_laptops' : 'pii_on_laptops'}
                      value={isHSN ? state.phi_on_laptops : state.pii_on_laptops}
                      onChange={handleChangeState}
                    >
                      {YES_NO_CHOICES.map(choice => {
                        return (
                          <RadioInput
                            key={choice.value}
                            value={choice.value}
                            label={choice.label}
                            labelPlacement="end"
                            color="default"
                          />
                        );
                      })}
                    </RadioGroup>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>
              {/* Please check any operating systems your workstations and laptops are running */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      7.Please check any operating systems your workstations and laptops are running *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Oses */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormGroup aria-label="position" row>
                  {AVAILABLE_OSES.map(item => {
                    const { label, name } = item;
                    return (
                      <FormControlLabel
                        key={name}
                        value={state[name] === 1}
                        checked={state[name] === 1}
                        control={<Checkbox color="default" />}
                        name={name}
                        label={label}
                        labelPlacement="end"
                        onChange={handleStateBooleanChange}
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>

          {/* Action buttons */}
          <Grid
            item
            sx={{ marginTop: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid container spacing={4} display="flex" justifyContent="flex-end">
              <Grid
                sx={{ margin: 2 }}
                display="flex"
                justifyContent="flex-end"
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <ButtonCancel variant="text" onClick={e => handleClose()}>
                  Exit
                </ButtonCancel>
                <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
                  Save
                </Button>
                <Button
                  color="success"
                  startIcon={<ArrowRightIcon />}
                  variant="contained"
                  disableElevation
                  sx={{ marginLeft: '30px' }}
                  onClick={() => save('next')}
                >
                  Save and Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Network.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default Network;
