import React, {createContext, useContext} from "react"

const ReferralCodeContext = createContext({})

export const ReferralCodeProvider = ({value, children}) => {
  return (
    <ReferralCodeContext.Provider value={value}>
      {children}
    </ReferralCodeContext.Provider>
  )
}

export const useReferralCodeContext = () => useContext(ReferralCodeContext)

