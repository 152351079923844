import {
  CircleCheckIcon,
  CircleCloseIcon,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  enqueueAlertSnackbar,
  Typography
} from '@trustsecurenow/components-library';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clientsAPI from 'helpers/apis/clients';
import { isNA } from '../helpers';

const MTNLCell = ({ enabled, clientId, refetchTable }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  if (isNA(enabled))
    return (
      <Tooltip placement="top" title="This account is inactive or does not have Micro Trainings/Newsletters">
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  const handleClick = async e => {
    e.stopPropagation();
    if (enabled) return;

    try {
      setIsLoading(true);
      await clientsAPI.putInformation(clientId, 'options', { send_newsletter: true });
      refetchTable();
      enqueueAlertSnackbar(
        'Micro Trainings/Newsletter emails enabled. Ensure users have been sent a welcome message to start receiving Micro Trainings/Newsletter emails.',
        {
          props: { severity: 'success' }
        }
      );
    } catch (err) {
      enqueueAlertSnackbar(`${err.response.data.description}`, { props: { severity: 'error' } });
    } finally {
      setIsLoading(false);
    }
  };

  const tooltipTitle = enabled ? 'Micro Trainings and Newsletters are enabled to send to clients, click into this account and go to the Information tab to adjust these settings' : 'Click here to Enable Micro Trainings/Newsletter emails';
  const Icon = enabled ? CircleCheckIcon : CircleCloseIcon;
  const IconButtonProps = enabled
    ? {
        color: 'success',
        LinkComponent: Link,
        to: `/clients/information/${clientId}`
      }
    : {
        color: 'error'
      };

  return isLoading ? (
    <Stack p={1}>
      <CircularProgress size={20} />
    </Stack>
  ) : (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton {...IconButtonProps} onClick={handleClick}>
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

MTNLCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  refetchTable: PropTypes.func.isRequired
};

export default MTNLCell;