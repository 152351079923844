// @flow

import React, { memo } from 'react';
import styled from 'styled-components';
import { LoadingStyled } from '../../types';

const Loading = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: ${({ fetched }) => (fetched ? '215px' : '260px')};
  height: ${({ fetched }) => (fetched ? 'auto' : 'calc(100vh - 300px)')};
  display: flex;

  > h2 {
    padding: 80px 20px 20px;
    display: table-cell;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
  }

  > div {
    margin: auto;
  }
`;

type TableListNoResultstypes = {
  hasRecords: boolean,
  fetched: boolean,
  colNumber: Number
};

const TableListNoResults = ({ hasRecords, fetched, colNumber }: TableListNoResultstypes) => {
  return hasRecords ? (
    <tr style={{ opacity: 1 }}>
      <td colSpan={colNumber}>
        <Loading fetched={fetched}>{fetched ? <h2>No results.</h2> : <LoadingStyled />}</Loading>
      </td>
    </tr>
  ) : null;
};

export default memo<TableListNoResultstypes>(TableListNoResults);
