import complianceChart from './complianceChart';
import threats from './threats';
import recommendations from './recommendations';

const sra = {};

sra.complianceChart = complianceChart;
sra.threats = threats;
sra.recommendations = recommendations;

export default sra;
