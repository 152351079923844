import React from 'react';
import { Link, Stack, Typography } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';

const ContactUs = ({ email, title, emailColor, copyrightsColor }) => {
  return (
    <Stack
      px={3}
      pt={2.5}
      pb={10.25}
      spacing={9}
      justifyContent="space-between"
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'center', md: 'flex-end' }}
    >
      <Stack spacing={{ xs: 4, md: 5 }} alignItems={{ xs: 'center', md: 'flex-start' }}>
        <Typography variant="h2" color={emailColor}>
          Contact Us:
        </Typography>
        <Link target="_blank" variant="link" fontSize={{ xs: 20, md: 24 }} color={emailColor} href={`mailto:${email}`}>
          {email}
        </Link>
      </Stack>
      <Typography variant="body2" color={copyrightsColor}>
        {title} © {new Date().getFullYear()}
      </Typography>
    </Stack>
  );
};

ContactUs.propTypes = {
  email: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  emailColor: PropTypes.func.isRequired,
  copyrightsColor: PropTypes.func.isRequired
};

export default ContactUs;
