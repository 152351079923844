// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const StarFilled = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8589 0.319426C11.1734 0.11789 11.5378 0.00945364 11.9106 0.00651094C12.3133 -0.026267 12.7165 0.0652546 13.0662 0.268873C13.4159 0.472491 13.6955 0.77853 13.8679 1.14619L15.9743 5.7049C16.1121 5.99845 16.3189 6.25381 16.5768 6.4489C16.8347 6.64398 17.1361 6.77293 17.4546 6.8246L22.3327 7.58438C22.6904 7.64305 23.0254 7.79901 23.3013 8.03539C23.5772 8.27177 23.7834 8.57957 23.898 8.92548C24.0126 9.27139 24.0311 9.64225 23.9514 9.99793C23.8716 10.3536 23.6967 10.6806 23.4456 10.9434L19.7893 14.7324C19.5755 14.9566 19.4166 15.2278 19.3252 15.5245C19.2338 15.8213 19.2124 16.1354 19.2627 16.4419L20.1073 21.6804C20.1537 21.9685 20.1369 22.2633 20.058 22.5441C19.9792 22.8249 19.8401 23.085 19.6508 23.306C19.4614 23.527 19.2263 23.7037 18.9619 23.8235C18.6975 23.9434 18.4102 24.0035 18.1202 23.9998C17.7898 23.9967 17.4655 23.9108 17.1764 23.7498L12.9937 21.4105C12.691 21.2386 12.3477 21.1522 12.0001 21.1606C11.6691 21.1582 11.3431 21.2445 11.0561 21.4105L6.87338 23.7498C6.5696 23.9191 6.22705 24.0052 5.87986 23.9998C5.58987 24.0035 5.3026 23.9434 5.03817 23.8235C4.77375 23.7037 4.53866 23.527 4.3493 23.306C4.15994 23.085 4.02089 22.8249 3.94204 22.5441C3.86319 22.2633 3.84637 21.9685 3.8928 21.6804L4.7374 16.4419C4.78637 16.1346 4.76335 15.82 4.67021 15.5232C4.57707 15.2264 4.41634 14.9556 4.20085 14.7324L0.554679 10.9434C0.303548 10.6806 0.128472 10.3536 0.0486977 9.99793C-0.0310769 9.64225 -0.0125295 9.27139 0.102061 8.92548C0.216652 8.57957 0.423097 8.27177 0.699003 8.03539C0.974909 7.79901 1.30966 7.64305 1.66731 7.58438L6.54569 6.8246C6.86424 6.77293 7.16535 6.64398 7.42327 6.4489C7.6812 6.25381 7.88817 5.99845 8.02603 5.7049L10.1322 1.14619C10.2925 0.807466 10.5444 0.520963 10.8589 0.319426Z" />
  </SvgIconStyled>
);

export default StarFilled;
