import React, { useEffect, useState, cloneElement } from 'react';
import QuizHeader from "./QuizHeader"
import QuizContent from "./QuizContent"
import { QuizWrapper } from './quizStyle';
import { LoadingStyled } from '../types';
import { QuizContextProvider } from './quizContext';
import useQuiz from './useQuiz';

const Quiz = ({
  quizId,
  finishingModal,
  onFinishQuiz,
  redirect
}) => {

  const [loading, setLoading] = useState(true)
  const { record, dispatch } = useQuiz()

  useEffect(() => {
    (async () => {
      setLoading(true)
      await dispatch.getInitialData(quizId)
      setLoading(false)
    })()
  }, [])

  return (
    <QuizContextProvider value={{...record, dispatch, onFinishQuiz, quizId, redirect}}>
      {finishingModal && cloneElement(finishingModal, {
        open: record.isQuizFinished,
        onClose: dispatch.closeModal
      })}
      {
        loading ? (
          <LoadingStyled />
        ) : (
          <QuizWrapper>
            <QuizHeader/>
            <QuizContent/>
          </QuizWrapper>
        )
      }
    </QuizContextProvider>
  )
}

export default Quiz
