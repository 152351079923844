import bsnReducer from './bsnReducer';
import { setStorageObject } from 'helpers/storage';
import { initialState } from 'conf/initialState';

/* 
 * this file is meant to persist the data got from the login that we don't have access to it anymore
 * the data we got in the login we need to persist are used in redux state across the application
 * currently the data are {user, access, colors}
*/

/* 
 * CAUTION: You should only persist the user profile, user access, and user theme.
 * Persisting any other state could lead to unintended behavior or security risks.
 * Any other Redux state should be isolated from the user object in the bsn state
*/

export default (previousState = initialState, 
    { type, resource, tab, item, key, payload, atBeginning }) => {
    const newState = bsnReducer(previousState, { type, resource, tab, item, key, payload, atBeginning });
    const userData = newState?.user || {};
    userData?.profile && userData?.access && userData?.theme && setStorageObject('userData', userData);
    return newState;
};
