/* eslint-disable react/prop-types */
import {
  CalenderIcon,
  DateCalendarPopover,
  Link,
  Stack,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import * as sra from 'helpers/apis/services/sra';
import { useSRA } from '../context/sraContext';

const DateCell = props => {
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const { clientId } = useSRA();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const open = Boolean(anchorEl);

  const isAdmin = userRole === 'Administrator';

  const { date_completed, status, id } = props.row;

  const handleOpenDatePicker = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDatePicker = () => {
    setAnchorEl(null);
  };

  const handleChangeCompletedDate = async date => {
    try {
      handleCloseDatePicker();
      setIsLoading(true);
      let dateCompleted = null;
      if (date) {
        const selected_date = new Date(date);
        const now = new Date();
        selected_date.setHours(now.getHours());
        selected_date.setMinutes(now.getMinutes());
        selected_date.setSeconds(now.getSeconds());
        dateCompleted = selected_date.toISOString();
      }
      const response = await sra.updateSraReport(clientId, id, { date_completed: dateCompleted });
      const message = response?.data?.description;
      await props.refetch();
      if (message) enqueueAlertSnackbar(message, { props: { color: 'success' } });
    } finally {
      setIsLoading(false);
    }
  };

  const canEdit = isAdmin && status === 1; // Pending

  if (!date_completed) return null;

  return !canEdit ? (
    <Typography variant="body2">{format(new Date(date_completed), 'MM/dd/yyyy')}</Typography>
  ) : (
    <Stack direction="row" alignItems="center" spacing={1.25}>
      <Link
        component="button"
        startIcon={<CalenderIcon />}
        onClick={handleOpenDatePicker}
        disabled={isLoading}
        loading={isLoading}
        hoverColor="info.main"
        color={open ? 'info.main' : 'inherit'}
        underline="none"
      />
      <Typography variant="body2" color={open ? 'info.main' : 'inherit'}>
        {format(new Date(date_completed), 'MM/dd/yyyy')}
      </Typography>
      <DateCalendarPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseDatePicker}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        DateCalendarProps={{ onChange: handleChangeCompletedDate, disableFuture: true }}
      />
    </Stack>
  );
};

export default DateCell;
