import { REDIRECT_TO_FAVORITE } from 'conf/constants';

const initialState = {
  redirectToFavorite: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case REDIRECT_TO_FAVORITE: {
      return {
        ...state,
        redirectToFavorite: action.payload
      };
    }
  
    default:
      return state;
  }
};

export default authReducer;
