// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const MyDashboard = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 26.8 15.2" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h26.8v15H2z" />
    <path d="M25.9,6.1L25.9,6.1l-6.3,0c-1.2,0-1.8,1.6-2.4,4.3c-0.2-0.8-0.3-1.7-0.5-2.6l0-0.1c-0.4-2.2-0.8-4.3-1.5-6.5  c-0.2-0.6-0.6-1-1.2-1.2c-0.6-0.1-1.2,0.2-1.5,0.8L7.7,8.2L7.4,7.4c-0.2-0.6-0.8-1-1.4-1H0.9C0.4,6.4,0,6.9,0,7.4  c0,0.5,0.4,0.8,0.9,0.9h5l0.9,2.3c0.1,0.3,0.4,0.5,0.7,0.6c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.7-0.4l5.5-8.5c0.4,1.4,0.8,3.6,1.2,5.8  c0.4,2.2,0.8,4.3,1.5,6.5c0.1,0.4,0.4,0.6,0.8,0.6c0.4,0,0.7-0.2,0.8-0.6c0.3-0.8,0.5-1.7,0.6-2.5c0.2-1.4,0.6-2.7,1.1-4.1h6.1  c0.5,0,0.9-0.5,0.9-1.1C26.7,6.5,26.4,6.1,25.9,6.1z" />
  </SvgIconStyled>
);

export default MyDashboard;
