// @flow

import palette from './palette';

const typography = {};
typography.fontFamily = '"Roboto", sans-serif';
typography.fontWeightLight = '300';
typography.fontWeightRegular = '400';
typography.fontWeightMedium = '500';
typography.fontWeightBold = '700';
typography.fontSize = '1.4rem';
typography.htmlFontSize = typography.fontSize;

const display = {
  fontFamily: typography.fontFamily,
  fontSize: '3.5rem',
  fontWeight: typography.fontWeightRegular,
  lineHeight: '1.3',
  color: palette.primary
};

typography.display4 = {
  ...display,
  ...{
    fontSize: '7rem',
    fontWeight: 300,
    lineHeight: 1.14286,
    color: palette.secondary
  }
};
typography.display3 = { ...display, ...{ fontSize: '3.5rem', color: palette.secondary } };
typography.display2 = { ...display, ...{ fontSize: '2.8125rem', color: palette.secondary } };
typography.display1 = { ...display, ...{ fontSize: '2.125rem', color: palette.secondary } };
typography.title = {
  ...display,
  ...{
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: '1.16667em',
    color: palette.secondary
  }
};
typography.headline = {
  ...display,
  ...{
    fontSize: '2rem',
    fontWeight: 600,
    color: palette.secondary
  }
};
typography.subheading = {
  ...display,
  ...{
    fontSize: '1.6rem',
    lineHeight: 1.5
  }
};
// Paragraph
typography.body2 = {
  ...display,
  ...{
    fontSize: '1.6rem',
    lineHeight: 1.5
  }
};
typography.body1 = {
  ...display,
  ...{
    fontSize: '1.6rem',
    lineHeight: 1.5
  }
};
typography.caption = {
  ...display,
  ...{
    fontSize: '1rem',
    lineHeight: 1.66
  }
};
typography.button = {
  ...display,
  ...{
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: 'uppercase'
  }
};
typography.h1 = {
  ...display,
  ...{
    fontSize: '6rem',
    fontWeight: 300,
    lineHeight: 1
  }
};
typography.h2 = {
  ...display,
  ...{
    fontSize: '3rem',
    fontWeight: 300,
    lineHeight: 1
  }
};
typography.h3 = {
  ...display,
  ...{
    fontSize: '2.4rem',
    lineHeight: 1.04,
    fontWeight: 600
  }
};
typography.h4 = {
  ...display,
  ...{
    fontSize: '1.9rem',
    lineHeight: 1.17,
    fontWeight: 600
  }
};
typography.h5 = {
  ...display,
  ...{
    fontSize: '1.5rem',
    lineHeight: 1.33
  }
};

typography.h6 = {
  ...display,
  ...{
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.04,
    color: 'var(--colorDefault)'
  }
};

typography.subtitle1 = {
  ...display,
  ...{
    fontSize: '1.6rem',
    lineHeight: 1.75
  }
};
typography.subtitle2 = {
  ...display,
  ...{
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.57
  }
};
typography.overline = {
  ...display,
  ...{
    fontSize: '0.75rem',
    lineHeight: 2.66,
    textTransform: 'uppercase'
  }
};
typography.useNextVariants = true;

export default typography;
