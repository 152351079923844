import React from 'react';
import { useCheckAccess } from 'helpers';

const useAdminAccess = () => {
  const hasAccess = useCheckAccess("app", "admin")

  return hasAccess;
};

export default useAdminAccess;
