import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';

const baseURL = process.env.DIRECTORY_SYNC_BASE_URL;
const baseURLOAuth = process.env.DIRECTORY_SYNC_OAUTH_BASE_URL;


const directorySync = {};

directorySync.auth = (payload) => {
    const requestHeaders = {
        "Content-Type": "application/json",
        "Authorization": getStorage('idToken', true)
    }
    const requestOptions = {
        url:`${baseURL}v1/aad-auth/`,
        data: payload,
        headers: requestHeaders
    }
    return apiClient.post(requestOptions);
}

directorySync.getVerification = (item) => {
    const requestOptions = {
        url: `${baseURL}verify/${item}/`,
        // url: `${baseURL}verify/MjI5MzM=/`,
    }
    return apiClient.get(requestOptions);
}

directorySync.createPowerShell = (cliendId) => {
  const requestOptions = {
      url: `${baseURLOAuth}${cliendId}`,
      // url: `${baseURL}verify/MjI5MzM=/`,
  }
  return apiClient.get(requestOptions);

}

directorySync.syncAzure = (clientId) => {
  const requestOptions = {
    url: `${baseURL}list/${clientId}/`
  }
  return apiClient.get(requestOptions)
}

directorySync.RefreshDirectory = (clientId) => {
  const requestHeaders = {
    "Content-Type": "application/json",
    "Authorization": getStorage('idToken', true)
  }

  const requestOptions = {
    url: `${baseURL}reset-directory/${clientId}/`,
    headers: requestHeaders
  }
  return apiClient.post(requestOptions)
}

export default directorySync;
