// @flow
import React, { useState, type Element, type ComponentType } from 'react';
import { useNotify } from 'react-admin';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout as AppLayout, TextField, Footer } from 'components';
import cognito from '../../helpers/apis/cognito';
import { inputPatterns } from 'helpers';
import { isValidEmail } from 'helpers/utils';

const Layout: ComponentType<*> = styled.div`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: 5px solid #082f49;
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
`;

const Title: ComponentType<*> = styled.h2`
  color: #082f49;
  text-align: left;
`;

const Content: ComponentType<*> = styled.h3`
  color: #082f49;
  text-align: left;
`;


const UserForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const submit = () => {
    if (!email) return notify('Please enter an email', 'warning');
    if (!isValidEmail(email)) return notify('Email address is invalid', 'warning');

    const encodedEmail = encodeURIComponent(email);
    setLoading(true);
    cognito
      .sendForgotPasswordEmail(email)
      .then(response => {
        window.location.href = `/#login?email=${encodedEmail}`;
        notify(response.data.description);
        setLoading(false)
      })
      .catch(err => {
        notify(err.response?.data?.description??'Check your connection and try again', 'error');
        setLoading(false)
      })
    return null;
  };
     

  return (
    <AppLayout sidebar={null} background="paper" centered footer={<Footer />}>
      <Layout>
        <Form noValidate>
          <Title>Forgot Your Password?</Title>
          <Content>Please enter the email address that you registered with.</Content>
          <TextField
            required
            autoFocus
            id="email"
            name="email"
            value={email}
            label="Email"
            disabled={loading}
            type="email"
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <Button
            style={{ 'background-color': '#082f49' }}
            onClick={submit}
            disabled={loading}
            variant="contained"
            color="primary"
          >
            Submit
            {loading && <CircularProgress size={18} thickness={2} />}
          </Button>
        </Form>
      </Layout>
    </AppLayout>
  );
};

export default UserForgotPassword;
