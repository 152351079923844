// @flow

const phishingCampaignRecipientsList = {
  type: 'phishingCampaignRecipientsList',
  selecting: true,
  selectedAll: true,
  search: true,
  searchFields: ['name', 'email'],
  actions: [],

  cells: [
    { id: 'name', label: 'Recipient Name' },
    { id: 'email', label: 'Email' },
    { id: 'group_role', label: 'Role' },
    { id: 'tag', label: 'Tag' },
    { id: 'ess', label: 'ESS' }
  ]
};

export default phishingCampaignRecipientsList;
