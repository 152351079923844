// @flow
export { default as PagePolicies } from './PagePolicies';
export { default as PagePoliciesModal } from './PagePoliciesModal';
export { default as PagePolicyInfoModal } from './PagePolicyInfoModal';
export { default as PageDarkWebMonitoring } from './PageDarkWebMonitoring';
export { default as PageDocuments } from './PageDocuments';
export { default as PageDocumentsServiceProviderModal } from './PageDocumentsServiceProviderModal';
export { default as PageDocumentsServiceProviderInfoModal } from './PageDocumentsServiceProviderInfoModal';
export { default as PageDocumentsModal } from './PageDocumentsModal';
export { default as PageDocumentsInfoModal } from './PageDocumentsInfoModal';
export { default as PageSRA } from './PageSRA';
export { default as PagePushNotifications } from './PagePushNotifications';
export { default as PageTraining } from './PageTraining';
export { default as PageWorkPlan } from './PageWorkPlan';
export { default as AdminCapabilities } from './adminCapabilities/AdminCapabilities';
export { default as AdminCapabilitiesNotes } from './adminCapabilities/AdminCapabilitiesNotes';