import { Box, CircularProgress } from '@material-ui/core';
import { ButtonSubmit } from 'components';
import { getStorage } from 'helpers';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import { useNotify } from 'ra-core';
import React, { useState } from 'react';
import styled from 'styled-components';

export const ButtonSubmitStyled = styled(ButtonSubmit)`
  height: 40px;
`;

function TestMessageButton({ state, setActionLoading }) {
  const notify = useNotify();

  const [testLoading, setTestLoading] = useState(false);

  const handleTestMessage = async () => {
    const subscriptionId = state?.subscription?.id;
    if (!subscriptionId) return;

    setActionLoading(true);
    setTestLoading(true);

    pushNotifications
      .testPushNotificationsMessage(subscriptionId, {
        partner_id: getStorage('partnerId', true),
        user_id: getStorage('userId', true),
        client_id: getStorage('clientId', true),
        user_email: getStorage('email', true),
        use_custom_message: state.subscription.use_custom_message,
        custom_message_subject: state.subscription.custom_message_subject,
        custom_message_body: state.subscription.custom_message_body,
        subscription_id: subscriptionId
      })
      .then(res => {
        notify('The test message has been sent', { type: 'success' });
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => {
        setTestLoading(false);
        setActionLoading(false);
      });
  };

  return (
    <Box ml={3}>
      <ButtonSubmitStyled
        onClick={handleTestMessage}
        disabled={
          testLoading ||
          (state.subscription.use_custom_message &&
            (!state.subscription.custom_message_body ||
              !state.subscription.custom_message_subject ||
              state.subscription.custom_message_body.trim() === ('' || '<p><br></p>') ||
              state.subscription.custom_message_subject.trim() === ''))
        }
      >
        {testLoading && (
          <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
        )}
        Test Message
      </ButtonSubmitStyled>
    </Box>
  );
}

export default TestMessageButton;
