// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Clock = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 25 25" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.38154 5.38154 0 12 0C18.6185 0 24 5.38154 24 12C24 18.6185 18.6185 24 12 24C5.38154 24 0 18.6185 0 12ZM1.84615 12C1.84615 17.6031 6.39692 22.1538 12 22.1538C17.6031 22.1538 22.1538 17.6031 22.1538 12C22.1538 6.39692 17.6031 1.84615 12 1.84615C6.39692 1.84615 1.84615 6.39692 1.84615 12ZM12.9691 6.88615V11.6215L16.3292 14.9815C16.6892 15.3415 16.6892 15.9231 16.3292 16.2831C16.1445 16.4585 15.9138 16.5508 15.6738 16.5508C15.4338 16.5508 15.203 16.4677 15.0184 16.2831L11.3815 12.6461C11.1876 12.4523 11.1045 12.1938 11.123 11.9446V11.9354V6.88615C11.123 6.37846 11.5384 5.96307 12.0461 5.96307C12.5538 5.96307 12.9691 6.37846 12.9691 6.88615Z" />
  </SvgIconStyled>
);

export default Clock;
