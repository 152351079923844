import React from 'react';
import { makeStyles } from '@material-ui/core';
import { QuizVideoButton, QuizVideoButtons } from '../../mobile/styled';
import Arrow from 'components/icons/Arrow';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '22px 0px 7px',
    textAlign: 'center',
    '& > div': {
      display: 'inline-flex',
      '& > div': {
        margin: '0 25px'
      }
    }
  }
}));

const Navigation = ({ record, handleChangeTrainingId }) => {
  const classes = useStyles();
  const { next_id, previous_id } = record;

  return (
    <div className={classes.container}>
      <QuizVideoButtons>
        <QuizVideoButton disabled={!previous_id} onClick={!previous_id ? () => {} : () => handleChangeTrainingId(previous_id)}>
          <Arrow className={"prev-button quiz-paginate-buttons"}/>
          <span>Prev</span>
        </QuizVideoButton>
        <QuizVideoButton disabled={!next_id} onClick={!next_id ? () => {} : () => handleChangeTrainingId(next_id)}>
          <span>Next</span>
          <Arrow className={"next-button quiz-paginate-buttons"}/>
        </QuizVideoButton>
      </QuizVideoButtons>
    </div>
  )
}

export default  React.memo(Navigation);
