import axios from 'axios';
import {getRequestOptions} from './apiClientUtils';

const apiClient = {};

apiClient.get = (options) => {
    const requestOptions = getRequestOptions(options);
    return axios.get(options.url,requestOptions);
}

apiClient.post = (options = {}) => {
    const requestOptions = getRequestOptions(options);
    return axios.post(options.url,options.data, requestOptions);
}

apiClient.delete = (options) => {
    return axios.delete(options.url,{...options});
}

apiClient.put = (options) => {
    const requestOptions = getRequestOptions(options);
    return axios.put(options.url,options.data,requestOptions);
}

apiClient.patch = (options) => {
  const requestOptions = getRequestOptions(options);
  return axios.patch(options.url,options.data,requestOptions);
}

export default apiClient;
