// @flow
// todo: Remove file
import React, { Children } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { translate, REDUX_FORM_NAME } from 'ra-core';
import { Error } from 'components';
import type { Element } from 'react';
import { FormInput, Toolbar } from 'react-admin';
import { CardContentInner } from './ComponentTypes';

type SimpleFormType = {
  anyTouched: any,
  asyncValidate: any,
  asyncValidating: any,
  autofill: Function,
  basePath: string,
  blur: Function,
  buttonFullWidth?: boolean,
  change: Function,
  children: Element<*>,
  className: string,
  clearAsyncError: Function,
  clearFields: Function,
  clearSubmit: any,
  clearSubmitErrors: Function,
  defaultValue: Object | Function,
  destroy: Function,
  dirty: boolean,
  dispatch: Function,
  handleSubmit: Function, // passed by redux-form
  initialize: Function,
  initialized: boolean,
  initialValues: Function,
  invalid: boolean,
  isForm?: boolean,
  pristine: boolean,
  pure: boolean,
  record: Object,
  resetSection: Object,
  reset: Function,
  resource: string,
  redirect: string | boolean | Function,
  save: Function, // the handler defined in the parent, which triggers the REST submission
  saving: Object | boolean,
  submit: Function,
  submitFailed: boolean,
  submitSucceeded: boolean,
  submitting: boolean,
  submitOnEnter?: boolean,
  toolbar?: Element<*>,
  touch: Function,
  translate: Function,
  triggerSubmit: any,
  undoable: boolean,
  untouch: Function,
  valid: boolean,
  validate: Function,
  version: number | string,
  ...
};

const Wrapper = ({ isForm, children, ...props }: { isForm: boolean, children: Element<*>}) => {
  return isForm ? <form {...props}>{children}</form> : <div {...props}>{children}</div>;
};

const SimpleForm = ({
  anyTouched,
  asyncValidate,
  asyncValidating,
  autofill,
  basePath,
  blur,
  buttonFullWidth,
  change,
  children,
  className,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dispatch,
  handleSubmit,
  initialize,
  initialized,
  initialValues,
  invalid,
  isForm = true,
  pristine,
  pure,
  record,
  redirect,
  reset,
  resetSection,
  resource,
  save,
  saving,
  submit,
  submitFailed,
  submitSucceeded,
  submitting,
  submitOnEnter,
  undoable,
  toolbar,
  touch,
  translate: trans,
  triggerSubmit,
  untouch,
  valid,
  validate,
  version,
  ...props
}: SimpleFormType) => {
  const handleSubmitWithRedirect = () => handleSubmit(values => save(values, redirect));
  return (
    <Wrapper isForm={isForm} className={classnames('simple-form', className)} {...(props: $Rest<SimpleFormType, any>)}>
      <Error />
      <CardContentInner key={version}>
        {Children.map(children, input => (
          <FormInput basePath={basePath} input={input} record={record} resource={resource} />
        ))}
      </CardContentInner>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
          buttonFullWidth
        })}
    </Wrapper>
  );
};

SimpleForm.defaultProps = {
  buttonFullWidth: false,
  isForm: true,
  submitOnEnter: true,
  toolbar: <Toolbar />
};

const enhance = compose(
  connect((state, props) => {
    return {
      form: props.form || REDUX_FORM_NAME,
      saving: props.saving || state.admin.saving
    };
  }),
  translate, // Must be before reduxForm so that it can be used in validation
  reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
);

export default enhance(SimpleForm);
