// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Mention = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M21.2136 1H2.78637C2.04738 1 1.33866 1.28057 0.81611 1.77999C0.293564 2.27941 0 2.95678 0 3.66306V17.582C0.0121836 18.2806 0.311117 18.9466 0.832349 19.4365C1.35358 19.9263 2.05536 20.2008 2.78637 20.2007H14.3684L16.3746 23.1922C16.5427 23.4393 16.7724 23.6426 17.0432 23.7836C17.314 23.9246 17.6173 23.999 17.9257 24C18.2356 24.0005 18.5408 23.9268 18.8133 23.7857C19.0858 23.6446 19.3171 23.4406 19.486 23.1922L21.613 20.1741C21.8763 20.1439 22.1332 20.0751 22.3746 19.9699C22.7009 19.8353 22.9957 19.6394 23.2413 19.3939C23.487 19.1484 23.6787 18.8583 23.8049 18.5407C23.9354 18.2428 24.0017 17.9228 24 17.5998V3.66306C24 2.95678 23.7064 2.27941 23.1839 1.77999C22.6613 1.28057 21.9526 1 21.2136 1V1ZM22.1424 17.582C22.1467 17.5995 22.1467 17.6178 22.1424 17.6353C22.1262 17.8341 22.0404 18.0219 21.8989 18.1685C21.7573 18.315 21.5683 18.4118 21.3622 18.4431C20.647 18.4431 20.7028 18.3365 20.3498 18.7981L17.935 22.2069L15.5851 18.7538C15.3158 18.3632 15.1393 18.4875 14.3498 18.4697H2.78637C2.54004 18.4697 2.3038 18.3762 2.12962 18.2097C1.95544 18.0432 1.85758 17.8174 1.85758 17.582V3.66306C1.85758 3.42763 1.95544 3.20185 2.12962 3.03537C2.3038 2.8689 2.54004 2.77538 2.78637 2.77538H21.2136C21.4599 2.77538 21.6962 2.8689 21.8703 3.03537C22.0445 3.20185 22.1424 3.42763 22.1424 3.66306V17.582Z" />
    <path d="M9.72538 12.2368C9.84421 12.3437 9.97493 12.4507 10.1175 12.5457C10.2601 12.6408 10.4027 12.724 10.5572 12.8071C10.5928 12.8309 10.6285 12.8428 10.6641 12.8666C10.7236 12.8903 10.783 12.9141 10.8424 12.9379C10.878 12.9497 10.9256 12.9735 10.9612 12.9854C11.0444 13.021 11.1395 13.0567 11.2345 13.0805C11.3652 13.1161 11.5078 13.1518 11.6504 13.1874C11.7455 13.2112 11.8406 13.2231 11.9356 13.2349C11.9832 13.2468 12.0307 13.2468 12.0901 13.2587C12.2446 13.2706 12.3991 13.2825 12.5654 13.2825C12.7793 13.2825 12.9695 13.2706 13.1715 13.2468C13.1834 13.2468 13.1834 13.2468 13.1952 13.2468C13.3973 13.2231 13.5755 13.1874 13.7656 13.1399C13.8013 13.128 13.8488 13.1161 13.8845 13.1042C13.9439 13.0805 14.0152 13.0686 14.0746 13.0448C14.134 13.021 14.1934 12.9973 14.2528 12.9735C14.3241 12.9379 14.4073 12.9141 14.4786 12.8784C14.5024 12.8428 14.538 12.819 14.5737 12.7953C14.7163 12.724 14.847 12.6408 14.9777 12.5457C15.1084 12.4507 15.2272 12.3675 15.3342 12.2605C16.5819 12.8428 17.4494 14.1024 17.4494 15.564C17.4494 15.8136 17.2474 16.0156 16.9978 16.0156H8.00234C7.75279 16.0156 7.55078 15.8136 7.55078 15.564C7.55078 14.0786 8.44201 12.8071 9.72538 12.2368Z" />
    <path d="M12.5058 11.3218C14.3171 11.3218 15.7855 9.90661 15.7855 8.1609C15.7855 6.41518 14.3171 5 12.5058 5C10.6945 5 9.22607 6.41518 9.22607 8.1609C9.22607 9.90661 10.6945 11.3218 12.5058 11.3218Z" />
  </SvgIconStyled>
);

export default Mention;
