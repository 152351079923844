import React from "react"
import Arrow from '../../icons/Arrow';
import { Filter } from '../../icons';
import { HeadContainer, HeadTitleContainer } from './style';
import { useMediaQuery, useTheme } from '@material-ui/core';


const HeadTitle = ({backButton, filter, onFilter = () => {}, title, onBack = () => {}, customRightComponent = null}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  if (!mobileView) {
    return null
  }

  return (
    <HeadContainer>
      <HeadTitleContainer onClick={onBack}>
        {backButton && <Arrow className={"back-button-arrow"}/>}
        <h3>{title}</h3>
      </HeadTitleContainer>
      {filter && <Filter onClick={onFilter}/>}
      {customRightComponent}
    </HeadContainer>
  )
}

export default HeadTitle
