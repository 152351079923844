/**
 * Calculates the next Thursday from a given start date or the current date if not provided.
 * @param {Date} [startDate=new Date()] The start date. Defaults to the current date if not provided.
 * @returns {Date} The next Thursday date.
 */
const getNextThursday = (startDate = new Date()) => {
  const currentDay = startDate.getDay();
  const daysCountToNextThursday = currentDay <= 4 ? 4 - currentDay : 11 - currentDay;
  return new Date(startDate.getTime() + daysCountToNextThursday * 24 * 60 * 60 * 1000);
};

export default getNextThursday;
