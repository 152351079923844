const partnersTaxExempt = {
  defaultSortValue: {},
  sort: [],
  head: [
    {
      id: 'state',
      label: 'State',
      align: 'left',
    },
    {
      id: 'signed_date',
      label: 'Signed Date',
      align: 'left',
    },
    {
      id: 'expiration_date',
      label: 'Expiration Date',
      align: 'left',
    },
    {
      label: 'Status',
      align: 'left',
    },
    {
      label: 'Actions',
      align: 'left',
    }
  ]
};

export default partnersTaxExempt;