import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Divider, Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { LoadingStyled, VideoPlayer } from 'components';
import { trackingUtils } from 'helpers';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '30px 30px 60px'
  },
  dialogHeader: {
    padding: '22px 30px',
    position: 'relative'
  },
  closeBtn: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: 'var(--colorDefault)'
  }
}));

const VideoModal = ({ open, close, title, video, loading, autoplay, trackGA }) => {
  const classes = useStyles();
  const [duration, setDuration] = useState(0);
  const [watched, setWatched] = useState(0);

  const watchedPercentage = useMemo(() => {
    const percentage = duration ? (watched / duration) * 100 : 0;
    return percentage.toFixed(0);
  }, [duration, watched]);

  const closeVideoModal = () => {
    const watchedNumber = Number(watchedPercentage);
    if (trackGA && watchedNumber) {
      trackingUtils.customEvent('watch_about_video', {
        sendUserName: true,
        sendPartnerName: true,
        percentage: watchedNumber
      });
    }
    close();
  };

  return (
    <Dialog open={open} onClose={closeVideoModal} maxWidth="md" fullWidth>
      <DialogTitle className={classes.dialogHeader}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton className={classes.closeBtn} onClick={closeVideoModal}>
          <CloseIcon style={{ fontSize: 28 }} />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {loading && !video ? (
          <LoadingStyled />
        ) : (
          <VideoPlayer
            src={`${video}?clientBandwidthHint=4.9`}
            playing={autoplay}
            controls
            width="100%"
            height="auto"
            onDuration={seconds => {
              if (trackGA) setDuration(seconds);
            }}
            onProgress={({ playedSeconds }) => {
              if (trackGA) setWatched(playedSeconds);
            }}
            onEnded={() => {
              if (trackGA) setWatched(duration);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

VideoModal.defaultProps = {
  loading: false,
  autoplay: false,
  trackGA: false
};

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  autoplay: PropTypes.bool,
  trackGA: PropTypes.bool
};

export default VideoModal;
