// @flow
import React, { memo, type Element, useState, useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from './ComponentTypes';

type Props = Object;

const DateField = ({ required, value, onChange, format, error, helperText, ...props }: Props): Element<*> => {
  const [newError, setNewError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let date =
    value && value !== '' ? new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60000) : null;
  const [newValue, setValue] = useState(date);
  const isOnchange = typeof onChange === 'function';
  const onDateChange = e => {
    let date = new Date(e);
    let dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    if (isOnchange) onChange(dateString);
    setValue(e);
  };

  const resetError = () => {
    setNewError(false);
    setErrorMessage('');
  };

  useEffect(() => {
    if (value) resetError();
  }, [value]);

  const handleInvalid = e => {
    e.preventDefault();
    setNewError(!e.target.validity.valid);
    setErrorMessage(e.target.validationMessage);
  };

  const handleError = msg => {
    if (msg) {
      setNewError(true);
      setErrorMessage(msg);
    } else {
      resetError();
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...props}
        autoOk
        inputVariant="outlined"
        margin="none"
        value={newValue}
        onChange={onDateChange}
        InputLabelProps={{ classes: { focused: 'field-focused' } }}
        format={format === 'year' ? 'yyyy' : 'yyyy-MM-dd'}
        placeholder="yyyy-MM-dd"
        views={format === 'year' ? ['year'] : ['date']}
        error={error || newError}
        helperText={helperText || errorMessage}
        onError={handleError}
      />
      <input required={required} value={newValue} style={{ display: 'none' }} onInvalid={handleInvalid} />
    </MuiPickersUtilsProvider>
  );
};

export default memo<Props>(DateField);
