// @flow
import { ContentAdmin as ContentAdminIcon } from 'components/icons';
import ContentAdminList from "./ContentAdminList"

export default {
  name: 'contentadmin',
  icon: ContentAdminIcon,
  list: ContentAdminList,
  edit: ContentAdminList,
  options: {
    label: 'Content Admin',
    hasMobileSublist: false
  }
}
