// @flow

import dataOptions from './dataOptions';

type dataQueryNameTypes = {
  app: string,
  tab: string | false,
  item?: string,
  params?: Object
};

function dataQueryName({ app, tab, item, params }: dataQueryNameTypes): Array<string> {
  const { params: obj } = dataOptions({ app, tab, item, params, method: 'GET' });
  const serial = Object.entries(obj)
    .map(([key, val]) => `${key}=${String(val)}`)
    .join('&');
  const qn = tab ? [app, tab] : [app];
  if (item) qn.push(item);
  if (serial) qn.push(serial);
  return qn;
}

export default dataQueryName;
