import React, { createContext, useContext } from 'react';

const PreassessmentWrapperContext = createContext(null)

export const PreassessmentWrapperProvider = ({ children, value }) => {
  return (
    <PreassessmentWrapperContext.Provider value={value}>
      {children}
    </PreassessmentWrapperContext.Provider>
  )
}

export const usePreassessmentWrapper = () => {
  return useContext(PreassessmentWrapperContext)
}
