// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Flag = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g>
      <path d="M20.6489 16.9238C20.6652 16.7884 20.6513 16.6511 20.6081 16.5217C20.565 16.3923 20.4937 16.2741 20.3995 16.1755L14.1547 9.93072L20.3348 3.75058C20.4215 3.66317 20.4899 3.55929 20.536 3.44507C20.582 3.33084 20.6046 3.20858 20.6027 3.08545C20.6109 3.01487 20.6109 2.94357 20.6027 2.87299C20.6027 2.87299 20.6027 2.79907 20.6027 2.76212C20.5372 2.58678 20.4201 2.43548 20.2667 2.32825C20.1132 2.22102 19.9308 2.16293 19.7437 2.16167H2.91227V0.933027C2.91227 0.688023 2.81488 0.453053 2.64163 0.27981C2.46839 0.106566 2.23349 0.0092392 1.98848 0.0092392C1.74348 0.0092392 1.50858 0.106566 1.33534 0.27981C1.16209 0.453053 1.0647 0.688023 1.0647 0.933027V23.1039C1.0647 23.3489 1.16209 23.5839 1.33534 23.7571C1.50858 23.9304 1.74348 24.0277 1.98848 24.0277C2.23349 24.0277 2.46839 23.9304 2.64163 23.7571C2.81488 23.5839 2.91227 23.3489 2.91227 23.1039V18.06H19.8269C20.0719 18.06 20.3068 17.9627 20.48 17.7895C20.6533 17.6162 20.7507 17.3813 20.7507 17.1363C20.7299 17.0599 20.6954 16.9879 20.6489 16.9238ZM2.84754 3.99076H17.4712L12.2149 9.24711C12.0822 9.38216 11.9941 9.55472 11.9627 9.74143C11.9313 9.92814 11.9579 10.12 12.0392 10.291C12.084 10.4192 12.1566 10.5359 12.2516 10.6328L17.7944 16.1755H2.84754V3.99076Z" />
    </g>
  </SvgIconStyled>
);

export default Flag;
