import React, { useEffect } from 'react';
import { calc_diff } from './utils';

const KEY_STORAGE = 'app_version';
// const local_url = 'http://127.0.0.1:5000/cache';
const base_url = '';

const CacheHandler = ({ fileName = 'meta.json', duration }) => {
  useEffect(() => {
    const fetchCacheTimeout = setInterval(() => handleCache(), duration);
    return () => {
      clearInterval(fetchCacheTimeout);
    };
  }, []);

  const forceReload = () => {
    const lastUpdated = sessionStorage.getItem('lastUpdated');
    //safer, just in case
    if (!lastUpdated || calc_diff(Date.now(), lastUpdated) > 10) {
      window.location.reload();
    }
  };

  function handleCache() {
    fetch(`${base_url}/${fileName}`, {
      cache: 'no-store'
    })
      .then(response => response.json())
      .then(meta => {
        const currentVersion = localStorage.getItem(KEY_STORAGE);

        if (!currentVersion) localStorage.setItem(KEY_STORAGE, meta.version);
        if (currentVersion && currentVersion !== meta.version) {
          forceReload();
          sessionStorage.setItem('lastUpdated', Date.now());
          localStorage.setItem(KEY_STORAGE, meta.version);
        }
      })
      .catch(e => {
        console.log(`can't fetch meta.json file`);
      });
  }
  return <></>;
};

export default CacheHandler;
