// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const OtherPolicy = (props: Props) => (
  <SvgIconStyled width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1589 0.36866L21.1442 4.97807C21.9974 5.47889 22.5353 6.39706 22.5353 7.38942C22.5353 14.067 18.9369 20.2902 13.1589 23.629C12.7322 23.8794 12.25 24 11.7677 24C11.2854 24 10.8031 23.8794 10.3765 23.629C4.58921 20.2902 1 14.067 1 7.38942C1 6.39706 1.53792 5.46962 2.39117 4.97807L10.3765 0.36866C11.239 -0.122887 12.3056 -0.122887 13.1589 0.36866ZM11.304 22.0245C11.5915 22.1915 11.9439 22.1915 12.2314 22.0245C17.4437 19.0103 20.6805 13.4086 20.6805 7.39871C20.6805 7.06483 20.5042 6.75876 20.2167 6.59182L12.2314 1.98241C12.0923 1.89894 11.9254 1.86185 11.7677 1.86185C11.61 1.86185 11.4524 1.89894 11.304 1.98241L3.31862 6.59182C3.03111 6.75876 2.85489 7.06483 2.85489 7.39871C2.85489 13.4086 6.09173 19.0196 11.304 22.0245ZM12.6951 11.0713H16.8964C17.4065 11.0713 17.8238 11.4887 17.8238 11.9988C17.8238 12.5089 17.4065 12.9262 16.8964 12.9262H12.6951V17.1276C12.6951 17.6377 12.2778 18.055 11.7677 18.055C11.2576 18.055 10.8402 17.6377 10.8402 17.1276V12.9262H6.63887C6.12878 12.9262 5.71143 12.5089 5.71143 11.9988C5.71143 11.4887 6.12878 11.0713 6.63887 11.0713H10.8402V6.87001C10.8402 6.35992 11.2576 5.94257 11.7677 5.94257C12.2778 5.94257 12.6951 6.35992 12.6951 6.87001V11.0713Z"
    />
  </SvgIconStyled>
);

export default OtherPolicy;
