import { ALWAYS_VISIBLE_BULK_ACTIONS, BULK_ACTIONS, BULK_ACTIONS_FIELDS, TABLE_VIEWS } from './constants';
import { columnDependencyMap, generateSettings, settingsByHeaderName } from './settings';

export const getUpdatedFilterItems = filterItems => {
  const items = filterItems.reduce((filters, item) => {
    if (!item.value?.length) {
      return filters;
    }
    return [...filters, { field: item.field, value: item.value, operator: item.operator }];
  }, []);

  return items;
};

export const getApiFields = tableColumns => {
  const apiFields = tableColumns.flatMap(column => columnDependencyMap[column]);
  const uniqueApiFields = [...new Set([...BULK_ACTIONS_FIELDS, ...apiFields])];
  return uniqueApiFields;
};

export const getDefaultColumns = tableView => TABLE_VIEWS[tableView]?.defaultColumns;
export const getViewColumns = tableView => TABLE_VIEWS[tableView]?.columns;

export const getVisibilitySettings = (columnProps, columnVisibilityModel) => {
  const visibilitySettings = columnProps
    .filter(col => columnVisibilityModel[col.field] !== false)
    .reduce(
      (acc, col) => {
        const { field, headerName } = col;
        const relatedBulkActions = settingsByHeaderName[headerName].relatedBulkActions;
        acc.visibleColumns.push(headerName);
        acc.visibleFieldsObj[field] = '';
        if (relatedBulkActions) acc.visibleBulkActions.push(...relatedBulkActions);
        return acc;
      },
      {
        visibleColumns: [],
        visibleFieldsObj: {},
        visibleBulkActions: []
      }
    );
  visibilitySettings.visibleBulkActions.push(...ALWAYS_VISIBLE_BULK_ACTIONS);

  return visibilitySettings;
};

export const getColumnProps = (tableColumns, cellsProps) => {
  // Generate settings
  const settings = generateSettings(cellsProps);
  const filteredColumns = tableColumns.filter(column => {
    return !settings[column]?.hidden;
  });

  const columnProps = filteredColumns.map(column => settings[column]?.columnProps);

  return columnProps;
};

export const getDefaultColumnVisibilityModel = tableView => {
  const { columns, defaultColumns } = TABLE_VIEWS[tableView];
  return columns.reduce((acc, column) => {
    if (!defaultColumns.includes(column)) {
      const field = settingsByHeaderName[column]?.columnProps?.field;
      acc[field] = false;
    }
    return acc;
  }, {});
};

export const isNA = value => value === 'N/A';

export const castStringToNumber = value => {
  if (typeof value !== 'string') return value;
  const casted = Number(value);
  if (isNaN(casted)) return value;
  return casted;
};

export const processRows = rows => {
  // cast all string values that include only digits to numbers
  return rows.map(row =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        return [key, castStringToNumber(value)];
      })
    )
  );
};

export const updateSelectedRowsObj = (selectedRowsObj, selectedClientIds, action) => {
  const updated = { ...selectedRowsObj };
  if (action === BULK_ACTIONS.DELETE_CLIENT) {
    selectedClientIds.forEach(id => {
      delete updated[id];
    });
  } else if (action === BULK_ACTIONS.ENABLE_CLIENT) {
    selectedClientIds.forEach(id => {
      updated[id] = { ...updated[id], active: true };
    });
  } else if (action === BULK_ACTIONS.DISABLE_CLIENT) {
    selectedClientIds.forEach(id => {
      updated[id] = { ...updated[id], active: false };
    });
  }
  return updated;
};
