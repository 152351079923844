import { Quill } from 'react-quill';

let Blockquote = Quill.import('formats/blockquote');
let Codeblock = Quill.import('formats/code-block');

class BlockquoteInlineStyle extends Blockquote {
  static create() {
    BlockquoteInlineStyle.blotName = 'blockquote';
    BlockquoteInlineStyle.tagName = 'blockquote';
    let node = super.create();
    node.setAttribute('style', 'border-left: 4px solid #ccc;margin-bottom: 5px; margin-top: 5px; padding-left: 16px;');
    return node;
  }
}
class CodeblockInlineStyle extends Codeblock {
  static create() {
    CodeblockInlineStyle.tagName = 'pre';
    let node = super.create();
    node.removeAttribute('Class');
    node.setAttribute('Style', 'margin: 5px 0px; padding: 7px 7px; border: 2px solid #D3D3D3; border-radius: 5px;');

    return node;
  }
}

export { BlockquoteInlineStyle, CodeblockInlineStyle };
