// @flow
import React, { useEffect } from 'react';
import { TableContainer, TableToolbarWrapper, TableWrapper, Table, TableContentWrapper, TableTitle } from './style';
import { CustomTableBody, CustomTableHead } from './components';
import { TableContextProvider } from './context';
import useTable from './hooks/useTable';
import CustomTablePagination from '../table/pagination/CustomTablePagination';

type Props = {
  source: string,
  onSort: Function,
  onChangePage: Function,
  onChangePerPage: Function,
  onLoad: Function,
  onSelectAll: Function,
  onSelectRow: Function,
  onClickRow: Function,
  selectedRows: Array<string>,
  toolbar: any,
  children: Element<any>,
  total: number,
  showPagination: boolean,
  tableTitle: string
};

const TableContent = ({
  source,
  onSort = () => {},
  onChangePage = () => {},
  onChangePerPage = () => {},
  onLoad = () => {},
  onSelectAll = () => {},
  onSelectRow = () => {},
  onClickRow = () => {},
  highlightedRowId,
  selectedRows,
  toolbar,
  children,
  total,
  overallTotal,
  className,
  isSelectAll,
  showPagination = true,
  params,
  preferences,
  isFetching = false,
  loading = false,
  data,
  hideColumns,
  tableTitle = '',
  ...restProps
}: Props) => {
  // check if child tables with fragment are set or not
  const tableChildren = children?.props?.children || children;

  const tableData = useTable({
    total,
    overallTotal,
    selectedRows,
    source,
    onSort,
    onChangePage,
    onChangePerPage,
    onSelectAll,
    onSelectRow,
    isSelectAll,
    params,
    preferences,
    hideColumns
  });

  useEffect(() => {
    if (tableData) {
      onLoad({
        page: tableData.onLoadValues.page,
        perPage: tableData.onLoadValues.perPage,
        sortname: tableData.onLoadValues.sortname,
        order: tableData.onLoadValues.order
      });
    }
  }, [
    source,
    tableData?.onLoadValues?.order,
    tableData.onLoadValues.page,
    tableData?.onLoadValues?.perPage,
    tableData?.onLoadValues?.sortname
  ]);

  const { dispatch, page, perPage } = tableData;

  const handleClickRow = (rowId, isCollapsedRowClick, data) => {
    if (!isCollapsedRowClick) {
      dispatch.onClickRow(rowId);
    }
    if (tableData.tableData.highlightOnClick) {
      dispatch.highlightRow(rowId);
    }
    onClickRow(rowId, isCollapsedRowClick, data);
  };

  useEffect(() => {
    if (!highlightedRowId) {
      dispatch.highlightRow('');
    }
  }, [highlightedRowId]);

  return (
    <TableContextProvider
      value={{
        source,
        tableChildren,
        ...tableData,
        selectedRows,
        onClickRow: handleClickRow,
        isSelectAll,
        loading,
        isFetching,
        data,
        hideColumns,
        ...restProps
      }}
    >
      <TableContainer lg={12}>
        <TableWrapper className={className}>
          {tableTitle && <TableTitle>{tableTitle}</TableTitle>}
          {toolbar && <TableToolbarWrapper className={'table-toolbar'}>{toolbar}</TableToolbarWrapper>}
          <TableContentWrapper>
            <Table isFetching={!loading && isFetching}>
              <CustomTableHead className={'table-head'} />
              <CustomTableBody className={'table-body'} />
            </Table>
          </TableContentWrapper>
          {showPagination && data?.length ? (
            <CustomTablePagination
              totalCount={total || 0}
              rowsPerPage={perPage}
              page={page}
              onChangePage={(_, page) => dispatch.handleChangePage(page)}
              onChangeRowsPerPage={e => dispatch.handleChangePerPage(e.target.value)}
              isFetching={isFetching}
            />
          ) : null}
        </TableWrapper>
      </TableContainer>
    </TableContextProvider>
  );
};

export default TableContent;