import React, { useEffect, useState } from 'react';
import { useLocation } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useNotify } from 'ra-core';
import { LoadingStyled } from 'components';
import * as sra from 'helpers/apis/services/sra';
import OrganizationProfile from '../../organizationProfile/OrganizationProfile';
import { SRA_SUB_PAGES } from '../../constants';
import { useClientInfo } from '../../hooks';
import { useSRA } from '../../context/sraContext';

const EditOrganizationProfile = () => {
  const { app, tab, item } = useLocation();
  const { reportId } = useParams();
  const history = useHistory();
  const notify = useNotify();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const [state, setState] = useState(null);
  const [originalState, setOriginalState] = useState(null);
  const {
    state: { revisionId },
    clientId
  } = useSRA();
  const { isHSN, isLoadingClientInfo } = useClientInfo(clientId);

  useEffect(() => {
    if (clientId && revisionId) {
      const data = { enabled: 1 };
      sra
        .setSraEditMode(clientId, revisionId, data)
        .then(() => {
          sra.getClientOrganizationProfile(clientId).then(res => setState(res.data));
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'warning');
        });
    }
  }, [clientId, revisionId, notify]);

  const goToEditReportPage = () => {
    history.push(
      `/${app}/${tab}${item && !isSraSubPage ? `/${item}` : ''}/editSraReport${reportId ? `/${reportId}` : ''}`
    );
  };

  if (isLoadingClientInfo) return <LoadingStyled />;

  return (
    <OrganizationProfile
      isHSN={isHSN}
      clientId={clientId}
      handleClose={goToEditReportPage}
      state={state}
      setState={setState}
      originalState={originalState}
      setOriginalState={setOriginalState}
    />
  );
};

export default EditOrganizationProfile;
