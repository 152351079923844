// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Chat = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 29.7 26.2" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h29.7v26.2H0z" />
    <path d="M28.5,0H1.1C0.5,0,0,0.5,0,1.1v18.2c0,0.6,0.5,1.1,1.1,1.1h8.7h0.1l7.6,5.5c0.2,0.1,0.4,0.2,0.7,0.2  c0.2,0,0.4,0,0.5-0.1c0.4-0.2,0.6-0.6,0.6-1v-4.4c0-0.1,0.1-0.2,0.2-0.2h9c0.6,0,1.1-0.5,1.1-1.1V1.1C29.7,0.5,29.1,0,28.5,0z   M27.4,17.2c0,0.6-0.5,1-1,1h-7.9c-0.7,0-1.3,0.6-1.3,1.3v3c0,0.1-0.1,0.2-0.2,0.1l-5.9-4.2c-0.2-0.1-0.4-0.2-0.7-0.2H3.2  c-0.5,0-0.9-0.4-0.9-0.9v-14c0-0.6,0.5-1,1-1h23.2c0.5,0,0.9,0.4,0.9,0.9V17.2z" />
  </SvgIconStyled>
);

export default Chat;
