import React from 'react';
import { mobilePagesWhiteList } from 'conf';
// import { isMobile } from 'react-device-detect';

function useMobileAccess({ app, access }) {
  const allowedTabs = mobilePagesWhiteList[app] || [];

  return allowedTabs.reduce((acc, tabname) => {
    acc[tabname] = access[tabname] || false;
    return acc;
  }, {});
}

export default useMobileAccess;
