// @flow
import Values from 'values.js';
import { getStorage } from './storage';
import emojiRegex from 'emoji-regex';
import { useAccess, useId, useLocation } from '../hooks';
import { useHistory } from 'react-router-dom';
import {
  CONTENT_ADMIN_CAMPAIGN,
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_TAGS,
  CONTENT_ADMIN_SENDING_PROFILES,
  CONTENT_ADMIN_SCENARIO,
  CONTENT_ADMIN_PICKLIST_DOMAINS
} from '../apps/contentadmin/constants';
import { rolesPriority } from './index';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const idMap = {
  myDashboard: {
    default: 'userId'
  },
  myCompany: {
    default: 'clientId'
  },
  phishing: {
    default: 'clientId'
  },
  clients: {
    list: 'partnerId',
    default: 'clientId'
  },
  partnerProfile: {
    default: 'partnerId'
  },
  user: {
    default: '',
    profile: 'userId',
    contactUs: 'userId'
  }
};

const percentsPalleteDefault = {
  tint: [5, 15, 95],
  shade: [30, 50, 85]
};

const EXECUTABLE_FILE_EXTENSIONS = [
  'ACTION',
  'APK',
  'APP',
  'BAT',
  'BIN',
  'CMD',
  'COM',
  'COMMAND',
  'CPL',
  'CSH',
  'EXE',
  'GADGET',
  'INF1',
  'INS',
  'INX',
  'IPA',
  'ISU',
  'JOB',
  'JSE',
  'KSH',
  'LNK',
  'MSC',
  'MSI',
  'MSP',
  'MST',
  'OSX',
  'OUT',
  'PAF',
  'PIF',
  'PRG',
  'PS1',
  'REG',
  'RGS',
  'RUN',
  'SCT',
  'SHB',
  'SHS',
  'U3P',
  'VB',
  'VBE',
  'VBS',
  'VBSCRIPT',
  'WORKFLOW',
  'WS',
  'WSF',
  '0XE',
  '73K',
  '89K',
  'A6P',
  'AC',
  'ACC',
  'ACR',
  'ACTM',
  'AHK',
  'AIR',
  'APP',
  'ARSCRIPT',
  'AS',
  'ASB',
  'AWK',
  'AZW2',
  'BEAM',
  'BTM',
  'CEL',
  'CELX',
  'CHM',
  'COF',
  'CRT',
  'DEK',
  'DLD',
  'DMC',
  'DOCM',
  'DOTM',
  'DXL',
  'EAR',
  'EBM',
  'EBS',
  'EBS2',
  'ECF',
  'EHAM',
  'ELF',
  'ES',
  'EX4',
  'EXOPC',
  'EZS',
  'FAS',
  'FKY',
  'FPI',
  'FRS',
  'FXP',
  'GS',
  'HAM',
  'HMS',
  'HPF',
  'HTA',
  'IIM',
  'IPF',
  'ISP',
  'JAR',
  'JS',
  'JSX',
  'KIX',
  'LO',
  'LS',
  'MAM',
  'MCR',
  'MEL',
  'MPX',
  'MRC',
  'MS',
  'MS',
  'MXE',
  'NEXE',
  'OBS',
  'ORE',
  'OTM',
  'PEX',
  'PLX',
  'POTM',
  'PPAM',
  'PPSM',
  'PPTM',
  'PRC',
  'PVD',
  'PWC',
  'PYC',
  'PYO',
  'QPX',
  'RBX',
  'ROX',
  'RPJ',
  'S2A',
  'SBS',
  'SCA',
  'SCAR',
  'SCB',
  'SCR',
  'SCRIPT',
  'SMM',
  'SPR',
  'TCP',
  'THM',
  'TLB',
  'TMS',
  'UDF',
  'UPX',
  'URL',
  'VLX',
  'VPM',
  'WCM',
  'WIDGET',
  'WIZ',
  'WPK',
  'WPM',
  'XAP',
  'XBAP',
  'XLAM',
  'XLM',
  'XLSM',
  'XLTM',
  'XQT',
  'XYS',
  'ZL9'
];

const HIPAA_PRODUCT_NAMES = [
  'HIPAA Compliance',
  'HIPAA BPP',
  'HIPAA Training Only',
  'Partner HIPAA Compliance Program',
  'EVA MD'
];

// Checking if mask mode is enabled
export function isMaskModeEnabled(resource: string): boolean {
  const maskMode = getStorage('maskMode', true);
  return resource === 'clients' && maskMode;
}

// Get type of id.
export function getId(key: 'partnerId' | 'userId' | 'clientId' | 'maskPartnerId'): string {
  return getStorage(key, true);
}

// Get type of id based on the resource.
export function getIdByResource(resource: string, tab?: string | false = false): string {
  const m = idMap[resource.split('/')[0]];
  const isTab = Object.prototype.hasOwnProperty.call(m, tab);
  const key = m[isTab ? tab : 'default'];
  const newKeyId = isMaskModeEnabled(resource) && key === 'partnerId' ? 'maskPartnerId' : key;
  return getId(newKeyId);
}

// Make sure the value is array.
export function toArray(item: any): Array<*> {
  if (Array.isArray(item)) return item.length === 0 ? [] : item;
  return new Array(item);
}

export function validateVariable(variable: any, object?: Object | boolean = false): boolean {
  let validation =
    typeof variable === 'undefined' ||
    variable === null ||
    variable === 0 ||
    variable === '' ||
    (Array.isArray(variable) && variable.length > 0);
  if (object !== false && typeof object === 'object' && object !== null) {
    validation = !Object.prototype.hasOwnProperty.call(object, variable);
  }

  return !validation;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Transform camel case string to Capitalised string.
export function camelCaseToString(camelCase: string): string {
  return camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();
}

export function snakeCaseToString(snakeCase: string): string {
  return snakeCase
    .replace('_', ' ')
    .replace(' bg', ' background')
    .replace(/^./, match => match.toUpperCase())
    .trim();
}

export function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}

export function snakeToCamelTitle(str) {
  str = str.replace(/(?![^(]*\))([A-Z])/g, ' $1');
  return str
    .replace(/^./, match => match.toUpperCase())
    .replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', ' ')
        .replace('_', ' ')
    );
}


export function getRecord(state: Object, { record, id }: { record: Object | null, id: string | number }): Object {
  if (record === null || typeof record === 'undefined') return null;
  return id !== 0 && Object.hasOwnProperty.call(record, id) ? record[id] : record;
}

export function getActiveTab(tab: Object): string {
  return Object.keys(tab).filter(t => tab[t].active)[0];
}

export function getActiveAvailableTab(tabs, orderedTabs) {
  if (!tabs || !orderedTabs) {
    return '';
  }
  
  const list = Object.keys(orderedTabs).map(item => tabs[item] && item);
  const tab = list.find(item => item);
  return tab || '';
}

export function itemExist(object: Object | null, item: string): boolean {
  return object && Object.hasOwnProperty.call(object, item);
}

export function strClean(string: string) {
  return (
    string &&
    string
      .replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
      .replace(/(?:&nbsp;)/g, '')
      .replace(/(?:&#39;)/g, "'")
      .replace(/(?:&rsquo;)/g, "'")
      .replace(/(?:&amp;)/g, '&')
  );
}

export function getMonthFromString(month: string) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months.indexOf(month) + 1;
}

export function isBase64(string: string) {
  const exclude = ['list', 'clients', 'dashboard', 'company', 'phishing', 'training'];
  if (string === '' || string.trim() === '' || exclude.includes(string)) return false;
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(string);
}

export function ChartColorPercent(number: number, invert: boolean, colorType1: boolean, percentType: boolean) {
  if (percentType && invert) {
    if (number === 0) return `none`;
    if (number > 0 && number <= 50) return `Success`;
    if (number >= 51 && number <= 75) return `Warning`;
    return `Danger`;
  }

  if (colorType1) {
    if (number === 0) return `none`;
    if (number > 0 && number <= 50) return `Danger`;
    if (number >= 51 && number <= 75) return `Warning`;
    return `Success`;
  }

  if (invert) {
    if (number === 0) return `none`;
    if (number > 0 && number <= 5) return `Success`;
    if (number > 5.1 && number <= 10) return `Warning`;
    return `Danger`;
  }

  if (number === 0) return `none`;
  if (number > 0 && number <= 62.375) return `Danger`;
  if (number > 62.376 && number <= 78.625) return `Warning`;
  return `Success`;
}

export function dataBreachColorIndicator(value) {
  if (value === 0) return 'none';
  if (value < 6) return 'Success';
  if (value < 11) return 'Warning';
  return 'Danger';
}

export function hasKey(obj: Object, key: string) {
  return typeof obj === `object` ? Object.prototype.hasOwnProperty.call(obj, key) : null;
}

export function isObjectEmpty(obj: Object) {
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}

const getColors = (color: Array<*>, type: string, percents: Object) => {
  return color
    .filter(row => row.type === type)
    .filter(
      row => row.weight === percents[type][0] || row.weight === percents[type][1] || row.weight === percents[type][2]
    )
    .map(row => row.hex);
};

export function palleteColors(color: string, weight?: number = 5, percents?: Object = percentsPalleteDefault) {
  if (/^#([0-9a-f]{6}|[0-9a-f]{3})$/i.test(color)) {
    const pallete = new Values(color).all(weight);
    const shades = getColors(pallete, 'shade', percents);
    const tints = getColors(pallete, 'tint', percents);
    const colors = [...shades, ...tints.reverse()];

    return colors;
  }
  return [];
}

export function isHexColor(color: string): boolean {
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    return true;
  }
  return false;
}

export function validURL(input: string, limit = null): boolean {
  if (limit && input.length > limit) return false;
  const re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,253}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
  return re.test(input);
}

export function redirectValidURL(input: string, limit = null): boolean {
  if (limit && input.length > limit) return false;
  const re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,253}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//={}]*)$/;
  return re.test(input);
}

export function validSecureURL(input: string, limit = null): boolean {
  if (limit && input.length > limit) return false;
  const re = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,253}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
  return re.test(input);
}

export function checkIfDuplicateExists(arr, isCaseSensitive = true) {
  let newArr = [...arr];
  if (!isCaseSensitive) newArr = newArr.map(item => item.toLowerCase());
  return new Set(newArr).size !== arr.length;
}

export function isValidEmail(email: string): boolean {
  if (!email) return false;
  if (email.length > 199) return false;
  if (email.includes('"')) return false;
  const re = /^(?!_)\w([!#$%'*+\-/=?^_`{|*&()\\-`.+,/\"]?\w){0,63}@([A-Za-z0-9]+(?:[.-]?[A-Za-z0-9]+)*)\.[A-Za-z]{2,63}$/;
  return re.test(email);
}

export function isTestDomain(email: string): boolean {
  const re = /\.test$/;
  return re.test(email);
}

export function isValidEmailLocalPart(local_part) {
  const email = `${local_part}@domain.com`;
  return isValidEmail(email);
}

export function isValidDomain(input: string): boolean {
  const re = /^[^\.!#$%@'*+\-=?^_`{|*&()\\-`.+,\"]?[A-Za-z0-9]+([\.-]?[A-Za-z0-9])*(\.[A-Za-z]){1,1}[A-Za-z0-9]{1,62}$/;
  return re.test(input);
}

export function isNumber(value: string): boolean {
  if (!isNaN(parseFloat(value)) && isFinite(value)) {
    return true;
  }
  return false;
}

export function hasLeadingZeros(str: string): boolean {
  return /^0\d+$/.test(str);
}

export function removeLeadingZeros(str) {
  return hasLeadingZeros(str) ? str.replace(/^0+/, '') || '0' : str;
}

export function isHtml(str: string): boolean {
  return /<[a-z/][\s\S]*>/i.test(str);
}

export function getPreContent(str: string): string {
  return isHtml(str) ? str.replace(/\s+/g, ' ') : str;
}

export function formatAMPMForFullDate(dateValue) {
  const allDate = addTimeZone(dateValue).split(' ');
  const date = allDate[0];
  const time = allDate.slice(1).join(' ');

  return `${date} ${time}`;
}

export function addTimeZone(date) {
  const correctDate = date.replace(/-/g, '/');
  const newDate = new Date(
    new Date(correctDate).getTime() -
      new Date(correctDate).getTimezoneOffset() * 60000 -
      new Date(correctDate).getTimezoneOffset() * 60000
  ).toString();
  const formated = new Date(newDate).toISOString().split(/[T.]/gm);
  // const time = [...formated.slice(1)].join('.');
  // console.log('time', time);
  return `${formated[0]} ${formatAMPM(formated[1])}`;
}

export function dateTimeZone(date: string): string {
  if (/^\d{4}-\d{2}-\d{2}$/i.test(date)) {
    const newDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const formatDate = Intl.DateTimeFormat('en-US', {
      timeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
      // hour: 'numeric',
      // minute: 'numeric'
    }).format(newDate);

    return formatDate;
  }

  return '';
}

export const hasInternetConnection = (notify, callBack, callbackWithoutInternet) => {
  if (!navigator.onLine) {
    callbackWithoutInternet();
    notify('No Internet Connection', 'warning');
  } else {
    return callBack();
  }
};

export const inputPatterns = {
  email:
    "^[_A-Za-z0-9-!#$%&'*+/=?^_`{|}~]+(\\.[_A-Za-z0-9-!#$%&'*+/=?^_`{|}~]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
};

export const isPublicUrl = path => {
  const publicPages = [
    'signup',
    'logout',
    'redirector.html',
    'federated',
    'setPassword',
    'forgotPassword',
    'newUser',
    'MicroTrainingPublicView',
    'PreAssessment',
    'AIAssessment',
    'expiredSession',
    'bsnteamsappabout',
    'bsnteamsapptermsofuse',
    'bsnteamsappprivacy',
    'hsnteamsappabout',
    'hsnteamsapptermsofuse',
    'hsnteamsappprivacy'
  ];
  if (publicPages.indexOf(path) === -1) return false;
  else return true;
};

export const decodeId = id => {
  return id ? atob(atob(atob(id))) : id;
};

export const encodeId = id => {
  return btoa(btoa(btoa(id)));
};
// better way with pipeliness
const compose = (...fns) => arg => fns.reduce((composed, f) => f(composed), arg);
const encode = s => compose(btoa, btoa, btoa)(s);

export const strToBase64 = value => {
  // works with str, number(int, float), boolean
  if (value == null) return '';
  else if (typeof value === 'function') return null;
  return Buffer.from(value.toString()).toString('base64');
};

export const base64toFile = (url, filename) => {
  const arr = url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getCurrentTimeStamp = () => {
  return new Date().getTime();
};

export const getCurrentTime = () => {
  const currentDate = new Date();
  currentDate.setFullYear(2000);
  currentDate.setMonth(0);
  currentDate.setDate(1);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  const currentTime = currentDate.getTime();

  return currentTime;
};

export const getUrlsInsideText = str => {
  let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  return str.match(expression);
};

export const cleanTextFromHtml = text => {
  let div = document.createElement('div');
  div.innerHTML = text;
  return div.innerText;
};

export const cleanTextFromHtmlImproved = text => {
  return text
    .split(/<\/*[a-z]+(?![^>]*\/>)[^>]*>/gm)
    .filter(el => !!el)
    .join(' ')
    .replace('\n', '');
};

export const convertStyleAttr = (text, reverse) => {
  return reverse ? text.replace(/style&#61;/g, 'style=') : text.replace(/style\=/g, 'style&#61;');
};

export const toUTCfromLocal = dateTime => {
  var d = new Date(dateTime.replace('Z', ''));
  return new Date(d.toUTCString()).toISOString();
};

export const fromUTCtoLocal = dateTime => {
  var date = new Date(dateTime.replace(' ', 'T') + 'Z');
  return new Date(date).toLocaleString('sv-SE').replace(' ', 'T') + 'Z';
};

export const getCurrentDateWithTimezone = value => {
  let date = value ? new Date(value) : new Date();
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
};

export const getDate = date => {
  const completeDate = date ? new Date(date.replace(/\s/, 'T')) : '';

  const completeDateDay = completeDate
    ? completeDate.getDate() < 10
      ? '0' + completeDate.getDate()
      : completeDate.getDate()
    : '';
  const completeDateMoth = completeDate
    ? completeDate.getMonth() + 1 < 10
      ? '0' + (completeDate.getMonth() + 1)
      : completeDate.getMonth() + 1
    : '';
  const completeDateYear = completeDate ? completeDate.getFullYear() : '';

  return {
    completeDate,
    completeDateDay,
    completeDateMoth,
    completeDateYear
  };
};
export const setVariable = (name, value) => document.documentElement?.style.setProperty(`--${name}`, value);

export const setVariables = colors =>
  Object.keys(colors).map(s =>
    Object.keys(colors[s]).map(c =>
      setVariable(`color${capitalizeFirstLetter(s)}${capitalizeFirstLetter(c)}`, colors[s][c])
    )
  );

export const objectsEqual = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(p => obj1[p] === obj2[p]);

export const arrayOfObjectsEqual = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((obj, idx) => objectsEqual(obj, arr2[idx]));

export const toJson = jsonStr => JSON.parse(jsonStr);

function fallbackCopyTextToClipboard(text) {
  var el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    var editable = el.contentEditable;
    var readOnly = el.readOnly;

    // convert to editable with readonly to stop iOS keyboard opening
    el.contentEditable = true;
    el.readOnly = true;

    // create a selectable range
    var range = document.createRange();
    range.selectNodeContents(el);

    // select the range
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);

    // restore contentEditable/readOnly to original state
    el.contentEditable = editable;
    el.readOnly = readOnly;
  } else {
    el.select();
  }

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('error', err);
  }

  document.body.removeChild(el);
}

export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};

export const constructJiraTicketURL = key => {
  return `https://trustsecurenow.atlassian.net/browse/${key}`;
};

export const validateFileFormat = (filename, accept) => {
  const name = filename.toLowerCase();
  const formats = accept.replace(/\s/g, '').split(','); // [".jpeg", ".jpg", ".png"]
  const regex = new RegExp(`(${formats.join('|').replace(/\./g, '\\.')})$`);
  const isValid = regex.test(name);
  return isValid;
};

export const validateExecutableFile = filename => {
  const isExecutableFile = EXECUTABLE_FILE_EXTENSIONS.some(ext => {
    const extension = `.${ext.toLowerCase()}`;
    return filename.toLowerCase().includes(extension);
  });
  return isExecutableFile;
};

export const getTimeFromSeconds = seconds => {
  const minute = Math.floor(seconds / 60);
  const sec = seconds % 60;
  return {
    minute,
    sec
  };
};

export const getContentAdminPath = page => {
  switch (page) {
    case CONTENT_ADMIN_EMAIL_TEMPLATES: {
      return 'templates';
    }
    case CONTENT_ADMIN_LANDING_PAGES: {
      return 'landing-pages';
    }
    case CONTENT_ADMIN_SENDING_PROFILES: {
      return 'smtp';
    }
    case CONTENT_ADMIN_SCENARIO: {
      return 'scenarios';
    }
  }
};

export const getContentAdminName = page => {
  switch (page) {
    case CONTENT_ADMIN_EMAIL_TEMPLATES: {
      return 'email template ';
    }
    case CONTENT_ADMIN_LANDING_PAGES: {
      return 'landing page';
    }
    case CONTENT_ADMIN_SENDING_PROFILES: {
      return 'sending profile';
    }
    case CONTENT_ADMIN_SCENARIO: {
      return 'scenarios';
    }
  }
};

export const formatAMPM = dateValue => {
  const date = new Date(`2000-01-01T${dateValue}`);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const getPickListLabel = name => {
  switch (name) {
    case CONTENT_ADMIN_PICKLIST_COUNTRIES: {
      return 'country';
    }
    case CONTENT_ADMIN_PICKLIST_DIFFICULTIES: {
      return 'difficulty_level';
    }
    case CONTENT_ADMIN_PICKLIST_TAGS: {
      return 'tag';
    }
    case CONTENT_ADMIN_PICKLIST_DOMAINS: {
      return 'domain';
    }
  }
};

export const getContentAdminFormTitle = (page, method) => {
  const firstTitleSentence = method === 'create' ? 'Create a new' : 'Edit';
  const secondTitleSentence = getContentAdminName(page);

  return `${firstTitleSentence} ${secondTitleSentence}`;
};

export const isStrContainsEmoji = str => {
  return /[\ud000-\udfff]/gm.test(str);
};

export const emojiToUnicode = str => {
  const regex = emojiRegex();
  return str.replace(regex, match => {
    let emojiStr = '';
    let emojiStr1 = match.charCodeAt(0);
    let emojiStr2 = match.charCodeAt(1);
    if (!isNaN(emojiStr1)) emojiStr += '\\u' + emojiStr1.toString(16);
    if (!isNaN(emojiStr2)) emojiStr += '\\u' + emojiStr2.toString(16);
    return emojiStr || match;
  });
};

export const unicodeToEmoji = str => {
  const regex = /\\u[\dA-F]{4}/gi;
  return str.replace(regex, match => {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
  });
};

export const objBoolFieldsToNumber = obj => {
  Object.keys(obj).map(key => typeof obj[key] == 'boolean' && (obj[key] = +obj[key]));
  return obj;
};

export const arrayToChoices = (array, keyValue, keyLabel) => {
  if (!Array.isArray(array)) return [];
  return array.map(item => {
    return { value: item[keyValue], label: item[keyLabel] };
  });
};

export const formDataUpload = (response, file) => {
  const formData = new FormData();
  formData.append('AWSAccessKeyId', response.data.fields.AWSAccessKeyId);
  formData.append('key', response.data.fields.key);
  formData.append('policy', response.data.fields.policy);
  formData.append('signature', response.data.fields.signature);
  formData.append('x-amz-security-token', response.data.fields['x-amz-security-token']);
  formData.append('file', file);
  return formData;
};

export const sortArrayOfObjectsByKey = (array, order, key) => {
  array.sort(function(a, b) {
    let A = a[key],
      B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const checkLocalhost = ({
  alterOrigin // origin to return if not localhost
}) => {
  const { hostname, origin } = window.location;

  const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';

  return {
    isLocalhost,
    origin: alterOrigin && !isLocalhost ? alterOrigin : origin
  };
};

export const getURLQueryParams = (queryString = window.location.search) => {
  const urlSearchParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export const buildQueryString = (queryParams = {}) => {
  return Object.entries(queryParams)
    .filter(([key, value]) => !!value || value === 0)
    .reduce((queryString, [key, value], idx) => {
      const prefix = idx === 0 ? '?' : '&';
      return queryString + `${prefix}${key}=${encodeURIComponent(value)}`;
    }, '');
};

export const resolveObj = (path, obj = {}, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export const replaceWords = (str, mapObj) => {
  // for str, it replaces all occurrences of keys in mapObj with their values
  let regex = new RegExp('\\b(?:' + Object.keys(mapObj).join('|') + ')\\b', 'gi');
  return str.replace(regex, matched => mapObj[matched]);
};

export const downloadFile = (url, name) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  if (name) a.download = name;
  document.body.appendChild(a);
  a.href = url;
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const downloadFileFromUrl = async(url) => {
  try {
    const fileName = decodeURIComponent(url.split('/').pop().split('?')[0]);

    const response = await fetch(url, { method: 'GET' });
    if (!response.ok) {
      throw new Error('Failed to fetch file');
    }

    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName; 

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (error) {
    enqueueAlertSnackbar(`${error.message}`, { props: { severity: 'error' } });
  }
};



export const getNameShortcut = name => {
  const products = {
    'Breach Prevention Platform (BPP)': 'BPP',
    'HIPAA BPP': 'HBPP',
    'EVA MD': 'EVAMD',
    'Partner HIPAA Compliance Program': 'PHCP',
    'Unlimited Cybersecurity Training': 'UCT',
    'HIPAA Compliance': 'HC'
  };

  const roles = {
    User: 'E',
    Manager: 'M',
    'Manager Admin': 'MA',
    'Partner Administrator': 'PA',
    Administrator: 'A'
  };

  const shortcut = products[name] || roles[name];

  return shortcut || name;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const useClientId = () => {
  const userId = useId({ key: 'clientId' });
  const { item: itemLoc, app: appLoc } = useLocation('clientId');
  const item = itemLoc || userId;
  return item;
};

export const isLocalEnv = () => {
  return window.location.hostname === 'localhost';
};

export const getCookie = name => {
  const cookies = `; ${document.cookie}`;
  const parts = cookies.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

export const isExist = val => {
  return val !== undefined && val !== null;
};

export const sortRoles = (roles = []) => {
  const rolesPriority = {
    E: 0,
    M: 1,
    MA: 2,
    PA: 3,
    A: 4
  };

  return roles.sort((a, b) => {
    if (rolesPriority[a.abbreviation] < rolesPriority[b.abbreviation]) {
      return -1;
    } else {
      return 1;
    }
  });
};

export const useCheckAccess = (type, app) => {
  const accessApps = useAccess({ type });
  const hasAccess = hasKey(accessApps, app);
  const history = useHistory();

  if (accessApps && !hasAccess) history.push('/#/myDashboard/dashboard');

  return hasAccess;
};

export const calc_diff = (timestamp1, timestamp2, type = 'minute', floored = false) => {
  if (!timestamp1 || !timestamp2) return 0;
  const different_time = { day: 1000 * 60 * 60 * 24, hour: 1000 * 60 * 60, minute: 1000 * 60, second: 1000 };
  const difference = floored
    ? Math.floor(Math.abs(timestamp1 - timestamp2) / different_time[type])
    : Math.abs(timestamp1 - timestamp2) / different_time[type];
  return difference;
};

export const dateToISO = date => {
  if (!(date instanceof Date && !isNaN(date))) return;
  return date.toISOString().split('T')[0];
};

export const isHipaaProduct = val => {
  const hipaaTypes = [
    'hipaa_compliance',
    'hipaa_bpp',
    'hipaa_training_only',
    'partner_hipaa_compliance_program',
    'eva_md'
  ];

  return hipaaTypes.includes(val);
};

export const isHipaaProductByName = val => {
  return HIPAA_PRODUCT_NAMES.includes(val);
};

export const USER_ROLES = {
  MANGER: 'Manager',
  ADMINISTRATOR: 'Administrator',
  USER: 'User',
  EMPLOYEE: 'Employee',
  PENDING: 'Pending',
  PARTNER_ADMINISTRATOR: 'Partner Administrator',
  ADMIN_LITE: 'Admin Lite',
  MANAGER_ADMIN: 'Manager Admin'
};

export const dataURLtoFile = (dataUrl, type, filename) => {
  const arr = dataUrl.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type });
};

// Get the day of a month with suffixes: st,nd,rd,th (1st, 2nd, 3rd, 4th)
export const getOrdinalNumber = n => {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
};

export const checkIsToday = date => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

/**
 * sets local storage variables required for the mfa configuration step
 * @param {object} password_config : returned in the login\
 *  response if the user didn't configure his/her mfa auth app yet.
 */
export const setMfaConfigData = password_config => {
  localStorage.setItem('qr_code_image', password_config?.qr_code_image);
  localStorage.setItem('secret_code', password_config?.secret_code);
  localStorage.setItem('show_configure_mfa', password_config?.show_configure_mfa);
  localStorage.setItem('show_verify_code', password_config?.show_verify_code);
  localStorage.setItem('strict_new_password', password_config?.strict_new_password);
};

export const clearMfaConfigData = () => {
  localStorage.removeItem('qr_code_image');
  localStorage.removeItem('secret_code');
  localStorage.removeItem('show_configure_mfa');
  localStorage.removeItem('show_verify_code');
  localStorage.removeItem('strict_new_password');
};

export const ObjectToString = ({ obj = {}, connector = ':', separator = ',' }) => {
  return Object.entries(obj)
    .filter(([key, value]) => !!value || value === 0)
    .reduce((str, [key, value], idx) => {
      const prefix = idx === 0 ? '' : separator;
      return str + `${prefix}${key}${connector}${value}`;
    }, '');
};

export const ObjectToCommaSeparatedString = (obj = {}) => {
  return ObjectToString({ obj, connector: ':', separator: ',' });
};

export const arraysHaveSameValues = (arr1, arr2) => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays to ensure elements are in the same order for comparison
  arr1.sort();
  arr2.sort();

  // Compare each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all elements match, return true
  return true;
};

export const setTimeInDateObject = (dateObj, type = 'now') => {
  const nowDate = new Date();
  const selectedDate = new Date(dateObj);
  const [hour, minute, second, millisecond] = {
    now: [nowDate.getUTCHours(), nowDate.getUTCMinutes(), nowDate.getUTCSeconds(), nowDate.getUTCMilliseconds()],
    startOfDay: [0, 0, 0, 0],
    endOfDay: [23, 59, 59, 999]
  }[type];

  selectedDate.setUTCHours(hour);
  selectedDate.setUTCMinutes(minute);
  selectedDate.setUTCSeconds(second);
  selectedDate.setUTCMilliseconds(millisecond);
  return selectedDate;
};

export const setTimeInDateObjectToNow = dateObj => setTimeInDateObject(dateObj, 'now');
export const setTimeInDateObjectToStartOfDay = dateObj => setTimeInDateObject(dateObj, 'startOfDay');
export const setTimeInDateObjectToEndOfDay = dateObj => setTimeInDateObject(dateObj, 'endOfDay');

export const addPluralS = (unit, count) => `${unit}${count > 1 ? 's' : ''}`;
