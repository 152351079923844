import { Box, Container, Stack, alpha, styled, useTheme } from '@trustsecurenow/components-library';
import React, { useRef } from 'react';
import Section from './Section';
import AccountDependence from './AccountDependence';
import ContactUs from './ContactUs';
import HSNMain from './HSNMain';
import Header from './Header';

const SECTIONS = [
  {
    id: '01',
    title: 'Dashboard Access',
    description:
      'Engage with the HIPAA Secure Now platform through your user dashboard, which includes your Employee Secure Score (ESS), task list, ESS leaderboard, training, dark web status, and policy access. Additionally, you can manage your profile, access the Newsfeed, and submit contact inquiries within this interface.',
    imagePath: '/media/hsnteams/dashboard.svg'
  },
  {
    id: '02',
    title: 'Complete Training',
    description:
      'Access and complete your HIPAA Secure Now trainings and quizzes (when applicable), including Annual Cybersecurity Courses, Weekly Micro Trainings, Productivity Training, and Newsletters.',
    imagePath: '/media/hsnteams/training.svg'
  },
  {
    id: '03',
    title: 'Receive Notifications',
    description:
      'Receive notifications for new training opportunities and stay informed about activity in the Newsfeed that you have been alerted to.',
    imagePath: '/media/hsnteams/notification.svg'
  },
  {
    id: '04',
    title: 'Mobile Device Access',
    description:
      'Unlock seamless connectivity on the go! Download the Teams app on your mobile device today and enjoy access to your account anytime, anywhere. Stay connected, stay productive!',
    imagePath: '/media/hsnteams/mobile.svg'
  }
];

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.primary.lightest, 0.2)} 11.43%, ${alpha(
    theme.palette.error.main,
    0.2
  )} 100%)`
}));

const HSNTeamsAbout = () => {
  const theme = useTheme();
  const contactUsRef = useRef(null);
  const sectionsRef = useRef(null);

  const handleLearnMoreClick = () => {
    sectionsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleContactUsClick = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Stack>
      <HeaderContainer>
        <Container maxWidth="lg">
          <Header
            title="HIPPA Secure Now"
            logoPath="/media/hsnteams/logo.svg"
            onContactUsClick={handleContactUsClick}
          />
          <HSNMain onLearnMoreClick={handleLearnMoreClick} />
        </Container>
      </HeaderContainer>
      <Container maxWidth="lg">
        <Stack alignItems={{ xs: 'center', md: 'normal' }} py={11} px={2.5} spacing={9} ref={sectionsRef}>
          {SECTIONS.map(({ description, imagePath, title, id }, index) => (
            <Section
              key={id}
              isReversed={index % 2 !== 0}
              description={description}
              id={id}
              title={title}
              imagePath={imagePath}
              strokeColor={theme.palette.error.main}
              titleColor={theme.palette.primary.dark}
              descriptionColor={theme.palette.primary.light}
            />
          ))}
        </Stack>
      </Container>
      <Stack bgcolor={alpha(theme.palette.error.main, 0.08)}>
        <Container maxWidth="lg">
          <AccountDependence
            title="Account Dependency"
            description="Access to this app requires an account in a qualifying HIPAA Secure Now product. Please reach out to your IT provider or sales representative to inquire about gaining access."
            avatarColor={alpha(theme.palette.error.main, 0.2)}
            titleColor={theme.palette.primary.dark}
            descriptionColor={theme.palette.primary.light}
            avatarIconColor="error"
          />
          <ContactUs
            email="help@hipaasecurenow.com"
            title="HIPAA Secure Now"
            emailColor={theme.palette.primary.dark}
            copyrightsColor={theme.palette.primary.light}
          />
          <Box ref={contactUsRef} />
        </Container>
      </Stack>
    </Stack>
  );
};

export default HSNTeamsAbout;
