// @flow
import React, { cloneElement, memo, type Element, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Datagrid as RADataGrid, DatagridBody, RecordContextProvider } from 'react-admin';
import { Route } from 'react-router';
import { getActiveTab, getStorage } from 'helpers';
import { LoadingStyled } from 'components';
import { ListParent, ListStyled } from './ComponentType';

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'var(--backgroundPaper)',
    color: 'var(--colorDefault)',
    boxShadow: theme.shadows[2],
    padding: 10,
    fontWeight: 'normal',
    //transform: 'translate(232px, 20px) !important'
  }
}));

type ListProps = {
  componentPush: Function,
  create?: Element<*> | null,
  edit: Element<*>,
  actions: Element<*>,
  children: any,
  basePath: string,
  resource: string,
  ...
};

const DatagridRow = ({ basePath, className, children, id, onRowClick, record, ...props }) => {
  const class_tooltip = useStyles();
  const [tootipPosition, setTooltipPosition] = useState({ x: undefined, y: undefined });
  const { bsn } = useSelector(rxState => rxState);
  const isAdmin = bsn.user?.profile?.user_role === 'Administrator';
  const is_enterprise_partner = bsn.user?.profile?.is_enterprise_partner;

  const disabledRow = React.useMemo(
    () => 
      !record?.active 
      && record?.enterprise_partner === true 
      && record?.product_code === 'EBPPC-0' 
      && !isAdmin 
      && is_enterprise_partner,
    [record?.active, record?.enterprise_partner, record?.product_code, isAdmin, is_enterprise_partner]
  )

  const tableRow = (
    <TableRow hover onClick={() => onRowClick(id, basePath, record)} className={className}>
      {/* data columns based on children */}
      {React.Children.map(children, (field) =>
        React.isValidElement(field) ? (
          <TableCell
            key={`${id}-${field.props.source}`}
            align={field.props.textAlign}
            className={'data-grid-cell'}
          >
            {field}
          </TableCell>
        ) : null
      )}
    </TableRow>
  )
  return (
    <RecordContextProvider value={record}>
      {disabledRow ? (
        <Tooltip
          onMouseMove={e => setTooltipPosition({ x: e.pageX, y: e.pageY })}
          classes={class_tooltip}
          title={<span>Client Inactive: <br /> Click here to activate</span>}
          PopperProps={{
            anchorEl: {
              clientHeight: 0,
              clientWidth: 0,
              getBoundingClientRect: () => ({
                top: tootipPosition.y,
                left: tootipPosition.x,
                right: tootipPosition.x,
                bottom: tootipPosition.y,
                width: 0,
                height: 0,
              })
            }
          }}
        >
          {tableRow}
        </Tooltip>
      ) : tableRow}
    </RecordContextProvider>
  )
}

const CustomDatagridBody = ({ onRowClick, ...rest }) => (
  <DatagridBody {...rest} row={<DatagridRow onRowClick={onRowClick} />} />
);

const DataGrid = ({ children, activateEBBClient, ...rest }) => {
  const { bsn } = useSelector(rxState => rxState);
  const isAdmin = bsn.user?.profile?.user_role === 'Administrator';
  const is_enterprise_partner = bsn.user?.profile?.is_enterprise_partner;
  const loadingList = getStorage('showLoading', true) === 'true';
  const history = useHistory();
  // Show loading after enable or disable mask partner and avoid display cache of data on clients list (default beharvior of React admin package)
  if (loadingList) return <LoadingStyled />;

  const onRowClick = (id, basePath, record) => {
    const { active, enterprise_partner, name, product_code, product_type } = record;
    if (!active && enterprise_partner === true && product_code === 'EBPPC-0' && !isAdmin && is_enterprise_partner) {
      activateEBBClient({ name, id, product_type });
      return;
    }
    history.push({
      pathname: `${basePath}/${getActiveTab(bsn.tabs[basePath.split('/')[1]])}/${id}`
    });
  };

  return (
    <RADataGrid
      body={<CustomDatagridBody onRowClick={onRowClick} />}
      classes={{
        table: 'data-grid-table',
        headerCell: 'data-grid-header',
        tbody: 'data-grid-body',
        rowCell: 'data-grid-cell',
        rowEven: 'data-grid-cell__even',
        rowOdd: 'data-grid-cell__odd'
      }}
      {...rest}
    >
      {children}
    </RADataGrid>
  );
};

const List = ({
  componentPush,
  create,
  edit,
  actions,
  children,
  activateEBBClient,
  ...props
}: ListProps): Element<*> => {
  const handleClose: Function = (redirect: string): void => componentPush(redirect);

  return (
    <>
      <ListParent>
        <ListStyled {...props} perPage={25} actions={actions}>
          <DataGrid activateEBBClient={activateEBBClient}>{children}</DataGrid>
        </ListStyled>
      </ListParent>
      {create && (
        <Route path={`${props.basePath}/create`}>
          {({ match }) => (
            <Dialog
              open={!!match}
              fullWidth
              maxWidth="md"
              anchor="right"
              onClose={handleClose}
              aria-labelledby={`${props.resource}-create`}
            >
              {cloneElement(create, {
                handleCancel: (redirect?: string): void => handleClose(redirect || props.basePath),
                ...(props: $Rest<ListProps, any>)
              })}
            </Dialog>
          )}
        </Route>
      )}
    </>
  );
};

List.defaultProps = {
  create: null
};

export default memo<ListProps>(List);
