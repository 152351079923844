// @flow
import React from 'react';
import { useModalConfig } from 'hooks';
import { Loadable } from 'components/loadable';
import { DialogActions, AlignRight, AlignLeft } from './ModalFooter.styles';

type ModalFooterProps = { ...AnyAppTab };

function ModalFooter({ app, tab }: ModalFooterProps) {
  const settings = useModalConfig(app, tab);

  return (
    <DialogActions>
      <AlignLeft>
        <Loadable component={settings?.layout?.footer?.left} app={app} tab={tab} />
      </AlignLeft>

      <AlignRight>
        <Loadable component={settings?.layout?.footer?.right} app={app} tab={tab} />
      </AlignRight>
    </DialogActions>
  );
}

export default ModalFooter;
