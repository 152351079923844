import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, InputAdornment, TextField, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import { ArrowDown, Block, Close, Delete, Filter, Success } from 'components';
import IconButton from '@material-ui/core/IconButton';
import { dataProvider } from 'helpers';
import { useId } from 'hooks';
import { Autocomplete } from '@material-ui/lab';
import DeleteClientModal from './DeleteClientModal';
import { useTabsAllowed } from 'hooks';
import { useNotify } from 'ra-core';
import ActivateClientModal from './ActivateClientModal';
import DeactivateClientModal from './DeactivateClientModal';
import { useDispatch, useSelector } from 'react-redux';
import { BSN_SET_ANY } from 'conf';

const Container = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  height: 75px;
  border-bottom: 1px solid var(--borderLight);
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 2rem;
`;

const HeaderRow = styled(Row)`
  height: unset;
`;

const SearchRow = styled(Row)`
  padding: 0;
  justify-content: space-around;
`;

const FilterRow = styled(Row)`
  padding: 0 0 1rem 0;
  justify-content: center;
  flex-wrap: wrap;
  height: ${props => (props.isOpen ? 'auto' : '0')};
  && * {
    display: ${props => (props.isOpen ? 'auto' : 'none')};
  }
`;

const TableHeader = styled(Row)`
  height: 53px;
  padding: 0;
  justify-content: space-around;
`;

const TableRow = styled(Row)`
  padding-right: 2rem;
  justify-content: space-between;
  .clientContainer {
    padding-left: 1rem;
    text-overflow: ellipsis;
  }
  .actionsContainer {
    display: flex;
  }
  .iconStatus {
    padding: 1.2rem;
  }
`;

const SearchInput = styled(Input)`
  width: 265px;
  height: 52px;
  padding: 12px 0px 12px 12px;
  color: var(--colorLight);
  border: none;
  border-radius: 5px;
  background-color: var(--borderLight);
  & svg {
    font-size: 25px;
    color: var(--colorLight);
  }
  & input {
    box-shadow: 0 0 0 30px var(--borderLight) inset !important;
  }
`;

const AutocompleteFilterInput = styled(Autocomplete)`
  width: 92%;
  height: 52px;
  margin-top: 1rem;
  color: var(--colorLight);
  background-color: var(--borderLight);
  && * {
    border: none;
  }
  && svg {
    margin-right: 0.8rem;
  }
`;

const FilterButton = styled(Button)`
  && {
    height: 52px;
    background-color: var(--colorBaseBase);
    &:focus,
    &:active {
      background-color: var(--colorBaseBase);
    }
  }
`;

const ClientField = styled.div`
  opacity: ${({ opacity }) => (opacity ? '0.5' : '1')};
`;

const ClientRow = ({ client, onDeletion, changeClientStatus, basePath }) => {
  const history = useHistory();
  const isAdmin = useSelector(state => state?.bsn?.user?.profile?.user_role) === 'Administrator';
  const allowedLayout = useTabsAllowed({ isMobile: true, clientAccess: client.access });
  const firstAllowedTab = Object.keys(allowedLayout)?.[0];
  const [openActivate, setOpenActivate] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);

  // making sure text.length doesn't exceed suitableLength
  const suitableLength = 32;
  const clientName =
    client.name.trim().length > suitableLength
      ? client.name.trim().slice(0, suitableLength - 1) + '...'
      : client.name.trim();
  const productType =
    client.product_type.trim().length > suitableLength
      ? client.product_type.trim().slice(0, suitableLength - 1) + '...'
      : client.product_type.trim();

  const isEbppClient = client.enterprise_partner === true && client.product_code === 'EBPPC-0';
  const openActivateModal = !client.active && isEbppClient && client.sys_conf_disable_emails;

  const handleClose = () => {
    setOpenActivate(false);
    setOpenDeactivate(false);
  };

  return (
    <TableRow key={client.id}>
      <ClientField opacity={!client.active && isEbppClient && !isAdmin}>
        <div
          className="clientContainer"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (openActivateModal && !isAdmin) {
              setOpenActivate(true);
              return;
            }
            history.push({
              pathname: `${basePath}/${firstAllowedTab}/${client.id}`
            });
          }}
        >
          <p>{clientName}</p>
          <p>Product: {productType}</p>
        </div>
      </ClientField>
      <div className="actionsContainer">
        {client.product_type === 'Unlimited Cybersecurity Training' || isEbppClient ? (
          client.active ? (
            <>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setOpenDeactivate(true)}
              >
                <Success style={{ color: '#0CCE6B' }} />
              </IconButton>
              <DeactivateClientModal
                isMobile
                clientState={{ isDeactivateClientModalOpen: openDeactivate }}
                onClose={handleClose}
                onSubmit={() => {
                  handleClose()
                  changeClientStatus(client.id, 'deactivate')
                }}
              />
            </>
          ) : (
            <>
              {openActivateModal ? (
                <>
                  <IconButton color="primary" aria-label="activate client" component="span">
                    <Block color="#FF5A61" onClick={() => setOpenActivate(true)} />
                  </IconButton>
                  <ActivateClientModal
                    client={client}
                    onActivation={onDeletion}
                    open={openActivate}
                    handleClose={handleClose}
                  />
                </>
              ) : (
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <Block color="#FF5A61" onClick={() => changeClientStatus(client.id, 'activate')} />
                </IconButton>
              )}
              
              <DeleteClientModal clientId={client.id} onDeletion={onDeletion} />
            </>
          )
        ) : (
          client.active ? (
            <Success style={{ color: '#0CCE6B' }} className="iconStatus" />
          ) : (
            <Block color="#FF5A61" className="iconStatus" />
          )
        )}
      </div>
    </TableRow>
  );
};

const ClientsListMobile = ({clientsAlert, basePath}) => {
  const partnerId = useId({ key: 'partnerId' });
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(null);

  const notify = useNotify();
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    name: {
      label: 'Name',
      value: null
    },
    productType: {
      label: 'Product Type',
      options: [],
      value: null
    },
    activeState: {
      label: 'Active Status',
      options: [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false }
      ],
      value: null
    }
  });
  /* fetching clients list on first render */
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('clients', `list/${partnerId}`, {})
      .then(response => {
        setClients(response.data.clients);
        setLoading(false);
      })
      .catch(error => {
        // console.log({ mobileError: error });
        setClients([]);
        setLoading(false);
      });

    dataProvider.getList('clients', `productsType/${partnerId}`, {}).then(res => {
      setFilters({
        ...filters,
        productType: {
          ...filters.productType,
          options: res.data.map(type => {
            return { label: type.name, value: type.id };
          })
        }
      });
    });

    return () => {
      setClients([]);
    };
  }, [partnerId]);

  /**
   * fetches clients list based of filtering params
   */
  const filterClients = async filters => {
    try {
      const filter = {
        name: filters.name.value,
        product_type: filters.productType.value?.value,
        active: filters.activeState.value?.value
      };
      Object.keys(filter).forEach(key => {
        // deleting filters of falsy values
        // except for active filter
        if (!filter[key] & (key !== 'active')) {
          delete filter[key];
        }
        // deleting active filter only if it's true of false
        if ((key === 'active') & (filter[key] !== false) & (filter[key] !== true)) {
          delete filter[key];
        }
      });
      setLoading(true);
      dataProvider
        .getList('clients', `list/${partnerId}`, {
          filter
        })
        .then(response => {
          setClients([...response.data.clients]);
          dispatch({
            type: BSN_SET_ANY,
            payload: {
              user: {
                profile: {
                  is_enterprise_partner: response.data?.is_enterprise_partner ?? 0,
                  disabled_system_email_clients_count: response.data?.is_enterprise_partner
                    ? response.data.disabled_system_email_clients_count ?? 0
                    : 0
                }
              }
            }
          });
          setLoading(false);
        })
        .catch(error => {
          setClients([]);
          setLoading(false);
        });
    } catch (error) {
      // console.log({ filterClientsError: error });
    }
  };

  const setFilter = async (value, key) => {
    try {
      const newFilters = {
        ...filters,
        [key]: {
          ...filters[key],
          value: value
        }
      };
      // updating filters state
      setFilters({
        ...newFilters
      });
      // updating clients list
      filterClients({
        ...newFilters
      });
    } catch (error) {
      // console.log({ setFilterError: error });
    }
  };

  const FilterInputs = Object.keys(filters).map((key, index) => {
    if (key === 'name') return;
    const filter = filters[key];
    return (
      <AutocompleteFilterInput
        id="clientsListMobile_FilterSelector"
        options={filter.options}
        loading={filter.options.length === 0}
        getOptionLabel={option => option.label}
        renderInput={params => <TextField {...params} variant="outlined" placeholder={filter.label} />}
        closeIcon={<Close />}
        popupIcon={<ArrowDown />}
        onChange={(e, value) => setFilter(value, key)}
      />
    );
  });

  // changes the status of a client
  const changeClientStatus = async (clientId, status) => {
    try {
      const app = 'clients';
      const tab = 'actions';
      await dataProvider.post(app, tab, clientId, { type: status });
      filterClients(filters);
      notify(`Client has been ${status}d.`);
    } catch (error) {
      notify("client status didn't update successfully!");
    }
  };

  const Clients = loading ? (
    <p>loading...</p>
  ) : clients.length === 0 ? (
    <p>No Results.</p>
  ) : (
    clients.map(client => (
      <ClientRow client={client} onDeletion={() => filterClients(filters)} changeClientStatus={changeClientStatus} basePath={basePath} />
    ))
  );

  return (
    <Container>
      <HeaderRow>
        <Box dispay="flex" direction="column">
          <h1>Clients</h1>
          <Box style={{ margin: '2rem 2rem 0rem 0rem' }}>{clientsAlert}</Box>
        </Box>
      </HeaderRow>
      <SearchRow>
        <SearchInput
          disableUnderline
          id="clientsListMobile_searchInput"
          value={filters.name.value}
          onChange={e => {
            setFilter(e.target.value, 'name');
          }}
          placeholder="search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon fontSize="inherit" />
            </InputAdornment>
          }
        />
        <FilterButton
          icon
          variant="contained"
          color="primary"
          onClick={() => {
            setIsFilterOpen(isOpen => !isOpen);
          }}
        >
          <Filter />
        </FilterButton>
      </SearchRow>
      <FilterRow isOpen={isFilterOpen}>{FilterInputs}</FilterRow>

      <TableHeader>
        <h2>Client</h2>
        <h2>Active</h2>
      </TableHeader>
      {Clients}
    </Container>
  );
};

export default ClientsListMobile;
