import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { snakeToCamelTitle } from 'helpers';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export default function ShowingHeadCell({ options, showPassword, optionDefault }) {
  const [show, setShow] = useState(optionDefault);
  showPassword(show);

  return (
    <FormControl component="fieldset">
      <RadioGroup row aria-label="show" name="show" value={show} onChange={e => setShow(e.target.value)}>
        {options.map(option => {
          return <FormControlLabel value={option} control={<Radio />} label={snakeToCamelTitle(option)} />;
        })}
      </RadioGroup>
    </FormControl>
  );
}

ShowingHeadCell.propTypes = {
  options: PropTypes.arrayOf.isRequired,
  showPassword: PropTypes.func.isRequired
};
