import { ArrowUndoIcon, IconButton, Tooltip } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';

const DeletedCell = ({ isDeleted, onClick }) => {
  return (
    isDeleted && (
      <Tooltip title="Undelete Client" placement="top">
        <IconButton onClick={onClick}>
          <ArrowUndoIcon color="info" sx={{ fontSize: '2rem' }} />
        </IconButton>
      </Tooltip>
    )
  );
};

DeletedCell.propTypes = {
  isDeleted: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
export default DeletedCell;
