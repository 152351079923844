import React from 'react';
import { Cached } from '@material-ui/icons';
import { Typography } from 'components';
import { CircularProgress } from '@material-ui/core';
import { CardStyled, CardTitle, CardBody, CardImg, CardContent, CardFooter, CardActions } from './style';
import { ButtonBaseFilled } from '../../style';

const RetakeAssessment = ({ onRetakeSRA, loadingRetake }) => {
  return (
    <CardStyled>
      <CardTitle>Perform Another Security Risk Assessment</CardTitle>

      <CardBody>
        <CardImg>
          <img src="/media/sra/retake_sra.svg" alt="task" />
        </CardImg>

        <CardContent>
          <Typography.p fontSize={14} mt="0px" mb="0px">
            Clicking the &quot;Perform Another Security Risk Assessment&quot; button will conclude your current risk
            assessment and initiate a new checklist. You can continue to modify your current work plan, which will
            update the corresponding question in the questionnaire. However, once your new risk assessment is complete,
            the current work plan checklist will be replaced by an updated version.
          </Typography.p>

          <CardFooter>
            <CardActions btnWidth="auto">
              <ButtonBaseFilled
                variant="outlined"
                startIcon={loadingRetake ? <CircularProgress size={16} /> : <Cached mr={1.2} size={1.3} />}
                disableElevation
                fullWidth
                disabled={loadingRetake}
                onClick={onRetakeSRA}
              >
                Perform Another Security Risk Assessment
              </ButtonBaseFilled>
            </CardActions>
          </CardFooter>
        </CardContent>
      </CardBody>
    </CardStyled>
  );
};

export default RetakeAssessment;
