import React, { createContext, useContext } from "react"


const ContentAdminFormContext = createContext({})

export const ContentAdminFormProvider = ({value, children}) => {
  return (
    <ContentAdminFormContext.Provider value={value}>
      {children}
    </ContentAdminFormContext.Provider>
  )
}

export const useContentAdminFormContext = () => {
  return useContext(ContentAdminFormContext)
}
