// @flow

import React, { useState, useEffect, type Element } from 'react';
import { LoadingStyled } from '../types';

type ImageTypes = {
  src: string,
  alt?: string,
  ...
};

const ImageComponent = ({ src, alt = 'image', ...props }: ImageTypes): Element<*> | null => {
  const [isLoading, setIsLocading] = useState(true);

  useEffect(() => {
    asyncImageLoader(src).then(res => setIsLocading(res));
  }, [src]);

  if (!src) return null;

  return !isLoading ? <img src={src} alt={alt} {...props} /> : <LoadingStyled />;
};

ImageComponent.defaultProps = {
  alt: 'image'
};

function asyncImageLoader(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(false);
    image.onerror = () => reject(new Error('could not load image'));
  });
}

export default ImageComponent;
