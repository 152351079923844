// @flow
import React, { type Element } from 'react';
import { TextField, CardActions, CreateButton, NumberField, DateField } from 'react-admin';
import { List } from 'components';
import PhishingCreate from './PhishingCreate';
import PhishingEdit from './PhishingEdit';

type PhishingsListProps = Object;

const PhishingListActions = ({ basePath }: { basePath: string }): Element<*> => (
  <CardActions style={{ display: 'none' }}>
    <CreateButton basePath={basePath} id="bsn_button_new_phishings" />
  </CardActions>
);

const PhishingList = (props: PhishingsListProps): Element<*> => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    actions={<PhishingListActions basePath={props.basePath} />}
    edit={<PhishingEdit />}
    create={<PhishingCreate />}
    bulkActions={false}
  >
    <TextField source="name" label="Name" />
    <TextField source="account_type" label="Account Type" />
    <NumberField source="users" label="Users" />
    <DateField source="last_run_date" label="Last Run Date" />
    <TextField source="status" label="Status" />
    <NumberField source="average_ess" label="ESS" />
    <TextField source="phishing_fail_rate" label="PFR%" />
    <NumberField source="campaigns_sent" label="Campaigns Sent" />
  </List>
);

export default PhishingList;
