import React, { useEffect, useState } from 'react';
import { Grid, getRichTextEdiorConfig, RichTextEditor, Typography, useThemeContext } from '@trustsecurenow/components-library';
import ContainerStepContent from './ContainerStepContent';
import { ADMIN_NEWSLETTERS } from '../../constants';
import { updateNestedMutable } from 'utils/update';

const CUSTOM_CONFIG = {
  uploader: {
    insertImageAsBase64URI: false
  },
  link: {
    openInNewTabCheckbox: false
  },
  enter: "br",
  addNewLineOnDBLClick: false,
  buttons: [
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'eraser',
    '|',
    'source',
  ]
};

const DEFAULT_CONFIG = getRichTextEdiorConfig();
const editor_config = updateNestedMutable(DEFAULT_CONFIG, CUSTOM_CONFIG);

const useRichTextContent = (initialValue, recordValue, recordKey, dispatchFunc) => {
  const [content, setContent] = useState(initialValue);

  useEffect(() => {
    setContent(recordValue ?? initialValue);
  }, [recordValue, initialValue]);

  const handleContentChange = (value) => {
    const textContent = value.replace(/<[^>]*>/g, '');
    const normalizedValue = textContent ? value : "";
    setContent(normalizedValue);
    dispatchFunc(recordKey, normalizedValue);
  };

  return [content, handleContentChange];
};

const CompleteStep = ({ stepNumber, dispatch, record, page, createMode }) => {
  const email_body_name = page === ADMIN_NEWSLETTERS ? 'email_body' : 'email_text';
  const quick_tips_name = page === ADMIN_NEWSLETTERS ? 'quick_tips_body' : 'quick_tips';

  const { themeMode } = useThemeContext()
  const [contentWT,  handleChangeWT] = useRichTextContent(
    '', record[email_body_name], email_body_name, dispatch.onChange
  );
  const [contentQT, handleChangeQT] = useRichTextContent(
    '', record[quick_tips_name], quick_tips_name, dispatch.onChange
  );

  return (
    <ContainerStepContent
      disableSubmit={!contentWT || !contentQT}
      headerTitle={`${stepNumber}. Complete`}
      onSubmit={dispatch.onNext}
      onBack={dispatch.onBack}
      submitLabel={createMode ? 'Create' : 'Update'}
    >
      <Grid container borderTop={6} borderColor={theme => theme.palette.grey[themeMode === 'dark' ? 800 : 100]}>
        <Grid item xs={12} p={2} borderBottom={1} borderColor={theme => theme.palette.grey[themeMode === 'dark' ? 800 : 100]}>
          <Typography variant="h5">
            This Week’s Training
          </Typography>
        </Grid>
        <Grid item p={2} xs={12}>
          <RichTextEditor
            config={editor_config}
            value={contentWT}
            onChange={handleChangeWT}
          />
        </Grid>
      </Grid>

      <Grid container borderTop={6} borderBottom={6} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
        <Grid item xs={12} p={2} borderBottom={1} borderColor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}>
          <Typography variant="h5">
            Quick Tips
          </Typography>
        </Grid>
        <Grid item p={2} xs={12}>
          <RichTextEditor
            config={editor_config}
            value={contentQT}
            onChange={handleChangeQT}
          />
        </Grid>
      </Grid>
    </ContainerStepContent>
  );
};

export default CompleteStep;
