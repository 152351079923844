// @flow

const directorySync = {
  title: 'View Power Shell Script',
  component: 'shared/directorySync/DirectorySyncAzureActiveDirectoryPowerShellModal',
  noState: true,
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Cancel',
      type: 'close'
    }
  },
  initialState: null
};

export default directorySync;
