// @flow
import React, { type Element, useState } from 'react';
import { Button as MuiButton, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { LazyIcon } from '../icons';
import Modal3 from '../modal/Modal3';
import { useTableList2 } from './TableList2Context';

const Button = styled(MuiButton)`
  white-space: nowrap;
`;

type TableListActionTypes = {
  icon?: string | null,
  hideIcon: boolean,
  label?: string | null,
  paramId?: string | null,
  tooltip?: string,
  modal?: {
    app: string,
    tab: string
  },
  rowId: string,
  handleModal: Function
};

const TableListAction = ({
  icon,
  hideIcon,
  label,
  paramId,
  tooltip,
  modal,
  rowId,
  handleModal
}: TableListActionTypes): Element<*> | null => {
  const { app, tab } = useTableList2();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (modal) {
      setOpen(true);
    }
  };

  const modalApp = modal?.app || app;
  const modalTab = modal?.tab || tab;

  return !hideIcon ? (
    <>
      <Tooltip title={tooltip} aria-label={label}>
        <Button color="primary" aria-label={label} onClick={onClick}>
          {icon && <LazyIcon component={icon} />}
          {icon && label && <>&nbsp;&nbsp;</>}
          {label}
        </Button>
      </Tooltip>
      {modal && open && (
        <Modal3
          paramId={paramId}
          {...modal}
          open={open}
          app={modalApp}
          tab={modalTab}
          setOpen={setOpen}
          rowId={rowId}
          handleModal={handleModal}
        />
      )}
    </>
  ) : null;
};

TableListAction.defaultProps = {
  icon: null,
  label: null,
  tooltip: '',
  modal: null,
  paramId: null
};

export default TableListAction;
