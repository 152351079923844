import React, { useCallback, useState } from 'react';

const useLayout = () => {
  const [essChartAnchorEl, setEssChartAnchorEl] = useState(null)

  const setAnchorEl = useCallback((value) => {
    setEssChartAnchorEl(value)
  }, [])

  return {
    record: {
      essChartAnchorEl
    },
    dispatch: {
      setAnchorEl
    }
  }
}

export default useLayout
