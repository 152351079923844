import { MailAlertIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const SystemEmailsCell = ({ disabled, onClick }) => {
  if (isNA(disabled)) return <Typography variant="body2">N/A</Typography>;

  return !disabled ? (
    ''
  ) : (
    <Tooltip title="System emails are disabled for this client. Click here to enable." placement="top">
      <IconButton onClick={onClick}>
        <MailAlertIcon color="error" sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

SystemEmailsCell.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default SystemEmailsCell;
