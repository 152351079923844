export const DELETE_VARIANT = 'DELETE';
export const STOP_VARIANT = 'STOP';
export const CAMPAIGN_STATUS_COMPLETED = 'Completed';
export const CAMPAIGN_STATUS_IN_PROGRESS = 'In progress';

export const ADMIN_USER = 'Administrator';
export const PA_USER = 'Partner Administrator';
export const MA_USER = 'Manager Admin';
export const MANAGER_USER = 'Manager';

export const UT_CLIENT = 'Unlimited Training';

export const AAD_SERVICE = 'aad';
export const GSUITE_SERVICE = 'GSUITE_SERVICE';

export const CONFIGURE_DMD = 'configureDMD'