import React, { createContext, useContext } from 'react';

const settings = [
  {
    name: 'trainingTrainings',
    label: 'Micro Training',
    folding: 'trainingTrainingsUsers'
  },
  {
    name: 'trainingUsers',
    label: 'Users',
    folding: 'trainingUsersTrainings'
  }
];

const TrainingsContext = createContext({
  dispatch: e => e,
  radio: 0,
  settings,
  trainingReportId: ''
});

export const TrainingProvider = ({ children, value }) => {
  return <TrainingsContext.Provider value={value}>{children}</TrainingsContext.Provider>;
};

export const useTrainingsContext = () => useContext(TrainingsContext);
