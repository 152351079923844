// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Cybersecurity = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3765 23.629C10.8031 23.8794 11.2854 24 11.7677 24C12.2499 24 12.7322 23.8702 13.1588 23.629C18.9461 20.2902 22.5353 14.067 22.5353 7.38942C22.5353 6.39706 22.0067 5.46962 21.1441 4.97807L13.1588 0.36866C12.2963 -0.122887 11.239 -0.122887 10.3765 0.36866L2.39117 4.97807C1.52864 5.47889 1 6.39706 1 7.38942C1 14.067 4.59849 20.2902 10.3765 23.629ZM11.3039 1.97314C11.443 1.88967 11.61 1.85257 11.7677 1.85257C11.9253 1.85257 12.0923 1.89894 12.2314 1.97314L20.2167 6.58255C20.5042 6.74949 20.6804 7.05554 20.6804 7.38942C20.6804 13.3993 17.4436 19.0103 12.2314 22.0153C11.9439 22.1822 11.5914 22.1822 11.3039 22.0153C6.09168 19.0103 2.85489 13.4086 2.85489 7.38942C2.85489 7.05554 3.03111 6.74949 3.31862 6.58255L11.3039 1.97314ZM15.4403 8.45603C15.802 8.09433 16.3862 8.09433 16.7479 8.45603C17.1097 8.81774 17.1097 9.40203 16.7665 9.773L10.2651 16.2744C10.0796 16.4506 9.84775 16.5434 9.60661 16.5434C9.47677 16.5434 9.3562 16.5063 9.23563 16.4599C9.12434 16.4135 9.01304 16.3486 8.9203 16.2558L6.07304 13.4086C5.71133 13.0469 5.71133 12.4626 6.07304 12.1009C6.43474 11.7392 7.01903 11.7392 7.38074 12.1009L9.58806 14.3082L15.4403 8.45603Z" />
  </SvgIconStyled>
);

export default React.memo(Cybersecurity);
