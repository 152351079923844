const trainingTrainings = {
  defaultSortValue: {
    sortname: 'published',
    order: 'desc',
  },
  selecting: false,
  sort: ['name', 'published'],
  rowClick: false,
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'published',
      label: 'Published',
      align: 'left',
    },
    {
      id: 'view',
      label: 'View',
      align: 'left',
    },
  ],
};

export default trainingTrainings;
