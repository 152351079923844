import { CustomTooltip, Button } from 'components';
import React from 'react';
import styled from 'styled-components';

const ButtonOutlined = styled(Button)`
  ${({ color, clickable }) => `
  && {
    padding-left: 12px;
    padding-right: 12px;
    background: transparent;
    border: 1.6px solid ${clickable ? color : 'var(--colorDefault)'};
    & > span {
      color: var(--colorDefault);
    }
    &:hover {
      background: transparent;
      border: 1.6px solid ${clickable ? color : 'var(--colorDefault)'};
    }
  }
`}
`;

const BadgeIcon = styled.div`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 14px;
`;

const StatusButton = ({
  label,
  clickable = true,
  disabled = false,
  onClick = () => {},
  inactiveOnClick = () => {},
  status = 'inactive',
  tooltip,
  style
}) => {
  const color = {
    active: 'var(--colorSystemSuccess)',
    inactive: 'var(--colorSystemDanger)'
  }[status];

  const button = (
    <ButtonOutlined
      {...{
        color,
        onClick: clickable && status === 'active' ? onClick : inactiveOnClick,
        clickable,
        textTransform: false
      }}
      disabled={disabled}
      style={style}
    >
      {label}
      <BadgeIcon color={color} />
    </ButtonOutlined>
  );

  return !tooltip?.title ? (
    button
  ) : (
    <CustomTooltip arrow light title={tooltip?.title} placement={tooltip?.placement || 'top'}>
      {button}
    </CustomTooltip>
  );
};

export default StatusButton;
