// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components';
import Indicator from 'components/charts/Indicator';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      background: 'var(--backgroundPaper)',
      borderRadius: 'var(--borderRadius)',
      padding: '25px 15px',
      marginTop: 10
    }
  },
  resultIcon: {
    width: 105,
    height: 105,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 186,
      height: 186,
      marginTop: 0
    }
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 700,
    margin: '5px 0',
    color: props => (props.rightAnswers <= 2 ? 'var(--colorSystemDanger)' : 'var(--colorSystemSuccess)'),
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      margin: '16px 0'
    }
  },
  description: {
    margin: 0,
    color: 'var(--colorDarkGrey)',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  }
}));

const Result = ({ score, rightAnswers, questionsLength, isCompleted, isSubmitted }) => {
  const classes = useStyles({ rightAnswers });

  return (
    isCompleted ? (
      <div className={classes.container}>
        <Indicator title={score ? `${score}%` : ''} value={Number(score)} className={classes.resultIcon} />
        <h3 className={classes.title}>
          {
            rightAnswers === 4
            ? "Perfect!"
            : rightAnswers === 3
            ? "Good Job!"
            : "Nice Try!"
          }
        </h3>
        <p className={classes.description}>
          You answered {rightAnswers} out of {questionsLength} questions correct
        </p>
      </div>
    ) : (
      isSubmitted && (
        <div className={classes.container}>
          <Typography.p textAlign="center"> Loading Score ... </Typography.p>
        </div>
      )
    )
  );
};

export default React.memo(Result);
