import React, { useState } from 'react';
import { LoadingStyled } from 'components';
import { TrainingProvider } from './TrainingContext';
import TrainingTable from './TriningTable';
import useTraining from './useTraining';
import TrainingModal from './TrainingModal';
import TrainingPrintModal from './TrainingPrintModal';

const Training = () => {
  const [trainingModalData, setTrainingModalData] = useState({ open: false, id: null });
  const [trainingPrintData, setTrainingPrintData] = useState({ open: false, id: null, trainingId: null });

  const { dispatch, record, params, filterString } = useTraining();

  const { trainingFilterData, requestReportType } = record;

  if (trainingFilterData.loading) {
    return <LoadingStyled />;
  }

  return (
    <TrainingProvider
      value={{
        params,
        filterString,
        record,
        dispatch: {
          ...dispatch,
          setTrainingModalData,
          setTrainingPrintData
        }
      }}
    >
      <TrainingPrintModal
        open={trainingPrintData.open}
        onClose={() => setTrainingPrintData(prev => ({ ...prev, open: false }))}
        userId={trainingPrintData.id}
        trainingId={trainingPrintData.trainingId}
      />

      <TrainingModal
        id={trainingModalData.id}
        open={trainingModalData.open}
        onClose={() => setTrainingModalData(prev => ({ ...prev, open: false }))}
        source={requestReportType}
      />

      <TrainingTable />
    </TrainingProvider>
  );
};

export default Training;
