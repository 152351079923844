// @flow
import React, { type Element, type Context, createContext, useContext, useState, useCallback } from 'react';

type DashboardContextType = {
  dispatch: {
    setBox: Function,
    getBox: Function
  }
};

type DashboardProviderType = {
  children: any
};

const DashboardContext: Context<DashboardContextType> = createContext({
  dispatch: {
    setBox: e => e,
    getBox: e => e
  }
});

export const DashboardProvider = ({ children }: DashboardProviderType): Element<*> => {
  const [box, setBox] = useState({
    open: false,
    box: ''
  });

  const dispatch = {};
  dispatch.setBox = useCallback((e: Object) => setBox(e), []);
  dispatch.getBox = useCallback(() => box, [box]);

  return <DashboardContext.Provider value={{ dispatch }}>{children}</DashboardContext.Provider>;
};

export const useDashboard = () => useContext(DashboardContext);
