import React, { useMemo, cloneElement } from 'react';
import { 
  InfoStep,
  CompleteStep,
  NewsletterUploadStep,
  SuccessModal,
  StepsContainer,
} from '../../components';
import { useNewsletterAddEdit } from '../../hooks';

const STEP_NAMES = ['Information', 'Upload document', 'Complete'];

const NewslettersAddEdit = () => {
  const newsletterProps = useNewsletterAddEdit(STEP_NAMES);

  const components = [<InfoStep />, <NewsletterUploadStep />, <CompleteStep />];

  const stepComponents = useMemo(
    () => components.map((component, idx) => {
      return cloneElement(component, { stepNumber: idx + 1, ...newsletterProps })
    }), [components, newsletterProps]
  );

  return (
    <>
      <StepsContainer
        activeStep={newsletterProps.activeStep}
        completed={newsletterProps.completed}
        stepLabels={STEP_NAMES}
        stepComponents={stepComponents}
        loading={newsletterProps.loading}
      />

      <SuccessModal
        onClose={newsletterProps.dispatch.onCloseModal}
        config={newsletterProps.successModal}
      />
    </>
  )
}

export default NewslettersAddEdit;