import { useReducer } from 'react';
import { useQuery } from 'react-query';
import { dataFetch, isObjectEmpty, dataQueryName } from 'helpers';
import { BLOCKED_TAB_OPEN_REQUEST } from 'conf';
import useId from './useId';

function useDataGet({ app, tab, item: Item, page, params = {}, isList = false, options = {}, transform = e => e }) {
  const newParams = getParams(isList, params);
  const UseId = () => useId({ app, tab });
  const item = Item || UseId();

  const [state, dispatch] = useReducer(reducer, {
    app,
    tab,
    item,
    params: newParams
  });
  const queryName = dataQueryName({ app, tab, item, params: newParams });

  const { isSuccess, data: record, refetch: queryRefetch, ...query } = useQuery(
    queryName,
    async () => {
      if (app in BLOCKED_TAB_OPEN_REQUEST && BLOCKED_TAB_OPEN_REQUEST[app].includes(tab)) {
        return { data: null, total: 0 };
      }
      const { data, headers } = isMaskedApiCall({ app, tab })
        ? await maskingApiCall()
        : await dataFetch({ app, tab, item, params: newParams });
      return { data, total: headers['x-total-count'] };
    },
    {
      ...options,
      keepPreviousData: options.keepPreviousData || isList,
      staleTime: options?.staleTime === 0 ? 0 : options?.staleTime || 1000000,
      refetchOnWindowFocus: false
    }
  );

  const refetch = () => {
    dispatch({
      type: 'SETREFETCH',
      payload: {
        params: newParams,
        queryName: dataQueryName({ app, tab, item, params: newParams })
      }
    });
    queryRefetch();
  };

  return {
    ...query,
    data: isSuccess ? transform(record.data) : null,
    total: isSuccess ? record.total : 0,
    isSuccess,
    dispatch,
    refetch
  };
}

function reducer(previousState, { type, payload }) {
  const prev = { ...previousState };
  switch (type) {
    case 'SETPAGE': {
      prev.params = {
        ...prev.params,
        pagination: {
          page: payload,
          perPage: 25
        }
      };
      return prev;
    }

    case 'SETFILTER': {
      prev.params = payload;
      return prev;
    }

    default: {
      return { ...previousState, ...payload };
    }
  }
}

function getParams(isList, params) {
  if (isList && isObjectEmpty(params)) {
    return {
      pagination: {
        page: 1,
        perPage: 25
      },
      ...params
    };
  }
  return params;
}
/**
 * returns true for the endpoints that
 * we don't the tabsContext to call automatically.
 * @returns Boolean
 */
function isMaskedApiCall({ app, tab }) {
  const maskedEndPoint = [
    {
      app: 'myDashboard',
      tab: 'dashboard'
    },
    {
      app: 'myDashboard',
      tab: 'darkWeb'
    },
    {
      app: 'myCompany',
      tab: 'dashboard'
    },
    {
      app: 'myCompany',
      tab: 'documents'
    },
    {
      app: 'clients',
      tab: 'dashboard'
    },
    {
      app: 'clients',
      tab: 'documents'
    },
    {
      app: 'myCompany',
      tab: 'policies'
    },
    {
      app: 'myCompany',
      tab: 'employees'
    },
    {
      app: 'myCompany',
      tab: 'sraDocuments'
    },
    {
      app: 'clients',
      tab: 'policies'
    },
    {
      app: 'clients',
      tab: 'complianceDashboard'
    },
    {
      app: 'myCompany',
      tab: 'complianceDashboard'
    }
  ];
  return maskedEndPoint.some(endpoint => {
    return endpoint.app === app && endpoint.tab === tab;
  });
}
/**
 * called instead of the real fetcher function
 * @returns an empty data object to be utilized by the useDataGet hook without raising an error
 */
async function maskingApiCall() {
  return { data: [], headers: { 'x-total-count': 0 } };
}

export default useDataGet;
