import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Plus, AddFilter, Search } from 'components';
import {
  CardPageLayoutCreateButton,
  CardPageLayoutHeaderBox,
  CardPageLayoutHeaderContainer,
  SearchField,
  SelectFieldStyled,
  SelectLabel
} from '../../style';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useCardPageContext } from '../../context';
import {
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_ROOT_PATH
} from '../../constants';
import { useDebounce } from 'hooks';
import { getButtonName } from '../../helpers';
import { useContentAdminPageFilter } from '../../hooks';
import { InputAdornment } from '@material-ui/core';

const CardPageLayoutHeader = () => {
  const history = useHistory();
  const { pagination, dispatch, search, pagePath, filters } = useCardPageContext();

  const [searchValue, setSearchValue] = useState(search);

  const { limit } = pagination;

  const [searchValueDebounce] = useDebounce({ value: searchValue, delay: 500 });

  const handleChangeSearch = value => {
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch.onChange('search', searchValueDebounce.value);

    dispatch.onChange('page', 1);
  }, [searchValueDebounce]);

  const handleCreate = () => {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${pagePath}/create`);
  };

  const handleChangeLimit = value => {
    dispatch.onChange('limit', value);
    dispatch.onChange('page', 1);
  };

  const handleChangeFilter = (name, value) => {
    dispatch.changeFilter(name, value);
  };

  const buttonName = getButtonName(pagePath);
  const { filterItems } = useContentAdminPageFilter();

  return (
    <CardPageLayoutHeaderContainer justifyContent={'space-between'} alignItems={'center'}>
      <CardPageLayoutHeaderBox container spacing={2} xs={10} alignItems={'center'}>
        <CardPageLayoutHeaderBox xs={4} item>
          <SearchField
            id="search"
            placeholder={'Search'}
            onChange={e => handleChangeSearch(e.target.value)}
            value={searchValue}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </CardPageLayoutHeaderBox>

        <AddFilter values={filters} buttonFullWidth onChange={handleChangeFilter} config={filterItems} />

        <CardPageLayoutHeaderBox xs={3} alignItems={'center'} pl={2}>
         <span id="showItem">Show:</span> 
        <span>
          <Select
            labelId="showItem"
            id="show-item-select"
            value={limit}
            onChange={e => handleChangeLimit(e.target.value)}
            fullWidth
          >
            <MenuItem value={9}>9 entries</MenuItem>
            <MenuItem value={12}>12 entries</MenuItem>
            <MenuItem value={15}>15 entries</MenuItem>
            <MenuItem value={21}>21 entries</MenuItem>
          </Select>
          </span>
        </CardPageLayoutHeaderBox>
      </CardPageLayoutHeaderBox>

      <CardPageLayoutHeaderBox xs={2} justifyContent={'flex-end'}>
        <CardPageLayoutCreateButton onClick={handleCreate}>
          <Plus />
          New {buttonName}
        </CardPageLayoutCreateButton>
      </CardPageLayoutHeaderBox>
    </CardPageLayoutHeaderContainer>
  );
};

export default CardPageLayoutHeader;
