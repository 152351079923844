// @flow
import { MyDashboard as MyDashboardIcon } from 'components/icons';
import DashboardList from './DashboardList';

export default {
  name: 'myDashboard',
  list: DashboardList,
  icon: MyDashboardIcon,
  options: {
    label: 'My Dashboard'
  }
};
