import React from 'react';
import { Card } from '@material-ui/core';

// custom components
import LoadingStyled from 'components/types/Loading';
import { Indicator as ProgressIndicator } from 'components/charts';
import { Clock, Timer } from 'components';

import QuestionsStepper from './QuestionsStepper';
import QuestionAnswers from './QuestionAnswers';
import { NextButton, PreviousButton, SubmitButton } from './Buttons';
import useQuiz from './useQuiz';

const QuizViewer = ({
  quizId,
  quizToken,
  onQuizEnd = () => {}, // executes after quiz is submitted. it is passed the 'score' as an argument
  onError = () => {}, // executes when an error is caught
  isPre = false, // TODO: Remove when we have a common design for both preassessment and darkweb quiz
  containerStyle // TODO: find a better way to automate container width and responsiveness
}) => {
  const { state, handle, isSubmitDisabled, isNextDisabled, isActionsDisbled } = useQuiz({
    quizId,
    quizToken,
    onQuizEnd,
    onError
  });

  if (state.isQuizLoading) return <LoadingStyled text="Loading Quiz..." />;
  if (state.isQuizFinished) return <div>You have completed this quiz.</div>;

  return (
    <div style={{ padding: '1rem', background: 'var(--backgroundDefault)', ...containerStyle }}>
      {!isPre && state.allowQuestionsNavigation && (
        <QuestionsStepper
          questionId={state.questionId}
          questionsStatus={state.questionsStatus}
          gotoQuestion={handle.gotoQuestion}
          disabled={isActionsDisbled}
        />
      )}

      {isPre && state.showTimeElapsed && (
        <Card
          style={{
            padding: '18px 32px',
            boxShadow: 'none !important',
            marginBottom: 'var(--spacing)'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Clock style={{ color: 'var(--colorSystemInfoHover)' }} />
            <p
              style={{
                color: 'var(--colorSystemInfoHover)',
                margin: '0 0 0 var(--spacing)',
                fontSize: '14px',
                fontWeight: '400'
              }}
            >
              Time Elapsed <Timer pause={state.isQuizFinished} seconds={state.timeElapsed} />
            </p>
          </div>
        </Card>
      )}

      <Card style={{ padding: '2%' }}>
        {state.showCategories && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #F6F6F6'
            }}
          >
            <div>
              <h3
                style={{
                  fontSize: '18px',
                  lineHeight: '22px',
                  color: 'var(--colorGreyLight1)',
                  margin: '0 0 3px 0'
                }}
              >
                {state.quizTitle}
              </h3>
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '25px',
                  color: 'var(--colorGreyLight2)',
                  margin: '0'
                }}
              >
                Category {state.questionCategoryNumber} / {state.categoriesCount} - {state.questionCategoryName}
              </p>
            </div>

            {state.showProgress && (
              <div style={{ marginLeft: '1rem' }}>
                <ProgressIndicator title={`${state.quizProgress}%`} value={+state.quizProgress} />
              </div>
            )}
          </div>
        )}
        {state.isQuestionLoading ? (
          <LoadingStyled text="Loading Question..." />
        ) : (
          <>
            {isPre && (
              <p style={{ color: 'var(--colorDefault)' }}>{`${state.questionNumber}. ${state.questionText}`}</p>
            )}

            {!isPre && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ color: 'var(--colorDefault)' }}>
                  <h3
                    style={{
                      margin: 0 // category change
                    }}
                  >
                    Question {state.questionNumber}
                  </h3>
                  <p>{state.questionText}</p>
                </div>

                {state.showProgress && (
                  <div style={{ marginLeft: '1rem' }}>
                    <ProgressIndicator title={`${state.quizProgress}%`} value={+state.quizProgress} />
                  </div>
                )}
              </div>
            )}

            <QuestionAnswers
              answers={state.questionAnswers}
              selectedAnswerId={state.selectedAnswerId}
              handleAnswerQuestion={handle.answerQuestion}
            />
          </>
        )}
      </Card>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          margin: '2rem 0 1rem'
        }}
      >
        {state.isLastQuestion ? (
          <SubmitButton onClick={handle.submitQuiz} disabled={isActionsDisbled || isSubmitDisabled} />
        ) : (
          <NextButton onClick={handle.nextQuestion} disabled={isActionsDisbled || isNextDisabled} />
        )}
        {state.allowQuestionsNavigation && !state.isFirstQuestion && (
          <PreviousButton onClick={handle.prevQuestion} disabled={isActionsDisbled} />
        )}
      </div>
    </div>
  );
};

export default QuizViewer;
