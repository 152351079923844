// @flow
import React, { useState } from 'react';
import { IconButton, Menu, makeStyles } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { EditIcon, DeleteIcon, Flag } from 'components';
import { FlagModal } from '../modals';
import { MenuLink } from '../common';

const useStyles = makeStyles(theme => ({
  menuBtn: {
    marginRight: '-12px',
    '& svg': {
      fontSize: 20,
      color: 'var(--colorIcon)'
    }
  }
}));

const PostCommentActions = ({
  comment,
  showDeleteModal,
  toggleUpdate,
  isOwnComment,
  postId,
  canFlag,
  commentsCount
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFlagModal, setOpenFlagModal] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editComment = () => {
    toggleUpdate();
    handleClose();
  };

  const flagComment = () => {
    setOpenFlagModal(true);
    handleClose();
  };

  const deleteComment = () => {
    showDeleteModal();
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`long-menu-comments${comment.id}`}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={comment.disableActions}
        className={classes.menuBtn}
        component="span"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`long-menu-comments${comment.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={openFlagModal}
        PaperProps={{
          style: { boxShadow: '0 6px 25px 0 rgba(83, 83, 83, 0.07)' }
        }}
      >
        {/* Edit */}
        {isOwnComment && (
          <MenuLink onClick={editComment}>
            <EditIcon size={1.7} /> Edit
          </MenuLink>
        )}

        {/* Flag */}
        {canFlag && !isOwnComment && (
          <MenuLink onClick={flagComment}>
            <Flag size={1.7} /> Flag
          </MenuLink>
        )}
        {openFlagModal && (
          <FlagModal
            close={() => setOpenFlagModal(false)}
            open={openFlagModal}
            type="comment"
            postId={postId}
            commentId={comment.id}
            commentsCount={commentsCount}
          />
        )}

        {/* Delete */}
        {isOwnComment && (
          <MenuLink onClick={deleteComment} className="danger">
            <DeleteIcon size={1.7} /> Delete
          </MenuLink>
        )}
      </Menu>
    </>
  );
};

export default PostCommentActions;
