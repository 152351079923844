// @flow
import React, { type ComponentType } from 'react';
import styled, { keyframes } from 'styled-components';
import { ChartColorPercent } from 'helpers';
import useBreakpoint from '../../hooks/useBreakpoint';
import { Hidden } from '@material-ui/core';

const barAnimation = keyframes`
  from {
    stroke-dashoffset: var(--fill);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const ContainerBar: ComponentType<*> = styled.div`
  width: 100%;

  ${props => props.theme.breakpoints.down("sm")} {
    margin: 20px 0;
  }

`;

const SvgBar: ComponentType<*> = styled.svg`
  --path: 100%;
  --fill: ${({ fillValue }) => `${fillValue}%`};
  --none: transparent;
`;

const LineBarBackground: ComponentType<*> = styled.line`
  stroke-width: var(--spacing);
  stroke-linecap: round;
  stroke: var(--colorGreyLight3);
  stroke-dasharray: var(--path);
`;

const LineBarForeground: ComponentType<*> = styled.line`
  stroke-width: var(--spacing);
  stroke-linecap: round;
  stroke: ${({ fillValue, invert, colorType1 }) =>
    `var(--colorSystem${ChartColorPercent(fillValue, invert, colorType1)})`};
  stroke-dasharray: var(--fill) calc(var(--path) * 2);
  stroke-dashoffset: var(--fill) calc(var(--path) * 2);
  animation: ${barAnimation} 1s linear alternate;
`;

const LineBarTextTitle: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) * 1.2);
  font-weight: var(--fontWeightRegular);
  fill: var(--colorDefault);
`;

const LineBarTextName: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) * 1.4);
  font-weight: var(--fontWeightMedium);
  fill: var(--colorDark);
`;

const LineBarTextLabel: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) * 1);
  font-weight: var(--fontWeightRegular);
  fill: var(--colorDefault);
`;

type ChartBarTypes = {
  title: string,
  chart: Object,
  labels?: boolean | null,
  invert?: boolean | null,
  colorType1?: boolean | null
};

const ChartBar = ({ title, chart, labels, invert, colorType1, className, isComplete = false, height }: ChartBarTypes) => {
  const mobileView = useBreakpoint("sm");

  const maxValue = chart[1].value;
  const chartValue = !maxValue ? 0 : (chart[0].value * 100) / maxValue;

  return (
    <ContainerBar className={className}>
      {labels && (
        <Hidden mdUp>
          <h3>{title}</h3>
        </Hidden>
      )}
      <SvgBar viewBox={`0 ${mobileView ? "0" : "-20"} 350 ${height ? height : "100"}`} fillValue={chartValue}>
        {labels && (
          <>
            <Hidden smDown>
              <LineBarTextTitle x="45" y="-7">
                {title}
              </LineBarTextTitle>
            </Hidden>
            <LineBarTextName x="45" y="30">
              {chart[0].name}
            </LineBarTextName>
          </>
        )}
        <LineBarBackground x1="50" y1={labels ? '50' : '25'} x2="350" y2={labels ? '50' : '25'} />
        <LineBarForeground
          x1="50"
          y1={labels ? '50' : '25'}
          x2="350"
          y2={labels ? '50' : '25'}
          fillValue={chartValue}
          invert={invert}
          colorType1={colorType1}
        />
        {labels && (
          <LineBarTextLabel x={isComplete ? "148" : "275"} y="70">
            {chart[1].name}
          </LineBarTextLabel>
        )}
      </SvgBar>
    </ContainerBar>
  );
};

ChartBar.defaultProps = {
  labels: false,
  invert: false,
  colorType1: false
};

export default ChartBar;
