import apiClient from '../../apiClient/apiClient';
import { DOMAIN_URL } from '../utils/constants';
import { getHeaders } from '../utils/utils';

const BASE_PATH = `${DOMAIN_URL}/bsnclientservices`;

export const getClientsList = async (partnerId, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/list/${partnerId}`,
    headers: getHeaders(),
    params
  };
  return apiClient.get(requestOptions);
};

export const activateDeactivateClient = async (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};
 
export const handleClientBulkAction = async (partnerId, { ...props }) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${partnerId}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.post(requestOptions);
};

export const deleteClient = async clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.delete(requestOptions);
};

export const enableDisableWelcomeMessage = async (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/sendAutomated/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.post(requestOptions);
};

export const manageClientActions = (id, data, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${id}`,
    data,
    headers: getHeaders(),
    params
  };
  return apiClient.post(requestOptions);
};

export const deleteBulkClient = (partnerId, data, params) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/${partnerId}`,
    data,
    headers: getHeaders(),
    params
  };
  return apiClient.delete(requestOptions);
};

export const applyClientAction = (clientId, action, systemEmailsEnabled) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/actions/${clientId}`,
    headers: getHeaders(),
    data: {
      type: action,
      system_emails_status: systemEmailsEnabled
    }
  };
  return apiClient.post(requestOptions);
};

export const getMTNLData = (id, ...props) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/bulk-actions/mt-newsletter-engagement-status/${id}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.get(requestOptions);
};

export const enableNewsLetters = (clientId, data) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/information/options/${clientId}`,
    headers: getHeaders(),
    data
  };
  return apiClient.patch(requestOptions);
};

export const getWelcomeMsgInfo = (id, ...props) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/directorySync/${id}`,
    headers: getHeaders(),
    ...props
  };
  return apiClient.get(requestOptions);
};

export const sendTeamsAppAnnouncement = (clientId, usersList = null) => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/teams-app/notification/${clientId}`,
    headers: getHeaders()
  };
  if (usersList !== null) {
    requestOptions.data = { selected_users: usersList };
  }
  return apiClient.post(requestOptions);
};

export const getTeamsAppUnregisteredUsers = clientId => {
  const requestOptions = {
    url: `${BASE_PATH}/clients/teams-app/unregistered-users/${clientId}`,
    headers: getHeaders()
  };
  return apiClient.get(requestOptions);
};
