import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useLocation } from 'hooks';
import { Container, DateField, LoadingStyled } from 'components';
import { AccordionFooter } from '../../../apps/admin/components';
import { admin as adminApi } from 'helpers';


const AdminCapabilitiesInsurance = () => {
  const notify = useNotify();
  const { item } = useLocation();

  const [cancelDate, setCancelDate] = useState(null);
  const [loadingInsurance, setLoadingInsurance] = useState(false);

  const getInsuranceData = useCallback(() => {
    setLoadingInsurance(true);
    adminApi
      .getInsuranceCancellation(item)
      .then((resp) => setCancelDate(resp.data.cancellation_date))
      .catch((err) => notify(err?.response?.data?.status || 'Something went wrong', 'error'))
      .finally(() => setLoadingInsurance(false));
  }, [item]);

  useEffect(() => {
    getInsuranceData();
  }, [getInsuranceData]);

  const save = () => {
    setLoadingInsurance(true);
    const data = { cancel_date: cancelDate };
    adminApi.createInsuranceCancellation(item, data)
      .then((resp) => notify(resp.data?.message || 'The information was successfully updated', 'info'))
      .catch((err) => notify(err?.response?.data?.status || 'Something went wrong', 'error'))
      .finally(() => setLoadingInsurance(false));
  };

  if (loadingInsurance) return (
    <Container.Grid justifyContent="center">
      <LoadingStyled />
    </Container.Grid>
  );

  return (
    <Container.Grid container md={12} spacing={2}>
      <Container.Grid item md={5} lg={4} mt={1}>
        <DateField
          name="cancel_date"
          label="Cancel Cyber Insurance "
          value={cancelDate}
          onChange={(e) => setCancelDate(e)}
          fullWidth
        />
      </Container.Grid>
      <AccordionFooter onSubmit={save} disabled={loadingInsurance} />
    </Container.Grid>
  );
};

export default AdminCapabilitiesInsurance;