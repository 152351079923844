// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const PlaySquareOutline = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1538 2.76923C22.1538 2.26154 21.7385 1.84615 21.2308 1.84615H2.76923C2.26154 1.84615 1.84615 2.26154 1.84615 2.76923V21.2308C1.84615 21.7385 2.26154 22.1538 2.76923 22.1538H21.2308C21.7385 22.1538 22.1538 21.7385 22.1538 21.2308V2.76923ZM2.76923 0H21.2308C22.7538 0 24 1.24615 24 2.76923V21.2308C24 22.7538 22.7538 24 21.2308 24H2.76923C1.24615 24 0 22.7538 0 21.2308V2.76923C0 1.24615 1.24615 0 2.76923 0ZM8.64932 8.01228C8.54778 7.94767 8.43703 7.91998 8.32627 7.91998C7.98473 7.91998 7.67086 8.18767 7.67086 8.56613V12.6738V16.7815C7.67086 17.16 7.98473 17.4369 8.32627 17.4369C8.4278 17.4369 8.54778 17.4092 8.64932 17.3446L12.2309 15.2954L15.8124 13.2461C16.2463 12.9877 16.2463 12.36 15.8124 12.1107L12.2309 10.0615L8.64932 8.01228ZM5.82471 8.57536C5.82471 7.19998 6.94165 6.07382 8.32627 6.07382C8.76011 6.07382 9.19394 6.18459 9.5724 6.42459L13.1539 8.47382L16.7355 10.5231C17.5109 10.9661 17.9909 11.7969 17.9909 12.6923C17.9909 13.5877 17.5109 14.4184 16.7355 14.8615L13.1539 16.9107L9.5724 18.96C9.19394 19.1815 8.76011 19.2923 8.32627 19.2923C6.95088 19.2923 5.82471 18.1661 5.82471 16.7907V12.6831V8.57536Z"
    />
  </SvgIconStyled>
);

export default React.memo(PlaySquareOutline);
