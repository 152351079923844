// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Heart = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 23.433 21" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h23.433v21H0z" />
    <path
      d="M32.6,16.94a6.089,6.089,0,0,0-8.84,0l-1.06,1.1-1.06-1.108a6.1,6.1,0,0,0-8.84,0,6.65,6.65,0,0,0,0,9.131l9.244,9.694a.9.9,0,0,0,1.274.031l.031-.031L32.6,26.071A6.646,6.646,0,0,0,32.6,16.94Zm-1.3,7.892q-4.3,4.5-8.6,8.99l-8.6-9a4.853,4.853,0,0,1,0-6.644,4.218,4.218,0,0,1,6.241,0l1.708,1.792A.9.9,0,0,0,23.327,20l.031-.031,1.7-1.783a4.224,4.224,0,0,1,6.241,0A4.852,4.852,0,0,1,31.3,24.832Z"
      transform="translate(-10.985 -15.035)"
    />
  </SvgIconStyled>
);

export default Heart;
