// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ScrollLeft = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 23.9 19.7" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h23.9v19.7H0z" />
    <g>
      <path d="M23.8,9.3c0-1.1-0.9-2.1-2-2.1c-0.2,0-0.5,0-0.7,0.1c-0.1-1.1-1-1.9-2-1.8c-0.3,0-0.6,0.1-0.9,0.2   c-0.4-0.6-1-1-1.8-1c-0.2,0-0.5,0-0.7,0.1V1.9c-0.1-1.1-1-2-2.2-1.9c-1,0.1-1.8,0.9-1.9,1.9v7.9c-1.1,0-2,0.7-2.3,1.7   c-0.3,1.5-0.2,3,0.3,4.4c0.6,1.5,1.7,2.8,3,3.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2c0.2-0.3,0.2-0.7,0-0.9c0,0,0,0,0,0   c-1.1-0.8-2-1.9-2.6-3.1c-0.5-1.2-0.6-2.5-0.3-3.7c0.1-0.4,0.6-0.7,1-0.7v1.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.6-0.3,0.7-0.7V1.9   c0-0.4,0.4-0.6,0.7-0.6c0.3,0,0.6,0.3,0.6,0.6v7.3c0,0.4,0.3,0.7,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6V6.6c0-0.4,0.4-0.6,0.7-0.6   c0.3,0,0.6,0.3,0.6,0.6v2.9c0,0.4,0.3,0.7,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6V7.4c0-0.4,0.4-0.6,0.7-0.6c0.3,0,0.6,0.3,0.6,0.6v2.8   c0,0.4,0.3,0.7,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6V9.3c-0.1-0.4,0.2-0.7,0.5-0.8c0.4-0.1,0.7,0.2,0.8,0.5c0,0.1,0,0.2,0,0.3   c0,0,0,0,0,0c0,0.1,0.4,5.6-1.5,9.5c-0.1,0.3,0,0.7,0.3,0.8c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.1,0.6-0.4   C24.2,15.3,23.9,9.8,23.8,9.3z" />
      <path d="M6.6,1.8H1.8l0.3-0.3c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0L0.2,1.9c0,0,0,0.1-0.1,0.1   c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1   c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1l1.2,1.2C1.5,4,1.9,4,2.1,3.8c0.2-0.2,0.2-0.5,0-0.7L1.8,2.8h4.8c0.3,0,0.5-0.2,0.5-0.5   C7.1,2,6.9,1.8,6.6,1.8z" />
    </g>
  </SvgIconStyled>
);

export default ScrollLeft;
