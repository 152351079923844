import React from 'react';
import {
  RHFTextField as TextField,
  RHFSelectField as SelectField,
  RHFSwitch as Switch,
  RHFAutoCompleteAsync as AutoCompleteAsync,
  RHFAutoComplete as AutoComplete,
  InfoButtonWithPopover,
  LazyIcon,
  QuestionMark
} from 'components';
import contentAdmin from 'helpers/apis/contentAdmin';
import { isValidEmailLocalPart } from 'helpers';
import { IconButton, InputAdornment } from '@material-ui/core';
import {
  ContentAdminFormGrid,
  ContentAdminFormRow,
  EmailLocalPart,
  EmailLocalPartTitle,
  EmailPopoverContainer,
  EmailPopoverFromName,
  EmailPopoverFromNameIcon,
  EmailPopoverFromNameTitle,
  EmailPopoverTitle,
  SubTitle
} from '../../style';
import { useContentAdminFormContext } from '../../context';
import {
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_DOMAINS,
  CONTENT_ADMIN_PICKLIST_TAGS,
  EMAIL_LOCAL_PART,
  FROM_NAME
} from '../../constants';

const EmailPopover = ({ variant, title }) => {
  console.log('variant', variant);
  return (
    <EmailPopoverContainer>
      <EmailPopoverTitle>{title}</EmailPopoverTitle>
      <EmailPopoverFromName>
        <EmailPopoverFromNameIcon>JS</EmailPopoverFromNameIcon>
        <EmailPopoverFromNameTitle active={variant === FROM_NAME}>John Smith</EmailPopoverFromNameTitle>
      </EmailPopoverFromName>
      <EmailLocalPart active={variant === EMAIL_LOCAL_PART}>
        <EmailLocalPartTitle active={variant === EMAIL_LOCAL_PART}>john.smith</EmailLocalPartTitle>
        @domain.com
      </EmailLocalPart>
    </EmailPopoverContainer>
  );
};

// using this immutable object of callbacks to preserve refrences to functions and as dependency api to `AutoCompleteAsync` useEffect preventing unessary rendrers
// alternativly you can prevent functions from taking a new refrence by addding useMemo in component
const AUTOCOMPLETE_API_CB_LIST = Object.freeze({
  templates: params => contentAdmin.getList(`templates`, params),
  landingPages: params => contentAdmin.getList('landing-pages', params)
});

const ScenarioForm = () => {
  const { pickLists } = useContentAdminFormContext();

  return (
    <>
      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <TextField label="Name" name="name" fullWidth required />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <SelectField
            fullWidth
            allowEmpty={false}
            id="selectDifficulty"
            label="Difficulty Level"
            choices={pickLists[CONTENT_ADMIN_PICKLIST_DIFFICULTIES] || []}
            name="difficulty_level_id"
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <AutoCompleteAsync
            fullWidth
            disableCheckbox
            dataPath="data.result"
            id="selectTemplates"
            label="Template "
            labelName="name"
            name="template_id"
            api={AUTOCOMPLETE_API_CB_LIST.templates}
            required
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <AutoCompleteAsync
            dataPath="data.result"
            disableCheckbox
            fullWidth
            id="selectLandingPage"
            label="Landing page"
            name="landing_page_id"
            api={AUTOCOMPLETE_API_CB_LIST.landingPages}
            labelName="name"
            required
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid md={4}>
          <SelectField
            fullWidth
            allowEmpty={false}
            id="selectCountry"
            label="Country"
            choices={pickLists[CONTENT_ADMIN_PICKLIST_COUNTRIES] || []}
            name="country_id"
            required
          />
        </ContentAdminFormGrid>
        <ContentAdminFormGrid md={4}>
          <Switch label="HIPAA" labelPlacement="start" id="switchPublished" name={'is_hipaa'} />
          <InfoButtonWithPopover
            name="hipaa-info"
            tooltip="If turned on, only HIPAA clients will have access to this scenario."
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid md={2}>
          <Switch label="Published" labelPlacement="start" name="is_published" />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow>
        <ContentAdminFormGrid md={4}>
          <TextField
            label="From Name"
            name="from_name"
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InfoButtonWithPopover
                    name="from-name"
                    tooltip={<EmailPopover variant={FROM_NAME} title="Describes who sent the email" />}
                    Icon={QuestionMark}
                  />
                </InputAdornment>
              )
            }}
          />
        </ContentAdminFormGrid>

        <ContentAdminFormGrid md={4}>
          <TextField
            label="Email Local Part"
            name="email_local_part"
            fullWidth
            required
            rules={{
              validate: value => (!isValidEmailLocalPart(value) ? `"${value}" is not a valid local part.` : null)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InfoButtonWithPopover
                    name="from-name"
                    tooltip={<EmailPopover variant={EMAIL_LOCAL_PART} title="Describes the text before the @ symbol" />}
                    Icon={QuestionMark}
                  />
                </InputAdornment>
              )
            }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid sm={12}>
          <SubTitle>Domains</SubTitle>
        </ContentAdminFormGrid>
        <ContentAdminFormGrid md={4}>
          <AutoComplete
            label="Phishing Domains"
            disableCheckbox
            fullWidth
            options={pickLists[CONTENT_ADMIN_PICKLIST_DOMAINS] || []}
            name="phishing_domain_id"
            required
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>

      <ContentAdminFormRow withoutBorder>
        <ContentAdminFormGrid sm={12}>
          <SubTitle>Scenario tags</SubTitle>
        </ContentAdminFormGrid>
        <ContentAdminFormGrid item lg={4} md={6} sm={12}>
          <AutoComplete
            creatable
            forcePopupIcon
            labelName="label"
            label="Scenario tags"
            fullWidth
            multiple
            id="templateTags"
            name="scenario_tags"
            options={pickLists[CONTENT_ADMIN_PICKLIST_TAGS] || []}
            required
            getOptionSelected={(option, value) => {
              const optionType = typeof option === 'string';
              const valueType = typeof value === 'string';
              const currentValue = valueType ? value : value.label;
              const currentOption = optionType ? option : option.label;
              return currentValue === currentOption;
            }}
            // getValue={(options, value) => {
            //   return value ? options.filter(({value: optionId}) => value.find(({value}) => value === optionId)) : value
            // }}
          />
        </ContentAdminFormGrid>
      </ContentAdminFormRow>
    </>
  );
};

export default ScenarioForm;
