// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Consulting = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 23.466 22" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h23.466v22H0z" />
    <g transform="translate(-778 -204)">
      <path
        d="M11.667,26.6a2.2,2.2,0,0,0,2.2-2.2V18.533a2.2,2.2,0,0,0-2.156-2.2,6.6,6.6,0,0,1,6.556-5.867H21.2a6.6,6.6,0,0,1,6.556,5.867,2.2,2.2,0,0,0-2.156,2.2V24.4a2.2,2.2,0,0,0,2.068,2.2A2.2,2.2,0,0,1,25.6,28.066H22.534a2.2,2.2,0,1,0,0,1.467H25.6a3.666,3.666,0,0,0,3.623-3.219,3.666,3.666,0,0,0,2.244-3.381V20a3.666,3.666,0,0,0-2.2-3.366A8.067,8.067,0,0,0,21.2,9H18.266A8.067,8.067,0,0,0,10.2,16.634,3.666,3.666,0,0,0,8,20v2.933A3.667,3.667,0,0,0,11.667,26.6Zm8.8,2.933A.733.733,0,1,1,21.2,28.8.734.734,0,0,1,20.466,29.533ZM30,20v2.933a2.2,2.2,0,0,1-2.2,2.2.734.734,0,0,1-.733-.733V18.533A.734.734,0,0,1,27.8,17.8,2.2,2.2,0,0,1,30,20ZM9.467,20a2.2,2.2,0,0,1,2.2-2.2.734.734,0,0,1,.733.733V24.4a.734.734,0,0,1-.733.733,2.2,2.2,0,0,1-2.2-2.2Z"
        transform="translate(770 195)"
      />
    </g>
  </SvgIconStyled>
);

export default Consulting;
