import React from "react"
import styled from "styled-components"

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;

  .popover-button:hover + .popover-content {
    display: block;
  }
`

const PopoverButton = styled.div`
  position: relative;
`

const PopoverContent = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 5px;
  background-color: #fff;
  display: none;
  box-shadow: 1px 2px 16px rgba(0, 0, 0, .12);
  color: var(--colorGreyLight2);

  & > div {
    white-space: nowrap;
    text-align: center;

    &:not(:last-of-type) {
      margin-bottom: 2px;
    }
  }
`

const CustomPopover = ({children, popoverLabel}) => {
  return (
    <PopoverContainer>
      <PopoverButton className={"popover-button"}>
        {children}
      </PopoverButton>
      <PopoverContent className={"popover-content"}>
        {popoverLabel}
      </PopoverContent>
    </PopoverContainer>
  )
}

export default CustomPopover
