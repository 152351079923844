import React from 'react';
import { AdminCapabilitiesNotes as NotesTable } from 'components';

const PartnersNotes = ({ data }) => {
  return (
    data?.data?.length > 0 && (
     <NotesTable 
      data={data.data}
      isLoading={data.isLoading}
      dispatch={data.dispatch} 
      total={data.total}  
     />
    )
  );  
};

export default PartnersNotes;