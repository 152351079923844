// @flow
import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { FilePreview } from '../common';
import { Typography } from 'components';
import Parser from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  descriptipn: {
    margin: '0 0 20px',
    fontSize: '1.4rem',
    lineHeight: '1.5'
  }
}));

const FeedNewsletter = ({ newsletter }) => {
  const { name, topic_text, quick_tips, attachment_url } = newsletter;
  const classes = useStyles();

  return (
    <>
      <Typography.h3>{name}</Typography.h3>
      {topic_text && <p className={classes.descriptipn}>{Parser(topic_text)}</p>}
      {quick_tips && (
        <>
          <Typography.h3>Quick Tips</Typography.h3>
          <p className={classes.descriptipn}>{Parser(quick_tips)}</p>
        </>
      )}
      <Box fontWeight={500} mb={1.5}>Attachment List (1)</Box>
      <FilePreview url={attachment_url} filename={decodeURIComponent(attachment_url.match(/[^\/]*$/))} />
    </>
  );
};

export default FeedNewsletter;
