// @flow
import React from 'react';
import { Box, Typography, makeStyles, Link, Button, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Delete, Happy } from 'components/icons';
import { Dialog } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '60px 20px',
    textAlign: 'center',
    position: 'relative'
  },
  title: {
    color: 'var(--colorSystemDanger)',
    fontWeight: 'bold',
    marginBottom: 12
  },
  cancelBtn: {
    color: 'var(--colorDefault)',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    fontWeight: 'bold'
  },
  deleteBtn: {
    backgroundColor: '#FF5A61 !important',
    color: '#fff !important',
    textTransform: 'capitalize',
    '&:disabled': {
      backgroundColor: '#ffa6aa !important'
    }
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    borderRadius: '50%',
    width: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  successContent: {
    minHeight: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  successTitle: {
    color: 'var(--colorSystemSuccess)',
    fontWeight: 'bold'
  },
  successIcon: {
    backgroundColor: 'rgba(12, 206, 107, 0.2)',
    borderRadius: '50%',
    width: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const DeleteConfirmationModal = ({
  open,
  close,
  onDelete,
  title,
  description,
  disabledSubmit = false,
  showSuccessDialog = false,
  successMessage,
  additionalText = true
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <div className={classes.content}>
        {showSuccessDialog ? (
          <div className={classes.successContent}>
            <IconButton className={classes.closeBtn} onClick={close}>
              <CloseIcon style={{ fontSize: 25 }} />
            </IconButton>
            <Box pb={2} display="flex" justifyContent="center">
              <span className={classes.successIcon}>
                <Happy size={3.4} color="success" />
              </span>
            </Box>
            <Typography variant="h3" display="block" className={classes.successTitle}>
              {successMessage}
            </Typography>
          </div>
        ) : (
          <>
            <Box pb={3} display="flex" justifyContent="center">
              <span className={classes.deleteIcon}>
                <Delete size={2.4} color="danger" />
              </span>
            </Box>
            <Typography variant="h3" display="block" gutterBottom className={classes.title}>
              {title}
            </Typography>
            <Typography display="block" gutterBottom>
              {description}
            </Typography>
            <Box pb={2.5}>
              {additionalText && (
                <Typography display="block" gutterBottom>
                  Are you sure about that?
                </Typography>
              )}
            </Box>
            <Box pb={3}>
              <Button
                variant="contained"
                disableElevation
                className={classes.deleteBtn}
                onClick={onDelete}
                disabled={disabledSubmit}
              >
                Yes, I Want To Delete
              </Button>
            </Box>
            <Box>
              <Link
                className={classes.cancelBtn}
                href="#"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                color="inherit"
              >
                Cancel
              </Link>
            </Box>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
