// @flow
import React, { memo, /* type ElementRef, */ type Element } from 'react';
import styled from 'styled-components';
import { FormControl as MuiFormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

type RadioFieldTypes = {
  label?: string | null,
  labelPlacement?: 'start' | 'botton' | 'top' | 'end',
  id?: string | number,
  choices: Array<{ value: string, label: string }>,
  fullWidth?: boolean,
  name: string,
  mt?: number | null,
  mb?: number | null,
  value: number | string,
  onChange?: Function,
  readOnly?: boolean
};

const FormControl = styled(({ fullWidth, ...props }) => <MuiFormControl {...props} />)`
  &.BSNFormControl-root {
    min-width: ${({ fullWidth }) => (fullWidth ? 100 : 0)}%;
    ${({ mt }) => mt && `margin-top: ${mt * 8}px`};
    ${({ mb }) => mb && `margin-bottom: ${mb * 8}px`};
  }
`;

const RadioField = ({
  id,
  choices,
  value,
  name,
  label,
  labelPlacement,
  onChange,
  fullWidth,
  mt,
  mb,
  readOnly,
  ...rest
}: RadioFieldTypes): Element<*> => {
  const handleChange = e => {
    typeof onChange === 'function' && onChange(e);
  };

  const labels = label
    ? {
        label,
        labelId: `${id || name}__label`
      }
    : {};

  return (
    <FormControl
      mt={mt}
      mb={mb}
      variant="outlined"
      classes={{ root: 'BSNFormControl-root' }}
      fullWidth={fullWidth}
      component="fieldset"
    >
      <FormLabel id={`${name}--label`}>{label}</FormLabel>
      <RadioGroup {...rest} value={value} id={id} name={name} {...labels} onChange={handleChange}>
        {choices.map((k, i) => (
          <FormControlLabel
            value={k.value}
            control={<Radio />}
            label={k.label}
            labelPlacement={labelPlacement}
            disabled={readOnly}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioField.defaultProps = {
  label: null,
  labelPlacement: 'start',
  id: 0,
  fullWidth: false,
  onChange: e => e,
  mt: null,
  mb: null,
  readOnly: false
};

export default memo<RadioFieldTypes>(RadioField);
