import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  VideoPlayer,
  CloseIcon,
  CircularProgress,
  Divider
} from '@trustsecurenow/components-library';
import clientsAPI from 'helpers/apis/clients';
import { useId } from 'hooks';

function EngagmentDialog({ onClose }) {
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const disabledWelcomeMessagesCount = useSelector(({ bsn }) => bsn?.user?.profile?.disabledWelcomeMessagesCount);
  const partnerId = useId({ key: 'partnerId' });
  const { isEBPP } = useSelector(({ bsn }) => bsn?.user?.profile);
  const footer = `To rectify this, please review the Welcome Message column and enable them to ensure welcome messages will be sent to all new users added and all existing users who have not already received a welcome message or logged in. ${
    isEBPP
      ? 'You can also use the Enable Welcome Messages option in Actions to enable for multiple clients at once.'
      : ''
  }`;
  useEffect(() => {
    clientsAPI
      .getWelcomeMessageVideo(partnerId)
      .then(res => {
        setVideoUrl(res.data.url);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: 760,
        }
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        Finish Onboarding Users
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ height: 'fit-content' }}>
        <Typography variant="subtitle2" mb={3.5}>
          Sending a Welcome Message is essential to ensure that users receive other platform emails and successfully
          complete the onboarding process.
          <br />
          <br />
          {disabledWelcomeMessagesCount > 1
            ? `Currently, ${disabledWelcomeMessagesCount} clients do not have Welcome Messages enabled.`
            : 'Currently, one client does not have Welcome Messages enabled.'}
          <br />
          <br />
          {footer}
        </Typography>
        {loading ? (
          <Box width="100%" height="400px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress shaded size={75} />
          </Box>
        ) : (
          <VideoPlayer url={videoUrl} rounded width="100%" height="auto"/>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EngagmentDialog;
