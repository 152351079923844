import React from 'react';
import { Tabs } from 'components';
import { useClientAccess, useLocation } from 'hooks';

const ClientsEdit = () => {
  const { item } = useLocation();
  const clientAccess = useClientAccess({ id: item });

  return <Tabs id={String(item)} access={clientAccess} />;
};

export default ClientsEdit;
