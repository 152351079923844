import React from 'react'
import { ArrowRightIcon, Box, Button, Divider, Grid, Link, Paper, Typography } from '@trustsecurenow/components-library';

const ContainerStepContent = ({
  children,
  disableSubmit,
  isFirstStep,
  headerTitle,
  headerRigthContent,
  onSubmit,
  onBack,
  submitLabel
}) => {
  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ padding: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Typography variant="h4">
              {headerTitle}
            </Typography>
          </Grid>
          <Grid item xs>
            {headerRigthContent}
          </Grid>
        </Grid>
      </Box>
        
      <Divider />
      
      {children}
        
      <Divider />
        
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {!isFirstStep && (
          <Link
            underline="none"
            style={{ color: 'default', marginRight: '24px' }}
            href="#"
            onClick={e => {
              e.preventDefault();
              onBack();
            }}
            color="inherit"
          >
            Back
          </Link>
        )}
        
        <Button
          color="success"
          onClick={onSubmit}
          disabled={disableSubmit}
          endIcon={<ArrowRightIcon color="white" sx={{ fontSize: 16 }} />}
        >
          {submitLabel ?? 'Save'}
        </Button>
      </Box>
    </Paper>
  )
}

export default ContainerStepContent