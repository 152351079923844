// @flow

import { type ComponentType } from 'react';
import styled from 'styled-components';
import { SelectInput } from 'react-admin';

export const SelectOutlined: ComponentType<*> = styled(SelectInput)`
  margin: 0 15px 0 0;

  & > label {
    background-color: #fff;
    z-index: 1;
    left: 10px;

    & > span {
      padding: 0 7px;
    }
  }

  > div {
    &:before {
      top: -9px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      border-color: rgba(0, 0, 0, 0.23);
      pointer-events: none;
    }

    &:after {
      top: -9px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-style: solid;
      border-width: 2px;
      border-radius: 4px;
      border-color: #545761;
      pointer-events: none;
    }

    & > div {
      top: -5px;
      padding: 5px;
      text-indent: 10px;

      & > div {
        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }
`;
