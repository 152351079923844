// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Complete = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
      <path d="M12 24C5.38154 24 0 18.6185 0 12C0 5.38154 5.38154 0 12 0C18.6185 0 24 5.38154 24 12C24 18.6185 18.6185 24 12 24ZM12 1.84615C6.39692 1.84615 1.84615 6.39692 1.84615 12C1.84615 17.6031 6.39692 22.1538 12 22.1538C17.6031 22.1538 22.1538 17.6031 22.1538 12C22.1538 6.39692 17.5938 1.84615 12 1.84615Z" fill="#535353"/>
      <path d="M17.7784 9.04612C17.4184 8.68612 16.8369 8.68612 16.4769 9.04612L9.72919 15.7938L7.53226 13.5969C7.17226 13.2369 6.59069 13.2369 6.23069 13.5969C5.87069 13.9569 5.87069 14.5384 6.23069 14.8984L9.06455 17.7323C9.15685 17.8246 9.26765 17.8892 9.37842 17.9354C9.49842 17.9815 9.61838 18.0184 9.74761 18.0184C9.98761 18.0184 10.2184 17.9261 10.403 17.7507L17.7969 10.3569C18.1384 9.98766 18.1384 9.40612 17.7784 9.04612Z" fill="#535353"/>
  </SvgIconStyled>
);

export default Complete;
