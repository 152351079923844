import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Container, Eye, TableList, Renew, Update, Typography } from 'components';
import { Button } from '@material-ui/core';
import { useId } from 'hooks';
import { hasKey } from '../../helpers';
import taxExempt from '../../helpers/apis/taxExempt';
import PartnerTaxExemptViewModal from './PartnerTaxExemptViewModal';
import PartnerTaxExemptToolbar from '../shared/taxExempt/PartnerTaxExemptToolbar';
import { InfoButtons, ActionButtons } from './shared';
import { ContainerTitle } from './ComponentTypes';

const TaxExemptActionButtonContainer = styled(Button)`
  && {
    text-transform: capitalize;
    color: ${({ color }) => color || 'var(--colorDefault)'};
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-right: 40px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    padding-left: 0;

    &:hover {
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }

    p {
      margin: 0 0 0 8px;
    }
  }
`;

const ErrorMessage = styled(Typography.h3)`
  text-align: center;
  margin: 100px auto;
  max-width: 500px;
  font-size: 24px;
  color: var(--colorSystemDanger);
  line-height: 30px;
`;

const TaxExemptActionButton = ({ icon, title, onClick, color }) => {
  return (
    <TaxExemptActionButtonContainer onClick={onClick} color={color}>
      {icon}
      <p>{title}</p>
    </TaxExemptActionButtonContainer>
  );
};

const PartnerTaxExempt = props => {
  const { id: propsId } = props;

  const initialState = {
    open: false,
    isNew: true,
    data: null,
    method: 'addCertificate'
  };

  const [modalInfo, setModalInfo] = useState(initialState);
  const [refresh, setRefresh] = useState(false);
  const partner_id = useId({ key: 'partnerId' });
  const app = 'partnerProfile';
  const tab = 'taxExempt';
  const id = propsId || partner_id;
  const [openDialog, setOpenDialog] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [err, setErr] = useState(null);
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const isPA = userRole === 'Partner Administrator';

  const viewCertificate = async certificateId => {
    setOpenDialog(true);
    const data = await taxExempt.getCertificate(certificateId);
    setPdfData(data.data.url);
  };

  useEffect(() => {
    if (!modalInfo.open) {
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      }, 300);
    }
  }, [modalInfo.open]);

  const setClose = () => {
    setModalInfo(initialState);
  };

  return (
    <>
      {isPA && (
        <Container.Paper mt={2} radius={1}>
          <Container.Grid spacing={4}>
            <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerTitle noMargin noBorder>
                <Typography.h6 mt={0.1} mb={0.1}>
                  Sales Tax Exemptions
                </Typography.h6>
                <InfoButtons name="tax_exemption" />
              </ContainerTitle>
            </Container.Grid>
          </Container.Grid>
        </Container.Paper>
      )}

      {/* View modal */}
      <PartnerTaxExemptViewModal open={openDialog} setOpen={setOpenDialog} pdfData={pdfData} setPdfData={setPdfData} />

      {/* Table */}
      <Container.Paper mt={2} radius={1}>
        {err ? (
          <ErrorMessage>{err}</ErrorMessage>
        ) : (
          <TableList
            id={id}
            setApp={app}
            source={tab}
            toolbar={<PartnerTaxExemptToolbar setModalInfo={setModalInfo} modalInfo={modalInfo} setClose={setClose} />}
            transform={data => transformData(data, viewCertificate, setModalInfo)}
            refresh={refresh}
            tableOptions={{ retry: false, refetchOnWindowFocus: false }}
            setErr={setErr}
          />
        )}
      </Container.Paper>

      {/* Action buttons */}
      {isPA && <ActionButtons />}
    </>
  );
};

function transformData(data, viewCertificate, setModalInfo) {
  const taxData = hasKey(data, 'data') ? data.data : data;
  return taxData.map(row => {
    const rowCopy = JSON.parse(JSON.stringify(row));
    const handleOpenModal = method => {
      setModalInfo({
        open: true,
        isNew: false,
        method,
        data: {
          exposure_zones: rowCopy.exposure_zones
        }
      });
    };

    rowCopy.actions = (
      <Container.Grid direction="row" alignItems="center">
        {row.actions.view && (
          <TaxExemptActionButton onClick={() => viewCertificate(rowCopy.id)} title="View" icon={<Eye />} />
        )}
        {row.actions.renew && (
          <TaxExemptActionButton onClick={() => handleOpenModal('postCertificate')} title="Renew" icon={<Renew />} />
        )}
        {row.actions.update && (
          <TaxExemptActionButton
            color="var(--colorSystemInfo)"
            onClick={() => handleOpenModal('putCertificate')}
            title="Update"
            icon={<Update />}
          />
        )}
      </Container.Grid>
    );
    return rowCopy;
  });
}

export default PartnerTaxExempt;
