import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Happy = props => (
  <SvgIconStyled {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 37.2369 10.7631 48 24 48C37.2369 48 48 37.2369 48 24C48 10.7631 37.2369 0 24 0C10.7631 0 0 10.7631 0 24ZM3.69231 24C3.69231 12.7938 12.7938 3.69231 24 3.69231C35.2062 3.69231 44.3077 12.7938 44.3077 24C44.3077 35.2062 35.2062 44.3077 24 44.3077C12.7938 44.3077 3.69231 35.2062 3.69231 24ZM19.2923 20.5291C19.2923 22.497 17.6392 24.0922 15.6 24.0922C13.5608 24.0922 11.9077 22.497 11.9077 20.5291C11.9077 18.5613 13.5608 16.9661 15.6 16.9661C17.6392 16.9661 19.2923 18.5613 19.2923 20.5291ZM32.3632 24.0922C34.4024 24.0922 36.0555 22.497 36.0555 20.5291C36.0555 18.5613 34.4024 16.9661 32.3632 16.9661C30.324 16.9661 28.6709 18.5613 28.6709 20.5291C28.6709 22.497 30.324 24.0922 32.3632 24.0922ZM12.5908 31.3477C15.7292 34.4861 19.8646 36.0554 23.9815 36.0554C28.1169 36.0554 32.2338 34.4861 35.3354 31.3477C36.0554 30.6277 36.0554 29.4646 35.3354 28.7446C34.6154 28.0246 33.4523 28.0246 32.7323 28.7446C27.8954 33.5815 20.0308 33.5815 15.1938 28.7446C14.4738 28.0246 13.3108 28.0246 12.5908 28.7446C11.8708 29.4646 11.8708 30.6277 12.5908 31.3477Z"
      fill="#0CCE6B"
    />
  </SvgIconStyled>
);

export default React.memo(Happy);
