import React from "react";
import { RadioButtonWithTitle, LoadingStyled, useQuiz } from 'components';
import { QuizContentBodyLayout, QuizSubtitle } from './quizStyle';

const QuizContentBody = () => {
  const {question, answers, dispatch, loading, quizId, selectedAnswerId} = useQuiz()

  return (
    <QuizContentBodyLayout>
      {
        loading ? (
          <LoadingStyled/>
        ) : (
          <>
            <QuizSubtitle>
              {question?.number}. {question?.text}
            </QuizSubtitle>

            {
              answers?.map(({answer, id}) => {
                return (
                  <RadioButtonWithTitle
                    checked={selectedAnswerId === id}
                    key={id}
                    id={id}
                    name={`quiz`}
                    label={answer}
                    onChange={(e) => dispatch.setAnswerId(quizId, question.id,  id)}
                  />
                )
              })
            }
          </>
        )
      }


    </QuizContentBodyLayout>
  )
}

export default QuizContentBody
