import {
  CircularProgress,
  IconButton,
  SpreadsheetIcon,
  Stack,
  Tooltip,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clients from 'helpers/apis/clients';
import { downloadFile } from 'helpers';
import { isNA } from '../helpers';

const ReportCell = ({ clientId, clientReport }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isNA(clientReport)) return <Typography variant="body2">N/A</Typography>;

  if (!clientReport) return null;

  if (isLoading)
    return (
      <Stack p={1}>
        <CircularProgress size={20} />
      </Stack>
    );

  const handleDownloadReport = async e => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const res = await clients.getClientReport(clientId);
      downloadFile(res.data.url);
    } catch (error) {
      enqueueAlertSnackbar(`${error.response}`, { props: { severity: 'error' } });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip title="Download Client Report" placement="top">
      <IconButton onClick={handleDownloadReport}>
        <SpreadsheetIcon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

ReportCell.propTypes = {
  clientId: PropTypes.string.isRequired,
  clientReport: PropTypes.string.isRequired
};

export default ReportCell;
