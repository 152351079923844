// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Phishing = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 12.193 12.792" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h12.193v12.792H0z" />
    <path
      d="M.494,0A1.276,1.276,0,0,0,0,.992v.091A9.77,9.77,0,0,0,5.227,9.638l.2.11a1.407,1.407,0,0,0,1.354,0l.2-.11A10.276,10.276,0,0,0,9.019,8.167Z"
      transform="translate(0.406 3.474)"
    />
    <path
      d="M7.8,8.988A9.462,9.462,0,0,0,9.262,3.952V3.862a1.287,1.287,0,0,0-.677-1.134L3.846.174a1.407,1.407,0,0,0-1.354,0L0,1.528Z"
      transform="translate(3.331 0.604)"
    />
    <path
      d="M1.161.191A.7.7,0,0,0,.5.022a.666.666,0,0,0-.481.46A.632.632,0,0,0,.2,1.111l10.833,10.37a.7.7,0,0,0,.961,0,.629.629,0,0,0,0-.92Z"
      transform="translate(0.403 1.164)"
    />
  </SvgIconStyled>
);

export default Phishing;
