import React from 'react';
import { Stack, AlertDialog, Typography } from '@trustsecurenow/components-library';

function DisabledWelcomeMessageDialog({ openDialog, onCloseDialog, onSubmitDialog }) {

    return (
        <AlertDialog
            open={openDialog}
            dialogTitle="Disable Welcome Messages"
            message={<Typography variant="subtitle1"> Are you sure you would like to disable welcome messages? Users will not receive any portal notifications until they login or receive a welcome message.</Typography> }
            SubmitButtonProps={{
                color: "error",
                children: "Yes, Disable Welcome Message",
                onClick: onSubmitDialog,
            }}
            onClose={onCloseDialog}
        />
    );
}

export default DisabledWelcomeMessageDialog;