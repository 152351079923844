// @flow
import React from 'react';

const CircleArrow = props => (
  <svg {...props} width="35" height="35" viewBox="0 0 35 35" fill="none">
    <circle cx="17.5" cy="17.5" r="17" stroke={props.iconColor} />
    <g clip-path="url(#clip0_4887_3148)">
      <path
        d="M13.6401 24.9127C13.4608 24.9127 13.288 24.8358 13.1599 24.695C12.9295 24.4261 12.9551 24.0228 13.2175 23.7923L20.298 17.5825L13.2175 11.3663C12.9487 11.1359 12.9231 10.7261 13.1599 10.4637C13.3904 10.2012 13.8001 10.1692 14.0626 10.406L21.1431 16.6159C21.4183 16.8591 21.5784 17.2112 21.5784 17.5761C21.5784 17.941 21.4183 18.2931 21.1431 18.5364L14.0626 24.7462C13.941 24.8615 13.7873 24.9127 13.6401 24.9127Z"
        fill={props.iconColor}
      />
    </g>
  </svg>
);

export default CircleArrow;
