// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Play = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 13 15.019" role="img" aria-hidden="false">
    <path
      id="icon"
      d="M17.044,30.969c.329.191.69-.714,1.051-.714a2.091,2.091,0,0,0,1.041-.28l8.809-5.04a2.1,2.1,0,0,0,0-3.638h0l-8.809-5.04A2.1,2.1,0,0,0,16,18.075V28.156C16,28.912,16.39,30.59,17.044,30.969Zm.632-12.894a.412.412,0,0,1,.209-.363.432.432,0,0,1,.212-.058.411.411,0,0,1,.206.057l8.809,5.04a.419.419,0,0,1,0,.728L18.3,28.52a.419.419,0,0,1-.627-.364Z"
      transform="translate(-16 -15.977)"
      fill={props.fill}
    />
  </SvgIconStyled>
);

export default Play;
