// @flow
import transitions from './transitions';

const layout = {};

layout.header = {
  height: '75px'
};

layout.sidebar = {
  width: '292px',
  transition: `transform ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp}`
};

export default layout;
