// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Block = props => (
  <SvgIconStyled {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0C5.38154 0 0 5.38154 0 12C0 18.6185 5.38154 24 12 24C18.6185 24 24 18.6185 24 12C24 5.38154 18.6185 0 12 0ZM12 1.84615C14.4646 1.84615 16.7354 2.73231 18.4893 4.2L4.19999 18.4892C2.7323 16.7262 1.84615 14.4646 1.84615 12C1.84615 6.39692 6.39692 1.84615 12 1.84615ZM12 22.1538C9.53538 22.1538 7.26464 21.2677 5.5108 19.8L19.8 5.51077C21.2677 7.27385 22.1538 9.53538 22.1538 12C22.1538 17.5938 17.6031 22.1538 12 22.1538Z"
      fill={props.color || '#FF5A61'}
    />
  </SvgIconStyled>
);

export default Block;
