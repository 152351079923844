import { useId, useLocation } from 'hooks';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as sra from 'helpers/apis/services/sra';
import generateApiTableParams from 'utils/generateApiTableParams';
import userPreferences from 'helpers/apis/UsersAPI/userPreferences';
import { SRA_SUB_PAGES } from '../constants';
import { useClientInfo, useSraReports } from '../hooks';
import { useQuery } from 'react-query';

export const RecommendationsContext = createContext(null);

const SRA_RECOMMENDATIONS_TABLE_NAME = 'SRARecommendations';

export const RecommendationsProvider = ({ children }) => {
  const userId = useId();
  const { item } = useLocation();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const clientId = item && !isSraSubPage ? item : userId;
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [isPreferenceFetched, setIsPreferenceFetched] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState([]);
  const [rowUpdated, setRowUpdated] = useState(null);
  const {
    data: reports,
    isLoading: reportsLoading,
  } = useSraReports(clientId);
  // this is a hack to get the revision id of the most recent COMPLETED revision
  // where the /revision endpoint will give us the most recent revision,
  // regardless of completion status
  const revisionId = reports?.[0]?.revision_id;

  const fetchRecommendations = useCallback(async () => {
    if (!revisionId || !isPreferenceFetched) return;
    try {
      setIsLoading(true);
      const params = generateApiTableParams({
        pagination: paginationModel,
        sorting: sortModel,
        filter: { items: filterModel, fields: { finding: 'date_completed' } }
      });
      userPreferences.setMuiDataGridSettings(SRA_RECOMMENDATIONS_TABLE_NAME, {
        pageSize: paginationModel.pageSize,
        sorting: sortModel
      });
      const response = await sra.getWorkplanRecommendations(params, revisionId);
      setRows(response.data.result);
      setRowCount(response.data.count);
    } finally {
      setIsLoading(false);
    }
  }, [isPreferenceFetched, paginationModel, revisionId, sortModel, filterModel, rowUpdated]);

  const {
    data: workPlanInfo,
    isLoading: isHSNLoading,
  } = useQuery({
    queryKey: ['sraApi.getWorkplanInfo', revisionId],
    queryFn: () => sra.getWorkplanInfo(revisionId)
  })

  const isHSN = Boolean(workPlanInfo?.data?.is_hsn);

  useEffect(() => {
    setIsPreferenceFetched(true);
    const preferences = userPreferences.getMuiDataGridSettings(SRA_RECOMMENDATIONS_TABLE_NAME);
    if (preferences) {
      setPaginationModel(prevState => ({ ...prevState, pageSize: preferences.pageSize || prevState.pageSize }));
      setSortModel(prevState => preferences.sorting || prevState);
    }
  }, []);

  const handleFilterModelChange = filter => {
    const items = filter.items
      .map(filterItem => ({ field: filterItem.field, value: filterItem.value, operator: filterItem.operator }))
      .filter(filterItem => (Array.isArray(filterItem.value) ? filterItem.value.length : filterItem.value));
    const isFilterChanged = JSON.stringify(filterModel) !== JSON.stringify(items);
    if (isFilterChanged) {
      setPaginationModel(prevState => ({ ...prevState, page: 0 }));
      setFilterModel(items);
    }
  };

  const handleSortModelChange = value => {
    setPaginationModel(prevState => ({ ...prevState, page: 0 }));
    setSortModel(value);
  };

  return (
    <RecommendationsContext.Provider
      value={{
        isHSN,
        isHSNLoading,
        revisionId,
        rows,
        rowCount,
        isLoading: isLoading || reportsLoading || isHSNLoading,
        refetch: fetchRecommendations,
        pagination: paginationModel,
        onChangePagination: setPaginationModel,
        setRowUpdated,
        sorting: sortModel,
        onChangeSorting: handleSortModelChange,
        onChangeFilter: handleFilterModelChange,
      }}
    >
      {children}
    </RecommendationsContext.Provider>
  );
};

RecommendationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useRecommendations = () => useContext(RecommendationsContext);
