// @flow

const otherPolicies = {
  title: 'Add Other Policy or Procedure',
  component: 'clients/ClientsEditOtherPoliciesModal',
  noQuery: true,
  idRequired: true,
  buttons: [],
  initialState: {
    name: '',
    description: '',
    details: '',
    file: '',
    step: 0
  }
};

export default otherPolicies;
