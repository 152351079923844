import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import { Typography } from 'components';
import { EDIT_REPORT_PAGE_ITEMS } from '../constants';
import { ButtonBaseFilled } from '../style';

const useStyles = makeStyles(theme => ({
  editPage: {
    padding: '30px 0'
  },
  paper: {
    height: '100%'
  },
  card: {
    padding: '40px 50px 40px 40px',
    display: 'flex',
    height: '100%',
    boxSizing: 'border-box'
  },
  cardImg: {
    width: 115,
    minWidth: 115,
    marginRight: 30,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      marginTop: 'auto'
    }
  }
}));

const EditSraPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const goToSubPage = url => {
    history.push(`${location.pathname}/${url}`);
  };

  return (
    <div className={classes.editPage}>
      <Grid container justifyContent="center" spacing={4}>
        {EDIT_REPORT_PAGE_ITEMS.map(item => (
          <Grid key={item.title} item lg={6} md={12} sm={12} xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <div className={classes.card}>
                <div className={classes.cardImg}>
                  <img src={item.img} alt={item.title} />
                </div>

                <div className={classes.cardContent}>
                  <Typography.h3 fontSize={24} mt="0px" mb={3}>
                    {item.title}
                  </Typography.h3>

                  <Typography.p fontSize={14} mt="0px" mb={4}>
                    {item.text}
                  </Typography.p>

                  <ButtonBaseFilled variant="outlined" disableElevation onClick={() => goToSubPage(item.url)}>
                    {item.btnText}
                  </ButtonBaseFilled>
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default EditSraPage;
