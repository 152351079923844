import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { Container } from 'components';
import { useDirectorySync } from './DirectorySyncContext';
import { Button, ArrowSyncIcon } from '@trustsecurenow/components-library';

const AzureActiveDirectoryVerifySetupAndSync = () => {
  const { verifyAADState, dispatch, loading, verifyAADFailed } = useDirectorySync();

  return (
    <Box display="flex" flexDirection="column" width="100%" px={1}>
      <Box display="flex" alignItems="center">
        <p style={{ marginRight: 30, fontWeight: 'bold' }}>Verify Setup and Sync</p>
        <Button disabled={loading} onClick={e => dispatch.onVerify()} sx={{ width: 180 }} startIcon={<ArrowSyncIcon />}>
          Update
        </Button>
      </Box>
      {verifyAADState && (
        <Box mt={1}>
          <Divider />
          <Box mt={1}>
            <Grid container spacing={4} alignItems="center">
              {verifyAADState?.groups.map((group, index) => {
                return (
                  <Grid item key={index}>
                    <Box display="flex" flexWrap="no-wrap">
                      <span style={{ fontWeight: 500, marginRight: '8px' }}>{group.group}:</span>
                      <span style={{ color: group?.found ? '#0CCE6B' : '#FF5A61' }}>
                        {group?.found ? 'Validated' : 'Group Not Found'}
                      </span>
                    </Box>
                  </Grid>
                );
              })}
              <Grid item>
                <Button
                  id="directorySyncAuthButton"
                  disabled={verifyAADState?.status !== 200}
                  onClick={() => dispatch?.onAzureSync()}
                  color="success"
                  startIcon={<ArrowSyncIcon />}
                >
                  Sync Azure now
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {verifyAADFailed && (
        <Container.Grid container spacing={2} p={3} alignItems="center">
          <Grid item xs={12}>
            <span>
              <span style={{ fontWeight: 500 }}>Number of Users in Azure AD:</span> Unknown
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              <span style={{ fontWeight: 500 }}>Groups:</span> Unknown
            </span>
          </Grid>
        </Container.Grid>
      )}
    </Box>
  );
};

export default AzureActiveDirectoryVerifySetupAndSync;
