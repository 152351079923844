// @flow
import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';

const AsyncComponent = lazy(({ component }) => import(`../../${component}`), {
  cacheKey: props => props.component
});

type LoadableProps = {
  component: string,
  ...
};

function Loadable({ component, ...props }: LoadableProps): React$Element<*> {
  if (!component) return <></>;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AsyncComponent component={component} {...props} />
    </Suspense>
  );
}

export default Loadable;
