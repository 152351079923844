import React from "react"
import { Container } from "components";
import { PreassessmentLogo, PreassessmentWrapper } from '../components';
import { Hidden } from '@material-ui/core';

const PreassessmentGetStarted = () => {
  return (
    <Container.Grid container>
      <Hidden smDown>
        <PreassessmentLogo/>
      </Hidden>
      <PreassessmentWrapper/>
    </Container.Grid>
  )
}

export default PreassessmentGetStarted
