import * as quizManagementSystem from 'helpers/apis/services/quizManagementSystem';

export const analyzeUserState = userStateResponse => {
  /* 204 -> data = undefined, 200 ->
    data = {
      "action": {
        "show_resume": false,
        "show_retake": false

        "new_quiz": true, // what to use this for?
      },
      "info": {
        "completed": false, // user completed quiz before
        "revision_id": "VFdwbk1rMUJQVDA9"
      }
    }
  */
  const { data, status } = userStateResponse;
  const userFirstTimeInQuiz = status === 204;
  const userVisitedQuiz = status === 200;
  const userCanRetakeQuiz = userVisitedQuiz && data?.action?.show_retake;
  const userCanResumeQuiz = userVisitedQuiz && data?.action?.show_resume;
  const userCompletedQuiz = userVisitedQuiz && data?.info?.completed;
  const quizIsDone = userCompletedQuiz && !userCanResumeQuiz && !userCanRetakeQuiz;

  return userFirstTimeInQuiz
    ? 'take'
    : userCanRetakeQuiz
    ? 'retake'
    : userCanResumeQuiz
    ? 'resume'
    : quizIsDone
    ? 'done'
    : 'error';
};

export const getAnalyzedUserState = async ({ quiz_token, quiz_id }) => {
  /**
   * we can use this function outside the quiz viewer to control UI in previous steps,
   * i.e. changing button label: Take, Retake, Resume, etc.
   */

  const userStateResponse = await quizManagementSystem.getQuizUserState(quiz_id);
  const type = analyzeUserState(userStateResponse);
  return { type, data: userStateResponse.data };
};
