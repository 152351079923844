// @flow
import React from 'react';
import { makeStyles, Dialog, DialogContent } from '@material-ui/core';
import MicroTraining from 'apps/dashboard/microTraining/MicroTraining';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '15px 0 30px !important'
  }
}));

const MicroTrainingMobileModal = ({ open, close, trainingId, submitCallback }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm" fullScreen>
      <DialogContent className={classes.dialogContent}>
        <MicroTraining
          trainingId={trainingId}
          showVideo={false}
          isMobile
          hasTitle
          closeCallback={close}
          submitCallback={submitCallback}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MicroTrainingMobileModal;
