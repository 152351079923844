// @flow

import { CREATE_USER_MATCH_EMAIL_ERR, SET_ERROR } from "conf";

type errorHandlerType = {
  messgeArgs: Object,
  type: string,
  message: string,
  undoable: boolean
};

type errorHandlerReducerType = {
  type: string,
  payload: errorHandlerType
};

export default (
  previousState: errorHandlerType = {
    messgeArgs: {},
    type: '',
    message: '',
    undoable: false,
    createUserMatchingEmailError: ''
  },
  { type, payload }: errorHandlerReducerType
): Object | errorHandlerReducerType => {
  // (type === 'RA/SHOW_NOTIFICATION' ? payload : previousState)
  switch(type) {
    case CREATE_USER_MATCH_EMAIL_ERR: {
      return {
        ...previousState,
        createUserMatchingEmailError: payload
      };
    }

    case 'RA/SHOW_NOTIFICATION': {
      return payload;
    }

    default:
      return previousState
  }
};
