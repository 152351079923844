// @flow

export default {
  winter: {
    name: 'winter',
    background: '#ffffff',
    alt_bg: '#f2f5f9',
    nav_bg: '#082e49',
    body_copy: '#222230',
    nav_copy: '#ffffff',
    links: '#4184b5',
    active_links: '#77b8e6',
    nav_links: '#77b8e6',
    mid_gray: '#666666'
  },
  spring: {
    name: 'spring',
    background: '#ffffff',
    alt_bg: '#eaf7ef',
    nav_bg: '#073c2a',
    body_copy: '#170b12',
    nav_copy: '#ffffff',
    links: '#9f0552',
    active_links: '#819e3e',
    nav_links: '#b4ffce',
    mid_gray: '#5b6b67'
  },
  summer: {
    name: 'summer',
    background: '#ffffff',
    alt_bg: '#fffef5',
    nav_bg: '#002242',
    body_copy: '#0b1017',
    nav_copy: '#ffffff',
    links: '#0069a0',
    active_links: '#0083c9',
    nav_links: '#a0dfff',
    mid_gray: '#d6a700'
  },
  autumn: {
    name: 'autumn',
    background: '#ffffff',
    alt_bg: '#f0f7ea',
    nav_bg: '#1b3306',
    body_copy: '#19120c',
    nav_copy: '#fff',
    links: '#ff620f',
    active_links: '#d44a00',
    nav_links: '#ceffb4',
    mid_gray: '#636b5b'
  },
  custom: {
    name: 'custom',
    background: '#ffffff',
    alt_bg: '#eaf7f6',
    nav_bg: '#2d3e40',
    nav_copy: '#ffffff',
    nav_links: '#00ffeb',
    active_links: '#ff6e52',
    body_copy: '#2c2620',
    links: '#ff340f',
    linksActive: '#ff6e52',
    mid_gray: '#5b6b67'
  }
};
