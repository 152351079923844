// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const LeftArrow = props => (
  <SvgIconStyled {...props} role="img" aria-hidden="false">
    <path
      d="M12.0405 -0.000101174C12.3094 -0.00010115 12.5687 0.115133 12.7607 0.326395C13.1064 0.729712 13.068 1.33469 12.6743 1.68039L2.0536 10.9951L12.6743 20.3194C13.0776 20.6651 13.116 21.2797 12.7607 21.6734C12.415 22.0671 11.8004 22.1151 11.4067 21.7598L0.786029 12.4451C0.373109 12.0802 0.133039 11.5521 0.133039 11.0047C0.13304 10.4573 0.373109 9.92919 0.786029 9.56428L11.4067 0.249573C11.5892 0.0767211 11.8196 -0.000101193 12.0405 -0.000101174Z"
    />
  </SvgIconStyled>
);

export default LeftArrow;
