import React, {createContext, useContext} from 'react';

const CardPageContext = createContext({})

export const CardPageProvider = ({children, value}) => {
  return (
    <CardPageContext.Provider value={value}>
      {children}
    </CardPageContext.Provider>
  )
}

export const useCardPageContext = () => {
  return useContext(CardPageContext)
}
