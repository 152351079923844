// @flow
import React from 'react';
import { Typography, makeStyles, Divider, IconButton, DialogTitle } from '@material-ui/core';
import { ArrowLeft } from 'components/icons';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px 10px',
      '& h2 div': {
        marginLeft: 'auto'
      },
      '& h2 div .post-btn': {
        fontSize: '14px',
        lineHeight: 1.5
      }
    },
    '& h2': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  dialogHeaderSm: {
    padding: '16px 16px 10px',
    '& p': {
      marginLeft: 15
    }
  },
  backBtn: {
    padding: 8
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  }
}));

const ModalTitle = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="dialog-title" className={`${classes.dialogHeader} ${classes.dialogHeaderSm}`}>
        <IconButton className={classes.backBtn} onClick={onClick}>
          <ArrowLeft size={1.7} />
        </IconButton>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <Divider />
    </>
  );
};

export default React.memo(ModalTitle);
