import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { Delete, SubmissionModal, Typography } from 'components';
import { useNotify } from 'react-admin';
import { dataProvider } from 'helpers';

const DeleteClientModal = ({ clientId, onDeletion }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const actionDeleteClient = () => {
    const app = 'clients';
    const tab = 'actions';
    setOpen(false);
    dataProvider
      .delete(app, tab, clientId)
      .then(res => {
        onDeletion();
        notify(res.data.message);
      })
      .catch(err => notify(`${err.response.data.description}`, 'error'));
  };
  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          console.log('hello deletion!');
          setOpen(true);
        }}
      >
        <Delete style={{ color: '#FF5A61' }} />
      </IconButton>
      <SubmissionModal
        open={open}
        onClose={() => setOpen(false)}
        color="colorSystemDanger"
        onSubmit={actionDeleteClient}
        icon="Delete"
        iconSize={34}
        title="Delete"
        description={
          <Typography.p mx={0} mt={0.1} mb={0.1} textAlign="center">
            This client will be deleted and cannot be recovered.
            <br/>Are you sure about that?
          </Typography.p>
        }
        submitButtonTitle="Yes, I want to delete"
        cancelButtonTitle="Cancel"
        maxWidth="xs"
      />
    </>
  );
};

export default DeleteClientModal;
