import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const baseURL = `https://api.outlook-${env}.pii-protect.com`;

const catchPhish = {};
catchPhish.env = env;
catchPhish.baseURL = baseURL;

catchPhish.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

/**
 * fetches catchphish configuration for current partner,
 * or for a specific client if id provided
 * @param {optional} clientID : id of the client to get catchphish configuration for
 * @returns axios get response
 */
catchPhish.getFeatures = clientID => {
  const requestOptions = {
    url: `${baseURL}/GetPartnerFeatures${clientID ? `?client_id=${clientID}` : ``}`,
    headers: catchPhish.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

catchPhish.setFeatures = payload => {
  const requestOptions = {
    url: `${baseURL}/SetPartnerFeatures`,
    headers: catchPhish.getRequestHeaders(),
    data: payload
  };
  return apiClient.post(requestOptions);
};

export default catchPhish;
