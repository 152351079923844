import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import { hasInternetConnection } from '../helpers';

function useHasInternet() {
  const notify = useNotify();

  return useCallback(
    (callback, callbackWithoutInternet) => hasInternetConnection(notify, callback, callbackWithoutInternet),
    [notify]
  );
}

export default useHasInternet;
