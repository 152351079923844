// @flow
import React, { type Element, useEffect } from 'react';
import styled from 'styled-components';
import { Box as BoxComponent } from '@material-ui/core';
import { Container, CustomAlert } from 'components';
import { useLocation, useId, useDataGet } from 'hooks';
import { useDashboard } from './DashboardContext';
import { Success, SuccessXl, Warning, WarningXl } from '../icons';
import DashboardBestPractices from './DashboardBestPractices';
import DashboardChartBarGroupWidget from './DashboardChartBarGroupWidget';
import DashboardBoxTitle from './DashboardBoxTitle';

type DashboardTypes = {
  resource: string,
  hasData: boolean,
  sra: {
    title: string | null,
    description: string | null,
    message: string | null,
    status: string | null
  } | null,
  bestPractices: {
    title: string | null,
    description: string | null,
    data: Array<Object>
  } | null,
  statistics: {
    title: string | null,
    description: string | null,
    data: Array<Object>
  } | null
};

type String = string & { replaceAll: Function };

const Frame = styled.div`
  min-height: 480px;
`;

const Box = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 100px;
  flex: 1 ${({ shrink }) => shrink}%;
  padding: 10px;
  @media only screen and (min-width: 1235px) {
    flex: 1 ${({ shrinkLg }) => shrinkLg}%;
  }
  ${({ opacity }) => opacity && `opacity: 0.1`};
`;

const Content = styled.div`
  flex: 1 100%;
  background-color: var(--backgroundPaper);
  padding: 20px;
`;

const IconWrap = styled.div`
  max-width: 60%;
  width: 230px;
  > svg {
    width: 100%;
    height: auto;
  }
`;

const Dashboard = ({ sra, bestPractices, statistics }: DashboardTypes): Element<*> => {
  const { dispatch } = useDashboard();
  const { open, box } = dispatch.getBox();
  const sraIsSuccess = sra.status === 'Completed' ? true : false;

  const showBox = (checkOpen: boolean, label: String, compareLabel: String) =>
    !(checkOpen && replaceAll(label) === replaceAll(compareLabel));

  return (
    <Frame>
      <Container.Grid container>
        <Container.Grid item xs={12} sm={12} md={12} lg={4}>
          <Box shrink="100" opacity={open && showBox(open, sra.title, box)}>
            <Content>
              <DashboardBoxTitle label={sra.title} description={sra.description} fontSize={13} icon iconSize={1.3} />
              {sraIsSuccess ? (
                <>
                  <BoxComponent display="flex" justifyContent="center" mt={5} mb={2.5}>
                    <IconWrap>
                      <SuccessXl size={16.43} color="success" strokeColor="var(--colorSystemSuccess)" />
                    </IconWrap>
                  </BoxComponent>
                  <CustomAlert type="success" variant="outlined" icon={<Success size={1.5} color="success" />}>
                    {sra.message}
                  </CustomAlert>
                </>
              ) : (
                <>
                  <BoxComponent display="flex" justifyContent="center" mt={5} mb={2.5}>
                    <IconWrap>
                      <WarningXl size={16.43} color="warning" strokeColor="var(--colorSystemWarning)" />
                    </IconWrap>
                  </BoxComponent>
                  <CustomAlert type="warning" variant="outlined" icon={<Warning size={1.5} color="warning" />}>
                    {sra.message}
                  </CustomAlert>
                </>
              )}
            </Content>
          </Box>
        </Container.Grid>

        <Container.Grid item xs={12} sm={12} md={12} lg={6}>
          <Box shrink="100" opacity={open && showBox(open, bestPractices.title, box)}>
            <Content>
              <DashboardBoxTitle
                label={bestPractices.title}
                description={bestPractices.description}
                fontSize={13}
                icon
                iconSize={1.3}
              />
              <DashboardBestPractices record={bestPractices.data} />
            </Content>
          </Box>
        </Container.Grid>

        <Container.Grid item xs={12} sm={12} md={12} lg={10}>
          <Box shrink="100" opacity={open && showBox(open, statistics.title, box)}>
            <Content>
              <DashboardBoxTitle
                label={statistics.title}
                description={statistics.description}
                justify="flex-end"
                hideTitle
                fontSize={13}
                icon
                iconSize={1.3}
              />
              {statistics && <DashboardChartBarGroupWidget chart={statistics.data} type="horizontal" />}
            </Content>
          </Box>
        </Container.Grid>
      </Container.Grid>
    </Frame>
  );
};

function replaceAll(str: String) {
  return str.replaceAll(' ', '_').toLowerCase();
}

Dashboard.defaultProps = {
  sra: null,
  bestPractices: null,
  statistics: null
};

export default Dashboard;
