// @flow
import React, { type ComponentType } from 'react';
import styled, { keyframes , css} from 'styled-components';
import { ChartColorPercent } from 'helpers';
import DashboardBoxTitle from '../dashboard/DashboardBoxTitle';
import { useBreakpoint } from 'hooks';
import { Tooltip } from '@material-ui/core';

const barAnimation = keyframes`
  from {
    stroke-dashoffset: var(--fill);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const ContainerBar: ComponentType<*> = styled.div`
  width: 100%;
  ${props => props.med && css`
      width: 80%;
      margin: auto;
  `}
  /* > p,
  h3,
  h6 {
    margin: 0;
    padding: 0 calc(var(--spacing) * 6) var(--spacing) calc(var(--spacing) * 5.5);
  }

  > h3 {
    margin-bottom: calc(var(--spacing) * 2);
  }

  > p:last-child {
    text-align: right;
    color: var(--colorDefault);
  }

  @media (min-width: 1295px) and (max-width: 1520px) {
    > p,
    h3,
    h6 {
      margin: 0;
      padding: 0 calc(var(--spacing) * 3) var(--spacing) calc(var(--spacing) * 2.5);
    }
  } */
`;

const SvgBar: ComponentType<*> = styled.svg`
  --path: 100%;
  --none: transparent;
`;

const GroupLinesEqualizer: ComponentType<*> = styled.g`
  stroke: none;
`;

const LineEqualizer: ComponentType<*> = styled.line`
  /* Line Horizontal / Verical Equalizer */
`;

const GroupVerticalTextsEqualizer: ComponentType<*> = styled.g`
  font-size: calc(var(--fontSize) / 1.2);
  fill: var(--colorDefault);
`;

const GroupHorizontalTextsEqualizer: ComponentType<*> = styled.g`
  font-size: calc(var(--fontSize) / 1.2);
  fill: var(--colorDefault);
`;

const TextEqualizer: ComponentType<*> = styled.text``;

// const LineCurvedEqualizer: ComponentType<*> = styled.path`
//   fill: none;
//   stroke: var(--colorSystemInfo);
//   stroke-width: calc(var(--spacing) / 8);
//   stroke-linecap: round;
// `;

const PathEqualizerBackground: ComponentType<*> = styled.path`
  transform: rotateX(190deg);
  stroke-width: var(--spacing);
  stroke-linecap: round;
  stroke: var(--colorGreyLight3);
  stroke-dasharray: var(--path);

  ${props => props.theme.breakpoints.down("sm")} {
    stroke-width: 11px;
  }
`;

const PathEqualizerForeground: ComponentType<*> = styled.path`
  transform: rotateX(190deg);
  --fill: ${({ fillValue }) => `${fillValue}%`};
  stroke-width: var(--spacing);
  stroke-linecap: round;
  stroke: ${({ fillValue }) => `var(--colorSystem${ChartColorPercent(fillValue)})`};
  stroke-dasharray: calc(var(--fill) / 2) calc(var(--path) * 2);
  stroke-dashoffset: calc(var(--fill) / 2) calc(var(--path) * 2);
  animation: ${barAnimation} 1s linear alternate;

  ${props => props.theme.breakpoints.down("sm")} {
    stroke-width: 11px;
    stroke-dasharray: calc(var(--fill) / 1.2) calc(var(--path) * 2);
    stroke-dashoffset: 0;
  }
`;

type ChartBarTypes = {
  title: string,
  chart: Object
};

const ChartEqualizer = ({ title, chart , size='default'}: ChartBarTypes) => {
  let x = -15;
  let y = 0;
  const mobileView = useBreakpoint("sm")

  const RefPathEqualizer = React.forwardRef(function myCust(props, ref) {
    return <PathEqualizerForeground {...props} ref={ref} />
  })

  return (
  <>
    <DashboardBoxTitle label={title} fontSize={13} />
    <ContainerBar med={size === 'medium'? true : false}>
      <SvgBar viewBox={`0 -15 390 ${mobileView ? "390" : "220"}`} role="img" preserveAspectRatio="none" >
        <GroupLinesEqualizer>
          <LineEqualizer x1="50" x2="50" y1="0" y2="170" />
        </GroupLinesEqualizer>
        <GroupLinesEqualizer>
          <LineEqualizer x1="50" x2="370" y1="170" y2="170" />
        </GroupLinesEqualizer>
        <GroupHorizontalTextsEqualizer>
          {chart.data.map(({ name }) => {
            x += 60;
            return (
              <TextEqualizer x={x} y={`${mobileView ? "365" : "195"}`}>
                {name}
              </TextEqualizer>
            );
          })}
        </GroupHorizontalTextsEqualizer>
        <GroupVerticalTextsEqualizer>
          <TextEqualizer x="10" y="15">
            800
          </TextEqualizer>
          <TextEqualizer x="10" y={mobileView ? '89' : "50"}>
            600
          </TextEqualizer>
          <TextEqualizer x="10" y={mobileView ? '176' : "90"}>
            400
          </TextEqualizer>
          <TextEqualizer x="10" y={mobileView ? '263' : "130"}>
            200
          </TextEqualizer>
          <TextEqualizer x="10" y={mobileView ? '350' : "170"}>
            0
          </TextEqualizer>
        </GroupVerticalTextsEqualizer>


        <GroupLinesEqualizer >
          {chart.data.map(({ value, name }, index) => {
            y += 60;
            return (
              <React.Fragment key={index}>
                <PathEqualizerBackground d={`M${y} -${mobileView ? "335" : "165"} v80 ${mobileView ? "245" : "80"}`} />
                <Tooltip title={`${name}\n value : ${value}`} placement={'top'}>
                  <RefPathEqualizer d={`M${y} -${mobileView ? "335" : "165"} v80 ${mobileView ? "245" : "80"}`} fillValue={(value / 800) * 100} />
                </Tooltip>
              </React.Fragment>
            );
          })}
        </GroupLinesEqualizer>

      </SvgBar>
    </ContainerBar>
  </>
  );
};

export default ChartEqualizer;
