// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WatchNow = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18.3 11.4" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18.3v18.3H0z" />
    <path d="M17.8,1c-0.3-0.2-0.7-0.3-1.1-0.1l-3.6,1.4V1.7c0-0.9-0.8-1.7-1.7-1.7H1.7C0.8,0,0,0.8,0,1.7v8  c0,0.9,0.8,1.7,1.7,1.7h9.7c0.9,0,1.7-0.8,1.7-1.7V9.1l3.6,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0,1.1-0.5,1.1-1.1V2  C18.3,1.6,18.1,1.3,17.8,1z M12,3.2v6.5c0,0.3-0.3,0.6-0.6,0.6H1.7c-0.3,0-0.6-0.3-0.6-0.6v-8c0-0.3,0.3-0.6,0.6-0.6h9.7  c0.3,0,0.6,0.3,0.6,0.6V3.2z M17.2,9.4l-4-1.6V3.6l4-1.6V9.4z" />
  </SvgIconStyled>
);

export default WatchNow;
