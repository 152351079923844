// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Account = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" fill="none" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_5292_4721)">
      <path
        d="M13.0696 8.60449H2.92931C2.76572 8.60449 2.60883 8.66948 2.49316 8.78515C2.37749 8.90082 2.3125 9.05771 2.3125 9.2213C2.3125 9.38489 2.37749 9.54178 2.49316 9.65745C2.60883 9.77312 2.76572 9.83811 2.92931 9.83811H13.0696C13.2332 9.83811 13.3901 9.77312 13.5058 9.65745C13.6215 9.54178 13.6864 9.38489 13.6864 9.2213C13.6864 9.05771 13.6215 8.90082 13.5058 8.78515C13.3901 8.66948 13.2332 8.60449 13.0696 8.60449Z"
        fill="#535353"
      />
      <path
        d="M13.0696 11.1274H2.92931C2.76572 11.1274 2.60883 11.1924 2.49316 11.3081C2.37749 11.4238 2.3125 11.5807 2.3125 11.7443C2.3125 11.9078 2.37749 12.0647 2.49316 12.1804C2.60883 12.2961 2.76572 12.3611 2.92931 12.3611H13.0696C13.2332 12.3611 13.3901 12.2961 13.5058 12.1804C13.6215 12.0647 13.6864 11.9078 13.6864 11.7443C13.6864 11.5807 13.6215 11.4238 13.5058 11.3081C13.3901 11.1924 13.2332 11.1274 13.0696 11.1274Z"
        fill="#535353"
      />
      <path
        d="M2.92931 7.37082H6.3896C6.55319 7.37082 6.71008 7.30584 6.82575 7.19017C6.94143 7.07449 7.00641 6.9176 7.00641 6.75402C7.00641 6.59043 6.94143 6.43354 6.82575 6.31787C6.71008 6.20219 6.55319 6.13721 6.3896 6.13721H2.92931C2.76572 6.13721 2.60883 6.20219 2.49316 6.31787C2.37749 6.43354 2.3125 6.59043 2.3125 6.75402C2.3125 6.9176 2.37749 7.07449 2.49316 7.19017C2.60883 7.30584 2.76572 7.37082 2.92931 7.37082Z"
        fill="#535353"
      />
      <path
        d="M2.92931 4.84787H6.3896C6.55319 4.84787 6.71008 4.78289 6.82575 4.66722C6.94143 4.55154 7.00641 4.39465 7.00641 4.23107C7.00641 4.06748 6.94143 3.91059 6.82575 3.79492C6.71008 3.67924 6.55319 3.61426 6.3896 3.61426H2.92931C2.76572 3.61426 2.60883 3.67924 2.49316 3.79492C2.37749 3.91059 2.3125 4.06748 2.3125 4.23107C2.3125 4.39465 2.37749 4.55154 2.49316 4.66722C2.60883 4.78289 2.76572 4.84787 2.92931 4.84787Z"
        fill="#535353"
      />
      <path
        d="M10.6516 5.93999C10.8095 5.94575 10.9669 5.92003 11.1147 5.86433C11.2626 5.80863 11.3978 5.72407 11.5126 5.61557C11.6275 5.50706 11.7195 5.37679 11.7835 5.23235C11.8475 5.0879 11.882 4.93217 11.8852 4.77422C11.882 4.61628 11.8475 4.46055 11.7835 4.3161C11.7195 4.17165 11.6275 4.04138 11.5126 3.93288C11.3978 3.82437 11.2626 3.73981 11.1147 3.68411C10.9669 3.62841 10.8095 3.60269 10.6516 3.60846C10.4935 3.60185 10.3357 3.62698 10.1875 3.68237C10.0393 3.73776 9.9037 3.82228 9.78871 3.93095C9.67372 4.03962 9.58167 4.17023 9.51799 4.31506C9.45431 4.4599 9.4203 4.61602 9.41797 4.77422C9.4203 4.93242 9.45431 5.08855 9.51799 5.23338C9.58167 5.37822 9.67372 5.50883 9.78871 5.61749C9.9037 5.72616 10.0393 5.81069 10.1875 5.86608C10.3357 5.92147 10.4935 5.9466 10.6516 5.93999Z"
        fill="#535353"
      />
      <path
        d="M8.90739 7.4016H12.3924C12.4382 7.4016 12.4821 7.3834 12.5145 7.35101C12.5469 7.31863 12.5651 7.2747 12.5651 7.22889C12.5655 6.95974 12.4884 6.69615 12.3429 6.46971C12.1974 6.24327 11.9897 6.06356 11.7447 5.9521L11.609 6.06313C11.5592 6.09983 11.5054 6.13086 11.4486 6.15565H11.4055L11.3191 6.19265H11.2513H11.1772H11.1279C11.0574 6.21345 10.9851 6.2279 10.912 6.23583H10.6838H10.4679H10.4124H10.3014L10.1472 6.19265L10.0485 6.15565H9.99914H9.9313H9.89429L9.72158 6.05696C9.67224 6.01378 9.61672 5.97677 9.57355 5.93359C9.32201 6.04222 9.10788 6.22227 8.95767 6.45141C8.80747 6.68056 8.72779 6.94874 8.72852 7.22272C8.72852 7.24621 8.73315 7.26947 8.74214 7.29118C8.75113 7.31288 8.7643 7.3326 8.78091 7.34921C8.79752 7.36582 8.81724 7.37899 8.83894 7.38798C8.86064 7.39697 8.8839 7.4016 8.90739 7.4016Z"
        fill="#535353"
      />
      <path
        d="M14.169 1.78519e-08H1.83285C1.34208 1.78519e-08 0.871421 0.194956 0.524399 0.541978C0.177378 0.888999 -0.0175781 1.35966 -0.0175781 1.85042V14.1557C-0.0175781 14.6465 0.177378 15.1172 0.524399 15.4642C0.871421 15.8112 1.34208 16.0062 1.83285 16.0062H14.169C14.6598 16.0062 15.1304 15.8112 15.4775 15.4642C15.8245 15.1172 16.0194 14.6465 16.0194 14.1557V1.88126C16.0235 1.63568 15.9787 1.39174 15.8875 1.16366C15.7964 0.935588 15.6607 0.727942 15.4885 0.552834C15.3162 0.377726 15.1109 0.238661 14.8843 0.143746C14.6578 0.0488304 14.4146 -3.40982e-05 14.169 1.78519e-08ZM14.7858 14.1557C14.7858 14.3193 14.7208 14.4762 14.6052 14.5919C14.4895 14.7076 14.3326 14.7726 14.169 14.7726H1.83285C1.66926 14.7726 1.51237 14.7076 1.3967 14.5919C1.28102 14.4762 1.21604 14.3193 1.21604 14.1557V1.88126C1.21604 1.71768 1.28102 1.56079 1.3967 1.44512C1.51237 1.32944 1.66926 1.26446 1.83285 1.26446H14.169C14.3326 1.26446 14.4895 1.32944 14.6052 1.44512C14.7208 1.56079 14.7858 1.71768 14.7858 1.88126V14.1557Z"
        fill="#535353"
      />
    </g>
    <defs>
      <clipPath id="clip0_5292_4721">
        <rect width="16" height="15.9692" fill="white" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default Account;
