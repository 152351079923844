import React from 'react';
import { DirectorySyncProvider } from '../../directorySync/DirectorySyncContext';
import DirectorySyncStart from '../../directorySync/DirectorySyncStart';

const DirectorySync = props => {
  return (
    <DirectorySyncProvider {...props}>
      <DirectorySyncStart />
    </DirectorySyncProvider>
  );
};

export default DirectorySync;
