import { Button, alpha, styled } from '@trustsecurenow/components-library';

const RoundedButton = styled(Button)(({ theme: { palette }, variant, isWhite }) => {
  const isOutlined = variant === 'outlined';
  const themeColor = isWhite ? palette.common.white : palette.primary.dark;
  const containedTextColor = isWhite ? palette.primary.main : palette.common.white;
  const borderColor = isOutlined ? themeColor : 'none';
  const backgroundColor = isOutlined ? 'none' : themeColor;
  const color = isOutlined ? themeColor : containedTextColor;
  const activeBackgroundColor = alpha(themeColor, 0.6);

  return {
    borderColor,
    color,
    backgroundColor,
    borderRadius: 100,
    ':hover': {
      borderColor,
      backgroundColor
    },
    ':active': {
      backgroundColor: activeBackgroundColor
    }
  };
});

export default RoundedButton;
