import React from 'react';
import {
  IndicatorContainer,
  IndicatorTitle,
  PreassessmentWrapperContainer,
  PreassessmentWrapperDescription,
  PreassessmentWrapperLayout,
  PreassessmentWrapperTitle
} from 'apps/preassessment/style';
import Indicator from 'components/charts/Indicator';
import { usePreassessmentContext } from 'apps/preassessment/contexts';

const PreassessmentWrapper = () => {
  const { step, type, assessment = {} } = usePreassessmentContext();

  const { label, description, steps = [] } = assessment;
  const isPreemploymentOrAIRIA = type === 'preemployment' || type === 'airia';

  return (
    <PreassessmentWrapperLayout item md={8} alignItems={'center'}>
      <PreassessmentWrapperContainer>
        <PreassessmentWrapperTitle>{label} Assessment</PreassessmentWrapperTitle>
        <PreassessmentWrapperDescription>{description}</PreassessmentWrapperDescription>

        <IndicatorContainer alignItems={'center'}>
          <IndicatorTitle>{steps[step - 1].title}</IndicatorTitle>
          <Indicator
            title={`${step} / ${steps.length}`}
            colorVariant={isPreemploymentOrAIRIA && step > 1 && step < steps.length ? 'Warning' : undefined}
            value={
              isPreemploymentOrAIRIA
                ? Math.round(((step - 1) / (steps.length - 1)) * 100)
                : Math.ceil((step / steps.length) * 100)
            }
          />
        </IndicatorContainer>

        {steps[step - 1].component}
      </PreassessmentWrapperContainer>
    </PreassessmentWrapperLayout>
  );
};

export default PreassessmentWrapper;
