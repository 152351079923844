import { Box, Stack, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import RoundedButton from './RoundedButton';

const BSNMain = ({ onLearnMoreClick }) => {
  return (
    <Stack
      px={4.75}
      pt={7}
      pb={1.25}
      spacing={5}
      useFlexGap
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack spacing={5} alignItems={{ xs: 'center', md: 'flex-start' }} maxWidth={723}>
        <Typography
          color="common.white"
          variant="heading"
          textAlign={{ xs: 'center', md: 'start' }}
          fontSize={{ xs: 32, md: 56 }}
          lineHeight={{ xs: '44.8px', md: '67.2px' }}
        >
          Simplifying Cybersecurity and Productivity Training Within Your Daily Workspace
        </Typography>
        <RoundedButton onClick={onLearnMoreClick} width={{ xs: 250, md: 168 }} size="large" isWhite>
          Learn more
        </RoundedButton>
      </Stack>
      <Box minWidth={{ md: 355 }} width={{ xs: 244, sm: 300 }}>
        <img src="/media/bsnteams/hero.svg" alt="PII Protect" width="100%" />
      </Box>
    </Stack>
  );
};

BSNMain.propTypes = {
  onLearnMoreClick: PropTypes.func.isRequired
};

export default BSNMain;
