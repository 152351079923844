// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const SendEmail = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" role="img" aria-hidden="false">
    <path d="M16.411 0.75H2.58897C1.44004 0.75 0.5 1.69004 0.5 2.83897V10.4777C0.5 11.6266 1.44004 12.5666 2.58897 12.5666H8.38936L8.31276 12.49C7.95068 12.1349 7.82535 11.6336 7.95068 11.174H2.58897C2.206 11.174 1.89265 10.8606 1.89265 10.4777V3.11751L8.52862 9.75348C8.71663 9.94149 8.94643 10.0738 9.19014 10.1295C9.31548 10.1713 9.44081 10.1852 9.56615 10.1852C9.60097 10.1852 9.64275 10.1852 9.67757 10.1713C10.0118 10.1573 10.3391 10.0181 10.5897 9.76044L17.1073 3.24981V10.4777C17.1073 10.8606 16.794 11.174 16.411 11.174H15.6381L16.9542 12.49C17.8455 12.2533 18.5 11.4386 18.5 10.4777V2.83897C18.5 1.69004 17.56 0.75 16.411 0.75ZM9.60793 8.77862C9.58704 8.79951 9.5383 8.79255 9.51741 8.77166L2.8884 2.14265H16.2369L9.60793 8.77862ZM11.0424 14.8297C11.1816 14.6904 11.1816 14.4746 11.0424 14.3353L9.05786 12.3508C8.78629 12.0792 8.78629 11.6336 9.05786 11.362C9.32943 11.0904 9.77508 11.0904 10.0466 11.362L12.2749 13.5902L12.7693 14.0846C13.0408 14.3562 13.0408 14.8018 12.7693 15.0734L12.2749 15.5678L10.0466 17.796C9.90738 17.9283 9.73329 17.998 9.55225 17.998C9.3712 17.998 9.19713 17.9283 9.05786 17.796C8.78629 17.5245 8.78629 17.0788 9.05786 16.8073L11.0424 14.8297ZM15.3525 14.3353C15.4918 14.4746 15.4918 14.6904 15.3525 14.8297L13.368 16.8073C13.0964 17.0788 13.0964 17.5245 13.368 17.796C13.5072 17.9283 13.6813 17.998 13.8624 17.998C14.0434 17.998 14.2175 17.9283 14.3568 17.796L16.585 15.5678L17.0794 15.0734C17.351 14.8018 17.351 14.3562 17.0794 14.0846L16.585 13.5902L14.3568 11.362C14.0852 11.0904 13.6395 11.0904 13.368 11.362C13.0964 11.6336 13.0964 12.0792 13.368 12.3508L15.3525 14.3353Z"/>
  </SvgIconStyled>
);

export default SendEmail;
