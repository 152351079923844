import React, { Fragment, useEffect } from 'react';
import { ContentAdminFormLayoutContainer } from '../../style';
import ContentAdminFormLayoutHeader from './ContentAdminFormLayoutHeader';
import ContentAdminFormLayoutFooter from './ContentAdminFormLayoutFooter';
import { useContentAdminFormContext } from '../../context';
import { CONTENT_ADMIN_LANDING_PAGES } from '../../constants';
import { useFormContext } from 'react-hook-form';
import forOwn from "lodash/forOwn"

const ContentAdminFormLayout = ({children}) => {

  const { setError } = useFormContext()
  const { page, errors } = useContentAdminFormContext()

  useEffect(() => {
    if (errors) {
      forOwn(errors, function(value, key) {
        setError(key, value, { shouldFocus: true });
      } );
    }
  }, [errors])

  const isLandingPage = page === CONTENT_ADMIN_LANDING_PAGES
  const Container = isLandingPage ? Fragment : ContentAdminFormLayoutContainer

  return (
    <Container>
      {!isLandingPage && <ContentAdminFormLayoutHeader/>}
        {children}
      <ContentAdminFormLayoutFooter/>
    </Container>
  )
}

export default ContentAdminFormLayout
