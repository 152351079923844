// @flow
import React from 'react';
import { makeStyles, Link, Box } from '@material-ui/core';
import { Word, Excel, Ppt, Pdf, Csv, DownloadIcon } from 'components/icons';

const useStyles = makeStyles(theme => ({
  content: {
    borderRadius: 'var(--borderRadius)',
    overflow: 'hidden',
    border: '1px solid var(--backgroundDefault)',
    width: 160
  },
  icon: {
    display: 'flex',
    alighItems: 'center',
    justifyContent: 'center',
    padding: '22px'
  },
  link: {
    display: 'block',
    padding: '12px 10px',
    background: 'var(--backgroundDefault)',
    color: 'var(--colorDarkGrey)',
    cursor: 'pointer'
  },
  filename: {
    wordBreak: 'break-word',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    maxWidth: 'calc(100% - 30px)'
  }
}));

function getIcon(type) {
  switch (type) {
    case 'pdf':
      return <Pdf color="colorIcon" size={1.7} />;
    case 'csv':
      return <Csv color="colorIcon" size={1.7} />;
    case 'doc':
    case 'docx':
      return <Word color="colorIcon" size={1.7} />;
    case 'xls':
    case 'xlsx':
      return <Excel color="colorIcon" size={1.7} />;
    case 'ppt':
    case 'pptx':
      return <Ppt color="colorIcon" size={1.7} />;
    default:
      return <Word color="colorIcon" size={1.7} />;
  }
}

const FilePreview = ({ url, filename }) => {
  const classes = useStyles();
  const type = filename.split('.')[filename.split('.').length - 1].toLowerCase();

  return (
    <div className={classes.content}>
      <div className={classes.icon}>{getIcon(type)}</div>
      <Link href={url} target="_blank" className={classes.link}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={1} className={classes.filename}>
            {filename}
          </Box>
          <DownloadIcon color="colorIcon" size={1.3} />
        </Box>
      </Link>
    </div>
  );
};

export default FilePreview;
