import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Financies = props => (
  <SvgIconStyled {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7456 16.7318L33.916 16.7872C34.649 16.7872 35.3405 16.4829 35.8107 15.9297C36.3086 15.3765 36.5575 14.6435 36.4884 13.8967C35.7831 6.55296 29.9468 0.716695 22.6169 0.0113648C21.8563 -0.0577852 21.1233 0.191157 20.5701 0.689037C20.0307 1.18692 19.7264 1.87842 19.7264 2.61141V14.7127C19.7264 15.8191 20.6392 16.7318 21.7456 16.7318ZM33.7777 14.0212L22.5063 13.9797V2.80502C28.4393 3.4412 33.1415 8.17106 33.7777 14.0212ZM14.3883 35.8034C14.983 35.8726 15.5777 35.9002 16.1724 35.9002C19.7543 35.9002 23.1842 34.697 25.9778 32.4289C29.2556 29.7873 31.3024 26.0256 31.7311 21.8628C31.8141 21.0883 31.5652 20.3276 31.0396 19.7468C30.5141 19.1797 29.7811 18.8478 29.0066 18.8478H17.5415V7.38276C17.5415 6.60828 17.2235 5.87529 16.6426 5.34975C16.0617 4.82421 15.3011 4.5891 14.5266 4.65825C10.3499 5.08698 6.60202 7.13382 3.96049 10.4115C1.33279 13.6892 0.129577 17.7967 0.599797 22.0149C1.40194 29.2065 7.19671 35.0151 14.3883 35.8034ZM6.1318 12.1541C8.30311 9.45725 11.3872 7.77 14.7894 7.38276H14.8032V20.1478V20.1893V20.2308C14.8032 20.9915 15.4255 21.6138 16.1862 21.6138L29.0066 21.5862C28.647 25.016 26.9598 28.1139 24.2629 30.2852C21.5799 32.4565 18.1777 33.4385 14.7064 33.0512C8.78715 32.4012 4.01581 27.6299 3.3658 21.7106C2.97856 18.2393 3.96049 14.8371 6.1318 12.1541Z"
      fill="#0069FF"
    />
  </SvgIconStyled>
);

export default React.memo(Financies);
