// @flow
import React from 'react';
import styled from 'styled-components';
import { Create as RACreate, required } from 'react-admin';
import { Layout as AppLayout, SimpleForm, TextInput, Footer } from 'components';
import type { Element } from 'react';
import UserToolbar from './UserToolbar';

const Layout = styled(AppLayout)`
  margin: 0 auto;
`;

const Create = styled(RACreate)`
  > div {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-color: transparent;
  }
`;

const Header = styled.h2`
  background-color: grey;
  border-radius: 2px;
  padding: 15px 30px;
  color: #fff;
  font-size: 2.2rem;
`;

const Form = styled(SimpleForm)`
  display: flex;
  flex-direction: column;

  > div {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
`;

const HiddenField = styled(TextInput)`
  display: none;
`;

type UserSignupProps = {
  location: Object,
  ...
};

const UserSignup: Function = ({ location, ...props }: UserSignupProps): Element<*> => {
  const token: string | null | typeof undefined =
    new URLSearchParams(location.search).get('token') || localStorage.getItem('initiate_signup_token');
  console.log(token);
  console.log(props);
  return (
    <Layout {...props} sidebar={null} background="paper" centered minWidth="320" maxWidth="400" footer={<Footer />}>
      <Header variant="h2">{token ? 'Finish Sign Up' : 'Sign Up'}</Header>
      <Create
        id="user-signup"
        basePath="/signup"
        resource={token ? 'signup' : 'initiate_signup'}
        location="us"
        {...(props: $Rest<UserSignupProps, any>)}
      >
        <Form redirect={token ? 'login' : undefined} toolbar={<UserToolbar />}>
          {token ? (
            <>
              <HiddenField source="unique_token" type="hidden" defaultValue={token} />
              <TextInput source="phone_number" label="Phone Number" fullWidth />
              <TextInput source="phone_number_ext" label="Extension" fullWidth />
              <TextInput source="cell_number" label="Mobile Number" fullWidth />
              <TextInput source="password" type="password" label="Password" validate={required()} fullWidth />
            </>
          ) : (
            <>
              <TextInput source="registration_code" label="Registration Code" validate={required()} fullWidth />
              <TextInput source="first_name" label="First Name" validate={required()} fullWidth />
              <TextInput source="last_name" label="Last Name" validate={required()} fullWidth />
              <TextInput source="email" label="Email Address" type="email" validate={required()} fullWidth />
            </>
          )}
        </Form>
      </Create>
    </Layout>
  );
};

export default UserSignup;
