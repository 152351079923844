import React from 'react';
import { InputAdornment, TextField, SearchIcon } from '@trustsecurenow/components-library';

const SearchInput = ({ searchValue, onChangeSearchValue }) => {
  return (
    <TextField
      placeholder="Search"
      value={searchValue}
      onChange={onChangeSearchValue}
      fullWidth
      variant="filled"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchInput;
