import { EmptyListIcon, Stack, Typography } from '@trustsecurenow/components-library';
import React from 'react';

const NoRowsOverlay = () => (
  <Stack alignItems="center" mt={3} justifyContent="center" spacing={2}>
    <EmptyListIcon sx={{ fontSize: '5.6rem' }} />
    <Typography>No Reports to Display</Typography>
  </Stack>
);

export default NoRowsOverlay;
