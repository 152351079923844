// @flow
import { PageError } from 'components';
import React, { useEffect } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

type UserFederatedType = {
  location: {
    search: Object
  }
};

const UserFederated = ({ location }: UserFederatedType) => {
  const login = useLogin();
  const notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const errorDescription = new URLSearchParams(location.search).get('error_description');

  useEffect(() => {
    if (!errorDescription) {
      const code = new URLSearchParams(location.search).get('code');
      const userpoolID = new URLSearchParams(location.search).get('state');

      login({ loginPayload: { auth_code: code, userpool_id: userpoolID }, dispatchRx: dispatch })
        .then(() => {})
        .catch(error => {
          notify(error.message, 'error');
          if (error?.redirectTo) {
            history.push(`/${error.redirectTo}`);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errorDescription)
    return <PageError title="Oops!" subtitile={errorDescription} linkText="Login Page" linkTo="/login" />;
  return <center> Redirecting...... </center>;
};

export default UserFederated;
