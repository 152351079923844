// @flow
import React from 'react';
import { Container, Typography, Button } from 'components';
import { CancelButton } from '../../style/AdminCommon';
import UploadFile from '../UploadFile';

type Props = {
  dispatch: Function,
  record: Object,
};

const NewsletterUploadStep = ({ dispatch, record }: Props) => {
  return (
    <Container.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Container.Paper fullWidth radius={1} pb={2}>
        <Container.Grid container>
          <Container.Grid item mb={3} bb={1} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Container.Grid container spacing={4} justify="space-between">
              <Container.Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography.h3 p={2} m={0.1} fontSize={16}>
                  2. Upload document
                </Typography.h3>
              </Container.Grid>
            </Container.Grid>
          </Container.Grid>
          <Container.Grid item pl={2} pr={2} pb={2} xs={12} sm={12} md={12} lg={6} xl={6}>
            <UploadFile
              title="Upload a file"
              fileType=".pdf"
              fileInfo={record.file}
              dispatch={dispatch.onFileUpload}
              type="file"
            />
          </Container.Grid>
          <Container.Grid bt={1} pt={2} pr={2} mt={2} xs={12} md={12} align="center" justify="flex-end">
            <CancelButton onClick={dispatch.onBack}>Back</CancelButton>
            <Button
              backgroundColor="colorSystemSuccess"
              backgroundColorHover="colorSystemSuccessHover"
              backgroundDisabled="colorSystemSuccessDisable"
              onClick={() => dispatch.onNext()}
              disabled={!record.file}
            >
              Next
            </Button>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>
    </Container.Grid>    
  );
};

export default NewsletterUploadStep;