const taxExempt = {
  title: 'Tax Compliance',
  component: 'partner/PartnerAddTaxExemptModal',
  noQuery: true,
  buttons: [],
  initialState: {
    exposure_zones: '',
    method: "addCertificate"
  }
};

export default taxExempt;

