import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { ButtonCancel, Container } from 'components';
import { Save2 } from 'components/icons';
import { PARTNER_SETUP_BASE_PATH } from '../constants';

export const MainContainer = styled(Container.Paper)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
  }
`;

export const SaveButton = styled(Button)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#d2eafa' : 'var(--colorSystemInfo)')};
    border-color: ${({ disabled }) => (disabled ? '#d2eafa !important' : 'var(--colorSystemInfo)')};
    color: ${({ disabled }) => (disabled ? '#98c2ff !important' : 'var(--buttonLight) !important')};
    text-transform: none;
    font-size: 14px;
    min-width: 140px;
    height: 40px;
    opacity: 1 !important;
    &:hover {
      background-color: var(--colorSystemInfoHover);
    }
  }
`;

const ActionButtons = ({ onSave, hasCancel, hasSave, isLoading, isDisabled }) => {
  const { page } = useParams();
  const history = useHistory();

  const goBack = () => {
    history.push(PARTNER_SETUP_BASE_PATH);
  };

  const handleCancel = () => {
    sessionStorage.setItem('configureActiveTab', page);
    goBack();
  };

  const handleSave = () => {
    sessionStorage.setItem('configureActiveTab', page);
    if (hasSave) onSave(goBack);
    else goBack();
  };

  return (
    <MainContainer mt={1.2} radius={1}>
      {hasCancel && <ButtonCancel onClick={handleCancel}>Cancel</ButtonCancel>}

      <SaveButton onClick={handleSave} variant="outlined" disableElevation disabled={isLoading || isDisabled}>
        {hasSave ? (
          <>
            {isLoading ? (
              <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
            ) : (
              <Save2 mr={1.2} size={1.3} />
            )}
            Save & Exit
          </>
        ) : (
          'Exit'
        )}
      </SaveButton>
    </MainContainer>
  );
};

ActionButtons.defaultProps = {
  onSave: () => {},
  hasCancel: false,
  hasSave: false,
  isLoading: false,
  isDisabled: false
};

ActionButtons.propTypes = {
  onSave: PropTypes.func,
  hasCancel: PropTypes.bool,
  hasSave: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default ActionButtons;
