// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Edit = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21 20" role="img" aria-hidden="false">
    <path d="M19.4051 2.9223L17.2528 0.680325C16.8298 0.23966 16.2732 0 15.6794 0C15.0857 0 14.5291 0.23966 14.106 0.680325L3.61169 11.6119C3.18865 12.0526 2.95858 12.6324 2.95858 13.2509C2.95858 13.2741 2.966 13.2973 2.966 13.3204L0.89536 18.477C0.717239 18.9177 0.791425 19.3893 1.09572 19.6985C1.28868 19.8995 1.54104 20 1.80822 20C1.95665 20 2.1125 19.9691 2.26836 19.8995L7.12958 17.7812C7.19638 17.7889 7.26319 17.8044 7.33741 17.8044C7.93115 17.8044 8.48777 17.5647 8.91081 17.1241L19.4051 6.1925C19.8281 5.75184 20.0582 5.17201 20.0582 4.55354C20.0582 3.93506 19.8281 3.36297 19.4051 2.9223ZM3.84919 15.145L5.48198 16.8458L2.68398 18.0673L3.84919 15.145ZM7.86432 16.0417C7.71589 16.1964 7.53038 16.2659 7.33741 16.2659C7.14445 16.2659 6.95889 16.1886 6.81045 16.0417L4.65813 13.7998C4.36869 13.4983 4.36869 13.0112 4.65813 12.7097L12.5771 4.46076L13.6607 5.52764L10.4026 8.92153C10.1131 9.22304 10.1131 9.71009 10.4026 10.0116C10.551 10.1662 10.7365 10.2358 10.9295 10.2358C11.1225 10.2358 11.308 10.1585 11.4565 10.0116L14.7443 6.58678L15.8724 7.70004L7.86432 16.0417ZM18.3587 5.11017L16.9114 6.6177L13.6236 3.3707L15.1525 1.77812C15.3009 1.6235 15.4865 1.55392 15.6794 1.55392C15.8724 1.55392 16.0579 1.63123 16.2063 1.77812L18.3587 4.0201C18.6481 4.32161 18.6481 4.80866 18.3587 5.11017Z"/>
  </SvgIconStyled>
);

export default Edit;
