// @flow

const pagination = {
  rowsPerPageOptions: [25],
  component: 'div',
  rowsPerPage: 25,
  backIconButtonProps: {
    'aria-label': 'previous page'
  },
  nextIconButtonProps: {
    'aria-label': 'next page'
  }
};

export default pagination;
