// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ScrollRight = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 19.7" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h18v19.7H0z" />
    <g>
      <path d="M12.7,7.2c-0.2,0-0.5,0-0.7,0.1c-0.1-1.1-1-1.9-2-1.8C9.7,5.5,9.3,5.6,9,5.7c-0.4-0.6-1-1-1.8-1   c-0.2,0-0.5,0-0.7,0.1V1.9c-0.1-1.1-1-2-2.2-1.9c-1,0.1-1.8,0.9-1.9,1.9v7.9c-1.1,0-2,0.7-2.3,1.7c-0.3,1.5-0.2,3,0.3,4.4   c0.6,1.5,1.7,2.8,3,3.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2c0.2-0.3,0.2-0.7,0-0.9c0,0,0,0,0,0c-1.1-0.8-2-1.9-2.6-3.1   c-0.5-1.2-0.6-2.5-0.3-3.7c0.1-0.4,0.6-0.7,1-0.7v1.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.6-0.3,0.7-0.7V1.9c0-0.4,0.4-0.6,0.7-0.6   c0.3,0,0.6,0.3,0.6,0.6v7.3c0,0.4,0.3,0.7,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6V6.6C6.6,6.3,7,6,7.3,6c0.3,0,0.6,0.3,0.6,0.6v2.9   c0,0.4,0.3,0.7,0.7,0.6c0.3,0,0.6-0.3,0.6-0.6V7.4C9.3,7,9.7,6.8,10,6.8c0.3,0,0.6,0.3,0.6,0.6v2.8c0,0.4,0.3,0.7,0.7,0.6   c0.3,0,0.6-0.3,0.6-0.6V9.3c-0.1-0.4,0.2-0.7,0.5-0.8s0.7,0.2,0.8,0.5c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.1,0.4,5.6-1.5,9.5   c-0.1,0.3,0,0.7,0.3,0.8c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.1,0.6-0.4c2-4.1,1.6-9.6,1.6-10.1   C14.7,8.2,13.8,7.2,12.7,7.2z" />
      <path d="M18,2.2C18,2.2,18,2.2,18,2.2C18,2.1,18,2.1,18,2.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1   l-1.2-1.2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l0.3,0.3h-4.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h4.8l-0.3,0.3   c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0c0,0,0,0,0,0l1.2-1.2c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0   c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C18,2.3,18,2.2,18,2.2z" />
    </g>
  </SvgIconStyled>
);

export default ScrollRight;
