// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const EyeClose = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 13" role="img" aria-hidden="false">
      <path d="M22.8197 2.88225L23.7942 1.60503C24.0686 1.24551 24.0686 0.753546 23.7942 0.394031C23.3685 -0.164164 22.4981 -0.126322 22.148 0.488638C22.0534 0.658935 21.9493 0.829232 21.8358 0.999529C19.7544 4.23517 16.1214 6.37333 11.9964 6.37333C7.88092 6.37333 4.24797 4.23517 2.16657 0.999529C2.05304 0.829232 1.94895 0.658935 1.85434 0.488638C1.49483 -0.126322 0.624421 -0.164162 0.198679 0.403493C-0.0662265 0.753548 -0.0662265 1.24551 0.198679 1.60503L1.17312 2.89171C1.60833 3.45937 2.09085 3.97972 2.6112 4.47169L1.21101 5.87191C0.842029 6.24088 0.842029 6.83692 1.21101 7.2059C1.40022 7.39512 1.63671 7.48026 1.88269 7.48026C2.12868 7.48026 2.36522 7.38566 2.55444 7.2059L4.07763 5.68269C4.76828 6.18412 5.51573 6.61932 6.30099 6.9883L5.08996 9.69412C4.87236 10.1672 5.08996 10.7348 5.56301 10.943C5.686 10.9997 5.81848 11.0281 5.95094 11.0281C6.31045 11.0281 6.66048 10.82 6.81186 10.4699L8.07018 7.66002C9.04465 7.95331 10.0569 8.14253 11.1071 8.20876C11.0882 8.28444 11.0598 8.35067 11.0598 8.42635V11.6147C11.0598 12.135 11.4855 12.5608 12.0059 12.5608C12.5262 12.5608 12.952 12.135 12.952 11.6147V8.42635C12.952 8.35067 12.9236 8.27498 12.9047 8.20876C13.9927 8.13307 15.0429 7.94385 16.0457 7.62218L17.3229 10.4605C17.4838 10.8105 17.8244 11.0187 18.1839 11.0187C18.3164 11.0187 18.4488 10.9903 18.5718 10.9335C19.0448 10.7159 19.2624 10.1577 19.0448 9.68466L17.8149 6.93153C18.5623 6.58148 19.2624 6.15573 19.9247 5.67323L21.4858 7.23428C21.675 7.4235 21.9115 7.50865 22.1574 7.50865C22.4034 7.50865 22.64 7.41404 22.8292 7.23428C23.1982 6.86531 23.1982 6.26926 22.8292 5.90029L21.3911 4.46223C21.8925 3.97026 22.3845 3.44991 22.8197 2.88225Z"/>
  </SvgIconStyled>
);

export default EyeClose;
