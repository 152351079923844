import { Box, Container, Stack, styled } from '@trustsecurenow/components-library';
import React, { useRef } from 'react';
import Header from './Header';
import BSNMain from './BSNMain';
import Section from './Section';
import AccountDependence from './AccountDependence';
import ContactUs from './ContactUs';

const SECTIONS = [
  {
    id: '01',
    title: 'Dashboard',
    description:
      'Engage with the PII Protect platform through your user dashboard, which includes your Employee Secure Score (ESS), task list, ESS leaderboard, training, dark web status, and policy access. Additionally, you can manage your profile, access the Newsfeed, and submit contact inquiries within this interface.',
    imagePath: '/media/bsnteams/dashboard.svg'
  },
  {
    id: '02',
    title: 'Complete Training',
    description:
      'Access and complete your PII Protect trainings and quizzes (when applicable), including Annual Cybersecurity Courses, Weekly Micro Trainings, Productivity Training, and Newsletters.',
    imagePath: '/media/bsnteams/training.svg'
  },
  {
    id: '03',
    title: 'Receive Notifications',
    description:
      'Receive notifications for new training opportunities and stay informed about activity in the Newsfeed that you have been alerted to.',
    imagePath: '/media/bsnteams/notification.svg'
  },
  {
    id: '04',
    title: 'Mobile Device Access',
    description:
      'Unlock seamless connectivity on the go! Download the Teams app on your mobile device today and enjoy access to your account anytime, anywhere. Stay connected, stay productive!',
    imagePath: '/media/bsnteams/mobile.svg'
  }
];

const BSNTeamsAboutRoot = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(216deg, #308C83 0%, #104255 44.08%, ${theme.palette.primary.main} 63.9%)`
}));

const BSNTeamsAbout = () => {
  const contactUsRef = useRef(null);
  const sectionsRef = useRef(null);

  const handleLearnMoreClick = () => {
    sectionsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleContactUsClick = () => {
    contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <BSNTeamsAboutRoot>
      <Container maxWidth="lg">
        <Header
          onContactUsClick={handleContactUsClick}
          logoPath="/media/bsnteams/logo.svg"
          title="PII Protect"
          isWhiteContactUsButton
        />
        <BSNMain onLearnMoreClick={handleLearnMoreClick} />
        <Stack alignItems={{ xs: 'center', md: 'normal' }} py={11} px={2.5} spacing={9} ref={sectionsRef}>
          {SECTIONS.map(({ description, imagePath, title, id }, index) => (
            <Section
              key={id}
              isReversed={index % 2 !== 0}
              description={description}
              id={id}
              title={title}
              imagePath={imagePath}
              strokeColor="#28BDE9"
              titleColor="common.white"
              descriptionColor="common.white"
            />
          ))}
        </Stack>
        <AccountDependence
          title="Account Dependence"
          avatarColor="#154D5C"
          description="Access to this app requires an account in a qualifying PII Protect product. Please reach out to your IT
          provider to inquire about gaining access."
          titleColor="common.white"
          descriptionColor="common.white"
          avatarIconColor="inherit"
        />
        <ContactUs
          email="help@breachsecurenow.com"
          title="PII Protect Copyright"
          emailColor="common.white"
          copyrightsColor="common.white"
        />
        <Box ref={contactUsRef} />
      </Container>
    </BSNTeamsAboutRoot>
  );
};

export default BSNTeamsAbout;
