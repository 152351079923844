import React, { useState } from 'react';
import { Container } from 'components';
import { PreassessmentButton, PreassessmentEmailStepTitle, PreassessmentInputField } from '../../style';
import cognito from '../../../../helpers/apis/cognito';
import { usePreassessmentContext } from '../../contexts/PreassessmentContext';
import { isValidEmail } from 'helpers';

const PreassessmentEmailStep = () => {
  const [record, setRecord] = useState(null);

  const {
    client_id,
    dispatch,
    disabled,
    user: { email }
  } = usePreassessmentContext();

  const handleChangeEmail = e => {
    setRecord(null);
    dispatch.onChange(e.target.value, e.target.name);
  };

  const handleSubmit = async () => {
    if (!email.trim()) {
      setRecord(prevState => ({ ...prevState, message: 'Please enter an email address' }));
      return false;
    }
    if (isValidEmail(email)) {
      dispatch.setDisabled(true);
      try {
        await cognito.registerExternalUser({
          body: {
            email,
            client_id
          }
        });
        dispatch.setDisabled(false);
        dispatch.changeStep(2);
      } catch (err) {
        console.log('err', err);
        dispatch.setDisabled(false);
        setRecord(prevState => ({ ...prevState, message: 'Something went wrong' }));
      }
    } else {
      setRecord(prevState => ({ ...prevState, message: 'Email is not correct' }));
    }
  };

  return (
    <>
      <PreassessmentEmailStepTitle>Let’s Get Started</PreassessmentEmailStepTitle>
      <Container.Grid alignItems={'flex-start'}>
        <Container.Grid sm={9}>
          <PreassessmentInputField
            value={email}
            name="email"
            label="Enter your business email address"
            onChange={handleChangeEmail}
            fullWidth
            error={record}
            helperText={record?.message}
          />
        </Container.Grid>
        <Container.Grid sm={3} justifyContent={'flex-end'}>
          <PreassessmentButton fullwidth onClick={handleSubmit} disabled={disabled}>
            Next
          </PreassessmentButton>
        </Container.Grid>
      </Container.Grid>
    </>
  );
};

export default PreassessmentEmailStep;
