// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ArrowLeft = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.1508 10.8328H3.15757L9.41584 4.58377C9.50235 4.49796 9.57101 4.39587 9.61787 4.28338C9.66473 4.1709 9.68886 4.05025 9.68886 3.9284C9.68886 3.80655 9.66473 3.6859 9.61787 3.57342C9.57101 3.46094 9.50235 3.35885 9.41584 3.27304C9.33003 3.18652 9.22792 3.11785 9.11544 3.07099C9.00296 3.02413 8.88231 3 8.76045 3C8.6386 3 8.51798 3.02413 8.40549 3.07099C8.29301 3.11785 8.19091 3.18652 8.1051 3.27304L0.923781 10.4544L0.268424 11.1097C0.0965057 11.2827 0 11.5166 0 11.7605C0 12.0043 0.0965057 12.2383 0.268424 12.4112L0.923781 13.0666L8.1051 20.2479C8.19135 20.3334 8.29362 20.4011 8.40609 20.4471C8.51855 20.493 8.63898 20.5163 8.76045 20.5156C8.88193 20.5163 9.00236 20.493 9.11482 20.4471C9.22728 20.4011 9.32959 20.3334 9.41584 20.2479C9.58776 20.075 9.68424 19.841 9.68424 19.5972C9.68424 19.3533 9.58776 19.1193 9.41584 18.9464L3.14833 12.6789H23.1508C23.3826 12.6603 23.5988 12.5549 23.7562 12.3838C23.9137 12.2126 24.0007 11.9884 24 11.7559C24.0007 11.5233 23.9137 11.2991 23.7562 11.1279C23.5988 10.9568 23.3826 10.8514 23.1508 10.8328Z" />
  </SvgIconStyled>
);

export default React.memo(ArrowLeft);
