// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Newsletters = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 36 36" role="img" aria-hidden="false">
      <path d="M20.0061 0.913144C19.4251 0.332052 18.6503 0 17.8201 0C16.9485 0.0138355 16.2014 0.318217 15.6203 0.899308L0.539585 15.9939C0.511914 16.0215 0.498078 16.063 0.470407 16.0907C0.179862 16.3397 0 16.6995 0 17.1007V33.3159C0 34.8378 1.57725 36 3.65258 36H32.2367C34.3259 36 35.8893 34.8517 35.8893 33.3159V17.1007C35.8893 16.4781 20.0061 0.913144 20.0061 0.913144ZM17.5849 2.85012C17.6541 2.78094 17.7371 2.7671 17.7925 2.7671C17.8063 2.75327 17.9447 2.78094 18.0415 2.87779L32.3751 17.2114L18.4842 27.2836C18.4289 27.3251 18.3736 27.3805 18.3182 27.422C18.1799 27.5603 18.0415 27.6018 17.8478 27.4081C17.7925 27.3528 17.7371 27.3113 17.6818 27.2698L3.52806 16.907L17.5849 2.85012ZM32.2367 33.2329H3.65258C3.23751 33.2329 2.91929 33.136 2.7671 33.053V19.7848L15.9939 29.442C16.5749 29.9816 17.3221 30.2721 18.1107 30.2721C18.8855 30.2721 19.6326 29.9816 20.1998 29.4558L33.1222 20.0892V33.053C32.97 33.136 32.6518 33.2329 32.2367 33.2329Z"/>
  </SvgIconStyled>
);

export default Newsletters;