import {
  CopyIcon,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { copyTextToClipboard } from 'helpers';
import { Link as RouterLink } from 'react-router-dom';

const NameCell = ({ name, clientId, decodedClientId = '' }) => {
  const handleCopyClientId = e => {
    e.stopPropagation();
    copyTextToClipboard(decodedClientId);
    enqueueAlertSnackbar(`Client ID: ${decodedClientId} has been copied to the clipboard`);
  };

  return (
    <Stack>
      <Tooltip title="Click here to view this client's account" placement="top">
        <Link
          underline="hover"
          hoverColor="info.main"
          component={RouterLink}
          to={`/clients/dashboard/${clientId}`}
          variant="body2"
        >
          {name}
        </Link>
      </Tooltip>
      {decodedClientId && (
        <Stack direction="row">
          <Typography variant="body2"> Client ID: {decodedClientId}</Typography>
          <Tooltip title="Copy" placement="top">
            <IconButton onClick={handleCopyClientId}>
              <CopyIcon sx={{ fontSize: '1.6rem' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

NameCell.propTypes = {
  name: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  decodedClientId: PropTypes.string
};

export default NameCell;
