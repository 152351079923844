// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerLogin = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20.559 21.275" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20.559v21.275H0z" />
    <g transform="translate(-16.385 -37.9)">
      <path
        d="M28.213,159.694q-1.426,1.111-2.852,2.226c-.221.17-.441.341-.657.511a.757.757,0,0,0,0,1.063.771.771,0,0,0,1.063,0q2.279-1.775,4.558-3.555c.221-.17.441-.341.657-.511a.752.752,0,0,0,0-1.063q-2.279-1.775-4.558-3.555c-.221-.17-.441-.341-.657-.511a.776.776,0,0,0-1.063,0,.757.757,0,0,0,0,1.063q1.813,1.412,3.627,2.828H17.138a.752.752,0,0,0,0,1.5H28.213Z"
        transform="translate(0 -110.362)"
      />
      <path
        d="M118.161,38.687v-.035a.764.764,0,0,0-.752-.752H102.652a.764.764,0,0,0-.752.752V43.17a.752.752,0,1,0,1.5,0V39.4h13.252V55.981c0,.559-.022,1.126-.019,1.69H103.4V54.025a.752.752,0,1,0-1.5,0v4.4a.764.764,0,0,0,.752.752h14.756a.764.764,0,0,0,.752-.752V41.094C118.161,40.292,118.186,39.489,118.161,38.687Z"
        transform="translate(-81.227)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerLogin;
