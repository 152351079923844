/* eslint-disable react/prop-types */
import React from 'react';
import { FormGroup, List, ListItem, Divider, CheckboxInput, styled } from '@trustsecurenow/components-library';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 2.75)
}));

const ClientsList = ({ data = [], checkedAllClients, onSelectAllClients, onChangeClientsList }) => {
  return (
    <List disablePadding>
      <Divider />
      <FormGroup>
        <StyledListItem divider>
          <CheckboxInput
            label="Select All"
            slotProps={{ typography: { variant: 'button' } }}
            CheckboxProps={{ checked: checkedAllClients, onChange: onSelectAllClients, color: 'default' }}
          />
        </StyledListItem>
        {data.map(client => (
          <StyledListItem key={client.id} divider>
            <CheckboxInput
              label={client.name}
              CheckboxProps={{
                onChange: event => onChangeClientsList(event, client),
                color: 'default'
              }}
            />
          </StyledListItem>
        ))}
      </FormGroup>
    </List>
  );
};

export default ClientsList;
