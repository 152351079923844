import React, { useEffect, useState } from 'react';
import {
  ArrowLeft, ArrowRight,
  CurrentPaginationInput,
  MobilePaginationContainer,
  MobilePaginationItemsCount,
  MobilePaginationWrapper
} from './style';
import { useDispatch } from "react-redux";
import { useDebounce } from '../../../hooks';
import { resetCreateUserMatchingEmailError } from "helpers/action/errorHandlerAction";

const MobilePagination = ({
  start,
  end = 0,
  totalCount = 0,
  onChangePage,
  paginationCount,
  isFetching,
  page
}) => {

  const [paginationValue, setPaginationValue] = useState(1)
  const dispatch = useDispatch();

  useEffect(() => {
    setPaginationValue(page)
  }, [page])

  useEffect(() => {
    if (isFetching && page !== paginationValue) setPaginationValue(page);
  }, [isFetching, paginationValue, page]);

  const handleChangePagination = (e) => {
    if (e.target.value) {
      setPaginationValue(Math.floor(+e.target.value))
    } else {
      setPaginationValue(Math.floor(e.target.value))
    }
  }

  const handleBlur = (e) => {
    const {value} = e.target

    if (paginationValue > paginationCount) return false

    if (value < 1) {
      setPaginationValue(1)
    } else {
      onChangePage("_", paginationValue - 1)
    }
    dispatchRedux(resetCreateUserMatchingEmailError());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (paginationValue > paginationCount || paginationValue < 1) return false
    onChangePage("_", paginationValue - 1)
    dispatchRedux(resetCreateUserMatchingEmailError())
  }

  const handleChangePage = (type, isDisabled) => {
    if (isFetching || isDisabled) {
      return null
    }
    if (type === "next") {
      if (paginationValue < paginationCount) {
        setPaginationValue(prevValue => prevValue + 1)
        onChangePage("_", paginationValue)
      }
    } else {
      if (paginationValue > 1) {
        setPaginationValue(prevValue => prevValue - 1)
        onChangePage("_", paginationValue - 2)
      }
    }
    dispatch(resetCreateUserMatchingEmailError());
  }

  const isPrevButtonDisabled = paginationValue <= 1 || isFetching || (paginationValue > paginationCount) && (paginationCount > 0)
  const isNextButtonDisabled = paginationValue >= paginationCount || isFetching

  return (
    <MobilePaginationContainer display={"flex"} py={3} px={3} >
      <MobilePaginationItemsCount>
        {start} - {end} of  {totalCount} items
      </MobilePaginationItemsCount>
      <MobilePaginationWrapper>
        <ArrowLeft
          disabled={isPrevButtonDisabled}
          onClick={() => handleChangePage("prev", isPrevButtonDisabled)}
        />
        <form onSubmit={handleSubmit}>
          <CurrentPaginationInput
            type={"number"}
            value={paginationValue}
            onChange={handleChangePagination}
            onBlur={handleBlur}
            danger={paginationValue > paginationCount && paginationCount > 0}
            disabled={isFetching}
          />
        </form>
        <MobilePaginationItemsCount>
           of  {paginationCount}
        </MobilePaginationItemsCount>
        <ArrowRight
          disabled={isNextButtonDisabled}
          onClick={() => handleChangePage("next", isNextButtonDisabled)}
        />
      </MobilePaginationWrapper>
    </MobilePaginationContainer>
  )
}

export default MobilePagination;
