import styled from "styled-components"
import { Container } from "components"

export * from "./getStarted/PreassessmentLogo"
export * from "./getStarted/PreassessmentWrapper"
export * from "./getStarted/PreassessmentEmailStep"
export * from "./getStarted/PreassessmentVerifyStep"
export * from "./getStarted/PreassessmentInfo"

export * from "./quiz/PreassessmentQuiz"

export * from "./PreassessmentModal"

export const PreassessmentLayout = styled(Container.Paper)`
  width: 100%;
`

