import React from 'react';
import { Button, CustomAlert, LazyIcon } from 'components';
import { Arrow } from 'components/icons';
import { useMaskPartner } from '../../apps/admin/hooks';
import { useLocation } from 'hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const AlertContainer = styled.div`
  padding: 16px;
`;

const ArrowBack = styled(Arrow)`
  transform: rotate(270deg);
  margin-right: var(--spacing);
  path {
    fill: inherit;
  }
`;

export const BackToAdminButton = styled(Button)`
  && {
    color: var(--colorBox);
    background: transparent;
    border: 1px solid var(--colorBox);
    & span {
      color: var(--colorBox);
    }
    &:hover {
      color: var(--colorBox);
      background: transparent;
    }
  }
`;

const MaskModeAlert = () => {
  const { returnToAdmin } = useMaskPartner();
  const { tab } = useLocation();
  const history = useHistory();

  const handleClickToAdmin = () => {
    if (tab !== 'welcomeMessage') {
      returnToAdmin();
    } else {
      history.push('/admin/dashboard/manage-partners');
    }
  };

  return (
    <AlertContainer>
      <CustomAlert
        type="info"
        variant="outlined"
        action={
          <BackToAdminButton onClick={handleClickToAdmin}>
            <ArrowBack />
            Return to admin
          </BackToAdminButton>
        }
        icon={
          <LazyIcon component="DarkWebMan" size={1.5} color="colorSystemInfo" />
        }
      >
        Mask mode enabled
      </CustomAlert>
    </AlertContainer>
  );
};

export default MaskModeAlert;
