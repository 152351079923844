import React, { useEffect, useRef, useState } from 'react';
import { FormHelperText, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import { DoneOutline as CheckIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Controller from './Controller';
import { FormControl } from './ComponentTypes';
import { useFormContext } from 'react-hook-form';

const SelectFieldWrapper = ({
  name,
  required,
  rules,
  variant,
  label,
  allowEmpty,
  emptyValue,
  deselectValue,
  choices = [],
  highlightKey,
  valueKey,
  labelKey,
  id,
  helperText,
  onChange,
  error,
  ...props
}) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (inputLabel !== null && inputLabel.current !== null) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const labels = label
    ? {
        label,
        labelId: `${id || name}__label`,
        labelWidth
      }
    : {};

  const labelValue = label ? { label } : {};

  const variantValue = variant ? 'standard' : 'outlined';

  const handleChange = (...params) => {
    onChange(...params);
  };

  return (
    <FormControl {...props} variant={variantValue} classes={{ root: `FormVariant-${variantValue}` }} error={error}>
      {label && (
        <InputLabel id={`${name}--label`} ref={inputLabel}>
          {label}
        </InputLabel>
      )}

      <Select {...props} {...labels} {...labelValue} onChange={(...params) => handleChange(...params)} name={name}>
        {allowEmpty && (
          <>
            {emptyValue ? (
              <MenuItem value="">
                <em>{emptyValue}</em>
              </MenuItem>
            ) : (
              <MenuItem value="">
                <em>Please Select</em>
              </MenuItem>
            )}
          </>
        )}
        {deselectValue ? (
          <MenuItem value="">
            <em>{deselectValue}</em>
          </MenuItem>
        ) : null}
        {choices.map((k, i) =>
          highlightKey && k[highlightKey] ? (
            <MenuItem key={`menu-item-highlight-${i}`} value={valueKey ? k[valueKey] : k.value}>
              <Tooltip title={highlightKey.replace('_', ' ')}>
                <span>
                  {labelKey ? k[labelKey] : k.label} &nbsp;
                  <CheckIcon style={{ color: green[500] }} />
                </span>
              </Tooltip>
            </MenuItem>
          ) : (
            <MenuItem
              key={`menu-item-${i}`}
              value={valueKey ? k[valueKey] : k.value}
              data-ga-button={props.GAclick && `Dropdown - ${labelKey ? k[labelKey] : k.label}`}
            >
              {labelKey ? k[labelKey] : k.label}
            </MenuItem>
          )
        )}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const SelectField = ({ name, required, rules, onChange, ...props }) => {
  const formContextData = useFormContext();

  if (!formContextData) {
    return <SelectFieldWrapper {...props} onChange={onChange} name={name} />;
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => (
        <SelectFieldWrapper
          {...field}
          {...props}
          onChange={onChange ? onChange : field.onChange}
          name={name}
          error={error || props.error}
          helperText={error?.message || props.helperText}
        />
      )}
    />
  );
};

export default SelectField;
