// @flow
import React, { type Element } from 'react';
import { Container, Tabs } from 'components';

const PartnerEdit: Function = (): Element<*> => (
  <Container.Tab>
    <Tabs />
  </Container.Tab>
);

export default PartnerEdit;
