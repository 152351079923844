// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Message = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15.794 15.801" role="img" aria-hidden="false">
    <g id="icon" transform="translate(-44.122 -44.218)">
      <g id="Group_9" data-name="Group 9" transform="translate(41.122 41.218)">
        <path
          id="Path_190"
          data-name="Path 190"
          d="M14.465,1.316a.056.056,0,0,1,.013.039v9.47a.233.233,0,0,1-.007.039H13.227a1.316,1.316,0,0,0-1.217.809l-.869,1.546-.316.566-.316-.559-.869-1.546a1.326,1.326,0,0,0-1.217-.809l-7.088-.007a.049.049,0,0,1-.02-.039V1.356a.1.1,0,0,1,.007-.039ZM14.478,0H1.316A1.342,1.342,0,0,0,0,1.356v9.47a1.342,1.342,0,0,0,1.316,1.356H8.43a.249.249,0,0,0,.039.086l.895,1.6.895,1.593a.645.645,0,0,0,1.138,0l.895-1.593.895-1.6a.249.249,0,0,0,.039-.086h1.25a1.342,1.342,0,0,0,1.316-1.356V1.356A1.342,1.342,0,0,0,14.478,0Z"
          transform="translate(3 3)"
          fill="#fff"
        />
      </g>
    </g>
  </SvgIconStyled>
);

export default Message;
