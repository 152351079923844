// @flow
import React from 'react';

type Props = Object;

const ContentAdminSendingProfile = (props: Props) => (
  <svg width={props.width || 24} height={props.height || 24} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.2247 19.8978H6.77525C6.39695 19.8978 6.03415 20.0481 5.76665 20.3156C5.49916 20.5831 5.34888 20.9459 5.34888 21.3242C5.34888 21.7025 5.49916 22.0653 5.76665 22.3328C6.03415 22.6003 6.39695 22.7506 6.77525 22.7506H30.2247C30.603 22.7506 30.9658 22.6003 31.2333 22.3328C31.5008 22.0653 31.6511 21.7025 31.6511 21.3242C31.6511 20.9459 31.5008 20.5831 31.2333 20.3156C30.9658 20.0481 30.603 19.8978 30.2247 19.8978Z" />
    <path d="M30.2247 25.7317H6.77525C6.39695 25.7317 6.03415 25.882 5.76665 26.1495C5.49916 26.417 5.34888 26.7798 5.34888 27.1581C5.34888 27.5364 5.49916 27.8992 5.76665 28.1667C6.03415 28.4342 6.39695 28.5844 6.77525 28.5844H30.2247C30.603 28.5844 30.9658 28.4342 31.2333 28.1667C31.5008 27.8992 31.6511 27.5364 31.6511 27.1581C31.6511 26.7798 31.5008 26.417 31.2333 26.1495C30.9658 25.882 30.603 25.7317 30.2247 25.7317Z" />
    <path d="M6.77525 17.0451H14.7772C15.1555 17.0451 15.5183 16.8948 15.7858 16.6273C16.0533 16.3599 16.2035 15.997 16.2035 15.6188C16.2035 15.2405 16.0533 14.8777 15.7858 14.6102C15.5183 14.3427 15.1555 14.1924 14.7772 14.1924H6.77525C6.39695 14.1924 6.03415 14.3427 5.76666 14.6102C5.49916 14.8777 5.34888 15.2405 5.34888 15.6188C5.34888 15.997 5.49916 16.3599 5.76666 16.6273C6.03415 16.8948 6.39695 17.0451 6.77525 17.0451Z" />
    <path d="M6.77525 11.2113H14.7772C15.1555 11.2113 15.5183 11.061 15.7858 10.7935C16.0533 10.526 16.2035 10.1632 16.2035 9.78489C16.2035 9.40659 16.0533 9.04379 15.7858 8.77629C15.5183 8.5088 15.1555 8.35852 14.7772 8.35852H6.77525C6.39695 8.35852 6.03415 8.5088 5.76666 8.77629C5.49916 9.04379 5.34888 9.40659 5.34888 9.78489C5.34888 10.1632 5.49916 10.526 5.76666 10.7935C6.03415 11.061 6.39695 11.2113 6.77525 11.2113Z" />
    <path d="M24.6335 13.736C24.9986 13.7493 25.3627 13.6898 25.7045 13.561C26.0464 13.4322 26.3592 13.2367 26.6247 12.9858C26.8902 12.7348 27.1031 12.4336 27.251 12.0996C27.399 11.7655 27.4789 11.4054 27.4862 11.0401C27.4789 10.6749 27.399 10.3148 27.251 9.98073C27.1031 9.6467 26.8902 9.34545 26.6247 9.09453C26.3592 8.84362 26.0464 8.64806 25.7045 8.51926C25.3627 8.39046 24.9986 8.33099 24.6335 8.34431C24.2679 8.32903 23.903 8.38714 23.5603 8.51523C23.2176 8.64332 22.904 8.83878 22.6381 9.09008C22.3722 9.34137 22.1593 9.6434 22.0121 9.97834C21.8648 10.3133 21.7862 10.6743 21.7808 11.0401C21.7862 11.406 21.8648 11.767 22.0121 12.102C22.1593 12.4369 22.3722 12.7389 22.6381 12.9902C22.904 13.2415 23.2176 13.437 23.5603 13.5651C23.903 13.6931 24.2679 13.7513 24.6335 13.736Z" />
    <path d="M20.5968 17.1164H28.6557C28.7617 17.1164 28.8633 17.0744 28.9382 16.9995C29.0131 16.9246 29.0551 16.823 29.0551 16.7171C29.0562 16.0946 28.8778 15.4851 28.5413 14.9614C28.2049 14.4378 27.7246 14.0222 27.1581 13.7645L26.8443 14.0212C26.729 14.1061 26.6046 14.1779 26.4734 14.2352H26.3736L26.1739 14.3208H26.017H25.8458H25.7317C25.5686 14.3689 25.4015 14.4023 25.2325 14.4206H24.7047H24.2055H24.0771H23.8204L23.4638 14.3208L23.2355 14.2352H23.1214H22.9645H22.879L22.4796 14.007C22.3655 13.9071 22.2371 13.8215 22.1372 13.7217C21.5556 13.9729 21.0604 14.3892 20.713 14.9191C20.3657 15.449 20.1814 16.0692 20.1831 16.7028C20.1831 16.7571 20.1938 16.8109 20.2146 16.8611C20.2354 16.9113 20.2659 16.9569 20.3043 16.9953C20.3427 17.0337 20.3883 17.0642 20.4385 17.0849C20.4887 17.1057 20.5424 17.1164 20.5968 17.1164Z"/>
    <path d="M32.7638 4.12825e-08H4.23638C3.10149 4.12825e-08 2.01309 0.450835 1.2106 1.25332C0.408111 2.05581 -0.0427246 3.14422 -0.0427246 4.27911V32.7352C-0.0427246 33.87 0.408111 34.9585 1.2106 35.7609C2.01309 36.5634 3.10149 37.0143 4.23638 37.0143H32.7638C33.8986 37.0143 34.987 36.5634 35.7895 35.7609C36.592 34.9585 37.0429 33.87 37.0429 32.7352V4.35042C37.0523 3.78251 36.9486 3.2184 36.7378 2.69097C36.527 2.16355 36.2133 1.68337 35.815 1.27843C35.4167 0.873492 34.9418 0.551903 34.4179 0.332412C33.8941 0.11292 33.3317 -7.88521e-05 32.7638 4.12825e-08ZM34.1901 32.7352C34.1901 33.1135 34.0398 33.4763 33.7723 33.7438C33.5049 34.0112 33.142 34.1615 32.7638 34.1615H4.23638C3.85808 34.1615 3.49528 34.0112 3.22778 33.7438C2.96029 33.4763 2.81001 33.1135 2.81001 32.7352V4.35042C2.81001 3.97213 2.96029 3.60933 3.22778 3.34183C3.49528 3.07434 3.85808 2.92405 4.23638 2.92405H32.7638C33.142 2.92405 33.5049 3.07434 33.7723 3.34183C34.0398 3.60933 34.1901 3.97213 34.1901 4.35042V32.7352Z"/>
  </svg>
);

export default ContentAdminSendingProfile;
