// @flow
import React from 'react';
import { PageError } from '../common';

type Props = {
  children: Element<any>,
};

class ErrorBoundary extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  };

  componentDidCatch(error) {
    this.setState((prevState) => {
      return {
        ...prevState,
        message: error.message
      }
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <PageError
          title="Oops!"
          subtitile="There was a problem with the page?"
          description={this.state.message}
          linkText="Contact Us"
          linkTo="/user/contactUs"
        />
      );
    }

    return this.props.children;
  };
};

export default ErrorBoundary;
