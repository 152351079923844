import { useParams, useHistory } from 'react-router-dom';
import { ADMIN_ROOT_PATH } from '../constants';

const useAdminNavigate = () => {
  const { page } = useParams();
  const history = useHistory();

  const navigateToEdit = (id) => {
    history.push(`${ADMIN_ROOT_PATH}/${page}/edit/${id}`);
  };

  const navigateToCreate = () => {
    history.push(`${ADMIN_ROOT_PATH}/${page}/create`);
  };

  const navigateToList = () => {
    history.push(`${ADMIN_ROOT_PATH}/${page}`);
  };

  const navigateToConflict = (id) => {
    history.push(`${ADMIN_ROOT_PATH}/${page}/conflict/${id}`)
  }

  const navigateGoBack = () => {
    history.goBack();
  };

  return {
    navigateToEdit,
    navigateToCreate,
    navigateToConflict,
    navigateToList,
    navigateGoBack
  };
};

export default useAdminNavigate;