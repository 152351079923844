// @flow
const currentDate = new Date();
const currentDateAndTime = currentDate.toISOString();

const trackServerRoomAccess = {
  layout: Object.freeze({
    header: Object.freeze({
      title: 'Server Room Access'
    }),
    content: Object.freeze({
      component: 'shared/track/TrackServerAccessModal'
    }),
    footer: Object.freeze({
      left: false,
      right: `apps/shared/track/TrackServerFooterRight`
    })
  }),
  open: false,
  disabled: true,
  isNew: false,
  defaultChoice: 'trackServerRoomAccess',
  initialState: {
    date: currentDateAndTime,
    name: '',
    company: '',
    reason: '',
    changes: '',
    notes: ''
  }
};
// const trackServerRoomAccess = {
//   title: 'Server Room Access',
//   component: 'shared/track/TrackServerAccessModal',
//   noQuery: true,
//   buttons: {
//     cancel: {
//       label: 'Cancel'
//     },
//     submit: {
//       label: 'Save'
//     }
//   },
//   initialState: {
//     date: currentDateAndTime,
//     name: '',
//     company: '',
//     reason: '',
//     changes: '',
//     notes: ''
//   }
// };

export default trackServerRoomAccess;
