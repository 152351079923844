// @flow
import React from 'react';
import { Container, Typography, LazyIcon } from 'components';

type PartnerInfoProps = {
  item: Object,
  isSimilar?: boolean,
}

const PartnerInfoRow = ({ item, isSimilar }: PartnerInfoProps) => {
  return (
    <>
      <Container.Grid item xs={6} sm={2} md={3} lg={3}>
        <Typography.p p={2} m={0.1} fontSize={14} color={isSimilar ? 'colorSystemDanger' : 'colorDefault'}>
          {item.name}
        </Typography.p>
      </Container.Grid>
      <Container.Grid item xs={6} sm={2} md={2} lg={2}>
        <Typography.p p={2} m={0.1} fontSize={14} color={isSimilar ? 'colorSystemDanger' : 'colorDefault'}>
          {item.email}
        </Typography.p>
      </Container.Grid>
      <Container.Grid item xs={6} sm={2} md={3} lg={3}>
        <Typography.p p={2} m={0.1} fontSize={14}>
          {item.link}
        </Typography.p>
      </Container.Grid>
      <Container.Grid item xs={6} sm={2} md={2} lg={2}>
        <Typography.p p={2} m={0.1} fontSize={14}>
          {item.distributor}
        </Typography.p>
      </Container.Grid>
      <Container.Grid item xs={6} sm={2} md={2} lg={2}>
        <Typography.p p={2} m={0.1} fontSize={14}>
          {item.active ? (
            <LazyIcon
              component="Enable"
              color="colorSystemSuccess"
              strokeColor="colorSystemSuccess"
            />
          ) : (
            <LazyIcon
              component="Disable"
              color="colorSystemDanger"
              strokeColor="colorSystemDanger"
            />
          )}
        </Typography.p>
      </Container.Grid>
    </>
  )
};

export default React.memo(PartnerInfoRow);