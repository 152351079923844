import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Container } from 'components';

const ImgWrap: ComponentType<*> = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const Img: ComponentType<*> = styled.img`
  width: 100%;
  max-width: 310px;
  height: auto;
  margin: 0 auto;
  ${props => props.theme.breakpoints.down('sm')} {
    max-width: 400px;
    width: 80%;
  }
`;

const TextContent: ComponentType<*> = styled.div`
  max-width: 440px;
  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 auto 15px;
    text-align: center;
  }
`;

const Title: ComponentType<*> = styled.p`
  font-size: 95px;
  line-height: 1.1;
  font-weight: bold;
  margin: 100px 0 0;
  color: var(--colorBase);
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.down('md')} {
    margin: 50px 0 0;
    font-size: 120px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 0 10px;
    font-size: 90px;
  }
`;

const SubTitle: ComponentType<*> = styled.p`
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  color: var(--colorBase);
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 28px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`;

const Paragraph: ComponentType<*> = styled.p`
  font-size: 11px;
  font-weight: 600;
  margin: 10px 0;
  color: var(--colorBase);
  opacity: 0.5;
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 20px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

const CustomContainerGrid: ComponentType<*> = styled.div`
 display: flex; 
 height: calc(100vh - 159px);
 align-items: center; 
 flex-direction: row;
 justify-content:center;
 width:100%;
 ${props => props.theme.breakpoints.down('sm')} {
  height:auto;
  flex-direction:column;
  margin: 0 auto;
}
`;

const LinkStyled: ComponentType<*> = styled(Link)`
  text-decoration: underline;
  color: var(--colorSystemInfo);
  font-size: 12px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 5px;
    font-size: 16px;
  }
`;

function PageError({ title, subtitile, description, linkText, linkTo }) {
  return (
    <Box mt={{ xs: 0, md: 2 }}>
      <Container.Paper p={2} radius={1}>
        <CustomContainerGrid >
          <Container.Grid item md={6} xs={12}>
            <ImgWrap>
              <Img src="/media/woman.svg" alt="Woman" />
            </ImgWrap>
          </Container.Grid>
          <Container.Grid item md={6} xs={12} style={{ margin: 'auto' }}>
            <TextContent>
              <Title>{title}</Title>
              <SubTitle>{subtitile}</SubTitle>
              <Paragraph>{description}</Paragraph>
              <LinkStyled to={linkTo}>{linkText}</LinkStyled>
            </TextContent>
          </Container.Grid>
        </CustomContainerGrid>
      </Container.Paper>
    </Box>
  );
}

export default PageError;
