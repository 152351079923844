// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerBPP = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h16v16H0z" />
    <g transform="translate(-360 -201)">
      <path
        d="M48.308,39.685l-6.493-6.493a1.5,1.5,0,0,0-1.065-.442H34.257a1.509,1.509,0,0,0-1.507,1.507V47.243a1.509,1.509,0,0,0,1.507,1.507H47.243a1.509,1.509,0,0,0,1.507-1.507V40.75A1.5,1.5,0,0,0,48.308,39.685Zm-7.094,2.109h5.8a.116.116,0,0,1,.116.116v5.1a.116.116,0,0,1-.116.116H34.489a.116.116,0,0,1-.116-.116V34.489a.116.116,0,0,1,.116-.116h5.1a.116.116,0,0,1,.116.116v5.8A1.509,1.509,0,0,0,41.214,41.793Zm.232-1.623a.116.116,0,0,1-.116-.116V35L46.5,40.17Z"
        transform="translate(327.25 168.25)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerBPP;
