import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alert3, Info } from 'components/icons';

const useStyles = makeStyles(theme => ({
  taskItem: {
    cursor: 'pointer',
    color: 'var(--colorDark)',
    marginTop: 12,
    boxShadow: ({ isSelected }) => (isSelected ? '0px 4px 4px var(--boxShadow)' : 'none'),
    opacity: ({ isEnabled }) => (isEnabled ? 1 : 0.5),
    pointerEvents: ({ isEnabled }) => (isEnabled ? 'auto' : 'none'),
    '&:first-child': {
      marginTop: 0
    },
    '&:hover': {
      boxShadow: '0px 4px 4px var(--boxShadow)',
      '& p': {
        color: 'var(--colorSystemInfo)'
      }
    }
  },
  taskName: {
    margin: '0 auto 0 0',
    paddingRight: 10,
    fontSize: 16,
    transition: '0.3s',
    color: ({ complete, isInfoTask, isSelected }) =>
      // eslint-disable-next-line no-nested-ternary
      complete && !isInfoTask
        ? 'var(--colorSystemSuccess) !important'
        : isSelected
        ? 'var(--colorSystemInfo)'
        : 'var(--colorDark)',
    // eslint-disable-next-line no-nested-ternary
    fontWeight: ({ isInfoTask }) => (isInfoTask ? 700 : 400)
  },
  checkbox: {
    marginRight: 10,
    fontSize: 22,
    color: ({ complete }) => (complete ? 'var(--colorSystemSuccess)' : 'var(--colorDark)')
  },
  endText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '20px',
    color: ({ isRetake, isEnabled, complete }) =>
      isRetake && isEnabled && !complete ? 'var(--colorSystemWarning)' : 'var(--colorDark)'
  }
}));

const TaskItem = ({ task, taskName, isSelected, onSelect, isMarkedComplete }) => {
  const { name, complete, enabled, percent_complete, is_a_retake } = task;
  const isInfoTask = taskName === 'information';
  const disbaleOrgProfileAndQuestions = ['org_profile', 'assessment'].includes(taskName) && isMarkedComplete;
  const isEnabled = (enabled || isInfoTask) && !disbaleOrgProfileAndQuestions;
  const classes = useStyles({ complete, isEnabled, isSelected, isInfoTask, isRetake: is_a_retake });

  const getEndText = () => {
    switch (taskName) {
      case 'org_profile':
      case 'assessment': {
        if (is_a_retake) {
          return complete ? 'Complete' : 'Incomplete';
        }
        return percent_complete;
      }
      case 'work_plan':
        return complete ? 'Complete' : percent_complete;
      case 'mark_complete':
        return complete ? 'Complete' : 'Incomplete';
      default:
        return percent_complete;
    }
  };

  return (
    <Paper className={classes.taskItem} elevation={0} onClick={() => isEnabled && onSelect(taskName)}>
      <Box p={2.5} display="flex" alignItems="center">
        {isInfoTask ? (
          <>
            <p className={classes.taskName}>{task.report_name}</p>
            <Info color="info" />
          </>
        ) : (
          <>
            {complete ? (
              <CheckBoxIcon className={classes.checkbox} />
            ) : (
              <CheckBoxOutlineBlankIcon className={classes.checkbox} />
            )}
            <p className={classes.taskName}>{name}</p>
            <span className={classes.endText}>
              {is_a_retake && !complete ? <Alert3 color="inherit" mr={1.5} size={1.3} /> : null}
              {getEndText()}
            </span>
          </>
        )}
      </Box>
    </Paper>
  );
};

TaskItem.propTypes = {
  task: PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, null]),
    name: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    percent_complete: PropTypes.string.isRequired,
    is_a_retake: PropTypes.bool
  }).isRequired,
  taskName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isMarkedComplete: PropTypes.bool.isRequired
};

export default TaskItem;
