// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Box, Menu, MenuItem } from '@material-ui/core';
import { Button } from 'components';
import { ArrowDown } from 'components/icons';
import { SEARCH_OPTIONS } from '../../constants';
import { setFeedSearchBy, setFeedSearch } from 'helpers/action';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 60,
    zIndex: 1,
    '& [class*=ContainerButton]': {
      height: '100%',
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 54
    }
  },
  btn: {
    padding: '8px !important',
    color: 'var(--colorDark)',
    height: '100%',
    minWidth: '100%',
    borderRadius: '5px 0 0 5px !important',
    position: 'relative',
    '& svg.arrow': {
      transform: props => `rotate(${props.open ? 180 : 0}deg)`
    },
    '& svg:not(.arrow)': {
      fontSize: 20,
      color: 'var(--colorDark)',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      width: 1,
      height: '100%',
      background: 'var(--colorDark)'
    },
  },
  menuLink: {
    fontSize: '16px',
    fontWeight: 400,
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    },
    '& svg': {
      marginRight: '12px'
    },
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  label: {
    fontWeight: 700
  }
}));

const SearchBySelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ open: Boolean(anchorEl) });
  const dispatch = useDispatch();
  const searchBy = useSelector(state => state.newsfeed.searchBy);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelect = val => {
    setAnchorEl(null);
    dispatch(setFeedSearchBy(val));
    dispatch(setFeedSearch(null));
  };

  const getSelectedIndex = () => {
    const index = SEARCH_OPTIONS.findIndex(item => item.value === searchBy);
    return index;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.content}>
      <Button
        onClick={openMenu}
        className={classes.btn}
        backgroundColor="backgroundPaper"
        backgroundColorHover="backgroundPaper"
        variant="contained"
        disableElevation
      >
        {SEARCH_OPTIONS[getSelectedIndex()].icon}
        <ArrowDown size={1} ml={0.5} color="colorDark" className="arrow" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem disabled className={classes.menuLink}>
          <span className={classes.label}>Search by:</span>
        </MenuItem>
        {SEARCH_OPTIONS.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === searchBy}
            onClick={() => onSelect(option.value)}
            className={classes.menuLink}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SearchBySelect;
