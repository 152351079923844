// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const SuccessXl = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 230 230" role="img" aria-hidden="false">
    <path d="M115 0C92.2552 0 70.0211 6.74463 51.1095 19.381C32.1978 32.0174 17.458 49.9779 8.7539 70.9914C0.0498257 92.0049 -2.22756 115.128 2.20974 137.435C6.64705 159.743 17.5998 180.234 33.6828 196.317C49.7658 212.4 70.2569 223.353 92.5647 227.79C114.873 232.228 137.995 229.95 159.009 221.246C180.022 212.542 197.983 197.802 210.619 178.891C223.255 159.979 230 137.745 230 115C230 99.898 227.026 84.9439 221.246 70.9914C215.467 57.039 206.996 44.3615 196.317 33.6827C185.639 23.004 172.961 14.5331 159.009 8.75385C145.056 2.97456 130.102 0 115 0ZM115 204.852C97.229 204.852 79.8571 199.582 65.081 189.709C50.3049 179.836 38.7883 165.803 31.9876 149.385C25.1869 132.967 23.4076 114.9 26.8745 97.4707C30.3415 80.0412 38.8991 64.0311 51.4651 51.465C64.0311 38.899 80.0412 30.3414 97.4708 26.8745C114.9 23.4075 132.967 25.1869 149.385 31.9876C165.803 38.7883 179.836 50.3048 189.709 65.0809C199.582 79.857 204.852 97.229 204.852 115C204.852 138.83 195.386 161.684 178.535 178.535C161.685 195.385 138.83 204.852 115 204.852Z"/>
    <path d="M145.842 92.1027L147.605 93.8655L145.842 92.1027L102.375 135.569L85.3099 118.504C82.9686 116.163 79.1805 116.163 76.8392 118.504C74.4979 120.846 74.4979 124.633 76.8392 126.975L78.607 125.207L76.8392 126.975L98.0873 148.223C98.73 148.865 99.4549 149.271 100.084 149.533L100.115 149.546L100.148 149.558C100.681 149.763 101.485 150.04 102.445 150.04C104.017 150.04 105.503 149.429 106.652 148.337L106.675 148.315L106.698 148.293L154.382 100.608L154.417 100.574L154.45 100.538C156.626 98.1853 156.658 94.4487 154.312 92.1027C151.971 89.7613 148.183 89.7614 145.842 92.1027Z" stroke={props.strokeColor} stroke-width="5"/>
  </SvgIconStyled>
);

export default SuccessXl;
