// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DownloadIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C16.3475 1 19.941 4.2211 20.3692 8.33591C22.4453 8.75484 24.0093 10.6261 24 12.8696C24 15.4298 21.9798 17.5058 19.4942 17.5058C18.9821 17.5058 18.5632 17.0869 18.5632 16.5748C18.5632 16.0628 18.9821 15.6439 19.4942 15.6439C20.9464 15.6439 22.1381 14.3964 22.1381 12.8696C22.1381 11.3522 20.9557 10.114 19.4942 10.114C19.2242 10.114 18.9914 10.0023 18.8239 9.82543C18.6563 9.65786 18.5446 9.42513 18.5446 9.16446C18.5446 5.69201 15.6028 2.87121 11.9907 2.87121C8.39718 2.87121 5.47399 5.69201 5.47399 9.16446C5.47399 9.55546 5.23195 9.88129 4.8875 10.0209C4.76648 10.0675 4.64545 10.1047 4.50581 10.1047C3.05352 10.1047 1.86191 11.3522 1.86191 12.879C1.86191 14.3964 3.04421 15.6346 4.50581 15.6346C5.01783 15.6346 5.43676 16.0535 5.43676 16.5655C5.43676 17.0776 5.01783 17.4965 4.50581 17.4965C2.02016 17.4965 0 15.4298 0 12.879C0 10.626 1.58261 8.74553 3.65864 8.33591C4.08688 4.2211 7.66174 1 12 1ZM12.9403 19.7121L14.9884 17.6641C15.3515 17.301 15.9473 17.301 16.3103 17.6641C16.4872 17.8502 16.5803 18.083 16.5803 18.325C16.5803 18.5671 16.4872 18.7998 16.3103 18.986L13.3313 21.9651L12.6703 22.626C12.3072 22.9891 11.7114 22.9891 11.3484 22.626L10.6874 21.9651L7.70834 18.986C7.34527 18.6229 7.34527 18.0271 7.70834 17.6641C8.07141 17.301 8.66722 17.301 9.03029 17.6641L11.0784 19.7121V13.1582C11.0784 12.6462 11.4973 12.2273 12.0093 12.2273C12.5214 12.2273 12.9403 12.6462 12.9403 13.1582V19.7121Z" />
  </SvgIconStyled>
);

export default DownloadIcon;
