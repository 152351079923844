// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const CommentIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g id="Communication/Full-Comment">
      <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M2.7853 2H21.2054C22.7466 2 23.9907 3.2441 24 4.7853V14.9702C24 15.323 23.935 15.6665 23.805 15.9729C23.5543 16.6507 23.0344 17.1984 22.3752 17.4955C22.1339 17.607 21.8739 17.672 21.6046 17.7091L19.4785 20.9029C19.1257 21.4228 18.5501 21.7292 17.928 21.7292H17.9095C17.2781 21.7292 16.7025 21.4043 16.359 20.875L14.3629 17.7462H2.7853C1.2441 17.7462 0 16.5021 0 14.9609V4.7853C0 3.2441 1.2441 2 2.7853 2ZM22.1338 14.961V14.9609H22.1246V4.7853C22.1246 4.27466 21.7161 3.85687 21.1961 3.85687H2.77602C2.2561 3.85687 1.84759 4.27466 1.84759 4.7853V14.9702C1.84759 15.4808 2.2561 15.8986 2.77602 15.8986H14.335C14.5239 15.9031 14.6776 15.8985 14.8059 15.8947H14.806H14.806C15.2139 15.8825 15.365 15.8779 15.5698 16.1957L17.928 19.8723L20.3327 16.2514C20.608 15.8645 20.6328 15.8652 20.9952 15.876C21.0849 15.8786 21.1953 15.8819 21.3354 15.8801C21.7718 15.8151 22.0967 15.4623 22.1246 15.0259C22.1338 15.0074 22.1338 14.9795 22.1338 14.961ZM8.66232 10.7551H15.3285C15.8391 10.7551 16.2569 10.3373 16.2569 9.82669C16.2569 9.31605 15.8391 8.89825 15.3285 8.89825H8.66232C8.15168 8.89825 7.73389 9.31605 7.73389 9.82669C7.73389 10.3373 8.15168 10.7551 8.66232 10.7551ZM15.3285 7.35706H8.66232C8.15168 7.35706 7.73389 6.93927 7.73389 6.42863C7.73389 5.91799 8.15168 5.5002 8.66232 5.5002H15.3285C15.8391 5.5002 16.2569 5.91799 16.2569 6.42863C16.2569 6.93927 15.8391 7.35706 15.3285 7.35706ZM8.66232 14.1253H15.3285C15.8391 14.1253 16.2569 13.7075 16.2569 13.1969C16.2569 12.6863 15.8391 12.2685 15.3285 12.2685H8.66232C8.15168 12.2685 7.73389 12.6863 7.73389 13.1969C7.73389 13.7075 8.15168 14.1253 8.66232 14.1253Z" />
      </g>
  </SvgIconStyled>
);

export default CommentIcon;
