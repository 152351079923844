// @flow

const trainingUsers = {
  title: 'Micro Training',
  component: 'clients/ClientsTrainingModal',
  buttons: {
    cancel: {
      label: 'Cancel'
    }
  },
  initialState: null
};

export default trainingUsers;
