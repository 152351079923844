import React from 'react';
import { Clock, Arrows, UserOutline2, Hashtag, Users2, Users3, Settings, Newsletter, Microtraining } from 'components/icons';
import { HeartFilledBeatIcon, OtherPoliciesIcon, PartnerIcon, PlanetIcon, PeopleIcon, Microsoft365Icon, SelectedRobotFillIcon } from '@trustsecurenow/components-library';

export const MAX_IMG_SIZE = 10485760; // 10MB

export const ASSET_COOKIES = ['CloudFront-Key-Pair-Id', 'CloudFront-Policy', 'CloudFront-Signature'];

export const SORT_OPTIONS = [
    { label: 'Most Recent', value: 'most_recent', icon: <Arrows size={1.7} /> },
    { label: 'Last Activity', value: 'last_activity_datetime', icon: <Clock size={1.7} /> }
];
  
export const SEARCH_OPTIONS = [
    { label: 'Hashtag', value: 'hashtag', icon: <Hashtag size={1.2} /> },
    { label: 'User', value: 'user', icon: <UserOutline2 size={1.2} /> }
];

export const FILTER_OPTIONS = [
    { label: 'Micro Trainings', value: 'microtrainings' },
    { label: 'Newsletters', value: 'security_newsletters' },
    { label: 'Favorites', value: 'favorites' }
];

export const AUDIENCES = {
    partner: 'Partner',
    general: 'General',
    clients: 'Clients',
    cybersecurity: 'Cybersecurity',
    healthcare: 'Healthcare',
    microsoft: 'Microsoft',
    artificial_intelligence: 'Artificial Intelligence',
}

export const AUDIENCE_ORDER = [
  AUDIENCES.partner,
  AUDIENCES.general,
  AUDIENCES.clients,
  AUDIENCES.cybersecurity,
  AUDIENCES.healthcare,
  AUDIENCES.microsoft,
  AUDIENCES.artificial_intelligence
];
export const ROLE_ORDER = ['Manager Admin', 'Manager', 'User', 'Partner Administrator'];

export const AUDIENCE_OPTIONS = [
    { name: AUDIENCES.partner, tooltip: 'Partner', icon: <PartnerIcon />},
    { name: AUDIENCES.general, tooltip: 'General', icon: <PlanetIcon />},
    { name: AUDIENCES.clients, tooltip: 'All Clients', icon: <PeopleIcon />},
    { name: AUDIENCES.cybersecurity, tooltip: 'Cybersecurity', icon: <OtherPoliciesIcon /> },
    { name: AUDIENCES.healthcare, tooltip: 'Healthcare', icon: <HeartFilledBeatIcon /> },
    { name: AUDIENCES.microsoft, tooltip: 'Microsoft', icon: <Microsoft365Icon /> },
    { name: AUDIENCES.artificial_intelligence, tooltip: 'Artificial Intelligence', icon: <SelectedRobotFillIcon /> },
    { name: 'Roles', value: 'Roles', icon: <Users3 /> },
];

export const ROLE_OPTIONS = [
    { id: '7', name: 'Manager Admin', value: 'Manager Admin', abbr: 'MA', icon: <Settings /> },
    { id: '2', name: 'Manager', value: 'Manager', abbr: 'M', icon: <UserOutline2 /> },
    { id: '3', name: 'Employee', value: 'User', abbr: 'E', icon: <Users2 /> },
    { id: '5', name: 'Partner Administrator', value: 'Partner Administrator', abbr: 'PA', icon: <PartnerIcon /> },
];

export const CONTENT_OPTIONS = {
    security_newsletter: {
      title: 'Select Newsletter',
      param: 'security_newsletters',
      tooltip: 'Newsletter',
      view: 'security_newsletter',
      icon: <Newsletter />
    },
    microtraining: {
      title: 'Select Micro Training',
      param: 'microtrainings',
      tooltip: 'Micro Training',
      view: 'microtraining',
      icon: <Microtraining />
    },
};