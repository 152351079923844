// @flow
import React, { type Element, useMemo } from 'react';
import { useConfig, useTableRowSelected } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import { hasKey } from 'helpers';
import { LazyIcon } from 'components/icons';
import TableHead from './TableHead';
import TableToolbar from './TableToolbar';
import {
  PaperContent,
  TableResponsive,
  PaginationTable,
  TableContent,
  TableBodyContent,
  TableRowContent,
  TableCellContent
} from './ComponentTypes';
import { useTableList2 } from './TableList2Context';
import Modal3 from '../modal/Modal3';
import { TableListNoResults, TableListSelecting, TableListCell } from './containers';
import CustomTablePagination from '../table/pagination/CustomTablePagination';
import { isMobile } from "react-device-detect";

// const stableSort = (array, orderBy, order) => {
//   if (!Array.isArray(array)) return [];
//   array.sort(sortingUtil(orderBy, order === 'asc'));
//   return array;
// };
// function sortingUtil(orderBy, ascending) {
//   return (a, b) => {
//     let first = a[orderBy];
//     let second = b[orderBy];
//     if (typeof first === 'string') {
//       first = first.toLowerCase();
//     }
//     if (typeof second === 'string') {
//       second = second.toLowerCase();
//     }
//     if (first && typeof first === 'object' && hasKey(first, 'label')) {
//       first = first.label;
//     }
//     if (second && typeof second === 'object' && hasKey(second, 'label')) {
//       second = second.label;
//     }
//     // equal items sort equally
//     if (first === second) {
//       return 0;
//     }
//     // nulls sort after anything else
//     if (first === null) {
//       return 1;
//     }
//     if (second === null) {
//       return -1;
//     }
//     // otherwise, if we're ascending, lowest sorts first
//     if (ascending) {
//       return first < second ? -1 : 1;
//     }
//     // if descending, highest sorts first
//     return first < second ? 1 : -1;
//   };
// }

type TableListTableType = {
  tableSettings?: Object,
  data?: Object,
  source?: string,
  id?: string,
  onSearch?: Function,
  cache?: boolean
};

const TableListTable = ({
  tableSettings,
  data,
  source,
  id,
  onSearch,
  cache = true
}: TableListTableType): Element<*> => {
  const {
    tab,
    settings,
    record,
    order,
    orderBy,
    page,
    open,
    dispatch: { handleRequestSort, handleChangeRowsPerPage, setNewPage, setOpen, setPassword },
    toolbar,
    total,
    status,
    isFetching,
    rowsPerPage,
    handleSelectAllRows,
    transformBeforeSubmit
  } = useTableList2();

  const modalMemo = useMemo(
    () =>
      open && (
        <Modal3
          {...settings.row.modal}
          open={!!open}
          setOpen={setOpen}
          rowId={open}
          rowIndex={record.map(x => x.id).indexOf(open)}
          parentTab={tab}
          fullScreen={settings.row.modal.fullScreen || false}
          transformBeforeSubmit={transformBeforeSubmit}
        />
      ),
    [open]
  );
  const hiddenCell = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { pagination } = useConfig('system', 'table');
  const { isSelected } = useTableRowSelected();
  if (!settings) return <h1>API does not exist</h1>;
  const { total: sTotal } = settings;
  const tableList = record;
  // const tableList = stableSort(record, orderBy, order);

  return (
    <PaperContent>
      <TableToolbar setToolbar={toolbar} />
      <TableResponsive>
        <TableContent aria-labelledby="tableTitle" size="medium">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            tableSettings={settings}
            onSearch={onSearch}
            isFilter={false}
            showPassword={setPassword}
            handleSelectAllRows={handleSelectAllRows}
            isFetching={isFetching}
          />
          <TableBodyContent isFetching={isFetching}>
            <TableListNoResults
              hasRecords={tableList.length === 0}
              fetched={status === 'success'}
              colNumber={settings?.cells?.length + (!hiddenCell ? 3 : 1)}
            />
            {tableList.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const isOpen = open === row.id;

              return (
                <>
                  <TableRowContent
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!hiddenCell && <TableCellContent />}
                    <TableListSelecting row={row} index={index} />
                    <TableListCell isOpen={isOpen} row={row} />
                    {!hiddenCell && <TableCellContent />}
                  </TableRowContent>
                  {isOpen && settings.row.action === 'modal' ? modalMemo : ''}
                </>
              );
            })}
          </TableBodyContent>
        </TableContent>
      </TableResponsive>
      {/* {modal && open && (
        <Modal3 {...modal} open={open === row.id} setOpen={setOpen} rowId={rowId} handleModal={handleModal} />
      )} */}

      {tableList.length !== 0 && (
        <CustomTablePagination
          {...pagination}
          totalCount={total || sTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={setNewPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          isFetching={isFetching}
        />
      )}
    </PaperContent>
  );
};

TableListTable.defaultProps = {
  data: null,
  source: '',
  id: '',
  tableSettings: null,
  onSearch: e => e,
  cache: true
};

export default TableListTable;
