import { useEffect } from 'react';

const useResourceUrl = (resourceUrl, onLoad) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.onload = onLoad;
    script.src = resourceUrl;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [onLoad, resourceUrl]);
};
export default useResourceUrl;
