import {
  SET_FEEDS,
  CLEAR_FEEDS,
  EMPTY_FEEDS,
  ADD_FEED,
  UPDATE_FEED,
  UPDATE_FEED_ID,
  UPDATE_FEED_SCORE,
  UPDATE_FEEDS,
  DELETE_FEED,
  SET_COMMENTS,
  CLEAR_COMMENTS,
  ADD_COMMENT,
  UPDATE_COMMENT_ID,
  DELETE_COMMENT,
  SET_NOTIFICATIONS,
  MARK_OPENED,
  MARK_ALL_OPENED,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  NEWSFEED_LICENSE,
  NEWSFEED_LICENSE_AGREED,
  SET_USER_NOTIFICATION_COUNT,
  SET_CAN_FLAG,
  SET_CAN_PIN_POST,
  SET_FEED_SEARCH,
  SET_FEED_SEARCH_BY,
  SET_FEED_FILTER,
  SET_FEED_SEARCH_FILTER,
  SET_HAS_PINNED_POST
} from 'conf/constants';

const initialState = {
  feeds: null,
  comments: {},
  notifications: [],
  reloadNotifications: false,
  reloadFeeds: false,
  license: null,
  newsfeed_unopened_notifications_count: 0,
  canFlag: false,
  canPinPost: false,
  search: null,
  searchBy: 'hashtag',
  filter: {},
  hasPinnedPost: false
};

const newsfeedReducer = (state = initialState, action) => {
  switch (action.type) {
    //general

    case NEWSFEED_LICENSE: {
      return {
        ...state,
        license: action.payload
      };
    }

    case NEWSFEED_LICENSE_AGREED: {
      return {
        ...state,
        license: { ...state.license, newsfeed_license_agreed: 1 }
      };
    }

    case SET_CAN_FLAG: {
      return {
        ...state,
        canFlag: action.payload
      };
    }

    case SET_CAN_PIN_POST: {
      return {
        ...state,
        canPinPost: action.payload
      };
    }

    case SET_HAS_PINNED_POST: {
      return {
        ...state,
        hasPinnedPost: action.payload
      };
    }

    //feeds

    case SET_FEEDS:
      return {
        ...state,
        feeds: action.payload
      };

    case CLEAR_FEEDS:
      return {
        ...state,
        feeds: null
      };

    case EMPTY_FEEDS:
      return {
        ...state,
        feeds: []
      };

    case ADD_FEED: {
      const newFeed = action.payload;
      const { hasPinnedPost, feeds } = state;
      let newFeeds = [...feeds];

      if (newFeeds) newFeeds.splice(hasPinnedPost ? 1 : 0, 0, newFeed);
      else newFeeds = [newFeed];

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case UPDATE_FEED: {
      const { postId, params } = action;

      return {
        ...state,
        feeds: state.feeds.map(feed =>
          feed.id === postId
            ? {
                ...feed,
                ...params
              }
            : feed
        )
      };
    }

    case UPDATE_FEED_ID: {
      const { oldId, newId } = action;
      const newFeeds = [...state.feeds];

      const feed = newFeeds.find(obj => obj.id === oldId);
      if (feed) {
        feed.id = newId;
        delete feed.disableActions;
      }

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case UPDATE_FEED_SCORE: {
      const { postId, value } = action;
      const newFeeds = [...state.feeds];

      const elementIndex = newFeeds.findIndex(element => element.id === postId);
      newFeeds[elementIndex] = {
        ...newFeeds[elementIndex],
        microtraining: { ...newFeeds[elementIndex].microtraining, user_score: value }
      };

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case UPDATE_FEEDS: {
      return {
        ...state,
        reloadFeeds: action.payload
      };
    }

    case DELETE_FEED: {
      const feedId = action.payload;
      let newFeeds = [...state.feeds];
      newFeeds = newFeeds.filter(obj => obj.id !== feedId);

      return {
        ...state,
        feeds: newFeeds
      };
    }

    case SET_FEED_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    case SET_FEED_SEARCH_BY: {
      return {
        ...state,
        searchBy: action.payload
      };
    }

    case SET_FEED_FILTER: {
      return {
        ...state,
        filter: action.payload
      };
    }

    case SET_FEED_SEARCH_FILTER: {
      return {
        ...state,
        searchFilter: action.payload
      };
    }

    //comments

    case SET_COMMENTS: {
      const { postId, postComments } = action;
      const newComments = { ...state.comments };

      if (postComments.length) newComments[postId] = postComments;
      else delete newComments[postId];

      if (newComments[postId]) {
        let ids = [];
        newComments[postId] = newComments[postId].filter(item => {
          if (!ids.includes(item.id)) {
            ids.push(item.id);
            return item;
          }
        });
      }

      return {
        ...state,
        comments: newComments
      };
    }

    case CLEAR_COMMENTS: {
      const postId = action.payload;
      let newComments = { ...state.comments };

      if (postId) delete newComments[postId];
      else newComments = {};

      return {
        ...state,
        comments: newComments
      };
    }

    case ADD_COMMENT: {
      const { postId, newComment } = action;
      const newComments = { ...state.comments };

      if (newComments[postId]) newComments[postId].unshift(newComment);
      else newComments[postId] = [newComment];

      return {
        ...state,
        comments: newComments
      };
    }

    case UPDATE_COMMENT_ID: {
      const { postId, oldId, newId } = action;
      const newComments = { ...state.comments };

      const comment = newComments[postId].find(obj => obj.id === oldId);
      comment.id = newId;
      delete comment.disableActions;

      return {
        ...state,
        comments: newComments
      };
    }

    case DELETE_COMMENT: {
      const { postId, commentId } = action;
      let newComments = { ...state.comments };
      newComments[postId] = newComments[postId]?.filter(obj => obj.id !== commentId);

      return {
        ...state,
        comments: newComments
      };
    }

    //notifications

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };

    case MARK_OPENED: {
      const { notificationId } = action;
      let newNotifications = [ ...state.notifications ];
      if (newNotifications.length) {
        let idx = newNotifications.findIndex( s => s.notification_id == +notificationId );
        if (newNotifications[idx]) newNotifications[idx].opened = 1;
      }

      return {
        ...state,
        notifications: newNotifications
      };
    }

    case MARK_ALL_OPENED: {
      let newNotifications = [ ...state.notifications ];
      if (newNotifications.length) {
        newNotifications = newNotifications.map(item => {
          return { ...item, opened: 1 };
        });
      }

      return {
        ...state,
        notifications: newNotifications
      };
    }

    case ADD_NOTIFICATION: {
      const notifications = state.notifications;
      return {
        ...state,
        notifications: notifications ? [action.payload, ...notifications] : notifications
      };
    }

    case DELETE_NOTIFICATION: {
      let newNotifications = [ ...state.notifications ];
      newNotifications = newNotifications.filter(obj => obj.notification_id !== action.id);
      return {
        ...state,
        notifications: newNotifications
      };
    }

    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        reloadNotifications: action.payload
      };
    }

    case SET_USER_NOTIFICATION_COUNT: {
      const { replace, value } = action;      
      const newValue = replace ? value : state.newsfeed_unopened_notifications_count + value;

      return {
        ...state,
        newsfeed_unopened_notifications_count: newValue >= 0 ? newValue : 0
      };
    }
  
    default:
      return state;
  }
};

export default newsfeedReducer;
