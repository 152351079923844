import React from "react"
import QuizContentHeader from './QuizContentHeader';
import QuizContentBody from './QuizContentBody';
import QuizContentFooter from './QuizContentFooter';
import {
  QuizContentWrapper
} from './quizStyle';

const QuizContent = () => {

  return (
    <QuizContentWrapper>
      <QuizContentHeader/>
      <QuizContentBody/>
      <QuizContentFooter/>
    </QuizContentWrapper>
  )
}

export default QuizContent
