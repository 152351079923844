// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const SelectedBullet = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" role="img" aria-hidden="false">
    <g transform="translate(-492 -470)">
      <circle cx="501" cy="479" r="4" />
      <g transform="translate(492 470)">
        <circle cx="9" cy="9" r="9" fill="none" />
        <path d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9c5,0,9-4,9-9C18,4,14,0,9,0z M9,16.5c-4.1,0-7.5-3.4-7.5-7.5S4.9,1.5,9,1.5    s7.5,3.4,7.5,7.5S13.1,16.5,9,16.5z" />
      </g>
    </g>
  </SvgIconStyled>
);

export default SelectedBullet;
