import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import {
  CONTENT_ADMIN,
  CONTENT_ADMIN_CAMPAIGN,
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_ROOT_PATH,
  CONTENT_ADMIN_SCENARIO,
  CONTENT_ADMIN_SENDING_PROFILES
} from '../constants';
import {
  ContentAdminFormLayout,
  EmailTemplateForm,
  LandingPagesForm,
  ScenarioForm,
  SendingProfileForm
} from '../components/ContentAdminFormLayout';
import { ContentAdminFormProvider } from '../context';
import { useContentAdminForm } from '../hooks';
import { LoadingStyled, RHFForm as Form, Button } from 'components';
import { useCheckAccess } from 'helpers';

const formComponent = {
  [CONTENT_ADMIN_EMAIL_TEMPLATES]: <EmailTemplateForm />,
  [CONTENT_ADMIN_LANDING_PAGES]: <LandingPagesForm />,
  [CONTENT_ADMIN_SENDING_PROFILES]: <SendingProfileForm />,
  [CONTENT_ADMIN_SCENARIO]: <ScenarioForm />
};

const ContentAdminFormPage = () => {
  const hasAccess = useCheckAccess('app', CONTENT_ADMIN);
  const { page, method, id } = useParams();
  const history = useHistory();

  if (!(method === 'edit' || method === 'create') || (!id && method === 'edit') || (method === 'create' && id)) {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${page}`);
  }

  const { pickLists, isLoading = false, record, dispatch, defaultValues, ...query } = useContentAdminForm(
    page,
    id,
    hasAccess
  );

  if (hasAccess === null) return <LoadingStyled />;

  if (!hasAccess) return <div>You don't have access for this page</div>;

  return (
    <Box pt={2}>
      <ContentAdminFormProvider
        value={{
          method,
          page,
          id,
          pickLists,
          dispatch,
          record,
          ...query
        }}
      >
        {!isLoading ? (
          <Form defaultValues={defaultValues} onSubmit={dispatch.onSubmit}>
            <ContentAdminFormLayout>{formComponent[page]}</ContentAdminFormLayout>
          </Form>
        ) : (
          <LoadingStyled />
        )}
      </ContentAdminFormProvider>
    </Box>
  );
};

export default ContentAdminFormPage;
