import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const WelcomeMessageCell = ({ enabled, onEnableClick, onDisableClick }) => {
  if (isNA(enabled)) return <Typography variant="body2">N/A</Typography>;

  const tooltipTitle = enabled
    ? 'Welcome Message enabled. New users will receive welcome messages by default'
    : 'Click here to enable Welcome Messages to send to all new users by default';
  const Icon = enabled ? CircleCheckIcon : CircleCloseIcon;
  const IconButtonProps = enabled
    ? {
        color: 'success',
        onClick: onDisableClick
      }
    : {
        color: 'error',
        onClick: onEnableClick
      };

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <IconButton {...IconButtonProps}>
        <Icon sx={{ fontSize: '2rem' }} />
      </IconButton>
    </Tooltip>
  );
};

WelcomeMessageCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onEnableClick: PropTypes.func.isRequired,
  onDisableClick: PropTypes.func.isRequired
};

export default WelcomeMessageCell;
