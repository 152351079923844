const selectRecipients = {
  defaultSortValue: {
    sortname: 'last_name',
    order: 'asc'
  },
  selecting: true,
  selectingAll: true,
  sort: ['last_name', 'role', 'tag', 'ess', 'email'],
  head: [
    {
      id: 'last_name',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left'
    },
    {
      id: 'role',
      label: 'Role',
      align: 'left'
    },
    {
      id: 'tag',
      label: 'Tag',
      align: 'left'
    },
    {
      id: 'ess',
      label: 'ESS',
      align: 'left'
    }
  ]
};

export default selectRecipients;
