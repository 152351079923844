// @flow

import otherPolicy from './otherPolicy';
import securityPolicy from './securityPolicy';
import microTrainingUsers from './microTrainingUsers';
import trainingCertificate from './trainingCertificate';

const myDashboard = {};

myDashboard.otherPolicy = otherPolicy;
myDashboard.securityPolicy = securityPolicy;
myDashboard.microTrainingUsers = microTrainingUsers;
myDashboard.trainingCertificate = trainingCertificate;

export default myDashboard;
