// @flow

const trackServerRoomAccessInfo = {
  title: 'Server Room Access',
  component: 'shared/track/TrackServerAccessModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save'
    }
  },
  initialState: {}
};

export default trackServerRoomAccessInfo;
