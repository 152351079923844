import { useMediaQuery, useTheme } from '@material-ui/core';

export const useBreakpoint = (breakPoint) => {
  const theme = useTheme();
  const isBreakpointView = useMediaQuery(theme.breakpoints.down(breakPoint));

  return isBreakpointView
}

export default useBreakpoint
