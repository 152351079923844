// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerSecurity = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 17.85 23.039" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h17.85v23.039H0z" />
    <g transform="translate(-21.1 -12.725)">
      <path d="M28.432,35.238l1.083.464a.785.785,0,0,0,.371.062,1.553,1.553,0,0,0,.371-.062l1.237-.526a12.111,12.111,0,0,0,7.455-11.2v-6a.892.892,0,0,0-.495-.8L30.35,12.841a.925.925,0,0,0-.9,0l-7.858,4.331a.892.892,0,0,0-.495.8V24.1A12.237,12.237,0,0,0,28.432,35.238Zm-5.445-16.7,6.96-3.836,7.146,3.836v5.445a10.286,10.286,0,0,1-6.311,9.5l-.9.371-.712-.309A10.331,10.331,0,0,1,22.987,24.1Z" />
      <path
        d="M38.11,46.92h.031l.712-.588,5.661-4.578L43.338,40.3l-4.981,4.022-2.135-2.506L34.8,43.022l2.753,3.186Z"
        transform="translate(-9.462 -19.045)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerSecurity;
