const newsletters = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc',
  },
  selecting: true,
  sort: ['name', 'published_date'],
  rowClick: true,
  head: [
    {
      id: 'id',
      label: 'ID',
      align: 'left',
    },
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'hash_tags',
      label: 'Hashtag',
      align: 'left',
    },
    {
      id: 'published_date',
      label: 'Published Date',
      align: 'left',
    },
    {
      label: 'Status',
      align: 'left',
    },
    // {
    //   //id: "mass_email",
    //   label: 'Mass email',
    //   align: 'left',
    // },
    // {
    //   //id: "scheduled",
    //   label: 'Scheduled',
    //   align: 'left',
    // },
    // {
    //   label: 'Send',
    //   align: 'left',
    // },
    {
      id: "link",
      label: 'Download',
      align: 'left',
    },
  ],
};

export default newsletters;