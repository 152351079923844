// @flow

import { useSelector } from 'react-redux';
import useLocation from './useLocation';
import useTabsAllowed from './useTabsAllowed';

type useTabDefaultTypes = {
  tab: string,
  layout: Object,
  layoutOverride?: Object,
  accessMerger?: Object
};

function useTabDefault({ tab, layout, layoutOverride, accessMerger = {} }: useTabDefaultTypes) {
  const active = useSelector(state => state.bsn.system.tabCurrent);
  const { app } = useLocation();
  const allowed = useTabsAllowed({ layout, app, accessMerger });

  return active || (tab !== 'false' && tab) || getActive(allowed) || getActive(layout);
}

function getActive(layout) {
  return Object.keys(layout).filter(t => layout[t].active)[0];
}

export default useTabDefault;
