// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const ArrowExternal = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1482 0H23.0741C23.5926 0 24.0093 0.415544 23.9908 0.923432V1.84686V6.03001C23.9908 6.5379 23.5741 6.95344 23.0648 6.95344C22.5556 6.95344 22.1389 6.5379 22.1389 6.03001V3.15814L17.0556 8.22778C16.8704 8.40323 16.6389 8.49557 16.3982 8.49557C16.1574 8.49557 15.926 8.41247 15.7408 8.22778C15.3797 7.86764 15.3797 7.28588 15.7408 6.92574L20.8241 1.85609H17.9445C17.4353 1.85609 17.0186 1.44055 17.0186 0.932661C17.0278 0.6741 17.1297 0.44325 17.2964 0.277032C17.4723 0.10158 17.6945 0.00923432 17.9537 0H22.1482ZM22.4166 9.47441L22.4352 9.45595C23.0093 8.87419 24 9.28049 24 10.1023V20.3155C24 22.3471 22.3426 24 20.3056 24H3.69446C1.65743 24 0 22.3471 0 20.3155V3.74913C0 1.71758 1.65743 0.0646477 3.69446 0.0646477H13.6944C14.5093 0.0646477 14.9167 1.05271 14.3426 1.62524L14.3241 1.64371C14.1574 1.80993 13.9166 1.91151 13.6759 1.91151H3.69446C2.67594 1.91151 1.85185 2.73336 1.85185 3.74913V20.3155C1.85185 21.3313 2.67594 22.1531 3.69446 22.1531H20.3056C21.3241 22.1531 22.1481 21.3313 22.1481 20.3155V10.1208C22.1481 9.88073 22.25 9.64986 22.4166 9.47441Z"
    />
  </SvgIconStyled>
);

export default React.memo(ArrowExternal);
