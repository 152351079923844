// @flow

const phishingCampaignScenariosList = {
  title: 'Phishing Scenario Preview',
  component: 'shared/phishingCampaign/PhishingCampaignScenariosModal',
  requireRowId: true,
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Close'
    }
  },
  initialState: null
};

export default phishingCampaignScenariosList;
