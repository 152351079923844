// @flow

const trackServerRoomAccess = {
  type: 'track',
  selecting: true,
  selectAll: true,
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  row: {
    click: true,
    selecting: true,
    action: 'newModal',
    modal: {
      app: 'clients',
      tab: 'trackServerRoomAccessInfo'
    }
  },

  cells: [
    { id: 'date', label: 'Server Room Access Date', align: 'left', type: 'date' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'company', label: 'Company', align: 'left' },
    { id: 'reason', label: 'Reason', align: 'left' },
    { id: 'created', label: 'Created', align: 'left' },
    { id: 'modified', label: 'Modified', align: 'left' },
  ]
};

export default trackServerRoomAccess;
