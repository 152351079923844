import { Stack, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import RoundedButton from './RoundedButton';

const HSNMain = ({ onLearnMoreClick }) => {
  return (
    <Stack px={4.75} pt={13.5} spacing={5} useFlexGap alignItems="center">
      <Typography
        color="primary.dark"
        variant="heading"
        fontSize={{ xs: 32, md: 56 }}
        lineHeight={{ xs: '44.8px', md: '67.2px' }}
        textAlign="center"
        maxWidth={723}
      >
        <Typography variant="inherit" component="span" color="error">
          Simplifying
        </Typography>{' '}
        Cybersecurity and Productivity Training Within Your Daily Workspace
      </Typography>
      <RoundedButton onClick={onLearnMoreClick} width={168} size="large">
        Learn more
      </RoundedButton>
      <img src="/media/hsnteams/hero.svg" alt="HIPPA Secure Now" width="100%" />
    </Stack>
  );
};

HSNMain.propTypes = {
  onLearnMoreClick: PropTypes.func.isRequired
};

export default HSNMain;
