// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Minus = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path d="M13.6464 8.64705H2.35491C1.7934 8.64705 1.33398 8.34705 1.33398 7.98039C1.33398 7.61372 1.7934 7.31372 2.35491 7.31372H13.6464C14.2079 7.31372 14.6673 7.61372 14.6673 7.98039C14.6673 8.34705 14.2079 8.64705 13.6464 8.64705Z" />
  </SvgIconStyled>
);

export default React.memo(Minus);