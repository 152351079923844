// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const PinFilled = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_5823_39959)">
      <path d="M23.15 5.15L18.81 0.81C18.28 0.28 17.59 0 16.85 0C16.11 0 15.41 0.29 14.89 0.81L10.87 4.83C10.68 5.01 10.52 5.23 10.4 5.46H3.66999C3.44999 5.46 3.24999 5.53 3.08999 5.66C2.99999 5.7 2.90999 5.76 2.82999 5.84C2.46999 6.2 2.46999 6.78 2.82999 7.14L7.14999 11.46L0.139995 22.59C-0.130005 23.02 -5.30481e-06 23.59 0.439995 23.86C0.589995 23.95 0.759995 24 0.929995 24C1.18999 24 1.44999 23.89 1.60999 23.69C1.63999 23.69 1.66999 23.66 1.68999 23.64L12.15 16.48L16.75 21.08C16.93 21.26 17.17 21.35 17.4 21.35C17.63 21.35 17.88 21.26 18.06 21.08C18.14 21 18.21 20.9 18.25 20.79C18.25 20.79 18.25 20.7833 18.25 20.77C18.37 20.62 18.43 20.43 18.43 20.22V13.59C18.68 13.46 18.92 13.29 19.12 13.08L23.14 9.06C24.22 7.98 24.22 6.22 23.14 5.14L23.15 5.15Z" />
    </g>
    <defs>
      <clipPath id="clip0_5823_39959">
        <rect width="23.96" height="24" fill="white"/>
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default React.memo(PinFilled);
