// @flow

const disasterRecovery = {
  title: 'Disaster Recovery Plan',
  component: 'clients/ClientsEditDisasterRecoveryInfoModal',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      icon: 'Done',
      label: 'Save',
      uploadType: 'disaster_recovery' // to UploadPresignedURL API
    },
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: null
};

export default disasterRecovery;
