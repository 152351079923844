import React from 'react';
import { Box } from '@material-ui/core';
import { CalendarTime, Archive } from 'components/icons';
import { ArchiveIcon, CalendarTimeIcon } from '@trustsecurenow/components-library';

export const findLastTagBeforeLimit = (html, limit) => {
  // regex to match HTML tags in a string
  const regex = /<[^>]*>/g;
  let match;
  let lastIndex = 0;
  while ((match = regex.exec(html)) !== null) {
    if (match.index >= limit) {
      break;
    }
    lastIndex = regex.lastIndex;
  }
  return lastIndex;
};

export const getProgressColor = val => {
  if (val === 100) return 'var(--colorSystemSuccess)';
  if (val >= 35) return 'var(--colorSystemWarning)';
  if (val > 0) return 'var(--colorSystemDanger)';
  return 'var(--backgroundDefault)';
};

export const getUpdateWorkPlanTaskText = val => {
  switch (val) {
    case 'pending':
      return {
        taskName: 'Update Work Plan',
        description:
          'We are currently processing your reports, and once they are ready, your Work Plan will be accessible for you to review and address your recommendations. Please note that your reports may take up to one week to be finalized and made available.'
      };
    case 'published':
      return {
        taskName: 'Update Work Plan',
        description:
          'Your {Assessment Name} is now available. Next, begin reviewing and addressing the recommendations within your work plan. Once you have marked a recommendation complete, the corresponding question in your risk assessment questionnaire will be updated for future assessments.'
      };
    case 'completed':
      return {
        taskName: 'Update Work Plan',
        description:
          'Great job! You have no outstanding recommendations to address at this time. Keep up the good work by maintaining strong safeguards, and remember to complete your assessment annually.'
      };
    default:
      return {
        taskName: 'Update Work Plan',
        description:
          'We are currently processing your reports, and once they are ready, your Work Plan will be accessible for you to review and address your recommendations. Please note that your reports may take up to one week to be finalized and made available.'
      };
  }
};

export const getRecommendationChipColor = text => {
  switch (text) {
    case 'Required':
      return 'error';
    case 'Addressable':
      return 'warning';
    default:
      return;
  }
};

export const getRecommendationChipColorOld = text => {
  switch (text) {
    case 'Required':
      return 'var(--colorSystemDanger)';
    case 'Addressable':
      return 'var(--colorSystemWarning)';
    default:
      return 'var(--colorDefault)';
  }
};

export const getThreatsChipColor = text => {
  switch (text) {
    case 'High':
      return 'error';
    case 'Medium':
      return 'warning';
    case 'Low':
      return 'success';
    default:
      return;
    // return 'var(--colorDefault)';
  }
};

export const getThreatsChipColorOld = text => {
  switch (text) {
    case 'High':
      return 'var(--colorSystemDanger)';
    case 'Medium':
      return 'var(--colorSystemWarning)';
    case 'Low':
      return 'var(--colorSystemSuccess)';
    default:
      return 'var(--colorDefault)';
  }
};

export const getSraReportChip = status => {
  switch (status) {
    case 0:
      return {
        label: 'Archived',
        disabled: true,
        icon: <ArchiveIcon />
      };
    case 1:
      return {
        label: 'Pending',
        color: 'warning',
        icon: <CalendarTimeIcon />
      };
    case 2:
      return {
        label: 'Published',
        color: 'success',
        icon: null
      };
    default:
      return {
        label: 'Published',
        color: 'success',
        icon: null
      };
  }
};

export const getSraReportChipOld = status => {
  switch (status) {
    case 0:
      return {
        text: 'Archived',
        color: 'var(--colorLightGray)',
        icon: (
          <Box pl={1} pr={0.5} display="flex" alignItems="center">
            <Archive size={1.1} color="colorLightGray" />
          </Box>
        )
      };
    case 1:
      return {
        text: 'Pending',
        color: 'var(--colorSystemWarning)',
        icon: (
          <Box pl={1} pr={0.5} display="flex" alignItems="center">
            <CalendarTime size={1.1} color="warning" />
          </Box>
        )
      };
    case 2:
      return {
        text: 'Published',
        color: 'var(--colorSystemSuccess)',
        icon: null
      };
    default:
      return {
        text: 'Published',
        color: 'var(--colorSystemSuccess)',
        icon: null
      };
  }
};

export const getStatus = (status, is_deleted) => {
  return is_deleted ? 0 : status;
};

export const getSraReportActions = (status, index, can_be_retaken, can_restore, can_be_edited) => {
  switch (true) {
    case status === 0: // Archived
      return [...(can_restore ? ['restore'] : [])];
    case status === 1: // Pending
      return ['publish', ...(can_be_edited ? ['edit'] : [])];
    case status === 2 && index === 0: // Published and latest
      return ['update', ...(can_be_retaken ? ['reTake'] : [])];
    case status === 2 && index !== 0: // Published
      return ['delete'];
    default:
      return [];
  }
};

export const getRiskLevel = (probability, impact) => {
  switch (`${probability}${impact}`) {
    case 'LowLow':
    case 'LowMedium':
    case 'MediumLow':
      return 'Low';
    case 'MediumMedium':
    case 'LowHigh':
    case 'HighLow':
      return 'Medium';
    case 'HighHigh':
    case 'MediumHigh':
    case 'HighMedium':
      return 'High';
    default:
      return 'Low';
  }
};

export const getRecommendationSubsections = isHSN => {
  const list = [
    { value: 'Access Controls', label: 'Access Controls' },
    { value: 'Audit Controls', label: 'Audit Controls' },
    ...(isHSN ? [{ value: 'Business Associates', label: 'Business Associates' }] : []),
    { value: 'Contingency Planning', label: 'Contingency Planning' },
    { value: 'Device and Media Controls', label: 'Device and Media Controls' },
    { value: 'Encryption', label: 'Encryption' },
    { value: 'Facility Access Controls', label: 'Facility Access Controls' },
    { value: 'Information Access', label: 'Information Access' },
    { value: 'Password Control', label: 'Password Control' },
    { value: 'Security Awareness', label: 'Security Awareness' },
    { value: 'Security Management', label: 'Security Management' },
    { value: 'Security Response', label: 'Security Response' },
    ...(!isHSN ? [{ value: 'Service Providers', label: 'Service Providers' }] : []),
    { value: 'Workstation Security', label: 'Workstation Security' }
  ];

  return list;
};

export const getRecommendationSections = () => {
  const list = [
    { value: 'Administrative', label: 'Administrative' },
    { value: 'Physical', label: 'Physical' },
    { value: 'Technical', label: 'Technical' }
  ];

  return list;
};

export const getRecommendationPriorities = () => {
  const list = [
    { value: 'Required', label: 'Required' },
    { value: 'Addressable', label: 'Addressable' }
  ];

  return list;
};
