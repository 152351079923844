// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PlaySquare = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.76923 0H21.2308C22.7631 0 24 1.24615 24 2.76923V21.2308C24 22.7538 22.7538 24 21.2308 24H2.76923C1.24615 24 0 22.7538 0 21.2308V2.76923C0 1.24615 1.24615 0 2.76923 0ZM21.2308 22.1538C21.7385 22.1538 22.1538 21.7385 22.1538 21.2308V2.76923C22.1538 2.26154 21.7385 1.84615 21.2308 1.84615H2.76923C2.26154 1.84615 1.84615 2.26154 1.84615 2.76923V21.2308C1.84615 21.7385 2.26154 22.1538 2.76923 22.1538H21.2308ZM13.1539 8.46461L16.7355 10.5138C17.5109 10.9569 17.9909 11.8061 17.9909 12.7015C17.9909 13.5969 17.5109 14.4277 16.7355 14.8708L13.1539 16.92L9.5724 18.9692C9.19394 19.1908 8.76011 19.3015 8.32627 19.3015C6.95088 19.3015 5.82471 18.1754 5.82471 16.8V12.6923V8.58461C5.82471 7.20922 6.94165 6.08307 8.32627 6.08307C8.76011 6.08307 9.19394 6.19384 9.5724 6.41538L13.1539 8.46461Z" />
  </SvgIconStyled>
);

export default React.memo(PlaySquare);
