// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const LikeIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path d="M13.8031 5.40348H10.0744L10.5719 3.02002C10.7194 2.32587 10.4061 1.61944 9.77951 1.22015L8.35435 0.304858C7.81991 -0.0391456 7.16262 -0.0944318 6.58518 0.151285C6.05075 0.384715 5.67606 0.83929 5.57163 1.3983L4.73616 5.79047C4.68088 5.83347 4.63173 5.87034 4.58259 5.91334C4.25701 5.60005 3.80858 5.40348 3.31715 5.40348H1.84287C0.82315 5.40348 0 6.23277 0 7.24635V14.1571C0 15.1707 0.82315 16 1.84287 16H3.31715C3.80858 16 4.25701 15.8034 4.58259 15.4901C4.95731 15.8096 5.47332 16 6.05075 16H12.0094C13.1213 16 14.012 15.2874 14.1226 14.3476L15.8856 7.57192C15.9163 7.46749 15.9286 7.36307 15.9286 7.25864C15.9286 6.22049 14.9948 5.40348 13.8031 5.40348ZM3.93144 7.25864V14.1448C3.93144 14.151 3.93144 14.151 3.93144 14.1571C3.93144 14.495 3.66115 14.7714 3.31715 14.7714H1.84287C1.50501 14.7714 1.22858 14.495 1.22858 14.1571V7.24635C1.22858 6.90849 1.50501 6.63206 1.84287 6.63206H3.31715C3.66115 6.63206 3.93144 6.90849 3.93144 7.24635C3.93144 7.25249 3.93144 7.2525 3.93144 7.25864ZM12.9062 14.1448C12.9062 14.4888 12.507 14.7714 12.0094 14.7714H6.05075C5.56546 14.7714 5.17231 14.495 5.16002 14.1571C5.16002 14.151 5.16002 14.151 5.16002 14.1448V7.58421V7.25864C5.16002 7.2525 5.16002 7.25249 5.16002 7.24635C5.17231 6.90849 5.56546 6.63206 6.05075 6.63206H6.73876L6.46232 6.58291C6.1306 6.52149 5.90946 6.19591 5.97089 5.86419L6.0569 5.40348L6.77561 1.62558C6.81247 1.42286 6.97221 1.32458 7.06435 1.28158C7.26707 1.19558 7.49432 1.214 7.68475 1.33686L9.11606 2.25216C9.30649 2.37502 9.40479 2.57773 9.36179 2.76816L8.80892 5.40348L8.72905 5.77819C8.72291 5.80277 8.72291 5.8212 8.72291 5.84577C8.71676 5.85805 8.71064 5.87033 8.7045 5.88876C8.62464 6.26962 8.91334 6.63206 9.30649 6.63206H9.42321H9.81021H13.797C14.2884 6.63206 14.6938 6.91464 14.6938 7.25864L12.9062 14.1448Z" />
  </SvgIconStyled>
);

export default LikeIcon;
