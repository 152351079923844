const trainingTrainingsUsers = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc'
  },
  selecting: false,
  sort: ['name', 'score', 'date_completed'],
  rowClick: false,
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'score',
      label: 'Score',
      align: 'left'
    },
    {
      id: 'date_completed',
      label: 'Completed',
      align: 'left'
    }
  ]
};

export default trainingTrainingsUsers;
