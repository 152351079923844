// @flow

import { MyCompany as MyCompanyIcon } from 'components/icons';
import CompanyList from './CompanyList';

export default {
  name: 'myCompany',
  list: CompanyList,
  icon: MyCompanyIcon,
  options: {
    label: 'My Company'
  }
};
