// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Arrow = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 12 7" role="img" aria-hidden="false">
      <path d="M11.5 5.9538C11.5 6.08824 11.4424 6.21788 11.3367 6.3139C11.1351 6.48675 10.8326 6.46755 10.6597 6.27069L6.00239 0.960347L1.34023 6.27069C1.16738 6.47235 0.860094 6.49156 0.663237 6.3139C0.466379 6.14105 0.442372 5.83376 0.620024 5.63691L5.27738 0.326562C5.45983 0.120102 5.72391 6.72963e-05 5.99759 6.72996e-05C6.27127 6.73029e-05 6.53534 0.120102 6.7178 0.326562L11.3752 5.63691C11.4616 5.72813 11.5 5.84337 11.5 5.9538Z" />
  </SvgIconStyled>
);


export default Arrow;
