import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const FileCheck = props => (
  <SvgIconStyled {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76817 22.0623H10.895C11.3287 22.7543 11.8731 23.3633 12.5375 23.8524C12.5651 23.8708 12.5836 23.8892 12.602 23.9077L12.6021 23.9077H4.76817C3.23645 23.9077 2 22.6713 2 21.1396V2.80507C2 1.27336 3.23645 0.0369089 4.76817 0.0369089H11.624V0L17.6125 5.98847H17.6494V10.7405H17.5848C17.5156 10.7359 17.4441 10.7336 17.3725 10.7313C17.301 10.729 17.2295 10.7266 17.1603 10.722C17.0968 10.722 17.0334 10.7176 16.9688 10.713C16.902 10.7084 16.834 10.7036 16.7636 10.7036H16.7451H16.7174C16.4037 10.7036 16.0992 10.7313 15.8039 10.7589V7.83391H12.6667C11.0796 7.83391 9.77854 6.5421 9.77854 4.94579V1.88235H4.76817C4.26067 1.88235 3.84544 2.29758 3.84544 2.80507V21.1396C3.84544 21.6563 4.26067 22.0623 4.76817 22.0623ZM15.0012 5.98847L11.624 2.6113V4.94579C11.624 5.52711 12.0946 5.98847 12.6667 5.98847H15.0012ZM16.7821 24C13.3496 24 10.5537 21.2872 10.5537 17.9562C10.5537 14.6252 13.3496 11.9124 16.7821 11.9124C20.2146 11.9124 23.0105 14.6252 23.0105 17.9562C23.0105 21.2872 20.2146 24 16.7821 24ZM16.7821 13.7578C14.3646 13.7578 12.3992 15.6402 12.3992 17.9562C12.3992 20.2722 14.3646 22.1546 16.7821 22.1546C19.1996 22.1546 21.165 20.2722 21.165 17.9562C21.165 15.6402 19.1996 13.7578 16.7821 13.7578ZM18.037 16.0646L16.2746 17.827L15.5272 17.0796C15.2227 16.7751 14.6875 16.8305 14.3277 17.1903C13.9678 17.5502 13.9216 18.0854 14.2169 18.3899L15.5918 19.7647C15.7579 19.9308 15.9701 20.0138 16.1915 20.0323C16.4407 20.0784 16.6806 20.0323 16.8467 19.857L19.338 17.3656C19.6425 17.0611 19.5872 16.526 19.2273 16.1661C18.8674 15.8062 18.3323 15.7601 18.037 16.0646Z"
      fill="#535353"
    />
  </SvgIconStyled>
);

export default React.memo(FileCheck);
