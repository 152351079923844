import React from 'react';
import { Button, Dialog } from 'components/forms';
import { Container, Typography } from 'components/types';
import styled from 'styled-components';

const CancelButton = styled(Button)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    & span {
      color: var(--colorDefault);
    }
    &:hover {
      color: var(--colorDefault);
      background: transparent;
      text-decoration: underline;
    }
  }
`;

const DialogModal = ({ open, handleClose, onSubmit, title, message, buttonSubmit, buttonProps }) => {
  return (
    <Dialog open={open || false} setOpen={handleClose} maxWidth="sm" onlyOk closeButton variant="standard">
      <Container.Grid container direction="column" alignItems="center" justify="center" p={3}>
        <Container.Grid item>
          <Typography.h3 fontSize={24}>{title}</Typography.h3>
        </Container.Grid>

        <Container.Grid item alignItems="center" pb={2} pl={2} pr={2}>
          <Typography.p style={{ textAlign: 'center' }}>{message}</Typography.p>
        </Container.Grid>

        <Container.Grid item pb={1} pt={2}>
          <Button onClick={onSubmit} {...buttonProps}>
            {buttonSubmit}
          </Button>
        </Container.Grid>

        <CancelButton onClick={handleClose}>Cancel</CancelButton>
      </Container.Grid>
    </Dialog>
  );
};

export default DialogModal;
