import React from 'react';
import { useCardPageContext } from '../context';
import {
  CONTENT_ADMIN_PICKLIST_COUNTRIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES,
  CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED,
  CONTENT_ADMIN_SCENARIO
} from '../constants';
import { SelectFieldStyled } from '../style';
import { RHFAutoComplete as AutoComplete, RHFSelectField as Select } from 'components';

const useContentAdminPageFilter = () => {
  const { pagePath, pickLists, dispatch } = useCardPageContext();

  const filterAutocompleteData = ({ name, ...props }) => ({
    component: AutoComplete,
    props: {
      ...props,
      name,
      item: true,
      multiple: true,
      onChange: value => {
        dispatch.changeFilter(
          name,
          value.map(({ value }) => value)
        );
      },
      getValue: (options, value) => {
        return options.filter(({ value: optionValue }) => value?.find(value => value === optionValue));
      }
    }
  });

  const getPageFilterItems = page => {
    switch (page) {
      case CONTENT_ADMIN_SCENARIO:
        return [
          {
            ...filterAutocompleteData({
              label: 'Difficulty',
              name: 'difficulty_levels',
              options: pickLists[CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED] || [],
              grid: {
                md: 2
              }
            })
          },
          {
            ...filterAutocompleteData({
              label: 'Country',
              name: 'countries',
              options: pickLists[CONTENT_ADMIN_PICKLIST_COUNTRIES] || [],
              grid: {
                md: 3
              }
            })
          },
          {
            component: Select,
            props: {
              item: true,
              label: 'HIPAA',
              name: 'is_hipaa',
              onChange: e => {
                const value = e.target.value;
                const correctValue = value === 'none' ? null : value;
                dispatch.changeFilter('is_hipaa', correctValue);
              },
              grid: {
                md: 2
              },
              choices: [
                {
                  label: 'All',
                  value: 'none'
                },
                {
                  label: 'Yes',
                  value: 1
                },
                {
                  label: 'No',
                  value: 0
                }
              ]
            }
          }
        ];
      default:
        return [];
    }
  };

  return {
    filterItems: getPageFilterItems(pagePath)
  };
};

export default useContentAdminPageFilter;
