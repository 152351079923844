// @flow
import { useSelector } from 'react-redux';
import { hasKey } from 'helpers';

type useAccessProps = {
  type?: 'app' | 'apps' | 'tab' | 'tabs' | 'feature',
  app?: 'clients' | 'myCompany' | 'myDashboard' | 'contentadmin' | 'reports' | 'getStarted' | 'newsfeed' | 'partnerProfile' | 'phishing' | 'user' | string,
  tab?: string,
  feature?: string,
  merger?: Object
};

function useAccess({ type = 'app', app, tab, feature, merger = {} }: useAccessProps): Object {
  const access = useSelector(state => state?.bsn?.user?.access);

  if (!access) return false;

  const { apps, features } = access;

  switch (type) {
    case 'app': {
      if (app) return hasKey(apps, app);
      return apps;
    }

    case 'tab': {
      return tab && apps[tab];
    }

    case 'tabs': {
      const newAccess = app ? apps[app] : {};
      return { ...newAccess, ...merger };
    }

    case 'feature': {
      return feature ? features[feature] : features;
    }

    default: {
      return false;
    }
  }
}

export default useAccess;
