// @flow

const microTrainingUsers = {
  paramKey: 'microTrainingId',
  title: 'Company MicroTrainings',
  component: 'company/CompanyMicrotrainingsModal',
  buttons: {
    cancel: {
      label: 'Cancel'
    }
  },
  initialState: null
};

export default microTrainingUsers;
