// @flow
import React, { type ComponentType } from 'react';
import styled, { css } from 'styled-components';
import {
  Button as MuiButton,
  TextField as MuiTextField,
  InputBase as MuiInputBase,
  DialogContentText as MuiDialogContentText,
  Switch as MuiSwitch,
  FormControl as MuiFormControl
} from '@material-ui/core';
import { TimePicker as MuiTimePicker, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { Search } from 'components/icons';

export const CardContentInner: ComponentType<*> = styled.div`
  flex: 1 1 auto;
  overflow: hidden;

  button {
    width: 100%;
  }
`;

export const TextFieldStyled: ComponentType<*> = styled(MuiTextField)`
  label {
    font-size: calc(var(--fontSize) * 1.1);
    line-height: calc(var(--fontSize) * 1.5);
  }
  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }
  &.variant-outlined {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border-radius: 0;
      -webkit-text-fill-color: var(--colorDarkGrey);
      box-shadow: 0 0 0px 1000px var(--backgroundDefault) inset;
    }
    & .Mui-disabled {
      cursor: not-allowed;
    }
  }

  &.variant-standard {
    margin: 8px 15px 8px 0;
    background-color: var(--backgroundDefault);
    font-size: var(--fontSize);
    border: none;
    height: 35px;
    width: 100%;
    max-width: 250px;
    border-radius: 4px;
  }
  &.variant-standard > div {
    padding: 4px 0 0 14px;
    color: var(--colorDefalut);
    &:before {
      border: none;
    }
    &:after {
      border: none;
    }
    &:hover::before {
      border: none;
    }
  }
`;

export const Link: ComponentType<*> = styled(MuiButton)`
  text-transform: capitalize;
  font-size: var(--fontSize);
  margin: 10px 0;
  display: block;
`;

export const DatePicker: ComponentType<*> = styled(MuiDatePicker)`
  margin: 8px 0;
  font-size: var(--fontSize);
  label {
    color: var(--colorGreyLight2);
  }
`;

export const TimePicker: ComponentType<*> = styled(MuiTimePicker)`
  margin: 8px 0;
  font-size: var(--fontSize);
  label {
    color: var(--colorGreyLight2);
  }
`;

export const InputBase: ComponentType<*> = styled(MuiInputBase)`
  && {
    background-color: var(--backgroundDefault);
    && {
      color: var(--colorDefault);
    }
    font-size: var(--fontSize);
    margin: 8px 0;
    border: none;
    height: 35px;
    width: 220px;
    border-radius: 4px;
    padding-left: 14px;
    margin-right: 27px;
  }
`;

export const SearchIcon: ComponentType<*> = styled(Search)`
  && {
    height: 17px;
    width: 17px;
    color: var(--iconDefaultcolor);
  }
`;
export const ButtonCancel: ComponentType<*> = styled(MuiButton)`
  && {
    text-transform: capitalize;
    color: var(--colorDefault);
    background: transparent;
    text-decoration: underline;
    box-shadow: none;
    margin-top: 12px;
    font-size: var(--fontSize);
    &:hover {
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }
  }
`;

export const ButtonSubmit: ComponentType<*> = styled(MuiButton)`
  && {
    text-transform: initial;
    color: var(--colorCommonWhite);
    background: var(--colorSystemDanger);
    font-size: var(--fontSize);
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 12px;
    &:hover {
      background: var(--colorSystemDanger);
    }
    &:disabled {
      opacity: 0.2;
      background: var(--colorSystemDanger);
      color: var(--colorCommonWhite);
    }
  }
`;

export const DialogContentText: ComponentType<*> = styled(MuiDialogContentText)`
  && {
    justify-content: center;
    font-size: var(--fontSize);
  }
`;

const spacing = 12;
export const SwitchStyle: ComponentType<*> = styled(({ color, ...props }) => (
  <MuiSwitch
    {...props}
    classes={{
      root: `switchRoot`,
      switchBase: `switchBase`,
      thumb: `swichThumb`,
      checked: `switchChecked`,
      track: `switchTrack`,
      colorSecondary: `switchColorSecondary`,
      edgeEnd: `switchEndStyle`
    }}
  />
))`
  &.switchRoot {
    width: calc(45px + ${spacing * 2}px);
    height: calc(25px + ${spacing * 2}px);
    && + span {
      font-size: var(--fontSize);
    }
  }
  && .switchColorSecondary.switchChecked {
    transform: translateX(${spacing + 6}px);
  }

  && .switchColorSecondary.switchChecked + span.switchTrack {
    opacity: 1;
    background-color: var(--colorSystemSuccess) !important;
    border-color: var(--colorSystemSuccess);
  }

  && .switchTrack {
    border-radius: 50px;
    height: 25px;
    width: 100%;
    opacity: 1;
    background-color: var(--backgroundSwitch) !important;
  }

  && .switchBase {
    color: var(--colorCommonWhite);
    padding: 16px 0;
    left: calc(12px + 5px);
  }

  && .switchBase:hover {
    background-color: transparent;
  }

  && .swichThumb {
    width: ${spacing + 5}px;
    height: ${spacing + 5}px;
    box-shadow: none;
  }
`;

export const FormControl = styled(({ fullWidth, offset, maxWidth, customBg, ...props }) => (
  <MuiFormControl {...props} />
))`
  &.FormVariant-outlined {
    ${({ fullWidth }) => fullWidth && `min-width: 100%`};
    ${({ offset }) => offset && `min-width: calc(100% - ${offset}%)`};
    ${({ mt }) => mt && `margin-top: ${mt * 8}px`};
    ${({ mb }) => mb && `margin-bottom: ${mb * 8}px`};
    ${({ mr }) => mr && `margin-right: ${mr * 8}px`};
    ${({ ml }) => ml && `margin-left: ${ml * 8}px`};
  }

  &.FormVariant-standard {
    background-color: var(--backgroundDefault);
    color: var(--colorDefault);
    margin-top: 8px;
    margin-bottom: 8px;
    ${({ mr }) => mr && `margin-right: ${mr * 8}px`};
    ${({ ml }) => ml && `margin-left: ${ml * 8}px`};
    border: none;
    height: 35px;
    width: 100%;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '220px')};
    border-radius: 4px;
  }
  &.FormVariant-standard svg {
    top: calc(50% - 4px);
    right: 4px;
  }
  &.FormVariant-standard > div {
    padding: 5px 0 0 10px;
    color: var(--colorDefault);
    background-color: transparent;
    & > div {
      font-size: var(--fontSize);
    }
    &:before {
      border: none;
      height: 35px;
    }
    &:after {
      border: none;
    }
    &:hover::before {
      border: none;
    }
  }

  & > div.Mui-disabled > div {
    opacity: 0.6;
    cursor: not-allowed;
  }

  [class*='MuiSelect-root'],
  [class*='MuiSelect-root']:focus {
    ${({ customBg }) => customBg && `background: ${customBg}`};
  }
`;

export const CustomLabelStyled = styled.span`
  position: absolute;
  z-index: 1;
  background: var(--backgroundPaper);
  padding: 0 5px;
  font-size: 13px;

  ${({ positionObj: { left, right, bottom, top } }) => {
    return css`
      ${left &&
        css`
          left: 10px;
        `};
      ${right &&
        css`
          right: 10px;
        `};
      ${bottom &&
        css`
          bottom: 0;
        `};
      ${top &&
        css`
          top: 0;
        `};
    `;
  }}
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;
