import React, { useCallback, useEffect, useState } from 'react';
import * as usersAPI from '../../../../helpers/apis/services/usersAPI';
import ReferralCodeModal from './ReferralCodeModal';
import { Badge } from '../../../icons';
import { ReferralCodeProvider } from './ReferralCodeContext';
import { useReferralCode } from './useReferralCode';
import { ReferralCodeButton } from './style';

const ReferralCode = () => {
  const [showModal, setShowModal] = useState(false);

  const { getReferralCodeSubmissionMsg, record, dispatch } = useReferralCode();

  const getPromotionClaimVisibility = useCallback(async () => {
    try {
      await usersAPI.getPromotionClaimVisibility()
      dispatch.showReferralCodeButton(true)
    } catch (e) {
      dispatch.showReferralCodeButton(false)
    }
  }, [dispatch.showReferralCodeButton])

  useEffect(() => {
    getPromotionClaimVisibility()
  }, [getPromotionClaimVisibility])

  useEffect(() => {
    if (!showModal) {
      setTimeout(() => {
        dispatch.clearState()
      }, 300)
    }
  }, [showModal])

  const {isNeedToShow} = record

  return (
    <ReferralCodeProvider
      value={{
        getReferralCodeSubmissionMsg,
        record,
        dispatch,
      }}
    >
      <ReferralCodeModal
        open={showModal}
        onClose={() => setShowModal(false)}
      />
      {
        isNeedToShow && (
          <ReferralCodeButton
            backgroundColor="colorBaseBase"
            backgroundColorHover="colorBaseDark1"
            fullwidth
            onClick={() => setShowModal(true)}
          >
            <Badge/>
            Referral code
          </ReferralCodeButton>
        )
      }
    </ReferralCodeProvider>
  )
}

export default ReferralCode
