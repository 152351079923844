import React from 'react';
import { CardPageLayoutGrid, TemplatePreviewLogo, TemplatePreviewLogoContainer } from '../../style';
import { CardTitleAndText } from '../shared';
import { format } from 'date-fns';

const EmailTemplateCard = props => {
  const { name, modified, logo_preview_url, setDialogDataAndOpen, preview_html } = props;

  return (
    <>
      <CardPageLayoutGrid>
        <CardPageLayoutGrid xs={12}>
          <CardTitleAndText title="Name" text={name} />
        </CardPageLayoutGrid>

        <CardPageLayoutGrid xs={12} justifyContent={'space-between'} alignItems={'center'}>
          <CardTitleAndText title="Modified Date" text={format(new Date(modified), 'MMMM do yyyy, h:mm:ss aaa')} />
          {logo_preview_url && (
            <TemplatePreviewLogoContainer>
              <TemplatePreviewLogo
                src={logo_preview_url}
                alt={'template-logo'}
                onClick={() => setDialogDataAndOpen({ preview_html })}
              />
            </TemplatePreviewLogoContainer>
          )}
        </CardPageLayoutGrid>
      </CardPageLayoutGrid>
    </>
  );
};

export default EmailTemplateCard;
