import React from 'react';
import LabelAndInfo from '../../../../components/types/LabelAndInfo';
import styled from 'styled-components';

const IpAddressLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

const phishingSummary = {
  defaultSortValue: {
    sortname: 'recipient_name',
    order: 'asc'
  },
  page: 0,
  perPage: 25,
  selecting: true,
  sort: [
    'difficulty',
    'email_sent',
    'last_action',
    'recipient_email',
    'recipient_name',
    'role',
    'scenario',
    'status',
    'ip_address'
  ],
  head: [
    {
      id: 'recipient_name',
      label: 'Recipient Name',
      align: 'left'
    },
    {
      id: 'recipient_email',
      label: 'Recipient Email',
      align: 'left'
    },
    {
      id: 'role',
      label: 'Role',
      align: 'left'
    },
    {
      id: 'scenario',
      label: 'Scenario',
      align: 'left'
    },
    {
      id: 'difficulty',
      label: 'Difficulty',
      align: 'left'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
    {
      id: 'ip_address',
      label: (
        <LabelAndInfo
          weight={300}
          label={<IpAddressLabel>IP Address</IpAddressLabel>}
          tooltip="If the link in the email was clicked or opened, this column will display the IP Address. Hover over to see the type of IP Address."
        />
      ),
      align: 'left'
    },
    {
      id: 'email_sent',
      label: 'Email Scheduled/Sent',
      align: 'left'
    },
    {
      id: 'last_action',
      label: 'Last action',
      align: 'left'
    }
  ]
};

export default phishingSummary;
