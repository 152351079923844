// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerResource = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 27.3 20.1" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h27.3v20H1z" />
    <g>
      <path d="M26.9,14.8c-0.5-0.8-1.5-1.2-3.4-1.9c-1.7-0.6-1.9-1.1-1.9-1.9c0-0.1,0-0.2,0.1-0.3c0.3-0.4,0.6-0.9,0.6-1.4   c0.4-0.2,0.6-0.6,0.7-1.4c0-0.3,0-0.6-0.2-0.8c0-0.3,0.1-0.7,0.1-1.1c0-0.8-0.3-1.6-0.9-2.2c-0.7-0.6-1.7-1-2.6-0.9   c-1,0-1.9,0.3-2.6,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.4,0.1,0.8,0.1,1.1c-0.1,0.3-0.2,0.5-0.2,0.8c0.1,0.7,0.2,1.1,0.7,1.4   c0.1,0.5,0.3,1,0.6,1.4c0.1,0.1,0.1,0.1,0.1,0.2c0,0.5,0,0.5-0.5,0.9c-0.3,0.1-0.4,0.5-0.2,0.7c0,0,0,0.1,0.1,0.1   c0.2,0.3,0.6,0.3,0.9,0.2c0.9-0.6,1.1-0.9,1.1-1.9c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.3-0.6-0.6-0.7   c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.4-0.1-0.8-0.2-1.2c0-0.5,0.2-1,0.5-1.3   C18.7,4,20,4,21,4.7c0.4,0.3,0.6,0.8,0.5,1.3c0,0.4-0.1,0.8-0.2,1.2c0,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1c0,0.7-0.1,0.5-0.1,0.5   C21.2,8.5,21,8.8,21,9.1c0,0.3-0.2,0.7-0.4,0.9c-0.2,0.3-0.4,0.6-0.4,1c0,1.6,1,2.3,2.7,3c1.6,0.6,2.5,1,2.8,1.4   c0.2,1.2,0.2,2.4,0.2,3.6h-4.9c-0.3,0-0.6,0.2-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h5.6c0.2,0,0.4-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4   C27.2,15.4,27,14.9,26.9,14.8z" />
      <path
        className="st0"
        d="M14,13.4c-2.3-0.9-2.7-1.5-2.7-2.7c0-0.2,0.1-0.4,0.2-0.5c0.5-0.5,0.7-1.2,0.8-1.9c0.5-0.2,0.8-0.6,0.9-1.8   c0-0.4-0.1-0.7-0.3-1c0.1-0.5,0.2-1,0.2-1.5C13.1,3,12.7,2,12,1.2C11.1,0.4,9.9-0.1,8.6,0C7.4-0.1,6.2,0.4,5.3,1.2   C4.5,2,4.1,3,4.2,4.1c0,0.5,0.1,1,0.2,1.5C4.2,5.9,4.1,6.2,4.1,6.6C4.2,7.7,4.5,8.1,5,8.3C5.1,9,5.3,9.7,5.8,10.2   C6,10.4,6,10.4,6,10.7c0,1.1-1.1,1.8-3.7,2.8C1.5,13.7,0,14.3,0,15.6v3.9c0,0.3,0.3,0.6,0.6,0.6h16c0.3,0,0.6-0.2,0.7-0.6v-2.9   C17.3,15.3,16.3,14.3,14,13.4z M16,18.9H1.4v-3.3c0-0.5,0.7-0.8,1.5-1.1L3,14.5c2-0.8,4.4-1.7,4.4-3.8c0-0.4-0.2-0.9-0.5-1.2   C6.6,9.1,6.4,8.7,6.3,8.2C6.2,7.8,6,7.4,5.6,7.3c0,0-0.1-0.1-0.2-0.7c0-0.2,0.1-0.3,0-0.3c0.2-0.1,0.3-0.4,0.3-0.6   c0,0-0.2-1-0.2-1.7c0-0.7,0.2-1.4,0.8-1.9c0.6-0.6,1.5-0.9,2.4-0.8C9.5,1.2,10.3,1.4,11,2c0.5,0.5,0.8,1.2,0.8,1.9   c-0.1,0.7-0.2,1.7-0.2,1.7c-0.1,0.2,0.1,0.5,0.3,0.6c0.1,0.1,0.1,0.2,0.1,0.3c0,0.6-0.1,0.7-0.2,0.8c-0.3,0.2-0.6,0.5-0.6,0.9   c-0.1,0.5-0.3,0.9-0.6,1.3c-0.3,0.3-0.5,0.8-0.5,1.2c0,2.1,1.2,2.9,3.5,3.8c2.6,1,2.6,1.8,2.6,2.1L16,18.9z"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerResource;
