const trainingAnnual = {
  defaultSortValue: {
    sortname: 'date',
    order: 'desc',
  },
  selecting: true,
  selectAll: true,
  sort: ['name', 'score', 'date', 'certificate'],
  rowClick: false,
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'score',
      label: 'Score',
      align: 'left',
    },
    {
      id: 'date',
      label: 'Date Completed',
      align: 'left',
    },
    {
      id: "certificate",
      label: 'View',
      align: 'left',
    },
  ],
};

export default trainingAnnual;