import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { ButtonCancel } from 'components';
import {
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as InsertDriveFileIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import { OrganisationListItem, GreenButton, BlueButton } from '../../style';
import {
  ArrowRightIcon,
  Button,
  Grid,
  Paper,
  SaveIcon,
  TextField,
  Typography,
} from '@trustsecurenow/components-library'

const AdditionalInformation = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState({ ...newState });
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  const onConfirmChanges = () => {
    onSave('close', null, { confirmation_needed: 0 })
  };

  return (
    <>
      {/* Info */}
      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(value => !value)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelNetworkbh-content"
          id="panelNetworkbh-header"
        >
          <Typography variant='p' component='p' sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }} fontSize={16} lineHeight={1.5} my={2}>
            <ErrorOutlineIcon color="secondary" style={{ marginRight: 4 }} />
            Please provide additional information that will better help us understand your network/computer environment
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth radius={2} pb={2}>
                <Grid container>
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List>
                      <OrganisationListItem alignItems="flex-start">
                        <ListItemIcon>
                          <InsertDriveFileIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            isHSN
                              ? 'List any additional system that contain patient information or electronic protected health information (ePHI);'
                              : 'List any additional system that contain PII;'
                          }
                          secondary={
                            <List>
                              <OrganisationListItem>
                                <ListItemText primary="List any known threats or issues that you have experienced in the past (floods, crimes, etc.);" />
                              </OrganisationListItem>
                              <OrganisationListItem>
                                <ListItemText primary="Use this section to provide anything that you feel will help us with the Risk Assessment." />
                              </OrganisationListItem>
                            </List>
                          }
                        />
                      </OrganisationListItem>
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth radius={2} pb={2}>
            <Grid container>
              {/* Additional Information */}
              <Grid item p={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  value={state.additional_info}
                  name="additional_info"
                  placeholder="Additional Information"
                  fullWidth
                  rows={4}
                  multiline
                  onChange={handleChangeState}
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Action buttons */}
          <Grid item mt={3} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={4} display='flex' justifyContent="flex-end">
              <Grid m={2} display='flex' justifyContent="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
                <ButtonCancel variant="text" onClick={e => handleClose()}>
                  Exit
                </ButtonCancel>
                <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
                  Save
                </Button>
                {state?.confirmation_needed ? (
                  <Button
                    color="success"
                    startIcon={<ArrowRightIcon />}
                    variant="contained"
                    disableElevation
                    sx={{ marginLeft: '30px' }}
                    onClick={onConfirmChanges}
                  >
                    Confirm Changes
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AdditionalInformation.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default AdditionalInformation;
