// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerPII = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 22.515 20.264" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h22.515v20.264H0z" />
    <g transform="translate(-2 -3)">
      <path
        d="M7.585,6.779A.845.845,0,1,0,8.779,5.585L7.722,4.528A.845.845,0,1,0,6.528,5.722Z"
        transform="translate(0.538 0.161)"
      />
      <path
        d="M7.585,17.528,6.528,18.585a.845.845,0,1,0,1.194,1.194l1.057-1.057a.845.845,0,0,0-1.194-1.194Z"
        transform="translate(0.538 1.796)"
      />
      <path
        d="M6.5,9.689h4.784a.844.844,0,1,0,0-1.689H6.5a4.5,4.5,0,0,0-4.482,4.945A4.633,4.633,0,0,0,6.7,17.006h4.591a.844.844,0,1,0,0-1.689H6.664A2.925,2.925,0,0,1,3.7,12.793,2.815,2.815,0,0,1,6.5,9.689Z"
        transform="translate(0 0.629)"
      />
      <path
        d="M16.722,6.779l1.057-1.057a.845.845,0,1,0-1.194-1.194L15.528,5.585a.845.845,0,1,0,1.194,1.194Z"
        transform="translate(1.67 0.161)"
      />
      <path
        d="M18.629,8H13.844a.844.844,0,0,0,0,1.689h4.784a2.815,2.815,0,0,1,2.8,3.1,2.925,2.925,0,0,1-2.961,2.524H13.844a.844.844,0,1,0,0,1.689h4.591a4.633,4.633,0,0,0,4.675-4.061A4.5,4.5,0,0,0,18.629,8Z"
        transform="translate(1.383 0.629)"
      />
      <path
        d="M12.094,18a.845.845,0,0,0-.844.844v1.689a.844.844,0,0,0,1.689,0V18.844A.845.845,0,0,0,12.094,18Z"
        transform="translate(1.163 1.886)"
      />
      <path
        d="M16.722,17.528a.845.845,0,0,0-1.194,1.194l1.057,1.057a.845.845,0,1,0,1.194-1.194Z"
        transform="translate(1.67 1.796)"
      />
      <path
        d="M12.094,3a.845.845,0,0,0-.844.844V5.533a.844.844,0,1,0,1.689,0V3.844A.845.845,0,0,0,12.094,3Z"
        transform="translate(1.163 0)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerPII;
