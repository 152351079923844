import React from 'react';
import { DatePicker } from './ComponentTypes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Controller from './Controller';
import { useFormContext } from 'react-hook-form';
import { getCurrentDateWithTimezone } from '../../helpers';

const DateField = ({ name, helperText, format, nullValue, required, rules, value, onChange, ...props }) => {
  const onDateChange = (e, onChange) => {
    let dateString = getCurrentDateWithTimezone(e);
    onChange(dateString);
  };

  const formContextData = useFormContext();

  const DatePickerWrapper = wrapperProps => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...props}
          label={props.label && `${props.label} ${required && '*'}`}
          name={name}
          autoOk
          inputVariant="outlined"
          margin="none"
          InputLabelProps={{ classes: { focused: 'field-focused' } }}
          format={format === 'year' ? 'yyyy' : 'yyyy-MM-dd'}
          placeholder="yyyy-MM-dd"
          views={format === 'year' ? ['year'] : ['date']}
          {...wrapperProps}
        />
      </MuiPickersUtilsProvider>
    );
  };

  if (!formContextData) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePickerWrapper
          value={!value && nullValue ? null : value}
          onChange={e => onDateChange(e, onChange)}
          error={props.error}
          helperText={props.helperText}
        />
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <Controller
      name={name}
      required={required}
      rules={rules}
      render={({ field }, error) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePickerWrapper
            {...field}
            inputRef={field.ref}
            value={
              !field.value && nullValue
                ? null
                : new Date(new Date(field.value).getTime() + new Date(field.value).getTimezoneOffset() * 60000)
            }
            onChange={e => onDateChange(e, field.onChange)}
            error={error || props.error}
            helperText={error?.message || props.helperText}
          />
        </MuiPickersUtilsProvider>
      )}
    />
  );
};

export default DateField;
