import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Save2 = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_3109_17368)">
      <path d="M22.5323 8.07694L17.76 1.45848C17.4185 1.11694 16.9477 0.923096 16.4585 0.923096H2.76925C1.75386 0.923096 0.923096 1.75386 0.923096 2.76925V21.2308C0.923096 22.2462 1.75386 23.0769 2.76925 23.0769H21.2308C22.2462 23.0769 23.0769 22.2462 23.0769 21.2308V9.38771C23.0769 8.89848 22.8831 8.42771 22.5323 8.07694ZM6.00925 21.2216V18.9416H17.9816V21.2216H6.00925ZM21.2308 21.2308H19.8277V18.0185C19.8277 17.5108 19.4123 17.0954 18.9046 17.0954H5.08617C4.57848 17.0954 4.1631 17.5108 4.1631 18.0185V21.2308H2.76925V2.76925L16.3569 2.8431L21.0369 9.16617L21.12 9.28617L21.2308 9.38771V21.2308Z" />
      <path d="M11.9539 6.96924C9.79389 6.96924 8.04004 8.72308 8.04004 10.8831C8.04004 13.0431 9.79389 14.7969 11.9539 14.7969C14.1139 14.7969 15.8677 13.0431 15.8677 10.8831C15.8677 8.72308 14.1139 6.96924 11.9539 6.96924ZM11.9539 12.9508C10.8093 12.9508 9.88619 12.0185 9.88619 10.8831C9.88619 9.7477 10.8185 8.81539 11.9539 8.81539C13.0985 8.81539 14.0216 9.7477 14.0216 10.8831C14.0216 12.0185 13.0893 12.9508 11.9539 12.9508Z" />
    </g>
    <defs>
      <clipPath id="clip0_3109_17368">
        <rect width="22.1538" height="22.1538" fill="white" transform="translate(0.923096 0.923096)" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default React.memo(Save2);
