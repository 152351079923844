// @flow
import { useHistory } from 'react-router-dom';
import { getId } from 'helpers';

type useLocationReturnType = {
  app: string,
  tab: string | false,
  item: string | false
};

function useLocation(itemType?: 'partnerId' | 'userId' | 'clientId'): useLocationReturnType {
  const {
    location: { pathname }
  } = useHistory();
  const [, app, tab, itemPath] = pathname.split('/');
  const item = itemPath || (itemType && getId(itemType)) || false;

  return { app, tab: tab || false, item };
}

export default useLocation;
