import React from 'react';
import { makeStyles, Paper, Box } from '@material-ui/core';
import { Typography, TextField } from 'components';
import HeaderActions from './HeaderActions';
import { ChipStyled } from '../../../style';
import { getRecommendationChipColorOld } from '../../../utils';
import { DatePickerField, CroppedHtmlText } from '../../../components';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%'
  },
  item: {
    width: '48%',
    marginBottom: 30
  },
  cardHeader: {
    padding: '20px 10px 20px 20px',
    borderBottom: '1px solid var(--borderDefault)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardContent: {
    padding: '16px 20px 20px'
  },
  subtitle: {
    fontWeight: 500
  }
}));

const Recommendation = ({ item, setCurrentRecommendation, setOpenAddEditModal, setOpenDeleteModal, isHSN }) => {
  const classes = useStyles();
  const { section, subsection, priority, finding, recommendation, due_date, notes } = item;

  return (
    <div className={classes.item}>
      <Paper elevation={0} className={classes.paper}>
        {/* Card Header */}
        <div className={classes.cardHeader}>
          <Box mr={3} display="flex" alignItems="center">
            <Typography.h4 fontSize={18} mt="0px" mb="0px" mr={1}>
              Section: {section}
            </Typography.h4>

            {isHSN && <ChipStyled label={priority} variant="outlined" color={getRecommendationChipColorOld(priority)} />}
          </Box>
          <HeaderActions
            recommendation={item}
            setCurrentRecommendation={setCurrentRecommendation}
            setOpenAddEditModal={setOpenAddEditModal}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </div>

        {/* Card Content */}
        <div className={classes.cardContent}>
          <Typography.h5 fontSize={16} mt="0px" mb={2}>
            Subsection: {subsection}
          </Typography.h5>

          <Typography.h6 fontSize={14} mt={2} mb={1} className={classes.subtitle}>
            Finding
          </Typography.h6>
          <CroppedHtmlText text={finding} limit={150} />

          <Typography.h6 fontSize={14} mt={2} mb={1} className={classes.subtitle}>
            Recommendation
          </Typography.h6>
          <CroppedHtmlText text={recommendation} limit={150} />

          {/* Due Date */}
          <Box mt={2} mb={0.5}>
            <DatePickerField
              id="due_date"
              label="Due date"
              value={due_date}
              style={{ width: '160px' }}
              KeyboardButtonProps={{ disabled: true }}
              disabled
              TextFieldComponent={params => (
                <TextField
                  {...params}
                  size="small"
                  variant={false}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true
                  }}
                />
              )}
            />
          </Box>

          {/* Notes */}
          <TextField
            value={notes || ''}
            name="notes"
            label="Notes"
            multiline
            rows={3}
            maxRows={3}
            fullWidth
            InputProps={{ readOnly: true }}
            disabled
          />
        </div>
      </Paper>
    </div>
  );
};

export default Recommendation;
