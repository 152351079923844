// @flow

import React, { type ComponentType, useCallback } from 'react';
import { connect } from 'react-redux';
import type { Element } from 'react';
import { MenuItemLink as MuiMenuItemLink } from 'react-admin';
import { Badge, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { transitions } from 'conf';
import { useApp } from 'helpers';
import { useLocation } from 'hooks';
import { CustomTooltip } from 'components';
import { LazyIcon } from '../../icons';
import { layoutToolbarBottomData /* , layoutToolbarTopData */ } from '../helper';

export const ToolbarMenu: ComponentType<*> = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background-color: var(--colorBaseDark2);
  color: var(--colorCommonWhite);
  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};

  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }

`;

export const ToolbarMenuContent: ComponentType<*> = styled.div`
  display: flex;
  height: 175px;
  flex-direction: column;
  justify-content: ${({ align }) => (align ? `flex-${align}` : `initial`)};

  svg {
    font-size: calc(var(--fontSize) * 1.2);
    color: var(--colorCommonWhite);
  }
`;

export const ToolbarMenuIconButton: ComponentType<*> = styled(IconButton)`
  && {
    padding: 10px 5px;
  }
`;

export const ToolbarMenuBadge: ComponentType<*> = styled(Badge)`
  span {
    background: var(--colorSystemDanger);
    right: 4px;
  }
`;

export const ToolbarMenuLink: ComponentType<*> = styled(MuiMenuItemLink)`
  padding: 0;
  &:hover {
    && {
      background-color: initial;
    }
  }
`;

const Toolbar = (): Element<*> => {
  const { app, item } = useLocation('clientId');
  const { dispatch } = useApp();

  const handleEmptyTabs = () => {
    dispatch.changeTab(app, null, null, item);
  };

  const actionClick = useCallback(
    link => {
      const [, appLink, tabLink] = link.split('/');

      dispatch.changeTab(appLink, tabLink, 'dashboard', null, false);
    },
    [dispatch]
  );

  return (
    <ToolbarMenu>
      <ToolbarMenuContent align="start">
        {/* {layoutToolbarTopData.map(({ color, size, icon, id, link, label }) => (
          <Tooltip title={label} placement="right">
            <ToolbarMenuIconButton id={id} color={color} size={size} onClick={e => topClick(e, id)} key={id}>
              <ToolbarMenuLink sidebarIsOpen to={link} primaryText={<LazyIcon component={icon} />} />
            </ToolbarMenuIconButton>
          </Tooltip>
        ))} */}
      </ToolbarMenuContent>
      <ToolbarMenuContent />
      <ToolbarMenuContent align="end">
        {layoutToolbarBottomData.map(({ color, size, icon, id, badge, link, label }) => (
          <CustomTooltip title={label} placement="right">
            <ToolbarMenuIconButton color={color} size={size} key={id}>
              <ToolbarMenuLink
                sidebarIsOpen
                to={link}
                primaryText={
                  badge ? (
                    <ToolbarMenuBadge color="secondary" variant="dot">
                      <LazyIcon component={icon} />
                    </ToolbarMenuBadge>
                  ) : (
                    <LazyIcon component={icon} />
                  )
                }
                onClick={() => (link === '/logout' ? handleEmptyTabs() : actionClick(link))}
                data-ga-button={label}
              />
            </ToolbarMenuIconButton>
          </CustomTooltip>
        ))}
      </ToolbarMenuContent>
    </ToolbarMenu>
  );
};

export default connect(() => ({}))(Toolbar);
