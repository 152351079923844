// @flow

const user = {
  title: 'Edit User',
  component: 'shared/users/ClientsUsersModalEditUser',
  requireRowId: true,
  // userIdrequired: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save',
      icon: 'Save2',
      style: {
        backgroundColor: '#0069FF'
      }
    },
    other: [
      // {
      //   type: 'resetPassword',
      //   label: 'Reset Password'
      // },
      // {
      //   type: 'resetMFAToken',
      //   label: 'Reset MFA Token'
      // }
    ]
  },
  initialState: null
};

export default user;
