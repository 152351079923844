import React from 'react';

const ShieldChecked = props => (
  <svg {...props} width="59" height="64" viewBox="0 0 59 64" fill="red" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.67 63.0107C26.8076 63.6785 28.0937 64 29.3798 64C30.6658 64 31.9519 63.6538 33.0895 63.0107C48.5223 54.1073 58.0935 37.5121 58.0935 19.7051C58.0935 17.0588 56.6838 14.5856 54.3837 13.2749L33.0895 0.983094C30.7895 -0.327698 27.97 -0.327698 25.67 0.983094L4.3758 13.2749C2.07573 14.6104 0.666016 17.0588 0.666016 19.7051C0.666016 37.5121 10.262 54.1073 25.67 63.0107ZM28.1432 5.2617C28.5141 5.03912 28.9593 4.94018 29.3798 4.94018C29.8002 4.94018 30.2454 5.06385 30.6164 5.2617L51.9105 17.5535C52.6772 17.9986 53.1471 18.8148 53.1471 19.7051C53.1471 35.7314 44.5157 50.6942 30.6164 58.7074C29.8497 59.1525 28.9099 59.1525 28.1432 58.7074C14.2438 50.6942 5.6124 35.7561 5.6124 19.7051C5.6124 18.8148 6.0823 17.9986 6.84899 17.5535L28.1432 5.2617ZM39.1733 22.5494C40.1379 21.5849 41.696 21.5849 42.6605 22.5494C43.6251 23.514 43.6251 25.0721 42.71 26.0613L25.3729 43.3984C24.8783 43.8683 24.26 44.1156 23.617 44.1156C23.2707 44.1156 22.9492 44.0167 22.6277 43.893C22.3309 43.7694 22.0341 43.5962 21.7868 43.3489L14.1941 35.7563C13.2296 34.7917 13.2296 33.2336 14.1941 32.269C15.1587 31.3045 16.7168 31.3045 17.6813 32.269L23.5675 38.1553L39.1733 22.5494Z"
      fill="#0CCE6B"
    />
  </svg>
);

export default ShieldChecked;
