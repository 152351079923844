import styled, { css } from 'styled-components';
import { Paper, TableCell, TableHead, Table as MuiTable, TableRow } from '@material-ui/core';
import { Container, LoadingStyled } from '../../types';
import { Arrow } from '../../icons';

export const TableContainer = styled(Container.Grid)``;

export const TableWrapper = styled(Paper)`
  width: 100%;
`;

export const TableToolbarWrapper = styled.div`
  padding: calc(var(--spacing) * 2);
  border: var(--borderSize) solid var(--borderBase);
  border-bottom: none;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
`;
export const Table = styled(MuiTable)`
  && {
    border-collapse: separate;
  }
  border-radius: var(--borderRadius);
  tr {
    opacity: ${({ isFetching }) => (isFetching ? 0.5 : 1)};
    pointer-events: ${({ isFetching }) => (isFetching ? 'none' : 'auto')};
  }
`;

export const MainTable = styled.div`
  overflow-x: auto;
`;

export const TableHeadStyled = styled(TableHead)`
  && {
    tr {
      th {
        border-top: var(--borderSize) solid var(--borderBase);
        border-bottom: var(--borderSize) solid var(--borderBase);
        &:first-child {
          border-top-left-radius: var(--borderRadius);
          border-bottom-left-radius: var(--borderRadius);
          border-left: var(--borderSize) solid var(--borderBase);
        }
        &:last-child {
          border-top-right-radius: var(--borderRadius);
          border-bottom-right-radius: var(--borderRadius);
          border-right: var(--borderSize) solid var(--borderBase);
        }
      }
    }
  }
`;

export const TableCellWrapper = styled(TableCell)`
  && {
    font-weight: 500;
    font-size: 1.4rem;
    padding-left: calc(var(--spacing) * 2);
    span {
      color: var(--colorDarkGrey);
      span > svg {
        font-size: 16px;
      }
    }
  }
`;

export const NoDataText = styled.h2`
  text-align: center;
  color: var(--colorDefault);
  padding: 30px;
`;

export const TableRowStyled = styled(TableRow)`
  && {
    td {
      cursor: ${({ highlightOnClick }) => (highlightOnClick ? 'pointer' : 'default')};
      ${({ isHighlighted }) =>
        isHighlighted &&
        css`
          color: var(--colorSystemInfo) !important;
          background-color: rgba(25, 148, 230, 0.2);
        `}
    }
  }
`;

export const TableCellStyled = styled(TableCell)`
  && {
    padding: calc(var(--spacing) * 2);
    color: var(--colorDefault) !important;
    border-bottom-color: var(--borderTableDefault);
    cursor: ${({ rowClick }) => (rowClick ? 'pointer' : 'default')};
    width: ${({ width }) => (width ? width : 'auto')};
    font-size: 14px;
    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        color: var(--colorSystemInfo) !important;
        background-color: rgba(25, 148, 230, 0.2);
      `}

    ${({ collapse }) =>
      collapse &&
      css`
        background-color: var(--backgroundDefault);
      `}
  }
`;

export const CollapseIcon = styled(Arrow)`
  transform: rotate(180deg);
  margin: 0 8px;
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      transform: rotate(0deg);
      color: var(--colorSystemInfo);
    `}
`;

export const CollapseLoading = styled(LoadingStyled)`
  padding-bottom: 25px;
  padding-top: 25px;
`;

export const TableContentWrapper = styled.div`
  overflow-x: auto;
`;

export const TableTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 20px 16px;
`;
