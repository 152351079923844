// @flow
import React, { memo, type Element, useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Container, Typography, Button } from 'components';
import { LazyIcon } from 'components/icons';
import { ExpansionPanelDetails as AccordionDetails, CircularProgress } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { AccordionSummary, Accordion } from './ComponentTypes';
import useHasInternet from '../../hooks/useHasInternet';
import { dataProvider, getMonthFromString } from 'helpers';
import { useId, useLocation } from 'hooks';
import styled from "styled-components"

type PartnerBillingReportTypes = {
  record: Object
};


const BillingReportButton = styled(Button)`
  && {
    width: 180px;
    padding: 11px 10px;
  }
`

const PartnerBillingReport = ({ record, partnerId }: PartnerBillingReportTypes): Element<*> => {
  const { app } = useLocation();
  const [open, setOpen] = useState(null);
  const userId = useId({ key: 'partnerId' });
  const item = partnerId || userId;
  const [loadingReport, setLoadingReport] = useState({
    pdf: false,
    xls: false
  });
  const notify = useNotify();
  const hasInternet = useHasInternet();

  useEffect(() => {
    if (record && record.data.length > 0) {
      setOpen(record.data[0].id);
    }
  }, []);

  const downloadBillingReport = ({statement_id, url}, type) => {
    hasInternet(() => {
        setLoadingReport(prevState => ({...prevState, [type]: true}));
        dataProvider
          .getOne(app, `billingStatement/${item}`, { type, statement_id })
          .then(response => window.open(response.data))   
          .finally(() => setLoadingReport(prevState => ({...prevState, [type]: false})));
        return;
    })
  }

  return (
    <Container.Grid block spacing={24}>
      {record && record.data.length > 0 ? (
        <>
          <Container.Paper fullWidth mb={open === record.data[0].id && 1} pl={3}>
            <Typography.p color="blue" fontSize={14}>
              Changes can take up to 24 hours
            </Typography.p>
          </Container.Paper>
          {record.data.map((column, i) => {
            return (
              <Container.Grid key={column.id} item block direction="column" xs={12}>
                <Accordion expanded={open === column.id} onChange={e => setOpen(column.id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="access2-content"
                    id="access2-body"
                    classes={{
                      root: `accessAccordionSummary`,
                      selected: `Selected`
                    }}
                  >
                    <LazyIcon component="Calendar" color="grey" fontSize="default" mt={2.5} mb={2} mr={2} />
                    <Container.Grid item direction="row" sm={12} xs={12} xl={12} md={12} justify="flex">
                      <Container.Grid item sm={6} xs={6} xl={6} md={6} justify="flex-start">
                        <Typography.p>
                           {column.month} {column.closed_date.split("-")[2]},{column.year}
                          {i === 0 && <span style={{ color: `var(--colorSystemInfo)` }}> (Current)</span>}
                        </Typography.p>
                      </Container.Grid>
                      <Container.Grid item sm={6} xs={6} xl={6} md={6} justify="flex-end">
                        <Typography.p>$ {column.amount}</Typography.p>
                      </Container.Grid>
                    </Container.Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container.Grid item direction="column" sm={12} xs={12} xl={12} md={12} justify="flex-end">
                      <BillingReportButton
                        pr={3}
                        pl={3}
                        onClick={() => downloadBillingReport(column, 'pdf')}
                        disabled={loadingReport.pdf}
                      >
                        {!loadingReport.pdf ? (
                          <>
                            <LazyIcon size={0.9} color="var(--colorCommonWhite)" component="Download"/>
                            &nbsp;&nbsp;Download Statement
                          </>
                        ):(
                          <>
                            <CircularProgress style={{color: 'white'}} size={18} thickness={3} />
                            &nbsp; Loading Statement...
                          </>
                        )}
                      </BillingReportButton>

                      <BillingReportButton
                        mt={2}
                        pr={3}
                        pl={3}
                        onClick={() => downloadBillingReport(column, "xls")}
                        disabled={loadingReport.xls}
                      >
                        {!loadingReport.xls ? (
                          <>
                            <LazyIcon size={0.9} color="var(--colorCommonWhite)" component="Exel"/>
                            &nbsp;&nbsp;Download XLS file
                          </>
                        ):(
                          <>
                            <CircularProgress style={{color: 'white'}} size={18} thickness={3} />
                            &nbsp; Loading Statement...
                          </>
                        )}

                      </BillingReportButton>
                    </Container.Grid>
                  </AccordionDetails>
                </Accordion>
              </Container.Grid>
            );
          })}
        </>
      ) : (
        <h1>No reports for this period</h1>
      )}
    </Container.Grid>
  );
};

export default memo<PartnerBillingReportTypes>(PartnerBillingReport);
