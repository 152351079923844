import React from 'react';

const Pencil = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3161 2.35158L17.6507 0.678489C17.2035 0.246723 16.6253 0 16.0085 0C15.3839 0 14.8057 0.246723 14.3662 0.678489L4.37395 10.6785C4.22746 10.825 4.1041 10.9946 3.99616 11.1642C3.90364 11.3493 3.82651 11.542 3.77254 11.7348C3.76483 11.7502 3.74942 11.7656 3.74171 11.781L3.1403 12.8913L0.179652 18.3655C-0.144172 18.9591 0.0177515 19.4526 0.287605 19.7147C0.441807 19.8766 0.688517 20 0.996921 20C1.18967 20 1.40557 19.9537 1.63687 19.8227L7.18811 16.8157L8.22128 16.2606C8.25212 16.2452 8.28295 16.2298 8.30608 16.2066C8.50654 16.1527 8.69157 16.0678 8.8689 15.9599C9.03082 15.8674 9.17732 15.7517 9.3161 15.613L19.3084 5.62066C20.2105 4.71858 20.2105 3.25366 19.3161 2.35158ZM8.229 14.5258C8.09022 14.6646 7.79724 14.8111 7.79724 14.8111C7.697 14.8188 7.58905 14.8497 7.49653 14.9036L4.13494 16.7232L3.27908 15.8905L5.10642 12.5135C5.14497 12.4441 5.16808 12.3593 5.1835 12.2822C5.1835 12.2822 5.31457 11.9275 5.47649 11.7656L13.3871 3.85505L16.2167 6.53816L8.229 14.5258ZM18.2213 4.53354L17.3038 5.45104L14.4664 2.76793L15.4611 1.77332C15.6076 1.61912 15.808 1.54202 16.0085 1.54202C16.2012 1.54202 16.4017 1.61912 16.5482 1.77332L18.2213 3.4387C18.522 3.7394 18.522 4.22513 18.2213 4.53354Z"
      fill="#535353"
    />
  </svg>
);

export default Pencil;
