/* eslint-disable react/prop-types */
import { Chip } from '@trustsecurenow/components-library';
import React from 'react';

const getThreatsChipColor = text => {
  switch (text) {
    case 'High':
      return 'error';
    case 'Medium':
      return 'warning';
    case 'Low':
      return 'success';
    default:
      return 'primary';
  }
};

const ChipCell = props => <Chip label={props.value} variant="outlined" color={getThreatsChipColor(props.value)} />;

export default ChipCell;
