// @flow
import { Phishing as PhishingIcon } from 'components/icons';
import PhishingList from './PhishingList';

export default {
  name: 'phishing',
  list: PhishingList,
  icon: PhishingIcon,
  options: {
    label: 'Phishing'
  }
};
