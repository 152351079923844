// @flow

const otherPolicy = {
  title: 'Other Policy',
  paramKey: 'policyId',
  buttons: {
    cancel: {
      label: 'Cancel',
      clear: true
    },
    submit: null,
    other: [
      {
        icon: 'Download',
        label: 'Download',
        key: 'attachment_link',
        type: 'download'
      }
    ]
  },
  initialState: {
    name: '',
    attachment: null,
    created: null,
    modified: null,
    acknowledgement: null,
    description: ''
  }
};

export default otherPolicy;
