const taxExempt = {
  type: 'taxExempt',
  search: false,
  cells: [
    { id: 'state', label: 'State', align: 'left', renderBadge: true },
    { id: 'signed_date', label: 'Signed Date', align: 'left'},
    { id: 'expiration_date', label: 'Expiration Date', align: 'left' },
    { id: 'status', label: 'Status', align: 'left', renderStatusIcon: true },
    { id: "actions", label: "Actions", align: "left"}
  ]
};

export default taxExempt;
