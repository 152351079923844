import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import { ANNUAL } from 'components/pages/training/constants';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { isNA } from '../helpers';

const TrainingCell = ({ value, clientId }) => {
  if (isNA(value)) return <Typography variant="body2">N/A</Typography>;

  return (
    <Tooltip title="Click here to navigate to Training Reports" placement="top">
      <Link
        underline="hover"
        hoverColor="info.main"
        component={RouterLink}
        variant="body2"
        to={`/clients/trainingReports/${clientId}?redirectionTarget=${ANNUAL}`}
        onClick={e => e.stopPropagation()}
      >
        {value}%
      </Link>
    </Tooltip>
  );
};

TrainingCell.propTypes = {
  value: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};

export default TrainingCell;
