import React, { useMemo, useState } from 'react';
import { debounce } from 'helpers/debounce';
import LoadingStyled from 'components/types/Loading';
import { Box, Button as MuiButton, alpha } from '@material-ui/core';
import { Button, Container, Typography, Modal, CustomTooltip, ConfirmLeavePageModal } from 'components';
import { NewWelcomeMessage, Siren } from 'components/icons';
import { useSelector } from 'react-redux';
import  InfoIconLegacy  from 'components/icons/Info';
import SaveIcon from 'components/icons/Save2';
import EmailIcon from 'components/icons/Email2';
import InternetIcon from 'components/icons/Internet';
import ReloadIcon from 'components/icons/Reset';
import SelectField from 'components/newForms/SelectField';
import Switch from 'components/newForms/Switch';
import { ButtonCancel } from 'components/forms/ComponentTypes';
import styled from 'styled-components';
import TextField from 'components/newForms/TextField';
import useForm from 'hooks/useForm';
import clients from 'helpers/apis/clients';
import partner from 'helpers/apis/partner';
import { useNotify } from 'ra-core';
import { useHistory } from 'react-router-dom';
import { CloseIcon } from 'components/common/ComponentTypes';
import partnerChecklistApi from 'helpers/apis/partnerChecklist';
import isEqual from 'lodash/isEqual';
import Parser from 'html-react-parser';
import {
  RichTextEditor,
  getRichTextEdiorConfig,
  useTheme,
  enqueueAlertSnackbar,
  Tooltip,
  InfoIcon,
  Stack
} from '@trustsecurenow/components-library';
import { updateMutable, updateNestedMutable } from 'utils/update';
import { isStrContainsEmoji } from 'helpers/utils';
import { ContainerTitle } from './ComponentTypes';
import { InfoButtons, SaveButton } from './shared';
import { USER_ROLES, useApp } from '../../helpers';
import LeavePageDialog from 'apps/shared/users/modals/LeavePageDialog';

const defaultConfig = getRichTextEdiorConfig();
const disabledRichTextEditorButtons = ['video', 'eraser', 'print', 'copyformat', 'hr', 'fullsize', 'font', 'table'];

export const StyledButton = styled(Button)`
  && {
    background-color: var(--colorSystemInfo);
    border-color: var(--colorSystemInfo);
    color: var(--buttonLight) !important;
    text-transform: none;
    font-size: 14px;
    min-width: 140px;
    height: 40px;
    opacity: 1 !important;
    &:hover {
      background-color: var(--colorSystemInfoHover);
    }
  }
`;

const OutlineBtn = styled(props => <MuiButton {...props} />)`
  && {
    border-color: var(--colorSystemInfo);
    color: var(--colorSystemInfo);
    &:hover: {
      background-color: var(--colorSystemInfo);
      color: var(--whiteAndBlack);
    }
  }
`;
const howManyDaysChoices = Array(31)
  .fill(0)
  .map((_, i) => {
    return { label: `${i + 1}`, value: `${i + 1}` };
  });

const howManyHoursChoices = Array(24)
  .fill(0)
  .map((_, i) => {
    return { label: `${i + 1}`, value: `${i + 1}` };
  });

const optionsSelect = [
  { label: 'Days', value: 'days' },
  { label: 'Hours', value: 'hours' }
];

const richTextEditorFields = {
  subjectHeaderDescription: '',
  textWithSignOn: '',
  textWithoutSignOn: '',
  second_text: '',
};

const PartnerWelcomeMessage = props => {
  // const partner_id = useId({ key: 'partnerId' });
  // const id = getId('partnerId');
  const notify = useNotify();
  const iframeRef = React.useRef();
  const history = useHistory();
  const { location } = history;
  const theme = useTheme();
  const { dispatch } = useApp();
  const {
    system: { tabCurrent }
  } = useSelector(rxState => rxState.bsn);

  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: ''
  });

  const [partner_welcome_state, setPartnerWelcomeState] = React.useState({
    default_welcome_message_used: undefined,
    id: '',
    original_data: undefined,
    pick_list: undefined,
    test_message_id: '',
    is_preview_loading: false,
    template: '',
    frame_template_url: undefined,
    show_welcome_modal: false,
    show_confimation_modal: false,
    hideFrame: false,
    page_loading: false,
    email: '',
    pending_edit: false
  });
  const { form, setForm, onSwitchChange, onSelectChange } = useForm({
    subjectHeader: '',
    sso_enabled: false,
    isWelcomeMessageDelay: false,
    quantatiy: '',
    option: '',
    ...richTextEditorFields
  });
  const [richTextInputs, setRichTextInputs] = useState(richTextEditorFields);
  const formValues = {...richTextInputs, ...form};
  const userRole = useSelector(state => state?.bsn?.user?.profile?.user_role);
  const [isLeavePageDialogOpened, setIsLeavePageDialogOpened] = useState(false);

  const handleCloseLeavePage = () => setIsLeavePageDialogOpened(false);
  const handleSubmitLeavePage = () => {
    setTriggerExit(prevState => ({
      ...prevState,
      onOk: true
    }));
    setIsLeavePageDialogOpened(false);
  };

  const handleClickExit = () => {
    const params = new URLSearchParams(location.search);
    history.push(`${params.get('location')}`);
  }

  const isClient = React.useMemo(() => {
    return partner_welcome_state.id === partner_welcome_state.test_message_id;
  }, [partner_welcome_state.id, partner_welcome_state.test_message_id]);

  React.useEffect(() => {
    if (triggerExit.onOk) {
      if(triggerExit.path === '/admin/dashboard/manage-partners') {
        dispatch.changeApp('admin', 'clients', 'dashboard', tabCurrent);
      }
      history.replace(triggerExit.path)
    }
    const unblock = history.block((location, type) => {
      if (location.pathname !== history.location.pathname) {
        if (type === 'POP') history.goForward();
        setIsLeavePageDialogOpened(true);
      }
      setTriggerExit(prevState => ({ ...prevState, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [history, triggerExit.onOk, triggerExit.path]);

  React.useEffect(() => {
    if (!location.search) {
      return;
    }
    const params = new URLSearchParams(location.search);
    setPartnerWelcomeState(old => {
      return {
        ...old,
        id: params.get('id'),
        test_message_id: params.get('test_message_id'),
        show_welcome_modal: !Number(params.get('new_welcome_message_seen') ?? '1'),
        email: params.get('email')
      };
    });
  }, [location.search]);

  React.useEffect(() => {
    if (userRole == USER_ROLES.MANGER) {
      history.push('/myDashboard/dashboard');
    }
  }, [history, userRole]);

  const setMessageHandler = React.useCallback(
    (data, is_preview) => {
      if (!partner_welcome_state.test_message_id) {
        return;
      }

      setPartnerWelcomeState(old => {
        return { ...old, is_preview_loading: true };
      });

      clients
        .testCustomMessage(partner_welcome_state.test_message_id, {
          subject: data.subjectHeader ?? '',
          first_text: Buffer.from(data.subjectHeaderDescription ?? '').toString('base64'),
          single_sign_on_text: Buffer.from(data.textWithSignOn ?? '').toString('base64'),
          without_single_sign_on_text: Buffer.from(data.textWithoutSignOn ?? '').toString('base64'),
          second_text: Buffer.from(data.second_text ?? '').toString('base64'),
          email: partner_welcome_state.email,
          is_template_preview: Number(is_preview),
          sso_enabled: Number(data.sso_enabled)
        })
        .then(response => {
          if (!is_preview) {
            notify(response.data.description ?? 'Save Successful', 'success');
            return;
          }
          setPartnerWelcomeState(old => {
            return { ...old, template: response.data.bound_template };
          });
        })
        .catch(err => {
          const err_response = err?.response?.data;
          notify(`${err_response?.message ?? err_response?.description ?? 'Something went wrong'}`, 'error');
        })
        .finally(() => {
          setPartnerWelcomeState(old => {
            return { ...old, is_preview_loading: false, pending_edit: false };
          });
        });
    },
    [partner_welcome_state.test_message_id, partner_welcome_state.email, notify]
  );

  const debounded_setMessageHandler = React.useCallback(
    debounce((...args) => {
      setForm(args[0]);
      setMessageHandler(...args);
    }, 1500),
    [setMessageHandler, setRichTextInputs]
  );

  const getCutomMessageCallback = React.useCallback(() => {
    if (!partner_welcome_state.id) {
      return;
    }
    setPartnerWelcomeState(old => {
      return { ...old, page_loading: true };
    });

    (isClient ? clients : partner)
      .getCutomMessages(partner_welcome_state.id, {
        new_welcome_message_seen: Number(partner_welcome_state.show_welcome_modal ?? 1),
        message_type: 1
      })
      .then(response => {
        const data = !isClient ? response?.data?.partner_messages : response?.data?.client_messages;
        const pick_list =
          response?.data?.custom_message_picklist?.map(f => {
            return {
              title: f
                .replace(/_/g, ' ')
                .split(' ')
                .map(word => {
                  return word[0].toUpperCase() + word.substring(1);
                })
                .join(' '),
              value: `{{${f}}}`
            };
          }) ?? [];

        const default_welcome_message_used = response?.data?.default_welcome_message_used;

        if (!data) {
          notify('Something went wrong', 'error');
          return;
        }
        let option = 'hours';
        if (data.hours > 24) {
          option = 'days';
        }

        const second_text = new RegExp('^<p>.*</p>$', 'g').test(data.second_text)
        ? data.second_text
        : `<p>${data.second_text}</p>`;

        const result = {
          subjectHeader: data.subject,
          subjectHeaderDescription: data.first_text,
          textWithSignOn: data.single_sign_on_text,
          textWithoutSignOn: data.without_single_sign_on_text,
          second_text,
          quantatiy: option === 'days' ? data.hours / 24 : data.hours,
          isWelcomeMessageDelay: Boolean(data.deferred_sending),
          option,
          sso_enabled: response.data.sso_enabled
        };

        setMessageHandler(result, true);

        setForm(old => {
          return { ...old, ...result };
        });


        setPartnerWelcomeState(old => {
          return {
            ...old,
            original_data: result,
            pick_list,
            default_welcome_message_used: isClient ? default_welcome_message_used : undefined
          };
        });

        // preview the call
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => {
        setPartnerWelcomeState(old => {
          return { ...old, page_loading: false };
        });
      });
  }, [
    notify,
    setMessageHandler,
    setForm,
    partner_welcome_state.id,
    partner_welcome_state.show_welcome_modal,
    isClient
  ]);

  React.useEffect(() => {
    getCutomMessageCallback();
  }, [getCutomMessageCallback]);

  const richTextConfig = React.useMemo(() => {
    const config = updateMutable(defaultConfig, ['autocomplete', 'sources', 1], partner_welcome_state.pick_list);
    config.iframe = false;
    config.uploader.insertImageAsBase64URI = false;
    config.buttons = config.buttons.filter(v => !disabledRichTextEditorButtons.includes(v));
    config.toolbarSticky = false;
    config.allowResizeY = true;
    config.style = { color: theme.palette.text.primary , maxHeight: 197 };
    return updateNestedMutable(config, { link: { openInNewTabCheckbox: false, noFollowCheckbox: false } });
  }, [partner_welcome_state.pick_list, theme.palette.text.primary]);

  const smallLimitRichTextConfig = useMemo(() => ({ ...richTextConfig, limitChars: 2048 }), [richTextConfig])
  const largeLimitRichTextConfig = useMemo(() => ({ ...richTextConfig, limitChars: 65535 }), [richTextConfig])

  function onSaveClick() {
    if (!form.subjectHeader?.trim().length) {
      return enqueueAlertSnackbar('Subject Header is a required field', {
        props: { severity: 'error' }
      });
    }
    setPartnerWelcomeState(old => {
      return { ...old, page_loading: true };
    });
    const obj = {
      subject: form.subjectHeader ?? '',
      first_text: Buffer.from(form.subjectHeaderDescription ?? '').toString('base64'),
      single_sign_on_text: Buffer.from(form.textWithSignOn ?? '').toString('base64'),
      without_single_sign_on_text: Buffer.from(form.textWithoutSignOn ?? '').toString('base64'),
      second_text: Buffer.from(form.second_text ?? '').toString('base64'),
      deferred_sending: form.isWelcomeMessageDelay ?? false,
      add_notification_email: partner_welcome_state.email,
      hours: form.option === 'days' ? form.quantatiy * 24 : form.quantatiy,
      delete_draft: 1,
      is_template_preview: 0,
      message_type: 1
    };

    (isClient ? clients : partner)
      .setCustomMessage(
        partner_welcome_state.id,
        {
          message_type: 1
        },
        obj
      )
      .then(_res => {
        notify('Save Successful', 'success');
        setPartnerWelcomeState(old => {
          return { ...old, original_data: form, default_welcome_message_used: isClient ? false : undefined };
        });
        setForm(prevFormData => ({...prevFormData, ...richTextInputs}))
      })
      .catch(err => {
        notify(err?.response?.data?.message || err?.response?.data?.description || 'Something went wrong', 'error');
      })
      .finally(() => {
        setPartnerWelcomeState(old => {
          return { ...old, page_loading: false };
        });
        getCutomMessageCallback();
      });
  }

  const onModalClose = () => {
    setPartnerWelcomeState(old => {
      return { ...old, show_welcome_modal: false };
    });
    partnerChecklistApi
      .welcomeMessageStatus()
      .then(res => {
        const params = new URLSearchParams(location.search);
        params.set('new_welcome_message_seen', '1');
        history.replace(`${location.pathname}?${params.toString()}`);
      })
      .catch(err => {
        notify(err?.response?.message || err?.response?.description || 'Something went wrong', 'error');
      });
  };
  const ResetHandle = React.useMemo(() => {
    if (!isClient || partner_welcome_state.default_welcome_message_used === undefined) {
      return <></>;
    }
    if (!partner_welcome_state.default_welcome_message_used) {
      return (
        <span style={{ marginLeft: 'auto' }}>
          <ButtonCancel
            onClick={() => {
              setPartnerWelcomeState(old => {
                return { ...old, show_confimation_modal: true };
              });
            }}
          >
            <ReloadIcon ml={1.2} mr={1.2} size={1.3} />
            <Typography.p bold={500}>Revert to Default Settings</Typography.p>
          </ButtonCancel>
        </span>
      );
    }
    return (
      <span style={{ marginLeft: 'auto', display: 'inline-flex', alignItems: 'center', textDecoration: 'underline' }}>
        <InternetIcon ml={1.2} mr={1.2} size={1.3} />
        <Typography.p bold={500}> Default settings are being used </Typography.p>
      </span>
    );
  }, [isClient, partner_welcome_state.default_welcome_message_used]);
  React.useEffect(() => {
    const elem = iframeRef.current;
    if (!elem || !partner_welcome_state.template) return;
    const blob = new Blob([partner_welcome_state.template], {
      type: 'text/html'
    });
    const url = URL.createObjectURL(blob);
    setPartnerWelcomeState(old => {
      if (old.frame_template_url) {
        URL.revokeObjectURL(old.frame_template_url);
      }
      return { ...old, frame_template_url: url };
    });
    return function cleanup() {
      URL.revokeObjectURL(url);
    };
  }, [partner_welcome_state.template, iframeRef.current]);

  if (partner_welcome_state.page_loading) {
    return <LoadingStyled />;
  }


  return (
    <>
      {/* Welcome message buttons */}
      <Container.Paper mt={2} radius={1}>
        <Container.Grid spacing={4}>
          <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ContainerTitle style={{ marginBottom: '0', paddingBottom: '0' }}>
              <Typography.h3 mt={0.1} my={2.5}>
                Edit Message
              </Typography.h3>
              {!isClient ? (
                <InfoButtons name="global_welcome_message" />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  {ResetHandle}
                </Box>
              )}
            </ContainerTitle>
            <Stack
              spacing={2}
              sx={{
                '@media (max-width: 1600px)': {
                  flexWrap: 'wrap'
                }
              }}
              useFlexGap
              direction="row"
              px={2.5}
              py={3.75}
              height="calc(100vh - 344px)"
              overflow="auto"
              flexGrow={1}
            >
              <Stack flexGrow={1}>
                <TextField
                  style={{ minWidth: '35.4rem', marginBottom: '1rem' }}
                  label="Subject Header"
                  name="subjectHeader"
                  inputProps={{ maxLength: 255 }}
                  value={form.subjectHeader || ''}
                  onChange={e => {
                    const { value } = e.currentTarget;
                    if (value.length < 100 && !isStrContainsEmoji(value)) {
                      setPartnerWelcomeState(old => ({ ...old, pending_edit: true, is_preview_loading: true }));
                      setForm(old => ({ ...old, subjectHeader: value }));
                      debounded_setMessageHandler({ ...form, subjectHeader: value }, true);
                    }
                  }}
                />
                  <RichTextEditor
                    value={form.subjectHeaderDescription}
                    config={largeLimitRichTextConfig}
                    onBlur={value => {
                      setForm(old => ({ ...old, subjectHeaderDescription: value }));
                    }}
                    onChange={value => {
                      setPartnerWelcomeState(old => ({ ...old, pending_edit: true, is_preview_loading: true }));
                      debounded_setMessageHandler({ ...form, subjectHeaderDescription: value }, true);
                    }}
                  />
                <Box mt={3.75}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginBottom: '1.5rem'
                    }}
                  >
                    <Typography.h4 my={0}>Text With Single Sign-On </Typography.h4>
                    <Tooltip title="This text will be used if Single Sign-On is enabled" placement="right-end">
                      <Stack mx={1.5}>
                        <InfoIcon sx={{ fontSize: 16 }} />
                      </Stack>
                    </Tooltip>
                    <Switch
                      name="Single Sign-On"
                      checked={form.sso_enabled}
                      disabled={partner_welcome_state.is_preview_loading}
                      onChange={e => {
                        const { checked } = e.target;
                        setPartnerWelcomeState(old => ({ ...old, pending_edit: true }));
                        setForm(old => {
                          return {
                            ...old,
                            sso_enabled: !old.sso_enabled
                          };
                        });

                        setMessageHandler({ ...form, sso_enabled: checked }, true);
                      }}
                      label={<Typography.p>Preview</Typography.p>}
                    />
                  </div>
                  <Box mt={1}>
                    <RichTextEditor
                      value={form.textWithSignOn}
                      config={smallLimitRichTextConfig}
                      onBlur={value => {
                        setForm(old => ({ ...old, textWithSignOn: value }));
                      }}
                      onChange={value => {
                        setPartnerWelcomeState(old => ({ ...old, pending_edit: true, is_preview_loading: true }));
                        debounded_setMessageHandler({ ...form, textWithSignOn: value }, true);
                      }}
                    />
                  </Box>
                </Box>
                <Box mt={3.75}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginBottom: '1.5rem'
                    }}
                  >
                    <Typography.h4 my={0}>Text Without Single Sign-On </Typography.h4>
                    <Tooltip title="This text will be used if Single Sign-On is not enabled" placement="right-end">
                      <Stack mx={1.5}>
                        <InfoIcon sx={{ fontSize: 16 }} />
                      </Stack>
                    </Tooltip>
                    <Switch
                      name="Without Single Sign-On"
                      checked={!form.sso_enabled}
                      disabled={partner_welcome_state.is_preview_loading}
                      onChange={e => {
                        const { checked } = e.target;
                        setForm(old => {
                          return {
                            ...old,
                            sso_enabled: !old.sso_enabled
                          };
                        });
                        setPartnerWelcomeState(old => ({ ...old, pending_edit: true }));
                        setMessageHandler({ ...form, sso_enabled: !checked }, true);
                      }}
                      label={<Typography.p>Preview</Typography.p>}
                    />
                  </div>
                  <Box mt={2}>
                    <RichTextEditor
                      value={form.textWithoutSignOn}
                      config={smallLimitRichTextConfig}
                      onBlur={value => {
                        setForm(old => ({ ...old, textWithoutSignOn: value }));
                      }}
                      onChange={value => {
                        setPartnerWelcomeState(old => ({ ...old, pending_edit: true, is_preview_loading: true }));
                        debounded_setMessageHandler({ ...form, textWithoutSignOn: value }, true);
                      }}
                    />
                  </Box>
                  <Box mt={2}>
                    <RichTextEditor
                      value={form.second_text}
                      config={largeLimitRichTextConfig}
                      onBlur={value => {
                        setForm(old => ({ ...old, second_text: value }));
                      }}
                      onChange={value => {
                        setPartnerWelcomeState(old => ({ ...old, pending_edit: true, is_preview_loading: true }));
                        debounded_setMessageHandler({ ...form, second_text: value }, true);
                      }}
                    />
                  </Box>
                  <div
                    style={{
                      marginTop: '6.25rem',
                      marginBottom: '4rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Switch
                      name="isWelcomeMessageDelay"
                      checked={form.isWelcomeMessageDelay}
                      onChange={e => {
                        onSwitchChange({ currentTarget: { name: 'isWelcomeMessageDelay' } });
                        // debounded_setMessageHandler({ ...form, isWelcomeMessageDelay: e.target.checked }, true);
                      }}
                      label={<Typography.h4>Welcome Message Delay</Typography.h4>}
                    />

                    <CustomTooltip
                      arrow
                      light
                      maxWidth={400}
                      width={400}
                      title={
                        <Typography.Base component="span">
                          When a new user is added via directory sync or csv bulk upload the welcome messages will not
                          go out for this period of time. This can be used to allow time to send phishing emails and
                          other configuration before alerting users.
                        </Typography.Base>
                      }
                      placement="right"
                    >
                      <InfoIconLegacy ml={1.2} size={1.3} />
                    </CustomTooltip>
                  </div>

                  {form.isWelcomeMessageDelay && (
                    <div
                      style={{
                        maxWidth: '34.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <SelectField
                        style={{ minWidth: '16rem' }}
                        id="hours-per-days"
                        name="option"
                        label="Hours/Days"
                        value={form.option}
                        choices={optionsSelect}
                        onChange={e => {
                          const { name, value } = e.target;
                          onSelectChange({ currentTarget: { name: 'option', value } });
                          setForm(old => {
                            return { ...old, quantatiy: '' };
                          });
                          // debounded_setMessageHandler({ ...form, option: value }, true);
                        }}
                      />
                      <SelectField
                        style={{ minWidth: '16rem' }}
                        ml={3.75}
                        value={form.quantatiy}
                        onChange={e => {
                          onSelectChange({ currentTarget: e.target });
                          // debounded_setMessageHandler({ ...form, quantatiy: e.target.value }, true);
                        }}
                        choices={form.option === 'days' ? howManyDaysChoices : howManyHoursChoices}
                        id="days"
                        name="quantatiy"
                        label={`How many ${form.option === 'days' ? 'days' : 'hours'}?`}
                      />
                    </div>
                  )}
                </Box>
              </Stack>

              <Stack borderRadius={1} minWidth={748} minHeight={848} bgcolor="#E5E5E5">
                <Typography.h4 mx={2.5} sx={{ marginBottom: '8px' }}>
                  Email Preview
                </Typography.h4>
                {!partner_welcome_state.hideFrame && (
                  <Box position="relative" pointerEvents="none" height="100%">
                    {partner_welcome_state.is_preview_loading && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        height="100%"
                        width="100%"
                        bgcolor={alpha('#E5E5E5', 1)}
                      >
                        <LoadingStyled />
                      </Box>
                    )}
                    <Box height="100%">
                      <iframe
                        key={partner_welcome_state.frame_template_url}
                        title="preview"
                        ref={iframeRef}
                        sandbox="allow-same-origin"
                        style={{
                          border: 0,
                          height: '100%',
                          width: '100%'
                        }}
                        src={partner_welcome_state.frame_template_url}
                      />
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
              alignItems="center"

              // style={{
              //   display: 'flex',
              //   justifyContent: 'space-evenly',
              //   marginLeft: 'auto',
              //   alignItems: 'center',
              //   padding: '2rem'
              // }}
            >
              <ButtonCancel
                onClick={handleClickExit}
                style={{ marginTop: '0', marginRight: '1rem' }}
              >
                Exit
              </ButtonCancel>
              <OutlineBtn
                style={{ marginRight: '1rem' }}
                onClick={() => {
                  if (form.subjectHeader == '') {
                    return enqueueAlertSnackbar('Subject Header is a required field', {
                      props: { severity: 'error' }
                    });
                  }
                  setMessageHandler(form, false);
                }}
                variant="outlined"
              >
                <EmailIcon size={1.3} mr={1.25} />
                Test Message
              </OutlineBtn>
              <SaveButton
                disabled={
                  isEqual(formValues, partner_welcome_state.original_data) ||
                  partner_welcome_state.pending_edit ||
                  partner_welcome_state.is_preview_loading ||
                  (form.isWelcomeMessageDelay && !form.quantatiy)
                }
                onClick={() => onSaveClick()}
                style={{ marginRight: '1rem' }}
              >
                <SaveIcon size={1.3} mr={1.25} />
                Save
              </SaveButton>
            </Box>
          </Container.Grid>
        </Container.Grid>
      </Container.Paper>

      <Modal
        opened={partner_welcome_state.show_confimation_modal}
        dismissOutside
        onClose={() => {
          setPartnerWelcomeState(old => {
            return { ...old, show_confimation_modal: false };
          });
        }}
        overwriteStyles={{
          dialog: { maxWidth: '60rem', margin: '0 auto' }
        }}
        data={
          <>
            <div
              style={{
                padding: '4.4rem',
                display: 'flex',
                justifySelf: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <div
                style={{
                  backgroundColor: '#FFA45733',
                  opacity: '0.8',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '7rem',
                  height: '7rem'
                }}
              >
                <Siren color="var(--colorSystemWarning)" />
              </div>
              {/* h2 */}
              <Typography.h2 mx={0} my={0} textAlign="center" style={{ color: 'var(--colorSystemWarning)' }}>
                Warning{' '}
              </Typography.h2>

              {/*  subtitle  */}
              <Typography.p mx={0} mt={0} mb={5} textAlign="center">
                If you proceed, any changes you made to the Welcome Message will be lost, and it will be reverted to the
                default settings.
                <br /> <br /> <br />
                Do you want to continue with this action?{' '}
              </Typography.p>
              <Button
                backgroundColor="colorSystemSuccess"
                backgroundColorHover="colorSystemSuccessHover"
                backgroundDisabled="colorSystemSuccessDisable"
                onClick={() => {
                  clients
                    .revertCustomMessage(partner_welcome_state.id)
                    .then(() => {
                      setPartnerWelcomeState(old => {
                        return { ...old, default_welcome_message_used: true, show_confimation_modal: false };
                      });
                      getCutomMessageCallback();
                    })
                    .catch(err_response => {
                      notify(
                        `${err_response?.message ?? err_response?.description ?? 'Something went wrong'}`,
                        'error'
                      );
                    });
                }}
              >
                Yes
              </Button>

              <ButtonCancel
                mt={1}
                onClick={() => {
                  // redirectTo('/partnerProfile/configure');
                  setPartnerWelcomeState(old => {
                    return { ...old, show_confimation_modal: false };
                  });
                }}
              >
                Cancel
              </ButtonCancel>
            </div>
          </>
        }
      />
      <Modal
        opened={partner_welcome_state.show_welcome_modal}
        dismissOutside
        onClose={() => {
          onModalClose();
        }}
        overwriteStyles={{
          dialog: { maxWidth: '65rem', margin: '0 auto' }
        }}
        data={
          <>
            <CloseIcon
              style={{ marginLeft: 'auto', padding: '2rem', width: '2.2rem', height: '2.2rem', cursor: 'pointer' }}
              onClick={() => {
                onModalClose();
              }}
            />
            <div
              style={{
                padding: '7.2rem',
                display: 'flex',
                justifySelf: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <NewWelcomeMessage style={{ minWidth: '14.2rem', height: '100%' }} />

              {/* h2 */}
              <Typography.h2 mx={0} my={0} textAlign="center">
                New Default Welcome Message
              </Typography.h2>

              {/*  subtitle  */}
              <Typography.p mx={0} mt={0} mb={5} textAlign="center">
                Set your default welcome message settings. These settings will be the default settings for all welcome
                messages sent to clients. Welcome messages can also be customized for each client in the Manage Clients
                application.
              </Typography.p>
              <StyledButton
                onClick={() => {
                  onModalClose();
                }}
              >
                Let&apos;s Get Started
              </StyledButton>
            </div>
          </>
        }
      />
      <LeavePageDialog
        openDialog={isLeavePageDialogOpened}
        onCloseDialog={handleCloseLeavePage}
        onSubmitDialog={handleSubmitLeavePage}
      />
      {/* Action buttons */}
      {/* <ActionButtons /> */}
    </>
  );
};

export default PartnerWelcomeMessage;