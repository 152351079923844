const partners = {
  defaultSortValue: {
    sortname: 'email',
    order: 'asc',
  },
  selecting: false,
  sort: [
    'name',
    'email',
    'distributor',
    'client_name',
    'client_count',
    'users_count',
    'date_created',
    'active',
    'account',
    'ebpp'
  ],
  head: [
    {
      id: 'name',
      label: 'Partner',
      align: 'left',
    },
    {
      id: 'client_name',
      label: 'Client',
      align: 'left',
    },
    {
      id: 'email',
      label: 'Partner Contact',
      align: 'left',
    },
    {
      id: 'client_count',
      label: 'Client Count',
      align: 'left',
    },
    {
      id: 'users_count',
      label: 'Users',
      align: 'left',
    },
    {
      id: 'distributor',
      label: 'Distributor',
      align: 'left',
    },
    {
      id: 'ebpp',
      label: 'EBPP',
      align: 'center'
    },
    {
      id: 'date_created',
      label: 'Created',
      align: 'left',
    },
    {
      id: 'active',
      label: 'Active',
      align: 'left',
    },
    {
      id: 'account',
      label: 'Account',
      align: 'left',
    },
    {
      label: 'Actions',
      align: 'left',
    }
  ]
};

export default partners;
