export const PARTNER_SETUP_BASE_PATH = '/partnerProfile/configure';

export const TASKS = [
  {
    title: 'Branding',
    name: 'branding',
    getDescription: () => {
      return 'Align your branding with your cybersecurity training portal to help your clients celebrate your partnership and ensure a strong human security focus between your management and every end-user. Here’s where you can set up your master logo and color scheme that will be displayed to all your clients.'
    }
  },
  {
    title: 'Partner Notifications',
    name: 'partner_notifications',
    getDescription: isNewSRA => {
      return `Ensure that the appropriate team member receives notifications regarding important inquiries and updates from our platform. Here’s where you can set up a contact email for directory sync, contact us, ${
        isNewSRA ? 'Security Risk Assessment,' : ''
      } and Catch Phish notifications.`;
    }
  },
  {
    title: 'Default Welcome Message',
    name: 'global_welcome_message',
    urlPath: 'default_welcome_message',
    getDescription: () => {
      return 'Welcome messages can be customized and sent to your clients’ users as you onboard them. Here’s where you can customize and set up your default welcome messages. If you’d like to craft customized welcome messages for each client, you can do so on a per-client basis. If you’re not ready to send welcome messages immediately upon onboarding, you can also customize your deferment options here.'
    }
  },
  {
    title: 'Ticket Management System',
    name: 'ticket_management_system',
    getDescription: () => {
      return 'Improve visibility into your clients’ training programs with alerts delivered directly to your Ticket Management System by leveraging our integrations with ConnectWise, Autotask, and Gradient MSP.'
    }
  },
  {
    title: 'Automated Reminders & Reports',
    name: 'automated_reminders_and_reports',
    getDescription: () => {
      return 'Simplify your program management while increasing user engagement with the platform by taking advantage of our automated reminders and reports. Here’s where you configure automated reminders to encourage users to complete their required tasks and automated reports to send to users of your choice to report on specific areas of the platform. You can also track the frequency and status of those configurations in this section.'
    }
  },
  {
    title: 'Tax Exemption',
    name: 'tax_exemption',
    getDescription: () => {
      return 'Avoid sales tax additions by uploading your sales tax exemption certificate or creating a new one with our tool.'
    }
  },
  {
    title: 'Integrations',
    name: 'integrations',
    getDescription: () => {
      return 'Streamline the process for packaging and reporting on important human security training metrics by leveraging our integrations.'
    }
  },
  {
    title: 'Training Configuration',
    name: 'training_configuration',
    getDescription: () => {
      return 'View and tailor the availability of your current and future training courses to fit your client’s needs.'
    }
  }
];
