import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Typography, Link, Box, makeStyles } from '@material-ui/core';
import { LazyIcon, Reset } from 'components';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  content: {
    maxWidth: '600px',
    maxHeight: '480px',
    padding: '30px 20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  cancelBtn: {
    color: 'var(--colorDefault)',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  submitBtn: {
    backgroundColor: '#FFA457 !important',
    color: '#fff !important',
    textTransform: 'none',
    fontWeight: 'normal'
  },
  resetIcon: {
    backgroundColor: '#FFA45733',
    color: '#FFA457',
    borderRadius: '50%',
    padding: '10px',
    fontSize: '35px'
  }
}));

const WarningAddUserModal = (props) => {
  const { showWarning, setShowWarning, onConfirm } = props;
  const classes = useStyles();

  return (
      <Dialog onClose={ () => setShowWarning(false) } aria-labelledby="simple-dialog-title" open={ showWarning }>
        <div className={classes.content}>
            <Box pb={2.5}>
                <LazyIcon
                className={classes.resetIcon}
                style={{ backgroundColor: '#FFA45733' }}
                component="Siren"
                color="#FFA457"
                size={4}
                />
            </Box>
            <Typography
                className={classes.title}
                variant="h4"
                display="block"
                gutterBottom
                pb={2}
                style={{ color: '#FFA457' }}
            >
                Warning
            </Typography>
            <Typography display="block" gutterBottom pb={2}>
                Directory Sync is enabled.<br/>
                Manually adding users will prevent them from being managed through
                Directory Sync, which may inhibit them from receiving
                portal-generated emails. To include this user in your sync,
                add them to your BSN Security Group in your directory.
            </Typography>
            <Box pb={1.5} pt={2.5}>
                <Typography display="block" gutterBottom>
                    Would you like to proceed with manually adding this user?
                </Typography>
            </Box>
            <>
                <Box pb={2.5}>
                <Button
                    variant="contained"
                    className={classes.submitBtn}
                    disabled={false}
                    onClick={onConfirm}     
                >
                    Yes, Create User
                </Button>
                </Box>

                <Box>
                <Link
                    className={classes.cancelBtn}
                    color="inherit"
                    onClick={() => {
                        setShowWarning(false);
                    }}               
                >
                    Cancel
                </Link>
                </Box>
            </>
        </div>
      </Dialog>
  );

};

export default WarningAddUserModal;
