// @flow

const documentsTemplates = {
  type: 'documentsTemplates',
  // row: {
  //   click: true,
  //   action: 'newWindow',
  //   field: 'attachment_link'
  // },

  cells: [
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: 'created_date', label: 'Created', align: 'left' },
    { id: 'modified_date', label: 'Modified', align: 'left' },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      val: 'attachment_link',
      renderDowloadIcon: true,
      hideOrderBy: true,
      GAbutton: 'Download Template'
    }
  ]
};

export default documentsTemplates;
