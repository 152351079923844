const downloadFile = (url, name) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  if (name) a.download = name;
  document.body.appendChild(a);
  a.href = url;
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export default downloadFile;
