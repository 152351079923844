// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Undo = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M15.0773 3.00956C14.8194 3.00956 14.5041 3.00955 14.1411 3H14.1316C13.6061 3 13.1858 3.42035 13.1762 3.94577C13.1667 4.4712 13.5966 4.90108 14.122 4.91064C14.4946 4.91064 14.8098 4.9202 15.0773 4.9202C18.9464 4.9202 22.0894 7.36581 22.0894 10.3655C22.0894 13.3557 18.9464 15.7822 15.0773 15.7822C13.7781 15.7822 11.2369 15.7344 8.72445 15.6866C6.75649 15.6484 4.81719 15.6102 3.47974 15.6007L5.37128 13.7091C5.74386 13.3366 5.74386 12.7252 5.37128 12.3526C5.18022 12.1711 4.94138 12.0755 4.693 12.0755C4.44462 12.0755 4.20579 12.1711 4.01473 12.3526L0.957699 15.4096L0.279431 16.0879C-0.0931436 16.4605 -0.0931436 17.0719 0.279431 17.4444L0.957699 18.1227L4.01473 21.1797C4.38731 21.5523 4.99871 21.5523 5.37128 21.1797C5.74386 20.8071 5.74386 20.1957 5.37128 19.8232L3.04985 17.5017C4.35864 17.5113 6.51767 17.5495 8.68624 17.5973C11.2178 17.645 13.7781 17.6928 15.0773 17.6928C19.9972 17.6928 24 14.4065 24 10.3655C23.9904 6.30541 19.9972 3.00956 15.0773 3.00956Z" />
  </SvgIconStyled>
);

export default React.memo(Undo);
