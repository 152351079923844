
/**
 * @function resolve
 * @async
 * @param {Promise} promise //any callback promise 
 * @returns // promise response | err
 * @example 
 * const {response,err} = resolve( ()=> new promise(...) );
 */
export async function resolve(
    promise
) {
    try {
        const response = await promise();
        return { response: response };
    } catch (err) {
        return { err: err };
    }
}

/**
 * @function resolveAll
 * @async
 * @param {()=> Promise[]} promise any callback promise 
 * @returns // promise response | err
 * @description use this when you need to make more than api calls , promises , events that are mutually inclusive
 * @example 
 * const {response,err} = resolveAll( ()=> [new Promise(...),new Promise(...)] );
 */
export async function resolveAll(promise) {
    try {
        const response = await Promise.all(promise());
        return { response: response };
    } catch (err) {
        return { err: err };
    }
}