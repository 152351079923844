import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Communications = props => (
  <SvgIconStyled {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_21258_32738)">
      <path
        d="M32.3204 0H4.67957C3.57108 0 2.50799 0.440176 1.72417 1.22369C0.940346 2.00721 0.5 3.06989 0.5 4.17795V26.0147C0.518275 27.1107 0.966676 28.1555 1.74852 28.924C2.53037 29.6926 3.58304 30.1232 4.67957 30.123H22.0526L25.0619 34.7188C25.3127 35.1045 25.6552 35.4221 26.0588 35.6433C26.4623 35.8644 26.9144 35.9822 27.3746 35.9861C27.8334 35.9891 28.2858 35.8787 28.6917 35.665C29.0975 35.4512 29.4443 35.1405 29.7012 34.7605L32.8777 30.0395C33.2755 29.9838 33.6643 29.8761 34.0341 29.7192C34.5228 29.503 34.9639 29.1924 35.3321 28.8052C35.7004 28.418 35.9884 27.9619 36.1796 27.4631C36.3767 26.9961 36.4763 26.4937 36.4721 25.9868V4.08046C36.4468 2.99419 35.9991 1.96051 35.224 1.19872C34.4489 0.436927 33.4074 0.00694849 32.3204 0ZM33.7136 26.0147C33.7204 26.0468 33.7204 26.08 33.7136 26.1122C33.6857 26.4237 33.5537 26.7167 33.3389 26.9441C33.124 27.1714 32.8389 27.3199 32.5294 27.3656C31.4706 27.3656 31.5542 27.2124 31.0248 27.9226L27.4025 33.3261L23.8638 27.8809C23.4737 27.2681 23.209 27.4631 22.0248 27.4352H4.67957C4.31007 27.4352 3.95571 27.2885 3.69443 27.0273C3.43316 26.7661 3.28638 26.4119 3.28638 26.0426V4.08046C3.28638 3.71111 3.43316 3.35688 3.69443 3.09571C3.95571 2.83454 4.31007 2.68781 4.67957 2.68781H32.3204C32.6899 2.68781 33.0443 2.83454 33.3056 3.09571C33.5668 3.35688 33.7136 3.71111 33.7136 4.08046V26.0147Z"
        fill="#0069FF"
      />
      <path
        d="M17.1075 16.6144C17.1308 17.0582 17.2249 17.4954 17.3861 17.9096C17.4695 18.092 17.6056 18.2452 17.777 18.3495C17.9483 18.4538 18.147 18.5043 18.3474 18.4945C18.5471 18.5006 18.7443 18.4486 18.9149 18.3447C19.0856 18.2409 19.2224 18.0897 19.3087 17.9096C19.4715 17.5007 19.5657 17.0678 19.5873 16.6283L19.9496 12.4504C19.9704 12.0561 19.9704 11.661 19.9496 11.2666C20.0096 10.7224 19.9526 10.1716 19.7824 9.65117C19.6596 9.42026 19.4743 9.22856 19.2476 9.09803C19.0209 8.9675 18.7621 8.90339 18.5007 8.91306C18.2713 8.90618 18.0432 8.94863 17.8316 9.03753C17.6201 9.12643 17.4302 9.25971 17.2747 9.42834C17.1086 9.61784 16.9823 9.83877 16.9033 10.078C16.8244 10.3172 16.7943 10.5699 16.8149 10.821C16.8149 11.1692 16.8149 11.7401 16.8846 12.5479L17.1075 16.6144Z"
        fill="#0069FF"
      />
      <path
        d="M17.2745 19.7196C17.1195 19.8725 16.9964 20.0546 16.9124 20.2554C16.8284 20.4562 16.7852 20.6717 16.7852 20.8894C16.7852 21.1071 16.8284 21.3226 16.9124 21.5234C16.9964 21.7242 17.1195 21.9064 17.2745 22.0592C17.5851 22.3364 17.9867 22.4901 18.403 22.491C18.8199 22.4927 19.2223 22.3387 19.5315 22.0592C19.6985 21.9091 19.8301 21.7238 19.9168 21.5167C20.0036 21.3096 20.0433 21.0858 20.033 20.8616C20.036 20.6477 19.9942 20.4357 19.9102 20.239C19.8262 20.0423 19.7019 19.8654 19.5454 19.7196C19.2396 19.4276 18.8329 19.2646 18.41 19.2646C17.9871 19.2646 17.5804 19.4276 17.2745 19.7196Z"
        fill="#0069FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_21258_32738">
        <rect width="36" height="36" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default React.memo(Communications);
