/* eslint-disable react/prop-types */
import { Box, DataGrid, Paper } from '@trustsecurenow/components-library';
import React, { memo, useMemo } from 'react';
import Toolbar from './Toolbar';
import ChipCell from './ChipCell';
import ActionsCell from './ActionsCell';

const ThreatsTable = memo(
  ({
    rows,
    rowCount,
    paginationModel,
    onEditThreat,
    onPaginationModelChange,
    onAddThreat,
    onDeleteThreat,
    isLoading,
    sortModel,
    onSortModelChange,
    filterModel,
    onFilterModelChange
  }) => {
    const columns = useMemo(
      () => [
        {
          field: 'number',
          headerName: 'Threat #',
          minWidth: 115,
        },
        {
          field: 'name',
          headerName: 'Threat Name',
          minWidth: 150,
          flex: 1
        },
        {
          field: 'probability',
          headerName: 'Probability w/existing Controls',
          width: 247,
          renderCell: ChipCell
        },
        {
          field: 'impact',
          headerName: 'Impact w/existing controls',
          width: 227,
          renderCell: ChipCell
        },
        {
          field: 'risk',
          headerName: 'Risk w/existing controls',
          width: 192,
          renderCell: ChipCell
        },
        {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          width: 300,
          disableColumnMenu: true,
          renderCell: props => (
            <ActionsCell
              permissions={props.row.permissions}
              onDeleteThreat={() => onDeleteThreat(props.row)}
              onEditThreat={() => onEditThreat(props.row)}
            />
          )
        }
      ],
      [onDeleteThreat, onEditThreat]
    );

    return (
      <Paper component={Box} mt={3.75} height="84vh">
        <DataGrid
          slots={{ toolbar: () => <Toolbar onAddThreat={onAddThreat} /> }}
          columns={columns}
          disableColumnFilter
          disableColumnReorder
          disableColumnResize
          rows={rows}
          loading={isLoading}
          hideFooter={!rows.length}
          rowCount={rowCount}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          filterDebounceMs={500}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
        />
      </Paper>
    );
  }
);

export default ThreatsTable;
