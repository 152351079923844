import DateFnsUtils from '@date-io/date-fns';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CalendarFile, Container, TextField, Typography } from 'components';
import { add, format, getDate } from 'date-fns';
import { checkIsToday } from 'helpers';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FREQUENCY_PERIOD, WEEKDAYS, getFrequencyPeriodText, validateNumber, parseDate } from './helpers';

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return date.toLocaleString('default', { month: 'long' });
  }
}

const dateToolbarStyle = {
  fontWeight: 'bold',
  fontSize: '130%',
  alignSelf: 'center',
  margin: '15px 0 -10px 0',
  color: 'var(--blackAndWhite)'
};

const DaysTextField = styled(TextField)`
  > div {
    width: 80px;
  }
`;

function FrequencyPeriod({
  initialData,
  state,
  setState,
  isFrequencyPeriodSelected,
  isEditedSubscriptionData,
  actionLoading
}) {
  const [nextSendDate, setNextSendDate] = useState(state?.subscription?.next_send);
  const [quarterlyCalenderDate, setQuarterlyCalenderDate] = useState(null);

  useEffect(() => {
    setNextSendDate(state?.subscription?.next_send)
  }, [state?.subscription?.next_send])

  useEffect(() => {
    if (!!quarterlyCalenderDate) return; // already executed
    if (!initialData?.subscription) return; // data not fetched yet

    // set INITIAL VALUES for start/next/last send dates and don't change until the modal is closed
    const start = initialData?.subscription?.start_sending_date;
    const next = initialData?.subscription?.next_send;
    const last = initialData?.subscription?.last_send;

    const today = format(new Date(), 'yyyy-MM-dd');
    const min = last
      ? format(add(new Date(last), { months: 3 }), 'yyyy-MM-dd')
      : !!start && parseDate(start) < parseDate(today) // fix timezone issue where start can be less than today
      ? start
      : today;

    const calenderData = {
      min,
      value: last ? next : start
    };

    setQuarterlyCalenderDate(calenderData);
  }, [initialData, quarterlyCalenderDate]);

  const handleChange = (e, name) => {
    switch (name) {
      case 'frequency_period': {
        const error = validateNumber({
          value: e.target.value === '' ? '' : Number(e.target.value),
          constraints: {
            minimum: 1,
            integer: true,
            maximum: 84
          }
        });

        const newVal = e.target.value === '' ? '' : Number(e.target.value);
        setState(state => {
          return {
            ...state,
            subscription: {
              ...state.subscription,
              frequency_days: newVal,
              day_of_month: newVal === 28 ? 1 : null,
              start_sending_date: null,
              ...(newVal === 28 || newVal === 84 ? { day_of_week: null } : {})
            },
            subscription_validation: {
              ...state.subscription_validation,
              day_of_month_error: false,
              day_of_month_error_text: ''
            }
          };
        });
        setNextSendDate(null);

        break;
      }
      case 'day_of_week': {
        const newVal = Number(e.target.value);
        setState(state => {
          return {
            ...state,
            subscription: { ...state.subscription, day_of_week: newVal }
          };
        });
        break;
      }
      case 'day_of_month': {
        const error = validateNumber({
          value: e.target.value === '' ? '' : Number(e.target.value),
          constraints: {
            minimum: 1,
            integer: true,
            maximum: 31
          }
        });
        const newVal = e.target.value === '' ? '' : Number(e.target.value);
        setState(state => {
          return {
            ...state,
            subscription: { ...state.subscription, day_of_month: newVal },
            subscription_validation: {
              ...state.subscription_validation,
              day_of_month_error: error.error,
              day_of_month_error_text: error.error_text
            }
          };
        });
        break;
      }
      case 'start_sending_date': {
        const selectedDateObj = e;
        const start_sending_date = format(selectedDateObj, 'yyyy-MM-dd');
        const day_of_month = getDate(selectedDateObj);
        const isToday = checkIsToday(selectedDateObj);
        const nextSendDate = format(isToday ? add(selectedDateObj, { months: 3 }) : selectedDateObj, 'yyyy-MM-dd');

        setState(state => {
          return {
            ...state,
            subscription: {
              ...state.subscription,
              day_of_month,
              start_sending_date
            }
          };
        });
        setQuarterlyCalenderDate(s => ({ ...s, value: start_sending_date }));
        setNextSendDate(nextSendDate);
        break;
      }
      case 'last_send': {
        const newVal = format(e, 'yyyy-MM-dd');
        setState(state => {
          return {
            ...state,
            subscription: {
              ...state.subscription,
              last_send: newVal
            }
          };
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      {/* Frequency Period */}
      {Boolean(state?.push_notification?.show_frequency_period) && (
        <Container.Grid py={1} container alignItems="center">
          <Container.Grid item xs={12}>
            <Typography.p>How often should users receive this report?</Typography.p>
          </Container.Grid>
          <Container.Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="frequencyDays"
                value={state?.subscription?.frequency_days || ''}
                onChange={e => handleChange(e, 'frequency_period')}
              >
                {FREQUENCY_PERIOD.map(period => (
                  <FormControlLabel key={period.label} value={period.value} control={<Radio />} label={period.label} />
                ))}
              </RadioGroup>
            </FormControl>
          </Container.Grid>

          {state?.subscription?.frequency_days && (
            <Container.Grid py={1} container alignItems="center">
              {/* Weekly or Bi-Weekly */}
              {(state?.subscription?.frequency_days === 7 || state?.subscription?.frequency_days === 14) && (
                <>
                  <Container.Grid item xs={12}>
                    <Typography.p>Select the day of the week that you would like to send the report:</Typography.p>
                  </Container.Grid>
                  <Container.Grid item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="weekdays"
                        value={state?.subscription?.day_of_week || ''}
                        onChange={e => handleChange(e, 'day_of_week')}
                      >
                        {WEEKDAYS.map(weekday => (
                          <FormControlLabel
                            key={weekday.label}
                            value={weekday.value}
                            control={<Radio />}
                            label={weekday.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Container.Grid>
                </>
              )}

              {/* Monthly */}
              {state?.subscription?.frequency_days === 28 && (
                <>
                  <Container.Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Typography.p mr={2}>
                        Select the day of the month that you would like to send the report
                      </Typography.p>
                      <DaysTextField
                        inputProps={{ min: 1, max: 31 }}
                        integerOnly
                        type="number"
                        size="small"
                        onChange={e => handleChange(e, 'day_of_month')}
                        value={state?.subscription?.day_of_month || ''}
                        error={state.subscription_validation.day_of_month_error}
                        helperText={state.subscription_validation.day_of_month_error_text}
                      />
                    </Box>
                  </Container.Grid>
                </>
              )}

              {/* Quarterly */}
              {state?.subscription?.frequency_days === 84 && (
                <>
                  <Container.Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Typography.p mr={2}>
                        Select the day you would like to send the first quarterly report
                      </Typography.p>
                      <MuiPickersUtilsProvider utils={LocalizedUtils}>
                        <KeyboardDatePicker
                          ToolbarComponent={() => <div style={dateToolbarStyle}>Choose Date</div>}
                          TextFieldComponent={params => (
                            <TextField
                              {...params}
                              size="small"
                              variant={false}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true
                              }}
                            />
                          )}
                          disabled={actionLoading}
                          style={{ width: '160px' }}
                          value={parseDate(quarterlyCalenderDate?.value)}
                          minDate={parseDate(quarterlyCalenderDate?.min)}
                          initialFocusedDate={parseDate(quarterlyCalenderDate?.value || quarterlyCalenderDate?.min)}
                          id="start_sending_date"
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          size="small"
                          allowKeyboardControl={false}
                          autoOk
                          placeholder="mm/dd/yyyy"
                          onChange={date => {
                            const min = parseDate(quarterlyCalenderDate?.min);
                            if (date && date >= min) {
                              handleChange(date, 'start_sending_date');
                            }
                          }}
                          keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Container.Grid>
                </>
              )}

              {isFrequencyPeriodSelected &&
              state?.subscription?.enabled &&
              !isEditedSubscriptionData &&
              !state?.subscription_validation?.day_of_month_error ? (
                <Container.Grid item xs={12}>
                  <Typography.p fontSize={14} title>
                    {
                      getFrequencyPeriodText(
                        state?.subscription?.frequency_days,
                        state?.subscription?.day_of_week,
                        state?.subscription?.day_of_month,
                        state?.subscription?.start_sending_date,
                        nextSendDate
                      )?.longText
                    }
                  </Typography.p>
                </Container.Grid>
              ) : null}
            </Container.Grid>
          )}
        </Container.Grid>
      )}

      {/* Test Fields */}
      {['dev', 'qa'].includes(process.env.REACT_APP_BUILD_ENV) && (
        <>
          {!!state?.push_notification?.is_monthly_subscription && (
            <Container.Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography.p mr={2}>Select the day of the month that you would like to send the report</Typography.p>
                <DaysTextField
                  inputProps={{ min: 1, max: 31 }}
                  integerOnly
                  type="number"
                  size="small"
                  onChange={e => handleChange(e, 'day_of_month')}
                  value={state?.subscription?.day_of_month || ''}
                  error={state.subscription_validation.day_of_month_error}
                  helperText={state.subscription_validation.day_of_month_error_text}
                />
              </Box>
            </Container.Grid>
          )}
          {(!!state?.push_notification?.is_monthly_subscription ||
            !!state?.push_notification?.show_frequency_period) && (
            <Container.Grid item xs={12}>
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <KeyboardDatePicker
                  ToolbarComponent={() => <div style={dateToolbarStyle}>Choose Date</div>}
                  TextFieldComponent={params => (
                    <TextField
                      {...params}
                      size="small"
                      variant={false}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true
                      }}
                    />
                  )}
                  disabled={actionLoading}
                  style={{ width: '160px' }}
                  value={parseDate(state.subscription.last_send)}
                  id="last_send"
                  format="MM/dd/yyyy"
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  allowKeyboardControl={false}
                  autoOk
                  placeholder="mm/dd/yyyy"
                  label="Last Run"
                  onChange={date => {
                    if (date) {
                      handleChange(date, 'last_send');
                    }
                  }}
                  keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
                />
              </MuiPickersUtilsProvider>
            </Container.Grid>
          )}
        </>
      )}
    </>
  );
}

export default FrequencyPeriod;
