// @flow

import { fetchUtils, GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE } from 'react-admin';
import axios from 'axios';
import { stringify } from 'query-string';
import { api } from 'conf';
import debug from './debug';
import store from './store';
import { getStorage } from './storage';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

/**
 * @param {string} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {string} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataRequestToHTTP: Function = (type: string, resource: string, params: Object): Object => {
  debug.log(`%cAPI CALL ${type}`, 'font-size: medium; color: cyan');
  debug.log(`Resource: ${resource}`);
  debug.log('Params:', params);
  const partnerId = getStorage('partnerId', true);
  const options = {};
  options.method = 'GET';
  options.headers = new Headers({});
  options.headers.set('Authorization', getStorage('idToken', true));

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage
      };
      if (resource === 'Training') options.url = `${api(resource)}/${resource}?${stringify(query)}`;
      else if (resource === 'Company') options.url = `${api(resource)}?${stringify(query)}`;
      else options.url = `${api(resource)}/${resource}/${partnerId}?${stringify(query)}`;
      break;
    }
    case 'GET_CUSTOM_LIST': {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage
      };

      options.url = `${api(resource)}/${resource}?${stringify(query)}`;
      break;
    }
    case 'GET_USER_LIST': {
      const { clientId, filter } = params;
      options.url = `${api(resource)}/${resource}/${partnerId}/${clientId}?${stringify(filter)}`;
      break;
    }
    case GET_ONE:
      switch (resource) {
        case 'partnerProfile':
        case 'clients':
          options.url = `${api(resource, type)}/${resource}/${partnerId}`;
          break;
        case 'Training':
          options.url = `${api(resource)}/${resource}`;
          break;
        case 'Company':
          options.url = `${api(resource)}`;
          break;
        default:
          options.url = encodeURI(`${api(resource)}/${resource}.json`);
          break;
      }

      break;
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage
      };
      options.url = `${api(resource)}/${resource}?${stringify(query)}`;
      break;
    }
    case UPDATE: {
      options.url = `${api(resource)}/${resource}.json`;
      options.method = 'put';
      options.data = JSON.stringify(params);
      break;
    }
    case CREATE: {
      options.url = `${api(resource)}/${resource}`;
      options.method = 'POST';
      options.data = JSON.stringify(params.data);
      options.headers = {};
      options.headers['Content-Type'] = 'application/json';
      break;
    }
    case DELETE: {
      options.url = `${api(resource)}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;
    }
    case GET_MANY: {
      const query = {
        id: params.ids
      };
      options.url = `${api(resource)}/${resource}?${stringify(query)}`;
      break;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }

  debug.log('Axios Options:');
  debug.log(options);
  return options;
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {string} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {string} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse: Function = (response: Object, type: string, resource: string, params: Object): Object => {
  debug.log('Response: ', response);
  const { headers, data } = response;
  debug.log(`Data:`, data);
  debug.log(params);
  // eslint-disable-next-line default-case
  switch (resource) {
    case 'initiate_signup': {
      localStorage.setItem('initiate_signup_token', response.data.unique_token);
      break;
    }

    case 'signup': {
      localStorage.removeItem('initiate_signup_token');
      break;
    }
  }

  switch (type) {
    case 'GET_CUSTOM_LIST':
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      if (resource === 'partnerProfile' || resource === 'Training') {
        return {
          data: [data],
          total: 1
        };
      }
      if (!Object.prototype.hasOwnProperty.call(headers, 'x-total-count')) {
        throw new Error(
          `The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data,
        total: parseInt(headers['x-total-count'], 10)
      };
    }

    case GET_ONE: {
      switch (resource) {
        case 'Training': {
          return { data: data[0] };
        }

        case 'partnerProfile': {
          return { data };
        }

        case 'clients': {
          return {
            data: {
              ...params.data,
              id: data.id,
              DarkWebMonitoring: data.DarkWebMonitoring
            }
          };
        }

        default: {
          return { data };
        }
      }
    }

    case CREATE: {
      return { data: { ...params.data, id: data.id } };
    }

    default: {
      return { data };
    }
  }
};

/**
 * @param {Object} error The error returned by the server
 * @returns {void} no return
 */
const errorRespose: Function = (error: Object): void => {
  if (process.env.NODE_ENV === 'development') {
    console.log('%cERROR', 'font-size:medium; color: red', error);
    if (error.response) {
      const { data } = error.response;
      debug.log(`The request was made and the server responded with a status code
that falls out of the range of 2xx`);
      debug.log('Response Data:');
      debug.table(data);
      debug.log(`Response Status: ${error.response.status}`);
      debug.log('Response Headers:');
      debug.table(error.response.headers);
      const { description, response } = data;
      const message = description || response;
      throw new Error(message);
    } else if (error.request) {
      debug.log(`The request was made but no response was received
"error.request" is an instance of XMLHttpRequest in the browser and an instance of
http.ClientRequest in node.js`);
      debug.log(error.request);
    } else {
      debug.log(`Something happened in setting up the request that triggered an Error`);
      debug.log(`Error: ${error.message}`);
    }
    debug.log('Error Config:');
    debug.log(error.config);
    store('errorHandler').dispatch();
  }
};

const customCall = resource =>
  new Promise((resolve, reject) => {
    resolve({
      data: {
        name: 'Paulo',
        brand_key: '',
        email: '',
        resource
      }
    });
  });

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params The data request params, depending on the type
 * @returns {Promise} the Promise for a data response
 */
export default async (type: string, resource: string | false, params: Object) => {
  switch (type) {
    case 'getCustom': {
      console.log(type);
      return customCall(resource);
    }

    default: {
      return axios(convertDataRequestToHTTP(type, resource, params))
        .then(response => convertHTTPResponse(response, type, resource, params))
        .catch(error => errorRespose(error));
    }
  }
};
