import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Stepper, Step, StepLabel, IconButton } from '@material-ui/core';

// custom components
import { CircleArrow } from 'components/icons';
import CustomStepIcon from 'components/steppers/CustomStepIcon';

const breakPoints = [
  { width: 90, itemsToShow: 1, itemsToScroll: 1 },
  { width: 150, itemsToShow: 2, itemsToScroll: 2 },
  { width: 200, itemsToShow: 3, itemsToScroll: 3 },
  { width: 250, itemsToShow: 3, itemsToScroll: 3 },
  { width: 300, itemsToShow: 4, itemsToScroll: 4 },
  { width: 350, itemsToShow: 4, itemsToScroll: 4 },
  { width: 400, itemsToShow: 5, itemsToScroll: 5 },
  { width: 450, itemsToShow: 6, itemsToScroll: 6 },
  { width: 500, itemsToShow: 7, itemsToScroll: 7 },
  { width: 650, itemsToShow: 8, itemsToScroll: 8 },
  { width: 700, itemsToShow: 9, itemsToScroll: 9 },
  { width: 750, itemsToShow: 10, itemsToScroll: 10 },
  { width: 800, itemsToShow: 10, itemsToScroll: 10 }
];

const customArrow = ({ type, onClick, isEdge, setDisableArrow }) => {
  const isLeftArrow = type === consts.PREV;
  
  // this is a workaround so we can dynamically hide the arrow buttons when both are disabled
  setDisableArrow(old => {
    const direction = isLeftArrow ? 'left' : 'right';
    if (old[direction] === isEdge) return old;
    return { ...old, [direction]: isEdge };
  });

  return (
    <IconButton onClick={onClick} disabled={isEdge}>
      <CircleArrow style={{ transform: isLeftArrow ? 'rotate(180deg)' : null }} iconColor="var(--colorIcon)" />
    </IconButton>
  );
};

function QuestionsStepper({ questionId, questionsStatus, gotoQuestion, disabled }) {
  const [disableArrow, setDisableArrow] = React.useState({ left: false, right: false });

  return (
    <Stepper style={{ padding: '1.5rem 0rem', backgroundColor: 'transparent' }}>
      <Carousel
        breakPoints={breakPoints}
        pagination={false}
        renderArrow={props => customArrow({ ...props, setDisableArrow })}
        showArrows={!(disableArrow.left && disableArrow.right)}
        initialActiveIndex={0}
        style={{ height: 'auto' }}
      >
        {questionsStatus.map(({ question_id, is_answered }, index) => {
          return (
            <>
              <Step
                key={question_id}
                active={questionId === question_id}
                completed={is_answered}
                onClick={() => !disabled && gotoQuestion({ question_id })}
                style={{ padding: '1rem' }}
              >
                <StepLabel disabled={disabled} StepIconComponent={CustomStepIcon} icon={index + 1} />
              </Step>
            </>
          );
        })}
      </Carousel>
    </Stepper>
  );
}

export default React.memo(QuestionsStepper);
