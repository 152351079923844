

const addIfExisting = (inputObj,outputObj,key)=>{
    if(inputObj.hasOwnProperty(key)){
        outputObj[key] = inputObj[key];
    }
}

const addQueryParams= (options,requestOptions) =>{
    addIfExisting(options,requestOptions,"params");
}

const addHeaders= (options,requestOptions) =>{
    addIfExisting(options,requestOptions,"headers");
}

const addCancelToken= (options,requestOptions) =>{
  addIfExisting(options,requestOptions,"cancelToken");
}

const addUploadProgress= (options,requestOptions) =>{
    addIfExisting(options,requestOptions,"onUploadProgress");
  }

  const addResponseType = (options,requestOptions) => {
    addIfExisting(options,requestOptions,"responseType");
}
export function getRequestOptions(options){
    const requestOptions = {}
    addHeaders(options,requestOptions);
    addResponseType(options,requestOptions);
    addQueryParams(options,requestOptions);
    addCancelToken(options,requestOptions);
    addUploadProgress(options,requestOptions);
    return requestOptions;
}
