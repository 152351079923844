import { format } from 'date-fns';
import { fromUTCtoLocal } from '../../helpers/utils';

const DateTime = ({ date = '', isUTC = false }) => {
  /*
   * new Date("yyyy-mm-dd hh:mm:ss") returns invalid date on Safari
   * reformat string date from "yyyy-mm-dd hh:mm:ss" to "yyyy-mm-ddThh:mm:ss"
   */
  const _date = date?.replace(' ', 'T') || date;

  if (isUTC)
    return fromUTCtoLocal(_date)
      .replace('T', ' ')
      .replace('Z', '');

  const dateString = !!_date ? new Date(_date) : new Date();
  return format(dateString, 'yyyy-MM-dd, h:mm a');
};

export default DateTime;
