// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Track = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21.238 27.916" role="img" aria-hidden="false">
    <g transform="translate(-13.9 -2.5)">
      <path
        d="M60.965,16.683a2.883,2.883,0,1,0-2.883,2.883A2.887,2.887,0,0,0,60.965,16.683Zm-4.295,0a1.441,1.441,0,1,1,1.441,1.441A1.425,1.425,0,0,1,56.671,16.683Z"
        transform="translate(-29.151 -7.976)"
      />
      <path
        d="M50.107,20.12a1.978,1.978,0,0,0,1.736-1c2.03-3.53,4.471-8.207,4.471-10.413a6.207,6.207,0,1,0-12.413,0c0,2.206,2.442,6.883,4.471,10.413A2.05,2.05,0,0,0,50.107,20.12Zm0-16.179a4.783,4.783,0,0,1,4.765,4.765c0,1.441-1.559,4.971-4.295,9.707a.494.494,0,0,1-.471.265.558.558,0,0,1-.471-.265c-2.736-4.707-4.295-8.266-4.295-9.707A4.783,4.783,0,0,1,50.107,3.941Z"
        transform="translate(-21.175)"
      />
      <path
        d="M30.343,53.707H17.959a2.618,2.618,0,0,1,0-5.236h3.883a.735.735,0,0,0,0-1.471H17.959a4.059,4.059,0,1,0,0,8.119H30.343a2.618,2.618,0,1,1,0,5.236H20.195a.735.735,0,0,0,0,1.471H30.343a4.059,4.059,0,1,0,0-8.119Z"
        transform="translate(0 -31.41)"
      />
    </g>
  </SvgIconStyled>
);

export default Track;
