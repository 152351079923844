import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Alert3 = props => (
  <SvgIconStyled {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" role="img" aria-hidden="false">
    <g clip-path="url(#clip0_21258_64258)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.045 15.1198L14.315 2.59484C16.04 -0.870156 20.96 -0.870156 22.67 2.59484L28.94 15.1198L35.99 29.2048C36.335 29.8948 36.5 30.6148 36.5 31.3048C36.5 33.7648 34.535 35.9998 31.82 35.9998H18.5H5.18C2.48 35.9998 0.5 33.7648 0.5 31.3048C0.5 30.6148 0.665 29.8948 0.995 29.2048L8.045 15.1198ZM26.27 16.4548L20 3.92984C19.58 3.08984 18.815 2.99984 18.5 2.99984C18.185 2.99984 17.42 3.08984 17 3.92984L10.73 16.4698L3.68 30.5548C3.56 30.7948 3.5 31.0498 3.5 31.3048C3.5 32.1148 4.145 32.9998 5.18 32.9698H18.5H31.82C32.855 32.9698 33.5 32.0998 33.5 31.2898C33.5 31.0348 33.44 30.7798 33.32 30.5398L26.27 16.4548ZM16.6849 14.7448L17.0899 20.8798C17.1649 21.6748 17.2999 22.2748 17.4649 22.6498C17.6449 23.0398 17.9599 23.2348 18.4249 23.2348C18.8749 23.2348 19.2049 23.0398 19.3999 22.6348C19.5949 22.2448 19.7299 21.6598 19.7899 20.9098L20.3449 14.5948C20.4049 14.0098 20.4349 13.4398 20.4349 12.8698C20.4349 11.9098 20.3149 11.1748 20.0599 10.6648C19.8199 10.1548 19.3249 9.89981 18.5899 9.89981C17.9749 9.89981 17.4949 10.1098 17.1199 10.4998C16.7449 10.9048 16.5649 11.4598 16.5649 12.1648C16.5649 12.6898 16.6099 13.5448 16.6849 14.7448ZM17.1499 28.8148C17.5399 29.1598 17.9899 29.3248 18.5149 29.3248C19.0249 29.3248 19.4749 29.1448 19.8649 28.8148C20.2399 28.4698 20.4349 28.0048 20.4349 27.4048C20.4349 26.8798 20.2399 26.4298 19.8649 26.0548C19.4899 25.6798 19.0249 25.4998 18.4849 25.4998C17.9449 25.4998 17.4949 25.6798 17.1199 26.0548C16.7449 26.4298 16.5649 26.8798 16.5649 27.4048C16.5649 28.0048 16.7599 28.4848 17.1499 28.8148Z"
        fill={props.color ? props.color : '#0069FF'}
      />
    </g>
    <defs>
      <clipPath id="clip0_21258_64258">
        <rect width="36" height="36" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default React.memo(Alert3);
