import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from 'react-admin';
import { useNotify } from 'ra-core';
import { Paper, styled } from '@material-ui/core';
import * as sra from 'helpers/apis/services/sra';
import { SRA_SUB_PAGES } from '../../constants';
import { useSRA } from '../../context/sraContext';
import SRAQuestionsQuiz from '../../SRAQuestionsQuiz';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(5),
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(2.5),
  height: 'calc(100vh - 204px)'
}));

const EditQuestions = () => {
  const { app, tab, item } = useLocation();
  const { reportId } = useParams();
  const history = useHistory();
  const notify = useNotify();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const [sraReportData, setSraReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    state: { revisionId },
    clientId
  } = useSRA();


  const fetchReportData = useCallback(async () => {
    const response = await sra.getSraReportDetails(clientId, reportId);
    if (response?.status === 200) {
      setSraReportData(response?.data);
    }
    setLoading(false);
  }, [clientId, reportId]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  useEffect(() => {
    if (clientId && revisionId) {
      const data = { enabled: 1 };
      sra.setSraEditMode(clientId, revisionId, data).catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'warning');
      });
    }
  }, [clientId, revisionId, notify]);

  const goToEditReportPage = () => {
    history.push(
      `/${app}/${tab}${item && !isSraSubPage ? `/${item}` : ''}/editSraReport${reportId ? `/${reportId}` : ''}`
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <SRAQuestionsQuiz
      clientId={clientId}
      Container={StyledPaper}
      onSubmit={goToEditReportPage}
      quizRevisionId={sraReportData.quiz_revision_id}
    />
  );
};

export default EditQuestions;
