// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Lock = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15 19.2" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h15v19.2H0z" />
    <path
      d="M13.1,8h-10V5.5c0.1-2.4,2-4.3,4.4-4.3c2.3,0,4.2,1.9,4.3,4.3c0,0.1,0,0.2,0.1,0.3c0.2,0.3,0.5,0.4,0.8,0.3  c0.2-0.1,0.4-0.3,0.4-0.6C13,2.4,10.5-0.1,7.4,0C4.4,0.1,2,2.5,1.9,5.5V8C0.9,8,0,8.9,0,9.9v7.3c0,1,0.8,1.9,1.9,1.9h11.2  c1,0,1.9-0.8,1.9-1.9V9.9C15,8.9,14.1,8,13.1,8z M13.7,17.3c0,0.3-0.3,0.6-0.6,0.6H1.9c-0.3,0-0.6-0.3-0.6-0.6V9.9  c0-0.3,0.3-0.6,0.6-0.6h11.2c0.3,0,0.6,0.3,0.6,0.6L13.7,17.3z"
      fill={props.fill ?? 'inherit'}
    />
  </SvgIconStyled>
);

export default Lock;
