import React from 'react';

const Uploadlg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1.41699C23.1591 1.41699 28.2498 5.98023 28.8565 11.8096C31.7843 12.403 34 15.0539 34 18.2324C34 21.8592 31.1381 24.8002 27.6168 24.8002C26.8914 24.8002 26.2979 24.2067 26.2979 23.4814C26.2979 22.756 26.8914 22.1625 27.6168 22.1625C29.6742 22.1625 31.3623 20.3953 31.3623 18.2324C31.3623 16.0826 29.6874 14.3285 27.6168 14.3285C27.2343 14.3285 26.9046 14.1703 26.6672 13.9197C26.4298 13.6823 26.2715 13.3526 26.2715 12.9833C26.2715 8.06401 22.104 4.06788 16.9868 4.06788C11.8961 4.06788 7.75486 8.06401 7.75486 12.9833C7.75486 13.5372 7.41195 13.9988 6.92397 14.1967C6.75252 14.2626 6.58107 14.3154 6.38324 14.3154C4.32583 14.3154 2.6377 16.0826 2.6377 18.2455C2.6377 20.3953 4.31264 22.1493 6.38324 22.1493C7.10861 22.1493 7.70209 22.7428 7.70209 23.4682C7.70209 24.1936 7.10861 24.7871 6.38324 24.7871C2.8619 24.7871 0 21.8592 0 18.2455C0 15.0539 2.24204 12.3898 5.18308 11.8096C5.78975 5.98023 10.8542 1.41699 17 1.41699ZM17.9363 19.0237L18.8727 19.9601L23.093 24.1804C23.6074 24.6947 23.6074 25.5388 23.093 26.0532C22.5787 26.5675 21.7346 26.5675 21.2202 26.0532L18.3188 23.1517V32.4364C18.3188 33.1618 17.7253 33.7553 16.9999 33.7553C16.2746 33.7553 15.6811 33.1618 15.6811 32.4364V23.1517L12.7796 26.0532C12.2652 26.5675 11.4211 26.5675 10.9068 26.0532C10.6562 25.7894 10.5244 25.4597 10.5244 25.1168C10.5244 24.7739 10.6562 24.4442 10.9068 24.1804L15.1271 19.9601L16.0635 19.0237C16.5779 18.5093 17.4219 18.5093 17.9363 19.0237Z"
      fill="#0069FF"
    />
  </svg>
);

export default React.memo(Uploadlg);
