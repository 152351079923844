// @flow
import React, { useCallback, useMemo } from 'react';
import { makeStyles, DialogContent, Box, RadioGroup } from '@material-ui/core';
import { LoadingStyled } from 'components';
import { UserSearch } from 'components/icons';
import { RadioWithIcon, OptionsSelect } from '../../common';
import { getAudienceByName, getClientsDescription } from '../../../newsfeedHelpers';
import { ModalTitle, ModalActions } from './index';
import { AUDIENCES } from 'apps/newsfeed/constants';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0
  },
  titleInfo: {
    color: 'var(--colorSystemInfo)',
    padding: '24px 24px 12px',
    fontWeight: 500,
    fontSize: 16,
    margin: 0
  }
}));

const SelectAudience = ({
  options,
  setView,
  data,
  setAudienceData,
  audienceLoading,
  setNotifyAudience,
  isContentAdmin,
  disableNotifyAudience
}) => {
  const classes = useStyles();
  const isPartnerAdmin = useMemo(() => options?.find(item => item.name === AUDIENCES.clients), [options]);
  const isClientsSelected = data.included_clients?.length > 0;

  const onChangeAudience = e => {
    setView('main');
    const newAudicence = options.find(item => item.id === Number(e.target.value));
    setAudienceData(prevState => {
      return {
        allowed_groups: newAudicence?.name === AUDIENCES.partner ? [] : prevState.allowed_groups,
        included_clients: [],
        audience: newAudicence
      };
    });

    const disableEmailNotifications = isContentAdmin
      ? newAudicence?.name === AUDIENCES.general
      : newAudicence?.name === AUDIENCES.partner;

    if (disableEmailNotifications && !disableNotifyAudience) {
      setNotifyAudience({
        push_notifications: false,
        email_notifications: false
      });
    }
  };

  const handleViewChange = useCallback(view => setView(view), []);

  return (
    <>
      <ModalTitle title="Select Audience" onClick={() => handleViewChange('main')} />

      <DialogContent className={classes.dialogContent}>
        {audienceLoading ? (
          <LoadingStyled />
        ) : (
          <>
            <p className={classes.titleInfo}>Who can see your post?</p>
            <RadioGroup name="audience" value={!isClientsSelected && data?.audience?.id} onChange={onChangeAudience}>
              {options.map(item => (
                <RadioWithIcon
                  key={item.id}
                  value={item.id}
                  label={item.name}
                  labelPlacement="start"
                  icon={getAudienceByName(item.name).icon}
                  selectedVal={!isClientsSelected && data?.audience?.id}
                />
              ))}
            </RadioGroup>
            {isPartnerAdmin && (
              <OptionsSelect
                value="Specific Clients"
                description={isClientsSelected && getClientsDescription(data.included_clients)}
                icon={<UserSearch />}
                isSelected={isClientsSelected}
                onClick={() => handleViewChange('clients')}
              />
            )}
          </>
        )}
      </DialogContent>

      {isClientsSelected ? (
        <ModalActions title="Save Changes" onClick={() => handleViewChange('main')} disabled={audienceLoading} />
      ) : (
        <Box p={3} />
      )}
    </>
  );
};

export default React.memo(SelectAudience);
