// @flow
// todo: Remove file
import React, { useReducer } from 'react';
import { addField, FieldTitle } from 'ra-core';
import { InputAdornment, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from './TextField';
import { Link } from './ComponentTypes';

type Props = {
  classes?: Object,
  className?: string,
  input: Object,
  isRequired?: boolean,
  label: string | boolean,
  meta?: Object,
  name: string,
  onChange?: Function,
  options?: Object,
  resource: string,
  source: string,
  type?: string,
  margin?: string,
  onBlur: Function,
  onFocus: Function
};

const defaultProps = {
  onChange: () => {},
  options: {},
  type: 'text',
  isRequired: false,
  classes: '',
  className: '',
  margin: 'normal',
  meta: {
    touched: null,
    error: null
  }
};

const fieldReducer = (state, { type, value, event }) => {
  event.preventDefault();
  switch (type) {
    case 'add': {
      return [...state, ''];
    }

    case 'remove': {
      const val = state;
      val.splice(value, 1);
      return [...val];
    }

    default: {
      return [...state];
    }
  }
};

const TextInputMultiple = ({
  input,
  isRequired,
  label,
  meta,
  options,
  resource,
  source,
  onBlur,
  onFocus,
  onChange,
  ...rest
}: Props) => {
  if (typeof meta === 'undefined') {
    throw new Error(
      `The TextInputMultiple component wasn't called within a redux-form <Field>.
        Did you decorate it and forget to add the addField prop to your component?
        See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.`
    );
  }
  const { touched, error } = meta;
  const { name } = input;
  const [value, setValue] = useReducer(fieldReducer, input.value);

  return (
    <>
      {value.map((val, i) => (
        <TextField
          label={
            label === false ? (
              label
            ) : (
              <FieldTitle label={label} source={source} resource={resource} required={isRequired} />
            )
          }
          error={!!(touched && error)}
          helperText={touched && error}
          {...options}
          {...rest}
          {...input}
          value={val}
          name={`${name}[${i}]`}
          InputProps={
            i > 0 && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="remove company"
                    onClick={e => setValue({ type: 'remove', event: e, value: i })}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        />
      ))}
      {value.length < 3 && (
        <Link size="small" onClick={e => setValue({ type: 'add', event: e, value: '' })}>
          + Add company
        </Link>
      )}
    </>
  );
};

TextInputMultiple.defaultProps = defaultProps;

export default addField(TextInputMultiple);
