const threats = {
  defaultSortValue: {
    sortname: 'name',
    order: 'asc'
  },
  sort: ['number', 'name', 'probability', 'impact', 'risk'],
  head: [
    {
      id: 'number',
      label: 'Threat #',
      align: 'left'
    },
    {
      id: 'name',
      label: 'Threat Name',
      align: 'left'
    },
    {
      id: 'probability',
      label: 'Probabality w/existing Controls',
      align: 'left'
    },
    {
      id: 'impact',
      label: 'Impact w/existing controls',
      align: 'left'
    },
    {
      id: 'risk',
      label: 'Risk w/existing controls',
      align: 'left'
    },
    {
      label: 'Actions',
      align: 'left'
    }
  ]
};

export default threats;
