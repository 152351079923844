import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { LazyIcon } from 'components';
import { CloseFilter } from './filterStyles';
import { SelectField, TextField } from 'components';


const FilterActive = ({ name, state, handleClose, onChange, value, noLabel, isFixed, readOnly, style }) => {
  const [open, setOpen] = React.useState(false);

  const handleCloseOptions = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (readOnly) {
    return (
      <TextField
        name={name}
        label={state.label}
        value={value}
        size="small"
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <CloseFilter onClick={() => handleClose(name)}>
              <LazyIcon component="Close" color="colorDefault" size={0.8} />
            </CloseFilter>
          ),
        }}
      />
    )
  }

  return (
    <SelectField
      name={name}
      label={!noLabel ? state.label : ''}
      value={value}
      choices={state.choices}
      onChange={onChange}
      open={open}
      onClose={handleCloseOptions}
      onOpen={handleOpen}
      IconComponent={() =>
        <>
          {!isFixed && (
            <CloseFilter onClick={() => handleClose(name)}>
              <LazyIcon component="Close" color="colorDefault" size={0.8} />
            </CloseFilter>
          )}
          <LazyIcon
            component="ArrowDown"
            color="colorDefault"
            onClick={handleOpen}
            style={{ cursor: 'pointer' }}
            size={1}
            mr={1.2}
            ml={1.2}
            mb={0.4}
          />
        </>
      }
      size="small"
      fullWidth
      mt={1}
      style={style}
    />
  );
};

export default FilterActive;
