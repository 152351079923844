// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { SvgIconStyled } from './ComponentTypes';

const SVGRect: ComponentType<*> = styled.rect`
  width: calc(var(--spacing) * 1);
  height: calc(var(--spacing) * 5);
  fill: ${({ color }) => `var(--${color})`};
`;

type Props = Object;

const Loading = (props: Props) => {
  return (
    <SvgIconStyled {...props} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <SVGRect x="17.5" y="30" rx="4" color="loadingNormal">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="18;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="64;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
        />
      </SVGRect>
      <SVGRect x="42.5" y="30" rx="4" color="loadingMedium">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="20.999999999999996;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="58.00000000000001;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
        />
      </SVGRect>
      <SVGRect x="67.5" y="30" rx="4" color="loadingDark">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="20.999999999999996;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="58.00000000000001;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        />
      </SVGRect>
    </SvgIconStyled>
  );
};

export default Loading;
