import React from 'react';
import { Pdf, Notes, Edit, DeleteIcon, DragDrop, Undo } from 'components/icons';
import { Cached } from '@material-ui/icons';
import AssessmentInfo from './CheckList/TaskDetailsCards/AssessmentInfo';
import OrganizationProfile from './CheckList/TaskDetailsCards/OrganizationProfile';
import AssessmentQuestionnaire from './CheckList/TaskDetailsCards/AssessmentQuestionnaire';
import MarkComplete from './CheckList/TaskDetailsCards/MarkComplete';
import WorkPlan from './CheckList/TaskDetailsCards/WorkPlan';
import RetakeAssessment from './CheckList/TaskDetailsCards/RetakeAssessment';

export const SRA_SUB_PAGES = ['workPlan', 'orgProfile', 'questions', 'editSraReport'];

export const EDIT_REPORT_PAGE_ITEMS = [
  {
    title: 'Organization Profile',
    text:
      'The organization profile helps document your organization’s assets and identify areas where sensitive information may exist.',
    btnText: 'Go To Organization Profile',
    url: 'organizationProfile',
    img: '/media/sra/organization_profile.svg'
  },
  {
    title: 'SRA Questions',
    text:
      'The risk assessment questions are designed to help identify your current safeguards and detect potential risks and vulnerabilities based on those practices.',
    btnText: 'Go To SRA Questions',
    url: 'questions',
    img: '/media/sra/assessment_info.svg'
  },
  {
    title: 'Recommendations',
    text: 'The recommendations are based on the information provided in the organization profile and questionnaire.',
    btnText: 'Go To Recommendations',
    url: 'recommendations',
    img: '/media/sra/recommendations.svg'
  },
  {
    title: 'Threats',
    text: 'The threats were identified based on the recommendations that were found.',
    btnText: 'Go To Threats',
    url: 'threats',
    img: '/media/AccessDeniedLight.svg'
  }
];

export const SRA_REPORT_FILES = {
  show_executive_summary: {
    text: 'Executive Summary Report',
    icon: <Pdf />
  },
  show_detailed_report: {
    text: 'Detailed Report',
    icon: <Pdf />
  },
  show_work_plan: {
    text: 'Work Plan',
    icon: <Pdf />
  }
};

export const SRA_REPORT_ACTIONS = {
  update: {
    text: 'Update Work Plan',
    icon: <Notes />
  },
  reTake: {
    text: 'Re-take SRA',
    icon: <Cached />
  },
  publish: {
    text: 'Publish',
    icon: <DragDrop />
  },
  edit: {
    text: 'Edit',
    icon: <Edit />
  },
  delete: {
    text: 'Delete Reports',
    icon: <DeleteIcon />,
    hoverColor: 'var(--colorSystemDanger)'
  },
  restore: {
    text: 'Restore Reports',
    icon: <Undo />
  }
};

export const TASK_LIST = [
  {
    name: 'information',
    component: AssessmentInfo
  },
  {
    name: 'org_profile',
    component: OrganizationProfile
  },
  {
    name: 'assessment',
    component: AssessmentQuestionnaire
  },
  {
    name: 'mark_complete',
    component: MarkComplete
  },
  {
    name: 'work_plan',
    component: WorkPlan
  },
  {
    name: 'retake_sra',
    component: RetakeAssessment
  }
];
