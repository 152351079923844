// @flow

const tags = {
  title: 'Create Tag',
  component: 'shared/users/ClientsUsersModalAddTag',
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    }
    // submit: {
    //   icon: 'Done',
    //   label: 'Add Tag'
    // }
    // other: [
    //   {
    //     type: 'resetPassword',
    //     label: 'Reset Password'
    //   } // ,
    //   // {
    //   //   type: 'resetMFAToken',
    //   //   label: 'Reset MFA Token'
    //   // }
    // ]
  },
  initialState: null
};

export default tags;
