import { useEffect, useState, useReducer } from 'react';
import { useQuery } from 'react-query';
import { getContentAdminPath } from 'helpers';
import { getPickListArr, getPickLists } from '../helpers';
import { CONTENT_ADMIN_PICKLIST_DIFFICULTIES, CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED } from '../constants';
import map from 'lodash/map';
import contentAdmin from 'helpers/apis/contentAdmin';

export const useContentAdminPage = (pagePath, isEnabled) => {
  const initialsState = {
    search: '',
    limit: 9,
    page: 1,
    filters: {}
  };

  const path = getContentAdminPath(pagePath);
  const [pickLists, setPickLists] = useState({});
  const [state, setState] = useReducer(reducer, initialsState);

  const { page, limit, search, filters } = state;

  const params = {
    page,
    limit,
    filter: search,
    ...filters
  };

  const { refetch, loading: cardLoading, ...query } = useQuery(
    [`content-admin`, params],
    () => contentAdmin.getList(path, params),
    {
      keepPreviousData: true,
      staleTime: 10,
      cacheTime: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled
    }
  );

  const pickListQuery = useQuery('picklists-1', () => Promise.all(getPickListArr(pagePath)), {
    keepPreviousData: true,
    staleTime: 10,
    cacheTime: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    (async () => {
      if (pickListQuery.status === 'success') {
        try {
          const difficultyGrouped = await contentAdmin.getPickList(CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED);
          const difficultyGroupedFixedData = map(difficultyGrouped.data, ({ difficulty_level, id }) => {
            return {
              value: id,
              label: difficulty_level
            };
          });
          const pickLists = getPickLists(pickListQuery.data);
          setPickLists({ ...pickLists, [CONTENT_ADMIN_PICKLIST_DIFFICULTIES_GROUPED]: difficultyGroupedFixedData });
        } catch (e) {
          console.error('something went wrong');
        }
      }
    })();
  }, [pickListQuery.isLoading]);

  const dispatch = {};

  dispatch.onChange = (name, value) => {
    setState({
      type: 'CHANGE_VALUES',
      payload: {
        name,
        value
      }
    });
  };

  dispatch.changeFilter = (name, value) => {
    setState({
      type: 'CHANGE_FILTERS',
      payload: {
        name,
        value
      }
    });
  };

  dispatch.refetch = () => {
    return refetch();
  };

  return {
    ...query,
    ...state,
    loading: cardLoading || pickListQuery.loading,
    pickLists,
    dispatch
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_VALUES': {
      const { name, value } = action.payload;

      return {
        ...state,
        [name]: value
      };
    }

    case 'CHANGE_FILTERS': {
      const { name, value } = action.payload;

      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          [name]: value
        }
      };
    }
  }
}
