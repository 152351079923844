/* eslint-disable react/prop-types */
import { AddIcon, Button, GridToolbarQuickFilter, Stack } from '@trustsecurenow/components-library';
import React from 'react';

const Toolbar = ({ onAddThreat }) => {
  return (
    <Stack p={2.5} direction="row" justifyContent="space-between">
      <GridToolbarQuickFilter placeholder="Search threats" />
      <Button onClick={onAddThreat} startIcon={<AddIcon />}>
        New Threat
      </Button>
    </Stack>
  );
};

export default Toolbar;
