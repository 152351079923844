import React from 'react';
import { TextField, IconButton, InputAdornment, AddIcon, LessIcon, styled } from '@trustsecurenow/components-library';

const StyledTextField = styled(TextField)(({ value }) => ({
  '.MuiInputBase-root': {
    maxWidth: 200,
    width: 92 + 8 * Math.max(0, value?.toString().length - 1)
  },
  'input.MuiInputBase-input': {
    textAlign: 'center',
    paddingInline: 0,
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '-moz-appearance': 'textfield',
  },
  '.MuiFormHelperText-root': {
    textDecoration: 'none'
  }
}));

const CONTAINS_SIGN_OR_EXPONENT_CHARACTER_REGEX = /[eE\-\+\.]/;
const CONTAINS_SINGLE_SIGN_OR_EXPONENT_CHARACTER_REGEX = /^[eE\-\+\.]$/;

const QuantityInput = ({
  isDecrementDisabled,
  isIncrementDisabled,
  value,
  onIncrementClick,
  onDecrementClick,
  onInputChange,
  error
}) => {
  const handleInputKeyDown = e => {
    if (CONTAINS_SINGLE_SIGN_OR_EXPONENT_CHARACTER_REGEX.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputPaste = e => {
    const pastedData = e.clipboardData.getData('text');
    
    if (CONTAINS_SIGN_OR_EXPONENT_CHARACTER_REGEX.test(pastedData)) {
      e.preventDefault();
    }
  };
  

  return (
    <StyledTextField
      data-testid="quantityInput"
      value={value}
      onChange={onInputChange}
      error={!!error}
      onKeyDown={handleInputKeyDown}
      onPaste={handleInputPaste}
      helperText={error}
      type="number"
      inputMode="numeric"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              color="info"
              onClick={onDecrementClick}
              disabled={isDecrementDisabled}
              data-testid="decrementBtn"
            >
              <LessIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="info"
              onClick={onIncrementClick}
              disabled={isIncrementDisabled}
              data-testid="incrementBtn"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      FormHelperTextProps={{
        'data-testid': 'quantityInputCaption'
      }}
    />
  );
};

export default QuantityInput;
