import React from 'react';

const CircleCheck = props => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="35" fill="#0CCE6B" fillOpacity="0.2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 35C11 48.2369 21.7631 59 35 59C48.2369 59 59 48.2369 59 35C59 21.7631 48.2369 11 35 11C21.7631 11 11 21.7631 11 35ZM14.6923 35C14.6923 23.7938 23.7938 14.6923 35 14.6923C46.1877 14.6923 55.3077 23.7938 55.3077 35C55.3077 46.2062 46.2062 55.3077 35 55.3077C23.7938 55.3077 14.6923 46.2062 14.6923 35ZM43.9538 29.0922C44.6738 28.3722 45.8368 28.3722 46.5568 29.0922C47.2768 29.8122 47.2768 30.9753 46.5938 31.7138L31.806 46.5015C31.4368 46.8522 30.9752 47.0369 30.4952 47.0369C30.2368 47.0369 29.9968 46.963 29.7568 46.8707C29.5353 46.7784 29.3137 46.6492 29.1291 46.4646L23.4614 40.7969C22.7414 40.0769 22.7414 38.9138 23.4614 38.1938C24.1814 37.4738 25.3445 37.4738 26.0645 38.1938L30.4584 42.5876L43.9538 29.0922Z"
      fill="#0CCE6B"
    />
  </svg>
);

export default React.memo(CircleCheck);
