// @flow
import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { LoadingStyled, Indicator } from 'components';
import { Success } from 'components/icons';
import HeadTitle from 'components/mobile/HeadTitle'
import { ButtonSubmit } from '../ComponentTypes';
import { Navigation, Video, Result, Questions } from './components';
import useMicroTrainingQuizData from 'components/mobile/hooks/useMicroTrainingQuizData';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
      margin: '0 auto',
      maxWidth: 576,
      boxSizing: 'border-box',
      position: 'relative',
      '&:before': {
        content: ({ isSubmitted }) => (isSubmitted ? '""' : 'none'),
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.48)',
        zIndex: 1
      },
      '& [class*=ButtonSubmit]': {
        height: 52,
        borderRadius: 30
      }
    }
  },
  titleContainer: {
    '& [class*=Indicator]': {
      minWidth: 55,
      marginLeft: 10
    }
  },
  actionsLeft: {
    opacity: ({ isSubmitted }) => (isSubmitted ? '0.5' : '1'),
    pointerEvents: ({ isSubmitted }) => (isSubmitted ? 'none' : 'auto')
  }
}));

const MicroTraining = ({
  trainingId,
  showVideo = true,
  hasNavigation,
  hasTitle,
  submitCallback,
  closeCallback,
  actionsLeft = null,
  actionsRight = null,
  params,
  isMobile
}) => {
  const {
    record,
    loading,
    handleChangeTrainingId,
    answerProgress,
    handleChangeTraining,
    isCompleted,
    isSubmitted,
    handleSubmitTraining,
    clearTraining
  } = useMicroTrainingQuizData(params);
  const classes = useStyles({ isSubmitted });

  useEffect(() => {
    handleChangeTrainingId(trainingId);
  }, [trainingId]);

  useEffect(() => {
    return () => {
      clearTraining();
      handleChangeTrainingId(null);
    };
  }, []);

  if (loading) return <LoadingStyled />;

  return (
    <>
      {hasTitle && (
        <div className={classes.titleContainer}>
          <HeadTitle
            title={record?.name}
            backButton
            onBack={closeCallback}
            customRightComponent={!isCompleted && <Indicator title={`${answerProgress}%`} value={answerProgress}/>}
          />
        </div>
      )}

      <div className={classes.container}>
        {showVideo && <Video src={record.video} width={isCompleted ? 650 : 890} />}

        {hasNavigation && (
          <Navigation record={record} handleChangeTrainingId={handleChangeTrainingId} />
        )}

        <Result
          score={Number(record.score).toFixed(0)}
          rightAnswers={record.total_correct_answers}
          questionsLength={record.questions.length}
          isCompleted={isCompleted}
          isSubmitted={isSubmitted}
        />

        <Questions
          questions={record.questions}
          isCompleted={isCompleted}
          handleChangeTraining={handleChangeTraining}
          isMobile={isMobile}
        />

        {/* Action buttons */}
        <Box display={isMobile ? "block" : "flex"} justifyContent="space-between" alignItems="center" mt={3}>
          <div className={classes.actionsLeft}>{actionsLeft}</div>
          <div>
            {isCompleted ? (
              actionsRight
            ) : (
              <ButtonSubmit
                disabled={answerProgress !== 100 || isSubmitted}
                onClick={() => handleSubmitTraining(submitCallback)}
                variant="contained"
                disableElevation
                fullWidth={isMobile}
              >
                <Success size={1.3} mr={1.2} />
                Submit Quiz
              </ButtonSubmit>
            )}
          </div>
        </Box>
      </div>
    </>
  );
};

export default MicroTraining;
