// @flow

import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import zxcvbn from 'zxcvbn';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import type { Element } from 'react';
import TextField from './TextField';

const scoreTester: Function = (
  score: number,
  weak: string = 'red',
  fair: string = 'orange',
  good: string = 'lightgreen',
  strong: string = 'green'
): string | null => {
  switch (score) {
    case 0:
      return weak;
    case 1:
      return weak;
    case 2:
      return fair;
    case 3:
      return good;
    case 4:
      return strong;
    default:
      return null;
  }
};

const matchTester: Function = (match: boolean | null): string | null => {
  switch (match) {
    case true:
      return 'green';
    case false:
      return 'red';
    default:
      return null;
  }
};

const setStyle = (val, callback) => `
    > div > fieldset {
    border-color: ${callback(val)} !important;
  }

  label {
    span {
      color: ${callback(val)} !important;
    }
  }

  p {
    color: ${callback(val)} !important;
  }
`;

const Field = styled(TextField)`
  ${({ score }) => setStyle(score, scoreTester)}
`;

const Confirm = styled(TextField)`
  ${({ match }) => setStyle(match, matchTester)}
`;

type Props = Object;

const PasswordField = ({ error, helperText, ...props }: Props): Element<*> => {
  const [password, setPassword]: [string, Function] = useState('');
  const [showPassword, setShowPassword]: [boolean, Function] = useState(false);
  const [passScore, setPassScore]: [number, Function] = useState(-1);
  const [confirmPassword, setConfirmPassword]: [string, Function] = useState('');
  const [showConfirmPassword, setShowConfirmPassword]: [boolean, Function] = useState(false);
  const [match, setMatch]: [boolean | null, Function] = useState(null);
  const [helpText, setHelperText]: [string | null, Function] = useState(null);
  const [errorPass, setErrorPass]: [boolean, Function] = useState(error);
  const [errorConfirm, setErrorConfirm]: [boolean, Function] = useState(error);

  const handlePassword: Function = (e: { target: { value: string } }): void => {
    const val = e.target.value;
    const { score } = zxcvbn(val);
    setPassword(val);
    setPassScore(score);
  };

  const handleConfirmPassword: Function = (e: { target: { value: string } }): void => {
    const val = e.target.value;
    setConfirmPassword(val);
    setMatch(val === password);
    setHelperText(val === password ? 'password match' : 'password does not match');
  };

  useEffect(() => {
    setErrorPass(() => {
      if (passScore === -1 && error) return error;
      return passScore < -1 && passScore > 1;
    });

    setErrorConfirm(() => {
      if (error) return error;
      return match !== null && !match;
    });
  }, [errorPass, errorConfirm, error, match, passScore]);

  return (
    <>
      <Field
        {...props}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handlePassword}
        score={passScore}
        error={errorPass}
        helperText={(passScore === -1 && helperText) || scoreTester(passScore, 'weak', 'fair', 'good', 'strong')}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Confirm
        {...props}
        label="Confirm Password"
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={handleConfirmPassword}
        helperText={(match === null && helperText) || helpText}
        match={match}
        error={errorConfirm}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle confirm password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default memo<Props>(PasswordField);
