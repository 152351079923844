import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_17;
const baseURL = `https://${apiGWID}.${base}/${env}`;

// const localURL = 'http://127.0.0.1:3001'

const trainingInformation = {};


trainingInformation.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true)
  };
};

trainingInformation.getTrainingInformation = (courseId) => {
  const requestOptions = {
    url: `${baseURL}/training_information/${courseId}`,
    headers: trainingInformation.getRequestHeaders()
  };
  return apiClient.get(requestOptions);
};

trainingInformation.setCompletedPage = (data, courseId) => {
  const requestOptions = {
    url: `${baseURL}/completed_page/${courseId}`,
    headers: trainingInformation.getRequestHeaders(),
    data: data,
  };
  return apiClient.post(requestOptions);

};

trainingInformation.setCourseRating = (data, courseId) => {
  const requestOptions = {
    url: `${baseURL}/course_rating/${courseId}`,
    headers: trainingInformation.getRequestHeaders(),
    data: data,
  };
  return apiClient.post(requestOptions);
};


export default trainingInformation;

