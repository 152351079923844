// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { Typography, Button } from 'components';
import { Play, Search } from 'components/icons';
import { encodeId, trackingUtils } from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';
import Parser from 'html-react-parser';
import { MicroTrainingMobileModal } from '../modals';
import { Video as TrainingVideo } from 'apps/dashboard/microTraining/components';
import MicroTraining from 'apps/dashboard/microTraining/MicroTraining';
import { updateFeedScore } from 'helpers/action';
import { useLocation } from 'hooks';

const useStyles = makeStyles(theme => ({
  descriptipn: {
    margin: '0 0 20px',
    fontSize: '1.4rem',
    lineHeight: '1.5'
  },
  quizContent: {
    marginBottom: 10,
    '& [class*=ButtonSubmit]': {
      height: 32,
      fontSize: 14,
      padding: '8px 24px'
    },
    '& .question-item': {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  },
  link: {
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontWeight: 500,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const VIMEO_VIDEO_NUMBER_REGEX = /\/([a-zA-Z\d]+)\?/;

const sendGAEventOnPlayVideo = (videoUrl, videoName, app) => {
  const match = videoUrl.match(VIMEO_VIDEO_NUMBER_REGEX);
  const videoId = match && match[1] ? match[1] : null;

  trackingUtils.customEvent('video_started', {
    video_id: videoId,
    video_name: videoName,
    app,
    platform: "portal"
  });
}

const FeedMicrotraining = ({ microtraining, postId, updateScrollPosition }) => {
  const { name, topic_text, quick_tips, video_url, user_score, id } = microtraining;
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileView = useBreakpoint('sm');
  const [quizOpened, setQuizOpened] = useState(false);
  const trainingId = encodeId(id);
  const { app } = useLocation();

  const openQuizContent = () => {
    setQuizOpened(true);
  };

  const closeQuizContent = () => {
    setQuizOpened(false);
  };

  const hideQuizContent = () => {
    closeQuizContent();
    updateScrollPosition();
  };

  const updateTrainingScore = () => {
    dispatch(updateFeedScore(postId, true));
  };

  const handlePlayVideo = () => {
    sendGAEventOnPlayVideo(video_url, name, app);
  }

  return (
    <>
      <Typography.h3>{name}</Typography.h3>
      {topic_text && <p className={classes.descriptipn}>{Parser(topic_text)}</p>}
      {quick_tips && (
        <>
          <Typography.h3>Quick Tips</Typography.h3>
          <p className={classes.descriptipn}>{Parser(quick_tips)}</p>
        </>
      )}

      <TrainingVideo src={video_url} onPlayVideo={handlePlayVideo} />

      {quizOpened && (
        mobileView ? (
          <MicroTrainingMobileModal
            open={quizOpened}
            close={closeQuizContent}
            trainingId={trainingId}
            submitCallback={updateTrainingScore}
          />
        ) : (
          <div className={classes.quizContent}>
            <MicroTraining
              trainingId={trainingId}
              showVideo={false}
              actionsLeft={
                <span className={classes.link} onClick={hideQuizContent}>
                  Hide quiz
                </span>
              }
              submitCallback={updateTrainingScore}
            />
          </div>
        )
      )}

      {(!quizOpened || mobileView) && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          {user_score !== null ? (
            <Button
              height={32}
              backgroundColor="colorSystemWarning"
              backgroundColorHover="colorSystemWarningHover"
              onClick={() => openQuizContent()}
            >
              <Search color="colorCommonWhite" mr={1.2} size={1.3} />
              Review Quiz
            </Button>
          ) : (
            <Button
              height={32}
              backgroundColor="colorSystemSuccess"
              backgroundColorHover="colorSystemSuccessHover"
              onClick={() => openQuizContent()}
            >
              <Play color="colorCommonWhite" mr={1.2} size={1.3} />
              Start Quiz
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default FeedMicrotraining;
