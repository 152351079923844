import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const UserChecked = props => (
  <SvgIconStyled
    {...props}
    viewBox="0 0 17 17"
    role="img"
    aria-hidden="false"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12149_136409)">
      <path
        d="M5.71478 6.09087C6.12576 6.09571 6.53368 6.02054 6.91524 5.86965C7.29681 5.71875 7.64455 5.49509 7.9386 5.21144C8.23265 4.92779 8.46726 4.5897 8.62902 4.21648C8.79079 3.84326 8.87654 3.44222 8.88138 3.03627C8.87654 2.63031 8.79079 2.22927 8.62902 1.85605C8.46726 1.48283 8.23265 1.14474 7.9386 0.86109C7.64455 0.577438 7.29681 0.353779 6.91524 0.202886C6.53368 0.0519937 6.12576 -0.0231763 5.71478 -0.018334C4.88419 -0.0281215 4.0836 0.288026 3.48875 0.860717C2.8939 1.43341 2.5534 2.21584 2.54199 3.03627C2.54683 3.44275 2.63279 3.84429 2.79494 4.21791C2.95709 4.59153 3.19225 4.92989 3.48696 5.21363C3.78167 5.49736 4.13014 5.72089 4.51242 5.87142C4.8947 6.02195 5.30328 6.09652 5.71478 6.09087ZM5.71478 1.20351C5.96332 1.19865 6.2104 1.2422 6.4419 1.33168C6.6734 1.42117 6.88479 1.55482 7.06398 1.72502C7.24317 1.89521 7.38667 2.09862 7.48626 2.3236C7.58586 2.54859 7.6396 2.79076 7.64443 3.03627C7.6396 3.28178 7.58586 3.52394 7.48626 3.74893C7.38667 3.97392 7.24317 4.17732 7.06398 4.34751C6.88479 4.51771 6.6734 4.65137 6.4419 4.74085C6.2104 4.83033 5.96332 4.87389 5.71478 4.86903C5.46572 4.8747 5.21798 4.83175 4.98576 4.74263C4.75355 4.65352 4.54143 4.51999 4.36158 4.34971C4.18173 4.17944 4.03768 3.97576 3.93769 3.75037C3.83771 3.52497 3.78376 3.2823 3.77895 3.03627C3.79352 2.54257 4.00529 2.07463 4.36801 1.73466C4.73072 1.39469 5.2149 1.21032 5.71478 1.22183V1.20351Z"
        fill="#535353"
      />
      <path
        d="M8.18864 7.64261C8.64319 7.41932 9.13235 7.27271 9.63587 7.20886C9.37237 7.00075 9.08608 6.82247 8.78237 6.67736C8.61941 6.60294 8.44237 6.56338 8.26285 6.56128C7.95629 6.56207 7.66095 6.67528 7.43409 6.87896C7.36969 6.9363 7.30151 6.98937 7.23 7.0378C7.15565 7.09086 7.07705 7.13786 6.99498 7.17831L6.92076 7.21496L6.80325 7.26384L6.69811 7.3066L6.58678 7.34326H6.54967H6.51257C6.39682 7.3739 6.27901 7.39636 6.16003 7.41046H6.11674C5.99527 7.41933 5.87331 7.41933 5.75184 7.41046C5.65513 7.41781 5.55809 7.41984 5.46115 7.41657H5.39312H5.20758L4.954 7.35548L4.80557 7.3066H4.73754L4.63858 7.26384H4.60147L4.35408 7.11722C4.27935 7.06599 4.20902 7.00878 4.1438 6.94616C3.91316 6.73597 3.61028 6.62023 3.29648 6.62237C3.12651 6.62103 2.95808 6.6543 2.8017 6.72012C1.99754 7.07018 1.31352 7.64327 0.832658 8.36984C0.351799 9.09641 0.0947733 9.94523 0.0927734 10.8133C0.0927734 11.2443 0.2661 11.6576 0.574626 11.9624C0.883151 12.2671 1.3016 12.4383 1.73792 12.4383H5.91883C5.90004 12.0291 5.92909 11.6191 6.00541 11.2165H1.73792C1.62966 11.2165 1.52584 11.174 1.44929 11.0984C1.37273 11.0228 1.32973 10.9202 1.32973 10.8133C1.32943 10.183 1.51536 9.56635 1.86473 9.03895C2.21409 8.51154 2.71168 8.09632 3.29648 7.84421C3.40851 7.94251 3.52622 8.03431 3.64902 8.11913C3.7751 8.20586 3.90739 8.28344 4.04484 8.35128L4.1438 8.40626L4.29842 8.47346L4.40356 8.51012L4.64476 8.59564C4.76653 8.63335 4.89044 8.66395 5.01585 8.68728L5.27561 8.73005H5.41786C5.55393 8.73005 5.69617 8.73005 5.84461 8.73005C6.02462 8.73042 6.20443 8.71817 6.38269 8.69339C6.55572 8.6688 6.72713 8.63412 6.89602 8.58953H7.00735L7.15578 8.54066C7.4707 8.20642 7.84088 7.92753 8.25048 7.71592L8.18864 7.64261Z"
        fill="#535353"
      />
      <path
        d="M11.8191 8.02749C10.7288 8.01279 9.677 8.42577 8.89442 9.17589C8.11184 9.926 7.66232 10.952 7.64441 12.029C7.66232 13.106 8.11184 14.132 8.89442 14.8822C9.677 15.6323 10.7288 16.0452 11.8191 16.0305C12.3601 16.0354 12.8967 15.9349 13.3983 15.7349C13.8999 15.5349 14.3567 15.2392 14.7427 14.8648C15.1286 14.4904 15.4361 14.0446 15.6476 13.5527C15.8591 13.0609 15.9704 12.5328 15.9753 11.9985C15.9493 10.93 15.4989 9.91459 14.7212 9.17155C13.9435 8.42851 12.901 8.01756 11.8191 8.02749ZM11.8191 14.8087C11.0568 14.8235 10.3196 14.5392 9.76901 14.0182C9.2184 13.4972 8.89922 12.782 8.88136 12.029C8.89922 11.2761 9.2184 10.5608 9.76901 10.0398C10.3196 9.51882 11.0568 9.23458 11.8191 9.24933C12.5726 9.24087 13.2996 9.52361 13.845 10.0372C14.3904 10.5508 14.711 11.2546 14.7383 11.9985C14.7189 12.7477 14.4018 13.4592 13.8555 13.9794C13.3093 14.4995 12.5778 14.7864 11.8191 14.7782V14.8087Z"
        fill="#535353"
      />
      <path
        d="M12.6849 10.9721L11.5036 12.139L11.0274 11.6441C10.9099 11.5511 10.7604 11.5068 10.6104 11.5204C10.4605 11.5341 10.3217 11.6047 10.2234 11.7174C10.1093 11.8145 10.0378 11.9516 10.0239 12.0997C10.0101 12.2479 10.055 12.3955 10.1492 12.5116L11.0274 13.4219C11.1358 13.5254 11.2788 13.5863 11.4294 13.593C11.5062 13.611 11.5862 13.6104 11.6627 13.5913C11.7391 13.5721 11.8098 13.535 11.8686 13.483L13.5323 11.8335C13.6287 11.7188 13.6756 11.5712 13.6629 11.4227C13.6501 11.2741 13.5788 11.1364 13.4642 11.0393C13.3668 10.9331 13.2324 10.8672 13.0879 10.8547C12.9434 10.8423 12.7995 10.8842 12.6849 10.9721Z"
        fill="#535353"
      />
    </g>
    <defs>
      <clipPath id="clip0_12149_136409">
        <rect width="20" height="20" x={0} y={0} fill="white" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default UserChecked;
