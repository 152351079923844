import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 60px)',
    },
  },
  img: {
    width: '105px',
    height: 'auto',
    display: 'block'
  },
  text: {
    fontSize: '18px',
    color: 'var(--colorGreyLight1)',
    margin: '25px 0 0'
  }
}));

const NoNotifications = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <img src='/media/bell.svg' alt='Notification bell' className={classes.img} />
      <p className={classes.text}>You have no notifications</p>
    </div>
  );
};

export default NoNotifications;
