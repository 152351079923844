import { Link, Tooltip, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';
import { Link as RouterLink } from 'react-router-dom';

const ReceivedWelcomeMessageCell = ({ receiversPercent = 0, onClick, totalUsersCount }) => {
  if (isNA(receiversPercent)) return <Typography variant="body2">N/A</Typography>;
  if (receiversPercent === 100 ) return <Typography variant="body2" color="success.main">{`${receiversPercent}%`}</Typography>;

  if (!totalUsersCount)
    return (
      <Tooltip placement="top" title="There are no users in this account">
        <Typography variant="body2">{`${receiversPercent}%`}</Typography>
      </Tooltip>
    );


  const color = receiversPercent < 75 ? 'error' : 'success';

  return (
    <Tooltip title="Click here to configure sending Welcome Message" placement="top">
      <Link
        color={`${color}.main`}
        underline="hover"
        hoverColor="info.main"
        variant="body2"
        onClick={onClick}
        component='button'
      >
        {`${receiversPercent}%`}
      </Link>
    </Tooltip>
  );
};

ReceivedWelcomeMessageCell.propTypes = {
  receiversPercent: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ReceivedWelcomeMessageCell;
