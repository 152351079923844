import React from 'react';
import { Button as MuiButton } from '@material-ui/core';

import { Check, LeftArrow, RightArrow } from 'components/icons';
import { Button } from 'components/forms';

export const PreviousButton = props => {
  return (
    <MuiButton
      variant="outlined"
      style={{
        textTransform: 'none',
        color: 'var(--colorDefault)'
      }}
      {...props}
    >
      <LeftArrow /> &nbsp; Return To Previous
    </MuiButton>
  );
};

export const NextButton = props => {
  return (
    <Button
      backgroundColor="colorSystemSuccess"
      backgroundColorHover="colorSystemSuccessHover"
      backgroundDisabled="colorSystemSuccessDisable"
      height={40}
      {...props}
    >
      Next &nbsp; &nbsp; <RightArrow />
    </Button>
  );
};

export const SubmitButton = props => {
  return (
    <Button
      backgroundColor="colorSystemSuccess"
      backgroundColorHover="colorSystemSuccessHover"
      backgroundDisabled="colorSystemSuccessDisable"
      height={40}
      {...props}
    >
      <Check /> &nbsp; Submit
    </Button>
  );
};
