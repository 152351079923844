// @flow

import { createOpacity } from './mixins';
import { grey, red, common /* , yellow */ } from './colors';
import schemes from './schemes';

const themeName = 'winter';

// TODO: Change theme [themeName], summer, etc
const palette = {
  common,
  type: 'light',
  grey,
  contrastThreshold: 3,
  tonalOffset: 0.2,
  divider: createOpacity(0.12),
  primary: {},
  secondary: {},
  error: {},
  text: {},
  background: {},
  alt_bg: {},
  nav: {},
  sidebar: {},
  action: {}
};

palette.primary = {
  // Change [themeName]]. for other key scheme
  main: schemes[themeName].links,
  light: schemes[themeName].nav_links,
  dark: schemes[themeName].active_links,
  // main: grey[700],
  // light: grey[300],
  // dark: grey[900],
  contrastText: common.white
};

palette.secondary = {
  main: schemes[themeName].links,
  light: schemes[themeName].nav_links,
  dark: schemes[themeName].active_links,
  // main: grey[700],
  // light: grey[300],
  // dark: grey[800],
  contrastText: common.white
};

palette.error = {
  light: red[300],
  main: red[500],
  dark: red[700],
  contrastText: common.white
};

palette.text = {
  primary: schemes[themeName].body_copy,
  secondary: 'var(--colorDefault)',
  disabled: createOpacity(0.38),
  hint: createOpacity(0.38)
};

palette.background = {
  paper: 'var(--backgroundPaper)',
  default: schemes[themeName].background
};

palette.alt_bg = {
  default: schemes[themeName].alt_bg
};

palette.nav = {
  background: schemes[themeName].nav_bg,
  color: schemes[themeName].nav_copy,
  link: schemes[themeName].nav_links
};

palette.sidebar = {
  background: schemes[themeName].mid_gray,
  color: schemes[themeName].mid_gray,
  hover: schemes[themeName].mid_gray
};

palette.action = {
  disabled: 'var(--colorLight)'
}

export default palette;
