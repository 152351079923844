// @flow

const disasterRecovery = {
  title: 'Disaster Recovery Plan',
  component: 'clients/ClientsEditDisasterRecoveryModal',
  noQuery: true,
  idRequired: true,
  buttons: [],
  initialState: {
    name: '',
    description: '',
    date: new Date().toISOString().slice(0, 10),
    file: {},
    step: 0
  }
};

export default disasterRecovery;
