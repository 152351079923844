import { Box, Divider, Stack, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import { Switch } from 'components';

const Toolbar = ({ hasLegacyDocuments, legacyDocuments, onLegacyDocumentsChange }) => (
  <Stack>
    <Box px={2.5} py={1} display="flex" alignItems="center" justifyContent="space-between">
      <Typography py={1.5} variant="h4">Risk Assessment Reports</Typography>

      {hasLegacyDocuments && (
        <Switch
          label="Legacy Documents"
          value={Boolean(legacyDocuments)}
          checked={legacyDocuments}
          name="legacy_documents"
          onChange={({ target: { checked } }) => onLegacyDocumentsChange(checked)}
        />
      )}
    </Box>
    <Divider />
  </Stack>
);

export default Toolbar;
