// @flow
import React from 'react';
import { IconButton } from '@material-ui/core';
import { Container, Dialog, Typography, DateField, TextField, TimeField, Button, LazyIcon } from 'components';
import { CancelButton, SendButton } from '../style/AdminCommon';

type Props = {
  action: string,
  data: Object,
  onChange: Function,
  onClose: Function,
  onSubmit: Function,
  open: boolean,
  resource: string,
};

const EmailSettingsModal = ({ 
  action,
  data,
  open,
  onChange,
  onClose,
  onSubmit,
  resource,
  validateEmails,
  invalidEmails,
}: Props) => {

  return (
    <Dialog
      open={open || false}
      setOpen={onClose}
      onlyOk
      maxWidth="sm"
      variant="standard"
    >
      <Container.Grid direction="row" justify="space-between" alignItems="center" bb={1} pb={1}>
        <Typography.p bold m={0.2} fontSize={14}>Email Configuration Settings</Typography.p>
        <IconButton onClick={onClose}>
          <LazyIcon component="Close" color="colorDefault" size={1} />
        </IconButton>
      </Container.Grid>

      <Container.Grid container pt={4} bb={1} pb={4}>
        {action === 'configure' && (
          <>
            <Container.Grid item sm={12} md={8} pr={1}>
              <DateField
                label="Scheduled date"
                name="date"
                value={data?.date}
                fullWidth
                onChange={(e) =>  onChange('date', e)}
                minDate={new Date()}
              />
            </Container.Grid>
          
            <Container.Grid item sm={12} md={4} pl={1} pr={1}>
              <TimeField
                label="Time"
                name="time"
                value={data?.time}
                fullWidth
                onChange={(e) =>  onChange('time', e)}
              />
            </Container.Grid>

            <Container.Grid item sm={12} md={12} pt={2}>
              <Typography.p fontSize={14} color="colorLight">
                Note: If these is not entered, the mass email will be sent out within 30 minutes.
              </Typography.p>
            </Container.Grid>
          </>
        )} 
        {action === 'sendEmail' && (
          <Container.Grid item sm={12} md={12} lg={12}>
            <TextField
              label="Send to Recipients (separated by semicolon)"
              name="email"
              value={data?.email?.join(';')}
              fullWidth
              onChange={({ target: { name, value } }) => validateEmails(name, value)}
              error={invalidEmails}
              helperText={invalidEmails && "Please enter a valid email address"}
            />
          </Container.Grid>
        )}
      </Container.Grid>

      <Container.Grid container justify="flex-end" pt={2}>
        <CancelButton onClick={onClose} mr={1}>Cancel</CancelButton>
        
        {action === 'configure' && (
          <Button
            backgroundColor="colorSystemSuccess"
            backgroundColorHover="colorSystemSuccessHover"
            backgroundDisabled="colorSystemSuccessDisable"
            onClick={onSubmit}
          >
            Schedule
          </Button>
        )}
        {action === 'sendEmail' && (
          <SendButton onClick={onSubmit} disabled={!data?.email.length || invalidEmails}>
            <LazyIcon component="EmailSend" color="colorBox" mr={1}/>
            Send Now
          </SendButton>
        )}
      </Container.Grid>
    </Dialog>
  )
};

export default EmailSettingsModal;