// @flow

const clientsTrainingCertificateViewModal = {
  paramKey: 'training_id',
  title: 'View the Certificate',
  component: 'clients/ClientsTrainingCertificateViewModal',
  paramKey: 'training_id',
  requireRowId: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    other: [
      {
        label: 'Download',
        type: 'certificate',
        icon: 'Print'
      }
    ]
  },
  initialState: null
};

export default clientsTrainingCertificateViewModal;
