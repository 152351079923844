// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Warning = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path
      d="M10 20C4.48462 20 0 15.5154 0 10C0 4.48462 4.48462 0 10 0C15.5154 0 20 4.48462 20 10C20 15.5154 15.5154 20 10 20ZM10 1.53846C5.33077 1.53846 1.53846 5.33077 1.53846 10C1.53846 14.6692 5.33077 18.4615 10 18.4615C14.6692 18.4615 18.4615 14.6692 18.4615 10C18.4615 5.33077 14.6615 1.53846 10 1.53846Z"
      fill={props.color}
    />
    <path
      d="M9.27678 10.6539L9.06912 7.50771C9.03066 6.89232 9.00757 6.45386 9.00757 6.18463C9.00757 5.82309 9.09987 5.53848 9.29218 5.33078C9.48449 5.13078 9.73063 5.02309 10.046 5.02309C10.4229 5.02309 10.6768 5.15386 10.7999 5.4154C10.9307 5.67694 10.9922 6.05386 10.9922 6.54617C10.9922 6.83848 10.9768 7.13078 10.946 7.43078L10.6614 10.6692C10.6307 11.0539 10.5614 11.3539 10.4614 11.5539C10.3614 11.7616 10.1922 11.8616 9.96141 11.8616C9.72295 11.8616 9.5614 11.7616 9.46909 11.5616C9.38447 11.3616 9.31524 11.0616 9.27678 10.6539ZM10.0076 14.9769C9.73833 14.9769 9.50758 14.8923 9.30758 14.7154C9.10758 14.5462 9.00757 14.3 9.00757 13.9923C9.00757 13.7231 9.09987 13.4923 9.29218 13.3C9.48449 13.1077 9.71524 13.0154 9.99216 13.0154C10.2691 13.0154 10.5076 13.1077 10.6999 13.3C10.8922 13.4923 10.9922 13.7231 10.9922 13.9923C10.9922 14.3 10.8922 14.5385 10.6999 14.7154C10.4999 14.8846 10.2691 14.9769 10.0076 14.9769Z"
      fill={props.color}
    />
  </SvgIconStyled>
);

export default Warning;
