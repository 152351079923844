import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useId } from 'hooks';
import { dataProvider, hasKey, trackingUtils } from 'helpers';
import TrainingCard from 'components/trainings/TrainingCard';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { ContainerTitle } from 'apps/partner/ComponentTypes';
import { InfoButtons, ActionButtons } from 'apps/partner/shared';
import classes from './styles/cardStyles.module.css';
import PackageButton from './PackageButton';
import { LoadingStyled, Container, Typography, Notification, Success } from 'components';
import * as testAuthoringSystem from 'helpers/apis/services/testAuthoringSystem';
import { useNotify } from 'ra-core';
import { ConfirmModal } from 'apps/admin/components';
import { Stack, Alert } from '@trustsecurenow/components-library';

const getToolTipText = course => {
  // eslint-disable-next-line no-nested-ternary
  const text = course.trainings.length
    ? course.display_name.toLowerCase().includes('microsoft')
      ? 'The Microsoft Training package includes courses on core products like Teams, PowerPoint, Word, Excel, and Outlook, along with a Microsoft Cybersecurity course. Additionally, it provides access to Nanos©, which deliver ongoing productivity content through the Newsfeed.'
      : `This course package includes ${course.trainings.length} courses:\n${course.trainings
          .map((course, index) => `${index + 1} - ${course.training_title}`)
          .join('\n')}. \n Courses will be distributed to clients dependent on product type and access level`
    : `This course package has no courses`;
  return text;
};

const getClient = (app, clientId) => (['myCompany', 'clients'].includes(app) ? clientId : null);

const Dashboard = props => {
  const { isCofigureTab } = props;
  const { app, item } = useLocation('clientId');
  const partner_id = useId({ key: 'partnerId' });
  const notify = useNotify();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setClientName] = useState('');
  const [notification, setNotification] = useState({
    open: false,
    text: ''
  });
  const fetchTrainings = useCallback(async () => {
    setIsLoading(true);
    const res = await testAuthoringSystem.configurations(partner_id, getClient(app, item));
    setData(res?.data?.trainings);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (app !== 'clients') return;
    dataProvider.getOne('clients', `company/${item}`).then(resp => {
      if (typeof resp === 'object' && hasKey(resp, 'data')) setClientName(resp.data.name);
    });
  }, [app, item]);

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ open: false });
  };

  useEffect(() => {
    fetchTrainings();
  }, [fetchTrainings]);

  if (isLoading || !data) {
    return <LoadingStyled />;
  }

  const handlePackageButton = (packageId, releaseDate, futurePublication) => {
    if (releaseDate === null) {
      return testAuthoringSystem.unpublish(packageId, partner_id, getClient(app, item));
    }

    trackingUtils.customEvent('completed_subtask', {
      sendUserName: true,
      sendPartnerName: true,
      label: 'Set release date'
    });

    return testAuthoringSystem.setReleaseDate(packageId, releaseDate, partner_id, futurePublication, getClient(app, item));
  };

  return (
    <>
      <Stack spacing={2.5}>
        {isCofigureTab && (
          <>
            <Container.Paper mt={2} radius={1}>
              <Container.Grid spacing={4}>
                <Container.Grid item direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ContainerTitle noMargin noBorder>
                    <Typography.h6 mt={0.1} mb={0.1}>
                      Training Configuration
                    </Typography.h6>
                    <InfoButtons name="training_configuration" />
                  </ContainerTitle>
                </Container.Grid>
              </Container.Grid>
            </Container.Paper>
            <Alert sx={{ textTransform: 'none' }}>
             Configure your default training settings below; these settings can be customized on a per-client basis using the ‘Manage Clients’ application. Note: Some training courses may not be available for all product types.
            </Alert>
          </>
        )}

        <ConditionalWrapper
          condition={isCofigureTab}
          wrapper={children => (
            <>
              {children}
            </>
          )}
        >
          <div className={classes.card_container}>
            {data.map(course => (
              <TrainingCard
                key={course.package_id}
                courseId={course.package_id}
                description={course.tile_display_name}
                duration={course.total_duration}
                img={course.image}
                showProgress={false}
                additionalText={'Duration'}
                trainingDetails={getToolTipText(course)}
                PackageButton={
                  <PackageButton
                    training_year={course.training_official_year}
                    editable={course.is_editable}
                    packageId={course.package_id}
                    publish={course.release_date}
                    handlePackageButton={handlePackageButton}
                    description={course.tile_display_name}
                    trainingFuturePublication={course.adjust_future_publication}
                    refetchTrainings={fetchTrainings}
                    setNotification={setNotification}
                    unpublishText={course.unpublish_text}
                    allowExcludedClients={course.allow_excluded_clients}
                    packageName={course.display_name}
                    configureText={course.configure_text}
                    isAnnual={course.is_annual}
                    preventFuturePublication={course.prevent_future_publication}
                    defaultReleaseDate={course.default_release_date}
                  />
                }
              />
            ))}
          </div>
        </ConditionalWrapper>
      </Stack>
      <Notification
        text={notification.text}
        Icon={Success}
        open={notification.open}
        onClose={handleCloseNotification}
        autoHideDuration={5000}
      />

      {isCofigureTab && <ActionButtons />}
    </>
  );
};

export default Dashboard;