// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Newsfeed = (props: Props) => (
  <svg width="19" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1474 3.89206L12.8074 3.88589V1.85042C12.8074 0.826523 11.9799 0 10.9548 0H1.85257C0.833655 0 0 0.826523 0 1.85042V14.1496C0 15.1673 0.833655 16 1.85257 16H10.9548C11.1833 16 11.3933 15.9568 11.5971 15.8828C11.7082 15.852 11.8317 15.8088 11.9861 15.7594C14.3389 14.9391 16 12.6446 16 10.0046V5.74248C16 4.71858 15.1663 3.89206 14.1474 3.89206ZM1.23504 14.1496V1.85042C1.23504 1.51118 1.51293 1.23362 1.85257 1.23362H10.9548C11.3007 1.23362 11.5724 1.51118 11.5724 1.85042V14.1496C11.5724 14.3099 11.5106 14.4518 11.418 14.5628C11.3007 14.6862 11.1401 14.7664 10.9548 14.7664H1.85257C1.51293 14.7664 1.23504 14.4888 1.23504 14.1496ZM14.765 10.0046C14.765 11.6022 13.9992 13.0208 12.8074 13.9152V5.11334L14.1474 5.11951C14.4871 5.11951 14.765 5.39707 14.765 5.73631V10.0046Z"
      fill="white"
    />
    <path
      d="M5.16249 6.08173H4.00155C2.98264 6.08173 2.14899 6.91442 2.14899 7.93215V10.788C2.14899 11.8057 2.98264 12.6384 4.00155 12.6384H5.16249C6.18141 12.6384 7.01506 11.8057 7.01506 10.788V7.93215C7.01506 6.91442 6.18141 6.08173 5.16249 6.08173ZM5.78002 10.788C5.78002 11.1272 5.50213 11.4048 5.16249 11.4048H4.00155C3.66192 11.4048 3.38403 11.1272 3.38403 10.788V7.93215C3.38403 7.59291 3.66192 7.31534 4.00155 7.31534H5.16249C5.50213 7.31534 5.78002 7.59291 5.78002 7.93215V10.788Z"
      fill="white"
    />
    <path
      d="M10.0409 6.0694H8.27481C7.93517 6.0694 7.65729 6.34696 7.65729 6.68621C7.65729 7.02545 7.93517 7.30301 8.27481 7.30301H10.0409C10.3806 7.30301 10.6584 7.02545 10.6584 6.68621C10.6584 6.34696 10.3867 6.0694 10.0409 6.0694Z"
      fill="white"
    />
    <path
      d="M10.0409 11.3863H8.27481C7.93517 11.3863 7.65729 11.6639 7.65729 12.0031C7.65729 12.3423 7.93517 12.6199 8.27481 12.6199H10.0409C10.3806 12.6199 10.6584 12.3423 10.6584 12.0031C10.6584 11.6639 10.3867 11.3863 10.0409 11.3863Z"
      fill="white"
    />
    <path
      d="M8.26242 9.97995H10.0285C10.3682 9.97995 10.6461 9.70239 10.6461 9.36315C10.6461 9.0239 10.3682 8.74634 10.0285 8.74634H8.26242C7.92278 8.74634 7.6449 9.0239 7.6449 9.36315C7.6449 9.70239 7.92278 9.97995 8.26242 9.97995Z"
      fill="white"
    />
    <path
      d="M10.0409 3.3801H2.76651C2.42687 3.3801 2.14899 3.65766 2.14899 3.9969C2.14899 4.33615 2.42687 4.61371 2.76651 4.61371H10.0471C10.3867 4.61371 10.6646 4.33615 10.6646 3.9969C10.6646 3.65766 10.3867 3.3801 10.0409 3.3801Z"
      fill="white"
    />
  </svg>
);

export default Newsfeed;
