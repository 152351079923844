// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Email2 = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.5636 18.4464C23.6286 18.3443 23.6843 18.2422 23.7308 18.1308C23.7493 18.0843 23.7772 18.0379 23.7957 17.9915C23.8422 17.8708 23.8793 17.7501 23.9164 17.6201C23.9257 17.583 23.935 17.5458 23.9443 17.4994C23.9814 17.3323 24 17.1559 24 16.9702V6.7853C24 5.25338 22.7466 4 21.2147 4H2.7853C1.25338 4 0 5.25338 0 6.7853V16.9702C0 18.5021 1.25338 19.7555 2.7853 19.7555H10.5191H21.2147C21.4654 19.7555 21.7068 19.7091 21.9296 19.6534L21.9389 19.6627C22.0039 19.6441 22.0689 19.6163 22.1246 19.5977C22.1988 19.5698 22.2638 19.5513 22.3381 19.5234C22.4867 19.4584 22.6352 19.3841 22.7652 19.2913C22.7745 19.282 22.793 19.2727 22.8023 19.2634C22.9323 19.1799 23.0437 19.0778 23.1551 18.9756C23.1923 18.9478 23.2201 18.9106 23.248 18.8735C23.3315 18.7807 23.4058 18.6878 23.4801 18.5857C23.5079 18.53 23.5358 18.4836 23.5636 18.4464ZM20.9826 5.85687L12.1439 14.7048C12.1161 14.7327 12.0511 14.7234 12.0232 14.6956L3.18453 5.85687H20.9826ZM2.7853 17.8986C2.27466 17.8986 1.85687 17.4809 1.85687 16.9702V7.15667L10.7048 16.0046C10.9555 16.2553 11.2619 16.4317 11.5868 16.506C11.754 16.5617 11.9211 16.5803 12.0882 16.5803C12.1346 16.5803 12.1903 16.5803 12.2368 16.5617C12.6824 16.5431 13.1188 16.3574 13.453 16.0139L22.1431 7.33308V16.9702C22.1431 17.4809 21.7253 17.8986 21.2147 17.8986H2.7853Z" />
  </SvgIconStyled>
);

export default React.memo(Email2);
