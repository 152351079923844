// @flow

const newAutophishCampaign = {
  title: 'Add Autophish Campaign',
  component: 'shared/phishingCampaign/PhishingCampaign',
  noQuery: true,
  buttons: {},
  initialState: {
    data: {
      frequency: 1,
      name: '',
      date_start: '',
      date_end: '',
      recur: 'Monthly',
      campaign_time: '',
      fixed_time: false,
      scenarios_list: ['all'],
      scenarios_list_excluded: [],
      scenarios_filter: {},
      scenarios_include_new: false,
      reuse_campaign: false,
      recipients_list: ['all'],
      recipients_list_exclude: [],
      recipients_filter: {},
      recipients_include_new: false,
      easy: 1,
      medium: 1,
      difficult: 1,
      notify: true,
      email_notification: '',
      white_listing_acknowledgement: false
    },
    type: 'auto',
    scenarios_total: 0,
    recipients_total: 0,
    step: 0,
    createApi: 'autoPhishCampaign'
  }
};

export default newAutophishCampaign;
