import styled from 'styled-components';

export const WhiteBox = styled.div`
  max-width: 576px;
  width: calc(100% - 40px);
  margin: 0 auto;
  background-color: var(--backgroundPaper);
  padding: 24px 15px 26px;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const QuizVideoButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuizVideoButton = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({disabled}) => disabled ? "0.5" : "1" };
  
  span {
    font-size: 14px;
    color: ${({disabled}) => disabled ? "var(--colorLight)" : "var(--colorDark)" };
  }

  .quiz-paginate-buttons {
    path {
      fill: ${({disabled}) => disabled ? "var(--colorLight)" : "var(--colorDark)" };
    }
  }

  .prev-button {
    transform: rotate(270deg);
    margin-right: 5px;
  }

  .next-button {
    transform: rotate(-270deg);
    margin-left: 5px;
  }
`;