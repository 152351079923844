import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'hooks';
import { PAGES } from './pages';
import { SRAProvider } from './context/sraContext';

const SRANestedSubPage = () => {
  // this component is only entered on SRA edit mode
  const { page, subpage } = useParams();
  const history = useHistory();
  const { app, tab, item } = useLocation();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const subpageComponent = PAGES[page]?.subpages?.[subpage].component;

  useEffect(() => {
    const hasAppAccess = accessApps?.hasOwnProperty(app);
    const hasTabAccess = accessApps?.[app]?.[tab];
    const access_token = localStorage.getItem('accessToken');

    const noAccessToEditPage = !sessionStorage.getItem('accessEditSRA') && page === 'editSraReport';

    // Redirect to Dashboard page
    if ((accessApps && (!hasAppAccess || !hasTabAccess)) || !access_token) {
      history.push('/myDashboard/dashboard');
    }

    // Redirect to SRA page
    if ((page && !subpageComponent) || noAccessToEditPage) {
      history.push(`/${app}/${tab}${item && item !== page ? `/${item}` : ''}`);
    }
  }, [app, tab, item, accessApps, history, page, subpageComponent]);

  return <SRAProvider>{page && subpageComponent ? subpageComponent : null}</SRAProvider>;
};

export default SRANestedSubPage;
