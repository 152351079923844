// @flow

const otherDocuments = {
  title: 'Other Contracts & Documents',
  component: 'clients/ClientsEditOtherDocumentsModal',
  noQuery: true,
  buttons: [],
  initialState: {
    name: '',
    description: '',
    date: new Date().toISOString().slice(0, 10)
  }
};

export default otherDocuments;
