// TABLES
export const TRAINING_ANNUAL = "trainingAnnual"
export const TRAINING_USERS = "trainingUsers"
export const TRAINING_TRAININGS = "trainingTrainings"

// PAGES
export const ANNUAL = "annual"
export const MICRO_TRAINING = "micro-training"

// MODALS
export const TRAINING_TRAININGS_USER = "trainingTrainingsUsers"
export const TRAINING_USERS_TRAININGS = "trainingUsersTrainings"

// REPORT TYPES
export const FULL_TRAINING = "FULL_TRAINING"
export const SELECTED_TRAINING = "SELECTED_TRAINING"
