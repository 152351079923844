import React from 'react';
import { Container, CustomTable, CustomTooltip, Dialog, LazyIcon, TableCellField, Typography } from 'components';
import { IconButton } from '@material-ui/core';
import { TableCellText, ActionsContainer } from '../../style/AdminCommon';
import { useAdminList, useMaskPartner, useAdminNavigate } from '../../hooks';
import ManagePartnersToolbar from './ManagePartnersToolbar';

const ActiveCell = React.memo(({ active }) => {
  return (
    <TableCellText>
      {active ? (
        <LazyIcon component="Enable" color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.1} mr={1.1} />
      ) : (
        <LazyIcon component="CloseCircle" color="colorSystemDanger" strokeColor="colorSystemDanger" ml={1.1} mr={1.1} />
      )}
    </TableCellText>
  );
});

const ManagePartners = () => {
  const { filters, isLoading, isFetching, data, dispatch, total, page, perPage, openDialog } = useAdminList('partners');
  const { navigateToEdit, navigateToCreate, navigateToConflict } = useAdminNavigate();
  const { enableMaskMode } = useMaskPartner();

  return (
    <>
      <CustomTable
        toolbar={
          <ManagePartnersToolbar
            setSearchValue={dispatch.search}
            setFilters={dispatch.filters}
            navigateToCreate={navigateToCreate}
          />
        }
        source={'admin/partners'}
        total={total}
        data={data}
        loading={isLoading}
        isFetching={isFetching}
        hideColumns={!(filters.client || filters.user) && ['client_name']}
        onSort={sortValue => dispatch.setSort(sortValue)}
        onChangePage={page => dispatch.setPage(page)}
        onChangePerPage={perPage => dispatch.setPerPage(perPage)}
        onLoad={params => dispatch.onLoad(params)}
        preferences={{
          app: 'admin',
          tab: 'managePartners'
        }}
        params={{
          page,
          perPage
        }}
      >
        <TableCellField
          renderComponent={({ name, client_name }) => (
            <TableCellText>{name}</TableCellText>
          )}
        />
        
        <TableCellField
          hideCell={!(filters.client || filters.user)}
          renderComponent={({ client_name, user_email, user_first_name, user_last_name, user_group_role }) => (
            <>
              <TableCellText>{client_name}</TableCellText>
              {filters.user && user_email && (
                <Container.Grid container direction="row" alignItems="center">
                  <TableCellText pr={1}>{user_email}</TableCellText>
                  <CustomTooltip
                    arrow
                    light
                    right
                    title={
                      <Container.Grid container direction="column" justifyContent="flex-start" p={1}>
                        <Typography.p bold fontSize={12} mb={0.1} mt={0.4}>First Name</Typography.p>
                        <Typography.p fontSize={12} mt={0.1}>{user_first_name}</Typography.p>

                        <Typography.p bold fontSize={12} mb={0.1} mt={0.4}>Last Name</Typography.p>
                        <Typography.p fontSize={12} mt={0.1}>{user_last_name}</Typography.p>

                        <Typography.p bold fontSize={12} mb={0.1} mt={0.4}>Group Role</Typography.p>
                        <Typography.p fontSize={12} mt={0.1}>{user_group_role}</Typography.p>
                      </Container.Grid>
                    }
                  >
                    <LazyIcon component='Info' color="colorDefault" size={1}/>
                  </CustomTooltip>
                </Container.Grid>
              )} 
            </>
          )}
        />

        <TableCellField renderComponent={({ email }) => <TableCellText>{email}</TableCellText>} />

        <TableCellField renderComponent={({ client_count }) => <TableCellText>{client_count}</TableCellText>} />

        <TableCellField renderComponent={({ users_count }) => <TableCellText>{users_count}</TableCellText>} />

        <TableCellField renderComponent={({ distributor }) => <TableCellText>{distributor}</TableCellText>} />

        <TableCellField renderComponent={({ ebpp }) => <ActiveCell active={ebpp} />} />

        <TableCellField renderComponent={({ date_created }) => <TableCellText>{date_created}</TableCellText>} />

        <TableCellField
          renderComponent={({ active }) =>
            active ? (
              <LazyIcon
                component="Enable"
                color="colorSystemSuccess"
                strokeColor="colorSystemSuccess"
                ml={1.1}
                mr={1.1}
              />
            ) : (
              <LazyIcon
                component="Disable"
                color="colorSystemDanger"
                strokeColor="colorSystemDanger"
                ml={1.1}
                mr={1.1}
              />
            )
          }
        />

        <TableCellField renderComponent={({ account }) => <TableCellText>{account}</TableCellText>} />

        <TableCellField
          renderComponent={(data) => (
            <ActionsContainer>
              <Container.Grid alignItems="center">
                <CustomTooltip title="Mask Partner" placement="top">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      enableMaskMode(data, filters);
                    }}
                  >
                    <LazyIcon component="DarkWebMan" color="colorDefault" />
                  </IconButton>
                </CustomTooltip>

                <CustomTooltip title="Edit Partner" placement="top">
                  <IconButton
                    disabled={isFetching}
                    onClick={e => {
                      e.stopPropagation();
                      navigateToEdit(data.id);
                    }}
                  >
                    <LazyIcon component="EditIcon" color="colorDefault" />
                  </IconButton>
                </CustomTooltip>

                {data.pending ? (
                  <CustomTooltip title="Conflict" placement="top">
                    <IconButton
                      disabled={isFetching}
                      onClick={e => {
                        e.stopPropagation();
                        navigateToConflict(data.id);
                      }}
                    >
                      <LazyIcon component="Conflict" color="colorSystemDanger" />
                    </IconButton>
                  </CustomTooltip>
                ) : null}

                {!data.active && (
                  <CustomTooltip title="Delete Partner" placement="top">
                    <IconButton
                      disabled={isFetching}
                      onClick={e => {
                        e.stopPropagation();
                        dispatch.setOpenDialog(true, data.id);
                      }}
                    >
                      <LazyIcon component="Delete" color="colorDefault" />
                    </IconButton>
                  </CustomTooltip>
                )}
              </Container.Grid>
            </ActionsContainer>
          )}
        />
      </CustomTable>

      <Dialog
        title="Delete"
        message="This Partner will be deleted and cannot be recovered. Are you sure about that?"
        buttonSubmit="Yes, I want to delete"
        open={openDialog || false}
        setOpen={dispatch.setOpenDialog}
        maxWidth="xs"
        onSubmit={dispatch.delete}
      />
    </>
  );
};

export default ManagePartners;
