// @flow
import React, { useState } from 'react';
import { makeStyles, Box, Menu, MenuItem, Chip } from '@material-ui/core';
import { ArrowDown } from 'components/icons';
import { getAudiencePreview, getRolePreview } from '../../newsfeedHelpers';
import { AUDIENCES } from 'apps/newsfeed/constants';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: 14,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'var(--backgroundDefault)'
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: 10,
        transform: props => `rotate(${props.open ? 180 : 0}deg)`
      }
    }
  },
  menuLink: {
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    },
    '& svg': {
      marginRight: 12,
      fontSize: 20
    }
  }
}));

const AudienceSelect = ({ audienceData, setView }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ open: Boolean(anchorEl) });

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelect = view => {
    setAnchorEl(null);
    setView(view);
  };

  return (
    <Box ml={1.5}>
      <Chip
        variant="outlined"
        label={
          <>
            Select <ArrowDown size={1} ml={0.5} color="colorDefault" />
          </>
        }
        className={classes.chip}
        onClick={openMenu}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => onSelect('audience')} className={classes.menuLink}>
          {getAudiencePreview(audienceData).icon}
          {getAudiencePreview(audienceData).text}
        </MenuItem>
        <MenuItem
          onClick={() => onSelect('roles')}
          className={classes.menuLink}
          disabled={audienceData?.audience?.name === AUDIENCES.partner}
        >
          {getRolePreview(audienceData).icon}
          {getRolePreview(audienceData).text}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AudienceSelect;
