import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { buildQueryString } from 'helpers/utils';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = process.env.REACT_APP_BASE_URL_4;
const baseURL = `https://${apiGWID}.${base}/${env}/`;

const cognito = {};

cognito.checkFederatedLogin = userEmail => {
  const requestOptions = {
    url: `${baseURL}identity_provider`,
    params: { user_email: userEmail }
  };
  return apiClient.get(requestOptions);
};

cognito.verifyTokenExists = (user_token) => {
  const requestOptions = {
    url: `${baseURL}/user/verify_token_exists/${user_token}`
  };
  return apiClient.get(requestOptions);
};

cognito.resetUserPassword = (password, token) => {
  const requestOptions = {
    url: `${baseURL}user/reset_password`,
    data: { password, token }
  };
  return apiClient.post(requestOptions);
};

cognito.sendForgotPasswordEmail = email => {
  const requestOptions = {
    url: `${baseURL}user/forgot_password`,
    data: { email }
  };
  return apiClient.post(requestOptions);
};

cognito.registerUser = newUserPayload => {
  const requestOptions = {
    url: `${baseURL}user/register`,
    data: newUserPayload
  };
  return apiClient.post(requestOptions);
};

/**
 * takes accessToken as an optional param
 */
cognito.logout = ({ accessToken }) => {
  const requestOptions = {
    url: `${baseURL}user/logout`,
    data: { access_token: accessToken }
  };
  return apiClient.post(requestOptions);
};

cognito.authorize = () => {
  const id_token = getStorage('idToken', true);
  const refresh_token = getStorage('refreshToken', true);
  const access_token = getStorage('accessToken', true);
  const requestOptions = {
    url: `${baseURL}user/authorize2`,
    data: { id_token, refresh_token, access_token }
  };
  return apiClient.post(requestOptions);
};

cognito.registerExternalUser = ({ body, queryParams }) => {
  const queryString = buildQueryString(queryParams);

  return apiClient.post({
    url: `${baseURL}external_users/register/${queryString}`,
    data: body
  });
};

cognito.sendVerificationCode = data => {
  const requestOptions = {
    url: `${baseURL}external_users/send_verification_code/`,
    data
  };
  return apiClient.post(requestOptions);
};

cognito.changePhoneNumber = data => {
  const requestOptions = {
    url: `${baseURL}external_users/phone_number/`,
    data
  };
  return apiClient.patch(requestOptions);
};

cognito.preassessmentLogin = data => {
  const requestOptions = {
    url: `${baseURL}external_users/login/`,
    data
  };
  return apiClient.post(requestOptions);
};

cognito.getExternalUserData = (searchQuery = '') => {
  return apiClient.get({
    url: `${baseURL}external_users/${searchQuery}`
  });
};

cognito.updateExternalUser = data => {
  const requestOptions = {
    url: `${baseURL}external_users/`,
    headers: {
      Authorization: sessionStorage.getItem('quizToken')
    },
    data
  };
  return apiClient.patch(requestOptions);
};

cognito.createJobTitle = data => {
  return apiClient.post({
    url: `${baseURL}job-titles/`,
    data
  });
};

cognito.getJobTitles = queryParams => {
  const queryString = buildQueryString(queryParams);

  return apiClient.get({
    url: `${baseURL}job-titles/${queryString}`
    // headers: {
    //   Authorization:
    // },
  });
};

export default cognito;
