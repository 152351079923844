const integrationsApisKeys = {
  defaultSortValue: {
    sortname: 'friendly_name',
    order: 'asc'
  },
  sort: ['friendly_name', 'date_created'],
  head: [
    {
      id: 'friendly_name',
      label: 'Friendly Name',
      align: 'left'
    },
    {
      id: 'date_created',
      label: 'Created',
      align: 'left'
    },
    {
      id: 'delete',
      label: 'Delete',
      align: 'left'
    }
  ]
};

export default integrationsApisKeys;
