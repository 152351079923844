// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ArrowDown = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
      <g clip-path="url(#clip0_2244:14961)">
      <path d="M1.00002 6.96015C1.00002 6.69127 1.11526 6.432 1.32652 6.23994C1.72983 5.89424 2.33481 5.93265 2.68051 6.32637L11.9952 16.9471L21.3195 6.32637C21.6652 5.92305 22.2798 5.88464 22.6735 6.23994C23.0672 6.58564 23.1153 7.20022 22.76 7.59394L13.4452 18.2146C13.0803 18.6275 12.5522 18.8676 12.0048 18.8676C11.4575 18.8676 10.9293 18.6275 10.5644 18.2146L1.24969 7.59394C1.07684 7.41148 1.00002 7.18102 1.00002 6.96015Z" />
      </g>
      <defs>
      <clipPath id="clip0_2244:14961">
      <rect width="12.8677" height="22" transform="translate(23 6) rotate(90)"/>
      </clipPath>
      </defs>
  </SvgIconStyled>
);


export default ArrowDown;
