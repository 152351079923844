import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ContentAdminFormSubmitButton, LandingPagesModalContainer } from '../../style';
import { Link, RHFTextField as TextField } from 'components';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { useFormContext } from 'react-hook-form';
import { useNotify } from 'react-admin';
import { validSecureURL } from '../../../../helpers';

const LandingPagesModal = ({ onClose = () => {}, onDisabled = () => {}, disabled = false }) => {
  const { setValue } = useFormContext();
  const [url, setUrl] = useState('');

  const notify = useNotify();

  const handleChange = e => {
    const { value } = e.target;

    if (!value || !validSecureURL(value)) {
      onDisabled(true);
    } else {
      onDisabled(false);
    }

    setUrl(value);
  };

  const handleSubmit = async () => {
    if (!url) return;

    onDisabled(true);
    try {
      const readWebSiteData = await contentAdmin.readWebsite(url);
      const { content } = readWebSiteData.data;
      setValue('html', content.replaceAll('<form ', `<form onsubmit="event.preventDefault();"`));
      onClose();
      onDisabled(false);
    } catch (e) {
      notify(e?.response?.data?.message, 'warning');
      onClose();
      onDisabled(false);
    }
  };

  return (
    <LandingPagesModalContainer>
      <div className={'modal-subtitle'}>Enter a URL with "https" and Import the link</div>
      <TextField
        name="url"
        placeholder="URL"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link className={'icon'} />
            </InputAdornment>
          )
        }}
        fullWidth
        required
        onChange={handleChange}
        customLabel={{
          label: 'Required',
          position: 'right bottom'
        }}
      />
      <ContentAdminFormSubmitButton
        className={'submit-button'}
        disabled={disabled || !url}
        fullwidth
        onClick={handleSubmit}
      >
        Import
      </ContentAdminFormSubmitButton>
    </LandingPagesModalContainer>
  );
};

export default LandingPagesModal;
