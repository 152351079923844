import React from 'react';
import PropTypes from 'prop-types';
import { SvgIconStyled } from './ComponentTypes';

const Reload = props => (
  <SvgIconStyled {...props} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0212 13.7484C17.6276 13.5786 17.1723 13.7561 17.0025 14.142C15.4048 17.7849 11.1445 19.4443 7.517 17.8544C3.88181 16.2568 2.23015 11.9964 3.82778 8.35348C5.42542 4.71057 9.68578 3.04348 13.3133 4.64111C13.3596 4.65655 13.3981 4.65655 13.4444 4.67199L11.1213 5.67532C10.7277 5.84512 10.5502 6.30049 10.72 6.69411C10.8126 6.88706 10.9592 7.0337 11.1445 7.10316C11.3297 7.18034 11.5381 7.18035 11.7388 7.09545L14.9495 5.71392L15.6595 5.4052C16.0531 5.2354 16.2307 4.78003 16.0609 4.38641L15.7521 3.67636L14.3706 0.465652C14.2008 0.0720327 13.7455 -0.105474 13.3518 0.0643231C12.9582 0.23412 12.7807 0.689478 12.9505 1.0831L13.8612 3.20556C9.46966 1.32236 4.34489 3.33677 2.40767 7.73605C0.462724 12.1585 2.47712 17.3296 6.88412 19.2668C8.01867 19.7684 9.20724 20 10.3804 20C13.7454 20 16.9639 18.0396 18.4071 14.7594C18.5924 14.3813 18.4149 13.9259 18.0212 13.7484Z"
      fill={props.color}
    />
  </SvgIconStyled>
);

Reload.propTypes = {
  color: PropTypes.string
};

Reload.defaultProps = {
  color: ''
};

export default Reload;
