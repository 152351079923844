// @flow
import React, { type Element } from 'react';
import { DashboardProvider } from './DashboardContext';
import DashboardContent from './DashboardContent';

const Dashboard = (props: Object): Element<*> => {
  return (
    <DashboardProvider {...props}>
      <DashboardContent {...props} />
    </DashboardProvider>
  );
};

export default Dashboard;
