// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { theme } from 'conf';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Stepper as StepperMui,
  FormControl as MuiFormControl
} from '@material-ui/core';
import { SimpleForm as RaSimpleForm } from 'react-admin';

export const GridContainer: ComponentType<*> = styled(Grid).attrs({
  container: true,
  spacing: 24,
  alignItems: 'stretch'
})`
  padding: 0;
  margin-top: 0;
`;

export const GridItem: ComponentType<*> = styled(Grid).attrs({
  item: true
})`
  margin-bottom: 0;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
`;

export const BoxDashboardTable: ComponentType<*> = styled(Paper).attrs({
  elevation: 1
})`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? '12px' : '0')};
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const BoxButtonLink: ComponentType<*> = styled(Button)`
  text-transform: capitalize;
  width: 150px;
  /* margin: 0 0 5px 5px; */
`;

export const ButtonLink: ComponentType<*> = styled(Button)`
  text-transform: capitalize;
  color: ${theme.palette.secondary.main};
`;

export const FieldText: ComponentType<*> = styled(TextField)`
  margin: 10px 1px;
`;

export const FieldTextWrapper: ComponentType<*> = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper: ComponentType<*> = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const BoxWidgetWrapper: ComponentType<*> = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding: var(--spacing) calc(var(--spacing) * 2);
  color: var(--colorDefault);
  background-color: var(--backgroundPaper);
  border-radius: 10px;
  margin: 0 0 var(--spacing);
  min-height: 92px;
  margin-left: ${({ right }) => (right ? 'var(--spacing)' : '0')};
`;

export const Stepper: ComponentType<*> = styled(({ color, ...props }) => (
  <StepperMui
    {...props}
    classes={{
      root: `steperRoot`
    }}
  />
))`
  &.steperRoot {
    background-color: var(--backgroundDefault);
    padding: 24px 12px 12px 12px;
  }
`;

export const SimpleForm: ComponentType<*> = styled.form`
  /* && {
    > div:nth-last-child(2) {
      display: none;
    }
  } */
`;

export const FormControl: ComponentType<*> = styled(MuiFormControl)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;
