// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DateField } from 'components';
import { CalendarFile } from 'components/icons';

const useStyles = makeStyles(theme => ({
  dateWrap: {
    position: 'relative',
    zIndex: 0,
    width: 133,
    [theme.breakpoints.down('sm')]: {
      width: 125
    },
    '& input': {
      padding: '8px 30px 8px 14px',
      fontSize: 14
    },
    '& > svg': {
      position: 'absolute',
      right: 12,
      top: '50%',
      marginTop: -8,
      zIndex: -1
    },
    '& [class*=MuiFormHelperText-root]': {
      position: 'absolute',
      top: '100%',
      margin: 0,
      whiteSpace: 'nowrap'
    }
  }
}));

const FilterDate = ({ name, postFilter, onFilterChange, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.dateWrap}>
      <DateField
        datePicker
        label=""
        clearable
        InputLabelProps={{ shrink: false }}
        name={name}
        value={postFilter[name] || null}
        onChange={(value) => onFilterChange(name, value)}
        onAccept={date => {
          if (!date) onFilterChange(name, null)
        }}
        {...rest}
      />
      <CalendarFile size={1.15} />
    </div>
  );
};

export default FilterDate;
