// @flow
import React, { type Element, type Context, createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useAccess, useTabsLayout, useTabData, useTabsAllowed } from 'hooks';
import { useApp, isPublicUrl } from 'helpers';
import { BSN_SET_APP, tabType } from 'conf';
import isEqual from 'lodash/isEqual';

export const TabsContext: Context<{
  dispatch: Function,
  app: string,
  tabCurrent: string,
  layout: Object,
  record: Object,
  tabs: Object,
  setMutation: Function,
  hasMutation: boolean,
  noQuery: boolean,
  id?: string | number | boolean,
  showSaveButton: boolean,
  access: Object,
  settings: Object,
  success: boolean
}> = createContext({
  dispatch: e => e,
  app: '',
  tabCurrent: '',
  layout: {},
  record: {},
  tabs: {},
  setMutation: e => e,
  hasMutation: false,
  noQuery: true,
  id: '',
  showSaveButton: false,
  access: {},
  settings: {},
  success: false
});

export type TabsProviderTypes = {
  children?: any,
  setLayout?: {
    [id: string | number]: tabType
  } | null,
  onTabChange?: Function,
  id?: number | string,
  access?: Object
};

const checkBool = (value, record) => {
  const isString = value !== 'true' && value !== 'false';
  return isString ? value : !record;
};

export const TabsProvider = ({
  children,
  setLayout,
  onTabChange,
  access: clientAccess = {},
  id = 0
}: TabsProviderTypes): Element<*> => {
  const { app, tab: tabLoc, item } = useLocation();

  const state = useSelector(rxState => rxState.bsn);
  const { activeTabType } = state.system;
  const access = useAccess({ type: 'tabs', app, merger: clientAccess });
  const { layout, active, previous, settings } = useTabsLayout({
    app,
    tab: tabLoc,
    override: setLayout,
    accessMerger: clientAccess
  });

  const tab = active || tabLoc;
  const noQuery = settings?.noQuery || false;
  const queryOptions = settings?.queryOptions;
  const { data: record, isSuccess, error, ...query } = useTabData({ app, tab, item, enabled: !noQuery, queryOptions });
  const success = noQuery || isSuccess;
  const { dispatch: dispatchApp, tempState } = useApp();
  const [hasMutation, setMutation] = useState(false);
  const showSaveButton = settings?.showSaveButton || false;
  const hideTabs = settings?.hideTabs || false;
  const userTabs = useSelector(s => s.bsn.tabs[app], isEqual);
  const tabsAllowed = useTabsAllowed({ layout: userTabs, app, accessMerger: clientAccess });
  const tabs = Object.keys(tabsAllowed);
  const app_dispatch = useDispatch();
  useEffect(() => {
    if (app && active && record) {
      app_dispatch({ type: BSN_SET_APP, payload: { appName: [app], tabName: [active], record } });
    }
    if (!app || !active) {
      dispatchApp.changeTab(app, active, previous, item, false, activeTabType);
    }
  }, [active, item, app, previous, record, activeTabType]);

  const dispatch = {};

  dispatch.onChangeTab = (e, val) => {
    dispatchApp.changeTab(app, val, active, item, hasMutation, '');
  };

  dispatch.onChangeData = ({ target: { name, value } }) => {
    const [row, rowItem] = name.search('.') === -1 ? [name, null] : name.split('.');
    const rowData =
      !rowItem || rowItem === 'undefined'
        ? checkBool(value, record[row])
        : {
            ...record[row],
            [rowItem]: checkBool(value, record[row][rowItem])
          };
    const data = {
      ...record,
      [row]: rowData
    };
    dispatchApp.setItem(app, active, id !== 0 ? id : null, data);
  };

  dispatch.onChangeDataArray = data => {
    dispatchApp.setItem(app, active, id !== 0 ? id : null, data);
  };

  dispatch.update = () => {
    dispatchApp.update(app, active, item);
  };

  dispatch.cancel = () => {
    dispatchApp.setItem(app, active, id !== 0 ? id : null, null);
  };

  if (isPublicUrl(app)) window.location.reload();

  return (
    <TabsContext.Provider
      value={{
        app,
        dispatch,
        layout,
        tabs,
        tabCurrent: active,
        record,
        setMutation,
        hasMutation,
        noQuery,
        id: item,
        showSaveButton,
        access,
        hideTabs,
        settings,
        success,
        error,
        ...query
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

TabsProvider.defaultProps = {
  children: null,
  setLayout: null,
  onTabChange: e => e,
  id: 0,
  access: {}
};

export const useTabs = () => useContext(TabsContext);
