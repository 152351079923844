import apiClient from '../apiClient/apiClient';

const quiz = {};

quiz.uploadAttachment = (url, fields, file) => {
  const formData = new FormData();
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('key', fields.key);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('file', file);
  return apiClient.post({ url, headers: { 'Content-Type': 'multipart/form-data' }, data: formData });
};

export default quiz;