import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  InfoIcon,
  MenuItem,
  Stack,
  TextField,
  Tooltip
} from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { TEST_MT_NL_OPTIONS } from 'apps/contentadmin/constants';

/**
 * A component for testing MT/NL notifications for partners.
 * @param {Object} props - The component props.
 * @param {string} [props.type=''] - The value of the type of notification.
 * @param {Object|null} [props.item=null] - The selected MT/NL item for notification.
 * @param {Object|null} [props.partner=null] - The selected partner for notification.
 * @param {Object[]} [props.itemsOptions=[]] - The options for MT/NL item selection.
 * @param {Object[]} [props.partnersOptions=[]] - The options for partner selection.
 * @param {boolean} props.isLoadingItemsOptions - Indicates if MT/NL items options are loading.
 * @param {boolean} props.isLoadingPartnersOptions - Indicates if partners options are loading.
 * @param {boolean} props.isSendDisabled - Indicates if the send button is disabled.
 * @param {boolean} props.isSendLoading - Indicates if the send button is in loading state.
 * @param {Function} [props.onChangeType=()=>{}] - Handler for type change.
 * @param {Function} [props.onChangeName=()=>{}] - Handler for MT/NL item change.
 * @param {Function} [props.onChangePartner=()=>{}] - Handler for partner change.
 * @param {Function} [props.onChangePartnerInput=()=>{}] - Handler for partner input change.
 * @param {Function} [props.onSendNotification=()=>{}] - Handler for sending notification.
 * @returns {JSX.Element} The TestMTNLPartnerTestingCard component.
 */
const TestMTNLPartnerTestingCard = ({
  type = '',
  item = null,
  partner = null,
  itemsOptions = [],
  partnersOptions = [],
  isLoadingItemsOptions,
  isLoadingPartnersOptions,
  isSendDisabled,
  isSendLoading,
  onChangeType = () => {},
  onChangeName = () => {},
  onChangePartner = () => {},
  onChangePartnerInput = () => {},
  onSendNotification = () => {}
}) => {
  return (
    <Card>
      <CardHeader title="Partner Testing" />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" flexWrap="wrap" spacing={2} useFlexGap>
            <Box width={180}>
              <TextField name="type" fullWidth value={type} select label="Type" onChange={onChangeType}>
                {TEST_MT_NL_OPTIONS.map(({ label, value }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Box>
            <Box width={500}>
              <Autocomplete
                options={itemsOptions}
                value={item}
                loading={isLoadingItemsOptions}
                getOptionLabel={option => `${option.name} - ${option.created}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => <TextField {...params} label="Name - Created" />}
                onChange={onChangeName}
                disableCloseOnSelect={false}
              />
            </Box>
          </Stack>
          <Box maxWidth={300}>
            <Autocomplete
              options={isLoadingPartnersOptions ? [] : partnersOptions}
              value={partner}
              loading={isLoadingPartnersOptions}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => <TextField {...params} label="Partner" />}
              onChange={onChangePartner}
              onInputChange={onChangePartnerInput}
              disableCloseOnSelect={false}
            />
          </Box>
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Stack alignItems="center" direction="row" spacing={2} mr="auto">
          <Button disabled={isSendDisabled} loading={isSendLoading} onClick={onSendNotification} color="success">
            Send Notification
          </Button>
          <Tooltip
            placement="right"
            title="By clicking this you will test sending MT/NL to the selected partner users."
          >
            <Stack>
              <InfoIcon />
            </Stack>
          </Tooltip>
        </Stack>
      </CardActions>
    </Card>
  );
};

TestMTNLPartnerTestingCard.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  partner: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  isLoadingItemsOptions: PropTypes.bool.isRequired,
  isLoadingPartnersOptions: PropTypes.bool.isRequired,
  isSendDisabled: PropTypes.bool.isRequired,
  isSendLoading: PropTypes.bool.isRequired,
  itemsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired
    })
  ).isRequired,
  partnersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangePartner: PropTypes.func.isRequired,
  onChangePartnerInput: PropTypes.func.isRequired,
  onSendNotification: PropTypes.func.isRequired
};

export default TestMTNLPartnerTestingCard;
