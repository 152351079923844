import React from 'react'
import EnabledWelcomeMessageDialog from './EnabledWelcomeMessageDialog';
import DisabledWelcomeMessageDialog from './DisabledWelcomeMessageDialog';

const EnableDisableWelcomeMessageDialog = ({
    isWelcomeMsgEnabled,
    openDialog,
    onCloseDialog, 
    onSubmitEnable, 
    onSubmitDisable,
    enableDialogIsCustomWelcomeMsg,
    enableDialogWelcomeMsgFrequency,
    enableDialogWelcomeMsgHowMany,
    enableDialogWelcomeMsgDeferred
}) => {
    if (!isWelcomeMsgEnabled) {
        return <EnabledWelcomeMessageDialog 
            onSubmitDialog={onSubmitEnable}
            onCloseDialog={onCloseDialog}
            openDialog={openDialog}
            isCustomWelcomeMsg={enableDialogIsCustomWelcomeMsg}
            welcomeMsgFrequency={enableDialogWelcomeMsgFrequency}
            welcomeMsgHowMany={enableDialogWelcomeMsgHowMany}
            welcomeMsgDeferred={enableDialogWelcomeMsgDeferred}
        />
    } else {
        return <DisabledWelcomeMessageDialog 
            onSubmitDialog={onSubmitDisable}
            onCloseDialog={onCloseDialog}
            openDialog={openDialog}
        />
    }
}

export default EnableDisableWelcomeMessageDialog