import { CircularProgress, Stack, Typography } from '@trustsecurenow/components-library';
import React from 'react';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const TOTAL_PERCENTAGE_VALUE = 800;

const getPercent = value => (parseInt(value, 10) * 100) / TOTAL_PERCENTAGE_VALUE;

const CompanyESSCell = ({ averageEss }) => {
  if (isNA(averageEss)) return <Typography variant="body2">N/A</Typography>;

  return (
    <Stack justifyContent="center" alignItems="center">
      <CircularProgress
        shaded
        halfCircle
        value={getPercent(averageEss)}
        colorRanges={[
          { color: 'error', threeshold: getPercent(500) },
          { color: 'warning', threeshold: getPercent(630) },
          { color: 'success' }
        ]}
        size={54}
      >
        <Typography variant="button">{averageEss}</Typography>
      </CircularProgress>
    </Stack>
  );
};

CompanyESSCell.propTypes = {
  averageEss: PropTypes.number.isRequired
};

export default CompanyESSCell;
