const moveUserClients = {
  defaultSortValue: {},
  selecting: false,
  selectingRadio: true,
  sort: [],
  head: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'active',
      label: 'Active',
      align: 'left',
    }
  ]
};

export default moveUserClients;
