// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Search = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 34 35" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h34v35H0z" />
    <g transform="translate(-1269 -150)">
      <path d="M1300.4,179.6l-8-8c3.9-5.5,2.6-13-2.9-16.9c-5.5-3.9-13-2.6-16.9,2.9c-3.9,5.5-2.6,13,2.9,16.9   c4.2,3,9.9,3,14.1,0l0,0l8,8c0.8,0.8,2,0.8,2.8,0l0,0C1301.2,181.6,1301.2,180.4,1300.4,179.6   C1300.4,179.6,1300.4,179.6,1300.4,179.6z M1282.6,173.8c-5.1,0-9.3-4.2-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3s9.3,4.2,9.3,9.3l0,0   C1291.8,169.7,1287.7,173.8,1282.6,173.8z" />
    </g>
  </SvgIconStyled>
);

export default Search;
