import React from 'react';
import { CircleCheckIcon, CircleCloseIcon, IconButton, Tooltip, Typography, Stack } from '@trustsecurenow/components-library';
import PropTypes from 'prop-types';
import { isNA } from '../helpers';

const PositiveOptinCell = ({ enabled, onClick }) => {
  if (isNA(enabled))
    return (
      <Tooltip placement="top" title="This account is inactive or does not have Micro Trainings/Newsletters">
        <Typography variant="body2">N/A</Typography>
      </Tooltip>
    );

  const tooltipTitle = enabled
    ? 'Positive Opt-in has been enabled. Users will not be able to unsubscribe from Micro Trainings and Newsletter emails.'
    : 'Click here to enable positive opt-in and prevent users from unsubscribing from Micro Training and Newsletter emails.';

  return (
    <Tooltip title={tooltipTitle} placement="top">
      {
        !enabled ? (
          <IconButton color='error' onClick={onClick}>
            <CircleCloseIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        ) :
        (
          <Stack p={1}>
            <CircleCheckIcon color='success' sx={{ fontSize: '2rem' }}/>
          </Stack>
        )
      }
    </Tooltip>
  );
};

PositiveOptinCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PositiveOptinCell;
