// @flow

const documentsInfo = {
  title: 'Contract Info',
  otherId: 'documentId',
  component: 'company/CompanyDocumentsModal',
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: {
      label: 'Save'
    }
  },
  initialState: null
};

export default documentsInfo;
