// @flow
import React from 'react';
import { TableCellStyled } from '../style';

type Props = {
  data?: any,
  renderComponent?:  Function | null,
  onClick?: Function | null,
  rowClick?: boolean,
  disableRowClick?: boolean | null,
};

const TableCellField = ({
  data,
  renderComponent,
  onClick = () => {},
  rowClick,
  disableRowClick,
  index,
  ...rest
}: Props) => {

  return (
    <TableCellStyled onClick={() => !disableRowClick && onClick()} rowClick={rowClick} {...rest}>
      {
        renderComponent ? (
          renderComponent(data, index)
        ) : (
          data
        )
      }
    </TableCellStyled>
  );
};

export default TableCellField;
