// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Insurance = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16.389 19" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h16.389v19H0z" />
    <g transform="translate(-880 -203)">
      <path
        d="M48.962,37.068l-6.747-6.65A1.457,1.457,0,0,0,41.193,30H34.446A1.435,1.435,0,0,0,33,31.425v16.15A1.435,1.435,0,0,0,34.446,49H47.94a1.435,1.435,0,0,0,1.446-1.425v-9.5A1.415,1.415,0,0,0,48.962,37.068Zm-7.287-4.883a.121.121,0,0,1,.207-.081L47.251,37.4a.118.118,0,0,1,0,.168.123.123,0,0,1-.085.037H41.916a.239.239,0,0,1-.241-.237ZM47.94,47.338a.239.239,0,0,1-.241.237H34.687a.239.239,0,0,1-.241-.237V31.662a.239.239,0,0,1,.241-.237h5.3a.239.239,0,0,1,.241.237V37.6a1.435,1.435,0,0,0,1.446,1.425H47.7a.239.239,0,0,1,.241.238Z"
        transform="translate(847 173)"
      />
    </g>
  </SvgIconStyled>
);

export default Insurance;
