import styled from "styled-components"
import { Container, Typography, TextField } from 'components';

export const PreassessmentWrapperLayout = styled(Container.Grid)`
  padding-inline: 12.014%;

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 0 20px;
    justify-content: center;
    width: 100%;
  }
`

// max-width: 435px;
export const PreassessmentWrapperTitle = styled(Typography.h3)`
  font-size: 36px;
  line-height: 43.2px;
  margin: 0;
`

// max-width: 525px;
export const PreassessmentWrapperDescription = styled(Typography.p)`
  margin-top: 23px;
  margin-bottom: 36px;
  font-size: 14px;
  line-height: 25.2px;
`

export const IndicatorContainer = styled(Container.Grid)`
  margin-bottom: 50px;
`

export const IndicatorTitle = styled(Typography.h5)`
  font-size: 14px;
  line-height: 16.8px;
  color: var(--colorGreyLight1);
  margin: 0 10px 0 0;
`

export const PreassessmentBlueText = styled(Typography.p)`
  color: #0069FF;
  max-width: 514px;
  margin-top: 0;
  margin-bottom: 23px;
  font-size: 14px;
`

export const PreassessmentInputField = styled(TextField)`
  && {
    margin: 0 calc(var(--spacing) * 2) calc(var(--spacing) * 2) 0 !important;

    ${props => props.theme.breakpoints.down("xs")} {
      margin: 0 0 calc(var(--spacing) * 2) 0 !important;
    }
  }
`

export const PreassessmentWrapperContainer = styled.div``
