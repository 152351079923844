/* eslint-disable react/prop-types */
import { Chip } from '@trustsecurenow/components-library';
import React from 'react';

const getChipColor = priority => {
  switch (priority) {
    case 'Required':
      return 'error';
    case 'Addressable':
      return 'warning';
    default:
      return 'primary';
  }
};

const PriorityCell = props => <Chip label={props.value} variant="outlined" color={getChipColor(props.value)} />;

export default PriorityCell;
