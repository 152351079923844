// @flow

const today = new Date().toISOString().slice(0, 10);

const trackSecurityHIPAAIncidents = {
  layout: Object.freeze({
    header: Object.freeze({
      title: 'HIPAA Security Incident'
    }),
    content: Object.freeze({
      component: 'shared/track/TrackSteps'
    }),
    footer: Object.freeze({
      left: false,
      right: `apps/shared/track/TrackHipaaIncidentFooterRight`
    })
  }),
  step: 0,
  steps: ['Incident', 'Resolution Details', 'Breach Notification 1', 'Breach Notification 2', 'Corrective Measures'], // for hipaa client
  open: false,
  disabled: true,
  defaultChoice: 'trackSecurityIncidents'
};

export default trackSecurityHIPAAIncidents;
