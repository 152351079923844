import React, { useState, useMemo, useEffect } from 'react';
import { useNotify } from 'ra-core';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import * as sra from 'helpers/apis/services/sra';
import TaskItem from './TaskItem';
import { TASK_LIST } from '../constants';
import { useSRA } from '../context/sraContext';

const Container = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 35%;
`;

const TaskDetailsContainer = styled.div`
  display: flex;
  flex-basis: 60%;
`;

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 30px;
  width: 100%;
  height: calc(100% - 30px);
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.56);
  z-index: 1;
`;

const CheckList = ({ tasks, getTaskList, loadingTaskList }) => {
  const notify = useNotify();
  const {
    state: { activeTask, revisionId },
    dispatch,
    clientId
  } = useSRA();
  const [loadingRetake, setLoadingRetake] = useState(false);

  const activeTaskItem = useMemo(() => TASK_LIST.find(item => item.name === activeTask), [activeTask]);
  const ActiveTaskComponent = activeTaskItem?.component;
  const isMarkedComplete = tasks?.mark_complete?.complete;

  const onRetakeSRA = () => {
    setLoadingRetake(true);
    sra
      .retakeSra(clientId, revisionId)
      .then(res => {
        getTaskList();
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'warning');
      })
      .finally(() => {
        setLoadingRetake(false);
      });
  };

  useEffect(() => {
    sessionStorage.removeItem('accessOrgProfileSRA');
    sessionStorage.removeItem('accessQuestionsSRA');
  }, []);

  return (
    <Container>
      {loadingTaskList && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}

      <TasksContainer>
        {tasks &&
          TASK_LIST.map(item => {
            const taskName = item.name;
            const task = tasks[taskName];

            if (!task) return null;

            return (
              <TaskItem
                key={taskName}
                taskName={taskName}
                task={task}
                isSelected={activeTask === taskName}
                onSelect={() => dispatch.setActiveTask(taskName)}
                isMarkedComplete={isMarkedComplete}
              />
            );
          })}
      </TasksContainer>

      <TaskDetailsContainer>
        <ActiveTaskComponent
          task={tasks?.[activeTask]}
          clientId={clientId}
          revisionId={revisionId}
          getTaskList={getTaskList}
          onRetakeSRA={onRetakeSRA}
          loadingRetake={loadingRetake}
          isMarkedComplete={isMarkedComplete}
        />
      </TaskDetailsContainer>
    </Container>
  );
};

export default CheckList;
