import React, { Children, cloneElement, useEffect, useState } from 'react';
import { Container } from '../types';
import { Delete } from '../icons';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const DynamicFormContainer = styled(Container.Grid)``;
const DynamicFormWrapper = styled(Container.Grid)``;
const DynamicFormButton = styled(Container.Grid)``;
const DynamicFormRow = styled(Container.Grid)``;
const InputGrid = styled(Container.Grid)``;

const DynamicForm = ({ children, value, onChange = () => {}, addButton }) => {
  const [formState, setFormState] = useState({});

  useEffect(() => {
    Children.forEach(children, child => {
      setFormState(prev => ({
        ...prev,
        [child.props.name]: ''
      }));
    });
  }, []);

  const handleChange = (name, inputValue, index) => {
    const data = value;
    const newObj = { ...data[index] };
    newObj[name] = inputValue;
    const newArr = [...data.slice(0, index), newObj, ...data.slice(index + 1)];
    onChange(newArr);
  };

  const handleAddNewForm = () => {
    const newArr = [...value, formState];
    onChange(newArr);
  };

  const handleDeleteRow = index => {
    const newArr = [...value.slice(0, index), ...value.slice(index + 1)];
    onChange(newArr);
  };

  return (
    <DynamicFormContainer alignItems={'flex-start'}>
      <DynamicFormWrapper md={8} alignItems={'center'}>
        {value.map((val, index) => {
          return (
            <>
              <DynamicFormRow xs={11} key={`form-${index}`}>
                {Children.map(children, (child, childIndex) => {
                  return (
                    <InputGrid {...child.props.gridSize} key={`input-${index}-${childIndex}`} pr={3}>
                      {cloneElement(child, {
                        value: val[child.props.name],
                        onChange: e => handleChange(child.props.name, e.target.value, index)
                      })}
                    </InputGrid>
                  );
                })}
              </DynamicFormRow>

              {index > 0 && (
                <DynamicFormRow xs={1}>
                  <IconButton onClick={() => handleDeleteRow(index)}>
                    <Delete />
                  </IconButton>
                </DynamicFormRow>
              )}
            </>
          );
        })}
      </DynamicFormWrapper>
      <DynamicFormButton md={4} pt={1}>
        {cloneElement(addButton, {
          onClick: handleAddNewForm
        })}
      </DynamicFormButton>
    </DynamicFormContainer>
  );
};

export default DynamicForm;
