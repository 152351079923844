// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Pin = props => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <g clipPath="url(#clip0_2254_15009)">
      <path d="M23.1501 5.15473L18.8083 0.812933C18.2817 0.286374 17.5889 0 16.8499 0C16.1108 0 15.4088 0.286374 14.8914 0.812933L10.873 4.83141C10.679 5.01617 10.5219 5.22864 10.4018 5.45958H3.66741C3.4457 5.45958 3.24247 5.53349 3.08543 5.66282C3.07619 5.66282 3.07619 5.66282 3.07619 5.67206C2.98381 5.70901 2.89143 5.77367 2.81753 5.84758C2.45725 6.20785 2.45725 6.78984 2.81753 7.15012L7.14085 11.4734L0.138543 22.5866C-0.129356 23.0208 -2.52877e-05 23.5843 0.434155 23.8614C0.581961 23.9538 0.757481 24 0.923762 24C1.18242 24 1.44108 23.8891 1.60737 23.6859C1.63508 23.6767 1.66279 23.6582 1.69051 23.6397L12.1478 16.4804L16.7482 21.0808C16.9238 21.2564 17.1639 21.3487 17.3949 21.3487C17.6351 21.3487 17.8753 21.2564 18.0508 21.0808C18.1339 20.9977 18.1986 20.8961 18.2448 20.7945C18.2448 20.7852 18.2448 20.7852 18.254 20.776C18.3741 20.6282 18.4388 20.4342 18.4388 20.2309V13.5982C18.6882 13.4688 18.9284 13.2933 19.1316 13.0901L23.1501 9.07159C24.2309 7.99076 24.2309 6.23557 23.1501 5.15473ZM4.12007 19.7413L8.48035 12.8129L10.8268 15.1594L4.12007 19.7413ZM21.8383 7.76905L18.4388 11.1686L17.8291 11.7783C17.6443 11.963 17.4041 12.0554 17.1732 12.0554C16.9607 12.0554 16.7575 11.9815 16.5912 11.843V13.8383V18.3095L13.709 15.4273L12.3787 14.097L9.48727 11.2055L8.14778 9.86605L5.58889 7.30716H10.1062H12.0554C11.8245 6.93764 11.8614 6.44804 12.1755 6.13395L12.8499 5.45958L16.194 2.11547C16.3787 1.93995 16.6097 1.84758 16.8499 1.84758C17.0808 1.84758 17.321 1.93995 17.4965 2.11547L21.8383 6.45727C22.1986 6.81755 22.1986 7.40878 21.8383 7.76905Z" />
    </g>
    <defs>
      <clipPath id="clip0_2254_15009">
        <rect width="23.963" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default React.memo(Pin);
