// @flow

const documentsBusinessAssociateAgreement = {
  // Add and Delete one Service Provider
  title: 'Business Associate Agreement',
  component: 'clients/ClientsEditDocumentsServiceProviderModal',
  noQuery: true,
  buttons: [],
  initialState: {
    business_name: '',
    contact: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    contract_date: new Date().toISOString().slice(0, 10),
    created: new Date().toISOString().slice(0, 10),
    modified: new Date().toISOString().slice(0, 10),
    relationship_id: '',
    other_relationship: ''
  }
};

export default documentsBusinessAssociateAgreement;
