// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Database = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 36 36">
    <path d="M34.5877 21.7386H34.56V13.7771C34.5739 13.6663 34.5877 13.5555 34.5877 13.4309H34.56V6.27246H34.5462C34.5462 6.20323 34.56 6.14784 34.56 6.07861C34.56 1.6063 20.7416 1.38477 17.9723 1.38477C15.2031 1.38477 1.38464 1.6063 1.38464 6.07861C1.38464 6.17554 1.39849 6.25861 1.41234 6.35554V30.2263H1.48157C2.4508 34.2278 15.3416 34.4355 18.0139 34.4355C20.6862 34.4355 33.5631 34.2278 34.5323 30.2263H34.56V30.0878C34.5739 29.9771 34.5877 29.8663 34.5877 29.7417H34.56V22.0848C34.5739 21.9602 34.5877 21.8494 34.5877 21.7386ZM4.29234 21.7386H4.18157V16.2971C8.36311 18.0002 16.02 18.1248 18 18.1248C19.98 18.1248 27.5954 18.014 31.7908 16.3109V21.5448C30.8908 22.3202 26.1554 23.6632 18.0693 23.6632C9.59541 23.6632 4.80464 22.1955 4.23695 21.4478C4.23695 21.4617 4.29234 21.5725 4.29234 21.7386ZM4.18157 8.95861C8.36311 10.6617 15.9923 10.7725 17.9723 10.7725C19.9523 10.7725 27.6093 10.6617 31.7908 8.94477V13.2232C30.8908 13.9986 26.1416 15.3417 18 15.3417C9.74772 15.3417 4.98464 13.9571 4.18157 13.1955V8.95861V8.95861ZM17.9723 4.154C25.477 4.154 30.1016 5.28938 31.4862 6.07861C30.1016 6.86784 25.477 8.00323 17.9723 8.00323C10.4677 8.00323 5.84311 6.86784 4.45849 6.07861C5.84311 5.28938 10.4677 4.154 17.9723 4.154ZM4.18157 29.5202V24.5632C8.32157 26.3217 16.0616 26.4325 18.0554 26.4325C20.0216 26.4325 27.6093 26.3217 31.777 24.6186V29.5478C30.877 30.3232 26.1277 31.6663 17.9862 31.6663C9.74772 31.6663 4.98464 30.2817 4.18157 29.5202Z"/>
    <defs>
      <clipPath id="clip0_12082_133343">
        <rect width="33.2031" height="33.0508" fill="white" transform="translate(1.38464 1.38477)"/>
      </clipPath>
    </defs>
  </SvgIconStyled>
);

export default Database;
