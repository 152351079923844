// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WatchNow = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 27.1 21.9" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h27.1v21.9H0z" />
    <g>
      <path d="M4,5.2l2.3,2.3c0.2,0.2,0.4,0.2,0.6,0.2c0.5,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.4-0.3-0.6L5.2,4   C4.9,3.7,4.3,3.6,4,4C3.7,4.3,3.6,4.8,4,5.2C4,5.1,4,5.2,4,5.2z" />
      <path d="M13.5,5L13.5,5c0.5,0,0.9-0.3,0.9-0.8c0,0,0,0,0-0.1V0.8C14.4,0.4,14,0,13.5,0s-0.8,0.4-0.8,0.8v3.3   C12.7,4.6,13,5,13.5,5z" />
      <path d="M20.2,7.8c0.2,0,0.4-0.1,0.6-0.2l2.3-2.3c0.3-0.3,0.3-0.8,0-1.2c-0.3-0.3-0.8-0.3-1.2,0l-2.3,2.3   c-0.3,0.3-0.3,0.9,0,1.2C19.8,7.7,20,7.8,20.2,7.8z" />
      <path d="M20.2,7.8L20.2,7.8L20.2,7.8z" />
      <path d="M5,13.6L5,13.6c0-0.5-0.3-0.9-0.8-0.9c0,0,0,0-0.1,0H0.8c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h3.3   C4.6,14.4,5,14.1,5,13.6z" />
      <path d="M26.3,12.7H23c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8S26.7,12.7,26.3,12.7z" />
      <path d="M22.3,20.2h-1.2v-6.7c0-4.2-3.4-7.5-7.5-7.5C9.4,6,6,9.4,6,13.6v6.7H4.8c-0.5,0-0.8,0.4-0.8,0.8   s0.4,0.8,0.8,0.8h17.4c0.5,0,0.8-0.4,0.8-0.8S22.7,20.2,22.3,20.2z M19.4,20.2H7.7v-6.7c0-3.2,2.6-5.9,5.9-5.9   c3.2,0,5.9,2.6,5.9,5.9v6.7H19.4z" />
    </g>
  </SvgIconStyled>
);

export default WatchNow;
