import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import StatusButton from 'apps/shared/darkWebMonitoring/StatusButton';
import { CircularProgress, DialogContent, DialogContentText, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Switch } from 'components';
import clients from 'helpers/apis/clients';
import { useId, useLocation } from 'hooks';
import { useNotify } from 'ra-core';
import CustomTooltip from 'components/common/CustomTooltip';
import { BSN_SET_ANY } from 'conf';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const MfaModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateMfaSettings = useCallback(
    async (clientID, value) => {
      return clients.putInformation(clientID, 'password', { ...buttonsState, mfa_enabled: value });
    },
    [buttonsState]
  );

  const updateMfaSettingsEffect = useCallback(
    async (clientID, value) => {
      setLoading(true);
      updateMfaSettings(clientID, value)
        .then(response => {
          if (response.status === 200) {
            dispatch({
              type: BSN_SET_ANY,
              payload: {
                user: {
                  profile: {
                    shared: {
                      buttonsStatus: { ...buttonsState, mfa_enabled: response.data.mfa_enabled }
                    }
                  }
                }
              }
            });
          }
          setChecked(response?.data?.mfa_enabled);
          notify(`Saved MFA settings Successfully`, 'success');
        })
        .catch(error => {
          notify(error?.response?.data?.description || 'Could not update information settings', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [notify, updateMfaSettings]
  );

  const handleUpdatingMfaSettings = async value => {
    updateMfaSettingsEffect(setId, value);
  };

  return (
    <>
      {buttonsState?.federation_enabled ? (
        <CustomTooltip
          light
          title={
            buttonsState?.federation_enabled
              ? 'MFA cannot be enabled if Single Sign-on is enabled. \nPlease disable Single Sign-On to enable MFA.'
              : ''
          }
          placement="bottom"
        >
          <>
            <StatusButton
              style={{ marginRight: 5 }}
              status={buttonsState?.mfa_enabled ? 'active' : 'inactive'}
              disabled={buttonsState?.federation_enabled}
              label="MFA"
              onClick={handleOpen}
              inactiveOnClick={handleOpen}
            />
          </>
        </CustomTooltip>
      ) : (
        <StatusButton
          style={{ marginRight: 5 }}
          status={buttonsState?.mfa_enabled ? 'active' : 'inactive'}
          disabled={buttonsState?.federation_enabled}
          label="MFA"
          onClick={handleOpen}
          inactiveOnClick={handleOpen}
        />
      )}
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          Multi-Factor Authentication Settings
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Native Multi-Factor Authentication (MFA) - Enabling MFA will require users to use an authenticator app in
            addition to their credentials to login to the portal
          </DialogContentText>
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center', margin: '15px auto' }}>
              <CircularProgress size={30} thickness={4} />
              <span style={{ marginLeft: 10 }}>Enable MFA for All Users</span>
            </div>
          ) : (
            <>
              <Switch
                color="green"
                className={classes.formControlLabel}
                checked={buttonsState?.mfa_enabled}
                onChange={e => {
                  handleUpdatingMfaSettings(e.target.checked);
                }}
                label="Enable MFA for All Users"
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MfaModal;
