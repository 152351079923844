import React from 'react';
import { BaselineIlustration, PreemploymentIlustration, PreassessmentLogoContainer } from '../../style';
import { usePreassessmentContext } from '../../contexts';

const PreassessmentLogo = () => {
  const { type } = usePreassessmentContext();

  // const partnerLogosBaseURL = process.env.PARTNER_LOGOS_BASE_CF_URL;
  // const logoPath = new URLSearchParams(location.search).get('logo_path');

  return (
    <PreassessmentLogoContainer item md={4} alignItems={'flex-start'}>
      {/* <ImgWrap>
        <img
          style={{ maxWidth: '135px', maxHeight: '135px' }}
          alt="Preassessment logo"
          src={`${partnerLogosBaseURL}${logoPath}`}
        />
      </ImgWrap> */}
      {type === 'preemployment' ? <PreemploymentIlustration /> : <BaselineIlustration />}
    </PreassessmentLogoContainer>
  );
};

export default PreassessmentLogo;
