import React from 'react';
import { Button, LazyIcon, Modal3, Container } from "components"
import { useLocation } from "hooks"

const PartnerTaxExemptToolbar = ({modalInfo, setModalInfo, setClose}) => {
  const {app} = useLocation();

  const {open, isNew, data, method} = modalInfo;

  const setOpen = (val) => {
    setModalInfo(prev => ({
      ...prev,
      open: val
    }))
  }



  return (
    <Container.Grid justifyContent={"flex-end"}>
      <Button onClick={() => setOpen(true)}>
        <LazyIcon component="Plus" color="colorCommonWhite" mr={1} size={0.7} />
        Add certificate
      </Button>
      {open && <Modal3
        open={open}
        setOpen={setClose}
        app={app}
        tab={"cellTax"}
        createNew={isNew}
        maxWidth={"sm"}
        data={{
          ...data,
          method
        }}
      />}
    </Container.Grid>
  )
}


export default PartnerTaxExemptToolbar
