import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const ShieldAlert = props => {
  return (
    <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91797 15.7527C7.20239 15.9196 7.52391 16 7.84542 16C8.16694 16 8.48845 15.9134 8.77287 15.7527C12.631 13.5268 15.0239 9.37803 15.0239 4.92628C15.0239 4.2647 14.6652 3.64641 14.0964 3.31871L8.77287 0.245773C8.20403 -0.0819245 7.49299 -0.0819245 6.91797 0.245773L1.59444 3.31871C1.0256 3.6526 0.666992 4.2647 0.666992 4.92628C0.666992 9.37803 3.0598 13.5268 6.91797 15.7527ZM7.53627 1.31543C7.62902 1.25978 7.74031 1.23504 7.84542 1.23504C7.95053 1.23504 8.06183 1.26596 8.15457 1.31543L13.4781 4.38837C13.6698 4.49966 13.7873 4.7037 13.7873 4.92628C13.7873 8.93285 11.6294 12.6736 8.15457 14.6768C7.9629 14.7881 7.72794 14.7881 7.53627 14.6768C4.06144 12.6736 1.90359 8.93904 1.90359 4.92628C1.90359 4.7037 2.02106 4.49966 2.21274 4.38837L7.53627 1.31543ZM7.09713 5.99596L7.26408 8.52481C7.29499 8.8525 7.35064 9.09364 7.41865 9.25439C7.49285 9.41515 7.6227 9.49553 7.81437 9.49553C7.99986 9.49553 8.1359 9.41515 8.21628 9.24821C8.29666 9.08746 8.35231 8.84631 8.37704 8.53716L8.60581 5.93414C8.63054 5.693 8.64287 5.45804 8.64287 5.22309C8.64287 4.82738 8.59341 4.52442 8.4883 4.3142C8.38937 4.10398 8.18534 3.99886 7.88238 3.99886C7.62887 3.99886 7.43103 4.08543 7.27646 4.24619C7.12188 4.41313 7.04769 4.6419 7.04769 4.9325C7.04769 5.1489 7.06621 5.50132 7.09713 5.99596ZM7.28883 11.7894C7.44959 11.9316 7.6351 11.9996 7.85151 11.9996C8.06173 11.9996 8.24716 11.9316 8.40791 11.7894C8.56249 11.6472 8.64287 11.4555 8.64287 11.2082C8.64287 10.9918 8.56249 10.8063 8.40791 10.6518C8.25334 10.4972 8.06172 10.423 7.83913 10.423C7.61654 10.423 7.43103 10.4972 7.27646 10.6518C7.12188 10.8063 7.04769 10.9918 7.04769 11.2082C7.04769 11.4555 7.12808 11.6534 7.28883 11.7894Z"
        fill={props.fill ?? '#535353'}
      />
    </SvgIconStyled>
  );
};

export default React.memo(ShieldAlert);
