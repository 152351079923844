// @flow
import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import breakpoints from './breakpoints';
import shape from './shape';
import spacing from './spacing';
import transitions from './transitions';
import zIndex from './zIndex';
import layout from './layout';

const theme = {
  palette,
  shadows,
  typography,
  breakpoints,
  shape,
  spacing,
  transitions,
  zIndex,
  nprogress: { color: palette.common.black },
  overrides: {
    MuiInputBase: {
      root: {
        color: 'var(--colorDarkGrey)',
        '&$disabled': {
          color: 'var(--colorLight)'
        }
      },
      input: {
        caretColor: 'var(--blackAndWhite)',
        '&:-webkit-autofill': {
          borderRadius: '0 !important',
          '-webkit-text-fill-color': 'var(--colorDarkGrey)',
          boxShadow: '0 0 0px 1000px var(--backgroundDefault) inset'
        }
      }
    },
    MuiFormLabel: {
      root: {
        textSizeAdjust: '100%',
        '&$disabled': {
          color: 'var(--colorLight)'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: 'var(--colorLight)'
        }
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderColor: 'var(--borderBase) !important'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$disabled $notchedOutline': {
          borderColor: 'var(--borderBase)'
        },
        '&:hover $notchedOutline': {
          borderColor: 'var(--colorDarkGrey)'
        }
      },
      notchedOutline: {
        transition: 'border-color 0.3s',
        borderColor: 'var(--borderBase)'
      },
      input: {
        padding: '14px 14px 13px',
        height: 'auto',
        lineHeight: '21px',
        fontSize: 14
      },
      inputMarginDense: {
        paddingTop: 9.5,
        paddingBottom: 9.5
      }
    },
    MuiInputLabel: {
      root: {
        width: 'calc(100% - 30px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'left'
      },
      shrink: {
        width: 'auto',
        whiteSpace: 'normal'
      },
      outlined: {
        transform: `translate(14px, 14px) scale(1)`
      }
    },
    MuiPaper: {
      root: {
        color: 'var(--colorDarkGrey)'
      },
      outlined: {
        borderColor: 'var(--borderLight)'
      }
    },
    MuiChip: {
      root: {
        color: 'var(--colorDarkGrey)',
        backgroundColor: 'var(--backgroundLight)'
      },
      deleteIcon: {
        color: 'var(--colorDefault) !important',
        '&:hover': {
          opacity: 0.7
        }
      },
      outlined: {
        borderColor: 'var(--borderBase)'
      },
      labelSmall: {
        fontSize: 14,
        lineHeight: '21px'
      }
    },
    MuiIconButton: {
      root: {
        color: 'var(--colorDefault)',
        '&$disabled': {
          opacity: 0.5
        }
      }
    },
    MuiButton: {
      root: {
        color: 'var(--colorDarkGrey)',
        '&$disabled': {
          borderColor: 'var(--borderBase)',
          opacity: 0.5
        }
      },
      outlined: {
        color: 'var(--colorDarkGrey)',
        borderColor: 'var(--borderBase)',
        '&$disabled': {
          color: 'var(--colorDarkGrey)',
          borderColor: 'var(--borderBase)',
          opacity: 0.5
        }
      },
      contained: {
        '&$disabled': {
          opacity: 1,
          '& *': {
            opacity: 'var(--buttonTextOpacity)'
          }
        }
      }
    },
    MuiDialogContent: {
      dividers: {
        borderTopColor: 'var(--borderBase)',
        borderBottomColor: 'var(--borderBase)'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 670
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'var(--backdrop)'
      }
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: 'var(--backgroundDropdownHover)'
        },
        '&[aria-selected="true"]': {
          backgroundColor: 'var(--backgroundDropdownSelected) !important'
        }
      },
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: '4.5px 4px'
        },
        '&&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] $input': {
          padding: '3.5px 4px'
        }
      },
      hasPopupIcon: {
        '&&$hasClearIcon $inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingRight: 48
        }
      },
      popper: {
        minWidth: 'fit-content'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'var(--backgroundDropdownSelected)',
          '&:hover': {
            backgroundColor: 'var(--backgroundDropdownSelected)'
          }
        }
      },
      button: {
        '&:hover': {
          backgroundColor: 'var(--backgroundDropdownHover)'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'var(--colorDefault)'
      }
    },
    MuiStepIcon: {
      root: {
        color: 'var(--colorLight)'
      },
      text: {
        fill: 'var(--whiteAndBlack)'
      }
    },
    MuiStepLabel: {
      active: {
        color: 'var(--colorDarkGrey) !important'
      },
      completed: {
        color: 'var(--colorDefault) !important'
      }
    },
    MuiPaginationItem: {
      root: {
        color: 'var(--colorDarkGrey)'
      },
      page: {
        '&$selected': {
          backgroundColor: 'var(--backgroundDropdownSelected)',
          '&:hover': {
            backgroundColor: 'var(--backgroundDropdownSelected)'
          }
        },
        '&:hover': {
          backgroundColor: 'var(--backgroundDropdownHover)'
        }
      }
    },
    MuiSelect: {
      '&$disabled': {
        color: 'var(--colorLight)'
      },
      icon: {
        color: 'var(--colorDefault)',
        top: '50%',
        transform: 'translateY(-50%)'
      },
      root: {
        textSizeAdjust: '100%'
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'var(--colorDefault)'
      }
    },
    MuiPickersDay: {
      day: {
        color: 'var(--colorDarkGrey)'
      },
      dayDisabled: {
        color: 'var(--colorLight)'
      }
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: 'var(--colorDarkGrey)'
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: 'var(--backgroundSwitch) !important',
        '$checked$checked + &': {
          backgroundColor: '#4184b5 !important'
        }
      }
    },
    MuiTableCell: {
      head: {
        borderColor: 'var(--borderBase)',
        color: 'var(--colorDarkGrey)'
      },
      stickyHeader: {
        backgroundColor: 'var(--backgroundPaper)'
      },
      body: {
        borderColor: 'var(--borderTableDefault)',
        color: 'var(--colorDefault)'
      }
    },
    MuiTableSortLabel: {
      active: {
        color: 'var(--colorFontTableHeader) !important'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: 'var(--borderDefault)'
      }
    },
    MuiTablePagination: {
      input: {
        color: 'var(--colorDarkGrey)'
      }
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'var(--borderBase)'
        }
      }
    }
  },
  themeName: 'bsn2019',
  layout
};

export default createMuiTheme(theme);
