import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';

const base: string = process.env.PARTNER_API_BASE_URL || '';
const baseURL: string = `${base}/`;
const xApiKey: string = process.env.PARTNER_XAPIKEY || '';

//const partnerId = getStorage("partnerId");

const integrationApi = {};

integrationApi.getRequestHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getStorage('idToken', true),
    'x-api-key' : xApiKey
  };
};

integrationApi.get = (partnerId) => {
  const requestOptions = {
    url: `${baseURL}partners_integrations/${partnerId}`,
    headers: integrationApi.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
};

integrationApi.update = (partnerId, integrations) => {
  const requestOptions = {
    url: `${baseURL}partners_integrations/${partnerId}`,
    headers: integrationApi.getRequestHeaders(),
    data: integrations
  };
  return apiClient.put(requestOptions);
};

integrationApi.getApiKeys = (userId) => {
  const requestOptions = {
    url: `${baseURL}partners_api_keys/${userId}`,
    headers: integrationApi.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
}

integrationApi.updateApiKey = (userId, apiKeyId, apiKey) => {
  const requestOptions = {
    url: `${baseURL}partners_api_keys/${userId}/${apiKeyId}`,
    headers: integrationApi.getRequestHeaders(),
    data: { apiKey }
  };
  return apiClient.put(requestOptions);
}

integrationApi.createApiKey = (userId, friendly_name) => {
  const requestOptions = {
    url: `${baseURL}partners_api_keys/${userId}`,
    headers: integrationApi.getRequestHeaders(),
    data: { friendly_name }
  };
  return apiClient.post(requestOptions);
}

integrationApi.deleteApiKey = (userId, apiKey) => {
  const requestOptions = {
    url: `${baseURL}partners_api_keys/${userId}`,
    headers: integrationApi.getRequestHeaders(),
    data: { ...apiKey }
  };
  return apiClient.delete(requestOptions);
}

export default integrationApi;