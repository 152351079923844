/* eslint-disable react/prop-types */
import { Link, PencilIcon, Stack, TrashIcon } from '@trustsecurenow/components-library';
import React from 'react';

const ActionsCell = props => {
  const { permissions, onEditThreat, onDeleteThreat } = props;

  return (
    <Stack spacing={2} direction="row">
      {(permissions === 'full' || permissions === 'limited') &&
        (<Link component="button" hoverColor="info.main" startIcon={<PencilIcon />} onClick={onEditThreat}>
          Edit
        </Link>
        )}

      {(permissions === 'full') &&
        (<Link component="button" hoverColor="error.main" startIcon={<TrashIcon />} onClick={onDeleteThreat}>
          Delete
        </Link>
        )}
    </Stack>
  );
};

export default ActionsCell;
