import { useTableContext } from '../context';
import CustomTableRootRow from './CustomTableRootRow';
import CustomTableCollapsed from './CustomTableCollapsed';
import React from 'react';
import isEqual from "lodash/isEqual"

const CustomTableRow = ({
  index,
  rowId,
  isCollapsed,
  isHighlighted,
  data
}) => {

  const tableContext = useTableContext();

  return (
    <>
      <CustomTableRootRow
        {...tableContext}
        index={index}
        rowId={rowId}
        isCollapsed={isCollapsed}
        isHighlighted={isHighlighted}
        data={data}
      />
      <CustomTableCollapsed
        {...tableContext}
        isCollapsed={isCollapsed}
        rowId={rowId}
        data={data}
      />
    </>
  )
}

export default React.memo(CustomTableRow, (prevProps, nextProps) => {
  return prevProps.isCollapsed === nextProps.isCollapsed &&
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.isHighlighted === nextProps.isHighlighted
})
