import { useCallback, useState } from 'react';
import * as usersAPI from '../../../../helpers/apis/services/usersAPI';

export const useReferralCode = () => {

  const [record, setRecord] = useState({
    referral_code: "",
    disabled: true,
    isNeedToShow: false,
    modalData: null
  })

  const getReferralCodeSubmissionMsg = useCallback((obj = {}) => {
    let message = obj.msg;
    const urls = obj.urls

    if (urls?.length) {
      urls.forEach(({ title, path }) => {
        message = message.replace("{{url}}", `<a href='${path}' target='_blank'>${title}</a>`)
      })
    }

    return `<p>${message}</p>`.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }, [])

  const onChange = useCallback((name, value) => {
    setRecord((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }, [])

  const setData = useCallback((value) => {
    onChange("modalData", value)
  }, [onChange])

  const setDisabled = useCallback((value) => {
    onChange("disabled", value)
  }, [onChange])

  const showReferralCodeButton = useCallback((value) => {
    onChange("isNeedToShow", value)
  }, [onChange])

  const onSubmit = useCallback(async () => {
    setDisabled(true)

    try {
      const promotionData = await usersAPI.sendPromotionClaim({
        referral_code: record.referral_code.toUpperCase()
      });
      setData({...promotionData.data, isSuccess: true})
      showReferralCodeButton(false)
    } catch (e) {
      setDisabled(true)
      setData({...e.response.data, isSuccess: false})
    } finally {
      setDisabled(false)
    }
  }, [setDisabled, setData, record.referral_code])


  const clearState = useCallback(() => {
    setRecord( prevState => ({
      ...prevState,
      referral_code: "",
      disabled: true,
      modalData: null
    }))
  }, [])

  return {
    record,
    getReferralCodeSubmissionMsg,
    dispatch: {
      onChange,
      setData,
      setDisabled,
      showReferralCodeButton,
      onSubmit,
      clearState
    }
  }
}
