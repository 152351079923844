import React from "react";

// use with any any input Change either for or state

const useForm = (initial) => {

  const [form, setForm] = React.useState(initial ?? {});
  const clearForm = () => {
    setForm(initial ?? {});
  };
  const onInput = (e) => {
    const { name, value } = e.currentTarget;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onSelectChange = (event) => {
    const { name, value } = event.currentTarget;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onSwitchChange = (e) => {
    const { name } = e.currentTarget;
    setForm((old) => {
      return { ...old, [name]: !old[name] };
    });
  };
  const onMultiSelectChange = (event) => {
    const { name, value } = event.currentTarget;
    setForm((old) => {
      const field = old[name];
      // there is no field or there is but its empty then add 1st item to list
      if (!field || !field?.length) {
        return { ...old, [name]: [value] };
      }
      // item is not exits then toggle on
      if (!field.includes(value)) {
        return { ...old, [name]: [...field, value] };
      }
      // toggle off selected item if exits
      const toggleList = field.filter((f) => f != value);
      return { ...old, [name]: toggleList };
    });
  };

  const setFormCallback = React.useCallback((...args) => {
    setForm(...args);
  }, []);

  return {
    form,
    setForm: setFormCallback,
    clearForm,
    onSelectChange,
    onInput,
    onSwitchChange,
    onMultiSelectChange
  };
};

export default useForm;
