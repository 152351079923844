// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { VideoPlayer } from '@trustsecurenow/components-library';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: ({ width }) => width ? `${width}px` : '890px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'var(--borderRadius)',
      overflow: 'hidden'
    },
    '& .video': {
      overflow: 'hidden',
      height: 0,
      paddingTop: '56.25%',
      position: 'relative',
      width: '100%',
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  }
}));

const Video = ({ src, width, onPlayVideo }) => {
  const classes = useStyles({ width });

  return (
    <div className={classes.container}>
      <div className="video">
        <VideoPlayer
          url={src}
          onPlay={onPlayVideo}
          width={'100%'}
        />
      </div>
    </div>
  );
};

export default React.memo(Video);
