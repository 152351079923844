// @flow
import React from 'react';
import { Snackbar as MuiSnackBar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type Props = {
  message: string,
  severity?: 'success' | 'error' | 'warning' | 'info',
  open: boolean,
  onClose: Function
};

const SnackBar = ({ open, onClose, message, severity }: Props) => {
  return (
    <MuiSnackBar open={open} autoHideDuration={4000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </MuiSnackBar>
  );
};

SnackBar.defaultProps = {
  severity: 'success'
};

export default SnackBar;
