// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const User = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 14 17" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h14v17H0z" />
    <path d="M4,8A4,4,0,1,0,0,4,4,4,0,0,0,4,8Z" transform="translate(3)" />
    <path d="M13,8a1,1,0,0,0,1-1A7,7,0,0,0,0,7,1,1,0,0,0,1,8Z" transform="translate(0 9)" />
  </SvgIconStyled>
);

export default User;
