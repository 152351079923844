// @flow
import React from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  menuLink: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    },
    '& svg': {
      marginRight: '12px'
    },
    '&.withLink': {
      padding: 0,
      '& > a': {
        padding: '10px 40px 10px 20px',
        textDecoration: 'none',
        color: 'inherit',
        transition: '0s',
        display: 'flex',
        alignItems: 'center'
      }
    },
    '&.danger': {
      color: 'var(--colorSystemDanger)'
    }
  }
}));

const MenuLink = ({ children, className, onClick, ...rest }) => {
  const classes = useStyles();

  return (
    <MenuItem
      className={`${classes.menuLink} ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

export default MenuLink;
