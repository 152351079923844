// @flow
import React, { useState, useEffect } from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { SearchIcon } from 'components/icons';
import { useDebounce } from 'hooks';

const useStyles = makeStyles(theme => ({
  search: {
    padding: '20px 24px 10px',
    '& p': {
      margin: '0 0 13px',
      fontWeight: 500
    }
  },
  searchInput: {
    width: '100%'
  }
}));

const ModalSearch = ({ title, placeholder, onSearch }) => {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState('');
  const [searchInputDebounce] = useDebounce({ value: searchInput, delay: 500 });

  useEffect(() => {
    onSearch(searchInputDebounce.value);
  }, [searchInputDebounce, onSearch]);

  const onSearchInputChange = e => {
    setSearchInput(e.target.value);
  };

  return (
    <div className={classes.search}>
      {title && <p>{title}</p>}
      <TextField
        value={searchInput}
        onChange={onSearchInputChange}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: false }}
        className={classes.searchInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon size={1.2} />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

export default ModalSearch;
