// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Refresh = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
    <path
      d="M14.185,6.185A.816.816,0,0,0,13.369,7a6.368,6.368,0,1,1-3.1-5.465H9.928a.815.815,0,1,0,0,1.631h2.535a.816.816,0,0,0,.815-.815V-.185A.816.816,0,0,0,12.463-1a.816.816,0,0,0-.815.815V.489A8.039,8.039,0,0,0,7-1,8,8,0,0,0-1,7a8,8,0,0,0,8,8,8,8,0,0,0,8-8A.816.816,0,0,0,14.185,6.185Z"
      transform="translate(1 1)"
    />
  </SvgIconStyled>
);

export default Refresh;
