import React from 'react';
import { Container, EmailValidator, Typography } from 'components';

function ExternalEmails({ state, setState, setInvalidEmails }) {
  const handleEmailsChange = emails => {
    setInvalidEmails(!emails.valid);
    setState(st => ({ ...st, external_users: emails.list }));
  };

  return (
    <>
      {/* Email Addresses to receive notification */}
      {Boolean(state?.push_notification?.allow_external_emails) && (
        <Container.Grid py={1.5} container alignItems="center">
          <Container.Grid item xs={12} pr={1}>
            <Typography.p style={{ fontSize: '1.5rem' }}>
              {`Additional external email addresses to receive ${state?.push_notification?.name} (separated by semicolon)*`}
            </Typography.p>
          </Container.Grid>
          <Container.Grid item xs={8}>
            <EmailValidator
              label="Email address*"
              initialEmails={state?.external_users || []}
              newEmails={handleEmailsChange}
            />
          </Container.Grid>
        </Container.Grid>
      )}
    </>
  );
}

export default ExternalEmails;
