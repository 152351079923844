import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';


const useStepIconStyles = makeStyles({
    root: {
      width: 35,
      height: 35,
      borderRadius: '50%',
      border: '1px solid var(--colorIcon)',
      color: 'var(--colorIcon);',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 500
    },
    activeCompleted: {
      borderColor: '#0cce6b',
      textAlign: 'center'
    },
    circle: {
      margin: '0 auto'
    },
    completed: {
      margin: '0 auto',
      width: 27,
      height: 27,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      borderColor: '#0cce6b',
      color: 'var(--whiteAndBlack)',
      position: 'relative',
      textAlign: 'center'
    },
    active: {
      borderColor: 'var(--buttonInfo)',
      textAlign: 'center'
    },
    check: {
      position: 'absolute',
      margin: '0 auto',
      width: 15,
      height: 15,
      borderRadius: '50%',
      backgroundColor: '#0cce6b',
      borderColor: '#0cce6b',
      color: 'var(--whiteAndBlack)',
      // top: '-8px',
      // left: '-8px',
      top: '-9px',
      right: '-11px'
    },
    checkActiveCompleted: {
      backgroundColor: 'var(--buttonInfo)',
      borderColor: 'var(--buttonInfo)'
    },
    num: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      // backgroundColor: '#0cce6b',
      backgroundColor: 'rgba(12, 206, 107, 0.2)',
      borderColor: '#0cce6b',
      // color: 'var(--whiteAndBlack)',
      color: '#02c863',
      display: 'inline-block',
      lineHeight: '28px',
      verticalAlign: 'middle'
    },
    numActive: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: 'rgba(25, 148, 230, 0.2)',
      borderColor: 'var(--buttonInfo)',
      color: 'var(--buttonInfo)',
      display: 'inline-block',
      lineHeight: '28px',
      verticalAlign: 'middle'
    },
    numActiveCompleted: {
      color: 'var(--buttonInfo)',
      backgroundColor: 'rgba(25, 148, 230, 0.2)',
      borderColor: 'var(--colorBase)'
    },
    checkMark: {
      verticalAlign: 'top'
    }
  });

const CustomStepIcon = ({active, completed, icon}) => {
    const classes = useStepIconStyles();
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.activeCompleted]: completed
        })}
      >
        {completed ? (
          <div className={classes.completed}>
            <div
              className={clsx(classes.check, {
                [classes.checkActiveCompleted]: completed && active
              })}
            >
              <Check className={classes.checkMark} />
            </div>
            <span
              className={clsx(classes.num, {
                [classes.numActiveCompleted]: completed && active
              })}
            >
              {icon}
            </span>
          </div>
        ) : (
          <div className={classes.circle}>
            <span
              className={clsx({
                [classes.numActive]: active,
                [classes.num]: completed
              })}
            >
              {icon}
            </span>
          </div>
        )}
      </div>
    );
  }

export default CustomStepIcon;

