import React from 'react';

const FolderChecked = props => (
  <svg {...props} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5428 49.95H7.38177C6.02844 49.95 4.92118 48.8427 4.92118 47.4894V20.792C4.92118 20.5705 4.94573 20.3491 5.01955 20.1522V7.38177C5.01955 6.02844 6.12681 4.92118 7.48014 4.92118H40.7965C42.1499 4.92118 43.2571 6.02844 43.2571 7.38177V14.173C43.2571 16.4859 45.1272 18.3314 47.4155 18.3314H56.5935C57.9469 18.3314 59.0541 19.414 59.0541 20.792V32.7258C59.5709 33.1687 60.0875 33.6363 60.5551 34.1284L60.6043 34.1776C61.0472 34.6451 61.4655 35.1372 61.8346 35.6539C61.8469 35.6785 61.8654 35.697 61.8838 35.7155C61.9022 35.7339 61.9207 35.7524 61.933 35.777C62.3021 36.2937 62.6712 36.835 62.9911 37.3763C63.0034 37.3886 63.0095 37.401 63.0157 37.4133C63.0218 37.4256 63.028 37.4379 63.0403 37.4502C63.3848 38.0407 63.68 38.6559 63.9753 39.271V20.792C63.9753 16.7074 60.6535 13.4102 56.5935 13.4102H48.1783V7.38177C48.1783 3.29719 44.8565 0 40.7965 0H7.48014C3.42016 0 0.0983695 3.29719 0.0983695 7.38177L0 47.4894C0 51.5493 3.3218 54.8711 7.38177 54.8711H29.5271C29.0103 53.2964 28.6659 51.6478 28.5428 49.95ZM47.1939 64C38.0405 64 30.585 56.7659 30.585 47.8831C30.585 39.0004 38.0405 31.7663 47.1939 31.7663C56.3473 31.7663 63.8029 39.0004 63.8029 47.8831C63.8029 56.7659 56.3473 64 47.1939 64ZM47.1939 36.6875C40.7472 36.6875 35.5061 41.7071 35.5061 47.8831C35.5061 54.0592 40.7472 59.0788 47.1939 59.0788C53.6407 59.0788 58.8817 54.0592 58.8817 47.8831C58.8817 41.7071 53.6407 36.6875 47.1939 36.6875ZM45.8408 47.5387L50.5405 42.8389C51.3525 42.027 52.755 42.15 53.7146 43.1096C54.6743 44.0692 54.8219 45.4964 54.0099 46.3084L47.3664 52.952C46.9235 53.4195 46.2836 53.5425 45.6193 53.4195C45.0287 53.3703 44.4628 53.1488 44.0199 52.7059L40.3537 49.0396C39.5663 48.2276 39.6893 46.8005 40.6489 45.8409C41.6086 44.8812 43.0357 44.7336 43.8477 45.5456L45.8408 47.5387Z"
      fill="#0069FF"
    />
  </svg>
);

export default FolderChecked;
