// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const DarkMode = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21.6 21.6" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h21.6v21H6z" />
    <g transform="translate(20.467 -26.075)">
      <g>
        <path d="M-9.7,47.7c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8S1.1,30.9,1.1,36.9S-3.7,47.7-9.7,47.7z M-9.7,27.7    c-5.1,0-9.2,4.1-9.2,9.2c0,5.1,4.1,9.2,9.2,9.2c5.1,0,9.2-4.1,9.2-9.2C-0.5,31.8-4.6,27.7-9.7,27.7z" />
      </g>
      <g>
        <path d="M-9.6,30.9v11.9c-3.7,0-6.6-2.7-6.6-6S-13.2,30.9-9.6,30.9z" />
      </g>
    </g>
  </SvgIconStyled>
);

export default DarkMode;
