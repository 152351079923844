import React, { useState } from 'react';
import TextField from './TextField';
import styled from 'styled-components';
import Switch from '../forms/Switch';
import { Typography } from '../types';
import { useFormContext, useWatch } from 'react-hook-form';

const EditorContainer = styled.div`
  width: 100%;

  fieldset {
    border: none !important;
  }
`;

const EditorToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid var(--borderDefault);
`;

const EditorToolbarTitle = styled(Typography.h3)`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--colorDefault);
`;

const PreviewPage = styled.iframe`
  width: 100%;
  height: 320px;
  border-radius: 5px;
  overflow: auto;
  border: none;
`;

const EditorWithPreviewPage = ({ label, name, ...props }) => {
  const { control } = useFormContext();
  const value = useWatch({ control, name });
  const [previewPage, setPreviewPage] = useState(false);
  return (
    <EditorContainer>
      <EditorToolbar>
        <EditorToolbarTitle>{label}</EditorToolbarTitle>
        <Switch
          label={'Page Preview'}
          checked={previewPage}
          onChange={({ target: { checked } }) => setPreviewPage(checked)}
        />
      </EditorToolbar>
      {previewPage ? <PreviewPage srcDoc={value} /> : <TextField multiline rows={15} name={name} {...props} />}
    </EditorContainer>
  );
};

export default EditorWithPreviewPage;
