import { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import useAdminNavigate from './useAdminNavigate';
import useAdminList from './useAdminList';
import { admin, copyTextToClipboard, encodeId, isValidEmail, snakeToCamelTitle, validURL } from 'helpers';
import PartnerApi from 'helpers/apis/partner';
import StatusCodes from 'http-status-codes';

const DEFAULT_USERS_LIMIT = 5000;

const usePartnerAddEdit = () => {
  const initialState = {
    data: {
      aad_enable: 0,
      active: 0,
      add_notification_email: '',
      branding_services: 0,
      change_billing_data: 0,
      contact_us_notification_email: '',
      csv_upload: 0,
      created: new Date().toISOString(),
      distributor: '',
      dwba: 0,
      dwm_coupon_licenses: 0,
      dwm_licenses: 0,
      dwm_upgrade: 0,
      email: '',
      is_demo_eva: 0,
      is_dwm: 0,
      bpp_tier_id: null,
      ebp_tier_picklist: [],
      enterprise_enabled: 0,
      new_sra: 0,
      allow_enterprise_migration: false,
      is_real: null,
      link: '',
      name: '',
      payment_exempt: 0,
      phishing_forward_email: '',
      phishing_forward_flag: 0,
      sales_tax_exempt: 0,
      send_purchase_notification: 0,
      sending_domain: '',
      sending_email: '',
      special_pricing_option: 0,
      unbrand: 0,
      user_count: null,
      users_limit: DEFAULT_USERS_LIMIT,
      tms_id: null,
      whitelabel: 0
    },
    darkWebLicense: {
      block_purchased: null,
      coupons_applied: [],
      licences_allocated: null
    },
    coupon: null,
    tmsConfiguration: false,
    logo: null,
    service_logo: null
  };
  const { method, id } = useParams();
  const notify = useNotify();
  const { navigateToList } = useAdminNavigate();
  const [state, setState] = useReducer(reducer, initialState);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDWMLicense, setLoadingDWMLicense] = useState(false);
  const [loadingCoupon, setLoadingCoupon] = useState({ loading: false, coupon: '' });
  const [loadingLogo, setLoadingLogo] =  useState(false);
  const [fieldsError, setFieldsError] = useState({
    email: false,
    link: false,
    add_notification_email: false,
    contact_us_notification_email: false,
  });
  const [dialogConfig, setDialog] = useState({ open: false, message: '', submit: null });
  const [similarPartners, setSimilarPartners] = useState([]);
  const [openArchiveDialog, setArchiveDialog] = useState(false);
  const [tmsOptions, setTmsOptions] = useState([]);
  const [error, setError] = useState(false);
  const createMode = method === 'create' && !id;
  const UseNotesList = () => useAdminList('partnerNotes', id);
  const notesData = ((id) => {
    if (id) return UseNotesList();
    return null;
  })(id);

  const disabledSave = fieldsError.email
                    || fieldsError.link
                    || !state.data.name?.trim()
                    || !state.data.email
                    || state.data.is_real === null
                    || !state.data.distributor
                    || (state.data.enterprise_enabled && !state.data.bpp_tier_id);

  const disabledAccountInfo = disabledSave ||
    (!createMode &&
      state.data.bpp_tier_id === partnerInfo.bpp_tier_id &&
      state.data.name === partnerInfo.name &&
      state.data.email === partnerInfo.email &&
      Boolean(state.data.active) === Boolean(partnerInfo.active) &&
      state.data.enterprise_enabled === partnerInfo.enterprise_enabled &&
      Boolean(state.data?.new_sra) === Boolean(partnerInfo?.new_sra) &&
      state.data.distributor === partnerInfo.distributor &&
      state.data.link === partnerInfo.link &&
      Boolean(state.data.is_real) === Boolean(partnerInfo.is_real) &&
      state.data.users_limit === partnerInfo.users_limit);

  const disabledTMSconfig = (state.tmsConfiguration && !state.data.tms_id) || state.data.tms_id === partnerInfo.tms_id;

  const disabledEmailsSave = fieldsError.add_notification_email || fieldsError.contact_us_notification_email || (
    state.data.add_notification_email === partnerInfo.add_notification_email
    && state.data.contact_us_notification_email === partnerInfo.contact_us_notification_email
  )

  const getPartnerInformation = useCallback(() => {
    setLoading(true);
    setError(false);

    admin.partners
      .information(id)
      .then((res) => {
        setPartnerInfo(res.data);
        setState({ type: 'SET_DATA', payload: res.data });
      })
      .catch((err) => {
        notify(err?.response?.data?.description || 'Failed to get Partner Information', 'error');
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [id, notify]);

  const getDarkWebLicense = useCallback(() => {
    setLoadingDWMLicense(true);

    admin.partners
      .getDarkWebLicense(id)
      .then((res) => setState({ type: 'SET_DWM', payload: res.data.data }))
      .catch((err) => notify(err?.response?.data?.description || 'Failed to get Dark web License Information', 'error'))
      .finally(() => setLoadingDWMLicense(false));
  }, [id, notify]);

  const getTmsOptions = useCallback(() => {
    admin.partners.getTmsOptions()
      .then(res => {
        const options = res.data.map(tms => {
          return { value: tms.tms_id, label: tms.tms_name }
        });
        setTmsOptions(options);
      })
      .catch((err) => notify(err?.response?.data?.description || 'Something went wrong', 'error'));
  }, [notify]);

  const getSimilarPartners = useCallback(() => {
    admin.partners
      .getSimilarPartners(partnerInfo.name)
      .then(res => setSimilarPartners(res.data.data))
      .catch((err) => notify(err?.response?.data?.description || 'Something went wrong', 'error'));
  }, [partnerInfo.name, notify]);

  useEffect(() => {
    if (id) {
      getPartnerInformation();
      if (method === 'edit') {
        getDarkWebLicense();
        getTmsOptions();
      }
    }
  }, [id, method, getPartnerInformation, getDarkWebLicense, getTmsOptions]);

  useEffect(() => {
    if (method === 'conflict' && partnerInfo.name) getSimilarPartners();
  }, [method, partnerInfo.name, getSimilarPartners]);

  const changePayment = (value) => {
    setState({ type: 'SET_VALUE', payload: { name: 'payment_exempt', value } });
    setDialog({ ...dialogConfig, open: false });
  };

  const changeDistributor = (payment_exempt, value) => {
    setState({
      type: 'SET_DATA',
      payload: {
        ...state.data,
        payment_exempt,
        distributor: value,
        enterprise_enabled: value !== 'BSN' ? 0 : state.data.enterprise_enabled
      }
    });
    setDialog({ ...dialogConfig, open: false });
  };

  const changeEnterpriseBPP = async value => {
    setDialog({ ...dialogConfig, open: false });
    setState({ type: 'SET_VALUE', payload: { name: 'enterprise_enabled', value } });
    if (!value) setState({ type: 'SET_VALUE', payload: { name: 'bpp_tier_id', value: partnerInfo.bpp_tier_id } });
  };

  const changeNewSRA = async value => {
    setDialog({ ...dialogConfig, open: false });
    setState({ type: 'SET_VALUE', payload: { name: 'new_sra', value } });
  };

  const openDialog = (name, value) => {
    switch (name) {
      case 'payment_exempt':
        setDialog({
          open: true,
          title: 'Please Confirm',
          message:
            'Changing the payment exempt flag will alter if a partner is charged. Are you sure you wish to proceed with this change?',
          submit: () => changePayment(value),
        });
        break;
      case 'distributor': {
        const payment_exempt = value === 'PAX8' || value === 'MP';
        setDialog({
          open: true,
          title: 'Please Confirm',
          message:
            'Please note that changing the distributor will also change the payment exempt flag. Are you sure you wish to proceed?',
          submit: () => changeDistributor(payment_exempt, value),
        });
        break;
      }
      case 'enterprise_enabled':
        if (!value) return changeEnterpriseBPP(false);

        setDialog({
          open: true,
          title: 'Please Confirm',
          message: 'Are you sure you would like to convert this partner to the Enterprise program',
          submit: () => changeEnterpriseBPP(true)
        });
        break;
      case 'new_sra':
        if (!value) return changeNewSRA(value);
        setDialog({
          open: true,
          title: 'Please Confirm',
          message:
            'Please confirm that you want to switch to the new Risk Assessment, you will not be able to switch back',
          submit: () => changeNewSRA(value)
        });
        break;
      default:
        break;
    }
  };

  const onSuccess = (message, action) => {
    switch (action) {
      case 'approve':
        notify('The new Partner has been created', 'success');
        break;
      case 'save':
        notify('Record has been saved', 'success');
        break;
      default:
        notify(message, 'success');
        break;
    }

    if (!createMode && state.data?.notes) {
      setState({ type: 'SET_VALUE', payload: { name: 'notes', value: null } });
      notesData.refetch();
    }

    if (createMode || method === 'conflict') navigateToList();
  };

  const update = (action) => {
    const data = action === 'approve' ? { ...state.data, active: true } : state.data;
    return admin.partners.update(id, data);
  };

  const create = () => {
    const { data, logo_mimeType } = state;

    const payload = {
      ...data,
      ...(logo_mimeType ? { logo_mimeType } : {})
    };

    return admin.partners.create(payload);
  };

  const onSuccessUpdatedDWM = (data) => {
    setState({ type: 'SET_DWM', payload: data.data });
    notify(data.message, 'info');
  };

  const updateDWMLicense = (type) => {
    setLoadingDWMLicense(true);
    admin.partners
      .updateDWMLicense(id, { type })
      .then((resp) => onSuccessUpdatedDWM(resp.data))
      .catch((err) => notify(err?.response?.data?.message || 'Something went wrong', 'error'))
      .finally(() => setLoadingDWMLicense(false));
  };

  const dispatch = {};

  dispatch.onCloseDialog = () => {
    setDialog({ ...dialogConfig, open: false });
  };

  dispatch.onChange = (name, value) => {
    if (['payment_exempt', 'enterprise_enabled', 'new_sra'].includes(name)) {
      openDialog(name, value);
      return;
    }
    setState({ type: 'SET_VALUE', payload: { name, value } });
  };

  dispatch.onChangeDistributor = (name, value) => {
    const allowChange = ((value === 'MP' || value === 'PAX8') && state.data.payment_exempt)
                        || (value === 'BSN' && !state.data.payment_exempt)

    if (!state.data.distributor) {
      // first selection on distributor
      const payment_exempt = value === 'PAX8' || value === 'MP';
      changeDistributor(payment_exempt, value)
    } else if (allowChange) {
      // no needs ask confirmation to change distributor
      changeDistributor(state.data.payment_exempt, value)
    } else {
      // will ask confirmation to change distributor
      openDialog(name, value);
    }
  }

  dispatch.onChangeEmailAddresses = (name, value) => {
    let result = value.replace(/\s/g, '').split(/;/);
    if (result[0] === '') result = [];

    const invalid = result.some((email) => !isValidEmail(email));

    setFieldsError({ ...fieldsError, [name]: invalid });
    dispatch.onChange(name, result.join(';'));
  };

  dispatch.onChangeEmail = (name, value) => {
    setFieldsError({ ...fieldsError, [name]: (value && !isValidEmail(value)) });
    dispatch.onChange(name, value);
  };

  dispatch.onChangeLink = (name, value) => {
    setFieldsError({ ...fieldsError, [name]: (value && !validURL(value)) });
    dispatch.onChange(name, value);
  };

  dispatch.onIncrementUsersLimit = () => {
    setState({
      type: 'SET_VALUE',
      payload: {
        name: 'users_limit',
        value: state.data.users_limit + DEFAULT_USERS_LIMIT
      }
    });
  };

  dispatch.onDecrementUsersLimit = () => {
    if (state.data.users_limit === DEFAULT_USERS_LIMIT) return;
    setState({
      type: 'SET_VALUE',
      payload: {
        name: 'users_limit',
        value: state.data.users_limit - DEFAULT_USERS_LIMIT
      }
    });
  }

  dispatch.onChangeCoupon = (value) => {
    setState({ type: 'SET_COUPON', payload: value });
  };

  dispatch.applyCoupon = () => {
    setLoadingCoupon(preview => ({ ...preview, loading: true }));
    admin.partners
      .applyCoupon(id, { coupon: state.coupon })
      .then((resp) => onSuccessUpdatedDWM(resp.data))
      .catch((err) => notify(err?.response?.data?.message || 'Something went wrong', 'error'))
      .finally(() => {
        setLoadingCoupon(preview => ({ ...preview, loading: false }));
        dispatch.onChangeCoupon('');
      });
  };

  dispatch.removeCoupon = (coupon) => {
    setLoadingCoupon({ loading: true, coupon });
    admin.partners
      .deleteCoupon(id, { coupon })
      .then((resp) => onSuccessUpdatedDWM(resp.data))
      .catch((err) => notify(err?.response?.data?.message || 'Something went wrong', 'error'))
      .finally(() => setLoadingCoupon({ loading: false, coupon: '' }));
  };

  dispatch.addDWMBlocks = () => {
    updateDWMLicense('add_block_purchase');
  };

  dispatch.removeDWMBlocks = () => {
    if (state.darkWebLicense.block_purchased === 0) return;
    updateDWMLicense('remove_block_purchase');
  };

  dispatch.onChangeTmsConfiguration = (checked) => {
    if (!checked) {
      setState({ type: 'SET_VALUE', payload: { name: 'tms_id', value: null } });
    }
    setState({ type: 'SET_TMS_CONFIG', payload: checked });
  };

  dispatch.save = async (action = 'default') => {
    try {
      setLoading(true);
      if (disabledSave) {
        notify('Please check the fields in the Account Information section', 'warning');
        return;
      }

      if (fieldsError.add_notification_email || fieldsError.add_notification_email) {
        notify('Please check the fields in the Email Notifications section', 'warning');
        return;
      }

      if (!createMode && state.data.enterprise_enabled && state.data.allow_enterprise_migration) {
        await PartnerApi.migrateToEnterprise(id, state.data.bpp_tier_id);
        setState({ type: 'SET_VALUE', payload: { name: 'allow_enterprise_migration', value: false } });
      }
      const request = createMode ? create() : update(action);

      const resp = await request;

      setPartnerInfo(prevState => ({
        ...prevState,
        payment_exempt: state.data.payment_exempt,
        add_notification_email: state.data.add_notification_email,
        contact_us_notification_email: state.data.contact_us_notification_email,
        new_sra: state.data.new_sra ? 1: prevState.new_sra,
        is_real : state.data.is_real,
        distributor : state.data.distributor,
        users_limit : state.data.users_limit,
        active : state.data.active,
        tms_id : state.data.tms_id,
      }));

      if (resp.data?.logo) uploadLogo(resp.data.logo, resp.data.message);
      if (resp.data?.service_logo) uploadLogo(resp.data.service_logo, resp.data.message);
      else onSuccess(resp.data?.message || resp.data, action);
    } catch (err) {
      let message = err?.response?.data?.description;
      if (typeof message === 'object') {
        message = message?.message ?? '';
      }
      notify(message || 'Something went wrong', 'error');
    } finally {
      setLoading(false);
    }
  };

  dispatch.archive = () => {
    admin.partners
      .delete([id])
      .then(() => {
        navigateToList();
        notify('Record has been archived and Partner has not been created', 'info');
      })
      .catch((err) => notify(err?.response?.data?.description || 'Something went wrong', 'error'));
  };

  dispatch.setOpenArchiveDialog = (value) => {
    setArchiveDialog(value);
  };

  dispatch.copyValue = (id) => {
    copyTextToClipboard(id);
    notify('Partner Id has been copied to your clipboard');
  }

  /* -------------------------------------------------------------------------- */
  /*                                LOGOS                                       */
  /* -------------------------------------------------------------------------- */

  const savePartnerLogoFileName = async (partnerId, fileName) => {
    const res = await admin.partners.updatePartnerProfile(partnerId, { fileName })

    setState({
      type: 'SET_LOGO',
      payload: {
        logo: { ...state.logo, fileName, isChanged: false }
      }
    });

    setState({
      type: 'SET_VALUE',
      payload: {
        name: 'logo',
        value: res.data?.logo
      }
    });
  };


  const saveServiceLogoFileName = async (partnerId, serviceLogo) => {
    const res = await admin.partners.updatePartnerProfileServiceLogo(partnerId, { serviceLogo });

    setState({
      type: 'SET_SERVICE_LOGO',
      payload: {
        service_logo: { ...state.service_logo, fileName: serviceLogo, isChanged: false }
      }
    });

    setState({
      type: 'SET_VALUE',
      payload: {
        name: 'service_logo',
        value: res.data?.service_logo
      }
    });
  };


  const uploadLogo = async ({ fields, url }, message, isServiceLogo) => {
    const formData = new FormData();
    formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
    formData.append('key', fields.key);
    formData.append('policy', fields.policy);
    formData.append('signature', fields.signature);
    formData.append('x-amz-security-token', fields['x-amz-security-token']);
    formData.append('file', isServiceLogo ? state.service_logo.file : state.logo.file);

    const fileName = fields.key.substring(fields.key.lastIndexOf('/') + 1);
    const partnerId = createMode ? encodeId(Number(fields.key.split('/')[3])) : id;
    const response = await PartnerApi.uploadLogo(url, formData, null, 'canceltokensource');
    if (response.status === StatusCodes.NO_CONTENT) {
      isServiceLogo
        ? await saveServiceLogoFileName(partnerId, fileName)
        : await savePartnerLogoFileName(partnerId, fileName);
      if (createMode) onSuccess(message);
    }
  };


  const presignedUploadLogo = async () => {
    const isLogoChanged = Boolean(state.logo?.isChanged)
    const isServiceLogoChanged = Boolean(state.service_logo?.isChanged)

    if (!isLogoChanged && !isServiceLogoChanged) {
      await dispatch.save();
      return true;
    }

    try {
      setLoadingLogo(true);
      const types = [];
      isLogoChanged && types.push('type=logo');
      isServiceLogoChanged && types.push('type=service_logo');

      const response = await PartnerApi.getPresignedPost(
        state.logo_mimeType || state.service_logo_mimeType,
        id,
        types.join('&'),
        isServiceLogoChanged ? state.service_logo_mimeType : ''
      );
      if (response.data.logo) await uploadLogo(response.data.logo);
      if (response.data.service_logo) await uploadLogo(response.data.service_logo, '', true);
      return true;
    } catch {
      notify('Failed To Save Logo', 'error');
      return false;
    } finally {
      setLoadingLogo(false);
    }
  };


  dispatch.pressignedUploadFile = (partnerLogoChange, serviceLogoChange) => {
    presignedUploadLogo().then(uploadSuccess => {
      if (uploadSuccess) {
        const successMessages = [
          '',
          'Partner logo has been successfully updated',
          'Service logo has been successfully updated',
          'Partner and Service logo have been successfully updated'
        ];

        const index = (partnerLogoChange ? 1 : 0) + (serviceLogoChange ? 2 : 0);
        const successMessage = successMessages[index];

        if (successMessage.trim() !== '') {
          notify(successMessage);
        }
      }
    });
  };


  dispatch.onChangeLogo = (name, url, file) => {
    if (!url && !file) {
      setState({ type: 'DELETE_LOGO', payload: { name } });
      return;
    }

    setState({
      type: 'SET_LOGO',
      name: 'logo',
      payload: {
        logo: { file, url, fileName: file.name, isChanged: true }
      }
    });
  };


  dispatch.onChangeServiceLogo = (name, url, file) => {
    if (!url && !file) {
      setState({ type: 'DELETE_SERVICE_LOGO', payload: { name } });
      return;
    }

    setState({
      type: 'SET_SERVICE_LOGO',
      name: 'service_logo',
      payload: {
        service_logo: { file, url, fileName: file.name, isChanged: true }
      }
    });
  };


  dispatch.deleteLogo = (fieldName: 'logo' | 'service_logo') => {
    const data = { ...state.data, [fieldName]: null };
    const logoName = fieldName === 'logo' ? 'partner_logo' : fieldName;

    admin.partners.update(id, data)
      .then((resp) => notify(`The ${snakeToCamelTitle(logoName)} has been deleted`, 'info'))
      .catch((err) => notify(err?.response?.data?.description || 'Something went wrong', 'error'));
  }


  return {
    record: state.data,
    darkWebLicense: state.darkWebLicense,
    coupon: state.coupon,
    error,
    loading,
    loadingDWMLicense,
    loadingCoupon,
    loadingLogo,
    logo: state.logo,
    service_logo: state.service_logo,
    dispatch,
    createMode,
    disabledAccountInfo,
    disabledTMSconfig,
    disabledEmailsSave,
    disabledSave,
    dialogConfig,
    fieldsError,
    openArchiveDialog,
    partnerId: id,
    similarPartners,
    tmsConfiguration: state.tmsConfiguration,
    tmsOptions,
    notesData,
    partnerInfo
  };
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload,
        tmsConfiguration: Boolean(action.payload?.tms_id),
      };
    case 'SET_DWM':
      return {
        ...state,
        darkWebLicense: action.payload,
      };
    case 'SET_COUPON':
      return {
        ...state,
        coupon: action.payload,
      };
    case 'SET_TMS_CONFIG':
      return {
        ...state,
        tmsConfiguration: action.payload,
      };
    case 'SET_VALUE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.value
        }
      };
    case 'SET_LOGO':
      return {
        ...state,
        logo: action.payload.logo,
        ...(
          action.payload.logo.file
          ? { logo_mimeType: action.payload.logo.file.type }
          : {}
        )
      }
    case 'SET_SERVICE_LOGO':
      return {
        ...state,
        service_logo: action.payload.service_logo,
        ...(
          action.payload.service_logo.file
          ? { service_logo_mimeType: action.payload.service_logo.file.type }
          : {}
        )
      }
    case 'DELETE_LOGO':
      return {
        ...state,
        logo: null,
        data: {
          ...state.data,
          [action.payload.name]: null,
        }
      }
    case 'DELETE_SERVICE_LOGO':
      return {
        ...state,
        service_logo: null,
        data: {
          ...state.data,
          [action.payload.name]: null,
        }
      }
  }
}

export default usePartnerAddEdit;
