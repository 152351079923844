// @flow
import React, { type ComponentType } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Base: ComponentType<
  *
> = styled(({ p, pt, pr, pb, pl, m, mt, mr, mb, ml, b, bt, br, bb, bl, block, ...props }) => <Box {...props} />)`
  && {
    display: ${({ block }) => (block ? 'block' : 'flex')};
    ${({ p }) => p && `padding: calc(${p} * var(--spacing))`};
    ${({ pt }) => pt && `padding-top: calc(${pt} * var(--spacing))`};
    ${({ pr }) => pr && `padding-right: calc(${pr} * var(--spacing))`};
    ${({ pb }) => pb && `padding-bottom: calc(${pb} * var(--spacing))`};
    ${({ pl }) => pl && `padding-left: calc(${pl} * var(--spacing))`};

    ${({ m }) => m && `margin: calc(${m} * var(--spacing))`};
    ${({ mt }) => mt && `margin-top: calc(${mt} * var(--spacing))`};
    ${({ mr }) => mr && `margin-right: calc(${mr} * var(--spacing))`};
    ${({ mb }) => mb && `margin-bottom: calc(${mb} * var(--spacing))`};
    ${({ ml }) => ml && `margin-left: calc(${ml} * var(--spacing))`};

    ${({ b }) => b && `border: ${b}px solid var(--borderDefault)`};
    ${({ bt }) => bt && `border-top: ${bt}px solid var(--borderDefault)`};
    ${({ br }) => br && `border-right: ${br}px solid var(--borderDefault)`};
    ${({ bb }) => bb && `border-bottom: ${bb}px solid var(--borderDefault)`};
    ${({ bl }) => bl && `border-left: ${bl}px solid var(--borderDefault)`};
  }
`;

export default Base;
