// @flow

const policies = {
  title: 'Add Security Policies & Procedures',
  component: 'clients/ClientsEditPoliciesModal',
  noQuery: true,
  idRequired: true,
  buttons: [],
  initialState: {
    name: '',
    description: '',
    details: '',
    file: '',
    step: 0
  }
};

export default policies;
