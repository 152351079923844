import React from 'react';
import { useSelector } from 'react-redux';
import {
  AvailableLinksItem,
  LogoutButton,
  PageUnavailableContainer,
  PageUnavailableContent,
  AvailableLinksItemWrap,
  PageUnavailableDescription
} from './style';
import Unavailable from '../../icons/Unavailable';

const AVAILABLE_LINKS = [
  {
    label: 'My Dashboard',
    linkTo: '/myDashboard/dashboard',
    app: 'myDashboard',
    tab: 'dashboard'
  },
  {
    label: 'Micro Training',
    linkTo: '/myDashboard/microTrainings',
    app: 'myDashboard',
    tab: 'microTrainings'
  },
  {
    label: 'Training',
    linkTo: '/myDashboard/securityTraining',
    app: 'myDashboard',
    tab: 'securityTraining'
  },
  {
    label: 'Dark Web',
    linkTo: '/myDashboard/darkWeb',
    app: 'myDashboard',
    tab: 'darkWeb'
  },
  {
    label: 'Newsletters',
    linkTo: '/myDashboard/newsletters',
    app: 'myDashboard',
    tab: 'newsletters'
  },
  {
    label: 'Newsfeed',
    linkTo: '/newsfeed/dashboard',
    app: 'newsfeed',
    tab: ''
  },
  {
    label: 'My Company Dashboard',
    linkTo: '/myCompany/dashboard',
    app: 'myCompany',
    tab: 'dashboard'
  },
  {
    label: 'Employees',
    linkTo: '/myCompany/employees',
    app: 'myCompany',
    tab: 'employees'
  },
  {
    label: 'Clients',
    linkTo: '/clients',
    app: 'clients',
    tab: ''
  }
];

const AvailableLinks = () => {
  const userAccessApps = useSelector(({ bsn }) => bsn?.user?.access?.apps);

  return (
    <>
      {AVAILABLE_LINKS.map(link => {
        const { label, linkTo, app, tab } = link;
        const hasAccess = tab ? userAccessApps?.[app]?.[tab] : userAccessApps?.[app];
        return hasAccess ? (
          <AvailableLinksItemWrap key={label}>
            <AvailableLinksItem to={linkTo}>{label}</AvailableLinksItem>
          </AvailableLinksItemWrap>
        ) : null;
      })}
    </>
  );
};

const PageUnavailable = () => {
  const user = useSelector(({ bsn }) => bsn.user);

  const userProductName = user?.profile?.product_name;

  const isUserHaveNotMobilePages =
    userProductName === 'HIPAA Compliance' || userProductName === 'Unlimited Cybersecurity Training';

  return (
    <PageUnavailableContainer>
      <PageUnavailableContent>
        <Unavailable className="page-unavailable" />
        <h3>Page is unavailable!</h3>
        <PageUnavailableDescription>
          {!isUserHaveNotMobilePages
            ? 'This page cannot be run on your device.'
            : 'Here are a few suggested links below'}
        </PageUnavailableDescription>
        {!isUserHaveNotMobilePages ? <LogoutButton to="/logout">Log out</LogoutButton> : <AvailableLinks />}
      </PageUnavailableContent>
    </PageUnavailableContainer>
  );
};

export default PageUnavailable;
