// @flow

const workPlanFinding = {
  title: 'Risk Assessment Work Plan',
  component: 'myCompany/workPlan/CompanyWorkPlanModalFinding',
  requireRowId: true,
  noQuery: true,
  buttons: {
    cancel: {
      label: 'Cancel'
    },
    submit: false,
    other: []
  },
  initialState: null
};

export default workPlanFinding;
