import React, { useEffect, useState, useMemo } from 'react';
import { useNotify } from 'react-admin';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import { Paper, makeStyles, Box } from '@material-ui/core';
import * as usersAPI from 'helpers/apis/services/usersAPI';
import { LoadingStyled, ChartHalfCircle, ChartCircle, ChartBar } from 'components';
import { useBreakpoint } from 'hooks';
import { CustomAvatar } from './index';
import { getAvatar } from '../../newsfeedHelpers';


const useStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: '1px solid var(--borderDefault)'
  },
  topInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    color: 'var(--colorDark)',
    fontSize: '14px',
    fontWeight: 500,
    margin: 0
  },
  slider: {
    padding: '0 30px 35px',
    '& .swiper-button-next': {
      marginTop: -40,
      height: 30,
      right: 5,
      color: 'var(--colorDark)',
      '&:after': {
        fontSize: 22
      }
    },
    '& .swiper-button-prev': {
      marginTop: -40,
      height: 30,
      left: 5,
      color: 'var(--colorDark)',
      '&:after': {
        fontSize: 22
      }
    },
    '& .swiper-pagination-bullet': {
      background: 'var(--colorDark)'
    }
  },
  halfCircleChart: {
    width: 230,
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 200
    }
  },
  circleChart: {
    width: 250,
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 220
    }
  },
  progressWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: 10,
    '& > div': {
      width: '49%',
      paddingTop: 20,
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      '& > div': {
        margin: '0 0 0 -20px',
        width: 'calc(100% + 20px)',
        [theme.breakpoints.down('md')]: {
          width: 'calc(100% + 35px)',
          margin: '-15px 0 -25px -30px'
        }
      }
    }
  },
  progressTitle: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    color: 'var(--colorDark)',
    margin: '0 0 -5px'
  }
}));

const titles = ['Employee Secure Score (ESS)', 'External Data Breaches', 'Training Statistics', 'Training Statistics']; // The second 'Training Statistics' is for smaller devices as we are adding a new slide to fit the charts

const MyStats = ({ userName }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(titles[0]);
  const tabletView = useBreakpoint('md');

  useEffect(() => {
    setLoading(true);
    usersAPI
      .getEssStats()
      .then(res => {
        setChartData(res.data);
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [notify]);

  const setSliderTitle = swiper => {
    setTitle(titles[swiper.realIndex]);
  };

  const phishingAttackFailRateMemo = useMemo(
    () => (
      <div>
        <p className={classes.progressTitle}>Phishing Fail Rate</p>
        <ChartBar
          height={130}
          labels
          invert
          chart={[
            {
              name: chartData?.phishing_attack_fail_rate?.display_value,
              value: +chartData?.phishing_attack_fail_rate?.value
            },
            {
              name: '100%',
              value: 100
            }
          ]}
        />
      </div>
    ),
    [chartData?.phishing_attack_fail_rate?.display_value, chartData?.phishing_attack_fail_rate?.value]
  );

  const securityTrainingMemo = useMemo(
    () => (
      <div>
        <p className={classes.progressTitle}>Average Security Training Score</p>
        <ChartBar
          height={130}
          labels
          colorType1
          chart={[
            {
              name: chartData?.security_training?.display_value,
              value: +chartData?.security_training?.value
            },
            {
              name: '100%',
              value: 100
            }
          ]}
        />
      </div>
    ),
    [chartData?.security_training?.display_value, chartData?.security_training?.value]
  );

  const avgMicroQuizMemo = useMemo(
    () => (
      <div>
        <p className={classes.progressTitle}>Average Micro Training Score</p>
        <ChartBar
          height={130}
          labels
          colorType1
          chart={[
            {
              name: chartData?.avg_micro_quiz?.display_value,
              value: chartData?.avg_micro_quiz?.value
            },
            {
              name: '100%',
              value: 100
            }
          ]}
        />
      </div>
    ),
    [chartData?.avg_micro_quiz?.display_value, chartData?.avg_micro_quiz?.value]
  );

  const microQuizTakenMemo = useMemo(
    () => (
      <div>
        <p className={classes.progressTitle}>Micro Training Completion</p>
        <ChartBar
          height={130}
          labels
          colorType1
          chart={[
            {
              name: chartData?.micro_quiz_taken?.taken?.display_value,
              value: chartData?.micro_quiz_taken?.taken?.value
            },
            {
              name: chartData?.micro_quiz_taken?.total?.display_value,
              value: +chartData?.micro_quiz_taken?.total?.value
            }
          ]}
        />
      </div>
    ),
    [
      chartData?.micro_quiz_taken?.taken?.display_value,
      chartData?.micro_quiz_taken?.taken?.value,
      chartData?.micro_quiz_taken?.total?.display_value,
      chartData?.micro_quiz_taken?.total?.value
    ]
  );

  return (
    <Box mb={3}>
      <Paper elevation={0}>
        <Box>
          <Box px={2.5}>
            <Box py={2} className={classes.borderBottom}>
              <div className={classes.topInfo}>
                <CustomAvatar>{getAvatar(userName)}</CustomAvatar>
                <Box ml={1.5}>
                  <p className={classes.subtitle}>{title}</p>
                </Box>
              </div>
            </Box>
          </Box>

          {loading ? (
            <LoadingStyled />
          ) : (
            <Box pb={1.5}>
              <Swiper
                spaceBetween={30}
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                navigation
                loop
                className={classes.slider}
                onSlideChange={setSliderTitle}
              >
                {/* Employee Secure Score (ESS) */}
                <SwiperSlide>
                  <ChartHalfCircle
                    className={classes.halfCircleChart}
                    showTitle={false}
                    labels
                    chart={{
                      chartData: [
                        {
                          name: chartData?.ess?.display_value,
                          value: (chartData?.ess?.value * 100) / 800
                        }
                      ]
                    }}
                  />
                </SwiperSlide>

                {/* External Data Breaches */}
                <SwiperSlide>
                  <ChartCircle
                    className={classes.circleChart}
                    labels
                    chart={{
                      chartData: [
                        {
                          name: chartData?.external_data_breaches?.display_value,
                          value: +chartData?.external_data_breaches?.value
                        }
                      ]
                    }}
                  />
                </SwiperSlide>

                {/* Progress charts */}
                <SwiperSlide>
                  <div className={classes.progressWrap}>
                    {/* Phishing Attack Fail Rate */}
                    {phishingAttackFailRateMemo}

                    {/* Security Training */}
                    {securityTrainingMemo}

                    {!tabletView && (
                      <>
                        {/* Average Micro Quiz Score */}
                        {avgMicroQuizMemo}

                        {/* Micro Quiz Taken */}
                        {microQuizTakenMemo}
                      </>
                    )}
                  </div>
                </SwiperSlide>

                {tabletView && (
                  <SwiperSlide>
                    <div className={classes.progressWrap}>
                      {/* Average Micro Quiz Score */}
                      {avgMicroQuizMemo}

                      {/* Micro Quiz Taken */}
                      {microQuizTakenMemo}
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default MyStats;
