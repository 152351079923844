// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Sort = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 23 24" role="img" aria-hidden="false">
      <path d="M7.48548 5.1895C7.84544 5.54947 8.43614 5.54947 8.7961 5.1895C9.15607 4.82954 9.15607 4.23882 8.7961 3.87886L5.84253 0.925296L5.18725 0.269974C4.82728 -0.0899913 4.23653 -0.0899913 3.87656 0.269974L3.22128 0.925296L0.267703 3.87886C0.0923349 4.06346 0 4.2942 0 4.53418C0 4.77416 0.0923349 5.0049 0.267703 5.1895C0.627668 5.54947 1.21836 5.54947 1.57833 5.1895L3.60892 3.15893V23.077C3.60892 23.5847 4.02426 24 4.5319 24C5.03955 24 5.45489 23.5847 5.45489 23.077V3.16816L7.48548 5.1895Z" fill="#535353"/>
      <path d="M14.639 18.7944C14.279 18.4344 13.6883 18.4344 13.3283 18.7944C12.9684 19.1543 12.9684 19.745 13.3283 20.105L16.2819 23.0586L16.9372 23.7139C17.2972 24.0738 17.8879 24.0738 18.2479 23.7139L18.9032 23.0586L21.8568 20.105C22.0321 19.9204 22.1244 19.6896 22.1244 19.4497C22.1244 19.2097 22.0321 18.9789 21.8568 18.7944C21.4968 18.4344 20.9061 18.4344 20.5461 18.7944L18.5156 20.8249V0.9253C18.5156 0.417656 18.1002 0.00231171 17.5926 0.00231171C17.0849 0.00231171 16.6696 0.417656 16.6696 0.9253V20.8249L14.639 18.7944Z" fill="#535353"/>
  </SvgIconStyled>
);


export default Sort;
