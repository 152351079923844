// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Check = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 21 16" role="img" aria-hidden="false">
    <path d="M19.77 1.24069C19.41 0.880687 18.8285 0.880687 18.4685 1.24069L6.53852 13.1707L1.57157 8.20373C1.21157 7.84373 0.63 7.84373 0.27 8.20373C-0.09 8.56373 -0.09 9.14527 0.27 9.50527L5.87388 15.1091C5.96619 15.2014 6.07698 15.266 6.18775 15.3122C6.30775 15.3584 6.42772 15.3953 6.55695 15.3953C6.79695 15.3953 7.02774 15.303 7.21235 15.1276L19.7885 2.55146C20.13 2.18223 20.13 1.60069 19.77 1.24069Z"/>
  </SvgIconStyled>
);

export default Check;
