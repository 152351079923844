import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

import ClientsUsersAddButton from '../ClientsUsersAddButton';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const StyledButton = styled(Button)`
  && {
    width: 90%;
    background-color: ${props => !props.disabled && '#082F49'};
    text-transform: none;
    &:focus,
    &:hover {
      background-color: ${props => !props.disabled && '#082F49'};
      text-transform: none;
    }
  }
`;

const UsersListNewUserMobile = ({ setRefresh ,...rest}) => {
  const RenderedButton = ({ children, ...props }) => {
    return (
      <Container>
        <StyledButton {...props} variant="contained" color="secondary">
          {children}
        </StyledButton>
      </Container>
    );
  };
  return (
    <ClientsUsersAddButton
      {...rest}
      tab="addUser"
      label="New User"
      setRefresh={value => {
        setRefresh(false);
        setRefresh(true);
      }}
      RenderedButton={RenderedButton}
    />
  );
};

export default UsersListNewUserMobile;
