// @flow

import React, { memo, useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { Modal3 } from 'components';
import { Button } from '@trustsecurenow/components-library';
import { LazyIcon } from 'components/icons';

type ClientsUsersAddButtonType = {
  tab: string,
  label: string,
  setRefetch: Function
};

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const ClientsUsersAddButton = ({
  tab,
  label,
  setRefetch,
  RenderedButton = null,
  transformBeforeSubmit = null,
  color,
  startIcon
}: ClientsUsersAddButtonType) => {
  const app = 'clients';
  const [open, dispatchOpen] = useState(false);

  const setOpen = (value: boolean) => {
    dispatchOpen(value);
  };

  const refetch = () => {
    setRefetch(true);
    setRefetch(false);
  };

  return (
    <>
      {!RenderedButton ? (
        <Button startIcon={startIcon} onClick={() => setOpen(true)} sx={{ mr: 1 }}>
          {label}
        </Button>
      ) : (
        <RenderedButton onClick={() => setOpen(true)}>
          <LazyIcon component="Plus" color="colorCommonWhite" mr={1} size={0.7} />
          {label}
        </RenderedButton>
      )}
      {open && (
        <Modal3
          open={open}
          setOpen={setOpen}
          app={app}
          tab={tab}
          createNew
          TransitionComponent={Transition}
          transformBeforeSubmit={transformBeforeSubmit}
          refetchOnCreate={refetch}
        />
      )}
    </>
  );
};

export default memo<ClientsUsersAddButtonType>(ClientsUsersAddButton);
