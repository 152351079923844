import React from 'react';
import LabelAndInfo from '../../../../components/types/LabelAndInfo';

const phishing = {
  defaultSortValue: {
    order: 'desc'
  },
  selecting: true,
  collapse: true,
  selectingAll: false,
  rowClick: true,
  sort: ['campaign_name', 'frequency', 'test', 'run_date', 'status', 'pfr'],
  head: [
    {
      id: 'campaign_name',
      label: 'Campaign Name',
      align: 'left'
    },
    {
      id: 'frequency',
      label: 'Frequency',
      align: 'left'
    },
    {
      id: 'test',
      label: 'Test',
      align: 'left'
    },
    {
      id: 'run_date',
      label: 'Run Date',
      align: 'left'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
    {
      id: 'pfr',
      label: <LabelAndInfo weight={300} label={'PFR %'} tooltip={'Phishing Fail Rate'} />,
      align: 'left'
    },
    {
      id: 'error_key',
      label: 'Errors',
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      align: 'left'
    }
  ]
};

export default phishing;
