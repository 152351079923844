import React from "react"
import CardPageLayoutHeader from './CardPageLayoutHeader';
import CardPageLayoutFooter from './CardPageLayoutFooter';
import {
  CardPageLayoutContainer,
  CardPageLayoutBody,
} from '../../style';

const CardPageLayout = ({children}) => {


  return (
    <CardPageLayoutContainer>
      <CardPageLayoutHeader />

      <CardPageLayoutBody
        spacing={2}
        container
        alignItems={"flex-start"}
        alignContent={"flex-start"}
      >
        {children}
      </CardPageLayoutBody>

      <CardPageLayoutFooter />
    </CardPageLayoutContainer>
  )
}


export default CardPageLayout
