// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { LoadingStyled } from 'components';
import { makeStyles, Divider, IconButton, Dialog, DialogTitle, Box, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { updateFeed } from 'helpers/action';
import UserAvatar from '../common/UserAvatar';

const useStyles = makeStyles(theme => ({
  list: {
    padding: '15px',
    overflowY: 'auto',
    height: '30vh',
    [theme.breakpoints.down('sm')]: {
      height: '60vh'
    }
  },
  image: {
    maxWidth: '100%'
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: 'var(--colorDefault)'
  }
}));

const ReactionsModal = ({ open, close, id, type, likesCount }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reacters, setReacters] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const fetchMoreDataForPosts = () => {
    newsFeed
      .getPostsLikers(id, reacters.length)
      .then(res => {
        const reactersArray = [...reacters, ...res.data];
        if (res.data.length !== 0) {
          setReacters(reactersArray);
        } else {
          setHasMore(false);
          const dataLength = reactersArray.length;
          if (dataLength !== likesCount) {
            dispatch(updateFeed(id, { likes_count: dataLength }));
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchMoreDataForComments = () => {
    newsFeed
      .getCommentsLikers(id, reacters.length)
      .then(res => {
        if (res.data.length !== 0) {
          const reactersArray = [...reacters, ...res.data];
          setReacters(reactersArray);
        } else setHasMore(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchInitialDataForPosts = () => {
    newsFeed
      .getPostsLikers(id, 0)
      .then(res => {
        setReacters(res.data);
        const dataLength = res.data.length;
        if (dataLength === 10) setHasMore(true);
        else if (dataLength !== likesCount) {
          dispatch(updateFeed(id, { likes_count: dataLength }));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchInitialDataForComments = () => {
    newsFeed
      .getCommentsLikers(id, 0)
      .then(res => {
        setReacters(res.data);
        if (res.data.length === 10) setHasMore(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchData = () => {
    switch (type) {
      case 'comment':
        fetchMoreDataForComments();
        break;
      case 'post':
        fetchMoreDataForPosts();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (type) {
      case 'comment':
        fetchInitialDataForComments();
        break;
      case 'post':
        fetchInitialDataForPosts();
        break;

      default:
        break;
    }
  }, []);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle style={{ position: 'relative' }} id="dialog-title">
          <Typography className={classes.title}>Likes</Typography>
          <IconButton className={classes.closeBtn} id="ModalHeaderButtonClose" onClick={close}>
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {reacters ? (
          <div className={classes.list} id="scrollableDiv1">
            <InfiniteScroll
              dataLength={reacters.length} // This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv1"
              loader={<LoadingStyled />}
            >
              {reacters.map(reacter => (
                <div key={reacter.like_id}>
                  <Box display="flex" alignItems="center" px={{ xs: 0, md: 1 }} py={1}>
                    <UserAvatar user={reacter.user} timestamp={reacter.timestamp} />
                  </Box>
                  <Divider />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <LoadingStyled />
        )}
      </Dialog>
    </>
  );
};

export default ReactionsModal;
