// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Download = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 15.541 15.512" role="img" aria-hidden="false">
    <g transform="translate(-41.2 -41.5)">
      <path d="M56.531,257.315a.734.734,0,0,0-1.253.519v2.755H42.668v-2.755a.734.734,0,0,0-1.468,0v3.44a.58.58,0,0,0,.01.132.736.736,0,0,0,.729.656H56a.739.739,0,0,0,.719-.592.783.783,0,0,0,.024-.191v-3.44A.775.775,0,0,0,56.531,257.315Z" transform="translate(0 -205.05)"/>
      <path d="M112.356,52.95l.02.02a.835.835,0,0,0,.475.24c.015,0,.029,0,.044,0l.083,0a.532.532,0,0,0,.088,0c.015,0,.029,0,.044,0a.85.85,0,0,0,.475-.24s.015-.01.02-.02l3.606-3.606a.876.876,0,0,0,.015-1.214.862.862,0,0,0-1.214,0L113.843,50.3V42.356a.846.846,0,0,0-.25-.607.875.875,0,0,0-.607-.25.86.86,0,0,0-.856.856V50.3l-2.168-2.168a.858.858,0,0,0-1.214,1.214Z" transform="translate(-64.007)"/>
    </g>
  </SvgIconStyled>
);

export default Download;
