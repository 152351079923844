// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Script = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 18" role="img" aria-hidden="false">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.72008 17.25C3.51842 17.25 3.32397 17.1636 3.17992 17.0051C2.92065 16.7027 2.94946 16.2489 3.24474 15.9896L11.2103 9.00362L3.24474 2.01038C2.94225 1.75111 2.91345 1.29017 3.17992 0.994886C3.4392 0.6996 3.90013 0.663589 4.19542 0.930067L12.1609 7.9161C12.4706 8.18978 12.6507 8.58589 12.6507 8.99641C12.6507 9.40693 12.4706 9.80305 12.1609 10.0767L4.19542 17.0628C4.05858 17.1924 3.88573 17.25 3.72008 17.25Z" fill="white"/>
      <rect x="9" y="15" width="7.5" height="1.5" rx="0.75" fill="white"/>
    </svg>
  </SvgIconStyled>
);

export default Script;
