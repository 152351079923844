// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const MessageQuestion = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 15" fill="none" role="img" aria-hidden="false">
    <path
      d="M6.87472 5.75168C7.00687 5.37602 7.2677 5.05925 7.61102 4.85748C7.95434 4.65571 8.35799 4.58195 8.75048 4.64928C9.14297 4.7166 9.49897 4.92065 9.75543 5.2253C10.0119 5.52995 10.1522 5.91554 10.1516 6.31376C10.1516 7.43792 8.46541 8 8.46541 8M8.48715 10.25H8.49465M8.37472 14C11.8955 14 14.7497 11.1458 14.7497 7.625C14.7497 4.10418 11.8955 1.25 8.37472 1.25C4.8539 1.25 1.99972 4.10418 1.99972 7.625C1.99972 8.3375 2.1166 9.02269 2.33225 9.66246C2.4134 9.90321 2.45397 10.0236 2.46129 10.1161C2.46852 10.2074 2.46305 10.2714 2.44046 10.3602C2.41758 10.4501 2.36707 10.5436 2.26604 10.7306L1.0393 13.0013C0.864312 13.3252 0.776821 13.4871 0.796402 13.6121C0.813457 13.7209 0.877529 13.8168 0.971599 13.8742C1.0796 13.9401 1.26269 13.9211 1.62887 13.8833L5.46964 13.4863C5.58595 13.4742 5.64411 13.4682 5.69711 13.4703C5.74925 13.4722 5.78605 13.4771 5.83689 13.4889C5.88858 13.5008 5.95358 13.5258 6.08358 13.5759C6.79462 13.8498 7.56713 14 8.37472 14Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIconStyled>
);

export default MessageQuestion;
