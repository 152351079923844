// @flow
// todo: Remove file
import React from 'react';
import { addField, FieldTitle } from 'ra-core';
import TextField from './TextField';
import PassField from './PasswordField';

type Props = {
  classes?: Object,
  className?: string,
  input: Object,
  isRequired?: boolean,
  label: string | boolean,
  meta?: Object,
  name: string,
  onChange?: Function,
  options?: Object,
  resource: string,
  source: string,
  type?: string,
  margin?: string,
  onBlur: Function,
  onFocus: Function
};

const defaultProps = {
  onChange: () => {},
  options: {},
  type: 'text',
  isRequired: false,
  classes: '',
  className: '',
  margin: 'normal',
  meta: {
    touched: null,
    error: null
  }
};

const Field = ({ isRequired, type, ...props }: Props) => {
  return type === 'password' ? <PassField {...props} /> : <TextField {...props} />;
};

Field.defaultProps = defaultProps;

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput = ({
  input,
  isRequired,
  label,
  meta,
  options,
  resource,
  source,
  onBlur,
  onFocus,
  onChange,
  ...rest
}: Props) => {
  if (typeof meta === 'undefined') {
    throw new Error(
      `The TextInput component wasn't called within a redux-form <Field>.
        Did you decorate it and forget to add the addField prop to your component?
        See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.`
    );
  }
  const { touched, error } = meta;

  return (
    <Field
      label={
        label === false ? label : <FieldTitle label={label} source={source} resource={resource} required={isRequired} />
      }
      error={!!(touched && error)}
      helperText={touched && error}
      {...options}
      {...rest}
      {...input}
    />
  );
};

TextInput.defaultProps = defaultProps;

export default addField(TextInput);
