// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const WhiteMode = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 25" role="img" aria-hidden="false">
      <path d="M11.9999 24.0588C14.3733 24.0588 16.6935 23.3551 18.6669 22.0365C20.6403 20.7179 22.1783 18.8438 23.0866 16.651C23.9948 14.4583 24.2324 12.0455 23.7694 9.71776C23.3064 7.38999 22.1635 5.25179 20.4852 3.57356C18.807 1.89533 16.669 0.752437 14.3412 0.289414C12.0134 -0.173608 9.60056 0.0640397 7.40784 0.972291C5.21513 1.88054 3.34092 3.41861 2.02234 5.392C0.703768 7.36539 -0.000115697 9.68546 -0.00011549 12.0588C-0.000115212 15.2414 1.26434 18.2937 3.51478 20.5441C5.76521 22.7946 8.81729 24.0588 11.9999 24.0588V24.0588ZM10.89 3.64884C11.0311 3.63178 11.1742 3.64499 11.3097 3.68755C11.4452 3.73011 11.5702 3.80106 11.6762 3.89568C11.7821 3.99029 11.8666 4.1064 11.9242 4.23627C11.9818 4.36614 12.0109 4.50678 12.0099 4.64884L12.0099 19.4888C12.0109 19.6309 11.9818 19.7715 11.9242 19.9014C11.8666 20.0313 11.7821 20.1474 11.6762 20.242C11.5702 20.3366 11.4452 20.4076 11.3097 20.4501C11.1742 20.4927 11.0311 20.5059 10.89 20.4888C8.84378 20.2244 6.96395 19.2239 5.60169 17.6743C4.23944 16.1247 3.48792 14.1321 3.48792 12.0688C3.48792 10.0056 4.23944 8.01295 5.60169 6.46335C6.96394 4.91375 8.84378 3.91324 10.89 3.64884V3.64884Z"/>
  </SvgIconStyled>
);

export default WhiteMode;
