import React, { useState, useEffect } from 'react';
import { ButtonCancel } from 'components';
import { makeStyles } from '@material-ui/core';
import { isValidEmail, isTestDomain } from 'helpers';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  TextField,
  DialogContent,
  DialogActions,
  CloseIcon,
  CheckIcon
} from '@trustsecurenow/components-library';

const useStyles = makeStyles(() => ({
  infoText: {
    textAlign: 'left',
    color: 'var(--colorSystemInfo)',
    textDecoration: 'underline',
    fontSize: 12
  }
}));

const MarkCompleteDialog = ({ open, close, onComplete, state, setState, loading, consulting_option_available }) => {
  const classes = useStyles();
  const [additionalEmailError, setAdditionalEmailError] = useState(false);

  const handleChangeConsultationOption = e => {
    const { value } = e.target;
    setState({ consultationOption: value });
  };

  const handleChangeUserEmail = e => {
    const { checked } = e.target;
    setState({ emailCurrentUser: checked });
  };

  const handleChangeAdditionalEmail = e => {
    const value = e.target.value?.trim();
    const isInvalid = value ? !(isValidEmail(value) && !isTestDomain(value)) : false;
    setState({ additionalEmail: value });
    setAdditionalEmailError(isInvalid);
  };

  useEffect(() => {
    if (!open) setAdditionalEmailError(false);
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm" disableBackdropClick disableEscapeKeyDown>
        <DialogTitle alignItems="center" justifyContent="space-between" display="flex">
          <Typography variant="p">Mark Your Security Risk Assessment Complete</Typography>
          <IconButton onClick={close} disabled={loading}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Box fontSize={14} sx={{ marginBottom: 2.5 }}>
            By marking the Security Risk Assessment complete, I certify that I have provided information to the best of
            my knowledge and have utilized all appropriate resources necessary to complete the Security Risk Assessment
            information. I have received the appropriate level of information and I was made aware of the proper
            requirements necessary to complete the Security Risk Assessment.
          </Box>

          {consulting_option_available && (
            <Box fontSize={14} sx={{ marginBottom: 2.5 }}>
              Your subscription includes a consulting session with a HIPAA expert to review your answers before the
              final report is completed. Use the option below to schedule a consultation call to review this material
              with an expert or select the option to skip the call and only generate the final reports without
              discussion.
            </Box>
          )}

          {/* Call options */}
          {consulting_option_available && (
            <Box sx={{ marginBottom: 2.5 }}>
              <RadioGroup
                row
                aria-label="consultationOption"
                name="consultation_option"
                value={state.consultationOption}
                onChange={handleChangeConsultationOption}
              >
                <FormControlLabel value="1" control={<Radio />} label="Schedule Consultation Call" />
                <FormControlLabel value="2" control={<Radio />} label="Skip call and generate reports" />
              </RadioGroup>

              {state.dateScheduled && (
                <span className={classes.infoText}>Meeting scheduled for {state.dateScheduled}</span>
              )}
            </Box>
          )}

          <Box fontSize={14} fontWeight={500} mb={1.5}>
            Email to be included in all communication
          </Box>

          {/* Email current user */}
          <Box sx={{ marginBottom: 2.5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={state.emailCurrentUser}
                  checked={state.emailCurrentUser}
                  onChange={handleChangeUserEmail}
                  name="email_current_user"
                />
              }
              label="Email to who completed the task"
            />
          </Box>

          {/* Additional email */}
          <Box>
            <TextField
              fullWidth
              label="Additional email"
              variant="outlined"
              onChange={handleChangeAdditionalEmail}
              error={additionalEmailError}
              helperText={additionalEmailError && 'Please enter a valid email address'}
            />
          </Box>
        </DialogContent>

        <Divider />

        {/* Action buttons */}
        <DialogActions>
          <ButtonCancel variant="text" onClick={close} disabled={loading}>
            Cancel
          </ButtonCancel>

          <Button
            color="success"
            variant="contained"
            disableElevation
            startIcon={<CheckIcon />}
            onClick={onComplete}
            disabled={additionalEmailError || loading}
          >
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// MarkCompleteDialog.propTypes = {
//   setOpen: PropTypes.func.isRequired,
//   setCompleted: PropTypes.func.isRequired,
//   setShowSchedule: PropTypes.func.isRequired,
//   scheduling: PropTypes.string.isRequired,
//   setScheduling: PropTypes.func.isRequired,
//   emailCompleter: PropTypes.bool.isRequired,
//   setEmailCompleter: PropTypes.func.isRequired,
//   additionalEmail: PropTypes.string.isRequired,
//   setAdditionalEmail: PropTypes.func.isRequired,
//   additionalEmailError: PropTypes.bool.isRequired,
//   setAdditionalEmailError: PropTypes.func.isRequired
// };

export default MarkCompleteDialog;
