// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Error as ErrorIcon, CheckCircle, Info, Warning } from '@material-ui/icons';
import type { Element, Node } from 'react';
import { SnackbarContent, CloseIcon } from './ComponentTypes';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: ErrorIcon,
  info: Info
};

type ErrorProps = {
  message: string | null,
  type: string,
  ...
};

const ErrorContainer = ({ message, type, ...props }: ErrorProps): Element<*> => {
  const [show, onClose] = useState(true);
  const Icon = variantIcon[type];

  return (
    <>
      {show ? (
        <SnackbarContent
          classes={{ action: 'error-message-action'}}
          type={type}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              <Icon classes={{ root: 'error-message-icon'}} />
              {message}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          ]}
          {...(props: $Rest<ErrorProps, any>)}
        />
      ) : null}
    </>
  );
};

const Error = ({ message, ...props }: ErrorProps): Node => {
  return message !== '' ? <ErrorContainer {...props} message={message} /> : null;
};

const mapStateToProps = ({ errorHandler }) => errorHandler;

export default connect(
  mapStateToProps,
  {},
  null
)(Error);
