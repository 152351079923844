// @flow
import { PartnerProfile as PartnerProfileIcon } from 'components/icons';
import PartnerList from './PartnerList';

export default {
  name: 'partnerProfile',
  list: PartnerList,
  icon: PartnerProfileIcon,
  options: {
    label: 'Partner Profile'
  }
};
