// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Planet = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 24 24" role="img" aria-hidden="false">
    <path d="M23.3248 1.18622C22.2319 -0.383716 20.0558 -0.393652 16.8563 1.14648L16.8961 1.2359C15.3857 0.550298 13.7264 0.152845 11.9577 0.152845C5.37987 0.152845 0.0341278 5.49858 0.0341278 12.0764C0.0341278 13.7159 0.362026 15.266 0.968141 16.6869C-0.393134 20.0354 -0.323579 22.291 1.20661 23.3641C1.77298 23.7615 2.4884 23.9404 3.30318 23.9404C4.45579 23.9404 5.80713 23.5827 7.21808 23.0163C8.66879 23.6522 10.2685 24 11.9577 24C18.5355 24 23.8813 18.6543 23.8813 12.0764C23.8813 10.4369 23.5534 8.87693 22.9473 7.46597C23.9608 4.9024 24.2787 2.55743 23.3248 1.18622ZM21.6953 2.31896C22.053 2.83565 22.0133 3.85909 21.6655 5.15081C20.9103 4.09756 19.9962 3.17349 18.9429 2.41833C20.3539 1.8917 21.3674 1.84202 21.6953 2.31896ZM11.9676 2.13017C15.813 2.13017 19.1417 4.3261 20.7911 7.52559C19.6484 10.109 17.7406 13.08 15.4851 15.2461C13.0904 17.5414 9.94063 19.6081 7.28764 20.8303C4.16763 19.1511 2.03133 15.8622 2.03133 12.0764C2.03133 6.58164 6.49273 2.13017 11.9676 2.13017ZM2.35923 21.7246C1.96177 21.4464 1.81273 20.5819 2.2698 18.9623C3.01502 20.0155 3.92916 20.9297 4.97248 21.6948C3.79005 22.0227 2.85604 22.0724 2.35923 21.7246ZM21.904 12.0665C21.904 17.5414 17.4426 22.0028 11.9676 22.0028C11.2324 22.0028 10.5169 21.9134 9.8214 21.7643C12.5141 20.2838 15.1572 18.3164 16.8563 16.6769C18.5554 15.0374 20.3539 12.613 21.7052 10.109C21.8344 10.745 21.904 11.3908 21.904 12.0665Z" />
  </SvgIconStyled>
);

export default React.memo(Planet);
