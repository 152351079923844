// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const Export2 = props => (
  <SvgIconStyled {...props} viewBox="0 0 14.77 14.695" role="img" aria-hidden="false">
    <path d="M13.8426 0H14.4213C14.7454 0 15.0058 0.259715 14.9942 0.577145V1.15429V3.76876C14.9942 4.08619 14.7338 4.3459 14.4155 4.3459C14.0972 4.3459 13.8368 4.08619 13.8368 3.76876V1.97383L10.6598 5.14236C10.544 5.25202 10.3993 5.30973 10.2489 5.30973C10.0984 5.30973 9.95374 5.25779 9.838 5.14236C9.61231 4.91728 9.61231 4.55367 9.838 4.32859L13.0151 1.16006H11.2153C10.897 1.16006 10.6366 0.900343 10.6366 0.582913C10.6424 0.421313 10.7061 0.277031 10.8102 0.173145C10.9202 0.0634877 11.059 0.00577145 11.2211 0H13.8426ZM14.0104 5.9215L14.022 5.90997C14.3808 5.54637 15 5.80031 15 6.31397V12.6972C15 13.9669 13.9641 15 12.691 15H2.30904C1.03589 15 0 13.9669 0 12.6972V2.34321C0 1.07349 1.03589 0.0404048 2.30904 0.0404048H8.55903C9.06828 0.0404048 9.32292 0.657942 8.96413 1.01577L8.95254 1.02732C8.84837 1.1312 8.6979 1.19469 8.54744 1.19469H2.30904C1.67246 1.19469 1.15741 1.70835 1.15741 2.34321V12.6972C1.15741 13.332 1.67246 13.8457 2.30904 13.8457H12.691C13.3275 13.8457 13.8426 13.332 13.8426 12.6972V6.32551C13.8426 6.17546 13.9062 6.03116 14.0104 5.9215Z" />
  </SvgIconStyled>
);

export default React.memo(Export2);
