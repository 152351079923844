/* eslint-disable no-console */
// @flow
import 'core-js/features/global-this';
import 'core-js/proposals/promise-all-settled';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { registerServiceWorker } from './serviceWorker';

const env = process.env.REACT_APP_BUILD_ENV;
window.debug = true;
window.debug = window.debug && env !== 'prod';
TimeAgo.addDefaultLocale(en);

if (!window.debug) {
  console.log = () => null;
  console.warn = () => null;
  console.error = () => null;
  console.table = () => null;
  console.group = () => null;
  console.groupEnd = () => null;
}

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(<App />, root);

  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default;
      ReactDOM.render(<NextApp />, root);
    });
  }

  registerServiceWorker();
} else {
  console.error('Error: Root element not found on your public/index.html file');
}
