import React, { useState } from 'react';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Button,
  CircularProgress,
  EraserIcon,
  enqueueAlertSnackbar,
  NotesIcon,
  PrintIcon,
  Typography,
  Stack,
  CommentIcon
} from '@trustsecurenow/components-library';
import { useLocation } from 'hooks';
import { encodeId } from 'helpers';
import * as sra from '../../../helpers/apis/services/sra';
import clients from '../../../helpers/apis/clients';

const SRA_REPORT_ITEM = { 
  APPENDIX: 'appendix',
  WORKPLAN: 'workplan',
  INTERVIEW_QUESTIONS: 'interview_questions',
  ORG_PROFILE: 'organization_profile',
  SRA_ANSWERS: 'sra_answers'
}

const SRA_REPORTS_CONFIG = [{
  name: SRA_REPORT_ITEM.APPENDIX,
  icon: PrintIcon,
  label: 'Print Appendix'
},
{
  name: SRA_REPORT_ITEM.WORKPLAN,
  icon: NotesIcon,
  label: 'Print Work Plan'
},
{
  name: SRA_REPORT_ITEM.INTERVIEW_QUESTIONS,
  icon: CommentIcon,
  label: 'Print Interview Questions'
},
{
  name: SRA_REPORT_ITEM.ORG_PROFILE,
  icon: NotesIcon,
  label: 'Org. Profile'
},
{
  name: SRA_REPORT_ITEM.SRA_ANSWERS,
  icon: NotesIcon,
  label: 'SRA Answers'
}];


const AdminCapabilitiesSraDocuments = ({ sraStatus, onRefetch }) => {
  const [workPlanId, setWorkPlanId] = useState(null);
  const [loadingSraStatus, setLoadingSraStatus] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  
  const { item: clientId } = useLocation();

  const clearSraStatus = async () => {
    try {
      setLoadingSraStatus(true);
      await sra.setClientSraStatus(clientId, { completed: false });
      onRefetch();
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.description || 'Failed on clear status',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoadingSraStatus(false);
    }
  };

  const getWorkPlanId = async () => {
    try {
      setLoadingSraStatus(true);
      const resp = await clients.getLastWorkplan(clientId);
      setWorkPlanId(encodeId(resp?.data?.id));
    } finally {
      setLoadingSraStatus(false);
    }
  }

  const handleAccordionChange = async (_, isExpanded) => {
    if (isExpanded && !workPlanId) {
      getWorkPlanId();
    }
  };

  const getApiRequest = (type) => {
    switch (type) {
      case SRA_REPORT_ITEM.WORKPLAN:
        return clients.getWorkplanReport(workPlanId);
      case SRA_REPORT_ITEM.APPENDIX:
        return clients.getSRAppendixReport(clientId);
      case SRA_REPORT_ITEM.INTERVIEW_QUESTIONS:
        return clients.getInterviewQuestions(clientId);
      case SRA_REPORT_ITEM.ORG_PROFILE:
        return clients.getOrgProfileCSV(clientId);
      case SRA_REPORT_ITEM.SRA_ANSWERS:
        return clients.getSraAnswers(clientId);
      default:
        return () => {};
    }
  }

  const handleReportDownload = async (type) => {
    try {
      setLoadingReport(type);
      const resp = await getApiRequest(type);
      if (resp?.data?.url) window.open(resp?.data?.url);
    } catch (err) {
      enqueueAlertSnackbar(
        err?.response?.data?.description || 'Failed on load report',
        { props: { severity: 'error' } }
      );
    } finally {
      setLoadingReport(false);
    }
  }
  
  return (
    <Accordion onChange={handleAccordionChange} sx={{ '&:before': { background: 'none' },}}>
      <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
        <Typography variant='subtitle1' color="inherit">SRA</Typography>
      </AccordionSummary>
      
      <AccordionDetails>
        {loadingSraStatus ? (
           <>
            <CircularProgress size={20} color="info" />
            <Typography>
              Loading SRA Status...
            </Typography>
          </>
        ) : (
          <>
            <Stack direction="row" flexWrap="wrap" spacing={3.4} useFlexGap>
              {SRA_REPORTS_CONFIG
                .filter((card) => (card.name !== SRA_REPORT_ITEM.WORKPLAN || workPlanId) &&
                  (card.name !== SRA_REPORT_ITEM.INTERVIEW_QUESTIONS || sraStatus?.consulting)
                )
                .map((card) => {
                const Icon = card.icon;
                return (
                  <Stack
                    width={234}
                    height={113}
                    p={2}
                    onClick={() => handleReportDownload(card.name)}
                    spacing={1.75}
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={theme => theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]}
                    sx={{ cursor: 'pointer' }}
                    borderRadius={1}
                  >
                    <Avatar
                      sx={theme => ({ width: 52, height: 52, bgcolor: theme.palette.background.paper })}
                    >
                      {loadingReport === card.name 
                        ? <CircularProgress size={24} color="info" /> 
                        : <Icon color="info" sx={{ fontSize: 26 }} />
                      }
                    </Avatar>
                    <Typography variant="h3">{card.label}</Typography>
                  </Stack>
                )
              })}
            </Stack>

            <Stack direction="row" mt={4.75} spacing={3} alignItems="center">
              <Box>
                {sraStatus?.date_completed ? (
                  <Typography variant="body2">
                    SRA Marked Complete on 
                    <Typography variant="button" component="span"> {sraStatus?.date_completed}</Typography>
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    SRA <Typography color="error.main" component="span">Not</Typography> Complete
                  </Typography>
                )} 
              </Box>
              
              <Button
                disabled={!sraStatus?.date_completed}
                onClick={clearSraStatus}
                startIcon={<EraserIcon />}
              >
                Clear SRA Date
              </Button>
            </Stack>        
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
};

export default AdminCapabilitiesSraDocuments;
  
