import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Typography, CustomTooltip } from 'components'
import { Success, CloseCircle } from 'components/icons'
import { CustomLinearProgress } from './CustomLinearProgress';

const ChartBarUpload = ({ title, tooltipText, height, backgroundColor, backgroundMain, progressPercent, onCancel }) => {

  return (
    <>
      <Typography.h5 mb="0" mt="0">{title}</Typography.h5>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={-0.5}>
        <CustomLinearProgress
          variant="determinate"
          backgroundColor={backgroundColor}
          backgroundMain={backgroundMain}
          height={height}
          value={Math.floor(progressPercent)}
        />
        <Typography.p
          fontSize={10}
          color="colorLight"
          bold
          ml={2}
          mr={2}
          mt='0px'
          mb='0px'
        >
          {Math.floor(progressPercent)}%
        </Typography.p>
        
        <Box width={35} height={33}>
          {progressPercent === 100 ? (
            <Box p={1} display="flex">
              <Success size={1.2} color="success" />
            </Box>
          ) : (
            progressPercent > 1 && (
              <CustomTooltip title={tooltipText} placement='top'>
                <IconButton style={{ padding: 8 }} onClick={onCancel}>
                  <CloseCircle size={1.2} color="danger" />
                </IconButton>
              </CustomTooltip>
            )
          )}
        </Box>
      </Box>
    </>
  );
};

export default ChartBarUpload;

