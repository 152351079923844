import React, { useEffect, useState } from 'react';

const Timer = ({ seconds = 0, pause = false }) => {
  const [second, setSecond] = useState(seconds);

  useEffect(() => {
    let interval = null

    if (!pause) {
      interval = setInterval(() => {
        setSecond(prev => prev + 1);
      }, 1000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [pause])

  const date = new Date(second * 1000).toISOString().substr(11, 8)

  return (
    <span>{date}</span>
  )
}

export default Timer
