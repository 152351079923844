// @flow
import React, { type ComponentType } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'hooks';
import { dataBreachColorIndicator } from 'helpers';
import DashboardBoxTitle from '../dashboard/DashboardBoxTitle';

const circleAnimation = keyframes`
  from {
    stroke-dashoffset: calc(var(--fill) * 2.4);
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const ContainerCircle: ComponentType<*> = styled.div`
  width: 100%;
`;

const SvgCircle: ComponentType<*> = styled.svg`
  --path: 100%;
  --fill: ${({ fillValue }) => `${fillValue}%`};
  --none: transparent;
  position: relative;
`;

const CircleBackground: ComponentType<*> = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: var(--colorGreyLight3);
  stroke-dasharray: calc(var(--path) * 2.4);
`;

const CircleForeground: ComponentType<*> = styled.circle`
  fill: transparent;
  stroke-width: calc(var(--spacing) * 1.3);
  stroke-linecap: round;
  stroke: ${({ fillValue }) => `var(--colorSystem${dataBreachColorIndicator(fillValue)})`};
  stroke-dasharray: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  stroke-dashoffset: calc(var(--fill) * 2.4) calc(var(--path) * 2.4);
  animation: ${circleAnimation} 1s linear;
`;

const TextCircle: ComponentType<*> = styled.text`
  font-size: calc(var(--fontSize) * 1.5);
  font-weight: var(--fontWeightMedium);
  fill: var(--colorDefault);
`;

const GTotal: ComponentType<*> = styled.g`
  transform: translate(-5%, 12%);

  @-moz-document url-prefix(){
    transform: translate(-5%, 15%);
  }
`;

const TextTotalCircle: ComponentType<*> = styled(TextCircle)`
  font-size: calc(var(--fontSize) / 3);
`;

type ChartCircleTypes = {
  title: string,
  description?: string | null,
  fontSize?: number,
  icon?: boolean | null,
  iconSize?: number | null,
  chart: Object,
  labels?: boolean | null
};

const checkName = (name: string | number): boolean => (typeof name === 'string' ? !name.includes('%') : true);

const ChartCircle = ({ title, description, fontSize, icon, iconSize, chart, labels, className }: ChartCircleTypes) => {
  const { app } = useLocation();
  const {
    chartData: [start, end]
  } = chart;
  const { name, value } = start;
  const hasEndValue = end && checkName(end.name) && app !== 'myDashboard';
  const fillPercent = hasEndValue ? (value * 100) / end.value : value;

  return (
    <>
      {title && (
        <DashboardBoxTitle
          label={title}
          description={description}
          fontSize={fontSize}
          icon={icon}
          iconSize={iconSize}
        />
      )}
      <ContainerCircle className={className}>
        <SvgCircle viewBox="0 0 120 120" fillValue={fillPercent}>
          <g transform={"translate(60, 60)"}>
            <CircleBackground r="45" cx="0" cy="0" />
          </g>

          <g transform={"translate(60, 60) rotate(-90 0 0)"}>
            <CircleForeground r="45" cx="0" cy="0" fillValue={value} />
          </g>

          {labels && (
            <>
              <TextCircle x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" dominantBaseline={"central"}>
                {name}
              </TextCircle>

              {hasEndValue && (
                <GTotal>
                  <TextTotalCircle x="50%" y="48%"  alignmentBaseline="middle">
                    of {end.name}
                  </TextTotalCircle>
                </GTotal>

              )}
            </>
          )}
        </SvgCircle>
      </ContainerCircle>
    </>
  );
};

ChartCircle.defaultProps = {
  labels: false,
  fontSize: 13,
  icon: false,
  iconSize: 1.3,
  description: null
};

export default ChartCircle;
