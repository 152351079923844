import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  ContentAdminCheckbox,
  ContentAdminFormButtonCancel,
  ContentAdminFormButtonsContainer,
  ContentAdminFormLayoutFooterContainer,
  ContentAdminFormLayoutFooterContainerWithCard,
  ContentAdminFormOutlinedButton,
  ContentAdminFormSubmitButton
} from '../../style';
import {
  SendEmail,
  RHFCheckbox as Checkbox,
  RHFTextField as TextField,
  Container,
  CustomTooltip
} from 'components';
import { QuestionMark, Save2 } from 'components/icons';
import { useContentAdminFormContext } from '../../context';
import {
  CONTENT_ADMIN_EMAIL_TEMPLATES,
  CONTENT_ADMIN_LANDING_PAGES,
  CONTENT_ADMIN_ROOT_PATH,
  CONTENT_ADMIN_SENDING_PROFILES
} from '../../constants';
import { useHistory } from 'react-router-dom';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { useNotify } from 'react-admin';
import { isValidEmail, redirectValidURL, validURL } from '../../../../helpers';

const ContentAdminFormLayoutFooter = () => {
  const { record, page, method, dispatch } = useContentAdminFormContext();
  const [disabled, setDisabled] = useState(false);

  const isLandingPage = page === CONTENT_ADMIN_LANDING_PAGES;

  const Layout = isLandingPage ? ContentAdminFormLayoutFooterContainerWithCard : ContentAdminFormLayoutFooterContainer;

  const history = useHistory();
  const notify = useNotify();

  const handleBack = useCallback(() => {
    history.push(`${CONTENT_ADMIN_ROOT_PATH}/${page}`);
  }, [page]);

  const handleCheck = e => {
    const { name, checked } = e.target;
    dispatch.onChange(name, checked);
  };

  const handleSendTestEmail = useCallback(async () => {
    setDisabled(true);
    try {
      const response = await contentAdmin.sendTestEmail();
      notify(response.data.description);
    } catch (e) {
      notify(e?.response?.data?.description || 'Something went wrong', 'warning');
    } finally {
      setDisabled(false);
    }
  }, []);

  const _renderSubmitButton = useMemo(
    () => (
      <ContentAdminFormSubmitButton type={'submit'} disabled={record.disabled ?? false}>
        <Save2 />
        <span>Save Template</span>
      </ContentAdminFormSubmitButton>
    ),
    [record.disabled, method]
  );

  const _renderCancelButton = useMemo(
    () => <ContentAdminFormButtonCancel onClick={handleBack}>Cancel</ContentAdminFormButtonCancel>,
    [handleBack]
  );

  const _renderSendingProfilesButton = useMemo(
    () =>
      page === CONTENT_ADMIN_SENDING_PROFILES && (
        <ContentAdminFormOutlinedButton onClick={handleSendTestEmail} disabled={disabled}>
          <SendEmail />
          <span>Send Test Email</span>
        </ContentAdminFormOutlinedButton>
      ),
    [page, disabled]
  );

  const _renderCaptureSubmittedDataSwitch = useMemo(
    () =>
      isLandingPage && (
        <Container.Grid container spacing={2} alignItems="flex-start">
          <Container.Grid item xs={6}>
            <TextField
              name="redirect_uri"
              label="Redirect URL"
              fullWidth
              required
              rules={{
                validate: value => (!redirectValidURL(value) ? 'Please enter a valid URL address' : null)
              }}
            />
          </Container.Grid>

          <Container.Grid item>
            <Box display="flex" alignItems="center" py={1}>
              <Checkbox label="Capture Submitted Data" name={'capture_submitted_data'} />
              <span style={{ cursor: 'pointer' }}>
                <CustomTooltip
                  light
                  title="Enabling capture submitted data, will track any users that enter information in the pre-landing page"
                  placement="top"
                >
                  <QuestionMark color="colorDefault" style={{ verticalAlign: 'middle' }} />
                </CustomTooltip>
              </span>
            </Box>
          </Container.Grid>
        </Container.Grid>
      ),
    [isLandingPage]
  );

  return (
    <Layout>
      {_renderSendingProfilesButton}
      {_renderCaptureSubmittedDataSwitch}

      <ContentAdminFormButtonsContainer justifyContent={'flex-end'}>
        {_renderCancelButton}
        {_renderSubmitButton}
      </ContentAdminFormButtonsContainer>
    </Layout>
  );
};

export default ContentAdminFormLayoutFooter;
