import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const successColors = {
  color: 'var(--colorSystemSuccess)',
  background: '#C7EEDA'
};

const getStyles = mode => {
  switch (mode) {
    case 'success':
      return {
        color: successColors.color,
        borderStyle: 'solid',
        backgroundColor: successColors.background
      };
    //   you can add more modes here
  }
};

const Notification = ({ text, Icon = null, mode = 'success', ...rest }) => {
  return (
    <Snackbar {...rest}>
      <Alert style={getStyles(mode)} icon={<></>}>
        <>
          {<Icon style={{ verticalAlign: 'sub', marginRight: '5px' }} />}
          {text}
        </>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
