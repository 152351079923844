// @flow

const otherPolicies = {
  type: 'policies',
  selecting: true,
  selectAll: true,
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'clients',
      tab: 'otherPolicyInfo'
    }
  },
  actions: [
    {
      icon: 'Delete',
      title: 'Delete',
      method: 'delete'
    }
  ],
  cells: [
    { id: 'name', label: 'Name', align: 'left' },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      removeHtml: true,
      hideOrderBy: true
    },
    { id: 'created', label: 'Created Date', align: 'left' },
    { id: 'modified', label: 'Modified Date', align: 'left' },
    {
      id: 'attachment_link',
      label: 'Download',
      labelAlign: 'center',
      align: 'center',
      button: true,
      val: 'attachment_link',
      renderDowloadIcon: true,
      hideOrderBy: true,
      GAbutton: 'Download Other Policy'
    }
  ]
};

export default otherPolicies;
