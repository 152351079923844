import React from "react"
import { Arrow, useQuiz } from 'components';
import { QuizButton, QuizButtonContainer, QuizContentFooterLayout } from './quizStyle';

const QuizContentFooter = () => {
  const {selectedAnswerId, question,  dispatch, onFinishQuiz, quizId, disabled, redirect} = useQuiz();

  const handleSubmit = () => {
    dispatch.submitAnswer(quizId, question.id,  selectedAnswerId, onFinishQuiz, redirect)
  }

  return (
    <QuizContentFooterLayout justifyContent={"flex-end"}>
      <QuizButton onClick={handleSubmit} disabled={disabled}>
        <QuizButtonContainer alignItems={"center"}>
          <span>
            {question?.is_last ? "Submit" : "Next question"}
          </span>
          <Arrow/>
        </QuizButtonContainer>
      </QuizButton>
    </QuizContentFooterLayout>
  )
}

export default QuizContentFooter
