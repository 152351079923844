import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CustomTooltip } from 'components/common';
import { ButtonCancel } from 'components/modal';
import { SelectField, TextField } from '../../forms';
import { CalendarFile, LazyIcon, Refresh } from '../../icons';
import { Container } from '../../types';
import { ANNUAL, FULL_TRAINING, SELECTED_TRAINING } from './constants';
import useTrainingToolbar from './useTrainingToolbar';
import { useDebounce } from '../../../hooks';
import { useTrainingsContext } from './TrainingContext';
import {
  checkIsToday,
  setTimeInDateObjectToEndOfDay,
  setTimeInDateObjectToNow,
  setTimeInDateObjectToStartOfDay
} from 'helpers';
import { ArrowDownloadIcon, Stack, Button } from '@trustsecurenow/components-library';

// TODO: check if this needs to be used / removed
class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return date.toLocaleString('default', { month: 'long' });
  }
}

const DateField = props => {
  return (
    <KeyboardDatePicker
      disableToolbar
      // inputProps={{
      //   style: { fontSize: '77%', height: '19px' }
      // }}
      // disabled={false}
      style={{ width: '16rem' }}
      variant="inline"
      inputVariant="outlined"
      format="MM/dd/yyyy"
      margin="normal"
      size="small"
      // allowKeyboardControl={true}
      autoOk
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
      {...props}
    />
  );
};

const getTwoYearsBack = () => {
  const date = new Date();
  date.setYear(date.getFullYear() - 2);
  return date;
};

const today = new Date();
const twoYearsBack = getTwoYearsBack();

const Toolbar = () => {
  const [isSelectedClicked, setIsSelectedClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState({ begin_date: null, end_date: null });
  const { dispatch, record: state, filterString } = useTrainingsContext();

  const { onChangeValue, onChangeSubValue, onChangeFilter, onChangePage } = dispatch;
  const { resetSearch, selectedRows } = state;

  const { downloadButtonData } = useTrainingToolbar({ state, filterString, setLoading });

  const [searchDebounceValue] = useDebounce({ value: searchValue, delay: 500 });

  const isAnnual = state.reportValue === ANNUAL;

  useEffect(() => {
    if (searchDebounceValue.value) {
      onChangePage(0);
    }
    onChangeFilter('name', searchDebounceValue.value);
  }, [searchDebounceValue.value]);

  useEffect(() => {
    setSearchValue('');
  }, [resetSearch]);

  useEffect(() => {
    if (!dateRange.begin_date && !dateRange.end_date) {
      onChangeFilter('begin_date', null);
      onChangeFilter('end_date', null);
      return;
    }
    if (!dateRange.begin_date || !dateRange.end_date) return;
    // reset time in date objects and convert to ISO format
    const begin_date = setTimeInDateObjectToStartOfDay(dateRange.begin_date).toISOString();
    const end_date = (checkIsToday(dateRange.end_date) ? setTimeInDateObjectToNow : setTimeInDateObjectToEndOfDay)(
      dateRange.end_date
    ).toISOString();

    onChangeFilter('begin_date', begin_date);
    onChangeFilter('end_date', end_date);
  }, [dateRange]);

  const resetDateRange = () => {
    setDateRange({ begin_date: null, end_date: null });
  };

  const handleDateChange = ({ name, date }) => {
    if (date === null || date.toString() === 'Invalid Date') {
      return;
    }
    setDateRange(s => ({ ...s, [name]: date }));
  };

  return (
    <Container.Grid alignItems="center" justifyContent="space-between">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          flex: 1,
          gap: '1.5rem'
        }}
      >
        <TextField
          placeholder="Search"
          value={searchValue}
          onChange={({ target: { value } }) => {
            setSearchValue(value);
          }}
        />
        <div style={{ display: 'flex', gap: '1.5rem' }}>
          <SelectField
            name="training_report_type"
            id="training_report_type"
            emptyValue="Training Report Type"
            style={{ width: '16rem' }}
            allowEmpty
            variant
            GAclick
            value={state.reportValue}
            choices={state.trainingFilters.category}
            onChange={e => {
              resetDateRange();
              onChangeValue(e);
            }}
          />
          <SelectField
            variant
            style={{ width: '16rem' }}
            name={isAnnual ? 'filter' : 'viewBy'}
            emptyValue={isAnnual ? 'Filter' : 'View By'}
            highlightKey={isAnnual ? 'current_course' : ''}
            value={state.subValue}
            choices={state.trainingFilters.type[state.reportValue]}
            onChange={e => {
              onChangeSubValue(e);
            }}
          />
        </div>
        {!isAnnual && (
          <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateField
                id="begin_date"
                label="Start Date"
                minDate={twoYearsBack}
                maxDate={dateRange.end_date || today}
                disabled={state?.trainingReport?.isFetching}
                initialFocusedDate={today}
                value={dateRange.begin_date}
                onChange={date => handleDateChange({ name: 'begin_date', date })}
              // error={false}
              // helperText=""
              // onError={err => {}}
              />
              <DateField
                id="end_date"
                label="End Date"
                minDate={dateRange.begin_date || twoYearsBack}
                disabled={state?.trainingReport?.isFetching}
                maxDate={today}
                initialFocusedDate={today}
                value={dateRange.end_date}
                onChange={date => handleDateChange({ name: 'end_date', date })}
              />
            </MuiPickersUtilsProvider>
            <CustomTooltip
              maxWidth={220}
              arrow
              light
              bottom
              title="Select the dates you would like to generate the training report for"
            >
              <LazyIcon component="Info" color="colorDefault" size={1.3} />
            </CustomTooltip>
            <ButtonCancel
              disabled={!dateRange.end_date && !dateRange.begin_date}
              onClick={() => setDateRange({ begin_date: null, end_date: null })}
            >
              <Refresh style={{ marginRight: 8 }} />
              Reset Dates
            </ButtonCancel>
          </div>
        )}
      </div>
      <Stack direction="row" spacing={3.5} flexWrap="wrap">
        {isAnnual && (
          <Button
            onClick={() => {
              setIsSelectedClicked(true);
              downloadButtonData[state.reportValue].method(SELECTED_TRAINING, selectedRows, state.id);
            }}
            startIcon={<ArrowDownloadIcon />}
            loading={loading && isSelectedClicked}
          >
            Download selected training report
          </Button>
        )}
        <Button
          loading={loading && !isSelectedClicked}
          onClick={() => {
            setIsSelectedClicked(false);
            downloadButtonData[state.reportValue].method(FULL_TRAINING, selectedRows);
          }}
          startIcon={<ArrowDownloadIcon />}
        >
          {!loading || isSelectedClicked
            ? downloadButtonData[state.reportValue].title
            : downloadButtonData[state.reportValue].loadingTitle}
        </Button>
      </Stack>
    </Container.Grid>
  );
};

export default Toolbar;
