// @flow

import React, { memo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormControlLabel as MuiFormControlLabel, Switch, Toolbar as MuiToolbar, Typography } from '@material-ui/core';

const Toolbar = styled(MuiToolbar)`
  display: flex;
`;

const Title = styled(Typography)`
  margin-right: 15px;
  height: 29px;
  width: 250px;
  color: #212121;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  .home-screen-label {
    color: #7f7f7f;
    font-size: 14px;
  }
`;

type Props = {
  title?: string
};

type State = {
  admin: {
    resources: Object
  },
  router: {
    location: {
      pathname: string
    }
  }
};

const mapStateToProps = ({ router, admin }: State): Props => {
  const pathname = router.location.pathname.split('/')[1];
  let title = '';
  switch (pathname) {
    case 'my-profile': {
      title = 'My Profile';
      break;
    }

    case 'registration': {
      title = 'User Registration';
      break;
    }

    case 'login': {
      title = 'User Login';
      break;
    }

    case 'myTrainig': {
      title = 'My Training';
      break;
    }

    case '': {
      title = 'Home Page';
      break;
    }

    default: {
      title = admin.resources[pathname].props.options.label;
    }
  }

  return { title };
};

const AppBarBottom = connect(mapStateToProps)(({ title }: Props) => (
  <Toolbar>
    <Title variant="h2" color="inherit" id="dashboard-title">
      {title}
    </Title>
    <FormControlLabel
      control={<Switch value="checked" color="default" />}
      label="Set as Home Screen"
      labelPlacement="end"
      classes={{
        label: 'home-screen-label'
      }}
    />
  </Toolbar>
));

export default memo<Props>(AppBarBottom);
