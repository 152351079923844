import React from 'react';

const Trash = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.2251 3.1369H14.9327C14.942 3.09977 14.9606 3.06265 14.9606 3.02553C14.9606 1.355 13.6334 0 12.0093 0C10.3852 0 9.05801 1.355 9.05801 3.02553C9.05801 3.06265 9.05801 3.09977 9.05801 3.1369H2.78422C1.2529 3.1369 0 4.3898 0 5.92112V6.30163C0 7.83295 1.2529 9.08585 2.78422 9.08585H3.56383C3.47102 9.355 3.41531 9.6427 3.41531 9.93968V21.2158C3.41531 22.7471 4.66821 24 6.19953 24H17.819C19.3504 24 20.6033 22.7471 20.6033 21.2158V9.93968C20.6033 9.6427 20.5476 9.355 20.4548 9.08585H21.2343C22.7657 9.08585 24.0186 7.83295 24.0186 6.30163V5.92112C24.0093 4.3898 22.7657 3.1369 21.2251 3.1369ZM10.9049 3.02553C10.9049 2.37588 11.3967 1.85615 12 1.85615C12.6032 1.85615 13.0951 2.38516 13.0951 3.02553C13.0951 3.06265 13.1137 3.09977 13.123 3.1369H10.8863C10.8863 3.09977 10.9049 3.06265 10.9049 3.02553ZM18.7378 9.93968V21.2158C18.7378 21.7262 18.3202 22.1439 17.8097 22.1439H6.19024C5.6798 22.1439 5.26217 21.7262 5.26217 21.2158V9.93968C5.26217 9.54989 5.49424 9.22507 5.83762 9.08585H8.2877V17.2343C8.2877 17.7448 8.70533 18.1624 9.21577 18.1624C9.72621 18.1624 10.1438 17.7448 10.1438 17.2343V9.08585H13.8561V17.1694C13.8561 17.6798 14.2738 18.0974 14.7842 18.0974C15.2947 18.0974 15.7123 17.6798 15.7123 17.1694V9.08585H18.1624C18.5058 9.22507 18.7378 9.55917 18.7378 9.93968ZM22.1531 6.30163C22.1531 6.81207 21.7355 7.22971 21.2251 7.22971H18.413C18.2181 7.1833 18.0139 7.15546 17.8005 7.15546H6.18101C5.96755 7.15546 5.77262 7.1833 5.56845 7.22971H2.75641C2.24597 7.22971 1.82834 6.81207 1.82834 6.30163V5.92112C1.82834 5.41068 2.24597 4.99304 2.75641 4.99304H21.2158C21.7262 4.99304 22.1438 5.41068 22.1438 5.92112V6.30163H22.1531Z"
        fill="#535353"
      />
    </svg>
  );
};

export default Trash;
