import React from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { CustomTooltip } from 'components';
import { Enable, Disable } from 'components/icons';

const useStyles = makeStyles(theme => ({
  checkBtn: {
    margin: 0,
    justifyContent: 'space-between',
    padding: '18px 15px 18px 25px',
    color: 'var(--colorDefault)',
    borderBottom: '1px solid var(--backgroundPaper)',
    '& [class*=MuiIconButton-label] svg': {
      width: 22,
      height: 22
    },
    '& [class*=MuiFormControlLabel-label]': {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    '&.selected': {
      color: 'var(--colorSystemInfo)',
      background: 'var(--backgroundDefault)'
    },
    '& [class*=MuiCheckbox-root]': {
      opacity: ({ accessIcon, access }) => (accessIcon && !access ? 0 : 1),
      visibility: ({ accessIcon, access }) => (accessIcon && !access ? 'hidden' : 'visible')
    }
  },
  labelWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 40,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      verticalAlign: 'middle',
      fontSize: 24
    }
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5
  },
  labelTxt: {
    wordBreak: 'break-word',
    paddingRight: 10,
    color: ({ accessIcon, access }) => (accessIcon && !access ? 'var(--colorDefault) !important' : 'inherit')
  },
  hoverStyle: {
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemInfo)',
      background: 'var(--backgroundDefault)'
    }
  },
  accessIcon: {
    width: '30%',
    marginLeft: 'auto',
    flexShrink: 0,
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle'
    },
    [theme.breakpoints.down('sm')]: {
      width: '10%'
    }
  }
}));

const CheckboxWithIcon = ({ checked, label, name, icon, accessIcon, access, onChange }) => {
  const classes = useStyles({ accessIcon, access });

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} name={name} onChange={onChange} color="default" />}
      labelPlacement="start"
      className={`${classes.checkBtn} ${classes.hoverStyle} ${checked ? 'selected' : ''}`}
      disabled={accessIcon && !access}
      label={
        <>
          <span className={classes.icon}>{icon}</span>
          <span className={classes.labelTxt}>{label}</span>
          {accessIcon &&
            (access ? (
              <span className={classes.accessIcon}>
                <Enable color="colorSystemSuccess" strokeColor="colorSystemSuccess" size={1.4} />
              </span>
            ) : (
              <span className={classes.accessIcon}>
                <CustomTooltip title="Turn on access from the Manage Clients application" placement="top">
                  <Disable color="colorDefault" size={1.4} />
                </CustomTooltip>
              </span>
            ))}
        </>
      }
    />
  );
};

export default React.memo(CheckboxWithIcon);
