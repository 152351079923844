// @flow
import React, { useState, useRef, useCallback } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import 'cropperjs/dist/cropper.css';
import { DragDrop as DragDropIcon } from 'components/icons';
import { Typography, CustomTooltip, DeleteIcon } from 'components';
import COMMON_CONST from '../shared/constants';
import { ContainerTitle } from './ComponentTypes';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';

const MainBox = styled.div`
  width: calc((100% - 270px) / 2);
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const DragZone = styled(Box)`
  border: calc(var(--borderSize) * 2) dashed var(--borderDefault);
  border-radius: calc(var(--borderSize) * 10);
  cursor: pointer;
  width: 50%;
  min-width: 230px;
  max-width: 420px;
`;

const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const BoxImage = styled(Box)`
  overflow: hidden;
  & > img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    max-width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;

const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  h6,
  p {
    margin: 0;
    padding-bottom: 8px;
  }
`;

const BoxFigure = styled(Box)`
  border: calc(var(--borderSize) * 2) solid var(--borderDefault);
  border-radius: calc(var(--borderSize) * 10);
  margin: calc(var(--spacing) * 1.1) 0;
  width: 50%;
  min-width: 180px;
  max-width: 370px;
`;

const Figure = styled.figure`
  overflow: hidden;
  & > img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
`;

const ErrorMessage = styled(Typography.p)`
  color: var(--colorSystemDanger);
  font-size: 14px;
`;

const LogoInfo = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  min-width: 300px;
  .label {
    color: var(--colorSystemInfo);
    white-space: nowrap;
  }
  .filename {
    text-decoration: underline;
    margin: 0 auto 0 5px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PartnerInformationLogo = ({ logo = '', newLogo, setLogo, preview, setPreview, setCroppedImage }) => {
  const [uploadedFiles, setUploadedFiles] = useState([{ preview: null, name: '' }]);
  const [cropper, setCropper] = useState({});
  const [openDeleteLogoModal, setOpenDeleteLogoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [logoName, setLogoName] = useState("");
  const crop = useRef();

  const onFileUploadAccepted = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    const preview = URL.createObjectURL(acceptedFile);
    setPreview(true);
    setUploadedFiles([Object.assign(acceptedFile, { preview: preview })]);
  }, []);

  const onFileUploadRejected = useCallback(rejectedFiles => {
    // we are only interested in the first rejected file
    const rejectedFile = rejectedFiles[0]?.file;
    const { maxLogoSize, maxLogoSizeMB } = COMMON_CONST.LOGO_SETTINGS;
    let errorMsg = 'Failed to upload selected file';
    if (rejectedFile.size > maxLogoSize) {
      errorMsg = `File [${rejectedFile?.name}] exceeds maximum allowed file size (${Math.floor(maxLogoSize / 1000000)} MB)`;
    } else {
      errorMsg = `[${rejectedFile?.name}] Invalid file. Please use only (.png, .jpg, .jpeg) files`;
    }
    enqueueAlertSnackbar(errorMsg, { props: { severity: 'error',  sx: { backgroundColor: '#D32F2F' }}});
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [], 'image/png': [] },
    multiple: COMMON_CONST.LOGO_SETTINGS.allowedMultipleFiles,
    maxSize: COMMON_CONST.LOGO_SETTINGS.maxLogoSize,
    onDropAccepted: onFileUploadAccepted,
    onDropRejected: onFileUploadRejected,
    disabled: preview && uploadedFiles[0].preview !== null
  });

  const dataURLtoFile = (dataurl, mime, filename) => {
    const arr = dataurl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImageData = {
      imageFile: new File([u8arr], filename, { type: mime }),
      mimeType: mime
    };
    return croppedImageData
  };

  const onClick = e => {
    const canvas = crop.current.getCroppedCanvas({
      width: 200,
      height: 100,
    });
    if (canvas.toDataURL().split(",")[1] !== "") {
      setLogo(canvas.toDataURL());
      setLogoName(uploadedFiles[0]?.name);
      setPreview(false);
      const reader = new FileReader();
      canvas.toBlob(blob => {
        if (blob) {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const croppedImageData = dataURLtoFile(reader.result, uploadedFiles[0]?.type, uploadedFiles[0]?.name);
            setCroppedImage(croppedImageData);
          };
        }
      });
    } else {
      setErrorMessage("Please use correct dimensions for the image")
    }
  };

  const onDeleteClicked = () => {
    if (logo && !newLogo) {
      setOpenDeleteLogoModal(true);
    } else {
      setLogo(null);
      setCroppedImage(null);
    }
  };

  return (
    <>
      {/* Logo */}
      <MainBox>
        <ContainerTitle>
          <Typography.h6 mt={0.1} mb={0.1}>
            Logo
          </Typography.h6>
        </ContainerTitle>
        <Box pl={2.5}>
          <DragZone display="flex" alignItems="center" p={3} {...getRootProps()}>
            {preview && uploadedFiles[0].preview !== null ? (
              uploadedFiles.map(file => (
                <BoxContainer key={file.name}>
                  <BoxImage>
                    <Cropper
                      ref={crop}
                      style={{ maxHeight: '300px', maxWidth: '400px', margin: '0 auto' }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      src={file.preview}
                      viewMode={2}
                      background={false}
                      dragMode="move"
                      responsive={true}
                      autoCropArea={0.8}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                      crop={throttle(setCropper, 500)}
                      value={cropper}
                    />
                    <Button color="primary" onClick={onClick}>
                      Crop Image
                    </Button>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  </BoxImage>
                </BoxContainer>
              ))
            ) : (
              <>
                <input {...getInputProps()} />
                <DragDropIcon mr={3} size={2.3} color="colorDefault" />
                <BoxDescription>
                  <Typography.h6>Update logo</Typography.h6>
                  <Typography.p fontSize={13}>Drag &amp; drop your files or Browse</Typography.p>
                </BoxDescription>
              </>
            )}
          </DragZone>
          <Typography.p fontSize={11} mb={0.5} variant="subtitle1" paragraph>
            No larger than 5MB <br />
            Logo is cropped to 200px x 100px
          </Typography.p>
          <Typography.p fontSize={11} mt={0.01} variant="subtitle1" paragraph>
            Only .png, .jpg, .jpeg files will be accepted / Transparent or white background
          </Typography.p>
        </Box>
      </MainBox>

      {/* Current logo */}
      <MainBox>
        {logo && (
          <>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                Current Logo
              </Typography.h6>
            </ContainerTitle>
            <Box px={2.5}>
              <BoxFigure>
                <Figure>
                  <img src={logo} alt="Current Logo" />
                </Figure>
              </BoxFigure>
            </Box>
          </>
        )}

        {/* Preview new logo */}
        {newLogo && (
          <>
            <ContainerTitle>
              <Typography.h6 mt={0.1} mb={0.1}>
                Preview New Logo
              </Typography.h6>
            </ContainerTitle>
            <Box px={2.5} pb={2}>
              <BoxFigure>
                <Figure>
                  <img src={newLogo} alt="Preview New Logo" />
                </Figure>
              </BoxFigure>
              <LogoInfo>
                <span className="label">Current attachment:</span>
                <p className="filename">{logoName}</p>
                <CustomTooltip title="Remove new logo" placement="top">
                  <IconButton component="span" onClick={onDeleteClicked}>
                    <DeleteIcon color="colorDefault" size={1.2} />
                  </IconButton>
                </CustomTooltip>
              </LogoInfo>
            </Box>
          </>
        )}
      </MainBox>
    </>
  );
};

PartnerInformationLogo.defaultProps = {
  logo: ''
};

export default PartnerInformationLogo;
