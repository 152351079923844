import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from 'components/forms';

function DropdownButton({ choices, onSelect, children, ...buttonProps }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoice = choice => {
    handleClose();
    onSelect(choice);
  };

  return (
    <div>
      <Button onClick={handleOpen} {...buttonProps}>
        {children}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        // style={{
        //   maxHeight: '300px',
        // }}
      >
        {choices.map(({ label, value }) => {
          return <MenuItem onClick={() => handleChoice(value)}>{label}</MenuItem>;
        })}
      </Menu>
    </div>
  );
}

export default DropdownButton;
