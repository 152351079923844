import React, { useState } from 'react';
import { InfoButtonWithPopover } from 'components';
import styled from 'styled-components';

const Title = styled.h3`
  margin: 0;
  font-weight: ${({ weight }) => weight};
  display: flex;
  align-items: center;
`;

const LabelAndInfo = ({ label, tooltip, weight = 700, ...props }) => {
  return (
    <>
      <Title weight={weight} {...props}>
        <span>{label}</span>
        <InfoButtonWithPopover tooltip={tooltip} />
      </Title>
    </>
  );
};

export default LabelAndInfo;
