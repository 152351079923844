// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Share = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 16 16" role="img" aria-hidden="false">
      <path d="M13.1026 10.4391C12.0692 10.4391 11.1588 10.9742 10.6605 11.7739L5.6163 8.71665C5.68397 8.48289 5.72087 8.23068 5.72087 7.97847C5.72087 7.72626 5.68397 7.47405 5.6163 7.24029L10.6421 4.19531C11.1403 5.01346 12.0569 5.56094 13.1026 5.56094C14.6774 5.56094 15.9631 4.31219 15.9631 2.78047C15.9631 1.24875 14.6774 0 13.1026 0C11.5217 0 10.2422 1.24875 10.2422 2.78047C10.2422 2.85429 10.2422 2.9281 10.2545 2.99577L5.01961 6.16378C4.49673 5.57324 3.72164 5.198 2.86043 5.198C1.28566 5.198 0 6.44675 0 7.97847C0 9.51019 1.28566 10.7589 2.86043 10.7589C3.72164 10.7589 4.49673 10.3837 5.01961 9.79316L10.2545 12.9612C10.2484 13.0473 10.2422 13.1334 10.2422 13.2195C10.2422 14.7512 11.5217 16 13.1026 16C14.6774 16 15.9631 14.7512 15.9631 13.2195C15.9631 11.6817 14.6774 10.4391 13.1026 10.4391ZM13.1026 1.2303C14.0008 1.2303 14.7328 1.92541 14.7328 2.78047C14.7328 3.63552 14.0008 4.33064 13.1026 4.33064C12.506 4.33064 11.9769 4.02307 11.694 3.56171C11.5525 3.3341 11.4725 3.06344 11.4725 2.78047C11.4725 2.56517 11.5156 2.36217 11.6017 2.17762C11.8477 1.62399 12.426 1.2303 13.1026 1.2303ZM2.86043 9.52864C1.96232 9.52864 1.2303 8.83352 1.2303 7.97847C1.2303 7.12341 1.96232 6.4283 2.86043 6.4283C3.27258 6.4283 3.65398 6.57593 3.93694 6.82199C4.27527 7.10496 4.49057 7.51711 4.49057 7.97847C4.49057 8.43983 4.27527 8.85198 3.93694 9.13495C3.65398 9.38101 3.27258 9.52864 2.86043 9.52864ZM13.1026 14.7697C12.4075 14.7697 11.8108 14.3514 11.5832 13.767H11.5771C11.5094 13.6009 11.4725 13.4164 11.4725 13.2195C11.4725 12.9181 11.5648 12.6413 11.7186 12.4075C12.0015 11.9646 12.5183 11.6694 13.1026 11.6694C14.0008 11.6694 14.7328 12.3645 14.7328 13.2195C14.7328 14.0746 14.0008 14.7697 13.1026 14.7697Z"/>
  </SvgIconStyled>
);

export default Share;
