// @flow
import React, { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { ExpansionPanelDetails as AccordionDetails } from '@material-ui/core';
import { ArrowDown, Container, CustomTable, LazyIcon, Typography, TableCellField } from 'components';
import { Accordion, AccordionSummary, Chip, CancelButton as ActionButton, TableCellText } from '../../style/AdminCommon';
import PartnerTaxExemptViewModal from '../../../partner/PartnerTaxExemptViewModal';
import { useAdminList } from '../../hooks';
import { admin } from 'helpers';

type Props = {
  partnerId: string,
}

type StatusChipProps = {
  name: string,
}

const TaxExemptStatusChip = ({ name }: StatusChipProps) => {
  switch (name) {
    case 'COMPLETE':
      return <Chip label="Valid" variant="outlined" color="--colorSystemSuccess" size="small" />
    case 'INVALID':
      return <Chip label="Invalid" variant="outlined" color="--colorSystemDanger" size="small" />
    case 'EXPIRED':
      return <Chip label="Expired" variant="filled" color="--colorSystemDanger" size="small" />
    default:
      return <Chip label="Pending" variant="outlined" color="--colorSystemWarning" size="small" />
  }
};

const PartnersTaxExempt = ({ partnerId }: Props) => {
  const notify = useNotify();
  const { isLoading, data, dispatch, total, isSuccess, status } = useAdminList('partnersTaxExempt', partnerId);
  const [openCerfificate, setOpenCertificate] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const viewCertificate = (certificateId) => {
    setOpenCertificate(true);
    admin.partnersTaxExempt
      .getCertificate(partnerId, certificateId)
      .then((resp) => setCertificate(resp.data.url))
      .catch((err) => notify(err?.response?.data?.description, 'error'));
  };
  
  return (
    <>
      <PartnerTaxExemptViewModal
        open={openCerfificate}
        setOpen={setOpenCertificate}
        pdfData={certificate}
        setPdfData={setCertificate}
      />
      
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDown size={0.8} />}>
          <Typography.p>Tax Exempt</Typography.p>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTable
            source={'admin/partnersTaxExempt'}
            total={total}
            data={data}
            loading={isLoading}
            onSort={(sortValue) => dispatch.setSort(sortValue)}
            onChangePage={(page) => dispatch.setPage(page)}
            onChangePerPage={(perPage) => dispatch.setPerPage(perPage)}
            onLoad={(params) => dispatch.onLoad(params)}
          >
            <TableCellField
              renderComponent={({ state }) => (
                <TableCellText>{state}</TableCellText>
              )}
            />
            <TableCellField
              renderComponent={({ signed_date }) => (
                <TableCellText>{signed_date}</TableCellText>
              )}
            />
            <TableCellField
              renderComponent={({ expiration_date }) => (
                <TableCellText>{expiration_date}</TableCellText>
              )}
            />
            <TableCellField
              renderComponent={({ status }) => (
                <TaxExemptStatusChip name={status.name} />
              )}
            />
            <TableCellField
              renderComponent={({ actions, id }) => (
                <Container.Grid alignItems="center">
                  {actions.view && (
                    <ActionButton onClick={() => viewCertificate(id)}>
                      <LazyIcon component="Eye" color="colorDefault" mr={1.2} />
                      View
                    </ActionButton>
                  )}
                </Container.Grid>
              )}
            />
          </CustomTable>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PartnersTaxExempt;