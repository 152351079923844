import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { format } from 'date-fns';
import { Link, CircularProgress, makeStyles } from '@material-ui/core';
import { PostComment, FeedEditMode } from './index';
import { CustomAvatar, CharacterProgress, FileUpload } from '../common';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { getStorage, decodeId, cleanTextFromHtml, emojiToUnicode, convertStyleAttr } from 'helpers';
import { LoadingStyled, Button } from 'components';
import { setComments, updateFeed, clearComments, addComment, updateCommentId, deleteComment } from 'helpers/action';
import { formatHashtags, getAvatar, removeEmptyHashtagAndMention } from '../../newsfeedHelpers';

const editorOptions = [['custom-emoji']];
const LIMIT = 1000;

const useStyles = makeStyles(theme => ({
  content: {
    padding: '10px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px'
    }
  },
  addComment: {
    display: 'flex'
  },
  editor: {
    position: 'relative',
    flexGrow: 1,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
    paddingBottom: 8
  },
  noComment: {
    textAlign: 'center',
    fontWeight: 700,
    padding: 16
  },
  seeMore: {
    marginTop: 20
  }
}));

const PostCommentingSection = ({ commentsCount, postId, canPost, setDisableCommentBtn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const comments = useSelector(state => state.newsfeed.comments);
  const { profile, theme } = useSelector(state => state.bsn?.user);
  const [postingLoading, setPostingLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [newComments, setNewComments] = useState([]);
  const [clearEditorValue, setClearEditorValue] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const [startUpload, setStartUpload] = useState(false);
  const [startPreview, setStartPreview] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState(null);
  const [fileChanged, setFileChanged] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [disableCommenting, setDisableCommenting] = useState(true);
  const user = {
    name: getStorage('name'),
    id: decodeId(getStorage('userId'))
  };
  const [commentData, setCommentData] = useState({
    user_id: user.id,
    post_id: postId,
    comment_text: '',
    thumbnail_metadata: null
  });
  const cleanCommentText = useMemo(() => cleanTextFromHtml(commentData.comment_text), [commentData.comment_text]);

  const updateComment = useCallback(
    (index, newData) => {
      dispatch(
        setComments(postId, [
          ...comments[postId].slice(0, index),
          ...[
            {
              ...comments[postId][index],
              ...newData
            }
          ],
          ...comments[postId].slice(index + 1)
        ])
      );
    },
    [postId, comments, dispatch]
  );

  const postComment = useCallback(
    (filename = null) => {
      const newCommentTempId = Date.now();
      const newCommentTs = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
      const newCommentText = convertStyleAttr(
        emojiToUnicode(formatHashtags(removeEmptyHashtagAndMention(commentData.comment_text)))
      );

      const newComment = {
        comment_text: newCommentText,
        content: null,
        id: newCommentTempId,
        liked: false,
        likes_count: 0,
        timestamp: format(newCommentTs, 'yyyy-MM-dd HH:mm:ss'),
        disableActions: true,
        user: {
          client_name: profile?.client_name,
          email: profile?.email,
          first_name: profile?.first_name,
          id: +commentData.user_id,
          last_name: profile?.last_name,
          partner_base_color: theme?.base?.base
        }
      };

      if (filePreviewData) {
        newComment.content = {
          data: {
            filename,
            url: `${process.env.NEWSFEED_ASSETS_BASE_URL}assets/newsfeed/${encodeURIComponent(filename)}`
          },
          type: filePreviewData.type
        };
      } else if (commentData.thumbnail_metadata) {
        newComment.content = {
          data: {
            ...commentData.thumbnail_metadata
          },
          type: 'thumbnail'
        };
      }

      dispatch(addComment(postId, newComment));
      setPostingLoading(false);
      setFilePreviewData(null);
      dispatch(updateFeed(postId, { comments_count: commentsCount + 1 }));

      setCommentData({
        user_id: user.id,
        post_id: postId,
        comment_text: '',
        thumbnail_metadata: null,
        filename: null
      });
      setClearEditorValue(true);
      setDisableCommentBtn(true);

      newsFeed
        .addComment({ ...commentData, comment_text: newCommentText, filename })
        .then(res => {
          dispatch(updateCommentId(postId, newCommentTempId, res?.data?.data?.comment_id));
          setNewComments([...newComments, res?.data?.data?.comment_id]);
          notify(res?.data?.message);
        })
        .catch(err => {
          dispatch(deleteComment(postId, newCommentTempId));
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        })
        .finally(() => setDisableCommentBtn(false));
    },
    [
      commentData,
      commentsCount,
      dispatch,
      filePreviewData,
      newComments,
      notify,
      postId,
      profile?.client_name,
      profile?.email,
      profile?.first_name,
      profile?.last_name,
      theme?.base?.base,
      user.id
    ]
  );

  const updateCommentData = useCallback(
    (idx, comment) => {
      const newData = {
        comment_text: comment.comment_text,
        thumbnail_metadata: comment.thumbnail_metadata
      };
      updateComment(idx, newData);
    },
    [updateComment]
  );

  const deleteCommentfromComments = useCallback(
    idx => {
      dispatch(setComments(postId, [...comments[postId].slice(0, idx), ...comments[postId].slice(idx + 1)]));
    },
    [postId, comments, dispatch]
  );

  const loadMoreComments = () => {
    setCommentsLoading(true);
    const offset = comments[postId] ? comments[postId].length - newComments.length : 0;
    newsFeed
      .getComments(postId, offset, user.id)
      .then(res => {
        const oldComments = comments[postId] || [];
        const commentsArray = [...oldComments, ...res.data];
        dispatch(setComments(postId, commentsArray));
        const dataLength = commentsArray.length;
        if (res.data.length < 10) {
          setHasMore(false);
          if (dataLength !== commentsCount) {
            dispatch(updateFeed(postId, { comments_count: dataLength }));
          }
        } else {
          if (dataLength !== commentsCount) setHasMore(true);
          else setHasMore(false);
        }
      })
      .catch(err => {
        notify(err?.response?.data?.message || 'Something went wrong', 'error');
      })
      .finally(() => setCommentsLoading(false));
  };

  const likeComment = useCallback(
    idx => {
      comments[postId][idx].liked
        ? newsFeed.unLikeComment(comments[postId][idx].id, user.id, postId)
        : newsFeed.likeComment(comments[postId][idx].id, user.id, postId);

      const newData = {
        likes_count: comments[postId][idx].liked
          ? comments[postId][idx].likes_count - 1
          : comments[postId][idx].likes_count + 1,
        liked: !comments[postId][idx].liked
      };
      updateComment(idx, newData);
    },
    [postId, comments, user.id, updateComment]
  );

  const onDeleteComment = useCallback(
    id => {
      const index = newComments.indexOf(id);
      if (index > -1) {
        const newList = [...newComments];
        newList.splice(index, 1);
        setNewComments(newList);
      }
    },
    [newComments]
  );

  useEffect(() => {
    return () => dispatch(clearComments(postId));
  }, [dispatch, postId]);

  useEffect(() => {
    if (commentsCount !== 0) {
      setCommentsLoading(true);
      newsFeed
        .getComments(postId, 0, user.id)
        .then(res => {
          dispatch(setComments(postId, res.data));
          const dataLength = res.data.length;
          if (dataLength < 10) {
            setHasMore(false);
            if (dataLength !== commentsCount) {
              dispatch(updateFeed(postId, { comments_count: dataLength }));
            }
          } else {
            if (dataLength !== commentsCount) setHasMore(true);
            else setHasMore(false);
          }
        })
        .catch(err => {
          notify(err?.response?.data?.message || 'Something went wrong', 'error');
        })
        .finally(() => setCommentsLoading(false));
    }
  }, []);

  const includesDangerousUrl = () => {
    return commentData?.comment_text?.includes(localStorage.getItem('prohibitedLink'));
  };

  useEffect(() => {
    if (cleanCommentText.trim() === '' || postingLoading || cleanCommentText.length > LIMIT || includesDangerousUrl()) {
      setDisableCommenting(true);
    } else {
      setDisableCommenting(false);
    }
  }, [cleanCommentText, postingLoading]);

  const onReplyToComment = useCallback(data => setReplyData(data), []);

  const previewFile = useCallback(e => setStartPreview(e.target), []);

  const onSetData = useCallback(newData => {
    setCommentData(prevState => {
      return { ...prevState, ...newData };
    });
  }, []);

  const onPostClick = useCallback(() => {
    const hasFile = filePreviewData && (!commentData.filename || fileChanged);
    return hasFile ? setStartUpload(true) : postComment(commentData.filename);
  }, [commentData.filename, fileChanged, filePreviewData, postComment]);

  const commentsList = useMemo(
    () =>
      comments[postId]?.map((comment, index) => (
        <PostComment
          updateComments={(idx, commentData) => updateCommentData(idx, commentData)}
          deleteFromComments={idx => deleteCommentfromComments(idx)}
          isOwnComment={user.id == comment.user.id}
          key={comment.id}
          comment={comment}
          idx={index}
          postId={postId}
          toggleLike={idx => likeComment(idx)}
          onDeleteComment={onDeleteComment}
          onReplyToComment={onReplyToComment}
          canPost={canPost}
          commentsCount={commentsCount}
        />
      )),
    [
      comments,
      postId,
      user.id,
      updateCommentData,
      deleteCommentfromComments,
      likeComment,
      onDeleteComment,
      onReplyToComment,
      canPost,
      commentsCount
    ]
  );

  const fileUploadMemo = useMemo(
    () => <FileUpload type="image" previewFile={previewFile} iconSize={1} insideEditor />,
    [previewFile]
  );

  const avatarMemo = useMemo(() => <CustomAvatar user={{avatar_filename: profile?.avatar_filename}}>{getAvatar(user.name)}</CustomAvatar>, [user.name]);

  const commentBtnMemo = useMemo(
    () => (
      <Button onClick={onPostClick} disabled={disableCommenting} variant="contained" color="primary" disableElevation>
        {postingLoading && <CircularProgress color="inherit" size={20} />}&nbsp; Post
      </Button>
    ),
    [disableCommenting, onPostClick, postingLoading]
  );

  const progressMemo = useMemo(
    () => <CharacterProgress length={cleanCommentText.length} limit={LIMIT} mr={16} />,
    [cleanCommentText.length]
  );

  const feedEditModeMemo = useMemo(
    () => (
      <FeedEditMode
        data={commentData}
        setData={onSetData}
        initialData={null}
        textType="comment_text"
        loading={postingLoading}
        setLoading={setPostingLoading}
        startUpload={startUpload}
        setStartUpload={setStartUpload}
        startPreview={startPreview}
        setStartPreview={setStartPreview}
        setFileChanged={setFileChanged}
        onSubmit={postComment}
        filePreviewData={filePreviewData}
        setFilePreviewData={setFilePreviewData}
        setDisableCommentBtn={setDisableCommentBtn}
        editorData={{
          editorOptions,
          height: '100px',
          placeholder: 'Write a comment',
          clearState: clearEditorValue,
          setClearState: setClearEditorValue,
          mentionData: replyData
        }}
      />
    ),
    [
      clearEditorValue,
      commentData,
      filePreviewData,
      onSetData,
      postComment,
      postingLoading,
      replyData,
      startPreview,
      startUpload
    ]
  );

  return (
    <div className={classes.content}>
      {canPost && (
        <>
          <div className={classes.addComment}>
            {avatarMemo}

            <div className={classes.editor}>
              {fileUploadMemo}
              {feedEditModeMemo}
            </div>
          </div>

          <div className={classes.footer}>
            {cleanCommentText.trim().length > 0 && progressMemo}
            {commentBtnMemo}
          </div>
        </>
      )}

      {!canPost && commentsCount === 0 && (
        <div className={classes.noComment} p={2} fontWeight={700} textAlign="center">
          No comments yet
        </div>
      )}

      {commentsCount !== 0 && comments[postId] && commentsList}

      {commentsCount !== 0 && commentsLoading && <LoadingStyled />}

      {hasMore && !commentsLoading ? (
        <div className={classes.seeMore}>
          <Link component="button" variant="body2" onClick={() => loadMoreComments()}>
            See More
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default PostCommentingSection;
