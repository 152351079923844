// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const ReferralCodeIcon = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 87 48" role="img" aria-hidden="false">
      <path d="M81.3889 8.54879C80.5952 7.60829 79.7328 6.72796 78.8087 5.91505C74.4488 2.12649 68.8657 0.0400391 63.0881 0.0400391C57.3104 0.0400391 51.7274 2.12649 47.3674 5.91505C46.4123 6.70517 45.5133 7.56058 44.6767 8.47512C41.0136 12.799 39.0034 18.2808 39.0034 23.9461C39.0034 29.6114 41.0136 35.0933 44.6767 39.4171C45.4818 40.3475 46.35 41.2213 47.2753 42.0324C51.4619 45.6842 56.7955 47.7563 62.3509 47.8893C62.5261 47.9622 62.714 47.9998 62.9038 47.9998C63.0936 47.9998 63.2815 47.9622 63.4567 47.8893C69.0422 47.7632 74.4053 45.6767 78.606 41.9956C79.6007 41.1846 80.5311 40.2979 81.3889 39.3434C85.0132 35.0303 87 29.5783 87 23.9461C87 18.3139 85.0132 12.862 81.3889 8.54879ZM83.2319 22.1043H74.2565C74.6263 18.1022 76.1788 14.3004 78.7166 11.1825C81.2743 14.2938 82.8463 18.0962 83.2319 22.1043ZM70.6627 22.1043H64.8021V3.77858C68.982 4.14058 72.9414 5.80969 76.118 8.54879C72.8823 12.3682 70.9403 17.1136 70.5706 22.1043H70.6627ZM61.1161 3.77858V22.1043H55.4766C55.1249 17.0906 53.1888 12.3182 49.9476 8.47512C53.098 5.79814 56.9983 4.158 61.1161 3.77858ZM47.2753 11.0536C49.8728 14.1908 51.4484 18.0468 51.7906 22.1043H42.7784C43.1223 18.0505 44.6905 14.1967 47.2753 11.0536ZM42.7784 25.7879H51.7906C51.4484 29.8454 49.8728 33.7014 47.2753 36.8386C44.6905 33.6955 43.1223 29.8417 42.7784 25.7879ZM55.4766 25.7879H61.1161V44.2057C56.962 43.815 53.0327 42.1413 49.8739 39.4171C53.1417 35.5835 55.104 30.8101 55.4766 25.7879ZM64.8021 44.2057V25.7879H70.5706C70.9351 30.7765 72.8705 35.5217 76.0995 39.3434C72.9398 42.1123 68.9862 43.8139 64.8021 44.2057ZM78.7166 36.8386C76.1551 33.6851 74.6012 29.835 74.2565 25.7879H83.2319C82.8463 29.796 81.2743 33.5984 78.7166 36.7097V36.8386Z" fill="white"/>
      <rect x="20" y="17" width="12" height="3" rx="1.5" fill="#3387FF"/>
      <rect x="10" y="23" width="22" height="3" rx="1.5" fill="#0069FF"/>
      <rect y="29" width="32" height="3" rx="1.5" fill="#3387FF"/>
  </SvgIconStyled>
);

export default ReferralCodeIcon;
