// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerDark = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 23.164 17.944" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h23.164v17.944H0z" />
    <g transform="translate(-535 -207)">
      <path
        d="M36.881,33.691H35.394l-2.572-8.909a2.86,2.86,0,0,0-3.712-2.107l-3.037,1.108-3.037-1.108a2.963,2.963,0,0,0-.939-.182,2.994,2.994,0,0,0-2.773,2.289l-2.572,8.909H15.267a.767.767,0,1,0,0,1.535h1.66L20.5,40.139a.817.817,0,0,0,1.121.136l4.442-3.66.009.009a.009.009,0,0,1,.009-.009l4.441,3.66a.817.817,0,0,0,1.122-.136l3.575-4.913h1.66a.767.767,0,1,0,0-1.535ZM21.268,38.568l-2.435-3.342h6.493Zm9.612,0-4.058-3.342h6.493ZM18.349,33.691,20.8,25.209a1.533,1.533,0,0,1,1.341-1.144,1.349,1.349,0,0,1,.42.064c1.085.4,3.247,1.2,3.247,1.2a.794.794,0,0,0,.529,0s2.162-.8,3.247-1.2a1.434,1.434,0,0,1,1.76,1.081L33.8,33.691Z"
        transform="translate(520.508 184.506)"
      />
    </g>
  </SvgIconStyled>
);

export default PartnerDark;
