// @flow
import { useReducer } from 'react';

const initialState = {
  activeStep: 0,
  completed: {}
}

const useAdminSteps = () => {
  const [state, setState] = useReducer(reducer, initialState);

  const nextStep = () => {
    setState({ type: "NEXT_STEP", payload: state.activeStep + 1});
  };

  const onBack = () => {
    setState({ type: "BACK_STEP", payload: state.activeStep - 1});
  };

  return {
    onBack,
    nextStep,
    activeStep: state.activeStep,
    completed: state.completed,
  };
};

function reducer (state, action) {
  switch (action.type){
    case "NEXT_STEP":
      return {
        ...state,
        activeStep: action.payload,
        completed: {
          ...state.completed,
          [state.activeStep]: true
        }
      }
    case "BACK_STEP":
      return {
        ...state,
        activeStep: action.payload,
      }
  }
}

export default useAdminSteps;