// @flow
import React, { memo, type Element, useState, useEffect } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

type Props = Object;

const DateTimeField = ({
  required,
  classes,
  nullValue,
  value,
  onChange,
  helperText,
  error,
  ...props
}: Props): Element<*> => {
  const [newError, setNewError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getDate = () => {
    return value && value !== ''
      ? new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60000).toISOString()
      : nullValue
      ? null
      : new Date();
  };

  let date = getDate();

  useEffect(() => {
    if (value) {
      let date = getDate();
      setValue(date);
      setNewError(false);
      setErrorMessage('');
    }
  }, [value]);

  const [newValue, setValue] = useState(date);
  const isOnchange = typeof onChange === 'function';

  const onDateChange = e => {
    let dateString = new Date(e.getTime() - e.getTimezoneOffset() * 60000).toISOString();
    if (isOnchange) onChange(dateString);
    setValue(e);
  };

  const handleInvalid = e => {
    e.preventDefault();
    setNewError(!e.target.validity.valid);
    setErrorMessage(e.target.validationMessage);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        {...props}
        inputVariant="outlined"
        margin="none"
        value={newValue}
        onChange={onDateChange}
        classes={classes || {}}
        InputLabelProps={{ classes: { focused: 'field-focused' } }}
        format="yyyy-MM-dd hh:mm a"
        error={error || newError}
        helperText={helperText || errorMessage}
      />
      <input required={required} value={newValue} style={{ display: 'none' }} onInvalid={handleInvalid} />
    </MuiPickersUtilsProvider>
  );
};

export default memo<Props>(DateTimeField);
