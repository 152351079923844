import React, { useEffect, useState } from 'react';
import { Autocomplete, CheckboxInput, Grid, TextField } from '@trustsecurenow/components-library';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ContainerStepContent from './ContainerStepContent';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { useDebounce } from 'hooks';
import { ADMIN_MICRO_TRAINING } from '../../constants';
import PublishDatePicker from './PublishDatePicker';

const filter = createFilterOptions();

function formatHashtags(value) {
  if (value) {
    const words = value.split(',') || value.split(' ');
    return words.filter(word => word.match(/#[\w_]+/));
  }
  return [];
}

const HashtagsField = ({ dispatch, record_hashtags }) => {
  const current_hashtags = formatHashtags(record_hashtags);
  const [hashtags, setHashtags] = useState({
    options: [],
    selecteds: current_hashtags,
    search: '',
    loading: false
  })
  const [searchHashTag] = useDebounce({ value: hashtags.search, delay: 500 });
  
  const fetchHashtags  = async (searchTerm = null)  => {
    setHashtags(prev => ({ ...prev, loading: true}));
    try {
      const res = await newsFeed.getHashtags(searchTerm)
      const options = res?.data && res.data.map(item => ({ label: `#${item.tag_name}` }))
      setHashtags(prev => ({
        ...prev,
        options: [...options]
      }))
    } catch (err) {
      setHashtags(prev => ({ ...prev, options: [] }));
    } finally {
      setHashtags(prev => ({ ...prev, loading: false}));
    }
  }

  useEffect(() => {
    const searchTerm = searchHashTag.value?.charAt(0) === '#' ? searchHashTag.value.substring(1) : searchHashTag.value
    fetchHashtags(searchTerm)
  }, [searchHashTag.value]);

  return (
    <Autocomplete
      multiple
      name="hash_tags"
      loading={hashtags.loading}
      onChange={(_e, val, reason) => {
        const seleteds = val.map(option => {
          if ( typeof option === 'string' && option.startsWith('#')) {
            return option;
          }
          // case user selected to add new option
          if (option?.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }).filter(option => option !== undefined);
      
        setHashtags(prev => ({
          ...prev,
          selecteds: seleteds
        }))

        dispatch.onChange('hash_tags', seleteds.join(','))
        
        if (reason === 'clear') {
          setHashtags(prev => ({
            ...prev,
            search: ''
          }));
        }
      }}
      value={hashtags.selecteds}
      inputValue={hashtags.search}
      onInputChange={(e, value) => {
        setHashtags(prev => ({
          ...prev,
          search: value
        }));
      }}
      freeSolo
      clearOnBlur
      getOptionLabel={option => typeof option === 'string' ? option : option?.label}
      options={hashtags.options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const allowedHashtag = /#[\w_]+/.test(params.inputValue);
        // Suggest the creation of a new value
        const isExisting = !hashtags.loading && options.some((option) => {
          return params.inputValue?.toLocaleLowerCase() === option?.label?.toLocaleLowerCase();
        });
        if (allowedHashtag && !isExisting) {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      fullWidth
      renderInput={(params) =>
        <TextField
          {...params}
          label="Hashtags"
          multiline
          sx={{
            '& .MuiInputBase-root': {
              alignItems: 'flex-start',
              gap: 1
            }
          }}
          rows={6}
        />
      }
    />
  )
};

const InfoStep = ({ stepNumber, dispatch, record, page, createMode }) => {
  const isMicroTraining = page === ADMIN_MICRO_TRAINING;

  const disabled = !record.name?.trim() || (isMicroTraining && !record.video_number?.trim());

  return (
    <ContainerStepContent
      headerTitle={`${stepNumber}. Information`}
      onSubmit={dispatch.onNext}
      disableSubmit={disabled}
      submitLabel={createMode ? 'Next' : 'Update'}
      isFirstStep
    >
      <Grid container spacing={2} p={2} pt={4} pb={4}>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            value={record.name}
            name="name"
            label="Name"
            onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
            required
            fullWidth
          />
        </Grid>

        
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            value={record.created_date}
            name="created_date"
            label="Created Date"
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} lg={2}>
          <PublishDatePicker dispatch={dispatch} record={record} page={page} />
        </Grid>

        {isMicroTraining && (
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              value={record.video_number}
              name="video_number"
              label="Video Number"
              onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
              fullWidth
              required
            />
          </Grid>
        )}
      
        <Grid item xs={12} lg={isMicroTraining ? 2 : 5}>
          <CheckboxInput
            label="Publicly Accessible"
            value={record.publicly_accessible}
            checked={Boolean(record.publicly_accessible)}
            onChange={(e) => dispatch.onChange(e.target.name, e.target.checked)}
            name="publicly_accessible"
            color="default"
          />
        </Grid>
        
        <Grid item xs={12} md={6} lg={5}>
          <TextField
            name="description"
            label="Description"
            value={record.description}
            onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
            fullWidth
            multiline
            rows={6}
          />
        </Grid>
       
        <Grid item xs={12} md={6} lg={5}>
          <HashtagsField dispatch={dispatch} record_hashtags={record.hash_tags} />
        </Grid>
      </Grid>
    </ContainerStepContent>    
  );
};

export default InfoStep;