// @flow
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  makeStyles,
  Dialog
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Button, ButtonCancel, Container, Typography } from 'components';
import { LazyIcon, Save2 } from 'components/icons';
import { arraysHaveSameValues, decodeId } from 'helpers';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialogTitleStyled, IconButton } from 'components/modal/ComponentTypes';
import { TYPES } from './helpers';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  },
  padded: {
    margin: '0 10px'
  },
  input: {
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0px 1000px var(--backgroundPaper) inset'
    }
  }
});

const TableCellContainer: ComponentType<*> = styled(TableCell)`
  && {
    &:hover {
      cursor: pointer;
    }
  }
`;

const NoResults = styled.h2`
  text-align: center;
  color: var(--colorDefault);
  font-size: 21px;
  padding: 20px;
  margin: 0;
`;

export const SaveButton = styled(Button)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#d2eafa' : 'var(--colorSystemInfo)')};
    border-color: ${({ disabled }) => (disabled ? '#d2eafa !important' : 'var(--colorSystemInfo)')};
    color: ${({ disabled }) => (disabled ? '#98c2ff !important' : 'var(--buttonLight) !important')};
    text-transform: none;
    font-size: 14px;
    min-width: 115px;
    height: 40px;
    opacity: 1 !important;
    &:hover {
      background-color: var(--colorSystemInfoHover);
    }
  }
`;

const settings = {
  [TYPES.includeUser]: {
    searchName: 'user',
    title: 'Select Users',
    isIncludeType: true,
    stateKey: 'included_users',
    picklistKey: 'users'
  },
  [TYPES.includePartner]: {
    searchName: 'partner',
    title: 'Select Partner Admins',
    isIncludeType: true,
    stateKey: 'included_users',
    picklistKey: 'users'
  },
  [TYPES.excludeClient]: {
    searchName: 'client',
    title: 'Select Clients',
    isIncludeType: false,
    stateKey: 'excluded_clients',
    picklistKey: 'clients'
  }
};

const ReceiversSelectionModal = props => {
  const classes = useStyles();
  const groupId = decodeId(localStorage.getItem('groupId'));

  const { title, searchName, isIncludeType, stateKey, picklistKey } = settings[props.type] || {};

  const initialState = {
    options: [],
    selected_options: [],
    firstSelectedOptions: []
  };
  const [state, setState] = useState(initialState);
  const [tableList, setTableList] = useState([]);
  const [group, setGroup] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const picklist = props.state?.picklist?.[picklistKey] || [];
    const selectedList = props.state?.[stateKey] || [];
    setState({
      options: picklist,
      selected_options: selectedList,
      firstSelectedOptions: selectedList
    });
    setTableList(picklist);

    return reset;
  }, [props.state, stateKey, picklistKey]);

  const reset = () => {
    setState(initialState);
    setTableList([]);
    setGroup([]);
    setSearchValue('');
  };

  const handleSave = arr => {
    props.setState(st => ({ ...st, [stateKey]: arr }));
    props.onClose();
  };

  const search = e => {
    const searchVal = e.target.value;
    const arr = [...state.options].filter(el => {
      const isUserExist = el.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1;
      if (group.length > 0) {
        return isUserExist && group.includes(el.group_name);
      }
      return isUserExist;
    });
    setSearchValue(searchVal);
    setTableList(arr);
  };

  const filter = e => {
    const groupVal = e.target.value;
    const arr = [...state.options].filter(el => {
      const isUserExist = el.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      if (groupVal.length > 0) {
        return isUserExist && groupVal.includes(el.group_name);
      }
      return isUserExist;
    });
    setGroup(groupVal);
    setTableList(arr);
  };

  const selectOption = idx => {
    setState({
      ...state,
      selected_options: [...state.selected_options, tableList[idx]]
    });
  };

  const revertSelectOption = idx => {
    const arr = [...state.selected_options];
    arr.splice(idx, 1);
    setState({ ...state, selected_options: arr });
  };

  const handleClose = () => {
    props.onClose();
    setSearchValue('');
    setTableList(state.options);
  };

  const saveDisabled = React.useMemo(() => {
    return arraysHaveSameValues(state.selected_options, state.firstSelectedOptions)
  }, [state.selected_options, state.firstSelectedOptions])

  return (
    <>
      <Dialog open={props.open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="sm">
        <DialogTitleStyled id="dialog-title">
          <Typography.h3>{title}</Typography.h3>
          <IconButton onClick={handleClose}>
            <LazyIcon component="Close" />
          </IconButton>
        </DialogTitleStyled>

        <Container.Grid p={2} container>
          <Container.Grid item xs={12}>
            <TextField
              className={`${classes.padded} ${classes.input}`}
              name="name"
              id="input-with-icon-textfield"
              label={`Enter the ${searchName} name`}
              variant="outlined"
              fullWidth
              value={searchValue}
              onChange={e => search(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            {props.type === TYPES.includeUser && (
              <FormControl className={classes.padded} fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">Group role</InputLabel>
                <Select
                  name="group_name"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={group}
                  variant="outlined"
                  label="Group Role"
                  onChange={e => filter(e)}
                  multiple
                >
                  {/* <MenuItem value={'E'}>Employee</MenuItem> */}
                  <MenuItem value={'M'}>Manager</MenuItem>
                  <MenuItem value={'MA'}>Manager Admin</MenuItem>
                  {groupId != 7 && <MenuItem value={'PA'}>Partner Administrator</MenuItem>}
                </Select>
              </FormControl>
            )}
          </Container.Grid>
        </Container.Grid>
        <Container.Grid flexWrap="wrap" p={2} container>
          {state.selected_options.map((row, idx) => (
            <Box p={1}>
              <Chip label={row.name} onDelete={() => revertSelectOption(idx)} />
            </Box>
          ))}
        </Container.Grid>
        <Container.Grid p={2} container>
          <Container.Grid item xs={12}>
            <TableContainer className={classes.container} component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">{isIncludeType && 'Group Role'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableList.length > 0 ? (
                    tableList.map((row, idx) => {
                      if (state.selected_options.filter(e => e.id === row.id).length === 0)
                        return (
                          <TableRow onClick={e => selectOption(idx)} key={row.id}>
                            <TableCellContainer component="th" scope="row">
                              {row.name}
                            </TableCellContainer>
                            <TableCell align="right">{isIncludeType && row.group_name}</TableCell>
                          </TableRow>
                        );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <NoResults>No results.</NoResults>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container.Grid>
        </Container.Grid>
        <Container.Grid p={2} container>
          <Container.Grid justify="flex-end" item xs={12}>
            <Box>
              <ButtonCancel onClick={handleClose}>Cancel</ButtonCancel>
            </Box>
            <Box mr={1}>
              <Tooltip
                title={!state.selected_options.length ? 'Please select users from the list to continue' : ''}
                placement="top-end"
              >
                <div>
                  <SaveButton
                    disabled={saveDisabled}
                    disableElevation
                    variant="outlined"
                    onClick={e => handleSave(state.selected_options)}
                  >
                    <Save2 mr={1.2} size={1.3} /> Save
                  </SaveButton>
                </div>
              </Tooltip>
            </Box>
          </Container.Grid>
        </Container.Grid>
      </Dialog>
    </>
  );
};

export default ReceiversSelectionModal;
