// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Cube = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20 20" role="img" aria-hidden="false">
    <path d="M1.54739 2.32108V7.31915C1.54739 7.74468 1.20696 8.09284 0.773694 8.09284C0.348162 8.09284 0 7.74468 0 7.31915V0.773694C0 0.348162 0.348162 0 0.773694 0H7.16442C7.58995 0 7.93811 0.348162 7.93811 0.773694C7.93811 1.19923 7.58995 1.54739 7.16442 1.54739H2.32108C1.89555 1.54739 1.54739 1.89555 1.54739 2.32108ZM1.54739 12.7195V17.6789C1.54739 18.1044 1.89555 18.4526 2.32108 18.4526H7.16442C7.58995 18.4526 7.93811 18.8007 7.93811 19.2263C7.93811 19.6518 7.58995 20 7.16442 20H0.773694C0.348162 20 0 19.6518 0 19.2263V12.7195C0 12.294 0.348162 11.9458 0.773694 11.9458C1.19923 11.9458 1.54739 12.294 1.54739 12.7195ZM18.3908 2.32108V7.31915C18.3908 7.74468 18.739 8.09284 19.1645 8.09284C19.59 8.09284 19.9382 7.74468 19.9382 7.31915V0.773694C19.9382 0.348162 19.59 0 19.1645 0H12.5649C12.1393 0 11.7912 0.348162 11.7912 0.773694C11.7912 1.19923 12.1393 1.54739 12.5649 1.54739H17.6171C18.0427 1.54739 18.3908 1.89555 18.3908 2.32108ZM18.3908 17.6789V12.7195C18.3908 12.294 18.739 11.9458 19.1645 11.9458C19.59 11.9458 19.9382 12.294 19.9382 12.7195V19.2263C19.9382 19.6518 19.59 20 19.1645 20H12.5649C12.1393 20 11.7912 19.6518 11.7912 19.2263C11.7912 18.8007 12.1393 18.4526 12.5649 18.4526H17.6171C18.0427 18.4526 18.3908 18.1044 18.3908 17.6789Z" />
  </SvgIconStyled>
);

export default Cube;
