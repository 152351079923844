// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showNotification as RAshowNotification, translate } from 'ra-core';

type SaveButtonTypes = {
  buttonFullWidth?: boolean,
  handleSubmitWithRedirect?: Function,
  invalid: boolean,
  label: string,
  onClick: Function,
  pristine: boolean,
  redirect: string | boolean | Function,
  saving: Object | boolean,
  showNotification: Function,
  submitOnEnter: boolean,
  translate: Function,
  variant: 'raised' | 'flat' | 'fab',
  basePath: string,
  className: string,
  classes: Array<Object>,
  handleSubmit: Function,
  record: boolean,
  resource: Object,
  locale: string,
  undoable: boolean,
  ...
};

export class SaveButton extends Component<SaveButtonTypes> {
  static defaultProps = {
    buttonFullWidth: false,
    handleSubmitWithRedirect: () => () => {}
  };

  handleClick: Function = (e: Event): void => {
    const { handleSubmitWithRedirect, invalid, redirect, saving, showNotification, onClick } = this.props;

    if (saving) {
      // prevent double submission
      e.preventDefault();
    } else {
      if (invalid) {
        showNotification('ra.message.invalid_form', 'warning');
      }
      // always submit form explicitly regardless of button type
      if (e) {
        e.preventDefault();
      }
      if (handleSubmitWithRedirect) handleSubmitWithRedirect(redirect)();
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  render() {
    const {
      buttonFullWidth,
      invalid,
      label = 'ra.action.save',
      pristine,
      redirect,
      saving,
      submitOnEnter,
      translate: trans,
      onClick,
      ...rest
    } = this.props;

    const type = submitOnEnter ? 'submit' : 'button';
    return (
      <Button
        fullwidth={buttonFullWidth}
        type={type}
        onClick={this.handleClick}
        color={saving ? 'default' : 'primary'}
        {...(rest: $Rest<SaveButtonTypes, any>)}
      >
        {saving && saving.redirect === redirect ? (
          <CircularProgress size={18} thickness={2} />
        ) : (
          label && trans(label, { _: label })
        )}
      </Button>
    );
  }
}

const enhance = compose(
  translate,
  connect(
    undefined,
    { showNotification: RAshowNotification }
  )
);

export default enhance(SaveButton);
