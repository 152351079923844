// @flow
import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: props => (props.mr ? `${props.mr}px` : 0),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  bottom: {
    color: '#F2F2F2'
  },
  top: {
    color: props => props.color,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  text: {
    fontSize: 10,
    fontWeight: 700,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block'
  }
}));

function getColor(charsLeft, length) {
  if (length === 0) return 'none';
  if (charsLeft > 20) return 'var(--colorSystemInfo)';
  if (charsLeft < 0) return 'var(--colorSystemDanger)';
  return 'var(--colorSystemWarning)';
}

const CharacterProgress = ({ length = 0, limit, mr, customErrorText }) => {
  const charsLeftVal = limit - length;
  const charsLeft = charsLeftVal < 0 ? 0 : charsLeftVal;
  const text = charsLeft > 20 ? '' : charsLeft;
  const color = getColor(charsLeftVal, length);
  const percentVal = (length / limit) * 100;
  const percent = percentVal > 100 ? 100 : percentVal;
  const classes = useStyles({ color, mr });

  return (
    <div className={classes.root}>
      <CircularProgress variant="determinate" value={100} className={classes.bottom} size={25} thickness={4} />
      <CircularProgress variant="determinate" value={percent} className={classes.top} size={25} thickness={4} />
      {customErrorText && percent === 100 && charsLeftVal !== 0 ? (
        <span className={classes.text} style={{ color: 'red' }}>
          {customErrorText}
        </span>
      ) : (
        <span className={classes.text}>{text}</span>
      )}
    </div>
  );
};

export default React.memo(CharacterProgress);
