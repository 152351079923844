// @flow
import React from 'react';
import ClientsEditNotification from './pushNotifications/pushNotifications';

const PagePushNotifications = ({ clientId, trackGA, partnerId, onFinishLoading = () => {}, isLoading }) => {
  return (
    <ClientsEditNotification
      clientId={clientId}
      trackGA={trackGA}
      partner_id={partnerId}
      onFinishLoading={onFinishLoading}
      isLoading={isLoading}
    />
  );
};

export default PagePushNotifications;