import { Box, CircularProgress, Button as MUIButton } from '@material-ui/core';
import { CustomTooltip } from 'components';
import { Save2 } from 'components/icons';
import * as pushNotifications from 'helpers/apis/services/pushNotifications';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import styled from 'styled-components';

export const SaveButtonStyled = styled(MUIButton)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#d2eafa' : 'var(--colorSystemInfo)')};
    border-color: ${({ disabled }) => (disabled ? '#d2eafa !important' : 'var(--colorSystemInfo)')};
    color: ${({ disabled }) => (disabled ? '#98c2ff !important' : 'var(--buttonLight) !important')};
    text-transform: none;
    font-size: 14px;
    min-width: 110px;
    height: 40px;
    opacity: 1 !important;
    &:hover {
      background-color: var(--colorSystemInfoHover);
    }
  }
`;

function SaveButton({
  state,
  setInitialData,
  refetchSubscription,
  refetchTable,
  noUsersSelected,
  invalidData,
  isEditedData,
  setActionLoading
}) {
  const notify = useNotify();
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSave = async () => {
    setActionLoading(true);
    setSaveLoading(true);
    try {
      await pushNotifications.setSubscription(state?.subscription?.id, state);

      // Note: In case of a client is customizing default settings and pressed Save,
      // his custom settings will not be effective unless he presses Enable,
      // therefore, we can't refetch subscription data here since it will fall back to the default settings again.
      setInitialData(cloneDeep(state));
      await refetchSubscription();
      await refetchTable(); // refetching table data

      notify('Settings have been saved', { type: 'success' });
    } catch (err) {
      notify(err?.response?.data?.message || 'Something went wrong', 'error');
    } finally {
      setSaveLoading(false);
      setActionLoading(false);
    }
  };

  const tooltip = noUsersSelected ? `Select users to receive ${state?.push_notification?.name} notifications` : '';

  return (
    <Box ml={3}>
      <CustomTooltip title={tooltip} placement="top" arrow light maxWidth={250}>
        <span>
          <SaveButtonStyled
            disabled={saveLoading || invalidData || !isEditedData}
            variant="outlined"
            disableElevation
            onClick={handleSave}
          >
            {saveLoading ? (
              <CircularProgress size={14} thickness={2} style={{ color: `var(--commonWhite)`, marginRight: 10 }} />
            ) : (
              <Save2 mr={1.2} size={1.3} />
            )}
            Save
          </SaveButtonStyled>
        </span>
      </CustomTooltip>
    </Box>
  );
}

export default SaveButton;
