// @flow
import React from 'react';
import { addField } from 'ra-core';
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import type { Element } from 'react';

type Props = {
  input: string,
  isRequired: boolean,
  label?: string | null,
  record?: Object,
  meta: string,
  options: Object,
  resource: string,
  source: string,
  onBlur: Function,
  onFocus: Function,
  onChange: Function
};

const SwitchMultipleColumns = ({
  input,
  isRequired,
  label,
  meta,
  options,
  resource,
  source,
  onBlur,
  onFocus,
  onChange,
  ...rest
}: Props): Element<*> => {
  // const [bool, setBool] = useState(record[source]);
  console.log(resource);
  console.log(source);
  console.log(rest);
  return (
    <FormControlLabel
      {...rest}
      control={
        <MuiSwitch
          name={source}
          checked={false}
          onChange={false}
          {...options}
          {...rest}
          {...input}
        />
      }
    />
  );
};

SwitchMultipleColumns.defaultProps = {
  label: null,
  record: {}
};

export default addField(SwitchMultipleColumns);
