/* eslint-disable no-shadow */
import React from 'react';
import { Paper, styled } from '@material-ui/core';
import { useLocation } from 'hooks';
import { useHistory } from 'react-router-dom';
import { SRA_SUB_PAGES } from '../constants';
import { useSRA } from '../context/sraContext';
import SRAQuestionsQuiz from '../SRAQuestionsQuiz';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(5),
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(2.5),
  height: 'calc(100vh - 204px)'
}));

const QuestionsPage = () => {
  const { app, tab, item } = useLocation();
  const history = useHistory();
  const isSraSubPage = SRA_SUB_PAGES.includes(item);
  const { clientId } = useSRA();

  const goToSraPage = () => {
    history.push(`/${app}/${tab}${item && !isSraSubPage ? `/${item}` : ''}`);
  };

  return <SRAQuestionsQuiz clientId={clientId} Container={StyledPaper} onSubmit={goToSraPage} />;
};

export default QuestionsPage;
