import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

const MultiMedia = props => (
  <SvgIconStyled {...props} width="37" height="36" viewBox="0 0 37 36" fill="none" role="img" aria-hidden="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7308 4.15385C33.7308 3.39231 33.1077 2.76923 32.3462 2.76923H4.65385C3.89231 2.76923 3.26923 3.39231 3.26923 4.15385V31.8462C3.26923 32.6077 3.89231 33.2308 4.65385 33.2308H32.3462C33.1077 33.2308 33.7308 32.6077 33.7308 31.8462V4.15385ZM4.65385 0H32.3462C34.6308 0 36.5 1.86923 36.5 4.15385V31.8462C36.5 34.1308 34.6308 36 32.3462 36H4.65385C2.36923 36 0.5 34.1308 0.5 31.8462V4.15385C0.5 1.86923 2.36923 0 4.65385 0ZM13.474 12.0184C13.3217 11.9215 13.1556 11.88 12.9894 11.88C12.4771 11.88 12.0063 12.2815 12.0063 12.8492V19.0107V25.1723C12.0063 25.74 12.4771 26.1553 12.9894 26.1553C13.1417 26.1553 13.3217 26.1138 13.474 26.0169L18.8463 22.943L24.2186 19.8692C24.8694 19.4815 24.8694 18.54 24.2186 18.1661L18.8463 15.0923L13.474 12.0184ZM9.23706 12.863C9.23706 10.8 10.9125 9.11073 12.9894 9.11073C13.6402 9.11073 14.2909 9.27689 14.8586 9.63689L20.2309 12.7107L25.6032 15.7846C26.7663 16.4492 27.4863 17.6953 27.4863 19.0384C27.4863 20.3815 26.7663 21.6277 25.6032 22.2923L20.2309 25.3661L14.8586 28.44C14.2909 28.7723 13.6402 28.9384 12.9894 28.9384C10.9263 28.9384 9.23706 27.2492 9.23706 25.1861V19.0246V12.863Z"
      fill="#0069FF"
    />
  </SvgIconStyled>
);

export default React.memo(MultiMedia);
