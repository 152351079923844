import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CalendarFile } from 'components/icons';

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return date.toLocaleString('default', { month: 'long' });
  }
}

const dateToolbarStyle = {
  fontWeight: 'bold',
  fontSize: '130%',
  alignSelf: 'center',
  margin: '15px 0 -10px 0',
  color: 'var(--blackAndWhite)'
};

const DatePickerField = ({ value, onChange, minDate, id, label, ...rest }) => {
  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils}>
      <KeyboardDatePicker
        ToolbarComponent={() => <div style={dateToolbarStyle}>Choose Date</div>}
        value={value}
        minDate={minDate}
        id={id}
        format="MM/dd/yyyy"
        variant="inline"
        fullWidth
        inputVariant="outlined"
        size="small"
        allowKeyboardControl={false}
        autoOk
        placeholder="mm/dd/yyyy"
        label={label}
        onChange={onChange}
        keyboardIcon={<CalendarFile style={{ color: 'var(--colorDefault)' }} />}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
