import styled from "styled-components";
import { Container } from 'components';
import { PreassessmentInputField } from './PreassessmentWrapper';

export const  PreassessmentInfoContainer = styled(Container.Grid)`
  max-width: 435px;
  width: 100%;
`

export const PreassessmentInfoInput = styled(PreassessmentInputField)`
  && {
    margin: ${({ml, mr, mt, mb}) => `${mt ?? 0} ${mr ?? 'calc(var(--spacing) * 2)'} ${mb ?? 'calc(var(--spacing) * 2)'} ${ml ?? 0}`} !important;

    ${props => props.theme.breakpoints.down("xs")} {
      margin: 0 0 calc(var(--spacing) * 2) 0 !important;
    }
  }
`
