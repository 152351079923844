// @flow

import useDataGet from './useDataGet';

type useDataListTypes = {
  app: string,
  tab: string,
  item?: string,
  params?: Object,
  page?: number
};

function useDataList({ params = {}, page = 1, ...rest }: useDataListTypes) {
  const options = {
    pagination: {
      page,
      perPage: 25
    },
    ...params
  };

  return useDataGet({ ...rest, page, params: options, isList: true });
}

export default useDataList;
