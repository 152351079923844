import React, { Children, createElement, memo } from 'react';
import { Checkbox, TableRow } from '@material-ui/core';
import { CollapseLoading, NoDataText, TableCellStyled } from '../style';
import isEqual from 'lodash/isEqual';

const CustomTableCollapsedCell = memo(
  ({ child, data, collapseDataItem, onClickRow, rowClick }) => {
    return createElement(child.type, {
      ...child.props,
      data: { ...data, collapseData: collapseDataItem },
      collapse: true,
      rowClick,
      onClick: () => onClickRow(collapseDataItem.id, true) // the second parameter needed to understand is it a collapsed row, clicked or not
    });
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.data, nextProps.data) && isEqual(prevProps.collapseDataItem, nextProps.collapseDataItem);
  }
);

const CustomTableCollapsedRow = ({
  tableChildren,
  tableData,
  dispatch,
  data,
  collapseDataItem,
  isChecked,
  onClickRow
}) => {
  const { id } = collapseDataItem;

  return (
    <TableRow>
      {tableData.selecting && (
        <TableCellStyled align="left" padding="checkbox" collapse>
          <Checkbox onChange={e => dispatch.handleSelectRow(e, id, collapseDataItem)} checked={isChecked} />
        </TableCellStyled>
      )}
      {tableData?.collapse && !tableData?.selecting && <TableCellStyled align="left" padding="checkbox" collapse />}
      {Children.map(tableChildren, (child, index) => {
        return (
          <CustomTableCollapsedCell
            key={index}
            child={child}
            data={data}
            collapseDataItem={collapseDataItem}
            onClickRow={e => onClickRow(e, collapseDataItem)}
            rowClick={tableData.rowClick}
          />
        );
      })}
    </TableRow>
  );
};

const CustomTableCollapsed = ({ isCollapsed, collapseData, collapseLoading, isSelectAll, selectedRows, ...props }) => {
  const colspanSize = props?.tableData?.head?.length ? props?.tableData?.head.length + 1 : 1;

  if (!isCollapsed) return null;

  if (!isCollapsed) return null;

  if (collapseLoading)
    return (
      <TableRow>
        <TableCellStyled colspan={colspanSize}>
          <CollapseLoading />
        </TableCellStyled>
      </TableRow>
    );

  if (!collapseData.length)
    return (
      <TableRow>
        <TableCellStyled colspan={colspanSize}>
          <NoDataText>No Data</NoDataText>
        </TableCellStyled>
      </TableRow>
    );

  return (
    <>
      {collapseData.map(collapseDataItem => {
        const isChecked = isSelectAll
          ? !selectedRows.includes(collapseDataItem.id)
          : selectedRows.includes(collapseDataItem.id);
        return (
          <CustomTableCollapsedRow
            {...props}
            key={collapseDataItem.id}
            collapseDataItem={collapseDataItem}
            isChecked={isChecked}
          />
        );
      })}
    </>
  );
};

export default CustomTableCollapsed;
