import React from 'react';
import { Link } from '@material-ui/core';
import { Bell } from 'components/icons';
import { Typography } from 'components/types';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const IndustyAlertContainer = styled.div`
  height: 5.6rem;
  padding: 1rem;
  background: var(--colorSystemInfo);
  display: flex;
  justify-content: center;
  align-items: center;
`;

function IndustryAlert(props) {
  return (
    <IndustyAlertContainer>
      <Bell />
      <Typography.p ml={2.5} bold={500} color="var(--colorCommonWhite)">
        Please{' '}
        <Link
          style={{
            color: ' var(--colorCommonWhite)',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={props.onLinkClick}
        >
          click here
        </Link>{' '}
        to select your industry. This information will help us create more industry specific content in the future.
      </Typography.p>
    </IndustyAlertContainer>
  );
}

IndustryAlert.propTypes = {
  onLinkClick: PropTypes.func.isRequired
};

export default React.memo(IndustryAlert);
