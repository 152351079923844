// @flow
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as newsFeed from 'helpers/apis/services/newsFeed';
import { Grid, makeStyles, Link, Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { CustomTooltip } from 'components';
import { Comment, LikeIcon } from 'components/icons';
import { ReactionsModal } from '../modals';
import { updateFeed } from 'helpers/action';
import { getStorage, decodeId } from 'helpers';

const useStyles = makeStyles(theme => ({
  padded: {
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px'
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.5'
  },
  hoverUnderline: {
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const PostInteractions = ({ feed, toggleCommentsSection, disableCommentBtn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = { id: decodeId(getStorage('userId')) };
  const [open, setOpen] = useState(false);

  const likePost = useCallback(() => {
    const id = feed.id;
    feed.liked ? newsFeed.unLikePost(id, user.id) : newsFeed.likePost(id, user.id);

    dispatch(
      updateFeed(id, {
        likes_count: feed.liked ? feed.likes_count - 1 : feed.likes_count + 1,
        liked: !feed.liked
      })
    );
  }, [feed.id, feed.liked, feed.likes_count, user.id, dispatch]);

  const likeIconMemo = useMemo(
    () => (
      <CustomTooltip title={feed.liked ? 'Unlike' : 'Like'} placement="top">
        <IconButton component="span" onClick={likePost}>
          <LikeIcon color={feed.liked ? 'colorSystemSuccess' : 'colorDefault'} size={1.7} />
        </IconButton>
      </CustomTooltip>
    ),
    [feed.liked, likePost]
  );

  const likesCountMemo = useMemo(
    () => (
      <Link
        style={{ color: 'var(--colorDefault)' }}
        href="#"
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <Box component="span" px={{ xs: 1, md: 0 }}>
          {feed.likes_count}
        </Box>
      </Link>
    ),
    [feed.likes_count]
  );

  const commentIconMemo = useMemo(
    () => (
      <Link
        href="#"
        onClick={e => {
          e.preventDefault();
          toggleCommentsSection();
        }}
        color="inherit"
        underline="none"
        className={disableCommentBtn ? classes.disabled : ''}
      >
        <Box display="flex" alignItems="center">
          <CustomTooltip title="Comments" placement="top">
            <IconButton component="span">
              <Comment color="colorDefault" size={1.7} />
            </IconButton>
          </CustomTooltip>
          <Box component="span" px={{ xs: 1, md: 0 }} className={classes.hoverUnderline}>
            {feed.comments_count !== 0 && `${feed.comments_count}`}
          </Box>
        </Box>
      </Link>
    ),
    [feed.comments_count, toggleCommentsSection, disableCommentBtn]
  );

  return (
    <Grid
      className={clsx(classes.padded, {
        [classes.disabled]: feed.disableActions
      })}
      alignItems="center"
      container
    >
      <Box mr={{ xs: 4, md: 2.5 }} display="flex" alignItems="center">
        {likeIconMemo}
        {feed.likes_count !== 0 && likesCountMemo}
      </Box>
      {open && (
        <ReactionsModal
          id={feed.id}
          likesCount={feed.likes_count}
          open={open}
          close={() => setOpen(false)}
          type="post"
        />
      )}
      {commentIconMemo}
    </Grid>
  );
};

export default PostInteractions;
