import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'hooks';
import { PARTNER_SETUP_BASE_PATH } from './constants';
import { PAGES } from './utils';

const PartnerSetupPage = () => {
  const { page } = useParams();
  const history = useHistory();
  const { app, tab } = useLocation();
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const hasAppAccess = accessApps?.hasOwnProperty(app);
  const hasTabAccess = accessApps?.[app]?.[tab];
  const access_token = localStorage.getItem('accessToken');

  if ((accessApps && (!hasAppAccess || !hasTabAccess)) || !access_token) {
    window.location.href = '/#/myDashboard/dashboard';
    return null;
  }

  if (page && !PAGES[page]?.component) {
    history.push(PARTNER_SETUP_BASE_PATH);
    return null;
  }

  return page ? PAGES[page].component : null;
};

export default PartnerSetupPage;
