/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { RichTextEditor, Typography, Box } from '@trustsecurenow/components-library';
import { useFormContext } from 'react-hook-form';
import Controller from './Controller';

const RichTextEditorController = ({ name, required = false, rules, config }) => {
  const { getValues, setValue, formState } = useFormContext();
  const editorValue = getValues()[name];

  const RichTxtEditor = useMemo(() => {
    // https://github.com/jodit/jodit-react/issues/172
    return (
      <Box width="100%" key="editor1" /* do not remove this div */>
        <RichTextEditor value={editorValue} config={config} onBlur={value => setValue(name, value)} />
      </Box>
    );
  }, [config, editorValue, name, setValue]);

  return (
    <>
      <Controller required={required} name={name} rules={rules} render={() => null} />
      {RichTxtEditor}
      {formState?.errors?.[name] && (
        <Typography display="block" variant="caption" color="error.main">
          {formState?.errors?.[name].message}
        </Typography>
      )}
    </>
  );
};

export default RichTextEditorController;
