// @flow
import create from './create';
import dashboard from './dashboard';

const clients = {};

clients.create = create;
clients.dashboard = dashboard;

export default clients;
