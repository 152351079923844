import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCancel } from 'components';
import {
  ArrowRightIcon,
  Button,
  Grid,
  Paper,
  RadioGroup,
  RadioInput,
  SaveIcon,
  TextField,
  Typography
} from '@trustsecurenow/components-library';

const EMAIL_VENDOR_CHOICES = [
  { value: 'Office365 (Exchange Online)', label: 'Office365 (Exchange Online)' },
  { value: 'GSuite', label: 'GSuite' },
  { value: 'Microsoft Exchange', label: 'Microsoft Exchange' },
  { value: 'Google Gmail', label: 'Google Gmail' },
  { value: 'Hotmail', label: 'Hotmail' },
  { value: 'Yahoo! Mail', label: 'Yahoo! Mail' },
  { value: 'Other', label: 'Other' }
];

const EMAIL_SERVER_LOCATION_CHOICES = [
  { value: 'Onsite', label: 'Onsite' },
  { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
  { value: 'Cloud(Google, Yahoo!, Hotmail)', label: 'Cloud(Google, Yahoo!, Hotmail)' },
  { value: 'Other', label: 'Other' }
];

const YES_NO_CHOICES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const Email = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };

    if (name === 'email' && value === 'No') {
      newState[isHSN ? 'phi_on_email' : 'pii_on_email'] = null;
      newState.email_vendor = '';
      newState.email_vendor_other = '';
      newState.email_vendor_details = '';
      newState.email_server_location = null;
      newState.email_details = '';
    }

    if (name === 'email_vendor' && value !== 'Other' && state.email_vendor_other?.length !== 0) {
      newState.email_vendor_other = '';
    }

    setState(newState);
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
            <Grid container>
              {/* Do you utilize email in your organization? */}
              <Grid
                item
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                      1.Do you utilize email in your organization? *
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Email */}
              <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                <RadioGroup
                  sx={{ marginTop: 2, marginBottom: 1 }}
                  row
                  name="email"
                  onChange={handleChangeState}
                  value={state.email}
                >
                  {YES_NO_CHOICES.map(choice => (
                    <RadioInput
                      label={choice.label}
                      labelPlacement="end"
                      value={choice.value}
                      key={choice.label}
                      color="default"
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {state.email === 'Yes' && (
        <>

          {/* Email accounts information */}
          <Grid container spacing={4} mt={2}>
            <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth sx={{ borderRadius: 2, paddingBottom: 2 }}>
                <Grid container>
                  <Grid
                    item
                    borderBottom={theme => `1px solid ${theme.palette.divider}`}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          {isHSN
                            ? '2.Do you have patient information on any email accounts? *'
                            : '2.Do you have PII or Sensitive Data in any email accounts? *'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* pi on email */}
                <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RadioGroup
                    sx={{ marginTop: 2, marginBottom: 1 }}
                    row
                    name={isHSN ? 'phi_on_email' : 'pii_on_email'}
                    onChange={handleChangeState}
                    value={isHSN ? state.phi_on_email : state.pii_on_email}
                  >
                    {YES_NO_CHOICES.map(choice => (
                      <RadioInput
                        label={choice.label}
                        labelPlacement="end"
                        value={choice.value}
                        key={choice.label}
                        color="default"
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Paper>
            </Grid>
          </Grid >

          <Grid container spacing={4} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
                <Grid container>

                  {/* What Email vendor and product do you use? */}
                  <Grid
                    item
                    borderBottom={theme => `1px solid ${theme.palette.divider}`}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          3.What Email vendor and product do you use? *
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Vendor */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name="email_vendor"
                      onChange={handleChangeState}
                      value={state.email_vendor}
                    >
                      {EMAIL_VENDOR_CHOICES.map(choice => (
                        <RadioInput
                          label={choice.label}
                          labelPlacement="end"
                          value={choice.value}
                          key={choice.label}
                          color="default"
                        />
                      ))}
                    </RadioGroup>

                  </Grid>

                  {/* Other Email Vendor */}
                  {state.email_vendor === 'Other' && (
                    <>
                      <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          value={state.email_vendor_other}
                          name="email_vendor_other"
                          label="Other Email Vendor"
                          fullWidth
                          onChange={handleChangeState}
                          sx={{ marginBottom: 1 }}
                        />
                      </Grid>
                      <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6} />
                    </>
                  )}

                  {/* Email Vendor Details */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      value={state.email_vendor_details}
                      name="email_vendor_details"
                      label="Email Vendor Details"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                      sx={{ marginY: 2 }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
                <Grid container>
                  {/* Where is your Email Server? */}
                  <Grid
                    item
                    borderBottom={theme => `1px solid ${theme.palette.divider}`}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          4.Where is your Email Server? *
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Email server location */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup
                      sx={{ marginTop: 2, marginBottom: 1 }}
                      row
                      name="email_server_location"
                      onChange={handleChangeState}
                      value={state.email_server_location}
                    >
                      {EMAIL_SERVER_LOCATION_CHOICES.map(choice => (
                        <RadioInput
                          label={choice.label}
                          labelPlacement="end"
                          value={choice.value}
                          key={choice.label}
                          color="default"
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth sx={{ paddingBottom: 2, borderRadius: 2 }}>
                <Grid container>
                  {/* Please provide us with any additional details regarding your email */}
                  <Grid
                    item
                    sx={{ marginBottom: 2 }}
                    borderBottom={theme => `1px solid ${theme.palette.divider}`}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid container spacing={4} justifyContent="space-between">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                          5.Please provide us with any additional details regarding your email
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Email details */}
                  <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      value={state.email_details}
                      name="email_details"
                      placeholder="Ex: Office365(Exchange Online) is configured with Exchange Online
                    Protection(EOP) and Data Loss Prevention(DLP)"
                      fullWidth
                      rows={4}
                      multiline
                      onChange={handleChangeState}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {/* Action buttons */}
      <Grid
        item
        sx={{ marginTop: 3 }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid container spacing={4} display="flex" justifyContent="flex-end">
          <Grid
            sx={{ margin: 2 }}
            display="flex"
            justifyContent="flex-end"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <ButtonCancel variant="text" onClick={e => handleClose()}>
              Exit
            </ButtonCancel>
            <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
              Save
            </Button>
            <Button
              color="success"
              startIcon={<ArrowRightIcon />}
              variant="contained"
              disableElevation
              sx={{ marginLeft: '30px' }}
              onClick={() => save('next')}
            >
              Save and Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Email.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default Email;
