const clientNotes = {
  defaultSortValue: {
    sortname: 'last_name',
    order: 'asc',
  },
  selecting: false,
  sort: [],
  head: [
    {
      id: 'last_name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'notes',
      label: 'Note',
      align: 'left',
    },
    {
      id: 'created',
      label: 'Created',
      align: 'left',
    },
  ]
};

export default clientNotes; 