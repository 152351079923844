import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, DragZone, TextField, DateField } from 'components';
import { useNotify } from 'react-admin';
import { dataProvider } from 'helpers';
import { useId, useLocation } from 'hooks';
import { Step, StepLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { LoadingStyled } from 'components/types';
import COMMON_CONST from 'apps/shared/constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from 'components/forms/ComponentTypes';
import * as dashboard from 'helpers/apis/services/dashboard';
import { Stepper } from './ComponentTypes';
import { Button, enqueueAlertSnackbar } from '@trustsecurenow/components-library'

const initialState = {
  data: {
    description: '',
    date: new Date().toISOString(),
    year: new Date().toISOString(),
    file: { name: '' },
    name: '',
    id: ''
  },
  step: 0,
  disabled: true,
  loading: false
};

let statusMessage = null;
let showCreatedMsg = false;

const PageDocumentsModal = ({ record, tab, app, modalType, dispatch, refetchOnCreate }) => {
  const notify = useNotify();
  const [paste, setPaste] = useState(false);
  const { item } = useLocation();
  const clientId = useId({ key: 'clientId' });
  const setId = item || clientId;
  const [state, setState] = useReducer(reducer, initialState);
  const steps = ['Information', 'Attachment'];
  const loadingMsg = { 0: 'Please Wait', 1: `Uploading File\nPlease Wait` };
  const fileType =
    tab === 'disasterRecovery' || tab === 'otherDocuments'
      ? COMMON_CONST.DOCUMENT_SETTINGS.acceptedFileTypes.concat(', .jpeg, .tiff, .png')
      : COMMON_CONST.DOCUMENT_SETTINGS.acceptedFileTypes

  useEffect(() => {
    return () => {
      if (showCreatedMsg) {
        notify(statusMessage);
        showCreatedMsg = false;
        statusMessage = null;
      }
    };
  }, [notify]);

  const onChange = (name, value, type) => {
    if (paste) {
      setState({ type, payload: { [name]: value } });
      setPaste(false);
    }
    setState({ type, payload: { [name]: value } });
  };

  const handleBack = e => {
    e.preventDefault();
    setState({ type: 'PREVSTEP', payload: null });
  };

  const handleNext = e => {
    e.preventDefault();
    setState({ type: 'NEXTSTEP', payload: null });
  };

  const onSave = async e => {
    try {
      e.preventDefault();
      setState({ type: 'SETLOADING', payload: true });
      const sendData = {
        name: state.data.name,
        description: state.data.description
      };
      if (record.date) {
        sendData.date = state.data.date.slice(0, 10);
      }
      if (record.year) {
        sendData.year = state.data.year.slice(0, 4);
      }

      // this modal is used for
      // disasterRecovery, otherDocuments, sraReports document types
      const createdDocument = await dashboard
        .createDocument({
          app,
          documentType: tab,
          id: setId,
          data: { ...sendData }
        })
        .catch(error => {
          throw new Error(error?.response?.data?.message || error?.response?.data?.status || 'Something went wrong');
        });

      const preSignedUpload = await dashboard
        .presignedUpload({
          app,
          id: createdDocument.data?.data?.id,
          data: {
            filename: state.data.file.name,
            type: modalType
          }
        })
        .catch(err => {
          throw new Error((err && err.response && err.response.data.message) || 'Failed presigned upload');
        });

      const formData = new FormData();
      formData.append('AWSAccessKeyId', preSignedUpload.data.fields.AWSAccessKeyId);
      formData.append('key', preSignedUpload.data.fields.key);
      formData.append('policy', preSignedUpload.data.fields.policy);
      formData.append('signature', preSignedUpload.data.fields.signature);
      formData.append('x-amz-security-token', preSignedUpload.data.fields['x-amz-security-token']);
      formData.append('file', state.data.file);

      const upload = await dataProvider
        .postUrl_propagatesError(preSignedUpload.data.url, formData, 'multipart/form-data')
        .catch(err => {
          throw new Error('Failed File Upload');
        });

      refetchOnCreate && refetchOnCreate();

      statusMessage =
        createdDocument.data?.status || `New Document '${createdDocument.data?.data?.name}' was successfully created`;
      enqueueAlertSnackbar(statusMessage, { props: { severity: 'success' } });
      dispatch.onClose();
      setState({ type: 'SETLOADING', payload: false });
    } catch (error) {
      enqueueAlertSnackbar(error.message || 'Someting went wrong!', { props: { severity: 'error' } });
      setState({ type: 'SETLOADING', payload: false });
    }
  };

  const onFileUpload = file => {
    setState({ type: 'STEP2', payload: file });
  };

  return state.loading === true ? (
    <LoadingStyled text={loadingMsg[state.step]} marginTopPercentage={5} />
  ) : (
    <Container.Grid direction="column">
      <Stepper activeStep={state.step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container.Grid item alignItems="center" sm={12} xs={12} xl={12} md={12}>
        {state.step === 0 ? (
          <Container.Grid item direction="column" mb={3} ml={3} mr={3} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid item sm={3} xs={3}>
              {record.year ? (
                <DateField
                  fullWidth
                  id="year"
                  datePicker
                  label="Year"
                  name="year"
                  format="year"
                  value={state.data.year}
                  onChange={value => onChange('year', `${value}T23:59:59.000Z`, 'SETDATA')}
                  maxDate={new Date('2100-12-31')}
                />
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    id="date"
                    datePicker
                    label="Date"
                    name="date"
                    value={state.data.date}
                    onChange={date => {
                      const dateString = date.toISOString().split('T')[0];
                      onChange('date', `${dateString}`, 'SETDATA');
                    }}
                    fullWidth
                    autoOk
                    inputVariant="outlined"
                    margin="none"
                    InputLabelProps={{ classes: { focused: 'field-focused' } }}
                    format="yyyy-MM-dd"
                    placeholder="yyyy-MM-dd"
                    views={['date']}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Container.Grid>
            <TextField
              fullWidth
              source={state.data.name}
              value={state.data.name}
              id="name"
              label="Name"
              name="name"
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange('name', e.target.value, 'STEP1')
              }}
            />
            <TextField
              fullWidth
              source={state.data.description}
              value={state.data.description}
              id="description"
              label="Description"
              name="description"
              multiline
              enableEnterKey
              rows={4}
              ml={3}
              required
              inputProps={{
                onPaste: e => setPaste(true),
                onChange: e => onChange('description', e.target.value, 'STEP1')
              }}
            />
          </Container.Grid>
        ) : (
          <Container.Grid item direction="column" ml={3} pb={2} sm={12} xs={12} xl={12} md={12}>
            <Container.Grid direction="row" alignItems="center" sm={4} xs={4} xl={4} md={4}>
              <DragZone
                title="Upload a file*"
                fileType={fileType}
                allowMultipleFiles={COMMON_CONST.DOCUMENT_SETTINGS.allowedMultipleFiles}
                maxUploadSize={COMMON_CONST.DOCUMENT_SETTINGS.maxFileSize}
                size={12}
                record={state.data}
                dispatch={onFileUpload}
                type="file"
              />
            </Container.Grid>
          </Container.Grid>
        )}
      </Container.Grid>
      <Container.Grid item justify="flex-end" alignItems="center" mr={3} sm={12} xs={12} xl={12} md={12}>
        {state.step > 0 ? (
          <>
            <Button onClick={e => handleBack(e)}>Back</Button>
            <Button sx={{ ml: 2 }} disabled={state.loading || state.disabled} onClick={onSave}>
              Save
            </Button>
          </>
        ) : (
          <Button onClick={handleNext} disabled={state.loading || state.disabled}>
            Next
          </Button>
        )}
      </Container.Grid>
    </Container.Grid>
  );
};

function reducer(prevState, { type, payload }) {
  switch (type) {
    case 'STEP1': {
      const newData = { ...prevState.data, ...payload };
      const isDisable = newData.name.trim().length > 0 && newData.description.trim().length > 0;
      return { ...prevState, data: newData, disabled: !isDisable };
    }

    case 'STEP2': {
      return { ...prevState, data: { ...prevState.data, file: payload }, disabled: false };
    }

    case 'SETDATA': {
      return { ...prevState, data: { ...prevState.data, ...payload } };
    }

    case 'NEXTSTEP': {
      return {
        ...prevState,
        step: prevState.step + 1,
        disabled: !prevState.data.file.name
      };
    }

    case 'PREVSTEP': {
      const { name, description } = prevState.data;
      const isDisable = name.length > 0 && description.length > 0;
      return { ...prevState, step: prevState.step - 1, disabled: !isDisable, loading: false };
    }

    case 'SETLOADING': {
      return { ...prevState, loading: payload };
    }

    default: {
      return prevState;
    }
  }
}

PageDocumentsModal.propTypes = {
  record: PropTypes.shape({
    date: PropTypes.string,
    year: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired
  }).isRequired,
  modalType: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    onClose: PropTypes.func.isRequired
  }).isRequired,
  refetchOnCreate: PropTypes.func.isRequired
};

export default PageDocumentsModal;
