// @flow

import React, { type ComponentType } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import { IconButton } from '@material-ui/core';
import MuiMenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
// import { theme } from 'conf';

export const Button: ComponentType<*> = styled(IconButton)`
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

// export const Icon: ComponentType<*> = styled(MuiMenuIcon)`
//   .menuButtonIconClosed {
//     transition: ${theme.transitions.create(['transform'], {
//       easing: theme.transitions.easingSharp,
//       duration: theme.transitions.durationLeavingScreen
//     })};
//     transform: rotate(0deg);
//   }
//
//   .menuButtonIconOpen {
//     transition: ${theme.transitions.create(['transform'], {
//       easing: theme.transitions.easingSharp,
//       duration: theme.transitions.durationLeavingScreen
//     })};
//     transform: rotate(180deg);
//   }
// `;

export const Icon: ComponentType<*> = styled(MuiMenuIcon)`
  .menuButtonIconClosed {
    transform: rotate(0deg);
  }

  .menuButtonIconOpen {
    transform: rotate(180deg);
  }
`;

type Props = {
  toggleSidebar: Function,
  open: boolean
};

const MenuIcon = ({ toggleSidebar, open }: Props) => (
  <Button color="inherit" aria-label="open drawer" onClick={toggleSidebar}>
    <Icon
      classes={{
        root: open ? { classes: 'menuButtonIconOpen' } : { classes: 'menuButtonIconClose' }
      }}
    />
  </Button>
);
const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction
    }
  )
);

export default enhance(MenuIcon);
