import React from 'react';
import { TableCell, TableRow, TableBody } from '@material-ui/core';
import { useTableContext } from '../context';
import { LoadingStyled } from '../../types';
import { NoDataText } from '../style';
import CustomTableRow from './CustomTableRow';

const CustomTableBody = ({ className }) => {
  const { data, loading, expandedId, highlightedId, isFetching, tableData } = useTableContext();

  const colspanSize = tableData?.head?.length ? tableData.head.length + 1 : 1;

  if (loading) {
    return (
      <TableBody className={className}>
        <TableRow>
          <TableCell colSpan={colspanSize}>
            <LoadingStyled />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (!data?.length) {
    return (
      <TableBody className={className}>
        <TableRow>
          <TableCell colSpan={colspanSize}>
            <NoDataText>No Data</NoDataText>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody className={className}>
      {data.map((item, index) => {
        const isCollapsed = expandedId === item.id;
        const isHighlighted = highlightedId === item.id;
        return (
          <CustomTableRow
            key={item.id}
            index={index}
            isCollapsed={isCollapsed}
            isHighlighted={isHighlighted}
            data={{ ...item, isFetching }}
            rowId={item.id}
          />
        );
      })}
    </TableBody>
  );
};

export default CustomTableBody;
