import React from 'react';
import { Box, Typography, Stack, EmptyListIcon } from '@trustsecurenow/components-library';

const NoRowsOverlay = () => (
  <Stack spacing={1.5} height="100%" justifyContent="center" alignItems="center">
    <EmptyListIcon sx={{ fontSize: 56 }} />
    <Box textAlign="center">
      <Typography variant="body1">No clients match your search or filter criteria.</Typography>
      <Typography variant="body1">Try adjusting your search or filter settings.</Typography>
    </Box>
  </Stack>
);

export default NoRowsOverlay;
