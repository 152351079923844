// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const PartnerProfile = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 20.2 19.4" role="img" aria-hidden="false">
    <path fill="none" d="M0 0h20.2v19H4z" />
    <g>
      <path d="M10.1,12.8c2.5,0,5.2-2.9,5.2-7.1c0-3.4-2.1-5.7-5.2-5.7C7.1,0,4.9,2.4,4.9,5.7C4.9,9.9,7.7,12.8,10.1,12.8z    M10.1,1.4L10.1,1.4c2.3,0,3.8,1.7,3.8,4.3c0,3-1.9,5.7-3.8,5.7c-2,0-3.8-2.8-3.8-5.7C6.3,3.1,7.8,1.4,10.1,1.4z" />
      <path d="M18.8,13.5c-1.2-1.3-3-2-4.8-1.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c1.4-0.1,2.8,0.4,3.8,1.4   c0.8,1.1,1.2,2.4,1.1,3.7H1.4c-0.1-1.3,0.3-2.6,1.1-3.7c1-1,2.4-1.5,3.8-1.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7   c-1.8-0.1-3.6,0.6-4.8,1.9C0.3,15-0.2,17,0.1,18.9l0.1,0.6h19.9l0.1-0.6C20.4,17,20,15,18.8,13.5z" />
    </g>
  </SvgIconStyled>
);

export default PartnerProfile;
