// @flow
import React from 'react';
import { Button, Dialog, Container, Typography, LazyIcon } from 'components';
import { IconButton } from '@material-ui/core';
import { CancelButton } from '../style/AdminCommon';
import styled from 'styled-components';

const Message = styled(Typography.p)`
  text-align: center;
`;

type Props = {
  onClose: Function,
  onSubmit: Function,
  config: {
    open: boolean,
    title: string,
    message: string,
  },
};

const ConfirmModal = ({ onClose, config, onSubmit, ...rest }: Props) => {
  return (
    <Dialog
      open={config?.open || false}
      setOpen={onClose}
      maxWidth="sm"
      onlyOk
      closeButton
      variant="standard"
      {...rest}
    >
      <Container.Grid container direction="column" alignItems="center" justify="center" p={3}>
        <Container.Grid item>
          <Typography.h3 fontSize={24}>{config?.title}</Typography.h3>
        </Container.Grid>

        <Container.Grid item alignItems="center" pb={2} pl={2} pr={2}>
          <Message>{config?.message}</Message>
        </Container.Grid>

        <Container.Grid item pb={1} pt={2}>
          <Button
            backgroundColor="colorSystemSuccess"
            backgroundColorHover="colorSystemSuccessHover"
            onClick={onSubmit}
            disabled={!config?.open}
          >
            Yes, continue
          </Button>
        </Container.Grid>

        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </Container.Grid>
    </Dialog>
  );
};

export default React.memo(ConfirmModal);