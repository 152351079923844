import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import AutoComplete from './AutoComplete';
import { useDebounce } from '../../hooks';

const AutoCompleteAsyncWrapper = ({
  name,
  required,
  id,
  api,
  label,
  labelName = '',
  onChange,
  oneRequest = false,
  refetch,
  getOptionSelected,
  dataPath,
  value,
  ...props
}) => {
  const [autoCompleteWrapperState, setAutoCompleteWrapperState] = React.useState({
    initialRequest: true,
    inputValue: '',
    isOptionLoading: false
  });

  const [searchValueDebounce] = useDebounce({ value: autoCompleteWrapperState.inputValue, delay: 500 });

  const { isLoading, isFetching, refetch: refetchQuery, data } = useQuery(
    id,
    () =>
      api({
        page: 1,
        fields: ['id', 'name'],
        filter: autoCompleteWrapperState.inputValue
      }),
    {
      keepPreviousData: true,
      staleTime: 10,
      cacheTime: false,
      refetchOnWindowFocus: false
    }
  );

  const getData = React.useMemo(() => {
    if (data) {
      const dataPathArr = dataPath.split('.');
      let currentData = data;

      dataPathArr.forEach(path => {
        if (currentData[path]) {
          currentData = currentData[path];
        }
      });

      return currentData;
    }
    // guard aginst render and line 66 find
    return !Array.isArray(data) ? [] : data;
  }, [data, dataPath]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (value !== '' && typeof value !== 'object') {
        const currentData = getData.find(v => v.id === value);
        onChange && onChange(currentData);
      }
      setAutoCompleteWrapperState(old => ({ ...old, initialRequest: false, isOptionLoading: false }));
    }
  }, [isLoading, isFetching, getData, value, onChange]);

  useEffect(() => {
    if (!oneRequest) {
      if (value && searchValueDebounce.value !== '') {
        if (value[labelName] !== searchValueDebounce.value) {
          refetchQuery();
        }
      } else {
        refetchQuery();
      }
    }
  }, [labelName, oneRequest, refetchQuery, searchValueDebounce.value, value]);

  useEffect(() => {
    refetch && refetchQuery();
  }, [refetch, refetchQuery]);

  const getOptionLabel = React.useCallback(
    option => {
      if (option) {
        if (Array.isArray(labelName)) {
          return `${labelName.map(label => option[label]).join(' ')}`;
        }
        return option[labelName];
      }
      return '';
    },
    [labelName]
  );

  const currentGetOptionSelected = React.useMemo(() => {
    return (
      getOptionSelected ||
      ((option, value) => {
        return getOptionLabel(option) === getOptionLabel(value);
      })
    );
  }, [getOptionLabel, getOptionSelected]);

  const onInputChange = React.useCallback(
    (_event, newInputValue) => {
      setAutoCompleteWrapperState(old => ({
        ...old,
        inputValue: !old.initialRequest ? newInputValue : old.inputValue,
        isOptionLoading: !oneRequest
      }));
    },
    [oneRequest]
  );

  if (!labelName) {
    throw new Error('Please write label name');
  }

  return (
    <AutoComplete
      required={required}
      name={name}
      getOptionLabel={getOptionLabel}
      id={id}
      label={label}
      options={getData || []}
      getOptionSelected={currentGetOptionSelected}
      loading={autoCompleteWrapperState.isOptionLoading}
      inputDefaultValue={autoCompleteWrapperState.initialRequest && value ? value : autoCompleteWrapperState.inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      labelName={labelName}
      {...props}
    />
  );
};

const AutoCompleteAsync = ({ options, onChange, name, ...props }) => {
  let formContext = {};

  // when we use AutoComplete outside of Form component useFormContext give null
  const formContextData = useFormContext();
  if (formContextData) formContext = formContextData;

  const { watch } = formContext;

  if (!formContextData) {
    return <AutoCompleteAsyncWrapper {...props} onChange={onChange} />;
  }

  const value = watch(name);

  return <AutoCompleteAsyncWrapper {...props} value={value} name={name} />;
};

export default AutoCompleteAsync;
