import React from 'react';
import { Grid, RadioGroup, RadioInput, TextField, Typography } from '@trustsecurenow/components-library';
import ContainerStepContent from './ContainerStepContent'

const Question = ({ question_number, dispatch, record }) => {
  return (
    <React.Fragment>
      <Grid item md={12}>
        <Typography variant="h5"> Question {question_number}</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          value={record[`question_${question_number}`]}
          name={`question_${question_number}`}
          label={`Question`}
          required
          onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              value={record[`feedback_true_${question_number}`]}
              name={`feedback_true_${question_number}`}
              label="Feedback for Correct Response"
              onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              value={record[`feedback_false_${question_number}`]}
              name={`feedback_false_${question_number}`}
              label="Feedback for Incorrect Response"
              onChange={(e) => dispatch.onChange(e.target.name, e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item pb={4} xs={12} md={12}>
        <Typography variant="h5">Correct?</Typography>
        <RadioGroup
          row
          name={`answer_${question_number}`}
          onChange={e => dispatch.onChange(e.target.name, !record[`answer_${question_number}`])}
          value={Boolean(record[`answer_${question_number}`])}
        >
          <RadioInput
            label="True"
            labelPlacement="end"
            value={true}
            color="info"
          />
          <RadioInput
            label="False"
            labelPlacement="end"
            value={false}
            color="info"
          />
        </RadioGroup>
      </Grid>
    </React.Fragment>
  )
}


const QuestionsStep = ({ stepNumber, dispatch, record, createMode }) => {

  const disabled = !record.question_1?.trim()
    || !record.question_2?.trim()
    || !record.question_3?.trim()
    || !record.question_4?.trim()

  return (
    <ContainerStepContent
      headerTitle={`${stepNumber}. Questions`}
      disableSubmit={disabled}
      onSubmit={dispatch.onNext}
      onBack={dispatch.onBack}
      submitLabel={createMode ? 'Next' : 'Update'}
    >
      <Grid container spacing={2} p={2} pt={4} pb={4}>
        <Question
          question_number={1}
          dispatch={dispatch}
          record={record}
        />

        <Question
          question_number={2}
          dispatch={dispatch}
          record={record}
        />

        <Question
          question_number={3}
          dispatch={dispatch}
          record={record}
        />

        <Question
          question_number={4}
          dispatch={dispatch}
          record={record}
        />
      </Grid>
    </ContainerStepContent>
  );
};

export default QuestionsStep;