import React, { useState } from 'react';
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { EditIcon, DeleteIcon, Disable } from 'components';
import contentAdmin from '../../../../helpers/apis/contentAdmin';
import { useCardPageContext } from '../../context';
import { useNotify } from 'react-admin';
import { STATUS_CHIP_COMPLETED } from '../../helpers';
import { CONTENT_ADMIN_CAMPAIGN } from '../../constants';

const useStyles = makeStyles(theme => ({
  menuBtn: {
    padding: '5px',
    position: "absolute",
    top: "10px",
    right: "10px",
    '& svg': {
      fontSize: 30,
      color: 'var(--colorDefault)'
    }
  },
  menuLink: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: '#3387FF'
    },
    '& svg': {
      marginRight: '12px'
    }
  },
  menuLinkDelete: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: '#ff3333'
    },
    '& svg': {
      marginRight: '12px'
    }
  },
  stopCampaign: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--colorSystemWarning)'
    },
    '& svg': {
      marginRight: '12px'
    }
  },
  stopCampaignDisabled: {
    fontSize: '16px',
    padding: '10px 40px 10px 20px',
    color: 'var(--colorDefault)',
    opacity: 0.5,
    transition: '0.3s',
    cursor: "default",
    '& svg': {
      marginRight: '12px'
    }
  }
}));

const ElementActions = ({ itemId, onDelete, onEdit, status }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {dispatch, pagePath} = useCardPageContext();
  const notify = useNotify();
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseEdit = () => {
    setAnchorEl(null);
    onEdit(itemId);
  };
  const handleCloseDelete = () => {
    setAnchorEl(null);
    onDelete(itemId);
  };

  const handleStopCampaign = async () => {
    try {
      const response = await contentAdmin.stopCampaign(itemId);
      dispatch.refetch();
      notify(response.data.description)
    } catch (e) {
      console.log(e)
    }
  }

  const isStatusCompleted = status?.toLowerCase() !== STATUS_CHIP_COMPLETED;

  const isPageCampaign = pagePath === CONTENT_ADMIN_CAMPAIGN;


  return (
    <>
      <IconButton
        className={classes.menuBtn}
        onClick={handleClick}
        aria-label="more"
        aria-haspopup="true"
        aria-controls="long-menu"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {
          isPageCampaign && (
            <MenuItem className={isStatusCompleted ? classes.stopCampaign : classes.stopCampaignDisabled } onClick={isStatusCompleted ? handleStopCampaign : () => {}}>
              <Disable size={1.7} /> Stop Campaign
            </MenuItem>
          )
        }
        <MenuItem className={classes.menuLink} onClick={() => handleCloseEdit()}>
          <EditIcon size={1.7} /> Edit
        </MenuItem>
        <MenuItem className={classes.menuLinkDelete} onClick={() => handleCloseDelete()}>
          <DeleteIcon size={1.7} /> Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default ElementActions;
