// @flow
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FeedViewMode } from './index';
import { formatHashtagsInElement } from '../../newsfeedHelpers';

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    },
  }
}));

const PostBody = ({ feed, currPlayingVideoPostId, setCurrPlayingVideoPostId, updateScrollPosition }) => {
  const classes = useStyles();
  const { text, content, file_ready, id, security_newsletter, microtraining } = feed;

  const formattedPostText = () => {
    return formatHashtagsInElement(text);
  };

  return (
    <Grid className={classes.padded} alignItems="center" container>
      <Grid item xs={12}>
        <FeedViewMode
          allowTruncate
          text={formattedPostText()}
          content={content}
          fileIsReady={file_ready}
          postId={id}
          newsletter={security_newsletter}
          currPlayingVideoPostId={currPlayingVideoPostId}
          setCurrPlayingVideoPostId={setCurrPlayingVideoPostId}
          microtraining={microtraining}
          updateScrollPosition={updateScrollPosition}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(PostBody);
