import React from "react"
import { Container, useQuiz, Indicator } from "components";
import { QuizContentHeaderLayout, QuizTitle, QuizSubtitle } from './quizStyle';

const QuizContentHeader = () => {

  const {quiz_title, categories_count, category, progress, isQuizFinished} = useQuiz()

  return (
    <QuizContentHeaderLayout>
      <Container.Grid block sm={8}>
        <QuizTitle>
          {quiz_title}
        </QuizTitle>
        <QuizSubtitle>
          Category {category?.number} / {categories_count} - {category?.name}
        </QuizSubtitle>
      </Container.Grid>
      <Container.Grid sm={4} alignItems={"center"} justifyContent={"flex-end"}>
        <QuizSubtitle style={{marginRight: "15px"}}>
          {isQuizFinished ? "Completed" : "Complete"}
        </QuizSubtitle>
        <Indicator title={`${progress}%`} value={+progress} />
      </Container.Grid>
    </QuizContentHeaderLayout>
  )
}

export default QuizContentHeader
