// @flow
import React from 'react';
import { SvgIconStyled } from './ComponentTypes';

type Props = Object;

const Filter = (props: Props) => (
  <SvgIconStyled {...props} viewBox="0 0 18 20" role="img" aria-hidden="false">
      <path d="M17.8286 1.37399C17.4658 0.540332 16.5396 0 15.4743 0H9.33003H3.18567C2.12044 0 1.19417 0.540332 0.831375 1.37399C0.514895 2.09186 0.661549 2.91007 1.2096 3.50444L4.28176 6.83906L6.80589 9.57159C6.80589 9.57931 6.79817 9.58703 6.79817 9.59475V19.2281C6.79817 19.2821 6.82131 19.3284 6.82903 19.3825C6.82903 19.3902 6.82904 19.4056 6.83675 19.4134C6.92166 19.7453 7.20728 20 7.57007 20C7.73989 20 7.88656 19.9305 8.01779 19.8379C8.02551 19.8302 8.04093 19.8302 8.04865 19.8225L8.09496 19.7839C8.10268 19.7761 8.11042 19.7761 8.11814 19.7684L11.5454 17.1517C11.6612 17.059 11.7306 16.9433 11.7847 16.812C11.8078 16.7734 11.8155 16.7271 11.8233 16.6885C11.831 16.6654 11.8387 16.6422 11.8387 16.6113C11.8464 16.565 11.8696 16.5264 11.8696 16.4724V9.56388L14.386 6.83134L17.4581 3.49672C17.9985 2.91007 18.1451 2.09186 17.8286 1.37399ZM3.18567 1.54381H9.33003H15.4743C16.2231 1.54381 16.694 2.05326 16.3235 2.46237L15.9529 2.86376H2.72252H2.71482L2.33657 2.46237C1.95834 2.05326 2.4292 1.54381 3.18567 1.54381ZM10.318 16.1405L8.33425 17.6534V10.7989C8.64301 10.907 8.97496 10.9765 9.33003 10.9765C9.68511 10.9765 10.017 10.907 10.3258 10.7989V16.1405H10.318ZM13.2436 5.78927L10.1714 9.12389C9.98612 9.32459 9.65422 9.43265 9.32231 9.43265C8.99039 9.43265 8.66616 9.3323 8.47318 9.12389L5.40103 5.78927L4.12737 4.40756H14.5172L13.2436 5.78927Z" />
  </SvgIconStyled>
);

export default Filter;
