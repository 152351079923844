/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { DatePicker } from '@material-ui/pickers';
import {
  Box,
  Button as ButtonLegacy,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { theme } from 'conf';
import {
  ButtonCancel,
  ButtonSubmit,
  CalendarFile,
  Disable,
  Flag,
  Success,
  Switch,
  Typography,
} from 'components';
import classes from './styles/publishButton.module.css';
import { useNotify } from 'react-admin';
import { format, startOfDay } from 'date-fns';
import UnPublishDialog from './dialogs/UnPublishDialog';
import { useDialog } from './hooks/useDialog';
import ConfigureTrainingDialog from './dialogs/ConfigureTrainingDialog';
import SelectClientsDialog from './dialogs/selectClientsDialog';
import { Button, enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { useLocation } from 'hooks';
import * as testAuthoringSystem from 'helpers/apis/services/testAuthoringSystem';

const CONFIGURE_TRAINING_DIALOG = 'CONFIGURE_TRAINING_DIALOG';

const SELECT_CLIENTS_DIALOG = 'SELECT_CLIENTS_DIALOG';

const PARTNER_PROFILE = 'partnerProfile';

const materialTheme = createTheme({
  ...theme,
  overrides: {
    MuiPickersBasePicker: {
      container: {
        display: 'flex',
        flexDirection: 'column-reverse !important'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'var(--colorSystemInfo)',
        '&:hover': {
          backgroundColor: 'var(--colorSystemInfo)'
        }
      },
      day: {
        color: 'var(--blackAndWhite)'
      }
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0
      }
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: 'var(--blackAndWhite)'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: 'var(--blackAndWhite)'
      },
      dayLabel: {
        color: 'var(--colorLight)'
      }
    }
  }
});

const iconStyle = {
  padding: '0 10px'
};

const CourseAnnounce = ({ publishDate, allowExcludedClients }) => {
  const announce =
    publishDate === null
      ? {
          info: ' No publication date set',
          icon: <Flag style={{ verticalAlign: 'sub' }} />,
          color: 'var(--colorSystemDanger)'
        }
      : publishDate <= new Date()
      ? {
          info: `${allowExcludedClients ? 'Training has been published for selected client(s) on' : 'Training published on'} ${format(publishDate, 'yyyy-MM-dd')}`,
          icon: <Success style={{ verticalAlign: 'sub', marginRight: '5px' }} />,
          color: 'var(--colorSystemSuccess)'
        }
      : {
          info: `Training will be published ${allowExcludedClients ? 'for selected client(s)' : ''} on`,
          icon: <Flag style={{ verticalAlign: 'sub' }} />,
          color: 'var(--colorSystemInfo)'
        };
  return (
    <p style={{ color: announce.color, marginLeft: '6%', width: '100%', minHeight: 18, textAlign: 'start' }}>
      {announce.icon}
      {announce.info}
    </p>
  );
};

const ButtonText = ({ publishDate, editable }) => {
  const buttonInfo = !editable
    ? { text: 'Unpublish Training', icon: <Disable style={iconStyle} /> }
    : publishDate === null
    ? { text: 'Publish', icon: <CalendarFile style={iconStyle} /> }
    : publishDate <= new Date()
    ? { text: 'Unpublish Training', icon: <Disable style={iconStyle} /> }
    : {
          text: `${format(publishDate, 'yyyy-MM-dd')}`,
        icon:  <CalendarFile style={iconStyle} />
      };
  return (
    <>
      {buttonInfo.text !== 'Published' && buttonInfo.icon}
      {buttonInfo.text}
      {buttonInfo.text === 'Published' && (
        <Tooltip arrow title="This course package cannot be unpublished.">
          <Box sx={{ pointerEvents: 'auto', display: 'flex' }}>{buttonInfo.icon}</Box>
        </Tooltip>
      )}
    </>
  );
};

const PackageButton = ({
  training_year,
  editable,
  handlePackageButton,
  packageId,
  publish,
  description,
  refetchTrainings,
  setNotification,
  trainingFuturePublication = null,
  unpublishText,
  allowExcludedClients,
  packageName,
  configureText,
  isAnnual,
  preventFuturePublication,
  defaultReleaseDate
}) => {
  const [open, setOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  const [futurePublication, setFuturePublication] = useState(trainingFuturePublication);

  const [clientsList, setClientsList] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [packageClientsLoading, setPackageClientsLoading] = useState(false);
  const [excludedClientLoading, setExcludedClientLoading] = useState(false);

  const { hasDialogOpen, closeDialog, openDialog, closeDialogs } = useDialog();

  const fetchPackageClients = async () => {
    try {
      setPackageClientsLoading(true);
      const { data } = await testAuthoringSystem.getPackageClients(packageId);
      const clients = data.clients_picklist;
      setClientsList(clients);
      setSelectedClients(clients.filter(client => client.excluded_client === 1));
    } catch (error) {
      enqueueAlertSnackbar(`${error.message}`, { props: { severity: 'error' } });
      closeDialogs();
    } finally {
      setPackageClientsLoading(false);
    }
  };

  const [date, setDate] = useState(
    publish === null
      ? {
          selectedDate: new Date(),
          scheduleDate: null
        }
      : {
        selectedDate: new Date(publish),
        scheduleDate: new Date(publish)
        }
  );

  const buttonRef = useRef();
  const notify = useNotify();
  const { app } = useLocation('clientId');

  const cancel = () => {
    setOpen(false);
  };


  const unpublish = () => {
    setDisableButton(true);
    handlePackageButton(packageId, null)
      .then(res => {
        setNotification({
          open: true,
          text: 'Selected training unpublished successfully'
        });
        setDate(prev => ({
          ...prev,
          scheduleDate: null
        }));
      })
      .catch(e => {
        notify('An Error occurred while Unpublish the course', 'warning');
      })
      .finally(() => {
        setDisableButton(false);
        closeDialogs();
      });
  };

  const handleClickPackageButton = () => {
    if (date.scheduleDate <= new Date() && date.scheduleDate !== null) {
      unpublishText ? openDialog(packageName) : unpublish();  
    } else {
      setOpen(true);
    }
  };
  
  const handlePublishPackage = () => {
    setOpen(false);
    setDisableButton(true);
    handlePackageButton(packageId, new Date(date.selectedDate).toISOString(), futurePublication)
      .then(res => {
        if (futurePublication) refetchTrainings();
        setDate(prev => ({
          ...prev,
          scheduleDate: date.selectedDate
        }));
        const notificationText = futurePublication
          ? 'This training and future trainings will be published on the date selected'
          : 'The Training will be published on the date you selected';
        setNotification({
          open: true,
          text: notificationText
        });
      })
      .catch(e => {
        notify('An Error occurred while setting the publish date', 'warning');
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const handleConfirmPublishDialog = () => {
    setPublishDialogOpen(false);
    handlePublishPackage();
  };

  const handleConfirmPublishDate = () => {
    if (trainingFuturePublication === null || isAnnual === 0) {
      handlePublishPackage();
    } else {
      setPublishDialogOpen(true);
    }
  };

  const handleCofigurePackage = () => {
    fetchPackageClients();
    openDialog(CONFIGURE_TRAINING_DIALOG);
  };

  const handleCloseSelectClientsDialog = () => {
    closeDialog(SELECT_CLIENTS_DIALOG);
  };

  const handleOpenSelectClientsDialog = () => {
    openDialog(SELECT_CLIENTS_DIALOG);
  };

  const handleDeleteClient = clientId => {
    setSelectedClients(prevState => prevState.filter(client => client.id !== clientId));
  };

  const handleSaveCheckedClients = clients => {
    setSelectedClients(clients);
    handleCloseSelectClientsDialog();
  };

  const handleCloseConfigureTrainingDialog = () => {
    closeDialog(CONFIGURE_TRAINING_DIALOG);
  };

  const handleSaveExcludePackageClients = async () => {
    try {
      setExcludedClientLoading(true);
      await testAuthoringSystem.addExcludedPackageClients({ selectedClients, packageId });
      enqueueAlertSnackbar('The package configured  successfully', { props: { severity: 'success' } });
    } catch {
      enqueueAlertSnackbar('An Error occurred while configuring package', { props: { severity: 'error' } });
    } finally {
      closeDialogs();
      setExcludedClientLoading(false);
    }
  };

  return (
    <>
      <CourseAnnounce publishDate={date.scheduleDate} allowExcludedClients={allowExcludedClients && app==PARTNER_PROFILE} />

      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            PopoverProps={{
              anchorEl: () => buttonRef.current
            }}
            ToolbarComponent={props => (
              <span className={classes.actionArea}>
                <ButtonLegacy className={classes.cancelButton} onClick={cancel}>
                  {'Cancel'}
                </ButtonLegacy>
                <ButtonLegacy className={classes.confirmButton} onClick={handleConfirmPublishDate} variant="contained">
                  {'Confirm'}
                </ButtonLegacy>
              </span>
            )}
            variant="inline"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            disablePast
            minDate={new Date( Math.max( Date.now(), new Date( defaultReleaseDate ).getTime()))}
            maxDate={new Date(training_year, 11, 31)}
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            label="From"
            size="small"
            InputProps={{ display: 'inline', flexDirection: 'column-reverse' }}
            value={date.selectedDate}
            onChange={date => {
              setDate(prev => ({
                ...prev,
                selectedDate: new Date(Math.max(Date.now(), startOfDay(date).getTime()))
              }));
            }}
            TextFieldComponent={pros => (
              <ButtonLegacy
                ref={buttonRef}
                variant="outlined"
                disabled={!editable || disableButton}
                onClick={handleClickPackageButton}
                style={{
                  width: '90%',
                  ...(editable &&
                    !disableButton && {
                      color: 'var(--colorBase)',
                      borderColor: 'var(--colorBase)'
                    }),
                  textTransform: 'none',
                  margin: '0 20px'
                }}
              >
                <ButtonText
                  publishDate={date.scheduleDate}
                  editable={editable}
                />
              </ButtonLegacy>
            )}
          ></DatePicker>
          <Dialog open={publishDialogOpen}>
            <DialogTitle>
              <Typography.h3>Confirm Publish Date</Typography.h3>
            </DialogTitle>
            <DialogContent>
              <Typography.h5>
                The {description} course will be published on {format(date.selectedDate, 'yyyy-MM-dd')}.
              </Typography.h5>
              <Typography.p>
                By changing this date, the default setting for future publication dates of {packageName} will also be
                altered to one year after the selected date. However, you can turn off this feature using the toggle
                button below.
              </Typography.p>
              <Switch
                label="Adjust future publication dates"
                checked={Boolean(futurePublication)}
                onChange={e => setFuturePublication(e.target.checked)}
                labelPlacement="start"
              />
            </DialogContent>
            <DialogActions style={{ margin: 8 }}>
              <ButtonCancel onClick={() => setPublishDialogOpen(false)}>Cancel</ButtonCancel>
              <ButtonSubmit onClick={handleConfirmPublishDialog}>Confirm</ButtonSubmit>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      {Boolean(preventFuturePublication) && date.scheduleDate > new Date() && (
        <Button disabled={disableButton} variant="outlined" fullWidth sx={{ mx: 2.5, mt: 1.25 }} onClick={unpublish}>
          Remove Publish Date
        </Button>
      )}
      {app === PARTNER_PROFILE && Boolean(allowExcludedClients) && (
        <Button variant="outlined" fullWidth sx={{ mx: 2.5, mt: 1.25 }} onClick={handleCofigurePackage}>
          Configure
        </Button>
      )}

      <ConfigureTrainingDialog
        open={hasDialogOpen(CONFIGURE_TRAINING_DIALOG)}
        onCloseDialog={handleCloseConfigureTrainingDialog}
        configureText={configureText}
        packageName={packageName}
        onClickClientList={handleOpenSelectClientsDialog}
        onDeleteClient={handleDeleteClient}
        onSaveExcludePackageClients={handleSaveExcludePackageClients}
        isLoading={packageClientsLoading}
        isSaving={excludedClientLoading}
        selectedClients={selectedClients}
      />

      <UnPublishDialog
        open={hasDialogOpen(packageName)}
        onCloseDialog={closeDialogs}
        unpublishText={unpublishText}
        onUnpublishPackage={unpublish}
        packageName={packageName}
      />

      <SelectClientsDialog
        open={hasDialogOpen(SELECT_CLIENTS_DIALOG)}
        onCloseDialog={closeDialogs}
        onBack={handleCloseSelectClientsDialog}
        allClients={clientsList}
        selectedClients={selectedClients}
        onSaveCheckedClients={handleSaveCheckedClients}
        isLoading={packageClientsLoading}
      />
    </>
  );
};

export default PackageButton;
