/* eslint-disable no-shadow */
import React from 'react';
import { Stack, Typography } from '@trustsecurenow/components-library';
import Parser from 'html-react-parser';

const QuestionsInfo = ({ info, howToAnswer }) => {
  if (!info && !howToAnswer) return null;
  return (
    <Stack>
      {!!info && (
        <>
          <Typography variant="button">Additional Information</Typography>
          <Typography sx={{ pointerEvents: 'none' }} variant="body2">
            {Parser(info)}
          </Typography>
        </>
      )}
      {!!howToAnswer && (
        <>
          <Typography variant="button">How To Answer Question</Typography>
          <Typography sx={{ pointerEvents: 'none' }} variant="body2">
            {Parser(howToAnswer)}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default QuestionsInfo;
