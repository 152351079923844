// @flow
import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { LikeIcon, CommentIcon, Mention, Alert2 } from 'components';
import { useBreakpoint } from 'hooks';

function getType(type, mobileView, size) {
  switch (type) {
    case 'post_like':
    case 'comment_like':
      return {
        color: '#0CCE6B',
        icon: <LikeIcon size={mobileView || size === 'sm' ? 1.1 : 1.6} color="colorCommonWhite" />
      };
    case 'post_comment':
      return {
        color: '#0069FF',
        icon: <CommentIcon size={mobileView || size === 'sm' ? 1.1 : 1.6} color="colorCommonWhite" />
      };
    case 'post_mention':
    case 'comment_mention':
      return {
        color: '#082F49',
        icon: <Mention size={mobileView || size === 'sm' ? 1.1 : 1.6} color="colorCommonWhite" />
      };
    case 'post_creation':
      return {
        color: 'var(--colorSystemDanger)',
        icon: <Alert2 size={mobileView || size === 'sm' ? 1.1 : 1.6} color="colorCommonWhite" />
      };
    default:
      return {
        color: '#0069FF',
        icon: <CommentIcon size={mobileView || size === 'sm' ? 1.1 : 1.6} color="colorCommonWhite" />
      };
  }
};

const useStyles = makeStyles(theme => ({
  avatarWrap: {
    position: 'relative'
  },
  avatar: {
    backgroundColor: props => props.backgroundColor,
    width: props => props.size === 'sm' ? 50 : 63,
    height: props => props.size === 'sm' ? 50 : 63,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
      fontSize: 16,
    },
  },
  type: {
    position: 'absolute',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: props => props.size === 'sm' ? -4 : -6,
    right: props => props.size === 'sm' ? -7 : -11,
    width: props => props.size === 'sm' ? 24 : 30,
    height: props => props.size === 'sm' ? 24 : 30,
    backgroundColor: props => getType(props.type)['color'],
    [theme.breakpoints.down('sm')]: {
      top: '-4px',
      right: '-7px',
      width: '24px',
      height: '24px',
    },
  }
}));

const NotificationAvatar = ({ user, type, size }) => {
  const mobileView = useBreakpoint('sm');
  const backgroundColor = user?.avatar_filename ? null : user.partner_base_color;
  const classes = useStyles({ user, type, size, backgroundColor });
  return (
    <div className={classes.avatarWrap}>
      <Avatar 
      src={user?.avatar_filename ? `/media/avatars/${user?.avatar_filename}` : undefined}
      className={classes.avatar}>
        {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
      </Avatar>
      <span className={classes.type}>{getType(type, mobileView, size)['icon']}</span>
    </div>
  );
};

export default NotificationAvatar;
