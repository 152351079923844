// @flow
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { makeStyles, Popover } from '@material-ui/core';
import { cleanTextFromHtml } from 'helpers';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css'; // ES6
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import 'quill-emoji/dist/quill-emoji.css';
import './RichTextEditorStyles.css';
import data from 'emoji-mart/data/google.json';
import { NimblePicker, Emoji as EmojiIcon } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import * as InlineStyle from './RichTextEditorInStyle';
import { Box, styled as CLStyled } from '@trustsecurenow/components-library';

const useStyles = makeStyles({
  editor: {
    // This style is only for Safai browser to fix the cursor issue
    '@media not all and (min-resolution:.001dpcm)': {
      '@media': {
        '& .mention': {
          '-webkit-user-select': 'unset'
        }
      }
    }
  }
});

Quill.register('modules/emoji', Emoji);

const Container = CLStyled(Box)(() => ({
  width: '100%',
  fontFamily: 'var(--fontFamily)',
  margin: 0,
  border: 'none',
  background: 'none',
  padding: 0
}));

const RQcontent = styled.div`
  width: 100%;
  .ql-snow .ql-stroke {
    stroke: var(--colorIcon);
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--colorIcon);
  }
  .ql-snow .ql-picker-label::before {
    color: var(--colorIcon);
  }
  .ql-formats button {
    position: relative;
    &:hover::after {
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .quill {
    width: 100%;
    min-height: ${({ minHeight }) => minHeight || '300px'};
    display: flex;
    flex-direction: column;
  }
  .ql-toolbar {
    border-radius: 5px 5px 0 0;
    border-color: var(--borderBase);
  }
  .ql-container {
    border-radius: 0 0 5px 5px;
    font-family: var(--fontFamily);
    font-size: ${({ fontSize }) => fontSize || '13px'};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-color: var(--borderBase);
    .ql-mention-list-container {
      max-height: 200px;
      white-space: normal;
    }
  }
  .ql-editor {
    resize: ${({ allowResize }) => (allowResize ? 'vertical' : 'none')};
    flex-grow: 1;
    max-height: 350px;
    word-break: break-word;
    color: ${({ textColor }) => textColor || 'inherit'};
    pre,
    code {
      background-color: var(--backgroundCode);
    }
    p {
      width: fit-content;
      min-width: 1px;
    }
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    color: var(--colorDarkGrey);
    opacity: 0.5;
  }
  .mention {
    color: var(--colorSystemInfo);
    font-weight: 500;
  }
  .ql-bold {
    &:hover::after {
      content: 'Bold';
    }
  }
  .ql-italic {
    &:hover::after {
      content: 'Italic';
    }
  }
  .ql-underline {
    &:hover::after {
      content: 'Underline';
    }
  }
  .ql-strike {
    &:hover::after {
      content: 'Strike';
    }
  }
  .ql-font {
    &:hover::after {
      content: 'Font';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-blockquote {
    &:hover::after {
      content: 'Quote';
    }
  }
  .ql-code-block {
    &:hover::after {
      content: 'Code';
    }
  }
  .ql-size {
    &:hover::after {
      content: 'Size';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-list[value='ordered'] {
    &:hover::after {
      content: 'Ordered-List';
    }
  }
  .ql-list[value='bullet'] {
    &:hover::after {
      content: 'Bullet-List';
    }
  }
  .ql-script[value='sub'] {
    &:hover::after {
      content: 'Sub-Script';
    }
  }
  .ql-script[value='super'] {
    &:hover::after {
      content: 'Super-Script';
    }
  }
  .ql-background {
    &:hover::after {
      content: 'Text-Background';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-color {
    &:hover::after {
      content: 'Text-Color';
      background: #0d1e42;
      white-space: pre;
      color: white;
      padding: 0.5em;
      border-radius: 0.4em;
      top: -120%;
      left: -10px;
      position: absolute;
      font-size: 12px;
    }
  }
  .ql-link {
    &:hover::after {
      content: 'Link';
    }
  }
  .ql-image {
    &:hover::after {
      content: 'Image URL';
    }
  }
  .ql-custom-emoji {
    background: url('/media/emojiIconDark.svg') no-repeat center/19px 19px !important;
    &:hover::after {
      content: 'Emoji';
    }
  }

  body[data-theme='dark'] & .ql-custom-emoji {
    background: url('/media/emojiIconLight.svg') no-repeat center/19px 19px !important;
  }
`;

const defaultToolBarOptions = [
  [{ size: ['0.75em', false, '1.5em', '2.5em'] }], // custom dropdown
  [{ font: [] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }]
];

const defaultFormat = [
  'size',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'script',
  'color',
  'background',
  'link',
  'image',
  'video',
  'mention'
];

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['0.75em', '1em', '1.5em', '2.5em'];
Quill.register(Size, true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register(InlineStyle.BlockquoteInlineStyle, true);
Quill.register(InlineStyle.CodeblockInlineStyle, true);

const Link = Quill.import('formats/link');
Link.sanitize = function(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

// Custom emoji blot
const BlockEmbed = Quill.import('blots/embed');

class CustomEmojiBlot extends BlockEmbed {
  static blotName = 'customEmoji';

  static tagName = 'span';

  static className = 'custom-emoji-class';

  static create({ emojiStyles, emojiUniCode }) {
    const node = super.create();
    node.classList.add('emoji-mart-emoji');
    node.setAttribute('style', emojiStyles);
    node.innerText = emojiUniCode;
    return node;
  }

  static value(node) {
    return {
      emojiStyles: node.getAttribute('style'),
      emojiUniCode: node.innerText
    };
  }

  // Overriding of update function due to problem with Emoji deletion on some phone devices.
  // For more info referr to: https://github.com/quilljs/quill/issues/1985

  update(mutations, context) {
    mutations.forEach(mutation => {
      if (
        mutation.type === 'childList' &&
        (Array.from(mutation.removedNodes).includes(this.leftGuard) ||
          Array.from(mutation.removedNodes).includes(this.rightGuard))
      ) {
        let tag;
        if (mutation.previousSibling) {
          tag = mutation.previousSibling.innerText;
        } else if (mutation.nextSibling) {
          tag = mutation.nextSibling.innerText;
        }
        if (tag) {
          super.replaceWith('text', tag);
        }
      }
    });

    super.update(mutations, context);
  }
}

Quill.register(CustomEmojiBlot);

function RichTextEditor({
  name,
  toolbarOptions = defaultToolBarOptions,
  format = defaultFormat,
  autoComplete = {},
  onChangeEditorState,
  currentState,
  height,
  fontSize,
  allowResize,
  clearState,
  setClearState,
  mentionData = null,
  scrollingContainer = null,
  disabled = false,
  enableOnChangeApiSource = true,
  disableDragDrop = false,
  containerStyles,
  textColor = null,
  containerComponent = 'pre',
  ...rest
}) {
  const [text, setText] = useState(currentState ?? '');
  const [mentionDataLocal, setMentionDataLocal] = useState(null);
  const quillRef = useRef(null);
  const classes = useStyles();
  const [emojiBtnAnchorEl, setEmojiBtnAnchorEl] = useState(null);
  const [, setUpdatePicker] = useState(true);

  const removeMentionCloseDiv = useCallback(() => {
    return document?.querySelector('#mention-close-div')?.remove();
  }, []);

  useEffect(() => {
    if (!quillRef.current) {
      return;
    }
    setClearState &&
      setClearState(old => {
        if (old) {
          const quill = quillRef.current.getEditor();
          quill.root.innerHTML = '';
          setText('');
          setTimeout(() => {
            quill.setSelection(null, 'user');
          }, 0);
          setClearState(!old);
        }
      });
    // setClearState should be a dispatcher function from either useState or reducer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef, clearState]);

  useEffect(() => {
    if (!quillRef?.current) {
      return;
    }
    if (disabled) quillRef.current.editor.enable(false);
  }, [disabled, quillRef]);

  const closeMentionMenu = React.useCallback(() => {
    const mentionListDiv = document.querySelector('.ql-mention-list-container');
    if (mentionListDiv) mentionListDiv.remove();
  }, []);

  const onRotate = React.useCallback(() => {
    closeMentionMenu();
  }, [closeMentionMenu]);

  // This effect is programmatically setting @mention inside of a text editor when user clicks on "Reply" from Newsfeed comments section. This is only allowed when the editor is empty or there is only another mention that should be replaced with the new one
  useEffect(() => {
    if (mentionData && quillRef.current) {
      setMentionDataLocal(prevData => {
        const cleanText = cleanTextFromHtml(text).trim();
        const userChanged = prevData?.id !== mentionData.id && cleanText === prevData?.value;
        if ((!prevData && !cleanText) || !cleanText || userChanged) {
          const quill = quillRef.current.getEditor();
          quill.root.innerHTML = '';
          quill.setContents([{ insert: ' ' }]);
          setText('');
          quill.getModule('mention').insertItem(mentionData, true);
          return mentionData;
        }
        return prevData;
      });
    }
  }, [mentionData]);

  useEffect(() => {
    document.body.addEventListener('click', closeMentionMenu);
    if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', onRotate);
    }
    return () => {
      document.body.removeEventListener('click', closeMentionMenu);
      if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', onRotate);
      }

      closeMentionMenu();
      removeMentionCloseDiv();
    };
  }, [removeMentionCloseDiv, closeMentionMenu, onRotate]);

  const handleChange = (content, delta, source, editor) => {
    setText(content);
    onChangeEditorState && onChangeEditorState(content, source);
  };

  useEffect(() => {
    const mentions = document.querySelectorAll('.mention span');
    if (!mentions) return;
    mentions.forEach(mention => {
      mention.setAttribute('contenteditable', 'true');
    });
  }, [text]);

  const imageHandler = React.useCallback(() => {
    if (!quillRef.current) {
      return;
    }
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    const value = prompt('What is the image URL');
    if (value) {
      quill.insertEmbed(range.index, 'image', value);
    }
  }, [quillRef]);

  const customEmojiHandler = useCallback(() => {
    if (!quillRef.current) return;
    const toolbar = quillRef.current.getEditor().getModule('toolbar');
    const customEmojiBtn = toolbar.controls.find(btn => btn[0] === 'custom-emoji')[1];
    setEmojiBtnAnchorEl(customEmojiBtn);
    setUpdatePicker(true);
    setTimeout(() => {
      setUpdatePicker(false);
    }, 500);
  }, [quillRef]);

  const addCustomEmoji = emoji => {
    let emojiStyles = '';

    const emojiElement = <EmojiIcon emoji={emoji} set="google" size={22} />;
    const emojiHtmlString = ReactDOMServer.renderToStaticMarkup(emojiElement);

    parse(emojiHtmlString, {
      replace: domNode => {
        if (domNode.attribs && domNode.attribs.style) {
          emojiStyles = domNode.attribs.style;
        }
      }
    });

    const quill = quillRef.current.getEditor();
    const range = quill.getSelection(true);
    const position = range ? range.index : 0;
    quill.insertEmbed(position, 'customEmoji', { emojiStyles, emojiUniCode: emoji.native });
    setTimeout(() => {
      quill.setSelection(position + 1);
    }, 0);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
          'custom-emoji': customEmojiHandler
        }
      },
      history: {
        userOnly: true
      },
      mention: autoComplete,
      clipboard: {
        matchVisual: false
      }
    }),
    [JSON.stringify(autoComplete)]
  );

  return (
    <Container style={containerStyles} component={containerComponent}>
      <RQcontent
        textColor={textColor}
        fontSize={fontSize}
        minHeight={height}
        allowResize={allowResize}
        {...(disableDragDrop ? { onDrop: e => e.preventDefault() } : {})}
      >
        <ReactQuill
          value={text}
          modules={modules}
          formats={format ?? []}
          theme="snow"
          scrollingContainer={scrollingContainer}
          onChange={handleChange}
          ref={quillRef}
          {...rest}
          className={classes.editor}
        />
        <textarea disabled name={`${name}`} value={text} style={{ display: 'none' }} />
      </RQcontent>

      {/* Custom emoji picker */}
      <Popover
        id="emoji-popover"
        open={Boolean(emojiBtnAnchorEl)}
        anchorEl={emojiBtnAnchorEl}
        onClose={() => {
          setEmojiBtnAnchorEl(null);
          quillRef.current.focus();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <NimblePicker
          set="google"
          showPreview={false}
          showSkinTones={false}
          data={data}
          onSelect={emoji => addCustomEmoji(emoji)}
        />
      </Popover>
    </Container>
  );
}
export default RichTextEditor;
