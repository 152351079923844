// @flow
import React, { useState, memo } from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton, Select, FormControl, MenuItem, Chip, Checkbox, Input, ListItemText } from '@material-ui/core';
import { TableCellAlign } from './ComponentTypes';

type EditingCellTypes = {
  cellValue: string,
  cellEditing: Array<[boolean, string]>,
  cellEditType: string
};

const EditingCell = ({ cellValue, cellEditing, cellEditType }: EditingCellTypes) => {
  const [select, setSelect] = useState(false);
  const [value, setValue] = useState([cellValue]);

  const handleSelect = e => {
    setValue(e.target.value);
    setSelect(false);
  };

  return (
    <TableCellAlign>
      {select ? (
        <>
          {cellEditType === 'single' ? (
            <FormControl variant="outlined">
              <Select value={value} onChange={handleSelect} displayEmpty>
                <MenuItem value={value} disabled>
                  {value}
                </MenuItem>
                {cellEditing.map(option => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined">
              <Select
                multiple
                value={value}
                onChange={handleSelect}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (selected.length === 0 ? { value } : selected.join(', '))}
              >
                {cellEditing.map(option => (
                  <MenuItem value={option}>
                    <Checkbox checked={value.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      ) : (
        <>
          {cellEditType === 'single' ? (
            value
          ) : (
            <>
              {value.map(label => (
                <Chip key={label} label={label} />
              ))}
            </>
          )}
        </>
      )}
      <IconButton onClick={() => setSelect(!select)}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    </TableCellAlign>
  );
};

export default memo<EditingCellTypes>(EditingCell);
