// @flow

import { type ComponentType } from 'react';
import styled from 'styled-components';
// import { theme } from 'conf/theme';
import { transitions } from 'conf';
import { /* Box as MuiBox, CardContent, Paper as MuiPaper, */ Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';

export const TabsFixed: ComponentType<*> = styled.div`
  position: sticky;
  top: 0;
  background: var(--backgroundDefault);
  /* TODO: Change CSS Variables */
  transition: all all ${transitions.duration.leavingScreen}ms ${transitions.easing.sharp};
  z-index: 999;
`;

export const TabsHeader: ComponentType<*> = styled(MuiTabs)`
  && {
    width: 100%;
  }
  border-bottom: 1px solid var(--borderDefault);
  /* margin-bottom: 20px; */

  > div {
    &:nth-of-type(3) {
      > span {
        background: var(--colorBaseLight2);
      }
    }
  }
`;

export const TabButton: ComponentType<*> = styled(MuiTab)`
  && {
    text-transform: none;
    font-size: var(--fontSize);
    padding: 0;
    margin-right: 30px;
    min-width: inherit;
  }
`;

// export const Box: ComponentType<*> = styled(MuiBox)`
//   && {
//     padding-top: calc(var(--spacing) * 2);
//     padding-bottom: calc(var(--spacing) * 2);
//   }
// `;

// export const Tabs: ComponentType<*> = styled(MuiTabs)`
//   .bsn-tab-indicator {
//     height: 5px;
//     border-radius: 10px;
//     background: red;
//   }

//   button {
//     width: inherit;
//   }
// `;

// export const Tab: ComponentType<*> = styled(MuiTab)`
//   text-transform: none;
//   color: var(--colorCommonBlack);
//   font-size: 1.5rem;
// `;

// export const Paper: ComponentType<*> = styled(MuiPaper)`
//   ${theme.mixins.gutters()};
//   && {
//     padding: calc(var(--spacing) * 4);
//   }
// `;

// export const TabContent: ComponentType<*> = styled(CardContent)`
//   padding: 0;
// `;
