// @flow
import React, { useState } from 'react';
import { Tooltip, ClickAwayListener, makeStyles } from '@material-ui/core';

type TooltipTypes = {
  title: string,
  children: any,
  disableClickListener: boolean
};

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'var(--backgroundPaper)',
    color: 'var(--colorDefault)',
    maxWidth: props => props?.maxWidth,
    padding: 10,
    fontWeight: 'normal',
    textAlign: props => props?.textAlign ?? 'center',
    boxShadow: theme.shadows[2],
    left: props => `${props.tooltipX}%`,
    pointerEvents: 'auto'
  },
  arrow: {
    // white arrow problem solved in https://github.com/mui/material-ui/pull/21095
    color: 'var(--backgroundPaper)',
    '&:before': {
      boxShadow:
        '-1px -1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    left: props => `${props?.arrowX}% !important`
  }
}));

const CustomTooltip = ({
  title,
  children,
  disableClickListener,
  light,
  maxWidth = 200,
  tooltipX = null,
  arrowX = null,
  hide = false,
  isClickable = false,
  ...rest
}: TooltipTypes) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({
    maxWidth,
    tooltipX,
    arrowX,
    textAlign: rest.textAlign
  });

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (!children?.props?.disabled) setOpen(true);
  };

  // const TooltipComp = light ? LightTooltip : Tooltip;

  const contentConfig = isClickable
    ? {
        onClick: handleTooltipOpen
      }
    : {
        onMouseEnter: handleTooltipOpen,
        onMouseLeave: handleTooltipClose,
        onClick: disableClickListener ? null : handleTooltipClose
      };

  return !!hide ? (
    children
  ) : (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        classes={light ? classes : null}
        title={title}
        open={open}
        {...rest}
        style={{ cursor: disableClickListener ? 'default' : 'pointer' }}
      >
        <div {...contentConfig} style={{ cursor: disableClickListener ? 'default' : 'pointer' }}>
          {children}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CustomTooltip;
