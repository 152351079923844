// @flow
import React from 'react';
import { Box, Typography, makeStyles, Link, Dialog, Button } from '@material-ui/core';
import { DeleteIcon } from 'components';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '30px 20px',
    textAlign: 'center'
  },
  title: {
    fontSize: '24px',
    color: 'var(--colorSystemDanger)'
  },
  description: {
    padding: '12px 120px 12px 120px',
  },
  cancelBtn: {
    color: 'var(--colorDefault)',
    textDecoration: 'underline',
  },
  deleteBtn: {
    backgroundColor: ({disabledSubmit}) => `${disabledSubmit ? "#ffa6aa" : "#FF5A61"} !important`,
    color: '#fff !important',
    textTransform: 'capitalize',
  },
  deleteIcon: {
    backgroundColor: 'rgba(255, 90, 97, 0.2)',
    color: '#FF5A61',
    borderRadius: '50%',
    padding: '14px',
    fontSize: '34px'
  }
}));

const PermanentDeleteModal = ({ open, close, onDelete, title, description, disabledSubmit = false }) => {
  const classes = useStyles({
    disabledSubmit
  });

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <div className={classes.content}>
        <Box pb={2.5}>
          <DeleteIcon className={classes.deleteIcon} />
        </Box>
        <Typography variant="h4" display="block" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <Typography display="block" gutterBottom className={classes.description}>
          {description}
        </Typography>
        <Box pb={2.5}>
          <Button variant="contained" className={classes.deleteBtn} onClick={onDelete} disabled={disabledSubmit}>
            Yes, I want to permanently delete
          </Button>
        </Box>
        <Box>
          <Link
            className={classes.cancelBtn}
            href="#"
            onClick={e => {
              e.preventDefault();
              close();
            }}
            color="inherit"
          >
            Cancel
          </Link>
        </Box>
      </div>
    </Dialog>
  );
};

export default PermanentDeleteModal;
