// @flow

const phishingCampaignScenariosList = {
  type: 'phishingCampaignScenariosList',
  selecting: true,
  selectingRadio: true,
  folding: false,
  filtering: false,
  search: true,
  searchFields: ['name', 'description'],
  searchLabel: 'Search',
  row: {
    click: true,
    action: 'modal',
    modal: {
      app: 'clients',
      tab: 'scenarioPreview'
    }
  },

  cells: [
    { id: 'name', label: 'Name', editing: false, filter: false },
    { id: 'difficulty', label: 'Difficulty', editing: false, filter: false },
    { id: 'country', label: 'Country', editing: false, filter: false },
    { id: 'description', label: 'Description', editing: false, filter: false, removeHtml: true }
  ]
};

export default phishingCampaignScenariosList;
